import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NotificationsBasicInfo from "./NotificationsBasicInfo";
import NotificationsDeviceSelection from "./NotificationsDeviceSelection";
import NotificationsTimeInterval from "./NotificationsTimeInterval";
import { NotificationType } from "../Notifications";
import { isMobile } from "../../../../mobileConfig";
import NotificationsReasons from "./NotificationsReasons";
import { i18n } from "../../../../i18n/i18nText";
import { Notification } from "../../../../redux/notifications/types";
import { hideModal } from "../../../../redux/modal/actions";
import { useDispatch } from "react-redux";
import { NotificationHookActions } from "../../../../hooks/models/useNotifications";
import { Reasons } from "../../../../hooks/models/useReasons";
import { Devices } from "../../../../hooks/models/useDevices";
import SmsFailedOutlinedIcon from "@material-ui/icons/SmsFailedOutlined";
import { StepIconProps } from "@material-ui/core";
import { Keyboard } from "@capacitor/keyboard";

interface Props {
  farmID: number;
  userID?: number;
  type: NotificationType;
  mode: "create" | "edit";
  notificationID?: number;
  notification?: Notification;
  devices?: Devices[];
  reasons?: Reasons[];
  actions?: NotificationHookActions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      backgroundColor: theme.palette.background.paper,
      display: "grid",
      gridTemplateRows: `repeat(1, 1fr)`,
      alignItems: "center",
      gridGap: "50px 50px 0px 50px",
      width: isMobile() ? "auto" : "600px",
    },
    confirmation: {
      backgroundColor: theme.palette.background.paper,
      display: "grid",
      gridTemplateRows: `repeat(1, 1fr)`,
      alignItems: "center",
      gridGap: "50px 50px 0px 50px",
    },
    content: {
      margin: isMobile() ? "15px" : "30px",
    },
    buttonsDesktop: {
      display: "flex",
      alignItems: " center",
      justifyContent: "space-evenly",
      margin: "15px",
      fontColor: "white",
    },
    buttonsMobile: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "15px",
      color: "white",
      bottom: 0,
      left: 0,
      right: 0,
    },
    button: {
      width: "100px",
    },
    instructions: {
      display: "flex",
      alignItems: " center",
      justifyContent: "center",
      height: "150px",
    },
  })
);

function validateNotification(notification: Notification): boolean {
  const requiredFields: (keyof Notification)[] = [
    "farm",
    "name",
    "reasons",
    "devices",
  ];

  for (const field of requiredFields) {
    const value = notification[field];
    if (
      !value ||
      (typeof value === "string" &&
        (!value.length || notification.name.length > 30)) ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return false;
    }
  }

  return true;
}

function validateStep(
  notification: Notification,
  required: (keyof Notification)[]
): boolean {
  const requiredFields: (keyof Notification)[] = required;

  for (const field of requiredFields) {
    const value = notification[field];
    if (
      !value ||
      (typeof value === "string" &&
        (!value.length || notification.name.length > 30)) ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return false;
    }
  }

  return true;
}

const getStartData = (
  farmID: number,
  mode: string,
  notification: Notification | null
) => {
  if (mode === "edit") return notification;
  if (mode === "create")
    return {
      farm: farmID,
      name: "",
      reasons: [],
      enable: true,
      critical_reasons: [],
      start: "00:00:00",
      end: "23:59:59",
      devices: [],
    };
};

export default function NotificationSteps(props: Props) {
  const {
    farmID,
    userID,
    type,
    mode,
    notificationID,
    notification,
    devices,
    reasons,
    actions,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentNotification, setCurrentNotification] = useState<Notification>(
    getStartData(farmID, mode, notification)
  );
  const [protocol, setProtocol] = useState<number>();
  const [currentDevices, setCurrentDevices] = useState<any[]>();
  const [currentReasons, setCurrentReasons] = useState<Reasons[]>();
  const [currentCriticalReasons, setCurrentCriticalReasons] = useState<
    Reasons[]
  >();

  const hasProtocols: boolean = Boolean(
    Array.from(new Set(devices.map((obj) => obj.protocol))).length > 1
  );

  useEffect(() => {
    if (!hasProtocols)
      setProtocol(
        Number(Array.from(new Set(devices.map((obj) => obj.protocol))))
      );
  }, [hasProtocols]);

  useEffect(() => {
    if (farmID && devices && reasons && protocol && mode === "create") {
      let devicesList = devices.filter((device) => device.protocol == protocol);
      let reasonsList = reasons.filter((reason) => reason.protocol == protocol);
      let criticalReasonsList = reasons.filter((reason) => reason.critical);
      setCurrentDevices(devicesList);
      setCurrentReasons(reasonsList);
      setCurrentCriticalReasons(criticalReasonsList);
    } else {
      setCurrentDevices(devices);
      setCurrentReasons(reasons);
      let criticalReasonsList = reasons.filter((reason) => reason.critical);
      setCurrentCriticalReasons(criticalReasonsList);
    }
  }, [farmID, protocol]);

  const [isKeyboardVisible, setKeyboardVisible] = useState(true);

  useEffect(() => {
    console.log(isKeyboardVisible);
  }, [isKeyboardVisible]);

  const SmsFailedOutlinedIconStyled = (props: StepIconProps) => {
    return (
      <SmsFailedOutlinedIcon
        style={{
          backgroundColor: props.active ? "#44b86a" : "#9e9e9e",
          color: "white",
          borderRadius: "50%",
          width: "28px",
          height: "28px",
          padding: "4px",
        }}
      />
    );
  };

  const STEPS = {
    0: [
      {
        step: 0,
        label: i18n("CREATE_NOTIFICATIONS_BASIC_INFO"),
        required: ["name"],
        content: (
          <NotificationsBasicInfo
            type={type}
            mode={mode}
            notification={currentNotification}
            setNotification={setCurrentNotification}
            protocol={protocol}
            setProtocol={setProtocol}
            hasProtocols={hasProtocols}
          />
        ),
      },
      {
        step: 1,
        label: i18n("CREATE_NOTIFICATIONS_TIME_PERIOD"),
        required: [],
        content: (
          <NotificationsTimeInterval
            notification={currentNotification}
            setNotification={setCurrentNotification}
          />
        ),
      },
      {
        step: 2,
        label: i18n("CREATE_NOTIFICATIONS_DEVICES"),
        required: ["devices"],
        content: (
          <NotificationsDeviceSelection
            notification={currentNotification}
            setNotification={setCurrentNotification}
            devices={currentDevices}
          />
        ),
      },
      {
        step: 3,
        label: i18n("CREATE_NOTIFICATIONS_REASONS"),
        required: ["reasons"],
        content: (
          <NotificationsReasons
            notification={currentNotification}
            setNotification={setCurrentNotification}
            reasons={currentReasons}
            criticalReasons={currentCriticalReasons}
          />
        ),
      },
    ],
    1: [
      {
        step: 0,
        label: i18n("CREATE_NOTIFICATIONS_BASIC_INFO"),
        required: ["name"],
        content: (
          <NotificationsBasicInfo
            type={type}
            mode={mode}
            notification={currentNotification}
            setNotification={setCurrentNotification}
            protocol={protocol}
            setProtocol={setProtocol}
            hasProtocols={hasProtocols}
          />
        ),
      },
      {
        step: 1,
        label: i18n("CREATE_NOTIFICATIONS_TIME_PERIOD"),
        required: [],
        content: (
          <NotificationsTimeInterval
            notification={currentNotification}
            setNotification={setCurrentNotification}
          />
        ),
      },
      {
        step: 2,
        label: i18n("CREATE_NOTIFICATIONS_DEVICES"),
        required: ["devices"],
        content: (
          <NotificationsDeviceSelection
            notification={currentNotification}
            setNotification={setCurrentNotification}
            devices={currentDevices}
          />
        ),
      },
      {
        step: 3,
        label: i18n("CREATE_NOTIFICATIONS_REASONS"),
        required: ["reasons"],
        content: (
          <NotificationsReasons
            notification={currentNotification}
            setNotification={setCurrentNotification}
            reasons={currentReasons}
            criticalReasons={currentCriticalReasons}
          />
        ),
      },
    ],
    2: [
      {
        step: 0,
        label: i18n("CREATE_NOTIFICATIONS_BASIC_INFO"),
        required: ["name"],
        content: (
          <NotificationsBasicInfo
            type={type}
            mode={mode}
            notification={currentNotification}
            setNotification={setCurrentNotification}
            protocol={protocol}
            setProtocol={setProtocol}
            hasProtocols={hasProtocols}
          />
        ),
      },
      {
        step: 1,
        label: i18n("CREATE_NOTIFICATIONS_TIME_PERIOD"),
        required: [],
        content: (
          <NotificationsTimeInterval
            notification={currentNotification}
            setNotification={setCurrentNotification}
          />
        ),
      },
      {
        step: 2,
        label: i18n("CREATE_NOTIFICATIONS_DEVICES"),
        required: ["devices"],
        content: (
          <NotificationsDeviceSelection
            notification={currentNotification}
            setNotification={setCurrentNotification}
            devices={currentDevices}
          />
        ),
      },
      {
        step: 3,
        label: i18n("CREATE_NOTIFICATIONS_REASONS"),
        required: ["reasons"],
        content: (
          <NotificationsReasons
            notification={currentNotification}
            setNotification={setCurrentNotification}
            reasons={currentReasons}
            criticalReasons={currentCriticalReasons}
          />
        ),
      },
    ],
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    if (mode === "create") {
      actions.post(type, { ...currentNotification, user: userID });
      dispatch(hideModal());
    }
    if (mode === "edit") {
      actions.patch(type, notificationID, currentNotification);
      dispatch(hideModal());
    }
  };

  return (
    <div className={classes.root}>
      {activeStep === STEPS[type].length ? null : (
        <Stepper activeStep={activeStep} alternativeLabel>
          {STEPS[type].map((step) => (
            <Step key={step.step}>
              <StepLabel
                StepIconComponent={step.icon}
                StepIconProps={step.icon}
              >
                {isMobile() ? null : step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {activeStep === STEPS[type].length ? (
        <div className={classes.confirmation}>
          <div className={classes.content}>
            <Typography className={classes.instructions}>
              {mode === "create"
                ? i18n("CREATE_NOTIFICATIONS_QUESTION")
                : i18n("EDIT_NOTIFICATIONS_QUESTION")}
            </Typography>
          </div>

          <div
            className={
              isMobile() ? classes.buttonsMobile : classes.buttonsDesktop
            }
            style={{ position: isKeyboardVisible ? "relative" : "absolute" }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="inherit"
              onClick={handleBack}
            >
              {i18n("CREATE_NOTIFICATIONS_BACK")}
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!validateNotification(currentNotification)}
            >
              {mode === "create"
                ? i18n("CREATE_NOTIFICATIONS_CREATE")
                : i18n("EDIT_NOTIFICATIONS_SAVE")}
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.content}>
            {STEPS[type][activeStep].content}
          </div>

          <div
            className={
              isMobile() ? classes.buttonsMobile : classes.buttonsDesktop
            }
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              color="inherit"
              className={classes.button}
            >
              {i18n("CREATE_NOTIFICATIONS_BACK")}
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={
                !validateStep(
                  currentNotification,
                  STEPS[type][activeStep].required
                )
              }
            >
              {activeStep === STEPS[type].length - 1
                ? i18n("CREATE_NOTIFICATIONS_FINISH")
                : i18n("CREATE_NOTIFICATIONS_NEXT")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

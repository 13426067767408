import React, { useEffect, useState } from "react";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import useIrpd from "../../../../hooks/models/useIrpd";
import { useParams } from "react-router";
import EditIrpdForm from "./Components/EditLrpdForm/EditIrpdForm";
import EditIrpdFormV5 from "./Components/EditIrpdFormV5/EditIrpdFormV5";
import { useRequest } from "ahooks";
import { coreHTTPClient } from "../../../../services/webclient";

export default function EditIrpd() {
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);

  const [availableMeters, setAvailableMeters] = useState<
    { id: number | null; name: string }[]
  >([
    {
      id: null,
      name: "No Imeter",
    },
  ]);

  const imeterReq = useRequest(
    () =>
      coreHTTPClient.get(
        `v4/farms/${farmID}/metersystems/imeter/simple/?protocol=5.3`
      ),
    { manual: false }
  );

  useEffect(() => {
    if (imeterReq.data) {
      setAvailableMeters([
        {
          id: null,
          name: "No Imeter",
        },
        ...imeterReq.data?.data?.map((dt) => ({
          id: dt.id,
          name: dt.name,
        })),
      ]);
    }
  }, [imeterReq.data]);

  const [async, irpd] = useIrpd(farmID, irpdID, true);

  if (async.done && irpd !== undefined) {
    if (irpd.protocol == 4) {
      return <EditIrpdForm irpd={irpd} />;
    } else
      return <EditIrpdFormV5 irpd={irpd} availableMeters={availableMeters} />;
  }

  return <CustomCircularProgress />;
}

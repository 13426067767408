import { Box } from "@material-ui/core";
import * as React from "react";
import Cards from "react-credit-cards-2";
import { PaymentMethodCard } from "../../../../../../../../../../hooks/models/usePaymentMethod";

interface Props {
  paymentMethod: PaymentMethodCard;
}

const PaymentCard = (props: Props) => {
  const { paymentMethod } = props;

  return (
    <Box>
      <Cards
        name={paymentMethod.name}
        number={paymentMethod.last4}
        expiry={paymentMethod.exp_date}
        issuer={paymentMethod.brand}
        cvc={"***"}
        preview
      />
    </Box>
  );
};

export default PaymentCard;

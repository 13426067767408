import React, { useEffect, useState } from "react";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../../../../../../services/webclient";
import { isMobile } from "../../../../../../../../../mobileConfig";
import BillingHistorySkeleton from "./BillingHistorySkeleton";
import BillingHistoryMobile from "./BillingHistoryMobile";
import BillingHistoryComponent from "./BillingHistoryComponent";
import { i18n } from "../../../../../../../../../i18n/i18nText";

interface Props {
  costumerID: number;
  farmID: string;
}

export default function BillingHistory(props: Props) {
  const { costumerID, farmID } = props;

  const [loading, setLoading] = useState(true);

  const [customerExists, setCustomerExists] = useState(true);

  const [invoices, setInvoices] = useState([]);

  const [count, setCount] = useState(0);

  const rowsPerPage = 6;

  const labelDisplayedRows = (props) => {
    const { count, page } = props;

    return `${count > 0 ? page * rowsPerPage + 1 : 0}-${
      (page + 1) * rowsPerPage < count ? (page + 1) * rowsPerPage : count
    } ${i18n("TABLE_OF")} ${count}`;
  };

  async function getInvoices(farmID: string) {
    try {
      if (costumerID) {
        const invoiceResponse = await coreHTTPClient.get(
          `/v4/farms/${farmID}/billing/customer/${costumerID}/invoice/`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
          }
        );

        const invoiceData = invoiceResponse.data.result;

        setCount(invoiceData.length);

        const sliceList = (lista, tamanho) => {
          var result = [];
          for (var i = 0; i < lista.length; i += tamanho) {
            result.push(lista.slice(i, i + tamanho));
          }
          return result;
        };

        const tableInvoiceData = sliceList(invoiceData, rowsPerPage);

        setInvoices(tableInvoiceData);
        setLoading(false);
      } else {
        setCustomerExists(false);
        setLoading(false);
      }
    } catch (err) {
      setCustomerExists(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    getInvoices(farmID);
  }, [farmID]);

  return (
    <div style={{ marginBottom: "40px" }}>
      {loading ? (
        <BillingHistorySkeleton />
      ) : isMobile() ? (
        <BillingHistoryMobile
          loading={loading}
          customerExists={customerExists}
          invoices={invoices}
          count={count}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
        />
      ) : (
        <BillingHistoryComponent
          loading={loading}
          customerExists={customerExists}
          invoices={invoices}
          count={count}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
        />
      )}
    </div>
  );
}

import "./RepeaterItem.scss";
import { Slide } from "@material-ui/core";
import moment from "moment";
import React from "react";
import DesktopZone from "../../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../../components/Zones/MobileZone";

export interface SelectedFarmParams {
  farm: string;
}

interface Props {
  repeaterName: string;
}

function RepeaterItem(props: Props) {
  let { repeaterName } = props;
  return (
    <>
      <MobileZone>
        <Slide direction="down" in={true}>
          <div className="irpd-item">
            <div className="pivot-item__row-left">
              <div className="irpd-item__container">
                <div className="irpd-item__content">
                  <div className="irpd-item__text-content">
                    <div className="pivot-item__title">{repeaterName}</div>
                    <div className="pivot-item__angle" />
                    <div className="irpd-item__last-update">
                      {moment().format("DD MMM HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="irpd-item__row-right">
              <div className="irpd-item__sweep-status"></div>
            </div>
          </div>
        </Slide>
      </MobileZone>

      <DesktopZone>
        <div className="irpd-item">
          <div className="pivot-item__row-left">
            <div className="irpd-item__container">
              <div className="irpd-item__content">
                <div className="irpd-item__text-content">
                  <div className="pivot-item__title">{repeaterName}</div>

                  <div className="pivot-item__angle" />

                  <div className="irpd-item__last-update">
                    {moment().format("DD MMM HH:mm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="irpd-item__row-right">
            <div className="irpd-item__sweep-status"></div>
          </div>
        </div>
      </DesktopZone>
    </>
  );
}

export default RepeaterItem;

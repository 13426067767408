import React, { useEffect, useMemo, useState } from "react";
import { i18n } from "../../../../../../i18n/i18nText";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coreHTTPClient } from "../../../../../../services/webclient";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import { isMobile } from "../../../../../../mobileConfig";
import Chart from "react-apexcharts";
import styled from "@emotion/styled";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import GeneralChartDateSelect from "../../../../components/ChartDateSelect/GeneralChartDateSelect";
import ChartPagination from "../../../../components/ChartPagination/ChartPagination";
import { formatFloatValue } from "../../../../../../utils/models/format";

const Container = styled.div`
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
`;

const TotalWaterConsumption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 15.75px;
  font-weight: 500;
`;

type DataObject = { from: Date; to: Date; value: number };

interface Props {
  farmID: number;
  irpdID: number;
}

function IrpdWaterConsumptionChart(props: Props) {
  const { farmID, irpdID } = props;

  const [chartPage, setChartPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [chartDataLength, setChartDataLength] = useState("12");
  const [chartPrecision, setChartPrecision] = useState("1");
  const [tempStartDate, setTempStartDate] = useState<moment.Moment>(
    moment().subtract(1, "month")
  );
  const [tempEndDate, setTempEndDate] = useState<moment.Moment>(moment());
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(1, "month")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [chartData, setChartData] = useState<DataObject[]>();

  function handleDataLength(value) {
    setChartDataLength(value);
    setChartPage(0);
  }

  function handleChartPrecision(value) {
    setChartPrecision(value);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  }

  async function getWaterConsumptionData() {
    let startDateURL: string;
    let endDateURL: string;

    if (chartPrecision == "30") {
      startDateURL = startDate.format("YYYY-MM");
      endDateURL = endDate.format("YYYY-MM");
    } else {
      startDateURL = startDate.format("YYYY-M-D");
      endDateURL = endDate.format("YYYY-M-D");
    }

    try {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/irpds/${irpdID}/water-consumption/${chartPrecision}/?date_start=${startDateURL}&date_end=${endDateURL}`
      );
      if (response.data) {
        setChartData(response.data);
        setLastPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10))
        );
        setChartPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10)) - 1
        );
      } else {
        setLastPage(0);
        setChartPage(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function MyResponsiveBar(chartData: DataObject[]) {
    const sortedData = chartData
      .map((chartDatum) => ({
        labelDate:
          chartPrecision == "30"
            ? moment(chartDatum.from).format("MMM")
            : moment(chartDatum.from),
        valueColor: "#68c0f3",
        value: chartDatum.value ? chartDatum.value : 0,
        from: chartDatum.from,
        to: chartDatum.to,
      }))
      .sort((a, b) => {
        if (moment(a.from).isBefore(b.from)) {
          return -1;
        }
        if (moment(a.from).isAfter(b.from)) {
          return 1;
        }
        return 0;
      })
      .slice(
        parseInt(chartDataLength, 10) * chartPage,
        parseInt(chartDataLength, 10) * (chartPage + 1)
      );

    const state = {
      series: [
        {
          name: "m³",
          data: sortedData.map((sortedDatum) => sortedDatum?.value),
        },
      ],
      options: {
        chart: {
          events: {
            mounted: () => {
              window.scrollTo(0, 0);
            },
          },
        },
        colors: ["#68c0f3"],
        grid: {
          row: {
            colors: ["#fff", "#f7f2f2"],
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (value, opts) {
            return formatFloatValue(value);
          },
          style: {
            colors: ["rgba(51, 51, 51, 0.8)"],
            fontSize: "11px",
          },
        },
        xaxis: {
          categories: sortedData.map((sortedDatum) =>
            chartPrecision == "1" && sortedDatum.from != sortedDatum.to
              ? `${moment(sortedDatum.from).format("DD MMM")} - ${moment(
                  sortedDatum.to
                ).format("DD MMM")}`
              : chartPrecision == "7"
              ? `${moment(sortedDatum.labelDate).format("DD MMM")} 
            - ${moment(sortedDatum.labelDate).add(6, "day").format("DD MMM")}`
              : chartPrecision == "30"
              ? sortedDatum.labelDate
              : moment(sortedDatum.labelDate).format("DD MMM")
          ),
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return value.toFixed();
            },
          },
        },
        title: {
          text: `${i18n("BASIC_INFO_BOX_WATER_CONSUMPTION")} (m³)`,
          align: "center",
          offsetY: 20,
        },
        tooltip: {
          y: {
            formatter(value) {
              return formatFloatValue(value);
            },
          },
        },
      },
    };
    return (
      <Chart
        id="IrpdWaterConsumptionChart"
        // @ts-ignore
        options={state.options}
        series={state.series}
        type="bar"
        width={isMobile() ? "100%" : "180%"}
        height={isMobile() ? "350px" : "400px"}
      />
    );
  }

  const totalWaterConsumption = useMemo(() => {
    var totalWaterConsumption = 0;
    if (chartData?.length) {
      chartData.forEach((chartDatum) => {
        totalWaterConsumption = totalWaterConsumption + chartDatum.value;
      });
    }
    return totalWaterConsumption;
  }, [chartData]);

  useEffect(() => {
    getWaterConsumptionData();
  }, [chartPrecision, chartDataLength, startDate, endDate, irpdID]);

  return (
    <DashboardBox
      centerElement={<h2>{i18n("BASIC_INFO_BOX_CHART_WATER_CONSUMPTION")}</h2>}
      rightElement={
        <GeneralChartDateSelect
          chartPrecision={chartPrecision}
          tempStartDate={tempStartDate}
          tempEndDate={tempEndDate}
          setTempStartDate={setTempStartDate}
          setTempEndDate={setTempEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      }
    >
      <Container>
        <div className="basic-info__header-infos">
          {i18n("BASIC_INFO_BOX_CHART_GENERATED_BETWEEN", [
            moment(startDate).format("DD MMM YYYY"),
            moment(endDate).format("DD MMM YYYY"),
          ])}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: "15px 20px", width: "100%" }}>
            <FormSelect
              id="SelectPrecision"
              newLabel="SCALE"
              state={[chartPrecision, handleChartPrecision]}
              values={[
                ["1", "EDIT_FARM_DAY"],
                ["7", "EDIT_FARM_WEEK"],
                ["30", "EDIT_FARM_MONTH"],
              ]}
            />
          </div>

          <div style={{ margin: "15px 20px", width: "100%" }}>
            <FormSelect
              id="SelectPrecision"
              disabled={chartPrecision !== "1"}
              label="IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS"
              newLabel="IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS"
              state={[chartDataLength, handleDataLength]}
              values={[
                ["12", "NUMBER_OF_BARS_12"],
                ["24", "NUMBER_OF_BARS_24"],
                ["30", "NUMBER_OF_BARS_30"],
              ]}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {chartData ? (
              chartData.length > 0 ? (
                <>
                  {MyResponsiveBar(chartData)}

                  <TotalWaterConsumption>
                    {`${i18n(
                      "PERIOD_TOTAL_WATER_CONSUMPTION"
                    )}: ${formatFloatValue(totalWaterConsumption)} m³`}
                  </TotalWaterConsumption>
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: isMobile() ? "380px" : "430px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {i18n("METERSYSTEM_NO_READINGS")}
                </div>
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  height: isMobile() ? "380px" : "430px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
        </div>

        <ChartPagination
          lastPage={lastPage}
          chartPage={chartPage}
          setChartPage={setChartPage}
          chartDataLength={chartDataLength}
          chartData={chartData}
        />
      </Container>
    </DashboardBox>
  );
}

export default IrpdWaterConsumptionChart;

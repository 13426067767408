import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { i18n } from "../../i18n/i18nText";
import { isMobile } from "../../mobileConfig";
import { Farm } from "../../redux/farms/types";
import FormDatePicker from "../Form/FormDatePicker/FormDatePicker";
import {
  DrawerFarmTable,
  DrawerTableHeaders,
  PowerStyledButton,
} from "../PowerRange/PowerRange";

const HolidaysRoot = styled.div`
  width: 100%;
`;

const AddDateRow = styled.div`
  display: grid;
  grid-template-columns: 300px 300px;
  grid-gap: 20px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
`;

const ImportContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
`;

const Row = styled.div`
  width: "100%";
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HolidaysTable = styled(DrawerFarmTable)`
  grid-template-columns: 1fr 1fr 0.2fr;
  max-height: ${isMobile() ? "none" : "475px"};
  overflow-y: ${isMobile() ? "none" : "auto"};
`;

const ImportButton = styled(Button)`
  height: 41px;
  width: 300px;
  margin: 35px 0;
`;

const ImportText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
`;

interface HolidaysProps {
  holidays: Farm["holidays_list"];
  addHoliday: (holiday: { day: number; month: number }) => any;
  deleteHoliday: (holiday: { day: number; month: number }) => any;
  importHolidays: () => any;
}

const monthArray = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
];

export default function Holidays(props: HolidaysProps) {
  let [holidayDate, setHolidayDate] = useState(
    moment().set("date", 1).set("month", 0)
  );

  return (
    <HolidaysRoot>
      <AddDateRow>
        <Row>
          <FormDatePicker
            id="report-filter-end-date"
            label={null}
            state={[holidayDate, (date) => setHolidayDate(date)]}
          />
          <PowerStyledButton
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              // Fixed month beeing 1 minus like january = 0 now january = 1
              props.addHoliday({
                day: holidayDate.get("date"),
                month: holidayDate.get("month") + 1,
              });
            }}
            style={{ marginLeft: 20 }}
          >
            +
          </PowerStyledButton>
        </Row>
        <ImportContainer>
          <ImportButton
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              props.importHolidays();
            }}
          >
            <ImportText>
              {i18n("EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS")}
            </ImportText>
          </ImportButton>
        </ImportContainer>
      </AddDateRow>

      {/* Foi criada duas HollidaysTable para poder aplicar overflow apenas nas linhas e evitar o cabeçalho. */}

      <HolidaysTable>
        {/* Headers */}
        <>
          <DrawerTableHeaders>{i18n("EDIT_FARM_DAY")}</DrawerTableHeaders>
          <DrawerTableHeaders>{i18n("EDIT_FARM_MONTH")}</DrawerTableHeaders>
          <DrawerTableHeaders>
            <Delete style={{ opacity: 0 }}></Delete>
          </DrawerTableHeaders>
        </>
        <></>
      </HolidaysTable>
      <HolidaysTable>
        {/* Headers */}
        <></>
        {props.holidays.map((holiday) => (
          <>
            <div>{holiday.day}</div>
            <div>
              {moment()
                .set("month", holiday.month - 1)
                .format("MMMM")}
            </div>
            <div
              onClick={() => {
                props.deleteHoliday({
                  day: holiday.day,
                  month: holiday.month,
                });
              }}
            >
              <Delete style={{ color: "#e45d5f", cursor: "pointer" }}></Delete>
            </div>
          </>
        ))}
      </HolidaysTable>
    </HolidaysRoot>
  );
}

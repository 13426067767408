import React, { useEffect, Suspense } from "react";
import { DrawerProvider } from "../../pages/Dashboard/components/DrawerProvider/DrawerProvider";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import DashboardContainer from "../../pages/Dashboard/components/DashboardContainer/DashboardContainer";
import routes from "../routes";
import SelectedPivot from "../../pages/Dashboard/Pivot/SelectedPivot/SelectedPivot";
import SelectedBauerPivot from "../../pages/Dashboard/BauerPivot/SelectedPivot/SelectedBauerPivot";
import SelectedFarm from "../../pages/Dashboard/Farm/SelectedFarm/SelectedFarm";
import EditFarm from "../../pages/Dashboard/Farm/EditFarm/EditFarm";
import EditPivot from "../../pages/Dashboard/Pivot/EditPivot/EditPivot";
import EditBauerPivot from "../../pages/Dashboard/BauerPivot/EditPivot/EditPivot";
import Profile from "../../pages/Dashboard/Profile/Profile";
import SelectedIrpd from "../../pages/Dashboard/Irpd/SelectedIrpd/SelectedIrpd";
import EditIrpd from "../../pages/Dashboard/Irpd/EditIrpd/EditIrpd";
import Devices from "../../pages/Dashboard/Devices/Devices";
import NoAccess, { TYPE } from "../../pages/NoAccess/NoAccess";
import SelectedMeterSystem from "../../pages/Dashboard/MeterSystem/SelectedMeterSystem/SelectedMeterSystem";
import { EditImeter } from "../../pages/Dashboard/MeterSystem/SelectedMeterSystem/EditImeter/EditImeter";
import { acceptTermsStatus } from "../../recoils/AcceptedTermsRecoil";
import { changePasswordStatus } from "../../recoils/ChangePasswordRecoil";
import { useRecoilValue } from "recoil";
import PasswordRecovery from "../../pages/Auth/ForgetPassword/PasswordRecovery/PasswordRecovery";
import { coreHTTPClient } from "../../services/webclient";
import Notifications from "../../pages/Dashboard/Notifications/Notifications";
import EditRepeater from "../../pages/Dashboard/Repeater/EditRepeater/EditRepeater";
import BillingCancel from "../../pages/Dashboard/BillingCancel/BillingCancel";
import BillingSuccess from "../../pages/Dashboard/BillingSuccess/BillingSuccess";

function DashboardRouter() {
  let location = useLocation();
  let navigate = useNavigate();

  const acceptedTerms = useRecoilValue(acceptTermsStatus);
  const changePassword = useRecoilValue(changePasswordStatus);

  async function getTokenRedirectPassword() {
    const token = await coreHTTPClient.get("/v3/auth/recovery/");
    navigate(routes.AUTH.PASSWORD_RECOVERY.replace(":token", token.data.token));
  }

  useEffect(() => {
    if (acceptedTerms == false) {
      navigate(routes.DASHBOARD.PRIVACY_POLICY_NOT_ACCEPTED);
    } else {
      if (location.pathname == routes.AUTH.LOGIN) {
        navigate(routes.DASHBOARD.MAIN);
      }
      if (changePassword) {
        getTokenRedirectPassword();
      }
    }
  }, [location.pathname, acceptedTerms]);

  return (
    <DrawerProvider>
      <DashboardContainer>
        <Routes>
          <Route
            path={routes.DASHBOARD.SELECTED_FARM}
            element={
              <Suspense fallback={<div>...</div>}>
                <SelectedFarm />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.DEVICES}
            element={
              <Suspense fallback={<div>...</div>}>
                <Devices />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.EDIT_FARM}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditFarm />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.SELECTED_BAUER_PIVOT}
            element={
              <Suspense fallback={<div>...</div>}>
                <SelectedBauerPivot />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.EDIT_DISABLED_FARM}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditFarm />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.SELECTED_PIVOT}
            element={
              <Suspense fallback={<div>...</div>}>
                <SelectedPivot />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.SELECTED_IRPD}
            element={
              <Suspense fallback={<div>...</div>}>
                <SelectedIrpd />
              </Suspense>
            }
          />

          {/* <Route
            path={routes.DASHBOARD.RESELLER}
            element={
              <Suspense fallback={<div>...</div>}>
                <Reseller />
              </Suspense>
            }
          />
         <Route path={routes.DASHBOARD.FIRST_STEPS} element={<FirstSteps />} />
          <Route
            path={routes.DASHBOARD.FIRST_STEPS_ADM}
            element={<AdmProfile />}
          />
          <Route
            path={routes.DASHBOARD.FIRST_STEPS_EMPLOYEE}
            element={<EmployeeProfile />}
          /> */}

          {/* <Route
            path={routes.DASHBOARD.CREATE_FARM}
            element={
              <Suspense fallback={<div>...</div>}>
                <CreateFarm
                  reseller={reseller}
                  createFarm={(patchObject) => actions.create(patchObject)}
                  userID={userID}
                />
              </Suspense>
            }
          /> */}

          <Route
            path={routes.DASHBOARD.EDIT_BAUER_PIVOT}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditBauerPivot />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.EDIT_PIVOT}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditPivot />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.NOTIFICATIONS}
            element={
              <Suspense fallback={<div>...</div>}>
                <Notifications />
              </Suspense>
            }
          />
          <Route
            path={routes.DASHBOARD.PROFILE}
            element={
              <Suspense fallback={<div>...</div>}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={routes.DASHBOARD.EDIT_IRPD}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditIrpd />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.SELECTED_METER_SYSTEM}
            element={
              <Suspense fallback={<div>...</div>}>
                <SelectedMeterSystem />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.EDIT_IMETER}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditImeter />
              </Suspense>
            }
          />
          <Route
            path={routes.DASHBOARD.EDIT_REPEATER}
            element={
              <Suspense fallback={<div>...</div>}>
                <EditRepeater />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.USER_HAS_NO_FARM}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.PERMISSION} />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.NO_INTERNET_CONECTION}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.INTERNET} />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.PRIVACY_POLICY_NOT_ACCEPTED}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.POLICY} />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.FARM_DISABLED}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.DISABLED} />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.CODE404}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.CODE404} />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.SUCCESS}
            element={
              <Suspense fallback={<div>...</div>}>
                <BillingSuccess />
              </Suspense>
            }
          />

          <Route
            path={routes.DASHBOARD.CANCEL}
            element={
              <Suspense fallback={<div>...</div>}>
                <BillingCancel />
              </Suspense>
            }
          />

          <Route
            path={"/*"}
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess Type={TYPE.CODE404} />
              </Suspense>
            }
          />

          <Route
            element={
              <Suspense fallback={<div>...</div>}>
                <NoAccess />
              </Suspense>
            }
          />

          <Route
            path={routes.AUTH.PASSWORD_RECOVERY}
            element={<PasswordRecovery />}
          />
        </Routes>
      </DashboardContainer>
    </DrawerProvider>
  );
}

export default DashboardRouter;

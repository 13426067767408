import React, { Dispatch, SetStateAction } from "react";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { i18n, i18nTextId } from "../../../i18n/i18nText";

interface Props {
  label: string;
  id: string;
  state: [moment.Moment | null, Dispatch<SetStateAction<moment.Moment>>];
  disabled?: boolean;
  error?: string;
  ampm?: boolean;
}

function FormTimePicker(props: Props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TimePicker
        ampm={false}
        margin="normal"
        id={props.id}
        invalidDateMessage={
          props.error !== undefined ? (
            <>{i18n(props.error as i18nTextId)}</>
          ) : undefined
        } // mudar aqui
        error={props.error !== undefined}
        label={<>{i18n(props.label as i18nTextId)}</>}
        // mask="__:__ _M"
        disabled={props.disabled}
        value={props.state[0]}
        //keyboardIcon={<TimerOutlined />}
        onChange={(value) => {
          if (value !== null) {
            props.state[1](value);
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormTimePicker;

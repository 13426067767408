import { NativeSelect } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { i18n } from "../../../../i18n/i18nText";

interface Props {
  lastPage: number;
  chartPage: number;
  setChartPage: Dispatch<SetStateAction<number>>;
  chartDataLength: string;
  chartData: any;
}

function ChartPagination(props: Props) {
  const {
    lastPage,
    chartPage,
    setChartPage,
    chartDataLength,
    chartData: dataChart,
  } = props;

  let optionsSelect = useMemo(() => {
    let pages = [];
    for (let i = 0; i < lastPage; i++) pages.push(i);
    return pages;
  }, [lastPage]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ddd",
        paddingTop: "10px",
      }}
    >
      <span
        style={{
          fontSize: "16px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        {lastPage ? parseInt(chartDataLength, 10) * chartPage + 1 : 0}-
        {parseInt(chartDataLength, 10) * (chartPage + 1) < dataChart?.length
          ? parseInt(chartDataLength, 10) * (chartPage + 1)
          : dataChart?.length}{" "}
        {i18n("TABLE_OF")} {dataChart?.length.toString()}
      </span>

      {
        <NativeSelect
          style={{
            marginLeft: 8,
            paddingRight: 24,
            textAlign: "right",
            textAlignLast: "right",
          }}
          id="pluviometer-chart-page-selector"
          value={chartPage}
          onChange={(e) => {
            setChartPage(parseInt(e.target.value));
          }}
        >
          {optionsSelect.length ? (
            optionsSelect.map((value) => {
              return (
                <option style={{ fontSize: "16px" }} value={value} key={value}>
                  {value + 1}
                </option>
              );
            })
          ) : (
            <option value={1} key={1}>
              {1}
            </option>
          )}
        </NativeSelect>
      }

      <div
        style={{
          cursor: chartPage == 0 ? "default" : "pointer",
          color: chartPage == 0 ? "#bdbdbd" : "#7e7e7e",
          paddingLeft: 8,
          paddingRight: 24,
        }}
        onClick={() => {
          if (chartPage > 0) {
            setChartPage(chartPage - 1);
          }
        }}
      >
        <ChevronLeft />
      </div>

      <div
        style={{
          cursor: chartPage < lastPage - 1 ? "pointer" : "default",
          color: chartPage < lastPage - 1 ? "#7e7e7e" : "#bdbdbd",
          paddingRight: 8,
        }}
        onClick={() => {
          if (chartPage < lastPage - 1) {
            setChartPage(chartPage + 1);
          }
        }}
      >
        <ChevronRight />
      </div>
    </div>
  );
}
export default ChartPagination;

import { Capacitor } from "@capacitor/core";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import DesktopZone from "../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../components/Zones/MobileZone";
import { i18n } from "../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../mobileConfig";
import Store from "../../../../../../redux/index";

const DarkBackground = styled(motion.div)`
  position: fixed;
  z-index: 9998;

  overflow: hidden;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const AnnouncementModalMobile = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;

  margin-bottom: -5vh;

  width: 100vw;
  height: 80vh;
  z-index: 99998;

  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const DesktopDescription = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const AnnouncementModalDesktop = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 600px;
  background-color: #fff;
`;

const AnnouncementContainer = styled.div`
  max-height: calc(80vh - 90px);
  width: 100%;
  display: flex;

  padding: 0 40px 25px 40px;

  font-size: 15px;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    height: 100%;
  }
`;

const MobileTopBar = styled.div`
  margin: 10px 10px;
  width: 50px;
  height: 4px;
  background-color: #e0dfe4;
  border-radius: 2px;
`;

const IconContainer = styled.span`
  vertical-align: middle;
  margin-left: -20px;
  margin-right: 15px;
`;

const AnnouncementDescriptionMobile = styled.p`
  overflow-y: auto;
  margin: 0;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  @media (max-width: 350px) {
    max-height: 250px;
  }
`;

const BACKDROP_VARIANTS = {
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
};

const ANNOUNCEMENT_MODAL_VARIANTS_MOBILE = {
  hidden: {
    y: "80vh",
  },
  visible: {
    y: 0,
  },
};

const ANNOUNCEMENT_MODAL_VARIANTS_DESKTOP = {
  hidden: {
    display: "none",
    y: "-200%",
    x: "-50%",
  },
  visible: {
    display: "block",
    y: "-50%",
    x: "-50%",
  },
};

interface Props {
  notification: any;
  acceptedTerms: boolean;
  policyInfos: any;
}

export default function Announcements(props: Props) {
  const { notification, acceptedTerms, policyInfos } = props;
  const { language } = Store().store.getState().setting;
  const lastAnnouncementUUID = localStorage.getItem("lastAnnouncementUUID");

  interface Announcement {
    uuid: string;
    id: number;
    description: string;
    priority: string;
    button_text: string;
    link: string;
  }
  const [announcement, setAnnouncement] = useState<Announcement>();
  const [animation, setAnimation] = useState("hidden");

  useEffect(() => {
    if (notification) {
      const { uuid, id, priority, link } = notification;
      const { description, button_text } = notification[
        `text_${language.split("-")[0]}`
      ];
      setAnnouncement({
        uuid,
        id,
        description,
        priority,
        button_text,
        link,
      });
      setAnimation("visible");
    }
  }, [notification]);

  const PRIORITY_MAP = {
    default: { icon: <CheckCircleIcon />, color: "#44b86a" },
    medium: { icon: <WarningIcon />, color: "rgb(255, 215, 0)" },
    high: { icon: <ErrorIcon />, color: "rgb(233, 36, 0)" },
  };

  useEffect(() => {
    if (animation === "hidden") {
      document.body.style.overflow = "initial";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [animation]);

  const handleOkClick = async () => {
    if (announcement?.link) {
      window.open(announcement.link);
    }
    localStorage.setItem("lastAnnouncementUUID", String(announcement.uuid));
    setAnimation("hidden");
  };

  useEffect(() => {
    document.getElementById("announcement").innerHTML =
      announcement?.description;
  }, [announcement?.description, isMobile()]);

  return lastAnnouncementUUID !== notification?.uuid ? (
    <>
      <MobileZone>
        <AnnouncementModalMobile
          onMouseEnter={(ev) => {
            if (!Capacitor.isNativePlatform()) {
              document.body.style.overflow = "hidden";
            }
          }}
          initial="hidden"
          animate={animation}
          variants={ANNOUNCEMENT_MODAL_VARIANTS_MOBILE}
        >
          <AnnouncementContainer>
            <MobileTopBar />
            <h2
              style={{
                display: "inline-block",
                color: PRIORITY_MAP[announcement?.priority]?.color,
              }}
            >
              <IconContainer>
                {PRIORITY_MAP[announcement?.priority]?.icon}
              </IconContainer>
              {i18n("ANNOUNCEMENT_SYSTEM_TITLE")}
            </h2>
            <AnnouncementDescriptionMobile id="announcement" />
            <Button
              style={{
                width: "90%",
                position: "fixed",
                bottom: 60,
                backgroundColor: PRIORITY_MAP[announcement?.priority]?.color,
              }}
              variant="contained"
              color="secondary"
              onClick={handleOkClick}
            >
              {announcement?.button_text != ""
                ? announcement?.button_text
                : i18n("FORGET_PASSWORD_OK")}
            </Button>
          </AnnouncementContainer>
        </AnnouncementModalMobile>
        <DarkBackground
          onScroll={(e) => {
            e.preventDefault();
          }}
          initial="hidden"
          animate={animation}
          variants={BACKDROP_VARIANTS}
        />
      </MobileZone>

      <DesktopZone>
        <AnnouncementModalDesktop
          initial="hidden"
          animate={animation}
          variants={ANNOUNCEMENT_MODAL_VARIANTS_DESKTOP}
        >
          <AnnouncementContainer>
            <h1
              style={{
                paddingTop: 10,
                display: "inline-block",
                color: PRIORITY_MAP[announcement?.priority]?.color,
                textAlign: "center",
              }}
            >
              <IconContainer>
                {PRIORITY_MAP[announcement?.priority]?.icon}
              </IconContainer>
              {i18n("ANNOUNCEMENT_SYSTEM_TITLE")}
            </h1>
            <DesktopDescription id="announcement" />
            <Button
              style={{
                width: "90%",
                backgroundColor: PRIORITY_MAP[announcement?.priority]?.color,
              }}
              variant="contained"
              color="secondary"
              onClick={handleOkClick}
            >
              {announcement?.button_text != ""
                ? announcement?.button_text
                : i18n("FORGET_PASSWORD_OK")}
            </Button>
          </AnnouncementContainer>
        </AnnouncementModalDesktop>
        <DarkBackground
          onScroll={(e) => {
            e.preventDefault();
          }}
          initial="hidden"
          animate={animation}
          variants={BACKDROP_VARIANTS}
        />
      </DesktopZone>
    </>
  ) : null;
}

import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import PowerRangeSlider from "./PowerRangeSlider";
import moment from "moment";
import FormTimePicker from "../Form/FormTimePicker/FormTimePicker";
import FormSelect from "../Form/FormSelect/FormSelect";
import { Button, Checkbox, IconButton } from "@material-ui/core";
import { DayIndex, IPowerRange, GroupedRange } from "../../redux/farms/types";
import { EditSharp, Delete, MoreVert } from "@material-ui/icons";
import { nanoid } from "nanoid";
import { isMobile } from "../../mobileConfig";
import DropdownPopover from "../DropdownPopover/DropdownPopover";
import AlertDialog from "../AlertDialog/AlertDialog";
import { i18n } from "../../i18n/i18nText";

const PowerRangeBody = styled.div`
  width: 100%;

  box-sizing: border-box;

  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  max-width: 100vw;

  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 5px;
`;

/** Days */
const DaysRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  position: relative;
  width: 100%;

  /* height: ${isMobile() ? "80px" : "auto"}; */

  flex-wrap: wrap;
`;

interface LabelProps {
  color?: string;
  editable?: boolean;
  disabled?: boolean;
}

const Label = styled.div`
  height: 32px;
  margin-right: 5px;

  background: ${(props: LabelProps) =>
    props.color || "rgba(196, 196, 196, 0.25)"};
  border-radius: 5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;

  line-height: 21px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  padding: ${(props: LabelProps) =>
    !props.editable ? "3px 30px" : "3px 10px 3px 0px"};

  opacity: ${(props: LabelProps) => (!props.disabled ? 1 : 0.4)};
  @media only screen and (max-width: 600px) {
    font-size: 10px;
    padding: 3px 15px;
  }

  line-height: 1;
`;

/** Table */
export const DrawerFarmTable = styled.div`
  display: grid;

  grid-template-columns: 1fr 0.5fr 1fr 0.2fr;
  grid-template-rows: auto;

  & > * {
    height: 55px;

    padding: 10px;
    font-size: 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    width: 100%;
    text-align: center;
    vertical-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DrawerTableHeaders = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: bold;
`;

const SpacedRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
`;
/**
 *
 * a chave do objeto é o dia da semana
 * 0 segunda
 * 1 terça
 * 2 quarta
 * 3 quinta
 * 4 sexta
 * 5 sab
 * 6 dom
 * o tipo é
 * 0 - ponta
 * 1 - fora de ponta
 * 2 - reduzido
 *
 * Assume-se que os power ranges para esse componente sempre venham ordenados
 */

export const PowerStyledButton = styled(Button)`
  margin: 10px 0px;
  line-height: 20px;
  font-size: 32px;
`;

const SaveButton = styled(Button)`
  margin: 10px 0px;
  line-height: 20px;
  background-color: #3266ff;
  color: white;
`;

const StyledCheckbox = styled(Checkbox)`
  color: black;

  & > span {
    color: black;
  }
`;

const DropdownButton = styled(Button)`
  color: ${(props: { buttonColor?: string }) =>
    props.buttonColor || "#000000aa"};
`;

interface Props {
  days: DayIndex[];
  ranges: IPowerRange[];
  editable?: boolean;
  alreadyCheckedDays?: DayIndex[];
  onEditClicked?: () => any;
  onDeleteClicked?: () => any;
  savePowerRange?: (groupedRange: GroupedRange) => any;
}

interface EditableDay {
  checked: boolean;
  disabled: boolean;
  day: DayIndex;
}

export default function PowerRange(props: Props) {
  //ranges cached and used for the edit form
  let [localRanges, setLocalRanges] = useState(props.ranges);

  //Dias que eu posso editar
  let [editableDays, setEditableDays] = useState<EditableDay[]>(() =>
    ([0, 1, 2, 3, 4, 5, 6] as DayIndex[]).map((day) => {
      //Dia já checado
      if (props.days.includes(day)) {
        return {
          checked: true,
          disabled: false,
          day,
        };
      } else if (props.alreadyCheckedDays?.includes(day)) {
        return {
          checked: false,
          disabled: true,
          day,
        };
      } else {
        return {
          checked: false,
          disabled: false,
          day,
        };
      }
    })
  );

  let lastLocalRange = localRanges[localRanges.length - 1];
  let [startTime, setStartTime] = useState(
    lastLocalRange
      ? moment(lastLocalRange.end, "HH:mm:ss")
      : moment("00:00:00", "HH:mm:ss")
  );
  let [endTime, setEndTime] = useState<moment.Moment>();

  useEffect(() => {
    //Criei ou deletei uma entrada, atualiza os valores dos inputs
    let newLastLocalRange = localRanges[localRanges.length - 1];

    if (newLastLocalRange) {
      setStartTime(moment(newLastLocalRange.end, "HH:mm:ss"));
      setEndTime(moment("23:59:00", "HH:mm:ss"));
    } else {
      //Tenho 0 ranges
      setStartTime(moment("00:00:00", "HH:mm:ss"));
      setEndTime(moment("23:59:00", "HH:mm:ss"));
    }
  }, [localRanges.length]);

  let [rangeType, setRangeType] = useState<0 | 1 | 2>(0);

  function savePowerRange() {
    if (!props.savePowerRange) return;

    let groupedRange: GroupedRange = {
      days: editableDays.filter((d) => d.checked).map((d) => d.day),
      range: localRanges,
      uuid: nanoid(),
    };

    props.savePowerRange(groupedRange);
  }

  const DropDownEditButton = useMemo(
    () => (
      <IconButton size="medium">
        <MoreVert style={{ color: "black" }} />
      </IconButton>
    ),
    []
  );

  let [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const daysMap = {
    0: i18n("EDIT_PIVOT_V5_WEEKDAY_MONDAY"),
    1: i18n("EDIT_PIVOT_V5_WEEKDAY_TUESDAY"),
    2: i18n("EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY"),
    3: i18n("EDIT_PIVOT_V5_WEEKDAY_THURSDAY"),
    4: i18n("EDIT_PIVOT_V5_WEEKDAY_FRIDAY"),
    5: i18n("EDIT_PIVOT_V5_WEEKDAY_SATURDAY"),
    6: i18n("EDIT_PIVOT_V5_WEEKDAY_SUNDAY"),
  };

  const powerMap = {
    0: { label: i18n("POWER_RANGE_POINT"), color: "#FF9D9E" },
    1: { label: i18n("POWER_RANGE_OUT_OF_POINT"), color: "#D9E7F5" },
    2: { label: i18n("POWER_RANGE_REDUCED"), color: "#95D2B5" },
  };
  return (
    <PowerRangeBody>
      <DaysRow>
        <div>
          {!props.editable
            ? props.days.map((day) => <Label>{daysMap[day]}</Label>)
            : editableDays.map((day) => (
                <>
                  <Label editable disabled={day.disabled}>
                    <StyledCheckbox
                      disabled={day.disabled}
                      checked={day.checked}
                      onChange={() => {
                        //toggle
                        setEditableDays((d) =>
                          d.map((editableDay) =>
                            editableDay == day
                              ? { ...day, checked: !day.checked }
                              : editableDay
                          )
                        );
                      }}
                      color="primary"
                    />
                    {daysMap[day.day]}
                  </Label>
                </>
              ))}
        </div>

        {!props.editable &&
          (isMobile() ? (
            <>
              <DropdownPopover
                divStyle={{ position: "absolute", top: -8, right: -4 }}
                button={DropDownEditButton}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <DropdownButton
                    color="primary"
                    size="medium"
                    onClick={props.onEditClicked}
                    startIcon={<EditSharp />}
                  >
                    {i18n("SELECTED_FARM_EDIT_BUTTON_TEXT")}
                  </DropdownButton>

                  <DropdownButton
                    color="primary"
                    size="medium"
                    buttonColor="#ff0000aa"
                    onClick={() => {
                      setDeleteDialogOpen(true);
                    }}
                    startIcon={<Delete />}
                  >
                    {i18n("SELECTED_FARM_DELETE_BUTTON_TEXT")}
                  </DropdownButton>
                </div>
              </DropdownPopover>
            </>
          ) : (
            <div>
              <Button
                style={{ color: "rgba(0,0,0, 0.8)" }}
                size="small"
                onClick={props.onEditClicked}
                startIcon={<EditSharp />}
              >
                {i18n("SELECTED_FARM_EDIT_BUTTON_TEXT")}
              </Button>
              <Button
                style={{ color: "red" }}
                size="small"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
                startIcon={<Delete />}
              >
                {i18n("SELECTED_FARM_DELETE_BUTTON_TEXT")}
              </Button>
            </div>
          ))}
      </DaysRow>

      <AlertDialog
        open={isDeleteDialogOpen}
        yesAction={() => {
          props.onDeleteClicked();
          setDeleteDialogOpen(false);
        }}
        noAction={() => {
          setDeleteDialogOpen(false);
        }}
        text={"POWER_RANGE_DELETE_CONFIRMATION"}
      />

      {props.editable && (
        <SpacedRow>
          <FormTimePicker
            id="pivot-config-hour-range-min"
            disabled
            label={"EDIT_FARM_START_TIME"}
            state={[startTime, () => {}]}
          />

          <FormTimePicker
            id="pivot-config-hour-range-min"
            disabled={lastLocalRange?.end == "23:59:00"}
            label={"EDIT_FARM_END_TIME"}
            state={[
              endTime,
              (time: moment.Moment) => {
                if (time.diff(startTime, "minutes") <= 1) {
                  return;
                } else {
                  setEndTime(moment(startTime).add(1, "minute"));
                }

                setEndTime(time);
              },
            ]}
          />

          <FormSelect
            id="pivot-config-endgun"
            label={"POWER_RANGE_TYPE"}
            state={[
              rangeType.toString(),
              (type: "0" | "1" | "2") =>
                setRangeType(parseInt(type) as 0 | 1 | 2),
            ]}
            values={[
              ["0", "POWER_RANGE_POINT"],
              ["1", "POWER_RANGE_OUT_OF_POINT"],
              ["2", "POWER_RANGE_REDUCED"],
            ]}
          />

          <PowerStyledButton
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              setLocalRanges((ranges) => [
                ...ranges,
                {
                  start: startTime.format("HH:mm:ss"),
                  end: endTime.format("HH:mm:ss"),
                  type: rangeType,
                },
              ]);
            }}
          >
            +
          </PowerStyledButton>
        </SpacedRow>
      )}

      <PowerRangeSlider ranges={localRanges} />

      <DrawerFarmTable>
        {/* Headers */}
        <>
          <DrawerTableHeaders>{i18n("EDIT_FARM_INTERVAL")}</DrawerTableHeaders>
          <DrawerTableHeaders>{i18n("EDIT_FARM_DURATION")}</DrawerTableHeaders>
          <DrawerTableHeaders>{i18n("EDIT_FARM_TYPE")}</DrawerTableHeaders>
          <DrawerTableHeaders></DrawerTableHeaders>
        </>

        {localRanges.map((range, index) => {
          let startDate = moment(range.start, "HH:mm:ss");
          let endDate = moment(range.end, "HH:mm:ss");
          let hoursDiff = endDate.diff(startDate, "hours").toString();
          let minDiff = (endDate.diff(startDate, "minutes") % 60).toString();
          return (
            <>
              <div>{`${startDate.format("HH:mm")} - ${endDate.format(
                "HH:mm"
              )}`}</div>
              <div>{`${hoursDiff.padStart(2, "0")}:${minDiff.padStart(
                2,
                "0"
              )}`}</div>
              <div>
                <Label color={powerMap[range.type].color}>
                  {powerMap[range.type].label}
                </Label>
              </div>

              <div
                onClick={() => {
                  if (!props.editable) return;

                  //Remove o ultimmo item
                  setLocalRanges((localRanges) => {
                    localRanges.pop();
                    return [...localRanges];
                  });
                }}
              >
                {props.editable && index == localRanges.length - 1 && (
                  <Delete
                    style={{ color: "#e45d5f", cursor: "pointer" }}
                  ></Delete>
                )}
              </div>
            </>
          );
        })}
      </DrawerFarmTable>

      {props.editable && (
        <SaveButton
          variant="contained"
          onClick={savePowerRange}
          disabled={
            lastLocalRange?.end != "23:59:00" || //Tudo preenchido
            !editableDays.some((e) => e.checked) //Todos os dias preenchidos
          }
        >
          {i18n("EDIT_FARM_BUTTON_SAVE")}
        </SaveButton>
      )}
    </PowerRangeBody>
  );
}

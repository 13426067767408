import { suffixClasses } from "../../../../../utils/classes";
import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import HistoricConfigV5Imeter from "./HistoricConfigV5Imeter";
import { useParams } from "react-router";
import { HistoryOutlined } from "@material-ui/icons";
import StarIcon from "@material-ui/icons/Star";
import { isMobile } from "../../../../../mobileConfig";
import { i18n } from "../../../../../i18n/i18nText";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

type Props = {
  iMeter: any;
  loadHistoricInfo: (
    metersystemName: string,
    imeterName: string,
    imeterSensor: any,
    imeterA: any,
    imeterB: any,
    imeterC: any,
    imeterLat: string,
    imeterLng: string,
    imeterMarker: any,
    imeterSensorMaxValue: any,
    imeterSensorOffset: any,
    imeterMinLimit: any,
    imeterMaxLimit: any
  ) => void;
  sensorOptions: any[];
};

const DrawerConfigImeter = (props: Props) => {
  const [value, setValue] = useState(0);
  const [drawerVisibleRight, setDrawerVisibleRight] = useState(false);
  const toggleDrawerRight = () => setDrawerVisibleRight(!drawerVisibleRight);
  const openDrawerRight = () => setDrawerVisibleRight(true);
  const closeDrawerRight = () => setDrawerVisibleRight(false);
  const [selectedTab, setSelectedTab] = useState("tab-1");
  const farmID: number = parseInt(useParams().farm, 10);
  const { iMeter, loadHistoricInfo, sensorOptions } = props;
  const iMeterID: number = props.iMeter.id;
  const meterSystemID = useParams().metersystem;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="drawerRight">
        <div
          className={suffixClasses("drawerRight__backdrop", [
            ["--inactive", !drawerVisibleRight],
          ])}
          onClick={toggleDrawerRight}
        />
        <div
          className={suffixClasses("drawerRight__bar", [
            ["--inactive", !drawerVisibleRight],
          ])}
        >
          <>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  width: "4px",
                },
              }}
              aria-label="Edit Farm"
            >
              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-1" ? "#293745" : "initial",
                }}
                icon={
                  <HistoryOutlined
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(0)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-1") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-1");
                    openDrawerRight();
                  }
                }}
              />

              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-2" ? "#293745" : "initial",
                }}
                icon={
                  <StarIcon
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(1)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-2") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-2");
                    openDrawerRight();
                  }
                }}
              />
            </Tabs>

            {drawerVisibleRight ? (
              <div
                style={{
                  backgroundColor: "white",
                  maxHeight: "100vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <TabPanel value={value} index={0}>
                  <h3 style={{ marginTop: "-10px", color: "#555" }}>
                    {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                  </h3>

                  <HistoricConfigV5Imeter
                    farmID={farmID}
                    iMeter={iMeter}
                    iMeterID={iMeterID}
                    meterSystemID={Number(meterSystemID)}
                    pinned={false}
                    loadHistoricInfo={loadHistoricInfo}
                    sensorOptions={sensorOptions}
                  />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <h3 style={{ marginTop: "-10px", color: "#555" }}>
                    {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                  </h3>

                  <HistoricConfigV5Imeter
                    farmID={farmID}
                    iMeter={iMeter}
                    iMeterID={iMeterID}
                    meterSystemID={Number(meterSystemID)}
                    pinned={true}
                    loadHistoricInfo={loadHistoricInfo}
                    sensorOptions={sensorOptions}
                  />
                </TabPanel>
              </div>
            ) : (
              <div></div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default DrawerConfigImeter;

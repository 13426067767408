import "../../../../../../style/components/DrawerRight.scss";
import { suffixClasses } from "../../../../../../utils/classes";
import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {
  PIVOT_COMMUNICATION_TYPE,
  Pivot,
} from "../../../../../../redux/pivots/types";
import { isV5Protocol } from "../../../../../../utils/models/pivots";
import HistoricConfigV5 from "./components/Historic/HistoricConfigV5";
import HistoricConfigOld from "./components/Historic/HistoricConfigOld";
import { useParams } from "react-router";
import { HistoryOutlined, EditLocationOutlined } from "@material-ui/icons";
import StarIcon from "@material-ui/icons/Star";
import { isMobile } from "../../../../../../mobileConfig";
import { i18n } from "../../../../../../i18n/i18nText";
import HistoricConfigEncoder from "./components/Historic/HistoricConfigEncoder";

interface Props {
  pivot: Pivot;
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DrawerRightEditPivot = (props: Props) => {
  const { pivot } = props;
  const [value, setValue] = useState(0);
  const [drawerVisibleRight, setDrawerVisibleRight] = useState(false);
  const toggleDrawerRight = () => setDrawerVisibleRight(!drawerVisibleRight);
  const openDrawerRight = () => setDrawerVisibleRight(true);
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  const closeDrawerRight = () => setDrawerVisibleRight(false);
  const [selectedTab, setSelectedTab] = useState("tab-1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="drawerRight">
        <div
          className={suffixClasses("drawerRight__backdrop", [
            ["--inactive", !drawerVisibleRight],
          ])}
          onClick={toggleDrawerRight}
        />
        <div
          className={suffixClasses("drawerRight__bar", [
            ["--inactive", !drawerVisibleRight],
          ])}
        >
          <>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  width: "4px",
                },
              }}
              aria-label="Edit Farm"
            >
              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-1" ? "#293745" : "initial",
                }}
                icon={
                  <HistoryOutlined
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(0)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-1") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-1");
                    openDrawerRight();
                  }
                }}
              />

              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-2" ? "#293745" : "initial",
                }}
                icon={
                  <StarIcon
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(1)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-2") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-2");
                    openDrawerRight();
                  }
                }}
              />
              {pivot.communication_type ===
                PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
                <Tab
                  style={{
                    minWidth: 0,
                    borderBottom: "1px solid rgba(255,255,255, 0.1)",
                    backgroundColor:
                      selectedTab === "tab-3" ? "#293745" : "initial",
                  }}
                  icon={
                    <EditLocationOutlined
                      style={{
                        color: "white",
                        fontSize: 20,
                      }}
                    />
                  }
                  {...a11yProps(2)}
                  onClick={() => {
                    if (drawerVisibleRight && selectedTab === "tab-3") {
                      setSelectedTab("");
                      closeDrawerRight();
                    } else {
                      setSelectedTab("tab-3");
                      openDrawerRight();
                    }
                  }}
                />
              )}
            </Tabs>

            {drawerVisibleRight ? (
              isV5Protocol(pivot) ? (
                <div
                  style={{
                    backgroundColor: "white",
                    maxHeight: "100vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <TabPanel value={value} index={0}>
                    <h3 style={{ marginTop: "-10px", color: "#555" }}>
                      {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                    </h3>

                    <HistoricConfigV5
                      pivot={pivot}
                      farmID={farmID}
                      pivotID={pivotID}
                      pinned={false}
                    />
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <h3 style={{ marginTop: "-10px", color: "#555" }}>
                      {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                    </h3>
                    <HistoricConfigV5
                      pivot={pivot}
                      farmID={farmID}
                      pivotID={pivotID}
                      pinned={true}
                    />
                  </TabPanel>
                  {pivot.communication_type ===
                    PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
                    <TabPanel value={value} index={2}>
                      <h3 style={{ marginTop: "-10px", color: "#555" }}>
                        {i18n("EDIT_ENCODER_LABEL_SETTING_HISTORIC")}
                      </h3>
                      <HistoricConfigEncoder
                        pivot={pivot}
                        farmID={farmID}
                        pivotID={pivotID}
                      />
                    </TabPanel>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                    maxHeight: "100vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <TabPanel value={value} index={0}>
                    <h3 style={{ marginTop: "-10px", color: "#555" }}>
                      {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                    </h3>

                    <HistoricConfigOld
                      pivot={pivot}
                      farmID={farmID}
                      pivotID={pivotID}
                      pinned={false}
                    />

                    <TabPanel value={value} index={1}>
                      <h3 style={{ marginTop: "-10px", color: "#555" }}>
                        {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                      </h3>
                      <HistoricConfigOld
                        pivot={pivot}
                        farmID={farmID}
                        pivotID={pivotID}
                        pinned={true}
                      />
                    </TabPanel>
                  </TabPanel>
                </div>
              )
            ) : (
              <div></div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default DrawerRightEditPivot;

import { UserAdministrator, UserProfile } from "../sharedTypes";

export enum FarmsTypes {
  SET_FARMS = "@@farms/SET_FARMS",
  UPDATE_OR_INSERT_FARM = "@@farms/UPDATE_OR_INSERT_FARM",
}

export type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface IPowerRange {
  type: 0 | 1 | 2;
  start: string;
  end: string;
}

export interface GroupedRange {
  uuid: string;
  days: DayIndex[];
  range: IPowerRange[];
}

export interface Farm {
  id: number;
  name: string;
  country: string;
  base?: { id: number; radio_id: string };
  state: string;
  city: string;
  address: string;
  postal_code: string;
  phone: string;
  location: string | null;
  billing_date: number;
  water_billing_date: number;
  img?: string;
  inicio_array: string[];
  fim_array: string[];
  tipo_horario_array: number[];
  maintencer?: any;
  farm_code: string;
  administrator: UserProfile;
  administrators: UserAdministrator[];
  temporary_administrator?: UserProfile;
  read_users: UserProfile[];
  write_users: UserProfile[];
  sms_users: number[];
  repeaters: number[];
  timezone?: string;
  is_administrator?: boolean;
  temp_admin_up_to?: Date;
  power_ranges?:
    | {
        "0"?: IPowerRange[];
        "1"?: IPowerRange[];
        "2"?: IPowerRange[];
        "3"?: IPowerRange[];
        "4"?: IPowerRange[];
        "5"?: IPowerRange[];
        "6"?: IPowerRange[];
        none?: false;
      }
    | { none: true };
  holidays_list?: { day: number; month: number }[];
  start_pivot_report_aggregate?: number;
  start_irpd_report_aggregate?: number;
}

export interface NewFarm {
  name: string;
  base?: string | null;
  country: string;
  state: string;
  city: string;
  address: string;
  postal_code: string;
  phone: string;
  location: string | null;
  billing_date: number;
  water_billing_date: number;
  img?: string;
  inicio_array?: string[];
  fim_array?: string[];
  tipo_horario_array?: number[];
  maintencer?: any;
  administrators?: any[];
  read_users?: UserProfile[];
  write_users?: UserProfile[];
  sms_users?: number[];
  repeaters?: number[];
  timezone?: string;
  is_administrator?: boolean;
  temporary_administrator?: UserProfile;
  temp_admin_up_to?: Date;
  power_ranges?:
    | {
        "0"?: IPowerRange[];
        "1"?: IPowerRange[];
        "2"?: IPowerRange[];
        "3"?: IPowerRange[];
        "4"?: IPowerRange[];
        "5"?: IPowerRange[];
        "6"?: IPowerRange[];
        none?: false;
      }
    | { none: true };
  holidays_list?: { day: number; month: number }[];
}

export interface FarmsState {
  list: Farm[];
}

import React, { useEffect, useState } from "react";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import "./SelectedBauerPivot.scss";
import GeneralBox from "./components/PivotGeneralBox/PivotGeneralBox";
import HistoricBox from "./components/HistoricBox/HistoricBox";
import EditIcon from "@material-ui/icons/Edit";
import { useParams, useNavigate } from "react-router";
import { coreHTTPClient } from "../../../../services/webclient";
import { EmptyPivot } from "../../../../redux/pivots/types";
import Routes from "../../../../routes/routes";
import { ReactJSX } from "../../../../utils/types";
import { Button, IconButton, Card, Typography } from "@material-ui/core";
import { EditSharp, ArrowBack, Refresh } from "@material-ui/icons";
import DashboardBoxTitle from "../../components/DashboardBox/DashboardBoxTitle";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import { isMobile, isAndroid } from "../../../../mobileConfig";
import FilterByDate from "../../../../components/FilterByDate/FilterByDate";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../redux/filterByDate/types";
import FarmMap from "../../Farm/SelectedFarm/components/FarmMap/FarmMap";
import styled from "@emotion/styled";
import {
  getLastFarmId,
  setLastFarmId,
} from "../../Farm/SelectedFarm/SelectedFarm";
import { useAnimation, motion } from "framer-motion";
import { i18n } from "../../../../i18n/i18nText";
import { FarmBauerPivotObj } from "../../../../redux/bauerPivot/types";
import QuickSelect from "../../../../components/QuickSelect/QuickSelect";
import { ApplicationState } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { setFilterByDate } from "../../../../redux/filterByDate/actions";
import moment from "moment";

export const PivotContext = React.createContext({ pivot: EmptyPivot() });

export const FloatingFadedNavBar = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;

  height: 55px;

  //iOs only
  @supports (-webkit-touch-callout: none) {
    height: 115px;
  }

  width: 100%;

  box-shadow: inset 0px 75px 25px -48px rgba(0, 0, 0, 0.75);

  color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 15px;
  z-index: 5;
`;

export const MiddleLayoutNavBar = styled.div`
  box-sizing: border-box;
  height: 55px;
  width: 100%;
  background-color: #44b86a;

  padding-right: 20px;
  padding-left: 5px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  display: grid;
  grid-template-columns: 50px calc(100% - 150px) 50px 50px;

  h2 {
    color: white;
    font-size: 20px;
  }
`;

// Requerido para fazer o padding da navbar no iOS
export const MobileContainer = styled.div`
  height: 260px;
  margin-top: -70px; //nega o espaço global da navbar
  //iOs only
  @supports (-webkit-touch-callout: none) {
    margin-top: -120px;
  }

  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 50px;
`;

export interface PivotParams {
  farm: string;
  pivot: string;
}

function SelectedBauerPivot() {
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);

  const dispatch = useDispatch();

  let filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const navigate = useNavigate();
  const floatingFadedNavBarAnimation = useAnimation();

  const [bauerPivot, setBauerPivot] = useState<FarmBauerPivotObj>();

  const [toggleFilterByDate, setToggleFilterByDate] = useState(false);

  useEffect(() => {
    (async () => {
      const bauerPivot = await coreHTTPClient.get(
        `v4/smartrain/pivot/${pivotID}`
      );
      if (bauerPivot.data) {
        setBauerPivot(bauerPivot.data);
      }
    })();
  }, [pivotID]);

  useEffect(() => {
    if (getLastFarmId() != farmID) {
      setLastFarmId(farmID);
    }
  }, [farmID]);

  useEffect(() => {
    if (filterByDate.data.end_date.isBefore(moment())) {
      const startDate = moment().subtract(1, "month");
      const endDate = moment();
      dispatch(
        setFilterByDate({
          start_date: startDate,
          end_date: endDate,
        })
      );
    }
  }, []);

  const buttonBack: ReactJSX = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const editButtonHandler = (): void => {
    navigate(
      Routes.DASHBOARD.EDIT_BAUER_PIVOT.replace(
        ":farm",
        String(farmID)
      ).replace(":pivot", String(pivotID))
    );
  };

  const rightElement: ReactJSX = (
    <>
      <Button color="primary" size="small" onClick={editButtonHandler}>
        <EditSharp />
      </Button>
    </>
  );

  const rightElements: ReactJSX = (
    <div className="flex-right-container">
      <div>
        <IconButton
          title={i18n("SHOW_CENTRAL_STATUS")}
          aria-label="delete"
          onClick={() => setToggleFilterByDate(!toggleFilterByDate)}
          size="small"
        >
          <FilterByDate
            title={"PIVOT_CONFIG_REPORT_PREFERENCES"}
            type={FilterTypesChoices.REPORT}
          />
        </IconButton>
      </div>
    </div>
  );

  if (bauerPivot) {
    return (
      <div
        className={
          isMobile() ? "selected-pivot-bauer-mobile" : "selected-pivot-bauer"
        }
      >
        {isMobile() && (
          <FloatingFadedNavBar animate={floatingFadedNavBarAnimation}>
            <ArrowBack
              style={{ marginTop: 15 }}
              onClick={() =>
                navigate(
                  Routes.DASHBOARD.SELECTED_FARM.replace(
                    ":farm",
                    String(farmID)
                  )
                )
              }
            />
            <EditIcon
              style={{ fontSize: "1.25em", marginTop: 15 }}
              onClick={() =>
                navigate(
                  Routes.DASHBOARD.EDIT_BAUER_PIVOT.replace(
                    ":farm",
                    String(farmID)
                  ).replace(":pivot", String(pivotID))
                )
              }
            />
          </FloatingFadedNavBar>
        )}
        <div className="selected-pivot-bauer__grid">
          <div className="selected-pivot-bauer__column">
            {isMobile() && (
              <MobileContainer>
                <FarmMap
                  draggable={false}
                  disableUI
                  bauerPivots={[bauerPivot]}
                  disableFullScreen
                  clickOnMapToToggleFullscreen
                  zoomScroll={!!isAndroid()}
                />

                <MiddleLayoutNavBar>
                  <div
                    style={{
                      display: "flex",
                      width: "calc(100vw - 45px)",
                      alignItems: "center",
                      gap: "30px",
                      marginLeft: "10px",
                    }}
                  >
                    <QuickSelect farmID={farmID} />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    >
                      <Refresh
                        style={{
                          color: "white",
                        }}
                        onClick={() => window.location.reload()}
                      />
                    </div>
                  </div>
                </MiddleLayoutNavBar>
              </MobileContainer>
            )}

            {isMobile() ? (
              <DashboardBox singleElement={<GeneralBox />}>
                <></>
              </DashboardBox>
            ) : (
              <DashboardBox
                leftElement={buttonBack}
                centerElement={
                  <DashboardBoxTitle
                    title={"SELECTED_PIVOT_GENERAL"}
                    pivotName={bauerPivot.name}
                  />
                }
                rightElement={rightElement}
              >
                <GeneralBox />
              </DashboardBox>
            )}

            <DashboardBox
              fixedHeight
              centerElement={
                <DashboardBoxTitle title={"SELECTED_PIVOT_HISTORIC"} />
              }
              rightElement={rightElements}
            >
              <HistoricBox filterByDate={filterByDate} />
            </DashboardBox>

            {isMobile() ? <div style={{ marginBottom: 40 }}></div> : null}
          </div>
          <div className="selected-pivot-bauer__column">
            {!isMobile() ? (
              <DashboardBox
                fixedHeight
                centerElement={
                  <DashboardBoxTitle title={"SELECTED_PIVOT_BASIC_INFO"} />
                }
                rightElement={null}
                leftElement={null}
              >
                <Card className="bauer-reports-construction-card">
                  <Typography className="bauer-reports-construction-text">
                    {i18n("UNDER_CONSTRUCTION")}
                  </Typography>
                </Card>
              </DashboardBox>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  {
    return <CustomCircularProgress />;
  }
}

export default React.memo(SelectedBauerPivot);

import "./MenuDrawer.scss";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonChecked,
  RadioButtonUnchecked,
  ExitToApp,
  Translate,
  Edit,
  Add,
} from "@material-ui/icons";

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Routes from "../../../../routes/routes";
import { suffixClasses } from "../../../../utils/classes";
import { DRAWER_ITEMS } from "./utils/consts";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../components/Zones/MobileZone";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useStyles } from "./MenuDrawer.style";
import Store, { ApplicationState } from "../../../../redux/index";
import { useSelector, useDispatch } from "react-redux";
import { Capacitor } from "@capacitor/core";
import { FCM } from "@capacitor-community/fcm";
import { showModal } from "../../../../redux/modal/actions";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { clearToken } from "../../../../redux/auth/actions";
import { isMobile } from "../../../../mobileConfig";
import { clearUserID } from "../../../../redux/userID/actions";
import {
  deleteToken,
  getMyTopics,
  getWebFCMToken,
  subscribeTokenToTopic,
  unsubscribeTokenFromTopic,
} from "../../../../utils/FCMService";

import { getDeviceNotch } from "../../../../utils/DeviceNotchCheck";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import { useRecoilState, useRecoilValue } from "recoil";
import { DrawerRecoil, useDrawer } from "../../../../recoils/DrawerRecoil";
import { Box, Input } from "@material-ui/core";
import { baseURL, coreHTTPClient } from "../../../../services/webclient";
import { i18n, i18nTextId } from "../../../../i18n/i18nText";
import { SettingFistName } from "../../../../recoils/SettingsRecoil";
import { setLanguage } from "../../../../redux/setting/actions";
import { Language } from "../../../../redux/setting/types";
import icWhiteLogo from "../../../../assets/images/logos/logo-white.svg";
import icIsoWhiteLogo from "../../../../assets/images/logos/ic_isologobranco.svg";
import { PushNotifications } from "@capacitor/push-notifications";
import CreateFarmSteps from "../../Farm/CreateFarm/CreateFarm/CreateFarmSteps";
import useFarm from "../../../../hooks/models/useFarm";

const { FCM_SUFFIX } = process.env;

const MOBILE_MAX_LENGTH = 25;
const DESKTOP_MAX_LENGTH = 20;
export interface StatusBarBackgroundColorOptions {
  color: string;
}

const languages = ["pt-br", "en", "ru", "es", "de-at"];

export function getProperLanguageID(language) {
  if (language == "pt-br") {
    return Language.PORTUGUESE_BRAZIL;
  } else if (language == "de-at") {
    return Language.GERMAN_AUSTRIA;
  } else return language as Language;
}
const Drawer = (props) => {
  // Drawer state.
  const [items, setItems] = useState(DRAWER_ITEMS);
  const [active, setActive] = useState(""); // Active Menu
  const [activeSubMenu, setActiveSubMenu] = useState(""); // Active Menu
  const [submenuShown, setSubmenuShown] = useState("");
  const [selectedFarmId, setSelectedFarmId] = useState<number>(-1);
  const [selectedItem, setSelectedItem] = useState("");
  const [searchInput, setSearchInput] = useState<string>("");
  const dispatch = useDispatch();
  const language = useSelector(
    (state: ApplicationState) => state.setting.language
  );
  const [profileName, setProfileName] = useRecoilState(SettingFistName);

  // Farms.
  const farms = Store().store.getState().farms.list;

  const reseller = useSelector(
    (state: ApplicationState) => state.userID.reseller
  );

  const userID = useSelector((state: ApplicationState) => state.userID.userID);

  const [asyncStatus, _, actions] = useFarm(-1);

  const navigate = useNavigate();

  const location = useLocation();

  const isDrawerOpen = useRecoilValue(DrawerRecoil);

  const drawer = useDrawer();

  /*
    ALGORITMO DE REDIRECIONAMENTO PARA A PÁGINA DO PIVÔ QUANDO A NOTIFICAÇÃO É CLICADA:

    É feito obtendo o ID da fazenda, ID do pivô e a flag caso seja realmente, necessário
    o redirecionamento para a página do pivô na qual o usuário clicou na notificação.

    O plugin nativo de notificação ao disparar uma notificação salva no storage nativo do SO
    dados do ID da fazenda, ID do pivô responsável pela notificação e também uma flag que indica
    se essa notificação já foi aberta redirecionando para a página do pivô responsável pela noti-
    ficação caso necessário.
  */

  const registerAndSubscribeAll = async (language: string) => {
    let topicsToSubscribe = [];
    let topicsToUnsubscribe = [];

    if (userID === -1 || !userID) return;

    const notificationsSufix = ["pivot", "pivot-monitor", "irpd"];
    var nAlertTopics = languages.flatMap((language) => {
      return notificationsSufix.map((sufix) => {
        return `${FCM_SUFFIX}n-${sufix}-user-${userID}-${language}`;
      });
    });

    if (Capacitor.getPlatform() == "web") {
      try {
        let token = await getWebFCMToken();

        if (token === "" || token === undefined) return;

        let mySubscribedTopics = [];

        nAlertTopics.forEach((alertTopic) => {
          //If i'm not already subscribed to this topic, subscribe to it
          if (!mySubscribedTopics.includes(alertTopic)) {
            topicsToSubscribe.push(alertTopic);
          }
        });

        mySubscribedTopics.forEach((subscribedTopic) => {
          //If i don't need to subscribe to this topics anymore, unsubscribe from it
          if (!nAlertTopics.includes(subscribedTopic)) {
            topicsToUnsubscribe.push(subscribedTopic);
          }
        });

        //Do the un/subscription
        topicsToSubscribe.forEach((topicToSubscribe) =>
          subscribeTokenToTopic(topicToSubscribe)
        );
        topicsToUnsubscribe.forEach((topicToUnsubscribe) =>
          unsubscribeTokenFromTopic(topicToUnsubscribe)
        );
      } catch (err) {}
    } else {
      (async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }

        PushNotifications.register().then(async () => {
          let fcmToken = await FCM.getToken();
          let token = fcmToken.token;
          let mySubscribedTopics = await getMyTopics(token);

          let topicsToSubscribe = [];
          let topicsToUnsubscribe = [];

          PushNotifications.createChannel({
            id: "highimportancechannel",
            description: "High priority notifications",
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true,
            sound: "classicalarm",
            name: "Critical Channel",
          })
            .then((success) => console.log("channel", success))
            .catch((err) => console.log("channel err", err));

          PushNotifications.createChannel({
            id: "mediumimportancechannel",
            description: "Normal priority notifications",
            importance: 3,
            visibility: 1,
            lights: true,
            vibration: true,
            sound: "default",
            name: "Normal Channel",
          })
            .then((success) => console.log("channel", success))
            .catch((err) => console.log("channel err", err));

          PushNotifications.createChannel({
            id: "lowimportancechannel",
            description: "Low priority notifications",
            importance: 1,
            visibility: 1,
            lights: true,
            vibration: true,
            sound: "default",
            name: "Low Channel",
          })
            .then((success) => console.log("channel", success))
            .catch((err) => console.log("channel err", err));

          PushNotifications.createChannel({
            id: "adimportancechannel",
            description: "Marketing and advertising notifications",
            importance: 3,
            visibility: 1,
            lights: true,
            vibration: true,
            sound: "default",
            name: "Marketing Channel",
          })
            .then((success) => console.log("channel", success))
            .catch((err) => console.log("channel err", err));

          nAlertTopics.forEach((alertTopic) => {
            //If i'm not already subscribed to this topic, subscribe to it
            if (!mySubscribedTopics.includes(alertTopic)) {
              topicsToSubscribe.push(alertTopic);
            }
          });

          mySubscribedTopics.forEach((subscribedTopic) => {
            //If i don't need to subscribe to this topics anymore, unsubscribe from it
            if (!nAlertTopics.includes(subscribedTopic)) {
              topicsToUnsubscribe.push(subscribedTopic);
            }
          });

          //Do the un/subscription
          topicsToSubscribe.forEach((topicToSubscribe) =>
            FCM.subscribeTo({ topic: topicToSubscribe })
              .then((r) => console.log(r))
              .catch((err) => console.log(err))
          );

          topicsToUnsubscribe.forEach((topicToUnsubscribe) =>
            FCM.unsubscribeFrom({ topic: topicToUnsubscribe })
              .then((r) => console.log(r))
              .catch((err) => console.log(err))
          );
        });
      })();
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => console.log(notification)
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        function (notification) {
          let { url } = notification.notification.data;
          if (window.location.href == `${baseURL}${url}`) return;
          navigate(url);
        }
      );
    }
  };

  useEffect(() => {
    let localStorageFirstName = localStorage.getItem("firstName");

    if (!language)
      coreHTTPClient.get(`/v3/auth/profile/language/`).then((response) => {
        if (response?.data !== Store().store.getState()?.setting?.language)
          dispatch(setLanguage(getProperLanguageID(response?.data)));
      });

    if (!profileName || !localStorageFirstName)
      coreHTTPClient.get(`/v3/auth/user/first_name/`).then((response) => {
        setProfileName(response?.data);
      });

    if (localStorageFirstName != profileName)
      setProfileName(localStorageFirstName);
  }, []);

  useEffect(() => {
    if (language) {
      registerAndSubscribeAll(language);
    }
  }, [language, userID]);

  /* ********************************************* */

  useEffect(() => {
    const { pathname } = location;
    let idFarmURL = pathname.split("/");
    if (pathname.includes("/farms/")) {
      setActive("farms");
      setActiveSubMenu(idFarmURL[idFarmURL.length - 1]);
      setSelectedFarmId(parseInt(idFarmURL[2]));
      setSelectedItem("");
    }
    if (pathname.includes("/me")) {
      setActive("home");
      setSelectedItem("profile");
    }
    if (pathname.includes("/devices")) {
      setActive("devices");
      setSelectedItem("devices");
      if (idFarmURL[2]) setSelectedFarmId(parseInt(idFarmURL[2]));
      else setSelectedFarmId(farms[0].id);
      drawer.closeDrawer();
    }
    if (pathname.includes("/notifications")) {
      setActive("notifications");
      setSelectedItem("notifications");
      if (idFarmURL[2]) setSelectedFarmId(parseInt(idFarmURL[2]));
      else setSelectedFarmId(farms[0].id);
      drawer.closeDrawer();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSubmenuShown("");
      setSearchInput("");
    }
    // eslint-disable-next-line
  }, [isDrawerOpen]);

  function replaceSpecialChars(str) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
    str = str.replace(/[àáâãäå]/, "a");
    str = str.replace(/[ã]/, "a");
    str = str.replace(/[ÈÉÊË]/, "E");
    str = str.replace(/[èéê]/, "e");
    str = str.replace(/[Ç]/, "C");
    str = str.replace(/[ç]/, "c");
    str = str.replace(/[ÍÌÎĨ]/, "I");
    str = str.replace(/[íìĩî]/, "i");
    str = str.replace(/[ÓÒÕÔ]/, "O");
    str = str.replace(/[óòõô]/, "o");
    str = str.replace(/[ÚÙŨÛ]/, "U");
    str = str.replace(/[úùũû]/, "u");
    return str.replace(/[^a-z0-9]/gi, "");
  }

  useEffect(() => {
    var ITEMS = DRAWER_ITEMS;
    setItems(
      ITEMS.map((item) => {
        if (item.id === "farms") {
          item.submenus = farms
            .sort((fA, fB) => {
              if (fA.name > fB.name) return 1;
              if (fA.name < fB.name) return -1;
              return 0;
            })
            .map((farm) => ({
              id: `farm-${farm.id}`,
              label: farm.name,
              to: Routes.DASHBOARD.SELECTED_FARM.replace(
                ":farm",
                String(farm.id)
              ),
              farmId: farm.id,
            }));
        }
        if (item.id === "devices") {
          item.to = Routes.DASHBOARD.DEVICES.replace(
            ":farm",
            selectedFarmId != -1
              ? String(selectedFarmId)
              : farms[0]
              ? farms[0].id.toString()
              : "-1"
          );
        }
        if (item.id === "notifications") {
          item.to = Routes.DASHBOARD.NOTIFICATIONS.replace(
            ":farm",
            selectedFarmId != -1
              ? String(selectedFarmId)
              : farms[0]
              ? farms[0].id.toString()
              : "-1"
          );
        }
        return item;
      })
    );
    // eslint-disable-next-line
  }, [farms, isMobile()]);

  const handleClickItem = useCallback(
    (clicked) => {
      if (!isDrawerOpen) drawer.openDrawer();

      if (clicked.submenus.length > 0) {
        // has submenu.
        if (submenuShown === clicked.id) {
          setSubmenuShown("");
        } else {
          setSubmenuShown(clicked.id);
        }
      } else {
        // redirect to a new page.
        if (isDrawerOpen) {
          setActive(clicked.id);
          navigate(clicked.to);
          drawer.closeDrawer();
        }
      }
    },
    // eslint-disable-next-line
    [submenuShown, isDrawerOpen]
  );

  const handleClickSubmenuItem = useCallback(
    (submenu) => {
      navigate(submenu.to);
      drawer.closeDrawer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDrawerOpen]
  );

  //MOBILE DRAWER:

  const classes = useStyles(props);
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={`drawer__items drawer__items__notch-${getDeviceNotch()}`}>
        {process.env.IS_DEV ? (
          <div className="dev-container">
            <DeveloperModeIcon fontSize="small" className="dev-icon" />{" "}
            Desenvolvimento
          </div>
        ) : null}
        {items.map((item) => {
          return (
            <div key={item.id}>
              <div
                className={
                  item.id === "home"
                    ? suffixClasses("drawer__itemp", [
                        ["--selected", active === item.id],
                        ["--submenu-active", submenuShown === item.id],
                      ])
                    : suffixClasses("drawer__item", [
                        ["--selected", active === item.id],
                        ["--submenu-active", submenuShown === item.id],
                      ])
                }
                onClick={() => handleClickItem(item)}
              >
                {React.createElement(item.icon)}
                <div
                  className={suffixClasses("drawer__item__label", [
                    ["--hidden", !isDrawerOpen],
                  ])}
                >
                  {item.id === "home" ? (
                    profileName ? (
                      profileName
                    ) : (
                      <>{i18n(item.label as i18nTextId)}</>
                    )
                  ) : (
                    <>{i18n(item.label as i18nTextId)}</>
                  )}
                  {item.submenus.length > 0 ? (
                    submenuShown === item.id ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )
                  ) : null}
                </div>
              </div>
              <div
                className={suffixClasses("drawer__submenu", [
                  [
                    "--visible",
                    item.submenus.length > 0 && submenuShown === item.id,
                  ],
                ])}
              >
                <div
                  style={{
                    position: "relative",
                    width: "87%",
                    height: "100%",
                    margin: "5px 5px 5px 15px",
                  }}
                >
                  {item.id === "farms" && (
                    <Input
                      autoComplete="off"
                      id="farm-search-input"
                      // error={validationErrors["name"]}
                      placeholder={i18n("FARM_FIELD_SEARCH_BAR")}
                      value={searchInput}
                      onChange={(value) => setSearchInput(value.target.value)}
                      style={{
                        color: "white",
                        borderBottomStyle: "solid",
                        borderWidth: 0.5,
                        borderColor: "white",
                      }}
                      disableUnderline
                    />
                  )}
                </div>
                <div
                  className={suffixClasses("drawer__submenu", [
                    [
                      "--visible--scroll",
                      item.submenus.length > 0 && submenuShown === item.id,
                    ],
                  ])}
                >
                  {searchInput === ""
                    ? item.submenus.map((submenu) => {
                        if (item.id === "farms") {
                          const isThisFarmSelected =
                            selectedFarmId === submenu.farmId;

                          return (
                            <div
                              key={submenu.id}
                              className={suffixClasses(
                                "drawer__submenu__item",
                                [["--selected", activeSubMenu === submenu.id]]
                              )}
                              onClick={() => {
                                handleClickSubmenuItem(submenu);
                                toggleDrawer("left", false);
                              }}
                            >
                              {isThisFarmSelected ? (
                                <RadioButtonChecked />
                              ) : (
                                <RadioButtonUnchecked />
                              )}
                              <div
                                className={suffixClasses(
                                  "drawer__submenu__label",
                                  [
                                    ["--hidden", !isDrawerOpen],
                                    ["--selected", isThisFarmSelected],
                                  ]
                                )}
                              >
                                <span>{` ${
                                  submenu.label?.length >
                                  (isMobile()
                                    ? MOBILE_MAX_LENGTH
                                    : DESKTOP_MAX_LENGTH)
                                    ? submenu.label.substring(
                                        0,
                                        isMobile()
                                          ? MOBILE_MAX_LENGTH
                                          : DESKTOP_MAX_LENGTH
                                      ) + "..."
                                    : submenu.label
                                }`}</span>
                              </div>
                            </div>
                          );
                        }
                      })
                    : item.submenus
                        .filter((submenu) => {
                          return replaceSpecialChars(submenu.label)
                            .toLowerCase()
                            .includes(
                              replaceSpecialChars(searchInput.toLowerCase())
                            );
                        })
                        .map((submenu) => {
                          if (item.id === "farms") {
                            const isThisFarmSelected =
                              selectedFarmId === submenu.farmId;

                            return (
                              <div
                                key={submenu.id}
                                className={suffixClasses(
                                  "drawer__submenu__item",
                                  [["--selected", activeSubMenu === submenu.id]]
                                )}
                                onClick={() => {
                                  handleClickSubmenuItem(submenu);
                                  toggleDrawer("left", false);
                                }}
                              >
                                {isThisFarmSelected ? (
                                  <RadioButtonChecked />
                                ) : (
                                  <RadioButtonUnchecked />
                                )}
                                <div
                                  className={suffixClasses(
                                    "drawer__submenu__label",
                                    [
                                      ["--hidden", !isDrawerOpen],
                                      ["--selected", isThisFarmSelected],
                                    ]
                                  )}
                                >
                                  <span>{` ${
                                    submenu.label?.length >
                                    (isMobile()
                                      ? MOBILE_MAX_LENGTH
                                      : DESKTOP_MAX_LENGTH)
                                      ? submenu.label.substring(
                                          0,
                                          isMobile()
                                            ? MOBILE_MAX_LENGTH
                                            : DESKTOP_MAX_LENGTH
                                        ) + "..."
                                      : submenu.label
                                  }`}</span>
                                </div>
                              </div>
                            );
                          }
                        })}
                  {item.submenus.map((submenu) => {
                    if (
                      submenu.id === "language" ||
                      submenu.id === "logout" ||
                      submenu.id === "profile"
                    ) {
                      return (
                        <div
                          key={submenu.id}
                          className={
                            submenu.id === "profile"
                              ? suffixClasses("drawer__submenu__item", [
                                  ["--selected", activeSubMenu === submenu.id],
                                ])
                              : "drawer__submenu__item"
                          }
                          onClick={() =>
                            submenu.id === "language"
                              ? dispatch(
                                  showModal({
                                    content: <LanguageSelector />,
                                    title: "MOBILE_MODAL_TITLE_LANGUAGES",
                                  })
                                )
                              : submenu.id === "logout"
                              ? (async () => {
                                  try {
                                    // Antes de deslogar desativamos as notificacoes tanto do firebase mobile quanto web
                                    if (userID) {
                                      deleteToken();
                                      const notificationsSufix = [
                                        "pivot",
                                        "pivot-monitor",
                                        "irpd",
                                      ];
                                      var nAlertTopics = languages.flatMap(
                                        (language) => {
                                          return notificationsSufix.map(
                                            (sufix) => {
                                              return `${FCM_SUFFIX}n-${sufix}-user-${userID}-${language}`;
                                            }
                                          );
                                        }
                                      );

                                      nAlertTopics.forEach(async (topic) => {
                                        if (Capacitor.getPlatform() !== "web") {
                                          FCM.unsubscribeFrom({
                                            topic,
                                          });
                                        }
                                      });
                                      if (Capacitor.getPlatform() !== "web")
                                        await PushNotifications.removeAllListeners();
                                    }
                                  } catch (err) {
                                    console.log("Logout error", err);
                                  } finally {
                                    dispatch(clearUserID());
                                    dispatch(clearToken());
                                  }
                                })()
                              : handleClickSubmenuItem(submenu)
                          }
                        >
                          {submenu.id === "language" ? (
                            <Translate />
                          ) : submenu.id === "logout" ? (
                            <ExitToApp />
                          ) : (
                            <Edit />
                          )}

                          <div
                            className={suffixClasses("drawer__submenu__label", [
                              ["--hidden", !isDrawerOpen],
                            ])}
                          >
                            <span>{i18n(submenu.label as i18nTextId)}</span>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                {item.id === "farms" && reseller == true && (
                  <div
                    key={"NewFarm"}
                    className={"drawer__submenu__item"}
                    style={{
                      borderTop: "1px solid #ffffff14",
                    }}
                    onClick={() => {
                      dispatch(
                        showModal({
                          content: (
                            <CreateFarmSteps
                              reseller={reseller}
                              createFarm={(patchObject) =>
                                actions.create(patchObject)
                              }
                              userID={userID}
                            />
                          ),
                          title: "NAV_ITEMS_NEW_FARM",
                        })
                      );
                      drawer.closeDrawer();
                    }}
                  >
                    <Add />
                    <div className={"drawer__submenu__label"}>
                      <>{i18n("NAV_ITEMS_NEW_FARM")}</>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <DesktopZone>
        <div className="drawer">
          <div
            className={suffixClasses("drawer__backdrop", [
              ["--inactive", !isDrawerOpen],
            ])}
            onClick={() => drawer.toggleDrawer()}
          />
          <div
            className={suffixClasses("drawer__bar", [
              ["--inactive", !isDrawerOpen],
            ])}
          >
            <div className="drawer__items">
              <DesktopZone>
                <div
                  className="drawer__item"
                  onClick={() => drawer.toggleDrawer()}
                >
                  {isDrawerOpen ? (
                    // <KeyboardArrowLeft />
                    <Box
                      style={{
                        width: "100%",
                        backgroundColor: "#2f4050",
                        padding: "5px",
                      }}
                    >
                      <img src={icWhiteLogo} />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        width: "40px",
                        backgroundColor: "#2f4050",
                        padding: "2px",
                      }}
                    >
                      <img src={icIsoWhiteLogo} />
                    </Box>
                  )}
                </div>
              </DesktopZone>
              {process.env.IS_DEV ? (
                <div className="dev-container">
                  <DeveloperModeIcon fontSize="small" className="dev-icon" />{" "}
                  Desenvolvimento
                </div>
              ) : null}
              {items.map((item) => {
                return (
                  <div key={item.id}>
                    <div
                      className={
                        item.id === "home"
                          ? suffixClasses("drawer__itemp", [
                              ["--selected", active === item.id],
                              ["--submenu-active", submenuShown === item.id],
                            ])
                          : suffixClasses("drawer__item", [
                              ["--selected", active === item.id],
                              ["--submenu-active", submenuShown === item.id],
                            ])
                      }
                      onClick={() => handleClickItem(item)}
                    >
                      {React.createElement(item.icon)}
                      <div
                        className={suffixClasses("drawer__item__label", [
                          ["--hidden", !isDrawerOpen],
                        ])}
                      >
                        {item.id === "home" ? (
                          profileName ? (
                            profileName
                          ) : (
                            <>{i18n(item.label as i18nTextId)}</>
                          )
                        ) : (
                          <>{i18n(item.label as i18nTextId)}</>
                        )}

                        {item.submenus.length > 0 ? (
                          submenuShown === item.id ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={suffixClasses("drawer__submenu", [
                        [
                          "--visible",
                          item.submenus.length > 0 && submenuShown === item.id,
                        ],
                      ])}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "87%",
                          height: "100%",
                          margin: "5px 5px 5px 15px",
                        }}
                      >
                        {item.id === "farms" && (
                          <Input
                            autoComplete="off"
                            id="farm-search-input"
                            // error={validationErrors["name"]}
                            placeholder={i18n("FARM_FIELD_SEARCH_BAR")}
                            value={searchInput}
                            onChange={(value) =>
                              setSearchInput(value.target.value)
                            }
                            style={{
                              color: "white",
                              borderBottomStyle: "solid",
                              borderWidth: 0.5,
                              borderColor: "white",
                            }}
                            disableUnderline
                          />
                        )}
                      </div>
                      <div
                        className={suffixClasses("drawer__submenu", [
                          [
                            "--visible--scroll",
                            item.submenus.length > 0 &&
                              submenuShown === item.id,
                          ],
                        ])}
                      >
                        {searchInput === ""
                          ? item.submenus.map((submenu) => {
                              if (item.id === "farms") {
                                const isThisFarmSelected =
                                  selectedFarmId === submenu.farmId;
                                return (
                                  <div
                                    key={submenu.id}
                                    className={suffixClasses(
                                      "drawer__submenu__item",
                                      [
                                        [
                                          "--selected",
                                          activeSubMenu === submenu.id,
                                        ],
                                      ]
                                    )}
                                    onClick={() =>
                                      handleClickSubmenuItem(submenu)
                                    }
                                  >
                                    {isThisFarmSelected ? (
                                      <RadioButtonChecked />
                                    ) : (
                                      <RadioButtonUnchecked />
                                    )}
                                    <div
                                      className={suffixClasses(
                                        "drawer__submenu__label",
                                        [
                                          ["--hidden", !isDrawerOpen],
                                          ["--selected", isThisFarmSelected],
                                        ]
                                      )}
                                    >
                                      <span>{` ${
                                        submenu.label?.length >
                                        (isMobile()
                                          ? MOBILE_MAX_LENGTH
                                          : DESKTOP_MAX_LENGTH)
                                          ? submenu.label.substring(
                                              0,
                                              isMobile()
                                                ? MOBILE_MAX_LENGTH
                                                : DESKTOP_MAX_LENGTH
                                            ) + "..."
                                          : submenu.label
                                      }`}</span>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          : item.submenus
                              .filter((submenu) => {
                                return replaceSpecialChars(submenu.label)
                                  .toLowerCase()
                                  .includes(
                                    replaceSpecialChars(
                                      searchInput.toLowerCase()
                                    )
                                  );
                              })
                              .map((submenu) => {
                                if (item.id === "farms") {
                                  const isThisFarmSelected =
                                    selectedFarmId === submenu.farmId;
                                  return (
                                    <div
                                      key={submenu.id}
                                      className={suffixClasses(
                                        "drawer__submenu__item",
                                        [
                                          [
                                            "--selected",
                                            activeSubMenu === submenu.id,
                                          ],
                                        ]
                                      )}
                                      onClick={() =>
                                        handleClickSubmenuItem(submenu)
                                      }
                                    >
                                      {isThisFarmSelected ? (
                                        <RadioButtonChecked />
                                      ) : (
                                        <RadioButtonUnchecked />
                                      )}
                                      <div
                                        className={suffixClasses(
                                          "drawer__submenu__label",
                                          [
                                            ["--hidden", !isDrawerOpen],
                                            ["--selected", isThisFarmSelected],
                                          ]
                                        )}
                                      >
                                        <span>{` ${
                                          submenu.label?.length >
                                          (isMobile()
                                            ? MOBILE_MAX_LENGTH
                                            : DESKTOP_MAX_LENGTH)
                                            ? submenu.label.substring(
                                                0,
                                                isMobile()
                                                  ? MOBILE_MAX_LENGTH
                                                  : DESKTOP_MAX_LENGTH
                                              ) + "..."
                                            : submenu.label
                                        }`}</span>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                        {item.submenus.map((submenu) => {
                          if (
                            submenu.id === "language" ||
                            submenu.id === "logout" ||
                            submenu.id === "profile"
                          ) {
                            return (
                              <div
                                key={submenu.id}
                                className={
                                  submenu.id === "profile"
                                    ? suffixClasses("drawer__submenu__item", [
                                        [
                                          "--selected",
                                          activeSubMenu === submenu.id,
                                        ],
                                      ])
                                    : "drawer__submenu__item"
                                }
                                onClick={() =>
                                  submenu.id === "language"
                                    ? dispatch(
                                        showModal({
                                          content: <LanguageSelector />,
                                          title: "MOBILE_MODAL_TITLE_LANGUAGES",
                                        })
                                      )
                                    : submenu.id === "logout"
                                    ? (async () => {
                                        // Antes de deslogar desativamos as notificacoes tanto do firebase mobile quanto web
                                        try {
                                          if (userID) {
                                            deleteToken();
                                            const notificationsSufix = [
                                              "pivot",
                                              "pivot-monitor",
                                              "irpd",
                                            ];
                                            var nAlertTopics = languages.flatMap(
                                              (language) => {
                                                return notificationsSufix.map(
                                                  (sufix) => {
                                                    return `${FCM_SUFFIX}n-${sufix}-user-${userID}-${language}`;
                                                  }
                                                );
                                              }
                                            );

                                            nAlertTopics.forEach(
                                              async (topic) => {
                                                if (
                                                  Capacitor.getPlatform() !==
                                                  "web"
                                                ) {
                                                  FCM.unsubscribeFrom({
                                                    topic,
                                                  });
                                                }
                                              }
                                            );
                                            if (
                                              Capacitor.getPlatform() !== "web"
                                            )
                                              await PushNotifications.removeAllListeners();
                                          }
                                        } catch (err) {
                                          console.log("Logout error", err);
                                        } finally {
                                          dispatch(clearUserID());
                                          dispatch(clearToken());
                                        }
                                      })()
                                    : handleClickSubmenuItem(submenu)
                                }
                              >
                                {submenu.id === "language" ? (
                                  <Translate />
                                ) : submenu.id === "logout" ? (
                                  <ExitToApp />
                                ) : (
                                  <Edit />
                                )}

                                <div
                                  className={suffixClasses(
                                    "drawer__submenu__label",
                                    [["--hidden", !isDrawerOpen]]
                                  )}
                                >
                                  <span>
                                    {i18n(submenu.label as i18nTextId)}
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                      {item.id === "farms" && reseller == true && (
                        <div
                          key={"NewFarm"}
                          className={"drawer__submenu__item"}
                          style={{
                            borderTop: "1px solid #ffffff14",
                            borderBottom: "1px solid #ffffff14",
                          }}
                          onClick={() => {
                            dispatch(
                              showModal({
                                content: (
                                  <CreateFarmSteps
                                    reseller={reseller}
                                    createFarm={(patchObject) =>
                                      actions.create(patchObject)
                                    }
                                    userID={userID}
                                  />
                                ),
                                title: "NAV_ITEMS_NEW_FARM",
                              })
                            );
                            drawer.closeDrawer();
                          }}
                        >
                          <Add />
                          <div className={"drawer__submenu__label"}>
                            {i18n("NAV_ITEMS_NEW_FARM")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </DesktopZone>
      <MobileZone>
        <SwipeableDrawer
          style={{ zIndex: 996 }}
          disableBackdropTransition={!iOS}
          // disableDiscovery={iOS}
          open={isDrawerOpen}
          disableSwipeToOpen={false}
          swipeAreaWidth={15}
          onClose={() => {
            drawer.closeDrawer();
          }}
          onOpen={() => {
            drawer.openDrawer();
          }}
        >
          {sideList("left")}
        </SwipeableDrawer>
      </MobileZone>
    </>
  );
};

export default Drawer;

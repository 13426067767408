import React from "react";
import { i18n } from "../../../../../../../../../../../i18n/i18nText";
import { IrpdStream } from "../../../../../../../../../../../redux/irpds/types";
import { parseHourmeter } from "../../../../../../../../../../../utils/models/pivots";
import { expandDateArgs } from "../../../utils";

interface Props {
  irpdStream: IrpdStream;
}

function ExpandedIrpdStream(props: Props) {
  const { irpdStream } = props;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_IRPD_ACTION_CREATED", [
            ...expandDateArgs(irpdStream.created),
          ])}
        </span>
        <span>
          {i18n("HISTORIC_IRPD_STREAM_HOURMETER")}
          <b>{i18n("VALUE", [parseHourmeter(irpdStream.hourmeter)])}</b>
        </span>
      </div>
    </div>
  );
}

export default ExpandedIrpdStream;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "@emotion/styled";
import { i18n, i18nTextId } from "../../i18n/i18nText";

interface Props {
  open: boolean;
  yesAction: any;
  noAction: any;
  onClose?: any;
  text: string;
}

const StyledButtonAccept = styled(Button)`
  background-color: #2196f3 !important;
  color: #fff !important;
  &:hover {
    background-color: #06467a !important;
  }
`;

const StyledButtonCancel = styled(Button)`
  background-color: #9b9b9b !important;
  color: #fff !important;
  &:hover {
    background-color: #6e6e6e !important;
  }
`;

function AlertDialog(props: Props) {
  return (
    <div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 0 },
        }}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <>{i18n("ALERT_DIALOG_CONFIRMATION")}</>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {i18n(props.text as i18nTextId)}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-evenly", paddingBottom: 22 }}
        >
          <StyledButtonCancel onClick={props.noAction}>
            <>{i18n("EDIT_FARM_BUTTON_CANCEL")}</>
          </StyledButtonCancel>
          <StyledButtonAccept onClick={props.yesAction}>
            <>{i18n("ALERT_DIALOG_YES")}</>
          </StyledButtonAccept>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;

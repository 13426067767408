import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { IPowerRange } from "../../redux/farms/types";
import { i18n } from "../../i18n/i18nText";

const PowerRangeBackground = require("../../assets/images/background/powerRangePatterns.gif");

interface PowerRangeWithPercentageLabel extends IPowerRange {
  percentage: number;
  label: string;
}

interface Props {
  ranges: IPowerRange[];
}

const PowerRangeBody = styled.div`
  width: 100%;
  height: 65px;
  background-image: url("${PowerRangeBackground}");

  display: flex;
  overflow-x: hidden;
`;

interface PowerRangeBarProps {
  percentage: number;
  type: 0 | 1 | 2;
}

const PowerRangeBar = styled.div`
  width: ${(props: PowerRangeBarProps) => props.percentage}%;
  height: 65px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props: PowerRangeBarProps) => {
    switch (props.type) {
      case 0:
        return "#FF9D9E";

      case 1:
        return "#D9E7F5";
      case 2:
        return "#95D2B5";

      default:
        return "transparent";
    }
  }};
`;

const PowerRangeLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  color: rgba(0, 0, 0, 0.7);

  @media only screen and (max-width: 600px) {
    color: transparent;
  }
`;

function convertRangeTimeToPercentageAndLabel(
  powerRange: IPowerRange
): PowerRangeWithPercentageLabel {
  let start = moment(powerRange.start, "HH:mm");
  let end = moment(powerRange.end, "HH:mm");
  let oneDay = 60 * 60 * 24;
  let absoluteTime = end.diff(start, "seconds");
  let percentage = (absoluteTime / oneDay) * 100;

  let label = "";
  switch (powerRange.type) {
    case 0:
      label = i18n("POWER_RANGE_PEAK_ACRONYM");
      break;
    case 1:
      label = i18n("POWER_RANGE_OFF_PEAK_ACRONYM");
      break;
    case 2:
      label = i18n("POWER_RANGE_REDUCED_ACRONYM");
      break;
  }

  return {
    ...powerRange,
    percentage,
    label,
  };
}

export default function PowerRangeSlider(props: Props) {
  let [rangesWithPercentage, setRangesWithPercentage] = useState<
    PowerRangeWithPercentageLabel[]
  >(() => props.ranges.map(convertRangeTimeToPercentageAndLabel));

  useEffect(() => {
    setRangesWithPercentage(
      props.ranges.map(convertRangeTimeToPercentageAndLabel)
    );
  }, [props.ranges]);

  return (
    <PowerRangeBody>
      {rangesWithPercentage.map((range) => (
        <PowerRangeBar
          key={range.start}
          percentage={range.percentage}
          type={range.type}
        >
          <PowerRangeLabel>{range.label}</PowerRangeLabel>
        </PowerRangeBar>
      ))}
    </PowerRangeBody>
  );
}

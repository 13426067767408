import { ResponsiveRadar } from "@nivo/radar";
import React from "react";
import { handleWaterBladeByAngle } from "../../../../../../Irpd/SelectedIrpd/components/IrpdHistoric/components/util/utils";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const theme = {
  legends: {
    title: {
      text: { fill: "#f00" },
    },
  },
  axis: {
    // position: "bottom",
    // tickValues: [0, 90, 180, 270],
    ticks: {
      tickValues: [0, 90, 180, 270],
      text: {
        fill: "rgba(255, 255, 255, 255)",
      },
    },
  },
};

interface Props {
  data: any[];
  referenceAngle: number;
}

const MyResponsiveRadar = (props: Props) => {
  let handledData = handleWaterBladeByAngle(props.data, props.referenceAngle);

  return (
    <ResponsiveRadar
      data={handledData}
      keys={["mm: "]}
      indexBy="Ângulo"
      tooltipFormat={(value) => formatFloatValue(value)}
      // @ts-ignore
      theme={theme}
      maxValue="auto"
      margin={{ top: 20, right: 25, bottom: 35, left: 25 }}
      curve="linearClosed"
      borderWidth={2}
      borderColor={{ from: "color" }}
      gridLevels={1}
      gridShape="circular"
      gridLabelOffset={12}
      enableDots={false}
      dotSize={0}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      dotBorderColor={{ from: "color" }}
      enableDotLabel={true}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={["#172783", "#882783"]}
      fillOpacity={0.18}
      blendMode="multiply"
      animate={true}
      motionStiffness={5}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateX: -50,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#f00",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default React.memo(MyResponsiveRadar);

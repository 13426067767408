import React, { useEffect, useState } from "react";
import { i18n } from "../../../../../../i18n/i18nText";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../services/webclient";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import { isMobile } from "../../../../../../mobileConfig";
import Chart from "react-apexcharts";
import ChartPagination from "../../../../components/ChartPagination/ChartPagination";
import PressureChartDateSelect from "../../../../components/ChartDateSelect/PressureChartDateSelect";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import styled from "@emotion/styled";
import { CircularProgress } from "@material-ui/core";

const Container = styled.div`
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
`;

type DataObject = {
  id: number;
  created: Date;
  pressure: number;
  message_subtype: string;
  irpd: number;
};

interface Props {
  farmID: number;
  irpdID: number;
}

function IrpdWaterPressureChart(props: Props) {
  const { farmID, irpdID } = props;

  const [lastPage, setLastPage] = useState(0);
  const [chartPage, setChartPage] = useState(0);
  const [chartDataLength, setChartDataLength] = useState("12");
  const [tempDay, setTempDay] = useState<moment.Moment>(moment());
  const [day, setDay] = useState<moment.Moment>(moment());
  const [chartData, setChartData] = useState<DataObject[]>();

  async function getPressureData() {
    let dayURL: string;

    dayURL = day.format("YYYY-M-D");

    try {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/irpds/${irpdID}/water-pressure/?date=${dayURL}`
      );

      if (response.data) {
        setChartData(response.data);
        setLastPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10))
        );
        setChartPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10)) - 1
        );
      } else {
        setLastPage(0);
        setChartPage(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleDataLength(value) {
    setChartDataLength(value);
    setChartPage(0);
  }

  function MyResponsiveBar(chartData: DataObject[]) {
    let sortedData = chartData
      .map((chartDatum) => {
        return {
          labelDate: moment(chartDatum.created).format("HH:mm"),
          valueColor: "#76de35",
          value: chartDatum.pressure ? chartDatum.pressure.toFixed(2) : 0,
        };
      })
      .slice(
        parseInt(chartDataLength) * chartPage,
        parseInt(chartDataLength) * (chartPage + 1)
      );

    const state = {
      series: [
        {
          name: "bar",
          data: sortedData.map((sortedDatum) => sortedDatum?.value),
        },
      ],
      options: {
        chart: {
          events: {
            mounted: function () {
              window.scrollTo(0, 0);
            },
          },
        },
        colors: ["#55b93c"],
        grid: {
          row: {
            colors: ["#fff", "#f7f2f2"],
          },
        },
        dataLabels: {
          style: {
            colors: ["rgba(51, 51, 51, 0.8)"],
            fontSize: "11px",
          },
        },
        xaxis: {
          categories: sortedData.map((sortedDatum) => sortedDatum.labelDate),
          labels: {
            rotate: -45,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        title: {
          text: `${i18n("BASIC_INFO_BOX_WATER_PRESSURE")} (bar)`,
          align: "center",
          offsetY: 20,
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
      },
    };
    return (
      <Chart
        id="IrpdWaterPressureChart"
        //@ts-ignore
        options={state.options}
        series={state.series}
        type="area"
        width={isMobile() ? "100%" : "180%"}
        height={isMobile() ? "350px" : "400px"}
      />
    );
  }

  useEffect(() => {
    getPressureData();
  }, [chartDataLength, day, irpdID]);

  return (
    <DashboardBox
      centerElement={<h2>{i18n("BASIC_INFO_BOX_CHART_WATER_PRESSURE")}</h2>}
      rightElement={
        <PressureChartDateSelect
          chartPrecision={"1"}
          tempDay={tempDay}
          setTempDay={setTempDay}
          setDay={setDay}
        />
      }
    >
      <Container>
        <div className="basic-info__header-infos">
          {i18n("BASIC_INFO_BOX_CHART_GENERATED_FOR", [
            moment(day).format("DD MMM YYYY"),
          ])}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ margin: "15px 20px", width: "80%" }}>
            <FormSelect
              id="SelectPrecision"
              label={"IRPD_PRESSURE_CHART_NUMBER_OF_POINTS"}
              newLabel={"IRPD_PRESSURE_CHART_NUMBER_OF_POINTS"}
              state={[chartDataLength, handleDataLength]}
              values={[
                ["12", "NUMBER_OF_POINTS_12"],
                ["24", "NUMBER_OF_POINTS_24"],
                ["30", "NUMBER_OF_POINTS_30"],
              ]}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {chartData ? (
              chartData.length > 0 ? (
                <>{MyResponsiveBar(chartData)}</>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: isMobile() ? "380px" : "430px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {i18n("METERSYSTEM_NO_READINGS")}
                </div>
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  height: isMobile() ? "380px" : "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
        </div>

        <ChartPagination
          lastPage={lastPage}
          chartPage={chartPage}
          setChartPage={setChartPage}
          chartDataLength={chartDataLength}
          chartData={chartData}
        />
      </Container>
    </DashboardBox>
  );
}

export default IrpdWaterPressureChart;

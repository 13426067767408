import moment from "moment";

export enum FilterByDateTypes {
  SET_FILTER_BY_DATE = "@@filter/SET_FILTER_BY_DATE",
}

export enum FilterTypesChoices {
  HISTORIC = "historic",
  REPORT = "report",
  OTHER = "other",
}

export interface FilterByDateRedux {
  start_date: moment.Moment;
  end_date: moment.Moment;
  type?: FilterTypesChoices;
}

export interface FilterByDateState {
  data: FilterByDateRedux;
}

/** @jsx jsx */
import React, { useLayoutEffect, useState, useEffect } from "react";
import "./style/App.scss";
import AppProviders from "./components/AppProviders/AppProviders";
import BaseRouter from "./routes/BaseRouter/BaseRouter";
import { BrowserRouter } from "react-router-dom";
import { Global, jsx, css } from "@emotion/core";
import "./utils/FCMService";
import { getDevicePlatform } from "./utils/DeviceNotchCheck";
import Store from "./redux/index";
import "moment/locale/en-SG";
import "moment/locale/pt-br";
import "moment/locale/ru";
import "moment/locale/de";
import "moment/locale/es";
import moment from "moment";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Keyboard } from "@capacitor/keyboard";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import ErrorBoundary from "./components/ErrorBoundary";
import Hotjar from "@hotjar/browser";

const App: React.FC = () => {
  const siteId = parseInt(process.env.HOTJAR_ID);
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  /*
    This code makes the application turn to tablet/mobile
    components form when the window size  reaches 1050px
    or lower.

    What happens is whe don't use it?
      
      When we resize the  page  to 1050px  or lower our  SCSS
      props changes and  receive  other properties, (it's the
      @include  tablet-landscape-and-below  spread  all  over
      the .scss of the app)  but  our  components still   the
      DesktopZone components, and that's what we need to fix.
      By updating the size we have a re-render  of the  react
      components and then they become MobileZone as  we need.
  */
  const [_, setSize] = useState([0, 0]);
  /*
    If we dont use this boolean  the react will
    re-render the component  all  day when  the 
    window width is lower  or  equal to 1050px.
  */
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    moment.locale("pt-br");
  }, []);

  function getLanguage() {
    return Store().store.getState().setting.language;
  }

  useEffect(() => {
    moment.locale(getLanguage()?.toString());
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 1024) {
        if (isUpdated === false) {
          setIsUpdated(true);
          setSize([window.innerWidth, window.innerHeight]);
        }
      } else if (window.innerWidth > 1024) {
        setIsUpdated(false);
      } else {
        setIsUpdated(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [isUpdated]);

  useEffect(() => {
    Keyboard.setAccessoryBarVisible({ isVisible: true });
    StatusBar.setStyle({
      style: Style.Dark,
    });
  }, []);

  return (
    // <ErrorBoundary>
    <AppProviders>
      <Global
        styles={css`
          html {
            /* overflow-x: hidden; */
            font-family: ${getDevicePlatform() === "ios"
                ? "Helvetica"
                : "Roboto"},
              sans-serif;
          }
          * {
            -webkit-tap-highlight-color: transparent !important;
          }
        `}
      />

      <BrowserRouter>
        <BaseRouter />
      </BrowserRouter>
    </AppProviders>
    // </ErrorBoundary>
  );
};

export default App;

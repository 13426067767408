import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../redux/setting/actions";
import { Language } from "../../../redux/setting/types";
import { i18n } from "../../../i18n/i18nText";

var filesaver = require("file-saver");

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px;
`;

function ExcelDownload() {
  var fullQueryParams = "";

  if (window.location.href.includes("/pivots/")) {
    fullQueryParams = window.location.href.split("/pivots/")[1];
  } else if (window.location.href.includes("/irpds/")) {
    fullQueryParams = window.location.href.split("/irpds/")[1];
  } else if (window.location.href.includes("/irpds_gh/")) {
    fullQueryParams = window.location.href.split("/irpds_gh/")[1];
  } else if (window.location.href.includes("/metersystems/")) {
    fullQueryParams = window.location.href.split("/metersystems/")[1];
  } else if (window.location.href.includes("/metersystems_gh/")) {
    fullQueryParams = window.location.href.split("/metersystems_gh/")[1];
  }

  const queryParams = fullQueryParams.split("&name=")[0];

  const apiKey = fullQueryParams?.split("apikey=")[1]?.split("&name")[0];

  const dispatch = useDispatch();

  const pivotName = decodeURIComponent(
    fullQueryParams.split("&name=")[1]?.split("&")[0]
  );

  const languageID = fullQueryParams.split("&language=")?.[1]?.split("&")?.[0];

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(true);

  const ghIrpdDownload = () => {
    axios
      .get(`/v4/reports/irpds/${queryParams}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Connection: "close",
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
        responseType: "blob",
        timeout: 30000,
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        filesaver.saveAs(blob, `Resumo ${pivotName}.xlsx`);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const ghMeterSystemDownload = () => {
    axios
      .get(`/v4/reports/meter_systems/${queryParams}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Connection: "close",
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
        responseType: "blob",
        timeout: 30000,
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        filesaver.saveAs(blob, `Resumo ${pivotName}.xlsx`);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const doExcelDownload = (equipment) => {
    console.log("equipment", queryParams);
    axios
      .get(`/v3/reports/${equipment}/${queryParams}`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Connection: "close",
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
        responseType: "blob",
        timeout: 30000,
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        filesaver.saveAs(blob, `Resumo ${pivotName}.xlsx`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (window.location.href.includes("/pivots/")) {
      doExcelDownload("pivots");
    } else if (window.location.href.includes("/irpds/")) {
      doExcelDownload("irpds");
    } else if (window.location.href.includes("/irpds_gh/")) {
      ghIrpdDownload();
    } else if (window.location.href.includes("/metersystems/")) {
      doExcelDownload("metersystems");
    } else if (window.location.href.includes("/metersystems_gh/")) {
      ghMeterSystemDownload();
    }

    dispatch(
      setLanguage(
        languageID ? (languageID as Language) : Language.PORTUGUESE_BRAZIL
      )
    );
  }, []);

  if (loading) {
    return (
      <Container>
        {i18n("SELECTED_PIVOT_DOWNLOADING_EXCEL")}
        <CircularProgress style={{ marginTop: "15px" }} />
      </Container>
    );
  } else if (!loading && !error) {
    return (
      <Container>
        {i18n("SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS")}
        <CheckCircleOutlineIcon
          color="primary"
          style={{ fontSize: "50px", marginTop: "15px" }}
        />
      </Container>
    );
  } else if (!loading && error) {
    return (
      <Container>
        {i18n("SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR")}
        <ErrorOutlineIcon
          style={{ fontSize: "50px", marginTop: "15px", color: "red" }}
        />
      </Container>
    );
  }
}

export default ExcelDownload;

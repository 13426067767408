import { Reducer } from "redux";

import { PivotsState, PivotsTypes, Pivot } from "./types";
import { PivotsActions } from "./actions";

import { arrUpdateOrInsert } from "../../utils/types";

const INITIAL_STATE: PivotsState = {
  list: []
};

const reducer: Reducer<PivotsState, PivotsActions> = (
  state = INITIAL_STATE,
  action: PivotsActions
): PivotsState => {
  const { type, payload } = action;

  switch (type) {
    case PivotsTypes.SET_PIVOTS:
      return { ...state, list: payload };

    case PivotsTypes.UPDATE_OR_INSERT_PIVOT:
      return {
        ...state,
        list: arrUpdateOrInsert<Pivot>(state.list, payload, "id")
      };

    default:
      return state;
  }
};

export default reducer;

export enum AdmTypes {
  ADD_ADM = "@@user/ADD_ADM",
}

export interface AdmState {
  data: {
    id: number;
    valid: boolean;
    type: string;
    take: boolean;
  };
}

import { coreHTTPClient } from "../../services/webclient";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import useNotify from "../tools/useNotify";
import { showLoading, hideLoading } from "../../redux/loading/actions";
import { useCountdown } from "./useCountdown";

const useCreateSimpleIrrigation = (
  farmID: number,
  pivotID: number,
  deviceID: number
): [(simpleIrrigation) => void] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  const [counter, setCounter] = useCountdown("Pivot", String(pivotID));

  async function createSimpleIrrigation(simpleIrrigation) {
    await new Promise(async (resolve) => {
      try {
        dispatch(showLoading());
        await coreHTTPClient
          .post(
            `/v3/farms/${farmID}/pivots/${pivotID}/device/${deviceID}/action/`,
            simpleIrrigation
          )
          .then(() => {
            setCounter(10);
            dispatch(hideLoading());
            dispatch(hideModal());
            notify("IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG", "success");
          });
      } catch (err) {
        dispatch(hideLoading());

        notify("IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG", "error");
      }
    });
  }

  return [createSimpleIrrigation];
};

export default useCreateSimpleIrrigation;

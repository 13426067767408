import { action } from "typesafe-actions";
import { FarmRedux, FarmsTypes } from "./types";
import { Action } from "redux";

export interface StoreFarm extends Action {
  type: FarmsTypes.STORE_FARM;
  payload: FarmRedux;
}

export const storeFarm = (farm: FarmRedux): StoreFarm => {
  sessionStorage.setItem("currentFarmName", farm.name);
  return action(FarmsTypes.STORE_FARM, farm);
};

export type FarmActions = StoreFarm;

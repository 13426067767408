import { atom } from "recoil";

interface Navbar {
  title: string;
}

export const NavbarState = atom<Navbar | null>({
  key: "NavbarState",
  default: {
    title: "",
  },
});

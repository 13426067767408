import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ArrowBack, CheckCircle, Settings } from "@material-ui/icons";
import DashboardBox from "../../../components/DashboardBox/DashboardBox";
import DraggableMap from "../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import FormInputPosition from "../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import { i18n, i18nTextId } from "../../../../../i18n/i18nText";
import ChangeRadioField from "../../../Pivot/EditPivot/components/ChangeRadioField/ChangeRadioField";
import { MessageStatus } from "../../../Pivot/EditPivot/EditPivot";
import { isMobile } from "../../../../../mobileConfig";
import { green, red } from "@material-ui/core/colors";
import DashboardBoxTitle from "../../../components/DashboardBox/DashboardBoxTitle";
import { centerMarkerSelector } from "../../../../../recoils/DraggableMapRecoil";
import { useNavigate, useParams } from "react-router";
import GeolocationGetter from "../../../../../components/GeolocationGetter/GeolocationGetter";
import { showModal } from "../../../../../redux/modal/actions";
import routes from "../../../../../routes/routes";
import { checkIsOnIntervalString } from "../../../../../utils/checks";
import { ValidationErrorMessage } from "../../../../../components/Form/types";
import { useDispatch } from "react-redux";
import { Repeater } from "../../../../../recoils/RepeaterRecoil";
import useNotify from "../../../../../hooks/tools/useNotify";
import styled from "@emotion/styled";
import FormSelect from "../../../../../components/Form/FormSelect/FormSelect";
import { objIsEmpty } from "../../../../../utils/types";
import { coreHTTPClient } from "../../../../../services/webclient";
import { useCountdown } from "../../../../../hooks/models/useCountdown";
import { showLocationModal } from "../../../../../redux/locationModal/actions";

const greenDot = require("../../../../../assets/images/markers/light-green-dot.svg");

interface Props {
  repeater: Repeater;
}

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

const useStyles = makeStyles({
  tabBar: {
    height: "40px",
    border: "0px",
    boxShadow: "0px 0px 0px 0px ",
  },
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function EditRepeaterForm(props: Props) {
  const { repeater } = props;
  const farmID: number = parseInt(useParams().farm, 10);
  const repeaterID: number = parseInt(useParams().repeaters, 10);

  const [counter, setCounter] = useCountdown("Repeater", String(repeaterID));
  const classes = useStyles(props);
  const notify = useNotify();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(false);
  const [repeaterCoordsError, setRepeaterCoordsError] = useState(false);
  const [hideButtonSave, setHideButtonSave] = useState(false);
  const [name, setName] = useState(props.repeater.name);
  const [energyType, setEnergyType] = useState(repeater.energy_type);
  const [repeaterLat, setrepeaterLat] = useState(
    props.repeater.position.split(",")[0]
  );
  const [repeaterLng, setrepeaterLng] = useState(
    props.repeater.position.split(",")[1]
  );

  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    name: undefined,
    repeaterLat: undefined,
    repeaterLng: undefined,
  });

  const setValidName = useCallback(
    (value) => {
      setName(value);
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 0, 17),
      });
    },
    [validationErrors]
  );

  const setValidrepeaterLat = useCallback(
    (value) => {
      setrepeaterLat(value);
    },
    [validationErrors]
  );

  const setValidrepeaterLng = useCallback(
    (value) => {
      setrepeaterLng(value);
    },
    [validationErrors]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkCanSave = (): boolean => {
    if (name === "") {
      return false;
    }
    return true;
  };

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  const checkErrors = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined || !checkCanSave()) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  useEffect(() => {
    checkErrors();
  }, [validationErrors]);

  async function patchRepeater(patchObject: any) {
    setLoading(true);
    await new Promise(async (resolve) => {
      try {
        if (!objIsEmpty(patchObject)) {
          const response = await coreHTTPClient.patch(
            `v3/farms/${farmID}/repeaters/${repeater.id}/`,
            patchObject
          );

          notify("IRPD_BOX_CONFIGURATION_SAVED", "success");
          setCounter(12);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setCounter(0);
        notify("IRPD_BOX_CONFIGURATION_ERROR", "error");
      }
    });
  }

  const saveButtonComponent = (
    <Button
      color="primary"
      disabled={
        hideButtonSave || loading || repeaterCoordsError || counter !== 0
      }
      endIcon={
        loading || counter !== 0 ? (
          <CircularProgress size={20} style={{ marginLeft: "8px" }} />
        ) : (
          <CheckCircle />
        )
      }
      onClick={() => {
        setValue(0);
        if (!showErrorMessages()) {
          const newPosition = repeaterLat + "," + repeaterLng;

          const patchObject = {
            name: name,
            position: newPosition,
            energy_type: energyType,
          };

          patchRepeater(patchObject);
          setValue(0);
        }
      }}
    >
      {counter !== 0 ? (
        `${counter}`
      ) : (
        <>{i18n("SELECTED_IRPD_SAVE_BUTTON_TEXT")}</>
      )}
    </Button>
  );

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const markers = [
    {
      lat: {
        state: repeaterLat,
        setFunction: setValidrepeaterLat,
      },
      lng: {
        state: repeaterLng,
        setFunction: setValidrepeaterLng,
      },
      markerUrl: greenDot,
      key: "center",
      event: {
        state: event,
        setFunction: setEvent,
      },
    },
  ];

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setrepeaterLat}
                setLongitude={setrepeaterLng}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={greenDot} />
    </Button3D>
  );

  return (
    <div className="general-form__container">
      <DashboardBox
        leftElement={backButton}
        centerElement={<DashboardBoxTitle title={"REPEATER_EDIT_LABEL"} />}
        rightElement={saveButtonComponent}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "grid",
            gridTemplateColumns: "12fr",
            height: "100%",
          }}
        >
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            variant={"scrollable"}
            indicatorColor="primary"
            aria-label="Edit Farm"
            centered={isMobile() ? true : false}
          >
            <Tab
              className={classes.tab}
              label={i18n("EDIT_PIVOT_LABEL_GENERAL")}
              tabIndex={value}
              icon={
                validationErrors["name"] || repeaterCoordsError ? (
                  <Settings
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Settings
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
            />
          </Tabs>
          <div
            style={{
              backgroundColor: "#fff",
              display: "grid",
              gridTemplateColumns: "1fr",
              height: "100%",
            }}
          >
            <div
              style={{
                maxHeight: isMobile() ? "" : "calc(100vh - 150px)",
                overflow: "auto",
              }}
            >
              <TabPanel value={value} index={0}>
                <div className="general-form__row-3-3-3-3">
                  <ChangeRadioField
                    radioID={repeater?.base_radio_id}
                    farmID={farmID}
                    deviceID={repeater.id}
                    label={i18n("EDIT_REPEATER_CENTRAL_LABEL")}
                    status={MessageStatus.NOT_SENT}
                    deviceType="REPEATER"
                    locked
                  />
                  <ChangeRadioField
                    radioID={repeater?.repeater_radio_id}
                    farmID={farmID}
                    deviceID={repeater.id}
                    label={i18n("EDIT_REPEATER_RADIO_LABEL")}
                    status={MessageStatus.NOT_SENT}
                    deviceType="REPEATER"
                    deviceSubType="repeater"
                  />
                </div>

                <div className="general-form__row-6-6">
                  <FormInput
                    id="repeater-name"
                    helperText={validationErrors["name"]}
                    label={"REPEATER_FIELD_NAME"}
                    state={[name, setValidName]}
                  />
                  <FormSelect
                    id="repeater-energy-type"
                    label={"REPEATER_CONFIG_FIELD_ENERGY_TYPE"}
                    state={[energyType, setEnergyType]}
                    values={[
                      ["Solar", "REPEATER_CONFIG_FIELD_SOLAR"],
                      ["Bivolt", "REPEATER_CONFIG_FIELD_BIVOLT"],
                    ]}
                  />
                </div>
                <div>
                  <FormInputPosition
                    setError={setRepeaterCoordsError}
                    id="repeater-config-center"
                    markerUrl={markerButton()}
                    label={"PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER"}
                    lat={[repeaterLat, setValidrepeaterLat]}
                    lng={[repeaterLng, setValidrepeaterLng]}
                    recoilSelector={centerMarkerSelector}
                  />

                  <div style={{ height: "400px", width: "100%" }}>
                    <DraggableMap
                      _setExperimentalRecoil
                      markers={markers}
                      zoomScroll={false}
                    />
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </DashboardBox>
    </div>
  );
}

export default EditRepeaterForm;

// tools
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
// redux elements
import { applyMiddleware, createStore, Store } from "redux";
import reducers from "./reducers";
import middlewares from "./middlewares";
import { Persistor, persistStore } from "redux-persist";
// state
import { AuthState } from "./auth/types";
import { SettingState } from "./setting/types";
import { FarmsState } from "./farms/types";
import { PivotsState } from "./pivots/types";
import { ModalState } from "./modal/types";
import { FarmState } from "./farm/types";
import { IrpdsState } from "./irpds/types";
import { LoadingState } from "./loading/types";
import { UserState } from "./userID/types";
import { AdminState } from "./adm/reducer";
import { UsersFarmState } from "./usersFarm/type";
import { FilterByDateState } from "./filterByDate/types";
import { NotificationsState } from "./notifications/types";
import { LocationModalState } from "./locationModal/types";
import { BillingState } from "./billing/types";

export interface ApplicationState {
  auth: AuthState;
  setting: SettingState;
  farms: FarmsState;
  farm: FarmState;
  billing: BillingState;
  pivots: PivotsState;
  irpds: IrpdsState;
  notifications: NotificationsState;
  locationModal: LocationModalState;
  modal: ModalState;
  loading: LoadingState;
  userID: UserState;
  adm: AdminState;
  usersFarm: UsersFarmState;
  filterByDate: FilterByDateState;
}

export const store: Store<ApplicationState> = createStore(
  reducers,
  undefined,
  composeWithDevTools(applyMiddleware(...middlewares))
);

const persistor: Persistor = persistStore(store);

export default () => ({ store, persistor });

import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import {
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fade,
} from "@material-ui/core";

import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import AddIcon from "@material-ui/icons/Add";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import {
  Pivot,
  ControllerConfig,
  ControllerStream,
} from "../../../../../../../../redux/pivots/types";

import "./ScheduleIrrigationV5.scss";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../../../../../../../redux/modal/actions";
import { ApplicationState } from "../../../../../../../../redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import {
  hideLoading,
  showLoading,
} from "../../../../../../../../redux/loading/actions";

import {
  irrigationMode,
  irrigationDirection,
  formatDateTime2,
  validadeFloatInput,
  hasValidationErrors,
  handleNumberMask,
  irrigationInjectionPump,
} from "../../../HistoricBox/utils/utils";

import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import { isMobile } from "../../../../../../../../mobileConfig";
import { RotateRight, RotateLeft } from "@material-ui/icons";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import RedoIcon from "@material-ui/icons/Redo";
import {
  PivotConfigFamily,
  PivotGPSStreamFamily,
} from "../../../../../../../../recoils/PivotRecoil";

import { useCountdown } from "../../../../../../../../hooks/models/useCountdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { PTPToMillimeter } from "../../../../../../../../utils/models/pivots";
import SpeedInputs from "../../../../../../../../components/SpeedInputs/SpeedInputs";
import { formatFloatValue } from "../../../../../../../../utils/models/format";
// import FormCheckBox from "../../../../../../../../components/Form/FormCheckBox/FormCheckBox";
// import FormOutlinedInput from "../../../../../../../../components/Form/FormOutlinedInput/FormOutlinedInput";

interface Props {
  pivot: Pivot;
  farmID: number;
  onTurningOn: () => any;
  sectorEndAngle?: string;
}

export const handleStopMode = (stopMode) => {
  switch (stopMode) {
    case 1:
      return i18n("SEGMENT_IRRIGATION_BY_ANGLE");
    case 2:
      return i18n("SEGMENT_IRRIGATION_BY_DATE");
    case 3:
      return i18n("SEGMENT_IRRIGATION_END_SECTOR");
    case 4:
      return i18n("SEGMENT_IRRIGATION_BY_ROUNDS");
  }
};

const IrrigationModeDiv = styled.div`
  font-size: 0.7rem;
  border-radius: 8px;
  color: white;
  margin-left: 5px;
  padding: 4px 7px;
`;

const ScheduleIrrigationV5 = (props: Props) => {
  const { pivot, farmID } = props;

  const notify = useNotify();

  const currentGPSStream = useRecoilValue(PivotGPSStreamFamily(pivot.id));

  const [counter, setCounter] = useCountdown("Pivot", String(pivot.id));

  const [blade, setBlade] = useState("100");

  const [millimeter, setMillimeter] = useState(
    PTPToMillimeter(props.pivot, 100).toFixed(4)
  );

  const setValidBlade = useCallback((value: string) => {
    if (value === "") {
      setBlade(value);
    } else if (value === "0") {
      setBlade(value);
    } else if (
      validadeFloatInput(value) &&
      !hasValidationErrors(value, 0, 100)
    ) {
      setBlade(handleNumberMask(value));
    }
    // eslint-disable-next-line
  }, []);

  const [scheduleMode, setScheduleMode] = useState("1");

  const [endTime, setEndTime] = useState(moment().add(1, "day"));

  const [endMode, setEndMode] = useState("2");

  const [endRounds, setEndRounds] = useState("1");

  const [stopAngle, setStopAngle] = useState(props.sectorEndAngle);

  const setValidStopAngle = useCallback((value: string) => {
    if (value === "") {
      setStopAngle(value);
    } else if (value === "0") {
      setStopAngle(value);
    } else if (
      validadeFloatInput(value) &&
      !hasValidationErrors(
        value,
        0,
        props.pivot.controllerconfig.content?.sector?.end_angle
      )
    ) {
      setStopAngle(handleNumberMask(value));
    }
    // eslint-disable-next-line
  }, []);

  const setValidScheduleMode = useCallback(
    (value: string) => {
      setScheduleMode(value);
      if (value === "2") {
        setInjectionPumpStatus(false);
        setInjectionPumpNote("");
      }
    },
    [scheduleMode]
  );

  const [startTime, setStartTime] = useState(moment().add(10, "minutes"));

  const [startMode, setStartMode] = useState<any>("on_a_date");

  const [type, setType] = useState("1");
  const [schedulePivotArray, setSchedulePivotArray] = useState<any[]>([]);

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  const isSectorialPivot = pivotConfig.content.sector.end_angle !== 360;

  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  useEffect(() => {
    if (
      pivotConfig !== undefined &&
      (startMode === "after_pause_time_1" || startMode === "after_pause_time_2")
    ) {
      var config = pivotConfig as ControllerConfig;
      var hour;
      var minute;
      var second = 59;
      if (startMode === "after_pause_time_1") {
        hour = config.content.pause_time.end_pause_time_hour_1;
        minute = config.content.pause_time.end_pause_time_minute_1;
      } else if (startMode === "after_pause_time_2") {
        hour = config.content.pause_time.end_pause_time_hour_2;
        minute = config.content.pause_time.end_pause_time_minute_2;
      }
      setStartTime(
        moment()
          .hour(hour)
          .minute(minute + 5)
          .second(second)
      );
    }
    if (startMode === "now") {
      setStartTime(moment());
    }
  }, [pivotConfig, startMode]);

  const handleStartModeValues = (): [string, string][] => {
    var config = pivotConfig as ControllerConfig;
    if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 ===
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    } else if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 !==
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["after_pause_time_2", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2"], //after pause time hour 3
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    }
    return [
      ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
      ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
    ];
  };

  const setValidEndRounds = useCallback(
    (value) => {
      if (value === "") {
        setEndRounds(value);
      } else if (
        validadeFloatInput(value) &&
        !hasValidationErrors(value, 1, 99)
      ) {
        setEndRounds(handleNumberMask(value));
      }
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (endMode === "1") {
      setEndRounds("1");
    } else if (endMode === "4") {
      setStopAngle("0");
    } else {
      setEndRounds("1");
      setStopAngle("0");
    }
  }, [endMode]);

  const setValidStartTime = useCallback(
    (value: moment.Moment) => {
      if (schedulePivotArray.length > 0) {
        var lastItemFromScheduleArray =
          schedulePivotArray[schedulePivotArray.length - 1];

        var lastStartTime = moment()
          .year(lastItemFromScheduleArray.start_year)
          .month(lastItemFromScheduleArray.start_month - 1)
          .date(lastItemFromScheduleArray.start_day)
          .hour(lastItemFromScheduleArray.start_hour)
          .minute(lastItemFromScheduleArray.start_minute);

        if (value < lastStartTime) return;
        else setStartTime(value);
      } else {
        setStartTime(value);
      }
      // eslint-disable-next-line
    },
    [endTime, schedulePivotArray, startMode]
  );

  const setValidEndTime = useCallback(
    (value: moment.Moment) => {
      if (schedulePivotArray.length > 0) {
        var lastItemFromScheduleArray =
          schedulePivotArray[schedulePivotArray.length - 1];

        var lastEndTime = moment()
          .year(lastItemFromScheduleArray.end_year + 2000)
          .month(lastItemFromScheduleArray.end_month - 1)
          .date(lastItemFromScheduleArray.end_day)
          .hour(lastItemFromScheduleArray.end_hour)
          .minute(lastItemFromScheduleArray.end_minute);

        if (value < lastEndTime) return;
        else setEndTime(value);
      } else {
        setEndTime(value);
      }
      // eslint-disable-next-line
    },
    [endTime, schedulePivotArray]
  );

  const dispatch = useDispatch();

  async function addScheduleLocallyToArray() {
    if (schedulePivotArray.length === 7) {
      notify("SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT", "error", 4000);
      return;
    }

    let scheduleObject = {
      number_editing: 1,
      mode: parseInt(scheduleMode),
      percent: parseFloat(blade),
      millimeter: parseFloat(millimeter),
      start_now: startMode === "now",
      start_on_a_date: startMode !== "now",
      start_day: startMode === "now" ? 0 : startTime.date(),
      start_month: startMode === "now" ? 0 : startTime.month() + 1,
      start_year: startMode === "now" ? 2000 : startTime.year(), // Neste caso setamos o ano 2000 pois ao enviar para o back subtraimos por 2000, portanto zeramos o start_year
      start_hour: startMode === "now" ? 0 : startTime.hour(),
      start_minute: startMode === "now" ? 0 : startTime.minute(),
      end_day: endMode === "2" ? endTime.date() : 0,
      end_month: endMode === "2" ? endTime.month() + 1 : 0,
      end_year: endMode === "2" ? endTime.year() : 0,
      end_hour: endMode === "2" ? endTime.hour() : 0,
      end_minute: endMode === "2" ? endTime.minute() : 0,
      direction: parseInt(type),
      stop_mode: parseInt(endMode),
      stop_angle: parseInt(stopAngle),
      schedule_rounds: parseInt(endRounds),
    };

    setSchedulePivotArray((schedArray) => [...schedArray, scheduleObject]);

    if (endMode === "2") {
      setStartTime(endTime);
      setEndTime(moment(endTime).add(1, "day"));
    }

    //
  }

  async function removeScheduleFromLocalArray() {
    setStartTime(moment());
    setEndTime(moment());
    setSchedulePivotArray([]);
  }

  async function postScheduleIrrigation() {
    try {
      // Will this add the number editing to the body?
      schedulePivotArray.forEach((_, index) => {
        schedulePivotArray[index].number_editing = index;
        schedulePivotArray[index].start_year =
          schedulePivotArray[index].start_year - 2000; // Isso ocorre pois o hardware só usa os dois últimos dígitos do ano, ex: 2017 -> 17
        schedulePivotArray[index].end_year =
          schedulePivotArray[index].stop_mode !== 2
            ? 0
            : schedulePivotArray[index].end_year - 2000; // Isso ocorre pois o hardware só usa os dois últimos dígitos do ano, ex: 2017 -> 17
      });

      var config = pivotConfig as ControllerConfig;
      const autoReversionCommand =
        config.content.autoreversion_command?.command || 0;

      let body = {
        message_subtype: "schedule",
        equipment: pivot.id,
        content: {
          injection_pump_command: {
            command: +injectionPumpStatus,
            note: injectionPumpNote,
          },
          autoreversion_command: {
            command: autoReversionCommand,
          },
          schedule_irrigation_parameters: schedulePivotArray,
          irrigation_status: {
            irrigation_type: 2,
            irrigation_status: schedulePivotArray[0].direction, // 1 forward, 2 reverse
          },
        },
      };

      dispatch(showLoading());
      const response = await coreHTTPClient
        .post(
          `v3/farms/${farmID}/pivots/${pivot.id}/device/${pivot.control}/action/`,
          body
        )
        .then(() => {
          notify("SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS", "success");
          setCounter(10);
          dispatch(hideLoading());
          dispatch(hideModal());
        });
    } catch (err) {
      dispatch(hideLoading());
      notify("SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE", "error");
    }
  }

  var isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  const handleEndAngle = useCallback(
    (mode, angle, rounds) => {
      switch (mode) {
        case 1:
          return parseFloat(angle);
        case 3:
          return pivot.controllerconfig?.content?.sector?.end_angle;
        case 4:
          return (
            Math.abs(
              pivot.controllerconfig?.content?.sector?.end_angle -
                pivot.controllerconfig?.content?.sector?.start_angle
            ) * parseInt(rounds)
          );
        default:
          return 0;
      }
    },
    [pivot?.controllerconfig]
  );

  const lastIrrigationUsed = async () => {
    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/controlleraction/schedule/latest/`
    );
    if (response.data?.content) {
      let lastIrrigationObj =
        response.data.content.schedule_irrigation_parameters;
      lastIrrigationObj.map((lastIrri) => {
        if (lastIrri.start_month < parseInt(moment().format("M")))
          lastIrri.start_year = parseInt(
            moment().add(1, "year").format("YYYY")
          );
        else lastIrri.start_year = parseInt(moment().format("YYYY"));
      });
      setSchedulePivotArray(lastIrrigationObj);
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");
    setIsLoadingLastAction(false);
  };

  const [injectionPumpStatus, setInjectionPumpStatus] = useState(false);
  const [injectionPumpNote, setInjectionPumpNote] = useState("");

  return (
    <div className="schedule-irrigation-v5">
      <div
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isLoadingLastAction}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : (
              <RedoIcon />
            )
          }
          onClick={lastIrrigationUsed}
        >
          <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          marginBottom: "10px",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        {i18n("CURRENT_ANGLE")}{" "}
        {
          (currentGPSStream as ControllerStream).content?.current_angle
            ?.current_angle
        }
        º
      </div>

      <div>
        {isLoading && <LinearProgress />}
        <div className="schedule-irrigation-v5__form">
          <FormSelect
            id="segment-start-mode"
            label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
            state={[startMode, setStartMode]}
            values={handleStartModeValues()}
          />
          <FormDateTimePicker
            minDate={startTime}
            id="final-hour"
            disabled={startMode === "now"}
            label={"SCHEDULE_IRRIGATION_DATE_START_LABEL"}
            state={[startTime, setValidStartTime]}
          />
          <FormSelect
            id="segment-end-mode"
            label={"SEGMENT_IRRIGATION_FIELD_END_MODE"}
            state={[endMode, setEndMode]}
            values={
              isSectorialPivot
                ? [
                    ["1", "SEGMENT_IRRIGATION_BY_ANGLE"], //stop by angle
                    ["2", "SEGMENT_IRRIGATION_BY_DATE"], //stop by date
                    ["4", "SEGMENT_IRRIGATION_BY_ROUNDS"], //stop by rounds
                  ]
                : [
                    ["1", "SEGMENT_IRRIGATION_BY_ANGLE"], //stop by angle
                    ["2", "SEGMENT_IRRIGATION_BY_DATE"], //stop by date
                    ["3", "SEGMENT_IRRIGATION_END_SECTOR"], //stop end sector
                    ["4", "SEGMENT_IRRIGATION_BY_ROUNDS"], //stop by rounds
                  ]
            }
          />
          {endMode === "1" ? (
            <FormInput
              id="segment-stop-angle"
              label={"SEGMENT_IRRIGATION_FIELD_END_ANGLE"}
              state={[stopAngle, setValidStopAngle]}
              inputMode="numeric"
              endAdornment="°"
            />
          ) : (
            <></>
          )}
          {endMode === "2" ? (
            <FormDateTimePicker
              id="segment-end-time"
              minDate={startTime}
              label={"SEGMENT_IRRIGATION_FIELD_END_DATE"}
              state={[endTime, setEndTime]}
            />
          ) : (
            <></>
          )}
          {endMode === "3" ? <div /> : <></>}
          {endMode === "4" ? (
            <FormInput
              state={[endRounds, setValidEndRounds]}
              id="segment-end-rounds"
              label={"SEGMENT_IRRIGATION_FIELD_END_ROUNDS"}
              inputMode="numeric"
            />
          ) : (
            <></>
          )}
          <SpeedInputs
            pivot={pivot}
            millimeter={millimeter}
            percentage={blade}
            setMillimeter={setMillimeter}
            setPercentage={setBlade}
          />
          <FormSelect
            id="farm-type-interval"
            label={"SCHEDULE_IRRIGATION_FIELD_MODE"}
            state={[scheduleMode, setValidScheduleMode]}
            values={[
              ["1", "SCHEDULE_IRRIGATION_VALUE_WET"],
              ["2", "SCHEDULE_IRRIGATION_VALUE_DRY"],
            ]}
          />

          <FormSelect
            id="farm-type-interval"
            label={"SCHEDULE_IRRIGATION_FIELD_DIRECTION"}
            state={[type, setType]}
            values={[
              ["1", "SCHEDULE_IRRIGATION_VALUE_FORWARD"],
              ["2", "SCHEDULE_IRRIGATION_VALUE_REVERSE"],
            ]}
          />
        </div>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {scheduleMode === "1" && pivot?.controllerconfig?.injection_pump && (
            <FormCheckBox
              label={"SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP"}
              state={[injectionPumpStatus, setInjectionPumpStatus]}
            />
          )}
          {injectionPumpStatus && (
            <div style={{ width: "800px" }}>
              <FormOutlinedInput
                id="input"
                type="text"
                label={"IRRIGATION_FIELD_INJECTION_PUMP_NOTE"}
                max="200"
                helperText={
                  injectionPumpNote.length > 200
                    ? "ERROR_INVALID_AMOUNT_MAX"
                    : ""
                }
                state={[injectionPumpNote, setInjectionPumpNote]}
                multiline={true}
              />
            </div>
          )}
        </div> */}
        <div className="schedule-irrigation-v5__button-container">
          <Button
            variant="contained"
            color="primary"
            disabled={
              stopAngle === "" ||
              endRounds === "" ||
              blade === "" ||
              injectionPumpNote.length > 200
            }
            style={
              isMobile ? { width: "100%" } : { width: "50%", height: "100%" }
            }
            onClick={() => {
              addScheduleLocallyToArray();
            }}
          >
            <AddIcon />
          </Button>
        </div>

        {schedulePivotArray ? (
          <div>
            {schedulePivotArray.map((row, index) => {
              return (
                <>
                  <div className="schedule-irrigation-v5__row-container">
                    <ExpansionPanel style={{ backgroundColor: "#f5f5f5" }}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {i18n("SELECTED_PIVOT_SCHEDULE_IRRIGATION")} {index + 1}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        style={{ backgroundColor: "rgba(0, 0, 0, .087)" }}
                      >
                        <div className="general-form__row-1-1-1-1-1-1-1">
                          <div
                            id={`start-time${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {i18n("SCHEDULE_IRRIGATION_DATE_START_LABEL")}
                            </div>
                            <div className="schedule-irrigation-v5__value">
                              {row.start_now
                                ? i18n("SEGMENT_IRRIGATION_MODE_NOW")
                                : `${formatDateTime2(
                                    row.start_year,
                                    row.start_month,
                                    row.start_day,
                                    row.start_hour,
                                    row.start_minute
                                  )}`}
                            </div>
                          </div>

                          <div
                            id={`end-time${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {row.stop_mode === 1 ? (
                                i18n("SEGMENT_IRRIGATION_FIELD_END_ANGLE")
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 2 ? (
                                i18n("SEGMENT_IRRIGATION_FIELD_END_DATE")
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 3 ? (
                                i18n("SEGMENT_IRRIGATION_END_SECTOR")
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 4 ? (
                                i18n("SEGMENT_IRRIGATION_FIELD_END_ROUNDS")
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="schedule-irrigation-v5__value">
                              {row.stop_mode === 1 ? (
                                `${row.stop_angle}°`
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 2 ? (
                                `${formatDateTime2(
                                  row.end_year,
                                  row.end_month,
                                  row.end_day,
                                  row.end_hour,
                                  row.end_minute
                                )}`
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 3 && !isSectorialPivot ? (
                                i18n("SIMPLE_IRRIGATION_END_SECTOR")
                              ) : (
                                <></>
                              )}
                              {row.stop_mode === 4 ? (
                                row.schedule_rounds
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div
                            id={`blade${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {i18n("SCHEDULE_IRRIGATION_FIELD_PERCENT")}
                            </div>
                            <div className="schedule-irrigation-v5__value">
                              {`${row.percent} %`}
                            </div>
                          </div>
                          <div
                            id={`millimeter${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {i18n("SCHEDULE_IRRIGATION_FIELD_PRECIP")}
                            </div>
                            <div className="schedule-irrigation-v5__value">
                              {`${formatFloatValue(row.millimeter)} mm`}
                            </div>
                          </div>
                          <div
                            id={`segment-mode${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {i18n("SCHEDULE_IRRIGATION_FIELD_MODE")}
                            </div>
                            <IrrigationModeDiv
                              className="schedule-irrigation-v5__value"
                              style={{
                                backgroundColor:
                                  row.mode === 1
                                    ? "rgb(70, 121, 237)"
                                    : "rgb(147, 66, 0)",
                                width: row.mode == 1 ? "65px" : "43px",
                                margin: "4px 0px 4px 8px",
                              }}
                            >
                              {irrigationMode(row.mode)}
                            </IrrigationModeDiv>
                          </div>
                          <div
                            id={`type${index}`}
                            className="schedule-irrigation-v5__fields"
                          >
                            <div className="schedule-irrigation-v5__title">
                              {i18n("SCHEDULE_IRRIGATION_FIELD_DIRECTION")}
                            </div>
                            <div className="schedule-irrigation-v5__value">
                              {irrigationDirection(row.direction)}
                              {row.direction === 1 ? (
                                <RotateRight
                                  style={{
                                    fontSize: "15px",
                                    margin: "0px 0px 0px 5px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                  }}
                                />
                              ) : null}
                              {row.direction === 2 ? (
                                <RotateLeft
                                  style={{
                                    fontSize: "15px",
                                    margin: "0px 0px 0px 5px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <div className="progress-bar">
            <CircularProgress thickness={2} size={40} />
          </div>
        )}

        <MobileZone>
          <div className="schedule-irrigation-v5__clear-table">
            {schedulePivotArray.length > 0 ? (
              <Fade in={true}>
                <Button
                  disabled={schedulePivotArray.length <= 0}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    width: "100%",
                    marginTop: isMobile() ? "0px" : "20px",
                  }}
                  variant="contained"
                  onClick={() => {
                    removeScheduleFromLocalArray();
                  }}
                >
                  {i18n("PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM")}
                </Button>
              </Fade>
            ) : null}
          </div>
        </MobileZone>
      </div>

      <MobileZone>
        <div className="energy-ranges-form__button-container">
          <Button
            disabled={schedulePivotArray.length <= 0 || isLoading}
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : null
            }
            onClick={() => {
              postScheduleIrrigation();
              props.onTurningOn();
            }}
          >
            {i18n("SCHEDULE_IRRIGATION_BUTTON_TURN_ON")}
          </Button>
        </div>
      </MobileZone>

      <DesktopZone>
        <div className="schedule-irrigation-v5__clear-table">
          <Button
            disabled={schedulePivotArray.length <= 0}
            style={
              schedulePivotArray.length <= 0
                ? {
                    width: "200px",
                    marginTop: "10px",
                  }
                : {
                    backgroundColor: "red",
                    color: "white",
                    width: "200px",
                    marginTop: "10px",
                  }
            }
            variant="contained"
            onClick={() => {
              removeScheduleFromLocalArray();
            }}
          >
            {i18n("PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={schedulePivotArray.length <= 0 || isLoading}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : null
            }
            style={{
              width: "200px",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            onClick={() => {
              postScheduleIrrigation();
              props.onTurningOn();
            }}
          >
            {i18n("SCHEDULE_IRRIGATION_BUTTON_TURN_ON")}
          </Button>
        </div>
      </DesktopZone>
    </div>
  );
};

export default ScheduleIrrigationV5;

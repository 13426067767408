import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import moment from "moment";
import useCreateIRPDAction from "../../../../../../hooks/models/useCreateIRPDAction";
import "./IrpdAction.scss";
import { useStyles } from "../../../../../../components/DialogModal/DialogModal.style";
import { hideModal } from "../../../../../../redux/modal/actions";
import { ApplicationState } from "../../../../../../redux";
import { isMobile } from "../../../../../../mobileConfig";
import { coreHTTPClient } from "../../../../../../services/webclient";
import useNotify from "../../../../../../hooks/tools/useNotify";
import {
  hideLoading,
  showLoading,
} from "../../../../../../redux/loading/actions";
import { i18n } from "../../../../../../i18n/i18nText";

interface Props {
  farmID: number;
  irpdID: number;
  mode: string;
  protocol?: number;
}

function IrpdActionModal(props: Props) {
  const { farmID, irpdID, mode } = props;
  const classes = useStyles(props);
  const [checkStart, setCheckStart] = useState(true);
  const [checkEnd, setCheckEnd] = useState(props.protocol < 5); // Mudaremos o valor inicial pra false caso seja IrpdV5 pois não podemos ter checkStart e checkEnd true no irpdV5
  const [startTime, setStartTime] = useState(moment());
  const [endTime, setEndTime] = useState(
    props.protocol >= 5 ? moment().add(1, "hour") : moment()
  );
  const notify = useNotify();

  const [createIrpdAction] = useCreateIRPDAction();

  const isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  const handleChangeEnd = useCallback(
    (value: moment.Moment) => {
      if (checkStart !== checkEnd) {
        setEndTime(value);
        return;
      }
      if (value.isAfter(startTime)) {
        setEndTime(value);
      }
    },
    [startTime]
  );

  const noEndDate = !checkStart && checkEnd;

  const handleChangeStart = useCallback(
    (value: moment.Moment) => {
      if (checkStart !== checkEnd) {
        setStartTime(value);
        return;
      }
      if (value.isBefore(endTime) || noEndDate) {
        setStartTime(value);
      }
    },
    [endTime, noEndDate]
  );

  const dispatch = useDispatch();

  const infoActions = () => {
    createIrpdAction({
      mode,
      checkStart,
      checkEnd,
      farmID,
      irpdID,
      startDateTime: startTime,
      endDateTime: endTime,
    });
  };

  async function scheduleActions() {
    dispatch(showLoading());
    const body = {
      message_subtype: "schedule",
      content: {
        pump_schedule_enable: {
          enable: 1,
        },
        pump_schedule: {
          start_year: !checkStart ? startTime.year() - 2000 : 0,
          start_month: !checkStart ? startTime.month() + 1 : 0,
          start_day: !checkStart ? startTime.date() : 0,
          start_hour: !checkStart ? startTime.hour() : 0,
          start_minute: !checkStart ? startTime.minute() : 0,
          stop_year: !checkEnd ? endTime.year() - 2000 : 0,
          stop_month: !checkEnd ? endTime.month() + 1 : 0,
          stop_day: !checkEnd ? endTime.date() : 0,
          stop_hour: !checkEnd ? endTime.hour() : 0,
          stop_minute: !checkEnd ? endTime.minute() : 0,
        },
      },
    };
    await new Promise(async (resolve) => {
      try {
        await coreHTTPClient
          .post(`v3/farms/${farmID}/irpds/${props.irpdID}/actionv5/`, body)
          .then(() => {
            dispatch(hideModal());
            notify("IRPD_BOX_COMMAND_SENT", "success");
          });
      } catch (err) {
        notify("IRPD_BOX_COMMAND_ERROR", "error");
      } finally {
        dispatch(hideLoading());
      }
    });
  }

  const startMode = (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "4fr 7fr",
          gridGap: isMobile() ? "5px" : "10px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={checkStart}
              onChange={() => setCheckStart(!checkStart)}
              color="primary"
              disabled={props.protocol >= 5 ? checkEnd : false}
            />
          }
          label={i18n("IRPD_CONFIG_FIELD_START_NOW")}
        />

        <FormDateTimePicker
          id="final-hour"
          label="IRPD_FIELD_START_INTERVAL"
          state={[startTime, handleChangeStart]}
          minDate={moment()}
          disabled={checkStart}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkEnd}
              onChange={() => setCheckEnd(!checkEnd)}
              color="primary"
              disabled={props.protocol >= 5 ? checkStart : false}
            />
          }
          label={i18n("IRPD_CONFIG_FIELD_DONT_STOP")}
        />
        <FormDateTimePicker
          id="final-hour"
          label="IRPD_FIELD_END_INTERVAL"
          state={[endTime, handleChangeEnd]}
          disabled={checkEnd}
          minDate={!checkEnd ? startTime : null}
        />
      </div>
      <div className="irpd-modal__button">
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          disabled={
            isLoading ||
            (!endTime.isSameOrAfter(startTime) && !checkEnd) ||
            (props.protocol >= 5 && checkStart && checkEnd)
          } // No hardware o irpdV5 não pode começar agora e não parar (Giuliano)
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : null
          }
          onClick={mode === "2" ? scheduleActions : infoActions}
        >
          <>{i18n("EDIT_IRPD_SEND_SUCCESS")}</>
        </Button>
      </div>
    </>
  );

  const endMode = (
    <>
      <strong>
        <>{i18n("SELECTED_IRPD_STOP")}</>
      </strong>
      <div className="irpd-modal__centralize">
        <>{i18n("EDIT_IRPD_CANCEL")}</>
      </div>

      <div className="irpd-modal__button-container">
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          className={classes.declineButton}
          onClick={() => dispatch(hideModal())}
        >
          <>{i18n("EDIT_IRPD_SEND_CANCEL")}</>
        </Button>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          disabled={isLoading}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : null
          }
          color="primary"
          onClick={infoActions}
        >
          <>{i18n("EDIT_IRPD_SEND_SUCCESS")}</>
        </Button>
      </div>
    </>
  );

  const startModeV5 = (
    <>
      <strong>
        <>{i18n("SELECTED_IRPD_START")}</>
      </strong>
      <div className="irpd-modal__centralize">
        <>{i18n("EDIT_IRPD_CANCEL")}</>
      </div>

      <div className="irpd-modal__button-container">
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          className={classes.declineButton}
          onClick={() => dispatch(hideModal())}
        >
          <>{i18n("EDIT_IRPD_SEND_CANCEL")}</>
        </Button>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          disabled={isLoading}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : null
          }
          color="primary"
          onClick={() => {
            dispatch(showLoading());
            const body = {
              message_subtype: "simple",
              equipment: props.irpdID,
              content: {
                pump_action: {
                  enable: 1,
                },
              },
            };
            const response = coreHTTPClient
              .post(`v3/farms/${farmID}/irpds/${props.irpdID}/actionv5/`, body)
              .then(() => {
                notify("IRPD_BOX_COMMAND_SENT", "success");
                dispatch(hideModal());
              })
              .catch((err) => {
                notify("IRPD_BOX_COMMAND_ERROR", "error");
              })
              .finally(() => {
                dispatch(hideLoading());
              });
          }}
        >
          <>{i18n("EDIT_IRPD_SEND_SUCCESS")}</>
        </Button>
      </div>
    </>
  );

  const endModeV5 = (
    <>
      <strong>
        <>{i18n("SELECTED_IRPD_STOP")}</>
      </strong>
      <div className="irpd-modal__centralize">
        <>{i18n("EDIT_IRPD_CANCEL")}</>
      </div>

      <div className="irpd-modal__button-container">
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          className={classes.declineButton}
          onClick={() => dispatch(hideModal())}
        >
          <>{i18n("EDIT_IRPD_SEND_CANCEL")}</>
        </Button>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          disabled={isLoading}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : null
          }
          color="primary"
          onClick={() => {
            dispatch(showLoading());
            const body = {
              message_subtype: "simple",
              equipment: props.irpdID,
              content: {
                pump_action: {
                  enable: 0,
                },
              },
            };
            const response = coreHTTPClient
              .post(`v3/farms/${farmID}/irpds/${props.irpdID}/actionv5/`, body)
              .then(() => {
                notify("IRPD_BOX_COMMAND_SENT", "success");
                dispatch(hideModal());
              })
              .catch((err) => {
                notify("IRPD_BOX_COMMAND_ERROR", "error");
              })
              .finally(() => {
                dispatch(hideLoading());
              });
          }}
        >
          <>{i18n("EDIT_IRPD_SEND_SUCCESS")}</>
        </Button>
      </div>
    </>
  );

  const modalSelector = () => {
    if (props.protocol < 5) {
      if (mode === "1") return startMode;
      return endMode;
    }
    if (mode === "2") return startMode; // Aproveitamos que o startMode do irpd V4 tem a mesma estrututa e utilizaremos quando o mode === 2
    if (mode === "1") return startModeV5;
    return endModeV5;
  };

  return (
    <>
      <div className="irpd-modal__centralize">{modalSelector()}</div>
    </>
  );
}

export default IrpdActionModal;

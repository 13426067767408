import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import useInterval from "../../../../../../hooks/tools/useInterval";
import { acceptTermsStatus } from "../../../../../../recoils/AcceptedTermsRecoil";
import { coreHTTPClient } from "../../../../../../services/webclient";
import PrivacyPolicies from "./PrivacyPolicies";
import Announcements from "./Announcements";

export default function NotificationsSystem(props) {
  const [notification, setNotification] = useState();
  const [acceptedTerms, setAcceptedTerms] = useRecoilState(acceptTermsStatus);
  const [policyInfos, setPolicyInfos] = useState({
    is_accept_policy: false,
    accept_policy_version: null,
    accept_policy_date: null,
    last_policy_version: null,
  });

  // 5 minutes if acceptedTerms, else 5 seconds.
  const SHOW_ANNOUNCEMENT_DELAY = acceptedTerms ? 300000 : 3500;

  const updatePolicyInfos = async () => {
    try {
      const response = await coreHTTPClient.get("/v3/auth/check_policy/");
      if (response.data) {
        setPolicyInfos({
          is_accept_policy: response.data.is_accept_policy,
          accept_policy_version: response.data.accept_policy_version,
          accept_policy_date: response.data.accept_policy_date,
          last_policy_version: response.data.last_policy_version,
        });
        setAcceptedTerms(response.data.is_accept_policy);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouncement = async () => {
    try {
      updatePolicyInfos();
      let response;
      if (acceptedTerms == false) {
        response = await coreHTTPClient.get("/v3/notifications/policy/");
      } else {
        response = await coreHTTPClient.get("/v3/notifications/general/");
      }

      if (response?.data) {
        const lastAnnouncementUUID = localStorage.getItem(
          "lastAnnouncementUUID"
        );
        if (
          response.data.is_active &&
          (lastAnnouncementUUID == null ||
            lastAnnouncementUUID != response.data.uuid)
        ) {
          setNotification(response.data);
        }
      }
    } catch (err) {
      console.log(err);
      setNotification(null);
    }
  };

  useInterval(() => {
    getAnnouncement();
  }, SHOW_ANNOUNCEMENT_DELAY);

  useEffect(() => {
    getAnnouncement();
  }, []);

  return notification ? (
    acceptedTerms == false ? (
      <PrivacyPolicies
        notification={notification}
        acceptedTerms={acceptedTerms}
        policyInfos={policyInfos}
      />
    ) : (
      <Announcements
        notification={notification}
        acceptedTerms={acceptedTerms}
        policyInfos={policyInfos}
      />
    )
  ) : null;
}

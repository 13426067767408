import { Action } from "redux";
import { UsersFarmTypes, UserFarm } from "./type";
import { action } from "typesafe-actions";

export interface SetUsersFarm extends Action {
  type: UsersFarmTypes.SET_USERSFARM;
  payload: UserFarm[];
}

// export interface UpdateOrInsertFarm extends Action {
//   type: FarmsTypes.UPDATE_OR_INSERT_FARM;
//   payload: Farm;
// }

export const setUsersFarm = (usersFarm: UserFarm[]): SetUsersFarm =>
  action(UsersFarmTypes.SET_USERSFARM, usersFarm);

// export const updateOrInsertFarm = (farm: Farm): UpdateOrInsertFarm =>
//   action(FarmsTypes.UPDATE_OR_INSERT_FARM, farm);

export type UsersFarmActions = SetUsersFarm;
//& UpdateOrInsertFarm;

import { LocationModalState, LocationModalTypes } from "./types";
import { LocationModalActions } from "./actions";
import { NoneReactJSX } from "../../utils/types";

const INITIAL_STATE: LocationModalState = {
  active: false,
  data: {
    content: NoneReactJSX(),
  },
};

function reducer(state = INITIAL_STATE, action: LocationModalActions) {
  const { type }: { type: string } = action;

  switch (type) {
    case LocationModalTypes.SHOW_LOCATION_MODAL:
      return { ...state, active: true, data: action.payload };

    case LocationModalTypes.HIDE_LOCATION_MODAL:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
}

export default reducer;

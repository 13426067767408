import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { IrrigationV5 } from "../redux/pivots/types";
import { Historic } from "../hooks/models/useHistoric";

export interface IMeterConfig extends IrrigationV5 {
  position_imeter: any;
}

export interface IMeterStreamPeriodicContent {
  id: number;
  imanage_master_status: {
    status: number;
  };
  imanage_sensor_measure_value: {
    number_editing: number;
    value: number;
  }[];
  pulse_counter_readings?: {
    last_current_flow: number;
    overflow_quantity: number;
    totalized_volume_measured: number;
  };
  pump_hourmeter?: {
    hours: number;
    minutes: number;
  };
}

export interface IMeterStreamEventContent {
  id: number;
  imanage_master_status: {
    status: number;
  };
  pump_hourmeter: {
    hours: number;
    minutes: number;
  };
}

export interface IMeterStreamPeriodic extends IrrigationV5 {
  id: number;
  content: IMeterStreamPeriodicContent;
  flow?: number;
}

export interface IMeterStreamEvent extends IrrigationV5 {
  id: number;
  content: IMeterStreamEventContent;
}

export interface IMeterConfig extends IrrigationV5 {
  graphic_max_value: number | null;
  sensor_offset?: number;
  flow_curve_equation?: number[];
  measure_scale?: number;
  measure_unit?: string;
  min_limit: number;
  max_limit: number;
  metersystem_name: string;
  imeter_name: string;
  position: string;
  sensor_process_controller_pair: any;
  irpd_related_list?: any[];
}

export enum IMeterFunction {
  LEVEL = "LEVEL",
  FLOW = "FLOW",
  PULSE_FLOW = "PULSE_FLOW",
}

export interface MeterSystem {
  id: number;
  base: number;
  farm: number;
  imeter_set: IMeter[];
  name: string;
  position: string;
  permission_level?: number;
}

export interface Sensor_process_controller_pair {
  id: number;
  sensor: number;
}

export interface IMeter {
  id: number;
  base: any;
  protocol?: string;
  latest_event_stream: IMeterStreamEvent;
  latest_periodic_stream: IMeterStreamPeriodic;
  imeter_device: {
    created: string;
    id: number;
    radio_id: string;
    taken: boolean;
  };
  latest_config: IMeterConfig;
  local_actuation: boolean;
  meter_system: MeterSystem;
  name: string;
  position: string;
  process_controllers: any[];
  remote_control: any[];
  sensors: any[];
  sensor_process_controller_pair: Sensor_process_controller_pair;
  function: IMeterFunction;
}

export const SelectedMetersystemState = atom<MeterSystem | null>({
  key: "SelectedMetersystem",
  default: null,
});

export const MeterSystemStateList = atom<MeterSystem[] | null>({
  key: "MetersystemState",
  default: [],
});

export const MeterSystemHistoric = atomFamily<Historic | null, number>({
  key: "MeterSystemHistoric",
  default: {
    limit: 0,
    offset: 0,
    count: 0,
    filters: {
      models: [],
    },
    results: [],
  },
});

export const MeterSystemItemFamily = selectorFamily<MeterSystem, number>({
  key: "MeterSystemItemFamily",
  get: (meterSystemId: number) => ({ get }) => {
    let meterSystem = get(MeterSystemStateList).find(
      (ms) => ms.id == meterSystemId
    );

    return meterSystem;
  },
  set: (meterSystemId: number) => ({ get, set }, newValue: MeterSystem) => {
    // fix bug de não carregar acessando direto pela página do meterSystem
    if (get(MeterSystemStateList).length == 0) {
      set(MeterSystemStateList, [newValue]);
    } else {
      let newMeterSystemStateList = get(MeterSystemStateList).map((ms) =>
        ms.id == meterSystemId ? newValue : ms
      );
      set(MeterSystemStateList, newMeterSystemStateList);
    }
  },
});

export const IMeterFamily = atomFamily<IMeter | null, number>({
  key: "IMeterFamily",
  default: null,
});

export const IMeterStreamEventFamily = atomFamily<IMeterStreamEvent, number>({
  key: "IMeterStreamEventFamily",
  default: null,
});

export const IMeterStreamPeriodicFamily = atomFamily<
  IMeterStreamPeriodic,
  number
>({
  key: "IMeterStreamPeriodicFamily",
  default: null,
});

export const IMeterLatestConfigFamily = atomFamily<
  MeterSystem["imeter_set"][0]["latest_config"],
  number
>({
  key: "IMeterLatestConfigFamily",
  default: null,
});

export const IMeterHelperSelector = selector<{
  IMeterID: number;
  imeter: IMeter;
}>({
  key: "IMeterHelperSelector",
  get: ({ get }) => null,
  set: (
    { get, set },
    newValue: {
      IMeterID: number;
      imeter: IMeter;
    }
  ) => {
    return set(IMeterFamily(newValue.IMeterID), newValue.imeter);
  },
});

export const IMeterCompleteAtomFamily = selectorFamily<IMeter, number>({
  key: "IMeterCompleteAtomFamily",
  get: (IMeterID: number) => ({ get }) => {
    let imeter = get(IMeterFamily(IMeterID));

    let latest_event_stream = get(IMeterStreamEventFamily(IMeterID));
    let latest_periodic_stream = get(IMeterStreamPeriodicFamily(IMeterID));
    let latest_config = get(IMeterLatestConfigFamily(IMeterID));

    if (latest_event_stream) {
      imeter = { ...imeter, latest_event_stream };
    }

    if (latest_periodic_stream) {
      imeter = { ...imeter, latest_periodic_stream };
    }

    if (latest_config) {
      imeter = { ...imeter, latest_config };
    }

    return imeter;
  },
});

import React, { useState, createContext } from "react";
import { ReactJSX } from "../../../../../../../utils/types";
import { Pivot } from "../../../../../../../redux/pivots/types";

interface Props {
  pivot: Pivot;
  children: ReactJSX;
}

export const EditPivotContext = createContext({
  name: "",
  setName: null,
  endgun: "",
  setEndgun: null,
  setorial: false,
  setSetorial: null,
  centerLat: "",
  setCenterLat: null,
  centerLng: "",
  setCenterLng: null,
  startRefLat: "",
  setStartRefLat: null,
  startRefLng: "",
  setStartRefLng: null,
  endRefLat: "",
  setEndRefLat: null,
  endRefLng: "",
  setEndRefLng: null,
  centerLatLabel: "",
  setCenterLatLabel: null,
  centerLngLabel: "",
  setCenterLngLabel: null,
  startLatLabel: "",
  setStartLatLabel: null,
  startLngLabel: "",
  setStartLngLabel: null,
  endLatLabel: "",
  setEndLatLabel: null,
  endLngLabel: "",
  setEndLngLabel: null,
  radius: "",
  setRadius: null,
  totalRadius: "",
  setTotalRadius: null,
  area: "",
  setArea: null,
  speed: "",
  setSpeed: null,
  flow: "",
  setFlow: null,
  potency: "",
  setPotency: null,
  pumpTime: "",
  setPumpTime: null,
  powerTime: "",
  setPowerTime: null,
  powerRange: false,
  setPowerRange: null,
  powerRangeMin: "",
  setPowerRangeMin: null,
  powerRangeMax: "",
  setPowerRangeMax: null,
  brandModel: null,
  setBrandModel: null,
  otherBrand: null,
  setOtherBrand: null,
  panelType: null,
  setPanelType: null,
});

export const EditPivotProvider = (props: Props) => {
  const { pivot, children } = props;

  const [name, setName] = useState(pivot.name);

  const [brandModel, setBrandModel] = useState(pivot.brand_model);
  const [otherBrand, setOtherBrand] = useState(pivot.brand_model);
  const [panelType, setPanelType] = useState(pivot.panel_type);

  const [endgun, setEndgun] = useState(String(pivot.config.endgun));
  const [setorial, setSetorial] = useState(pivot.config.setorial);
  const [centerLat, setCenterLat] = useState(pivot.config.center.split(",")[0]);
  const [centerLng, setCenterLng] = useState(pivot.config.center.split(",")[1]);
  const [startRefLat, setStartRefLat] = useState(
    pivot.config.reference.split(",")[0]
  );
  const [startRefLng, setStartRefLng] = useState(
    pivot.config.reference.split(",")[1]
  );
  const [endRefLat, setEndRefLat] = useState(
    pivot.config.end_reference.split(",")[0]
  );
  const [endRefLng, setEndRefLng] = useState(
    pivot.config.end_reference.split(",")[1]
  );
  const [centerLatLabel, setCenterLatLabel] = useState(
    pivot.config.center.split(",")[0].substring(0, 10)
  );
  const [centerLngLabel, setCenterLngLabel] = useState(
    pivot.config.center.split(",")[1].substring(0, 10)
  );
  const [startLatLabel, setStartLatLabel] = useState(
    pivot.config.reference.split(",")[0].substring(0, 10)
  );
  const [startLngLabel, setStartLngLabel] = useState(
    pivot.config.reference.split(",")[1].substring(0, 10)
  );
  const [endLatLabel, setEndLatLabel] = useState(
    pivot.config.end_reference.split(",")[0].substring(0, 10)
  );
  const [endLngLabel, setEndLngLabel] = useState(
    pivot.config.end_reference.split(",")[1].substring(0, 10)
  );
  const [radius, setRadius] = useState(String(pivot.config.radius.toFixed(2)));
  const [totalRadius, setTotalRadius] = useState(
    String(pivot.config.total_radius.toFixed(2))
  );
  const [area, setArea] = useState(String(pivot.config.area.toFixed(2)));
  const [speed, setSpeed] = useState(String(pivot.config.speed.toFixed(2)));
  const [flow, setFlow] = useState(String(pivot.config.flow.toFixed(2)));
  const [potency, setPotency] = useState(String(pivot.potency));
  const [pumpTime, setPumpTime] = useState(String(pivot.config.pump_time));
  const [powerTime, setPowerTime] = useState(String(pivot.config.power_time));
  const [powerRange, setPowerRange] = useState(
    !(pivot.config.power_range_min === 0 && pivot.config.power_range_max === 0)
  );
  const [powerRangeMin, setPowerRangeMin] = useState(
    String(pivot.config.power_range_min)
  );
  const [powerRangeMax, setPowerRangeMax] = useState(
    String(pivot.config.power_range_max)
  );

  return (
    <EditPivotContext.Provider
      value={{
        name,
        setName,
        endgun,
        setEndgun,
        setorial,
        setSetorial,
        centerLat,
        setCenterLat,
        centerLng,
        setCenterLng,
        startRefLat,
        setStartRefLat,
        startRefLng,
        setStartRefLng,
        endRefLat,
        setEndRefLat,
        endRefLng,
        setEndRefLng,
        centerLatLabel,
        setCenterLatLabel,
        centerLngLabel,
        setCenterLngLabel,
        startLatLabel,
        setStartLatLabel,
        startLngLabel,
        setStartLngLabel,
        endLatLabel,
        setEndLatLabel,
        endLngLabel,
        setEndLngLabel,
        radius,
        setRadius,
        totalRadius,
        setTotalRadius,
        area,
        setArea,
        speed,
        setSpeed,
        flow,
        setFlow,
        potency,
        setPotency,
        pumpTime,
        setPumpTime,
        powerTime,
        setPowerTime,
        powerRange,
        setPowerRange,
        powerRangeMin,
        setPowerRangeMin,
        powerRangeMax,
        setPowerRangeMax,
        brandModel,
        setBrandModel,
        otherBrand,
        setOtherBrand,
        panelType,
        setPanelType,
      }}
    >
      {children}
    </EditPivotContext.Provider>
  );
};

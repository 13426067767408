import React, { useState, useEffect } from "react";
import { ChildrenProps } from "../../../../utils/types";
import Drawer from "../MenuDrawer/MenuDrawer";
import { Pivot } from "../../../../redux/pivots/types";
import { Irpd } from "../../../../redux/irpds/types";
import ConnectionActionSheet from "./components/ConnectionActionSheet";
import NotificationsSystem from "./components/NotificationsSystem/NotificationsSystem";
import { useRecoilState } from "recoil";
import { superUserStatus } from "../../../../recoils/SuperUserStatusRecoil";
import { coreHTTPClient } from "../../../../services/webclient";
import MobileNav from "../MobileNav/MobileNav";

export const DrawerContext = React.createContext({
  visible: true,
  selectVisible: false,
  pivots: null,
  irpds: null,
  setPivots: null,
  setIrpds: null,
  meterSystems: null,
  setMeterSystems: null,
  meterSystemID: null,
  setMeterSystemID: null,
  setFarmID: null,
  farmID: null,
  setPivotID: null,
  setPivotNavigate: null,
  setIrpdID: null,
  pivotID: null,
  pivotNavigate: null,
  irpdID: null,
  historic: false,
  setHistoric: null,
  setHistoricTable: null,
  historicTable: null,
  inicitialOffset: false,
  setInicitialOffset: (flag: boolean) => {},
  inicitialPivotId: false,
  setInicitialIrpdId: (flag: boolean) => {},
  inicitialCheck: false,
  setInicitialCheck: (flag: boolean) => {},
  inicitialModels: false,
  setInicitialModels: (flag: boolean) => {},
  openSelect: () => {},
  closeSelect: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
  toggleDrawer: () => {},
});

export const DrawerProvider = (props: ChildrenProps) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectVisible, setSelectVisible] = useState(false);

  //Estados para selecioanar o pivô pelo componente na NavBar
  const [pivots, setPivots] = useState<Pivot[]>();
  const [farmID, setFarmID] = useState();
  const [pivotID, setPivotID] = useState();
  const [pivotNavigate, setPivotNavigate] = useState(true);

  //Estados para selecioanar o irpd pelo componente na NavBar
  const [irpds, setIrpds] = useState<Irpd[]>();
  const [irpdID, setIrpdID] = useState();

  //Estados par selecionar o metersystem pelo componente na NavBar
  const [meterSystems, setMeterSystems] = useState<Irpd[]>();
  const [meterSystemID, setMeterSystemID] = useState();

  //Estados para alterar o historicPivotOLD quando mudar o swith
  const [historicTable, setHistoricTable] = useState();
  const [historic, setHistoric] = useState(false);

  //Inicializadores para não bater nas requisições várias vezes
  const [inicitialOffset, setInicitialOffset] = useState(false);
  const [inicitialPivotId, setInicitialPivotId] = useState(false);
  const [inicitialCheck, setInicitialCheck] = useState(false);
  const [inicitialModels, setInicitialModels] = useState(false);

  // Recoil para checar se o usuário tem privilégios de superUser
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);
  const checkSUStatus = async () => {
    try {
      const superUserStatus = await coreHTTPClient.get("/v3/auth/check_su/");
      if (superUserStatus.data === true) {
        setSuperUser(true);
      } else {
        setSuperUser(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    checkSUStatus();
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        visible: drawerVisible,
        selectVisible: selectVisible,
        pivots: pivots,
        pivotID: pivotID,
        pivotNavigate: pivotNavigate,
        irpds,
        irpdID,
        meterSystems: meterSystems,
        setMeterSystems: setMeterSystems,
        meterSystemID: meterSystemID,
        setMeterSystemID: setMeterSystemID,
        farmID: farmID,
        historicTable: historicTable,
        historic: historic,
        setFarmID: setFarmID,
        setPivots: setPivots,
        setPivotID: setPivotID,
        setPivotNavigate: setPivotNavigate,
        setIrpds,
        setIrpdID,
        setHistoricTable: setHistoricTable,
        setHistoric: setHistoric,
        inicitialOffset: inicitialOffset,
        setInicitialOffset: (flag: boolean) => setInicitialOffset(flag),
        inicitialPivotId: inicitialPivotId,
        setInicitialIrpdId: (flag: boolean) => setInicitialPivotId(flag),
        inicitialCheck: inicitialCheck,
        setInicitialCheck: (flag: boolean) => setInicitialCheck(flag),
        inicitialModels: inicitialModels,
        setInicitialModels: (flag: boolean) => setInicitialModels(flag),
        openSelect: () => setSelectVisible(true),
        closeSelect: () => setSelectVisible(false),
        openDrawer: () => setDrawerVisible(true),
        closeDrawer: () => setDrawerVisible(false),
        toggleDrawer: () => setDrawerVisible(!drawerVisible),
      }}
    >
      <NotificationsSystem />
      <ConnectionActionSheet />
      <MobileNav />
      <Drawer />
      {props.children}
    </DrawerContext.Provider>
  );
};

import React from "react";
import moment from "moment";

/**
 * Generics
 */

export type Primitive =
  | string
  | boolean
  | number
  | bigint
  | symbol
  | null
  | undefined
  | any;

export type ReactJSX = JSX.Element[] | JSX.Element;

export interface ChildrenProps {
  children: ReactJSX;
}

export const NoneReactJSX = (): ReactJSX => {
  return React.createElement(React.Fragment);
};

export interface GenericObject {
  [key: string]: any;
}

/**
 *  Arrays
 */

export const arrUpdateOrInsert = <M>(
  array: M[],
  payload: M,
  keyCompare: string
): M[] => {
  const newList = array.slice();

  var index = newList.findIndex(
    (item) =>
      (item as GenericObject)[keyCompare] ===
      (payload as GenericObject)[keyCompare]
  );

  if (index !== -1) {
    newList[index] = payload;
  } else {
    newList.push(payload);
  }

  return newList;
};

export const arrDelete = <M>(
  array: M[],
  payload: M,
  keyCompare: string
): M[] => {
  var newList = array;

  newList = newList.filter(
    (item) =>
      (item as GenericObject)[keyCompare] !==
      (payload as GenericObject)[keyCompare]
  );

  return newList;
};

export const arrReverse = (arr: any[]) => {
  let value = [...arr];
  value.reverse();
  return value;
};

export const arrRemoveIndex = (arr: any[], index: number) => {
  const newArr = [...arr];
  newArr.splice(index, 1);
  return newArr;
};

export const arrAreEqualLength = (arr: any[][]): boolean => {
  const length = arr[0].length;
  let equal = true;

  arr.forEach((__array) => {
    if (__array.length !== length) equal = false;
  });

  return equal;
};

export const arrCompare = (a: any[], b: any[]) => {
  // if length is not equal
  if (a.length !== b.length) return false;
  else {
    // comapring each element of array
    for (var i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
    return true;
  }
};

/**
 * Objects
 */

export const objPatch = (root: any, newObject: any) => {
  let patch: any = {};

  Object.keys(newObject).forEach((key) => {
    if (Array.isArray(newObject[key])) {
      if (!arrCompare(newObject[key], root[key])) patch[key] = newObject[key];
    } else if (newObject[key] !== root[key]) patch[key] = newObject[key];
  });

  return patch;
};

export function objIsEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

/**
 *  Numbers
 */

export function numPad(n: number, zerosBefore: number) {
  let zerosString = "";

  for (var i = 0; i < zerosBefore; i++) {
    zerosString += "0";
  }

  return (zerosString + n).slice(-zerosBefore);
}

/**
 * Dates
 */

export const dateGetLatest = (buffer: string[]): moment.Moment => {
  let latestResult: moment.Moment = moment(buffer[0]);

  buffer.forEach((date) => {
    const element = moment(date);
    const diff = element.diff(latestResult, "seconds");

    if (diff > 0) latestResult = element;
  });

  return latestResult;
};

export const dateHumanized = (ISODate: string): string => {
  return moment
    .duration(moment().diff(moment(ISODate), "seconds", true), "seconds")
    .humanize();
};

// Tipos do Mapa
export interface PivotShape {
  type: "PivotShape";
  circle: google.maps.Circle;
  line: google.maps.Polyline;
  semiCircle: google.maps.Polygon[];
  triangle: google.maps.Polygon;

  sector?: google.maps.Polygon;
  arrow?: google.maps.Polyline;
  dashedLine?: google.maps.Polyline;
}

export interface BauerPivotShape {
  type: "BauerPivotShape";
  circle: google.maps.Circle;
  line: google.maps.Polyline;
  semiCircle: google.maps.Polygon[];
  triangle: google.maps.Polygon;

  sector?: google.maps.Polygon;
  arrow?: google.maps.Polyline;
  // dashedLine?: google.maps.Polyline;
}

export interface AngleIrrigationShape {
  type: "AngleIrrigationShape";
  mainLine?: google.maps.Polyline;
  pivotArc?: google.maps.Polygon[];
  markLines?: google.maps.Polyline[];
  circle?: google.maps.Circle;
}
export interface IrpdShape {
  type: "IrpdShape";
  circle: google.maps.Circle;
}

export interface MeterSystemShape {
  type: "MeterSystemShape";
  circle: google.maps.Circle;
}

export interface LinearPivotShape {
  type: "LinearPivotShape";
  line: google.maps.Polyline;
  circle: google.maps.Circle;
  rectangles: google.maps.Polygon[];
  arrow: google.maps.Polygon;
}
export interface RepeaterShape {
  type: "RepeaterShape";
  circle: google.maps.Circle;
}

export type MapShape =
  | PivotShape
  | BauerPivotShape
  | IrpdShape
  | MeterSystemShape
  | LinearPivotShape
  | RepeaterShape;

export function instanceOfBauerPivotShape(
  object: MapShape
): object is BauerPivotShape {
  return object.type === "BauerPivotShape";
}

export function instanceOfPivotShape(object: MapShape): object is PivotShape {
  return object.type === "PivotShape";
}

export function instanceOfIrpdShape(object: MapShape): object is IrpdShape {
  return object.type === "IrpdShape";
}

export function instanceOfMeterSystemShape(
  object: MapShape
): object is IrpdShape {
  return object.type === "MeterSystemShape";
}

export function instanceOfLinearPivotShapeShape(
  object: MapShape
): object is LinearPivotShape {
  return object.type === "LinearPivotShape";
}

export function instanceOfRepeaterShape(
  object: MapShape
): object is RepeaterShape {
  return object.type === "RepeaterShape";
}

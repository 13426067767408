import React from "react";
import { IrpdAction } from "../../../../../../../../../../../redux/irpds/types";
import { expandDateActionnArgs } from "../../../utils";
import { ReactJSX } from "../../../../../../../../../../../utils/types";
import { i18n } from "../../../../../../../../../../../i18n/i18nText";

interface Props {
  irpdAction: IrpdAction;
}

function ExpandedIrpdAction(props: Props) {
  const { irpdAction } = props;

  const dateStartIsNull: ReactJSX =
    irpdAction.start === null ? (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_START_AT")}
        <b>{i18n("VALUE", [i18n("HISTORIC_IRPD_ACTION_NOW")])}</b>
      </span>
    ) : (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_START_AT")}
        <b>{i18n("VALUE", [...expandDateActionnArgs(irpdAction.start, 3)])}</b>
      </span>
    );

  const dateEndIsNull: ReactJSX =
    irpdAction.end === null ? (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_END_AT")}
        <b>{i18n("VALUE", [i18n("HISTORIC_IRPD_ACTION_NEVER")])}</b>
      </span>
    ) : (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_END_AT")}
        <b>{i18n("VALUE", [...expandDateActionnArgs(irpdAction.end, 3)])}</b>
      </span>
    );

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_IRPD_ACTION_USER")}
          <b>{i18n("VALUE", [`${irpdAction.username}`])}</b>
        </span>
      </div>
      <div className="expanded-content__group">
        {dateStartIsNull}
        {dateEndIsNull}
      </div>
    </div>
  );
}

export default ExpandedIrpdAction;

import React, { useState, useEffect } from "react";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import "./Snack.scss";
import {
  getDeviceNotch,
  setSnackBarProviderRerenderFunction,
} from "../../../utils/DeviceNotchCheck";

interface Props {
  children: any;
}

// add action to all snackbars
const notistackRef = React.createRef<any>();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const SnackProvider = (props: Props) => {
  let [_, setState] = useState(0);

  useEffect(() => {
    setSnackBarProviderRerenderFunction(() => setState((x) => ++x));
  }, []);

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        root: `snackRoot snackRoot__notch-${getDeviceNotch()}`,
      }}
      hideIconVariant
      action={(key) => <CloseIcon onClick={onClickDismiss(key)} />}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default SnackProvider;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormInput from "../../../../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../../../../hooks/tools/useFormValidation";
import {
  checkIsNotNumber,
  checkIsOnIntervalString,
} from "../../../../../../../../../utils/checks";
import FormSelect from "../../../../../../../../../components/Form/FormSelect/FormSelect";
import countries from "../../../../../../../../../utils/consts/countries";
import { handleNumberMask } from "../../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { isMobile } from "../../../../../../../../../mobileConfig";
import axios from "axios";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../../i18n/i18nText";

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  phone: [string, (value: string) => void];
  IDD: any;
  postalCode: [string, (value: string) => void];
  address: [string, (value: string) => void];
  city: [string, (value: string) => void];
  state: [string, (value: string) => void];
  country: [string, (value: string) => void];
}

export default function Contact(props: Props) {
  const {
    validationErrors,
    formValidationActions,
    phone,
    IDD,
    postalCode,
    address,
    city,
    state,
    country,
  } = props;

  const notify = useNotify();

  // contact phone
  const setValidPhone = useCallback(
    (value) => {
      let updatedPhone = handleNumberMask(value);

      if (updatedPhone.length > 11) {
        updatedPhone = updatedPhone.slice(0, 11);
      }

      updatedPhone = updatedPhone.replace(
        /^(\d{2})(\d{4,5})(\d{4})$/,
        "($1) $2-$3"
      );

      phone[1](updatedPhone);

      if (
        updatedPhone === "" ||
        !/^\(\d{2}\) \d{4,5}-\d{4}$/.test(updatedPhone) ||
        /^(\d)\1+$/.test(updatedPhone.replace(/\D/g, ""))
      ) {
        formValidationActions.setError("phone", "EDIT_FARM_ERROR_PHONE");
      } else {
        formValidationActions.clearError("phone");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // contact postal code
  const setValidPostalCode = useCallback(
    (value) => {
      let trimValue = handleNumberMask(value).trimLeft();

      if (trimValue.length > 8) {
        trimValue = trimValue.slice(0, 8);
      }
      postalCode[1](trimValue);

      formValidationActions.setError(
        "postalCode",
        checkIsOnIntervalString(value, 8, 8)
      );

      const isAllZeros = /^0+$/.test(trimValue);

      const isAllSameDigit = /^(\d)\1+$/.test(trimValue);

      if (isAllZeros || isAllSameDigit) {
        formValidationActions.setError(
          "postalCode",
          "VALIDATION_FIELD_INVALID"
        );
      } else {
        formValidationActions.clearError("postalCode");
        formValidationActions.setError(
          "postalCode",
          checkIsOnIntervalString(value, 8, 8)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // contact address
  const setValidAddress = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      address[1](trimValue);

      formValidationActions.setError(
        "address",
        checkIsOnIntervalString(value, 3, 60)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // contact city
  const setValidCity = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        city[1](trimValue);

        formValidationActions.setError(
          "city",
          checkIsOnIntervalString(value, 3, 60)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // contact state
  const setValidState = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        state[1](trimValue);

        formValidationActions.setError(
          "state",
          checkIsOnIntervalString(value, 2, 36)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // contact country
  const setValidCountry = useCallback((value) => country[1](value), []);

  const countryValues: [string, string][] = useMemo(
    () => countries.map((country) => [country.iso, country.content]),
    []
  );

  function getPostalCodeInfo() {
    if (postalCode[0]?.length == 8) {
      axios
        .get(`https://viacep.com.br/ws/${postalCode[0]}/json/`)
        .then(function (response) {
          if (response.status == 200 && !response.data.erro) {
            setValidAddress(
              `${response.data.logradouro} - ${response.data.bairro}`
            );
            setValidCity(response.data.localidade);
            setValidState(response.data.uf);
          } else {
            notify("ERROR_ZIP_CODE", "error");
            setValidAddress("");
            setValidCity("");
            setValidState("");
          }
        });
    }
  }

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <h3 style={{ marginTop: "10px", marginBottom: "0px" }}>
        {i18n("FARM_CONTACT_INFORMATION_TITLE")}
      </h3>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-phone"
          inputMode="numeric"
          startAdornment={IDD}
          helperText={validationErrors["phone"]}
          label={"FARM_FIELD_PHONE"}
          state={[phone[0], setValidPhone]}
        />
      </div>

      <h3 style={{ marginTop: "40px", marginBottom: "0px" }}>
        {i18n("FARM_CONTACT_ADDRESS_TITLE")}
      </h3>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-postal-code"
          inputMode="numeric"
          helperText={validationErrors["postalCode"]}
          label={"FARM_CONTACT_FIELD_POSTAL_CODE"}
          state={[postalCode[0], setValidPostalCode]}
          onBlur={getPostalCodeInfo}
        />

        <FormInput
          id="farm-address"
          helperText={validationErrors["address"]}
          label={"FARM_FIELD_ADDRESS"}
          state={[address[0], setValidAddress]}
        />
      </div>

      <div className="general-form__row-4-4-4">
        <FormInput
          id="farm-city"
          helperText={validationErrors["city"]}
          label={"FARM_FIELD_CITY"}
          state={[city[0], setValidCity]}
        />

        <FormInput
          id="farm-state"
          helperText={validationErrors["state"]}
          label={"FARM_FIELD_STATE"}
          state={[state[0], setValidState]}
        />

        <FormSelect
          id="farm-country"
          values={countryValues}
          label={"FARM_FIELD_COUNTRY"}
          state={[country[0], setValidCountry]}
        />
      </div>
    </div>
  );
}

import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { IMeterCompleteAtomFamily } from "../../../../../../../../recoils/MeterSystemRecoil";
import InfoBox from "../../../../../../Pivot/SelectedPivot/components/BasicInfoBox/components/InfoBox/InfoBox";
import WavesIcon from "@material-ui/icons/Waves";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 12fr;
  row-gap: 15px;
  justify-content: center;
  padding-top: 60px;
`;

interface Props {
  iMeterID: number;
}

export default function WaterLevelCharts(props: Props) {
  const imeter = useRecoilValue(IMeterCompleteAtomFamily(props.iMeterID));

  return (
    <GridContainer>
      <InfoBox centerElement={<>{i18n("METERSYSTEM_FLOW_CURVE")}</>}>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WavesIcon style={{ color: "#B2D4E6", fontSize: "150px" }} />
        </div>

        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <span style={{ marginLeft: "5px", fontSize: "1.5em" }}>
            <strong>
              {formatFloatValue(
                imeter.latest_config?.flow_curve_equation[0] *
                  (imeter.latest_periodic_stream.content
                    .imanage_sensor_measure_value?.[0].value /
                    100) **
                    2 +
                  imeter.latest_config?.flow_curve_equation[1] *
                    (imeter.latest_periodic_stream.content
                      .imanage_sensor_measure_value?.[0].value /
                      100) +
                  imeter.latest_config?.flow_curve_equation[2]
              )}
            </strong>{" "}
            m³/h
          </span>
        </span>
      </InfoBox>
    </GridContainer>
  );
}

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, {
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GREEN_COLORS_TABLE,
  ZOOM_TABLE,
} from "../../pages/Dashboard/Pivot/utils/utils";
import { Pivot } from "../../redux/pivots/types";
import { isOldProtocol, isV5Protocol } from "../../utils/models/pivots";
import {
  DrawArcBackward,
  DrawArcFoward,
  DrawCircle,
  DrawLine,
  writeOnCanvas,
} from "./components/Canvas";
import "./GeneralAngleSelector.scss";

import styled from "@emotion/styled";
import { RotateLeft, RotateRight } from "@material-ui/icons";
import { i18n, i18nTextId } from "../../i18n/i18nText";
import FormInput from "../Form/FormInput/FormInput";
import FormInputIrrigation from "../Form/FormInputIrrigation/FormInputIrrigation";
import AngleLabels from "./components/AngleLabels";
import FormDateTimePicker from "../Form/FormDateTimePicker/FormDateTimePicker";
import { isMobile } from "../../mobileConfig";

interface Props {
  pivot?: Pivot;
  label: i18nTextId; // Label utilizando i18n novo
  hasNames?: boolean; // Caso além das faixas de ângulo seja necessário atribuir nome as faixas
  angleDirection?: boolean; // Direção avanço ou reverso, sendo avanço (true) e reverso (false)
  startOnReference?: boolean; // Existem dois casos: o caso que a primeira faixa deve começar onde o pivô está ou começar na referência do pivô
  setShowLastAngle?: (value: boolean) => void; // Único atributo que é específico do componente usado para configurar segmentos, previne flicks na tabela mostrada no formulário de segmentos na configuração do pivô
  sectorLength?: number; // Caso o pivô não seja de 0...360 essa variável indica até qual ângulo ele pivô é capaz de atingir
  anglesState: [any[], any]; // [state, setState] referente ao resultado da seleção de faixas realizadas
  namesState?: [any[], any]; // [state, setState] referente ao resultado do nome de cada faixa realizadas
  maxLength?: number; // Define quantas faixas serão possíveis de serem criadas
  centerPosition?: string[]; // If is not an Irricontrol Pivot
  gpsPosition?: string[]; // If is not an Irricontrol Pivot
  referenceAngle?: number; // If is not an Irricontrol Pivot
  referenceRadius?: number; // If is not an Irricontrol Pivot
  angleStart?: number;
}

const Row = styled.div`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.hasNames ? "1fr 1fr" : "1fr"};
}
  grid-gap: 10px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: ${(props: any) =>
      props.hasNames ? "1fr 1fr" : "1fr"};
  }
`;

const StyledH4 = styled.h4`
  margin: 2;
  text-align: left;
`;

export interface TableRow {
  direction: number;
  speed: number;
  endgun: number;
  start_angle: number;
  end_angle: number;
}

// let angle;

function GeneralAngleSelector(props: Props) {
  const {
    label,
    anglesState,
    hasNames,
    angleDirection,
    setShowLastAngle,
    startOnReference,
    sectorLength,
    maxLength,
    pivot,
  } = props;

  const plusIcon = require("../../assets/images/plus.svg");

  const trashCan = require("../../assets/images/redTrashCan.png");

  const startAngleDraw = props.angleStart || 0;

  // DIRECTION ARRAYS
  let [direction, setDirection] = useState<string[]>(
    // O array de direção atualmente sempre é todas as faixas em avanço ou todas em reverso para generalizar o uso desse componente
    new Array(anglesState[0].length).fill(angleDirection === true ? "0" : "1")
  );

  //ANGLE END
  const [angleEnd, setAngleEnd] = useState<string[]>(
    // Caso tenha que atribuir nomes as faixas realizamos de forma diferente a inicialização dessa variável
    !hasNames
      ? anglesState[0].map((angle) => {
          return `${angle}`;
        })
      : // TODO: se tiver hasNames faz esse, se não tiver faz o de cima normal
        anglesState[0].map((item) => {
          return `${item?.angle}`;
        })
  );

  const [localLatestGPSStream, setLocalLatestGPSStream] = useState(undefined);

  const isBauerPivot = props.centerPosition?.length > 0 ? true : false;

  // Responsável por mostrar as bolinhas de cada segmento com as suas devidas cores
  const [fillets, setFillets] = useState(
    anglesState[0].map((_, index) => {
      return GREEN_COLORS_TABLE[index];
    })
  );

  // Responsável por desenhar cada linha branca que divide os segmentos
  const [canvasWhiteLines, setCanvasWhiteLines] = useState<
    RefObject<HTMLCanvasElement>[]
  >(
    anglesState[0].map(() => {
      return createRef<HTMLCanvasElement>();
    })
  );

  // Responsável por desenhar cada arco dentro do pivô (a pŕopria faixa em si)
  const [canvasArcs, setCanvasArcs] = useState<RefObject<HTMLCanvasElement>[]>(
    anglesState[0].map(() => {
      return createRef<HTMLCanvasElement>();
    })
  );

  // Responsável por desenhar o arco vermelho no qual o usuário não pode criar faixas de ângulos
  const [canvaRedArc, setCanvaRedArc] = useState<RefObject<HTMLCanvasElement>>(
    createRef<HTMLCanvasElement>()
  );

  // Desenho do canva que contorna o pivô com uma linha branca e mostra alguns "ticks" com os ângulos
  const firstCanva = useRef(null);

  let gpsPosition = props.gpsPosition;
  let centerPosition = props.centerPosition;
  if (pivot) {
    if (isOldProtocol(pivot)) {
      centerPosition = pivot.config.center.split(",");
      gpsPosition = pivot.latest_gps_stream.position.split(",");
    } else if (isV5Protocol(pivot)) {
      centerPosition = [
        pivot.controllerconfig.content.pivot_positions.latitude_center.toString(),
        pivot.controllerconfig.content.pivot_positions.longitude_center.toString(),
      ];

      gpsPosition = startOnReference
        ? [
            pivot.controllerconfig.content.pivot_positions.latitude_reference.toString(),
            pivot.controllerconfig.content.pivot_positions.longitude_reference.toString(),
          ]
        : [
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
          ];
    }
  }

  let gpsPositionGMaps = new google.maps.LatLng(
    parseFloat(gpsPosition[0]),
    parseFloat(gpsPosition[1])
  );

  let centerPositionGMaps = new google.maps.LatLng(
    parseFloat(centerPosition[0]),
    parseFloat(centerPosition[1])
  );

  //Posição do pivo
  let angleGps = isBauerPivot
    ? startAngleDraw + props.referenceAngle
    : google.maps.geometry.spherical.computeHeading(
        centerPositionGMaps,
        gpsPositionGMaps
      );

  //Angulo de referencia
  let referenceAngle = pivot ? pivot.reference_angle : props.referenceAngle;
  // let referenceAngle = 0;

  let gpsAngleWithReference: number =
    angleGps > 0
      ? Math.round(angleGps - referenceAngle)
      : Math.round(angleGps + 360 - referenceAngle);

  gpsAngleWithReference =
    gpsAngleWithReference > 360
      ? gpsAngleWithReference - 360
      : gpsAngleWithReference < 0
      ? gpsAngleWithReference + 360
      : gpsAngleWithReference;

  // Desenhando de vermelho a faixa na qual o usuário não pode configurar os ângulos
  useEffect(() => {
    drawRedArc(0, parseInt(`${sectorLength}`), canvaRedArc);
  }, [sectorLength]);

  const [currentIndex, setCurrentIndex] = useState(
    anglesState[0]?.length - 1 ? anglesState[0].length - 1 : 0
  );

  function drawRedArc(
    strokeSize: number,
    sectorLength: number = 360,
    arcCanva: any
  ) {
    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();

    DrawArcFoward(
      contextArc,
      contextArc.canvas.height / 2,
      contextArc.canvas.width / 2,
      referenceRadius * conversionRatio,
      ((-360 + angleGps - 90 + sectorLength) * Math.PI) / 180, //parte do
      ((angleGps - 90) * Math.PI) / 180,
      sectorLength == 360 ? "transparent" : "#db1c0ec7",
      strokeSize
    );
    contextArc.fill();
    contextArc.restore();
  }

  // Função responsável por desenhar cada faixa de ângulo
  function handleMouseMove(
    canva: any,
    arcCanva: any,
    filletsMove: any,
    radius: number,
    startAngle: number,
    newAngle?: any,
    e?: any,
    index?: number
  ) {
    if (canva?.current == null) return;
    let context = canva.current.getContext("2d");
    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y, angle;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((referenceAngle * Math.PI) / 180);
    refX = radius * Math.sin((referenceAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field
      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
      angle = Math.round(angle);
    }

    // Prevent overlapping
    if (angle < parseInt(anglesState[0][index - 1]?.angle)) {
      if (startAngleDraw == 0) {
        angle = 360;
      } else {
        angle = startAngleDraw;
      }
    }

    let newY = radius * Math.cos(((referenceAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((referenceAngle + angle) * Math.PI) / 180);

    //Set the newAngle as EndAngle
    if (newAngle === undefined) {
      const newEndAngle = Math.round(angle);
      //direction é o sentido em que o pivot será movimentado
      //1 = horário e 0 = anti-horário
      for (
        let endAngleIndex = angleEnd.length - 1;
        endAngleIndex > currentIndex;
        endAngleIndex--
      ) {
        if (newEndAngle >= parseInt(angleEnd[endAngleIndex])) {
          setAngleEnd((prevAngleEnd) => {
            prevAngleEnd.splice(
              currentIndex,
              endAngleIndex,
              newEndAngle.toString()
            );
            return [...prevAngleEnd];
          });
          deleteFillet(currentIndex, endAngleIndex - 1);
          return;
        }
      }
      setAngleEnd((prevAngleEnd) => {
        prevAngleEnd.splice(currentIndex, 1, newEndAngle.toString());
        return [...prevAngleEnd];
      });
    }

    //Desenha uma nova linha branca
    DrawLine(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      context,
      canva.current.width / 2 + newX / 2,
      canva.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      context,
      canva.current.width / 2 + newX / 2 - 10,
      canva.current.height / 2 - newY / 2 + 5,
      angle + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );

    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();
    // contextArc.beginPath();

    DrawArcFoward(
      contextArc,
      contextArc.canvas.height / 2,
      contextArc.canvas.width / 2,
      referenceRadius * conversionRatio,
      ((startAngle + referenceAngle - 90) * Math.PI) / 180, //parte do
      ((angle + referenceAngle - 90) * Math.PI) / 180,
      filletsMove,
      0
    );

    contextArc.fill();
    contextArc.restore();
  }

  // Muda direção do pivô (avanço | reverso)
  const handleDirection = (
    event: React.MouseEvent<HTMLElement>,
    newDirection: string | null
  ) => {
    if (newDirection) {
      setAngleEnd(["0"]);
      setFillets(["#cc651b"]);
      anglesState[1]([]);
      setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);
      firstCanva.current = null;
      setDirection([newDirection]);
    }
  };

  let center, reference;
  if (pivot) {
    gpsPosition = [
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
    ];
    center = new google.maps.LatLng(
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.latitude_center.toString()
          : pivot.config.center.substring(0, pivot.config.center.indexOf(","))
      ),
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.longitude_center.toString()
          : pivot.config.center.substring(
              pivot.config.center.indexOf(",") + 1,
              pivot.config.center.length
            )
      )
    );

    reference = new google.maps.LatLng(
      pivot.protocol === 5
        ? pivot.controllerconfig.content.pivot_positions.latitude_reference.toString()
        : parseFloat(
            pivot.config.reference.substring(
              0,
              pivot.config.reference.indexOf(",")
            )
          ),
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.longitude_reference.toString()
          : pivot.config.reference.substring(
              pivot.config.reference.indexOf(",") + 1,
              pivot.config.reference.length
            )
      )
    );
  } else {
    //BAUER PIVOT
    center = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );
    reference = new google.maps.LatLng(
      parseFloat(gpsPosition[0]),
      parseFloat(gpsPosition[1])
    );
  }

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;
  let conversionRatio = 0;
  let conversionRatioReferenceLines = 0;
  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
      conversionRatio = ZOOM_TABLE[i].conversionRatio;
      conversionRatioReferenceLines =
        ZOOM_TABLE[i].conversionRatioReferenceLines;
    }
  }

  const setSegmentName = (value) => {
    anglesState[1]((item) => {
      var newItem = [...item];
      newItem[currentIndex] = {
        ...newItem[currentIndex],
        name: value,
      };
      return newItem;
    });
  };

  const setSegmentType = (value) => {
    anglesState[1]((item) => {
      var newItem = [...item];
      newItem[currentIndex] = {
        ...newItem[currentIndex],
        segment_type: value,
      };
      return newItem;
    });
  };

  const setSegmentCropPlantDate = (value) => {
    anglesState[1]((item) => {
      var newItem = [...item];
      newItem[currentIndex] = {
        ...newItem[currentIndex],
        crop_plant_date: value,
      };
      return newItem;
    });
  };

  const setSegmentCropHarvestDate = (value) => {
    anglesState[1]((item) => {
      var newItem = [...item];
      newItem[currentIndex] = {
        ...newItem[currentIndex],
        crop_harvest_date: value,
      };
      return newItem;
    });
  };

  const deleteFillet = (index: number, deletingCount: number) => {
    fillets.splice(index, deletingCount);
    setFillets([...fillets]);
    canvasWhiteLines.splice(index, deletingCount);
    setCanvasWhiteLines([...canvasWhiteLines]);
    canvasArcs.splice(index, deletingCount);
    setCanvasArcs([...canvasArcs]);
  };

  const circleDiameter = referenceRadius * conversionRatio * 2;

  // Usando o angleEnd de base setamos o estado que será usado fora do componente (estado que possuí as faixas)
  useEffect(() => {
    // Caso tenha que atribuir nomes as faixas realzamos de forma diferente a inicialização dessa variável
    !hasNames
      ? anglesState[1](
          angleEnd.map((angle, index) => {
            if (
              index === angleEnd.length - 1 &&
              (angle === "0" || angle === "" || angleEnd.length - 1 === 0)
            ) {
              return 0;
            }
            return angle;
          })
        )
      : // TODO: se tiver hasNames faz esse, se não tiver faz o de cima normal
        anglesState[1](
          angleEnd.map((angle, index) => {
            return {
              angle,
              name: anglesState[0][index]?.name
                ? anglesState[0][index]?.name
                : `Segmento ${index + 1}`,
              segment_type: anglesState[0][index]?.segment_type
                ? anglesState[0][index].segment_type
                : "",
              crop_plant_date: anglesState[0][index]?.crop_plant_date
                ? anglesState[0][index]?.crop_plant_date
                : null,
              crop_harvest_date: anglesState[0][index]?.crop_harvest_date
                ? anglesState[0][index]?.crop_harvest_date
                : null,
              fwd: anglesState[0][index]?.fwd ?? 10,
              rev: anglesState[0][index]?.rev ?? 10,
            };
          })
        );
  }, [JSON.stringify(angleEnd), sectorLength, hasNames]);

  // Desenhando as faixas ao renderizar o componente
  useEffect(() => {
    let sameLocation = localLatestGPSStream;
    setLocalLatestGPSStream(undefined);
    setLocalLatestGPSStream(sameLocation);
    for (let index = 0; index < anglesState[0].length; index++) {
      handleMouseMove(
        canvasWhiteLines[index],
        canvasArcs[index],
        GREEN_COLORS_TABLE[index],
        referenceRadius * conversionRatio,
        angleEnd[index - 1] ? parseInt(angleEnd[index - 1]) : -1,
        angleEnd[index],
        undefined,
        index
      );
    }
  }, [JSON.stringify(anglesState[0]), sectorLength]);

  // Desenho inicial das faixas
  useEffect(() => {
    let context = firstCanva.current?.getContext("2d");

    //Desenha circulo que representa alcance do pivot
    context.beginPath();
    context.arc(
      firstCanva.current.width / 2,
      firstCanva.current.height / 2,
      referenceRadius * conversionRatio,
      0,
      2 * Math.PI
    );
    context.strokeStyle = "white";
    context.stroke();

    //Desenha as linhas brancas ao redor do círculo
    for (let i = 0; i < 72; i++) {
      context.save();
      let ang = (i * 5 * Math.PI) / 180;
      context.translate(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2
      );
      context.rotate(ang);
      context.fillStyle = "white";
      context.textBaseline = "middle";
      context.textAlign = "center";
      context.beginPath();
      context.moveTo(0, referenceRadius * conversionRatioReferenceLines);
      context.lineTo(0, referenceRadius * conversionRatio);
      context.strokeStyle = "white";
      context.stroke();
      //back to checkpoint
      context.restore();
    }

    // Translate the context to the point we want to rotate around
    context.translate(
      firstCanva.current.width / 2,
      firstCanva.current.height / 2
    );

    // Perform the rotation
    context.rotate((referenceAngle * Math.PI) / 180);

    let pivotPositionY =
      referenceRadius *
      conversionRatio *
      Math.cos(((angleGps - referenceAngle) * Math.PI) / 180);
    let pivotPositionX =
      referenceRadius *
      conversionRatio *
      Math.sin(((angleGps - referenceAngle) * Math.PI) / 180);

    //Linha preta (posição atual do pivot)
    DrawLine(context, 0, 0, pivotPositionX, pivotPositionY, "black");
    DrawLine(context, 0, 0, 0, referenceRadius * conversionRatio, "black");

    //back to checkpoint
    context.restore();
    DrawCircle(
      context,
      firstCanva.current.width / 2,
      firstCanva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );
    let contextWhiteLines = canvasWhiteLines[
      canvasWhiteLines.length - 1
    ]?.current?.getContext("2d");

    //create canvas checkpoint with default translate and rotate
    contextWhiteLines.save();

    //Translate full canvas to it's center
    contextWhiteLines.translate(
      canvasWhiteLines[canvasWhiteLines.length - 1].current.width / 2,
      canvasWhiteLines[canvasWhiteLines.length - 1].current.height / 2
    );

    //Rotate canvas by reference_angle arround it's center
    contextWhiteLines.rotate((referenceAngle * Math.PI) / 180);

    //Desenha o circulo central preto
    DrawCircle(contextWhiteLines, 0, 0, 5, 0, 2 * Math.PI, "black");

    //rotate and translate back to checkpoint
    contextWhiteLines.restore();
  }, []);

  return (
    <>
      {angleDirection === undefined && (
        <div>
          <ToggleButtonGroup
            value={direction[direction.length - 1].toString()}
            exclusive
            onChange={handleDirection}
            aria-label="direction"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton value="1" aria-label="Reverse" style={{ width: 140 }}>
              <RotateLeft />

              {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
            </ToggleButton>

            <ToggleButton
              value="0"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <RotateRight />

              {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      <StyledH4>{i18n("NEW_SEGMENT")}</StyledH4>
      {/* IMAGEM DO MAPA COM CANVAS */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isMobile() ? "1fr" : "1fr 2fr",
        }}
      >
        <div>
          <div
            onTouchStart={() => {
              if (setShowLastAngle !== undefined) {
                setShowLastAngle(false);
              }
            }}
            onTouchEnd={() => {
              if (setShowLastAngle !== undefined) {
                setShowLastAngle(true);
              }
            }}
            style={{
              userSelect: "none",
              WebkitTouchCallout: "none" /* iOS Safari */,
              WebkitUserSelect: "none" /* Safari */,
              KhtmlUserSelect: "none" /* Konqueror HTML */,
              MozUserSelect: "none" /* Old versions of Firefox */,
              msUserSelect: "none" /* Internet Explorer/Edge */,
              width: window.innerWidth > 800 ? "400px" : "300px",
              height: "300px",
              display: "flex",
              alignSelf: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AngleLabels
              referenceAngle={referenceAngle}
              circleDiameter={circleDiameter}
              endAngle={360} // Fix para pivo setorial em V5
            />

            <canvas
              id="GeneralAngleSelector"
              ref={firstCanva}
              width={300}
              height={300}
              style={{ position: "absolute", zIndex: 900 }}
            />

            {canvasArcs.map((canva, index) => {
              return (
                <canvas
                  key={"canvaArc_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 820 - index,
                  }}
                />
              );
            })}

            <canvas
              id={"red_canva"}
              key={"red_canva"}
              ref={canvaRedArc}
              width={300}
              height={300}
              style={{
                position: "absolute",
                zIndex: 800,
              }}
            />

            {canvasWhiteLines.map((canva, index) => {
              return (
                <canvas
                  key={"canva_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 901 + index,
                  }}
                  onClick={(e) => {
                    if (fillets.length > maxLength) {
                      return;
                    }
                    handleMouseMove(
                      canvasWhiteLines[currentIndex],
                      canvasArcs[currentIndex],
                      GREEN_COLORS_TABLE[currentIndex],
                      referenceRadius * conversionRatio,
                      angleEnd[currentIndex - 1]
                        ? parseInt(angleEnd[currentIndex - 1]) + referenceAngle
                        : angleGps,
                      undefined,
                      e,
                      currentIndex
                    );
                  }}
                  onTouchEnd={(e) => {
                    // console.log("onTouchEnd");
                    document.body.style.overflow = "initial";
                  }}
                  onTouchStart={(e) => {
                    // console.log("onTouchStart");
                    document.body.style.overflow = "hidden";
                  }}
                />
              );
            })}

            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                pivot
                  ? pivot.protocol === 5
                    ? `${pivot.controllerconfig.content.pivot_positions.latitude_center}, ${pivot.controllerconfig.content.pivot_positions.longitude_center}`
                    : pivot.config.center
                  : `${props.centerPosition[0]}, ${props.centerPosition[1]}`
              }&zoom=${zoomLevel}&size=${
                window.innerWidth > 800 ? 400 : 300
              }x300&maptype=satellite${process.env.MAPS_KEY}`}
              style={{
                width: window.innerWidth > 800 ? "400px" : "300px",
                height: "300px",
              }}
            />
          </div>
        </div>

        {/* FAIXAS */}
        <div style={{ display: "grid", gridTemplateRows: "0.3fr 1.7fr" }}>
          <span
            style={{ fontSize: 14, marginLeft: isMobile() ? "0x" : "20px" }}
          >
            {i18n("SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON")}
          </span>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile() ? "1fr" : "0.2fr 1.8fr",
            }}
          >
            <div
              style={{
                width: window.innerWidth > 800 ? "120px" : "60px",
                display: "grid",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <div
                id="fillets-container"
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile()
                    ? "1fr 1fr 1fr 1fr 1fr 1fr"
                    : "1fr",
                }}
              >
                {fillets.length == 0 ? (
                  <div className="general-angle__fillets-disabled-circle">
                    1
                  </div>
                ) : null}

                {fillets.map((fillet, index) => {
                  return (
                    <button
                      key={"fillet_" + index}
                      value={index}
                      style={{
                        backgroundColor: GREEN_COLORS_TABLE[index],
                        borderWidth: index === currentIndex ? "5px" : "2px",
                        padding: index === currentIndex ? "10px" : "5px",
                      }}
                      onClick={(event) => {
                        setCurrentIndex(parseInt(event.currentTarget.value));
                      }}
                      className={`${
                        index === currentIndex
                          ? "general-angle__fillets-circle-active"
                          : "general-angle__fillets-circle"
                      }`}
                    >
                      {index + 1}
                    </button>
                  );
                })}

                {maxLength != fillets.length && (
                  <div
                    key={"disabled-circle-" + fillets.length}
                    className="general-angle__fillets-disabled-circle"
                    style={{
                      cursor: "pointer",
                      opacity:
                        maxLength == fillets.length ||
                        // (hasNames && !anglesState[0][anglesState[0]?.length - 1]?.name) ||
                        parseInt(angleEnd[angleEnd.length - 1]) ==
                          Number(sectorLength) + Number(startAngleDraw)
                          ? 0.5
                          : 1,
                    }}
                    onClick={() => {
                      if (
                        maxLength === fillets.length ||
                        // (hasNames && !anglesState[0][anglesState[0]?.length - 1]?.name) ||
                        parseInt(angleEnd[angleEnd.length - 1]) ==
                          Number(sectorLength) + Number(startAngleDraw)
                      ) {
                        return;
                      }
                      setCurrentIndex(fillets.length);
                      setAngleEnd((prevAngleEnd) => {
                        return prevAngleEnd.concat(
                          `${Number(sectorLength) + Number(startAngleDraw)}`
                        );
                      });
                      setFillets((prevFillets) =>
                        prevFillets.concat(GREEN_COLORS_TABLE[fillets.length])
                      );
                      setCanvasWhiteLines((prevCanvasWhiteLines) => {
                        return prevCanvasWhiteLines.concat(
                          createRef<HTMLCanvasElement>()
                        );
                      });
                      setCanvasArcs((prevCanvasArcs) => {
                        return prevCanvasArcs.concat(
                          createRef<HTMLCanvasElement>()
                        );
                      });
                      setDirection((prevDirection) =>
                        prevDirection.concat("0")
                      );
                    }}
                  >
                    <img src={plusIcon} width={15} height={15} />
                  </div>
                )}

                <div className="general-angle__fillets-trash">
                  <img
                    src={trashCan}
                    style={{ width: 23, height: 23, cursor: "pointer" }}
                    onClick={() => {
                      const initialFilletLength = fillets.length;
                      if (fillets.length > 1) {
                        fillets.pop();
                        setFillets([...fillets]);
                        canvasWhiteLines.splice(initialFilletLength - 1, 1);
                        setCanvasWhiteLines([...canvasWhiteLines]);
                        canvasArcs.splice(initialFilletLength - 1, 1);
                        setCanvasArcs([...canvasArcs]);
                        if (initialFilletLength == 2) {
                          setAngleEnd([
                            `${Number(sectorLength) + Number(startAngleDraw)}`,
                          ]);
                        } else {
                          angleEnd.splice(
                            initialFilletLength - 2,
                            2,
                            `${Number(sectorLength) + Number(startAngleDraw)}`
                          );
                          setAngleEnd([...angleEnd]);
                        }
                        setCurrentIndex(initialFilletLength - 2);
                        setSegmentCropPlantDate(null);
                        setSegmentCropHarvestDate(null);
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <Row>
                <FormInput
                  id="segment-name"
                  error={
                    anglesState[0][currentIndex]?.name ? undefined : "ALARM"
                  }
                  label={"ALARM"}
                  stringLabel={i18n("SEGMENT_NAME")}
                  state={[anglesState[0][currentIndex]?.name, setSegmentName]}
                  helperText={
                    !anglesState[0][currentIndex]?.name ||
                    anglesState[0][currentIndex]?.name?.length > 16
                      ? !anglesState[0][currentIndex]?.name
                        ? "ERROR_ADD_NAME"
                        : "ERROR_MAX_16_CHARACTERS"
                      : undefined
                  }
                />
              </Row>

              {!isBauerPivot && (
                <Row>
                  <FormInput
                    id="segment-type"
                    error={
                      anglesState[0][currentIndex]?.segment_type
                        ? undefined
                        : "ALARM"
                    }
                    label={"ALARM"}
                    stringLabel={i18n("SEGMENT_TYPE")}
                    state={[
                      anglesState[0][currentIndex]?.segment_type,
                      setSegmentType,
                    ]}
                    helperText={
                      anglesState[0][currentIndex]?.segment_type?.length > 16
                        ? !anglesState[0][currentIndex]?.segment_type
                          ? "ERROR_ADD_NAME"
                          : "ERROR_MAX_16_CHARACTERS"
                        : undefined
                    }
                  />
                </Row>
              )}

              <Row hasNames={hasNames}>
                <FormInput
                  id="segment-config-angle-start"
                  label={"ALARM"}
                  disabled
                  stringLabel={i18n("BY_ANGLE_IRRIGATION_FIELD_ANGLE_START")}
                  state={[
                    currentIndex === 0
                      ? startAngleDraw.toString()
                      : anglesState?.[0]?.[currentIndex - 1]?.angle,
                    () => {},
                  ]}
                />

                <FormInputIrrigation
                  id="pivot-config-angle-end"
                  label={i18n(label)}
                  val={angleEnd[currentIndex]}
                  onChangeFunction={(e) => {
                    if (e.target.value == "") e.target.value = "0";
                    const newAngleEnd = Math.round(parseInt(e.target.value));
                    if (newAngleEnd >= 0 && newAngleEnd <= 360) {
                      for (
                        let endAngleIndex = angleEnd.length - 1;
                        endAngleIndex > currentIndex;
                        endAngleIndex--
                      ) {
                        if (newAngleEnd >= parseInt(angleEnd[endAngleIndex])) {
                          setAngleEnd((prevAngleEnd) => {
                            prevAngleEnd.splice(
                              currentIndex,
                              endAngleIndex + 1,
                              newAngleEnd.toString()
                            );
                            return [...prevAngleEnd];
                          });
                          deleteFillet(currentIndex, endAngleIndex);
                          return;
                        }
                      }
                      setAngleEnd((prevAngleEnd) => {
                        prevAngleEnd.splice(
                          currentIndex,
                          1,
                          newAngleEnd.toString()
                        );
                        return [...prevAngleEnd];
                      });
                    }
                  }}
                  endAdornment="º"
                  helperText={
                    currentIndex != 0 &&
                    !(
                      parseInt(angleEnd[currentIndex]) >
                        parseInt(angleEnd[currentIndex - 1]) ||
                      parseInt(angleEnd[currentIndex]) <
                        parseInt(angleEnd[currentIndex + 1])
                    )
                      ? "EDIT_PIVOT_V5_ERROR_ANGLE_END"
                      : undefined
                  }
                />
              </Row>

              {!isBauerPivot && (
                <Row hasNames={hasNames}>
                  <FormDateTimePicker
                    id="segment-crop-plant-date"
                    label={"SEGMENTS_PLANT_DATE"}
                    state={[
                      anglesState[0][currentIndex]?.crop_plant_date,
                      setSegmentCropPlantDate,
                    ]}
                  />

                  <FormDateTimePicker
                    id="segment-crop-hasvest-date"
                    label={"SEGMENTS_HARVEST_DATE"}
                    disabled={!anglesState[0][currentIndex]?.crop_plant_date}
                    minDate={anglesState[0][currentIndex]?.crop_plant_date}
                    state={[
                      anglesState[0][currentIndex]?.crop_harvest_date,
                      setSegmentCropHarvestDate,
                    ]}
                  />
                </Row>
              )}
              {isBauerPivot && (
                <Row hasNames={hasNames}>
                  <FormInput
                    id="segment-crop-fwd-irrigation"
                    label={"PIVOT_SEGMENT_CONFIG_FORWARD"}
                    state={[
                      anglesState[0][currentIndex]?.fwd,
                      (value) => {
                        if (value == "")
                          anglesState[1]((item) => {
                            var newItem = [...item];
                            newItem[currentIndex] = {
                              ...newItem[currentIndex],
                              fwd: 0,
                            };
                            return newItem;
                          });
                        else
                          anglesState[1]((item) => {
                            var newItem = [...item];
                            newItem[currentIndex] = {
                              ...newItem[currentIndex],
                              fwd: parseInt(value).toString(),
                            };
                            return newItem;
                          });
                      },
                    ]}
                    endAdornment={"mm"}
                  />

                  <FormInput
                    id="segment-crop-rev-irrigation"
                    label={"PIVOT_SEGMENT_CONFIG_REVERSE"}
                    state={[
                      anglesState[0][currentIndex]?.rev,
                      (value) => {
                        if (value == "")
                          anglesState[1]((item) => {
                            var newItem = [...item];
                            newItem[currentIndex] = {
                              ...newItem[currentIndex],
                              rev: 0,
                            };
                            return newItem;
                          });
                        else
                          anglesState[1]((item) => {
                            var newItem = [...item];
                            newItem[currentIndex] = {
                              ...newItem[currentIndex],
                              rev: parseInt(value).toString(),
                            };
                            return newItem;
                          });
                      },
                    ]}
                    endAdornment={"mm"}
                  />
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(GeneralAngleSelector);

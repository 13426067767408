/*------------------------------------------------------------------------
Irrigation by angle for V5 component
Based of Irrigation by angle for V4
02/03/22 - Author: João Marcos da Silveira - joao.silveira@irricontrol.com
--------------------------------------------------------------------------*/

import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import FormInputIrrigation from "../../../../../../../../components/Form/FormInputIrrigation/FormInputIrrigation";
import Store from "../../../../../../../../redux/index";
import "./NewByAngleIrrigationV5.scss";

import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  ControllerConfig,
  ControllerStream,
  GPSStream,
  Pivot,
} from "../../../../../../../../redux/pivots/types";

import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "../../../../../../../../mobileConfig";
import { ApplicationState } from "../../../../../../../../redux";
import { hideModal } from "../../../../../../../../redux/modal/actions";
import {
  estimatedIrrigationDurationOnServer,
  parseHHMM,
  PIVOT_V4_PAUSE_TIME_WEEKDAYS,
  PTPToMillimeter,
} from "../../../../../../../../utils/models/pivots";

import {
  DrawArcBackward,
  DrawArcFoward,
  DrawCircle,
  DrawLine,
  writeOnCanvas,
} from "../../../../../../../../components/GeneralAngleSelector/components/Canvas";
import {
  angle_correction,
  BLUE_COLORS_TABLE,
  degrees_to_radians,
  ZOOM_TABLE,
} from "../../../../../utils/utils";

import { RotateLeft, RotateRight } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteIcon from "@material-ui/icons/Delete";
import RedoIcon from "@material-ui/icons/Redo";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useRecoilValue } from "recoil";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import AngleLabels from "../../../../../../../../components/GeneralAngleSelector/components/AngleLabels";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";
import SpeedInputs from "../../../../../../../../components/SpeedInputs/SpeedInputs";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { PivotConfigFamily } from "../../../../../../../../recoils/PivotRecoil";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import FormCheckBox from "../../../../../../../../components/Form/FormCheckBox/FormCheckBox";
import FormOutlinedInput from "../../../../../../../../components/Form/FormOutlinedInput/FormOutlinedInput";

interface Props {
  pivot: Pivot;
  farmID: number;
  latestGPSStream: ControllerStream & GPSStream;
  sector: any;
}

export interface TableRow {
  direction: number;
  speed: number;
  start_angle: number;
  end_angle: number;
}

export type ScheduleIrrigation = {
  direction: number;
  end_day: number;
  end_hour: number;
  end_minute: number;
  end_month: number;
  end_year: number;
  mode: number;
  number_editing: number;
  percent: number;
  schedule_rounds: number;
  start_day: number;
  start_hour: number;
  start_minute: number;
  start_month: number;
  start_now: boolean;
  start_on_a_date: boolean;
  start_year: number;
  stop_angle: number;
  stop_mode: number;
};

let angle = 0;

function NewByAngleIrrigationV5(props: Props) {
  const trashCan = require("../../../../../../../../assets/images/redTrashCan.png");
  const plusIcon = require("../../../../../../../../assets/images/plus.svg");
  const clockwise = require("../../../../../../../../assets/images/clockwise.svg");
  const { pivot, farmID, sector } = props;

  const dispatch = useDispatch();

  const [disableNextButtonState, setDisableNextButtonState] = useState<boolean>(
    true
  );

  const [lastAngle, setLastAngle] = useState<number[]>([]);

  const [nextPage, setNextPage] = useState(false);

  // DIRECTION ARRAYS
  let [direction, setDirection] = useState<string[]>(["1"]);

  //ANGLE END
  let [angleEnd, setAngleEnd] = useState<string[]>(["0"]);

  //START DATE
  const [startDate, setStartDate] = useState(moment());

  //OPERATION MODE
  const [operationMode, setOperationMode] = useState("2");
  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  const [injectionPumpStatus, setInjectionPumpStatus] = useState(false);
  const [injectionPumpNote, setInjectionPumpNote] = useState("");

  const setValidOperationMode = useCallback(
    (value: string) => {
      setOperationMode(value);
      if (value === "2") {
        setInjectionPumpStatus(false);
        setInjectionPumpNote("");
      }
    },
    [operationMode]
  );

  // const [schedulePivotArray, setSchedulePivotArray] = useState<ScheduleIrrigation[]>([]);

  //IRRIGATION PERCENT
  const [percentimeter, setPercentimeter] = useState<string>("100");
  const [percentimeterArray, setPercentimeterArray] = useState<string[]>([]);

  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  const [millimeter, setMillimeter] = useState(
    PTPToMillimeter(pivot, parseInt(percentimeter)).toString()
  );

  const [startMode, setStartMode] = useState("now");

  //By Rounds irrigation
  const [irrigationByRounds, setirrigationByRounds] = useState(0);

  const [fillets, setFillets] = useState(["#002372"]);
  const [canvasWhiteLines, setCanvasWhiteLines] = useState<
    RefObject<HTMLCanvasElement>[]
  >([createRef<HTMLCanvasElement>()]);

  const [canvasArcs, setCanvasArcs] = useState<RefObject<HTMLCanvasElement>[]>([
    createRef<HTMLCanvasElement>(),
  ]);

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  const [duration, setDuration] = useState<any>({
    duration: 0,
    formattedDuration: " - ",
    dateDuration: moment(),
  });

  const [directionDisabled, setDirectionDisabled] = useState<boolean>(false);

  // Div Modal for hidden overflow
  const divModalElement: HTMLDivElement = document.querySelector(
    "#mobileGeneralDivModal"
  )
    ? (document.querySelector("#mobileGeneralDivModal").children[2]
        .firstChild as HTMLDivElement)
    : (null as HTMLDivElement);

  // Horários de pico
  const pauseTime1 = pivot.controllerconfig?.content?.pause_time_command
    ?.pause_time_command
    ? [
        `${pivot.controllerconfig?.content?.pause_time?.start_pause_time_hour_1}:${pivot.controllerconfig?.content?.pause_time?.start_pause_time_minute_1}`,
        `${pivot.controllerconfig?.content?.pause_time?.end_pause_time_hour_1}:${pivot.controllerconfig?.content?.pause_time?.end_pause_time_minute_1}`,
      ]
    : ["00:00", "00:00"];
  const pauseTime2 = pivot.controllerconfig?.content?.pause_time_command
    ?.pause_time_command
    ? [
        `${pivot.controllerconfig?.content?.pause_time?.start_pause_time_hour_2}:${pivot.controllerconfig?.content?.pause_time?.start_pause_time_minute_2}`,
        `${pivot.controllerconfig?.content?.pause_time?.end_pause_time_hour_2}:${pivot.controllerconfig?.content?.pause_time?.end_pause_time_minute_2}`,
      ]
    : ["00:00", "00:00"];

  useEffect(() => {
    if (pivotConfig !== undefined && startMode.includes("after_pause_time")) {
      var config = pivotConfig as ControllerConfig;
      if (startMode == "after_pause_time_1") {
        setStartDate(
          moment()
            .hour(config?.content.pause_time.end_pause_time_hour_1)
            .minute(config?.content.pause_time.end_pause_time_minute_1)
            .second(0)
        );
      } else {
        setStartDate(
          moment()
            .hour(config?.content.pause_time.end_pause_time_hour_2)
            .minute(config?.content.pause_time.end_pause_time_minute_2)
            .second(0)
        );
      }
    }
    if (startMode === "now") {
      setStartDate(moment());
    }
  }, [pivotConfig, startMode]);

  const handleStartModeValues = (): [string, string][] => {
    var config = pivotConfig as ControllerConfig;
    if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 ===
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    } else if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 !==
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["after_pause_time_2", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2"], //after pause time hour 2
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    }
    return [
      ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
      ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
    ];
  };

  async function getEstimatedIrrigationDuration() {
    setDuration({
      duration: 0,
      formattedDuration: " - ",
      dateDuration: moment(),
    });

    var totalDuration = 0;
    var rawDuration = 0;

    const farmTimezone = Store().store.getState().farm.data?.timezone;

    for (let index = 0; index < tableRows?.length; index++) {
      var row = tableRows?.[index];

      const angle = row.start_angle;
      const endAngle = row.end_angle;
      const direction = row.direction == 1 ? true : false;
      const timezone = farmTimezone ? farmTimezone : "America/Sao_Paulo";
      const irrigationStartDate =
        startMode !== "now" && index + 1 === tableRows.length
          ? startDate.toISOString()
          : null;
      const numberOfRounds = irrigationByRounds < 0 ? irrigationByRounds : null;
      const isWetIrrigation = operationMode == "1" ? true : false;

      var estimatedIrrigation = await estimatedIrrigationDurationOnServer(
        pivot.controllerconfig.content?.pivot_parameters?.radius_last,
        pivot.controllerconfig.content?.pivot_parameters?.speed,
        row.speed,
        direction,
        angle,
        endAngle,
        pivot.config?.sector_angle ? pivot.config?.sector_angle : 360,
        PIVOT_V4_PAUSE_TIME_WEEKDAYS,
        pauseTime1,
        pauseTime2,
        timezone,
        irrigationStartDate,
        numberOfRounds,
        isWetIrrigation
      );

      totalDuration = totalDuration + estimatedIrrigation.total_duration;
      rawDuration = rawDuration + estimatedIrrigation.raw_duration;
    }
    totalDuration = Math.round(totalDuration * 100) / 100;
    rawDuration = Math.round(rawDuration * 100) / 100;

    setDuration({
      duration: rawDuration,
      formattedDuration: parseHHMM(rawDuration * 60),
      dateDuration: moment().add(totalDuration, "hour"),
    });
  }

  useEffect(() => {
    getEstimatedIrrigationDuration();
  }, [
    tableRows,
    startMode,
    pivot?.config,
    startDate,
    irrigationByRounds,
    operationMode,
  ]);

  var isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  const notify = useNotify();

  //ANGLE START
  const [angleStart, setAngleStart] = useState(
    pivot.controllerstream_gps.content.current_angle.current_angle
  );

  const firstCanva = useRef(null);

  let latLngCenterPositionString =
    pivot.controllerconfig.content.pivot_positions.latitude_center.toString() +
    "," +
    pivot.controllerconfig.content.pivot_positions.longitude_center.toString();

  let centerPosition = [
    pivot.controllerconfig.content.pivot_positions.latitude_center.toString(),
    pivot.controllerconfig.content.pivot_positions.longitude_center.toString(),
  ];
  let gpsPosition = [
    pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
    pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
  ];

  let gpsPositionGMaps = new google.maps.LatLng(
    parseFloat(gpsPosition[0]),
    parseFloat(gpsPosition[1])
  );

  let centerPositionGMaps = new google.maps.LatLng(
    parseFloat(centerPosition[0]),
    parseFloat(centerPosition[1])
  );

  //Posição do pivo
  let angleGps = google.maps.geometry.spherical.computeHeading(
    centerPositionGMaps,
    gpsPositionGMaps
  );

  //Angulo de referencia
  let referenceAngle = pivot.reference_angle;
  // let referenceAngle = 0;

  let gpsAngleWithReference: number =
    angleGps > 0
      ? Math.round(angleGps - referenceAngle)
      : Math.round(angleGps + 360 - referenceAngle);

  gpsAngleWithReference = angle_correction(gpsAngleWithReference);

  function handleMouseMove(
    canva: any,
    arcCanva: any,
    filletsMove: any,
    radius: number,
    startAngle: number,
    directionMove: string,
    lastAngleMove: number,
    newAngle?: any,
    e?: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");
    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field

      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }

      // Sectorial pivot
      if (sector.end_angle !== 360) {
        let lastAngle =
          lastAngleMove == -1
            ? gpsAngleWithReference
            : lastAngleMove + gpsAngleWithReference;
        lastAngle = angle_correction(lastAngle);

        if (lastAngle == 360) lastAngle = 0;
        let angleFromZero = angle + gpsAngleWithReference;

        angleFromZero = angle_correction(angleFromZero);

        if (directionMove == "1") {
          if (angleFromZero > sector.end_angle || angleFromZero < lastAngle) {
            angle = sector.end_angle - gpsAngleWithReference;
          }
        } else {
          if (angleFromZero > lastAngle) angle = 360 - gpsAngleWithReference;
        }
      }
    }

    let newY = radius * Math.cos(((startAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((startAngle + angle) * Math.PI) / 180);

    //Set the newAngle as EndAngle
    if (newAngle === undefined) {
      let newEndAngle = Math.round(angle) == 360 ? 0 : Math.round(angle);
      if (angleEnd.length == fillets.length) {
        angleEnd.pop();
        //direction é o sentido em que o pivot será movimentado
        //1 = horário e 2 = anti-horário

        setAngleEnd((angleEnd) => [
          ...angleEnd,
          Math.round(newEndAngle).toString(),
        ]);
      } else {
        setAngleEnd((angleEnd) => [
          ...angleEnd,
          Math.round(newEndAngle).toString(),
        ]);
      }
    }

    //Desenha uma nova linha branca
    DrawLine(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      context,
      canva.current.width / 2 + newX / 2,
      canva.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Ajusta o angulo para contar a partir do angulo do pivo
    let angleAux = Math.round(
      angle +
        (startAngle > 0
          ? startAngle - referenceAngle
          : startAngle + 360 - referenceAngle)
    );

    angleAux = angle_correction(angleAux);

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      context,
      canva.current.width / 2 + newX / 2 - 10,
      canva.current.height / 2 - newY / 2 + 5,
      angleAux + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );

    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();
    // contextArc.beginPath();
    if (directionMove == "1") {
      if (lastAngleMove != -1) {
        //Check if it's the first fillet or not. If it's not
        //the new fillet must use the last one as its new reference
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleMove) * Math.PI) / 180, //Parte da posicão inicial do pivot
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      } else {
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180, //parte do
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      }
    } else {
      if (lastAngleMove != -1) {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleMove) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      } else {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      }
    }

    contextArc.fill();
    contextArc.restore();
  }

  const handleDirection = (
    event: React.MouseEvent<HTMLElement>,
    newDirection: string | null
  ) => {
    let tableRows = calcTableRows();
    let newAngle =
      tableRows[tableRows.length - 1].end_angle >= 360
        ? 0
        : tableRows[tableRows.length - 1].end_angle;
    const lastAngle =
      tableRows.length == 1
        ? gpsAngleWithReference
        : tableRows[tableRows.length - 2].end_angle;

    if (newDirection) {
      if (sector.end_angle < 360) {
        if (
          (Number(newDirection) == 2 && newAngle >= lastAngle) ||
          (Number(newDirection) == 1 && newAngle <= lastAngle)
        ) {
          newAngle = angle_correction(lastAngle - gpsAngleWithReference);
        }

        angleEnd.pop();
        if (newDirection == "1") {
          setAngleEnd([...angleEnd, (Math.round(newAngle) + 2).toString()]);
        } else {
          setAngleEnd([...angleEnd, (Math.round(newAngle) - 2).toString()]);
        }
      }

      direction.pop();
      setDirection([...direction, newDirection]);
    }
  };

  const createBody = (rows, index) => {
    let schedulePivotArrayAux = {} as ScheduleIrrigation;
    schedulePivotArrayAux.number_editing = index;
    schedulePivotArrayAux.direction = Number(rows.direction);
    schedulePivotArrayAux.percent = rows.speed;
    schedulePivotArrayAux.mode = Number(operationMode);
    schedulePivotArrayAux.stop_mode = irrigationByRounds > 0 ? 4 : 1; // Stop mode: 1 - by angle; 4 - by rounds
    schedulePivotArrayAux.schedule_rounds = irrigationByRounds;
    schedulePivotArrayAux.stop_angle =
      irrigationByRounds > 0 ? 0 : rows.end_angle;
    schedulePivotArrayAux.end_day = 0;
    schedulePivotArrayAux.end_hour = 0;
    schedulePivotArrayAux.end_minute = 0;
    schedulePivotArrayAux.end_month = 0;
    schedulePivotArrayAux.end_year = 0;
    if (startMode === "now") {
      schedulePivotArrayAux.start_now = true;
      schedulePivotArrayAux.start_on_a_date = false;
      schedulePivotArrayAux.start_day = 0;
      schedulePivotArrayAux.start_hour = 0;
      schedulePivotArrayAux.start_minute = 0;
      schedulePivotArrayAux.start_month = 0;
      schedulePivotArrayAux.start_year = 0;
    } else {
      schedulePivotArrayAux.start_now = false;
      schedulePivotArrayAux.start_on_a_date = true;
      schedulePivotArrayAux.start_day = startDate.get("D");
      schedulePivotArrayAux.start_hour = startDate.get("h");
      schedulePivotArrayAux.start_minute = startDate.get("m");
      schedulePivotArrayAux.start_month = startDate.get("M") + 1;
      schedulePivotArrayAux.start_year = startDate.get("year") - 2000;
    }
    return schedulePivotArrayAux;
  };

  async function postScheduleIrrigation() {
    try {
      let schedulePivotArray = new Array<ScheduleIrrigation>();

      if (irrigationByRounds > 0) {
        schedulePivotArray.push(createBody(tableRows[tableRows.length - 1], 0));
      } else {
        tableRows.forEach((rows, index) => {
          schedulePivotArray.push(createBody(rows, index));
        });
      }

      var config = pivotConfig as ControllerConfig;
      const autoReversionCommand =
        config.content.autoreversion_command?.command || 0;

      let body = {
        message_subtype: "schedule",
        angle_V5: true,
        equipment: pivot.id,
        content: {
          injection_pump_command: {
            command: +injectionPumpStatus,
            note: injectionPumpNote,
          },
          autoreversion_command: {
            command: autoReversionCommand,
          },
          schedule_irrigation_parameters: schedulePivotArray,
          irrigation_status: {
            irrigation_type: 2,
            irrigation_status: schedulePivotArray[0].direction, // 1 forward, 2 reverse
          },
        },
      };

      const response = await coreHTTPClient
        .post(
          `v3/farms/${farmID}/pivots/${pivot.id}/device/${pivot.control}/action/`,
          body
        )
        .then(() => {
          notify("SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS", "success");
          dispatch(hideModal());
        });
    } catch (err) {
      notify("SELECTED_PIVOT_ANGLE_MODAL_FAILURE", "error");
    }
  }

  const checkDisableOnBorders = (currentAngle, isPivotCurrentAngle = false) => {
    if (sector.end_angle < 360) {
      if (Math.floor(currentAngle) == 0 || Math.round(currentAngle) == 360) {
        isPivotCurrentAngle
          ? setDirection(["1"])
          : setDirection([...direction, "1"]);
        setDirectionDisabled(true);
      } else if (Math.round(currentAngle) == sector.end_angle) {
        isPivotCurrentAngle
          ? setDirection(["2"])
          : setDirection([...direction, "2"]);
        setDirectionDisabled(true);
      } else {
        setDirectionDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (tableRows.length == 0) {
      checkDisableOnBorders(
        pivot.controllerstream_gps.content.current_angle.current_angle,
        true
      );
      setAngleStart(
        pivot.controllerstream_gps.content.current_angle.current_angle
      );
    }
  }, [
    pivot.latest_gps_stream?.angle,
    pivot.controllerstream_gps?.content.current_angle?.current_angle,
  ]);

  let center = new google.maps.LatLng(
    pivot.controllerconfig.content?.pivot_positions.latitude_center.toString(),
    pivot.controllerconfig.content?.pivot_positions.longitude_center.toString()
  );

  let reference = new google.maps.LatLng(
    pivot.controllerconfig.content?.pivot_positions.latitude_reference.toString(),
    pivot.controllerconfig.content?.pivot_positions.longitude_reference.toString()
  );

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;
  let conversionRatio = 0;
  let conversionRatioReferenceLines = 0;

  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
      conversionRatio = ZOOM_TABLE[i].conversionRatio;
      conversionRatioReferenceLines =
        ZOOM_TABLE[i].conversionRatioReferenceLines;
    }
  }

  const circleDiameter = referenceRadius * conversionRatio * 2;

  useEffect(() => {
    if (operationMode === "1") {
      setMillimeter(String(PTPToMillimeter(pivot, 100)));
    }
  }, [operationMode]);

  useEffect(() => {
    handleMouseMove(
      canvasWhiteLines[canvasWhiteLines.length - 1],
      canvasArcs[canvasArcs.length - 1],
      fillets[fillets.length - 1],
      referenceRadius * conversionRatio,
      angleGps,
      direction[direction.length - 1],
      lastAngle[lastAngle.length - 1] ? lastAngle[lastAngle.length - 1] : -1,
      angleEnd[angleEnd.length - 1]
    );
  }, [direction]);

  useEffect(() => {
    if (nextPage == false) {
      for (let index = 0; index < fillets.length; index++) {
        handleMouseMove(
          canvasWhiteLines[index],
          canvasArcs[index],
          fillets[index],
          referenceRadius * conversionRatio,
          angleGps,
          direction[index],
          lastAngle[index - 1] ? lastAngle[index - 1] : -1,
          angleEnd[index]
        );
      }
    }
  }, [nextPage]);

  useEffect(() => {
    if (nextPage == false) {
      let context = firstCanva.current.getContext("2d");

      //Desenha circulo que representa alcance do pivot
      context.beginPath();
      context.arc(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2,
        referenceRadius * conversionRatio,
        0 + degrees_to_radians(referenceAngle - 90),
        degrees_to_radians(sector.end_angle + (referenceAngle - 90))
      );
      context.strokeStyle = "white";
      context.stroke();

      //Desenha as linhas brancas ao redor do círculo
      for (let i = 0; i < Math.floor(sector.end_angle / 5); i++) {
        context.save();
        let ang = degrees_to_radians(i * 5 + (referenceAngle - 180));
        context.translate(
          firstCanva.current.width / 2,
          firstCanva.current.height / 2
        );
        context.rotate(ang);
        context.fillStyle = "white";
        context.textBaseline = "middle";
        context.textAlign = "center";
        context.beginPath();
        context.moveTo(0, referenceRadius * conversionRatioReferenceLines);
        context.lineTo(0, referenceRadius * conversionRatio);
        context.strokeStyle = "white";
        context.stroke();
        //back to checkpoint
        context.restore();
      }

      // Translate the context to the point we want to rotate around
      context.translate(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2
      );

      // Perform the rotation
      context.rotate(degrees_to_radians(referenceAngle));
      let pivotPositionY =
        referenceRadius *
        conversionRatio *
        Math.cos(degrees_to_radians(angleGps - referenceAngle));
      let pivotPositionX =
        referenceRadius *
        conversionRatio *
        Math.sin(degrees_to_radians(angleGps - referenceAngle));

      //Linha preta (posição atual do pivot)
      DrawLine(context, 0, 0, pivotPositionX, pivotPositionY, "black");
      // DrawLine(context, 0, 0, 0, referenceRadius * conversionRatio, "black");

      //back to checkpoint
      context.restore();
      DrawCircle(
        context,
        firstCanva.current.width / 2,
        firstCanva.current.height / 2,
        5,
        0,
        2 * Math.PI,
        "black"
      );
      let contextWhiteLines = canvasWhiteLines[
        canvasWhiteLines.length - 1
      ].current.getContext("2d");

      //create canvas checkpoint with default translate and rotate
      contextWhiteLines.save();

      //Translate full canvas to it's center
      contextWhiteLines.translate(
        canvasWhiteLines[canvasWhiteLines.length - 1].current.width / 2,
        canvasWhiteLines[canvasWhiteLines.length - 1].current.height / 2
      );

      //Rotate canvas by reference_angle arround it's center
      contextWhiteLines.rotate(degrees_to_radians(referenceAngle));

      //Desenha o circulo central preto
      DrawCircle(contextWhiteLines, 0, 0, 5, 0, 2 * Math.PI, "black");

      //rotate and translate back to checkpoint
      contextWhiteLines.restore();
    }
  }, [nextPage]);

  const handleStartDatetime = (startMode) => {
    var startDateTime;

    var startDateWithNoSeconds = startDate.second(0);

    if (startMode === "now") {
      startDateTime = undefined;
    } else {
      startDateTime = startDateWithNoSeconds.format("YYYY-MM-DDTHH:mm:ss[Z]");
    }

    return startDateTime;
  };

  const calcTableRows = () => {
    let tableRowsAux: any = [];

    let lastAngle: number = gpsAngleWithReference;

    let end_angleAux: number;
    for (let i = 0; i < fillets.length; i++) {
      end_angleAux = angle_correction(
        parseInt(angleEnd[i]) + gpsAngleWithReference
      );

      tableRowsAux.push({
        direction: parseInt(direction[i]),
        speed: parseInt(
          fillets.length - 1 == i ? percentimeter : percentimeterArray[i]
        ),
        start_angle: Math.round(lastAngle),
        end_angle: Math.round(end_angleAux),
      });
      lastAngle = end_angleAux;
    }

    return tableRowsAux;
  };

  const nextPageButton = () => {
    let tableRowsAux: any = calcTableRows();

    setTableRows(tableRowsAux);
    setNextPage(true);
  };

  const disableNextButton = (): boolean => {
    if (angleEnd.length == 1)
      if (
        Math.round(
          parseInt(angleEnd[angleEnd.length - 1]) + gpsAngleWithReference
        ) %
          360 ==
          Math.round(gpsAngleWithReference) ||
        percentimeter === "" ||
        millimeter === ""
      )
        return true;
  };

  useEffect(() => {
    setDisableNextButtonState(disableNextButton());
  }, [angleEnd, percentimeter, millimeter]);

  const byRoundsHandler = (e) => {
    setCanvasArcs([createRef<HTMLCanvasElement>()]);
    setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);

    if (parseInt(e.target.value) == 0) return true;
    let percentimeterArrayAux = [];
    let angleEndAux = [];
    let directionAux = [];
    let filletsAux: any = [];
    let newWhiteLineAux: any = [];
    let newArcAux: any = [];

    let tableRowsAux = [];
    for (let i = 0; i < parseInt(e.target.value) * 2; i++) {
      if (i % 2 == 0) {
        angleEndAux.push(
          Math.round(
            (parseFloat(angleStart) + 180 - gpsAngleWithReference) % 360
          ).toString()
        );
        tableRowsAux.push({
          direction: direction[direction.length - 1],
          speed: parseInt(percentimeter),
          start_angle: Math.round(parseFloat(angleStart)),
          end_angle: Math.round((parseFloat(angleStart) + 180) % 360),
        });
      } else {
        angleEndAux.push(
          Math.round(parseFloat(angleStart) - gpsAngleWithReference).toString()
        );
        tableRowsAux.push({
          direction: direction[direction.length - 1],
          speed: parseInt(percentimeter),
          start_angle: Math.round((parseFloat(angleStart) + 180) % 360),
          end_angle: Math.round(parseFloat(angleStart)),
        });
      }
      if (i != parseInt(e.target.value) - 1) {
        percentimeterArrayAux.push(percentimeter);
      }
      directionAux.push(direction[direction.length - 1]);
      filletsAux.push(BLUE_COLORS_TABLE[i]);

      if (i != 0) {
        newWhiteLineAux.push(createRef<HTMLCanvasElement>());
        newArcAux.push(createRef<HTMLCanvasElement>());
      }
    }

    setFillets([...filletsAux]);
    setCanvasWhiteLines([...canvasWhiteLines, ...newWhiteLineAux]);
    setCanvasArcs([...canvasArcs, ...newArcAux]);

    setPercentimeterArray(percentimeterArrayAux);
    setLastAngle(
      angleEndAux
        .slice(0, angleEndAux.length - 1)
        .map((value) => parseInt(value))
    );
    setAngleEnd(angleEndAux);
    setDirection(directionAux);

    setTableRows(tableRowsAux);
    setirrigationByRounds(parseInt(e.target.value));
    setNextPage(true);
  };

  useEffect(() => {
    if (isLoadingLastAction) {
      byRoundsHandler({ target: { value: irrigationByRounds } });
    }
  }, [irrigationByRounds]);

  const lastIrrigationUsed = async () => {
    setAngleEnd(["0"]);
    setPercentimeterArray([]);
    setMillimeter(`${PTPToMillimeter(pivot, 100)}`);
    setPercentimeter("100");
    setDirection(["1"]);
    setFillets(["#002372"]);
    setCanvasArcs([createRef<HTMLCanvasElement>()]);
    setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);
    setLastAngle([]);
    setirrigationByRounds(0);

    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/controlleraction/schedule/latest/?is_angle_v5=true`
    );

    if (response.data?.content) {
      let lastIrrigation = response.data.content.schedule_irrigation_parameters;

      if (lastIrrigation[0].stop_mode == 4) {
        setDirection([lastIrrigation[0].direction.toString()]);
        setPercentimeter(lastIrrigation[0].percent.toString());
        setirrigationByRounds(lastIrrigation[0].schedule_rounds);
      } else {
        let tableRowsAux: any = [];
        let filletsAux: any = [];
        let newWhiteLineAux: any = [];
        let newArcAux: any = [];
        let lastAngleAux: number = gpsAngleWithReference;
        let lastAngleArray: any = [];
        let percentimeterArray: any = [];
        let angleEndArray: any = [];
        let directionArray: any = [];
        let operationModeArray: any = [];

        lastIrrigation.map((irrigation, index) => {
          percentimeterArray.push(irrigation.percent.toString());
          angleEndArray.push(
            angle_correction(
              irrigation.stop_angle - gpsAngleWithReference
            ).toString()
          );
          directionArray.push(irrigation.direction.toString());
          operationModeArray.push(irrigation.mode.toString());
          tableRowsAux.push({
            direction: irrigation.direction,
            speed: irrigation.percent,
            start_angle: Math.round(lastAngleAux),
            end_angle: irrigation.stop_angle,
          });
          lastAngleAux = irrigation.stop_angle;

          if (index != 0) {
            filletsAux.push(BLUE_COLORS_TABLE[index + 1]);
            newWhiteLineAux.push(createRef<HTMLCanvasElement>());
            newArcAux.push(createRef<HTMLCanvasElement>());
            lastAngleArray.push(angleEndArray[index - 1]);
          }
        });

        setPercentimeterArray(percentimeterArray);
        setPercentimeter(percentimeterArray[percentimeterArray.length - 1]);
        setMillimeter(
          `${PTPToMillimeter(
            pivot,
            percentimeterArray[percentimeterArray.length - 1]
          )}`
        );
        setLastAngle(lastAngleArray);
        setAngleEnd(angleEndArray);
        setDirection(directionArray);
        setOperationMode(operationModeArray);
        setFillets([...fillets, ...filletsAux]);
        setCanvasWhiteLines([...canvasWhiteLines, ...newWhiteLineAux]);
        setCanvasArcs([...canvasArcs, ...newArcAux]);
        setTableRows(tableRowsAux);
        setNextPage(true);
      }
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");

    setIsLoadingLastAction(false);
  };

  const handleAngleInputChange = (e) => {
    let newAngle = e.target.value;

    if (newAngle == "") newAngle = "0";

    let angleEndAux = [...angleEnd];
    angleEndAux.pop();

    if (newAngle >= 0 && newAngle < 360) {
      if (sector.end_angle < 360) {
        if (
          Number(direction[direction.length - 1]) == 1 &&
          (newAngle > sector.end_angle || newAngle < gpsAngleWithReference)
        ) {
          newAngle = sector.end_angle;
        } else if (newAngle > gpsAngleWithReference) {
          newAngle = 0;
        }
      }

      const newAngleEnd = Math.round(
        parseInt(newAngle) - gpsAngleWithReference
      ).toString();

      setAngleEnd([...angleEndAux, newAngleEnd]);
      handleMouseMove(
        canvasWhiteLines[canvasWhiteLines.length - 1],
        canvasArcs[canvasArcs.length - 1],
        fillets[fillets.length - 1],
        referenceRadius * conversionRatio,
        angleGps,
        direction[direction.length - 1],
        lastAngle[lastAngle.length - 1] ? lastAngle[lastAngle.length - 1] : -1,
        newAngleEnd
      );
    }
  };

  const adjustAngle = (direction) => {
    let tableRow = calcTableRows();
    if (sector.end_angle < 360) {
      if (
        direction == "1" &&
        tableRow[tableRow.length - 1].end_angle == sector.end_angle
      ) {
        return { value: -1, newDirection: "2" };
      } else if (
        direction == "2" &&
        (tableRow[tableRow.length - 1].end_angle == 0 ||
          tableRow[tableRow.length - 1].end_angle == 360)
      ) {
        return { value: 1, newDirection: "1" };
      }
    }
    return { value: direction == "1" ? 1 : -1, newDirection: direction };
  };

  const addNewSector = (index) => {
    if (
      (angle == 0 && lastAngle.length == 0) ||
      angle === lastAngle[lastAngle.length - 1] ||
      fillets.length >= 20 ||
      percentimeter === "" ||
      millimeter === ""
    )
      return;

    setFillets([...fillets, BLUE_COLORS_TABLE[index + 1]]);

    let newWhiteLine = canvasWhiteLines.concat(createRef<HTMLCanvasElement>());
    setCanvasWhiteLines(newWhiteLine);

    let newArc = canvasArcs.concat(createRef<HTMLCanvasElement>());
    setCanvasArcs(newArc);
    setLastAngle([...lastAngle, angle]);

    const adjustFactor = adjustAngle(direction[direction.length - 1]);

    setAngleEnd([
      ...angleEnd,
      (Math.round(angle) + adjustFactor.value).toString(),
    ]);

    setPercentimeterArray([...percentimeterArray, percentimeter]);
    setPercentimeter("100");
    setMillimeter(`${PTPToMillimeter(pivot, 100)}`);

    setDirection([...direction, adjustFactor.newDirection]);

    checkDisableOnBorders(angle + gpsAngleWithReference);
  };

  return !nextPage ? (
    <>
      {isMobile() ? <></> : <br></br>}
      <div className="by-angle-irrigation_v5">
        <div className="by-angle-irrigation_v5__form">
          <div className="by-angle-irrigation_v5__center-row-without-margin">
            <Button
              style={
                isMobile()
                  ? { width: "100%" }
                  : {
                      marginTop: "-30px",
                      marginBottom: "10px",
                    }
              }
              variant="contained"
              color="primary"
              disabled={isLoadingLastAction}
              endIcon={
                isLoading ? (
                  <CircularProgress size={20} style={{ marginLeft: "8px" }} />
                ) : (
                  <RedoIcon />
                )
              }
              onClick={lastIrrigationUsed}
            >
              <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
            </Button>
          </div>
          <div
            className={
              isMobile()
                ? "by-angle-irrigation_v5__1-column"
                : "by-angle-irrigation_v5__1-column-big-screen"
            }
          >
            <SpeedInputs
              pivot={pivot}
              millimeter={millimeter}
              percentage={percentimeter}
              setMillimeter={setMillimeter}
              setPercentage={setPercentimeter}
            />
          </div>

          <div
            className={
              isMobile()
                ? "by-angle-irrigation_v5__1-column"
                : "by-angle-irrigation_v5__2-auto-columns"
            }
          >
            <div
              className={
                isMobile()
                  ? "by-angle-irrigation_v5__2-auto-columns"
                  : "by-angle-irrigation_v5__internal-2-columns"
              }
            >
              <div className={"by-angle-irrigation_v5__input-end-angle"}>
                {/*
                  O sistema de irrigação por voltas para pivôs setoriais precisa de implementações complexas e não está funcionando
                  para evitar que um usuário  envie  "por voltas" e ocorra problemas sérios na irrigação ele está bloqueado até que 
                  seja ajustado (atualmente as voltas estão sendo de 360 graus independente do pivô ser setorial ou não).
                */}
                <FormControl
                  className="form-select__form-control"
                  disabled={sector.end_angle !== 360}
                >
                  <InputLabel
                    id={"irrigation-by-rounds-label"}
                    style={{ color: "rgba(0, 0, 0, 0.38)" }}
                  >
                    {i18n("SIMPLE_IRRIGATION_BY_ROUNDS")}
                  </InputLabel>
                  <NativeSelect
                    id="irrigation-by-rounds"
                    key={irrigationByRounds}
                    value={irrigationByRounds}
                    onChange={byRoundsHandler}
                  >
                    <option key={"0"} value={"0"}>
                      {i18n("BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF")}
                    </option>
                    <option key={"1"} value={"1"}>
                      1
                    </option>
                    <option key={"2"} value={"2"}>
                      2
                    </option>
                    <option key={"3"} value={"3"}>
                      3
                    </option>
                    <option key={"4"} value={"4"}>
                      4
                    </option>
                    <option key={"5"} value={"5"}>
                      5
                    </option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className={"by-angle-irrigation_v5__input-end-angle"}>
                <FormInputIrrigation
                  id="pivot-config-angle-end"
                  label={i18n("BY_ANGLE_IRRIGATION_FIELD_ANGLE_END")}
                  val={angle_correction(
                    Math.round(
                      parseInt(angleEnd[angleEnd.length - 1]) +
                        gpsAngleWithReference
                    )
                  ).toString()}
                  onChangeFunction={handleAngleInputChange}
                  endAdornment="º"
                  inputMode="numeric"
                />
              </div>
            </div>
            <div className={"by-angle-irrigation_v5__toggle-container"}>
              <ToggleButtonGroup
                value={direction[direction.length - 1].toString()}
                exclusive
                onChange={handleDirection}
                aria-label="direction"
                style={{ width: 280, marginTop: 5, marginBottom: 5 }}
              >
                <ToggleButton
                  disabled={directionDisabled}
                  value="2"
                  aria-label="Reverse"
                  style={{ width: 140 }}
                >
                  <RotateLeft />
                  {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
                </ToggleButton>
                <ToggleButton
                  disabled={directionDisabled}
                  value="1"
                  aria-label="Progress"
                  style={{ width: 140 }}
                >
                  <RotateRight />
                  {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          <div className="by-angle-irrigation_v5__fillets">
            <span style={{ fontSize: 14 }}>
              {i18n("EDIT_FARM_ANGLE_RANGES")}
            </span>
            <div className="by-angle-irrigation_v5__fillets-container">
              <div
                id="fillets-container"
                className="by-angle-irrigation_v5__fillets-container"
              >
                {fillets.length == 0 ? (
                  <div className="by-angle-irrigation_v5__fillets-disabled-circle">
                    1
                  </div>
                ) : null}
                {fillets.map((fillet, index) => {
                  return (
                    <div
                      key={"fillet_" + index}
                      style={{ backgroundColor: BLUE_COLORS_TABLE[index] }}
                      className={`${
                        index === fillets.length - 1
                          ? "by-angle-irrigation_v5__fillets-circle-active"
                          : "by-angle-irrigation_v5__fillets-circle"
                      }`}
                    >
                      {direction[index] == "1" ? (
                        <img
                          src={clockwise}
                          width={15}
                          height={15}
                          style={{
                            transform: "scalex(-1)",
                            color: "white",
                          }}
                        />
                      ) : (
                        <img src={clockwise} width={15} height={15} />
                      )}
                    </div>
                  );
                })}
                {fillets.map((fillet, index) => {
                  if (index + 1 == fillets.length) {
                    return (
                      <div
                        key={"disabled-circle-" + index}
                        className={
                          (angle == 0 && lastAngle.length == 0) ||
                          angle === lastAngle[lastAngle.length - 1] ||
                          fillets.length >= 20 ||
                          percentimeter === "" ||
                          millimeter === ""
                            ? "by-angle-irrigation_v5__fillets-disabled-circle"
                            : "by-angle-irrigation_v5__fillets-circle-active"
                        }
                        onClick={() => {
                          addNewSector(index);
                        }}
                      >
                        <img src={plusIcon} width={15} height={15} />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="by-angle-irrigation_v5__fillets-trash">
                <img
                  src={trashCan}
                  style={{ width: 23, height: 23, marginRight: 5 }}
                  onClick={() => {
                    if (fillets.length > 1) {
                      angle = lastAngle[lastAngle.length - 1];
                      fillets.pop();
                      setFillets([...fillets]);
                      canvasWhiteLines.pop();
                      setCanvasWhiteLines([...canvasWhiteLines]);
                      canvasArcs.pop();
                      setCanvasArcs([...canvasArcs]);
                      lastAngle.pop();
                      setLastAngle([...lastAngle]);

                      const angleEndCopy = JSON.parse(JSON.stringify(angleEnd));
                      if (angleEndCopy.length >= 3) {
                        checkDisableOnBorders(
                          angle_correction(
                            Number(angleEndCopy[angleEndCopy.length - 3]) +
                              gpsAngleWithReference
                          )
                        );
                      } else {
                        checkDisableOnBorders(gpsAngleWithReference);
                      }

                      angleEnd.pop();
                      setAngleEnd([...angleEnd]);
                      direction.pop();
                      setDirection([...direction]);
                      percentimeterArray.pop();
                      setPercentimeterArray([...percentimeterArray]);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              userSelect: "none",
              WebkitTouchCallout: "none" /* iOS Safari */,
              WebkitUserSelect: "none" /* Safari */,
              KhtmlUserSelect: "none" /* Konqueror HTML */,
              MozUserSelect: "none" /* Old versions of Firefox */,
              msUserSelect: "none" /* Internet Explorer/Edge */,
              width: `${window.innerWidth > 800 ? "600px" : "300px"}`,
              height: `${isMobile() ? "300px" : "266px"}`,
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10%",
              marginBottom: "8%",
              paddingBottom: `${isMobile() ? "21%" : "13%"}`,
            }}
          >
            <AngleLabels
              referenceAngle={referenceAngle}
              circleDiameter={circleDiameter}
              endAngle={sector.end_angle}
            />
            <canvas
              id="Base canva"
              ref={firstCanva}
              width={300}
              height={300}
              style={{ position: "absolute", zIndex: 899 }}
            />
            {canvasArcs.map((canva, index) => {
              return (
                <canvas
                  key={"canvaArc_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 820 + index,
                  }}
                />
              );
            })}
            {canvasWhiteLines.map((canva, index) => {
              return (
                <canvas
                  key={"canva_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 901 + index,
                  }}
                  onTouchMove={(e) => {
                    if (lastAngle.length > canvasWhiteLines.length - 1) {
                      lastAngle.pop();
                      setLastAngle((lastAngle) => [...lastAngle]);
                    }
                    handleMouseMove(
                      canva,
                      canvasArcs[index],
                      fillets[index],
                      referenceRadius * conversionRatio,
                      angleGps,
                      direction[index],
                      lastAngle[index - 1] ? lastAngle[index - 1] : -1,
                      undefined,
                      e
                    );
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "hidden";

                    if (lastAngle.length > canvasWhiteLines.length - 1) {
                      lastAngle.pop();
                      setLastAngle([...lastAngle]);
                    }
                    handleMouseMove(
                      canva,
                      canvasArcs[index],
                      fillets[index],
                      referenceRadius * conversionRatio,
                      angleGps,
                      direction[index],
                      lastAngle[index - 1] ? lastAngle[index - 1] : -1,
                      undefined,
                      e
                    );
                    e.stopPropagation();
                  }}
                  onTouchEnd={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "auto";
                  }}
                  onTouchStart={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "hidden";
                  }}
                />
              );
            })}
            {window.innerWidth > 800 ? (
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${latLngCenterPositionString}&zoom=${zoomLevel}&size=600x300&maptype=satellite${process.env.MAPS_KEY}`}
                style={{ width: "600px", height: "300px" }}
              />
            ) : (
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${latLngCenterPositionString}&zoom=${zoomLevel}&size=300x300&maptype=satellite${process.env.MAPS_KEY}`}
                style={{ width: "300px", height: "300px" }}
              />
            )}
          </div>
        </div>

        <MobileZone>
          <div
            className="by-angle-irrigation_v5__2-columns"
            style={{
              position: "fixed",
              bottom: 0,
              backgroundColor: "white",
              boxShadow:
                "0px -3px 0px -2px rgba(0, 0, 0, 0.2), 0px -2px 2px 0px rgba(0, 0, 0, 0.14), 0px -1px 5px 1px rgba(0, 0, 0, 0.12)",
              zIndex: 950,
            }}
          >
            <Button
              style={{
                width: "100%",
                height: "40px",
                background: "#EB5757",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                setAngleEnd([]);
                setPercentimeterArray([]);
                setDirection([]);
                dispatch(hideModal());
              }}
            >
              {i18n("IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO")}
            </Button>
            <Button
              style={{
                width: "100%",
                height: "40px",
              }}
              color="primary"
              variant="contained"
              onClick={nextPageButton}
              disabled={disableNextButtonState}
            >
              <>{i18n("SIGN_UP_NEXT")}</>
            </Button>
          </div>
        </MobileZone>

        <DesktopZone>
          <div className="by-angle-irrigation_v5__2-columns">
            <Button
              style={{
                width: "100%",
                height: "40px",
                background: "#EB5757",
                marginTop: "-20%",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                setAngleEnd([]);
                setPercentimeterArray([]);
                setDirection([]);
                dispatch(hideModal());
              }}
            >
              {i18n("IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO")}
            </Button>
            <Button
              style={{
                width: "100%",
                height: "40px",
                marginTop: "-20%",
              }}
              color="primary"
              variant="contained"
              onClick={nextPageButton}
              disabled={disableNextButtonState}
            >
              <>{i18n("SIGN_UP_NEXT")}</>
            </Button>
          </div>
        </DesktopZone>
      </div>
    </>
  ) : (
    <>
      {isLoading && <LinearProgress />}
      {/* {isMobile() ? <></> : <br></br>} */}
      <div className="by-angle-irrigation_v5">
        <div className="by-angle-irrigation_v5__inputs">
          <FormSelect
            id="pivot-config-operation-mode"
            label={"BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE"}
            state={[operationMode, setValidOperationMode]}
            values={[
              ["2", "BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY"],
              ["1", "BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET"],
            ]}
          />
          {operationMode === "1" && pivot?.controllerconfig?.injection_pump && (
            <FormCheckBox
              label={"SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP"}
              state={[injectionPumpStatus, setInjectionPumpStatus]}
            />
          )}
          {injectionPumpStatus && (
            <FormOutlinedInput
              id="input"
              type="text"
              label={"IRRIGATION_FIELD_INJECTION_PUMP_NOTE"}
              max="200"
              helperText={
                injectionPumpNote.length > 200 ? "ERROR_INVALID_AMOUNT_MAX" : ""
              }
              state={[injectionPumpNote, setInjectionPumpNote]}
              multiline={true}
            />
          )}

          <div className="by-angle-irrigation_v5__row">
            <FormSelect
              id="segment-start-mode"
              label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
              state={[startMode, setStartMode]}
              values={handleStartModeValues()}
            />
            <FormDateTimePicker
              id="start-hour"
              label={"PIVOT_CONFIG_FIELD_DATE_TIME"}
              state={[startDate, setStartDate]}
              disabled={startMode === "now"}
            />
            <Button
              className={
                isMobile() ? "by-angle-irrigation_v5__button-mobile" : ""
              }
              style={{
                width: isMobile() ? "100%" : "70%",
                height: "40px",
                background: "#EB5757",
                marginLeft: isMobile() ? "0px" : "35px",
                alignSelf: "center",
              }}
              color="primary"
              endIcon={<DeleteIcon>opacity</DeleteIcon>}
              variant="contained"
              onClick={() => {
                setTableRows([]);
                for (let index = fillets.length; index > 1; index--) {
                  angle = lastAngle[lastAngle.length - 1];
                  fillets.pop();
                  setFillets([...fillets]);
                  canvasWhiteLines.pop();
                  setCanvasWhiteLines([...canvasWhiteLines]);
                  canvasArcs.pop();
                  setCanvasArcs([...canvasArcs]);
                  lastAngle.pop();
                  setLastAngle([...lastAngle]);
                  angleEnd.pop();
                  setAngleEnd([...angleEnd]);
                  percentimeterArray.pop();
                  setPercentimeterArray([...percentimeterArray]);
                }
              }}
            >
              <div style={{ fontSize: "14px" }}>
                {i18n("PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM")}
              </div>
            </Button>
          </div>
        </div>
        <div className="by-angle-irrigation_v5__table">
          <div className="by-angle-irrigation_v5__table-head">
            <div>
              <ArrowBackIcon />
            </div>
            <div>
              <ArrowForwardIcon />
            </div>
            <div>
              <SwapHorizIcon />
            </div>
            <div>% | mm</div>
          </div>
          <div className="by-angle-irrigation_v5__table-body">
            {tableRows.map((row, index) => (
              <>
                <div>{tableRows[index].start_angle}º</div>
                <div>{tableRows[index].end_angle}º</div>
                <div>
                  {tableRows[index].direction == 2 ? (
                    <RotateLeftIcon />
                  ) : (
                    <RotateRightIcon />
                  )}
                </div>
                <div>
                  {" "}
                  {`${tableRows[index].speed}% | ${PTPToMillimeter(
                    pivot,
                    tableRows[index].speed
                  ).toFixed(2)} mm`}
                </div>
              </>
            ))}
          </div>

          <div
            style={{
              marginLeft: isMobile() ? "20px" : "7px",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                marginBottom: "4px",
              }}
            >
              {i18n("FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE")}
            </div>
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                marginBottom: "-8px",
              }}
            >
              <strong style={{ marginBottom: "10px", marginRight: "5px" }}>
                {i18n("IRRIGATION_END_DURATION")}
              </strong>
              {!duration?.duration ? (
                <Skeleton width={100} />
              ) : (
                duration.formattedDuration
              )}
            </div>
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                marginBottom: "-8px",
              }}
            >
              <strong style={{ marginRight: "5px" }}>
                {i18n("IRRIGATION_END_DATE_PREDICTION")}
              </strong>
              {!duration?.duration ? (
                <Skeleton width={60} />
              ) : (
                duration.dateDuration?.format("DD MMM HH:mm")
              )}
              <div style={{ margin: "0 0 2px -5px" }}>
                <InputTooltip
                  size={14}
                  description={i18n("IRRIGATION_END_DATE_PREDICTION_TOOLTIP")}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="by-angle-irrigation_v5__2-columns"
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          <Button
            style={{
              width: "100%",
              height: "40px",
              background: "#EB5757",
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setNextPage(false);
            }}
          >
            {i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}
          </Button>

          <Button
            className={
              isMobile() ? "by-angle-irrigation_v5__button-mobile" : ""
            }
            style={{ width: "100%", height: "40px" }}
            color="primary"
            disabled={
              tableRows.length === 0 ||
              isLoading ||
              injectionPumpNote.length > 200
            }
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : (
                <Icon>opacity</Icon>
              )
            }
            variant="contained"
            onClick={() => {
              var speedArray = [];
              var directionArray = [];
              var endgunArray = [];
              var angleArray = [];

              tableRows.forEach((row, index) => {
                speedArray.push(String(row.speed));
                directionArray.push(String(row.direction));
                angleArray.push(String(row.end_angle));
              });

              postScheduleIrrigation();
            }}
          >
            {i18n("BY_ANGLE_IRRIGATION_BUTTON_SEND")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default NewByAngleIrrigationV5;

import React, { useEffect } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { RecoilState, useRecoilState } from "recoil";
import { nullAtom } from "../../../recoils/NullRecoil";
import { ReactJSX } from "../../../utils/types";
import { i18n, i18nTextId } from "../../../i18n/i18nText";
import InputTooltip from "../../InputTooltip/InputTooltip";

interface Props {
  state: [boolean, (value: boolean) => void];
  label: string;
  disabled?: boolean;
  recoilState?: RecoilState<boolean | null>;
  labelTooltip?: string;
  classes?: string;
  fontSize?: string;
}

function FormCheckBox(props: Props) {
  const { state, label, labelTooltip } = props;
  const [recoilState, setRecoilState] = useRecoilState<boolean>(
    props.recoilState || nullAtom
  );

  useEffect(() => {
    if (props.recoilState) setRecoilState(state[0]);
  }, []);

  let isDisabled = false;
  if (props.disabled !== undefined && props.disabled === true) {
    isDisabled = true;
  }

  const LabelTooltip = labelTooltip ? (
    <InputTooltip description={i18n(labelTooltip as i18nTextId)} size={14} />
  ) : null;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          disabled={isDisabled}
          checked={props.recoilState ? recoilState : state[0]}
          onChange={() => {
            if (props.recoilState) setRecoilState(!recoilState);
            state[1](!state[0]);
          }}
          value="checkedB"
          color="primary"
        />
      )}
      label={(
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: props.fontSize,
            }}
          >
            {i18n(label as i18nTextId)}
            {LabelTooltip}
          </div>
        </>
      )}
    />
  );
}

export default FormCheckBox;

import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import styled from "@emotion/styled";
import { isMobile } from "../../../../../../../../mobileConfig";
import { showModal } from "../../../../../../../../redux/modal/actions";
import GpsStreamTable from "../GpsStreamTable/GpsStreamTable";
import { useDispatch } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../i18n/i18nText";
import {
  labelDisplayedRows,
  TablePaginationActions,
} from "../../../../../../../../components/GeneralTable/GeneralTable";
import { irrigationMode } from "../../../HistoricBox/utils/utils";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

function createData(
  start_date,
  end_date,
  hour_p,
  // hour_hfp,
  // hour_r,
  hour_total,
  water_blade,
  start_angle,
  end_angle,
  operation_note,
  irrigation_mode
) {
  return {
    start_date,
    end_date,
    hour_p,
    // hour_hfp,
    // hour_r,
    hour_total,
    water_blade,
    start_angle,
    end_angle,
    operation_note,
    irrigation_mode,
  };
}

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "start_date",
      label: i18n("REPORT_TABLE_START_DATE"),
    },
    {
      id: "end_date",
      label: i18n("REPORT_TABLE_END_DATE"),
    },
    {
      id: "hour_p",
      label: i18n("TABLE_HP"),
    },
    {
      id: "hour_total",
      label: i18n("REPORT_TABLE_START_TOTAL"),
    },
    {
      id: "water_blade",
      label: i18n("REPORT_TABLE_START_WATER_BLADE"),
    },
    {
      id: "irrigation_mode",
      label: i18n("REPORT_TABLE_IRRIGATION_MODE"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledHeadTableCell
            key={headCell.id}
            align={index === 2 ? "left" : "center"}
            padding={"default"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </StyledHeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: isMobile() ? "95vw" : "100%",
    overflowX: isMobile() ? "scroll" : "hidden",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    height: "100%",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

interface Props {
  pivotProtocol: number;
  pivotID: number;
  pivotConfig: any;
  startDate: any;
  endDate: any;
}

const StyledHeadTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
  background-color: white;
`;

function ReportTable(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = useState(0);
  const [reportListResults, setReportListResults] = useState<any>(undefined);
  const notify = useNotify();
  const rows = useMemo(() => {
    if (reportListResults === undefined) return; // Historic isn't loaded yet.
    return reportListResults.map((item) => {
      return createData(
        item.start_date,
        item.end_date,
        item.hour_p,
        // item.hour_hfp,
        // item.hour_r,
        item.hour_total,
        item.water_blade,
        item?.start_angle,
        item?.end_angle,
        item?.operation_note,
        item?.irrigation_mode
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportListResults]);

  const handleChangePage = (event, newPage) => {
    setReportListResults(undefined);
    getReportList(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    if (props.startDate && props.endDate) {
      getReportList();
    }
  }, [props.startDate, props.endDate]);

  async function getReportList(page = 0) {
    var startDate;
    var endDate;
    if (props.startDate === null || props.endDate === null) {
      startDate = moment().subtract(1, "month");
      endDate = moment();
    } else {
      startDate = props.startDate;
      endDate = props.endDate;
    }
    await new Promise(async (resolve) => {
      moment().zone();
      await coreHTTPClient
        // .post(`v3/reports/pivots/${props.pivot.id}/`, body)
        .get(
          `v3/reports/pivots/${
            props.pivotID
          }/list/?date_start=${startDate.toISOString()}&&date_end=${endDate.toISOString()}&&page=${
            page + 1
          }`
        )
        .then((res) => {
          setReportListResults(res.data.results);
          setCount(res.data.count);
        })
        .catch((err) => {});
    });
  }

  const emptyRows = new Array(10).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        <StyledTableCell align="center" style={{ width: "25%" }}>
          <Skeleton />
        </StyledTableCell>
        <StyledTableCell align="center" style={{ width: "25%" }}>
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell>
          <Skeleton />
        </StyledTableCell>
      </TableRow>
    );
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {rows !== undefined
                ? rows.map((row, index) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          if (isMobile()) {
                            notify(
                              "BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY",
                              "default",
                              4000
                            );
                            return;
                          }
                          dispatch(
                            showModal({
                              content: (
                                <GpsStreamTable
                                  pivotProtocol={props.pivotProtocol}
                                  pivotID={props.pivotID}
                                  pivotConfig={props.pivotConfig}
                                  startDate={moment(row.start_date)}
                                  endDate={moment(row.end_date)}
                                />
                              ),
                              title: "GPS_STREAM_TABLE_TITLE",
                            })
                          );
                        }}
                      >
                        <StyledTableCell
                          align="center"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                            width: "25%",
                          }}
                        >
                          <div>
                            {moment(row.start_date).format("DD/MM HH:mm")}
                          </div>
                          <div>
                            {row?.start_angle ? (
                              <>
                                {parseInt(row?.start_angle)}
                                {"°"}
                              </>
                            ) : null}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                            width: "25%",
                          }}
                        >
                          <div>
                            {moment(row.end_date).format("DD/MM HH:mm")}
                          </div>
                          <div>
                            {row?.end_angle ? (
                              <>
                                {parseInt(row?.end_angle)}
                                {"°"}
                              </>
                            ) : null}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                          }}
                        >
                          {i18n("VALUE_AND_UNIT_HOUR", [
                            formatFloatValue(parseFloat(row.hour_p)),
                          ])}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                          }}
                        >
                          {i18n("VALUE_AND_UNIT_HOUR", [
                            formatFloatValue(parseFloat(row.hour_total)),
                          ])}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                          }}
                        >
                          {i18n("VALUE_AND_UNIT_MILIMETERS", [
                            formatFloatValue(parseFloat(row.water_blade)),
                          ])}
                        </StyledTableCell>

                        <StyledTableCell
                          align="center"
                          style={{
                            backgroundColor:
                              row.water_blade > 0
                                ? "rgba(70, 121, 237, 0.05)"
                                : "rgba(147, 66, 0, 0.05)",
                          }}
                        >
                          {row?.operation_note ? (
                            <>
                              {irrigationMode(row?.irrigation_mode)}
                              <InputTooltip
                                size={18}
                                description={row?.operation_note}
                              />
                            </>
                          ) : (
                            <>{irrigationMode(row?.irrigation_mode)}</>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                : emptyRows}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onChangePage={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}

export default React.memo(ReportTable);

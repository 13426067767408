import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import InfoBox from "../../../../../../Pivot/SelectedPivot/components/BasicInfoBox/components/InfoBox/InfoBox";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IMeterCompleteAtomFamily } from "../../../../../../../../recoils/MeterSystemRecoil";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../../../redux/filterByDate/types";
import { ApplicationState } from "../../../../../../../../redux";
import { useSelector } from "react-redux";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import FilterByDate from "../../../../../../../../components/FilterByDate/FilterByDate";
import { SensorValueByTimeChart } from "../WaterLevelCharts/Charts/SensorValueByTimeChart";
import { isMobile } from "../../../../../../../../mobileConfig";
import { i18n } from "../../../../../../../../i18n/i18nText";

interface Props {
  iMeterID: number;
}

const Container = styled.div`
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const ValueContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ValueBox = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const Value = styled.div`
  font-size: 4.5em;
  font-weight: bold;
`;

const Unit = styled.div`
  font-size: 1.5em;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const GridItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface MeasureProps {
  value: number;
  unit: string;
}

function MeasureValue(props: MeasureProps) {
  return (
    <ValueContainer>
      <ValueBox>
        <Value>{props.value}</Value>
        <Unit>{props.unit}</Unit>
      </ValueBox>
    </ValueContainer>
  );
}

function WaterFlowCharts(props: Props) {
  const farmID = useParams().farm;
  const metersystemID = useParams().metersystem;

  const imeter = useRecoilValue(IMeterCompleteAtomFamily(props.iMeterID));

  const [waterFlowByTimeChartData, setWaterFlowByTimeChartData] = useState<any>(
    []
  );

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  useEffect(() => {
    (async () => {
      try {
        let waterLevelByTimeChart = await coreHTTPClient.get(
          `v3/farms/${farmID}/metersystems/${metersystemID}/meter/${props.iMeterID}/imeter-streams/`
        );
        setWaterFlowByTimeChartData(waterLevelByTimeChart.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      filterByDate.data.start_date &&
      filterByDate.data.end_date &&
      filterByDate.data.type === FilterTypesChoices.REPORT
    ) {
      var date_end = filterByDate.data.end_date.toISOString();
      var date_start = filterByDate.data.start_date.toISOString();
      (async () => {
        try {
          let waterLevelByTimeChart = await coreHTTPClient.get(
            `v3/farms/${farmID}/metersystems/${metersystemID}/meter/${props.iMeterID}/imeter-streams/?date_start=${date_start}&date_end=${date_end}`
          );
          setWaterFlowByTimeChartData(waterLevelByTimeChart.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [filterByDate]);

  const waterFlowChartByTimeRightElement = (
    <div>
      <FilterByDate title={null} type={FilterTypesChoices.REPORT} />
    </div>
  );

  const totalFlow = () => {
    const measure1 =
      imeter.latest_periodic_stream?.content?.imanage_sensor_measure_value?.[1]
        ?.value;

    const measure2 =
      imeter.latest_periodic_stream?.content?.imanage_sensor_measure_value?.[2]
        ?.value - 3;

    if (!measure1 || !measure2) {
      return 0;
    }

    return (
      imeter.latest_periodic_stream?.content?.imanage_sensor_measure_value?.[1]
        ?.value *
      Math.pow(
        10,
        imeter.latest_periodic_stream?.content
          ?.imanage_sensor_measure_value?.[2]?.value - 3
      )
    );
  };

  return (
    <div>
      <Container>
        <GridItem style={isMobile() ? { gridColumn: "1/3" } : null}>
          <InfoBox centerElement={<>{i18n("METERSYSTEM_CURRENT_FLOW")}</>}>
            <MeasureValue
              value={
                imeter.latest_periodic_stream?.content
                  ?.imanage_sensor_measure_value?.[0]?.value
              }
              unit={"m³/s"}
            />
          </InfoBox>
        </GridItem>
        <GridItem style={isMobile() ? { gridColumn: "1/3" } : null}>
          <InfoBox centerElement={<>{i18n("METERSYSTEM_TOTAL_FLOW")}</>}>
            <MeasureValue value={totalFlow()} unit={"m³"} />
          </InfoBox>
        </GridItem>
        <div style={{ gridColumn: "1/3" }}>
          <InfoBox
            centerElement={<div>{i18n("METERSYSTEM_FLOW_BY_TIME")}</div>}
            rightElement={waterFlowChartByTimeRightElement}
          >
            <SensorValueByTimeChart data={waterFlowByTimeChartData} />
          </InfoBox>
        </div>
      </Container>
    </div>
  );
}

export default React.memo(WaterFlowCharts);

import React, { useMemo } from "react";
import { Column } from "../../../../../../../../components/GeneralTable/GeneralTable";
import { Pivot } from "../../../../../../../../redux/pivots/types";
import useConfigHistoric from "../../../../../../../../hooks/models/useConfigHistoric";
import moment from "moment";
import { ReactJSX } from "../../../../../../../../utils/types";
import "./HistoricConfigOld.scss";
import { getModeAdd } from "../../../../../../../../utils/models/pivots";
import ExpandedContentConfigOld from "../ExpandedContent/ExpandedContentConfigOld/ExpandedContentConfigOld";
import ConfigHistoryTable from "../ConfigHistoryTable";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import RemoveIcon from "@material-ui/icons/Remove";
import { Tooltip, Button } from "@material-ui/core";
import { i18n } from "../../../../../../../../i18n/i18nText";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import { Labels } from "../../../EditPivotForm/components/HistoricConfigMobile/HistoricConfigMobileV5";

const noPointer = {
  cursor: "default",
  marginLeft: 18,
};

const getDelivery = (reason: boolean) => {
  if (reason)
    return <DoneAllIcon style={noPointer} htmlColor={"rgb(33, 150, 243)"} />;
  else return <RemoveIcon style={noPointer} htmlColor={"rgba(0,0,0,0.6)"} />;
};

interface Props {
  pivot: Pivot;
  farmID: number;
  pivotID: number;
  ctx?: any;
  pinned?: boolean;
}

const HistoricConfigOld = (props: Props) => {
  const { pivot, farmID, pivotID, ctx } = props;
  const [
    async,
    historic,
    total,
    previousPage,
    nextPage,
    currentPage,
  ] = useConfigHistoric(farmID, pivotID, props.pinned);

  const pinnedcolumns: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },

    {
      id: "controller",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_CONTROLLER")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_CONTROLLER")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
    },
    {
      id: "gps",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_GPS")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_GPS")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
    },
  ];

  const mobcolumns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
      align: "left",
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
      align: "left",
    },

    {
      id: "controller",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_CONTROLLER")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_CONTROLLER")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "left",
    },
    {
      id: "gps",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_GPS")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_GPS")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "left",
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "left",
    },
  ];

  const rows = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.manual, item.username),
          ])}
        </span>
      );

      const gps = (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_GPS")}</>}>
          {getDelivery(item.gps_delivered)}
        </Tooltip>
      );

      const controller = (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_CONTROLLER")}</>}>
          {getDelivery(item.delivered)}
        </Tooltip>
      );

      const pump = (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          {getDelivery(item.pump_delivered)}
        </Tooltip>
      );
      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        controller: controller,
        gps: gps,
        pump: pump,
        datailedComponent: (): ReactJSX => (
          <ExpandedContentConfigOld
            pivotID={pivotID}
            farmID={farmID}
            pivot={pivot}
            pivotConfig={item}
            ctx={ctx}
          />
        ),
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic]);

  return (
    <div>
      <DesktopZone>
        <Labels pivot={pivot} />
      </DesktopZone>

      <ConfigHistoryTable
        columns={props.pinned ? pinnedcolumns : mobcolumns}
        total={total}
        rows={rows}
        rowsPerPage={10}
        loading={historic === undefined || rows === undefined || async.loading}
        nextPage={nextPage}
        previousPage={previousPage}
        currentPage={currentPage}
      />

      <DesktopZone>
        <div style={{ marginBottom: "20px" }} />
      </DesktopZone>
    </div>
  );
};

export default HistoricConfigOld;

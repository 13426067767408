import { coreHTTPClient } from "../../services/webclient";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import useNotify from "../tools/useNotify";

const useStopPivot = () => {
  const dispatch = useDispatch();
  const notify = useNotify();

  async function stopPivot(farmID: number, pivotID: number) {
    await new Promise(async (resolve) => {
      try {
        await coreHTTPClient.post(
          `/v3/farms/${farmID}/pivots/${pivotID}/actions/stop/`
        );
        notify("GENERAL_BOX_STOP_PIVOT_SUCCESS", "success");
      } catch (err) {
        notify("GENERAL_BOX_STOP_PIVOT_FAILURE", "error");
      }
    }).finally(() => {
      dispatch(hideModal());
    });
  }

  return [stopPivot];
};

export default useStopPivot;

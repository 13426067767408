import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import DashboardBox from "../../pages/Dashboard/components/DashboardBox/DashboardBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./EditFormsPlaceholder.scss";
import { i18n } from "../../i18n/i18nText";

interface Props {
  goBack: Function;
  centerElement: JSX.Element;
}

export default function EditFormsPlaceholder(props: Props) {
  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        props.goBack();
      }}
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const saveButton = (
    <Button
      color="primary"
      endIcon={<CheckCircleIcon />}
      onClick={() => {}}
      disabled
    >
      <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
    </Button>
  );

  return (
    <div style={{ width: "100%" }}>
      <DashboardBox
        leftElement={backButton}
        centerElement={props.centerElement}
        rightElement={saveButton}
      >
        <div className="form-placeholder"></div>
      </DashboardBox>
    </div>
  );
}

import { Tab, Tabs, Typography } from "@material-ui/core";
import { EventNote, Star } from "@material-ui/icons";
import React, { useState } from "react";
import { Pivot } from "../../../../../../../../redux/pivots/types";
import HistoricConfigOld from "../../../DrawerRightEditPivot/components/Historic/HistoricConfigOld";
import PropTypes from "prop-types";
import { Labels } from "./HistoricConfigMobileV5";

interface Props {
  farmID: number;
  pivotID: number;
  ctx: any;
  pivot: Pivot;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <div style={{ padding: "0px 10px 3px 0" }}>{children}</div>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function HistoricConfigMobileV4(props: Props) {
  const { farmID, pivotID, ctx, pivot } = props;
  //Configuração do tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Labels pivot={pivot} />
        <Tabs
          orientation="horizontal"
          value={value}
          centered={true}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Configuration Historic"
          variant="fullWidth"
        >
          <Tab
            style={{
              minWidth: 0,
              borderTop: "1px solid rgba(0,0,0,0.1)",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <EventNote
                color="inherit"
                htmlColor={value === 0 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(0)}
          />
          <Tab
            style={{
              minWidth: 0,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              borderTop: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <Star
                color="inherit"
                htmlColor={value === 1 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <HistoricConfigOld
            pivot={pivot}
            farmID={farmID}
            pivotID={pivotID}
            ctx={ctx}
            pinned={false}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HistoricConfigOld
            farmID={farmID}
            pivotID={pivotID}
            pivot={pivot}
            ctx={ctx}
            pinned={true}
          />
        </TabPanel>
      </div>
    </div>
  );
}

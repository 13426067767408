import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  IMeterCompleteAtomFamily,
  IMeterStreamPeriodicFamily,
} from "../../../../../../../../recoils/MeterSystemRecoil";
import useSocketIO from "../../../../../../../../hooks/tools/useSocketIO";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../../../../../../Devices/components/DrawerRightDevices/DrawerRightDevices";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import { i18n } from "../../../../../../../../i18n/i18nText";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../../../redux/filterByDate/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../redux";
import FilterByDate from "../../../../../../../../components/FilterByDate/FilterByDate";
import { PulseflowValueByTimeChart } from "../WaterLevelCharts/Charts/PulseflowValueByTimeChart";
import { motion } from "framer-motion";
import moment from "moment";

const { SOCKET_SUFFIX } = process.env;

interface Props {
  iMeterID: number;
}

const ValueContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ValueBox = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

const Value = styled.div`
  font-size: 4.5em;
  font-weight: bold;

  @media only screen and (max-width: 1024px) {
    font-size: 3.5em;
  }
`;

const Unit = styled.div`
  font-size: 2em;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 0 17px 5px;

  @media only screen and (max-width: 1024px) {
    margin: 0 0 10px 5px;
  }
`;
interface MeasureProps {
  value: number;
  unit: string;
}

function MeasureValue(props: MeasureProps) {
  if (props.value === undefined) {
    return (
      <ValueContainer style={{ marginBottom: "-30px" }}>
        <CircularProgress size={120} />
      </ValueContainer>
    );
  } else
    return (
      <ValueContainer style={{ marginBottom: "-30px" }}>
        <ValueBox>
          <Value>{props.value}</Value>
          <Unit>{props.unit}</Unit>
        </ValueBox>
      </ValueContainer>
    );
}

function WaterFlowCharts(props: Props) {
  const farmID = useParams().farm;
  const metersystemID = useParams().metersystem;

  const [tabIndex, setValue] = useState(0);

  const [isConnected, socket] = useSocketIO();

  const [cumulative, setCumulative] = useState(undefined);

  const setImeterStreamPeriodicState = useSetRecoilState(
    IMeterStreamPeriodicFamily(props.iMeterID)
  );

  const [startDate, setStartDate] = useState(moment().subtract(24, "hour"));

  const imeter = useRecoilValue(IMeterCompleteAtomFamily(props.iMeterID));

  const [loading, setLoading] = useState(true);

  const [pulseFlowByTimeChartData, setPulseFlowByTimeChartData] = useState<any>(
    []
  );

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const handleTabChange = (event, newValue) => {
    setValue(() => {
      setLoading(true);
      setCumulative(undefined);
      return newValue;
    });
  };

  const getScale = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return "24h";
      case 1:
        return "48h";
      case 2:
        return "72h";
      case 3:
        return "1s";
      case 4:
        return "1m";
      default:
        return "24h";
    }
  };

  const setProperStartDate = (scale: string) => {
    switch (scale) {
      case "24h":
        setStartDate(moment().subtract(24, "hours"));
        return;
      case "48h":
        setStartDate(moment().subtract(48, "hours"));
        return;
      case "72h":
        setStartDate(moment().subtract(72, "hours"));
        return;
      case "1s":
        setStartDate(moment().subtract(7, "days"));
        return;
      case "1m":
        setStartDate(moment().subtract(30, "days"));
        return;
      default:
        setStartDate(moment().subtract(24, "hours"));
        return;
    }
  };

  const accumulatedVolume = useMemo(() => {
    return `${
      (imeter.latest_periodic_stream?.content?.pulse_counter_readings
        ?.totalized_volume_measured +
        4294967295 *
          imeter.latest_periodic_stream?.content?.pulse_counter_readings
            ?.overflow_quantity) *
      imeter.latest_config.measure_scale
    }${" "} ${imeter.latest_config.measure_unit}`;
  }, [imeter.id, imeter.latest_config, imeter.latest_periodic_stream]);

  useEffect(() => {
    setImeterStreamPeriodicState(imeter?.latest_periodic_stream);
    socket.subscribe(`${SOCKET_SUFFIX}imeter@${imeter?.id}`);
    socket.bind("IMeterStream_periodic", (data) => {
      setImeterStreamPeriodicState(data);
    });

    return () => {
      socket.unbind("IMeterStream_periodic");
      socket.unsubscribe(`${SOCKET_SUFFIX}imeter@${imeter?.id}`);
    };
  }, []);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      try {
        let pulseflowByTimeChart = await coreHTTPClient.get(
          `v3/farms/${farmID}/metersystems/${metersystemID}/meter/${
            props.iMeterID
          }/imeter-streams/?date_start=${startDate.toISOString()}&date_end=${moment().toISOString()}`
        );
        setPulseFlowByTimeChartData(pulseflowByTimeChart.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, [startDate, imeter.latest_periodic_stream]);

  useEffect(() => {
    // setCumulative(undefined);
    var frequency = "24h";
    frequency = getScale(tabIndex);
    setProperStartDate(frequency);
    (async () => {
      coreHTTPClient
        .get(
          `v3/farms/${farmID}/metersystems/${metersystemID}/meter/${imeter.id}/cumulative/${frequency}/`
        )
        .then((res) => {
          setCumulative(
            res.data?.cumulative * imeter.latest_config?.measure_scale
          );
        });
      // .catch(() => {
      //   setCumulative(undefined);
      // });
    })();
  }, [
    tabIndex,
    imeter.id,
    imeter.latest_config,
    imeter.latest_periodic_stream,
  ]);

  const pulseflowChartByTimeRightElement = (
    <div>
      <FilterByDate title={null} type={FilterTypesChoices.REPORT} />
    </div>
  );

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          opacity: 0.7,
          paddingBottom: "10px",
          fontSize: "15.75px",
          textAlign: "center",
        }}
      >
        {i18n("CHOOSE_ACCUMULATED_VOL_PERIOD")}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Tabs
          orientation={"horizontal"}
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          aria-label="Cumulative volume"
          centered
          variant={"scrollable"}
        >
          <Tab label={i18n("HOURS_24")} />
          <Tab label={i18n("HOURS_48")} />
          <Tab label={i18n("HOURS_72")} />
          <Tab label={i18n("WEEK_1")} />
          <Tab label={i18n("MONTH_1")} />
        </Tabs>
      </div>
      <TabPanel value={tabIndex} index={0}>
        <MeasureValue
          value={cumulative}
          unit={imeter.latest_config.measure_unit}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <MeasureValue
          value={cumulative}
          unit={imeter.latest_config.measure_unit}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <MeasureValue
          value={cumulative}
          unit={imeter.latest_config.measure_unit}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <MeasureValue
          value={cumulative}
          unit={imeter.latest_config.measure_unit}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <MeasureValue
          value={cumulative}
          unit={imeter.latest_config.measure_unit}
        />
      </TabPanel>
      {/* <div style={{ textAlign: "center" }}>
        <strong>{i18n("ACCUMULATED_VOLUME")}:</strong> {accumulatedVolume}
      </div>
      <div style={{ textAlign: "center", marginTop: "8px" }}>
        <strong>{i18n("APPROXIMATE_USAGE_TIME")}</strong>{" "}
        {`${imeter.latest_periodic_stream?.content?.pump_hourmeter?.hours}h ${imeter.latest_periodic_stream?.content?.pump_hourmeter?.minutes}min`}
      </div> */}
      <motion.div
        animate={
          loading
            ? {
                opacity: 0.2,
                scale: 0.9,
              }
            : {
                opacity: 1,
                scale: 1,
              }
        }
        style={{ width: "100%", height: "300px", marginTop: "20px" }}
      >
        <PulseflowValueByTimeChart
          data={pulseFlowByTimeChartData}
          frequency={getScale(tabIndex)}
          scale={imeter?.latest_config?.measure_scale}
          unit={imeter?.latest_config?.measure_unit}
        />
      </motion.div>
    </div>
  );
}

export default React.memo(WaterFlowCharts);

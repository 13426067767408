export enum BillingTypes {
  SET_BILLING = "@@billing/STORE_BILLING",
  UPDATE_OR_INSERT_BILLING = "@@billing/UPDATE_OR_INSERT_BILLING",
  RESET = "@@billing/RESET",
}

export interface Billing {
  id?: string;
  document_type: number;
  document: string;
  farm: number | null;
  company_name: string;
  country: string;
  state: string;
  city: string;
  address: string;
  postal_code: string;
  email: string;
  phone: string;
  payment_status?: number;
  language?: string;
}

export interface BillingState {
  data: Billing | null;
}

import React, { useState, useEffect, useMemo } from "react";
import { IrpdStream, Irpd } from "../../redux/irpds/types";
// Panel stream status da irpd e do pivot são iguais??
import {
  IrpdStreamStatus,
  getIrpdStreamStatus,
  getLatestIrpdStreamV5,
} from "../../utils/models/irpds";
import { dateGetLatest } from "../../utils/types";

function useIrpdStatus(
  irpd: Irpd
): [
  IrpdStreamStatus,
  string,
  IrpdStream,
  (
    | React.Dispatch<React.SetStateAction<any>>
    | React.Dispatch<React.SetStateAction<IrpdStream>>
  )
] {
  const [latestIrpdStream, setLatestIrpdStream] = useState<IrpdStream>();

  const [latestIrpdStreamV5, setLatestIrpdStreamV5] = useState(
    getLatestIrpdStreamV5(irpd)
  );

  useEffect(() => {
    if (irpd) {
      setLatestIrpdStreamV5(getLatestIrpdStreamV5(irpd));
    }
  }, [irpd]);

  useEffect(() => {
    setLatestIrpdStream(irpd?.latest_irpd_stream);
  }, [irpd]);

  const latestUpdate = useMemo(() => {
    if (latestIrpdStream !== null) {
      try {
        //
        return dateGetLatest([latestIrpdStream?.created]).format(
          "DD MMM HH:mm"
        );
      } catch {
        return "-";
      }
    } else if (latestIrpdStreamV5 != null) {
      try {
        //
        return dateGetLatest([latestIrpdStreamV5?.created]).format(
          "DD MMM HH:mm"
        );
      } catch {
        return "-";
      }
    }
  }, [latestIrpdStream, latestIrpdStreamV5]);

  const latestStatus: IrpdStreamStatus = useMemo(() => {
    if (latestIrpdStream !== undefined)
      return getIrpdStreamStatus(latestIrpdStream?.reason);
  }, [latestIrpdStream]);

  return [latestStatus, latestUpdate, latestIrpdStream, setLatestIrpdStream];
}

export default useIrpdStatus;

import React from "react";
import styled from "@emotion/styled";
import {
  IMeterCompleteAtomFamily,
  IMeterFunction,
} from "../../../../../../recoils/MeterSystemRecoil";
import { useRecoilValue } from "recoil";
import WaterLevelCharts from "./components/WaterLevelCharts/WaterLevelCharts";
import WaterFlowCharts from "./components/WaterFlowCharts/WaterFlowCharts";
import WaterFlowByPulseCharts from "./components/WaterFlowByPulseCharts/WaterFlowByPulseCharts";

const Container = styled.div`
  padding-bottom: 60px;
  width: 100%;
  height: 100%;
`;

interface Props {
  iMeterID: number;
}

export function getProperMeterChartsComponent(
  imeterFunction: IMeterFunction,
  iMeterID: number
): JSX.Element {
  switch (imeterFunction) {
    case IMeterFunction.LEVEL:
      return <WaterLevelCharts iMeterID={iMeterID} />;
    case IMeterFunction.FLOW:
      return <WaterFlowCharts iMeterID={iMeterID} />;
    case IMeterFunction.PULSE_FLOW:
      return <WaterFlowByPulseCharts iMeterID={iMeterID} />;
    default:
      return <></>;
  }
}

function MeterSystemCharts(props: Props) {
  const imeter = useRecoilValue(IMeterCompleteAtomFamily(props.iMeterID));

  if (!imeter) return null;

  return (
    <Container>
      {getProperMeterChartsComponent(imeter.function, props.iMeterID)}
    </Container>
  );
}

export default React.memo(MeterSystemCharts);

import { Reducer } from "redux";
import { NotificationsState, NotificationsTypes, Notification } from "./types";
import { NotificationActions } from "./actions";
import { arrUpdateOrInsert, arrDelete } from "../../utils/types";

const INITIAL_STATE: NotificationsState = {
  list: null,
};

const reducer: Reducer<NotificationsState, NotificationActions> = (
  state = INITIAL_STATE,
  action: NotificationActions
): NotificationsState => {
  const { type, payload }: { type: string; payload: any } = action;

  switch (type) {
    case NotificationsTypes.SET_NOTIFICATIONS:
      return { ...state, list: payload };

    case NotificationsTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        list: arrDelete<Notification>(state.list, payload, "id"),
      };

    case NotificationsTypes.UPDATE_OR_INSERT_NOTIFICATION:
      return {
        ...state,
        list: arrUpdateOrInsert<Notification>(state.list, payload, "id"),
      };

    default:
      return state;
  }
};

export default reducer;

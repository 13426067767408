import "./RepeaterItem.scss";
import { Slide } from "@material-ui/core";
import React from "react";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import { Repeater } from "../../../../../../../../recoils/RepeaterRecoil";
import routes from "../../../../../../../../routes/routes";
import { useNavigate } from "react-router";

export interface SelectedFarmParams {
  farm: string;
}

interface Props {
  repeater: Repeater;
}

function RepeaterItem(props: Props) {
  let { repeater } = props;
  const navigate = useNavigate();
  const getIrpdImage = (repeater) => {
    const repeaterPosition = repeater.position.split(",");
    const cp = {
      lat: parseFloat(repeaterPosition[0]),
      lng: parseFloat(repeaterPosition[1]),
    };

    const marker = `&markers=color:0xc3fc49ff|${cp.lat},${cp.lng}`;
    const center = `&center=${cp.lat},${cp.lng}`;

    return (
      <img
        src={
          "https://maps.googleapis.com/maps/api/staticmap?sensor=false&maptype=satellite&sensor=false&size=320x320&zoom=18" +
          center +
          process.env.MAPS_KEY +
          marker
        }
      />
    );
  };

  return (
    <>
      <MobileZone>
        <Slide direction="down" in={true}>
          <div
            className="repeater-item"
            onClick={() =>
              navigate(
                routes.DASHBOARD.EDIT_REPEATER.replace(
                  ":farm",
                  String(repeater.farm)
                ).replace(":repeater", String(repeater.id))
              )
            }
          >
            <div className="repeater-item-img">{getIrpdImage(repeater)}</div>
            <div className="repeater-item__text-content">
              <div className="repeater-item__title">{repeater.name}</div>
            </div>
          </div>
        </Slide>
      </MobileZone>

      <DesktopZone>
        <div
          className="repeater-item"
          onClick={() =>
            navigate(
              routes.DASHBOARD.EDIT_REPEATER.replace(
                ":farm",
                String(repeater.farm)
              ).replace(":repeater", String(repeater.id))
            )
          }
        >
          <div className="repeater-item__text-content">
            <div className="repeater-item__title">{repeater.name}</div>
          </div>
        </div>
      </DesktopZone>
    </>
  );
}

export default RepeaterItem;

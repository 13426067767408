import { atom, useSetRecoilState } from "recoil";

export const DrawerRecoil = atom({
  key: "DrawerRecoil",
  default: false,
});

export function useDrawer() {
  const setDrawer = useSetRecoilState(DrawerRecoil);

  function openDrawer() {
    setDrawer(true);
  }

  function closeDrawer() {
    setDrawer(false);
  }

  function toggleDrawer() {
    setDrawer((d) => !d);
  }

  return {
    openDrawer,
    closeDrawer,
    toggleDrawer,
  };
}

import { LoadingState, LoadingTypes } from "./types";
import { LoadingActions } from "./actions";

const INITIAL_STATE: LoadingState = {
  data: {
    isLoading: false,
  }
};

function reducer(state = INITIAL_STATE, action: LoadingActions) {
  const { type }: { type: string } = action;

  switch (type) {
    case LoadingTypes.SHOW_LOADING:
      return { ...state, data: { isLoading: true } };

    case LoadingTypes.HIDE_LOADING:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
}

export default reducer;

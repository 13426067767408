import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Done, DoneAll, Edit, Error } from "@material-ui/icons";
import { blue, red, grey } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";
import { MessageStatus } from "../../../../../../../Pivot/EditPivot/EditPivot";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { showModal } from "../../../../../../../../../redux/modal/actions";
import { useParams } from "react-router";
import ModalChangeBase from "./ModalChangeBase/ModalChangeBase";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../../mobileConfig";
import QrReader from "modern-react-qr-reader";

/*
Editar rádio:
{{url}}/v3/farms/{{farm}}/pivots/{{pivot}}/controller/
*/

function getRadioStatusIcon(status: MessageStatus): JSX.Element {
  switch (status) {
    case MessageStatus.WAITING:
      return <CircularProgress size={18} />;
    case MessageStatus.SENT:
      return <Done style={{ color: "darkgray" }} />;
    case MessageStatus.DELIVERED:
      return <DoneAll style={{ color: blue[500] }} />;
    case MessageStatus.ERROR:
      return <Error style={{ color: red[500] }} />;
    default:
      return <></>;
  }
}

type Props = {
  radioId: string;
  setRadioId: (value: string) => void;
  label: string;
  status: MessageStatus;
  locked?: boolean;
  editBtn?: boolean;
  showQrCode?: boolean;
  setRadioIdError?: any;
};

let globalResolve: (data: string) => void;
let globalReject: (data: string) => void;

export default function BaseRadioField(props: Props) {
  const [showQrReader, setShowQrReader] = useState(false);
  const [radioId, setRadioId] = useState("");

  const dispatch = useDispatch();

  const [hasRadioIdErrors, setHasRadioIdErrors] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const notify = useNotify();

  function validateRadioId(radioId: string) {
    if (radioId.match(/^[a-fA-F0-9]{16}$/) === null) {
      setHasRadioIdErrors(true);
      props.setRadioIdError(true);
    } else {
      setHasRadioIdErrors(false);
      props.setRadioIdError(false);
    }
    props.setRadioId(radioId);
  }
  const farmID: number = parseInt(useParams().farm, 10);

  function openChangeBaseModal() {
    dispatch(
      showModal({
        content: (
          <ModalChangeBase
            setRadioId={props.setRadioId}
            radioId={props.radioId}
            farmID={farmID}
          />
        ),
        title: "CHANGE_BASE_RADIO_MODAL_TITLE",
      })
    );
  }

  function editButtonOnClick() {
    setEditable(true);
  }

  function cancelButtonOnClick() {
    setEditable(false);
    setHasRadioIdErrors(false);
  }

  useEffect(() => {
    setRadioId(props.radioId);
  }, [props.radioId]);

  async function showQrReaderWithMode(mode: "RADIO_ID") {
    setShowQrReader(true);

    const data = await new Promise<string>((resolve) => {
      globalResolve = resolve;
    });

    window.alert(`${i18n("CREATE_DEVICE_MODAL_CODE_READ_SUCCESS")}\n${data}`);

    setShowQrReader(false);

    if (mode == "RADIO_ID") {
      setRadioId(data.trim());
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor:
            props.status === MessageStatus.DELIVERED ? blue[50] : grey[200],
          padding: "10px",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" width="100%">
            <div style={{ fontSize: "14px", fontWeight: 600 }}>
              {props.label}
            </div>
            <div className="device-row mb-30">
              <div className="input">
                <TextField
                  InputProps={{
                    endAdornment: getRadioStatusIcon(props.status),
                  }}
                  value={radioId}
                  onChange={(event) => validateRadioId(event.target.value)}
                  disabled={props.locked === true}
                  error={hasRadioIdErrors}
                  fullWidth
                />
              </div>

              {isMobile() && props.showQrCode ? (
                <div
                  className="camera-button"
                  onClick={async () => {
                    showQrReaderWithMode("RADIO_ID");
                  }}
                >
                  <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                    <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </Box>
          <Box display="flex" flexDirection="column">
            {props.editBtn ? (
              <Tooltip placement="right" title={i18n("EDIT_RADIO")}>
                <IconButton size="small" onClick={openChangeBaseModal}>
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </div>
      {showQrReader && (
        <div className="qr-reader-root">
          <div className="close-button" onClick={() => setShowQrReader(false)}>
            X
          </div>

          <QrReader
            delay={300}
            facingMode={"environment"}
            onError={(data) => globalReject && globalReject(data)}
            onScan={(data) => globalResolve && data && globalResolve(data)}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </>
  );
}

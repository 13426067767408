import { atom } from "recoil";
import { FarmBauerPivotObj } from "../redux/bauerPivot/types";

export const BauerPivotState = atom<FarmBauerPivotObj | null>({
  key: "BauerPivotState",
  default: null,
});

export const BauerPivotStateList = atom<FarmBauerPivotObj[] | null>({
  key: "BauerPivotStateList",
  default: [],
});

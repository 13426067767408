import { atom, selector } from "recoil";
import { coreHTTPClient } from "../services/webclient";
/**
 * ISO 639-1 STANDARD LANGUAGE CODES
 * https://www.andiamo.co.uk/resources/iso-language-codes/
 */

export enum Language {
  PORTUGUESE_BRAZIL = "pt-br",
  GERMAN_AUSTRIA = "de-at",
  ENGLISH = "en",
  RUSSIAN = "ru",
  SPAIN_SPANISH = "es",
  I18N_KEYS = "keys",
}

export interface Setting {
  language?: Language;
  name?: any;
}

export const SettingsState = atom<Setting | null>({
  key: "LanguageState",
  default: {
    language: null,
    name: localStorage.getItem("firstName")
      ? localStorage.getItem("firstName")
      : null,
  },
});

export const SettingLanguage = selector({
  key: "SettingLanguage",
  get: ({ get }) => get(SettingsState).language,
  set: ({ set, get }, language: Language) => {
    localStorage.setItem("language", language);
    set(SettingsState, { ...get(SettingsState), language: language });
  },
});

export const SettingFistName = selector({
  key: "FistName",
  get: ({ get }) => get(SettingsState).name,
  set: ({ set, get }, name: string) => {
    if (name) {
      localStorage.setItem("firstName", name);
      set(SettingsState, { ...get(SettingsState), name: name });
    }
  },
});

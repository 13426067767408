import { ReactJSX } from "../../utils/types";

export enum LocationModalTypes {
  SHOW_LOCATION_MODAL = "@@locationModal/SHOW_LOCATION_MODAL",
  HIDE_LOCATION_MODAL = "@@locationModal/HIDE_LOCATION_MODAL",
}

export interface LocationModal {
  content: ReactJSX;
  title?: string;
  headerless?: boolean;
}

export interface LocationModalState {
  active: boolean;
  data: LocationModal;
}

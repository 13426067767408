import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../services/webclient";
import styled from "@emotion/styled";
import { isMobile } from "../../../../../../mobileConfig";
import { Skeleton } from "@material-ui/lab";
import { nanoid } from "nanoid";
import { i18n } from "../../../../../../i18n/i18nText";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../redux/filterByDate/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../redux";
import {
  labelDisplayedRows,
  TablePaginationActions,
} from "../../../../../../components/GeneralTable/GeneralTable";
import { formatFloatValue } from "../../../../../../utils/models/format";

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "date",
      label: i18n("REPORT_TABLE_START_DATE"),
    },
    {
      id: "waterLevel",
      label: i18n("REPORT_TABLE_END_DATE"),
    },
    {
      id: "flow",
      label: i18n("BASIC_INFO_BOX_WATER_CONSUMPTION"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledHeadTableCell
            key={headCell.id}
            align={"center"}
            padding={"default"}
          >
            {headCell.label}
          </StyledHeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: isMobile() ? "95vw" : "100%",
    overflowX: isMobile() ? "scroll" : "hidden",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    height: "100%",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

type IManage_Sensor = {
  value: number;
  number_editing: number;
};

type ResultItemObject = {
  cumulative_volume: number;
  end_date: Date;
  hour_hfp: number;
  hour_p: number;
  hour_r: number;
  hour_total: number;
  id: number;
  irpd: number;
  start_date: Date;
};

interface Props {
  irpdID: number;
}

const StyledHeadTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
  background-color: white;
`;

function ReportIRPDTable(props: Props) {
  const { irpdID } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = useState(0);
  const [reportListResults, setReportListResults] = useState<
    ResultItemObject[]
  >(undefined);

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const rows = useMemo(() => {
    if (reportListResults == undefined) return; // Historic isn't loaded yet.
    return reportListResults.map((row) => {
      return row;
    });
  }, [reportListResults]);

  const handleChangePage = (event, newPage) => {
    setReportListResults(undefined);
    getMeterSystemList(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    getMeterSystemList();
  }, [filterByDate, irpdID]);

  async function getMeterSystemList(page = 0) {
    setReportListResults(undefined);
    if (
      filterByDate.data.start_date &&
      filterByDate.data.end_date &&
      filterByDate.data.type === FilterTypesChoices.REPORT
    ) {
      var date_end = filterByDate.data.end_date.toISOString();
      var date_start = filterByDate.data.start_date.toISOString();
      try {
        let response = await coreHTTPClient.get(
          `v3/reports/irpds/${irpdID}/irpd-list/?date_start=${date_start}&date_end=${date_end}&page=${
            page + 1
          }`
        );
        if (response.data) {
          setReportListResults(response.data.results);
          setCount(response.data.count);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        let response = await coreHTTPClient.get(
          `v3/reports/irpds/${irpdID}/irpd-list/?page=${page + 1}`
        );
        if (response.data) {
          setReportListResults(response.data.results);
          setCount(response.data.count);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const emptyRows = new Array(10).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>
        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>
        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>
      </TableRow>
    );
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {rows != undefined ? (
                rows.length > 0 ? (
                  rows.map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={nanoid()}>
                        <StyledTableCell align="center">
                          <div>
                            {moment(row.start_date).format("DD/MM HH:mm")}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            {moment(row.end_date).format("DD/MM HH:mm")}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            {formatFloatValue(row.cumulative_volume)} m³
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                ) // i18n("BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO")
              ) : (
                emptyRows
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}

export default React.memo(ReportIRPDTable);

import { ControllerConfig, Pivot } from "../../redux/pivots/types";
import { PivotLight } from "../../recoils/PivotRecoil";
import moment from "moment";
import { coreHTTPClient } from "../../services/webclient";
import { i18n } from "../../i18n/i18nText";

export const PANEL_STREAM_REASON_GROUP_STATUS = {
  STOPPED: [0, 240, 241, 243, 244, 245, 247, 249, 252, 254],
  IN_MOVING: [1, 5, 9, 11, 33, 65, 74],
  IRRIGATING: [2, 4, 6, 8, 16, 18, 10, 22, 34, 38, 103, 66],
  PROBLEM: [242, 246, 248, 250, 251, 253, 255, 256],
  PROGRAMMED: [32, 21],
  PANEL_ON: [100],
  PANEL_OFF: [101],
  PRESSURIZING: [102],
  PRESSURIZED: [98],
};

export const PANEL_STREAM_REASON_GROUP_STATUS_V5 = {
  STOPPED: [],
  IN_MOVING: [],
  IRRIGATING: [],
  PROBLEM: [15, 16, 17, 18, 19, 20, 21, 38],
  PROGRAMMED: [],
  PANEL_ON: [],
  PANEL_OFF: [],
  PRESSURIZING: [],
};

enum PAINEL_STREAM_STATUS {
  STOPPED = 0,
  IN_MOVING,
  IRRIGATING,
  PROBLEM,
  PROGRAMMED,
  PANEL_ON,
  PANEL_OFF,
  PRESSURIZING,
  FERTIRRIGATION = 26,
}

export const PIVOT_STATUS_COLOR_OLD_RED = "#e92400";
export const PIVOT_STATUS_COLOR_OLD_GREEN = "#207833";
export const PIVOT_STATUS_COLOR_OLD_BLUE = "#4679ed";
export const PIVOT_STATUS_COLOR_OLD_ORANGE = "#ff8c00";
export const PIVOT_STATUS_COLOR_OLD_YELLOW = "#ffd700";
export const PIVOT_STATUS_COLOR_OLD_LIGHTBLUE = "#66b5ff";
export const PIVOT_STATUS_COLOR_OLD_BROWN = "#934200";
export const PIVOT_STATUS_COLOR_OLD_GREY = "#757575";

export const getPivotStatusColor = (status: number): string => {
  if ([39].includes(status)) return PIVOT_STATUS_COLOR_OLD_BLUE;
  if ([104].includes(status)) return PIVOT_STATUS_COLOR_OLD_BLUE;
  switch (status) {
    case 0:
      return PIVOT_STATUS_COLOR_OLD_YELLOW;
    case 1:
      return PIVOT_STATUS_COLOR_OLD_BROWN;
    case 2:
      return PIVOT_STATUS_COLOR_OLD_BLUE;
    case 3:
      return PIVOT_STATUS_COLOR_OLD_RED;
    case 4:
      return PIVOT_STATUS_COLOR_OLD_GREEN;
    case 5:
      return PIVOT_STATUS_COLOR_OLD_YELLOW;
    case 6:
      return PIVOT_STATUS_COLOR_OLD_ORANGE;
    case 7:
      return PIVOT_STATUS_COLOR_OLD_LIGHTBLUE;
    case 26:
      return PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1;
    default:
      return PIVOT_STATUS_COLOR_OLD_YELLOW;
  }
};

export type PivotSignalLevel =
  | "very strong"
  | "strong"
  | "moderate"
  | "weak"
  | "error";
export const PivotLineColorMap: {
  [signalLevel in PivotSignalLevel]: string;
} = {
  "very strong": "#007d00",
  strong: "#0c969f",
  moderate: "#d46900",
  weak: "#e70000",
  error: "#000000",
};

export const PIVOT_STATUS_COLOR_V5_RED = "#e92400";
export const PIVOT_STATUS_COLOR_V5_YELLOW = "#ffd700";
export const PIVOT_STATUS_COLOR_V5_ORANGE = "#ff8c00";
export const PIVOT_STATUS_COLOR_V5_GREEN = "#207833";
export const PIVOT_STATUS_COLOR_V5_BROWN = "#934200";
export const PIVOT_STATUS_COLOR_V5_BLUE = "#4679ed"; // "#4679ed";
export const PIVOT_STATUS_COLOR_V5_CREAM = "#202020";
export const PIVOT_STATUS_COLOR_V5_PURPLE = "rgba(108,0,81,0.83)";
export const PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1 = "#836FFF";

export const PIVOT_IRRIGATING_WET_OR_DRY_REASONS_V5 = [
  /* Razoes de irrigacao molhada */ 3,
  4,
  5,
  36,
  37,
  /* Razoes de irrigacao seca */ 6,
  33,
];
export const PROGRAMMED_REASONS_V5 = [7, 8];
export const MOVING_DRY_REASONS = [6, 33];
export const MOVING_WET_REASONS = [3, 4, 5, 36, 37];

export const getPivotStatusColorV5 = (status: number): string => {
  if ([0, 1, 2].includes(status)) return PIVOT_STATUS_COLOR_V5_CREAM;
  if (MOVING_WET_REASONS.includes(status)) return PIVOT_STATUS_COLOR_V5_BLUE;
  if (MOVING_DRY_REASONS.includes(status)) return PIVOT_STATUS_COLOR_V5_BROWN;
  if (PROGRAMMED_REASONS_V5.includes(status))
    return PIVOT_STATUS_COLOR_V5_GREEN;
  if ([9, 10, 11, 12, 13, 14, 34, 35].includes(status))
    return PIVOT_STATUS_COLOR_V5_YELLOW;
  if ([22].includes(status)) return PIVOT_STATUS_COLOR_V5_ORANGE;
  if ([15, 16, 17, 18, 19, 20, 21, 23, 32].includes(status))
    return PIVOT_STATUS_COLOR_V5_RED;
  if ([24, 25].includes(status)) return PIVOT_STATUS_COLOR_V5_PURPLE;
  if ([26].includes(status)) return PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1;
  return "#000000";
};

export const getPanelStreamV5Mode = (mode: number): string => {
  switch (mode) {
    case 0:
      return i18n("PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE");
    case 1:
      return i18n("PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET");
    case 2:
      return i18n("PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY");
    default:
      return i18n("PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE");
  }
};

export const getPanelStreamV5Direction = (direction: number): string => {
  switch (direction) {
    case 0:
      return i18n("HISTORIC_PANEL_STREAM_V5_STOPPED");

    case 1:
      return i18n("HISTORIC_PANEL_STREAM_V5_FORWARD");

    case 2:
      return i18n("HISTORIC_PANEL_STREAM_V5_REVERSE");

    default:
      return i18n("HISTORIC_PANEL_STREAM_V5_STOPPED");
  }
};

export const isOldProtocol = (pivot: Pivot | PivotLight | undefined) =>
  pivot?.protocol < 5;

export const isV5Protocol = (pivot: Pivot | PivotLight | undefined) =>
  pivot?.protocol === 5;

export interface PainelStreamStatus {
  number: number;
  text: string;
  color: string;
}

export const getPanelStreamStatus = (
  reason: number,
  resumed: boolean = false
): PainelStreamStatus => {
  // return {
  //   number: reason,
  //   text: getPanelStreamReason(reason),
  //   color: getPivotStatusColor(reason)
  // }

  if ([39, 103, 104].includes(reason)) {
    return {
      number: reason,
      text: getPanelStreamReason(reason),
      color: getPivotStatusColor(reason),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.STOPPED.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.STOPPED,
      text: i18n("PIVOT_STATUS_STOPPED"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.STOPPED),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.IN_MOVING.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.IN_MOVING,
      text: i18n("PIVOT_STATUS_IN_MOVING"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.IN_MOVING),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.IRRIGATING.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.IRRIGATING,
      text: i18n("PIVOT_STATUS_IRRIGATING"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.IRRIGATING),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PROBLEM.includes(reason)) {
    switch (reason) {
      case 18:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 242:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 246:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 248:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 250:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 251:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 253:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 255:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
        };
      case 256:
        return {
          number: PAINEL_STREAM_STATUS.PROBLEM,
          text: getPanelStreamReason(reason, resumed),
          color: PIVOT_STATUS_COLOR_OLD_GREY,
        };
    }
    return {
      number: PAINEL_STREAM_STATUS.PROBLEM,
      text: i18n("PIVOT_STATUS_PROBLEM"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROBLEM),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PROGRAMMED.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.PROGRAMMED,
      text: i18n("PIVOT_STATUS_PROGRAMMED"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PROGRAMMED),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PANEL_ON.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.PANEL_ON,
      text: i18n("PIVOT_STATUS_PANEL_ON"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PANEL_ON),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PANEL_OFF.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.PANEL_OFF,
      text: i18n("PIVOT_STATUS_PANEL_OFF"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PANEL_OFF),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PRESSURIZING.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.PRESSURIZING,
      text: i18n("PIVOT_STATUS_PRESSURIZING"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PRESSURIZING),
    };
  }

  if (PANEL_STREAM_REASON_GROUP_STATUS.PRESSURIZED.includes(reason)) {
    return {
      number: PAINEL_STREAM_STATUS.PRESSURIZING,
      text: i18n("PAINEL_STREAM_REASON_98"),
      color: getPivotStatusColor(PAINEL_STREAM_STATUS.PRESSURIZING),
    };
  }

  return {
    number: PAINEL_STREAM_STATUS.IN_MOVING,
    text: i18n("LOADING"),
    color: "#00000022",
  };
};

export const getIrrigationModeV5 = (mode) => {
  switch (mode) {
    case 1:
      return {
        number: PAINEL_STREAM_STATUS.IRRIGATING,
        text: i18n("PIVOT_STATUS_IRRIGATING"),
        color: getPivotStatusColor(PAINEL_STREAM_STATUS.IRRIGATING),
      };
    case 2:
      return {
        number: PAINEL_STREAM_STATUS.IN_MOVING,
        text: i18n("PIVOT_STATUS_IN_MOVING"),
        color: getPivotStatusColor(PAINEL_STREAM_STATUS.IN_MOVING),
      };
    case 3:
      return {
        number: PAINEL_STREAM_STATUS.IN_MOVING,
        text: i18n("PANEL_STREAM_STATUS_26"),
        color: getPivotStatusColor(PAINEL_STREAM_STATUS.FERTIRRIGATION),
      };
  }
  return {
    number: PAINEL_STREAM_STATUS.PROBLEM,
    text: i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN"),
    color: "#FFF",
  };
};

export const getPanelStreamStatusV5 = (
  irrigation_status: number
): PainelStreamStatus => {
  switch (irrigation_status) {
    case 0:
      return {
        number: 0,
        text: i18n("PANEL_STREAM_STATUS_0"),
        color: getPivotStatusColorV5(32),
      };

    case 1:
      return {
        number: 1,
        text: i18n("PANEL_STREAM_STATUS_1"),
        color: getPivotStatusColorV5(1),
      };

    case 2:
      return {
        number: 2,
        text: i18n("PANEL_STREAM_STATUS_2"),
        color: getPivotStatusColorV5(2),
      };

    case 3:
      return {
        number: 3,
        text: i18n("PANEL_STREAM_STATUS_3"),
        color: getPivotStatusColorV5(3),
      };

    case 4:
      return {
        number: 4,
        text: i18n("PANEL_STREAM_STATUS_4"),
        color: getPivotStatusColorV5(4),
      };

    case 5:
      return {
        number: 5,
        text: i18n("PANEL_STREAM_STATUS_5"),
        color: getPivotStatusColorV5(5),
      };

    case 6:
      return {
        number: 6,
        text: i18n("PANEL_STREAM_STATUS_6"),
        color: getPivotStatusColorV5(6),
      };

    case 7:
      return {
        number: 7,
        text: i18n("PANEL_STREAM_STATUS_7"),
        color: getPivotStatusColorV5(7),
      };

    case 8:
      return {
        number: 8,
        text: i18n("PANEL_STREAM_STATUS_8"),
        color: getPivotStatusColorV5(8),
      };

    case 9:
      return {
        number: 9,
        text: i18n("PANEL_STREAM_STATUS_9"),
        color: getPivotStatusColorV5(9),
      };

    case 10:
      return {
        number: 10,
        text: i18n("PANEL_STREAM_STATUS_10"),
        color: getPivotStatusColorV5(10),
      };

    case 11:
      return {
        number: 11,
        text: i18n("PANEL_STREAM_STATUS_11"),
        color: getPivotStatusColorV5(11),
      };

    case 12:
      return {
        number: 12,
        text: i18n("PANEL_STREAM_STATUS_12"),
        color: getPivotStatusColorV5(12),
      };

    case 13:
      return {
        number: 13,
        text: i18n("PANEL_STREAM_STATUS_13"),
        color: getPivotStatusColorV5(13),
      };

    case 14:
      return {
        number: 14,
        text: i18n("PANEL_STREAM_STATUS_14"),
        color: getPivotStatusColorV5(14),
      };

    case 15:
      return {
        number: 15,
        text: i18n("PANEL_STREAM_STATUS_15"),
        color: getPivotStatusColorV5(15),
      };

    case 16:
      return {
        number: 16,
        text: i18n("PANEL_STREAM_STATUS_16"),
        color: getPivotStatusColorV5(16),
      };

    case 17:
      return {
        number: 17,
        text: i18n("PANEL_STREAM_STATUS_17"),
        color: getPivotStatusColorV5(17),
      };

    case 18:
      return {
        number: 18,
        text: i18n("PANEL_STREAM_STATUS_18"),
        color: getPivotStatusColorV5(18),
      };

    case 19:
      return {
        number: 19,
        text: i18n("PANEL_STREAM_STATUS_19"),
        color: getPivotStatusColorV5(19),
      };

    case 20:
      return {
        number: 20,
        text: i18n("PANEL_STREAM_STATUS_20"),
        color: getPivotStatusColorV5(20),
      };

    case 21:
      return {
        number: 21,
        text: i18n("PANEL_STREAM_STATUS_21"),
        color: getPivotStatusColorV5(21),
      };

    case 22:
      return {
        number: 22,
        text: i18n("PANEL_STREAM_STATUS_22"),
        color: getPivotStatusColorV5(22),
      };

    case 23:
      return {
        number: 23,
        text: i18n("PANEL_STREAM_STATUS_23"),
        color: getPivotStatusColorV5(23),
      };

    case 24:
      return {
        number: 24,
        text: i18n("PANEL_STREAM_STATUS_24"),
        color: getPivotStatusColorV5(24),
      };

    case 25:
      return {
        number: 25,
        text: i18n("PANEL_STREAM_STATUS_25"),
        color: getPivotStatusColorV5(25),
      };
    case 26:
      return {
        number: 26,
        text: i18n("PANEL_STREAM_STATUS_26"),
        color: getPivotStatusColorV5(26),
      };
    case 32:
      return {
        number: 32,
        text: i18n("PANEL_STREAM_STATUS_32"),
        color: getPivotStatusColorV5(32),
      };

    case 33:
      return {
        number: 33,
        text: i18n("PANEL_STREAM_STATUS_33"),
        color: getPivotStatusColorV5(33),
      };

    case 34:
      return {
        number: 34,
        text: i18n("PANEL_STREAM_STATUS_34"),
        color: getPivotStatusColorV5(34),
      };

    case 35:
      return {
        number: 35,
        text: i18n("PANEL_STREAM_STATUS_35"),
        color: getPivotStatusColorV5(35),
      };

    case 38:
      return {
        number: 38,
        text: i18n("PANEL_STREAM_STATUS_38"),
        color: PIVOT_STATUS_COLOR_OLD_GREY,
      };

    default:
      return {
        number: 25,
        text: i18n("PANEL_STREAM_STATUS_NONE"),
        color: getPivotStatusColorV5(99),
      };
  }
};

export const getSectorPivot = (sector: boolean): string => {
  switch (sector) {
    case false:
      return i18n("SECTOR_PIVOT_REASON_FALSE");
    case true:
      return i18n("SECTOR_PIVOT_REASON_TRUE");
  }
};

export const getEndGun = (reason: number): string => {
  switch (reason) {
    case 0:
      return i18n("PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT");
    case 1:
      return i18n("PIVOT_CONFIG_VALUE_ENDGUN_ON");
    case 2:
      return i18n("PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER");
  }
};

export const getLanguage = (reason: number): string => {
  switch (reason) {
    case 1:
      return i18n("PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH");
    case 2:
      return i18n("PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE");
    case 3:
      return i18n("PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN");
    case 4:
      return i18n("PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH");
    case 5:
      return i18n("PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN");
  }
};

export const getModeEndGun = (reason: number): string => {
  switch (reason) {
    case 0:
      return i18n("PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF");
    case 1:
      return i18n("PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON");
    case 2:
      return i18n("PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS");
  }
};

export const getModeDirection = (reason: number): string => {
  switch (reason) {
    case 1:
      return i18n("SIMPLE_IRRIGATION_VALUE_WET");
    case 2:
      return i18n("SIMPLE_IRRIGATION_VALUE_DRY");
  }
};

export function getModeAdd(
  created_on_hardware: boolean,
  user: string
): string | string {
  if (created_on_hardware) return i18n("HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL");
  if (!user) return " - ";
  return "@".concat(user);
}

// export const getDelivery = (reason: boolean) => {
//   switch (reason) {
//     case true:
//       return (
//       <></>
//       );
//     case false:
//       return DoneAllIcon;
//   }
// };

export const getReadPressure = (reason: number): string => {
  switch (reason) {
    case 0:
      return i18n("PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF");
    case 1:
      return i18n("PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH");
    case 2:
      return i18n("PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR");
    default:
      return i18n("PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF");
  }
};

export const getPanelStreamReason = (
  reason: number,
  resumed: boolean = false
): string => {
  switch (reason) {
    case 0:
      return i18n("PAINEL_STREAM_REASON_0");
    case 1:
      return i18n("PAINEL_STREAM_REASON_1");
    case 2:
      return i18n("PAINEL_STREAM_REASON_2");
    case 4:
      return i18n("PAINEL_STREAM_REASON_4");
    case 5:
      return i18n("PAINEL_STREAM_REASON_5");
    case 6:
      return i18n("PAINEL_STREAM_REASON_6");
    case 8:
      return i18n("PAINEL_STREAM_REASON_8");
    case 9:
      return i18n("PAINEL_STREAM_REASON_9");
    case 10:
      return i18n("PAINEL_STREAM_REASON_10");
    case 11:
      return i18n("PAINEL_STREAM_REASON_11");
    case 16:
      return i18n("PAINEL_STREAM_REASON_16");
    case 18:
      return i18n("PAINEL_STREAM_REASON_18");
    case 21:
      return i18n("PAINEL_STREAM_REASON_21");
    case 22:
      return i18n("PAINEL_STREAM_REASON_22");
    case 32:
      return i18n("PAINEL_STREAM_REASON_32");
    case 33:
      return i18n("PAINEL_STREAM_REASON_33");
    case 34:
      return i18n("PAINEL_STREAM_REASON_34");
    case 38:
      return i18n("PAINEL_STREAM_REASON_38");
    case 39:
      return i18n("PAINEL_STREAM_REASON_39");
    case 65:
      return i18n("PAINEL_STREAM_REASON_65");
    case 66:
      return i18n("PAINEL_STREAM_REASON_66");
    case 98:
      return i18n("PAINEL_STREAM_REASON_98");
    case 100:
      return i18n("PAINEL_STREAM_REASON_100");
    case 101:
      return i18n("PAINEL_STREAM_REASON_101");
    case 102:
      return i18n("PAINEL_STREAM_REASON_102");
    case 103:
      return i18n("PAINEL_STREAM_REASON_103");
    case 104:
      return i18n("PAINEL_STREAM_REASON_104");
    case 240:
      return i18n("PAINEL_STREAM_REASON_240");
    case 241:
      return i18n("PAINEL_STREAM_REASON_241");
    case 242:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_242")
        : i18n("PAINEL_STREAM_REASON_242");
    case 243:
      return i18n("PAINEL_STREAM_REASON_243");
    case 244:
      return i18n("PAINEL_STREAM_REASON_244");
    case 245:
      return i18n("PAINEL_STREAM_REASON_245");
    case 246:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_246")
        : i18n("PAINEL_STREAM_REASON_246");
    case 247:
      return i18n("PAINEL_STREAM_REASON_247");
    case 248:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_248")
        : i18n("PAINEL_STREAM_REASON_248");
    case 249:
      return i18n("PAINEL_STREAM_REASON_249");
    case 250:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_250")
        : i18n("PAINEL_STREAM_REASON_250");
    case 251:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_251")
        : i18n("PAINEL_STREAM_REASON_251");
    case 252:
      return i18n("PAINEL_STREAM_REASON_252");
    case 253:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_253")
        : i18n("PAINEL_STREAM_REASON_253");
    case 254:
      return i18n("PAINEL_STREAM_REASON_254");
    case 255:
      return resumed
        ? i18n("PAINEL_STREAM_RESUMED_REASON_255")
        : i18n("PAINEL_STREAM_REASON_255");
    case 256:
      return resumed
        ? i18n("PAINEL_STREAM_REASON_256")
        : i18n("PAINEL_STREAM_REASON_256");
  }

  return i18n("PAINEL_STREAM_REASON_0");
};

export const getAllPanelStreamReasonsV4 = (): {
  id: number;
  name: string;
}[] => {
  return [
    {
      id: 0,
      name: i18n("PAINEL_STREAM_REASON_0"),
    },
    {
      id: 1,
      name: i18n("PAINEL_STREAM_REASON_1"),
    },
    {
      id: 2,
      name: i18n("PAINEL_STREAM_REASON_2"),
    },
    // {
    //   id: 4,
    //   name: "PAINEL_STREAM_REASON_4",
    // },
    {
      id: 5,
      name: i18n("PAINEL_STREAM_REASON_5"),
    },
    {
      id: 6,
      name: i18n("PAINEL_STREAM_REASON_6"),
    },
    {
      id: 8,
      name: i18n("PAINEL_STREAM_REASON_8"),
    },
    {
      id: 9,
      name: i18n("PAINEL_STREAM_REASON_9"),
    },
    {
      id: 10,
      name: i18n("PAINEL_STREAM_REASON_10"),
    },
    {
      id: 11,
      name: i18n("PAINEL_STREAM_REASON_11"),
    },
    {
      id: 16,
      name: i18n("PAINEL_STREAM_REASON_16"),
    },
    {
      id: 21,
      name: i18n("PAINEL_STREAM_REASON_21"),
    },
    {
      id: 22,
      name: i18n("PAINEL_STREAM_REASON_22"),
    },
    {
      id: 32,
      name: i18n("PAINEL_STREAM_REASON_32"),
    },
    {
      id: 33,
      name: i18n("PAINEL_STREAM_REASON_33"),
    },
    {
      id: 34,
      name: i18n("PAINEL_STREAM_REASON_34"),
    },
    {
      id: 38,
      name: i18n("PAINEL_STREAM_REASON_38"),
    },
    {
      id: 65,
      name: i18n("PAINEL_STREAM_REASON_65"),
    },
    {
      id: 66,
      name: i18n("PAINEL_STREAM_REASON_66"),
    },
    {
      id: 100,
      name: i18n("PAINEL_STREAM_REASON_100"),
    },
    {
      id: 101,
      name: i18n("PAINEL_STREAM_REASON_101"),
    },
    {
      id: 102,
      name: i18n("PAINEL_STREAM_REASON_102"),
    },
    {
      id: 103,
      name: i18n("PAINEL_STREAM_REASON_103"),
    },
    {
      id: 240,
      name: i18n("PAINEL_STREAM_REASON_240"),
    },
    {
      id: 242,
      name: i18n("PAINEL_STREAM_REASON_242"),
    },
    {
      id: 243,
      name: i18n("PAINEL_STREAM_REASON_243"),
    },
    {
      id: 244,
      name: i18n("PAINEL_STREAM_REASON_244"),
    },
    {
      id: 245,
      name: i18n("PAINEL_STREAM_REASON_245"),
    },
    {
      id: 246,
      name: i18n("PAINEL_STREAM_REASON_246"),
    },
    {
      id: 247,
      name: i18n("PAINEL_STREAM_REASON_247"),
    },
    {
      id: 248,
      name: i18n("PAINEL_STREAM_REASON_248"),
    },
    {
      id: 249,
      name: i18n("PAINEL_STREAM_REASON_249"),
    },
    {
      id: 250,
      name: i18n("PAINEL_STREAM_REASON_250"),
    },
    {
      id: 251,
      name: i18n("PAINEL_STREAM_REASON_251"),
    },
    {
      id: 252,
      name: i18n("PAINEL_STREAM_REASON_252"),
    },
    {
      id: 253,
      name: i18n("PAINEL_STREAM_REASON_253"),
    },
    {
      id: 254,
      name: i18n("PAINEL_STREAM_REASON_254"),
    },
    {
      id: 255,
      name: i18n("PAINEL_STREAM_REASON_255"),
    },
    {
      id: 256,
      name: i18n("PAINEL_STREAM_REASON_256"),
    },
  ];
};

export const getAllPanelStreamReasonsV5 = (): {
  id: number;
  name: string;
}[] => {
  return [
    {
      id: 0,
      name: i18n("PANEL_STREAM_STATUS_0"),
    },
    {
      id: 1,
      name: i18n("PANEL_STREAM_STATUS_1"),
    },
    {
      id: 2,
      name: i18n("PANEL_STREAM_STATUS_2"),
    },
    {
      id: 3,
      name: i18n("PANEL_STREAM_STATUS_3"),
    },
    {
      id: 4,
      name: i18n("PANEL_STREAM_STATUS_4"),
    },
    {
      id: 5,
      name: i18n("PANEL_STREAM_STATUS_5"),
    },
    {
      id: 6,
      name: i18n("PANEL_STREAM_STATUS_6"),
    },
    {
      id: 7,
      name: i18n("PANEL_STREAM_STATUS_7"),
    },
    {
      id: 8,
      name: i18n("PANEL_STREAM_STATUS_8"),
    },
    {
      id: 9,
      name: i18n("PANEL_STREAM_STATUS_9"),
    },
    {
      id: 10,
      name: i18n("PANEL_STREAM_STATUS_10"),
    },
    {
      id: 11,
      name: i18n("PANEL_STREAM_STATUS_11"),
    },
    {
      id: 12,
      name: i18n("PANEL_STREAM_STATUS_12"),
    },
    {
      id: 13,
      name: i18n("PANEL_STREAM_STATUS_13"),
    },
    {
      id: 14,
      name: i18n("PANEL_STREAM_STATUS_14"),
    },
    {
      id: 15,
      name: i18n("PANEL_STREAM_STATUS_15"),
    },
    {
      id: 16,
      name: i18n("PANEL_STREAM_STATUS_16"),
    },
    {
      id: 17,
      name: i18n("PANEL_STREAM_STATUS_17"),
    },
    {
      id: 18,
      name: i18n("PANEL_STREAM_STATUS_18"),
    },
    {
      id: 19,
      name: i18n("PANEL_STREAM_STATUS_19"),
    },
    {
      id: 20,
      name: i18n("PANEL_STREAM_STATUS_20"),
    },
    {
      id: 21,
      name: i18n("PANEL_STREAM_STATUS_21"),
    },
    {
      id: 22,
      name: i18n("PANEL_STREAM_STATUS_22"),
    },
    {
      id: 23,
      name: i18n("PANEL_STREAM_STATUS_23"),
    },
    {
      id: 24,
      name: i18n("PANEL_STREAM_STATUS_24"),
    },
    {
      id: 25,
      name: i18n("PANEL_STREAM_STATUS_NONE"),
    },
    {
      id: 32,
      name: i18n("PANEL_STREAM_STATUS_32"),
    },
    {
      id: 33,
      name: i18n("PANEL_STREAM_STATUS_33"),
    },
    {
      id: 34,
      name: i18n("PANEL_STREAM_STATUS_34"),
    },
    {
      id: 35,
      name: i18n("PANEL_STREAM_STATUS_35"),
    },
    {
      id: 38,
      name: i18n("PANEL_STREAM_STATUS_38"),
    },
  ];
};

export const PTPToMillimeter = (pivot: Pivot, PTP: number): number => {
  /**
  
  PERCENT TIMER POSITION [%%] TO MILLIMETER [mm]

  ** Calculate CicleTime according PTP **

  (1) Perimeter[m] = PivotRadius[m] * 2 * PI
  (2) FastestCicleTime[h] = Perimeter[m] / Speed[m/h]
  (3) CicleTime[h] = FastestCicleTime[h] * (100 / PTP[%])
  
  ** Find HeightOfWater (in mm) according CicleTime **

  (4) CiclePouredWater[m³] = Flow[m³/hora] * CicleTime[hora]
  (5) HeightOfWater[10^-2 m] = CiclePouredWater[m³] / Area[ha]
  (6) HeightOfWater[mm] = HeightOfWater[10^-2 m]/10

   **/

  let pivotRadius = 0;
  let pivotSpeed = 0;
  let pivotFlow = 0;
  let pivotArea = 0;

  if (isOldProtocol(pivot)) {
    pivotRadius = pivot.config?.radius;
    pivotSpeed = pivot.config?.speed;
    pivotFlow = pivot.config?.flow;
    pivotArea = pivot.config?.area;
  } else if (isV5Protocol(pivot)) {
    pivotRadius =
      pivot.controllerconfig?.content?.pivot_parameters?.radius_last;
    pivotSpeed = pivot.controllerconfig?.content?.pivot_parameters?.speed;
    pivotFlow = pivot.controllerconfig?.content?.pivot_parameters?.flow_rate;
    pivotArea =
      pivot.controllerconfig?.content?.pivot_parameters?.irrigated_area;
  } else return -1;

  // (1)
  const perimeter = pivotRadius * 2 * Math.PI;

  // (2)
  const fastestCicleTime = perimeter / pivotSpeed;

  // (3)
  const cicleTime = fastestCicleTime * (100 / PTP);

  // (4)
  const ciclePouredWater = pivotFlow * cicleTime;

  // (5)
  const heightOfWater = ciclePouredWater / pivotArea;

  // (6)
  const mmHeightOfWater = heightOfWater / 10 ? heightOfWater / 10 : 0;

  return parseFloat(mmHeightOfWater.toFixed(4));
};

export const mmToPTP = (pivot: Pivot, mmHeightOfWater: number): number => {
  /**
  
  MILLIMETER [mm] TO PERCENT TIMER POSITION [%%]

  // Calculate CicleTime

  (1) CiclePouredWater[m^3] = Area[ha] x (HeightOfWater[mm] * 10)[10^-2m]
  (2) CicleTime[h] = CiclePouredWater[m^3] / Flow[m^3/h]

  // Calculate FastesCicleTime

  (3) Perimeter[m] = PivotRadius[m] * 2 * PI
  (4) FastestCicleTime[h] = Perimeter[m] / Speed[m/h]

  // Calculate PTP
  (5) PTP[%] = (CicleTime[h] / FastestCicleTime[h]) * 100

   **/

  let pivotRadius = 0;
  let pivotSpeed = 0;
  let pivotFlow = 0;
  let pivotArea = 0;

  if (isOldProtocol(pivot)) {
    pivotRadius = pivot.config.radius;
    pivotSpeed = pivot.config.speed;
    pivotFlow = pivot.config.flow;
    pivotArea = pivot.config.area;
  } else if (isV5Protocol(pivot)) {
    pivotRadius = pivot.controllerconfig.content.pivot_parameters.radius_last;
    pivotSpeed = pivot.controllerconfig.content.pivot_parameters.speed;
    pivotFlow = pivot.controllerconfig.content.pivot_parameters.flow_rate;
    pivotArea = pivot.controllerconfig.content.pivot_parameters.irrigated_area;
  } else return -1;

  // (1)
  const ciclePouredWater = pivotArea * mmHeightOfWater * 10;

  // (2)
  const cicleTime = ciclePouredWater / pivotFlow;

  // (3)
  const perimeter = pivotRadius * 2 * Math.PI;

  // (4)
  const fastestCicleTime = perimeter / pivotSpeed;

  // (5)
  const PTP = (fastestCicleTime * 100) / cicleTime;

  return Math.round(PTP);
};

export const speedToMM = (pivot: Pivot, speed: number) => {
  let pivotRadius = 0;
  let pivotFlow = 0;
  let pivotArea = 0;

  if (isOldProtocol(pivot)) {
    pivotRadius = pivot.config.radius;
    pivotFlow = pivot.config.flow;
    pivotArea = pivot.config.area;
  } else if (isV5Protocol(pivot)) {
    pivotRadius = pivot.controllerconfig.content.pivot_parameters.radius_last;
    pivotFlow = pivot.controllerconfig.content.pivot_parameters.flow_rate;
    pivotArea = pivot.controllerconfig.content.pivot_parameters.irrigated_area;
  } else return -1;

  // (1)
  const perimeter = pivotRadius * 2 * Math.PI;

  // (2)
  const cicleTime = perimeter / speed;

  // (3)
  const ciclePouredWater = pivotFlow * cicleTime;

  // (4)
  const heightOfWater = ciclePouredWater / pivotArea;

  // (5)
  const mmHeightOfWater = heightOfWater / 10;

  return parseFloat(mmHeightOfWater.toFixed(3));
};

export const speedToPTP = (pivot: Pivot, speed: number) =>
  mmToPTP(pivot, speedToMM(pivot, speed));

export const parseHHMM = (totalMinutes: number) => {
  let duration = moment.duration(totalMinutes, "minutes");
  return `${Math.floor(duration.asHours())}h ${Math.round(
    (duration.asHours() % 1) * 60
  )}min`;
};

export const parseHourmeter = (totalMinutes: number) => {
  /**
  
  totalMinutes --> All time in minutes.

  hourValue --> How many full hours has.
  hourMinutes --> How many minutes has in totalMinutes (min) - hourValue (hour);
  
  result ==> Ex: "01h 20min"
  
  **/

  let duration = moment.duration(totalMinutes, "minutes");
  return `${Math.floor(duration.asHours())}h ${Math.round(
    (duration.asHours() % 1) * 60
  )}min`;
};

export function getIrrigationDirectionV4(clockwise: number) {
  if (clockwise === 1) return i18n("IRRIGATION_DIRECTION_REVERSE");
  if (clockwise === 2) return i18n("IRRIGATION_DIRECTION_FORWARD");
  return i18n("IRRIGATION_DIRECTION_STOPPED");
}

export function estimatedIrrigationDuration(
  lastTowerDistance: number,
  lastTowerSpeed: number,
  pivotSpeed: number,
  direction: boolean, // true = avanço | false: reverso
  startAngle: number,
  endAngle: number,
  sectorAngle: number,
  startDate?: moment.Moment,
  rounds?: number
) {
  var duration: number = 0;
  var timeToAdd: number = 0;
  var pauseTimeToAdd: number = 0;
  var sectorAngle = sectorAngle ? sectorAngle : 360;

  if (rounds) {
    if (sectorAngle === 360) {
      var angleRange: number = 360 * rounds;
    } else {
      var angleRange: number =
        (Math.abs(sectorAngle - startAngle) + sectorAngle + startAngle) *
        rounds;
    }
  } else {
    var rawAngleRange: number = Math.abs(360 - (startAngle - endAngle));

    var angleRange: number =
      rawAngleRange > 360 ? rawAngleRange - 360 : rawAngleRange;
    angleRange = direction ? angleRange : 360 - angleRange;
  }

  var angleLength: number = (3.14159 * angleRange) / 180;

  // A formula abaixo utiliza os valores de distancia em ft, porém a aplicação está em metros, convertendo para ft temos:
  lastTowerDistance = lastTowerDistance * 3.28084;
  lastTowerSpeed = (lastTowerSpeed * 3.28084) / 60;

  // console.log("Distance From Pivot Point to Last Tower: ", lastTowerDistance);
  // console.log("Last Tower Travel Speed at 100%: ", lastTowerSpeed);
  // console.log("Percentage Timer Setting: ", pivotSpeed);
  // console.log(`Angle Range = ${startAngle} - ${endAngle} = ${angleRange}`);

  // duração da irrigação em horas, pivotSpeed está em porcentagem
  duration =
    (angleLength * lastTowerDistance * 100) /
    (60 * lastTowerSpeed * pivotSpeed);

  if (startDate) {
    timeToAdd = moment.duration(startDate.diff(moment())).asHours();
  }

  return {
    // duração da irrigação bruta, sem contar data de inicio postergada ou horário de pico
    rawDuration: duration,
    // duração da irrigação com todas as variáveis (horário de pico e data de início postergada)
    totalDuration: duration + timeToAdd + pauseTimeToAdd,
  };
}

export async function estimatedIrrigationDurationOnServer(
  last_tower_distance: number,
  last_tower_speed: number,
  pivot_speed: number,
  direction: boolean,
  start_angle: number,
  end_angle: number,
  sector_angle: number,
  pause_time_weekdays: number[],
  pause_time_1: string[] = ["00:00", "00:00"],
  pause_time_2: string[] = ["00:00", "00:00"],
  farm_timezone: string = "America/Sao_Paulo",
  irrigation_start_date: string = null,
  rounds: number = null,
  wet: boolean = true
) {
  if (!pause_time_weekdays?.length) {
    pause_time_weekdays = [0, 1, 2, 3, 4, 5, 6];
  }
  const response = await coreHTTPClient.get(
    `/v3/estimated-time/?last_tower_distance=${last_tower_distance}&last_tower_speed=${last_tower_speed}&pivot_speed=${pivot_speed}&direction=${direction}&start_angle=${start_angle}&end_angle=${end_angle}&sector_angle=${sector_angle}&pause_time_weekdays=${pause_time_weekdays}&pause_time_1=${JSON.stringify(
      pause_time_1
    )}&pause_time_2=${JSON.stringify(
      pause_time_2
    )}&farm_timezone=${farm_timezone}&irrigation_start_date=${irrigation_start_date}&rounds=${rounds}&wet=${wet}`
  );
  if (response?.data) return response.data;
  else
    return {
      // duração da irrigação bruta, sem contar data de inicio postergada ou horário de pico
      raw_duration: 1,
      // duração da irrigação com todas as variáveis (horário de pico e data de início postergada)
      total_duration: 1,
    };
}

export function fillMapHistoryBySocket(historyMapRef, data, pivotProtocol) {
  let newMapHistory = [...historyMapRef];
  let index = Math.round(
    Number.parseInt(
      pivotProtocol === 5
        ? data.content.current_angle.current_angle
        : data.angle
    )
  );
  index = index > 360 ? 360 : index; // evita arredondamento para 361

  // Verifica se está irrigando(true) ou nao(false)
  const isIrrigating =
    pivotProtocol === 5
      ? data.content.current_irrigation_information.mode == 1 &&
        data.content.irrigation_status.irrigation_status == 4
      : data.reason == 2;
  if (isIrrigating) {
    const isIrrigatingForward =
      pivotProtocol === 5
        ? data.content.current_irrigation_information.direction == 1
        : data.clockwise == 2;
    const isIrrigatingBackward =
      pivotProtocol === 5
        ? data.content.current_irrigation_information.direction == 2
        : data.clockwise == 1;

    newMapHistory[index] = 2;
    if (isIrrigatingForward) {
      // Correção para pintar 2 a menos em 0 e 1
      if (index == 0) {
        newMapHistory[360] = 2;
        newMapHistory[359] = 2;
      } else if (index == 1) {
        newMapHistory[0] = 2;
        newMapHistory[360] = 2;
      }
      // Pinta 2 a menos
      else {
        newMapHistory[index - 1] = 2;
        newMapHistory[index - 2] = 2;
      }
    } else if (isIrrigatingBackward) {
      // Correção para pintar 2 a mais em 360 e 359
      if (index == 360) {
        newMapHistory[0] = 2;
        newMapHistory[1] = 2;
      } else if (index == 359) {
        newMapHistory[360] = 2;
        newMapHistory[0] = 2;
      }
      // Pinta 2 a mais
      else {
        newMapHistory[index + 1] = 2;
        newMapHistory[index + 2] = 2;
      }
    }
    return newMapHistory;
  } else {
    return null;
  }
}

export function getWeekdays(config: ControllerConfig) {
  if (config) {
    const monday = config?.content?.pause_time?.enable_monday ? 0 : null;
    const tuesday = config?.content?.pause_time?.enable_tuesday ? 1 : null;
    const wednesday = config?.content?.pause_time?.enable_wednesday ? 2 : null;
    const thursday = config?.content?.pause_time?.enable_thursday ? 3 : null;
    const friday = config?.content?.pause_time?.enable_friday ? 4 : null;
    const saturday = config?.content?.pause_time?.enable_saturday ? 5 : null;
    const sunday = config?.content?.pause_time?.enable_sunday ? 6 : null;
    var tempWeekdays = [
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    ];
    return tempWeekdays.filter((weekday) => weekday !== null);
  }
  return [];
}

export const PIVOT_V4_PAUSE_TIME_WEEKDAYS = [0, 1, 2, 3, 4];

export interface IrrigationRemainingTime {
  hours: number;
  minutes: number;
}

export function isValidIrrigationRemainingTime(
  irrigationRemainingTime: IrrigationRemainingTime
) {
  return (
    (irrigationRemainingTime?.hours !== 0 ||
      irrigationRemainingTime?.minutes !== 0) &&
    (irrigationRemainingTime?.hours != null ||
      irrigationRemainingTime?.minutes != null)
  );
}

export function convertIrrigationRemainingTimeToHoursMinutes(
  irrigationRemainingTime: IrrigationRemainingTime
): string {
  const { hours, minutes } = irrigationRemainingTime;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${formattedHours}:${formattedMinutes}`;
}

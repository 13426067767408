import React from "react";

const RepeaterIcon = ({ color, size, extraStyle = null }) => {
  const style = { fill: color, width: `${size}px`, height: `${size}px` };
  const newStyle = { ...style, ...extraStyle };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={newStyle}
      viewBox="0 0 68.35 92.3"
      version="1.1"
    >
      <defs>
        <clipPath id="clip1">
          <path d="M 5 35 L 64 35 L 64 92.300781 L 5 92.300781 Z M 5 35 " />
        </clipPath>
        <clipPath id="clip2">
          <path d="M 54 0 L 68.351563 0 L 68.351563 34 L 54 34 Z M 54 0 " />
        </clipPath>
      </defs>
      <g id="surface1">
        <path d="M 38.742188 47.671875 L 30.664063 47.671875 C 29.347656 47.671875 28.28125 46.605469 28.28125 45.289063 L 28.28125 33.746094 C 28.28125 32.429688 29.347656 31.363281 30.664063 31.363281 L 38.742188 31.363281 C 40.054688 31.363281 41.121094 32.429688 41.121094 33.746094 L 41.121094 45.289063 C 41.121094 46.605469 40.054688 47.671875 38.742188 47.671875 " />
        <path d="M 34.703125 41.78125 C 32.820313 41.78125 31.292969 40.253906 31.292969 38.371094 L 31.292969 6.25 C 31.292969 4.367188 32.820313 2.839844 34.703125 2.839844 C 36.585938 2.839844 38.113281 4.367188 38.113281 6.25 L 38.113281 38.371094 C 38.113281 40.253906 36.585938 41.78125 34.703125 41.78125 " />
        <g clip-path="url(#clip1)" clip-rule="nonzero">
          <path d="M 56.984375 63.976563 L 55.386719 66.042969 L 54.699219 65.300781 C 50.585938 60.980469 48.9375 59.625 45.585938 57.882813 C 34.84375 52.242188 22.683594 54.851563 13.847656 64.71875 L 12.707031 66.011719 L 11.105469 64.011719 C 10.242188 62.914063 9.53125 61.945313 9.53125 61.851563 C 9.53125 61.753906 10.113281 61.042969 10.851563 60.238281 C 16.84375 53.660156 23.191406 50.207031 30.933594 49.242188 C 40.429688 48.082031 50.511719 52.371094 57.339844 60.464844 L 58.585938 61.945313 Z M 50.433594 72.296875 L 48.835938 74.363281 L 47.132813 72.621094 C 43.859375 69.234375 39.570313 67.140625 35.175781 66.785156 C 29.96875 66.363281 24.132813 68.878906 20.449219 73.074219 L 19.308594 74.394531 L 17.707031 72.394531 C 16.84375 71.296875 16.132813 70.300781 16.132813 70.171875 C 16.132813 70.042969 17.074219 68.976563 18.214844 67.816406 C 20.679688 65.332031 23.976563 63.203125 27.175781 61.980469 C 29.285156 61.140625 29.792969 61.078125 33.90625 61.078125 C 38.804688 61.078125 40.40625 61.464844 44.0625 63.429688 C 45.992188 64.496094 49.367188 67.203125 51.042969 69.074219 L 52.058594 70.203125 Z M 43.832031 80.652344 L 42.234375 82.746094 L 40.914063 81.488281 C 38.730469 79.359375 37.257813 78.78125 34.035156 78.78125 C 30.785156 78.78125 29.132813 79.425781 27.101563 81.523438 L 25.910156 82.746094 L 24.308594 80.78125 C 23.445313 79.683594 22.734375 78.683594 22.734375 78.554688 C 22.734375 78.039063 25.503906 75.554688 27.175781 74.589844 C 29.667969 73.136719 31.367188 72.652344 34.160156 72.683594 C 37.945313 72.71875 41.800781 74.492188 44.441406 77.425781 L 45.457031 78.585938 Z M 58.816406 35.632813 L 10.585938 35.632813 C 7.882813 35.632813 5.6875 37.828125 5.6875 40.535156 L 5.6875 87.398438 C 5.6875 90.105469 7.882813 92.300781 10.585938 92.300781 L 58.816406 92.300781 C 61.523438 92.300781 63.71875 90.105469 63.71875 87.398438 L 63.71875 40.535156 C 63.71875 37.828125 61.523438 35.632813 58.816406 35.632813 " />
        </g>
        <g clip-path="url(#clip2)" clip-rule="nonzero">
          <path d="M 68.195313 14.671875 C 67.386719 9.40625 64.511719 5.089844 59.027344 1.015625 C 58.355469 0.515625 57.765625 0.117188 57.683594 0.117188 C 57.601563 0.117188 56.796875 0.601563 55.882813 1.1875 L 54.214844 2.277344 L 55.292969 3.050781 C 63.515625 9.058594 65.695313 17.332031 60.988281 24.636719 C 59.539063 26.914063 58.410156 28.035156 54.808594 30.835938 L 54.1875 31.300781 L 55.910156 32.390625 L 57.601563 33.476563 L 58.839844 32.628906 C 65.585938 27.984375 69.160156 21.128906 68.195313 14.671875 " />
        </g>
        <path d="M 56.367188 23.597656 C 58.007813 21.113281 58.328125 20.027344 58.328125 16.691406 C 58.328125 13.894531 58.273438 13.550781 57.578125 12.117188 C 56.554688 9.941406 54.78125 7.695313 52.710938 6.023438 C 51.742188 5.246094 50.855469 4.605469 50.746094 4.605469 C 50.640625 4.605469 49.808594 5.089844 48.894531 5.675781 L 47.226563 6.765625 L 48.328125 7.542969 C 51.824219 10.046875 53.917969 14.015625 53.570313 17.554688 C 53.273438 20.542969 51.527344 23.460938 48.707031 25.6875 L 47.253906 26.84375 L 48.972656 27.933594 L 50.722656 29.039063 L 51.660156 28.347656 C 53.222656 27.207031 55.480469 24.910156 56.367188 23.597656 " />
        <path d="M 47.066406 12.117188 C 46.261719 10.976563 44.191406 9.09375 43.761719 9.09375 C 43.652344 9.09375 42.820313 9.578125 41.90625 10.167969 L 40.265625 11.253906 L 41.285156 12.066406 C 43.035156 13.449219 43.570313 14.570313 43.570313 16.78125 C 43.570313 18.972656 43.085938 19.972656 41.3125 21.457031 L 40.265625 22.355469 L 42.011719 23.445313 L 43.730469 24.550781 L 44.699219 23.859375 C 47.148438 22.0625 48.625 19.4375 48.652344 16.863281 C 48.679688 14.964844 48.277344 13.808594 47.066406 12.117188 " />
        <path d="M 0.15625 18.933594 C 0.96875 24.238281 3.863281 28.585938 9.386719 32.6875 C 10.066406 33.191406 10.660156 33.59375 10.742188 33.59375 C 10.824219 33.59375 11.632813 33.105469 12.554688 32.515625 L 14.234375 31.417969 L 13.152344 30.636719 C 4.867188 24.585938 2.675781 16.257813 7.410156 8.902344 C 8.875 6.605469 10.011719 5.476563 13.636719 2.660156 L 14.261719 2.191406 L 12.527344 1.09375 L 10.824219 0 L 9.578125 0.851563 C 2.785156 5.53125 -0.816406 12.433594 0.15625 18.933594 " />
        <path d="M 12.066406 9.945313 C 10.417969 12.449219 10.09375 13.542969 10.09375 16.902344 C 10.09375 19.71875 10.144531 20.066406 10.847656 21.507813 C 11.878906 23.699219 13.664063 25.960938 15.75 27.648438 C 16.722656 28.429688 17.617188 29.074219 17.722656 29.074219 C 17.835938 29.074219 18.671875 28.585938 19.59375 27.996094 L 21.269531 26.898438 L 20.160156 26.117188 C 16.644531 23.59375 14.53125 19.59375 14.882813 16.03125 C 15.179688 13.023438 16.941406 10.085938 19.78125 7.84375 L 21.242188 6.675781 L 19.511719 5.582031 L 17.753906 4.46875 L 16.804688 5.164063 C 15.234375 6.3125 12.960938 8.625 12.066406 9.945313 " />
        <path d="M 21.433594 21.507813 C 22.246094 22.65625 24.328125 24.550781 24.765625 24.550781 C 24.871094 24.550781 25.710938 24.066406 26.628906 23.472656 L 28.28125 22.378906 L 27.253906 21.558594 C 25.492188 20.167969 24.953125 19.039063 24.953125 16.8125 C 24.953125 14.605469 25.441406 13.597656 27.226563 12.101563 L 28.28125 11.199219 L 26.523438 10.101563 L 24.789063 8.988281 L 23.816406 9.683594 C 21.351563 11.492188 19.863281 14.136719 19.835938 16.726563 C 19.808594 18.640625 20.214844 19.804688 21.433594 21.507813 " />
      </g>
    </svg>
  );
};

export default RepeaterIcon;

import React, { useEffect, useContext, useRef } from "react";
import "./SelectedFarm.scss";
import DeviceBox from "./components/DeviceBox/DeviceBox";
import { useNavigate, useParams, useLocation } from "react-router";
import { EditSharp, Map } from "@material-ui/icons";
import routes from "../../../../routes/routes";
import useFarm from "../../../../hooks/models/useFarm";
import {
  BottomNavigation,
  BottomNavigationAction,
  Tooltip,
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import FarmMap, { FarmMapHandle } from "./components/FarmMap/FarmMap";
import { storeFarm } from "../../../../redux/farm/actions";
import { useDispatch } from "react-redux";
import { isMobile } from "../../../../mobileConfig";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import useIrpds from "../../../../hooks/models/useIrpds";
import QueryString from "query-string";
import { useRecoilState, useRecoilCallback, useSetRecoilState } from "recoil";
import {
  PivotLightStateList,
  PivotPainelStreamFamily,
  PivotGPSStreamFamily,
  PivotConfigFamily,
  FallbackPivot,
} from "../../../../recoils/PivotRecoil";

import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../services/webclient";
import { DrawerContext } from "../../components/DrawerProvider/DrawerProvider";
import {
  IMeterHelperSelector,
  MeterSystemStateList,
} from "../../../../recoils/MeterSystemRecoil";
import MobileZone from "../../../../components/Zones/MobileZone";
import styled from "@emotion/styled";
import { getDeviceNotch } from "../../../../utils/DeviceNotchCheck";
import { Capacitor } from "@capacitor/core";
import { i18n } from "../../../../i18n/i18nText";
import useRepeaters from "../../../../hooks/models/useReapeaters";
import useBauerPivot from "../../../../hooks/models/useBauerPivots";
import useNotifyCustomerAndDebtorDaily from "../../../../hooks/models/useNotifyCustomerAndDebtorDaily";

export interface SelectedFarmParams {
  farm: string;
}

let lastFarmId: number = -1;

export function getLastFarmId() {
  return lastFarmId;
}

export function setLastFarmId(farmId: number) {
  lastFarmId = farmId;
}

function SelectedFarm() {
  // For Mobile: to save farm id and name, because it's gonna be needed inside the navbar
  const dispatch = useDispatch();

  const farmID: number = parseInt(useParams().farm, 10);

  const [asyncStatus, farm, farmActions] = useFarm(farmID, true);

  const [] = useNotifyCustomerAndDebtorDaily(farmID);

  const [pivotLight, setPivotLight] = useRecoilState(PivotLightStateList);

  const [meterSystems, setMeterSystems] = useRecoilState(MeterSystemStateList);

  const [loadingRepeater, repeaters] = useRepeaters(farmID);

  const iMeterHelper = useSetRecoilState(IMeterHelperSelector);

  const [loadingIrpds, irpds] = useIrpds(farmID);

  const [loadingBauerPivots, bauerPivots] = useBauerPivot(farmID);

  const farmMapRef = useRef<FarmMapHandle | null>(null);

  const [selectedTab, setSelectedTab] = React.useState(
    localStorage.getItem("selected-bottom-tab")
      ? parseInt(localStorage.getItem("selected-bottom-tab"))
      : 0
  );

  const resetPivotRelatedStreams = useRecoilCallback(
    ({ snapshot, reset }) => async () => {
      const pivotLight = await snapshot.getPromise(PivotLightStateList);

      if (pivotLight) {
        pivotLight.forEach((pivot) => {
          reset(PivotPainelStreamFamily(pivot.id));
          reset(PivotGPSStreamFamily(pivot.id));
          reset(PivotConfigFamily(pivot.id));
          reset(FallbackPivot(pivot.id));
        });
      }
    }
  );

  const navigate = useNavigate();

  const location = useLocation();

  const ctx = useContext(DrawerContext);

  const query = QueryString.parse(location.search) as { noPivots?: string };

  useEffect(() => {
    // if (lastFarmId != farmID) {
    resetPivotRelatedStreams();

    lastFarmId = farmID;
    // }

    (async () => {
      const pivotsLightResponse = await coreHTTPClient.get(
        `v3/farms/${farmID}/pivots/light/`
      );

      setPivotLight(pivotsLightResponse.data);
    })();

    (async () => {
      const metersystemsResponse = await coreHTTPClient.get(
        `v3/farms/${farmID}/metersystems/`
      );

      metersystemsResponse.data?.forEach((meterSystem) => {
        meterSystem.imeter_set.forEach((imeter) => {
          iMeterHelper({
            IMeterID: imeter.id,
            imeter,
          });
        });
      });

      // iMeterHelper;

      setMeterSystems(metersystemsResponse.data);
    })();
  }, [farmID]);

  useEffect(() => {
    if (farm) {
      document.title = farm.name;
    }
  }, [farm]);

  if (isMobile()) {
    window.location.hash = "no-back-button";
  }

  const editButton = (
    <Tooltip
      arrow={true}
      title={i18n("SELECTED_FARM_EDIT_BUTTON_TEXT") + " " + i18n("FARM")}
      placement="right"
    >
      <IconButton
        color="primary"
        size="medium"
        disabled={!farm?.is_administrator}
        onClick={() =>
          navigate(routes.DASHBOARD.EDIT_FARM.replace(":farm", String(farmID)))
        }
      >
        <EditSharp />
      </IconButton>
    </Tooltip>
  );

  /*
  Quando o usuário troca de fazenda pela lista na drawer esquerda
  a página usa a farm light, que tem só id, nome e timezone.
  É preciso então bater na rota da farm/id para pegar o objeto completo da
  farm, cuja propriedade is_administrator precisamos para exibir ou não o botão
  de editar fazenda. O código abaixo faz isso. Ao trocar de farmID, pede a fazenda 
  inteira para a rota.
  */
  useEffect(() => {
    if (farm?.id) farmActions.get(farm.id);
  }, [farm?.id]);

  useEffect(() => {
    ctx.setPivotID(null);
    ctx.setPivotNavigate(true);
  }, []);

  const navMobileEditButton = (
    <IconButton
      onClick={() =>
        navigate(routes.DASHBOARD.EDIT_FARM.replace(":farm", String(farmID)))
      }
    >
      <EditIcon style={{ color: "#FFF", fontSize: "20px" }} />
    </IconButton>
  );

  if (asyncStatus.done && farm !== undefined) {
    // For Mobile: to save farm id and name, because it's gonna be needed inside the navbar
    dispatch(
      storeFarm({
        id: farm.id,
        name: farm.name,
        is_administrator: farm?.is_administrator,
        rightButton: navMobileEditButton,
        timezone: farm?.timezone,
      })
    );

    if (pivotLight && !loadingIrpds && !loadingBauerPivots) {
      return (
        <>
          <DesktopZone>
            <div className="selected-farm-container">
              <DeviceBox
                loading={!pivotLight}
                farm={farm}
                pivots={query.noPivots ? [] : pivotLight}
                irpds={irpds}
                bauerPivots={bauerPivots}
                metersystems={meterSystems}
                repeaters={repeaters}
                farmMapRef={farmMapRef}
                leftElement={<></>}
                centerElement={<>{farm.name}</>}
                rightElement={editButton}
              />
            </div>

            <div className="selected-farm-map-container">
              <FarmMap
                ref={farmMapRef}
                pivotsLight={pivotLight}
                bauerPivots={bauerPivots}
                draggable={true}
                irpds={irpds}
                repeaters={repeaters}
                meterSystems={meterSystems}
              ></FarmMap>
            </div>
          </DesktopZone>

          <MobileZone>
            <div
              className={
                isMobile() ? "selected-farm-mobile" : "selected-farm-desktop"
              }
            >
              {/* <DrawerRightSelectFarm /> */}
              <div className="selected-farm__grid">
                <div className="selected-farm__devices">
                  {/*
                    É usado display none ao invés de uma verificação inline, pois o mapa depende da regra
                    de negócio do DeviceBox para carregar o FarmMap.
                  */}

                  <div style={selectedTab === 1 ? null : { display: "none" }}>
                    <MapContainer>
                      <FarmMap
                        ref={farmMapRef}
                        pivotsLight={pivotLight}
                        bauerPivots={bauerPivots}
                        draggable={true}
                        irpds={irpds}
                        repeaters={repeaters}
                        meterSystems={meterSystems}
                        isTabSelected={selectedTab}
                      />
                    </MapContainer>
                  </div>

                  <div style={selectedTab === 0 ? null : { display: "none" }}>
                    <DeviceBox
                      farm={farm}
                      loading={!pivotLight}
                      pivots={query.noPivots ? [] : pivotLight}
                      bauerPivots={bauerPivots}
                      irpds={irpds}
                      metersystems={meterSystems}
                      repeaters={repeaters}
                      farmMapRef={farmMapRef}
                    />
                  </div>
                </div>
              </div>
            </div>

            <BottomContainer>
              <BottomNavigation
                value={selectedTab}
                onChange={(event, newValue) => {
                  localStorage.setItem("selected-bottom-tab", String(newValue));
                  setSelectedTab(newValue);
                }}
                showLabels
              >
                <BottomNavigationAction
                  label={i18n("SELECTED_FARM_LIST")}
                  icon={<ListIcon />}
                />
                <BottomNavigationAction
                  label={i18n("SELECTED_FARM_MAP")}
                  icon={<Map />}
                />
              </BottomNavigation>
            </BottomContainer>
          </MobileZone>
        </>
      );
    }

    return <></>;
  }

  return <CustomCircularProgress />;
}

export default SelectedFarm;

export const BottomContainer = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0px -2px 7px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -2px 7px -2px rgba(0, 0, 0, 0.5);
  z-index: 4;
  padding-bottom: ${Capacitor?.getPlatform() === "ios" ? "12px" : "0px"};
  background-color: white;
`;

export function getNotchSize(deviceNotch) {
  if (deviceNotch === "none") return "55px";
  else if (deviceNotch === "full") return "95px";
  else if (deviceNotch === "minimal") return "65px";
}

export const MapContainer = styled.div`
  position: fixed;
  top: ${getNotchSize(getDeviceNotch())};
  left: 0;
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  padding-bottom: ${Capacitor?.getPlatform() === "ios" ? "122px" : "110px"};
  z-index: 1;
`;

import { Capacitor } from "@capacitor/core";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormCheckBox from "../../../../../../components/Form/FormCheckBox/FormCheckBox";
import DesktopZone from "../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../components/Zones/MobileZone";
import { i18n } from "../../../../../../i18n/i18nText";
import { coreHTTPClient } from "../../../../../../services/webclient";

const DarkBackground = styled(motion.div)`
  position: fixed;
  z-index: 9998;

  overflow: hidden;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const PolicyModalMobile = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;

  margin-bottom: -5vh;

  width: 100vw;
  height: 85vh;
  z-index: 99998;

  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const DesktopDescription = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

const PolicyModalDesktop = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: 600px;
  background-color: #fff;
`;

const PolicyContainer = styled.div`
  max-height: calc(85vh - 90px);
  width: 100%;
  display: flex;

  padding: 0 40px 25px 40px;

  font-size: 15px;
  text-align: left;
  word-break: break-word;
  white-space: pre-line;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    height: 100%;
  }
`;

const MobileTopBar = styled.div`
  margin: 10px 10px;
`;

const IconContainer = styled.span`
  vertical-align: middle;
  margin-left: -20px;
  margin-right: 15px;
`;

const PolicyDescriptionMobile = styled.p`
  overflow-y: auto;
  margin: 0;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 10px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  @media (max-width: 350px) {
    max-height: 250px;
  }
`;

const BACKDROP_VARIANTS = {
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
};

const POLICY_MODAL_VARIANTS_MOBILE = {
  hidden: {
    y: "80vh",
  },
  visible: {
    y: 0,
  },
};

const POLICY_MODAL_VARIANTS_DESKTOP = {
  hidden: {
    display: "none",
    y: "-200%",
    x: "-50%",
  },
  visible: {
    display: "block",
    y: "-50%",
    x: "-50%",
  },
};

interface Props {
  notification: any;
  acceptedTerms: boolean;
  policyInfos: any;
}

export default function PrivacyPolicies(props: Props) {
  const { notification, acceptedTerms, policyInfos } = props;
  interface Policy {
    uuid: string;
    id: number;
    priority: string;
  }
  const [policy, setPolicy] = useState<Policy>();
  const [animation, setAnimation] = useState("hidden");
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  const [checkboxAccepted, setCheckboxAccepted] = useState(false);

  useEffect(() => {
    if (notification) {
      const { uuid, id, priority } = notification;
      setPolicy({
        uuid,
        id,
        priority,
      });
      setAnimation("visible");
    }
  }, [notification]);

  const PRIORITY_MAP = {
    default: { icon: <CheckCircleIcon />, color: "#44b86a" },
    medium: { icon: <WarningIcon />, color: "rgb(255, 215, 0)" },
    high: { icon: <ErrorIcon />, color: "rgb(233, 36, 0)" },
  };

  useEffect(() => {
    if (redirect) {
      navigate("/dashboard");
      window.location.reload();
    }
  }, [redirect]);

  useEffect(() => {
    if (animation === "hidden") {
      document.body.style.overflow = "initial";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [animation]);

  const acceptedTermsPayload = {
    is_accept_policy: true,
    accept_policy_version: policyInfos.last_policy_version,
    accept_policy_date: moment(),
  };

  const handleOkClick = async () => {
    if (!policyInfos.is_accept_policy) {
      try {
        await coreHTTPClient.patch(
          "/v3/auth/check_policy/",
          acceptedTermsPayload
        );
      } catch (err) {
        console.log(err);
      } finally {
        setRedirect(true);
      }
    }
    localStorage.setItem("lastAnnouncementUUID", String(policy.uuid));
    setAnimation("hidden");
  };

  return (
    <>
      <MobileZone>
        <PolicyModalMobile
          onMouseEnter={(ev) => {
            if (!Capacitor.isNativePlatform()) {
              document.body.style.overflow = "hidden";
            }
          }}
          initial="hidden"
          animate={animation}
          variants={POLICY_MODAL_VARIANTS_MOBILE}
        >
          <PolicyContainer>
            <MobileTopBar />
            <h2
              style={{
                display: "inline-block",
                color: PRIORITY_MAP[policy?.priority]?.color,
              }}
            >
              {acceptedTerms && (
                <IconContainer>
                  {PRIORITY_MAP[policy?.priority]?.icon}
                </IconContainer>
              )}
              {policyInfos.accept_policy_version
                ? i18n("POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE")
                : i18n("POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS")}
            </h2>
            <PolicyDescriptionMobile id="policy">
              {policyInfos.accept_policy_version ? (
                <>
                  <p style={{ fontSize: "16px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                    </a>{" "}
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2")}
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      https://irricontrol.com.br/plataforma-termos-e-privacidade/
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p style={{ fontSize: "16px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                    </a>{" "}
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2")}
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      https://irricontrol.com.br/plataforma-termos-e-privacidade/
                    </a>
                  </p>
                </>
              )}
            </PolicyDescriptionMobile>
            <div
              style={{
                borderWidth: "0",
                padding: "10px",
              }}
            >
              <FormCheckBox
                state={[checkboxAccepted, setCheckboxAccepted]}
                label="POLICY_TERMS_AND_CONDITIONS_CHECKBOX"
                fontSize="14px"
              />
            </div>
            <Button
              style={{
                width: "90%",
                position: "fixed",
                bottom: 60,
                backgroundColor: PRIORITY_MAP[policy?.priority]?.color,
              }}
              variant="contained"
              color="secondary"
              onClick={handleOkClick}
              disabled={!checkboxAccepted && !acceptedTerms}
            >
              {i18n("ACCEPT_TEXT")}
            </Button>
          </PolicyContainer>
        </PolicyModalMobile>
        <DarkBackground
          onScroll={(e) => {
            e.preventDefault();
          }}
          initial="hidden"
          animate={animation}
          variants={BACKDROP_VARIANTS}
        />
      </MobileZone>

      <DesktopZone>
        <PolicyModalDesktop
          initial="hidden"
          animate={animation}
          variants={POLICY_MODAL_VARIANTS_DESKTOP}
        >
          <PolicyContainer>
            <h1
              style={{
                paddingTop: 10,
                display: "inline-block",
                color: PRIORITY_MAP[policy?.priority]?.color,
                textAlign: "center",
              }}
            >
              {acceptedTerms && (
                <IconContainer>
                  {PRIORITY_MAP[policy?.priority]?.icon}
                </IconContainer>
              )}
              {policyInfos.accept_policy_version
                ? i18n("POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE")
                : i18n("POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS")}
            </h1>
            <DesktopDescription id="policy">
              {policyInfos.accept_policy_version ? (
                <>
                  <p style={{ fontSize: "20px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                    </a>{" "}
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2")}
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      https://irricontrol.com.br/plataforma-termos-e-privacidade/
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p style={{ fontSize: "20px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                    </a>{" "}
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2")}
                  </p>
                  <p style={{ fontSize: "20px" }}>
                    {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN")}{" "}
                    <a
                      title={i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                      href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                      target="_blank"
                    >
                      https://irricontrol.com.br/plataforma-termos-e-privacidade/
                    </a>
                  </p>
                </>
              )}
            </DesktopDescription>
            <div style={{ marginTop: 0 }}>
              <div
                style={{
                  borderWidth: "0",
                  padding: "10px",
                }}
              >
                <FormCheckBox
                  state={[checkboxAccepted, setCheckboxAccepted]}
                  label="POLICY_TERMS_AND_CONDITIONS_CHECKBOX"
                  fontSize="1rem"
                />
              </div>
            </div>
            <Button
              style={{
                width: "90%",
                backgroundColor: PRIORITY_MAP[policy?.priority]?.color,
              }}
              variant="contained"
              color="secondary"
              onClick={handleOkClick}
              disabled={!checkboxAccepted && !acceptedTerms}
            >
              {i18n("ACCEPT_TEXT")}
            </Button>
          </PolicyContainer>
        </PolicyModalDesktop>
        <DarkBackground
          onScroll={(e) => {
            e.preventDefault();
          }}
          initial="hidden"
          animate={animation}
          variants={BACKDROP_VARIANTS}
        />
      </DesktopZone>
    </>
  );
}

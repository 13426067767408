export function checkEmail(str: string) {
  // eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );
}

export const checkUInt = (value: string): boolean => {
  return /^$|^[0-9]+$/.test(value);
};

export const checkFloat = (value: string): boolean => {
  return /^[+-]$|^[+-]?\d+?(\.)?(\d+)?$/.test(value);
};

export const checkLatLng = (value: string): boolean => {
  return /^(-?\d+(\.\d+)),?\s*(-?\d+(\.\d+)?)$/.test(value);
};

export const checkLat = (value: string): boolean => {
  return /^-?([0-8]?[0-9]|90)\.[0-9]{1,6}$/.test(value);
};

export const checkLng = (lng: string): boolean => {
  return /^-?((1?[0-7]?|[0-9]?)[0-9]|180)\.[0-9]{1,6}$/.test(lng);
};

export const checkLatCoordinates = (value: string) =>
  checkIsOnInterval(value, -90, 90);

export const checkLngCoordinates = (value: string) =>
  checkIsOnInterval(value, -180, 180);

export const checkIsOnInterval = (value: string, min: number, max: number) => {
  return (
    value === "" ||
    value === "-" ||
    (checkFloat(value) && parseFloat(value) >= min && parseFloat(value) <= max)
  );
};

export const formatPositiveFloat = (value: string): string => {
  return value?.replace(",", ".").replace("-", "");
};

export const formatFloat = (value: string): string => {
  return value?.replace(",", ".");
};

export const checkIsOnIntervalNumeric = (
  value: string,
  min?: number,
  max?: number,
  excludeZero?: boolean
) => {
  let message = undefined;
  if (value === "" || !checkFloat(value)) message = "ERROR_EMPTY_FIELD";
  else if (excludeZero && parseFloat(value) === 0) message = "ERROR_ZERO";
  else if (min && checkFloat(value) && parseFloat(value) < min)
    message = "ERROR_VALUE_UNDER";
  else if (max && checkFloat(value) && parseFloat(value) > max)
    message = "ERROR_VALUE_OVER";
  return message;
};

export const checkIsOnIntervalString = (
  text: string,
  min?: number,
  max?: number
) => {
  let message = undefined;

  const textNoSpaces = text.replace(/\s+/g, "");

  if (!textNoSpaces) message = "ERROR_EMPTY_FIELD";
  else if (min && textNoSpaces.length < min)
    message = "ERROR_INVALID_AMOUNT_MIN";
  else if (max && textNoSpaces.length > max)
    message = "ERROR_INVALID_AMOUNT_MAX";
  return message;
};

export const checkSegment = (
  workingAngle: number,
  angleStart: number,
  angleEnd: number,
  arrayAngleStart: string[],
  arrayAngleEnd: string[]
): boolean => {
  let nextValue = -1;
  let flag = true;
  const arrayStartSort = arrayAngleStart.map((elem) => {
    return parseInt(elem, 10);
  });
  const arrayStart = [...arrayStartSort];
  arrayStartSort.sort();

  if (workingAngle !== 360 && angleStart > angleEnd) return false;
  if (
    workingAngle !== 360 &&
    (angleStart >= workingAngle || angleEnd > workingAngle)
  )
    return false;

  if (angleStart === angleEnd) return false;

  for (let index = 0; index < arrayStart.length; index++) {
    if (arrayStart[index] < parseInt(arrayAngleEnd[index], 10)) {
      if (angleStart > angleEnd) {
        if (
          angleStart >= arrayStart[index] &&
          angleStart < parseInt(arrayAngleEnd[index], 10)
        )
          return false;

        if (angleEnd > arrayStartSort[0]) return false;

        if (arrayStart[index] > angleStart) return false;
      } else {
        if (arrayStart[index] > angleStart && flag) {
          flag = false;
          if (angleEnd > arrayStart[index]) return false;
        }
        if (
          angleStart >= arrayStart[index] &&
          angleStart < parseInt(arrayAngleEnd[index], 10)
        )
          return false;
      }
    } else if (arrayStart[index] > parseInt(arrayAngleEnd[index], 10)) {
      if (angleStart > angleEnd) return false;
      if (angleStart >= arrayStart[index]) return false;

      if (
        angleStart + 360 >= arrayStart[index] &&
        angleStart + 360 < parseInt(arrayAngleEnd[index], 10) + 360
      )
        return false;

      if (arrayStart[index] > angleStart && flag) {
        nextValue = arrayStart[index];
        flag = false;
        if (angleEnd > nextValue) return false;
      }
    }
  }

  return true;
};

export const checkSegmentComplete = (
  workingAngle: number,
  arrayAngleStart: string[],
  arrayAngleEnd: any[]
): boolean => {
  let sum = 0;
  arrayAngleStart.map((elem, index) => {
    if (parseInt(elem, 10) < parseInt(arrayAngleEnd[index]?.angle, 10)) {
      sum += parseInt(arrayAngleEnd[index]?.angle, 10) - parseInt(elem, 10);
    } else
      sum +=
        360 - parseInt(elem, 10) + parseInt(arrayAngleEnd[index]?.angle, 10);
  });
  if (workingAngle === sum) return true;
  else return false;
};

export const validateSegments = (
  workingAngle: number,
  arrayAngleStart: string[],
  arrayAngleEnd: string[]
): boolean => {
  for (let i = 0; i < arrayAngleStart.length; i++) {
    const startAngle = parseInt(arrayAngleStart[i], 10);
    const endAngle = parseInt(arrayAngleEnd[i], 10);
    if (startAngle >= endAngle) {
      return false;
    }
  }
  const lastSegment = parseInt(arrayAngleEnd[arrayAngleEnd.length - 1], 10);
  return lastSegment === workingAngle;
};

export const checkIsNotNumber = (value: string): boolean => {
  return /^([^0-9]*)$/.test(value);
};

export function validate_cpf(cpf) {
  cpf = cpf?.replace(/[^\d]+/g, "");
  if (cpf == "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf?.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  let i = 0;
  let rev = 0;
  for (i = 0; i < 9; i++) add += parseInt(cpf?.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf?.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf?.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf?.charAt(10))) return false;
  return true;
}

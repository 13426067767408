import React, { useEffect, useMemo, useRef, useState } from "react";
import "./PivotGeneralBox.scss";
import {
  Power,
  AutorenewTwoTone,
  PowerSettingsNew,
  Timelapse,
  RadioButtonChecked,
  Schedule,
  Opacity,
  PieChartOutlined,
  AvTimer,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import moment from "moment";
import { Button, Icon, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../redux/modal/actions";
import ConventionalIrrigation from "../IrrigationBox/components/ConventionalIrrigation/ConventionalIrrigation";
import ConventionalIrrigationV5 from "../IrrigationBox/components/ConventionalIrrigationV5/ConventionalIrrigationV5";
import { useParams } from "react-router";
import ScheduleIrrigation from "../IrrigationBox/components/ScheduleIrrigationV5/ScheduleIrrigationV5";
import SegmentedIrrigation from "../IrrigationBox/components/SegmentIrrigationV5/SegmentIrrigationV5";
import DialogModal from "../../../../../../components/DialogModal/DialogModal";
import { useStyles } from "../IrrigationBox/IrrigationBox.style";
import FarmMap from "../../../../Farm/SelectedFarm/components/FarmMap/FarmMap";
import useStopPivot from "../../../../../../hooks/models/useStopPivot";
import useCreatePivotAction from "../../../../../../hooks/models/useCreatePivotAction";
import NewByAngleIrrigation from "../IrrigationBox/components/NewByAngleIrrigation/NewByAngleIrrigation";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { isMobile } from "../../../../../../mobileConfig";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  FullPivotFamily,
  PivotLightStateFamily,
  PivotPainelStreamFamily,
  PivotGPSStreamFamily,
  PivotConfigFamily,
  PivotMaintenanceFamily,
  PivotPeriodicStreamFamily,
  PivotMapTowerStatus,
  PivotPanelTowerStatus,
  LatestStreamPivot,
} from "../../../../../../recoils/PivotRecoil";

import {
  convertIrrigationRemainingTimeToHoursMinutes,
  isValidIrrigationRemainingTime,
  parseHourmeter,
  PIVOT_STATUS_COLOR_V5_BLUE,
} from "../../../../../../utils/models/pivots";

import {
  ControllerStream,
  GPSStream,
  PivotConfig,
  ControllerConfig,
  PanelStreamOld,
  PIVOT_COMMUNICATION_TYPE,
} from "../../../../../../redux/pivots/types";

import {
  pivotActionDirectionIcon,
  pivotActionDirectionIconV5,
} from "../HistoricBox/utils/utils";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import NewByAngleIrrigationV5 from "../IrrigationBox/components/NewByAngleIrrigationV5/NewByAngleIrrigationV5";
import StatusHelper from "../../../../Farm/SelectedFarm/components/DeviceBox/components/PivotItem/StatusHelperPopover";
import { getDailyMesure } from "../../../utils/utils";
import QuickSelect from "../../../../../../components/QuickSelect/QuickSelect";
import usePivotIsOnline from "../../../../../../hooks/tools/usePivotIsOnline";
import { formatFloatValue } from "../../../../../../utils/models/format";
import useNotifyIfDebtor from "../../../../../../hooks/models/useNotifyIfDebtor";
import useCentralIsOnline from "../../../../../../hooks/tools/useCentralIsonline";
import useDebtor from "../../../../../../hooks/models/useDebtor";
import IrrifastBox from "../IrrifastBox";

// constante utilizada para montar a tooltip do horímetro
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    fontSize: 11,
  },
}))(Tooltip);
// -------------------------------------------------------

const { SOCKET_SUFFIX } = process.env;
const EndTowerIcon = require("../../../../../../assets/images/end_tower_pressure.png");
const CenterIcon = require("../../../../../../assets/images/center_pressure.png");

const StyledAlert = styled(Alert)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  background-color: gray;
  font-weight: 600;
  padding: 4px 8px;
  font-size: 0.7rem;
  border-radius: 8px;
  color: white;

  @media (max-width: 1024px) {
    margin-top: 12px;
  }
`;

const VerticalSpace = styled.div`
  width: 3px;
`;

const StyledSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 7px;

  .MuiSvgIcon-root {
    height: 20px;
    color: black;
  }
`;

// TODO: Isolate this type
export interface PivotStatusAPIResponse {
  irrigation_status: number;
  power: number;
  voltage: number;
  angle: number;
}

function PivotGeneralBox(props: any) {
  // estados utilizados para montar a tooltip do horímetro no modo mobile
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  // --------------------------------------------------------------------

  // const { pivot } = useContext(PivotContext);
  const dispatch = useDispatch();

  const farmID: number = parseInt(useParams().farm, 10);

  const [, notifyIfDebtor] = useNotifyIfDebtor(farmID);

  const [, debtor, action] = useDebtor(farmID);

  const classes = useStyles(props);

  const [stopPivot] = useStopPivot();

  const [createPivotAction] = useCreatePivotAction();

  const pivotID: number = parseInt(useParams().pivot, 10);
  const pivot = useRecoilValue(FullPivotFamily(pivotID));

  const pivotLight = useRecoilValue(PivotLightStateFamily(pivotID));

  // Production only
  const [isLoading, isOnline, getPivotIsOnline] = usePivotIsOnline();

  useEffect(() => {
    action.get(farmID, false);
  }, []);

  useEffect(() => {
    if (
      pivot.base_radio_id &&
      pivot.automation_type === PIVOT_COMMUNICATION_TYPE.TYPE_XBEE
    ) {
      getPivotIsOnline(pivot.base_radio_id);
    } else {
      getPivotIsOnline(pivot.control_radio_id);
    }
  }, [pivot.control_radio_id, pivot.base_radio_id]);

  // RECOIL
  // Estados recoil
  const [latestPanelStream, setLatestPanelStream] = useRecoilState(
    PivotPainelStreamFamily(pivotID)
  );
  const [latestGPSStream, setLatestGPSStream] = useRecoilState(
    PivotGPSStreamFamily(pivotID)
  );

  // Referencia dos estados para não haver problemas quando paralelizar ele no Interval
  const latestPanelStreamRef = useRef(latestPanelStream);
  const latestGPSStreamRef = useRef(latestGPSStream);
  useEffect(() => {
    latestPanelStreamRef.current = latestPanelStream;
    latestGPSStreamRef.current = latestGPSStream;
  }, [latestPanelStream, latestGPSStream]);

  const [newConfig, setNewConfig] = useRecoilState(PivotConfigFamily(pivotID));

  const maintenanceStatus = useRecoilValue(PivotMaintenanceFamily(pivotID));

  const latestStreamPivot = useRecoilValue(LatestStreamPivot(pivotID));

  const [latestPeriodicStream, setLatestPeriodicStream] = useRecoilState(
    PivotPeriodicStreamFamily(pivotID)
  );

  const [, setMapTowerStream] = useRecoilState(PivotMapTowerStatus(pivotID));

  const [, setPanelTowerStream] = useRecoilState(
    PivotPanelTowerStatus(pivotID)
  );

  const [newLatestAngle, setNewLatestAngle] = useState<string | undefined>(
    undefined
  );

  const [latestEndTowerPressure, setLatestEndTowerPressure] = useState<
    string | undefined
  >(undefined);

  const [latestCenterPressure, setLatestCenterPressure] = useState<
    string | undefined
  >(undefined);

  const totalHourMeterFormatted = useMemo(() => {
    if (!latestGPSStream?.created && !latestPanelStream?.created) return " - ";

    if (pivot.protocol === 5) {
      if (
        moment(latestPanelStream?.created).isSameOrAfter(
          moment(latestGPSStream?.created).second(0)
        )
      ) {
        return `${parseHourmeter(
          (latestPanelStream as ControllerStream)?.content?.operation_time
            ?.total_hour *
            60 +
            (latestPanelStream as ControllerStream)?.content?.operation_time
              ?.total_minute
        )}`;
      }

      return `${parseHourmeter(
        (latestGPSStream as ControllerStream)?.content?.operation_time
          ?.total_hour *
          60 +
          (latestGPSStream as ControllerStream)?.content?.operation_time
            ?.total_minute
      )}`;
    }

    if (
      moment(latestPanelStream?.created).isSameOrAfter(
        moment(latestGPSStream?.created).second(0)
      )
    ) {
      return `${parseHourmeter(
        (latestPanelStream as PanelStreamOld)?.hourmeter
      )}`;
    }

    return `${parseHourmeter((latestGPSStream as GPSStream)?.hourmeter)}`;
  }, [latestPanelStream, latestGPSStream, pivot.protocol]);

  const wetHourMeterFormatted = useMemo(() => {
    if (!latestGPSStream?.created && !latestPanelStream?.created) return " - ";

    if (pivot.protocol === 5) {
      if (
        moment(latestPanelStream?.created).isSameOrAfter(
          moment(latestGPSStream?.created).second(0)
        )
      ) {
        return `${parseHourmeter(
          (latestPanelStream as ControllerStream)?.content?.operation_time
            ?.wet_hour *
            60 +
            (latestPanelStream as ControllerStream)?.content?.operation_time
              ?.wet_minute
        )}`;
      }

      return `${parseHourmeter(
        (latestGPSStream as ControllerStream)?.content?.operation_time
          ?.wet_hour *
          60 +
          (latestGPSStream as ControllerStream)?.content?.operation_time
            ?.wet_minute
      )}`;
    }

    if (
      moment(latestPanelStream?.created).isSameOrAfter(
        moment(latestGPSStream?.created).second(0)
      )
    ) {
      return `${parseHourmeter(
        (latestPanelStream as PanelStreamOld)?.hourmeter
      )}`;
    }

    return `${parseHourmeter((latestGPSStream as GPSStream)?.hourmeter)}`;
  }, [latestPanelStream, latestGPSStream, pivot.protocol]);

  const dryHourMeterFormatted = useMemo(() => {
    const totalMinutes_Dry_Panel =
      ((latestPanelStream as ControllerStream)?.content?.operation_time
        ?.total_hour -
        (latestPanelStream as ControllerStream)?.content?.operation_time
          ?.wet_hour) *
        60 +
      ((latestPanelStream as ControllerStream)?.content?.operation_time
        ?.total_minute -
        (latestPanelStream as ControllerStream)?.content?.operation_time
          ?.wet_minute);

    const totalMinutes_Dry_GPS =
      ((latestGPSStream as ControllerStream)?.content?.operation_time
        ?.total_hour -
        (latestGPSStream as ControllerStream)?.content?.operation_time
          ?.wet_hour) *
        60 +
      ((latestGPSStream as ControllerStream)?.content?.operation_time
        ?.total_minute -
        (latestGPSStream as ControllerStream)?.content?.operation_time
          ?.wet_minute);

    if (!latestGPSStream?.created && !latestPanelStream?.created) return " - ";

    if (pivot.protocol === 5) {
      if (
        moment(latestPanelStream?.created).isSameOrAfter(
          moment(latestGPSStream?.created).second(0)
        )
      ) {
        return `${parseHourmeter(totalMinutes_Dry_Panel)}`;
      }

      return `${parseHourmeter(totalMinutes_Dry_GPS)}`;
    }

    if (
      moment(latestPanelStream?.created).isSameOrAfter(
        moment(latestGPSStream?.created).second(0)
      )
    ) {
      return `${parseHourmeter(
        (latestPanelStream as PanelStreamOld)?.hourmeter
      )}`;
    }

    return `${parseHourmeter((latestGPSStream as GPSStream)?.hourmeter)}`;
  }, [latestPanelStream, latestGPSStream, pivot.protocol]);

  // Função para a comparação de valores de cada objeto feito para apenas atualizar o Pivo se ele realmente for diferente
  function isObject(object) {
    return object != null && typeof object === "object";
  }
  function deepEqual(object1, object2) {
    if (!object1 || !object2) return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  const irrigationData = useMemo(() => {
    if (latestPanelStream && latestGPSStream) {
      if (
        moment(latestPanelStream.created).isSameOrAfter(
          moment(latestGPSStream.created).second(0)
        )
      ) {
        return null;
      }
      if (pivot.protocol === 5) {
        if (
          (latestGPSStream as ControllerStream).content
            ?.current_irrigation_information?.direction === 0 ||
          !(latestGPSStream as ControllerStream).content
            ?.current_irrigation_information?.irrigation_percent
        ) {
          return null;
        }
        return {
          percentage: (latestGPSStream as ControllerStream).content
            .current_irrigation_information.irrigation_percent,
          direction: pivotActionDirectionIconV5(
            (latestGPSStream as ControllerStream).content
              .current_irrigation_information.direction
          ),
        };
      }
      if (
        (latestGPSStream as GPSStream).clockwise === 0 ||
        !(latestGPSStream as GPSStream).speed
      ) {
        return null;
      }
      return {
        percentage: (latestGPSStream as GPSStream).speed,
        direction: pivotActionDirectionIcon(
          (latestGPSStream as GPSStream).clockwise
        ),
      };
    }
    return null;
  }, [latestGPSStream, latestPanelStream, pivot]);

  const showPluviometerInfos = useMemo(() => {
    if (pivot?.pluviometer && pivot.protocol === 5) {
      return true;
    }
    return false;
  }, [pivot?.pluviometer, pivot.protocol]);

  const showPluviometerMeasure = useMemo(() => {
    const periodicDate: moment.Moment = moment(latestPeriodicStream?.created);
    const dateCompare: boolean =
      periodicDate.day() === moment().day() &&
      periodicDate.month() === moment().month() &&
      periodicDate.year() === moment().year();

    if (dateCompare) {
      return true;
    }
    return false;
  }, [latestPeriodicStream]);

  const showPluviometerDecrementAlert = useMemo(() => {
    if (
      !latestGPSStream ||
      moment().diff(moment(latestGPSStream?.created), "minutes") >= 5 ||
      latestStreamPivot.streamStatus.color !== PIVOT_STATUS_COLOR_V5_BLUE // Azul é irrigando
    ) {
      return false;
    }

    const tempLatestGPSStream = latestGPSStream as ControllerStream;

    const gpsDate: moment.Moment = moment(tempLatestGPSStream?.created);
    const latestStreamDate: moment.Moment = moment(latestStreamPivot?.created);

    const dateCompare: boolean =
      gpsDate.day() === latestStreamDate.day() &&
      gpsDate.month() === latestStreamDate.month() &&
      gpsDate.year() === latestStreamDate.year();

    return (
      pivot.protocol === 5 &&
      pivot.pluviometer &&
      pivot?.controllerconfig?.content?.pluviometer_stop_mode?.stop_mode ===
        2 &&
      tempLatestGPSStream?.content?.current_irrigation_information
        ?.rain_meter_percent > 0 &&
      dateCompare
    );
  }, [
    pivot.protocol,
    pivot.pluviometer,
    latestGPSStream as ControllerStream,
    latestStreamPivot?.created,
  ]);

  // useEffect dos estados derivados

  // Este Use Effect é uma segunda segurança para manter o stream que vem pelo
  // subscibe atualizado caso ele falhe.
  // logo a cada um minuto ele realiza um "Check"
  useEffect(() => {
    const id = setInterval(async () => {
      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/pivots/${pivotID}/stream/latest/`
        );
        if (response.data) {
          if (
            response.data.latest_panel_stream &&
            !deepEqual(
              response.data.latest_panel_stream,
              latestPanelStreamRef.current
            )
          ) {
            setLatestPanelStream(response.data.latest_panel_stream);
          }
          if (
            response.data.latest_gps_stream &&
            !deepEqual(
              response.data.latest_gps_stream,
              latestGPSStreamRef.current
            )
          ) {
            setLatestGPSStream(response.data.latest_gps_stream);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 60 * 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (!pivot) return;
    if (pivot.protocol !== 5 && latestGPSStream) {
      setNewLatestAngle((latestGPSStream as GPSStream)?.angle);
      // Este pivotID 43 é um caso excepcional onde o pivô 43 da fazenda Boissucanga
      // devolve lixo em todas leituras de pressão. É o único onde ocorre isso.
      setLatestEndTowerPressure(
        (latestGPSStream as GPSStream)?.water_pressure > 0 && pivotID !== 43
          ? (latestGPSStream as GPSStream)?.water_pressure.toString()
          : "-"
      );
    } else if (pivot.protocol === 5 && latestGPSStream) {
      setNewLatestAngle(
        (latestGPSStream as ControllerStream)?.content?.current_angle
          ?.current_angle
      );
      // Para pivôs V5 recebe os valores de sensor de pressão na torre final e no centro do pivô
      setLatestEndTowerPressure(
        (latestGPSStream as GPSStream)?.content?.end_tower_pressure
          ?.end_tower_pressure > 0
          ? (latestGPSStream as GPSStream)?.content?.end_tower_pressure
              ?.end_tower_pressure
          : "-"
      );
      setLatestCenterPressure(
        (latestGPSStream as GPSStream)?.content?.center_pressure
          ?.center_pressure > 0
          ? (latestGPSStream as GPSStream)?.content?.center_pressure
              ?.center_pressure
          : "-"
      );
    }
  }, [latestGPSStream, pivot]);

  // FIM DO RECOIL

  const [isConnected, socket] = useSocketIO();

  const [loading, setLoading] = useState(true);

  const [isRaining, setIsRaining] = useState(false);

  useEffect(() => {
    if (
      latestPeriodicStream?.content?.pluviometer_daily_measure?.daily_measure >
      0
    ) {
      setIsRaining(
        moment().diff(moment(latestPeriodicStream?.created), "minutes") <= 70
      );
    }
  }, [latestPeriodicStream]);

  useEffect(() => {
    // if (alreadyInitializedSocket) return;
    // alreadyInitializedSocket = true;
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}pivot@${pivotID}`);

      socket.bind("ControllerStream_periodic", (data: ControllerStream) => {
        setLatestPeriodicStream(data);
      });

      if (pivotLight.protocol === 5) {
        socket.bind("ControllerStream_panel", (data) => {
          if (data.content.irrigation_status?.irrigation_status === 24) return;
          if (
            moment(data.created).isAfter(
              moment(latestPanelStreamRef.current.created)
            )
          ) {
            setLatestPanelStream(data);
          }
        });

        socket.bind("ControllerStream_gps", (data) => {
          if (
            data?.content?.latitude_longitude_gps?.latitude_gps === 0 ||
            data?.content?.latitude_longitude_gps?.longitude_gps === 0
          ) {
            return;
          }

          if (
            moment(data.created).isAfter(
              moment(latestGPSStreamRef.current.created)
            )
          ) {
            // Irrigation_status também vem do GPS
            if (data.content.irrigation_status) {
              setLatestPanelStream((latestPanel: any) =>
                latestPanel
                  ? ({
                      ...latestPanel,
                      content: {
                        ...latestPanel.content,
                        irrigation_status: data.content.irrigation_status,
                      },
                    } as any)
                  : undefined
              );
            }
            if (
              data.content.latitude_longitude_gps.latitude_gps !== 0 ||
              data.content.latitude_longitude_gps.longitude_gps !== 0
            ) {
              setLatestGPSStream(data);
            }
          }
        });

        socket.bind("ControllerStream_irrifast", (data) => {
          if (
            data?.content?.inverters_infos &&
            data.content.inverters_infos.length > 0
          ) {
            setPanelTowerStream(data.content?.inverters_infos);
            setMapTowerStream(
              data.content?.inverters_infos.map((t) => ({
                tower_number: t.tower_number,
                aligment_status: t.aligment_status,
              }))
            );
          }
        });
      } else {
        socket.bind("painel_stream", (data) => {
          // setLatestPanelStream(data);
          setLatestPanelStream(data);
        });
        socket.bind("gps_stream", (data) => {
          // setLatestGPSStream(data);
          setLatestGPSStream(data);
        });
      }
    }
    setLoading(false);

    return () => {
      if (pivot.protocol !== undefined) {
        socket.unbind("ControllerStream_panel");
        socket.unbind("ControllerStream_gps");
        socket.unbind("ControllerStream_irrifast");
        socket.unbind("painel_stream");
        socket.unbind("gps_stream");
        socket.unsubscribe(`${SOCKET_SUFFIX}pivot@${pivotID}`);
      }
    };
  }, [pivot.protocol]);

  if (!pivot) return <></>;

  /*
  OLD IRRIGATION HANDLERS
  */
  const conventionalIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: <ConventionalIrrigation pivot={pivot} farmID={farmID} />,
        title: "MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION",
      })
    );
  };

  const byAngleIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <NewByAngleIrrigation
            pivot={pivot}
            farmID={farmID}
            latestGPSStream={latestGPSStream as any}
          />
        ),
        title: "MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION",
      })
    );
  };

  const byAngleIrrigationHandlerV5 = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <NewByAngleIrrigationV5
            pivot={pivot}
            farmID={farmID}
            latestGPSStream={latestGPSStream as any}
            sector={pivot.controllerconfig.content.sector}
          />
        ),
        title: "MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION",
      })
    );
  };

  const stopIrrigationOldHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <DialogModal
            title="IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"
            description="IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION"
            descriptionArgs={[pivot.name]}
            yes="IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"
            yesAction={() => {
              createPivotAction({
                direction: "0",
                operation: "0",
                startDateTime: moment("0000-00-00 00:00Z").toISOString(),
                farmID: pivot.farm,
                pivotID: pivot.id,
                speed: "100",
                endgun: "0",
              });
            }}
            no="IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"
          />
        ),
      })
    );
  };

  /*
  V5 IRRIGATION HANDLERS
  */

  const simpleIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: <ConventionalIrrigationV5 pivot={pivot} farmID={farmID} />,
        title: "MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION",
      })
    );
  };

  const scheduleIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <ScheduleIrrigation
            onTurningOn={() => {
              // setIntervalId(piv => {
              //   startIntervalForPivotStatusFetchV5(piv);
              //   return piv;
              // });
            }}
            pivot={pivot}
            farmID={farmID}
            sectorEndAngle={pivot.controllerconfig.content.sector?.end_angle.toString()}
          />
        ),
        title: "MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION",
      })
    );
  };

  const segmentIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <SegmentedIrrigation
            sectorEndAngle={pivot.controllerconfig.content.sector?.end_angle.toString()}
            pivot={pivot}
            farmID={farmID}
          />
        ),
        title: "MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION",
      })
    );
  };

  const stopIrrigationV5Handler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <DialogModal
            title="IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"
            description="IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION"
            descriptionArgs={[pivot.name]}
            yes="IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"
            yesAction={() => {
              stopPivot(farmID, pivot.id);
            }}
            no="IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"
          />
        ),
      })
    );
  };

  /*
    Irrigation Action Buttons
  */

  const irrigationButtonsOld = (
    <>
      <Button
        className={isMobile() ? classes.bottomMobile : ""}
        variant="contained"
        color="primary"
        onClick={conventionalIrrigationHandler}
        startIcon={<RadioButtonChecked />}
        disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("SELECTED_PIVOT_STANDARD_IRRIGATION")}
      </Button>

      <Button
        className={isMobile() && classes.bottomMobile}
        variant="contained"
        color="primary"
        onClick={byAngleIrrigationHandler}
        startIcon={<Timelapse />}
        disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("SELECTED_PIVOT_BY_ANGLE_IRRIGATION")}
      </Button>
      <Button
        variant="contained"
        className={
          isMobile() ? classes.stopPivotButtonMobile : classes.stopPivotButton
        }
        onClick={stopIrrigationOldHandler}
        startIcon={<PowerSettingsNew />}
        disabled={!isOnline || maintenanceStatus !== false}
      >
        <>{i18n("SELECTED_PIVOT_STOP_ACTION")}</>
      </Button>
    </>
  );

  const irrigationButtonsV5 = (
    <>
      {pivot.brand_model === "gps" ? (
        <></>
      ) : (
        <>
          <Button
            className={isMobile() && classes.bottomMobile}
            variant="contained"
            color="primary"
            onClick={simpleIrrigationHandler}
            startIcon={<RadioButtonChecked />}
            disabled={!isOnline || maintenanceStatus !== false}
          >
            {i18n("SELECTED_PIVOT_STANDARD_IRRIGATION")}
          </Button>
          <Button
            className={isMobile() && classes.bottomMobile}
            variant="contained"
            color="primary"
            onClick={byAngleIrrigationHandlerV5}
            startIcon={<Timelapse />}
            disabled={!isOnline || maintenanceStatus !== false}
          >
            {i18n("SELECTED_PIVOT_BY_ANGLE_IRRIGATION")}
          </Button>
          <Button
            className={isMobile() && classes.bottomMobile}
            variant="contained"
            color="primary"
            onClick={segmentIrrigationHandler}
            startIcon={<PieChartOutlined />}
            disabled={!isOnline || maintenanceStatus !== false}
          >
            {i18n("SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON")}
          </Button>

          <Button
            className={isMobile() && classes.bottomMobile}
            variant="contained"
            color="primary"
            onClick={scheduleIrrigationHandler}
            startIcon={<Schedule />}
            disabled={!isOnline || maintenanceStatus !== false}
          >
            {i18n("SELECTED_PIVOT_SCHEDULE_IRRIGATION")}
          </Button>

          <Button
            variant="contained"
            className={
              isMobile()
                ? classes.stopPivotButtonMobile
                : classes.stopPivotButton
            }
            onClick={stopIrrigationV5Handler}
            startIcon={<PowerSettingsNew />}
            disabled={!isOnline || maintenanceStatus !== false}
          >
            <>{i18n("SELECTED_PIVOT_STOP_ACTION")}</>
          </Button>
        </>
      )}
    </>
  );

  // ///////////////////////////
  // Pivot live information //
  // ///////////////////////////

  //
  // V5
  //

  const mobileV5infos = (
    <>
      <div className="generalpivot__content__group">
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Status
            style={{
              backgroundColor: latestStreamPivot?.streamStatus?.color,
            }}
          >
            <span>{latestStreamPivot?.streamStatus?.text.toUpperCase()}</span>
            {irrigationData && (
              <StyledSpan>
                {` ${irrigationData.percentage}% `}
                <VerticalSpace />
                {irrigationData.direction}
              </StyledSpan>
            )}

            {latestStreamPivot?.streamStatus?.number === 4 &&
            isValidIrrigationRemainingTime(
              latestStreamPivot?.irrigation_remaining_time
            )
              ? convertIrrigationRemainingTimeToHoursMinutes(
                  latestStreamPivot?.irrigation_remaining_time
                )
              : ""}
          </Status>

          {latestStreamPivot?.streamStatus?.text ===
            i18n("PAINEL_STREAM_REASON_256") ||
          latestStreamPivot?.streamStatus?.number === 38 ? (
            <StatusHelper />
          ) : null}
        </div>

        <div className="generalpivot__content__item">
          {!isMobile() ? <>{i18n("GENERAL_BOX_LAST_UPDATE")}</> : <></>}
          {latestStreamPivot ? (
            moment(latestStreamPivot.created).format("DD MMM HH:mm")
          ) : (
            <>{i18n("UPDATING")}</>
          )}
        </div>
      </div>

      <div className="generalpivot__content__group">
        {(newConfig as PivotConfig)?.content?.pressure_config
          ?.read_pressure_by === 2 ? (
          <div className="generalpivot__content__item-mobile">
            <img
              src={CenterIcon}
              alt="pressure icon"
              width="20px"
              height="20px"
              style={{ opacity: 0.85 }}
            />

            {parseFloat(latestCenterPressure) >= 0
              ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
                  formatFloatValue(parseFloat(latestCenterPressure)),
                ])
              : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
          </div>
        ) : null}

        <div className="generalpivot__content__item-mobile">
          <div style={{ marginTop: "4px" }}>{""}</div>
          <img
            src={EndTowerIcon}
            alt="pressure icon"
            width="20px"
            height="20px"
            style={{ opacity: 0.85 }}
          />
          <div style={{ marginTop: "4px" }}>
            {parseFloat(latestEndTowerPressure) >= 0
              ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
                  formatFloatValue(parseFloat(latestEndTowerPressure)),
                ])
              : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
          </div>
        </div>

        {/* Conferir se pode tirar daqui tambem ou so da lista la fora */}

        {pivot.automation_type === 0 ? (
          <div className="generalpivot__content__item-mobile">
            <Power />

            {`${
              pivot.controllerstream_gps?.content?.voltage_measure
                ?.voltage_measure || "-"
            } V`}
          </div>
        ) : null}

        {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
          <div className="generalpivot__content__item-mobile">
            <AutorenewTwoTone />

            {parseFloat(newLatestAngle) >= 0
              ? `${formatFloatValue(parseFloat(newLatestAngle)) || "-"}º`
              : "-"}
          </div>
        ) : null}

        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <LightTooltip
              arrow
              placement="left"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <>
                  <div className="generalpivot__content__itema">
                    <AvTimer />

                    {totalHourMeterFormatted}
                  </div>

                  <div className="generalpivot__content__itemb">
                    <AvTimer />

                    {wetHourMeterFormatted}
                  </div>

                  <div className="generalpivot__content__itemc">
                    <AvTimer />

                    {dryHourMeterFormatted}
                  </div>
                </>
              }
            >
              <div
                onClick={handleTooltipOpen}
                className="generalpivot__content__item-mobile"
              >
                <AvTimer />

                {totalHourMeterFormatted}
              </div>
            </LightTooltip>
          </div>
        </ClickAwayListener>
      </div>

      {showPluviometerInfos ? (
        <div
          title={isRaining ? i18n("RAINING_NOW") : ""}
          className="generalpivot__content__item"
        >
          <Opacity style={{ color: isRaining ? "#0066ff" : "black" }} />
          {getDailyMesure(
            showPluviometerMeasure,
            latestPeriodicStream?.content?.pluviometer_daily_measure
              ?.daily_measure
          )}
          {i18n("DAILY_RAINING")}
        </div>
      ) : null}
    </>
  );

  const desktopV5infos = (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <Status
          style={{ backgroundColor: latestStreamPivot?.streamStatus?.color }}
        >
          <span>{latestStreamPivot?.streamStatus?.text.toUpperCase()}</span>
          {irrigationData && (
            <StyledSpan>
              {` ${irrigationData.percentage}% `}
              <VerticalSpace />
              {irrigationData.direction}
            </StyledSpan>
          )}
          {latestStreamPivot?.streamStatus?.number === 4 &&
          isValidIrrigationRemainingTime(
            latestStreamPivot?.irrigation_remaining_time
          )
            ? convertIrrigationRemainingTimeToHoursMinutes(
                latestStreamPivot?.irrigation_remaining_time
              )
            : ""}
        </Status>

        {latestStreamPivot?.streamStatus?.text ===
          i18n("PAINEL_STREAM_REASON_256") ||
        latestStreamPivot?.streamStatus?.number === 38 ? (
          <StatusHelper />
        ) : null}
      </div>

      <div className="generalpivot__content__item">
        {!isMobile() ? <>{i18n("GENERAL_BOX_LAST_UPDATE")}</> : <></>}
        {latestStreamPivot ? (
          moment(latestStreamPivot.created).format("DD MMM HH:mm")
        ) : (
          <>{i18n("UPDATING")}</>
        )}
      </div>

      {showPluviometerInfos ? (
        <div
          title={isRaining ? i18n("RAINING_NOW") : ""}
          className="generalpivot__content__item"
        >
          <Opacity style={{ color: isRaining ? "#0066ff" : "black" }} />
          <div style={{ marginBottom: "-3px" }}>
            {getDailyMesure(
              showPluviometerMeasure,
              latestPeriodicStream?.content?.pluviometer_daily_measure
                ?.daily_measure
            )}
            {i18n("DAILY_RAINING")}
          </div>
        </div>
      ) : null}

      {(newConfig as PivotConfig)?.content?.pressure_config
        ?.read_pressure_by === 2 ? (
        <div
          className="generalpivot__content__item"
          style={{ marginBottom: "6px" }}
        >
          <img
            src={CenterIcon}
            alt="pressure icon"
            width="20px"
            height="20px"
            style={{ marginRight: "8px", opacity: 0.85 }}
          />

          {parseFloat(latestCenterPressure) >= 0
            ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
                formatFloatValue(parseFloat(latestCenterPressure)),
              ])
            : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
        </div>
      ) : null}

      <div
        className="generalpivot__content__item"
        style={{ marginBottom: "6px" }}
      >
        <img
          src={EndTowerIcon}
          alt="pressure icon"
          width="20px"
          height="20px"
          style={{ marginRight: "8px", opacity: 0.85 }}
        />

        {parseFloat(latestEndTowerPressure) >= 0
          ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
              formatFloatValue(parseFloat(latestEndTowerPressure)),
            ])
          : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
      </div>

      {/* Conferir se pode tirar daqui tambem ou so da lista la fora */}

      {pivot.automation_type === 0 ? (
        <div className="generalpivot__content__item">
          <Power />

          {`${
            pivot.controllerstream_gps?.content?.voltage_measure
              ?.voltage_measure || "-"
          } V`}
        </div>
      ) : null}

      {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
        <div className="generalpivot__content__item">
          <AutorenewTwoTone />

          {parseFloat(newLatestAngle) >= 0
            ? `${formatFloatValue(parseFloat(newLatestAngle)) || "-"}º`
            : "-"}
        </div>
      ) : null}

      <LightTooltip
        arrow
        placement="right"
        title={
          <>
            <div className="generalpivot__content__itema">
              <AvTimer />

              {totalHourMeterFormatted}
            </div>

            <div className="generalpivot__content__itemb">
              <AvTimer />

              {wetHourMeterFormatted}
            </div>

            <div className="generalpivot__content__itemc">
              <AvTimer />

              {dryHourMeterFormatted}
            </div>
          </>
        }
      >
        <div className="generalpivot__content__item">
          <AvTimer />

          {totalHourMeterFormatted}
        </div>
      </LightTooltip>
    </>
  );

  const v5Infos = isMobile() ? mobileV5infos : desktopV5infos;

  //
  // Old/V4
  //

  const mobileOldInfos = (
    <>
      <div className="generalpivot__content__group">
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Status
            style={{
              backgroundColor: latestStreamPivot?.streamStatus?.color,
            }}
          >
            <span>{latestStreamPivot?.streamStatus?.text.toUpperCase()}</span>
            {irrigationData && (
              <StyledSpan>
                {` ${irrigationData.percentage}% `}
                <VerticalSpace />
                {irrigationData.direction}
              </StyledSpan>
            )}
          </Status>

          {latestStreamPivot?.streamStatus?.text ===
            i18n("PAINEL_STREAM_REASON_256") ||
          latestStreamPivot?.streamStatus?.number === 38 ? (
            <StatusHelper />
          ) : null}
        </div>

        <div className="generalpivot__content__item">
          {!isMobile() && <>{i18n("GENERAL_BOX_LAST_UPDATE")}</>}
          {latestStreamPivot ? (
            moment(latestStreamPivot.created).format("DD MMM HH:mm")
          ) : (
            <>{i18n("UPDATING")}</>
          )}
        </div>
      </div>

      <div className="generalpivot__content__group">
        <div className="generalpivot__content__item">
          <img
            src={EndTowerIcon}
            width="20px"
            height="20px"
            style={{ marginRight: "8px", opacity: 0.85 }}
            alt="pressure icon"
          />
          {parseFloat(latestEndTowerPressure) >= 0
            ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
                formatFloatValue(parseFloat(latestEndTowerPressure)),
              ])
            : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
        </div>

        <div className="generalpivot__content__item">
          <Power />
          {latestPanelStream
            ? `${(latestPanelStream as any).phase_a || "-"} V`
            : `${pivot.latest_panel_stream?.phase_a || "-"} V`}
        </div>

        <div className="generalpivot__content__item">
          <AutorenewTwoTone />
          {parseFloat(newLatestAngle) >= 0
            ? `${formatFloatValue(parseFloat(newLatestAngle)) || "-"}º`
            : "-"}
        </div>

        <div className="generalpivot__content__item">
          <Icon
            fontSize="small"
            style={{ fontSize: "1.4em", marginRight: "8px" }}
          >
            av_timer
          </Icon>
          {totalHourMeterFormatted}
        </div>
      </div>
    </>
  );

  const desktopOldInfos = (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <Status
          style={{ backgroundColor: latestStreamPivot?.streamStatus?.color }}
        >
          <span>{latestStreamPivot?.streamStatus?.text.toUpperCase()}</span>
          {irrigationData && (
            <StyledSpan>
              {` ${irrigationData.percentage}% `}
              <VerticalSpace />
              {irrigationData.direction}
            </StyledSpan>
          )}
        </Status>

        {latestStreamPivot?.streamStatus?.text ===
          i18n("PAINEL_STREAM_REASON_256") ||
        latestStreamPivot?.streamStatus?.number === 38 ? (
          <StatusHelper />
        ) : null}
      </div>

      <div className="generalpivot__content__item">
        {!isMobile() ? <>{i18n("GENERAL_BOX_LAST_UPDATE")}</> : <></>}
        {latestStreamPivot ? (
          moment(latestStreamPivot.created).format("DD MMM HH:mm")
        ) : (
          <>{i18n("UPDATING")}</>
        )}
      </div>

      <div className="generalpivot__content__item">
        <img
          src={EndTowerIcon}
          width="20px"
          height="20px"
          style={{ marginRight: "8px", opacity: 0.85 }}
          alt="pressure icon"
        />
        {parseFloat(latestEndTowerPressure) >= 0
          ? i18n("GENERAL_BOX_PRESSURE_LABEL", [
              formatFloatValue(parseFloat(latestEndTowerPressure)),
            ])
          : i18n("GENERAL_BOX_PRESSURE_LABEL", ["-"])}
      </div>

      <div className="generalpivot__content__item">
        <Power />
        {latestPanelStream
          ? `${(latestPanelStream as any).phase_a || "-"} V`
          : `${pivot.latest_panel_stream?.phase_a || "-"} V`}
      </div>

      <div className="generalpivot__content__item">
        <AutorenewTwoTone />
        {parseFloat(newLatestAngle) >= 0
          ? `${formatFloatValue(parseFloat(newLatestAngle)) || "-"}º`
          : "-"}
      </div>

      <div className="generalpivot__content__item">
        <Icon
          fontSize="small"
          style={{ fontSize: "1.4em", marginRight: "8px" }}
        >
          av_timer
        </Icon>
        {totalHourMeterFormatted}
      </div>
    </>
  );

  const oldInfos = isMobile() ? mobileOldInfos : desktopOldInfos;

  const pivotName = <>{!isMobile() ? <h2>{pivot.name}</h2> : <></>}</>;

  const offlineOrMaintenanceAlerts = (
    <>
      {isOnline && maintenanceStatus && (
        <Alert severity="warning">{i18n("PIVOT_UNDER_MAINTENANCE")}</Alert>
      )}
      {!isOnline && !isLoading ? (
        <Alert severity="error">{i18n("SELECTED_PIVOT_OFFLINE")}</Alert>
      ) : (
        <></>
      )}
    </>
  );

  const isPeakTimeDisabled = () => {
    if (newConfig !== null) {
      if (pivot.protocol === 5) {
        if (
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_sunday &&
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_monday &&
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_tuesday &&
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_wednesday &&
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_thursday &&
          !(newConfig as ControllerConfig)?.content?.pause_time
            ?.enable_friday &&
          !(newConfig as ControllerConfig)?.content?.pause_time?.enable_saturday
        ) {
          return <Alert severity="warning">{i18n("PEAK_TIME_DISABLED")}</Alert>;
        }
      } else if (
        (newConfig as PivotConfig).hour_range_max === "00:00:00" &&
        (newConfig as PivotConfig).hour_range_min === "00:00:00"
      ) {
        return <Alert severity="warning">{i18n("PEAK_TIME_DISABLED")}</Alert>;
      }
    }
  };

  const generalBoxContent = (
    <>
      <div className="generalpivot">
        {showPluviometerDecrementAlert && (
          <div>
            <StyledAlert severity="info">
              {i18n("IRRIGATION_INCREMENTED_DUE_TO_RAIN", [
                (latestGPSStream as ControllerStream)?.content
                  ?.current_irrigation_information?.irrigation_percent,
              ])}
            </StyledAlert>
          </div>
        )}
        <div
          className={
            pivot?.permission_level >= 2
              ? "generalpivot__inner"
              : "generalpivot__lowPermissionInner"
          }
        >
          {!isMobile() && (
            <div className="generalpivot__map">
              <FarmMap
                pivotsLight={[pivotLight]}
                draggable={false}
                latestGPSStream={latestGPSStream}
                latestPanelStream={latestPanelStream}
                disableUI
              />
            </div>
          )}

          <div className="generalpivot__content">
            {isMobile() ? null : <QuickSelect farmID={farmID} />}

            <div className="generalpivot__content__items">
              {pivot.protocol === 5 ? v5Infos : oldInfos}
            </div>
          </div>

          {pivot?.permission_level >= 2 && pivot?.control_radio_id ? (
            <div className="generalpivot__actions">
              <div className="generalpivot__actions__offlinealert">
                {isPeakTimeDisabled()}
                {offlineOrMaintenanceAlerts}
              </div>
              {pivot.protocol === 5
                ? irrigationButtonsV5
                : irrigationButtonsOld}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );

  if (loading) return <></>;
  return generalBoxContent;
}

export default React.memo(PivotGeneralBox);

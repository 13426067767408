import { AnyARecord } from "dns";
import moment from "moment";
import {
  atom,
  selector,
  selectorFamily,
  atomFamily,
  RecoilState,
} from "recoil";
import { NumericLiteral } from "typescript";
import { TYPE } from "../pages/Dashboard/Pivot/EditPivot/components/EditPivotFormV5/NewEditPivotV5";
import {
  PanelStreamOld,
  ControllerStream,
  PivotConfig,
  ControllerConfig,
  GPSStream,
  Pivot,
} from "../redux/pivots/types";
import { coreHTTPClient } from "../services/webclient";
import {
  getPanelStreamStatus,
  getPanelStreamStatusV5,
  isV5Protocol,
  PainelStreamStatus,
} from "../utils/models/pivots";

export interface PivotLight {
  id: number;
  farm: number;
  name: string;
  communication_type: number;
  is_online: boolean | null;
  protocol: number;
  automation_type: number;
  type: "LIGHT";
}

export type PivotStreamLight = {
  id: number;
  created: string | Date;
  streamStatus: PainelStreamStatus;
  irrigation_remaining_time?: any;
  irrifastStream?: {
    aligment_status: number | null;
    cc_voltage: number | null;
    tower_number: number;
    output_frequency: number | null;
    output_current: number | null;
    inverter_status: number | null;
  }[];
};

export const PivotLightStateList = atom<PivotLight[] | null>({
  key: "PivotLightState",
  default: null,
});

export const PivotLightStateFamily = selectorFamily<PivotLight, number>({
  key: "PivotLightStateFamily",
  get: (pivotId: number) => ({ get }) => {
    let pivotsLight = get(PivotLightStateList);

    if (pivotsLight) {
      let pivot = pivotsLight.find((p) => p.id == pivotId);

      return pivot;
    }
  },
});

/**
 * ATOM GERAL
 *
 * Possui todos os dados do Pivot, usado como fallback de propriedade.
 *
 * Exemplo.
 *
 * let pivot = {
 *  ...atomGeral,
 *  panelStream: ...atomPanelStream,
 *  gpsStream: ...atomGPSStream,
 *  ...
 * }
 *
 * Assim, se houver uma propriedade que é pega na rota geral do pivot, e ela não tiver atualizações em
 * tempo real via websocket (que é o motivo de existirem atoms específicos) você provavelmente vai encontrar
 * ela aqui
 *
 *
 * ██╗     ███████╗██╗ █████╗     ██╗
 * ██║     ██╔════╝██║██╔══██╗    ██║
 * ██║     █████╗  ██║███████║    ██║
 * ██║     ██╔══╝  ██║██╔══██║    ╚═╝
 * ███████╗███████╗██║██║  ██║    ██╗
 * ╚══════╝╚══════╝╚═╝╚═╝  ╚═╝    ╚═╝
 *
 *
 * ESSE ÁTOMO NÃO DEVE SER MODIFICADO DINAMICAMENTE.
 * ESSE ÁTOMO NÃO DEVE SER CONSUMIDO SOZINHO (sempre com os átomos específicos juntos).
 *
 * Caso seja necessário modificar uma propriedade, criar um átomo específico novo e
 * persistir a propriedade nele. Depois modificar o `FullPivotList` e `FullPivotFamily (To Do)` para incluir esse átomo
 * na criação do Pivot
 *
 */

export const FallbackPivot = atomFamily<Pivot | null, number>({
  key: "FallbackPivot",
  default: null,
});

/**
 * ATOMS ESPECÍFICOS
 *
 * Usados para atualizações em tempo real específicas, compõem objetos do pivot e elementos de interface
 * que são pegos pelo websocket.
 *
 * Sua totalidade ou parte do objeto é consumido por um componente e pego na tela.
 */

export const PivotPainelStreamFamily = atomFamily<
  PanelStreamOld | ControllerStream | null,
  number
>({
  key: "PivotPainelStreamFamily",
  default: null,
});

export const PivotMapTowerStatus = atomFamily<
  { tower_number: number; aligment_status: number }[] | null,
  number
>({
  key: "PivotMapTowerStatus",
  default: [],
});

export const PivotPanelTowerStatus = atomFamily<
  | {
      aligment_status: number | null;
      cc_voltage: number | null;
      tower_number: number;
      output_frequency: number | null;
      output_current: number | null;
      inverter_status: number | null;
    }[]
  | null,
  number
>({
  key: "PivotPanelTowerStatus",
  default: [],
});

export const PivotPeriodicStreamFamily = atomFamily<
  ControllerStream | null,
  number
>({
  key: "PivotPeriodicStreamFamily",
  default: null,
});

export const PivotGPSStreamFamily = atomFamily<
  GPSStream | ControllerStream | null,
  number
>({
  key: "PivotGPSStreamFamily",
  default: null,
});

export const PivotConfigFamily = atomFamily<
  PivotConfig | ControllerConfig | null,
  number
>({
  key: "PivotConfigFamily",
  default: null,
});

export const PivotMaintenanceFamily = atomFamily<boolean | null, number>({
  key: "PivotMaintenanceFamily",
  default: null,
});

/**
 * SELECTORS AGREGADORES
 *
 * Selectors que percorrem as familys e retornam arrays. Úteis para o mapa que faz a lógica em cima
 * de arrays completos de pivots.
 *
 * Pegando cada atom da lista de pivots ele retorna arrays, na mesma ordem que o PivotLight,
 * com os componentes que fazem um pivot.
 */

//All PainelStreams
export const PivotPainelStreamList = selector<
  (PanelStreamOld | ControllerStream)[]
>({
  key: "PivotPainelStreamList",
  get: ({ get }) => {
    let painelStreamList: (PanelStreamOld | ControllerStream)[] = [];
    let pivotLight = get(PivotLightStateList);
    pivotLight.forEach((pivot) => {
      painelStreamList.push(get(PivotPainelStreamFamily(pivot.id)));
    });

    return painelStreamList;
  },
});

//All Gps Streams
export const PivotGPSStreamList = selector<(GPSStream | ControllerStream)[]>({
  key: "PivotGPSStreamList",
  get: ({ get }) => {
    let gpsStreamList: (GPSStream | ControllerStream)[] = [];
    let pivotLight = get(PivotLightStateList);
    if (!pivotLight) return;
    pivotLight.forEach((pivot) => {
      gpsStreamList.push(get(PivotGPSStreamFamily(pivot.id)));
    });

    return gpsStreamList;
  },
});

//All Configs
export const PivotConfigList = selector<(PivotConfig | ControllerConfig)[]>({
  key: "PivotConfigList",
  get: ({ get }) => {
    let pivotConfigList: (PivotConfig | ControllerConfig)[] = [];
    let pivotLight = get(PivotLightStateList);
    if (!pivotLight) return;
    pivotLight.forEach((pivot) => {
      pivotConfigList.push(get(PivotConfigFamily(pivot.id)));
    });

    return pivotConfigList;
  },
});

export const ListLatestStreamPivot = selector<PivotStreamLight[]>({
  key: "LatestStreamPivot",
  get: ({ get }) => {
    let listPivotStreamLight: PivotStreamLight[] = [];
    let pivotLight = get(PivotLightStateList);
    if (!pivotLight) return;
    pivotLight.forEach((pivot) => {
      let painelStream = get(PivotPainelStreamFamily(pivot.id));
      let gpsStream = get(PivotGPSStreamFamily(pivot.id));
      let irrifastStream = get(PivotPanelTowerStatus(pivot.id));
      let streamStatus: PainelStreamStatus;
      let irrigation_remaining_time: string;
      let created: string | Date;
      let id: number;
      try {
        if (painelStream && gpsStream && pivotLight) {
          if (moment(painelStream.created) >= moment(gpsStream.created)) {
            if (isV5Protocol(pivot)) {
              streamStatus = getPanelStreamStatusV5(
                (painelStream as ControllerStream).content.irrigation_status
                  .irrigation_status
              );
              irrigation_remaining_time = (painelStream as ControllerStream)
                ?.content?.irrigation_remaining_time;
              id = (painelStream as ControllerStream).equipment;
            } else {
              streamStatus = getPanelStreamStatus(painelStream.reason, true);
              id = (painelStream as PanelStreamOld).pivot;
            }
            created = painelStream.created;
          } else {
            if (isV5Protocol(pivot)) {
              streamStatus = getPanelStreamStatusV5(
                (gpsStream as ControllerStream).content.irrigation_status
                  .irrigation_status
              );
              irrigation_remaining_time = (gpsStream as ControllerStream)
                ?.content?.irrigation_remaining_time;
              id = (gpsStream as ControllerStream).equipment;
            } else {
              streamStatus = getPanelStreamStatus(gpsStream.reason, true);
              id = (gpsStream as GPSStream).pivot;
            }
            created = gpsStream.created;
          }
          listPivotStreamLight.push({
            id,
            created,
            streamStatus,
            irrigation_remaining_time,
            irrifastStream,
          });
        }
      } catch (e) {
        console.log(e);
      }
    });
    return listPivotStreamLight;
  },
});

/**
 * GERADOR DE PIVOT
 *
 * Junta o átomo geral de fallback com os átomos que são
 * atualizados dinamicamente, retornando um objeto pivot mais atualizado.
 *
 * PREFIRA OS ÁTOMOS ESPECÍFICOS QUANDO PUDER, eles são mais leves
 */

const LpmGpsStreamFamily = atomFamily({
  key: "LpmGpsStreamFamily",
  default: [],
});

const LpmGpsStreams = selectorFamily({
  key: "LpmGpsStreams",
  get: (params: any) => async ({ get }) => {
    const startDate = moment().subtract(1, "day").toISOString();
    const endDate = moment().toISOString();
    const response = await coreHTTPClient.get(
      `/v3/farms/${params.farmId}/pivots/${params.pivotId}/gps_streams/?date_start=${startDate}&date_end=${endDate}`
    );
    return response.data;
  },
});

export const FullPivotList = selector<Pivot[]>({
  key: "FullPivotList",
  get: ({ get }) => {
    let fullPivotList: Pivot[] = [];
    let pivotLight = get(PivotLightStateList);
    if (!pivotLight) return;
    pivotLight.forEach((pivot) => {
      let fallbackPivot = get(FallbackPivot(pivot.id));
      let pivotConfig = get(PivotConfigFamily(pivot.id));
      let painelStream = get(PivotPainelStreamFamily(pivot.id));
      let gpsStream = get(PivotGPSStreamFamily(pivot.id));
      let periodicStream = get(PivotPeriodicStreamFamily(pivot.id));
      let lpmGpsStreams;
      let fullPivot: Pivot;

      // Irrifast tower status
      let towerStatus = get(PivotMapTowerStatus(pivot.id));

      if (pivot.protocol === 5) {
        fullPivot = {
          ...fallbackPivot,
          controllerconfig: pivotConfig as ControllerConfig,
          controllerstream_gps: gpsStream as ControllerStream,
          controllerstream_panel: painelStream as ControllerStream,
          controllerstream_periodic: periodicStream as ControllerStream,
          irrifast_tower_status: towerStatus,
        };
        if (pivot.automation_type == TYPE.LINEAR_PIVOT_MONITOR) {
          lpmGpsStreams = get(
            LpmGpsStreams({ farmId: pivot.farm, pivotId: pivot.id })
          );

          fullPivot = {
            ...fullPivot,
            lpm_gps_streams: lpmGpsStreams,
            irrifast_tower_status: towerStatus,
          };
        }
      } else {
        fullPivot = {
          ...fallbackPivot,
          config: pivotConfig as PivotConfig,
          latest_gps_stream: gpsStream as GPSStream,
          latest_panel_stream: painelStream as PanelStreamOld,
          irrifast_tower_status: towerStatus,
        };
      }
      fullPivotList.push(fullPivot);
    });

    return fullPivotList;
  },
});

//Latest Stream List between Panel and GPS
export const LatestStreamPivot = selectorFamily<PivotStreamLight, number>({
  key: "LatestStreamPivot",
  get: (pivotId: number) => ({ get }) => {
    let streamStatus: PainelStreamStatus;
    let irrigation_remaining_time: string;
    let created: string | Date;
    let pivotLight = get(PivotLightStateFamily(pivotId));
    let painelStream = get(PivotPainelStreamFamily(pivotId));
    let gpsStream = get(PivotGPSStreamFamily(pivotId));
    let irrifastStream = get(PivotPanelTowerStatus(pivotId));
    let id: number;
    try {
      if (painelStream && gpsStream && pivotLight) {
        if (moment(painelStream.created) >= moment(gpsStream.created)) {
          if (isV5Protocol(pivotLight)) {
            streamStatus = getPanelStreamStatusV5(
              (painelStream as ControllerStream).content.irrigation_status
                .irrigation_status
            );
            irrigation_remaining_time = (painelStream as ControllerStream)
              ?.content?.irrigation_remaining_time;
            id = (painelStream as ControllerStream).equipment;
          } else {
            streamStatus = getPanelStreamStatus(painelStream.reason, true);
            id = (painelStream as PanelStreamOld).pivot;
          }
          created = painelStream.created;
        } else {
          if (isV5Protocol(pivotLight)) {
            streamStatus = getPanelStreamStatusV5(
              (gpsStream as ControllerStream).content.irrigation_status
                .irrigation_status
            );
            irrigation_remaining_time = (gpsStream as ControllerStream)?.content
              ?.irrigation_remaining_time;
            id = (gpsStream as ControllerStream).equipment;
          } else {
            streamStatus = getPanelStreamStatus(gpsStream.reason, true);
            id = (gpsStream as GPSStream).pivot;
          }
          created = gpsStream.created;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return {
      id,
      created,
      streamStatus,
      irrigation_remaining_time,
      irrifastStream,
    };
  },
});

export const FullPivotFamily = selectorFamily<Pivot | null, number>({
  key: "FullPivotList",
  get: (pivotId: number) => ({ get }) => {
    let pivotLight = get(PivotLightStateFamily(pivotId));
    let fallbackPivot = get(FallbackPivot(pivotId));
    let pivotConfig = get(PivotConfigFamily(pivotId));
    let painelStream = get(PivotPainelStreamFamily(pivotId));
    let gpsStream = get(PivotGPSStreamFamily(pivotId));
    let pedriodicStream = get(PivotPeriodicStreamFamily(pivotId));

    // Irrifast tower status
    let towerStatus = get(PivotMapTowerStatus(pivotId));

    let fullPivot: Pivot;
    if (!pivotLight) return null;
    if (pivotLight.protocol === 5) {
      fullPivot = {
        ...fallbackPivot,
        controllerconfig: pivotConfig as ControllerConfig,
        controllerstream_gps: gpsStream as ControllerStream,
        controllerstream_panel: painelStream as ControllerStream,
        controllerstream_periodic: pedriodicStream as ControllerStream,
        irrifast_tower_status: towerStatus,
      };
    } else {
      fullPivot = {
        ...fallbackPivot,
        config: pivotConfig as PivotConfig,
        latest_gps_stream: gpsStream as GPSStream,
        latest_panel_stream: painelStream as PanelStreamOld,
        irrifast_tower_status: towerStatus,
      };
    }

    return fullPivot;
  },
});

/**
 * Selector custom usado para que eu possa setar qualquer valor da family.
 *
 * Exemplo:
 *
 * ```js
 * let atomFamilySetter = useSetRecoilState(AtomFamilySetterHelper);
 *
 * atomFamilySetter(PivotPainelStreamFamily, 30, {...})
 * ```
 *
 * O código acima seta o Atom na family `PivotPainelStreamFamily` de id `30` para o objeto passado
 *
 * Assim consigo dentro de um `for` setar todos os atomos de uma family.
 */

export const AtomFamilySetterHelper = selector({
  key: "AtomFamilySetterHelper",
  get: () => null,
  set: (
    { set },
    {
      PivotFamilyAtom,
      pivotId,
      value,
    }: {
      PivotFamilyAtom: (param: number) => RecoilState<any>;
      pivotId: number;
      value: any;
    }
  ) => {
    set(PivotFamilyAtom(pivotId), value);
  },
});

import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    size?: number;
    _customColor?: string;
    hideValue?: boolean;
  }
) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="static"
        {...props}
        style={{ color: props._customColor || "grey" }}
        size={props.size || 28}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {!props.hideValue && (
          <Typography
            variant="caption"
            component="div"
            style={{
              fontSize: props.size / 3 || 9,
              color: props._customColor || "grey",
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

interface CircularStaticProps {
  value: number;
  size?: number;
  color?: string;
  hideValue?: boolean;
}

export default function CircularStatic(props: CircularStaticProps) {
  return (
    <CircularProgressWithLabel
      value={props.value}
      size={props.size}
      _customColor={props.color}
      hideValue={props.hideValue}
    />
  );
}

import i18n_ptBr from "./i18n.pt-br";
import i18n_es from "./i18n.es";
import i18n_deAt from "./i18n.de-at";
import i18n_ru from "./i18n.ru";
import i18n_en from "./i18n.en";

import Store from "../redux/index";
import { Language } from "../redux/setting/types";

export type i18nTextId = keyof typeof i18n_en;

/**
 * Definição da linguagem. Uma linguagem é um conjunto de enums que possui um conjunto
 * de ids que possuem suas respectivas strings
 */
type LanguageMap = {
  [lang in Language]: { [id in i18nTextId]: string } | null;
};

const languageMap: LanguageMap = {
  en: i18n_en,
  "pt-br": i18n_ptBr,
  "de-at": i18n_deAt,
  ru: i18n_ru,
  es: i18n_es,
  keys: null,
};

function getLanguage() {
  return Store().store.getState().setting.language;
}

/**
 * Função i18n com tradução, aceita passagem de parâmetros.
 *
 * Examples:
 *
 * i18n("GO_BACK") //"Go Back"
 * i18n("HELLO_USER", "Pedro") //Original: "Hello, ${}" vira "Hello, Pedro"
 *
 * @param {i18nTextId} id
 * @param {any[]} values
 */
export function i18n(id: i18nTextId, values: any[] = []) {
  let selectedLanguage = getLanguage();

  let languageMapped = languageMap[selectedLanguage];

  if (selectedLanguage == "keys") return id;
  if (!languageMapped) return;

  let i18nText = languageMapped[id] || (id ? "MISSING_TRANSLATION" : "");

  // COMENTAR este trecho de código, pois ele é apenas para testar o i18n novo e ver se está tudo ok com todas as translations
  // if (i18nText == "MISSING_TRANSLATION") {
  //   if (localStorage.getItem("MISSING_TRANSLATIONS")) {
  //     var missingTranslations = JSON.parse(localStorage.getItem("MISSING_TRANSLATIONS"))
  //     missingTranslations[`${id}`] = "MISSING_TRANSLATION"
  //     localStorage.setItem("MISSING_TRANSLATIONS", JSON.stringify(missingTranslations))
  //   } else {
  //     localStorage.setItem("MISSING_TRANSLATIONS", JSON.stringify({}))
  //   }
  // }

  if (values?.length) {
    values.forEach((variable) => {
      i18nText = i18nText.replace("${}", variable);
    });
  }

  return i18nText;
}

/**
 * Quando uma linguagem é requisitada ela vai ser buscada e carregada
 */

Store().store.subscribe(() => {
  if (!languageMap[getLanguage()])
    import(
      /* webpackChunkName: `i18n` */
      `./i18n.${getLanguage()}`
    )
      .then((lang) => (languageMap[getLanguage()] = lang.default))
      .catch((e) => {
        languageMap[getLanguage()] = i18n_en;
      });
});

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportIRPDHydricList from "../ReportIRPDHydricList";
import useFarm from "../../../../../../hooks/models/useFarm";
import { GoToEditFarmButton } from "../../../../Pivot/SelectedPivot/components/BasicInfoBox/BasicInfoBox";
import { useNavigate } from "react-router";
import routes from "../../../../../../routes/routes";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../redux/filterByDate/types";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../redux";

const Container = styled.div`
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  padding: 20%;
`;

const HeaderInfos = styled.div`
  padding: 10px 0px 15px 5px;
  font-size: 0.8em;
  opacity: 0.8;
  text-align: center;
`;

interface Props {
  farmID: number;
  irpdID: number;
  regenerate_reports: boolean;
}

export default function IrpdHydricInfoTable(props: Props) {
  const { farmID, irpdID } = props;

  const [, farm] = useFarm(farmID);

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").format("DD MMM HH:mm")
  );
  const [endDate, setEndDate] = useState(moment().format("DD MMM HH:mm"));

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate,
    shallowEqual
  );

  useEffect(() => {
    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      setStartDate(filterByDate.data.start_date.format("DD MMM HH:mm"));
      setEndDate(filterByDate.data.end_date.format("DD MMM HH:mm"));
    }
  }, [
    filterByDate.data.start_date?.format("DD MMM HH:mm"),
    filterByDate.data.end_date?.format("DD MMM HH:mm"),
    filterByDate.data.type,
  ]);

  if (farm) {
    if (farm.start_irpd_report_aggregate === 0)
      return (
        <InfoContainer>
          {i18n("BASIC_INFO_BOX_ENABLE_IRPD_INFOS")}
          <GoToEditFarmButton
            variant="contained"
            onClick={() => {
              navigate(
                routes.DASHBOARD.EDIT_FARM.replace(":farm", farmID.toString()) +
                  "?tab=4"
              );
            }}
          >
            {i18n("BASIC_INFO_BOX_GO_TO_EDIT_FARM")}
          </GoToEditFarmButton>
        </InfoContainer>
      );
    if (props.regenerate_reports)
      return (
        <InfoContainer>
          <CircularProgress
            style={{ marginBottom: "20px", color: "gray", opacity: 0.3 }}
          />
          {i18n("BASIC_INFO_BOX_INFORMATION_CALCULATED")}
        </InfoContainer>
      );
  }

  return (
    <Container>
      <HeaderInfos>
        <>
          {i18n("BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN", [
            startDate,
            endDate,
          ])}
        </>
      </HeaderInfos>
      <ReportIRPDHydricList irpdID={irpdID} />
    </Container>
  );
}

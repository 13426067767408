import React, { useContext, useState } from "react";
import moment from "moment";
import { Settings, Map, Straighten } from "@material-ui/icons";
import { i18n } from "../../../../../i18n/i18nText";
import {
  Button,
  CircularProgress,
  createMuiTheme,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";
import { coreHTTPClient } from "../../../../../services/webclient";
import { Star, StarBorder } from "@material-ui/icons";
import { motion } from "framer-motion";
import useNotify from "../../../../../hooks/tools/useNotify";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import { isMobile } from "../../../../../mobileConfig";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fca103",
      contrastText: "#fff",
    },
  },
});

interface Props {
  imeter?: any;
  iMeter: any;
  iMeterConfig: any;
  imeterID: number;
  farmID: number;
  loadHistoricInfo: (
    metersystemName: string,
    imeterName: string,
    imeterSensor: any,
    imeterA: any,
    imeterB: any,
    imeterC: any,
    imeterLat: string,
    imeterLng: string,
    imeterMarker: any,
    imeterSensorMaxValue: any,
    imeterSensorOffset: any,
    imeterMinLimit: any,
    imeterMaxLimit: any
  ) => void;
  sensorOptions: any[];
  meterSystemID: number;
}

function TheExpandedImeter(props: Props) {
  const {
    imeter,
    iMeter,
    iMeterConfig,
    loadHistoricInfo,
    imeterID,
    farmID,
    meterSystemID,
    sensorOptions,
  } = props;
  const [pinned, setPinned] = useState<boolean>(props.iMeterConfig.pinned);
  const [loading, setLoading] = useState(false);
  const [pinName, setPinName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);
  const notify = useNotify();

  let sensorName: string = "";

  const sensorArray = sensorOptions.filter(
    (sensorOption) =>
      sensorOption[0] == iMeterConfig.sensor_process_controller_pair
  );

  if (sensorArray.length) {
    sensorName = sensorArray[0][1];
  }

  // O moment() atribui aos doze meses do ano um número que varia de 0 (janeiro) a 11 (dezembro).
  // Porém, os valores dos meses que vêm do back end variam de 1 (janeiro) a 12 (dezembro).
  // Para corrigir isto, devemos pegar o objeto clock que vem do back end e corrigir ele antes de passá-lo para o moment().
  // Basta diminuir 1 na chave "month".
  // O objeto clockAdjusted é então passado para o moment(), em vez do objeto clock original.
  const clockAdjusted = {
    day: iMeterConfig.content.clock.day,
    hour: iMeterConfig.content.clock.hour,
    minute: iMeterConfig.content.clock.minute,
    month: iMeterConfig.content.clock.month - 1,
    year: iMeterConfig.content.clock.year,
  };

  return (
    <div className="expanded-content__general">
      {/* BOTÃO FAVORITOS (ESTRELA) -------------------------------------------COMEÇO */}

      <div
        style={{
          width: "100%",
          height: "90px",
          margin: "0 0 -20px 5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <ThemeProvider theme={theme}>
          <IconButton
            aria-label="delete"
            onClick={() => {
              if (!pinned) {
                setShowNameInput(!showNameInput);
                return;
              }
              coreHTTPClient.patch(
                `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${imeterID}/config/${props.iMeterConfig.id}/`,
                { pinned: false }
              );
              setPinned(false);
              notify("HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY", "success");
            }}
          >
            {pinned ? <Star color="primary" /> : <StarBorder color="inherit" />}
          </IconButton>
        </ThemeProvider>

        <motion.div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          variants={{
            hidden: { width: 0, opacity: 0, x: "20px" },
            show: {
              width: "fit-content",
              opacity: 1,
              x: "0px",
            },
          }}
          initial="hidden"
          animate={showNameInput ? "show" : "hidden"}
        >
          <div style={{ width: "200px", marginBottom: "25px" }}>
            <FormInput
              id="pin-name"
              label={"SIGN_UP_NAME"}
              state={[pinName, setPinName]}
              disabled={!showNameInput}
            />
          </div>

          <Button
            variant="contained"
            style={{ transform: "scale(0.8)" }}
            // O botão está apenas invisível, mas precisa ser desativado
            disabled={!showNameInput || loading || !pinName}
            onClick={() => {
              (async () => {
                setLoading(true);
                try {
                  await coreHTTPClient.patch(
                    `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${imeterID}/config/${props.iMeterConfig.id}/`,
                    { pinned: true, name: pinName }
                  );
                  notify(
                    "HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY",
                    "success"
                  );
                  setShowNameInput(false);
                  setPinned(true);
                } catch {
                  notify("HISTORIC_CONFIG_PIVOT_PINNED_ERROR", "error");
                } finally {
                  setLoading(false);
                }
              })();
            }}
          >
            {loading ? <CircularProgress size={25} color="inherit" /> : "OK"}
          </Button>
        </motion.div>
      </div>
      {/* BOTÃO FAVORITOS (ESTRELA) -------------------------------------------FIM */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      ></div>

      {isMobile() ? (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            <div
              style={{
                display: "flex",
              }}
            >
              {imeter}
              {i18n("HISTORIC_CONFIG_INF_IMETER").toUpperCase()}
            </div>
          </div>
        </div>
      ) : null}

      {/* GERAL ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Settings
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_GENERAL").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("METERSYSTEM_FIELD_NAME")}:{" "}
            <b>{iMeterConfig.metersystem_name}</b>
          </span>

          <span>
            {i18n("IMETER_FIELD_NAME")}: <b>{iMeterConfig.imeter_name}</b>
          </span>

          <span>
            {i18n("IMETER_CONFIG_V5_FIELD_SENSOR")}: <b>{sensorName}</b>
          </span>

          <span
            style={{
              marginTop: "10px",
            }}
          >
            A:{" "}
            <b>
              {iMeterConfig.flow_curve_equation[0]} {"x²"}
            </b>
          </span>

          <span>
            B:{" "}
            <b>
              {iMeterConfig.flow_curve_equation[1]} {"x"}
            </b>
          </span>

          <span>
            C: <b>{iMeterConfig.flow_curve_equation[2]}</b>
          </span>

          <span
            style={{
              marginTop: "10px",
            }}
          >
            {i18n("PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL")}:{" "}
            <b>{moment(clockAdjusted).format("DD MMM YY - HH:mm")}</b>
          </span>
        </div>
      </div>

      {/* LOCALIZAÇÃO ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Map
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_LOCATION").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("EDIT_PIVOT_LAT_AND_LONG")}:{" "}
            <b>{iMeterConfig.position_imeter}</b>
          </span>
        </div>
      </div>

      {/* NÍVEL ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Straighten
            style={{
              marginLeft: "-5px",
              marginRight: "5px",
              transform: "rotate(270deg)",
            }}
          />
          <>{i18n("EDIT_IMETER_LEVEL_TAB").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("GRAPHIC_SCALE")}:{" "}
            <b>
              {iMeterConfig.graphic_max_value} {"m"}
            </b>
          </span>

          <span>
            {i18n("MEASUREMENT_OFFSET")}:{" "}
            <b>
              {iMeterConfig.sensor_offset / 100} {"m"}
            </b>
          </span>

          <span>
            {i18n("EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT")}:{" "}
            <b>
              {iMeterConfig.min_limit} {"%"}
            </b>
          </span>

          <span>
            {i18n("EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT")}:{" "}
            <b>
              {iMeterConfig.max_limit} {"%"}
            </b>
          </span>
        </div>
      </div>

      <Button
        color="primary"
        variant="contained"
        onClick={(e) => {
          loadHistoricInfo(
            iMeterConfig.metersystem_name,
            iMeterConfig.imeter_name,
            iMeterConfig.sensor_process_controller_pair,
            iMeterConfig.flow_curve_equation[0],
            iMeterConfig.flow_curve_equation[1],
            iMeterConfig.flow_curve_equation[2],
            iMeterConfig.position_imeter.split(",")[0].substring(0, 10),
            iMeterConfig.position_imeter.split(",")[1].substring(0, 10),
            (ll: any) => ({
              ...ll,
              lat: iMeterConfig?.position_imeter.split(",")[0].substring(0, 10),
              lng: iMeterConfig?.position_imeter.split(",")[1].substring(0, 10),
            }),
            iMeterConfig.graphic_max_value,
            iMeterConfig.sensor_offset / 100,
            iMeterConfig.min_limit,
            iMeterConfig.max_limit
          );
        }}
      >
        <>{i18n("EDIT_IRPD_LOAD_BUTTON")}</>
      </Button>
    </div>
  );
}

export default TheExpandedImeter;

import {
  Checkbox,
  Divider,
  FormControlLabel,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { green } from "@material-ui/core/colors";
import IOSSwitch from "../../components/IOSSwitch/IOSSwitch";
import { isMobile } from "../../../../mobileConfig";
import { i18n } from "../../../../i18n/i18nText";
import { Notification } from "../../../../redux/notifications/types";
import { Devices } from "../../../../hooks/models/useDevices";

interface Props {
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
  devices: Devices[];
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    maxHeight: isMobile() ? "50vh" : "450px",
    overflowY: "scroll",
    display: "grid",
    gridTemplateColumns: isMobile() ? `repeat(2, 1fr)` : `repeat(3, 1fr)`,
    gridGap: "20px 50px",
    paddingBottom: "20px",
  },
  typography: {
    fontSize: "18px",
    fontWeight: "bolder",
    margin: "0px 0px 15px 0px",
  },
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
}));

const getDevicesState = (devices: Devices[], notification: Notification) => {
  let deviceState = {};
  devices.map(
    (device) =>
      (deviceState = {
        ...deviceState,
        [`device_${device.id}`]: notification.devices.includes(device.id)
          ? true
          : false,
      })
  );
  return deviceState;
};

const getAllDevicesState = (devices: Devices[], value: boolean) => {
  let deviceState = {};
  devices.map(
    (device) =>
      (deviceState = {
        ...deviceState,
        [`device_${device.id}`]: value,
      })
  );
  return deviceState;
};

const NotificationsDeviceSelection = (props: Props) => {
  const { notification, setNotification, devices } = props;
  const allDevices = devices.map((device) => device.id);
  const [allChecked, setAllChecked] = useState(
    notification.devices.length === allDevices.length
  );
  const [checked, setChecked] = useState(
    getDevicesState(devices, notification)
  );

  const handleChangeAllChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(getAllDevicesState(devices, event.target.checked));
    setNotification({
      ...notification,
      devices: event.target.checked ? allDevices : [],
    });
    setAllChecked(event.target.checked);
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const devices = notification.devices;
    const deviceID = parseInt(event.target.name.replace("device_", ""));
    if (event.target.checked) {
      devices.push(deviceID);
      if (devices.length === allDevices.length) setAllChecked(true);
    } else if (!event.target.checked && devices.includes(deviceID)) {
      setAllChecked(false);
      const index = devices.indexOf(deviceID);
      if (index > -1) {
        devices.splice(index, 1);
      }
    }
    setNotification({ ...notification, devices: devices });
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();
  return (
    <>
      <Typography className={classes.typography}>
        {i18n("CREATE_NOTIFICATIONS_SELECT_THE_DEVICES")}
      </Typography>

      <ListItem style={{ margin: "0", padding: "0" }}>
        <ListItemText
          primary={i18n("CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES")}
        />
        <IOSSwitch checked={allChecked} onChange={handleChangeAllChecked} />
      </ListItem>

      <Divider />

      <div className={classes.body}>
        {checked &&
          devices &&
          devices.map((device) => (
            <FormControlLabel
              control={
                <Checkbox
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                  checked={checked[`device_${device.id}`]}
                  name={`device_${device.id}`}
                  onChange={handleChangeChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={<Typography variant="body2">{device.name}</Typography>}
            />
          ))}
      </div>
    </>
  );
};

export default NotificationsDeviceSelection;

import React, {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import FormInputIrrigation from "../../../../../../../../components/Form/FormInputIrrigation/FormInputIrrigation";
import FormSelectIrrigation from "../../../../../../../../components/Form/FormSelectIrrigation/FormSelectIrrigation";
import Store from "../../../../../../../../redux/index";
import "./NewByAngleIrrigation.scss";

import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  ControllerStream,
  GPSStream,
  Pivot,
  PivotConfig,
} from "../../../../../../../../redux/pivots/types";

import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "../../../../../../../../mobileConfig";
import { ApplicationState } from "../../../../../../../../redux";
import { hideModal } from "../../../../../../../../redux/modal/actions";
import {
  estimatedIrrigationDurationOnServer,
  isOldProtocol,
  isV5Protocol,
  parseHHMM,
  PIVOT_V4_PAUSE_TIME_WEEKDAYS,
  PTPToMillimeter,
} from "../../../../../../../../utils/models/pivots";

import {
  DrawArcBackward,
  DrawArcFoward,
  DrawCircle,
  DrawLine,
  writeOnCanvas,
} from "../../../../../../../../components/GeneralAngleSelector/components/Canvas";
import {
  angle_correction,
  BLUE_COLORS_TABLE,
  ZOOM_TABLE,
} from "../../../../../utils/utils";

import { RotateLeft, RotateRight } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DeleteIcon from "@material-ui/icons/Delete";
import RedoIcon from "@material-ui/icons/Redo";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useRecoilValue } from "recoil";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import AngleLabels from "../../../../../../../../components/GeneralAngleSelector/components/AngleLabels";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";
import SpeedInputs from "../../../../../../../../components/SpeedInputs/SpeedInputs";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import useCreatePivotActionVRI from "../../../../../../../../hooks/models/useCreatePivotActionVRI";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { PivotConfigFamily } from "../../../../../../../../recoils/PivotRecoil";
import { coreHTTPClient } from "../../../../../../../../services/webclient";

interface Props {
  pivot: Pivot;
  farmID: number;
  latestGPSStream: ControllerStream & GPSStream;
}

export interface TableRow {
  direction: number;
  speed: number;
  endgun: number;
  start_angle: number;
  end_angle: number;
}

let angle;

function NewByAngleIrrigation(props: Props) {
  const engGunTrue = require("../../../../../../../../assets/images/endgun_true.svg");
  const engGunFalse = require("../../../../../../../../assets/images/endgun_false.svg");
  const engGunIcon = require("../../../../../../../../assets/images/enggun.svg");
  const trashCan = require("../../../../../../../../assets/images/redTrashCan.png");
  const plusIcon = require("../../../../../../../../assets/images/plus.svg");
  const clockwise = require("../../../../../../../../assets/images/clockwise.svg");
  const { pivot, farmID } = props;

  const dispatch = useDispatch();
  const [createPivotActionVRI] = useCreatePivotActionVRI();

  let [disableNextButtonState, setDisableNextButtonState] = useState<boolean>(
    true
  );

  let [lastAngle, setLastAngle] = useState<number[]>([]);

  let [nextPage, setNextPage] = useState(false);

  // DIRECTION ARRAYS
  let [direction, setDirection] = useState<string[]>(["0"]);

  //ANGLE END
  let [angleEnd, setAngleEnd] = useState<string[]>(["0"]);

  //START DATE
  const [startDate, setStartDate] = useState(moment());

  //OPERATION MODE
  const [operationMode, setOperationMode] = useState("0");
  const [tableRows, setTableRows] = useState<TableRow[]>([]);

  //IRRIGATION PERCENT
  const [percentimeter, setPercentimeter] = useState<string>("100");
  const [percentimeterArray, setPercentimeterArray] = useState<string[]>([]);

  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  const [millimeter, setMillimeter] = useState(
    PTPToMillimeter(pivot, parseInt(percentimeter)).toString()
  );

  const [startMode, setStartMode] = useState("now");

  //FINAL CANNON MODE
  const [endgunMode, setEndgunMode] = useState<string[]>(["0"]);

  //By Rounds irrigation
  const [irrigationByRounds, setirrigationByRounds] = useState(0);

  const [fillets, setFillets] = useState(["#002372"]);
  const [canvasWhiteLines, setCanvasWhiteLines] = useState<
    RefObject<HTMLCanvasElement>[]
  >([createRef<HTMLCanvasElement>()]);

  const [canvasArcs, setCanvasArcs] = useState<RefObject<HTMLCanvasElement>[]>([
    createRef<HTMLCanvasElement>(),
  ]);

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  const [duration, setDuration] = useState<any>({
    duration: 0,
    formattedDuration: " - ",
    dateDuration: moment(),
  });

  // Div Modal for hidden overflow
  const divModalElement: HTMLDivElement = document.querySelector(
    "#mobileGeneralDivModal"
  )
    ? (document.querySelector("#mobileGeneralDivModal").children[2]
        .firstChild as HTMLDivElement)
    : (null as HTMLDivElement);

  useEffect(() => {
    if (pivotConfig !== undefined && startMode === "after_pause_time") {
      var config = pivotConfig as PivotConfig;
      var time = config.hour_range_max.split(":");
      var hour = parseInt(time[0]);
      var minute = parseInt(time[1]);
      var second = parseInt(time[2]);
      setStartDate(
        moment()
          .hour(hour)
          .minute(minute + 5)
          .second(second)
      );
    }
    if (startMode === "now") {
      setStartDate(moment());
    }
  }, [pivotConfig, startMode]);

  async function getEstimatedIrrigationDuration() {
    setDuration({
      duration: 0,
      formattedDuration: " - ",
      dateDuration: moment(),
    });

    var totalDuration = 0;
    var rawDuration = 0;

    const farmTimezone = Store().store.getState().farm.data?.timezone;

    for (let index = 0; index < tableRows?.length; index++) {
      var row = tableRows?.[index];

      const angle = row.start_angle;
      const endAngle = row.end_angle;
      const direction = row.direction == 0 ? true : false;
      const pauseTime1 = ["00:00", "00:00"];
      const pauseTime2 =
        pivot.config?.hour_range_min && pivot.config?.hour_range_max
          ? [
              pivot.config?.hour_range_min.slice(0, 5),
              pivot.config?.hour_range_max?.slice(0, 5),
            ]
          : ["00:00", "00:00"];
      const timezone = farmTimezone ? farmTimezone : "America/Sao_Paulo";
      const irrigationStartDate =
        startMode !== "now" && index + 1 === tableRows.length
          ? startDate.toISOString()
          : null;
      const numberOfRounds = irrigationByRounds < 0 ? irrigationByRounds : null;
      const isWetIrrigation = operationMode == "2" ? true : false;

      var estimatedIrrigation = await estimatedIrrigationDurationOnServer(
        pivot.config.radius,
        pivot.config.speed,
        row.speed,
        direction,
        angle,
        endAngle,
        pivot.config?.sector_angle ? pivot.config?.sector_angle : 360,
        PIVOT_V4_PAUSE_TIME_WEEKDAYS,
        pauseTime1,
        pauseTime2,
        timezone,
        irrigationStartDate,
        numberOfRounds,
        isWetIrrigation
      );

      totalDuration = totalDuration + estimatedIrrigation.total_duration;
      rawDuration = rawDuration + estimatedIrrigation.raw_duration;
    }
    totalDuration = Math.round(totalDuration * 100) / 100;
    rawDuration = Math.round(rawDuration * 100) / 100;

    setDuration({
      duration: rawDuration,
      formattedDuration: parseHHMM(rawDuration * 60),
      dateDuration: moment().add(totalDuration, "hour"),
    });
  }

  useEffect(() => {
    getEstimatedIrrigationDuration();
  }, [
    tableRows,
    startMode,
    pivot?.config,
    startDate,
    irrigationByRounds,
    operationMode,
  ]);

  var isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  const notify = useNotify();

  //ANGLE START
  const [angleStart, setAngleStart] = useState(pivot.latest_gps_stream.angle);

  const firstCanva = useRef(null);

  let gpsPosition;
  let centerPosition;
  if (isOldProtocol(pivot)) {
    centerPosition = pivot.config.center.split(",");
    gpsPosition = pivot.latest_gps_stream.position.split(",");
  } else if (isV5Protocol(pivot)) {
    centerPosition = [
      pivot.controllerconfig.content.pivot_positions.latitude_center.toString(),
      pivot.controllerconfig.content.pivot_positions.longitude_center.toString(),
    ];
    gpsPosition = [
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
    ];
  }
  let gpsPositionGMaps = new google.maps.LatLng(
    parseFloat(gpsPosition[0]),
    parseFloat(gpsPosition[1])
  );

  let centerPositionGMaps = new google.maps.LatLng(
    parseFloat(centerPosition[0]),
    parseFloat(centerPosition[1])
  );

  //Posição do pivo
  let angleGps = google.maps.geometry.spherical.computeHeading(
    centerPositionGMaps,
    gpsPositionGMaps
  );

  //Angulo de referencia
  let referenceAngle = pivot.reference_angle;
  // let referenceAngle = 0;

  let gpsAngleWithReference: number =
    angleGps > 0
      ? Math.round(angleGps - referenceAngle)
      : Math.round(angleGps + 360 - referenceAngle);

  gpsAngleWithReference =
    gpsAngleWithReference >= 360
      ? gpsAngleWithReference - 360
      : gpsAngleWithReference < 0
      ? gpsAngleWithReference + 360
      : gpsAngleWithReference;

  function handleMouseMove(
    canva: any,
    arcCanva: any,
    filletsMove: any,
    radius: number,
    startAngle: number,
    directionMove: string,
    lastAngleMove: number,
    newAngle?: any,
    e?: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");
    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field

      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
    }

    let newY = radius * Math.cos(((startAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((startAngle + angle) * Math.PI) / 180);

    //Set the newAngle as EndAngle
    if (newAngle === undefined) {
      let newEndAngle = Math.round(angle) == 360 ? 0 : Math.round(angle);
      if (angleEnd.length == fillets.length) {
        angleEnd.pop();
        //direction é o sentido em que o pivot será movimentado
        //1 = horário e 0 = anti-horário

        setAngleEnd((angleEnd) => [
          ...angleEnd,
          Math.round(newEndAngle).toString(),
        ]);
      } else {
        setAngleEnd((angleEnd) => [
          ...angleEnd,
          Math.round(newEndAngle).toString(),
        ]);
      }
    }

    //Desenha uma nova linha branca
    DrawLine(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      context,
      canva.current.width / 2 + newX / 2,
      canva.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Ajusta o angulo para contar a partir do angulo do pivo
    let angleAux = Math.round(
      angle +
        (startAngle > 0
          ? startAngle - referenceAngle
          : startAngle + 360 - referenceAngle)
    );

    angleAux =
      angleAux >= 360
        ? angleAux - 360
        : angleAux < 0
        ? angleAux + 360
        : angleAux;

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      context,
      canva.current.width / 2 + newX / 2 - 10,
      canva.current.height / 2 - newY / 2 + 5,
      angleAux + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );

    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();
    // contextArc.beginPath();
    if (directionMove == "0") {
      if (lastAngleMove != -1) {
        //Check if it's the first fillet or not. If it's not
        //the new fillet must use the last one as its new reference
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleMove) * Math.PI) / 180, //Parte da posicão inicial do pivot
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      } else {
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180, //parte do
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      }
    } else {
      if (lastAngleMove != -1) {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleMove) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      } else {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          0.9
        );
      }
    }

    contextArc.fill();
    contextArc.restore();
  }

  const handleDirection = (
    event: React.MouseEvent<HTMLElement>,
    newDirection: string | null
  ) => {
    if (newDirection) {
      direction.pop();
      setDirection([...direction, newDirection]);
    }
  };

  useEffect(() => {
    if (tableRows.length == 0) {
      setAngleStart(pivot.latest_gps_stream.angle);
    }
  }, [pivot.latest_gps_stream.angle]);

  let center = new google.maps.LatLng(
    parseFloat(
      pivot.config.center.substring(0, pivot.config.center.indexOf(","))
    ),
    parseFloat(
      pivot.config.center.substring(
        pivot.config.center.indexOf(",") + 1,
        pivot.config.center.length
      )
    )
  );

  let reference = new google.maps.LatLng(
    parseFloat(
      pivot.config.reference.substring(0, pivot.config.reference.indexOf(","))
    ),
    parseFloat(
      pivot.config.reference.substring(
        pivot.config.reference.indexOf(",") + 1,
        pivot.config.reference.length
      )
    )
  );

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;
  let conversionRatio = 0;
  let conversionRatioReferenceLines = 0;
  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
      conversionRatio = ZOOM_TABLE[i].conversionRatio;
      conversionRatioReferenceLines =
        ZOOM_TABLE[i].conversionRatioReferenceLines;
    }
  }

  const circleDiameter = referenceRadius * conversionRatio * 2;

  useEffect(() => {
    if (operationMode === "0") {
      setMillimeter(String(PTPToMillimeter(pivot, 100)));
    }
  }, [operationMode]);

  useEffect(() => {
    handleMouseMove(
      canvasWhiteLines[canvasWhiteLines.length - 1],
      canvasArcs[canvasArcs.length - 1],
      fillets[fillets.length - 1],
      referenceRadius * conversionRatio,
      angleGps,
      direction[direction.length - 1],
      lastAngle[lastAngle.length - 1] ? lastAngle[lastAngle.length - 1] : -1,
      angleEnd[angleEnd.length - 1]
    );
  }, [direction]);

  useEffect(() => {
    if (nextPage == false) {
      //Desenha os angulos quando voltar para o primeiro modal
      if (fillets.length > endgunMode.length) {
        let filletAux = fillets.slice(-endgunMode.length);
        let CanvasWhiteAux = canvasWhiteLines.slice(-endgunMode.length);
        let CanvasArcAux = canvasArcs.slice(-endgunMode.length);
        setFillets(filletAux);
        setCanvasArcs(CanvasWhiteAux);
        setCanvasWhiteLines(CanvasArcAux);
      }
      for (let index = 0; index < endgunMode.length; index++) {
        handleMouseMove(
          canvasWhiteLines[index],
          canvasArcs[index],
          fillets[index],
          referenceRadius * conversionRatio,
          angleGps,
          direction[index],
          lastAngle[index - 1] ? lastAngle[index - 1] : -1,
          angleEnd[index]
        );
      }
    }
  }, [nextPage]);

  useEffect(() => {
    if (nextPage == false) {
      let context = firstCanva.current.getContext("2d");

      //Desenha circulo que representa alcance do pivot
      context.beginPath();
      context.arc(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2,
        referenceRadius * conversionRatio,
        0,
        2 * Math.PI
      );
      context.strokeStyle = "white";
      context.stroke();

      //Desenha as linhas brancas ao redor do círculo
      for (let i = 0; i < 72; i++) {
        context.save();
        let ang = (i * 5 * Math.PI) / 180;
        context.translate(
          firstCanva.current.width / 2,
          firstCanva.current.height / 2
        );
        context.rotate(ang);
        context.fillStyle = "white";
        context.textBaseline = "middle";
        context.textAlign = "center";
        context.beginPath();
        context.moveTo(0, referenceRadius * conversionRatioReferenceLines);
        context.lineTo(0, referenceRadius * conversionRatio);
        context.strokeStyle = "white";
        context.stroke();
        //back to checkpoint
        context.restore();
      }

      // Translate the context to the point we want to rotate around
      context.translate(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2
      );

      // teste:
      // writeOnCanvas(
      //   context,
      //   -(firstCanva.current.height / 2),
      //   0,
      //   "angleAux" + "º",
      //   "red"
      // );

      // Perform the rotation
      context.rotate((referenceAngle * Math.PI) / 180);
      //Escreve os graus 0º, 90º, 180º, 270º

      // writeOnCanvas(
      //   context,
      //   0,
      //   -referenceRadius * conversionRatioReferenceLines + 5,
      //   "0º",
      //   "white"
      // );
      // writeOnCanvas(
      //   context,
      //   -9,
      //   referenceRadius * conversionRatioReferenceLines - 2,
      //   "180º",
      //   "white"
      // );
      // writeOnCanvas(
      //   context,
      //   referenceRadius * conversionRatioReferenceLines - 15,
      //   4,
      //   "90º",
      //   "white"
      // );
      // writeOnCanvas(
      //   context,
      //   -referenceRadius * conversionRatioReferenceLines + 1,
      //   4,
      //   "270º",
      //   "white"
      // );

      let pivotPositionY =
        referenceRadius *
        conversionRatio *
        Math.cos(((angleGps - referenceAngle) * Math.PI) / 180);
      let pivotPositionX =
        referenceRadius *
        conversionRatio *
        Math.sin(((angleGps - referenceAngle) * Math.PI) / 180);

      //Linha preta (posição atual do pivot)
      DrawLine(context, 0, 0, pivotPositionX, pivotPositionY, "black");
      // DrawLine(context, 0, 0, 0, referenceRadius * conversionRatio, "black");

      //back to checkpoint
      context.restore();
      DrawCircle(
        context,
        firstCanva.current.width / 2,
        firstCanva.current.height / 2,
        5,
        0,
        2 * Math.PI,
        "black"
      );
      let contextWhiteLines = canvasWhiteLines[
        canvasWhiteLines.length - 1
      ].current.getContext("2d");

      //create canvas checkpoint with default translate and rotate
      contextWhiteLines.save();

      //Translate full canvas to it's center
      contextWhiteLines.translate(
        canvasWhiteLines[canvasWhiteLines.length - 1].current.width / 2,
        canvasWhiteLines[canvasWhiteLines.length - 1].current.height / 2
      );

      //Rotate canvas by reference_angle arround it's center
      contextWhiteLines.rotate((referenceAngle * Math.PI) / 180);

      //Desenha o circulo central preto
      DrawCircle(contextWhiteLines, 0, 0, 5, 0, 2 * Math.PI, "black");

      //rotate and translate back to checkpoint
      contextWhiteLines.restore();
    }
  }, [nextPage]);

  const endGunIcon = (
    <>
      <img src={engGunIcon} height="35" />
    </>
  );

  const endGunActive = (
    <>
      <img src={engGunTrue} height="25" />
    </>
  );

  const endGunOff = (
    <>
      <img src={engGunFalse} height="25" />
    </>
  );

  const handleStartDatetime = (startMode) => {
    var startDateTime;

    var startDateWithNoSeconds = startDate.second(0);

    if (startMode === "now") {
      startDateTime = undefined;
    } else {
      startDateTime = startDateWithNoSeconds.format("YYYY-MM-DDTHH:mm:ss[Z]");
    }

    return startDateTime;
  };

  const nextPageButton = () => {
    let tableRowsAux: any = [];

    let lastAngle: number = gpsAngleWithReference;

    let end_angleAux: number;
    for (let i = 0; i < fillets.length; i++) {
      end_angleAux = parseInt(angleEnd[i]) + gpsAngleWithReference;

      end_angleAux = end_angleAux >= 360 ? end_angleAux - 360 : end_angleAux;

      tableRowsAux.push({
        direction: parseInt(direction[i]),
        speed: parseInt(
          fillets.length - 1 == i ? percentimeter : percentimeterArray[i]
        ),
        endgun: endgunMode[i],
        start_angle: Math.round(lastAngle),
        end_angle: Math.round(end_angleAux),
      });
      lastAngle = end_angleAux;
    }

    setTableRows(tableRowsAux);
    setNextPage(true);
  };

  const disableNextButton = (): boolean => {
    if (angleEnd.length == 1)
      if (
        Math.round(
          parseInt(angleEnd[angleEnd.length - 1]) + gpsAngleWithReference
        ) %
          360 ==
        Math.round(gpsAngleWithReference)
      )
        return true;
  };

  useEffect(() => {
    setDisableNextButtonState(disableNextButton());
  }, [angleEnd]);

  const byRoundsHandler = (e) => {
    setAngleEnd([]);
    setPercentimeterArray([]);
    setEndgunMode([]);
    setDirection([]);
    setFillets([]);
    setCanvasArcs([createRef<HTMLCanvasElement>()]);
    setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);
    setLastAngle([]);
    setirrigationByRounds(0);
    if (parseInt(e.target.value) == 0) return true;
    let percentimeterArrayAux = [];
    let angleEndAux = [];
    let directionAux = [];
    let endGunAux = [];
    let filletsAux: any = [];
    let newWhiteLineAux: any = [];
    let newArcAux: any = [];

    let tableRowsAux = [];
    for (let i = 0; i < parseInt(e.target.value) * 2; i++) {
      if (i % 2 == 0) {
        angleEndAux.push(
          Math.round(
            (parseFloat(angleStart) + 180 - gpsAngleWithReference) % 360
          ).toString()
        );
        tableRowsAux.push({
          direction: direction[direction.length - 1],
          speed: parseInt(percentimeter),
          endgun: endgunMode[direction.length - 1],
          start_angle: Math.round(parseFloat(angleStart)),
          end_angle: Math.round((parseFloat(angleStart) + 180) % 360),
        });
      } else {
        angleEndAux.push(
          Math.round(parseFloat(angleStart) - gpsAngleWithReference).toString()
        );
        tableRowsAux.push({
          direction: direction[direction.length - 1],
          speed: parseInt(percentimeter),
          endgun: endgunMode[direction.length - 1],
          start_angle: Math.round((parseFloat(angleStart) + 180) % 360),
          end_angle: Math.round(parseFloat(angleStart)),
        });
      }
      if (i != parseInt(e.target.value) - 1) {
        percentimeterArrayAux.push(percentimeter);
      }
      directionAux.push(direction[direction.length - 1]);
      endGunAux.push(endgunMode[endgunMode.length - 1]);
      if (i != 0) {
        filletsAux.push(BLUE_COLORS_TABLE[i]);
        newWhiteLineAux.push(createRef<HTMLCanvasElement>());
        newArcAux.push(createRef<HTMLCanvasElement>());
      }
    }
    setFillets([...fillets, ...filletsAux]);
    setCanvasWhiteLines([...canvasWhiteLines, ...newWhiteLineAux]);
    setCanvasArcs([...canvasArcs, ...newArcAux]);

    setPercentimeterArray(percentimeterArrayAux);
    setLastAngle(
      angleEndAux
        .slice(0, angleEndAux.length - 1)
        .map((value) => parseInt(value))
    );
    setAngleEnd(angleEndAux);
    setDirection(directionAux);
    setEndgunMode(endGunAux);

    setTableRows(tableRowsAux);
    setirrigationByRounds(parseInt(e.target.value));
    setNextPage(true);
  };

  const lastIrrigationUsed = async () => {
    setAngleEnd(["0"]);
    setPercentimeterArray([]);
    setMillimeter(`${PTPToMillimeter(pivot, 100)}`);
    setPercentimeter("100");
    setEndgunMode(["0"]);
    setDirection(["0"]);
    setFillets(["#002372"]);
    setCanvasArcs([createRef<HTMLCanvasElement>()]);
    setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);
    setLastAngle([]);
    setirrigationByRounds(0);

    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/actions/vri/`
    );
    if (response.data?.results[0]) {
      let lastIrration = response.data?.results[0];
      let tableRowsAux: any = [];
      let filletsAux: any = [];
      let newWhiteLineAux: any = [];
      let newArcAux: any = [];
      let lastAngleAux: number = gpsAngleWithReference;
      let end_angleAux: number;
      setPercentimeterArray(
        lastIrration.speed_array.slice(0, lastIrration.speed_array.length - 1)
      );
      setPercentimeter(
        lastIrration.speed_array[lastIrration.speed_array.length - 1]
      );
      setMillimeter(
        `${PTPToMillimeter(
          pivot,
          lastIrration.speed_array[lastIrration.speed_array.length - 1]
        )}`
      );
      setLastAngle([
        lastIrration.angle_array.slice(1, lastIrration.angle_array.length),
      ]);
      setAngleEnd(lastIrration.angle_array);
      setDirection(lastIrration.direction_array);
      setEndgunMode(lastIrration.endgun_array);
      setOperationMode(lastIrration.mode);

      lastIrration.angle_array.map((endAngle, index) => {
        end_angleAux = parseInt(endAngle);

        end_angleAux = end_angleAux >= 360 ? end_angleAux - 360 : end_angleAux;

        tableRowsAux.push({
          direction: lastIrration.direction_array[index],
          speed: lastIrration.speed_array[index],
          endgun: lastIrration.endgun_array[index],
          start_angle: Math.round(lastAngleAux).toString(),
          end_angle: Math.round(end_angleAux).toString(),
        });
        lastAngleAux = end_angleAux;

        if (index != 0) {
          filletsAux.push(BLUE_COLORS_TABLE[index + 1]);
          newWhiteLineAux.push(createRef<HTMLCanvasElement>());
          newArcAux.push(createRef<HTMLCanvasElement>());
        }
      });
      setFillets([...fillets, ...filletsAux]);
      setCanvasWhiteLines([...canvasWhiteLines, ...newWhiteLineAux]);
      setCanvasArcs([...canvasArcs, ...newArcAux]);

      setTableRows(tableRowsAux);
      setNextPage(true);
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");

    setIsLoadingLastAction(false);
  };

  return !nextPage ? (
    <>
      {isMobile() ? <></> : <br></br>}
      <div className="by-angle-irrigation">
        <div className="by-angle-irrigation__form">
          <div className="by-angle-irrigation__center-row-without-margin">
            <Button
              style={
                isMobile()
                  ? { width: "100%" }
                  : {
                      marginTop: "-30px",
                      marginBottom: "10px",
                    }
              }
              variant="contained"
              color="primary"
              disabled={isLoadingLastAction}
              endIcon={
                isLoading ? (
                  <CircularProgress size={20} style={{ marginLeft: "8px" }} />
                ) : (
                  <RedoIcon />
                )
              }
              onClick={lastIrrigationUsed}
            >
              <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
            </Button>
          </div>
          <div
            className={
              isMobile()
                ? "by-angle-irrigation__1-column"
                : "by-angle-irrigation__grid-2-1"
            }
          >
            <SpeedInputs
              pivot={pivot}
              millimeter={millimeter}
              percentage={percentimeter}
              setMillimeter={setMillimeter}
              setPercentage={setPercentimeter}
            />

            <FormSelectIrrigation
              id="pivot-config-endgun-mode"
              label={"BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE"}
              val={endgunMode[endgunMode.length - 1].toString()}
              onChangeFunction={(e) => {
                let newVal = e.target.value.toString();
                endgunMode.pop();
                setEndgunMode([...endgunMode, newVal]);
              }}
              values={[
                ["0", "BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF"],
                ["1", "BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON"],
              ]}
            />
          </div>

          <div
            className={
              isMobile()
                ? "by-angle-irrigation__1-column"
                : "by-angle-irrigation__2-auto-columns"
            }
          >
            <div className={"by-angle-irrigation__internal-2-columns"}>
              <div className={"by-angle-irrigation__input-end-angle"}>
                {/*
                  O sistema de irrigação por voltas para pivôs setoriais precisa de implementações complexas e não está funcionando
                  para evitar que um usuário  envie  "por voltas" e ocorra problemas sérios na irrigação ele está bloqueado até que 
                  seja ajustado (atualmente as voltas estão sendo de 360 graus independente do pivô ser setorial ou não).
                */}
                <FormControl
                  className="form-select__form-control"
                  disabled={pivot.config?.sector_angle !== null}
                >
                  <InputLabel
                    id={"irrigation-by-rounds-label"}
                    style={{ color: "rgba(0, 0, 0, 0.38)" }}
                  >
                    {i18n("SIMPLE_IRRIGATION_BY_ROUNDS")}
                  </InputLabel>
                  <NativeSelect
                    id="irrigation-by-rounds"
                    key={irrigationByRounds}
                    value={irrigationByRounds}
                    onChange={byRoundsHandler}
                  >
                    <option key={"0"} value={"0"}>
                      {i18n("BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF")}
                    </option>
                    <option key={"1"} value={"1"}>
                      1
                    </option>
                    <option key={"2"} value={"2"}>
                      2
                    </option>
                    <option key={"3"} value={"3"}>
                      3
                    </option>
                    <option key={"4"} value={"4"}>
                      4
                    </option>
                    <option key={"5"} value={"5"}>
                      5
                    </option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className={"by-angle-irrigation__input-end-angle"}>
                <FormInputIrrigation
                  id="pivot-config-angle-end"
                  label={i18n("BY_ANGLE_IRRIGATION_FIELD_ANGLE_END")}
                  val={angle_correction(
                    Math.round(
                      parseInt(angleEnd[angleEnd.length - 1]) +
                        gpsAngleWithReference
                    )
                  ).toString()}
                  onChangeFunction={(e) => {
                    let newAngle = e.target.value;

                    if (newAngle == "") newAngle = "0";

                    let angleEndAux = [...angleEnd];
                    angleEndAux.pop();

                    if (newAngle >= 0 && newAngle < 360) {
                      // Verify if result of Newangle is negative
                      if (newAngle < 0) {
                        newAngle = 0;
                      }

                      const newAngleEnd = Math.round(
                        parseInt(newAngle) - gpsAngleWithReference
                      ).toString();

                      setAngleEnd([...angleEndAux, newAngleEnd]);
                      handleMouseMove(
                        canvasWhiteLines[canvasWhiteLines.length - 1],
                        canvasArcs[canvasArcs.length - 1],
                        fillets[fillets.length - 1],
                        referenceRadius * conversionRatio,
                        angleGps,
                        direction[direction.length - 1],
                        lastAngle[lastAngle.length - 1]
                          ? lastAngle[lastAngle.length - 1]
                          : -1,
                        newAngleEnd
                      );
                    }
                  }}
                  endAdornment="º"
                  inputMode="numeric"
                />
              </div>
            </div>
            <div className={"by-angle-irrigation__toggle-container"}>
              <ToggleButtonGroup
                value={direction[direction.length - 1].toString()}
                exclusive
                onChange={handleDirection}
                aria-label="direction"
                style={{ width: 280, marginTop: 5, marginBottom: 5 }}
              >
                <ToggleButton
                  value="1"
                  aria-label="Reverse"
                  style={{ width: 140 }}
                >
                  <RotateLeft />
                  {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
                </ToggleButton>
                <ToggleButton
                  value="0"
                  aria-label="Progress"
                  style={{ width: 140 }}
                >
                  <RotateRight />
                  {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          <div className="by-angle-irrigation__fillets">
            <span style={{ fontSize: 14 }}>Faixas</span>
            <div className="by-angle-irrigation__fillets-container">
              <div
                id="fillets-container"
                className="by-angle-irrigation__fillets-container"
              >
                {fillets.length == 0 ? (
                  <div className="by-angle-irrigation__fillets-disabled-circle">
                    1
                  </div>
                ) : null}
                {fillets.map((fillet, index) => {
                  return (
                    <div
                      key={"fillet_" + index}
                      style={{ backgroundColor: BLUE_COLORS_TABLE[index] }}
                      className={`${
                        index === fillets.length - 1
                          ? "by-angle-irrigation__fillets-circle-active"
                          : "by-angle-irrigation__fillets-circle"
                      }`}
                    >
                      {direction[index] == "0" ? (
                        <img
                          src={clockwise}
                          width={15}
                          height={15}
                          style={{
                            transform: "scalex(-1)",
                            color: "white",
                          }}
                        />
                      ) : (
                        <img src={clockwise} width={15} height={15} />
                      )}
                    </div>
                  );
                })}
                {fillets.map((fillet, index) => {
                  if (index + 1 == fillets.length) {
                    return (
                      <div
                        key={"disabled-circle-" + index}
                        className={
                          angle !== 0 &&
                          angle !== lastAngle[lastAngle.length - 1] &&
                          fillets.length < 20
                            ? "by-angle-irrigation__fillets-circle-active"
                            : "by-angle-irrigation__fillets-disabled-circle"
                        }
                        onClick={() => {
                          if (
                            angle !== 0 &&
                            angle !== lastAngle[lastAngle.length - 1] &&
                            fillets.length < 20
                          ) {
                            setFillets([
                              ...fillets,
                              BLUE_COLORS_TABLE[index + 1],
                            ]);

                            let newWhiteLine = canvasWhiteLines.concat(
                              createRef<HTMLCanvasElement>()
                            );
                            setCanvasWhiteLines(newWhiteLine);

                            let newArc = canvasArcs.concat(
                              createRef<HTMLCanvasElement>()
                            );
                            setCanvasArcs(newArc);
                            setLastAngle([...lastAngle, angle]);
                            if (direction[direction.length - 1] == "0")
                              setAngleEnd([
                                ...angleEnd,
                                (Math.round(angle) + 1).toString(),
                              ]);
                            else
                              setAngleEnd([
                                ...angleEnd,
                                (Math.round(angle) - 1).toString(),
                              ]);
                            setPercentimeterArray([
                              ...percentimeterArray,
                              percentimeter,
                            ]);
                            setPercentimeter("100");
                            setMillimeter(`${PTPToMillimeter(pivot, 100)}`);
                            setEndgunMode([
                              ...endgunMode,
                              endgunMode[endgunMode.length - 1],
                            ]);
                            setDirection([
                              ...direction,
                              direction[direction.length - 1],
                            ]);
                          }
                        }}
                      >
                        <img src={plusIcon} width={15} height={15} />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="by-angle-irrigation__fillets-trash">
                <img
                  src={trashCan}
                  style={{ width: 23, height: 23, marginRight: 5 }}
                  onClick={() => {
                    if (fillets.length > 1) {
                      angle = lastAngle[lastAngle.length - 1];
                      fillets.pop();
                      setFillets([...fillets]);
                      canvasWhiteLines.pop();
                      setCanvasWhiteLines([...canvasWhiteLines]);
                      canvasArcs.pop();
                      setCanvasArcs([...canvasArcs]);
                      lastAngle.pop();
                      setLastAngle([...lastAngle]);
                      angleEnd.pop();
                      setAngleEnd([...angleEnd]);
                      direction.pop();
                      setDirection([...direction]);
                      percentimeterArray.pop();
                      setPercentimeterArray([...percentimeterArray]);
                      endgunMode.pop();
                      setEndgunMode([...endgunMode]);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              userSelect: "none",
              WebkitTouchCallout: "none" /* iOS Safari */,
              WebkitUserSelect: "none" /* Safari */,
              KhtmlUserSelect: "none" /* Konqueror HTML */,
              MozUserSelect: "none" /* Old versions of Firefox */,
              msUserSelect: "none" /* Internet Explorer/Edge */,
              width: `${window.innerWidth > 800 ? "600px" : "300px"}`,
              height: "300px",
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10%",
              marginBottom: "8%",
              paddingBottom: "21%",
            }}
          >
            <AngleLabels
              referenceAngle={referenceAngle}
              circleDiameter={circleDiameter}
              endAngle={360} // Fix para pivo setorial em V5 [não será implementado em V4 ( ˘ ³˘)♥ ]
            />
            <canvas
              ref={firstCanva}
              width={300}
              height={300}
              style={{ position: "absolute", zIndex: 899 }}
            />
            {canvasArcs.map((canva, index) => {
              return (
                <canvas
                  key={"canvaArc_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 820 + index,
                  }}
                />
              );
            })}
            {canvasWhiteLines.map((canva, index) => {
              return (
                <canvas
                  key={"canva_" + index}
                  ref={canva}
                  width={300}
                  height={300}
                  style={{
                    position: "absolute",
                    zIndex: 901 + index,
                  }}
                  onTouchMove={(e) => {
                    if (lastAngle.length > canvasWhiteLines.length - 1) {
                      lastAngle.pop();
                      setLastAngle((lastAngle) => [...lastAngle]);
                    }
                    handleMouseMove(
                      canva,
                      canvasArcs[index],
                      fillets[index],
                      referenceRadius * conversionRatio,
                      angleGps,
                      direction[index],
                      lastAngle[index - 1] ? lastAngle[index - 1] : -1,
                      undefined,
                      e
                    );
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "hidden";

                    if (lastAngle.length > canvasWhiteLines.length - 1) {
                      lastAngle.pop();
                      setLastAngle([...lastAngle]);
                    }
                    handleMouseMove(
                      canva,
                      canvasArcs[index],
                      fillets[index],
                      referenceRadius * conversionRatio,
                      angleGps,
                      direction[index],
                      lastAngle[index - 1] ? lastAngle[index - 1] : -1,
                      undefined,
                      e
                    );
                    e.stopPropagation();
                  }}
                  onTouchEnd={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "auto";
                  }}
                  onTouchStart={(e) => {
                    if (divModalElement)
                      divModalElement.style.overflow = "hidden";
                  }}
                />
              );
            })}
            {window.innerWidth > 800 ? (
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${pivot.config.center}&zoom=${zoomLevel}&size=600x300&maptype=satellite${process.env.MAPS_KEY}`}
                style={{ width: "600px", height: "300px" }}
              />
            ) : (
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${pivot.config.center}&zoom=${zoomLevel}&size=300x300&maptype=satellite${process.env.MAPS_KEY}`}
                style={{ width: "300px", height: "300px" }}
              />
            )}
          </div>
        </div>

        <MobileZone>
          <div
            className="by-angle-irrigation__2-columns"
            style={{
              position: "fixed",
              bottom: 0,
              backgroundColor: "white",
              boxShadow:
                "0px -3px 0px -2px rgba(0, 0, 0, 0.2), 0px -2px 2px 0px rgba(0, 0, 0, 0.14), 0px -1px 5px 1px rgba(0, 0, 0, 0.12)",
              zIndex: 950,
            }}
          >
            <Button
              style={{
                width: "100%",
                height: "40px",
                background: "#EB5757",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                setAngleEnd([]);
                setPercentimeterArray([]);
                setEndgunMode([]);
                setDirection([]);
                dispatch(hideModal());
              }}
            >
              {i18n("IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO")}
            </Button>
            <Button
              style={{
                width: "100%",
                height: "40px",
              }}
              color="primary"
              variant="contained"
              onClick={nextPageButton}
              disabled={disableNextButtonState}
            >
              <>{i18n("SIGN_UP_NEXT")}</>
            </Button>
          </div>
        </MobileZone>

        <DesktopZone>
          <div className="by-angle-irrigation__2-columns">
            <Button
              style={{
                width: "100%",
                height: "40px",
                background: "#EB5757",
                marginTop: "-20%",
              }}
              color="primary"
              variant="contained"
              onClick={() => {
                setAngleEnd([]);
                setPercentimeterArray([]);
                setEndgunMode([]);
                setDirection([]);
                dispatch(hideModal());
              }}
            >
              {i18n("IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO")}
            </Button>
            <Button
              style={{
                width: "100%",
                height: "40px",
                marginTop: "-20%",
              }}
              color="primary"
              variant="contained"
              onClick={nextPageButton}
              disabled={disableNextButtonState}
            >
              <>{i18n("SIGN_UP_NEXT")}</>
            </Button>
          </div>
        </DesktopZone>
      </div>
    </>
  ) : (
    <>
      <br></br>
      {isLoading && <LinearProgress />}
      {isMobile() ? <></> : <br></br>}
      <div className="by-angle-irrigation">
        <div className="by-angle-irrigation__inputs">
          <FormSelect
            id="pivot-config-operation-mode"
            label={"BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE"}
            state={[operationMode, setOperationMode]}
            values={[
              ["0", "BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY"],
              ["2", "BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET"],
            ]}
          />

          <div className="by-angle-irrigation__row">
            {/* <FormCheckBox
              state={[startNow, setStartNow]}
              label={"PIVOT_ACTION_FIELD_START_NOW"}
            /> */}
            <FormSelect
              id="segment-start-mode"
              label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
              state={[startMode, setStartMode]}
              values={[
                ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
                pivot.config.hour_range_min !== "00:00:00"
                  ? [
                      "after_pause_time",
                      "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME",
                    ]
                  : null,
                [
                  "on_a_date",
                  "SEGMENT_IRRIGATION_ON_DATE", //on a date
                ],
              ]}
            />
            <FormDateTimePicker
              id="start-hour"
              label={"PIVOT_CONFIG_FIELD_DATE_TIME"}
              state={[startDate, setStartDate]}
              disabled={startMode === "now"}
            />
            <Button
              style={{
                width: isMobile() ? "100%" : "70%",
                height: "40px",
                background: "#EB5757",
                marginLeft: isMobile() ? "0px" : "35px",
                alignSelf: "center",
              }}
              color="primary"
              endIcon={<DeleteIcon>opacity</DeleteIcon>}
              variant="contained"
              onClick={() => {
                setTableRows([]);
                for (let index = fillets.length; index > 1; index--) {
                  angle = lastAngle[lastAngle.length - 1];
                  fillets.pop();
                  setFillets([...fillets]);
                  canvasWhiteLines.pop();
                  setCanvasWhiteLines([...canvasWhiteLines]);
                  canvasArcs.pop();
                  setCanvasArcs([...canvasArcs]);
                  lastAngle.pop();
                  setLastAngle([...lastAngle]);
                  angleEnd.pop();
                  setAngleEnd([...angleEnd]);
                  percentimeterArray.pop();
                  setPercentimeterArray([...percentimeterArray]);
                }
              }}
            >
              <div style={{ fontSize: "14px" }}>
                {i18n("PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM")}
              </div>
            </Button>
          </div>
        </div>
        <div className="by-angle-irrigation__table">
          <div className="by-angle-irrigation__table-head">
            <div>
              <ArrowBackIcon />
            </div>
            <div>
              <ArrowForwardIcon />
            </div>
            <div>
              <SwapHorizIcon />
            </div>
            <div>% | mm</div>
            <div>{endGunIcon}</div>
            {/* <div>
              <DeleteIcon />
            </div> */}
          </div>
          <div className="by-angle-irrigation__table-body">
            {tableRows.map((row, index) => (
              <>
                <div>{tableRows[index].start_angle}º</div>
                <div>{tableRows[index].end_angle}º</div>
                <div>
                  {tableRows[index].direction == 1 ? (
                    <RotateLeftIcon />
                  ) : (
                    <RotateRightIcon />
                  )}
                </div>
                <div>
                  {" "}
                  {`${tableRows[index].speed}% | ${PTPToMillimeter(
                    pivot,
                    tableRows[index].speed
                  ).toFixed(2)} mm`}
                </div>
                <div>
                  {tableRows[index].endgun == 1 ? endGunActive : endGunOff}
                </div>
                {/* <div>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setTableRows([]);
                    }}
                    aria-label="close"
                  >
                    <DeleteIcon style={{ color: "red" }} fontSize="small" />
                  </IconButton>
                </div> */}
              </>
            ))}
          </div>

          <div
            style={{
              marginLeft: isMobile() ? "20px" : "7px",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                marginBottom: "-8px",
              }}
            >
              <strong style={{ marginBottom: "10px", marginRight: "5px" }}>
                {i18n("IRRIGATION_END_DURATION")}
              </strong>
              {!duration?.duration ? (
                <Skeleton width={100} />
              ) : (
                duration.formattedDuration
              )}
            </div>
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                marginBottom: "-8px",
              }}
            >
              <strong style={{ marginRight: "5px" }}>
                {i18n("IRRIGATION_END_DATE_PREDICTION")}
              </strong>
              {!duration?.duration ? (
                <Skeleton width={60} />
              ) : (
                duration.dateDuration?.format("DD MMM HH:mm")
              )}
              <div style={{ margin: "0 0 2px -5px" }}>
                <InputTooltip
                  size={14}
                  description={i18n("IRRIGATION_END_DATE_PREDICTION_TOOLTIP")}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="by-angle-irrigation__2-columns"
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          <Button
            style={{
              width: "100%",
              height: "40px",
              background: "#EB5757",
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              setNextPage(false);
            }}
          >
            {i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}
          </Button>

          <Button
            style={{ width: "100%", height: "40px" }}
            color="primary"
            disabled={tableRows.length === 0 || isLoading}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : (
                <Icon>opacity</Icon>
              )
            }
            variant="contained"
            onClick={() => {
              var speedArray = [];
              var directionArray = [];
              var endgunArray = [];
              var angleArray = [];

              tableRows.forEach((row, index) => {
                speedArray.push(String(row.speed));
                directionArray.push(String(row.direction));
                endgunArray.push(String(row.endgun));
                angleArray.push(String(row.end_angle));
              });

              // O firmaware requer que o envio do DateTime seja realizado com os segundos zerados
              var startDateWithNoSeconds = startDate.second(0);
              createPivotActionVRI({
                pivotID: pivot.id,
                farmID: farmID,
                start_angle: parseInt(angleStart),
                direction_array: directionArray,
                speed_array: speedArray,
                angle_array: angleArray,
                endgun_array: endgunArray,
                operation_mode: parseInt(operationMode),
                start:
                  startMode === "now"
                    ? null
                    : startDateWithNoSeconds.format("YYYY-MM-DDTHH:mm:ss[Z]"),
              });
            }}
          >
            {i18n("BY_ANGLE_IRRIGATION_BUTTON_SEND")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default NewByAngleIrrigation;

import "./CentralItem.scss";
import { Slide } from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import DesktopZone from "../../../../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../../../../components/Zones/MobileZone";
import useSocketIO from "../../../../../../../hooks/tools/useSocketIO";
import { Farm } from "../../../../../../../redux/farms/types";
import moment from "moment";

export interface SelectedFarmParams {
  farm: string;
}

interface Props {
  farm: Farm;
}

function CentralItem(props: Props) {
  const [isConnected, socket] = useSocketIO();

  const [loading, setLoading] = useState(true);

  const farmID: string = useParams().farm;

  const navigate = useNavigate();

  return (
    <>
      <>
        <MobileZone>
          <Slide direction="down" in={true}>
            <div
              className="irpd-item"
              onClick={
                () => null
                // navigate(
                //   routes.DASHBOARD.SELECTED_IRPD.replace(
                //     ":farm",
                //     farmID
                //   ).replace(":irpd", String(props.irpd.id))
                // )
              }
            >
              <div className="pivot-item__row-left">
                {/* {props.irpd.image ? (
                  <img src={props.irpd.image + process.env.MAPS_KEY} />
                ) : (
                  <></>
                )} */}
                <div className="irpd-item__container">
                  <div className="irpd-item__content">
                    <div className="irpd-item__text-content">
                      <div className="pivot-item__title">
                        {props.farm.name}
                        {/* <div
                          className={"irpd-item__status"}
                          style={{
                            backgroundColor: latestStatus.color,
                          }}
                        >
                          <GlobalText content={latestStatus.text} />
                        </div> */}
                      </div>

                      <div className="pivot-item__angle" />
                      <div className="irpd-item__last-update">
                        {moment().format("DD MMM HH:mm")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="irpd-item__row-right">
                <div className="irpd-item__sweep-status">
                  {/* {runningSweep === 1 ? <CircularProgress /> : <></>} */}
                  {/* {runningSweep === 2 ? <Done /> : <></>} */}
                </div>
              </div>
            </div>
          </Slide>
        </MobileZone>

        <DesktopZone>
          <div
            className="irpd-item"
            onClick={
              () => null
              // navigate(
              //   routes.DASHBOARD.SELECTED_IRPD.replace(
              //     ":farm",
              //     farmID
              //   ).replace(":irpd", String(props.irpd.id))
              // )
            }
          >
            <div className="pivot-item__row-left">
              <div className="irpd-item__container">
                <div className="irpd-item__content">
                  <div className="irpd-item__text-content">
                    <div className="pivot-item__title">
                      {props.farm.name}
                      {/* <div
                          className={"irpd-item__status"}
                          style={{
                            backgroundColor: latestStatus.color,
                          }}
                        >
                          <GlobalText content={latestStatus.text} />
                        </div> */}
                    </div>

                    <div className="pivot-item__angle" />

                    <div className="irpd-item__last-update">
                      {moment().format("DD MMM HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="irpd-item__row-right">
              <div className="irpd-item__sweep-status">
                {/* {runningSweep === 1 ? <CircularProgress /> : <></>}
                  {runningSweep === 2 ? <Done /> : <></>} */}
              </div>
            </div>
          </div>
        </DesktopZone>
      </>
    </>
  );
}

export default CentralItem;

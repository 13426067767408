import React, { useContext, useState } from "react";
import moment from "moment";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import {
  Pivot,
  ControllerConfig,
  PIVOT_COMMUNICATION_TYPE,
} from "../../../../../../../../../redux/pivots/types";
import {
  getLanguage,
  getModeEndGun,
  getReadPressure,
} from "../../../../../../../../../utils/models/pivots";
import {
  Settings,
  Map,
  AccessTime,
  Speed,
  Power,
  PieChart,
  PanoramaFishEye,
  Loop,
} from "@material-ui/icons";

import { EditPivotV5Context } from "../../../../EditPivotFormV5/EditPivotV5Provider/EditPivotV5Provider";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../../../../../../../redux/modal/actions";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import {
  Button,
  CircularProgress,
  createMuiTheme,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";
import { useRecoilState } from "recoil";
import {
  centerMarkerSelector,
  initialMarkerSelector,
  isNorthReferenceState,
} from "../../../../../../../../../recoils/DraggableMapRecoil";
import { coreHTTPClient } from "../../../../../../../../../services/webclient";
import { Star, StarBorder } from "@material-ui/icons";
import { motion } from "framer-motion";
import FormInput from "../../../../../../../../../components/Form/FormInput/FormInput";
import { isMobile } from "../../../../../../../../../mobileConfig";
import PluviometerIcon from "../../../../EditPivotFormV5/components/PluviometerIcon";
import { updateOrInsertPivot } from "../../../../../../../../../redux/pivots/actions";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fca103",
      contrastText: "#fff",
    },
  },
});

interface Props {
  monitor?: any;
  controller?: any;
  hardware?: any;
  gps?: any;
  pump?: any;
  pivot: Pivot;
  pivotConfig: ControllerConfig;
  ctx: any;
  pivotID: number;
  farmID: number;
}

function ExpandedContentConfigV5(props: Props) {
  const {
    monitor,
    controller,
    hardware,
    gps,
    pump,
    pivot,
    pivotConfig,
    pivotID,
    farmID,
  } = props;
  const [pinned, setPinned] = useState<boolean>(props.pivotConfig.pinned);
  const [loading, setLoading] = useState(false);
  const [pinName, setPinName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();
  const ctx = useContext(EditPivotV5Context);
  let [centerLatLng, setCenterLatLng] = useRecoilState(centerMarkerSelector);
  let [initialLatLng, setInitialLatLng] = useRecoilState(initialMarkerSelector);
  let [northReference, setNorthReference] = useRecoilState(
    isNorthReferenceState
  );
  const {
    setName,
    setLanguage,
    setRadius,
    setFlow,
    setSpeed,
    setArea,
    setBrandModel,
    setOtherBrand,
    setPanelType,
    setCheckVoltage,
    setVoltageMin,
    setVoltageMax,
    setVoltageStableTime,
    setCenterLat,
    setCenterLng,
    setCenterLatLabel,
    setCenterLngLabel,
    setStartRefLat,
    setStartRefLng,
    setStartLatLabel,
    setStartLngLabel,
    setReferenceNorth,
    setPotency,
    setPowerDelay,
    setReadPressureBy,
    setPumpTimeout,
    setPumpPressDelay,
    setPumpPressSwitch,
    setSensorMinRange,
    setSensorMaxRange,
    setSensorScaleEnd,
    setInjectionPump,
    setPluviometerStopMode,
    setMMToStop,
    setPluviometerScale,
    setValueKwhPeak,
    setValueKwhOutOfPeak,
    setValueKwhReduced,
    setWeekday,
    setPauseTimeStatus1,
    setPauseTimeStart1,
    setPauseTimeEnd1,
    setPauseTimeStatus2,
    setPauseTimeStart2,
    setPauseTimeEnd2,
    setAngleEnd,
    setArrayAngleStart,
    setArrayAngleEnd,
    setReload,
    setEndgunMode,
    setEndgunArray,
    setAutoreversionMode,
    setAutoreversionTime,
  } = props.ctx || ctx;

  // O moment() atribui aos doze meses do ano um número que varia de 0 (janeiro) a 11 (dezembro).
  // Porém, os valores dos meses que vêm do back end variam de 1 (janeiro) a 12 (dezembro).
  // Para corrigir isto, devemos pegar o objeto clock que vem do back end e corrigir ele antes de passá-lo para o moment().
  // Basta diminuir 1 na chave "month".
  // O objeto clockAdjusted é então passado para o moment(), em vez do objeto clock original.
  const clockAdjusted = {
    day: pivotConfig.content.clock.day,
    hour: pivotConfig.content.clock.hour,
    minute: pivotConfig.content.clock.minute,
    month: pivotConfig.content.clock.month - 1,
    year: pivotConfig.content.clock.year,
  };

  function getPluviometerStopModeText(
    pluviEnable: number,
    stopMode: number
  ): string {
    if (pluviEnable == 0 && stopMode == 1) {
      return i18n("EDIT_PIVOT_V5_AUTOREVERSION_DISABLED"); // Desabilitado
    } else if (pluviEnable == 1 && stopMode == 1) {
      return i18n("EDIT_PLUVIOMETER_MODE_BY_VALUE"); // Por valor
    } else if (pluviEnable == 1 && stopMode == 2) {
      return i18n("EDIT_PLUVIOMETER_MODE_DECREMENT"); // Por decremento
    } else {
      return null;
    }
  }

  function getAutoreversionStopModeText(
    stopCommand: number,
    stopMode: number
  ): string {
    if (stopCommand == 0 && stopMode == 0) {
      return i18n("EDIT_PIVOT_V5_AUTOREVERSION_DISABLED");
    } else if (stopCommand == 1 && stopMode == 0) {
      return i18n("EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH");
    } else if (stopCommand == 1 && stopMode == 1) {
      return i18n("EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE");
    } else {
      return null;
    }
  }

  function getAutoreversionWaitTimeText(waitTime: number): string {
    switch (waitTime) {
      case 30:
        return i18n("EDIT_PIVOT_V5_AUTOREVERSION_30S");
      case 60:
        return i18n("EDIT_PIVOT_V5_AUTOREVERSION_1MIN");
      case 120:
        return i18n("EDIT_PIVOT_V5_AUTOREVERSION_2MIN");
      default:
        break;
    }
  }

  const pluviometerUpdate = { pluviometer: !pivot.pluviometer };

  async function _patchPivot(pivotObject) {
    await coreHTTPClient
      .patch(`v3/farms/${farmID}/pivots/${pivotID}/`, pivotObject)
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        setPluviometerStopMode(
          res.data.pluviometer && res.data.protocol === 5
            ? pivotConfig.content?.pluviometer_enable?.enable
              ? String(pivotConfig.content.pluviometer_stop_mode?.stop_mode)
              : "0"
            : undefined
        );
        setMMToStop(
          res.data.pluviometer && res.data.protocol === 5
            ? pivotConfig.content?.mm_to_stop?.value
            : undefined
        );
        setPluviometerScale(
          !pivotConfig.content?.pluviometer_scale?.mm
            ? 0.2
            : pivotConfig.content?.pluviometer_scale?.mm
        );
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
      });
  }

  function loadButtonHandler() {
    try {
      // GERAL --------------------------------------

      setName(pivotConfig.name_pivot_on_config);
      setLanguage(pivotConfig.content.language.language);
      setRadius(pivotConfig.content.pivot_parameters.radius_last);
      setFlow(pivotConfig.content.pivot_parameters.flow_rate);
      setSpeed(pivotConfig.content.pivot_parameters.speed);
      setArea(pivotConfig.content.pivot_parameters.irrigated_area);
      if (
        pivotConfig.brand_model === "Bauer" ||
        pivotConfig.brand_model === "Carborundum" ||
        pivotConfig.brand_model === "Fockink" ||
        pivotConfig.brand_model === "Irrigabras" ||
        pivotConfig.brand_model === "Krebs" ||
        pivotConfig.brand_model === "Lindsay" ||
        pivotConfig.brand_model === "Reinke" ||
        pivotConfig.brand_model === "Valley"
      ) {
        setBrandModel(pivotConfig.brand_model);
      } else {
        setBrandModel("Other");
        setOtherBrand(pivotConfig.brand_model);
      }
      setPanelType(pivotConfig.panel_type);
      if (pivotConfig.content.voltage_limit_enable.voltage_limit_enable == 0) {
        setCheckVoltage(false);
      } else {
        setCheckVoltage(true);
        setVoltageMin(
          pivotConfig.content.voltage_configurations.minimum_voltage
        );
        setVoltageMax(
          pivotConfig.content.voltage_configurations.maximum_voltage
        );
        setVoltageStableTime(
          pivotConfig.content.voltage_configurations.stable_time
        );
      }

      // LOCALIZAÇÃO --------------------------------------

      setCenterLatLng((ll: any) => ({
        ...ll,
        lat: pivotConfig.content.pivot_positions.latitude_center,
        lng: pivotConfig.content.pivot_positions.longitude_center,
      }));

      setInitialLatLng((ll: any) => ({
        ...ll,
        lat: pivotConfig.content.pivot_positions.latitude_reference,
        lng: pivotConfig.content.pivot_positions.longitude_reference,
      }));
      setNorthReference(
        pivotConfig.content.pivot_positions.north_reference == 0 ? false : true
      );
      setCenterLat(
        String(pivotConfig.content.pivot_positions.latitude_center).substring(
          0,
          10
        )
      );
      setCenterLng(
        String(pivotConfig.content.pivot_positions.longitude_center).substring(
          0,
          10
        )
      );
      setCenterLatLabel(
        String(pivotConfig.content.pivot_positions.latitude_center).substring(
          0,
          10
        )
      );
      setCenterLngLabel(
        String(pivotConfig.content.pivot_positions.longitude_center).substring(
          0,
          10
        )
      );
      setStartRefLat(
        String(
          pivotConfig.content.pivot_positions.latitude_reference
        ).substring(0, 10)
      );
      setStartRefLng(
        String(
          pivotConfig.content.pivot_positions.longitude_reference
        ).substring(0, 10)
      );
      setStartLatLabel(
        String(
          pivotConfig.content.pivot_positions.latitude_reference
        ).substring(0, 10)
      );
      setStartLngLabel(
        String(
          pivotConfig.content.pivot_positions.longitude_reference
        ).substring(0, 10)
      );
      setReferenceNorth(
        pivotConfig.content.pivot_positions.north_reference == 0 ? false : true
      );

      // BOMBA --------------------------------------

      setPotency(String(pivotConfig.potency));
      setPowerDelay(pivotConfig.content.power_delay.power_delay);

      setReadPressureBy(pivotConfig.content.pressure_config.read_pressure_by);

      if (pivotConfig.content.pressure_config.read_pressure_by == 1) {
        setPumpTimeout(pivotConfig.content.pressure_config.pump_time_out);
        setPumpPressDelay(pivotConfig.content.pressure_config.pump_press_delay);
        setPumpPressSwitch(
          pivotConfig.content.pressure_config.pump_press_switch
        );
      } else if (pivotConfig.content.pressure_config.read_pressure_by == 2) {
        setPumpTimeout(pivotConfig.content.pressure_config.pump_time_out);
        setPumpPressDelay(pivotConfig.content.pressure_config.pump_press_delay);
        setPumpPressSwitch(
          pivotConfig.content.pressure_config.pump_press_switch
        );

        setSensorMinRange(
          pivotConfig.content.pressure_config.press_sensor_min_range
        );
        setSensorMaxRange(
          pivotConfig.content.pressure_config.press_sensor_max_range
        );
        setSensorScaleEnd(pivotConfig.content.pressure_config.sensor_scale_end);
      }

      setInjectionPump(pivotConfig.injection_pump);

      // PLUVIÔMETRO --------------------------------------

      if (pivot.pluviometer == false) {
        _patchPivot(pluviometerUpdate);
      } else {
        setPluviometerStopMode(
          pivot.pluviometer && pivot.protocol === 5
            ? pivotConfig.content?.pluviometer_enable?.enable
              ? String(pivotConfig.content.pluviometer_stop_mode?.stop_mode)
              : "0"
            : undefined
        );
        setMMToStop(
          pivot.pluviometer && pivot.protocol === 5
            ? pivotConfig.content?.mm_to_stop?.value
            : undefined
        );
        setPluviometerScale(
          !pivotConfig.content?.pluviometer_scale?.mm
            ? 0.2
            : pivotConfig.content?.pluviometer_scale?.mm
        );
      }

      // HORÁRIO DE PICO --------------------------------------

      setValueKwhPeak(pivotConfig.kwh_peak);
      setValueKwhOutOfPeak(pivotConfig.kwh_out_of_peak);
      setValueKwhReduced(pivotConfig.kwh_reduced);
      setWeekday({
        mon: pivotConfig.content.pause_time.enable_monday,
        tue: pivotConfig.content.pause_time.enable_tuesday,
        wed: pivotConfig.content.pause_time.enable_wednesday,
        thu: pivotConfig.content.pause_time.enable_thursday,
        fri: pivotConfig.content.pause_time.enable_friday,
        sat: pivotConfig.content.pause_time.enable_saturday,
        sun: pivotConfig.content.pause_time.enable_sunday,
      });

      const pivotPauseTimeStatus1 =
        pivotConfig.content?.pause_time_command?.pause_time_command === 0
          ? false
          : true;

      const pivotPauseTimeStart1 = moment().set({
        hour: pivotConfig.content.pause_time.start_pause_time_hour_1,
        minute: pivotConfig.content.pause_time.start_pause_time_minute_1,
        second: 0,
        millisecond: 0,
      });

      const pivotPauseTimeEnd1 = moment().set({
        hour: pivotConfig.content.pause_time.end_pause_time_hour_1,
        minute: pivotConfig.content.pause_time.end_pause_time_minute_1,
        second: 0,
        millisecond: 0,
      });

      if (pivotPauseTimeStatus1 === true) {
        setPauseTimeStart1(pivotPauseTimeStart1);
        setPauseTimeEnd1(pivotPauseTimeEnd1);
        setPauseTimeStatus1(pivotPauseTimeStatus1);
      } else {
        setPauseTimeStatus1(pivotPauseTimeStatus1);
      }

      const pivotPauseTimeStatus2 =
        pivotConfig.content?.pause_time_command?.pause_time_command === 0 ||
        (pivotConfig.content?.pause_time.start_pause_time_hour_2 === 0 &&
          pivotConfig.content?.pause_time.start_pause_time_minute_2 === 0 &&
          pivotConfig.content?.pause_time.end_pause_time_hour_2 === 0 &&
          pivotConfig.content?.pause_time.end_pause_time_minute_2 === 0)
          ? false
          : true;

      const pivotPauseTimeStart2 = moment().set({
        hour: pivotConfig.content.pause_time.start_pause_time_hour_2,
        minute: pivotConfig.content.pause_time.start_pause_time_minute_2,
        second: 0,
        millisecond: 0,
      });

      const pivotPauseTimeEnd2 = moment().set({
        hour: pivotConfig.content.pause_time.end_pause_time_hour_2,
        minute: pivotConfig.content.pause_time.end_pause_time_minute_2,
        second: 0,
        millisecond: 0,
      });

      if (pivotPauseTimeStatus2 === true) {
        setPauseTimeStart2(pivotPauseTimeStart2);
        setPauseTimeEnd2(pivotPauseTimeEnd2);
        setPauseTimeStatus2(pivotPauseTimeStatus2);
      } else {
        setPauseTimeStatus2(pivotPauseTimeStatus2);
      }

      // SEGMENTOS E PLANTIO --------------------------------------

      // setReload() é o responsável por redesenhar os ângulos
      // no componente de segmentos da aba SEGMENTOS E PLANTIO
      setReload((prevState) => !prevState);
      setAngleEnd(pivotConfig.content.sector?.end_angle);
      setArrayAngleEnd(
        pivotConfig.content.segments?.map((value: any, index: number) => {
          return {
            angle: String(value.angle_end),
            name: pivotConfig.segments_crop[index]?.name
              ? pivotConfig.segments_crop[index].name
              : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${index + 1}`,
            segment_type: pivotConfig.segments_crop[index]?.segment_type
              ? pivotConfig.segments_crop[index].segment_type
              : null,
            crop_plant_date: pivotConfig.segments_crop[index]?.crop_plant_date
              ? pivotConfig.segments_crop[index].crop_plant_date
              : null,
            crop_harvest_date: pivotConfig.segments_crop[index]
              ?.crop_harvest_date
              ? pivotConfig.segments_crop[index].crop_harvest_date
              : null,
          };
        })
      );
      setArrayAngleStart(
        pivotConfig.content.segments?.map((value: any) =>
          String(value.angle_start)
        )
      );

      // CANHÃO FINAL --------------------------------------

      setEndgunMode(pivotConfig.content.endgun_mode.endgun_mode);
      setEndgunArray(
        pivotConfig.content.endgun_angles?.length
          ? pivotConfig.content.endgun_angles
          : [{ start_angle: 0, end_angle: 0, number_editing: 0 }]
      );

      // AUTOREVERSÃO --------------------------------------

      setAutoreversionMode(
        pivotConfig.content.autoreversion_command?.command == 0
          ? -1
          : pivotConfig.content.autoreversion_configurations?.mode
      );
      setAutoreversionTime(
        pivotConfig.content.autoreversion_configurations?.time
      );

      notify("EDIT_PIVOT_LOAD_SUCCESS", "success", 3000);
      dispatch(hideModal());
    } catch (err) {
      notify("EDIT_PIVOT_LOAD_ERROR", "error");
    }
  }

  return (
    <div className="expanded-content__general">
      {/* BOTÃO FAVORITOS (ESTRELA) -------------------------------------------COMEÇO */}

      <div
        style={{
          width: "100%",
          height: "90px",
          margin: "0 0 -20px 5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <ThemeProvider theme={theme}>
          <IconButton
            aria-label="delete"
            onClick={() => {
              if (!pinned) {
                setShowNameInput(!showNameInput);
                return;
              }
              coreHTTPClient.patch(
                `v3/farms/${farmID}/pivots/${pivotID}/config/${props.pivotConfig.id}/`,
                { pinned: false }
              );
              setPinned(false);
              notify("HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY", "success");
            }}
          >
            {pinned ? <Star color="primary" /> : <StarBorder color="inherit" />}
          </IconButton>
        </ThemeProvider>

        <motion.div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          variants={{
            hidden: { width: 0, opacity: 0, x: "20px" },
            show: {
              width: "fit-content",
              opacity: 1,
              x: "0px",
            },
          }}
          initial="hidden"
          animate={showNameInput ? "show" : "hidden"}
        >
          <div style={{ width: "200px", marginBottom: "25px" }}>
            <FormInput
              id="pin-name"
              label={"SIGN_UP_NAME"}
              state={[pinName, setPinName]}
              disabled={!showNameInput}
            />
          </div>

          <Button
            variant="contained"
            style={{ transform: "scale(0.8)" }}
            // O botão está apenas invisível, mas precisa ser desativado
            disabled={!showNameInput || loading || !pinName}
            onClick={() => {
              (async () => {
                setLoading(true);
                try {
                  await coreHTTPClient.patch(
                    `v3/farms/${farmID}/pivots/${pivotID}/config/${props.pivotConfig.id}/`,
                    { pinned: true, name: pinName }
                  );
                  notify(
                    "HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY",
                    "success"
                  );
                  setShowNameInput(false);
                  setPinned(true);
                } catch {
                  notify("HISTORIC_CONFIG_PIVOT_PINNED_ERROR", "error");
                } finally {
                  setLoading(false);
                }
              })();
            }}
          >
            {loading ? <CircularProgress size={25} color="inherit" /> : "OK"}
          </Button>
        </motion.div>
      </div>

      {/* BOTÃO FAVORITOS (ESTRELA) -------------------------------------------FIM */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      ></div>

      {isMobile() ? (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            {pivot.automation_type == 2 ? (
              <div
                style={{
                  display: "flex",
                }}
              >
                {monitor}
                {i18n("HISTORIC_CONFIG_INF_MONITOR").toUpperCase()}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                }}
              >
                {controller}
                {i18n("HISTORIC_CONFIG_INF_CONTROLLER").toUpperCase()}
              </div>
            )}

            {pivot.automation_type == 2 ? null : (
              <div
                style={{
                  display: "flex",
                }}
              >
                {hardware}
                {i18n("HARDWARE").toUpperCase()}
              </div>
            )}

            {pivot.communication_type ===
            PIVOT_COMMUNICATION_TYPE.TYPE_4G ? null : (
              <div
                style={{
                  display: "flex",
                }}
              >
                {gps}
                {i18n("HISTORIC_CONFIG_INF_GPS").toUpperCase()}
              </div>
            )}

            {pivot.automation_type == 2 ||
            pivot.communication_type ===
              PIVOT_COMMUNICATION_TYPE.TYPE_4G ? null : (
              <div
                style={{
                  display: "flex",
                }}
              >
                {pump}
                {i18n("HISTORIC_CONFIG_INF_PUMP").toUpperCase()}
              </div>
            )}
          </div>
        </div>
      ) : null}

      {/* GERAL ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Settings
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_GENERAL").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("PIVOT_CONFIG_V5_FIELD_NAME")}:{" "}
            <b>{pivotConfig?.name_pivot_on_config}</b>
          </span>

          {pivot.automation_type == 0 ? (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_LANGUAGE")}:{" "}
              <b>{getLanguage(pivotConfig?.content?.language?.language)}</b>
            </span>
          ) : null}

          {pivot.automation_type == 2 ? (
            <span>
              {i18n("LPM_CONFIG_FIELD_LENGTH")}:{" "}
              <b>
                {pivotConfig.content.pivot_parameters.radius_last} {"m"}
              </b>
            </span>
          ) : (
            <span>
              {i18n("PIVOT_CONFIG_FIELD_RADIUS")}:{" "}
              <b>
                {pivotConfig.content.pivot_parameters.radius_last} {"m"}
              </b>
            </span>
          )}

          <span>
            {i18n("PIVOT_CONFIG_V5_FIELD_FLOW")}:{" "}
            <b>
              {pivotConfig.content.pivot_parameters.flow_rate} {"m³/h"}
            </b>
          </span>

          {pivot.automation_type == 2 ? (
            <span>
              {i18n("LPM_CONFIG_V5_FIELD_SPEED")}:{" "}
              <b>
                {pivotConfig.content.pivot_parameters.speed} {"m/h"}
              </b>
            </span>
          ) : (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_SPEED")}:{" "}
              <b>
                {pivotConfig.content.pivot_parameters.speed} {"m/h"}
              </b>
            </span>
          )}

          <span>
            {i18n("PIVOT_CONFIG_V5_FIELD_AREA")}:{" "}
            <b>
              {pivotConfig.content.pivot_parameters.irrigated_area} {"ha"}
            </b>
          </span>

          <span>
            {i18n("PIVOT_CONFIG_V5_BRAND_MODEL")}:{" "}
            <b>{pivotConfig.brand_model}</b>
          </span>

          {pivotConfig.panel_type == "1" ? (
            <span>
              {i18n("CREATE_DEVICE_MODAL_PANEL_TYPE")}: <b>Nexus</b>
            </span>
          ) : pivotConfig.panel_type == "2" ? (
            <span>
              {i18n("CREATE_DEVICE_MODAL_PANEL_TYPE")}: <b>SmartConnect</b>
            </span>
          ) : (
            <span>
              {i18n("CREATE_DEVICE_MODAL_PANEL_TYPE")}: <b>-</b>
            </span>
          )}

          {pivot.automation_type == 0 ? (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK")}: {""}
              <b>
                {pivotConfig.content.voltage_limit_enable
                  .voltage_limit_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>
          ) : null}

          {pivot.automation_type == 0 &&
          pivotConfig.content.voltage_limit_enable.voltage_limit_enable == 1 ? (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN")}:{" "}
              <b>
                {pivotConfig.content.voltage_configurations.minimum_voltage}{" "}
                {"V"}
              </b>
            </span>
          ) : null}

          {pivot.automation_type == 0 &&
          pivotConfig.content.voltage_limit_enable.voltage_limit_enable == 1 ? (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX")}:{" "}
              <b>
                {pivotConfig.content.voltage_configurations.maximum_voltage}{" "}
                {"V"}
              </b>
            </span>
          ) : null}

          {pivot.automation_type == 0 &&
          pivotConfig.content.voltage_limit_enable.voltage_limit_enable == 1 ? (
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_LIMIT_TIME")}:{" "}
              <b>
                {pivotConfig.content.voltage_configurations.stable_time} {"min"}
              </b>
            </span>
          ) : null}
        </div>
      </div>

      {/* LOCALIZAÇÃO ----------------------------------------------------- */}

      {pivot.automation_type == 2 ? null : (
        <>
          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Map
                style={{
                  marginRight: "5px",
                }}
              />
              <>{i18n("EDIT_PIVOT_LABEL_LOCATION").toUpperCase()}</>
            </div>
          </div>

          <div className="expanded-content__background">
            <div className="expanded-content__group">
              <span>
                {i18n("PIVOT_CONFIG_V5_FIELD_GP_CENTER")}:
                <b> {pivotConfig.content.pivot_positions.latitude_center}</b>,
                <b>{pivotConfig.content.pivot_positions.longitude_center}</b>
              </span>

              <span>
                {i18n("PIVOT_CONFIG_V5_FIELD_GP_REFERENCE")}:
                <b> {pivotConfig.content.pivot_positions.latitude_reference}</b>
                ,
                <b>{pivotConfig.content.pivot_positions.longitude_reference}</b>
              </span>

              <span>
                {i18n("EDIT_PIVOT_V5_REFERENCE_NORTH")}: {""}
                <b>
                  {pivotConfig.content.pivot_positions.north_reference == 0
                    ? i18n("DEACTIVATED")
                    : i18n("ACTIVATED")}
                </b>
              </span>
            </div>
          </div>

          {/* HORÁRIO ----------------------------------------------------- */}

          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccessTime
                style={{
                  marginRight: "5px",
                }}
              />
              <>{i18n("EDIT_PIVOT_LABEL_CLOCK").toUpperCase()}</>
            </div>
          </div>

          <div className="expanded-content__background">
            <div className="expanded-content__group">
              <span>
                {i18n("PIVOT_CONFIG_V5_FIELD_CLOCK_DATE")}:{" "}
                <b>{moment(clockAdjusted).format("DD MMM YY - HH:mm")}</b>
              </span>
            </div>
          </div>
        </>
      )}

      {/* BOMBA ----------------------------------------------------- */}

      {pivot.automation_type == 0 ? (
        <div
          style={{
            margin: "30px 0px 0px 20px",
            fontSize: "x-large",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Speed
              style={{
                marginRight: "5px",
              }}
            />
            <>{i18n("EDIT_PIVOT_LABEL_PUMP").toUpperCase()}</>
          </div>
        </div>
      ) : null}

      {pivot.automation_type == 0 ? (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_POTENCY")}:{" "}
              <b>{pivotConfig.potency} kW</b>
            </span>

            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_POWER_DELAY")}:{" "}
              <b>
                {pivotConfig.content.power_delay.power_delay} {"min"}
              </b>
            </span>

            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE")}:{" "}
              <b>
                {getReadPressure(
                  pivotConfig.content.pressure_config.read_pressure_by
                )}
              </b>
            </span>

            {pivotConfig.content.pressure_config.read_pressure_by ==
            0 ? null : pivotConfig.content.pressure_config.read_pressure_by ==
              1 ? (
              <>
                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_time_out} {"min"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_press_delay} {"s"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_press_switch}{" "}
                    {"min"}
                  </b>
                </span>
              </>
            ) : (
              <>
                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_time_out} {"min"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_press_delay} {"s"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.pump_press_switch}{" "}
                    {"min"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.press_sensor_min_range}{" "}
                    {"bar"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.press_sensor_max_range}{" "}
                    {"bar"}
                  </b>
                </span>

                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END")}:{" "}
                  <b>
                    {pivotConfig.content.pressure_config.sensor_scale_end}{" "}
                    {"bar"}
                  </b>
                </span>
              </>
            )}

            <span>
              {i18n("PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK")}: {""}
              <b>
                {pivotConfig.injection_pump === false
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>
          </div>
        </div>
      ) : null}

      {/* PLUVIÔMETRO ----------------------------------------------------- */}

      {pivot.automation_type == 2 ? null : (
        <>
          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PluviometerIcon color={undefined} size={27} />
              <div style={{ marginLeft: "5px" }}>
                {i18n("EDIT_PIVOT_LABEL_PLUVIOMETER").toUpperCase()}
              </div>
            </div>
          </div>

          {pivot.automation_type == 2 ? null : pivotConfig.content
              .pluviometer_enable?.enable ? (
            <>
              <div className="expanded-content__background">
                <div className="expanded-content__group">
                  <span>
                    {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE")}:{" "}
                    <b>
                      {getPluviometerStopModeText(
                        pivotConfig.content.pluviometer_enable?.enable,
                        pivotConfig.content.pluviometer_stop_mode?.stop_mode
                      )}
                    </b>
                  </span>

                  {pivotConfig.content.pluviometer_enable.enable == 1 &&
                  pivotConfig.content.pluviometer_stop_mode.stop_mode == 1 ? (
                    <span>
                      {i18n("EDIT_PLUVIOMETER_MM_TO_STOP")}:{" "}
                      <b>
                        {pivotConfig.content.mm_to_stop?.value} {"mm"}
                      </b>
                    </span>
                  ) : null}

                  <span>
                    {i18n("PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END")}:{" "}
                    <b>
                      {pivotConfig.content.pluviometer_scale?.mm} {"mm"}
                    </b>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div className="expanded-content__background">
              <div className="expanded-content__group">
                {i18n("PLUVIOMETER_WITHOUT_CONFIG")}
              </div>
            </div>
          )}
        </>
      )}

      {/* HORÁRIO DE PICO ----------------------------------------------------- */}

      {pivot.automation_type == 2 ? null : (
        <>
          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Power
                style={{
                  marginRight: "5px",
                }}
              />
              <>{i18n("EDIT_PIVOT_LABEL_PAUSE_TIME").toUpperCase()}</>
            </div>
          </div>

          {pivotConfig.content.pause_time_command.pause_time_command == 0 ? (
            <div className="expanded-content__background">
              <div className="expanded-content__group">
                {i18n("PAUSE_TIME_WITHOUT_CONFIG")}
              </div>
            </div>
          ) : (
            <div className="expanded-content__background">
              <div className="expanded-content__group">
                <span>
                  {i18n("BASIC_INFO_BOX_PONTA")}: {""}
                  <b>
                    {i18n("CURRENCY")} {pivotConfig.kwh_peak}
                  </b>
                </span>

                <span>
                  {i18n("BASIC_INFO_BOX_FORA_DE_PONTA")}: {""}
                  <b>
                    {i18n("CURRENCY")} {pivotConfig.kwh_out_of_peak}
                  </b>
                </span>

                <span>
                  {i18n("BASIC_INFO_BOX_REDUZIDO")}: {""}
                  <b>
                    {i18n("CURRENCY")} {pivotConfig.kwh_reduced}
                  </b>
                </span>

                <span
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_SUNDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_sunday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_MONDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_monday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_TUESDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_tuesday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_wednesday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_THURSDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_thursday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_FRIDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_friday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <span>
                  {i18n("EDIT_PIVOT_V5_WEEKDAY_SATURDAY")}: {""}
                  <b>
                    {pivotConfig.content.pause_time.enable_saturday == 0
                      ? i18n("DEACTIVATED")
                      : i18n("ACTIVATED")}
                  </b>
                </span>

                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {"1. "}

                  <span>
                    {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START")}:{" "}
                    <b>
                      {moment()
                        .set({
                          hour:
                            pivotConfig.content.pause_time
                              .start_pause_time_hour_1,
                          minute:
                            pivotConfig.content.pause_time
                              .start_pause_time_minute_1,
                          second: 0,
                          millisecond: 0,
                        })
                        .format("HH:mm")}
                    </b>
                  </span>
                </div>

                <div>
                  <span
                    style={{
                      marginLeft: "18px",
                    }}
                  >
                    {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END")}:{" "}
                    <b>
                      {moment()
                        .set({
                          hour:
                            pivotConfig.content.pause_time
                              .end_pause_time_hour_1,
                          minute:
                            pivotConfig.content.pause_time
                              .end_pause_time_minute_1,
                          second: 0,
                          millisecond: 0,
                        })
                        .format("HH:mm")}
                    </b>
                  </span>
                </div>

                {(pivotConfig.content?.pause_time.start_pause_time_hour_2 ===
                  0 &&
                  pivotConfig.content?.pause_time.start_pause_time_minute_2 ===
                    0 &&
                  pivotConfig.content?.pause_time.end_pause_time_hour_2 === 0 &&
                  pivotConfig.content?.pause_time.end_pause_time_minute_2 ===
                    0) ||
                (pivotConfig.content?.pause_time.start_pause_time_hour_1 ===
                  0 &&
                  pivotConfig.content?.pause_time.start_pause_time_minute_1 ===
                    0 &&
                  pivotConfig.content?.pause_time.end_pause_time_hour_1 === 0 &&
                  pivotConfig.content?.pause_time.end_pause_time_minute_1 ===
                    0) ||
                pivotConfig.content?.pause_time_command?.pause_time_command ===
                  false ? null : (
                  <>
                    <div>
                      {"2. "}
                      <span>
                        {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START")}:{" "}
                        <b>
                          {moment()
                            .set({
                              hour:
                                pivotConfig.content.pause_time
                                  .start_pause_time_hour_2,
                              minute:
                                pivotConfig.content.pause_time
                                  .start_pause_time_minute_2,
                              second: 0,
                              millisecond: 0,
                            })
                            .format("HH:mm")}
                        </b>
                      </span>
                    </div>

                    <div>
                      <span
                        style={{
                          marginLeft: "18px",
                        }}
                      >
                        {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END")}:{" "}
                        <b>
                          {moment()
                            .set({
                              hour:
                                pivotConfig.content.pause_time
                                  .end_pause_time_hour_2,
                              minute:
                                pivotConfig.content.pause_time
                                  .end_pause_time_minute_2,
                              second: 0,
                              millisecond: 0,
                            })
                            .format("HH:mm")}
                        </b>
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {/* SEGMENTOS E PLANTIO ----------------------------------------------------- */}

      {pivot.automation_type == 2 ? null : (
        <>
          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PieChart
                style={{
                  marginRight: "5px",
                }}
              />
              <>{i18n("EDIT_PIVOT_LABEL_PIVOT_SEGMENTS").toUpperCase()}</>
            </div>
          </div>

          <div className="expanded-content__background">
            <div className="expanded-content__group">
              <span
                style={{
                  marginBottom: "10px",
                }}
              >
                {i18n("WORKING_ANGLE")}:{" "}
                <b>
                  {pivotConfig.content.sector.end_angle} {"°"}
                </b>
              </span>

              {pivotConfig.content.segments?.map((elem, index) => {
                return (
                  <div className="expanded-content__group-no-padding">
                    <span>
                      {index + 1}. {i18n("SEGMENT_NAME")}:{" "}
                      <b>{pivotConfig.segments_crop[index]?.name}</b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("SEGMENT_TYPE")}:{" "}
                      <b>{pivotConfig.segments_crop[index]?.segment_type}</b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("BY_ANGLE_IRRIGATION_FIELD_ANGLE_START")}:{" "}
                      <b>
                        {elem.angle_start} {"°"}
                      </b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END")}:{" "}
                      <b>
                        {elem.angle_end} {"°"}
                      </b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("SEGMENTS_PLANT_DATE")}:{" "}
                      <b>
                        {pivotConfig.segments_crop[index]?.crop_plant_date
                          ? moment(
                              pivotConfig.segments_crop[index].crop_plant_date
                            ).format("DD MMM YY - HH:mm")
                          : null}
                      </b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("SEGMENTS_HARVEST_DATE")}:{" "}
                      <b>
                        {pivotConfig.segments_crop[index]?.crop_harvest_date
                          ? moment(
                              pivotConfig.segments_crop[index].crop_harvest_date
                            ).format("DD MMM YY - HH:mm")
                          : null}
                      </b>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* CANHÃO FINAL ----------------------------------------------------- */}

      {pivot.automation_type == 0 ? (
        <div
          style={{
            margin: "30px 0px 0px 20px",
            fontSize: "x-large",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PanoramaFishEye
              style={{
                marginRight: "5px",
              }}
            />
            <>{i18n("EDIT_PIVOT_LABEL_END_GUN").toUpperCase()}</>
          </div>
        </div>
      ) : null}

      {pivot.automation_type == 0 ? (
        <div className="expanded-content__background">
          {pivotConfig.content.endgun_mode.endgun_mode === 2 ? (
            <div className="expanded-content__group">
              <span
                style={{
                  marginBottom: "10px",
                }}
              >
                {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE")}:{" "}
                <b>
                  {getModeEndGun(pivotConfig.content.endgun_mode.endgun_mode)}
                </b>
              </span>

              {pivotConfig.content.endgun_angles.map((elem, index) => {
                return (
                  <div className="expanded-content__group-no-padding">
                    <span>
                      {index + 1}. {i18n("ENDGUN_START_ANGLE")}:{" "}
                      <b>
                        {elem.start_angle} {"°"}
                      </b>
                    </span>

                    <span
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      {i18n("ENDGUN_FINAL_ANGLE")}:{" "}
                      <b>
                        {elem.end_angle} {"°"}
                      </b>
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="expanded-content__group">
                <span>
                  {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE")}:{" "}
                  <b>
                    {getModeEndGun(pivotConfig.content.endgun_mode.endgun_mode)}
                  </b>
                </span>
              </div>
            </>
          )}
        </div>
      ) : null}

      {/* AUTOREVERSÃO ----------------------------------------------------- */}

      {pivot.automation_type == 2 ? null : (
        <>
          <div
            style={{
              margin: "30px 0px 0px 20px",
              fontSize: "x-large",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Loop
                style={{
                  marginRight: "5px",
                }}
              />
              <>{i18n("EDIT_PIVOT_V5_AUTOREVERSION").toUpperCase()}</>
            </div>
          </div>

          <div className="expanded-content__background">
            <div className="expanded-content__group">
              <span>
                {i18n("EDIT_PIVOT_V5_AUTOREVERSION_MODE")}:{" "}
                <b>
                  {getAutoreversionStopModeText(
                    pivotConfig.content.autoreversion_command?.command,
                    pivotConfig.content.autoreversion_configurations?.mode
                  )}
                </b>
              </span>

              {pivotConfig.content.autoreversion_command?.command == 0 &&
              pivotConfig.content.autoreversion_configurations?.mode ==
                0 ? null : (
                <span>
                  {i18n("EDIT_PIVOT_V5_AUTOREVERSION_TIME")}:{" "}
                  <b>
                    {getAutoreversionWaitTimeText(
                      pivotConfig.content.autoreversion_configurations?.time
                    )}
                  </b>
                </span>
              )}
            </div>
          </div>
        </>
      )}

      <Button color="primary" variant="contained" onClick={loadButtonHandler}>
        <>{i18n("EDIT_PIVOT_LOAD_BUTTON")}</>
      </Button>
    </div>
  );
}

export default ExpandedContentConfigV5;


import { createSelector } from 'reselect';
import { ApplicationState } from '../index';
import { Farm } from './types';


export const getFarm = createSelector(
  (state : ApplicationState, farmID: number) => ({ farmList: state.farms.list, id: farmID }),
  ({ farmList, id }): Farm | undefined => farmList.find((farm) => farm.id === id),
);

export interface FarmBauerPivotObj {
  id: number;
  name: string;
  hardware_id: string;
  connected: boolean;
  center_latitude: number;
  center_longitude: number;
  farm: number;
  latest: ResumeBauerInfoFarm;
  metadata: {
    last_communication: Date;
  };
}

export interface ResumeBauerInfoFarm {
  status?: number;
  last_tower_radius?: number;
  zero_position?: number;
  direction?: number;
  sector_begin?: number;
  sector_end?: number;
  machine_angle?: number;
  uuid?: string;
  created_at?: Date;
  detail?: string;
  id?: number;
}

export interface BauerPivotObj {
  id: number;
  name: string;
  connected: boolean;
  hardware_id: string;
  center_latitude: number;
  center_longitude: number;
  farm: number;
  latest: BauerPivot;
  metadata: {
    last_communication: Date;
  };
}

export interface BauerPivot {
  id: number;
  machine_address: string;
  command: string;
  version: number;
  message_type: number;
  status: number;
  irrigation_radius: number;
  ping_time: number;
  autoreverse: boolean;
  zero_position: number;
  end_gun_mode: number;
  direction: number;
  current_rounds: number;
  maximum_rounds: number;
  sector_begin: number;
  sector_end: number;
  parking_on_off: boolean;
  intermediate_on_off: boolean;
  flow: number;
  current_rate: number;
  operating_hours_wet: number;
  operating_hours_total: number;
  water_consumption: number;
  water_flow: number;
  parking_position: number;
  intermediate_position: number;
  machine_angle: number;
  segment_1_begin: number;
  segment_1_end: number;
  segment_2_begin: number;
  segment_2_end: number;
  segment_3_begin: number;
  segment_3_end: number;
  segment_4_begin: number;
  segment_4_end: number;
  segment_5_begin: number;
  segment_5_end: number;
  segment_6_begin: number;
  segment_6_end: number;
  segment_1_fwd: number;
  segment_1_rev: number;
  segment_2_fwd: number;
  segment_2_rev: number;
  segment_3_fwd: number;
  segment_3_rev: number;
  segment_4_fwd: number;
  segment_4_rev: number;
  segment_5_fwd: number;
  segment_5_rev: number;
  segment_6_fwd: number;
  segment_6_rev: number;
  end_gun_1_segment_1_begin: number;
  end_gun_1_segment_1_end: number;
  end_gun_1_segment_2_begin: number;
  end_gun_1_segment_2_end: number;
  end_gun_1_segment_3_begin: number;
  end_gun_1_segment_3_end: number;
  end_gun_1_segment_4_begin: number;
  end_gun_1_segment_4_end: number;
  end_gun_1_segment_5_begin: number;
  end_gun_1_segment_5_end: number;
  end_gun_1_segment_6_begin: number;
  end_gun_1_segment_6_end: number;
  end_gun_2_segment_1_begin: number;
  end_gun_2_segment_1_end: number;
  end_gun_2_segment_2_begin: number;
  end_gun_2_segment_2_end: number;
  end_gun_2_segment_3_begin: number;
  end_gun_2_segment_3_end: number;
  end_gun_2_segment_4_begin: number;
  end_gun_2_segment_4_end: number;
  end_gun_2_segment_5_begin: number;
  end_gun_2_segment_5_end: number;
  end_gun_2_segment_6_begin: number;
  end_gun_2_segment_6_end: number;
  auxiliary_1_segment_1_begin: number;
  auxiliary_1_segment_1_end: number;
  auxiliary_1_segment_2_begin: number;
  auxiliary_1_segment_2_end: number;
  auxiliary_1_segment_3_begin: number;
  auxiliary_1_segment_3_end: number;
  auxiliary_1_segment_4_begin: number;
  auxiliary_1_segment_4_end: number;
  auxiliary_1_segment_5_begin: number;
  auxiliary_1_segment_5_end: number;
  auxiliary_1_segment_6_begin: number;
  auxiliary_1_segment_6_end: number;
  auxiliary_2_segment_1_begin: number;
  auxiliary_2_segment_1_end: number;
  auxiliary_2_segment_2_begin: number;
  auxiliary_2_segment_2_end: number;
  auxiliary_2_segment_3_begin: number;
  auxiliary_2_segment_3_end: number;
  auxiliary_2_segment_4_begin: number;
  auxiliary_2_segment_4_end: number;
  auxiliary_2_segment_5_begin: number;
  auxiliary_2_segment_5_end: number;
  auxiliary_2_segment_6_begin: number;
  auxiliary_2_segment_6_end: number;
  last_tower_radius: number;
  maximum_speed: number;
  time_period: string;
  corner_angle: string;
  irrigation_time: string;
  corner_angle_calculated: string;
  corner_tower_bearing: string;
  modem_signal_strength: string;
  current_pressure: string;
  gps_longitude: string;
  gps_latitude: string;
  gps_altitude: string;
  gps_satellites: string;
  hdop: string;
  pdop: string;
  gps_timestamp: string;
  gps_fix_indicator: string;
  correction_age: string;
  gps_fix_antenna_2: string;
  gps_fix_counter: string;
  dgps_counter: string;
  float_counter: string;
  rtk_counter: string;
  vri_test_mode: string;
  vri_test_function: string;
  vri_test_hardware_address: string;
  vri_test_start_output: string;
  vri_test_end_output: string;
  vri_test_type: string;
  active_vri_program: string;
  list_of_vri_programs: string;
  delete_file: string;
  corner_valve_percentage: string;
  corner_distance: string;
  corner_offset: string;
  corner_sprinkler_distance: string;
  corner_speed_adjustment_minimum: string;
  corner_speed_adjustment_maximum: string;
  corner_offset_north_south: string;
  corner_offset_east_west: string;
  corner_nozzle_file_active: string;
  corner_status: string;
  corner_gps_distance: string;
  corner_waypoint_distance: string;
  corner_spanpoti: string;
  corner_span_sollfwd: string;
  corner_span_safety_status: string;
  corner_neigung_safety_status: string;
  corner_end_pro_velocity: string;
  corner_corner_pro_velocity: string;
  can_inputs: string;
  corner_end_fu_velocity: string;
  corner_corner_fu_velocity: string;
  corner_end_emergency_count: string;
  corner_corner_emergency_count: string;
  corner_end_failure_count: string;
  corner_corner_failure_count: string;
  corner_drive_state: string;
  endtower_drive_state: string;
  endtower_fu_last_failure: string;
  corner_fu_last_failure: string;
  system_uptime: string;
  corner_speed: string;
  nozzle_mapping: string;
  voltages_phase_1: string;
  voltages_phase_2: string;
  voltages_phase_3: string;
  power: string;
  work: string;
  vri_cycle_base_times: string;
  machine_type: string;
  drive_length: string;
  linear_mode: string;
  maximum_drive_length: string;
  maximum_angle_deviation: string;
  linear_angle_90_fwd: string;
  linear_angle_90_rev: string;
  linear_angle_270_fwd: string;
  linear_angle_270_rev: string;
  operation_mode: string;
  uuid: string;
  created_at: Date;
  raw_message: string;
  delivery_status: number;
  username: string;
}

export interface HistoryBauerPivot {
  items: BauerPivot[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export const bauerMappedData = {
  "0": "Protocol Type",
  "1": "Message Length",
  "2": "Checksum",
  "3": "Version",
  "4": "Message Type",
  "5": "Machine Address",
  "6": "Command",
  "7": "Status",
  "8": "Irrigation Radius",
  "9": "Software Version",
  "10": "Ping Time",
  "11": "Autoreverse",
  "12": "Zero Position",
  "13": "End Gun Mode",
  "14": "Direction",
  "15": "Current Rounds",
  "16": "Maximum Rounds",
  "17": "Sector Begin",
  "18": "Sector End",
  "19": "Parking On/Off",
  "20": "Intermediate On/Off",
  "21": "Flow",
  "22": "Current Rate",
  "23": "Operating Hours Wet",
  "24": "Operating Hours Total",
  "25": "Water Consumption",
  "26": "Water Flow",
  "27": "Parking Position",
  "28": "Intermediate Position",
  "29": "Machine Angle",
  "30": "Segment 1 Begin",
  "31": "Segment 1 End",
  "32": "Segment 2 Begin",
  "33": "Segment 2 End",
  "34": "Segment 3 Begin",
  "35": "Segment 3 End",
  "36": "Segment 4 Begin",
  "37": "Segment 4 End",
  "38": "Segment 5 Begin",
  "39": "Segment 5 End",
  "40": "Segment 6 Begin",
  "41": "Segment 6 End",
  "42": "Segment 1 FWD",
  "43": "Segment 1 REV",
  "44": "Segment 2 FWD",
  "45": "Segment 2 REV",
  "46": "Segment 3 FWD",
  "47": "Segment 3 REV",
  "48": "Segment 4 FWD",
  "49": "Segment 4 REV",
  "50": "Segment 5 FWD",
  "51": "Segment 5 REV",
  "52": "Segment 6 FWD",
  "53": "Segment 6 REV",
  "54": "End Gun 1 Segment 1 Begin",
  "55": "End Gun 1 Segment 1 End",
  "56": "End Gun 1 Segment 2 Begin",
  "57": "End Gun 1 Segment 2 End",
  "58": "End Gun 1 Segment 3 Begin",
  "59": "End Gun 1 Segment 3 End",
  "60": "End Gun 1 Segment 4 Begin",
  "61": "End Gun 1 Segment 4 End",
  "62": "End Gun 1 Segment 5 Begin",
  "63": "End Gun 1 Segment 5 End",
  "64": "End Gun 1 Segment 6 Begin",
  "65": "End Gun 1 Segment 6 End",
  "66": "End Gun 2 Segment 1 Begin",
  "67": "End Gun 2 Segment 1 End",
  "68": "End Gun 2 Segment 2 Begin",
  "69": "End Gun 2 Segment 2 End",
  "70": "End Gun 2 Segment 3 Begin",
  "71": "End Gun 2 Segment 3 End",
  "72": "End Gun 2 Segment 4 Begin",
  "73": "End Gun 2 Segment 4 End",
  "74": "End Gun 2 Segment 5 Begin",
  "75": "End Gun 2 Segment 5 End",
  "76": "End Gun 2 Segment 6 Begin",
  "77": "End Gun 2 Segment 6 End",
  "78": "Auxiliary 1 Segment 1 Begin",
  "79": "Auxiliary 1 Segment 1 End",
  "80": "Auxiliary 1 Segment 2 Begin",
  "81": "Auxiliary 1 Segment 2 End",
  "82": "Auxiliary 1 Segment 3 Begin",
  "83": "Auxiliary 1 Segment 3 End",
  "84": "Auxiliary 1 Segment 4 Begin",
  "85": "Auxiliary 1 Segment 4 End",
  "86": "Auxiliary 1 Segment 5 Begin",
  "87": "Auxiliary 1 Segment 5 End",
  "88": "Auxiliary 1 Segment 6 Begin",
  "89": "Auxiliary 1 Segment 6 End",
  "90": "Auxiliary 2 Segment 1 Begin",
  "91": "Auxiliary 2 Segment 1 End",
  "92": "Auxiliary 2 Segment 2 Begin",
  "93": "Auxiliary 2 Segment 2 End",
  "94": "Auxiliary 2 Segment 3 Begin",
  "95": "Auxiliary 2 Segment 3 End",
  "96": "Auxiliary 2 Segment 4 Begin",
  "97": "Auxiliary 2 Segment 4 End",
  "98": "Auxiliary 2 Segment 5 Begin",
  "99": "Auxiliary 2 Segment 5 End",
  "100": "Auxiliary 2 Segment 6 Begin",
  "101": "Auxiliary 2 Segment 6 End",
  "114": "Last Tower Radius",
  "115": "Maximum Speed",
  "120": "Corner Angle",
  "121": "Irrigation Time",
  "122": "Corner Angle Calculated",
  "123": "Corner Tower Bearing",
  "140": "Modem Signal Strength",
  "141": "Current Pressure",
  "150": "GPS Longitude",
  "151": "GPS Latitude",
  "152": "GPS Altitude",
  "153": "GPS Satellites",
  "154": "HDOP",
  "155": "PDOP",
  "156": "GPS Timestamp",
  "157": "GPS Fix Indicator",
  "158": "Correction Age",
  "159": "GPS Fix Antenna 2",
  "160": "GPS Fix Counter",
  "161": "DGPS Counter",
  "162": "Float Counter",
  "163": "RTK Counter",
  "190": "VRI Test Mode",
  "191": "VRI Test Function",
  "192": "VRI Test Hardware Address",
  "193": "VRI Test Start-Output",
  "194": "VRI Test End-Output",
  "195": "VRI Test Type",
  "198": "Active VRI Program",
  "199": "List of VRI Programs",
  "200": "Activate VRI Program",
  "201": "Delete File",
  "229": "Corner Valve Percentage",
  "230": "Corner Distance",
  "231": "Corner Offset",
  "232": "Corner Sprinkler Distance",
  "233": "Corner Speed Adjustment Minimum",
  "234": "Corner Speed Adjustment Maximum",
  "235": "Corner Offset North/South",
  "236": "Corner Offset East/West",
  "237": "Corner Nozzle File active",
  "270": "Corner Status",
  "271": "Corner GPS distance",
  "272": "Corner Waypoint distance",
  "273": "Corner SpanPoti",
  "274": "Corner Span SollFwd",
  "275": "Corner Span Safety Status",
  "276": "Corner Neigung Safety Status",
  "277": "Corner End Pro Velocity",
  "278": "Corner Corner Pro Velocity",
  "279": "CAN Inputs",
  "280": "Corner End FU Velocity",
  "281": "Corner Corner FU Velocity",
  "282": "Corner End Emergency Count",
  "283": "Corner Corner Emergency Count",
  "284": "Corner End Failure Count",
  "285": "Corner Corner Failure Count",
  "286": "Corner Drive State",
  "287": "Endtower Drive State",
  "288": "Endtower FU Last Failure",
  "289": "Corner FU Last Failure",
  "300": "System Uptime",
  "301": "Corner Speed",
  "302": "Nozzle Mapping",
  "310": "Voltages Phase 1",
  "311": "Voltages Phase 2",
  "312": "Voltages Phase 3",
  "313": "Power",
  "314": "Work",
  "320": "VRI Cycle Base Times",
  "400": "Machine Type",
  "401": "Drive Length",
  "402": "Linear Mode",
  "403": "Maximum Drive Length",
  "404": "Maximum Angle Deviation",
  "405": "Linear Angle 90° FWD",
  "406": "Linear Angle 90° REV",
  "407": "Linear Angle 270° FWD",
  "408": "Linear Angle 270° REV",
  "409": "Operation Mode",
};

import { ModalState, ModalTypes } from "./types";
import { ModalActions } from "./actions";
import { NoneReactJSX } from "../../utils/types";

const INITIAL_STATE: ModalState = {
  active: false,
  data: {
    content: NoneReactJSX()
  }
};

function reducer(state = INITIAL_STATE, action: ModalActions) {
  const { type }: { type: string } = action;

  switch (type) {
    case ModalTypes.SHOW_MODAL:
      return { ...state, active: true, data: action.payload };

    case ModalTypes.HIDE_MODAL:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
}

export default reducer;

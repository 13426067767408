import { useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux";
import { getFarm as getFarmSelector } from "../../redux/farms/selector";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { Farm } from "../../redux/farms/types";
import { updateOrInsertFarm } from "../../redux/farms/actions";
import useNotify from "../tools/useNotify";
import { useSetRecoilState } from "recoil";
import { NavbarState } from "../../recoils/NavbarRecoil";
import routes from "../../routes/routes";

interface FarmActions {
  get: (farmID: string | number, simple?: boolean) => Promise<Farm | null>;
  patch: (patchObject: any) => Promise<void>;
  create: (patchObject: any) => Promise<Farm>;
}

const useFarm = (
  farmID: number,
  simple?: boolean
): [AsyncStatus, Farm | undefined, FarmActions] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  const [async, initLoading, finishLoading] = useAsyncStatus();

  const farm = useSelector((state: ApplicationState) =>
    getFarmSelector(state, farmID)
  );

  const setNavbar = useSetRecoilState(NavbarState);

  async function getFarm(
    farmID: string | number,
    simple?: boolean
  ): Promise<Farm | null> {
    initLoading();

    try {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/` + (simple ? "?simple=true" : "")
      );

      if (checkExists(["data", response, "__cascade"])) {
        dispatch(updateOrInsertFarm(response.data));
        setNavbar({
          title: response.data.name,
        });

        return response.data;
      }
    } catch (err) {
      if (err.response?.status === 404) {
        //navigate('/404');
        window.location.replace(routes.DASHBOARD.CODE404);
      }
    } finally {
      finishLoading();
    }

    return null;
  }

  async function patchFarm(patchObject: any) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.patch(
          `v3/farms/${farmID}/`,
          patchObject
        );

        if (checkExists(["data", response, "__cascade"])) {
          if (response.data.error) {
            if (response.data.error == "BASE_RADIO_ID_NOT_FOUND") {
              notify("EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND", "error");
            } else {
              notify("EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED", "error");
            }
            resolve(null);
          } else {
            notify("EDIT_FARM_SAVE_SUCCESS", "success");
            dispatch(updateOrInsertFarm(response.data));
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        return resolve(finishLoading());
      }
    });
  }

  async function createFarm(patchObject: any) {
    const createdFarm = await new Promise<Farm>(async (resolve) => {
      initLoading();
      try {
        const response = await coreHTTPClient.post(
          `v4/farm_with_billing/`,
          patchObject
        );

        if (checkExists(["data", response, "__cascade"])) {
          if (response.data) {
            dispatch(updateOrInsertFarm(response.data));
            notify("EDIT_FARM_SAVE_SUCCESS", "success");
            resolve(response.data as Farm);
          }
        }
      } catch (err) {
        if (err?.response?.data?.base) {
          if (err.response.data.base == "BASE_RADIO_ID_NOT_FOUND") {
            notify("EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND", "error");
          } else {
            notify("EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED", "error");
          }
        } else if (err?.response?.data) {
          notify("EDIT_FARM_ERROR_FIELDS", "error");
        } else {
          notify("EDIT_FARM_ERROR", "error");
        }
      } finally {
        finishLoading();
      }
    });
    return createdFarm;
  }

  useEffect(() => {
    if (farmID !== -1) getFarm(farmID, simple);
    // eslint-disable-next-line
  }, []);

  return [
    async,
    farm,
    {
      get: getFarm,
      patch: patchFarm,
      create: createFarm,
    },
  ];
};

export default useFarm;

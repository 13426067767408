import React, { useEffect } from "react";
import useProfile from "../../../hooks/models/useProfile";
import ProfileForm from "./ProfileForm";
import CustomCircularProgress from "../../../components/CustomCircularProgress/CustomCircularProgress";
import { isMobile } from "../../../mobileConfig";
import "./Profile.scss";
import { useSetRecoilState } from "recoil";
import { NavbarState } from "../../../recoils/NavbarRecoil";
import { i18n } from "../../../i18n/i18nText";

export default function Profile() {
  const [profile, async, actions] = useProfile();

  const setNavbar = useSetRecoilState(NavbarState);

  useEffect(() => {
    setNavbar({
      title: i18n("NAV_ITEM_PROFILE"),
    });
  }, []);

  if (async.done && profile !== undefined) {
    return (
      <div className={isMobile() ? "profile-mobile" : "profile"}>
        <ProfileForm profile={profile} patch={actions.patch} />
      </div>
    );
  }

  return <CustomCircularProgress />;
}

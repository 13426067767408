import React, { useCallback, useEffect, useState } from "react";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../hooks/tools/useFormValidation";
import {
  checkIsOnIntervalNumeric,
  checkIsOnIntervalString,
} from "../../../../../../utils/checks";
import TimezoneSelectorForm from "../../../EditFarm/components/EditFarmForm/components/TimezoneSelectorForm/TimezoneSelectorForm";
import { isMobile } from "../../../../../../mobileConfig";
import { handleNumberMask } from "../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { i18n } from "../../../../../../i18n/i18nText";
import CentralLinkedCheckbox from "../../../EditFarm/components/EditFarmForm/components/CentralLinkedCheckbox/CentralLinkedCheckbox";

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  createFarmState: [any, (value: any) => void];
}

export default function GeneralInfo(props: Props) {
  const { validationErrors, formValidationActions, createFarmState } = props;
  const [centralLinked, setCentralLinked] = useState(
    createFarmState[0].central_linked
  );

  // name
  const setValidName = useCallback(
    (value) => {
      const trimValue = value.trimLeft();
      createFarmState[1]({ ...createFarmState[0], name: trimValue });
      formValidationActions.setError(
        "name",
        checkIsOnIntervalString(value, 3, 34)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const setValidWaterBillingDate = useCallback(
    (value) => {
      value = handleNumberMask(value).replace("-", "");
      createFarmState[1]({ ...createFarmState[0], water_billing_date: value });
      formValidationActions.setError(
        "water_billing_date",
        checkIsOnIntervalNumeric(value, 1, 31)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // billing_date
  const setValidBillingDate = useCallback(
    (value) => {
      value = handleNumberMask(value).replace("-", "");
      createFarmState[1]({ ...createFarmState[0], billing_date: value });
      formValidationActions.setError(
        "billing_date",
        checkIsOnIntervalNumeric(value, 1, 31)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  useEffect(() => {
    // making sure value does not reset on component reload
    if (createFarmState[0].central_linked !== centralLinked)
      createFarmState[1]({
        ...createFarmState[0],
        central_linked: centralLinked,
      });
  }, [createFarmState[0].central_linked, centralLinked]);

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <div className="general-form__row-1">
        <h3
          style={{
            marginBottom: "50px",
          }}
        >
          {i18n("FARM_GENERAL_INFORMATION_TITLE")}
        </h3>

        <FormInput
          id="farm-name"
          helperText={validationErrors["name"]}
          label={"FARM_FIELD_NAME"}
          state={[createFarmState[0].name, setValidName]}
        />

        <CentralLinkedCheckbox
          centralLinked={centralLinked}
          setCentralLinked={setCentralLinked}
        />

        <FormInput
          id="farm-billing-date"
          inputMode="numeric"
          helperText={validationErrors["billing_date"]}
          label={"FARM_FIELD_BILLING_DATE"}
          state={[createFarmState[0].billing_date, setValidBillingDate]}
        />

        <FormInput
          id="farm-water-billing-date"
          inputMode="numeric"
          helperText={validationErrors["water_billing_date"]}
          label={"FARM_FIELD_WATER_BILLING_DATE"}
          state={[
            createFarmState[0].water_billing_date,
            setValidWaterBillingDate,
          ]}
        />

        <TimezoneSelectorForm
          rawTimezone={createFarmState[0].timezone}
          setTimezone={(value) =>
            createFarmState[1]({ ...createFarmState[0], timezone: value })
          }
        />
      </div>
    </div>
  );
}

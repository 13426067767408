export enum LoadingTypes {
  SHOW_LOADING = "@@loading/SHOW_LOADING",
  HIDE_LOADING = "@@loading/HIDE_LOADING"
}

export interface Loading {
  isLoading: boolean;
}

export interface LoadingState {
  data: Loading;
}

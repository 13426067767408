import { useEffect, useState } from "react";
import { coreHTTPClient, getAuthorization } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import useNotify from "../tools/useNotify";
import { Billing } from "../../redux/billing/types";
import { reset, updateOrInsertBilling } from "../../redux/billing/actions";
import axios from "axios";

interface BillingActions {
  get: (farmID: string | number) => Promise<Billing>;
  put: (farmID: string | number, putObject: any) => Promise<void>;
  post: (farmID: string | number, postObject: any) => Promise<Billing>;
  patch: (farmID: string | number, patchObject: any) => Promise<void>;
}

const useBilling = (
  farmID?: string | number
): [AsyncStatus, Billing | undefined, BillingActions, boolean] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  const [async, initLoading, finishLoading] = useAsyncStatus();

  const [billing, setBilling] = useState<Billing>(null);

  const [groupPermission, setGroupPermission] = useState(false);

  async function getBilling(farmID: string | number): Promise<any> {
    initLoading();
    try {
      const response = await axios.get(
        `/v4/farms/${farmID}/billing/customer/`,
        {
          headers: {
            Authorization: `Bearer ${getAuthorization()}`,
          },
          baseURL: process.env.REACTWEB_COREAPI_URL,
        }
      );

      if (checkExists(["data", response, "__cascade"])) {
        dispatch(updateOrInsertBilling(response.data));
        setGroupPermission(true);
        setBilling(response.data);
      } else {
        dispatch(reset(null));
        setBilling(null);
      }
      return response.data;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setGroupPermission(false);
      } else {
        setGroupPermission(true);
      }
      console.log(err);
      dispatch(reset(null));
      setBilling(null);
    } finally {
      finishLoading();
    }

    return null;
  }

  async function putBilling(farmID: number, putObject: any) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.put(
          `/v4/farms/${farmID}/billing/customer/`,
          putObject
        );

        if (checkExists(["data", response, "__cascade"])) {
          dispatch(updateOrInsertBilling(response.data));
          setBilling(response.data);
          notify("EDIT_BILLING_SAVE_SUCCESS", "success");
        }
      } catch (err) {
      } finally {
        return resolve(finishLoading());
      }
    });
  }

  async function patchBilling(farmID: number, patchObject: any) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.patch(
          `/v4/farms/${farmID}/billing/customer/`,
          patchObject
        );

        if (checkExists(["data", response, "__cascade"])) {
          dispatch(updateOrInsertBilling(response.data));
          setBilling(response.data);
          notify("EDIT_BILLING_SAVE_SUCCESS", "success");
        }
      } catch (err) {
      } finally {
        return resolve(finishLoading());
      }
    });
  }

  async function postBilling(farmID: number, postObject: any) {
    const postedBilling = await new Promise<Billing>(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.post(
          `/v4/farms/${farmID}/billing/customer/`,
          postObject
        );

        if (checkExists(["data", response, "__cascade"])) {
          if (response.data.error) {
            notify("EDIT_BILLING_ERROR", "error");
            resolve(null);
          } else {
            dispatch(updateOrInsertBilling(response.data));
            setBilling(response.data);
            notify("EDIT_BILLING_SAVE_SUCCESS", "success");
            resolve(response.data as Billing);
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        finishLoading();
      }
    });

    return postedBilling;
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (farmID) getBilling(farmID);
  }, []);

  return [
    async,
    billing,
    {
      get: getBilling,
      put: putBilling,
      post: postBilling,
      patch: patchBilling,
    },
    groupPermission,
  ];
};

export default useBilling;

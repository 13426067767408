import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "@emotion/styled";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface Props {
  data?: CrudeData;
}

type CrudeData = [
  {
    content: {
      imanage_master_status: {
        status: number;
      };
      imanage_sensor_measure_value: [
        {
          number_editing: number;
          value: number;
        }
      ];
    };
    created: Date;
  }
];

type ChartObj = {
  id: string;
  data: ChartData;
};

type ChartData = {
  x: string;
  y: number;
}[];

export const SensorValueByTimeChart = (props: Props) => {
  const [tickValues, setTickValues] = useState<string>("every 1 hour");
  const [chartData, setChartData] = useState<ChartObj>();

  const handleChartData = (array: CrudeData): ChartObj => {
    var tempArray: ChartData = [];
    array.forEach((item) => {
      if (item)
        tempArray.push({
          y: parseFloat(
            (item.content.imanage_sensor_measure_value[0].value / 100).toFixed(
              2
            )
          ),
          x: moment(item.created).format("YYYY-MM-DDTHH:mm:ssZ"),
        });
    });
    tempArray.sort((a, b) => {
      if (moment(a.x).isBefore(b.x)) {
        return -1;
      } else if (moment(a.x).isAfter(b.x)) {
        return 1;
      } else {
        return 0;
      }
    });
    return { id: "Tensão", data: tempArray };
  };

  const theme = {
    tooltip: {
      container: {
        color: "white",
        background: "rebeccapurple",
      },
    },
  };

  const ToolTip = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 5px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 0.9em;
    border-radius: 4px;
    z-index: 999999999999;
  `;

  const TooltipDate = styled.div``;

  const TooltipWaterLevel = styled.div`
    font-weight: bold;
  `;

  const handleTickValues = (): string => {
    let timeArrayAux: moment.unitOfTime.Diff[] = [
      "month",
      "week",
      "day",
      "hour",
    ];

    if (chartData && chartData.data?.length > 0) {
      for (let i = 0; i < 4; i++) {
        let differenceTime = moment(
          chartData.data[chartData.data.length - 1].x
        ).diff(chartData.data[0].x, timeArrayAux[i], true);
        if (differenceTime > 4) {
          return `every ${Math.round(differenceTime / 4.0)} ${timeArrayAux[i]}`;
        }
      }
    }
  };

  useEffect(() => {
    setChartData(handleChartData(props.data));
  }, [props.data]);

  useEffect(() => {
    setTickValues(handleTickValues);
  }, [chartData]);

  return (
    <ResponsiveLine
      data={[chartData]}
      margin={{ top: 10, right: 20, bottom: 80, left: 40 }}
      tooltip={(data) => {
        return (
          <ToolTip>
            <TooltipDate>
              {moment(data.point.data.x).format("DD MMM YYYY HH:mm")}
            </TooltipDate>
            <TooltipWaterLevel>
              {data.point.data.y}
              {" m"}
            </TooltipWaterLevel>
          </ToolTip>
        );
      }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
      }}
      xScale={{
        type: "time",
        format: "%Y-%m-%dT%H:%M:%S%Z",
      }}
      axisBottom={{
        format: "%d/%m-%Hh",
        tickValues: tickValues,
        tickRotation: 60,
      }}
      pointColor={{ theme: "background" }}
      colors={["#004a99"]}
      theme={theme}
      useMesh
    />
  );
};

import React from "react";
import styled from "@emotion/styled";
import { isMobile } from "../../mobileConfig";

const StyledDiv = styled.div`
  width: 100%;
  font-size: 12.6px;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledDivider = styled.div`
  width: 100%;
  margin: 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

interface Props {
  protocol: number;
}

export default function ProtocolFooter(props: Props) {
  return (
    <StyledDiv>
      <StyledDivider />V{props.protocol}
      <div style={{ marginBottom: isMobile() ? "5px" : "12px" }} />
    </StyledDiv>
  );
}

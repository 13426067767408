import { Reducer } from 'redux';


import { IrpdsState, IrpdsTypes, Irpd } from "./types";
import { IrpdsActions } from "./actions";

import { arrUpdateOrInsert } from "../../utils/types";

const INITIAL_STATE: IrpdsState = {
  list: []
};

const reducer: Reducer<IrpdsState, IrpdsActions> = (
  state = INITIAL_STATE,
  action: IrpdsActions
): IrpdsState => {
  const { type, payload } = action;

  switch (type) {
    case IrpdsTypes.SET_IRPDS:
      return { ...state, list: payload };

    case IrpdsTypes.UPDATE_OR_INSERT_IRPD:
      return {
        ...state,
        list: arrUpdateOrInsert<Irpd>(state.list, payload, "id")
      };

    default:
      return state;
  }
};

export default reducer;
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../utils/models/format";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface Props {
  hasCenterPressure: boolean;
  latestData?: any;
  firstData?: any;
}

export const MyResponsivePressureLine = (props: Props) => {
  const hasCenterPressure = props.hasCenterPressure;

  const IndexLow = (data, index, prop) => {
    while (!data[`${index}`]?.[prop] && index > 0) {
      index -= 1;
    }
    return index;
  };

  const IndexHigh = (data, index, prop) => {
    while (!data[`${index}`]?.[prop] && index < 359) {
      index += 1;
    }
    return index;
  };

  const generateChartValues = (data, prop) => {
    var pressureData = new Array(360).fill(0);

    for (let i = 0; i < 360; i++) {
      if (data[`${i}`]?.[prop]) {
        pressureData[i] = data[`${i}`]?.[prop];
      } else {
        let index = i;
        const indexLow = IndexLow(data, i, prop);
        const indexHigh = IndexHigh(data, i, prop);
        i = IndexHigh(data, i, prop);
        const low = data[`${indexLow}`]?.[prop]
          ? data[`${indexLow}`]?.[prop]
          : 0;
        const high = data[`${indexHigh}`]?.[prop]
          ? data[`${indexHigh}`]?.[prop]
          : 0;
        while (index < indexHigh) {
          pressureData[index] =
            low +
            ((high - low) * (index - indexLow)) / (indexHigh - indexLow - 1);
          index += 1;
        }
        pressureData[indexHigh] = high;
      }
    }

    return pressureData;
  };

  let series;

  const auxVetFirst = generateChartValues(props.firstData, "water_pressure");
  const auxVetLatest = generateChartValues(props.latestData, "water_pressure");

  if (hasCenterPressure) {
    const centerPressureFirst = generateChartValues(
      props.firstData,
      "center_pressure"
    );
    const centerPressureLatest = generateChartValues(
      props.latestData,
      "center_pressure"
    );
    series = [
      {
        name: i18n("PRESSURE_CHART_COMPARISON_END_TOWER"),
        data: auxVetFirst.map((item) => item),
      },
      {
        name: i18n("PRESSURE_CHART_END_TOWER"),
        data: auxVetLatest.map((item) => item),
      },
      {
        name: i18n("PRESSURE_CHART_COMPARISON_CENTER"),
        data: centerPressureFirst.map((item) => item),
      },
      {
        name: i18n("PRESSURE_CHART_CENTER"),
        data: centerPressureLatest.map((item) => item),
      },
    ];
  } else {
    series = [
      {
        name: i18n("PRESSURE_CHART_COMPARISON_END_TOWER"),
        data: auxVetFirst.map((item) => item),
      },
      {
        name: i18n("PRESSURE_CHART_END_TOWER"),
        data: auxVetLatest.map((item) => item),
      },
    ];
  }

  const state = {
    series: series,
    options: {
      animations: {
        enabled: false,
        easing: "linear",
      },
      labels: auxVetFirst.map((item, index) => {
        const anglesToShow = [
          0,
          30,
          60,
          90,
          120,
          150,
          180,
          210,
          240,
          270,
          300,
          330,
          359,
        ];
        if (anglesToShow.includes(index)) {
          return index.toString() + "º";
        } else {
          return "";
        }
      }),
      colors: ["#172783", "#495EDA", "#e65116", "#DA7249"],
      stroke: {
        width: 2,
      },
      xaxis: {
        labels: {
          rotate: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return formatFloatValue(val);
          },
        },
        min: 0,
        max: 10,
        forceNiceScale: false,
        tickAmount: 10,
        title: {
          text: "bar",
        },
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return val + "º";
          },
        },
        y: {
          formatter: function (val) {
            return !val ? "0,00" : formatFloatValue(val);
          },
        },
      },
    },
  };

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="line"
      height="90%"
      width="100%"
    />
  );
};

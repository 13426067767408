import { action } from "typesafe-actions";
import { LocationModalTypes, LocationModal } from "./types";
import { Action } from "redux";

export interface ShowLocationModal extends Action {
  type: LocationModalTypes.SHOW_LOCATION_MODAL;
  payload: LocationModal;
}

export interface HideLocationModal extends Action {
  type: LocationModalTypes.HIDE_LOCATION_MODAL;
  payload: null;
}

export const showLocationModal = (
  locationModal: LocationModal
): ShowLocationModal => {
  return action(LocationModalTypes.SHOW_LOCATION_MODAL, locationModal);
};

export const hideLocationModal = (): HideLocationModal =>
  action(LocationModalTypes.HIDE_LOCATION_MODAL, null);

export type LocationModalActions = ShowLocationModal | HideLocationModal;

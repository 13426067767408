import styled from "@emotion/styled";
import { degreesToRadians } from "@nivo/core";
import React from "react";
import {
  degrees_to_radians,
  radians_to_degrees,
} from "../../../pages/Dashboard/Pivot/utils/utils";

interface WrapperProps {
  rotate: number;
  width: number;
  height: number;
}

const Wrapper = styled.div((props: WrapperProps) => ({
  transform: `rotate(${props.rotate}deg)`,
  width: `${props.width}px`,
  height: `${props.height}px`,
  position: "absolute",
  zIndex: 900,
  fontSize: "11px",
  fontFamily: "Verdana",
}));

interface LabelProps {
  rotate: number;
  top: number;
  left: number;
}

const Label = styled.div((props: LabelProps) => ({
  transform: `rotate(${props.rotate}deg)`,
  position: "absolute",
  width: "2.45em",
  top: `${props.top}px`,
  left: `${props.left}px`,
  color: "white",
  textAlign: "center",
}));

interface Props {
  referenceAngle: number;
  circleDiameter: number;
  endAngle: number;
}

function AngleLabels(props: Props) {
  const { referenceAngle, circleDiameter, endAngle } = props;

  function generateLabels(endAngle) {
    const distanceCorrectionFactor = 0.77; // Corrects the distance that the labels should be to beinserted on the circle
    let labels = [];
    let endAngleRadians = degrees_to_radians(endAngle);
    let radius = (circleDiameter / 2) * distanceCorrectionFactor;

    let i = 0;
    while (i <= 2) {
      let arc = i * Math.PI < endAngleRadians ? i * Math.PI : endAngleRadians;
      if (arc == endAngleRadians) {
        if (endAngle == 360) {
          break;
        } else i += 3;
      }
      i += 0.5;

      let top = -Math.cos(arc) * radius + circleDiameter / 2 - 6.8; // 6.8 corrects the position by the height of the div (13.6 px);
      let left = Math.sin(arc) * radius + circleDiameter / 2 - 13.5; // 13.5 corrects the position by the width of the div (27 px)

      labels.push(
        <Label rotate={360 - referenceAngle} top={top} left={left}>
          {Math.round(radians_to_degrees(arc)) + "º"}
        </Label>
      );
    }

    return labels;
  }

  return (
    <Wrapper
      rotate={referenceAngle}
      width={circleDiameter}
      height={circleDiameter}
    >
      {generateLabels(endAngle).map((element) => element)}
    </Wrapper>
  );
}

export default AngleLabels;

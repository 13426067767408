import React, { useEffect, useState } from "react";
import { ChildrenProps } from "../../../../utils/types";
import "./DashboardContainer.scss";
import useFarms from "../../../../hooks/models/useFarms";
import Routes from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import { useLocation } from "react-router";
import { getDeviceNotch } from "../../../../utils/DeviceNotchCheck";

function DashboardContainer(props: ChildrenProps) {
  const [loading, setLoading] = useState(true);
  const [farms, , loadingFarm] = useFarms();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (loadingFarm) {
      if (farms[0]) {
        if (location.pathname === "/" || location.pathname === "/dashboard") {
          navigate(
            Routes.DASHBOARD.SELECTED_FARM.replace(
              ":farm",
              farms[0].id.toString()
            )
          );
        }
      } else {
        if (location.pathname === "/" || location.pathname === "/dashboard") {
          navigate(Routes.DASHBOARD.USER_HAS_NO_FARM);
        }
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms, loadingFarm, location.pathname]);

  return (
    <div
      className={`dashboard-container dashboard-container__notch-${getDeviceNotch()}`}
    >
      {loading ? <CustomCircularProgress /> : props.children}
    </div>
  );
}

export default DashboardContainer;

import { useState, useEffect } from "react";
import {
  AsyncStatus,
  useAsyncStatus,
} from "../../../../../hooks/tools/useAsyncStatus";
import { coreHTTPClient } from "../../../../../services/webclient";

const useImeterConfigHistoricV5 = (
  farmID: number,
  iMeterID: number,
  meterSystemID: number,
  pinned: boolean = false
): [
  AsyncStatus,
  any[] | undefined,
  number,
  () => void,
  () => void,
  (value: string) => void
] => {
  const [async, initLoading, finishLoading] = useAsyncStatus();
  const [total, setTotal] = useState(0);
  const [result, setResult] = useState<any[]>();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();

  async function getHistoric() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient
          .get(
            `/v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/config/?pinned=${pinned}`
          )
          .then((response) => {
            setTotal(response.data.count);
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function nextPage() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(next).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function previousPage() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(previous).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function currentPage(current) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient
          .get(
            `/v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/config/?page=${current}&pinned=${pinned}`
          )
          .then((response) => {
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(
    () => {
      getHistoric();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [async, result, total, previousPage, nextPage, currentPage];
};

export default useImeterConfigHistoricV5;

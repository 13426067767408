import firebase from "firebase/app";
import "firebase/messaging";
import { coreHTTPClient } from "../services/webclient";
import { Capacitor } from "@capacitor/core";

/**
 * WARNING
 * WARNING
 * WARNING
 *
 * If you're fiddling around here, be sure to check the firebase
 * worker that runs on background called `firebase-messaging-sw.js`
 */

let firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

let token = "";
let messaging;

if (Capacitor.getPlatform() === "web") {
  try {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      process.env.REACT_APP_FIREBASE_USEPUBLICVAPIDKEY
    );

    messaging
      .getToken()
      .then((currentToken) => {
        if (currentToken) {
          token = currentToken;
        } else {
          // Show permission request.
        }
      })
      .catch((err) => {});

    messaging.onMessage((payload) => {
      let { title, body, image, url } = payload.data;

      let notif = new Notification(title, {
        body,
        image,
        icon: image,
      });

      notif.onclick = () => (window.location.href = url);
    });
  } catch (err) {
    console.error("FCMService", err);
  }
}

export async function deleteToken() {
  try {
    messaging.deleteToken(token);
  } catch (err) {
    console.log("Fail to delete token");
  }
}

export async function getWebFCMToken() {
  return await messaging?.getToken();
}

export async function getMyTopics(token: string): Promise<string[]> {
  //As vezes o token vem vazio na primeira chamada
  try {
    if (!token) {
      return [];
    }

    return [];
  } catch {
  } finally {
    return [];
  }
}

export async function subscribeTokenToTopic(topic) {
  coreHTTPClient
    .post("/v3/notifications/firebase/", {
      token,
      topic,
    })
    .then()
    .catch();
}

export async function unsubscribeTokenFromTopic(topic) {
  coreHTTPClient
    .delete("/v3/notifications/firebase/", {
      data: { token, topic },
    })
    .then()
    .catch();
}

import { makeStyles } from "@material-ui/styles";
import customMuiComponentStyle from "../../../../../../style/customMuiComponentStyle";

export const useStyles = makeStyles({
  stopPivotButton: {
    ...customMuiComponentStyle.redButton,
  },
  stopPivotButtonMobile: {
    ...customMuiComponentStyle.redButton,
    fontSize: "0.8rem",
  },
  bottomMobile: {
    fontSize: "0.8rem",
  },
});

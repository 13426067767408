import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../hooks/tools/useFormValidation";
import {
  checkIsNotNumber,
  checkIsOnIntervalString,
} from "../../../../../../utils/checks";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import countries from "../../../../../../utils/consts/countries";
import { handleNumberMask } from "../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { isMobile } from "../../../../../../mobileConfig";
import axios from "axios";
import useNotify from "../../../../../../hooks/tools/useNotify";
import IOSSwitch from "../../../../components/IOSSwitch/IOSSwitch";
import { ListItem, ListItemText } from "@material-ui/core";
import { i18n } from "../../../../../../i18n/i18nText";

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  createFarmState: [any, (value: any) => void];
  IDD: any;
}

export default function ContactStep(props: Props) {
  const {
    validationErrors,
    formValidationActions,
    createFarmState,
    IDD,
  } = props;

  const notify = useNotify();

  // Refs para os campos de endereço
  const addressRef = useRef<HTMLInputElement | null>(null);

  const cityRef = useRef<HTMLInputElement | null>(null);

  const stateRef = useRef<HTMLInputElement | null>(null);

  // contact phone
  const setValidPhone = useCallback(
    (value) => {
      let updatedPhone = handleNumberMask(value);

      if (updatedPhone.length > 14) {
        updatedPhone = updatedPhone.slice(0, 14);
      }

      updatedPhone = updatedPhone.replace(
        /^(\d{2})(\d{4,5})(\d{4})$/,
        "($1) $2-$3"
      );

      createFarmState[1]((prevCreateFarmState) => ({
        ...prevCreateFarmState,
        phone: updatedPhone,
      }));

      if (
        updatedPhone === "" ||
        !/^\(\d{2}\) \d{4,5}-\d{4}$/.test(updatedPhone) ||
        /^(\d)\1+$/.test(updatedPhone.replace(/\D/g, ""))
      ) {
        formValidationActions.setError("phone", "EDIT_FARM_ERROR_PHONE");
      } else {
        formValidationActions.clearError("phone");
      }
    },

    [createFarmState, formValidationActions]
  );

  // contact postal code
  const setValidPostalCode = useCallback(
    (value) => {
      let trimValue = handleNumberMask(value).trimLeft();

      if (trimValue.length > 8) {
        trimValue = trimValue.slice(0, 8);
      }

      createFarmState[1]((prevCreateFarmState) => ({
        ...prevCreateFarmState,
        postal_code: trimValue,
      }));

      const isAllZeros = /^0+$/.test(trimValue);

      const isAllSameDigit = /^(\d)\1+$/.test(trimValue);

      if (isAllZeros || isAllSameDigit) {
        formValidationActions.setError(
          "postal_code",
          "VALIDATION_FIELD_INVALID"
        );
      } else {
        formValidationActions.clearError("postal_code");
        formValidationActions.setError(
          "postal_code",
          checkIsOnIntervalString(trimValue, 8, 8)
        );
      }
    },

    [validationErrors, createFarmState, formValidationActions]
  );

  // contact address
  const setValidAddress = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      createFarmState[1]((prevCreateFarmState) => ({
        ...prevCreateFarmState,
        address: trimValue,
      }));

      formValidationActions.setError(
        "address",
        checkIsOnIntervalString(value, 3, 60)
      );
    },

    [validationErrors, createFarmState, formValidationActions]
  );

  // contact city
  const setValidCity = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        createFarmState[1]((prevCreateFarmState) => ({
          ...prevCreateFarmState,
          city: trimValue,
        }));

        formValidationActions.setError(
          "city",
          checkIsOnIntervalString(value, 3, 60)
        );
      }
    },

    [validationErrors, createFarmState, formValidationActions]
  );

  // contact state
  const setValidState = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        createFarmState[1]((prevCreateFarmState) => ({
          ...prevCreateFarmState,
          state: trimValue,
        }));

        formValidationActions.setError(
          "state",
          checkIsOnIntervalString(value, 2, 36)
        );
      }
    },

    [validationErrors, createFarmState, formValidationActions]
  );

  // contact country
  const setValidCountry = useCallback(
    (value) => {
      createFarmState[1]((prevCreateFarmState) => ({
        ...prevCreateFarmState,
        country: value,
      }));
    },

    [createFarmState]
  );

  const countryValues: [string, string][] = useMemo(
    () => countries.map((country) => [country.iso, country.content]),
    []
  );

  const [isAuto, setAuto] = useState(false);

  const handleToggleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuto(event.target.checked);
  };

  function getPostalCodeInfo() {
    if (createFarmState[0].postal_code?.length == 8) {
      axios
        .get(`https://viacep.com.br/ws/${createFarmState[0].postal_code}/json/`)
        .then(function (response) {
          if (response.status == 200 && !response.data.erro) {
            setValidAddress(
              `${response.data.logradouro} - ${response.data.bairro}`
            );
            setValidCity(response.data.localidade);
            setValidState(response.data.uf);
          } else {
            notify("ERROR_ZIP_CODE", "error");
            setValidAddress("");
            setValidCity("");
            setValidState("");
          }
        });
    }
  }

  useEffect(() => {
    if (isAuto) {
      createFarmState[1]((prevCreateFarmState) => ({
        ...prevCreateFarmState,
        postal_code: prevCreateFarmState.customer.postal_code,
        address: prevCreateFarmState.customer.address,
        city: prevCreateFarmState.customer.city,
        state: prevCreateFarmState.customer.state,
        country: prevCreateFarmState.customer.country,
      }));

      formValidationActions.setError(
        "postal_code",
        checkIsOnIntervalString(createFarmState[0].customer.postal_code, 8, 8)
      );

      formValidationActions.setError(
        "address",
        checkIsOnIntervalString(createFarmState[0].customer.address, 3, 60)
      );

      formValidationActions.setError(
        "city",
        checkIsOnIntervalString(createFarmState[0].customer.city, 3, 60)
      );

      formValidationActions.setError(
        "state",
        checkIsOnIntervalString(createFarmState[0].customer.state, 2, 36)
      );
      // } else {
      //   createFarmState[1]((prevCreateFarmState) => ({
      //     ...prevCreateFarmState,
      //     postal_code: "",
      //     address: "",
      //     city: "",
      //     state: "",
      //     country: "BR",
      //   }));
    }
  }, [isAuto]);

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <div className="general-form__row-12">
        <h3
          style={{
            marginBottom: "0px",
          }}
        >
          {i18n("FARM_CONTACT_INFORMATION_TITLE")}
        </h3>

        <div className="general-form__row-2-5">
          <FormInput
            id="farm-phone"
            inputMode="numeric"
            helperText={validationErrors["phone"]}
            startAdornment={IDD}
            label={"FARM_FIELD_PHONE"}
            state={[createFarmState[0].phone, setValidPhone]}
          />
        </div>

        <h3
          style={{
            marginTop: "40px",
            marginBottom: "-15px",
          }}
        >
          {i18n("FARM_CONTACT_ADDRESS_TITLE")}
        </h3>

        <ListItem
          className="modal-subtitle"
          style={{ width: "100%", marginBottom: "20px", padding: "0px" }}
        >
          <ListItemText primary={i18n("FARM_CONTACT_ADDRESS_SWITCH")} />

          <IOSSwitch
            checked={isAuto}
            onChange={handleToggleSwitchChange}
            name="IOSSwitch"
          />
        </ListItem>
      </div>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-postal-code"
          inputMode="numeric"
          helperText={validationErrors["postal_code"]}
          label={"FARM_CONTACT_FIELD_POSTAL_CODE"}
          state={[createFarmState[0].postal_code, setValidPostalCode]}
          disabled={isAuto}
          onBlur={getPostalCodeInfo}
        />

        <FormInput
          id="farm-address"
          helperText={validationErrors["address"]}
          label={"FARM_FIELD_ADDRESS"}
          state={[createFarmState[0].address, setValidAddress]}
          inputRef={addressRef}
          disabled={isAuto}
        />
      </div>

      <div className="general-form__row-4-4-4">
        <FormInput
          id="farm-city"
          helperText={validationErrors["city"]}
          label={"FARM_FIELD_CITY"}
          state={[createFarmState[0].city, setValidCity]}
          inputRef={cityRef}
          disabled={isAuto}
        />

        <FormInput
          id="farm-state"
          helperText={validationErrors["state"]}
          label={"FARM_FIELD_STATE"}
          state={[createFarmState[0].state, setValidState]}
          inputRef={stateRef}
          disabled={isAuto}
        />

        <FormSelect
          id="farm-country"
          label={"FARM_FIELD_COUNTRY"}
          state={[createFarmState[0].country, setValidCountry]}
          values={countryValues}
          disabled={isAuto}
        />
      </div>
    </div>
  );
}

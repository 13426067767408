import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Checkbox,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  ArrowBack,
  CheckCircle,
  DoneAll,
  Settings,
  Map,
} from "@material-ui/icons";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import DashboardBoxTitle from "../../../../components/DashboardBox/DashboardBoxTitle";
import { isMobile } from "../../../../../../mobileConfig";
import { green, red, blue } from "@material-ui/core/colors";
import "./EditIrpdForm.scss";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import FormTimePicker from "../../../../../../components/Form/FormTimePicker/FormTimePicker";
import { ValidationErrorMessage } from "../../../../../../components/Form/types";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import Routes from "../../../../../../routes/routes";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { Irpd } from "../../../../../../redux/irpds/types";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import {
  validadeFloatInput,
  handleNumberMask,
} from "../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";

import styled from "@emotion/styled";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { arrUpdateOrInsert, objIsEmpty } from "../../../../../../utils/types";
import checkExists from "../../../../../../utils/checks/checkExists";
import { IrpdStateList } from "../../../../../../recoils/IrpdRecoil";
import { useRecoilState } from "recoil";
import DraggableMap from "../../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import FormInputPosition from "../../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import { centerMarkerSelector } from "../../../../../../recoils/DraggableMapRecoil";
import ProtocolFooter from "../../../../../../components/ProtocolFooter/ProtocolFooter";
import { useCountdown } from "../../../../../../hooks/models/useCountdown";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import { useDispatch } from "react-redux";
import ChangeRadioField from "../../../../Pivot/EditPivot/components/ChangeRadioField/ChangeRadioField";
import {
  checkIsOnIntervalNumeric,
  checkIsOnIntervalString,
  formatPositiveFloat,
} from "../../../../../../utils/checks";
import { showLocationModal } from "../../../../../../redux/locationModal/actions";

const greenDot = require("../../../../../../assets/images/markers/light-green-dot.svg");
const { SOCKET_SUFFIX } = process.env;

export interface IrpdParams {
  farm: string;
  irpd: string;
}

interface Props {
  irpd: Irpd;
}

const RtcContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
`;

const CheckBoxItem = styled.div`
  font-size: 0.7em;
  opacity: 0.8;
  margin-left: -12px;
  display: grid;
  grid-template-columns: 1fr 8fr;
  height: 100%;
`;

const CheckBoxLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  box: {
    padding: "0px",
  },
});

function EditIrpdForm(props: Props) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const notify = useNotify();
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const dispatch = useDispatch();

  const [counter, setCounter] = useCountdown("Irpd", String(irpdID));

  const { irpd } = props;
  const [device, setDevice] = useState(`${irpd.base}`);
  const [pump, setPump] = useState(`${irpd.pump}`);
  const [isConnected, socket] = useSocketIO();
  const [irpds, setIrpds] = useRecoilState(IrpdStateList);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(false);

  const classes = useStyles(props);

  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    hourRangeMax: undefined,
    hourRangeMin: undefined,
    rtcDate: undefined,
    energyTime: undefined,
    irpdLat: undefined,
    monthlyWaterLimit: undefined,
    irpdLng: undefined,
    name: undefined,
    flow: undefined,
  });

  const [hideButtonSave, setHideButtonSave] = useState(false);

  const [lastUpdate, setLastUpdate] = useState(
    moment(irpd.latest_irpd_config.created).format("DD MMM HH:mm")
  );

  const [delivered, setDelivered] = useState<boolean | number>(
    props.irpd.latest_irpd_config?.delivered
      ? props.irpd.latest_irpd_config?.delivered
      : false
  );
  const [sent, setSent] = useState<boolean | number>(
    props.irpd.latest_irpd_config?.sent
      ? props.irpd.latest_irpd_config?.sent
      : false
  );
  const [manual, setManual] = useState<boolean>(
    props.irpd.latest_irpd_config?.manual
  );
  const [rtc, setRtc] = useState<boolean>(false);

  const [name, setName] = useState(props.irpd.name);
  const setValidName = useCallback(
    (value) => {
      setName(value);
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 2, 17),
      });
    },
    [validationErrors]
  );

  const [flow, setFlow] = useState(irpd.flow ? String(irpd.flow) : "");
  const setValidFlow = useCallback(
    (value) => {
      const errorID = "flow";
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setFlow(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 1000, true),
      });
    },
    [validationErrors]
  );

  const [potency, setPotency] = useState(irpd.potency);
  const setValidPotency = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setPotency(value);
      setValidationErrors({
        ...validationErrors,
        potency: checkIsOnIntervalNumeric(value, 1, 22000, true),
      });
    },
    [validationErrors]
  );

  const [irpdLat, setIrpdLat] = useState(props.irpd.position.split(",")[0]);
  const setValidIrpdLat = useCallback(
    (value) => {
      setIrpdLat(value);
    },
    [validationErrors]
  );

  const [irpdCoordsError, setIrpdCoordsError] = useState(false);

  const [irpdLng, setIrpdLng] = useState(props.irpd.position.split(",")[1]);
  const setValidIrpdLng = useCallback(
    (value) => {
      setIrpdLng(value);
    },
    [validationErrors]
  );

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setIrpdLat}
                setLongitude={setIrpdLng}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={greenDot} />
    </Button3D>
  );

  const [enableMonthlyWaterLimit, setEnableMonthlyWaterLimit] = useState<
    boolean
  >(irpd.latest_irpd_config?.monthly_water_limit !== 0);

  const [monthlyWaterLimit, setMonthlyWaterLimit] = useState<string>(
    String(irpd.latest_irpd_config?.monthly_water_limit)
  );

  const setValidMonthlyWaterLimit = useCallback(
    (value) => {
      const errorID = "monthlyWaterLimit";
      setMonthlyWaterLimit(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 100000, true),
      });
    },
    [validationErrors]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const doneIconInput = (
    <div className="edit-irpd-form__done-all">
      <DoneAll style={{ color: blue[500] }} />
    </div>
  );

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_IRPD.replace(
            ":farm",
            String(farmID)
          ).replace(":irpd", String(irpdID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  useEffect(() => {
    if (irpd?.permission_level < 3) {
      navigate(
        Routes.DASHBOARD.SELECTED_IRPD.replace(":farm", String(farmID)).replace(
          ":irpd",
          String(irpd.id)
        )
      );
    }
  }, [irpd]);

  useEffect(() => {
    checkErros();
  }, [validationErrors]);

  const checkErros = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  async function patchIrpd(irpdConfig: any, patchObject: any) {
    setLoading(true);
    await new Promise(async (resolve) => {
      try {
        await coreHTTPClient.post(
          `v3/farms/${farmID}/irpds/${irpdID}/${"config"}/`,
          irpdConfig
        );

        if (!objIsEmpty(patchObject)) {
          const response = await coreHTTPClient.patch(
            `v3/farms/${farmID}/irpds/${irpdID}/`,
            patchObject
          );

          if (checkExists(["data", response, "__cascade"]))
            setIrpds(arrUpdateOrInsert(irpds, response.data, "id"));

          notify("IRPD_BOX_CONFIGURATION_SAVED", "success");
          setCounter(12);
          setLoading(false);
          if (!rtc) setRtcDate(moment());
        }
      } catch (err) {
        setLoading(false);
        setCounter(0);
        notify("IRPD_BOX_CONFIGURATION_ERROR", "error");
      }
    });
  }

  const saveButtonComponent = (
    <Button
      color="primary"
      disabled={hideButtonSave || loading || irpdCoordsError || counter !== 0}
      endIcon={
        loading || counter !== 0 ? (
          <CircularProgress size={20} style={{ marginLeft: "8px" }} />
        ) : (
          <CheckCircle />
        )
      }
      onClick={() => {
        setValue(0);
        setSent(-1);
        setDelivered(-1);
        var rtcDatetime;
        if (rtc) {
          rtcDatetime = rtcDate.format("DD-MM-YYYY HH:mm");
        } else {
          rtcDatetime = moment().format("DD-MM-YYYY HH:mm");
        }
        if (!showErrorMessages()) {
          const irpdConfig = {
            monthly_water_limit: !enableMonthlyWaterLimit
              ? 0
              : parseInt(monthlyWaterLimit),
            hour_range_max: `${
              hourRangeMax.hour().toString().length === 1
                ? "0" + hourRangeMax.hour()
                : hourRangeMax.hour()
            }:${
              hourRangeMax.minute().toString().length === 1
                ? "0" + hourRangeMax.minute()
                : hourRangeMax.minute()
            }:00`,
            hour_range_min: `${
              hourRangeMin.hour().toString().length === 1
                ? "0" + hourRangeMin.hour()
                : hourRangeMin.hour()
            }:${
              hourRangeMin.minute().toString().length === 1
                ? "0" + hourRangeMin.minute()
                : hourRangeMin.minute()
            }:00`,
            rtc: moment.utc(rtcDatetime, "DD-MM-YYYY HH:mm").toISOString(),
            energy_time: parseInt(energyTime),
          };

          const newPosition = irpdLat + "," + irpdLng;

          const patchObject = {
            name: name,
            potency: potency,
            flow: parseFloat(flow),
            position: newPosition,
          };

          patchIrpd(irpdConfig, patchObject);

          setValue(0);
        }
      }}
    >
      {counter !== 0 ? (
        `${counter}`
      ) : (
        <>{i18n("SELECTED_IRPD_SAVE_BUTTON_TEXT")}</>
      )}
    </Button>
  );

  //HOURRANGEMAX
  const [hourRangeMax, setHourRangeMax] = useState(
    irpd.latest_irpd_config.hour_range_max
      ? moment(irpd.latest_irpd_config.hour_range_max, "H:mm")
      : moment()
  );
  const setValidHourRangeMax = useCallback(
    (value) => {
      let errorID = "hourRangeMax";
      setHourRangeMax(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "PIVOT_CONFIG_V5_FIELD_CLOCK_TIME",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  //HOURRANGEMIN
  const [hourRangeMin, setHourRangeMin] = useState(
    irpd.latest_irpd_config.hour_range_min
      ? moment(irpd.latest_irpd_config.hour_range_min, "H:mm")
      : moment()
  );
  const setValidHourRangeMin = useCallback(
    (value) => {
      let errorID = "hourRangeMin";
      setHourRangeMin(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "PIVOT_CONFIG_V5_FIELD_CLOCK_TIME",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  //RTCDATE
  const [rtcDate, setRtcDate] = useState(
    irpd.latest_irpd_config.rtc ? moment(irpd.latest_irpd_config.rtc) : moment()
  );
  const setValidRtcDate = useCallback(
    (value) => {
      let errorID = "rtcDate";
      setRtcDate(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "PIVOT_CONFIG_V5_FIELD_CLOCK",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  //ENERGYTIME
  const [energyTime, setEnergyTime] = useState(
    irpd.latest_irpd_config.energy_time.toString()
  );
  const setValidEnergyTime = useCallback(
    (value) => {
      const errorID = "energyTime";
      setEnergyTime(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 1, 300, true),
      });
    },
    [validationErrors]
  );

  useEffect(() => {
    if (!enableMonthlyWaterLimit) {
      setMonthlyWaterLimit("0");
      setValidationErrors({
        ...validationErrors,
        monthlyWaterLimit: undefined,
      });
    }
  }, [monthlyWaterLimit, enableMonthlyWaterLimit]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);

      socket.bind("irpd_config", (data) => {
        setManual(data?.manual);
        setLastUpdate(moment(data.created).format("DD MMM HH:mm"));
        if (data.sent || data.delivered) {
          setDelivered(data.delivered);
          setSent(data.sent);
        }
      });
    }

    return () => {
      socket.unbind("irpd_config");
      socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);
    };
  }, [isConnected]);

  const markers = [
    {
      lat: {
        state: irpdLat,
        setFunction: setValidIrpdLat,
      },
      lng: {
        state: irpdLng,
        setFunction: setValidIrpdLng,
      },
      markerUrl: greenDot,
      key: "center",
      event: {
        state: event,
        setFunction: setEvent,
      },
    },
  ];

  const handleStatuses = (sent, delivered) => {
    if (sent && !delivered) {
      return 1;
    } else if (sent === -1 && delivered === -1) {
      return -1;
    }
    if (sent && delivered) {
      return 2;
    }
    return 0;
  };

  return (
    <div className={isMobile() ? "edit-irpd-mobile" : "edit-irpd"}>
      <div className="general-form__container">
        <DashboardBox
          leftElement={backButton}
          centerElement={<DashboardBoxTitle title={"IRPD_EDIT_LABEL"} />}
          rightElement={saveButtonComponent}
        >
          <div
            style={{
              backgroundColor: "white",
              display: "grid",
              gridTemplateColumns: "12fr",
              height: "100%",
            }}
          >
            <Tabs
              orientation={"horizontal"}
              value={value}
              onChange={handleChange}
              style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
              indicatorColor="primary"
              aria-label="Edit Farm"
              centered={isMobile() ? true : false}
            >
              <Tab
                className={classes.tab}
                label={i18n("EDIT_PIVOT_LABEL_GENERAL")}
                tabIndex={value}
                style={{ borderLeft: "1px solid rgba(0,0,0,0.1)" }}
                icon={
                  <Settings
                    style={{
                      color:
                        validationErrors["rtcDate"] ||
                        validationErrors["hourRangeMax"] ||
                        validationErrors["hourRangeMin"] ||
                        validationErrors["hourRangeMin"] ||
                        validationErrors["energyTime"] ||
                        validationErrors["monthlyWaterLimit"] ||
                        validationErrors["name"] ||
                        validationErrors["flow"]
                          ? red[500]
                          : green[500],
                    }}
                  />
                }
              />
              <Tab
                className={classes.tab}
                label={i18n("EDIT_PIVOT_LABEL_LOCATION")}
                tabIndex={value}
                icon={
                  <Map
                    style={{
                      color: irpdCoordsError ? red[500] : green[500],
                    }}
                  />
                }
              />
            </Tabs>

            <div
              style={{
                backgroundColor: "#fff",
                display: "grid",
                gridTemplateColumns: "1fr",
                height: "100%",
              }}
            >
              <div
                style={{
                  maxHeight: isMobile() ? "" : "calc(100vh - 150px)",
                  overflow: "auto",
                }}
              >
                <TabPanel value={value} index={0}>
                  <div className="edit-irpd-form__last-settings">
                    <span>
                      {i18n("EDIT_PIVOT_LAST_SETTINGS", [lastUpdate])}
                    </span>
                    {manual
                      ? i18n("EDIT_PIVOT_MANUAL")
                      : i18n("EDIT_PIVOT_BY_WEB")}
                  </div>
                  <div className="general-form__row-3-3-3-3">
                    <ChangeRadioField
                      radioID={device}
                      farmID={farmID}
                      deviceID={props.irpd.id}
                      label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
                      deviceType="IRPD"
                      status={0}
                      locked
                    />
                    <ChangeRadioField
                      radioID={pump}
                      farmID={farmID}
                      deviceID={props.irpd.id}
                      label={i18n("EDIT_PIVOT_PUMP_LABEL")}
                      status={handleStatuses(sent, delivered)}
                      deviceType="IRPD"
                      deviceSubType="pump"
                    />
                    {/* <FormInput
                      id="irpd-central"
                      error={undefined}
                      label={"EDIT_PIVOT_CENTRAL_LABEL"}
                      state={[device, setDevice]}
                      disabled
                    />
                    <FormInput
                      id="irpd-pump"
                      error={undefined}
                      label={"EDIT_PIVOT_PUMP_LABEL"}
                      state={[pump, setPump]}
                      disabled
                      endAdornment={
                        <div className="edit-pivot-form-v5__information">
                          {handleStatuses(sent, delivered)}
                        </div>
                      }
                    /> */}
                  </div>
                  <div className="general-form__row-6-6">
                    <FormInput
                      id="irpd-name"
                      helperText={validationErrors["name"]}
                      label={"IRPD_FIELD_NAME"}
                      state={[name, setValidName]}
                    />
                    <CheckBoxItem>
                      <Checkbox
                        title={`${i18n("ALERT_ITEM_DO_ENABLE")}/${i18n(
                          "ALERT_ITEM_DO_DISABLE"
                        )} ${i18n("IRPD_MONTHLY_WATER_LIMIT")}`}
                        style={{
                          margin: ` 0 0 -20px ${isMobile() ? 0 : -5}px`,
                        }}
                        color="primary"
                        checked={enableMonthlyWaterLimit}
                        onChange={(event) => {
                          setEnableMonthlyWaterLimit((prevState) => {
                            if (!prevState && monthlyWaterLimit === "0") {
                              setMonthlyWaterLimit("1");
                            }
                            return !prevState;
                          });
                        }}
                      />
                      <FormInput
                        disabled={!enableMonthlyWaterLimit}
                        id="irpd-monthly-water-limit"
                        helperText={validationErrors["monthlyWaterLimit"]}
                        label={"IRPD_MONTHLY_WATER_LIMIT"}
                        state={[monthlyWaterLimit, setValidMonthlyWaterLimit]}
                        endAdornment="m³"
                      />
                    </CheckBoxItem>
                  </div>

                  <div className="general-form__row-6-6">
                    <FormInput
                      id="irpd-config-potency"
                      helperText={validationErrors.potency}
                      inputMode="numeric"
                      label="PIVOT_CONFIG_V5_FIELD_POTENCY"
                      state={[String(potency), setValidPotency]}
                      endAdornment="kW"
                    />
                    <FormInput
                      id="irpd-config-energy-time"
                      helperText={validationErrors.flow}
                      label="PIVOT_CONFIG_V5_FIELD_FLOW"
                      state={[flow, setValidFlow]}
                      endAdornment="m³/h"
                    />
                  </div>

                  <div className="general-form__row-6-6">
                    <FormTimePicker
                      id="irpd-config-hour-range-min"
                      error={validationErrors["hourRangeMin"]}
                      label={"EDIT_IRPD_FIELD_HOUR_RANGE_MIN"}
                      state={[hourRangeMin, setValidHourRangeMin]}
                    />
                    <FormTimePicker
                      id="irpd-config-hour-range-max"
                      error={validationErrors["hourRangeMax"]}
                      label={"EDIT_IRPD_FIELD_HOUR_RANGE_MAX"}
                      state={[hourRangeMax, setValidHourRangeMax]}
                    />
                  </div>

                  <div className="general-form__row-6-6">
                    <RtcContainer>
                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          checked={rtc}
                          onChange={(event) => {
                            setRtc((prevState) => !prevState);
                          }}
                        />
                        <CheckBoxLabel>
                          {i18n("PIVOT_CONFIG_V5_FIELD_CLOCK")}
                        </CheckBoxLabel>
                      </CheckBoxItem>
                      <FormDateTimePicker
                        disabled={!rtc}
                        id="irpd-config-rtc-date"
                        label={"EDIT_IRPD_FIELD_RTCDATE"}
                        state={[rtcDate, setValidRtcDate]}
                      />
                    </RtcContainer>
                    <FormInput
                      id="irpd-config-energy-time"
                      helperText={validationErrors["energyTime"]}
                      type="numeric"
                      label={"EDIT_IRPD_FIELD_ENERGY_TIME"}
                      tooltip={"EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP"}
                      state={[energyTime, setValidEnergyTime]}
                      endAdornment="min"
                    />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div>
                    <FormInputPosition
                      setError={setIrpdCoordsError}
                      id="irpd-config-center"
                      markerUrl={markerButton()}
                      label={"PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER"}
                      lat={[irpdLat, setValidIrpdLat]}
                      lng={[irpdLng, setValidIrpdLng]}
                      recoilSelector={centerMarkerSelector}
                    />
                    <div style={{ height: "400px", width: "100%" }}>
                      <DraggableMap
                        _setExperimentalRecoil
                        markers={markers}
                        zoomScroll={false}
                      />
                    </div>
                  </div>
                </TabPanel>
              </div>
              <ProtocolFooter protocol={irpd.protocol} />
            </div>
          </div>
        </DashboardBox>
      </div>
    </div>
  );
}

export default EditIrpdForm;

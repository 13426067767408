import React from "react";
import moment from "moment";
import {
  SimpleIrrigation,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import {
  irrigationMode,
  irrigationStopMode,
  irrigationDirection,
  irrigationInjectionPump,
  irrigationStartMode,
  formatDateTime2,
} from "../../../../utils/utils";

import {
  parseHHMM,
  PTPToMillimeter,
} from "../../../../../../../../../../utils/models/pivots";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  simpleIrrigation: SimpleIrrigation;
  pivot?: Pivot;
}

function ExpandedSimpleIrrigation(props: Props): any {
  const { simpleIrrigation } = props;
  const createdOnHardware = <span>{i18n("HISTORIC_CREATED_ON_HARDWARE")}</span>;
  const createdByUser = (
    <span>
      {i18n("HISTORIC_CREATED_BY")}
      <b>{i18n("VALUE", [`${simpleIrrigation.username}`])}</b>
    </span>
  );

  const startNow = <span>{i18n("HISTORIC_IRPD_ACTION_NOW")}</span>;

  const startOnDate = (
    <span>
      {i18n("HISTORIC_SIMPLE_IRRIGATION_START_DATE")}
      <b>
        {i18n("VALUE", [
          `${formatDateTime2(
            simpleIrrigation.content.simple_irrigation_parameters?.start_year,
            simpleIrrigation.content.simple_irrigation_parameters?.start_month,
            simpleIrrigation.content.simple_irrigation_parameters?.start_day,
            simpleIrrigation.content.simple_irrigation_parameters?.start_hour,
            simpleIrrigation.content.simple_irrigation_parameters?.start_minute
          )}`,
        ])}
      </b>
    </span>
  );

  const stopAngle = (
    <span>
      {i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE")}
      <b>
        {i18n("VALUE_AND_UNIT_DEGREES", [
          `${simpleIrrigation.content.simple_irrigation_parameters?.stop_angle}`,
        ])}
      </b>
    </span>
  );
  const stopRounds = (
    <span>
      {i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS")}
      <b>
        {i18n("VALUE", [
          `${simpleIrrigation.content.simple_irrigation_parameters?.rounds}`,
        ])}
      </b>
    </span>
  );

  const simpleIrrigationContent = (
    <>
      <span>
        {i18n("HISTORIC_SIMPLE_IRRIGATION_MODE")}
        <b>
          {i18n("VALUE", [
            irrigationMode(
              simpleIrrigation.content.simple_irrigation_parameters?.mode
            ),
          ])}
        </b>
      </span>
      <span>
        {i18n("HISTORIC_SIMPLE_IRRIGATION_DIRECTION")}
        <b>
          {i18n("VALUE", [
            irrigationDirection(
              simpleIrrigation.content.irrigation_status?.irrigation_status
            ),
          ])}
        </b>
      </span>
      <span>
        {i18n("HISTORIC_SIMPLE_IRRIGATION_PERCENT")}
        <b>
          {i18n("VALUE_AND_UNIT_PERCENT_MILIMETERS", [
            `${simpleIrrigation.content.simple_irrigation_parameters?.percent}`,
            `${formatFloatValue(
              PTPToMillimeter(
                props.pivot,
                simpleIrrigation.content.simple_irrigation_parameters?.percent
              )
            )}`,
          ])}
        </b>
      </span>
      <span>
        {i18n("HISTORIC_SEGMENT_IRRIGATION_START_MODE")}
        <b>
          {i18n("VALUE", [
            irrigationStartMode(
              simpleIrrigation.content.simple_irrigation_parameters?.start_mode
            ),
          ])}
        </b>
      </span>
      {simpleIrrigation.content.simple_irrigation_parameters?.start_mode ===
      1 ? (
        startOnDate
      ) : (
        <></>
      )}
      <span>
        {i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_MODE")}
        <b>
          {i18n("VALUE", [
            irrigationStopMode(
              simpleIrrigation.content.simple_irrigation_parameters?.stop_mode
            ),
          ])}
        </b>
      </span>
      {simpleIrrigation.content.simple_irrigation_parameters?.stop_mode ===
      1 ? (
        stopAngle
      ) : (
        <></>
      )}
      {simpleIrrigation.content.simple_irrigation_parameters?.stop_mode ===
      4 ? (
        stopRounds
      ) : (
        <></>
      )}
      {simpleIrrigation.content.simple_irrigation_parameters?.stop_mode !== 0 &&
      simpleIrrigation?.end_date_forecast &&
      simpleIrrigation?.operation_duration_forecast ? (
        <>
          <div style={{ marginBottom: "10px" }}>
            {i18n("IRRIGATION_END_DATE_PREDICTION")}
            <b>
              {i18n("VALUE", [
                moment(simpleIrrigation?.end_date_forecast).format(
                  "DD MMM HH:mm"
                ),
              ])}
            </b>
          </div>
          <div>
            {i18n("IRRIGATION_END_DURATION")}
            <b>
              {i18n("VALUE", [
                parseHHMM(simpleIrrigation?.operation_duration_forecast * 60),
              ])}
            </b>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <span>
          {i18n("HISTORIC_CREATED_AT")}
          <b>
            {i18n("VALUE", [
              `${moment(simpleIrrigation.created).format("DD MMM - HH:mm")}`,
            ])}
          </b>
        </span>
        {simpleIrrigation.created_on_hardware
          ? createdOnHardware
          : createdByUser}
        <span>
          {i18n("CURRENT_ANGLE")}
          <b>
            {i18n("VALUE", [
              ` ${formatFloatValue(simpleIrrigation.current_angle, 1)}º`,
            ])}
          </b>
        </span>
        {!!simpleIrrigation.content.injection_pump_command?.command && (
          <>
            <span>
              {i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS")}
              <b>
                {i18n("VALUE", [
                  irrigationInjectionPump(
                    simpleIrrigation.content.injection_pump_command?.command
                  ),
                ])}
              </b>
            </span>
            <span>
              {i18n("IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED")}
              <b>{simpleIrrigation.content.injection_pump_command?.note}</b>
            </span>
          </>
        )}
      </div>
      <div className="expanded-content__group">{simpleIrrigationContent}</div>
    </div>
  );
}

export default ExpandedSimpleIrrigation;

import React, { useCallback, useState, useEffect, useRef } from "react";
import "./Login.scss";
import icWhiteLogo from "../../../assets/images/logos/logo-white.svg";
import TextField from "@material-ui/core/TextField";
import useNotify from "../../../hooks/tools/useNotify";
import useLogin from "./hooks/useLogin";
import useStyles from "./hooks/useStyles";
import { checkEmail } from "../../../utils/checks/index";
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import Store from "../../../redux/index";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import routes from "../../../routes/routes";
import { useDispatch } from "react-redux";
import { myMSALObj, loginRequest, msalLoginUrl } from "../../../utils/msal";
import { isMobile } from "../../../mobileConfig";
import queryString from "query-string";
import { onEventEnterPressed } from "../../../utils/checks/EnterPressed";
import { setLanguage } from "../../../redux/setting/actions";
import { Language } from "../../../redux/setting/types";
import SimpleLanguageSelector from "../../../components/SimpleLanguageSelector/SimpleLanguageSelector";
import { i18n } from "../../../i18n/i18nText";

const Login: React.FC<any> = (props) => {
  const notify = useNotify();
  const [pwdLogin, loading] = useLogin();
  const classes = useStyles(props);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const passwordRef = useRef<HTMLInputElement>(null);

  document.title = "Login";

  const parsed = queryString.parse(location.search) as { type?: string };

  useEffect(() => {
    if (parsed.type == "unauthorized") {
      notify("NOT_FOUND_TOKEN", "error");
    }
  }, [parsed.type]);
  // const [profile, ,] = useProfile();

  const msal = myMSALObj;

  function getLanguage() {
    return Store().store.getState().setting.language;
  }

  useEffect(() => {
    if (
      location.search.includes("user") ||
      location.search.includes("pass") ||
      location.search.includes("lang")
    ) {
      let params = new URLSearchParams(window.location.search);
      if (params.has("user")) setUsername(params.get("user"));
      if (params.has("pass")) setPassword(params.get("pass"));
      if (
        params.has("lang") &&
        params.get("lang") != getLanguage().toString()
      ) {
        dispatch(setLanguage(params.get("lang") as Language));
      }
    }
  }, []);

  const handleNextButtonClicked = useCallback(() => {
    setSigningIn(true);
    if (username === "" || password === "") {
      notify("LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED", "error");
      setSigningIn(false);
      return;
    }

    if (checkEmail(username)) {
      pwdLogin({ email: username }, password);
    } else {
      pwdLogin({ username }, password);
    }
    // eslint-disable-next-line
  }, [pwdLogin, username, password]);

  const handleEnterKey = (e: any) => {
    if (e.key === "Enter") {
      handleNextButtonClicked();
    }
  };

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    setSigningIn(loading);
  }, [loading]);

  return (
    <>
      <div className="login-page">
        <img className="login-page__logo" src={icWhiteLogo} alt="" />
        <div className="login-page__card">
          <>
            <div className="login-page__title-container">
              <div className="login-page__title">
                <>{i18n("LOGIN_WELCOME")}</>
              </div>
              <div className="login-page__language-overlay">
                <SimpleLanguageSelector />
              </div>
            </div>
            <div className="login-page__input">
              {signingIn ? <LinearProgress /> : null}
              <TextField
                className={classes.textField}
                id="username"
                type="email"
                label={<>{i18n("LOGIN_USERNAME_OR_EMAIL")}</>}
                onKeyPress={(e) => {
                  if (onEventEnterPressed(e)) {
                    passwordRef.current?.focus();
                  }
                }}
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <FormControl className={(classes.margin, classes.textField)}>
                <InputLabel htmlFor="standard-adornment-password">
                  {<>{i18n("LOGIN_PASSWORD")}</>}
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={password}
                  inputRef={passwordRef}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (onEventEnterPressed(e)) {
                      handleNextButtonClicked();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <Button
              style={{
                marginBottom: isMobile() ? "20px" : 0,
              }}
              className={classes.buttonLogin}
              color="primary"
              variant="contained"
              onClick={handleNextButtonClicked}
              disabled={signingIn}
            >
              <>{i18n("LOGIN_SIGN_IN")}</>
            </Button>

            <button
              type="button"
              className="login-page__link"
              onClick={() => {
                navigate(routes.AUTH.FORGET_PASSWORD);
              }}
            >
              {i18n("FORGET_PASSWORD_OPTION_PASSWORD")}
            </button>

            <div className="login-page__buttons"></div>

            <div className="login-page__privacy-container">
              <>{i18n("TERMS_AND_CONDITIONS_LOGIN_1")}</>
              <>
                {" "}
                <a
                  className="login-page__privacy-link"
                  href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                  target="__blank"
                >
                  {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                </a>
                .{" "}
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Login;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pivot } from "../../redux/pivots/types";
import { ApplicationState } from "../../redux";
import checkExists from "../../utils/checks/checkExists";
import { setPivots } from "../../redux/pivots/actions";
import { coreHTTPClient } from "../../services/webclient";
import { isOldProtocol } from "../../utils/models/pivots";

const usePivots = (
  farmID: number,
  useFromCacheIfPossible?: boolean
): [boolean, Pivot[], () => Promise<void>] => {
  const dispatch = useDispatch();
  const pivots = useSelector((state: ApplicationState) => state.pivots.list);

  const [loading, setLoading] = useState(false);

  function rotatePoint(point, origin, angle, clockwise = false) {
    if (clockwise) angle = Math.abs(360 - angle);
    const angleRad = (angle * Math.PI) / 180.0;

    return {
      lat:
        Math.cos(angleRad) * (point.lat - origin.lat) -
        Math.sin(angleRad) * (point.lng - origin.lng) +
        origin.lat,
      lng:
        Math.sin(angleRad) * (point.lat - origin.lat) +
        Math.cos(angleRad) * (point.lng - origin.lng) +
        origin.lng,
    };
  }

  async function getPivots() {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await coreHTTPClient.get(`v3/farms/${farmID}/pivots/`);

        if (checkExists(["data", response, "__cascade"])) {
          let r = response.data as Pivot[];
          for (let i = 0; i < r.length; i++) {
            let marker = "";
            if (isOldProtocol(r[i])) {
              let point: string = r[i].config.reference;
              let origin: string = r[i].config.center;
              let po = {
                lat: parseFloat(point.split(",")[0]),
                lng: parseFloat(point.split(",")[1]),
              };
              let or = {
                lat: parseFloat(origin.split(",")[0]),
                lng: parseFloat(origin.split(",")[1]),
              };
              let angle = r[i].latest_gps_stream
                ? parseInt(r[i].latest_gps_stream.angle)
                : -1;
              if (angle < 0) {
                marker = "";
              } else {
                const l = rotatePoint(po, or, r[i].latest_gps_stream.angle);
                marker = `&path=color:0xc300aaff|weight:5|${or.lat}, ${or.lng}|${l.lat}, ${l.lng}`;
              }
            } else {
              if (r[i].automation_type == 0 || r[i].automation_type == 1) {
                let or = {
                  lat: parseFloat(
                    r[i].controllerconfig.content.pivot_positions
                      .latitude_center
                  ),
                  lng: parseFloat(
                    r[i].controllerconfig.content.pivot_positions
                      .longitude_center
                  ),
                };
                let po = {
                  lat: parseFloat(
                    r[i].controllerconfig.content.pivot_positions
                      .latitude_reference
                  ),
                  lng: parseFloat(
                    r[i].controllerconfig.content.pivot_positions
                      .longitude_reference
                  ),
                };
                let angle =
                  r[i].controllerstream_gps?.content?.current_angle
                    ?.current_angle !== undefined
                    ? parseInt(
                        r[i].controllerstream_gps?.content?.current_angle
                          ?.current_angle
                      )
                    : -1;
                if (angle < 0) {
                  marker = "";
                } else {
                  const l = rotatePoint(po, or, angle);
                  marker = `&path=color:0xc300aaff|weight:5|${or.lat}, ${or.lng}|${l.lat}, ${l.lng}`;
                }
              } else {
                const cp = {
                  lat: parseFloat(
                    r[i].controllerstream_gps.content.latitude_longitude_gps
                      .latitude_gps
                  ),
                  lng: parseFloat(
                    r[i].controllerstream_gps.content.latitude_longitude_gps
                      .longitude_gps
                  ),
                };
                marker = `&markers=color:0xc3fc49ff|${cp.lat},${cp.lng}`;
              }
            }
            r[i].image = r[i].image + process.env.MAPS_KEY + marker;
            r[i].type = "FULL";
          }

          //
          //dispatch(setPivots(response.data));

          dispatch(setPivots(r));
        }
        //novo ultimo
        //
      } catch (err) {
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (useFromCacheIfPossible && pivots.length != 0) return;

    getPivots();
    // eslint-disable-next-line
  }, [farmID]);

  return [loading, pivots, getPivots];
};

export default usePivots;

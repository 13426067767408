import { useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import useNotify from "./useNotify";

const usePivotIsOnline = (): [
  boolean,
  any,
  (radioID: string) => Promise<void>
] => {
  const notify = useNotify();
  const [isOnline, setIsOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function getPivotIsOnline(radioID: string) {
    await new Promise(async (resolve) => {
      setIsLoading(true);
      try {
        if (radioID) {
          const response = await coreHTTPClient.get(
            `/v3/devices/${radioID}/is-online/`
          );
          if (checkExists(["data", response, "__cascade"])) {
            setIsOnline(response.data["is_online"]);
          }
        }
      } catch (err) {
        console.error(err);
        notify("GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE", "error");
      } finally {
        setIsLoading(false);
      }
    });
  }

  return [isLoading, isOnline, getPivotIsOnline];
};

export default usePivotIsOnline;

import React from "react";

const PluviometerIcon = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ fill: color, width: `${size}px`, height: `${size}px` }}
  >
    <path d="M 9 2 C 9 2 2 9 2 15 C 2 19.056 4.944 22 9 22 C 13.056 22 16 19.056 16 15 C 16 9 9 2 9 2 z M 17 3 L 17 5 L 20 5 L 20 7 L 18 7 L 18 9 L 20 9 L 20 11 L 17 11 L 17 13 L 20 13 L 20 15 L 18 15 L 18 17 L 20 17 L 20 19 L 17 19 L 17 21 L 20 21 L 21 21 L 22 21 L 22 3 L 21 3 L 20 3 L 17 3 z M 9 5 C 10.143 6.474 12.27275 9.234 13.34375 12 L 6.625 12 C 6.245 13.039 6 14.065 6 15 C 6 17.597 7.602 19.49325 10 19.90625 C 10.169 19.87725 9.531 20 9 20 C 6.056 20 4 17.944 4 15 C 4 11.453 7.436 7.02 9 5 z" />
  </svg>
);

export default PluviometerIcon;

import { i18n } from "../../i18n/i18nText";
import { Update } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

export const colorStatus = (bauerPivotStatus) => {
  if (
    bauerPivotStatus == 11 ||
    bauerPivotStatus == 12 ||
    bauerPivotStatus == 16 ||
    bauerPivotStatus == 18 ||
    bauerPivotStatus == 19 ||
    bauerPivotStatus == 20 ||
    bauerPivotStatus == 21 ||
    bauerPivotStatus == 24 ||
    bauerPivotStatus == 25 ||
    bauerPivotStatus == 26
  )
    return "#e92400";
  else if (
    bauerPivotStatus == 3 ||
    bauerPivotStatus == 4 ||
    bauerPivotStatus == 5 ||
    bauerPivotStatus == 7 ||
    bauerPivotStatus == 8 ||
    bauerPivotStatus == 10 ||
    bauerPivotStatus == 17 ||
    bauerPivotStatus == 27 ||
    bauerPivotStatus == 40 ||
    bauerPivotStatus == 41
  )
    return "#ff8c00";
  else if (bauerPivotStatus == 1 || bauerPivotStatus == 15) return "#4679ed";
  else if (
    bauerPivotStatus == 2 ||
    bauerPivotStatus == 13 ||
    bauerPivotStatus == 14 ||
    bauerPivotStatus == 28
  )
    return "#207833";
  else return "#ffd700";
};

export const bauerPivotStatus = (number: number) => {
  switch (number) {
    case 0:
      return i18n("BAUER_PIVOT_STATUS_0");
    case 1:
      return i18n("BAUER_PIVOT_STATUS_1");
    case 2:
      return i18n("BAUER_PIVOT_STATUS_2");
    case 3:
      return i18n("BAUER_PIVOT_STATUS_3");
    case 4:
      return i18n("BAUER_PIVOT_STATUS_4");
    case 5:
      return i18n("BAUER_PIVOT_STATUS_5");
    case 6:
      return i18n("BAUER_PIVOT_STATUS_6");
    case 7:
      return i18n("BAUER_PIVOT_STATUS_7");
    case 8:
      return i18n("BAUER_PIVOT_STATUS_8");
    case 9:
      return i18n("BAUER_PIVOT_STATUS_9");
    case 10:
      return i18n("BAUER_PIVOT_STATUS_10");
    case 11:
      return i18n("BAUER_PIVOT_STATUS_11");
    case 12:
      return i18n("BAUER_PIVOT_STATUS_12");
    case 13:
      return i18n("BAUER_PIVOT_STATUS_13");
    case 14:
      return i18n("BAUER_PIVOT_STATUS_14");
    case 15:
      return i18n("BAUER_PIVOT_STATUS_15");
    case 16:
      return i18n("BAUER_PIVOT_STATUS_16");
    case 17:
      return i18n("BAUER_PIVOT_STATUS_17");
    case 18:
      return i18n("BAUER_PIVOT_STATUS_18");
    case 19:
      return i18n("BAUER_PIVOT_STATUS_19");
    case 20:
      return i18n("BAUER_PIVOT_STATUS_20");
    case 21:
      return i18n("BAUER_PIVOT_STATUS_21");
    case 22:
      return i18n("BAUER_PIVOT_STATUS_22");
    case 23:
      return i18n("BAUER_PIVOT_STATUS_23");
    case 24:
      return i18n("BAUER_PIVOT_STATUS_24");
    case 25:
      return i18n("BAUER_PIVOT_STATUS_25");
    case 26:
      return i18n("BAUER_PIVOT_STATUS_26");
    case 27:
      return i18n("BAUER_PIVOT_STATUS_27");
    case 28:
      return i18n("BAUER_PIVOT_STATUS_28");
    case 40:
      return i18n("BAUER_PIVOT_STATUS_40");
    case 41:
      return i18n("BAUER_PIVOT_STATUS_41");
    default:
      return i18n("NO_INFO");
  }
};

export const bauerPivotHistoryStatus = (number: number) => {
  switch (number) {
    case 0:
      return i18n("BAUER_PIVOT_STATUS_0");
    case 1:
      return i18n("BAUER_PIVOT_STATUS_1");
    case 2:
      return i18n("BAUER_PIVOT_STATUS_2");
    case 3:
      return i18n("BAUER_PIVOT_STATUS_3");
    case 4:
      return i18n("BAUER_PIVOT_STATUS_4");
    case 5:
      return i18n("BAUER_PIVOT_STATUS_5");
    case 6:
      return i18n("BAUER_PIVOT_STATUS_6");
    case 7:
      return i18n("BAUER_PIVOT_STATUS_7");
    case 8:
      return i18n("BAUER_PIVOT_STATUS_8");
    case 9:
      return i18n("BAUER_PIVOT_STATUS_9");
    case 10:
      return i18n("BAUER_PIVOT_STATUS_10");
    case 11:
      return i18n("BAUER_PIVOT_STATUS_11");
    case 12:
      return i18n("BAUER_PIVOT_STATUS_12");
    case 13:
      return i18n("BAUER_PIVOT_STATUS_13");
    case 14:
      return i18n("BAUER_PIVOT_STATUS_14");
    case 15:
      return i18n("BAUER_PIVOT_STATUS_15");
    case 16:
      return i18n("BAUER_PIVOT_STATUS_16");
    case 17:
      return i18n("BAUER_PIVOT_STATUS_17");
    case 18:
      return i18n("BAUER_PIVOT_STATUS_18");
    case 19:
      return i18n("BAUER_PIVOT_STATUS_19");
    case 20:
      return i18n("BAUER_PIVOT_STATUS_20");
    case 21:
      return i18n("BAUER_PIVOT_STATUS_21");
    case 22:
      return i18n("BAUER_PIVOT_STATUS_22");
    case 23:
      return i18n("BAUER_PIVOT_STATUS_23");
    case 24:
      return i18n("BAUER_PIVOT_STATUS_24");
    case 25:
      return i18n("BAUER_PIVOT_STATUS_25");
    case 26:
      return i18n("BAUER_PIVOT_STATUS_26");
    case 27:
      return i18n("BAUER_PIVOT_STATUS_27");
    case 28:
      return i18n("BAUER_PIVOT_STATUS_28");
    case 40:
      return i18n("BAUER_PIVOT_STATUS_40");
    case 41:
      return i18n("BAUER_PIVOT_STATUS_41");
    default:
      return "";
  }
};

export const bauerPivotCommand = (command: string) => {
  switch (command) {
    case "NEW_CON":
      return i18n("BAUER_PIVOT_STATUS_NEW_CON");
    case "MAN-START":
      return i18n("BAUER_PIVOT_STATUS_MAN_START");
    case "MAN-STOP":
      return i18n("BAUER_PIVOT_STATUS_MAN_STOP");
    case "ACK-FWD":
      return i18n("BAUER_PIVOT_STATUS_ACK_FWD");
    case "ACK-REV":
      return i18n("BAUER_PIVOT_STATUS_ACK_REV");
    case "ACK-STOP":
      return i18n("BAUER_PIVOT_STATUS_ACK_STOP");
    case "START-FWD":
      return i18n("BAUER_PIVOT_STATUS_START_FWD");
    case "START-REV":
      return i18n("BAUER_PIVOT_STATUS_START_REV");
    case "GET-CONFIG":
      return i18n("BAUER_PIVOT_STATUS_GET_CONFIG");
    case "SET-CONFIG":
      return i18n("BAUER_PIVOT_STATUS_SET_CONFIG");
    case "ACK-CONFIG":
      return i18n("BAUER_PIVOT_STATUS_ACK_CONFIG");
    case "ACK-VRI":
      return i18n("BAUER_PIVOT_STATUS_ACK_VRI");
    case "RFTP_ERROR_1":
      return i18n("BAUER_PIVOT_STATUS_RFTP_ERROR_1");
    case "RFTP MAX RETRY":
      return i18n("BAUER_PIVOT_STATUS_RFTP_MAX_RETRY");
    case "RFTP_ERR_FILE":
      return i18n("BAUER_PIVOT_STATUS_RFTP_ERR_FILE");
    case "RFTP_OK":
      return i18n("BAUER_PIVOT_STATUS_RFTP_OK");
    case "ACK-START":
      return i18n("BAUER_PIVOT_STATUS_ACK_START");
    case "STOP-CMD":
      return i18n("BAUER_PIVOT_STATUS_STOP_CMD");
    default:
      return i18n("BAUER_PIVOT_STATUS_DEFAULT");
  }
};

export const bauerPivotSentByUser = (command: string) => {
  switch (command) {
    case "NEW_CON":
      return false;
    case "MAN-START":
      return false;
    case "MAN-STOP":
      return false;
    case "ACK-FWD":
      return false;
    case "ACK-REV":
      return false;
    case "ACK-STOP":
      return false;
    case "START-FWD":
      return true;
    case "START-REV":
      return true;
    case "GET-CONFIG":
      return false;
    case "SET-CONFIG":
      return true;
    case "ACK-CONFIG":
      return false;
    case "ACK-VRI":
      return true;
    case "RFTP_ERROR_1":
      return false;
    case "RFTP MAX RETRY":
      return false;
    case "RFTP_ERR_FILE":
      return false;
    case "RFTP_OK":
      return false;
    case "ACK-START":
      return false;
    case "STOP-CMD":
      return true;
    default:
      return false;
  }
};

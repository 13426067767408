import { combineReducers } from "redux";
// persist
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// reducers
import auth from "./auth/reducer";
import setting from "./setting/reducer";
import farms from "./farms/reducer";
import farm from "./farm/reducer";
import billing from "./billing/reducer";
import pivots from "./pivots/reducer";
import irpds from "./irpds/reducer";
import modal from "./modal/reducer";
import locationModal from "./locationModal/reducer";
import loading from "./loading/reducer";
import userID from "./userID/reducer";
import adm from "./adm/reducer";
import usersFarm from "./usersFarm/reducer";
import filterByDate from "./filterByDate/reducer";
import notifications from "./notifications/reducer";

const persistConfig = {
  key: "auth$v1.0",
  // debug: true,
  storage,
};

export default combineReducers({
  auth: persistReducer(persistConfig, auth),
  setting: persistReducer({ ...persistConfig, key: "language$v1.0" }, setting),
  farm,
  billing,
  farms,
  pivots,
  irpds,
  notifications,
  modal,
  locationModal,
  loading,
  adm,
  filterByDate,
  usersFarm,
  userID: persistReducer({ ...persistConfig, key: "userID$v1.0" }, userID),
});

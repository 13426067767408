import { useState, useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";
import useNotify from "./useNotify";

const useCentralIsOnline = (farmID: number, notifyMensage: string) => {
  const notify = useNotify();
  const [isOnline, setIsOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function centralIsOnline() {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.get(
          `/v3/farms/${farmID}/central_panel/status/`
        );
        setIsOnline(response.data["is_online"]);
      } catch (err) {
        notify(notifyMensage, "error");
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    centralIsOnline();
  }, []);

  return [isOnline, centralIsOnline, isLoading];
};

export default useCentralIsOnline;

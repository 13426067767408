import React, { useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box, Link, TablePagination } from "@material-ui/core";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import moment from "moment";
import { isMobile } from "../../../../../../../../../mobileConfig";
import Store, {
  ApplicationState,
} from "../../../../../../../../../redux/index";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { TablePaginationActions } from "../../../../../../../../../components/GeneralTable/GeneralTable";
import { shallowEqual, useSelector } from "react-redux";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#44b86a",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: isMobile() ? "88vw" : "100%",
    maxHeight: "450px",
  },
  container: {
    width: "100%",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  status_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  status_open: {
    color: "#2370c5",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#cff5f6",
    fontWeight: "bold",
    border: "1px solid #2370c5",
    borderRadius: "4px",
    padding: "2px",
    minWidth: "150px",
  },
  status_paid: {
    color: "#147619",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#d7f7c2",
    fontWeight: "bold",
    border: "1px solid #147619",
    borderRadius: "4px",
    padding: "2px",
    minWidth: "150px",
  },
  status_past_due: {
    color: "#b3093c",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#ffe7f2",
    fontWeight: "bold",
    border: "1px solid #b3093c",
    borderRadius: "4px",
    padding: "2px",
    minWidth: "150px",
  },
  status_void: {
    color: "#545969",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#ebeef1",
    fontWeight: "bold",
    border: "1px solid #545969",
    borderRadius: "4px",
    padding: "2px",
    minWidth: "150px",
  },
  link_pay: {
    color: "#545969",
    fontWeight: "bold",
  },
  link_docs: {
    color: "#545969",
  },
}));

interface Props {
  loading: boolean;
  customerExists: boolean;
  invoices: any[];
  count: number;
  rowsPerPage: number;
  labelDisplayedRows: any;
}

export default function BillingHistoryComponent(props: Props) {
  const {
    customerExists,
    invoices,
    count,
    rowsPerPage,
    labelDisplayedRows,
  } = props;

  const classes = useStyles();

  const [page, setPage] = useState(0);

  function getDueDateText(dueDate: any): any {
    const dueDateFormated = moment(dueDate).format(i18n("DISPLAY_DATE_FORMAT"));
    if (moment(dueDateFormated, i18n("DISPLAY_DATE_FORMAT")).isValid())
      return <>{dueDateFormated}</>;
    else return "-";
  }

  function getStatusText(status: string): any {
    switch (status) {
      case "open":
        return (
          <Box className={classes.status_box}>
            <Box className={classes.status_open}>
              {i18n("BILLING_INVOICE_STATUS_OPEN")}
            </Box>
          </Box>
        );
      case "paid":
        return (
          <Box className={classes.status_box}>
            <Box className={classes.status_paid}>
              {i18n("BILLING_INVOICE_STATUS_PAID")}
            </Box>
          </Box>
        );
      case "past_due":
        return (
          <Box className={classes.status_box}>
            <Box className={classes.status_past_due}>
              {i18n("BILLING_INVOICE_STATUS_PAST_DUE")}
            </Box>
          </Box>
        );
      case "void":
        return (
          <Box className={classes.status_box}>
            <Box className={classes.status_void}>
              {i18n("BILLING_INVOICE_STATUS_VOID")}
            </Box>
          </Box>
        );
      default:
        break;
    }
  }

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  function getAmountText(currency: string, amount: number): string {
    try {
      return new Intl.NumberFormat(`${language}`, {
        style: "currency",
        currency: `${currency}`,
      }).format(amount);
    } catch (error) {
      return new Intl.NumberFormat(`${"pt-BR"}`, {
        style: "currency",
        currency: `${currency}`,
      }).format(amount);
    }
  }

  function getLinkText(status: string, url: string): any {
    switch (status) {
      case "open":
        return (
          <Link
            className={classes.link_pay}
            target="blank"
            href={url}
            color="inherit"
          >
            {i18n("BILLING_INVOICE_LINK_PAY").toUpperCase()}
          </Link>
        );
      case "paid":
        return (
          <Link
            className={classes.link_docs}
            target="blank"
            href={url}
            color="inherit"
          >
            <DescriptionOutlinedIcon />
          </Link>
        );
      case "past_due":
        return (
          <Link
            className={classes.link_pay}
            target="blank"
            href={url}
            color="inherit"
          >
            {i18n("BILLING_INVOICE_LINK_PAY").toUpperCase()}
          </Link>
        );
      case "void":
        return (
          <Link
            className={classes.link_docs}
            target="blank"
            href={url}
            color="inherit"
          >
            <DescriptionOutlinedIcon />
          </Link>
        );
      default:
        break;
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (customerExists && invoices.length === 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
        }}
      >
        {i18n("BILLING_HISTORY_NO_INVOICES_FOUND")}
      </div>
    );
  } else if (customerExists && invoices.length !== 0) {
    return (
      <div className={classes.root}>
        <TableContainer className={classes.container} component={Paper}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  {i18n("BILLING_INVOICE_DUE_DATE")}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {i18n("BILLING_INVOICE_STATUS")}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {i18n("BILLING_INVOICE_AMOUNT")}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {i18n("BILLING_INVOICE_LINK")}
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoices[page].map((invoice) => (
                <StyledTableRow key={invoice.due}>
                  <StyledTableCell align="center">
                    {getDueDateText(invoice.due_date)}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {getStatusText(invoice.status)}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {getAmountText(invoice.currency, invoice.total)}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {getLinkText(invoice.status, invoice.hosted_invoice_url)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onChangePage={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
        }}
      >
        {i18n("BILLING_HISTORY_NO_BILLING_DATA_FOUND")}
      </div>
    );
  }
}

export function DrawLine(
  context: any,
  xCenter: number,
  yCenter: number,
  x: number,
  y: number,
  color: string
) {
  context.beginPath();
  context.moveTo(xCenter, yCenter);
  context.lineTo(xCenter + x, yCenter - y);
  context.lineWidth = 2;
  context.strokeStyle = color;
  context.stroke();
}

export function DrawCircle(
  context: any,
  xCenter: number,
  yCenter: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  color: string
) {
  context.beginPath();
  context.arc(xCenter, yCenter, radius, startAngle, endAngle);
  context.strokeStyle = color;
  context.fillStyle = color;
  context.fill();
  context.stroke();
}

export function DrawArcFoward(
  contextArc: any,
  centerX: number,
  centerY: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  color: string,
  strokeSize: number
) {
  contextArc.beginPath();
  contextArc.arc(centerX, centerY, radius, startAngle, endAngle);
  contextArc.stroke();
  contextArc.arc(
    centerX,
    centerY,
    radius * strokeSize,
    endAngle,
    startAngle,
    true
  );
  contextArc.strokeStyle = color;
  contextArc.fillStyle = color;
  contextArc.stroke();
}

export function DrawArcBackward(
  contextArc: any,
  centerX: number,
  centerY: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  color: string,
  strokeSize: number
) {
  contextArc.beginPath();
  contextArc.arc(centerX, centerY, radius, startAngle, endAngle, true);
  contextArc.stroke();
  contextArc.arc(centerX, centerY, radius * strokeSize, endAngle, startAngle);
  contextArc.strokeStyle = color;
  contextArc.fillStyle = color;
  contextArc.stroke();
}

export function writeOnCanvas(
  context: any,
  x: number,
  y: number,
  text: string,
  color: string
) {
  context.font = "11px Verdana";
  context.fillStyle = color;
  context.fillText(text, x, y);
}

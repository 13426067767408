import React from "react";
import { Irpd } from "../../../../../../../../../../../redux/irpds/types";
import { i18n } from "../../../../../../../../../../../i18n/i18nText";

interface Props {
  irpdAction: any;
  irpd: Irpd;
}

function ExpandedIrpdActionSimpleV5(props: Props) {
  const { irpdAction, irpd } = props;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <div className="expanded-content__row-6-6">
          {irpdAction.created_on_hardware ? (
            i18n("HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL")
          ) : (
            <span>
              {i18n("HISTORIC_IRPD_ACTION_USER")}
              <b>{i18n("VALUE", [`${irpdAction.username}`])}</b>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpandedIrpdActionSimpleV5;

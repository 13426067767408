import usePersistentNotify from "../tools/usePersistentNotify";
import checkExists from "../../utils/checks/checkExists";
import React, { useCallback, useEffect } from "react";
import { i18n } from "../../i18n/i18nText";
import useFarm from "./useFarm";
import { getAuthorization } from "../../services/webclient";
import axios from "axios";
import routes from "../../routes/routes";

const useNotifyCustomerAndDebtorDaily = (
  farmID
): [boolean, (farmID) => void] => {
  const notify = usePersistentNotify();

  const [asyncStatus, , farmActions] = useFarm(-1, true);

  const fetchFromApi = useCallback(async (url, params = {}) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${getAuthorization()}`,
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
        ...params,
      });

      if (checkExists(["data", response, "__cascade"])) {
        return [response.data, true];
      }
      return [{}, true];
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 403) {
        return [{}, false];
      }
      return [{}, true];
    }
  }, []);

  const getDebtor = async (farmID, daily = true) => {
    const url = `/v4/farms/${farmID}/billing/customer/is_debtor/?daily=${daily}`;
    return await fetchFromApi(url);
  };

  const getCustomer = async (farmID) => {
    const url = `/v4/farms/${farmID}/billing/customer/`;
    return await fetchFromApi(url);
  };

  const notifyCustomerAndDebtorDaily = async (farmID) => {
    try {
      const response = await farmActions.get(farmID, true);
      if (checkExists(["is_administrator", response, "__cascade"])) {
        const [debtor, groupPermission] = await getDebtor(farmID);

        if (
          !window.location.href.includes("disabled") &&
          !window.location.href.includes("edit") &&
          farmID &&
          debtor.is_debtor
        )
          window.location.href = routes.DASHBOARD.FARM_DISABLED.replace(
            ":farm",
            `${farmID}`
          );

        if (!Object.keys(debtor).length) {
          const [customer] = await getCustomer(farmID);
          if (!Object.keys(customer).length) {
            renderNotification(
              "customer",
              response.is_administrator,
              groupPermission
            );
          }
        } else {
          renderNotification(
            "debtor",
            response.is_administrator,
            groupPermission,
            debtor
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderNotification = (
    type,
    isAdministrator,
    groupPermission,
    debtor = null
  ) => {
    if (!groupPermission) return;

    let message;
    if (type === "customer") {
      message = isAdministrator ? (
        <>
          <p>
            <b> {i18n("EDIT_BILLING_NOT_FOUND_ADM")}</b>{" "}
            <a
              title={i18n("DEBTOR_NOTIFICANTION_LINK")}
              href={`${process.env.WEB_URL}/farms/${farmID}/edit/?tab=1`}
            >
              <b>{i18n("DEBTOR_NOTIFICANTION_LINK")}</b>
            </a>
            .
          </p>
        </>
      ) : (
        <p>
          <b>{i18n("EDIT_BILLING_NOT_FOUND_USER")}</b>
        </p>
      );
    } else if (
      type === "debtor" &&
      debtor.show_daily_notification &&
      debtor.has_invoice_past_due &&
      !debtor.is_debtor
    ) {
      message = isAdministrator ? (
        <>
          <p>
            {i18n("DEBTOR_NOTIFICANTION_ADM")}{" "}
            <a
              title={i18n("DEBTOR_NOTIFICANTION_LINK")}
              href={`${process.env.WEB_URL}/farms/${farmID}/edit/?tab=1`}
            >
              <b>{i18n("DEBTOR_NOTIFICANTION_LINK")}</b>
            </a>
            .{" "}
            {!!debtor.total_days &&
              i18n("DEBTOR_NOTIFICANTION_COUNT", [debtor.total_days])}
          </p>
        </>
      ) : (
        <p>
          {i18n("DEBTOR_NOTIFICANTION_USER")}{" "}
          {!!debtor.total_days &&
            i18n("DEBTOR_NOTIFICANTION_COUNT", [debtor.total_days])}
        </p>
      );
    }
    if (message && type === "customer") notify(message, "warning");
    else if (message && type === "debtor") notify(message, "error");
  };

  useEffect(() => {
    notifyCustomerAndDebtorDaily(farmID);
  }, [farmID]);

  return [asyncStatus.loading, notifyCustomerAndDebtorDaily];
};

export default useNotifyCustomerAndDebtorDaily;

import { Reducer } from "redux";
import { UserActions } from "./actions";
import { UserState, UserTypes } from "./types";

export const INITIAL_STATE: UserState = {
  userID: -1,
  reseller: false,
};

const reducer: Reducer<UserState, UserActions> = (
  state = INITIAL_STATE,
  action: UserActions
): UserState => {
  const { type, payload }: { type: string; payload: any } = action;

  switch (type) {
    case UserTypes.ADD_USERID:
      return { ...state, userID: payload.userID, reseller: payload.reseller };

    case UserTypes.CLEAR_USERID:
      return { ...state, userID: 0 };

    default:
      return state;
  }
};

export default reducer;

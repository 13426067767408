import React from "react";
import "./EditPivot.scss";
import { useNavigate, useParams } from "react-router";
import usePivot from "../../../../hooks/models/usePivot";
import { isOldProtocol, isV5Protocol } from "../../../../utils/models/pivots";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import NewEditPivotV5 from "./components/EditPivotFormV5/NewEditPivotV5";
import NewEditPivotForm from "./components/EditPivotForm/NewEditPivotForm";
import { isMobile } from "../../../../mobileConfig";
import { EditPivotProvider } from "./components/EditPivotForm/EditPivotProvider/EditPivotProvider";
import { EditPivotV5Provider } from "./components/EditPivotFormV5/EditPivotV5Provider/EditPivotV5Provider";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import DrawerRightEditPivot from "./components/DrawerRightEditPivot/DrawerRightEditPivot";
import MobileZone from "../../../../components/Zones/MobileZone";
import EditFormsPlaceholder from "../../../../components/EditFormsPlaceholder/EditFormsPlaceholder";
import { i18n } from "../../../../i18n/i18nText";

export interface EditPivotParams {
  farm: string;
  pivot: string;
}

export enum MessageStatus {
  WAITING = -1,
  NOT_SENT = 0,
  SENT = 1,
  DELIVERED = 2,
  ERROR = 3,
}

export interface RadioStatuses {
  control: MessageStatus;
  monitor: MessageStatus;
  pump: MessageStatus;
}

function EditPivot() {
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  const navigate = useNavigate();
  const [asyncStatus, pivot, actions] = usePivot(farmID, pivotID);

  if (asyncStatus.done && pivot !== undefined) {
    if (isOldProtocol(pivot)) {
      return (
        <EditPivotProvider pivot={pivot}>
          <DrawerRightEditPivot pivot={pivot} />

          <div className={isMobile() ? "edit-pivot-mobile" : "edit-pivot"}>
            <NewEditPivotForm
              farmID={farmID}
              patchPivot={actions.patch}
              pivot={pivot}
            />
          </div>
        </EditPivotProvider>
      );
    } else if (isV5Protocol(pivot)) {
      return (
        <EditPivotV5Provider pivot={pivot}>
          <DrawerRightEditPivot pivot={pivot} />

          <div className={isMobile() ? "edit-pivot-mobile" : "edit-pivot"}>
            <NewEditPivotV5
              farmID={farmID}
              patchPivot={actions.patch}
              pivot={pivot}
            />
          </div>
        </EditPivotV5Provider>
      );
    }
  }

  return (
    <div className={isMobile() ? "edit-pivot-mobile" : "edit-pivot"}>
      <DesktopZone>
        <CustomCircularProgress />
      </DesktopZone>

      <MobileZone>
        <EditFormsPlaceholder
          centerElement={
            <h2>
              <>{i18n("EDIT_PIVOT_LABEL")}</>
            </h2>
          }
          goBack={() => {
            navigate(`farms/${farmID}/pivots/${pivotID}`);
          }}
        />
      </MobileZone>
    </div>
  );
}

export default EditPivot;

import {
  FilterByDateState,
  FilterByDateTypes,
  FilterTypesChoices,
} from "./types";
import { FilterByDateActions } from "./actions";
import moment from "moment";

const INITIAL_STATE: FilterByDateState = {
  data: {
    start_date: moment().subtract(1, "month"),
    end_date: moment(),
    type: FilterTypesChoices.OTHER,
  },
};

function reducer(state = INITIAL_STATE, action: FilterByDateActions) {
  const { type }: { type: string } = action;

  switch (type) {
    case FilterByDateTypes.SET_FILTER_BY_DATE:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export default reducer;

import React from "react";
import { Grid, Button } from "@material-ui/core";
import { i18n, i18nTextId } from "../../../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../../../mobileConfig";
import { PaymentMethodOptions } from "../PaymentMethodContainer";

interface Props {
  methods: PaymentMethodOptions[];
}

const PaymentMethodDialogContent = (props: Props) => {
  const { methods } = props;

  const handleLanguageChange = (method: PaymentMethodOptions) => {
    const externalLink = method.link;
    window.open(externalLink, "_blank");
  };

  return (
    <div style={{ paddingBottom: "25px" }}>
      <Grid container spacing={2}>
        {methods.map((method) => (
          <Grid
            item
            container={isMobile()}
            key={method.id}
            direction="row"
            alignItems="center"
          >
            <Button
              variant="outlined"
              style={{
                borderColor: "#ddd",
                borderRadius: "20px",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                minHeight: "165px",
                width: "185px",
                margin: "0 auto",
              }}
              disabled={method.disabled}
              onClick={() => handleLanguageChange(method)}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>{method.icon}</Grid>
                <Grid item>{i18n(method.name as i18nTextId)}</Grid>
              </Grid>
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PaymentMethodDialogContent;

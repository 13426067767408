const countries = [
  {
    content: "COUNTRY_AFGHANISTAN",
    iso: "AF",
    phone: "0093",
  },
  {
    content: "COUNTRY_SOUTH_AFRICA",
    iso: "ZA",
    phone: "0027",
  },
  {
    content: "COUNTRY_ALBANIA",
    iso: "AL",
    phone: "0355",
  },
  {
    content: "COUNTRY_GERMANY",
    iso: "DE",
    phone: "0049",
  },
  {
    content: "COUNTRY_ANDORRA",
    iso: "AD",
    phone: "0376",
  },
  {
    content: "COUNTRY_ANGOLA",
    iso: "AO",
    phone: "0244",
  },
  {
    content: "COUNTRY_ANGUILLA",
    iso: "AI",
    phone: "1264",
  },
  {
    content: "COUNTRY_ANTARCTICA",
    iso: "AQ",
    phone: "0672",
  },
  {
    content: "COUNTRY_ANTIGUA_AND_BARBUDA",
    iso: "AG",
    phone: "1268",
  },
  {
    content: "COUNTRY_SAUDI_ARABIA",
    iso: "SA",
    phone: "0966",
  },
  {
    content: "COUNTRY_ALGERIA",
    iso: "DZ",
    phone: "0213",
  },
  {
    content: "COUNTRY_ARGENTINA",
    iso: "AR",
    phone: "0054",
  },
  {
    content: "COUNTRY_ARMENIA",
    iso: "AM",
    phone: "0374",
  },
  {
    content: "COUNTRY_ARUBA",
    iso: "AW",
    phone: "0297",
  },
  {
    content: "COUNTRY_AUSTRALIA",
    iso: "AU",
    phone: "0061",
  },
  {
    content: "COUNTRY_AUSTRIA",
    iso: "AT",
    phone: "0043",
  },
  {
    content: "COUNTRY_AZERBAIJAN",
    iso: "AZ",
    phone: "0994",
  },
  {
    content: "COUNTRY_BAHAMAS",
    iso: "BS",
    phone: "1242",
  },
  {
    content: "COUNTRY_BAHRAIN",
    iso: "BH",
    phone: "0973",
  },
  {
    content: "COUNTRY_BANGLADESH",
    iso: "BD",
    phone: "0880",
  },
  {
    content: "COUNTRY_BARBADOS",
    iso: "BB",
    phone: "0246",
  },
  {
    content: "COUNTRY_BELARUS",
    iso: "BY",
    phone: "0375",
  },
  {
    content: "COUNTRY_BELGIUM",
    iso: "BE",
    phone: "0032",
  },
  {
    content: "COUNTRY_BELIZE",
    iso: "BZ",
    phone: "0501",
  },
  {
    content: "COUNTRY_BENIN",
    iso: "BJ",
    phone: "0229",
  },
  {
    content: "COUNTRY_BERMUDA",
    iso: "BM",
    phone: "1441",
  },
  {
    content: "COUNTRY_BOLIVIA",
    iso: "BO",
    phone: "0591",
  },
  {
    content: "COUNTRY_BOSNIA_AND_HERZEGOVINA",
    iso: "BA",
    phone: "0387",
  },
  {
    content: "COUNTRY_BOTSWANA",
    iso: "BW",
    phone: "0267",
  },
  {
    content: "COUNTRY_BRAZIL",
    iso: "BR",
    phone: "0055",
  },
  {
    content: "COUNTRY_BRUNEI",
    iso: "BN",
    phone: "0673",
  },
  {
    content: "COUNTRY_BULGARIA",
    iso: "BG",
    phone: "0359",
  },
  {
    content: "COUNTRY_BURKINA_FASO",
    iso: "BF",
    phone: "0226",
  },
  {
    content: "COUNTRY_BURUNDI",
    iso: "BI",
    phone: "0257",
  },
  {
    content: "COUNTRY_BHUTAN",
    iso: "BT",
    phone: "0975",
  },
  {
    content: "COUNTRY_CAPE_VERDE",
    iso: "CV",
    phone: "0238",
  },
  {
    content: "COUNTRY_CAMEROON",
    iso: "CM",
    phone: "0237",
  },
  {
    content: "COUNTRY_CAMBODIA",
    iso: "KH",
    phone: "0855",
  },
  {
    content: "COUNTRY_CANADA",
    iso: "CA",
    phone: "0001",
  },
  {
    content: "COUNTRY_CANARY_ISLANDS",
    iso: "IC",
    phone: "0",
  },
  {
    content: "COUNTRY_KAZAKHSTAN",
    iso: "KZ",
    phone: "0007",
  },
  {
    content: "COUNTRY_CEUTA_AND_MELILLA",
    iso: "EA",
    phone: "0",
  },
  {
    content: "COUNTRY_CHAD",
    iso: "TD",
    phone: "0235",
  },
  {
    content: "COUNTRY_CHILE",
    iso: "CL",
    phone: "0056",
  },
  {
    content: "COUNTRY_CHINA",
    iso: "CN",
    phone: "0086",
  },
  {
    content: "COUNTRY_CYPRUS",
    iso: "CY",
    phone: "0357",
  },
  {
    content: "COUNTRY_SINGAPORE",
    iso: "SG",
    phone: "0065",
  },
  {
    content: "COUNTRY_COLOMBIA",
    iso: "CO",
    phone: "0057",
  },
  {
    content: "COUNTRY_COMOROS",
    iso: "KM",
    phone: "0269",
  },
  {
    content: "COUNTRY_CONGO_REPUBLIC",
    iso: "CG",
    phone: "0242",
  },
  {
    content: "COUNTRY_NORTH_KOREA",
    iso: "KP",
    phone: "0850",
  },
  {
    content: "COUNTRY_SOUTH_KOREA",
    iso: "KR",
    phone: "0082",
  },
  {
    content: "COUNTRY_CTE_DIVOIRE",
    iso: "CI",
    phone: "0225",
  },
  {
    content: "COUNTRY_COSTA_RICA",
    iso: "CR",
    phone: "0506",
  },
  {
    content: "COUNTRY_CROATIA",
    iso: "HR",
    phone: "0385",
  },
  {
    content: "COUNTRY_CUBA",
    iso: "CU",
    phone: "0053",
  },
  {
    content: "COUNTRY_CURAAO",
    iso: "CW",
    phone: "0",
  },
  {
    content: "COUNTRY_DIEGO_GARCIA",
    iso: "DG",
    phone: "0",
  },
  {
    content: "COUNTRY_DENMARK",
    iso: "DK",
    phone: "0045",
  },
  {
    content: "COUNTRY_DJIBOUTI",
    iso: "DJ",
    phone: "0253",
  },
  {
    content: "COUNTRY_DOMINICA",
    iso: "DM",
    phone: "1767",
  },
  {
    content: "COUNTRY_EGYPT",
    iso: "EG",
    phone: "0020",
  },
  {
    content: "COUNTRY_EL_SALVADOR",
    iso: "SV",
    phone: "0503",
  },
  {
    content: "COUNTRY_UNITED_ARAB_EMIRATES",
    iso: "AE",
    phone: "0971",
  },
  {
    content: "COUNTRY_ECUADOR",
    iso: "EC",
    phone: "0593",
  },
  {
    content: "COUNTRY_ERITREA",
    iso: "ER",
    phone: "0291",
  },
  {
    content: "COUNTRY_SLOVAKIA",
    iso: "SK",
    phone: "0421",
  },
  {
    content: "COUNTRY_SLOVENIA",
    iso: "SI",
    phone: "0386",
  },
  {
    content: "COUNTRY_SPAIN",
    iso: "ES",
    phone: "0034",
  },
  {
    content: "COUNTRY_UNITED_STATES",
    iso: "US",
    phone: "0001",
  },
  {
    content: "COUNTRY_ESTONIA",
    iso: "EE",
    phone: "0372",
  },
  {
    content: "COUNTRY_ETHIOPIA",
    iso: "ET",
    phone: "0251",
  },
  {
    content: "COUNTRY_FIJI",
    iso: "FJ",
    phone: "0679",
  },
  {
    content: "COUNTRY_PHILIPPINES",
    iso: "PH",
    phone: "0063",
  },
  {
    content: "COUNTRY_FINLAND",
    iso: "FI",
    phone: "0358",
  },
  {
    content: "COUNTRY_FRANCE",
    iso: "FR",
    phone: "0033",
  },
  {
    content: "COUNTRY_GABON",
    iso: "GA",
    phone: "0241",
  },
  {
    content: "COUNTRY_GAMBIA",
    iso: "GM",
    phone: "0220",
  },
  {
    content: "COUNTRY_GHANA",
    iso: "GH",
    phone: "0233",
  },
  {
    content: "COUNTRY_GEORGIA",
    iso: "GE",
    phone: "0995",
  },
  {
    content: "COUNTRY_GIBRALTAR",
    iso: "GI",
    phone: "0350",
  },
  {
    content: "COUNTRY_UNITED_KINGDOM",
    iso: "GB",
    phone: "0044",
  },
  {
    content: "COUNTRY_GRENADA",
    iso: "GD",
    phone: "1473",
  },
  {
    content: "COUNTRY_GREECE",
    iso: "GR",
    phone: "0030",
  },
  {
    content: "COUNTRY_GREENLAND",
    iso: "GL",
    phone: "0299",
  },
  {
    content: "COUNTRY_GUADELOUPE",
    iso: "GP",
    phone: "0590",
  },
  {
    content: "COUNTRY_GUAM",
    iso: "GU",
    phone: "1671",
  },
  {
    content: "COUNTRY_GUATEMALA",
    iso: "GT",
    phone: "0502",
  },
  {
    content: "COUNTRY_GUERNSEY",
    iso: "GG",
    phone: "0",
  },
  {
    content: "COUNTRY_GUYANA",
    iso: "GY",
    phone: "0592",
  },
  {
    content: "COUNTRY_FRENCH_GUIANA",
    iso: "GF",
    phone: "0594",
  },
  {
    content: "COUNTRY_GUINEA",
    iso: "GN",
    phone: "0224",
  },
  {
    content: "COUNTRY_EQUATORIAL_GUINEA",
    iso: "GQ",
    phone: "0240",
  },
  {
    content: "COUNTRY_GUINEA_BISSAU",
    iso: "GW",
    phone: "0245",
  },
  {
    content: "COUNTRY_HAITI",
    iso: "HT",
    phone: "0509",
  },
  {
    content: "COUNTRY_NETHERLANDS",
    iso: "NL",
    phone: "0031",
  },
  {
    content: "COUNTRY_HONDURAS",
    iso: "HN",
    phone: "0504",
  },
  {
    content: "COUNTRY_HONG_KONG",
    iso: "HK",
    phone: "0852",
  },
  {
    content: "COUNTRY_HUNGARY",
    iso: "HU",
    phone: "0036",
  },
  {
    content: "COUNTRY_YEMEN",
    iso: "YE",
    phone: "0967",
  },
  {
    content: "COUNTRY_BOUVET_ISLAND",
    iso: "BV",
    phone: "0047",
  },
  {
    content: "COUNTRY_ASCENSION_ISLAND",
    iso: "AC",
    phone: "0",
  },
  {
    content: "COUNTRY_CLIPPERTON_ISLAND",
    iso: "CP",
    phone: "0",
  },
  {
    content: "COUNTRY_ISLE_OF_MAN",
    iso: "IM",
    phone: "0",
  },
  {
    content: "COUNTRY_CHRISTMAS_ISLAND",
    iso: "CX",
    phone: "0061",
  },
  {
    content: "COUNTRY_PITCAIRN_ISLANDS",
    iso: "PN",
    phone: "0672",
  },
  {
    content: "COUNTRY_RUNION",
    iso: "RE",
    phone: "0262",
  },
  {
    content: "COUNTRY_LAND_ISLANDS",
    iso: "AX",
    phone: "0",
  },
  {
    content: "COUNTRY_CAYMAN_ISLANDS",
    iso: "KY",
    phone: "1345",
  },
  {
    content: "COUNTRY_COCOS_KEELING_ISLANDS",
    iso: "CC",
    phone: "0672",
  },
  {
    content: "COUNTRY_COOK_ISLANDS",
    iso: "CK",
    phone: "0682",
  },
  {
    content: "COUNTRY_FAROE_ISLANDS",
    iso: "FO",
    phone: "0298",
  },
  {
    content: "COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS",
    iso: "GS",
    phone: "0500",
  },
  {
    content: "COUNTRY_HEARD_AND_MCDONALD_ISLANDS",
    iso: "HM",
    phone: "0672",
  },
  {
    content: "COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS",
    iso: "FK",
    phone: "0500",
  },
  {
    content: "COUNTRY_NORTHERN_MARIANA_ISLANDS",
    iso: "MP",
    phone: "1670",
  },
  {
    content: "COUNTRY_MARSHALL_ISLANDS",
    iso: "MH",
    phone: "0692",
  },
  {
    content: "COUNTRY_US_OUTLYING_ISLANDS",
    iso: "UM",
    phone: "0001",
  },
  {
    content: "COUNTRY_NORFOLK_ISLAND",
    iso: "NF",
    phone: "0672",
  },
  {
    content: "COUNTRY_SOLOMON_ISLANDS",
    iso: "SB",
    phone: "0677",
  },
  {
    content: "COUNTRY_SEYCHELLES",
    iso: "SC",
    phone: "0248",
  },
  {
    content: "COUNTRY_TOKELAU",
    iso: "TK",
    phone: "0690",
  },
  {
    content: "COUNTRY_TURKS_AND_CAICOS_ISLANDS",
    iso: "TC",
    phone: "1649",
  },
  {
    content: "COUNTRY_US_VIRGIN_ISLANDS",
    iso: "VI",
    phone: "1340",
  },
  {
    content: "COUNTRY_BRITISH_VIRGIN_ISLANDS",
    iso: "VG",
    phone: "1284",
  },
  {
    content: "COUNTRY_INDIA",
    iso: "IN",
    phone: "0091",
  },
  {
    content: "COUNTRY_INDONESIA",
    iso: "ID",
    phone: "0062",
  },
  {
    content: "COUNTRY_IRAN",
    iso: "IR",
    phone: "0098",
  },
  {
    content: "COUNTRY_IRAQ",
    iso: "IQ",
    phone: "0964",
  },
  {
    content: "COUNTRY_IRELAND",
    iso: "IE",
    phone: "0353",
  },
  {
    content: "COUNTRY_ICELAND",
    iso: "IS",
    phone: "0354",
  },
  {
    content: "COUNTRY_ISRAEL",
    iso: "IL",
    phone: "0972",
  },
  {
    content: "COUNTRY_ITALY",
    iso: "IT",
    phone: "0039",
  },
  {
    content: "COUNTRY_JAMAICA",
    iso: "JM",
    phone: "1876",
  },
  {
    content: "COUNTRY_JAPAN",
    iso: "JP",
    phone: "0081",
  },
  {
    content: "COUNTRY_JERSEY",
    iso: "JE",
    phone: "0",
  },
  {
    content: "COUNTRY_JORDAN",
    iso: "JO",
    phone: "0962",
  },
  {
    content: "COUNTRY_KIRIBATI",
    iso: "KI",
    phone: "0686",
  },
  {
    content: "COUNTRY_KOSOVO",
    iso: "XK",
    phone: "0",
  },
  {
    content: "COUNTRY_KUWAIT",
    iso: "KW",
    phone: "0965",
  },
  {
    content: "COUNTRY_LAOS",
    iso: "LA",
    phone: "0856",
  },
  {
    content: "COUNTRY_LESOTHO",
    iso: "LS",
    phone: "0266",
  },
  {
    content: "COUNTRY_LATVIA",
    iso: "LV",
    phone: "0371",
  },
  {
    content: "COUNTRY_LEBANON",
    iso: "LB",
    phone: "0961",
  },
  {
    content: "COUNTRY_LIBERIA",
    iso: "LR",
    phone: "0231",
  },
  {
    content: "COUNTRY_LIBYA",
    iso: "LY",
    phone: "0218",
  },
  {
    content: "COUNTRY_LIECHTENSTEIN",
    iso: "LI",
    phone: "0423",
  },
  {
    content: "COUNTRY_LITHUANIA",
    iso: "LT",
    phone: "0370",
  },
  {
    content: "COUNTRY_LUXEMBOURG",
    iso: "LU",
    phone: "0352",
  },
  {
    content: "COUNTRY_MACAU",
    iso: "MO",
    phone: "0853",
  },
  {
    content: "COUNTRY_MACEDONIA_FYROM",
    iso: "MK",
    phone: "0389",
  },
  {
    content: "COUNTRY_MADAGASCAR",
    iso: "MG",
    phone: "0261",
  },
  {
    content: "COUNTRY_MALAYSIA",
    iso: "MY",
    phone: "0060",
  },
  {
    content: "COUNTRY_MALAWI",
    iso: "MW",
    phone: "0265",
  },
  {
    content: "COUNTRY_MALDIVES",
    iso: "MV",
    phone: "0960",
  },
  {
    content: "COUNTRY_MALI",
    iso: "ML",
    phone: "0223",
  },
  {
    content: "COUNTRY_MALTA",
    iso: "MT",
    phone: "0356",
  },
  {
    content: "COUNTRY_MOROCCO",
    iso: "MA",
    phone: "0212",
  },
  {
    content: "COUNTRY_MARTINIQUE",
    iso: "MQ",
    phone: "0596",
  },
  {
    content: "COUNTRY_MAURITIUS",
    iso: "MU",
    phone: "0230",
  },
  {
    content: "COUNTRY_MAURITANIA",
    iso: "MR",
    phone: "0222",
  },
  {
    content: "COUNTRY_MAYOTTE",
    iso: "YT",
    phone: "0269",
  },
  {
    content: "COUNTRY_MEXICO",
    iso: "MX",
    phone: "0052",
  },
  {
    content: "COUNTRY_MICRONESIA",
    iso: "FM",
    phone: "0691",
  },
  {
    content: "COUNTRY_MOZAMBIQUE",
    iso: "MZ",
    phone: "0258",
  },
  {
    content: "COUNTRY_MOLDOVA",
    iso: "MD",
    phone: "0373",
  },
  {
    content: "COUNTRY_MONACO",
    iso: "MC",
    phone: "0377",
  },
  {
    content: "COUNTRY_MONGOLIA",
    iso: "MN",
    phone: "0976",
  },
  {
    content: "COUNTRY_MONTENEGRO",
    iso: "ME",
    phone: "0",
  },
  {
    content: "COUNTRY_MONTSERRAT",
    iso: "MS",
    phone: "1664",
  },
  {
    content: "COUNTRY_MYANMAR_BURMA",
    iso: "MM",
    phone: "0095",
  },
  {
    content: "COUNTRY_NAMIBIA",
    iso: "NA",
    phone: "0264",
  },
  {
    content: "COUNTRY_NAURU",
    iso: "NR",
    phone: "0674",
  },
  {
    content: "COUNTRY_NEPAL",
    iso: "NP",
    phone: "0977",
  },
  {
    content: "COUNTRY_NICARAGUA",
    iso: "NI",
    phone: "0505",
  },
  {
    content: "COUNTRY_NIGER",
    iso: "NE",
    phone: "0227",
  },
  {
    content: "COUNTRY_NIGERIA",
    iso: "NG",
    phone: "0234",
  },
  {
    content: "COUNTRY_NIUE",
    iso: "NU",
    phone: "0683",
  },
  {
    content: "COUNTRY_NORWAY",
    iso: "NO",
    phone: "0047",
  },
  {
    content: "COUNTRY_NEW_CALEDONIA",
    iso: "NC",
    phone: "0687",
  },
  {
    content: "COUNTRY_NEW_ZEALAND",
    iso: "NZ",
    phone: "0064",
  },
  {
    content: "COUNTRY_OMAN",
    iso: "OM",
    phone: "0968",
  },
  {
    content: "COUNTRY_CARIBBEAN_NETHERLANDS",
    iso: "BQ",
    phone: "0",
  },
  {
    content: "COUNTRY_PALAU",
    iso: "PW",
    phone: "0680",
  },
  {
    content: "COUNTRY_PALESTINE",
    iso: "PS",
    phone: "0970",
  },
  {
    content: "COUNTRY_PANAMA",
    iso: "PA",
    phone: "0507",
  },
  {
    content: "COUNTRY_PAPUA_NEW_GUINEA",
    iso: "PG",
    phone: "0675",
  },
  {
    content: "COUNTRY_PAKISTAN",
    iso: "PK",
    phone: "0092",
  },
  {
    content: "COUNTRY_PARAGUAY",
    iso: "PY",
    phone: "0595",
  },
  {
    content: "COUNTRY_PERU",
    iso: "PE",
    phone: "0051",
  },
  {
    content: "COUNTRY_FRENCH_POLYNESIA",
    iso: "PF",
    phone: "0689",
  },
  {
    content: "COUNTRY_POLAND",
    iso: "PL",
    phone: "0048",
  },
  {
    content: "COUNTRY_PUERTO_RICO",
    iso: "PR",
    phone: "1787",
  },
  {
    content: "COUNTRY_PORTUGAL",
    iso: "PT",
    phone: "0351",
  },
  {
    content: "COUNTRY_QATAR",
    iso: "QA",
    phone: "0974",
  },
  {
    content: "COUNTRY_KENYA",
    iso: "KE",
    phone: "0254",
  },
  {
    content: "COUNTRY_KYRGYZSTAN",
    iso: "KG",
    phone: "0996",
  },
  {
    content: "COUNTRY_CENTRAL_AFRICAN_REPUBLIC",
    iso: "CF",
    phone: "0236",
  },
  {
    content: "COUNTRY_CONGO_DRC",
    iso: "CD",
    phone: "0242",
  },
  {
    content: "COUNTRY_DOMINICAN_REPUBLIC",
    iso: "DO",
    phone: "1809",
  },
  {
    content: "COUNTRY_CZECH_REPUBLIC",
    iso: "CZ",
    phone: "0420",
  },
  {
    content: "COUNTRY_ROMANIA",
    iso: "RO",
    phone: "0040",
  },
  {
    content: "COUNTRY_RWANDA",
    iso: "RW",
    phone: "0250",
  },
  {
    content: "COUNTRY_RUSSIA",
    iso: "RU",
    phone: "0070",
  },
  {
    content: "COUNTRY_WESTERN_SAHARA",
    iso: "EH",
    phone: "0212",
  },
  {
    content: "COUNTRY_ST_PIERRE_AND_MIQUELON",
    iso: "PM",
    phone: "0508",
  },
  {
    content: "COUNTRY_AMERICAN_SAMOA",
    iso: "AS",
    phone: "1684",
  },
  {
    content: "COUNTRY_SAMOA",
    iso: "WS",
    phone: "0684",
  },
  {
    content: "COUNTRY_SAN_MARINO",
    iso: "SM",
    phone: "0378",
  },
  {
    content: "COUNTRY_ST_HELENA",
    iso: "SH",
    phone: "0290",
  },
  {
    content: "COUNTRY_ST_LUCIA",
    iso: "LC",
    phone: "1758",
  },
  {
    content: "COUNTRY_ST_BARTHLEMY",
    iso: "BL",
    phone: "0",
  },
  {
    content: "COUNTRY_ST_KITTS_AND_NEVIS",
    iso: "KN",
    phone: "1869",
  },
  {
    content: "COUNTRY_ST_MARTIN",
    iso: "MF",
    phone: "0",
  },
  {
    content: "COUNTRY_SINT_MAARTEN",
    iso: "SX",
    phone: "0",
  },
  {
    content: "COUNTRY_SO_TOM_AND_PRNCIPE",
    iso: "ST",
    phone: "0239",
  },
  {
    content: "COUNTRY_ST_VINCENT_AND_GRENADINES",
    iso: "VC",
    phone: "1784",
  },
  {
    content: "COUNTRY_SENEGAL",
    iso: "SN",
    phone: "0221",
  },
  {
    content: "COUNTRY_SIERRA_LEONE",
    iso: "SL",
    phone: "0232",
  },
  {
    content: "COUNTRY_SERBIA",
    iso: "RS",
    phone: "0",
  },
  {
    content: "COUNTRY_SYRIA",
    iso: "SY",
    phone: "0963",
  },
  {
    content: "COUNTRY_SOMALIA",
    iso: "SO",
    phone: "0252",
  },
  {
    content: "COUNTRY_SRI_LANKA",
    iso: "LK",
    phone: "0094",
  },
  {
    content: "COUNTRY_SWAZILAND",
    iso: "SZ",
    phone: "0268",
  },
  {
    content: "COUNTRY_SUDAN",
    iso: "SD",
    phone: "0249",
  },
  {
    content: "COUNTRY_SOUTH_SUDAN",
    iso: "SS",
    phone: "0",
  },
  {
    content: "COUNTRY_SWEDEN",
    iso: "SE",
    phone: "0046",
  },
  {
    content: "COUNTRY_SWITZERLAND",
    iso: "CH",
    phone: "0041",
  },
  {
    content: "COUNTRY_SURINAME",
    iso: "SR",
    phone: "0597",
  },
  {
    content: "COUNTRY_SVALBARD_AND_JAN_MAYEN",
    iso: "SJ",
    phone: "0047",
  },
  {
    content: "COUNTRY_TAJIKISTAN",
    iso: "TJ",
    phone: "0992",
  },
  {
    content: "COUNTRY_THAILAND",
    iso: "TH",
    phone: "0066",
  },
  {
    content: "COUNTRY_TAIWAN",
    iso: "TW",
    phone: "0886",
  },
  {
    content: "COUNTRY_TANZANIA",
    iso: "TZ",
    phone: "0255",
  },
  {
    content: "COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY",
    iso: "IO",
    phone: "0246",
  },
  {
    content: "COUNTRY_FRENCH_SOUTHERN_TERRITORIES",
    iso: "TF",
    phone: "0033",
  },
  {
    content: "COUNTRY_TIMOR_LESTE",
    iso: "TL",
    phone: "0670",
  },
  {
    content: "COUNTRY_TOGO",
    iso: "TG",
    phone: "0228",
  },
  {
    content: "COUNTRY_TONGA",
    iso: "TO",
    phone: "0676",
  },
  {
    content: "COUNTRY_TRINIDAD_AND_TOBAGO",
    iso: "TT",
    phone: "1868",
  },
  {
    content: "COUNTRY_TRISTAN_DA_CUNHA",
    iso: "TA",
    phone: "0",
  },
  {
    content: "COUNTRY_TUNISIA",
    iso: "TN",
    phone: "0216",
  },
  {
    content: "COUNTRY_TURKMENISTAN",
    iso: "TM",
    phone: "7370",
  },
  {
    content: "COUNTRY_TURKEY",
    iso: "TR",
    phone: "0090",
  },
  {
    content: "COUNTRY_TUVALU",
    iso: "TV",
    phone: "0688",
  },
  {
    content: "COUNTRY_UKRAINE",
    iso: "UA",
    phone: "0380",
  },
  {
    content: "COUNTRY_UGANDA",
    iso: "UG",
    phone: "0256",
  },
  {
    content: "COUNTRY_URUGUAY",
    iso: "UY",
    phone: "0598",
  },
  {
    content: "COUNTRY_UZBEKISTAN",
    iso: "UZ",
    phone: "0998",
  },
  {
    content: "COUNTRY_VANUATU",
    iso: "VU",
    phone: "0678",
  },
  {
    content: "COUNTRY_VATICAN_CITY",
    iso: "VA",
    phone: "0039",
  },
  {
    content: "COUNTRY_VENEZUELA",
    iso: "VE",
    phone: "0058",
  },
  {
    content: "COUNTRY_VIETNAM",
    iso: "VN",
    phone: "0084",
  },
  {
    content: "COUNTRY_WALLIS_AND_FUTUNA",
    iso: "WF",
    phone: "0681",
  },
  {
    content: "COUNTRY_ZAMBIA",
    iso: "ZM",
    phone: "0260",
  },
  {
    content: "COUNTRY_ZIMBABWE",
    iso: "ZW",
    phone: "0263",
  },
];

export default countries;

export const getCountryPhone = (iso: string): string => {
  let phone = "";

  countries.forEach((country) => {
    if (country.iso === iso) phone = country.phone;
  });

  return phone;
};

import { formatFloatValue } from "../../../../utils/models/format";

//Tabela com
export const ZOOM_TABLE = [
  {
    zoomLevel: 13,
    distance: 662.902480143 * 2,
    conversionRatio: 0.055,
    conversionRatioReferenceLines: 0.05,
  },
  {
    zoomLevel: 14,
    distance: 662.902480143,
    conversionRatio: 0.11,
    conversionRatioReferenceLines: 0.1,
  },
  {
    zoomLevel: 15,
    distance: 662.902480143 / 2,
    conversionRatio: 0.22,
    conversionRatioReferenceLines: 0.2,
  },
  {
    zoomLevel: 16,
    distance: 330.802486613 / 2,
    conversionRatio: 0.45,
    conversionRatioReferenceLines: 0.42,
  },
  {
    zoomLevel: 17,
    distance: 165.009931689 / 2,
    conversionRatio: 0.9,
    conversionRatioReferenceLines: 0.8,
  },
  {
    zoomLevel: 18,
    distance: 82.3813937538 / 2,
    conversionRatio: 1.8,
    conversionRatioReferenceLines: 1.6,
  },
  {
    zoomLevel: 19,
    distance: 41.3348643162 / 2,
    conversionRatio: 2.6,
    conversionRatioReferenceLines: 2.3,
  },
  {
    zoomLevel: 20,
    distance: 20.7395158774 / 2,
    conversionRatio: 5.3,
    conversionRatioReferenceLines: 4.8,
  },
];

export const BLUE_COLORS_TABLE = [
  "#3356A5",
  "#6689D8",
  "#99BCFF",
  "#CCEFFF",
  "#3356A5",
  "#6689D8",
  "#99BCFF",
  "#CCEFFF",
  "#3356A5",
  "#6689D8",
  "#99BCFF",
  "#CCEFFF",
  "#3356A5",
  "#6689D8",
  "#99BCFF",
  "#CCEFFF",
  "#3356A5",
  "#6689D8",
  "#99BCFF",
  "#CCEFFF",
];

export const GREEN_COLORS_TABLE = [
  "#444430",
  "#385A4C",
  "#427C4B",
  "#81a533",
  "#BCA890",
  "#444430",
  "#385A4C",
  "#427C4B",
  "#81a533",
  "#BCA890",
  "#444430",
  "#385A4C",
  "#427C4B",
  "#81a533",
  "#BCA890",
  "#444430",
  "#385A4C",
  "#427C4B",
  "#81a533",
  "#BCA890",
];

export const ORANGE_COLORS_TABLE = [
  "#cc651b99",
  "#a55d3399",
  "#f7712399",
  "#EF924A99",
  "#ffbb9999",
  "#cc651b99",
  "#a55d3399",
  "#f7712399",
  "#EF924A99",
  "#ffbb9999",
  "#cc651b99",
  "#a55d3399",
  "#f7712399",
  "#EF924A99",
  "#ffbb9999",
  "#cc651b99",
  "#a55d3399",
  "#f7712399",
  "#EF924A99",
  "#ffbb9995",
];

export const GREEN_COLORS_TABLE_DISPLAY_CROPS = [
  "#444430DD",
  "#385A4CDD",
  "#427C4BDD",
  "#81a533DD",
  "#BCA890DD",
  "#444430DD",
  "#385A4CDD",
  "#427C4BDD",
  "#81a533DD",
  "#BCA890DD",
  "#444430DD",
  "#385A4CDD",
  "#427C4BDD",
  "#81a533DD",
  "#BCA890DD",
  "#444430DD",
  "#385A4CDD",
  "#427C4BDD",
  "#81a533DD",
  "#BCA890DD",
];

export function degrees_to_radians(degrees) {
  return degrees * (Math.PI / 180);
}

export function radians_to_degrees(radians) {
  return (radians / Math.PI) * 180;
}

// Corrects the angle for the range 0 - 360
export function angle_correction(angle) {
  let correctedAngle = angle;

  if (angle < 0) {
    correctedAngle = angle + 360;
  } else if (angle > 360) {
    correctedAngle = angle % 360;
  }
  return correctedAngle;
}

// Calculates the coeficients of zoom for a given distance
// Used to substitute the ZOOM_TABLE
export async function calculate_zoom_coeficients(
  distance,
  method = "exponential"
) {
  let zoomLevel = 0;
  let conversionRatio = 0;
  let conversionRatioReferenceLines = 0;

  // y=23.3702−1.4429⋅lnx
  const a1 = 23.3702;
  const b1 = 1.4429;
  zoomLevel = Math.round(a1 - b1 * Math.log(distance));

  if (method == "exponential") {
    // y=e−10.8000+0.6247x
    let a2 = -10.8;
    let b2 = 0.6247;
    conversionRatio = await convertion_ratio_exponential(a2, b2, zoomLevel);

    // y=e−10.8137+0.6196x
    a2 = -10.8137;
    b2 = 0.6196;
    conversionRatioReferenceLines = await convertion_ratio_exponential(
      a2,
      b2,
      zoomLevel
    );
  } else {
    // y=44.6881x^(−0.9015)
    let a2 = 44.6881;
    let b2 = -0.9015;
    conversionRatio = await convertion_ratio_potency(a2, b2, zoomLevel);

    // y=39.0915x^(−0.8940)
    a2 = 39.0915;
    b2 = -0.894;
    conversionRatioReferenceLines = await convertion_ratio_potency(
      a2,
      b2,
      zoomLevel
    );
  }

  return [zoomLevel, conversionRatio, conversionRatioReferenceLines];
}

// Rounds numbers to two decimal places
export function round_to_two_decimal_places(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

function convertion_ratio_exponential(a, b, x) {
  // y=e^(a+b*x)
  return round_to_two_decimal_places(Math.exp(a + b * x));
}

function convertion_ratio_potency(a, b, x) {
  // y=a*x^b
  return round_to_two_decimal_places(a * Math.pow(x, b));
}

export const getDailyMesure = (showPluviometerMeasure, dailyMesure) => {
  if (showPluviometerMeasure && dailyMesure)
    return `${formatFloatValue(dailyMesure, 1)} mm `;
  else return `- mm `;
};

import { useEffect, useState } from "react";
import checkExists from "../../utils/checks/checkExists";
import { coreHTTPClient } from "../../services/webclient";
import { useRecoilState } from "recoil";
import { Repeater, RepeaterStateList } from "../../recoils/RepeaterRecoil";

const useRepeaters = (
  farmID: number,
  useFromCacheIfPossible?: boolean
): [boolean, Repeater[], () => Promise<void>] => {
  const [loading, setLoading] = useState(false);

  const [repeaters, setRepeaters] = useRecoilState(RepeaterStateList);

  async function getRepeaters() {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/repeaters/`
        );
        if (checkExists(["data", response, "__cascade"])) {
          setRepeaters(response.data);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (useFromCacheIfPossible && repeaters.length != 0) return;

    getRepeaters();
    // eslint-disable-next-line
  }, [farmID]);

  return [loading, repeaters, getRepeaters];
};

export default useRepeaters;

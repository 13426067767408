import React from "react";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { Box, Card, Grid } from "@material-ui/core";
import PaymentMethodDialogContent from "./components/PaymentMethodDialogContent";
import PaymentCard from "./components/PaymentCard";
import PaymentMethodSelection from "./components/PaymentMethodUpdateBTN";
import PaymentCardForm from "./components/PaymentCardForm";
import { Alert } from "@material-ui/lab";
import { PaymentMethodCard } from "../../../../../../../../../hooks/models/usePaymentMethod";
import { PaymentMethodOptions } from "./PaymentMethodContainer";
import PaymentMethodRemoveConfirmation from "./components/PaymentMethodRemoveConfirmation";

interface Props {
  paymentMethod: PaymentMethodCard;
  methods: PaymentMethodOptions[];
  hasRecurrence: boolean;
  isMethodCard: boolean;
  deletePaymentMethod: () => void;
}

export default function PaymentMethodComponent(props: Props) {
  const { paymentMethod, methods, hasRecurrence, deletePaymentMethod } = props;

  const method = methods[0];

  return hasRecurrence ? (
    <Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <Grid container justify="center" alignItems="center" spacing={1}>
        <PaymentCard paymentMethod={paymentMethod as PaymentMethodCard} />
      </Grid>
      <Card style={{ padding: "20px", marginTop: "16px" }}>
        <Grid container spacing={2}>
          <PaymentCardForm paymentMethod={paymentMethod as PaymentMethodCard} />
          <Grid container>
            <PaymentMethodSelection method={method} />
            <PaymentMethodRemoveConfirmation
              deletePaymentMethod={deletePaymentMethod}
              methods={methods}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  ) : (
    <Box style={{ display: "grid", gridTemplateColumns: "1fr" }}>
      <Box style={{ margin: "5px 0px 25px" }}>
        <Alert severity="warning">{i18n("PAYMENT_METHOD_WARNNING")}</Alert>
      </Box>
      <Grid container justify="center" alignItems="center" spacing={1}>
        <PaymentMethodDialogContent methods={methods} />
      </Grid>
    </Box>
  );
}

import { createSelector } from "reselect";
import { ApplicationState } from "../index";
import { Pivot } from "./types";

export const getPivot = createSelector(
  (state: ApplicationState, pivotID: number) => ({
    pivotList: state.pivots.list,
    id: pivotID
  }),
  ({ pivotList, id }): Pivot | undefined =>
    pivotList.find(pivot => pivot.id === id)
);

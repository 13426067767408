import React, { useEffect } from "react";
import {
  ControllerStream,
  Pivot,
  ControllerConfig,
} from "../../../../../../../../../../redux/pivots/types";

import {
  getPanelStreamV5Direction,
  getPanelStreamV5Mode,
  PIVOT_IRRIGATING_WET_OR_DRY_REASONS_V5,
  PROGRAMMED_REASONS_V5,
  PTPToMillimeter,
} from "../../../../../../../../../../utils/models/pivots";

import {
  irrigationInjectionPump,
  irrigationType,
} from "../../../../utils/utils";
import { ReactJSX } from "../../../../../../../../../../utils/types";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  panelStreamV5: ControllerStream;
  pivot?: Pivot;
}

function isPressureMeasuredBySensor(
  pivotConfigV5: ControllerConfig,
  center_pressure: number,
  end_tower_pressure: number
): ReactJSX {
  if (
    pivotConfigV5 &&
    pivotConfigV5.content.pressure_config?.read_pressure_by === 2
  ) {
    return (
      <>
        <span>
          {i18n("HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER")}
          <b>
            {i18n("VALUE_AND_UNIT_BAR", [
              `${center_pressure ? center_pressure : 0}`,
            ])}
          </b>
        </span>
        <span>
          {i18n("HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER")}
          <b>
            {i18n("VALUE_AND_UNIT_BAR", [
              `${
                end_tower_pressure ? formatFloatValue(end_tower_pressure) : 0
              }`,
            ])}
          </b>
        </span>
      </>
    );
  }
}

function isPressureMeasuredByPressureSwitch(
  pivotConfigV5: ControllerConfig,
  end_tower_pressure: number
): ReactJSX {
  if (
    pivotConfigV5 &&
    pivotConfigV5.content.pressure_config?.read_pressure_by === 1
  ) {
    return (
      <>
        <span>
          {i18n("HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER")}
          <b>
            {i18n("VALUE_AND_UNIT_BAR", [
              `${
                end_tower_pressure ? formatFloatValue(end_tower_pressure) : 0
              }`,
            ])}
          </b>
        </span>
      </>
    );
  }
}

function ExpandedPanelStreamV5(props: Props): any {
  const { panelStreamV5, pivot } = props;
  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        {PIVOT_IRRIGATING_WET_OR_DRY_REASONS_V5.includes(
          panelStreamV5.content.irrigation_status.irrigation_status
        ) && (
          <span>
            {i18n("HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION")}
            <b>
              {i18n("VALUE", [
                `${irrigationType(
                  panelStreamV5.content.irrigation_status?.irrigation_type
                )}`,
              ])}
            </b>
          </span>
        )}
        {Boolean(
          panelStreamV5.content.devices_current_status?.injection_pump
        ) && (
          <span>
            {i18n("HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION")}
            <b>
              {i18n("VALUE", [
                irrigationInjectionPump(
                  panelStreamV5.content.devices_current_status?.injection_pump
                ),
              ])}
            </b>
          </span>
        )}
        {isPressureMeasuredBySensor(
          pivot.controllerconfig,
          panelStreamV5.content.center_pressure?.center_pressure,
          panelStreamV5?.end_tower_pressure
        )}
        {isPressureMeasuredByPressureSwitch(
          pivot.controllerconfig,
          panelStreamV5?.end_tower_pressure
        )}
        <span>
          {i18n("HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL")}
          <b>
            {panelStreamV5.content?.operation_time?.total_hour &&
            panelStreamV5.content?.operation_time?.total_minute
              ? i18n("VALUE_HOUR_AND_MINUTE", [
                  `${panelStreamV5.content.operation_time.total_hour}`,
                  `${panelStreamV5.content.operation_time.total_minute}`,
                ])
              : "-"}
          </b>
        </span>
        <span>
          {i18n("HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET")}
          <b>
            {panelStreamV5.content?.operation_time?.wet_hour &&
            panelStreamV5.content?.operation_time?.wet_minute
              ? i18n("VALUE_HOUR_AND_MINUTE", [
                  `${panelStreamV5.content.operation_time.wet_hour}`,
                  `${panelStreamV5.content.operation_time.wet_minute}`,
                ])
              : "-"}
          </b>
        </span>
      </div>
      <div className="expanded-content__group">
        {/* Se a irrigação atual for schedule irrigation */}
        {panelStreamV5.content.irrigation_status?.irrigation_type === 2 &&
          PIVOT_IRRIGATING_WET_OR_DRY_REASONS_V5.includes(
            panelStreamV5.content.irrigation_status.irrigation_status
          ) && (
            <span>
              {i18n("HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE")}
              <b>
                {i18n("VALUE", [
                  `${
                    panelStreamV5.content.current_schedule?.current_schedule + 1
                  }`,
                ])}
              </b>
            </span>
          )}

        {/* Se a irrigação atual for segment irrigation */}
        {panelStreamV5.content.irrigation_status?.irrigation_type === 3 && (
          <span>
            {i18n("HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT")}
            <b>
              {i18n("VALUE", [
                `${panelStreamV5.content.current_segment?.current_segment}`,
              ])}
            </b>
          </span>
        )}

        {PIVOT_IRRIGATING_WET_OR_DRY_REASONS_V5.concat(
          PROGRAMMED_REASONS_V5
        ).includes(
          panelStreamV5.content.irrigation_status.irrigation_status
        ) && (
          <>
            <span>
              {i18n("HISTORIC_PANEL_STREAM_V5_DIRECTION")}
              <b>
                {i18n("VALUE", [
                  `${getPanelStreamV5Direction(
                    panelStreamV5.content.current_irrigation_information
                      ?.direction
                  )}`,
                ])}
              </b>
            </span>
            <span>
              {i18n("HISTORIC_PANEL_STREAM_V5_MODE")}
              <b>
                {i18n("VALUE", [
                  `${getPanelStreamV5Mode(
                    panelStreamV5.content.current_irrigation_information?.mode
                  )}`,
                ])}
              </b>
            </span>
            <span>
              {i18n("HISTORIC_PANEL_STREAM_V5_PERCENTAGE")}
              <b>
                {i18n("VALUE_AND_UNIT_PERCENT_MILIMETERS", [
                  `${panelStreamV5.content.current_irrigation_information?.irrigation_percent}`,
                  `${formatFloatValue(
                    PTPToMillimeter(
                      props.pivot,
                      panelStreamV5.content.current_irrigation_information
                        ?.irrigation_percent
                    )
                  )}`,
                ])}
              </b>
            </span>
          </>
        )}
        <span>
          {i18n("CURRENT_ANGLE")}
          <b>
            {i18n("VALUE_AND_UNIT_DEGREES", [
              `${formatFloatValue(panelStreamV5.current_angle)}`,
            ])}
          </b>
        </span>
      </div>
    </div>
  );
}

export default ExpandedPanelStreamV5;

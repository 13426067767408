import React from "react";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { Box, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PaymentMethodDialogContent from "./components/PaymentMethodDialogContent";
import PaymentCard from "./components/PaymentCard";
import PaymentMethodSelection from "./components/PaymentMethodUpdateBTN";
import { PaymentMethodCard } from "../../../../../../../../../hooks/models/usePaymentMethod";
import { PaymentMethodOptions } from "./PaymentMethodContainer";
import PaymentMethodRemoveConfirmation from "./components/PaymentMethodRemoveConfirmation";

interface Props {
  paymentMethod: PaymentMethodCard;
  methods: PaymentMethodOptions[];
  hasRecurrence: boolean;
  isMethodCard: boolean;
  deletePaymentMethod: () => void;
}

export default function PaymentMethodMobile(props: Props) {
  const { paymentMethod, methods, hasRecurrence, deletePaymentMethod } = props;

  const method = methods[0];

  return (
    <Box>
      {hasRecurrence ? (
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "25px",
            }}
          >
            <PaymentCard paymentMethod={paymentMethod as PaymentMethodCard} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PaymentMethodSelection method={method} />
            </Grid>
            <Grid item xs={6}>
              <PaymentMethodRemoveConfirmation
                deletePaymentMethod={deletePaymentMethod}
                methods={methods}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "5px 0px 25px",
            }}
          >
            <Alert severity="warning">{i18n("PAYMENT_METHOD_WARNNING")}</Alert>
          </Box>
          <PaymentMethodDialogContent methods={methods} />
        </>
      )}
    </Box>
  );
}

import React from "react";
import Chart from "react-apexcharts";
import { i18n } from "../../../../../../../../i18n/i18nText";

interface Props {
  series: any;
}

export const MyResponsivePie = (props: Props) => {
  const HP = props.series.ponta.total;
  const HFP = props.series.fora_de_ponta.total;
  const HR = props.series.reduzido.total;

  const state = {
    options: {
      animations: {
        enabled: false,
      },
      align: "center",
      dataLabels: {
        formatter: function (val, opts) {
          var series = opts.w.config.series;

          var percentageHP =
            (series[0] * 100) / (series[0] + series[1] + series[2]);
          var percentageHFP =
            (series[1] * 100) / (series[0] + series[1] + series[2]);
          var percentageHR =
            (series[2] * 100) / (series[0] + series[1] + series[2]);

          var percentageArray = [percentageHP, percentageHFP, percentageHR];

          var index = percentageArray.indexOf(val);
          return `R$ ${series[index].toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          colors: ["rgba(51, 51, 51, 0.8)"],
        },
      },
      legend: {
        position: "bottom",
      },
      colors: ["#E84C4D", "#D3E8FE", "#4C9472"],
      labels: [
        i18n("POWER_RANGE_PEAK_ACRONYM"),
        i18n("POWER_RANGE_OFF_PEAK_ACRONYM"),
        i18n("POWER_RANGE_REDUCED_ACRONYM"),
      ],
    },
    series: [HP, HFP, HR],
    xaxis: {
      type: "numeric",
    },
  };

  return (
    <Chart
      //@ts-ignore
      options={state.options}
      series={state.series}
      type="pie"
      height="93%"
      width="100%"
    />
  );
};

import React from "react";
import "./EditFarm.scss";
import { useNavigate, useParams } from "react-router";
import useFarm from "../../../../hooks/models/useFarm";
import EditFarmFormPlaceholder from "./components/EditFarmForm/components/EditFarmFormPlaceholder/EditFarmFormPlaceholder";
import NewEditFarmForm from "./components/EditFarmForm/NewEditFarmForm";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import { isMobile } from "../../../../mobileConfig";
import Routes from "../../../../routes/routes";
import EditFormsPlaceholder from "../../../../components/EditFormsPlaceholder/EditFormsPlaceholder";
import MobileZone from "../../../../components/Zones/MobileZone";
import { i18n } from "../../../../i18n/i18nText";

function EditFarm() {
  const farmID: number = parseInt(useParams().farm, 10);
  const [asyncStatus, farm, actions] = useFarm(farmID);
  const navigate = useNavigate();

  if (asyncStatus.done && farm !== undefined) {
    if (!farm.is_administrator) {
      navigate(Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID)));
    }
    return (
      <div className={isMobile() ? "edit-farm-mobile" : "edit-farm"}>
        <NewEditFarmForm
          farm={farm}
          patchFarm={(patchObject) => actions.patch(patchObject)}
          createFarm={(patchObject) => actions.create(patchObject)}
        />
      </div>
    );
  }

  return (
    <div className={isMobile() ? "edit-farm-mobile" : "edit-farm"}>
      <DesktopZone>
        <EditFarmFormPlaceholder />
      </DesktopZone>

      <MobileZone>
        <EditFormsPlaceholder
          centerElement={
            <h2>
              <>{i18n("EDIT_FARM_LABEL")}</>
            </h2>
          }
          goBack={() => {
            navigate(`/farms/${farmID}`);
          }}
        />
      </MobileZone>
    </div>
  );
}

export default EditFarm;

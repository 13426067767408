import React from "react";
import {
  GPSStream,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import { expandDateArgs } from "../../../../utils/utils";
import {
  getPanelStreamReason,
  parseHourmeter,
  getIrrigationDirectionV4,
  PTPToMillimeter,
} from "../../../../../../../../../../utils/models/pivots";
import { isMobile } from "../../../../../../../../../../mobileConfig";
import { i18n } from "../../../../../../../../../../i18n/i18nText";

interface Props {
  gpsStream: GPSStream;
  pivot: Pivot;
}

function ExpandedGPSStream(props: Props) {
  const { gpsStream, pivot } = props;

  //

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <div>
          <span>
            {i18n("HISTORIC_GPS_STREAM_CREATED_HUMANIZED", [
              expandDateArgs(gpsStream.created)[0],
            ])}
          </span>
        </div>
        <div>
          <span>
            {i18n("HISTORIC_GPS_STREAM_CREATED", [
              expandDateArgs(gpsStream.created)[1],
            ])}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          <div style={{ marginRight: "3px" }}>
            {i18n("HISTORIC_PANEL_STREAM_V5_POSITION")}:
          </div>
          <a
            target="__blank"
            href={`http://maps.google.com/maps?t=k&q=${
              gpsStream.position.split(",")[0]
            },${gpsStream.position.split(",")[1]}`}
          >
            {` ${gpsStream.position.split(",")[0]},${
              gpsStream.position.split(",")[1]
            }`}
          </a>
        </div>

        <div></div>
        {/* If contains Painel Stream data on GPSStream model */}
        {gpsStream.speed !== -1 && (
          <>
            <div>
              <span>{i18n("HISTORIC_GPS_STREAM_REASON")}</span>
              <b style={{ marginLeft: "5px" }}>
                <span>
                  {i18n("HISTORIC_GPS_STREAM_REASON_VALUE", [
                    getPanelStreamReason(gpsStream.reason),
                  ])}
                </span>
              </b>
            </div>
          </>
        )}
      </div>
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_GPS_STREAM_SPEED")}
          <b>
            {i18n("VALUE_AND_UNIT_PERCENT_MILIMETERS", [
              `${gpsStream.speed}`,
              `${PTPToMillimeter(pivot, gpsStream.speed)?.toFixed(2)}`,
            ])}
          </b>
        </span>
        <span>
          {i18n("HISTORIC_GPS_STREAM_HOURMETER")}
          <b>{i18n("VALUE", [parseHourmeter(gpsStream.hourmeter)])}</b>
        </span>
        <span>
          {i18n("HISTORIC_GPS_STREAM_WATER_PRESSURE")}
          <b>{i18n("VALUE_AND_UNIT_BAR", [`${gpsStream.water_pressure}`])}</b>
        </span>
        <span>
          {i18n("HISTORIC_GPS_STREAM_DIRECTION")}
          <b>
            {i18n("VALUE", [getIrrigationDirectionV4(gpsStream.clockwise)])}
          </b>
        </span>
      </div>
    </div>
  );
}

export default ExpandedGPSStream;

import React from "react";

const CentralIcon = ({ color, size, extraStyle = null }) => {
  const style = { fill: color, width: `${size}px`, height: `${size}px` };
  const newStyle = { ...style, ...extraStyle };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={newStyle}
      viewBox="0 0 58.03 89.46"
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M45 28h13.031v17H45zm0 0" />
        </clipPath>
        <clipPath id="prefix__b">
          <path d="M0 32h58.031v57.46H0zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path d="M55.648 44.832h-8.074a2.382 2.382 0 01-2.383-2.383V30.906a2.382 2.382 0 012.383-2.383h8.074a2.382 2.382 0 012.383 2.383V42.45a2.382 2.382 0 01-2.383 2.383" />
      </g>
      <path d="M51.61 38.941a3.408 3.408 0 01-3.407-3.41V3.411a3.408 3.408 0 116.817 0v32.12a3.41 3.41 0 01-3.41 3.41" />
      <g clipPath="url(#prefix__b)">
        <path d="M51.297 61.137l-1.598 2.066-.687-.742c-4.11-4.32-5.762-5.676-9.114-7.418-10.738-5.64-22.902-3.031-31.738 6.836l-1.14 1.293-1.602-2c-.863-1.098-1.574-2.067-1.574-2.16 0-.098.586-.809 1.32-1.614 5.992-6.578 12.34-10.03 20.086-10.996 9.496-1.16 19.574 3.13 26.402 11.223l1.246 1.48zm-6.55 8.32l-1.599 2.066-1.699-1.742c-3.277-3.386-7.566-5.484-11.96-5.836-5.204-.422-11.044 2.094-14.727 6.29l-1.14 1.32-1.602-2c-.864-1.098-1.575-2.094-1.575-2.223s.942-1.195 2.082-2.355c2.465-2.485 5.766-4.614 8.961-5.836 2.11-.84 2.617-.903 6.73-.903 4.903 0 6.5.387 10.157 2.352 1.93 1.066 5.305 3.773 6.98 5.644l1.016 1.13zm-6.599 8.356l-1.601 2.093-1.32-1.258c-2.184-2.128-3.657-2.707-6.88-2.707-3.25 0-4.902.645-6.933 2.739l-1.191 1.226-1.602-1.965c-.863-1.097-1.574-2.097-1.574-2.226 0-.516 2.77-3 4.441-3.965 2.492-1.453 4.192-1.938 6.985-1.906 3.785.035 7.64 1.808 10.28 4.742l1.02 1.16zm14.98-45.02H4.899A4.902 4.902 0 000 37.695V84.56a4.902 4.902 0 004.898 4.902h48.23a4.903 4.903 0 004.903-4.902V37.695a4.903 4.903 0 00-4.902-4.902" />
      </g>
    </svg>
  );
};

export default CentralIcon;

import "../../../../../../style/components/DrawerRight.scss";
import { suffixClasses } from "../../../../../../utils/classes";
import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Irpd } from "../../../../../../redux/irpds/types";
import HistoricConfigV5 from "./components/Historic/HistoricConfigV5";
import { useParams } from "react-router";
import { HistoryOutlined, AssignmentOutlined } from "@material-ui/icons";
import StarIcon from "@material-ui/icons/Star";
import { isMobile } from "../../../../../../mobileConfig";
import { i18n } from "../../../../../../i18n/i18nText";

export type IrpdConfigHistory = {
  arrived: Date;
  content: ContentIrpdConfigHistory;
  created: Date;
  created_by: { id: number; username: string };
  created_on_hardware: boolean;
  device: number;
  id: number;
  irpd: number;
  message_error: string;
  message_packets: number[];
  message_status: number;
  message_subtype: string;
  uuid: string;
  monthly_water_limit: number;
  has_pressure_sensor: boolean;
  name: string;
  flow: number;
  potency: number;
  position: string;
  pinned: boolean;
  name_irpd_on_config?: string;
  kwh_peak: number;
  kwh_out_of_peak: number;
  kwh_reduced: number;
  is_datasheet: boolean;
};

export type IrpdConfigResults = {
  count?: number;
  current_page?: number;
  next?: string;
  previous?: string;
  results?: IrpdConfigHistory[];
};

export type ContentIrpdConfigHistory = {
  clear_device_memory: { clear_device_memory: number };
  clock: {
    day: number;
    hour: number;
    year: number;
    month: number;
    minute: number;
    second: number;
  };
  datalogger_address: { address: string };
  enable_peak_time: { enable: number };
  event_stream_indexes: { indexes: string };
  holidays: { day: number; month: number; number_editing: number }[];
  imanage_sensors: {
    max_value: number;
    min_value: number;
    number_editing: number;
    sensor_type: number;
  }[];
  peak_time: {
    friday_enable: number;
    monday_enable: number;
    saturday_enable: number;
    start_hour_1: number;
    start_hour_2: number;
    start_minute_1: number;
    start_minute_2: number;
    stop_hour_1: number;
    stop_hour_2: number;
    stop_minute_1: number;
    stop_minute_2: number;
    sunday_enable: number;
    thursday_enable: number;
    tuesday_enable: number;
    wednesday_enable: number;
  };
  periodic_stream_indexes: { indexes: string };
  periodic_stream_timer: { time: number };
  pump_power_time: { minutes: number };
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

type Props = {
  irpd: Irpd;
  loadHistoricInfo: (
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdKwhPeak: number,
    irpdKwhOutOfPeak: number,
    irpdKwhReduced: number,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) => void;
};

const DrawerRightEditIrpd = (props: Props) => {
  const [value, setValue] = useState(0);
  const [drawerVisibleRight, setDrawerVisibleRight] = useState(false);
  const toggleDrawerRight = () => setDrawerVisibleRight(!drawerVisibleRight);
  const openDrawerRight = () => setDrawerVisibleRight(true);
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const closeDrawerRight = () => setDrawerVisibleRight(false);
  const [selectedTab, setSelectedTab] = useState("tab-1");
  const { irpd } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="drawerRight">
        <div
          className={suffixClasses("drawerRight__backdrop", [
            ["--inactive", !drawerVisibleRight],
          ])}
          onClick={toggleDrawerRight}
        />
        <div
          className={suffixClasses("drawerRight__bar", [
            ["--inactive", !drawerVisibleRight],
          ])}
        >
          <>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  width: "4px",
                },
              }}
              aria-label="Edit Farm"
            >
              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-1" ? "#293745" : "initial",
                }}
                icon={
                  <HistoryOutlined
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(0)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-1") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-1");
                    openDrawerRight();
                  }
                }}
              />

              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-2" ? "#293745" : "initial",
                }}
                icon={
                  <StarIcon
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(1)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-2") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-2");
                    openDrawerRight();
                  }
                }}
              />

              <Tab
                style={{
                  minWidth: 0,
                  borderBottom: "1px solid rgba(255,255,255, 0.1)",
                  backgroundColor:
                    selectedTab === "tab-2" ? "#293745" : "initial",
                }}
                icon={
                  <AssignmentOutlined
                    style={{
                      color: "white",
                      fontSize: 20,
                    }}
                  />
                }
                {...a11yProps(2)}
                onClick={() => {
                  if (drawerVisibleRight && selectedTab === "tab-3") {
                    setSelectedTab("");
                    closeDrawerRight();
                  } else {
                    setSelectedTab("tab-3");
                    openDrawerRight();
                  }
                }}
              />
            </Tabs>

            {drawerVisibleRight ? (
              <div
                style={{
                  backgroundColor: "white",
                  maxHeight: "100vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {/* First tab (Config history) */}
                <TabPanel value={value} index={0}>
                  <h3 style={{ marginTop: "-10px", color: "#555" }}>
                    {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                  </h3>
                  <HistoricConfigV5
                    farmID={farmID}
                    irpd={irpd}
                    irpdID={irpdID}
                    pinned={false}
                    datasheet={false}
                    loadHistoricInfo={props.loadHistoricInfo}
                  />
                </TabPanel>

                {/* Second tab (Config history favorites) */}
                <TabPanel value={value} index={1}>
                  <h3 style={{ marginTop: "-10px", color: "#555" }}>
                    {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                  </h3>
                  <HistoricConfigV5
                    farmID={farmID}
                    irpd={irpd}
                    irpdID={irpdID}
                    pinned={true}
                    datasheet={false}
                    loadHistoricInfo={props.loadHistoricInfo}
                  />
                </TabPanel>

                {/* Datasheet tab */}
                <TabPanel value={value} index={2}>
                  <h3 style={{ marginTop: "-10px", color: "#555" }}>
                    {i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}
                  </h3>
                  <Typography
                    style={{
                      backgroundColor: "#4caf50",
                      padding: "8px 16px",
                      borderRadius: 8,
                      color: "#fff",
                    }}
                  >
                    {i18n("HISTORIC_CONFIG_PIVOT_SUBTITLE")}
                  </Typography>
                  <HistoricConfigV5
                    farmID={farmID}
                    irpd={irpd}
                    irpdID={irpdID}
                    pinned={false}
                    datasheet={true}
                    loadHistoricInfo={props.loadHistoricInfo}
                  />
                </TabPanel>
              </div>
            ) : (
              <div></div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default DrawerRightEditIrpd;

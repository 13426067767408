import { Reducer } from "redux";
import { UsersFarmState, UsersFarmTypes } from "./type";
import { UsersFarmActions } from "./actions";

const INITIAL_STATE: UsersFarmState = {
  list: [],
};

const reducer: Reducer<UsersFarmState, UsersFarmActions> = (
  state = INITIAL_STATE,
  action: UsersFarmActions
): UsersFarmState => {
  const { type, payload }: { type: string; payload: any } = action;

  switch (type) {
    case UsersFarmTypes.SET_USERSFARM:
      return { ...state, list: payload };

    default:
      return state;
  }
};

export default reducer;

import { useState, useEffect } from "react";

export function useKeyboardOpen() {
  let [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    function setKeyboardOpen() {
      setIsKeyboardOpen(true);
    }

    function setKeyboardClosed() {
      setIsKeyboardOpen(false);
    }

    (window as any).addEventListener("keyboardWillShow", setKeyboardOpen);

    (window as any).addEventListener("keyboardDidHide", setKeyboardClosed);

    return () => {
      window.removeEventListener("keyboardWillShow", setKeyboardOpen);

      window.removeEventListener("keyboardDidHide", setKeyboardClosed);
    };
  }, []);

  return isKeyboardOpen;
}

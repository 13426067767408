import React from "react";
import { Irpd } from "../../../../../../../../../../../redux/irpds/types";
import { ReactJSX } from "../../../../../../../../../../../utils/types";
import { formatDateTime2 } from "../../../../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { i18n } from "../../../../../../../../../../../i18n/i18nText";

interface Props {
  irpdAction: any;
  irpd: Irpd;
}

function ExpandedIrpdActionScheduleV5(props: Props) {
  const { irpdAction, irpd } = props;

  const dateStartIsNull: ReactJSX =
    irpdAction.message_subtype === "schedule" &&
    irpdAction.content?.pump_schedule?.start_year === 0 ? (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_START_AT")}
        <b>{i18n("VALUE", [i18n("HISTORIC_IRPD_ACTION_NOW")])}</b>
      </span>
    ) : (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_START_AT")}
        <b>
          {i18n("VALUE", [
            formatDateTime2(
              irpdAction.content?.pump_schedule?.start_year,
              irpdAction.content?.pump_schedule?.start_month,
              irpdAction.content?.pump_schedule?.start_day,
              irpdAction.content?.pump_schedule?.start_hour,
              irpdAction.content?.pump_schedule?.start_minute
            ),
          ])}
        </b>
      </span>
    );

  const dateEndIsNull: ReactJSX =
    irpdAction.message_subtype === "schedule" &&
    irpdAction.content?.pump_schedule?.stop_year === 0 ? (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_END_AT")}
        <b>{i18n("VALUE", [`${i18n("HISTORIC_IRPD_ACTION_NEVER")}`])}</b>
      </span>
    ) : (
      <span>
        {i18n("HISTORIC_IRPD_ACTION_END_AT")}
        <b>
          {i18n("VALUE", [
            formatDateTime2(
              irpdAction.content?.pump_schedule?.stop_year,
              irpdAction.content?.pump_schedule?.stop_month,
              irpdAction.content?.pump_schedule?.stop_day,
              irpdAction.content?.pump_schedule?.stop_hour,
              irpdAction.content?.pump_schedule?.stop_minute
            ),
          ])}
        </b>
      </span>
    );

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group">
        <div className="expanded-content__column-6-6">
          <div>
            {irpdAction.created_on_hardware ? (
              i18n("HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL")
            ) : (
              <span>
                {i18n("HISTORIC_IRPD_ACTION_USER")}
                <b>{i18n("VALUE", [`${irpdAction.username}`])}</b>
              </span>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* Na simple irrigation de Irpd V5 não existe informações para colocar no expanded, na schedule existe */}
            {dateStartIsNull}
            {dateEndIsNull}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpandedIrpdActionScheduleV5;

import React, { useCallback, useEffect } from "react";
import FormInput from "../Form/FormInput/FormInput";
import "./SpeedInputs.scss";
import { Pivot } from "../../redux/pivots/types";
import { mmToPTP, PTPToMillimeter } from "../../utils/models/pivots";
import { checkFloat } from "../../utils/checks";
import { isMobile } from "../../mobileConfig";

interface Props {
  pivot: Pivot;
  disabled?: boolean;
  millimeter: string;
  percentage: string;
  setMillimeter: (value: any) => void;
  setPercentage: (value: any) => void;
}

function SpeedInputs(props: Props) {
  const {
    pivot,
    disabled,
    millimeter,
    percentage,
    setMillimeter,
    setPercentage,
  } = props;

  useEffect(() => {
    if (millimeter && millimeter != "") {
      const percentageAux = mmToPTP(pivot, parseFloat(millimeter));
      if (percentageAux > 0 && percentageAux <= 100) {
        setPercentage(String(percentageAux));
      } else {
        setPercentage("");
      }
    }
  }, [millimeter]);

  const setValidPercentage = useCallback((value: string) => {
    if (value === "") {
      setPercentage("");
    }
    if (
      checkFloat(value) &&
      parseFloat(value) > 0 &&
      parseFloat(value) <= 100
    ) {
      setPercentage(value);
      const mm = PTPToMillimeter(pivot, parseInt(value));
      setMillimeter(mm.toFixed(4));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={isMobile() ? "mobile-container" : "container"}>
      <FormInput
        state={[percentage, setValidPercentage]}
        id="percentage"
        label={"PIVOT_ACTION_FIELD_PERCENT"}
        inputMode="numeric"
        // helperText={percentage == "" ? "ERROR_EMPTY_FIELD" : undefined}
        min="1"
        max="100"
        endAdornment="%"
        disabled={disabled}
      />
      <FormInput
        id="millimeter"
        state={[millimeter, setMillimeter]}
        // helperText={
        //   mmToPTP(pivot, parseFloat(millimeter)) > 100 ||
        //   mmToPTP(pivot, parseFloat(millimeter)) < 1
        //     ? "ERROR_MIN_MAX_PRECIPITATION"
        //     : undefined
        // }
        label={"PIVOT_ACTION_FIELD_PRECIPITATION"}
        inputMode="numeric"
        endAdornment="mm"
        disabled={disabled}
      />
    </div>
  );
}

export default React.memo(SpeedInputs);

export enum SettingTypes {
  SET_LANGUAGE = "@@auth/SET_LANGUAGE",
}

export interface SettingState {
  language: Language;
}

/**
 * ISO 639-1 STANDARD LANGUAGE CODES
 * https://www.andiamo.co.uk/resources/iso-language-codes/
 */

export enum Language {
  PORTUGUESE_BRAZIL = "pt-br",
  GERMAN_AUSTRIA = "de-at",
  ENGLISH = "en",
  RUSSIAN = "ru",
  SPAIN_SPANISH = "es",
  I18N_KEYS = "keys",
}

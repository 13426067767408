import React, { useEffect, useState } from "react";
import "./EditPivot.scss";
import { useNavigate, useParams } from "react-router";
import usePivot from "../../../../hooks/models/usePivot";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import NewEditPivotV5 from "./components/EditPivotFormV5/NewEditPivotV5";
import { isMobile } from "../../../../mobileConfig";
import { EditPivotV5Provider } from "./components/EditPivotFormV5/EditPivotV5Provider/EditPivotV5Provider";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
// import DrawerRightEditPivot from "./components/DrawerRightEditPivot/DrawerRightEditPivot";
import MobileZone from "../../../../components/Zones/MobileZone";
import EditFormsPlaceholder from "../../../../components/EditFormsPlaceholder/EditFormsPlaceholder";
import { i18n } from "../../../../i18n/i18nText";
import axios from "axios";
import { coreHTTPClient } from "../../../../services/webclient";
import { BauerPivotObj } from "../../../../redux/bauerPivot/types";

export interface EditPivotParams {
  farm: string;
  pivot: string;
}

export enum MessageStatus {
  WAITING = -1,
  NOT_SENT = 0,
  SENT = 1,
  DELIVERED = 2,
  ERROR = 3,
}

export interface RadioStatuses {
  control: MessageStatus;
  monitor: MessageStatus;
  pump: MessageStatus;
}

function EditPivot() {
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  const navigate = useNavigate();
  const [bauerPivot, setBauerPivot] = useState<BauerPivotObj>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const bauerPivot = await coreHTTPClient.get(
          `v4/smartrain/pivot/${pivotID}`
        );
        if (bauerPivot.data) {
          let updatedData = { ...bauerPivot.data.latest };

          if (
            !updatedData.autoreverse &&
            !updatedData.parking_on_off &&
            !updatedData.intermediate_on_off
          ) {
            updatedData = { ...updatedData, autoreverse: true };
          }

          if (
            !updatedData.sector_end &&
            !updatedData.sector_begin &&
            !updatedData.parking_position &&
            !updatedData.intermediate_position &&
            !updatedData.zero_position &&
            !updatedData.last_tower_radius
          ) {
            updatedData = {
              ...updatedData,
              sector_end: 36000,
              sector_begin: 0,
              parking_position: 0,
              intermediate_position: 0,
              zero_position: 0,
              last_tower_radius: 0,
            };
          }

          setBauerPivot({
            ...bauerPivot.data,
            latest: updatedData,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (!loading)
    return (
      <div
        className={isMobile() ? "edit-bauer-pivot-mobile" : "edit-bauer-pivot"}
      >
        <NewEditPivotV5
          farmID={farmID}
          bauerPivot={bauerPivot}
          setBauerPivot={setBauerPivot}
        />
      </div>
    );

  return (
    <div
      className={isMobile() ? "edit-bauer-pivot-mobile" : "edit-bauer-pivot"}
    >
      <DesktopZone>
        <CustomCircularProgress />
      </DesktopZone>

      <MobileZone>
        <EditFormsPlaceholder
          centerElement={
            <h2>
              <>{i18n("EDIT_PIVOT_LABEL")}</>
            </h2>
          }
          goBack={() => {
            navigate(`farms/${farmID}/bauer-pivot/${pivotID}`);
          }}
        />
      </MobileZone>
    </div>
  );
}

export default EditPivot;

import React, { useState, useEffect } from "react";
import "./IrpdItem.scss";
import {
  Irpd,
  IrpdPressureStream,
} from "../../../../../../../../redux/irpds/types";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import { useNavigate, useParams } from "react-router";
import useIrpdStatus from "../../../../../../../../hooks/tools/useIrpdStatus";
import { Slide, CircularProgress } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import routes from "../../../../../../../../routes/routes";
import useSocketIO from "../../../../../../../../hooks/tools/useSocketIO";
import { useRecoilState } from "recoil";
import { IrpdPressureStreamFamily } from "../../../../../../../../recoils/IrpdRecoil";
import {
  getIrpdStreamStatus,
  getLatestIrpdStreamV5,
} from "../../../../../../../../utils/models/irpds";
import StatusHelper from "../PivotItem/StatusHelperPopover";
import { superUserStatus } from "../../../../../../../../recoils/SuperUserStatusRecoil";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

export const CenterIcon = require("../../../../../../../../assets/images/center_pressure.png");

export interface SelectedFarmParams {
  farm: string;
}

const { SOCKET_SUFFIX } = process.env;

interface Props {
  irpd: Irpd;
  sweepStatus?: number; // 0 - sweep not activated, 1 - sweep started, 2 - sweep done successfully
  shouldNotShowMapPreview?: boolean;
  shouldHideLatestStatus?: boolean;
}

function IrpdItem(props: Props) {
  let [
    latestStatus,
    latestUpdate,
    latestIrpdStream,
    setLatestIrpdStream,
  ] = useIrpdStatus(props.irpd);

  const [irpdConnectStatus, setIrpdConnectStatus] = useState();

  const [isConnected, socket] = useSocketIO();

  const [loading, setLoading] = useState(true);

  const farmID: string = useParams().farm;

  const navigate = useNavigate();

  const [runningSweep, setRunningSweep] = useState<number>(undefined);

  const [irpdPressureStream, setIrpdPressureStream] = useRecoilState(
    IrpdPressureStreamFamily(props.irpd.id)
  );

  const [latestIrpdStreamV5, setLatestIrpdStreamV5] = useState(
    getLatestIrpdStreamV5(props.irpd)
  );

  // Checagem de super usuário
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);
  // fim checagem

  const [pressureV5, setPressureV5] = useState<any>(
    props.irpd.latest_irpd_stream_v5_periodic?.content
      ?.imanage_sensor_measure_value?.[0]?.value / 10
  );

  useEffect(() => {
    if (props.sweepStatus !== undefined) {
      setRunningSweep(props.sweepStatus);
    }
  }, [props.sweepStatus]);

  useEffect(() => {
    if (
      (latestUpdate !== undefined && latestStatus !== undefined) ||
      props.irpd?.latest_irpd_stream_v5_periodic ||
      props.irpd?.latest_irpd_config_v5
    ) {
      setLoading(false);
    }
  }, [latestStatus, latestUpdate, props.irpd]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}irpd@${props.irpd.id}`);

      socket.bind("irpd_pressure_stream", (data: IrpdPressureStream) => {
        let dataCreatedDate = new Date(data.created).getTime();
        let latestCreatedDate = irpdPressureStream
          ? new Date(irpdPressureStream.created).getTime()
          : 0;

        if (dataCreatedDate - latestCreatedDate > 0) {
          setIrpdPressureStream(data);
        }
      });

      socket.bind("irpd_stream", (data) => {
        //
        setLatestIrpdStream((latest) => {
          let dataCreatedDate = new Date(data.created).getTime();
          let latestCreatedDate = latest
            ? new Date(latest.created).getTime()
            : 0;
          if (dataCreatedDate - latestCreatedDate > 0) {
            return data;
          }

          return latest;
        });
      });
    }

    socket.bind("IrpdStreamV5_event", (data) => {
      setLatestIrpdStreamV5(data);
      setLatestIrpdStream({ ...latestIrpdStream, created: data.created });
    });

    socket.bind("IrpdStreamV5_periodic", (data) => {
      setPressureV5(
        data?.content?.imanage_sensor_measure_value?.[0]?.value / 10
      );
      setLatestIrpdStream({ ...latestIrpdStream, created: data.created });
    });

    return () => {
      socket.unbind("irpd_stream");
      socket.unbind("IrpdStreamV5_event");
      socket.unbind("IrpdStreamV5_periodic");
      socket.unbind("irpd_pressure_stream");
      socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${props.irpd.id}`);
    };
  }, []);

  const getIrpdImage = (irpd) => {
    const irpdPosition = irpd.position.split(",");
    const cp = {
      lat: parseFloat(irpdPosition[0]),
      lng: parseFloat(irpdPosition[1]),
    };

    const marker = `&markers=color:0xc3fc49ff|${cp.lat},${cp.lng}`;

    return <img src={props.irpd.image + process.env.MAPS_KEY + marker} />;
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <MobileZone>
            <Slide direction="down" in={true}>
              <div
                className="irpd-item"
                onClick={() =>
                  navigate(
                    routes.DASHBOARD.SELECTED_IRPD.replace(
                      ":farm",
                      farmID
                    ).replace(":irpd", String(props.irpd.id))
                  )
                }
              >
                <div className="pivot-item__row-left">
                  {!props.shouldNotShowMapPreview && props.irpd.image ? (
                    getIrpdImage(props.irpd)
                  ) : (
                    <></>
                  )}
                  <div className="irpd-item__container">
                    <div className="irpd-item__content">
                      <div className="irpd-item__text-content">
                        <div className="pivot-item__title">
                          {props.irpd.name}
                          {!props.shouldHideLatestStatus && (
                            <div
                              className={"irpd-item__status"}
                              style={{
                                backgroundColor:
                                  props.irpd.protocol < 5
                                    ? latestStatus.color
                                    : getIrpdStreamStatus(
                                        latestIrpdStreamV5?.content
                                          ?.imanage_master_status?.status
                                      ).color,
                              }}
                            >
                              {props.irpd.protocol < 5
                                ? latestStatus.text.toUpperCase()
                                : getIrpdStreamStatus(
                                    latestIrpdStreamV5?.content
                                      ?.imanage_master_status?.status
                                  ).text.toUpperCase()}
                            </div>
                          )}
                          {latestIrpdStreamV5?.content?.imanage_master_status
                            ?.status == 193 ? (
                            <StatusHelper />
                          ) : null}
                        </div>

                        <div className="pivot-item__angle">
                          <img
                            src={CenterIcon}
                            width="20px"
                            height="20px"
                            style={{
                              marginRight: "8px",
                              opacity: 0.85,
                              height: 20,
                              marginLeft: -5,
                            }}
                          />
                          {props.irpd.protocol < 5
                            ? `${
                                irpdPressureStream
                                  ? formatFloatValue(
                                      irpdPressureStream.pressure / 10
                                    )
                                  : "-"
                              } bar`
                            : `${
                                pressureV5 && pressureV5 > 0
                                  ? formatFloatValue(pressureV5)
                                  : "-"
                              } bar`}
                        </div>
                        <div className="irpd-item__last-update">
                          {latestUpdate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="irpd-item__row-right">
                  <div className="irpd-item__sweep-status">
                    {runningSweep === 1 ? <CircularProgress /> : <></>}
                    {runningSweep === 2 ? <Done /> : <></>}
                  </div>
                </div>
              </div>
            </Slide>
          </MobileZone>

          <DesktopZone>
            <div
              className="irpd-item"
              onClick={() =>
                navigate(
                  routes.DASHBOARD.SELECTED_IRPD.replace(
                    ":farm",
                    farmID
                  ).replace(":irpd", String(props.irpd.id))
                )
              }
            >
              <div className="pivot-item__row-left">
                <div className="irpd-item__container">
                  <div className="irpd-item__content">
                    <div className="irpd-item__text-content">
                      <div className="pivot-item__title">
                        {props.irpd.name}
                        {!props.shouldHideLatestStatus && (
                          <>
                            {superUser && (
                              <div
                                className="protocol"
                                style={{
                                  backgroundColor: "808080",
                                }}
                              >
                                {`v${props.irpd.protocol}`}
                              </div>
                            )}
                            <div
                              className={"irpd-item__status"}
                              style={{
                                backgroundColor:
                                  props.irpd.protocol < 5
                                    ? latestStatus.color
                                    : getIrpdStreamStatus(
                                        latestIrpdStreamV5?.content
                                          ?.imanage_master_status?.status
                                      ).color,
                              }}
                            >
                              {props.irpd.protocol < 5
                                ? latestStatus.text.toUpperCase()
                                : getIrpdStreamStatus(
                                    latestIrpdStreamV5?.content
                                      ?.imanage_master_status?.status
                                  ).text.toUpperCase()}
                            </div>
                          </>
                        )}
                        {latestIrpdStreamV5?.content?.imanage_master_status
                          ?.status == 193 &&
                          props.irpd.protocol >= 5 && <StatusHelper />}
                      </div>

                      <div className="pivot-item__angle">
                        <img
                          src={CenterIcon}
                          width="20px"
                          height="20px"
                          style={{
                            marginRight: "8px",
                            opacity: 0.85,
                            height: 20,
                            marginLeft: -5,
                          }}
                        />
                        {props.irpd.protocol < 5
                          ? `${
                              irpdPressureStream
                                ? formatFloatValue(
                                    irpdPressureStream.pressure / 10
                                  )
                                : "-"
                            } bar`
                          : `${
                              pressureV5 && pressureV5 > 0
                                ? formatFloatValue(pressureV5)
                                : "-"
                            } bar`}
                      </div>

                      <div className="irpd-item__last-update">
                        {latestUpdate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="irpd-item__row-right">
                <div className="irpd-item__sweep-status">
                  {runningSweep === 1 ? <CircularProgress /> : <></>}
                  {runningSweep === 2 ? <Done /> : <></>}
                </div>
              </div>
            </div>
          </DesktopZone>
        </>
      )}
    </>
  );
}

export default React.memo(IrpdItem);

import React, { useState, useCallback, useEffect } from "react";
import "./ConventionalIrrigation.scss";
import { Button, CircularProgress } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import moment, { now } from "moment";
import { PTPToMillimeter } from "../../../../../../../../utils/models/pivots";
import { Pivot, PivotConfig } from "../../../../../../../../redux/pivots/types";
import useCreatePivotAction from "../../../../../../../../hooks/models/useCreatePivotAction";
import { isMobile } from "../../../../../../../../mobileConfig";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../redux";
import FormCheckBox from "../../../../../../../../components/Form/FormCheckBox/FormCheckBox";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import SpeedInputs from "../../../../../../../../components/SpeedInputs/SpeedInputs";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import { RotateLeft, RotateRight, Opacity } from "@material-ui/icons";
import FormatColorResetIcon from "@material-ui/icons/FormatColorReset";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import { useRecoilValue } from "recoil";
import { PivotConfigFamily } from "../../../../../../../../recoils/PivotRecoil";
import RedoIcon from "@material-ui/icons/Redo";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { i18n } from "../../../../../../../../i18n/i18nText";

interface Props {
  pivot: Pivot;
  farmID: number;
}

function ConventionalIrrigation(props: Props) {
  const [createPivotAction] = useCreatePivotAction();

  const { pivot, farmID } = props;
  const notify = useNotify();

  const [direction, setDirection] = useState("");
  const [operation, setOperation] = useState("");

  const [disableButton, setDisableButton] = useState(true);
  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  const [startDate, setStartDate] = useState(moment());

  const setValidStartDate = useCallback((value) => {
    setStartDate(value);
  }, []);

  const [endDate, setEndDate] = useState(moment());

  const setValidEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  const [blade, setBlade] = useState("100");

  const [startMode, setStartMode] = useState<any>("now");

  const [withoutStop, setWithoutStop] = useState<boolean>(true);

  const [millimeter, setMillimeter] = useState(
    PTPToMillimeter(props.pivot, 100).toFixed(4)
  );

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  useEffect(() => {
    if (pivotConfig !== undefined && startMode === "after_pause_time") {
      var config = pivotConfig as PivotConfig;
      var time = config.hour_range_max.split(":");
      var hour = parseInt(time[0]);
      var minute = parseInt(time[1]);
      var second = parseInt(time[2]);
      setStartDate(
        moment()
          .hour(hour)
          .minute(minute + 5)
          .second(second)
      );
    }
    if (startMode === "now") {
      setStartDate(moment());
    }
  }, [pivotConfig, startMode]);

  // END BACK BUTTON STUFF ****

  var isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  //Validate for enable button
  useEffect(() => {
    validRadioButtons();
  }, [startDate, endDate, withoutStop, startMode, direction, operation, blade]);

  const validRadioButtons = () => {
    if (
      (direction == "1" || direction == "2") &&
      (operation == "0" || operation == "2") &&
      blade != ""
    ) {
      if (!withoutStop) {
        if (startDate.isBefore(endDate)) {
          setDisableButton(false);
        } else {
          setDisableButton(true);
        }
      } else setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const handleStartDatetime = (startMode) => {
    var startDateTime;

    var startDateWithNoSeconds = startDate.second(0);

    if (startMode === "now") {
      startDateTime = undefined;
    } else {
      startDateTime = startDateWithNoSeconds.format("YYYY-MM-DDTHH:mm:ss[Z]");
    }

    return startDateTime;
  };

  const formatLabel = (icon: any, text: any) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span style={{ marginRight: "10px", marginTop: "5px" }}>{icon}</span>
        <span>{text}</span>
      </div>
    );
  };

  const lastIrrigationUsed = async () => {
    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/actions/`
    );
    if (response.data?.results[0]) {
      setDirection(`${response.data.results[0].direction}`);
      setOperation(`${response.data.results[0].mode}`);
      setBlade(response.data.results[0].speed);
      setMillimeter(
        PTPToMillimeter(
          pivot,
          parseInt(response.data.results[0].speed)
        ).toString()
      );
      if (
        response.data.results[0].start &&
        moment() < response.data.results[0].start
      ) {
        setStartMode("on_a_date");
        setValidStartDate(response.data.results[0].start);
      }
      if (
        response.data.results[0].end &&
        moment() < response.data.results[0].end
      ) {
        setWithoutStop(true);
        setValidEndDate(response.data.results[0].end);
      }
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");
    setIsLoadingLastAction(false);
  };

  return (
    <div className="conventional-irrigation">
      <div>
        {isLoading && <LinearProgress />}
        <div className="conventional-irrigation__center-row-without-margin">
          <Button
            style={isMobile() ? { width: "100%" } : {}}
            variant="contained"
            color="primary"
            disabled={isLoadingLastAction}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : (
                <RedoIcon />
              )
            }
            onClick={lastIrrigationUsed}
          >
            <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
          </Button>
        </div>
        <div className="conventional-irrigation__grid-1-1">
          <div className="conventional-irrigation__label">
            <>{i18n("PIVOT_ACTION_FIELD_DIRECTION")}</>
          </div>
          <ToggleButtonGroup
            value={direction}
            exclusive
            onChange={(event, newDirection) => setDirection(newDirection)}
            aria-label="direction"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton value="1" aria-label="Reverse" style={{ width: 140 }}>
              <RotateLeft />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
            </ToggleButton>
            <ToggleButton
              value="2"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <RotateRight />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="conventional-irrigation__grid-1-1">
          <div className="conventional-irrigation__label">
            <>{i18n("PIVOT_ACTION_FIELD_MODE")}</>
          </div>
          <ToggleButtonGroup
            value={operation}
            exclusive
            onChange={(event, newOperation) => setOperation(newOperation)}
            aria-label="text alignment"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton
              value="0"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <FormatColorResetIcon />
              <>{i18n("PIVOT_ACTION_VALUE_MODE_DRY")}</>
            </ToggleButton>
            <ToggleButton value="2" aria-label="Reverse" style={{ width: 140 }}>
              <Opacity style={{ height: "25px" }} />
              <>{i18n("PIVOT_ACTION_VALUE_MODE_WET")}</>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <SpeedInputs
          pivot={pivot}
          // disabled={operation === "0"}
          millimeter={millimeter}
          percentage={blade}
          setMillimeter={setMillimeter}
          setPercentage={setBlade}
        />

        <div className="conventional-irrigation__grid-1-2 ">
          {/* <FormCheckBox
            state={[startNow, setStartNow]}
            label={"PIVOT_ACTION_FIELD_START_NOW"}
          /> */}
          <FormSelect
            id="segment-start-mode"
            label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
            state={[startMode, setStartMode]}
            values={[
              ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
              pivot.config.hour_range_min !== "00:00:00"
                ? [
                    "after_pause_time",
                    "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME",
                  ]
                : null,
              ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
            ]}
          />
          <FormDateTimePicker
            id="start"
            label={"PIVOT_ACTION_FIELD_START_DATE"}
            state={[startDate, setValidStartDate]}
            disabled={startMode === "now"}
            minDate={now()}
          />
        </div>
        {/* Only device firmware protocols >= 4.3 supports end-date. */}
        {pivot.protocol === 4.1 && (
          <div className="conventional-irrigation__grid-1-2 ">
            <FormCheckBox
              state={[withoutStop, setWithoutStop]}
              label={"PIVOT_ACTION_FIELD_WITHOUT_STOP"}
            />
            <FormDateTimePicker
              id="end"
              label={"PIVOT_ACTION_FIELD_END_DATE"}
              state={[endDate, setValidEndDate]}
              disabled={withoutStop}
              minDate={withoutStop ? null : startDate}
            />
          </div>
        )}
      </div>
      <div className="conventional-irrigation__center-row">
        <Button
          style={isMobile() ? { width: "100%" } : {}}
          variant="contained"
          color="primary"
          disabled={disableButton || isLoading}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : (
              <Icon>opacity</Icon>
            )
          }
          onClick={() => {
            if (blade !== " ") {
              // O firmware requer que o envio do DateTime seja com segundos zerados
              var endDateWithNoSeconds = endDate.second(0);
              var startDateTime = handleStartDatetime(startMode);

              createPivotAction({
                direction: direction,
                operation: operation,
                startDateTime,
                endDateTime: !withoutStop
                  ? endDateWithNoSeconds.format("YYYY-MM-DDTHH:mm:ss[Z]")
                  : undefined,
                farmID: pivot.farm,
                pivotID: pivot.id,
                speed: blade,
                endgun: operation === "0" ? "0" : "1",
              });
            } else {
              notify("IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG", "error");
            }
          }}
        >
          {i18n("IRRIGATION_BOX_CONVENTIONAL_BUTTON")}
        </Button>
      </div>
    </div>
  );
}
{
  /* 
  <IconButton
aria-label="redo"
style={{
  position: "absolute",
  left: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}}
onClick={() => dispatch(hideModal())}
>
<RedoIcon />
</IconButton> 
*/
}

export default ConventionalIrrigation;

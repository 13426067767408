import React, { useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TablePagination } from "@material-ui/core";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import {
  TablePaginationActions,
  labelDisplayedRows,
} from "../../../../../../../../../components/GeneralTable/GeneralTable";
import Skeleton from "@material-ui/lab/Skeleton";
import { isMobile } from "../../../../../../../../../mobileConfig";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#44b86a",
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: isMobile() ? "88vw" : "100%",
    height: "400px",
  },
  container: {
    width: "100%",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  status_open: {
    color: "#2370c5",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#cff5f6",
    fontWeight: "bold",
    border: "1px solid #2370c5",
    borderRadius: "4px",
    padding: "2px",
  },
  status_paid: {
    color: "#147619",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#d7f7c2",
    fontWeight: "bold",
    border: "1px solid #147619",
    borderRadius: "4px",
    padding: "2px",
  },
  status_past_due: {
    color: "#b3093c",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#ffe7f2",
    fontWeight: "bold",
    border: "1px solid #b3093c",
    borderRadius: "4px",
    padding: "2px",
  },
  status_void: {
    color: "#545969",
    "&:hover": {
      textDecoration: "underline #ffffff",
    },
    backgroundColor: "#ebeef1",
    fontWeight: "bold",
    border: "1px solid #545969",
    borderRadius: "4px",
    padding: "2px",
  },
  link_pay: {
    color: "#545969",
    fontWeight: "bold",
  },
  link_docs: {
    color: "#545969",
  },
}));

export default function BillingHistorySkeleton() {
  const classes = useStyles();

  const [count, _] = useState(1);

  const [page, setPage] = useState(0);

  const emptyRows = new Array(6).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        {new Array(isMobile() ? 3 : 4).fill(0).map((_, index) => {
          return (
            <StyledTableCell key={`col_${index}`}>
              <Skeleton height={20} />
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                {i18n("BILLING_INVOICE_DUE_DATE")}
              </StyledTableCell>

              {isMobile() ? (
                <></>
              ) : (
                <StyledTableCell align="center">
                  {i18n("BILLING_INVOICE_STATUS")}
                </StyledTableCell>
              )}

              <StyledTableCell align="center">
                {i18n("BILLING_INVOICE_AMOUNT")}
              </StyledTableCell>

              <StyledTableCell align="center">
                {i18n("BILLING_INVOICE_LINK")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {emptyRows}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={count}
        rowsPerPage={6}
        page={page}
        labelDisplayedRows={labelDisplayedRows}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

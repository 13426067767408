import React from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Input,
  InputBaseComponentProps,
} from "@material-ui/core";
import "./FormInputIrrigation.scss";
import { ReactJSX } from "../../../utils/types";
import checkExists from "../../../utils/checks/checkExists";
import { i18n, i18nTextId } from "../../../i18n/i18nText";

interface Props {
  id: string;
  label: string;
  type?: string;
  inputMode?: string;
  min?: string;
  max?: string;
  val: string;
  onFocus?: (e: any) => void;
  onChangeFunction: (e: any) => void;
  startAdornment?: ReactJSX | string;
  endAdornment?: ReactJSX | string;
  disabled?: boolean;
  error?: string;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  helperText?: string;
}

/*
  NAO UTILIZAR type="number", usar inputMode="numeric" no lugar!

  Uma vez que type="number" libera o usuario de escrever
  multiplos ".", "+", "-" e "e".
*/

function FormInputIrrigation(props: Props) {
  const {
    id,
    label,
    val,
    onChangeFunction,
    type,
    inputMode,
    min,
    max,
    startAdornment,
    endAdornment,
    disabled,
    helperText,
    onFocus,
    inputComponent,
  } = props;

  const textProps = { min: min, max: max };
  const numericProps = {
    min: min,
    max: max,
    pattern: "d*",
    inputMode: "numeric",
  };

  return (
    <FormControl className="form-input__form-control">
      <InputLabel
        htmlFor={`${id}`}
        shrink={checkExists(startAdornment) ? true : undefined}
      >
        {label}
      </InputLabel>
      <Input
        id={`${id}`}
        type={type}
        disabled={disabled}
        error={!!helperText}
        value={val}
        onChange={onChangeFunction}
        aria-describedby={`helper-${id}`}
        inputProps={inputMode === "numeric" ? numericProps : textProps}
        inputComponent={inputComponent}
        // floatingLabel={true}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">
              <div className="form-input__adornments">{startAdornment}</div>
            </InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">
              <div className="form-input__adornments">{endAdornment}</div>
            </InputAdornment>
          )
        }
        onFocus={onFocus}
      />
      <FormHelperText
        style={{
          color: "red",
          margin: 0,
          padding: 0,
          fontSize: 12,
          backgroundColor: "#ff000005",
          boxShadow: "0px 5px 12px  #ff000015",
        }}
        id={`helper-${id}`}
      >
        {helperText !== undefined ? i18n(helperText as i18nTextId) : ""}
      </FormHelperText>{" "}
    </FormControl>
  );
}

export default FormInputIrrigation;

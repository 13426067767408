import { action } from "typesafe-actions";
import { Billing, BillingTypes } from "./types";
import { Action } from "redux";

export interface SetBilling extends Action {
  type: BillingTypes.SET_BILLING;
  payload: Billing;
}
export interface UpdateOrInsertBilling extends Action {
  type: BillingTypes.UPDATE_OR_INSERT_BILLING;
  payload: Billing | null;
}

export interface Reset extends Action {
  type: BillingTypes.RESET;
  payload: Billing | null;
}

export const setBilling = (billing: Billing): SetBilling =>
  action(BillingTypes.SET_BILLING, billing);

export const updateOrInsertBilling = (
  billing: Billing | null
): UpdateOrInsertBilling => {
  return action(BillingTypes.UPDATE_OR_INSERT_BILLING, billing);
};

export const reset = (billing: Billing | null): Reset => {
  return action(BillingTypes.RESET, billing);
};
export type BillingActions = SetBilling & UpdateOrInsertBilling;

import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import Map, { MapMouseEvent } from "../../../../../../../../components/Map/Map";
import CustomMarker, {
  CustomMarkerProps,
} from "../../../../../../../../components/CustomMarker/CustomMarker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  centerMarkerState,
  initialMarkerState,
  referenceMarkerState,
  addMarkerToGlobalMap,
  getMarkerFromGlobalMap,
  isSetorialPivotState,
  isNorthReferenceState,
} from "../../../../../../../../recoils/DraggableMapRecoil";

import { nanoid } from "nanoid";

export interface DraggableMarker {
  lat: {
    state: string;
    setFunction: (value: string) => void;
  };
  lng: {
    state: string;
    setFunction: (value: string) => void;
  };
  markerUrl: string;
  key: string;
  event: {
    state: boolean;
    setFunction: (value: boolean) => void;
  };
}

interface Props {
  markers: DraggableMarker[];
  shouldCentralize?: boolean;
  latCenter?: number;
  lngCenter?: number;
  _setExperimentalRecoil?: boolean;
  zoomScroll?: boolean;
}

function DraggableMap(props: Props) {
  const [draggable, setDraggable] = useState(true);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const latCenter = parseFloat(props.markers[0].lat.state);
  const lngCenter = parseFloat(props.markers[0].lng.state);

  const setCenterMarkerLatLng = useSetRecoilState(centerMarkerState);
  const setInitialMarkerLatLng = useSetRecoilState(initialMarkerState);
  const setReferenceMarkerLatLng = useSetRecoilState(referenceMarkerState);

  //Array que representa os markers que estão vindo via props mapeados com seus respectivos
  //estados recoil
  const SET_RECOIL_STATES = [
    setCenterMarkerLatLng,
    setInitialMarkerLatLng,
    setReferenceMarkerLatLng,
  ];

  const markersRef = useRef<google.maps.Marker[]>([]);

  const mapRef = useRef<any>();

  //ReferenceMarker
  let referenceMarkerCurrentState = useRecoilValue(referenceMarkerState);

  let referenceMarkerRef = useMemo(() => {
    return getMarkerFromGlobalMap(referenceMarkerCurrentState?.uuid);
  }, [referenceMarkerCurrentState?.uuid]);

  // IsSetorial
  const setorial = useRecoilValue(isSetorialPivotState);

  useEffect(() => {
    if (!setorial) {
      referenceMarkerRef?.marker.setMap(null);
    } else {
      //Se nunca existir uma referencia para o Pivo Setorial
      if (referenceMarkerRef == undefined) {
        let markerUUID = nanoid();
        let markerMap = new google.maps.Marker({
          position: {
            lat: parseFloat(props.markers[props.markers.length - 1].lat.state),
            lng: parseFloat(props.markers[props.markers.length - 1].lng.state),
          },
          map: mapRef.current,
          draggable: true,
          icon: props.markers[props.markers.length - 1].markerUrl,
        });
        addMarkerToGlobalMap(markerUUID, markerMap);
        setReferenceMarkerLatLng({
          lat: props.markers[props.markers.length - 1].lat.state,
          lng: props.markers[props.markers.length - 1].lng.state,
          uuid: markerUUID,
        });
        //Move with debounce, com flag se está movendo para o selector não atualizar ele mesmo
        markerMap.addListener("drag", (evt) => {
          if (markerMovedTimeout.current) return;

          markerMovedTimeout.current = true;

          setTimeout(() => {
            setReferenceMarkerLatLng({
              lat: evt.latLng.lat().toFixed(6),
              lng: evt.latLng.lng().toFixed(6),
              uuid: markerUUID,
            });

            markerMovedTimeout.current = false;
          }, 16);
        });

        //On end set one more time
        markerMap.addListener("dragend", (evt) => {
          //Update recoil
          setReferenceMarkerLatLng({
            lat: evt.latLng.lat().toFixed(6),
            lng: evt.latLng.lng().toFixed(6),
            uuid: markerUUID,
          });

          //Update legacy
          props.markers[props.markers.length - 1].lat.setFunction(
            String(evt.latLng.lat().toFixed(6))
          );
          props.markers[props.markers.length - 1].lng.setFunction(
            String(evt.latLng.lng().toFixed(6))
          );
          props.markers[props.markers.length - 1].event.setFunction(true);
        });
      }
      referenceMarkerRef?.marker.setMap(mapRef.current);
    }
  }, [setorial]);

  //InitialMarker Ref

  let initialMarkerCurrentState = useRecoilValue(initialMarkerState);

  let initialMarkerRef = useMemo(() => {
    return getMarkerFromGlobalMap(initialMarkerCurrentState?.uuid);
  }, [initialMarkerCurrentState?.uuid]);

  //isNorthReference
  const northReference = useRecoilValue(isNorthReferenceState);

  useEffect(() => {
    if (northReference) {
      initialMarkerRef?.marker.setMap(null);
    } else {
      initialMarkerRef?.marker.setMap(mapRef.current);
    }
  }, [northReference]);

  let markerMovedTimeout = useRef<any>(null);

  const center = useMemo(() => {
    return { lat: latCenter, lng: lngCenter };
  }, [latCenter, lngCenter]);

  const onDraggable = useCallback(
    (childKey: string, childProps: CustomMarkerProps, mouse: MapMouseEvent) => {
      if (draggable) setDraggable(false);
      if (mouse && mouse.lat)
        props.markers.forEach((marker) => {
          if (marker.key === childKey) {
            marker.lat.setFunction(String(mouse.lat.toFixed(7)));
            marker.lng.setFunction(String(mouse.lng.toFixed(7)));
            marker.event.setFunction(true);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.markers]
  );

  // useEffect(() => {
  //   if (props.shouldCentralize) setBounds(calculateBounds());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.shouldCentralize]);

  const afterDraggable = useCallback(() => {
    setDraggable(true);
    // setBounds(calculateBounds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markers]);

  const calculateBounds = useMemo(() => {
    if (props._setExperimentalRecoil) return;

    //If it's one marker only, we don't need to calculate bounds, it will mess
    //with the zoom, so we just follow it to the new center
    if (props.markers.length == 1 && mapRef.current) {
      let marker = props.markers[0];
      mapRef.current.panTo(
        new google.maps.LatLng(
          parseFloat(marker.lat.state),
          parseFloat(marker.lng.state)
        )
      );
      return undefined;
    }

    //Else, we follow the bounds of the whole map
    const bounds = new window.google.maps.LatLngBounds();

    props.markers.forEach((marker) => {
      if (
        !isNaN(parseFloat(marker.lat.state)) &&
        !isNaN(parseFloat(marker.lng.state))
      ) {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(marker.lat.state),
            parseFloat(marker.lng.state)
          )
        );
      }
    });

    setBounds(bounds);

    return bounds;
  }, [props.markers]);

  const onAPILoaded = useCallback((mapObject: any) => {
    mapRef.current = mapObject.map;

    //Experimental, desenha os markers pelo Recoil
    if (props._setExperimentalRecoil) {
      props.markers.map((marker, index) => {
        let markerUUID = nanoid();

        let setMarkerState = SET_RECOIL_STATES[index];

        let markerMap = new google.maps.Marker({
          position: {
            lat: parseFloat(marker.lat.state),
            lng: parseFloat(marker.lng.state),
          },
          map: mapRef.current,
          draggable: true,
          icon: marker.markerUrl,
        });

        addMarkerToGlobalMap(markerUUID, markerMap);

        //Seta inicialmente os markers
        setMarkerState({
          lat: marker.lat.state,
          lng: marker.lng.state,
          uuid: markerUUID,
        });

        //Move with debounce, com flag se está movendo para o selector não atualizar ele mesmo
        markerMap.addListener("drag", (evt) => {
          if (markerMovedTimeout.current) return;

          markerMovedTimeout.current = true;

          setTimeout(() => {
            setMarkerState({
              lat: evt.latLng.lat().toFixed(6),
              lng: evt.latLng.lng().toFixed(6),
              uuid: markerUUID,
            });

            markerMovedTimeout.current = false;
          }, 16);
        });

        //On end set one more time
        markerMap.addListener("dragend", (evt) => {
          //Update recoil
          setMarkerState({
            lat: evt.latLng.lat().toFixed(6),
            lng: evt.latLng.lng().toFixed(6),
            uuid: markerUUID,
          });

          //Update legacy
          marker.lat.setFunction(String(evt.latLng.lat().toFixed(6)));
          marker.lng.setFunction(String(evt.latLng.lng().toFixed(6)));
          marker.event.setFunction(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      //Tira os markers
      markersRef.current.forEach((marker) => marker.setMap(null));
    };
  }, []);

  // useMemo(() => {
  //   setBounds(calculateBounds());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.markers]);

  return (
    <Map
      lat={props.markers.length > 0 ? bounds.getCenter().lat() : -21}
      lng={props.markers.length > 0 ? bounds.getCenter().lng() : -50}
      draggable={draggable}
      bounds={calculateBounds}
      zoom={15}
      center={center}
      {...(!props._setExperimentalRecoil
        ? {
            onChildMouseDown: onDraggable,
            onChildMouseUp: afterDraggable,
            onChildMouseMove: onDraggable,
          }
        : {})}
      onGoogleApiLoaded={onAPILoaded}
      mapType={"hybrid"}
      zoomScroll={props.zoomScroll}
    >
      {!props._setExperimentalRecoil &&
        props.markers.map((marker) => {
          return (
            <CustomMarker
              lat={parseFloat(marker.lat.state)}
              lng={parseFloat(marker.lng.state)}
              key={marker.key}
            >
              <img alt="" src={marker.markerUrl} />
            </CustomMarker>
          );
        })}
    </Map>
  );
}

export default DraggableMap;

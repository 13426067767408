import React from "react";
import { useNavigate, useParams } from "react-router";
import { isMobile } from "../../../../mobileConfig";
import DesktopZone from "../../../../components/Zones/DesktopZone";
import MobileZone from "../../../../components/Zones/MobileZone";
import EditFormsPlaceholder from "../../../../components/EditFormsPlaceholder/EditFormsPlaceholder";
import { i18n } from "../../../../i18n/i18nText";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import EditRepeaterForm from "./components/EditRepeaterForm";
import useRepeater from "../../../../hooks/models/useRepeater";
import "./EditRepeater.scss";

function EditRepeater() {
  const farmID: number = parseInt(useParams().farm, 10);
  const repeaterID: number = parseInt(useParams().repeater, 10);

  const navigate = useNavigate();
  const [async, repeater] = useRepeater(farmID, repeaterID);

  if (async.done && repeater) {
    return (
      <div className={isMobile() ? "edit-repeater-mobile" : "edit-repeater"}>
        <EditRepeaterForm repeater={repeater} />
      </div>
    );
  }

  return (
    <div className={isMobile() ? "edit-repeater-mobile" : "edit-repeater"}>
      <DesktopZone>
        <CustomCircularProgress />
      </DesktopZone>
      <MobileZone>
        <EditFormsPlaceholder
          centerElement={
            <h2>
              <>{i18n("EDIT_FARM_REPEATERS")}</>
            </h2>
          }
          goBack={() => {
            navigate(`/farms/${farmID}`);
          }}
        />
      </MobileZone>
    </div>
  );
}

export default EditRepeater;

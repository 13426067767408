import moment from "moment";
import { useEffect, useState } from "react";

function calculateRemainingSeconds(countdown: {
  counter: number;
  timestamp: string;
}) {
  var seconds: number = moment
    .duration(moment().diff(moment(countdown.timestamp)))
    .asSeconds();
  if (seconds >= countdown.counter) return 0;
  else return Math.round(countdown.counter - seconds);
}

export function useCountdown(
  type: "Pivot" | "IMeter" | "Irpd" | "Base" | "Repeater",
  equipmentID: string
): [number, Function] {
  const [counter, setCounter] = useState(
    // Caso esteja contando tempo continua contando pelo valor no local storage, caso não esteja começa com valor zerado
    !JSON.parse(localStorage?.getItem(`${type}ConfigCountdown`))?.[equipmentID]
      ? 0
      : calculateRemainingSeconds(
          JSON.parse(localStorage.getItem(`${type}ConfigCountdown`))[
            equipmentID
          ]
        )
  );

  function updateCounter(value: number) {
    setCounter(value);
  }

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    // Para de contar o tempo quando atingir o valor zero
    if (counter <= 0) clearInterval(timer);

    // Sistema que persiste o contador de tempo no local storage
    var pivotConfigCountdownString: string = localStorage.getItem(
      `${type}ConfigCountdown`
    );
    if (pivotConfigCountdownString === null) {
      localStorage.setItem(
        `${type}ConfigCountdown`,
        JSON.stringify({
          [equipmentID]: { counter, timestamp: moment().toISOString() },
        })
      );
    } else {
      // Atualizando counter no localstorage para este pivô
      var pivotConfigCountdown = JSON.parse(pivotConfigCountdownString);
      pivotConfigCountdown[equipmentID] = {
        counter,
        timestamp: moment().toISOString(),
      };
      localStorage.setItem(
        `${type}ConfigCountdown`,
        JSON.stringify(pivotConfigCountdown)
      );
    }

    return () => clearInterval(timer);
  }, [counter]);

  return [counter, updateCounter];
}

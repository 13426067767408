import React, { useMemo } from "react";
import moment from "moment";
import { Tooltip, Button } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import { Column } from "../../../../../components/GeneralTable/GeneralTable";
import DesktopZone from "../../../../../components/Zones/DesktopZone";
import { i18n } from "../../../../../i18n/i18nText";
import { getModeAdd } from "../../../../../utils/models/pivots";
import { ReactJSX } from "../../../../../utils/types";
import TheExpandedImeter from "./TheExpandedImeter";
import useImeterConfigHistoricV5 from "./useImeterConfigHistoricV5";
import { Labels } from "./HistoricConfigMobileV5Imeter";
import { isMobile } from "../../../../../mobileConfig";
import ConfigHistoryTableImeter from "./ConfigHistoryTableImeter";

const noPointer1 = {
  cursor: "default",
  marginTop: "-5px",
};

const noPointer2 = {
  cursor: "default",
  marginTop: "-7px",
};

const noPointer3 = {
  cursor: "default",
  marginTop: "-7px",
};

function getMessageStatusIcon(status: number): JSX.Element | null {
  switch (status) {
    case 0:
      return <RemoveIcon style={noPointer1} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 1:
      return <DoneIcon style={noPointer2} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 2:
      return <DoneAllIcon style={noPointer3} htmlColor={"rgb(33, 150, 243)"} />;
    default:
      break;
  }
}

function getMessageStatusTooltipText(status: number): string {
  switch (status) {
    case 0:
      return i18n("CONFIG_NOT_SENT");
    case 1:
      return i18n("CONFIG_SENT");
    case 2:
      return i18n("CONFIG_RECEIVED");
    default:
      break;
  }
}

interface Props {
  farmID: number;
  iMeter: any;
  iMeterID: number;
  meterSystemID: number;
  pinned?: boolean;
  loadHistoricInfo: (
    metersystemName: string,
    imeterName: string,
    imeterSensor: any,
    imeterA: any,
    imeterB: any,
    imeterC: any,
    imeterLat: string,
    imeterLng: string,
    imeterMarker: any,
    imeterSensorMaxValue: any,
    imeterSensorOffset: any,
    imeterMinLimit: any,
    imeterMaxLimit: any
  ) => void;
  sensorOptions: any[];
}

const HistoricConfigV5Imeter = (props: Props) => {
  const {
    farmID,
    iMeter,
    iMeterID,
    meterSystemID,
    loadHistoricInfo,
    sensorOptions,
  } = props;

  const [
    async,
    historic,
    total,
    previousPage,
    nextPage,
    currentPage,
  ] = useImeterConfigHistoricV5(farmID, iMeterID, meterSystemID, props.pinned);

  // MOBILE -----------------------------------------------------------------------------

  const normalColumnsMobile: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const pinnedColumnsMobile: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const rowsMobile = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const imeter = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        datailedComponent: (): ReactJSX => (
          <TheExpandedImeter
            imeter={imeter}
            imeterID={iMeterID}
            farmID={farmID}
            iMeter={iMeter}
            iMeterConfig={item}
            loadHistoricInfo={loadHistoricInfo}
            sensorOptions={sensorOptions}
            meterSystemID={meterSystemID}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, iMeter]);

  // DESKTOP -----------------------------------------------------------------------------

  const normalColumnsDesktop: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "imeter",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_IMETER")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_IMETER")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const pinnedColumnsDesktop: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "imeter",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_IMETER")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_IMETER")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const rowsDesktop = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const imeter = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        imeter: imeter,
        datailedComponent: (): ReactJSX => (
          <TheExpandedImeter
            imeterID={iMeterID}
            farmID={farmID}
            iMeter={iMeter}
            iMeterConfig={item}
            loadHistoricInfo={loadHistoricInfo}
            sensorOptions={sensorOptions}
            meterSystemID={meterSystemID}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, iMeter]);

  return (
    <div>
      <DesktopZone>
        <Labels />
      </DesktopZone>

      {isMobile() ? (
        <ConfigHistoryTableImeter
          columns={props.pinned ? pinnedColumnsMobile : normalColumnsMobile}
          total={total}
          rows={rowsMobile}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsMobile === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      ) : (
        <ConfigHistoryTableImeter
          columns={props.pinned ? pinnedColumnsDesktop : normalColumnsDesktop}
          total={total}
          rows={rowsDesktop}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsDesktop === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      )}

      <DesktopZone>
        <div style={{ marginBottom: "20px" }} />
      </DesktopZone>
    </div>
  );
};

export default HistoricConfigV5Imeter;

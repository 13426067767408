import React from "react";

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  InputBaseComponentProps,
} from "@material-ui/core";

import "./FormOutlinedInput.scss";
import { ReactJSX } from "../../../utils/types";
import checkExists from "../../../utils/checks/checkExists";
import { i18n, i18nTextId } from "../../../i18n/i18nText";
import InputTooltip from "../../InputTooltip/InputTooltip";

interface Props {
  id: string;
  label: string;
  stringLabel?: string;
  type?: string;
  inputMode?: string;
  min?: string;
  max: string;
  state: [string, (value: string) => void];
  startAdornment?: ReactJSX | string;
  endAdornment?: ReactJSX | string;
  tooltip?: string;
  tooltipLabel?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  helperText: string;
  inputRef?: React.Ref<any>;
  multiline?: boolean;
}

/*
  NAO UTILIZAR type="number", usar inputMode="numeric" no lugar!

  Uma vez que type="number" libera o usuario de escrever
  multiplos ".", "+", "-" e "e".
*/

function FormOutlinedInput(props: Props) {
  const {
    id,
    label,
    state,
    type,
    inputMode,
    min,
    max,
    startAdornment,
    endAdornment,
    tooltip,
    tooltipLabel,
    stringLabel,
    disabled,
    inputComponent,
    helperText,
  } = props;
  const [count, setCount] = React.useState(0);
  const textProps = { min: min, max: max };
  const numericProps = {
    min: min,
    max: max,
    pattern: "^[-+]?d*.?d*$",
    inputMode: "decimal",
  };

  const Tooltip =
    tooltipLabel || tooltip ? (
      tooltipLabel ? (
        <InputTooltip size={18} description={tooltipLabel} />
      ) : (
        <InputTooltip size={18} description={i18n(tooltip as i18nTextId)} />
      )
    ) : null;

  return (
    <FormControl className="form-input__form-control" variant="filled">
      <InputLabel
        htmlFor={`${id}`}
        variant="filled"
        shrink={checkExists(startAdornment) ? true : undefined}
      >
        <div
          style={{
            display: "inline-flex",
            alignItems: "baseline",
            flexWrap: "nowrap",
          }}
        >
          {stringLabel ? stringLabel : i18n(label as i18nTextId)}
          {Tooltip}
        </div>
      </InputLabel>
      <OutlinedInput
        id={`${id}`}
        type={type}
        disabled={disabled}
        error={!!helperText}
        value={state[0]}
        onChange={(e) => {
          state[1]((e.target as HTMLInputElement).value);
          setCount(e.target.value.length);
        }}
        onFocus={(e) => state[1]((e.target as HTMLInputElement).value)}
        aria-describedby={`helper-${id}`}
        inputProps={inputMode === "numeric" ? numericProps : textProps}
        inputComponent={inputComponent}
        placeholder={props.placeholder}
        multiline={props.multiline}
        // floatingLabel={true}
        autoComplete="new-password"
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">
              <div className="form-input__adornments">{startAdornment}</div>
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment position="end">
            <div className="form-input__adornments">{`${count}/${max}`}</div>
          </InputAdornment>
        }
        ref={props.inputRef}
      />
      <FormHelperText
        style={{
          color: "red",
          margin: 0,
          padding: 0,
          fontSize: 12,
          // backgroundColor: "#ff000005",
          // boxShadow: "0px 5px 15px  #ff000010",
        }}
        id={`helper-${id}`}
      >
        {helperText !== undefined ? i18n(helperText as i18nTextId) : ""}
      </FormHelperText>
    </FormControl>
  );
}

export default FormOutlinedInput;

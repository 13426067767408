import React from "react";
import "./DashboardBoxTitle.scss";
import { isMobile } from "../../../../mobileConfig";
import { i18n, i18nTextId } from "../../../../i18n/i18nText";

interface Props {
  title: string;
  isMobile?: Boolean;
  pivotName?: string;
}

const PivotNameMobileStyle: React.CSSProperties = {
  width: `60vw`,
  textOverflow: `ellipsis`,
  overflowX: `hidden`,
  marginTop: 0,
  marginBottom: 0,
  alignSelf: "center",
};

function DashboardBoxTitle(props: Props) {
  return (
    <h2
      className="dashboard-box-title"
      style={isMobile() && props.pivotName ? PivotNameMobileStyle : undefined}
    >
      {isMobile() && props.pivotName ? (
        <>{i18n("PIVOT_CONFIG_DASHBOARD_NAME", [props.pivotName])}</>
      ) : (
        <>{i18n(props.title as i18nTextId)}</>
      )}
    </h2>
  );
}

export default DashboardBoxTitle;

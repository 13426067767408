import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, MenuItem, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setFilterByDate } from "../../redux/filterByDate/actions";
import useNotify from "../../hooks/tools/useNotify";
import { EventNote } from "@material-ui/icons";
import FormDateTimePicker from "../Form/FormDateTimePicker/FormDateTimePicker";
import { FilterTypesChoices } from "../../redux/filterByDate/types";
import "./FilterByDate.scss";
import { useParams } from "react-router";
import { i18n } from "../../i18n/i18nText";

interface Props {
  title: string;
  type: FilterTypesChoices;
}

function FilterByDate(props: Props) {
  const dispatch = useDispatch();

  const notify = useNotify();

  const pivotID: string = useParams().pivot;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterStartDate, setFilterStartDate] = useState<any>(
    moment().subtract(1, "month")
  );
  const [filterEndDate, setFilterEndDate] = useState<any>(moment());

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSave = () => {
    if (filterStartDate < filterEndDate && filterEndDate < moment()) {
      dispatch(
        setFilterByDate({
          start_date: filterStartDate,
          end_date: filterEndDate,
          type: props.type,
        })
      );
    } else {
      notify("PIVOT_ACTION_INVALID_DATE", "error");
    }
    handleClose();
  };

  useEffect(() => {
    setFilterStartDate(moment().subtract(1, "month"));
    setFilterEndDate(moment());
  }, [pivotID]);

  const setValidFilterEndDate = useCallback((value: moment.Moment) => {
    if (value.isAfter(moment())) {
      notify("PIVOT_ACTION_INVALID_DATE", "error");
    } else {
      setFilterEndDate(value);
    }
  }, []);

  const setValidFilterStartDate = useCallback((value: moment.Moment) => {
    if (value.isAfter(moment())) {
      notify("PIVOT_ACTION_INVALID_DATE", "error");
    } else {
      setFilterStartDate(value);
    }
  }, []);

  return (
    <div className="filter-by-date">
      <IconButton aria-label="delete" onClick={handleClick} size="small">
        <EventNote fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <FormDateTimePicker
            maxDate={moment()}
            id="report-filter-start-date"
            label={"PIVOT_ACTION_FIELD_START_DATE"}
            state={[filterStartDate, setValidFilterStartDate]}
          />
        </MenuItem>
        <MenuItem>
          <FormDateTimePicker
            maxDate={moment()}
            id="report-filter-end-date"
            label={"PIVOT_ACTION_FIELD_END_DATE"}
            state={[filterEndDate, setValidFilterEndDate]}
          />
        </MenuItem>
        <MenuItem>
          <div className="flex-btn-container">
            <Button
              variant="contained"
              style={{ background: "#0066FF", color: "white" }}
              onClick={() => {
                handleFilterSave();
              }}
            >
              <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
            </Button>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default React.memo(FilterByDate);

export enum NotificationsTypes {
  SET_NOTIFICATIONS = "@@notifications/SET_NOTIICATIONS",
  DELETE_NOTIFICATION = "@@notifications/DELETE_NOTIFICATION",
  UPDATE_OR_INSERT_NOTIFICATION = "@@notifications/UPDATE_OR_INSERT_NOTIFICATION",
}

export interface Notification {
  id?: number;
  farm: number;
  name: string;
  reasons: number[];
  enable: boolean;
  critical_reasons: number[];
  start: string;
  end: string;
  user?: number;
  devices: number[];
}

export interface NotificationsState {
  list: Notification[] | null;
}

import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { useEffect, useState, useRef } from "react";
import checkExists from "../../utils/checks/checkExists";
import { coreHTTPClient } from "../../services/webclient";
import {
  PivotAction,
  PanelStreamOld,
  GPSStream,
  PivotActionVRIDeliverer,
  ControllerStream,
  SimpleIrrigation,
  ScheduleIrrigation,
  SegmentIrrigation,
  StopAction,
  PivotMaintenance,
  CentralStream,
} from "../../redux/pivots/types";

import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../redux/filterByDate/types";

import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux";
import axios from "axios";
import { IMeterStreamEvent } from "../../recoils/MeterSystemRecoil";
import { historicOrderByDate } from "../../pages/Dashboard/Pivot/SelectedPivot/components/HistoricBox/utils/utils";

export type HistoricModels =
  | "painel_stream"
  | "pivot_action"
  | "pivot_action_vri_deliverer"
  | "gps_stream"
  | "ControllerStream_panel"
  | "ControllerStream_gps"
  | "ControllerAction_simple"
  | "ControllerAction_schedule"
  | "ControllerAction_segment"
  | "ControllerAction_stop"
  | "CentralStream";

interface Historic {
  limit: number;
  offset: number;
  count: number;
  filters: {
    models: HistoricModels[];
  };
  results: HistoricResultItem[];
}

interface PivotActionResult {
  model: "pivot_action";
  data: PivotAction;
}

interface PainelStreamResult {
  model: "painel_stream";
  data: PanelStreamOld;
}

interface GPSStreamResult {
  model: "gps_stream";
  data: GPSStream;
}

interface PivotActionVRIDelivererResult {
  model: "pivot_action_vri_deliverer";
  data: PivotActionVRIDeliverer;
}

interface PanelStreamV5Result {
  model: "ControllerStream_panel";
  data: ControllerStream;
}

interface GPSStreamV5Result {
  model: "ControllerStream_gps";
  data: ControllerStream;
}

interface SimpleIrrigationResult {
  model: "ControllerAction_simple";
  data: SimpleIrrigation;
}

interface ScheduleIrrigationResult {
  model: "ControllerAction_schedule";
  data: ScheduleIrrigation;
}

interface SegmentIrrigationResult {
  model: "ControllerAction_segment";
  data: SegmentIrrigation;
}

interface StopActionResult {
  model: "ControllerAction_stop";
  data: StopAction;
}

interface PivotMaintenanceResult {
  model: "maintenance";
  data: PivotMaintenance;
}

interface CentralStreamResult {
  model: "CentralStream";
  data: CentralStream;
}

interface IMetertreamEventResult {
  model: "imeter_stream_event";
  data: IMeterStreamEvent;
}

export type HistoricResultItem =
  | PivotActionResult
  | PainelStreamResult
  | GPSStreamResult
  | PivotActionVRIDelivererResult
  | PanelStreamV5Result
  | GPSStreamV5Result
  | SimpleIrrigationResult
  | SegmentIrrigationResult
  | ScheduleIrrigationResult
  | StopActionResult
  | IMetertreamEventResult
  | PivotMaintenanceResult
  | CentralStreamResult;

export type HistoricResultData = HistoricResultItem;

interface HistoricActions {
  getOffset: () => number;
  updateOffset: (offset: number) => void;
  getHistoric?: () => Promise<void>;
  updateHistoric?: (data: HistoricResultData) => void;
  setHistoricModels?: (historic: HistoricModels[]) => void;
  setAdvancedHistoric?: (advanced: boolean) => void;
  setCentralHistoric?: (central: boolean) => void;
}

/**
 *  Os resultados vem como
 *  [{tipo_do_resultado: objeto}]
 *
 *  Essa transformação faz com que eles fiquem
 *  [{model:"tipo_do_resultado", data: objeto}]
 *
 *  Nova API, mas continuam compatíveis
 * @param results
 */
export function mapHistoricToModelData(results: any[]): HistoricResultItem[] {
  return results.map((result) => ({
    model: Object.keys(result)[0] as any,
    data: result[Object.keys(result)[0]],
  }));
}

const usePivotHistoric = (
  farmID: number,
  pivotID: number,
  initialLimit: number,
  initialOffset: number,
  initalModels: HistoricModels[],
  arrayInitial?: boolean[]
): [AsyncStatus, Historic | undefined, HistoricActions] => {
  const [async, start, finish] = useAsyncStatus();

  const [result, setResult] = useState<Historic>();

  const [offset, setOffset] = useState(initialOffset);
  const offsetRef = useRef<number>(initialOffset);
  const [advancedHistoric, setAdvancedHistoric] = useState(false);
  const [centralHistoric, setCentralHistoric] = useState(false);
  const [models, setModels] = useState(initalModels);
  const cancelCurrentRequestRef = useRef<any | null>(null);

  const filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  useEffect(() => {
    offsetRef.current = offset;
  }, [offset]);

  async function getHistoric(offsetParam?: number) {
    if (cancelCurrentRequestRef.current) {
      cancelCurrentRequestRef.current();
    }
    if (offsetParam) setOffset(offsetParam);
    await new Promise<void>(async (resolve) => {
      start();

      let filterByDateQueryString: string;
      if (
        filterByDate.data.type === FilterTypesChoices.HISTORIC &&
        filterByDate.data.start_date &&
        filterByDate.data.end_date
      ) {
        let { start_date, end_date } = filterByDate.data;
        filterByDateQueryString = `&date_start=${start_date.toISOString()}&date_end=${end_date.toISOString()}`;
      }

      cancelCurrentRequestRef.current = new AbortController();
      try {
        const response = await coreHTTPClient
          .get(
            `v3/farms/${farmID}/pivots/${pivotID}/history/?page=${
              offsetParam || offset
            }${advancedHistoric ? "&gps=true" : ""}${
              centralHistoric ? "&central=true" : ""
            }${filterByDateQueryString ? filterByDateQueryString : ""}`,
            {
              cancelToken: new axios.CancelToken(
                (c) => (cancelCurrentRequestRef.current = c)
              ),
            }
          )
          .then((response) => {
            if (checkExists(["data.results", response, "__cascade"])) {
              let resultsMapped: HistoricResultItem[] = mapHistoricToModelData(
                response.data.results
              );

              response.data.results = historicOrderByDate(resultsMapped);
              setResult(response.data);
              finish();
            }
          });
      } catch (err) {
        //se for cancelado deixa carregando
        if (axios.isCancel(err)) return;
        finish();
      } finally {
        resolve();
      }
    });
  }

  // Recebe um novo ítem de histórico vindo do socket e percorre a lista procurando-o,
  // se não encontrar, o adiciona ao topo de tal lista

  function updateHistoric(newHistoricItem: HistoricResultItem) {
    //Se eu não estou na primeira página, não precisa ter atualização em tempo real
    if (offsetRef.current != 1) return;

    setResult((result) => {
      if (result !== undefined) {
        let isOnList = false;
        let newResult = result;

        newResult.results.map((index) => {
          if (index.data.uuid === newHistoricItem.data?.uuid) {
            //
            index.data = newHistoricItem.data;
            isOnList = true;
          }
        });

        if (!isOnList) {
          newResult.results.unshift(newHistoricItem);
          newResult.count += 1;
        }

        if (newResult.results.length > 10) newResult.results.pop();

        // @ts-ignore
        const orderedByDateResults = historicOrderByDate(newResult.results);
        newResult["results"] = orderedByDateResults;
        return { ...(newResult as Historic) };
      }
    });
  }

  function getOffset() {
    return offset;
  }

  function updateOffset(page: number) {
    setOffset(page);
  }

  function setHistoricModels(historic: HistoricModels[]) {
    setModels(historic);
  }

  useEffect(() => {
    getHistoric(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pivotID]);

  useEffect(() => {
    setOffset(1);
    getHistoric(1);
  }, [advancedHistoric, centralHistoric]);

  useEffect(() => {
    if (
      filterByDate.data.type === FilterTypesChoices.HISTORIC &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      getHistoric(1);
    }
  }, [filterByDate]);

  useEffect(() => {
    getHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return [
    async,
    result,
    {
      getOffset,
      updateOffset,
      getHistoric,
      updateHistoric,
      setHistoricModels,
      setAdvancedHistoric,
      setCentralHistoric,
    },
  ];
};

export default usePivotHistoric;

import React from "react";
import moment from "moment";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ErrorIcon from "@material-ui/icons/Error";
import { ReactJSX } from "../../../../../../../utils/types";
import {
  getPanelStreamStatus,
  getPanelStreamStatusV5,
  PANEL_STREAM_REASON_GROUP_STATUS,
  PANEL_STREAM_REASON_GROUP_STATUS_V5,
} from "../../../../../../../utils/models/pivots";
import {
  Pivot,
  PIVOT_COMMUNICATION_TYPE,
  PivotActionVRIDeliverer,
} from "../../../../../../../redux/pivots/types";
import { dateHumanized } from "../../../../../../../utils/types";
import { getPanelStreamV5Mode } from "../../../../../../../utils/models/pivots";
import { blue, red } from "@material-ui/core/colors";
import { checkFloat } from "../../../../../../../utils/checks";
import { DATETIME_FORMAT } from "../../../../../../../utils/consts";
import { Tooltip } from "@material-ui/core";
import { i18n, i18nTextId } from "../../../../../../../i18n/i18nText";
import { RedoOutlined, UndoOutlined } from "@material-ui/icons";
import { formatFloatValue } from "../../../../../../../utils/models/format";

export const validadeFloatInput = (value) => {
  if (value[0] === "+") {
    //
    return false;
  }
  if (value === "" || value === "-" || checkFloat(value)) {
    //
    return true;
  } else {
    //
    return false;
  }
};

export const hasValidationErrors = (value, min, max) => {
  if (
    value.length === 0 ||
    parseFloat(value) <= 0 ||
    parseFloat(value) < min ||
    parseFloat(value) > max ||
    value[0] < "0" ||
    value[0] > "9"
  ) {
    //
    //
    //
    //
    //
    //
    //
    return true;
  } else {
    //
    return false;
  }
};

//Essa função é igual a de cima, mas é usada apenas nos campos de Tempo de retardo e Tempo Instável na aba de bomba do pivô V5
export const hasValidationErrorsIncludeZero = (value, min, max) => {
  if (
    value.length === 0 ||
    parseFloat(value) < 0 ||
    parseFloat(value) < min ||
    parseFloat(value) > max ||
    value[0] < "0" ||
    value[0] > "9"
  ) {
    //
    //
    //
    //
    //
    //
    //
    return true;
  } else {
    //
    return false;
  }
};

export const headDate = (ISODate: string) => {
  return moment(ISODate).format("L") + " – " + moment(ISODate).format("LT");
};

export const insertAt = (mainString, string, index) => {
  return mainString.substr(0, index) + string + mainString.substr(index);
};

export const handleNumberMask = (value: string) => {
  return value?.replace(/\D/g, ""); // substitui qualquer caracter que nao seja numero por nada
};

export const handleFloatOneDecimalMask = (numero: number | string): string => {
  const numeroComoString =
    typeof numero === "number" ? numero.toFixed(3) : numero;
  const partes = numeroComoString.split(".");

  if (partes.length === 2) {
    const parteDecimal = partes[1].charAt(0);
    return partes[0] + (parteDecimal === undefined ? "" : `.${parteDecimal}`);
  } else {
    return numeroComoString;
  }
};

export const handlePostalCodeMask = (value: string) => {
  if (value.length <= 9)
    return value
      ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{5})(\d)/, "$1-$2");
};

export const handlePhoneMask = (value: string, isCellphone: boolean = true) => {
  return value
    ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{0})(\d)/, "$1($2")
    .replace(/(\d{2})(\d)/, "$1)$2")
    .replace(/(\d{5})(\d)/, "$1-$2");
};

export const handleFixedPhoneMask = (
  value: string,
  isCellphone: boolean = true
) => {
  return value
    ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{0})(\d)/, "$1($2")
    .replace(/(\d{2})(\d)/, "$1)$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
};

export const handleZipCodeMask = (
  value: string,
  isCellphone: boolean = true
) => {
  return value
    ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d)/, "$1-$2");
};

export const handleOfficialDocumentMask = (
  value: string,
  isCellphone: boolean = true
) => {
  return value
    ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{9})(\d)/, "$1-$2");
};

export const handleDocCodeMask = (value: string) => {
  if (value.length <= 14)
    return value
      ?.replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const handleMaskInput = (value: string, decimalPlaces: number) => {
  return toCurrency(value, decimalPlaces);
};

const getDigitsFromValue = (value = "") =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits, decimalPlaces) => {
  const desiredLength = decimalPlaces + 1;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, "$1");

const addDecimalToNumber = (number, separator, decimalPlaces: number) => {
  const centsStartingPosition = number.length - decimalPlaces;
  const dollars = removeLeadingZeros(
    number.substring(0, centsStartingPosition)
  );
  const cents = number.substring(centsStartingPosition);
  return dollars + separator + cents;
};

export const toCurrency = (value, decimalPlaces: number, separator = ".") => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits, decimalPlaces);
  return addDecimalToNumber(digitsWithPadding, separator, decimalPlaces);
};

export const countDecimals = (value) => {
  if ((value as String).includes(".")) {
    value = parseFloat(value);
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  } else {
    return false;
  }
};

export const painelStreamPower = (power: boolean): string => {
  return power
    ? "HISTORIC_PAINEL_STREAM_WITH_ENERGY"
    : "HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY";
};

export const painelStreamSecurity = (security: boolean): string => {
  return security
    ? "HISTORIC_PAINEL_STREAM_ALIGNED"
    : "HISTORIC_PAINEL_STREAM_NOT_ALIGNED";
};

export const painelStreamPressure = (pressureSwitch: number): string => {
  return pressureSwitch === 0
    ? "HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE"
    : "HISTORIC_PAINEL_STREAM_WITH_PRESSURE";
};

export const pivotActionOverview = (direction: number): string => {
  return direction === 0
    ? "HISTORIC_PIVOT_ACTION_STOP"
    : "HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW";
};
export const pivotActionOverviewOld = (direction: number): string => {
  return direction === 0
    ? "HISTORIC_PIVOT_ACTION_STOP"
    : "HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD";
};

export const pivotActionDirection = (direction: number): string => {
  // 0 parado, 1 reverso, 2 avanço
  if (direction === 0) return i18n("PIVOT_ACTION_VALUE_DIRECTION_STOPPED");
  if (direction === 1) return i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE");
  if (direction === 2) return i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS");
  return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
};

export const pivotActionDirectionV5 = (direction: number): string => {
  // 0 parado, 1 reverso, 2 avanço
  if (direction === 0) return i18n("PIVOT_ACTION_VALUE_DIRECTION_STOPPED");
  if (direction === 1) return i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS");
  if (direction === 2) return i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE");
  return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
};

export const pivotActionDirectionIcon = (direction: number): ReactJSX => {
  // 0 parado, 1 reverso, 2 avanço
  if (direction === 0) return <></>;
  if (direction === 1)
    return (
      <UndoOutlined
        fontSize="small"
        style={{ margin: "-5px 0 -4px 0", color: "white" }}
      />
    );
  if (direction === 2)
    return (
      <RedoOutlined
        fontSize="small"
        style={{ margin: "-5px 0 -4px 0", color: "white" }}
      />
    );
  return <></>;
};

export const pivotActionDirectionIconV5 = (direction: number): ReactJSX => {
  // 0 parado, 1 reverso, 2 avanço
  if (direction === 0) return <></>;
  if (direction === 1)
    return (
      <RedoOutlined
        fontSize="small"
        style={{ margin: "-5px 0 -4px 0", color: "white" }}
      />
    );
  if (direction === 2)
    return (
      <UndoOutlined
        fontSize="small"
        style={{ margin: "-5px 0 -4px 0", color: "white" }}
      />
    );
  return <></>;
};

export function pivotActionVRIDirection(direction: number): string {
  if (direction === 0) return i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS");
  if (direction === 1) return i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE");
  return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
}

export const pivotActionMode = (mode: number): string => {
  return mode === 2
    ? i18n("PIVOT_ACTION_VALUE_MODE_WET")
    : i18n("PIVOT_ACTION_VALUE_MODE_DRY");
};

export const pivotActionVRIDeliverEndgun = (endgun: number): string => {
  if (endgun == -1) return "";
  return endgun === 1
    ? i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON")
    : i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF");
};

export const irrigationType = (irrigationType: number): string => {
  switch (irrigationType) {
    case 1:
      return i18n("HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION");
    case 2:
      return i18n("HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION"); // TODO Irrigação por ângulo?
    case 3:
      return i18n("HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION");
    default:
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
};

export function irrigationStartMode(startMode: number): string {
  switch (startMode) {
    case 0:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_NOW");
    case 1:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE");
    default:
      // Estou reutilizando o TextContentID que possui o texto desejado
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
}

export const irrigationEndMode = (irrigationEndMode: number): string => {
  switch (irrigationEndMode) {
    case 0:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS");
    case 1:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE");
    case 2:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE");
    case 3:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR");
    case 4:
      return i18n("HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS");
    default:
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
};

export const irrigationDirection = (irrigationDirection: number): string => {
  switch (irrigationDirection) {
    case 0:
      return i18n("HISTORIC_PANEL_STREAM_V5_STOPPED");
    case 1:
      return i18n("HISTORIC_PANEL_STREAM_V5_FORWARD");
    case 2:
      return i18n("HISTORIC_PANEL_STREAM_V5_REVERSE");
    default:
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
};

export const irrigationMode = (irrigationMode: number): string => {
  switch (irrigationMode) {
    case 0:
      return i18n("IRRIGATION_MODE_NONE");
    case 1:
      return i18n("IRRIGATION_MODE_WET");
    case 2:
      return i18n("IRRIGATION_MODE_DRY");
    case 3:
      return i18n("IRRIGATION_MODE_FERTIRRIGATION");
    default:
      return i18n("IRRIGATION_MODE_NONE");
  }
};

export const irrigationInjectionPump = (
  irrigationInjectionPump: number
): string => {
  switch (irrigationInjectionPump) {
    case 0:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF");
    case 1:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON");
    default:
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
};

export const irrigationStopMode = (stopMode: number): string => {
  switch (stopMode) {
    case 0:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_DONT_STOP");
    case 1:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE");
    case 3:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR");
    case 4:
      return i18n("HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS");
    default:
      return i18n("PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN");
  }
};

const doneIcon = (
  <Tooltip title={i18n("COMMAND_SENT_WAITING_CONFIRMATION")}>
    <DoneIcon
      style={{
        margin: "0 5px 0 5px",
        fontSize: "1.4rem",
        color: blue[500],
      }}
    />
  </Tooltip>
);

const doneAllIcon = (
  <Tooltip title={i18n("COMMAND_CONFIRMED")}>
    <DoneAllIcon
      style={{
        margin: "0 5px 0 5px",
        fontSize: "1.4rem",
        color: blue[500],
      }}
    />
  </Tooltip>
);

const errorIcon = (message_error: string) => (
  <Tooltip title={i18n("COMMAND_ERROR") + ": " + message_error}>
    <ErrorIcon
      style={{
        fontSize: "1.4rem",
        color: red[500],
      }}
    />
  </Tooltip>
);

export const messageStatusV5 = (data: any): ReactJSX => {
  //
  if (data.message_status === 1) return doneIcon;
  if (data.message_status === 2) return doneAllIcon;
};

export function messageErrorsV5(data: any): ReactJSX {
  if (data.message_error !== "") {
    return errorIcon(data.message_error);
  }
}
//STANDARD IRRIGATION COMMAND STATUS
export const messageStatusPivotActionOld = (
  messageStatus: number,
  send: boolean
): ReactJSX => {
  if (send && messageStatus === 0) return doneIcon;
  if (send && messageStatus === 1) return doneAllIcon;
};

const wet = "rgba(70, 121, 237, 0.05)";
const dry = "rgba(147, 66, 0, 0.05)";
const problem = "rgba(255, 0, 0, 0.1)";

export const getHistoryRowBackgroundColor = (reason) => {
  let color = "";

  if (PANEL_STREAM_REASON_GROUP_STATUS_V5.PROBLEM.includes(reason)) {
    color = problem;
  } else {
    switch (reason) {
      case 4:
        color = wet;
        break;
      case 6:
        color = dry;
        break;
      case 33:
        color = dry;
        break;
      default:
        color = "white";
        break;
    }
  }

  return color;
};

export const getResumeOldColor = (reason) => {
  let color = "";

  if (PANEL_STREAM_REASON_GROUP_STATUS.PROBLEM.includes(reason)) {
    color = problem;
  } else {
    switch (reason) {
      case 1:
        color = dry;
        break;
      case 2:
        color = wet;
        break;
      default:
        color = "white";
        break;
    }
  }

  return color;
};

export const getResume = (
  model: string,
  data: any,
  pivot: Pivot,
  pivotConfig: any
) => {
  let type;
  let color = "white";
  let overview: string | ReactJSX = "";

  switch (model) {
    case "painel_stream":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE")}</>;
      overview = (
        <>
          <>{getPanelStreamStatus(data.reason).text} </>
        </>
      );
      color = getResumeOldColor(data.reason);
      break;

    case "gps_stream":
      type = (
        <>
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G
            ? i18n("HISTORIC_PAINEL_TYPE_POSITION_STREAM")
            : i18n("HISTORIC_PAINEL_TYPE_GPS_STREAM")}{" "}
        </>
      );
      overview = `${
        data.angle >= 0 ? data.angle : i18n("HISTORIC_PAINEL_NO_GPS")
      }º`;
      break;

    case "pivot_action":
      let infos = [];
      if (data.mode !== 2) {
        if (data.direction) {
          // caso a direction for zero (pivô parado) não mostrar infos
          infos = [
            pivotActionDirection(data.direction),
            pivotActionMode(data.mode),
          ];
        }
      } else {
        infos = [
          pivotActionDirection(data.direction),
          pivotActionMode(data.mode),
          `${data.speed} %`,
        ];
      }

      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      overview =
        data.mode !== 2 ? (
          <>
            <>
              {i18n(
                pivotActionOverviewOld(data.direction) as i18nTextId,
                infos
              )}
            </>
            {messageStatusPivotActionOld(data.delivered, data.sent)}
          </>
        ) : (
          <>
            <>
              {i18n(pivotActionOverview(data.direction) as i18nTextId, infos)}
            </>
            {messageStatusPivotActionOld(data.delivered, data.sent)}
          </>
        );
      break;

    case "pivot_action_vri_deliverer":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      overview = (
        <>
          <>{i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW")}</>
          {messageStatusPivotActionOld(data.delivered, data.sent)}
        </>
      );
      break;

    case "ControllerStream_panel":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_UPDATE")} </>;
      overview = (
        <>
          <>
            {
              getPanelStreamStatusV5(
                data.content.irrigation_status?.irrigation_status
              ).text
            }
          </>
          {" | "}
          <>
            {irrigationDirection(
              data.content.current_irrigation_information?.direction
            )}
          </>
          {" | "}
          <>
            {getPanelStreamV5Mode(
              data.content.current_irrigation_information?.mode
            )}
          </>
          {data.content.current_irrigation_information?.mode === 2
            ? ""
            : " | " +
              data.content.current_irrigation_information?.irrigation_percent +
              "%"}
        </>
      );
      if (
        [0, 3, 5, 8, 9, 10, 11, 12, 13, 14, 17, 22, 25, 35].includes(
          data.content.irrigation_status?.irrigation_status
        )
      ) {
        overview = (
          <>
            <>
              {
                getPanelStreamStatusV5(
                  data.content.irrigation_status?.irrigation_status
                ).text
              }
            </>
          </>
        );
      }
      color = getHistoryRowBackgroundColor(
        data.content.irrigation_status?.irrigation_status
      );
      break;

    case "ControllerStream_gps":
      type = (
        <>
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G
            ? i18n("HISTORIC_PAINEL_TYPE_POSITION_STREAM")
            : i18n("HISTORIC_PAINEL_TYPE_GPS_STREAM")}{" "}
        </>
      );
      let irrigation_percent = data.content.current_irrigation_information
        ? data.content.current_irrigation_information?.irrigation_percent + "%"
        : "";
      overview =
        pivotConfig?.content?.pressure_config?.read_pressure_by == "2"
          ? i18n("HISTORIC_PIVOT_GPS_V5_SENSOR", [
              formatFloatValue(data.content.current_angle?.current_angle, 1) +
                "º",
              irrigation_percent,
              formatFloatValue(data.content?.center_pressure?.center_pressure) +
                " bar",
              formatFloatValue(
                data.content?.end_tower_pressure?.end_tower_pressure
              ) + " bar",
              pivot.automation_type == 0
                ? data.content.voltage_measure?.voltage_measure + " V"
                : "",
            ])
          : i18n("HISTORIC_PIVOT_GPS_V5", [
              formatFloatValue(data.content.current_angle?.current_angle, 1) +
                "º",
              irrigation_percent,
              pivot.automation_type == 0
                ? formatFloatValue(
                    data.content?.end_tower_pressure?.end_tower_pressure
                  ) + " bar"
                : formatFloatValue(
                    data.content?.end_tower_pressure?.end_tower_pressure * 10
                  ) + " bar",
              pivot.automation_type == 0
                ? data.content.voltage_measure?.voltage_measure + " V"
                : "",
            ]);
      if (data.content.current_irrigation_information?.mode === 1) {
        color = wet;
      } else if (data.content.current_irrigation_information?.mode === 2) {
        color = dry;
      }
      break;

    case "ControllerAction_simple":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;

      const direction = irrigationDirection(
        data.content.irrigation_status?.irrigation_status
      );
      const mode = irrigationMode(
        data.content.simple_irrigation_parameters?.mode
      );

      overview = (
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.content.irrigation_status?.irrigation_status === 0 ? (
            <>{i18n("HISTORIC_PANEL_STREAM_V5_STOP_PIVOT")}</>
          ) : (
            <>
              {`${i18n(
                "HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION"
              )} | ${direction} | ${mode}`}
            </>
          )}
          <div>{messageStatusV5(data)}</div>
          <div>{messageErrorsV5(data)}</div>
        </div>
      );
      break;

    case "ControllerAction_schedule":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      overview = (
        <>
          {data.content?.irrigation_status?.irrigation_status === 0 ? (
            <>{i18n("HISTORIC_PANEL_STREAM_V5_STOP_PIVOT")}</>
          ) : data.angle_V5 ? (
            <>{i18n("MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION")}</>
          ) : (
            <>{i18n("HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION")}</>
          )}

          {messageStatusV5(data)}
          {messageErrorsV5(data)}
        </>
      );
      break;

    case "ControllerAction_segment":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      overview = (
        <>
          {data.content.irrigation_status.irrigation_status === 0 ? (
            <>{i18n("HISTORIC_PANEL_STREAM_V5_STOP_PIVOT")}</>
          ) : (
            <>{i18n("HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION")}</>
          )}
          {messageStatusV5(data)}
          {messageErrorsV5(data)}
        </>
      );
      break;

    case "ControllerAction_stop":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")}</>;
      overview = (
        <>
          {i18n("HISTORIC_PANEL_STREAM_V5_STOP_PIVOT")}
          {messageStatusV5(data)}
          {messageErrorsV5(data)}
        </>
      );
      break;
    case "maintenance":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_MAINTENANCE")}</>;
      overview = i18n(
        data.maintenance
          ? "HISTORIC_MAINTENANCE_ON"
          : "HISTORIC_MAINTENANCE_OFF"
      );
      color = "#FEF4E5";
      break;
    case "CentralStream":
      type = <>{i18n("CENTRAL_UPDATE")}</>;
      overview = (
        <>
          {i18n(
            data?.status == 1
              ? "SELECTED_FARM_CENTRAL_ONLINE"
              : "SELECTED_FARM_CENTRAL_OFFLINE"
          )}
        </>
      );
      break;
    default:
      break;
  }

  return { type, color, overview };
};

export const getResumeNoContent = (model: string, pivot: Pivot) => {
  let type;
  let color = "#fde5e5";
  let overview = i18n("HISTORIC_DATA_UNDEFINDED");

  switch (model) {
    case "painel_stream":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE")}</>;
      break;

    case "gps_stream":
      type = (
        <>
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G
            ? i18n("HISTORIC_PAINEL_TYPE_POSITION_STREAM")
            : i18n("HISTORIC_PAINEL_TYPE_GPS_STREAM")}{" "}
        </>
      );
      break;

    case "pivot_action":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      break;

    case "pivot_action_vri_deliverer":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      break;

    case "ControllerStream_panel":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_UPDATE")} </>;
      break;

    case "ControllerStream_gps":
      type = (
        <>
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G
            ? i18n("HISTORIC_PAINEL_TYPE_POSITION_STREAM")
            : i18n("HISTORIC_PAINEL_TYPE_GPS_STREAM")}{" "}
        </>
      );
      break;

    case "ControllerAction_simple":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      break;

    case "ControllerAction_schedule":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      break;

    case "ControllerAction_segment":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")} </>;
      break;

    case "ControllerAction_stop":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_COMMAND")}</>;
      break;
    case "maintenance":
      type = <>{i18n("HISTORIC_PAINEL_TYPE_MAINTENANCE")}</>;
      break;
    case "CentralStream":
      type = <>{i18n("CENTRAL_UPDATE")}</>;
      break;
    default:
      break;
  }

  return { type, color, overview };
};

// TODO: substituir a formatDateTime por essa daqui quando dar tempo
export function formatDateTime2(
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number
): string {
  try {
    return moment()
      .year(year + 2000)
      .month(month - 1)
      .date(day)
      .hour(hour)
      .minute(minute)
      .format(DATETIME_FORMAT);
  } catch {
    return "";
  }
}

export function formatDateToMomment(
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number
): moment.Moment {
  return moment()
    .year(year + 2000)
    .month(month - 1)
    .date(day)
    .hour(hour)
    .minute(minute);
}

export const expandDateArgs = (ISODate: string) => {
  return [dateHumanized(ISODate), moment(ISODate).format("DD MMM - HH:mm")];
};

export function historicOrderByDate(list: any[]): any[] {
  return list.sort((a, b) => {
    // Caso as datas do created forem iguais, desempate pelo arrived
    if (moment(a.data.created).isSame(moment(b.data.created))) {
      if (moment(a.data?.arrived).isBefore(moment(b.data?.arrived))) {
        return 1;
      }
      if (moment(a.data?.arrived).isAfter(moment(b.data?.arrived))) {
        return -1;
      }
    } else return a.data.created.valueOf() - b.data.created.valueOf();
  });
}

// Função que realiza a conversão de dados do tipo Agendamento V5 para Angulo V4
export function schedule2ActionVRI(data) {
  let speed_array = [];
  let direction_array = [];
  let endgun_array = [];
  let angle_array = [];
  let start: any;

  data.content.schedule_irrigation_parameters.map((schedule) => {
    speed_array.push(schedule.percent);
    direction_array.push(schedule.direction == 1 ? 0 : 1);
    endgun_array.push(-1);
    angle_array.push(schedule.stop_angle);
    start = !schedule.start_now
      ? new Date(
          schedule.start_year,
          schedule.start_month - 1,
          schedule.start_day,
          schedule.start_hour - 3,
          schedule.start_minute
        )
      : null;
  });

  let userprofile = {
    username: data.username,
    id: data.created_by,
    name: "Placeholder",
    first_name: "Place",
    last_name: "holder",
    email: "placeholder@email.com",
    profile_id: data.created_by,
  };

  var result: PivotActionVRIDeliverer = {
    created: data.created,
    start: start,
    manual: false,
    actionrecipe: {
      mode: data.content.schedule_irrigation_parameters[0].mode == 2 ? 1 : 2,
      speed_array: speed_array,
      direction_array: direction_array,
      endgun_array: endgun_array,
      angle_array: angle_array,
      id: 99,
      uuid: data.uuid,
      arrived: data.arrived,
      created: data.created,
      updated_at: data.created,
      name: "PLaceholder",
      start_angle: data.current_angle,
      manual: false,
      saved: true,
      origin: 68,
      user: userprofile,
      pivot: data.equipmenmt_id,
    },
    user_profile: userprofile,
    // Campos necessários para converter para PivotActionVRIDeliverer, mas que não são usados
    id: 99,
    uuid: data.uuid,
    arrived: data.arrived,
    sent: true,
    achieve: 99,
    delivered: 0,
    recomendation: false,
    display: true,
    frame_id: -1,
    user: data.created_by,
    content: data?.content,
  };

  return result;
}

import React, { useMemo } from "react";
import { Column } from "../../../../../../../../components/GeneralTable/GeneralTable";
import { Pivot } from "../../../../../../../../redux/pivots/types";
import moment from "moment";
import { getModeAdd } from "../../../../../../../../utils/models/pivots";
import ConfigHistoryTable from "../ConfigHistoryTable";
import { Tooltip } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { Labels } from "../../../EditPivotForm/components/HistoricConfigMobile/HistoricConfigMobileV5";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import useConfigEncoder from "../../../../../../../../hooks/models/useConfigEncoder";

const noPointer1 = {
  cursor: "default",
  marginTop: "-5px",
};

const noPointer2 = {
  cursor: "default",
  marginTop: "-7px",
};

const noPointer3 = {
  cursor: "default",
  marginTop: "-7px",
};

function getMessageStatusIcon(status: number): JSX.Element | null {
  switch (status) {
    case 0:
      return <RemoveIcon style={noPointer1} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 1:
      return <DoneIcon style={noPointer2} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 2:
      return <DoneAllIcon style={noPointer3} htmlColor={"rgb(33, 150, 243)"} />;
    default:
      break;
  }
}

function getMessageStatusTooltipText(status: number): string {
  switch (status) {
    case 0:
      return i18n("CONFIG_NOT_SENT");
    case 1:
      return i18n("CONFIG_SENT");
    case 2:
      return i18n("CONFIG_RECEIVED");
    default:
      break;
  }
}

interface Props {
  pivot: Pivot;
  farmID: number;
  pivotID: number;
  ctx?: any;
}

const HistoricConfigEncoder = (props: Props) => {
  const { pivot, pivotID, farmID } = props;
  const [
    async,
    historic,
    total,
    previousPage,
    nextPage,
    currentPage,
  ] = useConfigEncoder(farmID, pivotID);

  const columns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "encoder",
      label: <>{"E"}</>,
      minWidth: 200,
    },
  ];

  const rows = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(false, item.created_by?.username),
          ])}
        </span>
      );

      const encoder = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        encoder: encoder,
        datailedComponent: () => {
          return [];
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic]);

  return (
    <div>
      <DesktopZone>
        <Labels isV5 pivot={pivot} />
      </DesktopZone>

      <ConfigHistoryTable
        columns={columns}
        total={total}
        rows={rows}
        rowsPerPage={10}
        loading={historic === undefined || rows === undefined || async.loading}
        nextPage={nextPage}
        previousPage={previousPage}
        currentPage={currentPage}
      />

      <DesktopZone>
        <div style={{ marginBottom: "20px" }} />
      </DesktopZone>
    </div>
  );
};

export default HistoricConfigEncoder;

import React from "react";
import Map from "../../../../../../components/Map/Map";
import CustomMarker from "../../../../../../components/CustomMarker/CustomMarker";
import { IMeter } from "../../../../../../recoils/MeterSystemRecoil";
import LakeLevelDeviceMapIcon from "../Charts_2.0/LakeLevelDeviceMapIcon";

interface Props {
  imeters: IMeter[];
  draggable: boolean;
}

function IMetersMap(props: Props) {
  const { imeters, draggable } = props;
  const position = imeters[0].position.split(",");

  return (
    <Map
      center={{
        lat: parseFloat(position[0]),
        lng: parseFloat(position[1]),
      }}
      draggable={draggable}
      zoom={15}
      disableUI={true}
      disableFullScreen={true}
    >
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LakeLevelDeviceMapIcon
          minLimit={imeters[0].latest_config.min_limit}
          maxLimit={imeters[0].latest_config.max_limit}
          graphicMaxValueInMeters={imeters[0].latest_config.graphic_max_value}
          lakeLevelValueInMeters={
            imeters[0].latest_periodic_stream.content
              .imanage_sensor_measure_value[0].value / 100
          }
        />
      </div>
    </Map>
  );
}

export default React.memo(IMetersMap);

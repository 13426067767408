import React from "react";
import MomentUtils from "@date-io/moment";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { EventNote } from "@material-ui/icons";
import {
  DatePicker,
  DatePickerView,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import FormWeekPicker from "../../../../components/Form/FormWeekPicker/FormWeekPicker";
import { i18n } from "../../../../i18n/i18nText";

interface Props {
  chartPrecision?: String;
  tempDay: moment.Moment;
  setTempDay: (value: moment.Moment) => void;
  setDay: (value: moment.Moment) => void;
}

function PressureChartDateSelect(props: Props) {
  const { chartPrecision, tempDay, setTempDay, setDay } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function exitOpenTo(): DatePickerView {
    if (chartPrecision == "30") {
      return "year";
    }
    return "date";
  }

  function exitViews(): DatePickerView[] {
    if (chartPrecision == "30") {
      return ["year", "month"];
    }
    return ["date"];
  }

  function exitFormat(): string {
    if (chartPrecision == "30") {
      return "MM/YYYY";
    }
    return "L";
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleFilterSave() {
    setDay(tempDay);
    handleClose();
  }

  function dayPickerSelector(): JSX.Element {
    if (chartPrecision == "7") {
      return (
        <FormWeekPicker
          id="Weekpicker"
          state={[tempDay, setTempDay]}
          newLabel="IRPD_PRESSURE_CHART_DAY"
          endSelector
          maxDate={moment().endOf("week")}
        />
      );
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          id="dayChartDatePicker"
          label={i18n("IRPD_PRESSURE_CHART_DAY")}
          openTo={exitOpenTo()}
          views={exitViews()}
          format={exitFormat()}
          maxDate={chartPrecision == "30" ? moment().endOf("month") : moment()}
          value={tempDay}
          onChange={(value) => {
            if (value != null) {
              setTempDay(value);
            }
          }}
        />
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <div>
      <div className="filter-by-date">
        <IconButton aria-label="delete" onClick={handleClick} size="small">
          <EventNote fontSize="small" />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>{dayPickerSelector()}</MenuItem>

          <MenuItem>
            <div className="flex-btn-container">
              <Button
                variant="contained"
                style={{ background: "#0066FF", color: "white" }}
                onClick={() => {
                  handleFilterSave();
                }}
              >
                <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
              </Button>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default PressureChartDateSelect;

import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { isMobile } from "../../../../../../../../../mobileConfig";

export default function PaymentMethodSkeleton() {
  return (
    <Box
      style={{
        width: "100%",
        height: isMobile() ? "215px" : "217px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

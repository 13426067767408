import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { string, object } from "yup";
import icWhiteLogo from "../../../assets/images/logos/logo-white.svg";
import { i18n } from "../../../i18n/i18nText";
import { useFormik } from "formik";
import React, { useState } from "react";
import "./SignUp.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../redux/setting/actions";
import { Language } from "../../../recoils/SettingsRecoil";
import { coreHTTPClient } from "../../../services/webclient";
import { useMount, useRequest } from "ahooks";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import { Alert } from "@material-ui/lab";
import { clearToken } from "../../../redux/auth/actions";
import routes from "../../../routes/routes";

const BRIcon = require("../../../assets/images/brazil-icon-32.png");
const USAIcon = require("../../../assets/images/usa-icon-32.png");
const DEIcon = require("../../../assets/images/german-icon-32.png");
const RUIcon = require("../../../assets/images/russia-icon-32.png");
const ESIcon = require("../../../assets/images/spain-icon-32.png");

/** @deprecated This interface will go to a correct directory */
export interface SignUpForm {
  user: {
    username: string;
    password: string;
    first_name: string;
    last_name: string;
  };
  profile: {
    // email: string;
    name: string;
    birth: any;
    country: string;
    state: string;
    city: string;
    district: string;
    address_1: string;
    address_2: string;
    number: string;
    postal_code: string;
    phone: string;
    prefix_cell_phone: string;
    cell_phone: string;
    cpf: string;
  };
}

interface SignupForm {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

/** @deprecated This validation must be where it is used */
export function validateUsername(username) {
  const regex = RegExp(
    /^(?!.*[_.]$)(?=.{4,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/
  );
  return regex.test(String(username).toLowerCase());
}

const selectLanguage = [
  {
    language: "pt-br",
    image: BRIcon,
    label: "Português(Brasil)",
  },
  {
    language: "en",
    image: USAIcon,
    label: "English(US)",
  },
  {
    language: "es",
    image: ESIcon,
    label: "Español(ES)",
  },
  {
    language: "ru",
    image: RUIcon,
    label: "РУССКИЙ(RU)",
  },
  {
    language: "de-at",
    image: DEIcon,
    label: "Deutsch(AT)",
  },
];

function getToken(token): Promise<any> {
  return coreHTTPClient.get(`v3/auth/register/?token=${token}`);
}

function usernameIsAvaiable(username: string): Promise<any> {
  return coreHTTPClient.post(`v3/auth/check_username/`, {
    username: username,
  });
}

function registerRequest(formValues: SignupForm, token: string): Promise<any> {
  return coreHTTPClient.post(`v3/auth/register/?token=${token}`, formValues);
}

const SignUp = () => {
  /** UseFunctions */
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** Selectors */
  const setting = useSelector((state: any) => state.setting);

  /** States */
  const [errors, setErrors] = useState<string[]>([]);
  const [userAvaiable, setUserAvaiable] = useState(false);

  /** Requests */
  const register = useRequest(registerRequest, { manual: true });
  const usernameAvaiable = useRequest(usernameIsAvaiable, { manual: true });
  const { error, loading, runAsync } = useRequest(getToken, { manual: true });

  /** Validate token and populate initial values */
  useMount(async () => {
    const response: any = await runAsync(token);
    if (response.status === 200) {
      formik.setValues({ ...formik.values, ...response.data });
    }
  });

  /** Schema for form */
  const validationSchema = object({
    email: string().email().required(i18n("VALIDATION_FIELD_REQUIRED")),
    username: string()
      .matches(
        /^(?!.*[_.]$)(?=.{4,30}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/,
        i18n("VALIDATION_FIELD_INVALID")
      )
      .required(i18n("VALIDATION_FIELD_REQUIRED")),
    first_name: string()
      .required(i18n("VALIDATION_FIELD_REQUIRED"))
      .max(50, i18n("VALIDATION_FIELD_MAX", [50])),
    last_name: string()
      .required(i18n("VALIDATION_FIELD_REQUIRED"))
      .max(50, i18n("VALIDATION_FIELD_MAX", [50])),
    password: string()
      .min(8, i18n("VALIDATION_FIELD_MIN", [8]))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@'"!$!-_%*#?&]{8,}$/,
        i18n("VALIDATION_STRONGER_PASSWORD")
      )
      .required(i18n("VALIDATION_FIELD_REQUIRED")),
    confirmPassword: string()
      .min(8, i18n("VALIDATION_FIELD_MIN", [8]))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@'"!$!-_%*#?&]{8,}$/,
        i18n("VALIDATION_STRONGER_PASSWORD")
      )
      .required(i18n("VALIDATION_FIELD_REQUIRED")),
  });

  /** Library to help us in form */
  const formik = useFormik({
    initialValues: {
      language: setting.language,
      password: "",
      confirmPassword: "",
      email: "",
      username: "",
      first_name: "",
      last_name: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const submitErrors: string[] = [];

      /** Validate if passwords match */
      if (values.password !== values.confirmPassword) {
        submitErrors.push("PAGE_REGISTER_PASSWORD_UNMATCH");
      }

      /** Validate if username is already in use */
      if (!userAvaiable) {
        submitErrors.push("PAGE_REGISTER_USERNAME_IN_USE");
      }

      if (submitErrors.length > 0) {
        setErrors(submitErrors);
        return;
      }

      delete values.confirmPassword;

      setErrors([]);
      await register.runAsync(values, token);
      localStorage.clear();
      dispatch(clearToken());
      navigate(routes.AUTH.LOGIN);
      window.location.reload();
    },
  });

  const validateUser = async (e) => {
    const response = await usernameAvaiable.runAsync(formik.values.username);

    if (response.status === 200)
      if (response.data.available) {
        setUserAvaiable(true);
      } else {
        setUserAvaiable(false);
      }
  };

  return (
    <div className="signUp-page">
      <Grid
        container
        direction="column"
        alignContent="center"
        spacing={1}
        style={{ maxWidth: "100%" }}
      >
        <Grid item xs={12} md={4}>
          <Grid container justify="center">
            <Grid>
              <img
                className="signUp-page__logo"
                src={icWhiteLogo}
                alt="logo-init"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="textSecondary" gutterBottom>
                    {i18n("PAGE_REGISTER_TITLE")}
                  </Typography>
                </Grid>
                {error || errors.length > 0 ? (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {error ? (
                        <Typography style={{ fontSize: 13 }}>
                          - {i18n("PAGE_REGISTER_TOKEN_ERROR")}
                        </Typography>
                      ) : null}
                      {errors.length > 0
                        ? errors.map((item: any) => (
                            <Typography style={{ fontSize: 13 }}>
                              - {i18n(item)}
                            </Typography>
                          ))
                        : null}
                    </Alert>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl>
                          <InputLabel id="language-register-label">
                            {i18n("PAGE_REGISTER_LABEL_LANGUAGE")}
                          </InputLabel>
                          <Select
                            fullWidth
                            id="language"
                            name="language"
                            labelId="language-register-label"
                            type="language"
                            value={formik.values.language}
                            onChange={async (e) => {
                              await dispatch(
                                setLanguage(e.target.value as Language)
                              );
                              formik.handleChange(e);
                            }}
                            error={
                              formik.touched.language &&
                              Boolean(formik.errors.language)
                            }
                          >
                            {selectLanguage.map((item) => (
                              <MenuItem value={item.language}>
                                <Grid container alignItems="center">
                                  <Grid>
                                    <img
                                      src={item.image}
                                      alt="braicon"
                                      style={{
                                        marginRight: 8,
                                        marginLeft: 12,
                                        marginTop: 6,
                                        height: 16,
                                      }}
                                    />
                                  </Grid>
                                  <Grid>{item.label}</Grid>
                                </Grid>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          id="email"
                          name="email"
                          label={i18n("PAGE_REGISTER_LABEL_EMAIL")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                                {loading ? (
                                  <CircularProgress size="24px" />
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="first_name"
                          name="first_name"
                          label={i18n("PAGE_REGISTER_LABEL_FIRST_NAME")}
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                          helperText={
                            formik.touched.first_name &&
                            formik.errors.first_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="last_name"
                          name="last_name"
                          label={i18n("PAGE_REGISTER_LABEL_LAST_NAME")}
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                          helperText={
                            formik.touched.last_name && formik.errors.last_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="username"
                              name="username"
                              label={i18n("PAGE_REGISTER_LABEL_USERNAME")}
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              onBlur={validateUser}
                              error={
                                formik.touched.username &&
                                Boolean(formik.errors.username)
                              }
                              helperText={
                                formik.touched.username &&
                                formik.errors.username
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {userAvaiable &&
                                    !(
                                      formik.touched.username &&
                                      Boolean(formik.errors.username)
                                    ) ? (
                                      <CheckIcon color="primary" />
                                    ) : (
                                      <Tooltip
                                        title={i18n(
                                          "PAGE_REGISTER_USERNAME_IN_USE"
                                        )}
                                      >
                                        <BlockIcon
                                          color="error"
                                          style={{ fontSize: 20 }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="password"
                          fullWidth
                          id="password"
                          name="password"
                          label={i18n("PAGE_REGISTER_LABEL_PASSWORD")}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="password"
                          fullWidth
                          id="confirmPassword"
                          name="confirmPassword"
                          label={i18n("PAGE_REGISTER_LABEL_CONFIRM_PASSWORD")}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                          }
                          helperText={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2} justify="flex-end">
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={loading || register.loading || !!error}
                            >
                              {loading || register.loading ? (
                                <CircularProgress
                                  size="24px"
                                  style={{ color: "white", marginRight: 12 }}
                                />
                              ) : null}
                              {i18n("PAGE_REGISTER_SUBMIT_BUTTON_TEXT")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;

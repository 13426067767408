/*
  This function became a responsivity
  changer not a mobile detector *****
*/

/*
  This is the function that trully
  detects if the App is running on
  a mobile system.
*/
export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    // navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/Tablet|iPad/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    // navigator.userAgent.match(/BlackBerry/i) ||
    // navigator.userAgent.match(/Windows Phone/i)
    window.innerWidth <= 1024
  ) {
    return true;
  }
  return false;
};

/*
  This is  the  functions  that  is
  responsible  of  showing features
  android-mobile-only.
*/
export const isAndroid = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/Tablet/i) // ||
    // navigator.userAgent.match(/Tablet|iPad/i) ||    IOS features are not available for now
    // navigator.userAgent.match(/iPhone/i) ||         IOS features are not available for now
    // navigator.userAgent.match(/Windows Phone/i)) || Win Phone features are not available for now
  ) {
    return true;
  }
  return false;
};

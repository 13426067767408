import React from "react";
import "./CreateUser.scss";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { css } from "@emotion/core";

function PermissionsInfo() {
  const Bold = styled.p`
    font-weight: bold;
    color: "#000";
  `;
  return (
    <>
      <div className="root">
        <div className="create-user__social-container">
          <div className="create-user__text" style={{ color: "#0000008a" }}>
            <Bold>{i18n("NO_PERMISSION")}</Bold>{" "}
            <p>{i18n("NO_PERMISSION_DESCRIPTION")}</p>{" "}
            <Bold>{i18n("VISUALIZER")}</Bold>{" "}
            <p>{i18n("VIZUALIZER_DESCRIPTION")}</p>{" "}
            <Bold>{i18n("OPERATOR")}</Bold>{" "}
            <p>{i18n("OPERATOR_DESCRIPTION")}</p>{" "}
            <Bold>{i18n("CONFIGURATOR")}</Bold>{" "}
            <p>{i18n("CONFIGURATOR_DESCRIPTION")}</p>{" "}
            <Bold>{i18n("ADMINISTRATOR")}</Bold>{" "}
            <p>{i18n("ADMINISTRATOR_DESCRIPTION")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PermissionsInfo;

import React from "react";
import styled from "@emotion/styled";
import {
  Checkbox,
  FormControlLabel,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { green } from "@material-ui/core/colors";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";

const Container = styled.div`
  width: 100%;
  height: 60%;
  margin-top: 13px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

interface Props {
  centralLinked: boolean;
  setCentralLinked: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
  label: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default (props: Props) => {
  const classes = useStyles();

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setCentralLinked(event.target.checked);
  };

  return (
    <Container>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.centralLinked}
            onChange={handleChangeChecked}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          <div className={classes.label}>
            {i18n("CENTRAL_LINKED")}
            <InputTooltip
              description={i18n("CENTRAL_LINKED_TOOLTIP")}
              size={14}
            />
          </div>
        }
      />
    </Container>
  );
};

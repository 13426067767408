import { MutableRefObject } from "react";
import whiteMarker from "../../../../../../assets/images/markers/white.svg";
import {
  getIrpdStreamStatus,
  getLatestIrpdStreamV5,
} from "../../../../../../utils/models/irpds";
import { Irpd } from "../../../../../../redux/irpds/types";
import { MapShape } from "../../../../../../utils/types";

// Assets
export const getStatusValue = (irpd: Irpd): number => {
  if (irpd.protocol >= 5) {
    const stream_irpd = getLatestIrpdStreamV5(irpd);
    return stream_irpd?.content?.imanage_master_status?.status;
  } else {
    return irpd?.latest_irpd_stream?.reason;
  }
};

export async function addIrpdOnMap(
  googleMapRef: MutableRefObject<any>,
  irpds: Irpd[],
  farmID: number
): Promise<MapShape[]> {
  if (googleMapRef.current === undefined) return;

  return irpds.map<MapShape>((irpd) => {
    let centerPosition: string[] = irpd.position.split(",");
    let referenceRadius: number = 60;

    let centerPositionGMaps = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );

    const infowindow = new google.maps.InfoWindow({
      content: `<span 
        style="cursor:pointer;display:flex;flex-direction:column;justify-content:center;align-items:center;"
        onclick="(function (){
          let event = new CustomEvent('navigateToIrpd', { detail: {
            pivotId: ${irpd.id},
            farmId: ${farmID}
          }});
          window.dispatchEvent(event);
        })()">
          <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;text-align: left'>
            ${irpd.name}
          </span>
          <div
            style='background-color:${
              getIrpdStreamStatus(getStatusValue(irpd)).color
            };font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#fff;text-align: center;'
          >
            ${getIrpdStreamStatus(getStatusValue(irpd)).text}
          </div>
        </span>
      `,
    });

    //////////////
    // Desenhos //
    //////////////

    let irpdEngineIcon = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: getIrpdStreamStatus(getStatusValue(irpd)).path_image,
      },
    });

    let circle = new google.maps.Circle({
      fillOpacity: 0,
      strokeOpacity: 0.0,
      strokeWeight: 0.5,
      // fillOpacity: 0.0,
      map: googleMapRef.current,
      center: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      radius: referenceRadius,
    });

    //TODO Setor

    let marker = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: whiteMarker,
      },
      visible: false,
    });

    function setPivotVisibility(visibility: boolean) {
      circle.setVisible(visibility);
      irpdEngineIcon.setVisible(visibility);
      marker.setVisible(!visibility);
    }

    /////////////////////
    // Event Listeners //
    /////////////////////

    google.maps.event.addListener(irpdEngineIcon, "mouseover", function () {
      infowindow.setPosition(centerPositionGMaps);
      infowindow.open(googleMapRef.current, irpdEngineIcon);
    });

    google.maps.event.addListener(irpdEngineIcon, "click", function () {
      let event = new CustomEvent("navigateToIrpd", {
        detail: {
          irpdId: irpd.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(irpdEngineIcon, "mouseout", function () {
      infowindow.close();
    });

    google.maps.event.addListener(marker, "mouseover", function () {
      infowindow.setPosition(centerPositionGMaps);
      infowindow.open(googleMapRef.current, marker);
    });

    google.maps.event.addListener(marker, "click", function () {
      let event = new CustomEvent("navigateToIrpd", {
        detail: {
          irpdId: irpd.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(marker, "mouseout", function () {
      infowindow.close();
    });

    google.maps.event.addListener(
      googleMapRef.current,
      "zoom_changed",
      function () {
        let zoom = googleMapRef.current.getZoom();
        if (zoom > 11) {
          setPivotVisibility(true);
        }
        if (zoom <= 11) {
          setPivotVisibility(false);
        }
      }
    );

    return {
      type: "IrpdShape",
      circle: circle,
    };
  });
}

import { useState } from "react";
import useNotify from "./useNotify";

export interface ValidationErrorMessage {
  [value: string]: string | undefined;
}

export interface ValidationErrorActions {
  setError: (id: string, text: string) => void;
  clearError: (id: string) => void;
  showErrorMessages: () => boolean;
}

const useFormValidation = (
  ids: string[]
): [ValidationErrorMessage, ValidationErrorActions] => {
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >(ids.reduce((obj, key) => ({ ...obj, [key]: undefined }), {}));

  const notify = useNotify();

  function setError(id: string, message: string) {
    setValidationErrors((prevValidationErrors) => {
      return { ...prevValidationErrors, [id]: message };
    });
  }

  function clearError(id: string) {
    setValidationErrors((prevValidationErrors) => {
      return { ...prevValidationErrors, [id]: undefined };
    });
  }

  function showErrorMessages(): boolean {
    let hasError = false;
    let errorMessage: string | undefined = undefined;
    Object.keys(validationErrors).forEach((id) => {
      if (validationErrors[id] !== undefined) {
        errorMessage = validationErrors[id];
        hasError = true;
      }
    });
    if (errorMessage !== undefined) notify(errorMessage, "error");
    return hasError;
  }

  return [
    validationErrors,
    {
      setError: setError,
      clearError: clearError,
      showErrorMessages: showErrorMessages,
    },
  ];
};

export default useFormValidation;

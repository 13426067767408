import React from "react";
import "./SegmentsDisplayTable.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { GREEN_COLORS_TABLE_DISPLAY_CROPS } from "../../../../../../utils/utils";
import { makeStyles } from "@material-ui/core";

function createData(
  name: string,
  segment_type: string,
  angles: string,
  crop_plant_date: string,
  crop_harvest_date: string,
  cumulative_blade: string
) {
  return {
    name,
    segment_type,
    angles,
    crop_plant_date,
    crop_harvest_date,
    cumulative_blade,
  };
}

const useStyles = makeStyles({
  root: { width: "100%" },
  container: { maxHeight: 500 },
});

export function SegmentsDisplayTable({ pivot, waterBladePerSegment }) {
  const classes = useStyles();

  const page = 0;

  const rowsPerPage = 5;

  const columns = [
    { id: "name", label: i18n("SEGMENT_NAME") },
    { id: "segment_type", label: i18n("SEGMENT_TYPE") },
    { id: "angles", label: i18n("ANGLE_RANGE") },
    {
      id: "crop_plant_date",
      label: i18n("SEGMENTS_PLANT_DATE"),
    },
    {
      id: "crop_harvest_date",
      label: i18n("SEGMENTS_HARVEST_DATE"),
    },
    {
      id: "cumulative_blade",
      label: i18n("ACCUMULATED_BLADE"),
      format: (value) => value.toFixed(2),
    },
  ];

  const rows = pivot.controllerconfig.content.segments.map((element, index) => {
    return createData(
      pivot.controllerconfig?.segments_crop?.[index]?.name
        ? pivot.controllerconfig?.segments_crop?.[index]?.name
        : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${index + 1}`,
      pivot.controllerconfig?.segments_crop?.[index]?.segment_type
        ? pivot.controllerconfig?.segments_crop?.[index]?.segment_type
        : "-",
      parseInt(element.angle_start)
        ? `${parseInt(element.angle_start)}° - ${parseInt(element.angle_end)}°`
        : `0° - ${parseInt(element.angle_end)}°`,
      pivot.controllerconfig?.segments_crop?.[index]?.crop_plant_date
        ? moment(
            pivot.controllerconfig?.segments_crop?.[index]?.crop_plant_date
          ).format("DD/MM/YYYY HH:mm")
        : "-",
      pivot.controllerconfig?.segments_crop?.[index]?.crop_harvest_date
        ? moment(
            pivot.controllerconfig?.segments_crop?.[index]?.crop_harvest_date
          ).format("DD/MM/YYYY HH:mm")
        : "-",
      pivot.controllerconfig?.segments_crop?.[index]?.crop_plant_date &&
        waterBladePerSegment !== "-"
        ? `${waterBladePerSegment[index]?.water_blade.toFixed(2)} mm`
        : "-"
    );
  });

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{
                    minWidth: column.id === "name" ? "220px" : "140px",
                    color: "#808080",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.name}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell key={column.id} align={"center"}>
                          <div
                            className="custom-name-heading"
                            style={{
                              flexDirection:
                                column.id === "name" ? "row" : "column",
                            }}
                          >
                            {column.id === "name" && (
                              <div
                                className="table-pivot-segments__index custom-index"
                                style={{
                                  backgroundColor: GREEN_COLORS_TABLE_DISPLAY_CROPS[
                                    index
                                  ].replace("DD", ""),
                                }}
                              >
                                {index + 1}
                              </div>
                            )}

                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

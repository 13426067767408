import React, { useContext, useEffect, useMemo, useRef } from "react";
import GeneralTable, {
  Column,
} from "../../../../../../components/GeneralTable/GeneralTable";
import { useParams } from "react-router";
import { IrpdContext } from "../../SelectedIrpd";
import { getResume } from "./components/util/utils";
import moment from "moment";
import { ReactJSX } from "../../../../../../utils/types";
import useIrpdHistoric, {
  HistoricModels,
} from "../../../../../../hooks/models/useIrpdHistoric";
import ExpandedContent from "./components/util/ExpandedContent/ExpandedContent";
import { isMobile } from "../../../../../../mobileConfig";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { useRecoilState } from "recoil";
import {
  IrpdPressureStreamFamily,
  IrpdStreamFamily,
} from "../../../../../../recoils/IrpdRecoil";
import { IrpdPressureStream } from "../../../../../../redux/irpds/types";
import { i18n } from "../../../../../../i18n/i18nText";

const { SOCKET_SUFFIX } = process.env;

interface Props {
  advanced: boolean;
  central: boolean;
}

function HistoricBox(props: Props) {
  const columns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "type",
      label: <>{i18n("HISTORIC_PANEL_LABEL_TYPE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const mobcolumns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const { irpd } = useContext(IrpdContext);

  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);

  const historicTablesV5 = [
    "irpd_stream",
    "irpd_action",
    "irpd_stream_v5",
    "irpd_action_v5",
  ];

  const [async, historic, historicActions] = useIrpdHistoric(
    farmID,
    irpdID,
    10,
    1,
    historicTablesV5 as HistoricModels[]
  );

  const [isConnected, socket] = useSocketIO();

  const isAdvanced = useRef<boolean>(props.advanced);

  const showCentralInformation = useRef<boolean>(props.central);

  const [irpdPressureStream, setIrpdPressureStream] = useRecoilState(
    IrpdPressureStreamFamily(irpdID)
  );

  const [irpdStream, setIrpdStream] = useRecoilState(IrpdStreamFamily(irpdID));

  const rows = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic.results.map((item) => {
      let date: string;
      const { type, overview } = getResume(item.model, item.data, irpd);
      if (
        item.model === "irpd_stream" ||
        item.model === "irpd_action" ||
        item.model === "irpd_action_v5" ||
        item.model === "irpd_stream_v5" ||
        item.model === "CentralStream"
      ) {
        date = moment(item.data.created).format("DD MMM - HH:mm");
      }
      return {
        id: (item.data as any).id,
        date: date,
        type: type,
        overview: overview,
        datailedComponent: (): ReactJSX => <ExpandedContent {...item} />,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic]);

  // async function getFilteredHistoric(
  //   start_date: moment.Moment,
  //   end_date: moment.Moment,
  //   pages: number = 1
  // ) {
  //
  //   await new Promise(async (resolve) => {
  //     await coreHTTPClient
  //       .get(
  //         `v3/farms/${farmID}/irpds/${irpdID}/history/?date_start=${start_date.toISOString()}&pages=${pages}&date_end=${end_date.toISOString()}`
  //       )
  //       .then((res) => {
  //
  //         historicActions.setHistoricModels(res.data);
  //       })
  //       .catch((err) => {
  //
  //       });
  //   });
  // }

  // useEffect(() => {
  //   if (
  //     filterByDate.data.type === FilterTypesChoices.HISTORIC &&
  //     filterByDate.data.start_date &&
  //     filterByDate.data.end_date
  //   ) {
  //     getFilteredHistoric(
  //       filterByDate.data.start_date,
  //       filterByDate.data.end_date
  //     );
  //
  //   }
  // }, [filterByDate]);

  useEffect(() => {
    showCentralInformation.current = props.central;
    historicActions.setCentralHistoric(props.central);
  }, [props.central]);

  useEffect(() => {
    isAdvanced.current = props.advanced;
    historicActions.setAdvancedHistoric(props.advanced);
  }, [props.advanced]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}farm@${farmID}`);
      socket.bind("CentralStream", (data) => {
        showCentralInformation.current &&
          historicActions.updateHistoric({
            model: "CentralStream",
            data,
          });
      });

      socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);

      socket.bind("irpd_pressure_stream", (data: IrpdPressureStream) => {
        //

        let dataCreatedDate = new Date(data.created).getTime();
        let latestCreatedDate = irpdPressureStream
          ? new Date(irpdPressureStream.created).getTime()
          : 0;

        if (dataCreatedDate - latestCreatedDate > 0) {
          setIrpdPressureStream(data);
        }
      });

      socket.bind("irpd_stream", (data) => {
        historicActions.updateHistoric({ model: "irpd_stream", data: data });

        let dataCreatedDate = new Date(data.created).getTime();
        let latestCreatedDate = irpdStream
          ? new Date(irpdStream.created).getTime()
          : 0;

        if (dataCreatedDate - latestCreatedDate > 0) {
          setIrpdStream(data);
        }
      });

      socket.bind("irpd_action", (data) => {
        historicActions.updateHistoric({ model: "irpd_action", data: data });
      });

      socket.bind("IrpdStreamV5_event", (data) => {
        historicActions.updateHistoric({ model: "irpd_stream_v5", data });
      });

      socket.bind("IrpdActionV5_simple", (data) => {
        historicActions.updateHistoric({ model: "irpd_action_v5", data });
      });

      socket.bind("IrpdActionV5_schedule", (data) => {
        historicActions.updateHistoric({ model: "irpd_action_v5", data });
      });

      socket.bind("IrpdStreamV5_periodic", (data) => {
        historicActions.updateHistoric({ model: "irpd_stream_v5", data });
      });

      return () => {
        socket.unbind("CentralStream");
        socket.unbind("irpd_stream");
        socket.unbind("irpd_pressure_stream");
        socket.unbind("irpd_action");
        socket.unbind("IrpdStreamV5_event");
        socket.unbind("IrpdActionV5_simple");
        socket.unbind("IrpdActionV5_schedule");
        socket.unbind("IrpdStreamV5_periodic");
        socket.unsubscribe(`${SOCKET_SUFFIX}farm@${farmID}`);
        socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpdID}`);
      };
    }
  }, []);

  if (historic !== undefined && rows !== undefined) {
    return (
      <GeneralTable
        page={historicActions.getOffset() - 1}
        columns={isMobile() ? mobcolumns : columns}
        total={historic.count}
        rows={rows}
        rowsPerPage={10}
        hideColumns={true}
        loading={async.loading}
        onPageChange={(offset) => {
          historicActions.updateOffset(offset + 1);
        }}
      />
    );
  }

  return null;
}

export default React.memo(HistoricBox);

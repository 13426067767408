import axios from "axios";

import Store, { store } from "../redux/index";
import checkExists from "../utils/checks/checkExists";
import routes from "../routes/routes";
import { clearToken } from "../redux/auth/actions";
import { clearUserID } from "../redux/userID/actions";

export const baseURL = {
  ping: "https://google.com",
  core: process.env.REACTWEB_COREAPI_URL,
};

export const coreHTTPClient = axios.create({
  baseURL: baseURL.core,
  responseType: "json",
  timeout: 90000,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

coreHTTPClient.interceptors.request.use(
  (config) => {
    if (checkExists(getAuthorization())) {
      config.headers.Authorization = `Bearer ${getAuthorization()}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

coreHTTPClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      throw error;
    }

    if (error?.response?.status === 401) {
      store.dispatch(clearToken());
      store.dispatch(clearUserID());
      window.location.href = routes.AUTH.LOGIN_UNAUTHORIZED;
    }

    if (error?.response?.status === 404) {
      window.location.href = routes.DASHBOARD.CODE404;
    }

    return Promise.reject(error);
  }
);

export const coreHTTPClientOld = axios.create({
  baseURL: baseURL.core,
  responseType: "json",
  timeout: 90000,
});

coreHTTPClientOld.interceptors.request.use(
  (config) => {
    if (checkExists(getAuthorization())) {
      config.headers.Authorization = `Token ${getAuthorization()}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export function getAuthorization() {
  return Store().store.getState().auth.token;
}

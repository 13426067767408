import React, { useMemo } from "react";
import { FormControl, InputLabel, NativeSelect } from "@material-ui/core";
import "./FormSelectIrrigation.scss";
import { i18n, i18nTextId } from "../../../i18n/i18nText";

interface Props {
  id: string;
  label: string;
  val: string;
  onChangeFunction: (e) => void;
  values: [string, string][]; //[value, label][]
  disabled?: boolean;
}

function FormSelectIrrigation(props: Props) {
  const { id, label, val, values } = props;

  const formattedValues = useMemo(() => {
    return values.map((value) => [value[0], i18n(value[1] as i18nTextId)]);
  }, [values]);

  return (
    <FormControl className="form-select__form-control">
      <InputLabel id={`${id}-label`} style={{ color: "rgba(0, 0, 0, 0.38)" }}>
        <>{i18n(label as i18nTextId)}</>
      </InputLabel>

      <NativeSelect
        disabled={props.disabled}
        id={`${id}`}
        value={val}
        onChange={props.onChangeFunction}
      >
        {formattedValues.map((value) => {
          return <option value={value[0]}>{value[1]}</option>;
        })}
      </NativeSelect>
    </FormControl>
  );
}

export default FormSelectIrrigation;

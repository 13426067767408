import React from "react";
import Map from "../../../../../../components/Map/Map";
import { Irpd } from "../../../../../../redux/irpds/types";
import CustomMarker from "../../../../../../components/CustomMarker/CustomMarker";
import {
  getIrpdStreamStatus,
  getLatestIrpdStreamV5,
} from "../../../../../../utils/models/irpds";
import { getStatusValue } from "../../../../Farm/SelectedFarm/components/FarmMap/addIrpdOnMaps";

interface Props {
  irpd: Irpd;
  draggable: boolean;
  latest?: any;
}

function IrpdMap(props: Props) {
  // const [draggable, setDraggable] = useState(false);
  const { irpd, draggable, latest } = props;
  const position = irpd.position.split(",");

  return (
    <Map
      center={{
        lat: parseFloat(position[0]),
        lng: parseFloat(position[1]),
      }}
      draggable={draggable}
      zoom={15}
      disableUI
      disableFullScreen
    >
      <CustomMarker
        lat={parseFloat(position[0])}
        lng={parseFloat(position[1])}
        key="center"
      >
        <img
          alt=""
          src={
            getIrpdStreamStatus(
              latest
                ? latest?.content?.imanage_master_status?.status
                : getStatusValue(irpd)
            ).path_image
          }
        />
      </CustomMarker>
    </Map>
  );
}

export default IrpdMap;

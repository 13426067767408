import { atom, selector } from "recoil";

export interface Profile {
  id?: number;
  email: string;
  name: string;
  first_name?: string;
  last_name?: string;
  birth: string;
  country?: string;
  state: string;
  city: string;
  district: string;
  address_1: string;
  address_2?: string;
  number: string;
  postal_code: string;
  phone: string;
  prefix_cell_phone: string;
  cell_phone: string;
  cpf: string;
  language?: any;
  role?: any;
  user?: number;
}
export interface ProfileState {
  data: Profile;
}

export const Profile = atom<ProfileState | null>({
  key: "Profile",
  default: {
    data: null,
  },
});

export const ProfileState = selector({
  key: "ProfileState",
  get: ({ get }) => get(Profile).data,
  set: ({ set, get }, data: Profile) => {
    set(Profile, { ...get(Profile), data: data });
  },
});

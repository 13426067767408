import { coreHTTPClient } from "../../../../services/webclient";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../routes/routes";
import { changePasswordStatus } from "../../../../recoils/ChangePasswordRecoil";
import { useSetRecoilState } from "recoil";

interface NewPasswordActions {
  sendEmail: (email: string) => void;
  newPassword: (
    new_password1: string,
    new_password2: string,
    token: string
  ) => void;
}

function useNewPassword(): [NewPasswordActions, boolean, boolean, boolean] {
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const setChangePassword = useSetRecoilState(changePasswordStatus);

  // Promises are unsolved
  async function sendEmail(email: string) {
    setModal(false);

    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        await coreHTTPClient.post(`v3/auth/reset/`, {
          email: email,
        });
      } catch (err) {
        setModal(true);

        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        }
      } finally {
        setLoading(false);
        setSuccess(true);
      }
    });
  }

  async function newPassword(
    new_password1: string,
    new_password2: string,
    token: string
  ) {
    await new Promise(async (resolve) => {
      setModal(false);
      setLoading(true);
      try {
        await coreHTTPClient.post(`v3/auth/reset/${token}/`, {
          new_password1: new_password1,
          new_password2: new_password2,
        });

        setChangePassword(false);
      } catch (err) {
        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        } else if (err.response?.status === 400) {
          setSuccess(false);
        }
      } finally {
        setLoading(false);
        setModal(true);
      }
    });
  }

  return [{ sendEmail, newPassword }, modal, loading, success];
}

export default useNewPassword;

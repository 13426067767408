import React from "react";
import {
  useSnackbar,
  VariantType,
  OptionsObject,
  SnackbarMessage,
} from "notistack";
import { nanoid } from "nanoid";
import { IconButton, SnackbarContent } from "@material-ui/core";
import { Close as IconClose } from "@material-ui/icons";
import { isMobile } from "../../mobileConfig";

type NotifyFunction = (content: React.ReactNode, variant: VariantType) => void;

function usePersistentNotify(): NotifyFunction {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const COLOR = {
    default: "#313131",
    error: "#d32f2f",
    success: "#43a047",
    warning: "#f9a000",
    info: "#2979ff",
  };

  const notify: NotifyFunction = (
    content: SnackbarMessage,
    variant: VariantType
  ) => {
    const uuid = nanoid(5);

    const parameters: OptionsObject = {
      key: uuid,
      variant: variant,
      preventDuplicate: true,
      autoHideDuration: undefined,
      content: (key) => (
        <SnackbarContent
          style={{ backgroundColor: COLOR[variant] }}
          message={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minWidth: isMobile() ? "90vmin" : "288px",
                backgroundColor: COLOR[variant],
              }}
            >
              <div style={{ flexGrow: 1, flexShrink: 1 }}>{content}</div>
              <IconButton
                style={{
                  padding: 0,
                  margin: "10px",
                  color: "white",
                  flexShrink: 0,
                }}
                onClick={() => closeSnackbar(key)}
              >
                <IconClose />
              </IconButton>
            </div>
          }
        />
      ),
    };

    enqueueSnackbar(<></>, parameters);
  };

  return notify;
}

export default usePersistentNotify;

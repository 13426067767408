import React, { useState, useEffect } from "react";
import "./ForgetPassword.scss";
import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router";
import routes from "../../../routes/routes";
import { createMuiTheme, Button, LinearProgress } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import useStyles from "../Login/hooks/useStyles";
import EmailMessage from "./EmailMessage/EmailMessage";
import useNewPassword from "./hooks/useNewPassword";
import WarningEmail from "./components/WarningEmail";
import { i18n } from "../../../i18n/i18nText";

// redux

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#018786",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "15px",
        color: "#666666",
      },
    },
  },
});

const ForgetPassword = () => {
  const classes = useStyles({});
  const [user, setUser] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [passwordRecovery, setPasswordRecovery] = useState(false);
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const navigate = useNavigate();
  const [
    { sendEmail, newPassword },
    modal,
    loading,
    success,
  ] = useNewPassword();
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    modal && setOpenModal(true);
  }, [modal]);

  useEffect(() => {
    success && setEmailMessage(true);
  }, [success]);

  return (
    <div className="forget-password">
      <div className="forget-password__card">
        {!emailMessage ? (
          <>
            <div className="forget-password__cardTitle ">
              <div className="forget-password__cardTitle__title ">
                {i18n("PASSWORD_RECOVERY_INSERT_EMAIL")}
              </div>
            </div>

            <div className="forget-password__card-information">
              {loading ? <LinearProgress /> : null}
            </div>

            <div className="forget-password__passwordRecovey">
              <TextField
                className={classes.textField}
                id="standard-basic"
                type="email"
                label={i18n("CREATE_USER_MODAL_EMAIL")}
                onChange={(e) => {
                  setEmail(e.target.value);
                  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (re.test(String(e.target.value).toLowerCase())) {
                    setDisableButton(false);
                  } else {
                    setDisableButton(true);
                  }
                }}
                value={email}
              />
              <div className="forget-password__reCaptcha">
                <ReCAPTCHA
                  style={{ transform: "scale(0.8)" }}
                  sitekey="6LeH4_cUAAAAAJn1YZUm-91DpXPz35kLOEH5RSUr"
                  size="normal"
                  onChange={() => {
                    setRecaptcha(true);
                  }}
                />
              </div>
            </div>

            <div className="forget-password__buttons">
              <Button
                className={classes.buttonBack}
                color="primary"
                variant="outlined"
                onClick={() => {
                  //setForgetPassword(false);
                  navigate(routes.AUTH.LOGIN);
                }}
              >
                <>{i18n("SIGN_UP_BACK")}</>
              </Button>
              <Button
                className={classes.buttonBack}
                color="primary"
                variant="contained"
                onClick={() => {
                  sendEmail(email);
                }}
                disabled={!recaptcha || disableButton || loading}
              >
                {i18n("EDIT_IRPD_SEND_SUCCESS")}
              </Button>
            </div>

            {openModal && (
              <WarningEmail
                openModal={openModal}
                setOpenModal={setOpenModal}
                text={"FORGET_PASSWORD_MODAL_EMAIL_FAILED"}
              />
            )}
          </>
        ) : (
          <EmailMessage
            setPasswordRecovery={setPasswordRecovery}
            email={email}
          />
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;

import { MutableRefObject } from "react";
import blueMarker from "../../../../../../assets/images/markers/blue.svg";
import brownMarker from "../../../../../../assets/images/markers/brown.svg";
import grayMarker from "../../../../../../assets/images/markers/gray.svg";
import slaveBlue1Marker from "../../../../../../assets/images/markers/slaveBlue1.svg";
import greenMarker from "../../../../../../assets/images/markers/green.svg";
import lightblueMarker from "../../../../../../assets/images/markers/lightblue.svg";
import orangeMarker from "../../../../../../assets/images/markers/orange.svg";
import redMarker from "../../../../../../assets/images/markers/red.svg";
import yellowMarker from "../../../../../../assets/images/markers/yellow.svg";
import { i18n } from "../../../../../../i18n/i18nText";
import { PivotStreamLight } from "../../../../../../recoils/PivotRecoil";
import { Pivot } from "../../../../../../redux/pivots/types";
import { V5_IRRIGATING_STATUSES } from "../../../../../../utils/consts";
import {
  isOldProtocol,
  isV5Protocol,
  PIVOT_STATUS_COLOR_OLD_BLUE,
  PIVOT_STATUS_COLOR_OLD_BROWN,
  PIVOT_STATUS_COLOR_OLD_GREEN,
  PIVOT_STATUS_COLOR_OLD_LIGHTBLUE,
  PIVOT_STATUS_COLOR_OLD_ORANGE,
  PIVOT_STATUS_COLOR_OLD_RED,
  PIVOT_STATUS_COLOR_OLD_YELLOW,
  PIVOT_STATUS_COLOR_V5_BLUE,
  PIVOT_STATUS_COLOR_V5_BROWN,
  PIVOT_STATUS_COLOR_V5_GREEN,
  PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1,
  PIVOT_STATUS_COLOR_V5_RED,
  PIVOT_STATUS_COLOR_V5_YELLOW,
  mmToPTP,
  isValidIrrigationRemainingTime,
  convertIrrigationRemainingTimeToHoursMinutes,
} from "../../../../../../utils/models/pivots";
import { MapShape } from "../../../../../../utils/types";

import { IRRIFAST_COLORS } from "../../../../Pivot/SelectedPivot/components/IrrifastBox";

const AMOSTRATION_SIZE: number = 45;

//Conversion from spherical do (x,y) coordenades
const convertSphericalToCartesian = (latitude: number, longitude: number) => {
  const latRad = (latitude * Math.PI) / 180;
  const lonRad = (longitude * Math.PI) / 180;
  const earthRadius = 6367;
  const pX = earthRadius * Math.cos(latRad) * Math.cos(lonRad);
  const pY = earthRadius * Math.cos(latRad) * Math.sin(lonRad);
  return { x: pX, y: pY };
};

// Explanations and source code for test of intersections https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
function intersects(path: object) {
  const a = convertSphericalToCartesian(path[0].lat(), path[0].lng()).x;
  const b = convertSphericalToCartesian(path[0].lat(), path[0].lng()).y;
  const c = convertSphericalToCartesian(path[1].lat(), path[1].lng()).x;
  const d = convertSphericalToCartesian(path[1].lat(), path[1].lng()).y;
  const p = convertSphericalToCartesian(path[2].lat(), path[2].lng()).x;
  const q = convertSphericalToCartesian(path[2].lat(), path[2].lng()).y;
  const r = convertSphericalToCartesian(path[3].lat(), path[3].lng()).x;
  const s = convertSphericalToCartesian(path[3].lat(), path[3].lng()).y;
  let det: number, gamma: number, lambda: number;
  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

//  Test if two lines from rectangle's path intersect vertically and if horizontally
//  switching points in order to translate polygon to be drawn
const getIsPathValid = (path: any) => {
  if (intersects(path) || intersects([path[3], path[0], path[1], path[2]])) {
    if (intersects(path)) return [path[0], path[2], path[1], path[3]];
    else return [path[0], path[1], path[3], path[2]];
  } else {
    return path;
  }
};

export function addPivotsOnMap(
  googleMapRef: MutableRefObject<any>,
  pivots: Pivot[],
  mapShapes: MapShape[],
  latestPanelStream,
  latestGPSStream,
  latestStatus: PivotStreamLight[],
  farmID: number
): MapShape[] {
  mapShapes.forEach((shape) => {
    if (!shape) return;
    if (shape.type === "PivotShape") {
      shape.circle.setMap(null);
      shape.line.setMap(null);
      shape.triangle.setMap(null);
      shape.arrow.setMap(null);
      shape.sector && shape.sector.setMap(null);
      shape.dashedLine && shape.dashedLine.setMap(null);

      shape.semiCircle.forEach((s) => s.setMap(null));
    }
    if (shape.type === "LinearPivotShape") {
      shape.rectangles.forEach((rectangle) => rectangle.setMap(null));
      shape.line.setMap(null);
      shape.circle.setMap(null);
      shape.arrow.setMap(null);
    }
  });
  if (googleMapRef.current === undefined) return;
  return pivots.map<MapShape>((pivot, pivotIndex) => {
    /**
     * Safeguard do recoil. Se o pivot não tem os dois componentes que o fazem completo, não renderiza ele _ainda_
     */
    // Função para gerar o array que desenha o circulo com determinado angulo
    function circlePath(center, reference, radius, points, angle) {
      const a = [];
      const p = angle / points; // Variação do angulo baseado no numero de pontos
      let d = google.maps.geometry.spherical.computeHeading(
        // Angulo que começa o desenho
        center,
        reference
      );

      for (let i = 0; i < points; ++i, d += p) {
        a.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
      }
      a.unshift(center);

      return a;
    }

    if (pivot.automation_type === 0 || pivot.automation_type === 1) {
      if (pivot.protocol === 5) {
        if (
          !pivot.controllerstream_panel ||
          !pivot.controllerstream_gps ||
          !pivot.controllerconfig
        ) {
          return;
        }
      } else if (
        !pivot.latest_panel_stream ||
        !pivot.latest_gps_stream ||
        !pivot.config
      )
        return;

      // ////////////
      // Cálculos //
      // ////////////

      // V4
      // Clockwise = 0 --> Parado
      // Clockwise = 1 --> Reverso
      // Clockwise = 2 --> Avanço

      // V5
      // Clockwise = 0 --> Parado
      // Clockwise = 2 --> Reverso
      // Clockwise = 1 --> Avanço

      let irrigationDirection;
      if (pivot.protocol === 5) {
        irrigationDirection =
          pivot.controllerstream_panel?.content?.current_irrigation_information
            ?.direction;
      } else {
        irrigationDirection = pivot.latest_gps_stream
          ? pivot.latest_gps_stream.clockwise
          : latestPanelStream.clockwise;
      }

      let centerPosition: string[] = [];
      let referencePosition: string[] = [];
      let gpsPosition: string[] = [];
      let endReferencePosition: string[] = [];

      // Declarações
      if (isOldProtocol(pivot)) {
        centerPosition = pivot.config.center.split(",");
        referencePosition = pivot.config.reference.split(",");
        endReferencePosition = pivot.config.end_reference.split(",");

        if (latestGPSStream) gpsPosition = latestGPSStream.position?.split(",");
        else gpsPosition = pivot.latest_gps_stream.position?.split(",");
      } else if (isV5Protocol(pivot)) {
        centerPosition = [
          pivot.controllerconfig.content?.pivot_positions.latitude_center.toString(),
          pivot.controllerconfig.content?.pivot_positions.longitude_center.toString(),
        ];
        referencePosition = [
          pivot.controllerconfig.content?.pivot_positions.latitude_reference.toString(),
          pivot.controllerconfig.content?.pivot_positions.longitude_reference.toString(),
        ];

        if (latestGPSStream) {
          gpsPosition = [
            latestGPSStream.content?.latitude_longitude_gps?.latitude_gps.toString(),
            latestGPSStream.content?.latitude_longitude_gps?.longitude_gps.toString(),
          ];
        } else {
          gpsPosition = [
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
          ];
        }
      }

      const centerPositionGMaps = new google.maps.LatLng(
        parseFloat(centerPosition[0]),
        parseFloat(centerPosition[1])
      );

      if (!gpsPosition) return;

      const gpsPositionGMaps = new google.maps.LatLng(
        parseFloat(gpsPosition[0]),
        parseFloat(gpsPosition[1])
      );

      const referencePositionGMaps = new google.maps.LatLng(
        parseFloat(referencePosition[0]),
        parseFloat(referencePosition[1])
      );

      const referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
        centerPositionGMaps,
        referencePositionGMaps
      );

      let towersInnerHtml = "";
      if (pivot.is_irrifast && pivot.irrifast_tower_status.length > 0) {
        towersInnerHtml = pivot.irrifast_tower_status
          ?.map(
            (tower) => `
          <span style="background:${
            IRRIFAST_COLORS[
              tower.aligment_status !== null ? tower.aligment_status : 2
            ]
          };width:32px;height:32px;border-radius:4px;color:#ffffff;text-align:center;font-weight:bold;padding-top:6px;">
              ${tower.tower_number.toString().padStart(2, "0")}
            </span>
        `
          )
          .join("");
      }

      const infowindow = new google.maps.InfoWindow({
        content: `<span 
          style="cursor: pointer;display:flex;flex-direction:column;justify-content:center;align-items:center;"
          onclick="(function (){
            let event = new CustomEvent('navigateToPivot', { detail: {
              pivotId: ${pivot.id},
              farmId: ${farmID}
            }});

            window.dispatchEvent(event);
          })()">
            <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 0px;padding:4px 8px;border-radius:8px;color:#000;display:flex;flex-direction:row;flex-wrap:wrap;'>
              ${pivot.name}
            </span>
            <div
              style='background-color:${
                latestStatus?.[pivotIndex]?.streamStatus?.color
              };font-weight:600;font-size:.7rem;margin:4px 0 4px 0px;padding:4px 8px;border-radius:8px;color:#fff;display:flex;flex-direction:row;flex-wrap:wrap;'
            >
              ${latestStatus?.[pivotIndex]?.streamStatus?.text}             
              ${
                latestStatus?.[pivotIndex]?.streamStatus?.number === 4 &&
                isValidIrrigationRemainingTime(
                  latestStatus?.[pivotIndex]?.irrigation_remaining_time
                )
                  ? convertIrrigationRemainingTimeToHoursMinutes(
                      latestStatus?.[pivotIndex]?.irrigation_remaining_time
                    )
                  : ""
              }
            </div>
            ${
              pivot.is_irrifast && pivot.irrifast_tower_status.length > 0
                ? `
                  <div style="display:flex;flex-wrap:wrap;gap:4px;max-width:180px;margin-top:16px;margin-bottom:16px;">
                    ${towersInnerHtml}
                  </div>
                `
                : ""
            }
          </span>`,
      });

      const lineRadius = google.maps.geometry.spherical.computeDistanceBetween(
        centerPositionGMaps,
        gpsPositionGMaps
      );
      const lineAngle = google.maps.geometry.spherical.computeHeading(
        centerPositionGMaps,
        gpsPositionGMaps
      );

      let lineAngleFromReference = google.maps.geometry.spherical.computeHeading(
        centerPositionGMaps,
        referencePositionGMaps
      );

      let linePoints: google.maps.LatLng[] = [];

      if (lineRadius < referenceRadius) {
        linePoints = [
          centerPositionGMaps,
          gpsPositionGMaps,
          google.maps.geometry.spherical.computeOffset(
            gpsPositionGMaps,
            Math.abs(referenceRadius - lineRadius),
            lineAngle
          ),
        ];
      } else {
        linePoints = [
          centerPositionGMaps,
          google.maps.geometry.spherical.computeOffset(
            centerPositionGMaps,
            referenceRadius,
            lineAngle
          ),
        ];
      }

      const marginalPositionGMaps = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius,
        lineAngle
      );

      const interiorPositionRadiusGMaps = google.maps.geometry.spherical.interpolate(
        centerPositionGMaps,
        marginalPositionGMaps,
        0.7171
      );

      const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 2,
      };

      // Line endReference
      let dashedLine: google.maps.Polyline;

      const marginalPositionFromReference = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius,
        lineAngleFromReference
      );

      const upMarginalPositionFromReference = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius * 0.85,
        lineAngleFromReference
      );

      if (
        // Condições para irrigação V4
        (pivot.irrigation_end_angle != null &&
          pivot.latest_panel_stream.reason < 100) ||
        // Condições para irrigação V5
        // Se o status do pivô for irrigando
        (pivot.controllerstream_panel?.content?.irrigation_status
          ?.irrigation_status === 4 &&
          // Se o modo de parada da irrigação atual for por ângulo, fim de setor ou voltas
          [1, 3, 4].includes(
            pivot.controllerstream_panel?.content
              ?.current_irrigation_information.stop_mode
          ))
      ) {
        const endIrrigationDashedLine = google.maps.geometry.spherical.computeOffset(
          centerPositionGMaps,
          referenceRadius,
          (pivot.protocol === 5
            ? pivot.controllerstream_panel?.content
                .current_irrigation_information.stop_angle
            : pivot.irrigation_end_angle) + pivot.reference_angle
        );
        dashedLine = new google.maps.Polyline({
          path: [centerPositionGMaps, endIrrigationDashedLine],
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "10px",
            },
          ],
          map: googleMapRef.current,
        });
      }

      // Arrow
      const dotArrowPositionGMaps = google.maps.geometry.spherical.computeOffsetOrigin(
        marginalPositionGMaps,
        referenceRadius / 5,
        lineAngle +
          (pivot.protocol === 5
            ? irrigationDirection == 1
              ? -45
              : 45
            : irrigationDirection == 1
            ? 45
            : -45)
      );

      const dotUpArrow = google.maps.geometry.spherical.interpolate(
        marginalPositionGMaps,
        dotArrowPositionGMaps,
        0.3
      );

      const dotDownArrow = google.maps.geometry.spherical.interpolate(
        interiorPositionRadiusGMaps,
        dotArrowPositionGMaps,
        0.3
      );

      const panelDate = new Date(pivot.controllerstream_panel?.arrived);
      const gpsDate = new Date(pivot.controllerstream_gps?.arrived);

      const status =
        panelDate > gpsDate
          ? pivot.controllerstream_panel?.content?.irrigation_status
              .irrigation_status
          : pivot.controllerstream_gps?.content?.irrigation_status
              .irrigation_status;

      const lineUpArrow: google.maps.LatLng[] =
        irrigationDirection !== 0 &&
        ((pivot.protocol === 5 && V5_IRRIGATING_STATUSES.includes(status)) ||
          (pivot.latest_panel_stream?.reason < 100 &&
            pivot.latest_panel_stream?.reason > 0 &&
            !(
              pivot.latest_panel_stream?.reason == 32 ||
              pivot.latest_panel_stream?.reason == 21
            )))
          ? [dotUpArrow, dotArrowPositionGMaps, dotDownArrow]
          : [];

      // Triangle
      const referenceTriangleBottom = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius,
        pivot.reference_angle + 7
      );

      const referenceTriangleTop = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius,
        pivot.reference_angle - 7
      );

      const referenceTriangleCenter = google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius / 1.3,
        pivot.reference_angle
      );

      const triangleCoords: google.maps.LatLng[] = [
        referenceTriangleBottom,
        referenceTriangleTop,
        referenceTriangleCenter,
      ];

      // ARCO
      const delta = 1 / 32;
      const deltaAngle = 1;
      const pivotHistory = pivot.map_history;
      let iniciaDown = marginalPositionFromReference;
      let iniciaUp = upMarginalPositionFromReference;

      const angleInicialArc = pivotHistory.length * deltaAngle;
      const distaceArc =
        2 * Math.PI * referenceRadius * (angleInicialArc / 360);
      const distaceArcUp =
        2 * Math.PI * referenceRadius * 0.85 * (angleInicialArc / 360);
      const minDistance = distaceArc / Math.trunc(angleInicialArc / delta);
      const minDistanceUp = distaceArcUp / Math.trunc(angleInicialArc / delta);

      let pointArcDown = google.maps.geometry.spherical.computeOffsetOrigin(
        iniciaDown,
        minDistance,
        lineAngleFromReference
      );

      let pointArcUp = google.maps.geometry.spherical.computeOffsetOrigin(
        iniciaUp,
        minDistanceUp,
        lineAngleFromReference
      );

      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        iniciaDown,
        pointArcDown
      );

      const distanceUp = google.maps.geometry.spherical.computeDistanceBetween(
        iniciaUp,
        pointArcUp
      );

      let minAngle = 2 * Math.asin(distance / 2 / referenceRadius);
      minAngle = (minAngle * 180) / Math.PI;

      let minAngleUp = 2 * Math.asin(distanceUp / 2 / (referenceRadius * 0.85));
      minAngleUp = (minAngleUp * 180) / Math.PI;

      // calcula cor do marker do1
      let markerIconUrl = "";
      if (pivot.protocol === 5) {
        switch (latestStatus?.[pivotIndex]?.streamStatus?.color) {
          case PIVOT_STATUS_COLOR_V5_RED:
            markerIconUrl = redMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_GREEN:
            markerIconUrl = greenMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_BLUE:
            markerIconUrl = blueMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_BROWN:
            markerIconUrl = brownMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_YELLOW:
            markerIconUrl = yellowMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1:
            markerIconUrl = slaveBlue1Marker;
            break;
          default:
            markerIconUrl = grayMarker;
            break;
        }
      } else {
        switch (latestStatus?.[pivotIndex]?.streamStatus?.color) {
          case PIVOT_STATUS_COLOR_OLD_RED:
            markerIconUrl = redMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_BLUE:
            markerIconUrl = blueMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_GREEN:
            markerIconUrl = greenMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_YELLOW:
            markerIconUrl = yellowMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_ORANGE:
            markerIconUrl = orangeMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_LIGHTBLUE:
            markerIconUrl = lightblueMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_BROWN:
            markerIconUrl = brownMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1:
            markerIconUrl = slaveBlue1Marker;
            break;
          default:
            markerIconUrl = grayMarker;
            break;
        }
      }

      // ////////////
      // Desenhos //
      // ////////////

      let circle;
      let sector;

      // TODO Setor

      if (pivot.config && pivot.config.setorial) {
        // Desenho do Pivot Setorial V4
        sector = new google.maps.Polygon({
          paths: [
            ...circlePath(
              centerPositionGMaps,
              referencePositionGMaps,
              referenceRadius,
              360,
              pivot.config.sector_angle
            ),
          ],
          strokeColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          strokeOpacity: 0.5,
          strokeWeight: 0.5,
          fillColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          fillOpacity: 0.5,
          map: googleMapRef.current,
        });

        circle = new google.maps.Circle({
          strokeOpacity: 0,
          strokeWeight: 0,
          fillOpacity: 0,
          map: googleMapRef.current,
          center: {
            lat: parseFloat(centerPosition[0]),
            lng: parseFloat(centerPosition[1]),
          },
          radius: referenceRadius,
        });
      } else if (pivot.controllerconfig?.content?.sector?.end_angle < 360) {
        // Desenho do Pivot Setorial V5
        sector = new google.maps.Polygon({
          paths: [
            ...circlePath(
              centerPositionGMaps,
              referencePositionGMaps,
              referenceRadius,
              360,
              pivot.controllerconfig?.content?.sector?.end_angle
            ),
          ],
          strokeColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          strokeOpacity: 0,
          strokeWeight: 0.5,
          fillColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          fillOpacity: 0.5,
          map: googleMapRef.current,
        });

        circle = new google.maps.Circle({
          strokeOpacity: 0,
          strokeWeight: 0.5,
          fillOpacity: 0,
          map: googleMapRef.current,
          center: {
            lat: parseFloat(centerPosition[0]),
            lng: parseFloat(centerPosition[1]),
          },
          radius: referenceRadius,
        });
      } else {
        circle = new google.maps.Circle({
          strokeColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          strokeOpacity: 0.5,
          strokeWeight: 0.5,
          fillColor: latestStatus?.[pivotIndex]?.streamStatus?.color,
          fillOpacity: 0.5,
          map: googleMapRef.current,
          center: {
            lat: parseFloat(centerPosition[0]),
            lng: parseFloat(centerPosition[1]),
          },
          radius: referenceRadius,
        });
      }

      const arrayArc: google.maps.Polygon[] = [];
      let arcLine: google.maps.LatLng[] = [];
      let arcLineUp: google.maps.LatLng[] = [];
      let flag = pivotHistory[0];
      const amountPoints = Math.trunc(deltaAngle / delta);

      pivotHistory.forEach((value, index, array) => {
        if (value !== flag || index === array.length - 1) {
          if (flag === 2) {
            const newGeo = new google.maps.Polygon({
              paths: arcLineUp.concat(arcLine.reverse()),
              strokeColor: "#00026b",
              strokeOpacity: 0.7,
              strokeWeight: 1,
              fillColor: "#00026b",
              fillOpacity: 0.7,
              map: googleMapRef.current,
              zIndex: 1,
            });
            arrayArc.push(newGeo);
          }
          arcLine = [];
          arcLineUp = [];
        }

        for (let i = 1; i <= amountPoints; i++) {
          pointArcDown = google.maps.geometry.spherical.computeOffsetOrigin(
            iniciaDown,
            minDistance,
            lineAngleFromReference + -90
          );

          pointArcUp = google.maps.geometry.spherical.computeOffsetOrigin(
            iniciaUp,
            minDistanceUp,
            lineAngleFromReference + -90
          );

          lineAngleFromReference += minAngleUp;

          arcLine.push(pointArcDown);
          arcLineUp.push(pointArcUp);

          iniciaDown = pointArcDown;
          iniciaUp = pointArcUp;
        }

        flag = value;
      });

      const arrow = new google.maps.Polygon({
        paths: lineUpArrow,
        fillColor: "#000000",
        fillOpacity: 1,
        map: googleMapRef.current,
        zIndex: 1,
      });

      const line = new google.maps.Polyline({
        path: linePoints,
        // strokeColor: "#000000",
        // strokeOpacity: 1,
        // strokeWeight: 4,
        map: googleMapRef.current,
        zIndex: 10,
      });

      const triangle = new google.maps.Polygon({
        paths: triangleCoords,
        strokeColor: "#FFFFFF",
        strokeOpacity: 1,
        strokeWeight: 0,
        fillColor: "#FFFFFF",
        fillOpacity: 1,
        map: googleMapRef.current,
        zIndex: 1,
      });

      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(centerPosition[0]),
          lng: parseFloat(centerPosition[1]),
        },
        map: googleMapRef.current,
        icon: {
          url: markerIconUrl,
        },
        visible: false,
      });

      // ///////////////////
      // Event Listeners //
      // ///////////////////

      google.maps.event.addListener(
        googleMapRef.current,
        "zoom_changed",
        () => {
          const zoom = googleMapRef.current.getZoom();
          if (zoom <= 12) {
            line.setOptions({ strokeWeight: 1 });
            arrow.setOptions({ strokeWeight: 1 });
          } else if (zoom <= 14) {
            line.setOptions({ strokeWeight: 2 });
            arrow.setOptions({ strokeWeight: 2 });
          } else if (zoom <= 16) {
            line.setOptions({ strokeWeight: 3 });
            arrow.setOptions({ strokeWeight: 3 });
          } else {
            line.setOptions({ strokeWeight: 4 });
            arrow.setOptions({ strokeWeight: 4 });
          }
        }
      );

      const setPivotVisibility = (visibility: boolean) => {
        circle.setVisible(visibility);
        arrayArc.forEach((arc) => arc.setVisible(visibility));
        line.setVisible(visibility);
        arrow.setVisible(visibility);
        sector && sector.setVisible(visibility);
        dashedLine && dashedLine.setVisible(visibility);
        marker.setVisible(!visibility);
      };

      if (sector) {
        google.maps.event.addListener(sector, "mouseover", () => {
          infowindow.setPosition(centerPositionGMaps);
          infowindow.open(googleMapRef.current, circle);
        });

        google.maps.event.addListener(sector, "click", () => {
          const event = new CustomEvent("navigateToPivot", {
            detail: {
              pivotId: pivot.id,
              farmId: farmID,
            },
          });
          window.dispatchEvent(event);
        });
        google.maps.event.addListener(sector, "mouseout", () => {
          infowindow.close();
        });
      }

      google.maps.event.addListener(circle, "click", () => {
        const event = new CustomEvent("navigateToPivot", {
          detail: {
            pivotId: pivot.id,
            farmId: farmID,
          },
        });
        window.dispatchEvent(event);
      });

      google.maps.event.addListener(circle, "mouseover", () => {
        infowindow.setPosition(centerPositionGMaps);
        infowindow.open(googleMapRef.current, circle);
      });

      google.maps.event.addListener(circle, "mouseout", () => {
        infowindow.close();
      });

      google.maps.event.addListener(marker, "mouseover", () => {
        infowindow.setPosition(centerPositionGMaps);
        infowindow.open(googleMapRef.current, marker);
      });

      google.maps.event.addListener(marker, "click", () => {
        const event = new CustomEvent("navigateToPivot", {
          detail: {
            pivotId: pivot.id,
            farmId: farmID,
          },
        });
        window.dispatchEvent(event);
      });

      google.maps.event.addListener(marker, "mouseout", () => {
        infowindow.close();
      });

      google.maps.event.addListener(
        googleMapRef.current,
        "zoom_changed",
        () => {
          const zoom = googleMapRef.current.getZoom();
          if (zoom > 11) {
            setPivotVisibility(true);
          }
          if (zoom <= 11) {
            setPivotVisibility(false);
          }
        }
      );

      return {
        type: "PivotShape",
        circle,
        line,
        sector,
        semiCircle: arrayArc,
        triangle,
        arrow,
        dashedLine,
      };
    } else {
      if (
        !pivot.controllerstream_panel ||
        !pivot.controllerstream_gps ||
        !pivot.controllerconfig
      ) {
        return;
      }

      let gpsPosition: string[] = [];
      if (latestGPSStream)
        gpsPosition = [
          latestGPSStream.content?.latitude_longitude_gps?.latitude_gps.toString(),
          latestGPSStream.content?.latitude_longitude_gps?.longitude_gps.toString(),
        ];
      else
        gpsPosition = [
          pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
          pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
        ];

      if (!gpsPosition) return;

      const allGpsPositions = pivot.lpm_gps_streams;

      if (allGpsPositions.length === 0) return;

      // hover info span

      const infowindow = new google.maps.InfoWindow({
        content: `<span 
      style="cursor: pointer;"
      onclick="(function (){
        let event = new CustomEvent('navigateToPivot', { detail: {
          pivotId: ${pivot.id},
          farmId: ${farmID}
        }});

        window.dispatchEvent(event);
      })()">
        <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;text-align: left'>
          ${pivot.name}
        </span>
        
            <div
              style='background-color:${
                latestStatus?.[pivotIndex]?.streamStatus?.color
              };font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#fff;text-align: center;'
                >
              ${latestStatus?.[pivotIndex]?.streamStatus.text}
              </div>
              <p style='font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;display:flex;flex-direction:row;flex-wrap:wrap;text-align: left'>
          ${i18n("LPM_EFFICIENCY")} 
          <b>${
            " " +
            (
              (pivot?.controllerstream_panel?.content?.operation_time
                ?.wet_hour /
                pivot?.controllerstream_panel?.content?.operation_time
                  ?.total_hour) *
              100
            )?.toFixed(2)
          }%
          </b>
        </p>
      </span>`,
      });

      const position0 = new google.maps.LatLng(
        allGpsPositions[0].latitude,
        allGpsPositions[0].longitude
      );
      const positionN =
        allGpsPositions.length >= AMOSTRATION_SIZE
          ? new google.maps.LatLng(
              allGpsPositions[AMOSTRATION_SIZE - 1].latitude,
              allGpsPositions[AMOSTRATION_SIZE - 1].longitude
            )
          : new google.maps.LatLng(
              allGpsPositions[allGpsPositions.length - 1].latitude,
              allGpsPositions[allGpsPositions.length - 1].longitude
            );

      let currentPosition = position0;
      let mockPosition = google.maps.geometry.spherical.computeOffset(
        currentPosition,
        Math.round(
          pivot.controllerconfig.content.pivot_parameters.radius_last / 2
        ),
        90
      );
      let lastPosition = allGpsPositions.length >= 1 ? positionN : mockPosition;

      let arrow;

      const currentHeading = google.maps.geometry.spherical.computeHeading(
        lastPosition,
        currentPosition
      );

      const gpsStartLine = google.maps.geometry.spherical.computeOffset(
        currentPosition,
        Math.round(
          pivot.controllerconfig.content.pivot_parameters.radius_last / 2
        ),
        currentHeading + 90
      );

      const gpsEndLine = google.maps.geometry.spherical.computeOffset(
        currentPosition,
        Math.round(
          pivot.controllerconfig.content.pivot_parameters.radius_last / 2
        ),
        currentHeading - 90
      );

      // Line

      const line = new google.maps.Polyline({
        path: [gpsStartLine, gpsEndLine],
        strokeColor: allGpsPositions.length > 1 ? "#000" : "#0000",
        strokeOpacity: 1,
        strokeWeight: 8,
        map: googleMapRef.current,
        zIndex: 10,
      });

      const referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
        gpsStartLine,
        gpsEndLine
      );

      // Arrow

      const dotArrowPositionGMaps = google.maps.geometry.spherical.computeOffset(
        currentPosition,
        referenceRadius / 4,
        currentHeading
      );

      const dotDownArrow = google.maps.geometry.spherical.interpolate(
        dotArrowPositionGMaps,
        gpsEndLine,
        0.3
      );

      const dotUpArrow = google.maps.geometry.spherical.interpolate(
        dotArrowPositionGMaps,
        gpsStartLine,
        0.3
      );

      const lineUpArrow: google.maps.LatLng[] =
        allGpsPositions.length > 1 &&
        (V5_IRRIGATING_STATUSES.includes(
          pivot.controllerstream_panel?.content?.irrigation_status
            .irrigation_status
        ) ||
          (pivot.latest_panel_stream?.reason < 100 &&
            pivot.latest_panel_stream?.reason > 0 &&
            !(
              pivot.latest_panel_stream?.reason == 32 ||
              pivot.latest_panel_stream?.reason == 21
            )))
          ? [dotUpArrow, dotArrowPositionGMaps, dotDownArrow]
          : [];

      arrow = new google.maps.Polygon({
        paths: lineUpArrow,
        fillColor: "#000000",
        fillOpacity: 1,
        map: googleMapRef.current,
        zIndex: 1,
      });

      // calcula cor do marker do1
      let markerIconUrl = "";
      if (pivot.protocol === 5) {
        switch (latestStatus?.[pivotIndex]?.streamStatus?.color) {
          case PIVOT_STATUS_COLOR_V5_RED:
            markerIconUrl = redMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_GREEN:
            markerIconUrl = greenMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_BLUE:
            markerIconUrl = blueMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_BROWN:
            markerIconUrl = brownMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_YELLOW:
            markerIconUrl = yellowMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1:
            markerIconUrl = slaveBlue1Marker;
            break;
          default:
            markerIconUrl = grayMarker;
            break;
        }
      } else {
        switch (latestStatus?.[pivotIndex]?.streamStatus?.color) {
          case PIVOT_STATUS_COLOR_OLD_RED:
            markerIconUrl = redMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_BLUE:
            markerIconUrl = blueMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_GREEN:
            markerIconUrl = greenMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_YELLOW:
            markerIconUrl = yellowMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_ORANGE:
            markerIconUrl = orangeMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_LIGHTBLUE:
            markerIconUrl = lightblueMarker;
            break;
          case PIVOT_STATUS_COLOR_OLD_BROWN:
            markerIconUrl = brownMarker;
            break;
          case PIVOT_STATUS_COLOR_V5_SLAVE_BLUE1:
            markerIconUrl = slaveBlue1Marker;
            break;
          default:
            markerIconUrl = grayMarker;
            break;
        }
      }

      // ////////////
      // Desenhos //
      // ////////////

      let rectangles = [];

      let gpsPositions = [];

      let auxGpsPositions = [allGpsPositions[0]];

      let currentCondition = allGpsPositions[0]?.water_blade > 0 ? true : false;
      let nextCondition = allGpsPositions[1]?.water_blade > 0 ? true : false;

      let counter = 0;
      let accBladeCounter = 0;
      let accWaterBlade = 0;

      for (let index = 0; index < allGpsPositions.length; index++) {
        if (allGpsPositions[index].water_blade > 0) {
          accBladeCounter++;
          accWaterBlade = accWaterBlade + allGpsPositions[index].water_blade;
        }

        if (index + 1 < allGpsPositions.length) {
          currentCondition =
            allGpsPositions[index].water_blade > 0 ? true : false;
          nextCondition =
            allGpsPositions[index + 1].water_blade > 0 ? true : false;

          counter++;

          if (
            currentCondition !== nextCondition ||
            counter >= AMOSTRATION_SIZE ||
            index === allGpsPositions.length - 1
          ) {
            auxGpsPositions.push(allGpsPositions[index + 1]);
            if (
              allGpsPositions[0].id === auxGpsPositions[0].id &&
              counter < AMOSTRATION_SIZE
            ) {
              gpsPositions.push({
                id_destiny: auxGpsPositions[0].id,
                id_origin: auxGpsPositions[1].id,
                color_intensity:
                  mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
                isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
                destiny: new google.maps.LatLng(
                  auxGpsPositions[0].latitude,
                  auxGpsPositions[0].longitude
                ),
                destiny_heading: currentHeading,
                origin: new google.maps.LatLng(
                  auxGpsPositions[1].latitude,
                  auxGpsPositions[1].longitude
                ),
                origin_heading: currentHeading,
              });
              accBladeCounter = 0;
              accWaterBlade = 0;
            } else if (
              allGpsPositions[0].id === auxGpsPositions[0].id &&
              counter >= AMOSTRATION_SIZE
            ) {
              gpsPositions.push({
                id_destiny: auxGpsPositions[0].id,
                id_origin: auxGpsPositions[1].id,
                color_intensity:
                  mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
                isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
                destiny: new google.maps.LatLng(
                  auxGpsPositions[0].latitude,
                  auxGpsPositions[0].longitude
                ),
                destiny_heading: google.maps.geometry.spherical.computeHeading(
                  new google.maps.LatLng(
                    auxGpsPositions[1].latitude,
                    auxGpsPositions[1].longitude
                  ),
                  new google.maps.LatLng(
                    auxGpsPositions[0].latitude,
                    auxGpsPositions[0].longitude
                  )
                ),
                origin: new google.maps.LatLng(
                  auxGpsPositions[1].latitude,
                  auxGpsPositions[1].longitude
                ),
                origin_heading: google.maps.geometry.spherical.computeHeading(
                  new google.maps.LatLng(
                    auxGpsPositions[1].latitude,
                    auxGpsPositions[1].longitude
                  ),
                  new google.maps.LatLng(
                    auxGpsPositions[0].latitude,
                    auxGpsPositions[0].longitude
                  )
                ),
              });
              accBladeCounter = 0;
              accWaterBlade = 0;
            } else if (counter < AMOSTRATION_SIZE) {
              gpsPositions.push({
                id_destiny: auxGpsPositions[0].id,
                id_origin: auxGpsPositions[1].id,
                color_intensity:
                  mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
                isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
                destiny: new google.maps.LatLng(
                  auxGpsPositions[0].latitude,
                  auxGpsPositions[0].longitude
                ),
                destiny_heading:
                  gpsPositions[gpsPositions.length - 1]?.origin_heading,
                origin: new google.maps.LatLng(
                  auxGpsPositions[1].latitude,
                  auxGpsPositions[1].longitude
                ),
                origin_heading:
                  gpsPositions[gpsPositions.length - 1]?.origin_heading,
              });
              accBladeCounter = 0;
              accWaterBlade = 0;
            } else {
              gpsPositions.push({
                id_destiny: auxGpsPositions[0].id,
                id_origin: auxGpsPositions[1].id,
                color_intensity:
                  mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
                isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
                destiny: new google.maps.LatLng(
                  auxGpsPositions[0].latitude,
                  auxGpsPositions[0].longitude
                ),
                destiny_heading:
                  gpsPositions[gpsPositions.length - 1]?.origin_heading,
                origin: new google.maps.LatLng(
                  auxGpsPositions[1].latitude,
                  auxGpsPositions[1].longitude
                ),
                origin_heading: google.maps.geometry.spherical.computeHeading(
                  new google.maps.LatLng(
                    auxGpsPositions[1].latitude,
                    auxGpsPositions[1].longitude
                  ),
                  new google.maps.LatLng(
                    auxGpsPositions[0].latitude,
                    auxGpsPositions[0].longitude
                  )
                ),
              });
              accBladeCounter = 0;
              accWaterBlade = 0;
            }
            auxGpsPositions = [];

            auxGpsPositions.push(allGpsPositions[index + 1]);
            counter = 0;
          }
        } else if (
          allGpsPositions[0].id === auxGpsPositions[0].id &&
          index === allGpsPositions.length - 1 &&
          auxGpsPositions.length === 1
        ) {
          auxGpsPositions.push(allGpsPositions[auxGpsPositions.length - 1]);

          gpsPositions.push({
            id_destiny: auxGpsPositions[0].id,
            id_origin: auxGpsPositions[1].id,
            color_intensity:
              mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
            isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
            destiny: new google.maps.LatLng(
              auxGpsPositions[0].latitude,
              auxGpsPositions[0].longitude
            ),
            destiny_heading: currentHeading,
            origin: new google.maps.LatLng(
              auxGpsPositions[1].latitude,
              auxGpsPositions[1].longitude
            ),
            origin_heading: currentHeading,
          });
          accBladeCounter = 0;
          accWaterBlade = 0;
        } else if (
          index === allGpsPositions.length - 1 &&
          auxGpsPositions.length === 1
        ) {
          auxGpsPositions.push(allGpsPositions[allGpsPositions.length - 1]);

          gpsPositions.push({
            id_destiny: auxGpsPositions[0].id,
            id_origin: auxGpsPositions[1].id,
            color_intensity:
              mmToPTP(pivot, accWaterBlade / accBladeCounter) / 100,
            isIrrigating: auxGpsPositions[0].water_blade > 0 ? true : false,
            destiny: new google.maps.LatLng(
              auxGpsPositions[0].latitude,
              auxGpsPositions[0].longitude
            ),
            destiny_heading:
              gpsPositions[gpsPositions.length - 1]?.origin_heading,
            origin: new google.maps.LatLng(
              auxGpsPositions[1].latitude,
              auxGpsPositions[1].longitude
            ),
            origin_heading:
              gpsPositions[gpsPositions.length - 1]?.origin_heading,
          });
          accBladeCounter = 0;
          accWaterBlade = 0;
        }
      }

      auxGpsPositions = [];
      let currentPath = [];

      gpsPositions.forEach((item) => {
        currentPath = [
          google.maps.geometry.spherical.computeOffset(
            item.destiny,
            Math.round(
              pivot.controllerconfig.content.pivot_parameters.radius_last / 2
            ),
            item.destiny_heading + 90
          ),

          google.maps.geometry.spherical.computeOffset(
            item.origin,
            Math.round(
              pivot.controllerconfig.content.pivot_parameters.radius_last / 2
            ),
            item.origin_heading + 90
          ),
          google.maps.geometry.spherical.computeOffset(
            item.origin,
            Math.round(
              pivot.controllerconfig.content.pivot_parameters.radius_last / 2
            ),
            item.origin_heading - 90
          ),

          google.maps.geometry.spherical.computeOffset(
            item.destiny,
            Math.round(
              pivot.controllerconfig.content.pivot_parameters.radius_last / 2
            ),
            item.destiny_heading - 90
          ),
        ];

        const path = getIsPathValid(currentPath);

        if (path.length) {
          rectangles.push(
            new google.maps.Polygon({
              paths: path,
              strokeColor: "#FFFFFF",
              strokeOpacity: 1,
              strokeWeight: 0,
              fillColor: item.isIrrigating ? "#1d7ade" : "#44442f",
              fillOpacity: item.isIrrigating
                ? 0.65 * (1 - item.color_intensity) + 0.2
                : 0.9,
              map: googleMapRef.current,
              zIndex: 1,
            })
          );
        }
      });

      let circle = new google.maps.Circle({
        strokeOpacity: 0.0,
        strokeWeight: 0.0,
        fillOpacity: 0.0,
        map: googleMapRef.current,
        center: {
          lat: allGpsPositions[0].latitude,
          lng: allGpsPositions[0].longitude,
        },
        radius: referenceRadius,
      });

      const marker = new google.maps.Marker({
        position: currentPosition,
        map: googleMapRef.current,
        icon: {
          url: markerIconUrl,
        },
        visible: allGpsPositions.length === 1 ? true : false,
      });

      // ///////////////////
      // Event Listeners //
      // ///////////////////

      google.maps.event.addListener(
        googleMapRef.current,
        "zoom_changed",
        () => {
          const zoom = googleMapRef.current.getZoom();
          if (zoom <= 12) {
            line.setOptions({ strokeWeight: 6 });
          } else if (zoom <= 14) {
            line.setOptions({ strokeWeight: 7 });
          } else if (zoom <= 16) {
            line.setOptions({ strokeWeight: 8 });
          } else {
            line.setOptions({ strokeWeight: 9 });
          }
        }
      );

      const setPivotVisibility = (visibility: boolean) => {
        line.setVisible(visibility);
        marker.setVisible(!visibility);
      };

      google.maps.event.addListener(line, "click", () => {
        const event = new CustomEvent("navigateToPivot", {
          detail: {
            pivotId: pivot.id,
            farmId: farmID,
          },
        });
        window.dispatchEvent(event);
      });

      google.maps.event.addListener(line, "mouseover", () => {
        infowindow.setPosition(currentPosition);
        infowindow.open(googleMapRef.current, line);
      });

      google.maps.event.addListener(line, "mouseout", () => {
        infowindow.close();
      });

      google.maps.event.addListener(marker, "mouseover", () => {
        infowindow.setPosition(currentPosition);
        infowindow.open(googleMapRef.current, marker);
      });

      google.maps.event.addListener(marker, "click", () => {
        const event = new CustomEvent("navigateToPivot", {
          detail: {
            pivotId: pivot.id,
            farmId: farmID,
          },
        });
        window.dispatchEvent(event);
      });

      google.maps.event.addListener(marker, "mouseout", () => {
        infowindow.close();
      });

      google.maps.event.addListener(
        googleMapRef.current,
        "zoom_changed",
        () => {
          const zoom = googleMapRef.current.getZoom();
          if (zoom > 11) {
            allGpsPositions.length === 1
              ? setPivotVisibility(false)
              : setPivotVisibility(true);
          }
          if (zoom <= 11) {
            setPivotVisibility(false);
          }
        }
      );

      return {
        type: "LinearPivotShape",
        line,
        circle,
        rectangles,
        arrow,
      };
    }
  });
}

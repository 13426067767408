import React, { useEffect, useState } from "react";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../mobileConfig";
import { ZOOM_TABLE } from "../../../../../utils/utils";
import { Button, CircularProgress } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../../../../../services/webclient";
import axios from "axios";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { Alert } from "@material-ui/lab";
import { SegmentsDisplayTable } from "./components/SegmentsDisplayTable";
import { SegmentsDisplayChartMap } from "./components/SegmentsDisplayChartMap";
import "./SegmentsDisplay.scss";

const SegmentsDisplay = ({ pivot }) => {
  const [sendingExcel, setSendingExcel] = useState<boolean>(false);

  const [warningFlag, setWarningFlag] = useState<boolean>(true);

  let filesaver = require("file-saver");

  const notify = useNotify();

  const [waterBladePerSegment, setWaterBladePerSegment] = useState<
    string | any[]
  >("-");

  const waterBladeByAngle = async () => {
    try {
      const res = await coreHTTPClient.get(
        `/v3/reports/pivots/${pivot.id}/pivot_water_consumption_by_segment/`
      );
      if (res.data.error) setWaterBladePerSegment("-");
      else setWaterBladePerSegment(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    pivot.controllerconfig.content.segments.map((_element, index) => {
      if (pivot.controllerconfig?.segments_crop?.[index]?.crop_plant_date)
        setWarningFlag(false);
    });
    waterBladeByAngle();
  }, []);

  const handleExcelClick = (): void => {
    setSendingExcel(true);

    if (isMobile()) {
      setSendingExcel(false);
      window.open(
        `${process.env.WEB_URL}/v3/reports/pivots/${pivot.id}/pivot_water_consumption_by_segment/excel/`
      );
    } else {
      axios
        .get(
          `/v3/reports/pivots/${pivot.id}/pivot_water_consumption_by_segment/excel/`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              Connection: "close",
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
            responseType: "blob",
          }
        )
        .then((response) => {
          let blob: Blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          filesaver.saveAs(blob, `Segmentos/Plantio.csv`);
        })
        .catch((err) => {
          notify("PIVOT_REPORT_EXCEL_ERROR", "error");
        })
        .finally(() => {
          setSendingExcel(false);
        });
    }
  };

  // Center In String
  let centerInString =
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.latitude_center
    ).toString() +
    "," +
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.longitude_center
    ).toString();

  //Zoom Level Reference
  let center = new google.maps.LatLng(
    parseFloat(pivot.controllerconfig.content.pivot_positions.latitude_center),
    parseFloat(pivot.controllerconfig.content.pivot_positions.longitude_center)
  );

  let reference = new google.maps.LatLng(
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.latitude_reference
    ),
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.longitude_reference
    )
  );

  // Nos testes feitos como não era um caso de um pivo real as lat e lng estavam erradas então fiz um tratamento de erros
  // nos pivos oficiais não será necessário porem não atrapalha em nada
  let pivotLine = new google.maps.LatLng(
    parseFloat(
      pivot.controllerstream_gps.content.latitude_longitude_gps.latitude_gps
    ),
    parseFloat(
      pivot.controllerstream_gps.content.latitude_longitude_gps.longitude_gps
    )
  );

  let pivotAngle = google.maps.geometry.spherical.computeHeading(
    center,
    pivotLine
  );

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  let pivotLineWithRadius = google.maps.geometry.spherical.computeOffset(
    center,
    referenceRadius,
    pivotAngle
  );

  let triangleReferenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    pivotLineWithRadius
  );

  // Triangle
  const referenceTriangleBottom = google.maps.geometry.spherical.computeOffset(
    center,
    triangleReferenceRadius,
    pivot.reference_angle - 7
  );

  const referenceTriangleTop = google.maps.geometry.spherical.computeOffset(
    center,
    referenceRadius,
    pivot.reference_angle + 7
  );

  const referenceTriangleCenter = google.maps.geometry.spherical.computeOffset(
    center,
    referenceRadius / 1.3,
    pivot.reference_angle
  );

  const triangleCoords: string[] = [
    referenceTriangleBottom.lat().toString() +
      "," +
      referenceTriangleBottom.lng().toString(),
    referenceTriangleTop.lat().toString() +
      "," +
      referenceTriangleTop.lng().toString(),
    referenceTriangleCenter.lat().toString() +
      "," +
      referenceTriangleCenter.lng().toString(),
  ];

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;

  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
    }
  }

  let currentPivotPosition =
    pivotLineWithRadius.lat().toString() +
    "," +
    pivotLineWithRadius.lng().toString();

  const excelButton = () => {
    if (warningFlag)
      return (
        <div className="crop-alerts">
          <Alert severity="warning">{i18n("CROP_WARNING_CONFIG")}</Alert>
        </div>
      );
    else if (waterBladePerSegment === "-" && !warningFlag)
      return (
        <div className="crop-alerts">
          <Alert severity="info">{i18n("CROP_WARNING_INFO")}</Alert>
        </div>
      );
    else
      return (
        <Button
          className="excel-button"
          size="small"
          onClick={handleExcelClick}
          startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
          disabled={sendingExcel}
        >
          <>{i18n("PIVOT_REPORT_EXCEL")}</>
        </Button>
      );
  };

  return (
    !isMobile() && (
      <div className="segments-display">
        <div className="map-cointainer">
          <div>
            <span className="span-color">{i18n("HOVER_OVER_SEGMENT")}</span>
          </div>

          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${centerInString}&zoom=${zoomLevel}&size=${
              isMobile() ? "300" : "335"
            }x300&maptype=satellite&path=color:0xffffffff|weight:4|${
              triangleCoords[0]
            }|${triangleCoords[1]}|${triangleCoords[2]}|${
              triangleCoords[0]
            }&path=color:0x000000ff|weight:4|${centerInString}|${currentPivotPosition}${
              process.env.MAPS_KEY
            }`}
            style={{
              width: isMobile() ? "300px" : "335px",
            }}
          />

          <SegmentsDisplayChartMap
            pivot={pivot}
            waterBladePerSegment={waterBladePerSegment}
          />
        </div>

        <div className="table-container">
          {excelButton()}

          {pivot.controllerconfig?.content?.sector?.end_angle !== 360 && (
            <div className="crop-alerts">
              <Alert severity={"error"}>{i18n("CROP_WARNING_SECTORIAL")}</Alert>
            </div>
          )}

          <SegmentsDisplayTable
            pivot={pivot}
            waterBladePerSegment={waterBladePerSegment}
          />
        </div>
      </div>
    )
  );
};

export default SegmentsDisplay;

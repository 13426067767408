import React, { useState } from "react";
import styled from "@emotion/styled";
import { Select } from "@material-ui/core";
import momentTZ from "moment-timezone";
import { i18n } from "../../../../../../../../i18n/i18nText";

const Container = styled.div`
  width: 100%;
  height: 60%;
  margin-top: 13px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const Label = styled.div`
  position: relative;
  top: 0;
  font-size: 10px;
  opacity: 0.4;
`;

interface Props {
  rawTimezone: string;
  setTimezone: any;
}

export default function TimezoneSelectorForm(props: Props) {
  const [timezones, _] = useState(momentTZ.tz.names());
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    props.rawTimezone
  );

  return (
    <Container>
      <div>
        <Label>{i18n("EDIT_FARM_TIMEZONE")}</Label>
        <Select
          style={{ width: "100%" }}
          labelId="timezone-group-label"
          id="timezone-group"
          native
          value={selectedTimezone}
          onChange={(e) => {
            setSelectedTimezone(String(e.target.value));
            props.setTimezone(String(e.target.value));
          }}
        >
          {timezones.map((timezone) => (
            <option
              style={{ fontSize: "18px", fontWeight: "normal" }}
              key={timezone}
              value={timezone}
            >
              {timezone}
            </option>
          ))}
        </Select>
      </div>
    </Container>
  );
}

import { MutableRefObject } from "react";
import blueMarker from "../../../../../../assets/images/markers/blue.svg";
import grayMarker from "../../../../../../assets/images/markers/gray.svg";
import greenMarker from "../../../../../../assets/images/markers/green.svg";
import redMarker from "../../../../../../assets/images/markers/red.svg";
import yellowMarker from "../../../../../../assets/images/markers/yellow.svg";
import {
  PIVOT_STATUS_COLOR_V5_BLUE,
  PIVOT_STATUS_COLOR_V5_GREEN,
  PIVOT_STATUS_COLOR_V5_RED,
  PIVOT_STATUS_COLOR_V5_YELLOW,
} from "../../../../../../utils/models/pivots";
import { MapShape } from "../../../../../../utils/types";
import {
  bauerPivotStatus,
  colorStatus,
} from "../../../../../../utils/models/bauerpivots";

function interpolateColors(
  number: number,
  color1: string,
  color2: string
): string {
  const normalizedNumber = number / 360;

  // Interpola entre as duas cores com base na posição do número
  const r1 = parseInt(color1.substring(1, 3), 16);
  const g1 = parseInt(color1.substring(3, 5), 16);
  const b1 = parseInt(color1.substring(5, 7), 16);

  const r2 = parseInt(color2.substring(1, 3), 16);
  const g2 = parseInt(color2.substring(3, 5), 16);
  const b2 = parseInt(color2.substring(5, 7), 16);

  const r = Math.round(r1 + (r2 - r1) * normalizedNumber);
  const g = Math.round(g1 + (g2 - g1) * normalizedNumber);
  const b = Math.round(b1 + (b2 - b1) * normalizedNumber);

  return rgbToHex(r, g, b);
}

function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (value: number) => {
    const hex = value.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const RenderSegment = (props: any) => {
  /** Props */
  const points = [];
  const centerLat = props.center.lat; // Latitud;
  const centerLng = props.center.lng; // Longitude

  // Raio em metros
  const radiusMeters = props.radius;

  // Diferença angular em graus que você deseja mover
  const startAngle = props.segment.begin;
  const numberOfSides = props.segment.end;

  for (let i = startAngle; i <= numberOfSides; i++) {
    const angularDifferenceDegrees = i;

    // Converta a diferença angular de graus para radianos
    const angularDifferenceRadians = angularDifferenceDegrees * (Math.PI / 180);

    // Calcule as novas coordenadas
    const newLat =
      centerLat + (radiusMeters / 111320) * Math.cos(angularDifferenceRadians);

    const newLng =
      centerLng +
      (radiusMeters / (111320 * Math.cos(centerLat * (Math.PI / 180)))) *
        Math.sin(angularDifferenceRadians);

    const newLat2 =
      centerLat +
      ((radiusMeters - radiusMeters / 8) / 111320) *
        Math.cos(angularDifferenceRadians);

    const newLng2 =
      centerLng +
      ((radiusMeters - radiusMeters / 8) /
        (111320 * Math.cos(centerLat * (Math.PI / 180)))) *
        Math.sin(angularDifferenceRadians);

    if (i === startAngle) {
      points.push({ lat: newLat, lng: newLng });
    }

    points.push({ lat: newLat2, lng: newLng2 });
  }

  for (let i = numberOfSides; i >= startAngle; i--) {
    const angularDifferenceDegrees = i;

    // Converta a diferença angular de graus para radianos
    const angularDifferenceRadians = angularDifferenceDegrees * (Math.PI / 180);

    // Calcule as novas coordenadas
    const newLat =
      centerLat + (radiusMeters / 111320) * Math.cos(angularDifferenceRadians);

    const newLng =
      centerLng +
      (radiusMeters / (111320 * Math.cos(centerLat * (Math.PI / 180)))) *
        Math.sin(angularDifferenceRadians);

    points.push({ lat: newLat, lng: newLng });
  }

  return new google.maps.Polygon({
    paths: points,
    strokeColor: "#00026b",
    strokeOpacity: 0.7,
    strokeWeight: 1,
    fillColor: "#00026b",
    fillOpacity: 1,
    map: props.googleMapRef.current,
    zIndex: 1,
    visible: true,
  });
};

const formatMapHistoryInSegments = (mapHistory: {
  [index: number]: number;
}) => {
  const segments: {
    begin: number;
    end: number;
    color: string;
  }[] = [];

  const colors = ["#2218cb", "#ffa200"];

  if (mapHistory)
    Object.keys(mapHistory)?.forEach((item, index) => {
      if (mapHistory[item] >= 2) {
        segments.push({
          begin: segments.length === 0 ? index : index - 1,
          end: index + 1,
          color: "",
        });
      }
    });

  return segments.map((item, index) => {
    const color = interpolateColors(item.begin / 360, colors[0], colors[1]);
    item.color = color;

    return item;
  });
};

// Assets

const AMOSTRATION_SIZE: number = 45;
const kmInLatAngle = 0.0089928;

//Conversion from spherical do (x,y) coordenades
const convertSphericalToCartesian = (latitude: number, longitude: number) => {
  const latRad = (latitude * Math.PI) / 180;
  const lonRad = (longitude * Math.PI) / 180;
  const earthRadius = 6367;
  const pX = earthRadius * Math.cos(latRad) * Math.cos(lonRad);
  const pY = earthRadius * Math.cos(latRad) * Math.sin(lonRad);
  return { x: pX, y: pY };
};

// Explanations and source code for test of intersections https://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function
function intersects(path: object) {
  const a = convertSphericalToCartesian(path[0].lat(), path[0].lng()).x;
  const b = convertSphericalToCartesian(path[0].lat(), path[0].lng()).y;
  const c = convertSphericalToCartesian(path[1].lat(), path[1].lng()).x;
  const d = convertSphericalToCartesian(path[1].lat(), path[1].lng()).y;
  const p = convertSphericalToCartesian(path[2].lat(), path[2].lng()).x;
  const q = convertSphericalToCartesian(path[2].lat(), path[2].lng()).y;
  const r = convertSphericalToCartesian(path[3].lat(), path[3].lng()).x;
  const s = convertSphericalToCartesian(path[3].lat(), path[3].lng()).y;
  let det: number, gamma: number, lambda: number;
  det = (c - a) * (s - q) - (r - p) * (d - b);
  if (det === 0) {
    return false;
  } else {
    lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
    gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
}

//  Test if two lines from rectangle's path intersect vertically and if horizontally
//  switching points in order to translate polygon to be drawn
const getIsPathValid = (path: any) => {
  if (intersects(path) || intersects([path[3], path[0], path[1], path[2]])) {
    if (intersects(path)) return [path[0], path[2], path[1], path[3]];
    else return [path[0], path[1], path[3], path[2]];
  } else {
    return path;
  }
};

export function addBauerPivotsOnMap(
  googleMapRef: MutableRefObject<any>,
  bauerPivots: any[],
  mapShapes: MapShape[],
  farmID: number,
  withInfoWindow: boolean = true
): MapShape[] {
  mapShapes.forEach((shape) => {
    if (!shape) return;
    if (shape.type === "BauerPivotShape") {
      shape.circle.setMap(null);
      shape.line.setMap(null);
      shape.triangle.setMap(null);
      shape.arrow.setMap(null);
      shape.sector && shape.sector.setMap(null);
      // shape.dashedLine && shape.dashedLine.setMap(null);

      shape.semiCircle.forEach((s) => s.setMap(null));
    }
  });
  if (googleMapRef.current === undefined) return;
  return bauerPivots.map<MapShape>((bauerPivot) => {
    if (!bauerPivot?.latest) return;
    if (bauerPivot.latest.detail) return;
    /**
     * Safeguard do recoil. Se o pivot não tem os dois componentes que o fazem completo, não renderiza ele _ainda_
     */
    // Função para gerar o array que desenha o circulo com determinado angulo
    function circlePath(center, reference, radius, points, angle) {
      const a = [];
      const p = angle / points; // Variação do angulo baseado no numero de pontos
      let d = google.maps.geometry.spherical.computeHeading(
        // Angulo que começa o desenho
        center,
        reference
      );

      for (let i = 0; i < points; ++i, d += p) {
        a.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
      }
      a.unshift(center);

      return a;
    }

    // ////////////
    // Cálculos //
    // ////////////

    // Bauer Pivot
    // Clockwise = 0 --> Parado
    // Clockwise = 1 --> Avanço -> BauerStatus 1
    // Clockwise = 2 --> Reverso -> BauerStatus 15

    let irrigationDirection =
      bauerPivot.latest.status == 1
        ? 1
        : bauerPivot.latest.status == 15
        ? 2
        : 0;

    let centerPosition: string[] = [];
    let referencePosition: string[] = [];
    let gpsPosition: string[] = [];
    let endReferencePosition: string[] = [];

    // Declarações
    centerPosition = [
      bauerPivot.center_latitude.toString(),
      bauerPivot.center_longitude.toString(),
    ];

    referencePosition = [
      (
        Math.round(
          (parseFloat(centerPosition[0]) +
            (kmInLatAngle *
              bauerPivot.latest.last_tower_radius *
              Math.cos(
                ((bauerPivot.latest.sector_begin / 100 +
                  bauerPivot.latest.zero_position / 100) *
                  Math.PI) /
                  180
              )) /
              1000) *
            1e7
        ) / 1e7
      ).toString(),
      (
        Math.round(
          (parseFloat(centerPosition[1]) +
            (kmInLatAngle *
              bauerPivot.latest.last_tower_radius *
              Math.sin(
                ((bauerPivot.latest.sector_begin / 100 +
                  bauerPivot.latest.zero_position / 100) *
                  Math.PI) /
                  180
              )) /
              1000) *
            1e7
        ) / 1e7
      ).toString(),
    ];

    gpsPosition = [
      bauerPivot.center_latitude.toString(),
      bauerPivot.center_longitude.toString(),
    ];

    const centerPositionGMaps = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );

    if (!gpsPosition) return;

    const gpsPositionGMaps = new google.maps.LatLng(
      parseFloat(gpsPosition[0]),
      parseFloat(gpsPosition[1])
    );

    const referencePositionGMaps = new google.maps.LatLng(
      parseFloat(referencePosition[0]),
      parseFloat(referencePosition[1])
    );

    const endReferencePositionGMaps = new google.maps.LatLng(
      parseFloat(endReferencePosition[0]),
      parseFloat(endReferencePosition[1])
    );

    const referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
      centerPositionGMaps,
      referencePositionGMaps
    );

    const infowindow = new google.maps.InfoWindow({
      content: `<span
    style="cursor: pointer;"
    onclick="(function (){
      let event = new CustomEvent('navigateToBauerPivot', { detail: {
        pivotId: ${bauerPivot.id},
        farmId: ${farmID}
      }});

      window.dispatchEvent(event);
    })()">
      <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;display:flex;flex-direction:row;flex-wrap:wrap;'>
        ${bauerPivot.name}
      </span>
          <div
            style='background-color:${colorStatus(
              bauerPivot.latest.status
            )};font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#fff;display:flex;flex-direction:row;flex-wrap:wrap;'
              >
              ${bauerPivotStatus(bauerPivot.latest.status).toUpperCase()}
            </div>
    </span>`,
    });

    const lineRadius = google.maps.geometry.spherical.computeDistanceBetween(
      centerPositionGMaps,
      gpsPositionGMaps
    );
    const lineAngle = bauerPivot.latest.machine_angle / 100;

    let lineAngleFromReference = google.maps.geometry.spherical.computeHeading(
      centerPositionGMaps,
      referencePositionGMaps
    );

    let linePoints: google.maps.LatLng[] = [];

    linePoints = [
      centerPositionGMaps,
      google.maps.geometry.spherical.computeOffset(
        centerPositionGMaps,
        referenceRadius,
        lineAngle
      ),
    ];

    const marginalPositionGMaps = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius,
      lineAngle
    );

    const upMarginalPositionGMaps = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius * 0.85,
      lineAngle
    );

    const interiorPositionRadiusGMaps = google.maps.geometry.spherical.interpolate(
      centerPositionGMaps,
      marginalPositionGMaps,
      0.7171
    );

    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 2,
    };

    // Line endReference
    let dashedLine: google.maps.Polyline;

    const marginalPositionFromReference = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius,
      lineAngleFromReference
    );

    const upMarginalPositionFromReference = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius * 0.85,
      lineAngleFromReference
    );

    // if (
    //   // Condições para irrigação V4
    //   (pivot.irrigation_end_angle != null &&
    //     pivot.latest_panel_stream.reason < 100) ||
    //   // Condições para irrigação V5
    //   // Se o status do pivô for irrigando
    //   (pivot.controllerstream_panel?.content?.irrigation_status
    //     ?.irrigation_status === 4 &&
    //     // Se o modo de parada da irrigação atual for por ângulo, fim de setor ou voltas
    //     [1, 3, 4].includes(
    //       pivot.controllerstream_panel?.content?.current_irrigation_information
    //         .stop_mode
    //     ))
    // ) {
    //   const endIrrigationDashedLine = google.maps.geometry.spherical.computeOffset(
    //     centerPositionGMaps,
    //     referenceRadius,
    //     (pivot.protocol === 5
    //       ? pivot.controllerstream_panel?.content.current_irrigation_information
    //           .stop_angle
    //       : pivot.irrigation_end_angle) + pivot.reference_angle
    //   );
    //   dashedLine = new google.maps.Polyline({
    //     path: [centerPositionGMaps, endIrrigationDashedLine],
    //     strokeOpacity: 0,
    //     icons: [
    //       {
    //         icon: lineSymbol,
    //         offset: "0",
    //         repeat: "10px",
    //       },
    //     ],
    //     map: googleMapRef.current,
    //   });
    // }

    // Arrow
    const dotArrowPositionGMaps = google.maps.geometry.spherical.computeOffsetOrigin(
      marginalPositionGMaps,
      referenceRadius / 5,
      lineAngle + (irrigationDirection == 1 ? -45 : 45)
    );

    const dotUpArrow = google.maps.geometry.spherical.interpolate(
      marginalPositionGMaps,
      dotArrowPositionGMaps,
      0.3
    );

    const dotDownArrow = google.maps.geometry.spherical.interpolate(
      interiorPositionRadiusGMaps,
      dotArrowPositionGMaps,
      0.3
    );

    const lineUpArrow: google.maps.LatLng[] =
      irrigationDirection !== 0
        ? [dotUpArrow, dotArrowPositionGMaps, dotDownArrow]
        : [];

    // Triangle
    const referenceTriangleBottom = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius,
      bauerPivot.latest.zero_position / 100 + 7
    );

    const referenceTriangleTop = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius,
      bauerPivot.latest.zero_position / 100 - 7
    );

    const referenceTriangleCenter = google.maps.geometry.spherical.computeOffset(
      centerPositionGMaps,
      referenceRadius / 1.3,
      bauerPivot.latest.zero_position / 100
    );

    const triangleCoords: google.maps.LatLng[] = [
      referenceTriangleBottom,
      referenceTriangleTop,
      referenceTriangleCenter,
    ];

    // ARCO
    const delta = 1 / 16;
    const deltaAngle = 1;
    // const pivotHistory = Array.from({ length: 360 }, (v, k,) => k > 0 && k < 180 ? 2 : 0);

    const pivotHistory = bauerPivot?.history;

    let iniciaDown = marginalPositionFromReference;
    let iniciaUp = upMarginalPositionFromReference;

    const angleInicialArc = pivotHistory?.length * deltaAngle;
    const distaceArc = 2 * Math.PI * referenceRadius * (angleInicialArc / 359);
    const distaceArcUp =
      2 * Math.PI * referenceRadius * 0.85 * (angleInicialArc / 359);
    const minDistance = distaceArc / (angleInicialArc / delta);
    const minDistanceUp = distaceArcUp / (angleInicialArc / delta);

    let pointArcDown = google.maps.geometry.spherical.computeOffsetOrigin(
      iniciaDown,
      minDistance,
      lineAngleFromReference - 90
    );

    let pointArcUp = google.maps.geometry.spherical.computeOffsetOrigin(
      iniciaUp,
      minDistanceUp,
      lineAngleFromReference - 90
    );

    const distance = google.maps.geometry.spherical.computeDistanceBetween(
      iniciaDown,
      pointArcDown
    );

    const distanceUp = google.maps.geometry.spherical.computeDistanceBetween(
      iniciaUp,
      pointArcUp
    );

    let minAngle = 2 * Math.asin(distance / 2 / referenceRadius);
    minAngle = (minAngle * 180) / Math.PI;

    let minAngleUp = 2 * Math.asin(distanceUp / 2 / (referenceRadius * 0.85));
    minAngleUp = (minAngleUp * 180) / Math.PI;

    // calcula cor do marker do1
    let markerIconUrl = "";
    switch (colorStatus(bauerPivot.latest.status)) {
      case PIVOT_STATUS_COLOR_V5_RED:
        markerIconUrl = redMarker;
        break;
      case PIVOT_STATUS_COLOR_V5_GREEN:
        markerIconUrl = greenMarker;
        break;
      case PIVOT_STATUS_COLOR_V5_BLUE:
        markerIconUrl = blueMarker;
        break;

      case PIVOT_STATUS_COLOR_V5_YELLOW:
        markerIconUrl = yellowMarker;
        break;
      default:
        markerIconUrl = grayMarker;
        break;
    }

    // ////////////
    // Desenhos //
    // ////////////

    let circle;
    let sector;

    // TODO Setor

    if (bauerPivot.latest.sector_end / 100 < 360) {
      sector = new google.maps.Polygon({
        paths: [
          ...circlePath(
            centerPositionGMaps,
            referencePositionGMaps,
            referenceRadius,
            360,
            bauerPivot.latest.sector_end / 100 -
              bauerPivot.latest.sector_begin / 100
          ),
        ],
        strokeColor: colorStatus(bauerPivot.latest.status),
        strokeOpacity: 0,
        strokeWeight: 0.5,
        fillColor: colorStatus(bauerPivot.latest.status),
        fillOpacity: 0.5,
        map: googleMapRef.current,
      });

      circle = new google.maps.Circle({
        strokeOpacity: 0,
        strokeWeight: 0.5,
        fillOpacity: 0,
        map: googleMapRef.current,
        center: {
          lat: parseFloat(centerPosition[0]),
          lng: parseFloat(centerPosition[1]),
        },
        radius: referenceRadius,
      });
    } else {
      circle = new google.maps.Circle({
        strokeColor: colorStatus(bauerPivot.latest.status),
        strokeOpacity: 0.5,
        strokeWeight: 0.5,
        fillColor: colorStatus(bauerPivot.latest.status),
        fillOpacity: 0.5,
        map: googleMapRef.current,
        center: {
          lat: parseFloat(centerPosition[0]),
          lng: parseFloat(centerPosition[1]),
        },
        radius: referenceRadius,
      });
    }

    let arcLine: google.maps.LatLng[] = [];
    let arcLineUp: google.maps.LatLng[] = [];
    const amountPoints = Math.trunc(deltaAngle / delta);
    let irrigationArcLineArray: google.maps.LatLng[][] = [];
    let irrigationArcLineArrayUp: google.maps.LatLng[][] = [];
    // const highIrrigationValue = Math.max(...pivotHistory);

    // pivotHistory.forEach((value, index, array) => {
    //   arcLine = [];
    //   arcLineUp = [];

    //   for (let i = 1; i <= amountPoints; i++) {
    //     pointArcDown = google.maps.geometry.spherical.computeOffsetOrigin(
    //       iniciaDown,
    //       minDistance,
    //       lineAngleFromReference + -90
    //     );

    //     pointArcUp = google.maps.geometry.spherical.computeOffsetOrigin(
    //       iniciaUp,
    //       minDistanceUp,
    //       lineAngleFromReference + -90
    //     );

    //     lineAngleFromReference += minAngle;

    //     arcLine.push(pointArcDown);
    //     arcLineUp.push(pointArcUp);

    //     iniciaDown = pointArcDown;
    //     iniciaUp = pointArcUp;
    //   }

    //   if (value > 0) {
    //     const newGeo = new google.maps.Polygon({
    //       paths: arcLineUp.concat(arcLine.reverse()),
    //       strokeColor: "#00026b",
    //       strokeOpacity: 0.5 * (value / highIrrigationValue) + 0.2s,
    //       strokeWeight: 1,
    //       fillColor: "#00026b",
    //       fillOpacity: 0.5 * (value / highIrrigationValue) + 0.2,
    //       map: googleMapRef.current,
    //       zIndex: 1,
    //     });
    //     arrayArc.push(newGeo);
    //   }
    // });

    const angleDegrees = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(
        parseFloat(centerPosition[0]),
        parseFloat(centerPosition[1])
      ),
      new google.maps.LatLng(
        parseFloat(referencePosition[0]),
        parseFloat(referencePosition[1])
      )
    );

    const arrayArc = formatMapHistoryInSegments(pivotHistory)?.map(
      (item, index) =>
        RenderSegment({
          key: `segment-${index}`,
          center: {
            lat: parseFloat(centerPosition[0]),
            lng: parseFloat(centerPosition[1]),
          },
          segment: {
            ...item,
            begin: item.begin + angleDegrees,
            end: item.end + angleDegrees,
          },
          radius: referenceRadius,
          googleMapRef,
        })
    );

    const arrow = new google.maps.Polygon({
      paths: lineUpArrow,
      fillColor: "#000000",
      fillOpacity: 1,
      map: googleMapRef.current,
      zIndex: 12,
    });

    const line = new google.maps.Polyline({
      path: linePoints,
      // strokeColor: "#000000",
      // strokeOpacity: 1,
      // strokeWeight: 4,
      map: googleMapRef.current,
      zIndex: 10,
    });

    const triangle = new google.maps.Polygon({
      paths: triangleCoords,
      strokeColor: "#FFFFFF",
      strokeOpacity: 1,
      strokeWeight: 0,
      fillColor: "#FFFFFF",
      fillOpacity: 1,
      map: googleMapRef.current,
      zIndex: 1,
    });

    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: markerIconUrl,
      },
      visible: false,
    });

    // ///////////////////
    // Event Listeners //
    // ///////////////////

    google.maps.event.addListener(googleMapRef.current, "zoom_changed", () => {
      const zoom = googleMapRef.current.getZoom();
      if (zoom <= 12) {
        line.setOptions({ strokeWeight: 1 });
        arrow.setOptions({ strokeWeight: 1 });
      } else if (zoom <= 14) {
        line.setOptions({ strokeWeight: 2 });
        arrow.setOptions({ strokeWeight: 2 });
      } else if (zoom <= 16) {
        line.setOptions({ strokeWeight: 3 });
        arrow.setOptions({ strokeWeight: 3 });
      } else {
        line.setOptions({ strokeWeight: 4 });
        arrow.setOptions({ strokeWeight: 4 });
      }
    });

    const setPivotVisibility = (visibility: boolean) => {
      circle.setVisible(visibility);
      arrayArc.forEach((arc) => arc.setVisible(visibility));
      line.setVisible(visibility);
      arrow.setVisible(visibility);
      sector && sector.setVisible(visibility);
      dashedLine && dashedLine.setVisible(visibility);
      marker.setVisible(!visibility);
    };

    if (sector) {
      google.maps.event.addListener(sector, "mouseover", () => {
        if (withInfoWindow) {
          infowindow.setPosition(centerPositionGMaps);
          infowindow.open(googleMapRef.current, circle);
        }
      });

      google.maps.event.addListener(sector, "click", () => {
        const event = new CustomEvent("navigateToBauerPivot", {
          detail: {
            pivotId: bauerPivot.id,
            farmId: farmID,
          },
        });
        window.dispatchEvent(event);
      });
      google.maps.event.addListener(sector, "mouseout", () => {
        if (withInfoWindow) {
          infowindow.close();
        }
      });
    }

    google.maps.event.addListener(circle, "click", () => {
      const event = new CustomEvent("navigateToBauerPivot", {
        detail: {
          pivotId: bauerPivot.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(circle, "mouseover", () => {
      if (withInfoWindow) {
        infowindow.setPosition(centerPositionGMaps);
        infowindow.open(googleMapRef.current, circle);
      }
    });

    google.maps.event.addListener(circle, "mouseout", () => {
      if (withInfoWindow) {
        infowindow.close();
      }
    });

    // google.maps.event.addListener(marker, "mouseover", () => {
    //   infowindow.setPosition(centerPositionGMaps);
    //   infowindow.open(googleMapRef.current, marker);
    // });

    google.maps.event.addListener(marker, "click", () => {
      const event = new CustomEvent("navigateToBauerPivot", {
        detail: {
          pivotId: bauerPivot.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    // google.maps.event.addListener(marker, "mouseout", () => {
    //   infowindow.close();
    // });

    google.maps.event.addListener(googleMapRef.current, "zoom_changed", () => {
      const zoom = googleMapRef.current.getZoom();
      if (zoom > 11) {
        setPivotVisibility(true);
      }
      if (zoom <= 11) {
        setPivotVisibility(false);
      }
    });

    return {
      type: "BauerPivotShape",
      circle,
      line,
      sector,
      semiCircle: arrayArc,
      triangle,
      arrow,
      // dashedLine,
    };
  });
}

import React from "react";
import "./DashboardBox.scss";
import { ChildrenProps, ReactJSX } from "../../../../utils/types";
import { Fade } from "@material-ui/core";
import MobileZone from "../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../components/Zones/DesktopZone";

interface DashboardBoxProps {
  leftElement?: ReactJSX;
  centerElement?: ReactJSX;
  rightElement?: ReactJSX;
  twoThirdsElement?: ReactJSX;
  tenTwoElement?: ReactJSX;
  singleElement?: ReactJSX;
  majorCenterElement?: ReactJSX;
  fixedHeight?: boolean;
  disableFade?: boolean;
}

type Props = DashboardBoxProps & ChildrenProps;

function DashboardBox(props: Props) {
  const leftElement = (
    <div className="dashboard-box__header__left">{props.leftElement}</div>
  );

  const centerElement = (
    <div className="dashboard-box__header__center">{props.centerElement}</div>
  );

  //makes the grid be 1fr 3fr 1fr (icon input icon);
  const majorCenterElement = props.majorCenterElement && (
    <div className="dashboard-box__header__center">
      {props.majorCenterElement}
    </div>
  );

  const rightElement = (
    <div className="dashboard-box__header__right">{props.rightElement}</div>
  );

  const singleElement = (
    <div className="dashboard-box__header__single">{props.singleElement}</div>
  );

  const twoThirdsElement = (
    <div className="dashboard-box__header__two-thirds">
      {props.twoThirdsElement}
    </div>
  );

  const tenTwoElement = (
    <div className="dashboard-box__header__two-thirds">
      {props.tenTwoElement}
    </div>
  );

  const header3Columns = (
    <div
      className={`dashboard-box__header${
        props.majorCenterElement ? "__grid-1-3-1" : ""
      }`}
    >
      {leftElement}
      {majorCenterElement || centerElement}
      {rightElement}
    </div>
  );

  const headerTwoTen = (
    <div className="dashboard-box__header__grid-3-9">
      {leftElement}
      {twoThirdsElement}
    </div>
  );

  const headerTenTwo = (
    <div className="dashboard-box__header__grid-9-3">
      {leftElement}
      {tenTwoElement}
    </div>
  );

  const dashBoardBox = (
    <div className="dashboard-box">
      {props.singleElement
        ? singleElement
        : twoThirdsElement.props.children !== undefined
        ? headerTwoTen
        : tenTwoElement.props.children !== undefined
        ? headerTenTwo
        : null}
      {props.fixedHeight ? (
        <div className="dashboard-box__content-no-scroll">{props.children}</div>
      ) : (
        <div className="dashboard-box__content">{props.children}</div>
      )}
    </div>
  );

  return (
    <>
      <MobileZone>
        {props?.disableFade ? (
          dashBoardBox
        ) : (
          <Fade in={true}>{dashBoardBox}</Fade>
        )}
      </MobileZone>

      <DesktopZone>{dashBoardBox}</DesktopZone>
    </>
  );
}

export default DashboardBox;

import { ReactJSX } from "../../utils/types";

export enum FarmsTypes {
  STORE_FARM = "@@farms/STORE_FARM",
}

export interface FarmRedux {
  id: number;
  name: string;
  is_administrator?: boolean;
  rightButton?: ReactJSX;
  timezone?: string;
}

export interface FarmState {
  data: FarmRedux;
}

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Collapse, SvgIcon } from "@material-ui/core";
import { useNavigate, useParams } from "react-router";
import routes from "../../../../../../../../routes/routes";
import {
  MeterSystemItemFamily,
  SelectedMetersystemState,
  IMeterStreamPeriodicFamily,
  IMeterStreamEventFamily,
} from "../../../../../../../../recoils/MeterSystemRecoil";

import moment from "moment";
import { getIMeterEventStreamStatus } from "../../../../../../../../utils/models/imeter";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import useSocketIO from "../../../../../../../../hooks/tools/useSocketIO";
import { Straighten } from "@material-ui/icons";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

const { SOCKET_SUFFIX } = process.env;

const GeneralBoxItem = styled.div`
  margin-top: 0px;
  font-size: 0.8rem;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
`;

const GeneralBoxItem2 = styled.div`
  margin-top: 12px;
  font-size: 0.8rem;
  margin-right: 12px;
  margin-left: -6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #444;
`;

const Container = styled.div`
  width: 100%;
  min-width: 300px;
  height: 100%;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  padding: 0.8rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const Status = styled.div`
  max-width: 60%;
  background-color: gray;
  font-weight: 600;
  font-size: 0.7rem;
  margin: 4px 0 4px 8px;
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
`;

const Info = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
  min-height: 18px;
  margin-bottom: -20px;
`;

const LastUpdate = styled.div`
  margin-top: 10px;
  color: #6e6e6e;
  font-size: 0.8rem;
`;

const IMeterName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FullSizeDiv = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  meterSystemId: number;
  meter: any;
}

function MeterSystemItem(props: Props) {
  const navigate = useNavigate();
  const [isConnected, socket] = useSocketIO();

  const farmID = parseInt(useParams().farm, 10);

  const meterSystem = useRecoilValue(
    MeterSystemItemFamily(props.meterSystemId)
  );

  const iMeterID = props.meterSystemId;

  const setSelectedMetersystem = useSetRecoilState(SelectedMetersystemState);

  let [iMeterLatestEventStream, setImeterStreamEventState] = useRecoilState(
    IMeterStreamEventFamily(meterSystem?.imeter_set[0]?.id)
  );

  let [
    imeterStreamPeriodicState,
    setImeterStreamPeriodicState,
  ] = useRecoilState(IMeterStreamPeriodicFamily(iMeterID));

  if (!iMeterLatestEventStream) {
    iMeterLatestEventStream = meterSystem?.imeter_set[0]?.latest_event_stream;
  }

  if (!imeterStreamPeriodicState) {
    imeterStreamPeriodicState =
      meterSystem?.imeter_set[0]?.latest_periodic_stream;
  }

  const [latestStream, setLatestStream] = useState(undefined);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setImeterStreamEventState(null);
  }, []);

  useEffect(() => {
    if (iMeterLatestEventStream && imeterStreamPeriodicState) {
      if (
        moment(imeterStreamPeriodicState.created).isSameOrAfter(
          moment(iMeterLatestEventStream.created)
        )
      ) {
        setLatestStream(imeterStreamPeriodicState);
      } else {
        setLatestStream(iMeterLatestEventStream);
      }
    }
  }, [imeterStreamPeriodicState, iMeterLatestEventStream]);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}imeter@${iMeterID}`);

      socket.bind("IMeterStream_event", (data) => {
        setImeterStreamEventState(data);
      });

      socket.bind("IMeterStream_periodic", (data) => {
        if (
          data.content?.imanage_sensor_measure_value !== undefined &&
          data.content?.imanage_sensor_measure_value.length >= 1
        )
          setImeterStreamPeriodicState(data);
      });

      return () => {
        socket.unbind("IMeterStream_event");
        socket.unbind("IMeterStream_periodic");

        socket.unsubscribe(`${SOCKET_SUFFIX}imeter@${iMeterID}`);
      };
    }
  }, []);

  return (
    <>
      <Container>
        <Content
          style={{
            borderBottom: "1px solid rgb(206, 206, 206)",
          }}
        >
          <Info>
            <FullSizeDiv
              onClick={(e: any) => {
                setSelectedMetersystem(meterSystem);
                navigate(
                  routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
                    ":metersystem",
                    String(meterSystem.id)
                  ).replace(":farm", String(farmID))
                );
              }}
            ></FullSizeDiv>

            <SvgIcon
              id="svg-icon"
              onClick={() => {
                setIsExpanded((prevState) => !prevState);
              }}
              style={{
                fontSize: 48,
                opacity: 0.25,
                transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path d="M 18 9 L 12 13 L 6 9 L 5 10 l 7 5 l 7 -5 z"></path>
            </SvgIcon>
          </Info>

          <Header
            onClick={(e: any) => {
              setSelectedMetersystem(meterSystem);
              navigate(
                routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
                  ":metersystem",
                  String(meterSystem.id)
                ).replace(":farm", String(farmID))
              );
            }}
          >
            {meterSystem.name}
          </Header>

          <GeneralBoxItem2
            onClick={(e: any) => {
              setSelectedMetersystem(meterSystem);
              navigate(
                routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
                  ":metersystem",
                  String(meterSystem.id)
                ).replace(":farm", String(farmID))
              );
            }}
          >
            <Straighten
              style={{
                transform: "rotate(270deg)",
              }}
            />

            <div>
              {`${formatFloatValue(
                (props.meter.imeter_set[0].latest_periodic_stream.content
                  .imanage_sensor_measure_value[0].value /
                  100 /
                  props.meter.imeter_set[0].latest_config.graphic_max_value) *
                  100,
                1
              )}% (${formatFloatValue(
                props.meter.imeter_set[0].latest_periodic_stream.content
                  .imanage_sensor_measure_value[0].value / 100
              )}m)`}
            </div>
          </GeneralBoxItem2>

          <GeneralBoxItem>
            <LastUpdate
              onClick={(e: any) => {
                setSelectedMetersystem(meterSystem);
                navigate(
                  routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
                    ":metersystem",
                    String(meterSystem.id)
                  ).replace(":farm", String(farmID))
                );
              }}
            >
              {moment(imeterStreamPeriodicState?.created).format(
                "DD MMM HH:mm"
              )}
            </LastUpdate>
          </GeneralBoxItem>
        </Content>
      </Container>

      <Collapse in={isExpanded}>
        <Container
          onClick={(e: any) => {
            setSelectedMetersystem(meterSystem);
            navigate(
              routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
                ":metersystem",
                String(meterSystem.id)
              ).replace(":farm", String(farmID))
            );
          }}
          style={{
            backgroundColor: "#d6d6d6",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Content>
            <Header>
              <IMeterName>{meterSystem.imeter_set[0]?.name}</IMeterName>
              <Status
                style={{
                  backgroundColor: getIMeterEventStreamStatus(
                    latestStream?.content?.imanage_master_status?.status
                  ).color,
                }}
              >
                {getIMeterEventStreamStatus(
                  latestStream?.content?.imanage_master_status?.status
                ).text.toUpperCase()}
              </Status>
            </Header>
          </Content>
        </Container>
      </Collapse>
    </>
  );
}

export default React.memo(MeterSystemItem);

import React, { useState } from "react";
import "./Warning.scss";
import { Button, Modal, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router";
import { i18n, i18nTextId } from "../../../../i18n/i18nText";

const useStyles = makeStyles(() => ({
  button: {
    width: "150px",
  },
}));

function getModalStyle() {
  return {
    top: `${50}%`,
    margin: "auto",
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

interface Props {
  openModal: boolean;
  setOpenModal: any;
  text: string;
  route?: string;
}
const WarningEmail = (props: Props) => {
  const [modalStyle] = useState(getModalStyle);
  const { setOpenModal, text, route } = props;
  const classes = useStyles({});
  const navigate = useNavigate();
  return (
    <Modal
      disableAutoFocus
      open={true}
      onClose={() => {
        setOpenModal(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="absolute-wrapper">
        <div style={modalStyle} className="warning">
          <div className="warning__title">{i18n(text as i18nTextId)}</div>
          <div className="warning__button">
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                route && navigate(route);
                setOpenModal(false);
              }}
            >
              <>{i18n("FORGET_PASSWORD_OK")}</>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WarningEmail;

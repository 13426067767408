import { ClickAwayListener, IconButton, Tooltip } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React from "react";
import "*.svg";
interface Props {
  unit?: string;
  size?: number;
  description: string;
}

function InputTooltip(props: Props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        {props?.unit}
        <Tooltip
          style={{ paddingTop: "0", paddingBottom: "0" }}
          disableFocusListener
          title={props?.description}
          open={open}
          onMouseEnter={() => {
            setOpen(true);
          }}
        >
          <IconButton onClick={handleTooltipOpen}>
            <Help style={props.size ? { fontSize: `${props.size}px` } : {}} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default React.memo(InputTooltip);

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableWrapper: {
    overflow: "auto",
    borderTop: "1px solid #E0E0E0",
  },
  emptyColumnMobileV5: {
    padding: "15px 10px 15px 10px",
  },
  emptyColumnV5: {
    paddingTop: "22px",
    paddingBottom: "21px",
  },
  emptyColumnMobileV4: {
    padding: "25px 10px 25px 10px",
  },
  emptyColumnV4: {
    padding: "36px 10px 36px 10px",
  },
  emptyColumnLinear: {
    width: "100%",
  },
});

import React, { useCallback, useMemo, useState } from "react";
import { Column } from "../../../../../../../../components/GeneralTable/GeneralTable";
import moment from "moment";
import { ReactJSX } from "../../../../../../../../utils/types";
import { getModeAdd } from "../../../../../../../../utils/models/pivots";
import ExpandedContentConfigV5 from "../ExpandedContent/ExpandedContentConfigV5/ExpandedContentConfigV5";
import ConfigHistoryTable from "../ConfigHistoryTable";
import { Tooltip, Button, Checkbox } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import { Irpd } from "../../../../../../../../redux/irpds/types";
import useIrpdConfigHistoricV5 from "../../../../../../../../hooks/models/useIrpdConfigHistoricV5";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { Labels } from "../../../HistoricConfigMobile/HistoricConfigMobileV5";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import { isMobile } from "../../../../../../../../mobileConfig";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import AlertDialog from "../../../../../../../../components/AlertDialog/AlertDialog";
import { coreHTTPClient } from "../../../../../../../../services/webclient";

const noPointer1 = {
  cursor: "default",
  marginTop: "-5px",
};

const noPointer2 = {
  cursor: "default",
  marginTop: "-7px",
};

const noPointer3 = {
  cursor: "default",
  marginTop: "-7px",
};

function getMessageStatusIcon(status: number): JSX.Element | null {
  switch (status) {
    case 0:
      return <RemoveIcon style={noPointer1} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 1:
      return <DoneIcon style={noPointer2} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 2:
      return <DoneAllIcon style={noPointer3} htmlColor={"rgb(33, 150, 243)"} />;
    default:
      break;
  }
}

function getMessageStatusTooltipText(status: number): string {
  switch (status) {
    case 0:
      return i18n("CONFIG_NOT_SENT");
    case 1:
      return i18n("CONFIG_SENT");
    case 2:
      return i18n("CONFIG_RECEIVED");
    default:
      break;
  }
}

interface Props {
  irpdID: number;
  farmID: number;
  loadHistoricInfo: (
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdKwhPeak: number,
    irpdKwhOutOfPeak: number,
    irpdKwhReduced: number,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) => void;
  irpd: Irpd;
  pinned?: boolean;
  datasheet?: boolean;
}

const HistoricConfigV5 = (props: Props) => {
  const notify = useNotify();
  const { irpd, irpdID, farmID } = props;
  const [isAlertOpen, setalertOpenState] = useState({ open: false, id: null });

  const [
    async,
    historic,
    total,
    previousPage,
    nextPage,
    currentPage,
  ] = useIrpdConfigHistoricV5(
    farmID,
    irpdID,
    props.pinned,
    props.datasheet,
    irpd.protocol
  );

  // MOBILE -----------------------------------------------------------------------------

  const normalColumnsMobile: Column[] = [
    {
      id: "datasheet",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATASHEET")}</>,
      minWidth: 40,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const pinnedColumnsMobile: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "datasheet",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATASHEET")}</>,
      minWidth: 40,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const datasheetColumnsMobile: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const rowsMobile = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const datasheet = (
        <Checkbox
          checked={item.is_datasheet}
          onChange={(val) => {
            if (val.target.checked) {
              setalertOpenState({
                open: true,
                id: item.id,
              });
            }
          }}
        />
      );

      const pump = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        datasheet: datasheet,
        datailedComponent: (): ReactJSX => (
          <ExpandedContentConfigV5
            pump={pump}
            irpdID={irpdID}
            farmID={farmID}
            irpd={irpd}
            irpdConfig={item}
            hideFavoriteAction={props.datasheet}
            loadHistoricInfo={props.loadHistoricInfo}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, irpd]);

  // DESKTOP -----------------------------------------------------------------------------

  const normalColumnsDesktop: Column[] = [
    {
      id: "datasheet",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATASHEET")}</>,
      minWidth: 40,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const pinnedColumnsDesktop: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "datasheet",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATASHEET")}</>,
      minWidth: 40,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const datasheetColumnsDesktop: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const rowsDesktop = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const datasheet = (
        <Checkbox
          checked={item.is_datasheet}
          onChange={(val) => {
            if (val.target.checked) {
              setalertOpenState({
                open: true,
                id: item.id,
              });
            }
          }}
        />
      );

      const pump = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        datasheet: datasheet,
        pump: pump,
        datailedComponent: (): ReactJSX => (
          <ExpandedContentConfigV5
            irpdID={irpdID}
            farmID={farmID}
            irpd={irpd}
            irpdConfig={item}
            hideFavoriteAction={props.datasheet}
            loadHistoricInfo={props.loadHistoricInfo}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, irpd, isAlertOpen]);

  // Switch datasheet function
  const switchDatasheet = useCallback(
    (farmId: number, irpdId: number, configId: number) => {
      new Promise(async (resolve, reject) => {
        try {
          const response = await coreHTTPClient
            .post(
              `/v4/farms/${farmId}/irpds/${irpdId}/configv5/swap_datasheet/`,
              {
                name: i18n("DATASHEET_PLACEHOLDER"),
                config_id: configId,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                // Update the previous record if is in same page
                const hIndexDatasheet = historic?.findIndex(
                  (item) => item.is_datasheet === true
                );
                if (hIndexDatasheet !== -1) {
                  historic[hIndexDatasheet] = {
                    ...historic[hIndexDatasheet],
                    is_datasheet: false,
                  };
                }

                // Update the current record to datasheet
                const hIndexNonDatasheet = historic?.findIndex(
                  (item) => item.id === response.data.id
                );
                if (hIndexNonDatasheet !== -1) {
                  historic[hIndexNonDatasheet] = response.data;
                }

                // Notify success to user
                notify(
                  "HISTORIC_CONFIG_PIVOT_DATASHEET_ADD_SUCCESSFULLY",
                  "success"
                );
              } else {
                notify("HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED", "error");
              }
              setalertOpenState({ open: false, id: null });
            });
          resolve(response);
        } catch (err) {
          reject(err);
          notify("HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED", "error");
          setalertOpenState({ open: false, id: null });
        }
      });
    },
    [setalertOpenState, historic]
  );

  return (
    <div>
      <DesktopZone>
        <Labels />
      </DesktopZone>
      <AlertDialog
        open={isAlertOpen.open}
        yesAction={() => {
          switchDatasheet(farmID, irpdID, isAlertOpen.id);
        }}
        noAction={() => {
          setalertOpenState({
            open: false,
            id: null,
          });
        }}
        text={"DATASHEET_ALERT_CONFIRMATION"}
      />
      {isMobile() ? (
        <ConfigHistoryTable
          columns={
            props.datasheet
              ? datasheetColumnsMobile
              : props.pinned
              ? pinnedColumnsMobile
              : normalColumnsMobile
          }
          total={total}
          rows={rowsMobile}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsMobile === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      ) : (
        <ConfigHistoryTable
          columns={
            props.datasheet
              ? datasheetColumnsDesktop
              : props.pinned
              ? pinnedColumnsDesktop
              : normalColumnsDesktop
          }
          total={total}
          rows={rowsDesktop}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsDesktop === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      )}
      <DesktopZone>
        <div style={{ marginBottom: "20px" }} />
      </DesktopZone>
    </div>
  );
};

export default HistoricConfigV5;

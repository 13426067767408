import React, { Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { createStyles } from "@material-ui/styles";
// this guy required only on the docs site to work with dynamic date library
import { IconButton, makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { i18n, i18nTextId } from "../../../i18n/i18nText";

interface Props {
  label?: string;
  newLabel?: i18nTextId;
  id: string;
  disabled?: boolean;
  state: [moment.Moment | null, Dispatch<SetStateAction<moment.Moment>>];
  startSelector?: boolean;
  endSelector?: boolean;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
}

function FormWeekPicker(props: Props) {
  const classes = useStyles();

  function renderWrappedWeekDay(
    date: moment.Moment,
    selectedDate: moment.Moment,
    dayInCurrentMonth
  ) {
    let selectDateStart = moment(selectedDate);
    let selectDateEnd = moment(selectedDate);
    let start = selectDateStart.startOf("week");
    let end = selectDateEnd.endOf("week");

    let dayIsBetween = date.isBetween(start, end, "day");
    let isFirstDay = date.isSame(start, "day");
    let isLastDay = date.isSame(end, "day");

    const wrapperClassName = dayIsBetween
      ? classes.highlight
      : isFirstDay
      ? clsx(classes.highlight, classes.firstHighlight)
      : isLastDay
      ? clsx(classes.highlight, classes.endHighlight)
      : null;

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {date.format("D")} </span>
        </IconButton>
      </div>
    );
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        id={props.id}
        disabled={props.disabled}
        label={
          props.newLabel ? (
            i18n(props.newLabel)
          ) : (
            <>{i18n(props.label as i18nTextId)}</>
          )
        }
        format="L"
        value={props.state[0]}
        onChange={(value) => {
          if (value !== null) {
            if (props.startSelector) props.state[1](value.startOf("week"));
            else if (props.endSelector) props.state[1](value.endOf("week"));
            else props.state[1](value);
          }
        }}
        renderDay={renderWrappedWeekDay}
        maxDate={props.maxDate}
        minDate={props.minDate}
      />
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dayWrapper: {
      position: "relative",
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: "0 2px",
      color: "inherit",
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: "#676767",
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      extend: "highlight",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    },
    endHighlight: {
      extend: "highlight",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  })
);

export default FormWeekPicker;

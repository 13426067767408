import { atom, atomFamily, selectorFamily } from "recoil";
import { Irpd, IrpdPressureStream, IrpdStream } from "../redux/irpds/types";

export const IrpdState = atom<Irpd | null>({
  key: "IrpdState",
  default: null,
});

export const IrpdStateList = atom<Irpd[] | null>({
  key: "IrpdStateLIst",
  default: [],
});

export const IrpdFamily = selectorFamily<Irpd, number>({
  key: "IrpdFamily",
  get: (irpdId: number) => ({ get }) => {
    let irpds = get(IrpdStateList);
    let latest_irpd_pressure_stream = get(IrpdPressureStreamFamily(irpdId));
    let latest_irpd_stream = get(IrpdStreamFamily(irpdId));
    let latest_irpd_stream_v5_periodic = get(IrpdStreamV5Family(irpdId));

    if (irpds) {
      let irpd = irpds.find((p) => p.id == irpdId);

      if (latest_irpd_pressure_stream) {
        irpd = { ...irpd, latest_irpd_pressure_stream };
      }

      if (latest_irpd_stream) {
        irpd = { ...irpd, latest_irpd_stream };
      }

      if (latest_irpd_stream_v5_periodic) {
        irpd = { ...irpd, latest_irpd_stream_v5_periodic };
      }

      return irpd;
    }
  },
});

export const IrpdPressureStreamFamily = atomFamily<
  IrpdPressureStream | null,
  number
>({
  key: "IrpdPressureStreamFamily",
  default: null,
});

export const IrpdStreamFamily = atomFamily<IrpdStream | null, number>({
  key: "IrpdStreamFamily",
  default: null,
});

export const IrpdStreamV5Family = atomFamily<IrpdStream | null, number>({
  key: "IrpdStreamV5Family",
  default: null,
});

import React, { useEffect, useState } from "react";
import { Network } from "@capacitor/network";
import { Button } from "@material-ui/core";
import { i18n } from "../../../../../i18n/i18nText";
import MobileZone from "../../../../../components/Zones/MobileZone";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import routes from "../../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isInternetOnState } from "../../../../../recoils/IsInternetOn";

const OfflineBackDrop = styled(motion.div)`
  position: fixed;
  z-index: 99998;

  overflow: hidden;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const OfflineActionSheet = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;

  margin-bottom: -5vh;

  width: 100vw;
  height: 190px;
  z-index: 99999;

  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const ActionSheetContainer = styled.div`
  width: 100%;
  display: flex;

  padding: 0 40px 40px 40px;

  font-size: 15px;
  text-align: center;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ActionSheetTopBar = styled.div`
  margin: 10px 10px;
  width: 50px;
  height: 4px;
  background-color: #e0dfe4;
  border-radius: 2px;
`;

const BACKDROP_VARIANTS = {
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
};

const ACTION_SHEET_VARIANTS = {
  hidden: {
    y: 200,
  },
  visible: {
    y: 0,
  },
};

export default function ConnectionActionSheet() {
  const [animation, setAnimation] = useState("hidden");
  let navigate = useNavigate();
  const [isInternetOn, setIsInternetOn] = useRecoilState(isInternetOnState);
  useEffect(() => {
    Network.addListener("networkStatusChange", (status) => {
      setIsInternetOn(status.connected);
      if (status.connected) {
        setAnimation("hidden");
      } else {
        setAnimation("visible");
        navigate(routes.DASHBOARD.NO_INTERNET_CONECTION, { replace: true });
      }
    });
  }, []);

  useEffect(() => {
    if (animation === "hidden") {
      document.body.style.overflow = "initial";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [animation]);

  return (
    <MobileZone>
      <OfflineActionSheet
        initial="hidden"
        animate={animation}
        variants={ACTION_SHEET_VARIANTS}
      >
        <ActionSheetContainer>
          <ActionSheetTopBar />
          Parece que você está sem internet! Conecte-se e tente novamente.
          <Button
            style={{ width: "90%", marginTop: "15px" }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setAnimation("hidden");
            }}
          >
            {i18n("FORGET_PASSWORD_OK")}
          </Button>
        </ActionSheetContainer>
      </OfflineActionSheet>
      <OfflineBackDrop
        onScroll={(e) => {
          e.preventDefault();
        }}
        initial="hidden"
        animate={animation}
        variants={BACKDROP_VARIANTS}
      ></OfflineBackDrop>
    </MobileZone>
  );
}

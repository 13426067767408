import React, { ReactElement, useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import { ReactJSX } from "../../utils/types";
import { CSSProperties } from "react";

interface Props {
  button: ReactJSX;
  children: ReactJSX;
  divStyle?: CSSProperties;
}

function DropdownPopover(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = useState(false);

  const [button, setButton] = useState(null);

  const [content, setContent] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    open ? setOpen(false) : setOpen(true);
  };

  useEffect(() => {
    setButton(
      React.cloneElement(props.button as ReactElement<any>, {
        onClick: handleClick,
      })
    );
    setContent(
      React.cloneElement(props.children as ReactElement<any>, {
        onClick: () => {
          setOpen(false);
        },
      })
    );
  }, []);

  return (
    <div style={props.divStyle}>
      {button}
      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          onClick={() => {
            setOpen(false);
          }}
        >
          {content}
        </div>
      </Popover>
    </div>
  );
}

export default DropdownPopover;

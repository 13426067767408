import React, { useCallback, useEffect, useState } from "react";
import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../../../hooks/tools/useFormValidation";
import {
  checkIsOnIntervalNumeric,
  checkIsOnIntervalString,
} from "../../../../../../../../utils/checks";
import DraggableMap from "../../../../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import TimezoneSelectorForm from "../../components/TimezoneSelectorForm/TimezoneSelectorForm";
import FormInputPosition from "../../../../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import { centerMarkerSelector } from "../../../../../../../../recoils/DraggableMapRecoil";
import BaseRadioField from "./ChangeRadioField/BaseRadioField";
import { MessageStatus } from "../../../../../../Pivot/EditPivot/EditPivot";
import { isMobile } from "../../../../../../../../mobileConfig";
import { useDispatch } from "react-redux";
import GeolocationGetter from "../../../../../../../../components/GeolocationGetter/GeolocationGetter";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { Icon, Tooltip } from "@material-ui/core";
import QrReader from "modern-react-qr-reader";
import { handleNumberMask } from "../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { showLocationModal } from "../../../../../../../../redux/locationModal/actions";
import CentralLinkedCheckbox from "../../components/CentralLinkedCheckbox/CentralLinkedCheckbox";

const blueDot = require("../../../../../../../../assets/images/markers/light-blue-dot.svg");

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

let globalResolve: (data: string) => void;
let globalReject: (data: string) => void;

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  stateName: [string, (value: string) => void];
  stateCentralLinked: [boolean, (value: boolean) => void];
  stateLat: [string, (value: string) => void];
  stateLng: [string, (value: string) => void];
  stateBill: [string, (value: string) => void];
  stateWaterBill: [string, (value: string) => void];
  initialLat: string;
  initialLng: string;
  rawTimezone: string;
  setTimezone: any;
  stateBase: [string, (value: string) => void];
  isCreating?: boolean;
}
export default function GeneralInformation(props: Props) {
  const {
    validationErrors,
    formValidationActions,
    stateName,
    stateCentralLinked,
    stateLat,
    stateLng,
    stateBill,
    stateWaterBill,
    rawTimezone,
    setTimezone,
    stateBase,
    isCreating,
  } = props;

  const [showQrReader, setShowQrReader] = useState(false);
  const [radioId, setRadioId] = useState("");

  const dispatch = useDispatch();

  const [event, setEvent] = useState(false);
  const [farmCoordsError, setFarmCoordsError] = useState(false);
  // name
  const setValidName = useCallback(
    (value) => {
      stateName[1](value);
      formValidationActions.setError(
        "name",
        checkIsOnIntervalString(value, 3, 34)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // lat
  const setValidLat = useCallback(
    (value) => {
      stateLat[1](value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // lng
  const setValidLng = useCallback(
    (value) => {
      stateLng[1](value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={stateLat[1]}
                setLongitude={stateLng[1]}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={blueDot} />
    </Button3D>
  );

  useEffect(() => {
    if (farmCoordsError) {
      formValidationActions.setError("lat", "ALARM");
      formValidationActions.setError("lng", "ALARM");
    } else {
      formValidationActions.clearError("lat");
      formValidationActions.clearError("lng");
    }
  }, [farmCoordsError]);

  // water_billing_date
  const setValidWaterBillingDate = useCallback(
    (value) => {
      value = handleNumberMask(value).replace("-", "");
      stateWaterBill[1](value);
      formValidationActions.setError(
        "waterBillingDate",
        checkIsOnIntervalNumeric(value, 1, 31)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // billing_date
  const setValidBillingDate = useCallback(
    (value) => {
      value = handleNumberMask(value).replace("-", "");
      stateBill[1](value);
      formValidationActions.setError(
        "billingDate",
        checkIsOnIntervalNumeric(value, 1, 31)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  useEffect(() => {
    setRadioId(props.stateBase[0]);
  }, [props.stateBase[0]]);

  async function showQrReaderWithMode(mode: "RADIO_ID") {
    setShowQrReader(true);

    const data = await new Promise<string>((resolve) => {
      globalResolve = resolve;
    });

    window.alert(`${i18n("CREATE_DEVICE_MODAL_CODE_READ_SUCCESS")}\n${data}`);

    setShowQrReader(false);

    if (mode == "RADIO_ID") {
      setRadioId(data.trim());
    }

    document.getElementById("radio-central-farm").focus();
  }

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <div className="general-form__row-3-3-3-3">
        {isCreating ? (
          <div className="device-row mb-30">
            <div className="input">
              <FormInput
                id="radio-central-farm"
                helperText={
                  stateBase[0].length != 16
                    ? "CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID"
                    : undefined
                }
                label={"EDIT_PIVOT_CENTRAL_LABEL"}
                state={[radioId.toUpperCase(), stateBase[1]]}
              />
            </div>

            {isMobile() ? (
              <div
                className="camera-button"
                onClick={async () => {
                  showQrReaderWithMode("RADIO_ID");
                }}
              >
                <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                  <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                </Tooltip>
              </div>
            ) : null}
          </div>
        ) : (
          stateCentralLinked[0] && (
            <BaseRadioField
              radioId={stateBase[0]}
              setRadioId={stateBase[1]}
              label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
              status={MessageStatus.NOT_SENT}
              locked={true}
              editBtn={true}
            />
          )
        )}
      </div>

      <div className="general-form__row-3-3-3-3">
        <FormInput
          id="farm-name"
          helperText={validationErrors["name"]}
          label={"FARM_FIELD_NAME"}
          state={[stateName[0], setValidName]}
        />

        {!radioId && (
          <CentralLinkedCheckbox
            centralLinked={stateCentralLinked[0]}
            setCentralLinked={stateCentralLinked[1]}
          />
        )}
      </div>

      <div className="general-form__row-3-3-3-3">
        <FormInput
          id="farm-billing-date"
          inputMode="numeric"
          helperText={validationErrors["billingDate"]}
          label={"FARM_FIELD_BILLING_DATE"}
          state={[stateBill[0], setValidBillingDate]}
        />

        <FormInput
          id="farm-water-billing-date"
          inputMode="numeric"
          helperText={validationErrors["waterBillingDate"]}
          label={"FARM_FIELD_WATER_BILLING_DATE"}
          state={[stateWaterBill[0], setValidWaterBillingDate]}
        />

        <TimezoneSelectorForm
          rawTimezone={rawTimezone}
          setTimezone={setTimezone}
        />
      </div>

      <div className="general-form__row-6-6" style={{ marginTop: "10px" }}>
        <FormInputPosition
          setError={setFarmCoordsError}
          id="farm-config-position"
          markerUrl={markerButton()}
          label={
            stateCentralLinked[0]
              ? "PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL"
              : "FARM_LOCATION"
          }
          lat={[stateLat[0], setValidLat]}
          lng={[stateLng[0], setValidLng]}
          recoilSelector={centerMarkerSelector}
        />
      </div>

      <div className="edit-farm-form__map">
        <DraggableMap
          zoomScroll={false}
          _setExperimentalRecoil
          markers={[
            {
              lat: {
                state: stateLat[0],
                setFunction: setValidLat,
              },
              lng: {
                state: stateLng[0],
                setFunction: setValidLng,
              },
              markerUrl: blueDot,
              key: "center",
              event: {
                state: event,
                setFunction: setEvent,
              },
            },
          ]}
        />

        {showQrReader && (
          <div className="qr-reader-root">
            <div
              className="close-button"
              onClick={() => setShowQrReader(false)}
            >
              X
            </div>

            <QrReader
              delay={300}
              facingMode={"environment"}
              onError={(data) => globalReject && globalReject(data)}
              onScan={(data) => globalResolve && data && globalResolve(data)}
              style={{ width: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import { useDispatch, useSelector } from "react-redux";
import { setFarms } from "../../redux/farms/actions";
import checkExists from "../../utils/checks/checkExists";
import { ApplicationState } from "../../redux";
import { Farm } from "../../redux/farms/types";
import { useLocation, useMatch, useNavigate } from "react-router";

const useFarms = (): [Farm[], () => Promise<void>, boolean] => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const farms = useSelector((state: ApplicationState) => state.farms.list);

  // Obtendo farmID pela URL fora do router
  const { pathname } = useLocation();
  const params: any = useMatch(pathname.replace(/([0-9]+)/, ":farm")).params;
  const farmID = parseInt(params?.farm);

  async function getFarms() {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.get("v3/farms/");
        if (checkExists(["data", response, "__cascade"])) {
          dispatch(setFarms(response.data));
          if (!response.data.length) {
            navigate("/farms/none");
          }
        }
      } catch (err) {
      } finally {
        setLoading(true);
      }
    });
  }

  useEffect(() => {
    getFarms();
    // eslint-disable-next-line
  }, []);

  return [farms, getFarms, loading];
};

export default useFarms;

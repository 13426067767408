import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import styled from "@emotion/styled";
import { isMobile } from "../../../../../../../../mobileConfig";
import {
  pivotActionDirection,
  pivotActionDirectionV5,
} from "../../../HistoricBox/utils/utils";
import {
  getPanelStreamStatus,
  getIrrigationModeV5,
  parseHourmeter,
} from "../../../../../../../../utils/models/pivots";

import { Skeleton } from "@material-ui/lab";
import { i18n, i18nTextId } from "../../../../../../../../i18n/i18nText";
import {
  labelDisplayedRows,
  TablePaginationActions,
} from "../../../../../../../../components/GeneralTable/GeneralTable";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

function createData(
  created: any,
  angle: string | number,
  position: string,
  clockwise: any,
  speed: any,
  reason: any,
  end_tower_pressure: string,
  hourmeter: any,
  center_pressure?: string
) {
  return {
    created,
    angle,
    position,
    clockwise,
    speed,
    reason,
    center_pressure,
    end_tower_pressure,
    hourmeter,
  };
}

const headCellsStandard = [
  {
    id: "created",
    label: i18n("GPS_STREAM_TABLE_START"),
  },
  {
    id: "angle",
    label: i18n("GPS_STREAM_TABLE_ANGLE"),
  },
  {
    id: "position",
    label: i18n("GPS_STREAM_TABLE_POSITION"),
  },
  {
    id: "clockwise",
    label: i18n("GPS_STREAM_TABLE_DIRECTION"),
  },
  {
    id: "speed",
    label: i18n("GPS_STREAM_TABLE_SPEED"),
  },
  {
    id: "reason",
    label: i18n("GPS_STREAM_TABLE_STATUS"),
  },
  {
    id: "end_tower_pressure",
    label: i18n("GPS_STREAM_TABLE_WATER_PRESSURE"),
  },
  {
    id: "hourmeter",
    label: i18n("GPS_STREAM_TABLE_HOURMETER"),
  },
];

const headCellsCenterPressure = [
  {
    id: "created",
    label: i18n("GPS_STREAM_TABLE_START"),
  },
  {
    id: "angle",
    label: i18n("GPS_STREAM_TABLE_ANGLE"),
  },
  {
    id: "position",
    label: i18n("GPS_STREAM_TABLE_POSITION"),
  },
  {
    id: "clockwise",
    label: i18n("GPS_STREAM_TABLE_DIRECTION"),
  },
  {
    id: "speed",
    label: i18n("GPS_STREAM_TABLE_SPEED"),
  },
  {
    id: "reason",
    label: i18n("GPS_STREAM_TABLE_STATUS"),
  },
  {
    id: "center_pressure",
    label: i18n("GPS_STREAM_TABLE_CENTER_PRESSURE"),
  },
  {
    id: "end_tower_pressure",
    label: i18n("GPS_STREAM_TABLE_WATER_PRESSURE"),
  },
  {
    id: "hourmeter",
    label: i18n("GPS_STREAM_TABLE_HOURMETER"),
  },
];

function EnhancedTableHead(props: {
  pivotProtocol: number;
  pivotConfig: { content: { pressure_config: { read_pressure_by: number } } };
}) {
  const headCells =
    props.pivotProtocol === 5 &&
    props.pivotConfig.content.pressure_config?.read_pressure_by === 2
      ? headCellsCenterPressure
      : headCellsStandard;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledHeadTableCell
            style={{
              backgroundColor: "rgba(235, 235, 235, 0.6)",
              marginRight: index === 5 ? "10px" : null,
            }}
            key={headCell.id}
            align={"center"}
            padding={"default"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </StyledHeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: isMobile() ? "95vw" : "100%",
    overflowX: isMobile() ? "scroll" : "hidden",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    height: "100%",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

interface Props {
  pivotProtocol: number;
  pivotID: number;
  pivotConfig: any;
  startDate: any;
  endDate: any;
}

const StyledHeadTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 3px;
  width: 200px;
  height: 35px;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 0px 0px 0px 5px;
  width: 200px;
  height: 35px;
  background-color: white;
`;

function GpsStreamTable(props: Props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = useState(0);
  const [gpsStreamList, setgpsStreamList] = useState<any>(undefined);

  const rows = useMemo(() => {
    if (gpsStreamList === undefined) return; // Historic isn't loaded yet.

    return gpsStreamList.map((item) => {
      if (props.pivotProtocol === 4.1)
        return createData(
          item.created,
          item.angle,
          item.position,
          item.clockwise,
          item.speed,
          item.reason,
          formatFloatValue(item.end_tower_pressure),
          item.hourmeter
        );
      else if (props.pivotProtocol === 5)
        return createData(
          item?.created,
          item?.content?.current_angle?.current_angle
            ? formatFloatValue(item?.content?.current_angle?.current_angle, 1)
            : 0,
          `${item?.content?.latitude_longitude_gps?.latitude_gps},${item?.content?.latitude_longitude_gps?.longitude_gps}`,
          item?.content?.current_irrigation_information?.direction, // 0 parado - 1 avanço - 2 reverso
          item?.content?.current_irrigation_information?.irrigation_percent,
          item?.content?.current_irrigation_information?.mode, //
          formatFloatValue(
            item?.content?.end_tower_pressure?.end_tower_pressure
          ),
          item?.content?.operation_time?.total_minute +
            item?.content?.operation_time?.total_hour * 60,
          props.pivotConfig.content.pressure_config?.read_pressure_by === 2
            ? formatFloatValue(item?.content?.center_pressure?.center_pressure)
            : null
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsStreamList]);

  const handleChangePage = (event, newPage) => {
    setgpsStreamList(undefined);
    getgpsStreamList(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    if (props.startDate && props.endDate) {
      getgpsStreamList();
    }
  }, [props.startDate, props.endDate]);

  async function getgpsStreamList(page = 0) {
    var startDate;
    var endDate;
    if (props.startDate === null || props.endDate === null) {
      startDate = moment().subtract(1, "month");
      endDate = moment();
    } else {
      startDate = props.startDate;
      endDate = props.endDate;
    }
    await new Promise(async (resolve) => {
      await coreHTTPClient
        // .post(`v3/reports/pivots/${props.pivot.id}/`, body)
        .get(
          `v3/reports/pivots/${props.pivotID}/list_gps_streams${
            props.pivotProtocol === 5 ? "_v5" : ""
          }/?date_start=${startDate.toISOString()}&&date_end=${endDate.toISOString()}&&page=${
            page + 1
          }`
        )
        .then((res) => {
          setgpsStreamList(res.data.results);
          setCount(res.data.count);
        })
        .catch((err) => {});
    });
  }

  const emptyRows = new Array(8).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        {new Array(8).fill(0).map((_, index) => {
          return (
            <StyledTableCell key={`col_${index}`}>
              <Skeleton height={43} />
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  });

  const StatusDiv = styled.div`
    font-weight: 600;
    font-size: 0.7rem;
    margin: 4px 0px 4px 8px;
    padding: 4px 8px;
    border-radius: 8px;
    margin-left: 5px;
    width: calc(100% - 5px);
    color: white;
  `;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              pivotProtocol={props.pivotProtocol}
              pivotConfig={props.pivotConfig}
            />
            <TableBody>
              {rows !== undefined
                ? rows.map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <StyledTableCell align="center">
                          {moment(row.created).format("DD/MM/YY HH:mm")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {`${row.angle}°`}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <a
                            target="__blank"
                            href={`http://maps.google.com/maps?t=k&q=${
                              row.position.split(",")[0]
                            },${row.position.split(",")[1]}`}
                          >
                            {` ${row.position.split(",")[0]},${
                              row.position.split(",")[1]
                            }`}
                          </a>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {props.pivotProtocol === 4.1
                            ? pivotActionDirection(row.clockwise)
                            : pivotActionDirectionV5(row.clockwise)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {`${row.speed}%`}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <StatusDiv
                            style={{
                              backgroundColor:
                                props.pivotProtocol === 4.1
                                  ? getPanelStreamStatus(row.reason).color
                                  : getIrrigationModeV5(row.reason).color,
                            }}
                          >
                            {props.pivotProtocol === 4.1
                              ? getPanelStreamStatus(
                                  row.reason
                                ).text.toUpperCase()
                              : getIrrigationModeV5(row.reason).text}
                          </StatusDiv>
                        </StyledTableCell>
                        {props.pivotProtocol === 5 &&
                          props.pivotConfig.content.pressure_config
                            ?.read_pressure_by === 2 && (
                            <StyledTableCell align="center">
                              {`${row.center_pressure} bar`}
                            </StyledTableCell>
                          )}
                        <StyledTableCell align="center">
                          {`${row.end_tower_pressure} bar`}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {`${parseHourmeter(row.hourmeter)}`}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                : emptyRows}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}

export default React.memo(GpsStreamTable);

import { atom } from "recoil";

export interface Repeater {
  id: number;
  type: string;
  name: string;
  position: string;
  created: string;
  updated: string;
  energy_type: string;
  farm: number;
  base: number;
  repeater: number;
  base_radio_id: string;
  repeater_radio_id: string;
}

export const RepeaterState = atom<Repeater | null>({
  key: "RepeaterState",
  default: null,
});

export const RepeaterStateList = atom<Repeater[] | null>({
  key: "RepeaterStateLIst",
  default: [],
});

import React from "react";
import MomentUtils from "@date-io/moment";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { EventNote } from "@material-ui/icons";
import {
  DatePicker,
  DatePickerView,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import FormWeekPicker from "../../../../components/Form/FormWeekPicker/FormWeekPicker";
import { i18n } from "../../../../i18n/i18nText";

interface Props {
  chartPrecision?: String;
  tempStartDate?: moment.Moment;
  tempEndDate: moment.Moment;
  setTempStartDate?: (value: moment.Moment) => void;
  setTempEndDate: (value: moment.Moment) => void;
  setStartDate?: (value: moment.Moment) => void;
  setEndDate?: (value: moment.Moment) => void;
}

function GeneralChartDateSelect(props: Props) {
  const {
    chartPrecision,
    tempStartDate,
    tempEndDate,
    setTempStartDate,
    setTempEndDate,
    setStartDate,
    setEndDate,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function exitOpenTo(): DatePickerView {
    if (chartPrecision == "30") {
      return "year";
    }
    return "date";
  }

  function exitViews(): DatePickerView[] {
    if (chartPrecision == "30") {
      return ["year", "month"];
    }
    return ["date"];
  }

  function exitFormat(): string {
    if (chartPrecision == "30") {
      return "MM/YYYY";
    }
    return "L";
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleFilterSave() {
    if (setStartDate) setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    handleClose();
  }

  function startDatePickerSelector(): JSX.Element {
    if (chartPrecision == "7") {
      return (
        <FormWeekPicker
          id="Weekpicker"
          state={[tempStartDate, setTempStartDate]}
          newLabel="PIVOT_CONFIG_V5_START"
          startSelector
          maxDate={tempEndDate}
        />
      );
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          id="startChartDatePicker"
          label={i18n("PIVOT_CONFIG_V5_START")}
          openTo={exitOpenTo()}
          views={exitViews()}
          format={exitFormat()}
          value={tempStartDate}
          onChange={(value) => {
            if (value != null) {
              setTempStartDate(value);
            }
          }}
          maxDate={tempEndDate}
        />
      </MuiPickersUtilsProvider>
    );
  }

  function endDatePickerSelector(): JSX.Element {
    if (chartPrecision == "7") {
      return (
        <FormWeekPicker
          id="Weekpicker"
          state={[tempEndDate, setTempEndDate]}
          newLabel="PIVOT_CONFIG_V5_END"
          endSelector
          minDate={tempStartDate}
          maxDate={moment().endOf("week")}
        />
      );
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          id="endChartDatePicker"
          label={i18n("PIVOT_CONFIG_V5_END")}
          openTo={exitOpenTo()}
          views={exitViews()}
          format={exitFormat()}
          value={tempEndDate}
          onChange={(value) => {
            if (value != null) {
              setTempEndDate(value);
            }
          }}
          minDate={tempStartDate}
          maxDate={chartPrecision == "30" ? moment().endOf("month") : moment()}
        />
      </MuiPickersUtilsProvider>
    );
  }

  return (
    <div>
      <div className="filter-by-date">
        <IconButton aria-label="delete" onClick={handleClick} size="small">
          <EventNote fontSize="small" />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {setStartDate ? (
            <MenuItem>{startDatePickerSelector()}</MenuItem>
          ) : null}

          <MenuItem>{endDatePickerSelector()}</MenuItem>

          <MenuItem>
            <div className="flex-btn-container">
              <Button
                variant="contained"
                style={{ background: "#0066FF", color: "white" }}
                onClick={() => {
                  handleFilterSave();
                }}
              >
                <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
              </Button>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default GeneralChartDateSelect;

import React, { useContext, useEffect } from "react";
import "./MobileNav.scss";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { DrawerContext } from "../DrawerProvider/DrawerProvider";
import { Plugins } from "@capacitor/core";
import MobileZone from "../../../../components/Zones/MobileZone";
import EditIcon from "@material-ui/icons/Edit";
import { isMobile } from "../../../../mobileConfig";
import { createStyles, Theme, withStyles, InputBase } from "@material-ui/core";

import routes from "../../../../routes/routes";
import { useNavigate, useLocation } from "react-router";
import { hideModal } from "../../../../redux/modal/actions";
import Store, { ApplicationState } from "../../../../redux/index";
import styled from "@emotion/styled";
import { getDeviceNotch } from "../../../../utils/DeviceNotchCheck";
import { useRecoilValue } from "recoil";
import { useDrawer } from "../../../../recoils/DrawerRecoil";
import { NavbarState } from "../../../../recoils/NavbarRecoil";
import { i18n } from "../../../../i18n/i18nText";
import { StatusBar } from "@capacitor/status-bar";

export interface StatusBarBackgroundColorOptions {
  color: string;
}

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      color: "white",
      "& option": {
        color: "black",
        background: theme.palette.background.paper,
      },
      height: "1rem",
      borderRadius: 4,
      position: "relative",
      width: 100,
      border: "1px solid #ced4da",
      fontSize: 15,
      padding: "6px 18px 6px 8px !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        background: "primary",
      },
    },
  })
)(InputBase);

export const NotifContainer = styled.div`
  width: ${(props: { isPopover?: boolean }) =>
    props.isPopover ? "300px" : "100%"};
  height: 500px;
`;

function MobileNav() {
  const location = useLocation();
  const farms = Store().store.getState().farms.list;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useContext(DrawerContext);
  const drawer = useDrawer();
  const navbar = useRecoilValue(NavbarState);

  useEffect(() => {
    if (isMobile()) {
      if (ctx.visible) {
        StatusBar.setBackgroundColor({ color: "#2f4050" });
      } else if (location.pathname.includes("/pivots")) {
        //SelectedPivot specific color
        StatusBar.setBackgroundColor({ color: "#000000" });
      } else {
        StatusBar.setBackgroundColor({ color: "#44b86a" });
      }
    }
  }, [ctx.visible, location]);

  const selectedFarm: any = useSelector(
    (state: ApplicationState) => state.farm
  );

  var modalActive: boolean = useSelector(
    (state: ApplicationState) => state.modal.active
  );

  useEffect(() => {
    if (modalActive) {
      window.history.pushState(null, null, window.location.href);
    }
  }, [modalActive]);

  useEffect(() => {
    window.onpopstate = function (event) {
      dispatch(hideModal());
    };
  }, []);

  const mobileActionButtons = () => {
    if (
      location.pathname.includes("/notifications") ||
      location.pathname.includes("/devices") ||
      location.pathname.includes("/create-farm") ||
      location.pathname.includes("/me") ||
      location.pathname.includes("/edit")
    ) {
      return null;
    }

    return (
      <>
        {farms.length > 0 && selectedFarm?.data?.is_administrator && (
          <IconButton
            onClick={() => {
              navigate(
                routes.DASHBOARD.EDIT_FARM.replace(
                  ":farm",
                  String(selectedFarm.data.id)
                )
              );
            }}
          >
            <EditIcon style={{ color: "#fff" }} />
          </IconButton>
        )}
      </>
    );
  };

  const handleNavbarTitle = (title) => {
    if (title && title.length > 24) {
      return title.substring(0, 24) + "...";
    } else if (title && title.length <= 24) return title;
    else return "";
  };

  if (
    !isMobile() ||
    (isMobile() &&
      (location.pathname.includes("/pivots") ||
        location.pathname.includes("/irpds") ||
        location.pathname.includes("/bauer-pivot") ||
        location.pathname.includes("/metersystem")) &&
      !location.pathname.includes("/edit"))
  ) {
    return null;
  }

  function MobileHeader() {
    if (location.pathname.includes("/notifications")) {
      return (
        <>
          <IconButton onClick={() => drawer.toggleDrawer()}>
            <MenuIcon style={{ color: "#FFF" }} />
          </IconButton>
          <div className="nav__mobile-farm-title">
            <h3 className={`${getDeviceNotch() != "none" ? "notch" : ""}`}>
              {i18n("NOTIFICATIONS_DRAWER_TITLE")}
            </h3>
          </div>
        </>
      );
    } else if (location.pathname.includes("/create-farm")) {
      return (
        <>
          <IconButton onClick={() => drawer.toggleDrawer()}>
            <MenuIcon style={{ color: "#FFF" }} />
          </IconButton>
          <div className="nav__mobile-farm-title">
            <h3 className={`${getDeviceNotch() != "none" ? "notch" : ""}`}>
              {i18n("CREATE_FARM_LABEL")}
            </h3>
          </div>
        </>
      );
    } else {
      return (
        <>
          <IconButton onClick={() => drawer.toggleDrawer()}>
            <MenuIcon style={{ color: "#FFF" }} />
          </IconButton>
          <div className="nav__mobile-farm-title">
            <h3 className={`${getDeviceNotch() != "none" ? "notch" : ""}`}>
              {handleNavbarTitle(navbar.title)}
            </h3>
          </div>
          {selectedFarm.data.id !== undefined ? mobileActionButtons() : null}
        </>
      );
    }
  }

  return (
    <div
      className={`nav nav__notch-${getDeviceNotch()}`}
      style={
        process.env.IS_DEV
          ? { zIndex: 995, backgroundColor: "#037ade" }
          : { zIndex: 995 }
      }
    >
      <MobileZone>
        <MobileHeader />
      </MobileZone>
    </div>
  );
}

export default MobileNav;

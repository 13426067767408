import React from "react";
import "./RemoveUser.scss";
import { Button, Modal, makeStyles, createMuiTheme } from "@material-ui/core";
import styled from "@emotion/styled";
import { motion, AnimatePresence } from "framer-motion";
import { i18n, i18nTextId } from "../../../../../../../../../../i18n/i18nText";

const useStyles = makeStyles(() => ({
  button: {
    width: "100px",
  },
}));

export const modal = createMuiTheme({
  palette: {
    primary: {
      main: "##9B9B9B;",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF0000",
      contrastText: "#fff",
    },
  },
});

function getModalStyle() {
  return {
    top: `${50}%`,
    margin: "auto",
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const StyledModal = styled(Modal)`
  border: none;
`;

const AbsoluteDiv = styled(motion.div)`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;

  background-color: #00000077;

  z-index: 99999;

  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonAccept = styled(Button)`
  background-color: #ff0000 !important;
  color: #fff !important;
`;

const StyledButtonCancel = styled(Button)`
  background-color: #9b9b9b !important;
  color: #fff !important;
`;

const ModalBox = styled(motion.div)`
  padding: 30px;

  background-color: white;

  display: grid;

  grid-template-areas:
    "Text    Text"
    "Cancel  Accept";

  grid-row-gap: 30px;
  grid-column-gap: 20px;
`;

const TextArea = styled.div`
  grid-area: Text;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelArea = styled.div`
  grid-area: Cancel;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const AcceptArea = styled.div`
  grid-area: Accept;

  display: flex;
  justify-content: center;
  align-items: center;
`;

//

interface Props {
  openModal: boolean;
  setOpenModal: any;
  deleteUser: any;
  text: string;
  name: string;
}
const RemoveUser = (props: Props) => {
  let { openModal, setOpenModal, deleteUser, text, name } = props;

  return (
    <AnimatePresence>
      {openModal && (
        <AbsoluteDiv
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalBox initial={{ y: 1000 }} animate={{ y: 0 }} exit={{ y: 1000 }}>
            <TextArea>{i18n(text as i18nTextId, [name])}</TextArea>
            <CancelArea>
              <StyledButtonCancel
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <>{i18n("EDIT_FARM_BUTTON_CANCEL")}</>
              </StyledButtonCancel>
            </CancelArea>
            <AcceptArea>
              <StyledButtonAccept
                onClick={() => {
                  deleteUser();
                  setOpenModal(false);
                }}
              >
                <>{i18n("EDIT_FARM_BUTTON_DETELE")}</>
              </StyledButtonAccept>
            </AcceptArea>
          </ModalBox>
        </AbsoluteDiv>
      )}
    </AnimatePresence>
  );
};

export default RemoveUser;

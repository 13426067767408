import { i18nTextId } from "./i18nText";

const i18n_ru: { [id in i18nTextId]: string } = {
  PAGE_REGISTER_TITLE: "Зарегистрироваться",
  PAGE_REGISTER_LABEL_LANGUAGE: "Язык",
  PAGE_REGISTER_LABEL_EMAIL: "Электронная почта",
  PAGE_REGISTER_LABEL_USERNAME: "Пользователь",
  PAGE_REGISTER_LABEL_PASSWORD: "Пароль",
  PAGE_REGISTER_LABEL_CONFIRM_PASSWORD: "Подтвердить пароль",
  PAGE_REGISTER_LABEL_FIRST_NAME: "Имя",
  PAGE_REGISTER_LABEL_LAST_NAME: "Фамилия",
  PAGE_REGISTER_SUBMIT_BUTTON_TEXT: "Зарегистрироваться",
  PAGE_REGISTER_USERNAME_IN_USE: "Имя пользователя недоступно",
  PAGE_REGISTER_TOKEN_ERROR: "Срок действия токена истек или недействителен",
  PAGE_REGISTER_PASSWORD_UNMATCH: "Пароль и подтверждение пароля не совпадают",
  VALIDATION_FIELD_REQUIRED: "Обязательное поле",
  VALIDATION_FIELD_INVALID: "Недопустимое поле",
  VALIDATION_FIELD_MIN: "Минимум ${} цифр",
  VALIDATION_FIELD_MAX: "Максимум ${} цифр",
  VALIDATION_STRONGER_PASSWORD: "Недостаточно сильный",
  SELECTED_FARM_MAP_LABEL: "Карта",
  SELECTED_FARM_EDIT_BUTTON_TEXT: "Изменить",
  SELECTED_FARM_FILTER_BUTTON_TEXT: "Фильтр",
  SELECTED_FARM_FILTER_NAME: "Имя",
  SELECTED_FARM_FILTER_PROBLEM: "Проблема",
  SELECTED_FARM_PIVOTS: "Стержни",
  SELECTED_FARM_IRPDS: "Насосы",
  SELECTED_FARM_SWEEP_BUTTON_TEXT: "Очистка",
  SELECTED_FARM_SWEEP_EXECUTING_TEXT: "Выполнение очистки...",
  SELECTED_FARM_SWEEP_END_TEXT: "Очистка завершена!",
  SELECTED_FARM_SWEEP_GENERIC_ERROR: "Ошибка очистки устройства",
  DEVICE_DRAWER_TITLE: "Устройства",
  DEVICE_BOX_VERY_STRONG: "Очень сильный",
  DEVICE_BOX_STRONG: "Сильный",
  DEVICE_BOX_MODERATE: "Умеренный",
  DEVICE_BOX_WEAK: "Слабый",
  DEVICE_BOX_ERROR: "Ошибка",
  DEVICE_NO_LOGS: "Выполнить очистку для просмотра записей журнала.",
  EDIT_FARM_LABEL: "Редактировать Ферму",
  EDIT_FARM_GENERAL_INFORMATION: "Общая Информация",
  EDIT_FARM_GENERAL_CONTACT: "Контакт",
  EDIT_FARM_ENERGY_RANGES: "Диапазоны Мощности",
  EDIT_FARM_ENERGY_RANGES_GENERATED: "Созданное автоматически оставшееся время",
  EDIT_FARM_ENERGY_RANGES_ADD_BUTTON: "Добавить",
  EDIT_FARM_ENERGY_RANGES_REMOVE_BUTTON: "Удалить",
  EDIT_FARM_USERS: "Пользователи",
  EDIT_FARM_USER: "Пользователь",
  EDIT_FARM_VISITORS: "ПОСЕТИТЕЛИ",
  EDIT_FARM_OPERATORS: "Операторы",
  EDIT_FARM_CODE: "Код Фермы: ",
  EDIT_FARM_EMPLOYEES: "Пользователи",
  EDIT_FARM_RESALE: "Перепродажа",
  EDIT_FARM_APPROVE: "Утвердить",
  EDIT_FARM_PERMISSIONS: "Разрешения ",
  EDIT_FARM_VIEW: "Просмотр ",
  EDIT_FARM_SEND_INVITE_AGAIN: "Отправить приглашение еще раз",
  EDIT_FARM_RESEND_INVITATION_SUCCESS: "Приглашение отправлено.",
  EDIT_FARM_RESEND_INVITATION_FAILED:
    "Не удалось повторно отправить приглашение. Повторите попытку позже.",
  EDIT_FARM_REMOVE: "Удалить ",
  EDIT_FARM_REMOVE_INFO: "Вы хотите удалить пользователя ${}?",
  EDIT_FARM_REMOVE_REPEATER_INFO: "Вы хотите удалить ретранслятор ${}?",
  EDIT_FARM_BUTTON_CANCEL: "Отменить",
  EDIT_FARM_BUTTON_DETELE: "Удалить",
  EDIT_FARM_TITLE_PERMISSIONS: "Редактировать разрешения",
  EDIT_FARM_TAG_VIEW: "Просмотр",
  EDIT_FARM_TAG_OPERATE: "Работать",
  EDIT_FARM_BUTTON_SAVE: "Сохранить",
  EDIT_FARM_GROUPS: "Группы",
  EDIT_FARM_GROUPS_INFO:
    "Настроить группы стержней для получения статистики и отчетов по группам.",
  EDIT_FARM_BUTTON_CREATE_GROUP: "Создать группу стержней",
  EDIT_FARM_BUTTON_ADD_POWER_RANGE: "Добавить Диапазоны Мощности",
  SELECTED_FARM_DELETE_BUTTON_TEXT: "Удалить",
  EDIT_FARM_ADMINISTRATOR: "Администратор",
  EDIT_FARM_TEMPORARY_ADMINISTRATOR: "Временный администратор:",
  EDIT_FARM_REGISTER_USER:
    "Введите имя, имя пользователя или адрес электронной почты пользователя, которого нужно пригласить.",
  EDIT_FARM_BUTTON_REGISTER_USER: "Зарегистрировать пользователя",
  EDIT_FARM_BUTTON_EDIT: "Изменить",
  EDIT_FARM_NAME_GROUP: "Название группы",
  EDIT_FARM_REPEATERS: "Ретрансляторы",
  EDIT_FARM_BUTTON_REGISTER_REPEATERS: "Зарегистрировать ретрансляторы",
  EDIT_FARM_USER_PENDING: "Ожидает рассмотрения",
  EDIT_FARM_GROUP_NAME: "Название группы",
  EDIT_FARM_EDIT_REPEATER: "Изменить Ретранслятор",
  EDIT_FARM_USER_DELETE_SUCCESS: "Пользователь удален",
  EDIT_FARM_USER_PERMISSION_SUCCESS: "Разрешения успешно сохранены",
  EDIT_FARM_GROUP_SUCCESS: "Группа создана",
  EDIT_FARM_EDIT_GROUP_SUCCESS: "Группа отредактирована",
  EDIT_FARM_EDIT_GROUP_REMOVED: "Группа удалена",
  EDIT_FARM_GROUP_NAME_ERROR: "Имя группы не заполнено",
  EDIT_FARM_GROUP_PIVOT_ERROR: "Стержни не выбраны",
  EDIT_FARM_REPEATER_SUCCESS: "Зарегистрированный ретранслятор",
  EDIT_FARM_REPEATER_ERROR: "Ошибка регистрации ретранслятора",
  EDIT_FARM_REPEATER_DELETE_SUCCESS: "Ретранслятор удален",
  EDIT_FARM_EDIT_REPEATER_SUCCESS: "Ретранслятор отредактирован",
  EDIT_FARM_REPEATER_NAME_ERROR: "Имя ретранслятора не заполнено",
  EDIT_FARM_TIMEZONE: "Часовой пояс",
  EDIT_FARM_NO_REGISTERED_USERS: "У вас нет зарегистрированных пользователей.",
  EDIT_FARM_NO_REGISTERED_GROUPS: "У вас нет зарегистрированных групп.",
  EDIT_FARM_NO_REGISTERED_REPEATERS:
    "У вас нет зарегистрированных ретрансляторов.",
  HISTORIC_SCHEDULE_IRRIGATION_INDEX: "Запланировать ${}:",
  HISTORIC_SCHEDULE_IRRIGATION_MODE: "Режим: ",
  HISTORIC_SCHEDULE_IRRIGATION_PERCENT: "Процент: ",
  HISTORIC_SCHEDULE_IRRIGATION_DIRECTION: "Направление: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_DATE: "Дата Начала: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_DATE: "Дата Окончания: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS: "Закончить через: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE: "Конечный угол: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR: "Закончится в конце сектора",
  HISTORIC_SCHEDULE_IRRIGATION_END_MODE: "Конечный режим: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_END: "Начало - Конец: ${} - ${}",
  FORGET_PASSWORD_OPTION: "Выберите вариант",
  FORGET_PASSWORD_OPTION_USER:
    "Я забыл свое имя пользователя или адрес электронной почты",
  ORGET_PASSWORD_OPTION_PASSWORD: "Я забыл свой пароль",
  FORGET_PASSWORD_TYPE_EMAIL: "Введите адрес электронной почты",
  FORGET_PASSWORD_INFO_USER:
    "Обратитесь к администратору фермы или к местному дилеру.",
  FORGET_PASSWORD_INFO_EMAIL:
    "Электронное письмо было отправлено на адрес:\n\n${}\n\nСледуйте инструкциям в электронном письме и восстановите свой пароль.",
  FORGET_PASSWORD_RECOVEY: "Введите новый пароль.",
  FORGET_PASSWORD_NEW_PASSWORD: "Новый пароль",
  FORGET_PASSWORD_REPEAT_NEW_PASSWORD: "Повторите новый пароль",
  FORGET_PASSWORD_SAVE: "Сохранить пароль",
  FORGET_PASSWORD_OK: "ОК",
  FORGET_PASSWORD_MODAL_SUCCESS: "Пароль успешно изменен!",
  CHANGE_PASSWORD_ERROR:
    "Ошибка смены пароля, возможно, это не ваш текущий пароль",
  FORGET_PASSWORD_DIFFERENT: "Новый пароль отличается от пароля подтверждения",
  FORGET_PASSWORD_MODAL_EMAIL_FAILED:
    "На этот адрес электронной почты нет регистрации",
  EDIT_PIVOT_LABEL: "Редактировать Стержень",
  EDIT_MONITOR_LABEL: "Edit Pivot Monitor",
  EDIT_PIVOT_LATITUDE: "Широта",
  EDIT_PIVOT_LONGITUDE: "Долгота",
  EDIT_PIVOT_SAVE_BUTTON: "Сохранить",
  EDIT_PIVOT_LOAD_BUTTON: "Загрузить",
  EDIT_PIVOT_LOAD_SUCCESS: "Конфигурация успешно загружена!",
  EDIT_PIVOT_LOAD_ERROR: "Ошибка загрузки конфигурации.",
  EDIT_PIVOT_LABEL_GENERAL: "Общие",
  EDIT_PIVOT_LABEL_LOCATION: "Местоположение",
  EDIT_PIVOT_IMANAGE_RADIO: "Радио iManage",
  EDIT_PIVOT_SENSOR: "Датчики",
  EDIT_PIVOT_LABEL_CLOCK: "Часы",
  EDIT_PIVOT_LABEL_PUMP: "Давление",
  EDIT_IRPD_LABEL_LINKED_METER: "Связанный счётчик",
  EDIT_METER_LABEL_LINKED_IRPD: "Связанные IRPD",
  EDIT_METER_SUBTITLE_LINK_IRPD:
    "Свяжите до 10 устройств IRPD с этим устройством Imeter",
  EDIT_PIVOT_LABEL_PAUSE_TIME: "Время Паузы",
  EDIT_PIVOT_LABEL_PLUVIOMETER: "Датчик Дождя",
  EDIT_PIVOT_LABEL_END_GUN: "Пушечный Ороситель",
  EDIT_PIVOT_LABEL_GENERAL_SETTINGS: "Общие Настройки",
  EDIT_PIVOT_LABEL_PAUSE_TIME_SETTINGS: "Настройки Времени Паузы",
  EDIT_PIVOT_LABEL_SETTING_HISTORIC: "История Конфигураций",
  HISTORIC_SIMPLE_IRRIGATION_MODE: "Режим: ",
  HISTORIC_SIMPLE_IRRIGATION_DIRECTION: "Направление: ",
  HISTORIC_SIMPLE_IRRIGATION_PERCENT: "Осадки: ",
  HISTORIC_SIMPLE_IRRIGATION_START_MODE: "Режим Запуска: ${}",
  HISTORIC_SIMPLE_IRRIGATION_STOP_MODE: "Режим Остановки: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE: "Конечный Угол: ",
  HISTORIC_SIMPLE_IRRIGATION_START_DATE: "Дата Начала: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS: "Обороты Остановки: ",
  HISTORIC_SIMPLE_IRRIGATION_DONT_STOP: "Не останавливаться",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE: "Остановка по углу",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR: "Остановка по сектору",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS: "Остановка по оборотам",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON: "Вкл.",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF: "Выкл.",
  GPS_STREAM_TABLE_START: "Создано в",
  GPS_STREAM_TABLE_ANGLE: "Угол",
  GPS_STREAM_TABLE_POSITION: "Положение",
  GPS_STREAM_TABLE_DIRECTION: "Направление",
  GPS_STREAM_TABLE_SPEED: "Осадки",
  GPS_STREAM_TABLE_STATUS: "Статус",
  GPS_STREAM_TABLE_WATER_PRESSURE: "Давление - последняя башня",
  GPS_STREAM_TABLE_CENTER_PRESSURE: "Давление - центральная вышка",
  GPS_STREAM_TABLE_BATERY: "Батарея",
  GPS_STREAM_TABLE_HOURMETER: "Счетчик моточасов",
  GPS_STREAM_TABLE_TITLE: "Список Информации GPS",
  USER_HAS_NO_FARM: "У вас нет доступа ни к каким фермам.",
  SELECTED_IRPD_START: "Запустить Насос",
  SELECTED_IRPD_STOP: "Остановить Насос",
  LANGUAGE_SELECTOR_ENGLISH: "АНГЛИЙСКИЙ (США)",
  LANGUAGE_SELECTOR_SPANISH: "ИСПАНСКИЙ (ES)",
  LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL: "ПОРТУГАЛЬСКИЙ (BR)",
  LANGUAGE_SELECTOR_RUSSIAN: "РУССКИЙ (RU)",
  LANGUAGE_SELECTOR_GERMAN: "НЕМЕЦКИЙ (AT)",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER: "Центр",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE: "Ориентир Начала",
  PIVOT_CONFIG_V5_FIELD_GP_END_REFERENCE: "Ориентир Конца",
  PIVOT_CONFIG_V5_FIELD_RADIUS: "Радиус",
  PIVOT_CONFIG_V5_FIELD_FLOW: "Расход",
  PIVOT_CONFIG_V5_FIELD_DEPTH_WELL: "Шкала датчика",
  PIVOT_CONFIG_V5_FIELD_SPEED: "Конечная Скорость Башни",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_STABLE_TIME: "Стабильное Напряжение Стабильно",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_REF: "Опорное Напряжение",
  PIVOT_CONFIG_V5_FIELD_CLOCK: "Установить часы устройства вручную",
  PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK:
    "Автоматическая Установка часов устройства",
  PIVOT_CONFIG_V5_FIELD_PUMP_SOFT_START_TIME: "Время Плавного Запуска",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF: "Выкл.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH: "По реле давления",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR: "По датчику давления",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Максимальный диапазон датчика давления",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_FACTOR:
    "Коэффициент Датчика Давления",
  PIVOT_CONFIG_V5_FIELD_PERIODIC_STREAM_TIMER:
    "Периодический Таймер Потока (секунды)",
  PIVOT_CONFIG_V5_FIELD_ANGLE_RANGE: "Диапазон Углов",
  PIVOT_CONFIG_V5_PIVOT_SEGMENTS: "Сегменты",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_FORWARD: "Режим Вперёд",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_REVERSE: "Обратный Режим",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_FORWARD: "% Вперёд",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_REVERSE: "% Реверс",
  PIVOT_CONFIG_V5_FIELD_PILOT_AVERAGE_POWER: "Средняя Мощность Стержня",
  PIVOT_CONFIG_V5_FIELD_CANAL_AVERAGE_POWER: "Средняя Мощность Скважины",
  PIVOT_CONFIG_V5_FIELD_SEGMENT: "Сегмент",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH: "Английский",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE: "Португальский",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN: "Немецкий",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH: "Испанский",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN: "Русский",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE: "Нет",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET: "Мокрый",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY: "Сухой",
  PIVOT_CONFIG_V5_TITLE_ANGLE: "Угол",
  PIVOT_CONFIG_V5_FORWARD: "Вперед",
  PIVOT_CONFIG_V5_REVERSE: "Реверс",
  PIVOT_CONFIG_V5_START: "Начало",
  PIVOT_CONFIG_V5_END: "Конец",
  NOTIFICATIONS: "Уведомления",
  NO_NOTIFICATION:
    "У вас нет уведомлений.\nНажмите кнопку ниже, чтобы создать их.",
  ALERT_ANDROID_ONLY_NOTIFY: "Эта опция доступна только для Android.",
  ALERT_ITEM_TITLE: "${} Стержень(ни)",
  ALERT_ITEM_DO_ENABLE: "Включить",
  ALERT_ITEM_DO_DISABLE: "Отключить",
  ALERT_ITEM_ENABLED: "Включено",
  ALERT_ITEM_DISABLED: "Отключено",
  ALERT_ITEM_EDIT: "Изменить",
  ALERT_ITEM_DELETE: "Удалить",
  ALERT_BOX_TIP:
    "**Совет: дважды нажмите на карточку, чтобы отредактировать/удалить ее",
  SELECTED_ALERT_TIME_RANGE: "Диапазон Времени",
  SELECTED_ALERT_TIME_BETWEEN: "Между ${} и ${}",
  SELECTED_ALERT_SELECT_PIVOTS: "Выбрать Стержни",
  SELECTED_ALERT_SELECT_REASONS: "Выберите Причины",
  SELECTED_ALERT_REASONS: "Причины",
  EDIT_ALERT_ERROR_MSG: "Ошибка. Проверьте, все ли поля заполнены.",
  NEW_ALERT_CREATE_NOTIFICATION: "Создать Уведомление",
  NEW_ALERT_EDIT_SELECTED: "${} выбрано",
  NEW_ALERT_EDIT_NOTIFICATION: "Изменить Уведомление",
  NOTIFICATION: "Уведомление",
  ALARM: "Тревога",
  BASIC_INFO_BOX_COMMING_SOON: "Вскоре",
  BASIC_INFO_BOX_UNEXPECTED_STOPS: "Неожиданные Остановки",
  BASIC_INFO_BOX_LACK_OF_PRESSURE: "Низкое давление",
  BASIC_INFO_BOX_POWER_OUTAGE: "Сбой питания",
  BASIC_INFO_BOX_MISALIGNMENT: "Безопасность",
  BASIC_INFO_BOX_POWER_SURGE: "Скачок напряжения",
  BASIC_INFO_BOX_ACTIONS_TITLE: "Действия",
  BASIC_INFO_BOX_ACTIONS_BY_WEB: "Через Интернет",
  BASIC_INFO_BOX_ACTIONS_BY_PANEL: "По панели",
  BASIC_INFO_BOX_NON_EXECUTED_ACTIONS: "Не выполнено",
  BASIC_INFO_BOX_HOURS: "Часы",
  BASIC_INFO_BOX_WET: "Мокрый",
  BASIC_INFO_BOX_DRY: "Сухой",
  BASIC_INFO_BOX_TOTAL: "Итого",
  BASIC_INFO_BOX_WET_ENERGY_CONSUMPTION: "Энергопотребление в Мокром Режиме",
  BASIC_INFO_BOX_TYPE: "Тип",
  BASIC_INFO_BOX_KWH_PRICE: "Цена кВт/ч",
  BASIC_INFO_BOX_WATER_CONSUMPTION: "Расход",
  BASIC_INFO_BOX_PEAK_HOUR: "Время Паузы",
  BASIC_INFO_BOX_OUT_OF_PEAK_HOUR: "Вне Времени Паузы",
  BASIC_INFO_BOX_AT_NIGHTTIME: "Ночью",
  BASIC_INFO_BOX_AVERAGE_WATER_BLADE: "Среднее Количество Осадков",
  BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN: "Отчет создан между ${} и ${}",
  BASIC_INFO_BOX_WATER_BLADE_RADAR: "Карта Осадков",
  BASIC_INFO_BOX_WORK_HOURS: "Часы Влажной Работы",
  BASIC_INFO_BOX_TOTAL_COST: "Общая Стоимость",
  BASIC_INFO_BOX_PONTA: "Пик",
  BASIC_INFO_BOX_FORA_DE_PONTA: "Вне Пика",
  BASIC_INFO_BOX_REDUZIDO: "Ниght Time",
  BASIC_INFO_BOX_FLOW: "Поток:",
  BASIC_INFO_BOX_TOTAL_VALUE: "Итого: $",
  BASIC_INFO_BOX_TIME_RANGES: "Временные диапазоны",
  BASIC_INFO_BOX_WATER_BLADE_MM: "Водяное лезвие (мм)",
  BASIC_INFO_BOX_TOTAL_FEES: "Итого:",
  BASIC_INFO_BOX_TOTAL_VOLUME: "Общий объем:",
  BASIC_INFO_BOX_TENSION_TITLE: "Опорные Напряжения (В)",
  BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE: "Сравнение Давления (бар)",
  BASIC_INFO_BOX_TENSION_INFO:
    "Напряжения выше 700В и ниже 300В были отброшены.",
  BASIC_INFO_BOX_TENSION_INFO_MIN: "Мин",
  BASIC_INFO_BOX_TENSION_INFO_AVERAGE: "Средн",
  BASIC_INFO_BOX_TENSION_INFO_MAX: "Макс",
  BASIC_INFO_BOX_TENSION: "Напряжения (В)",
  BASIC_INFO_BOX_TENSION_DATES: "Дата",
  BASIC_INFO_BOX_PRESSURE: "Давление (бар)",
  BASIC_INFO_BOX_LATEST_PRESSURE: "Давление",
  BASIC_INFO_BOX_CURRENT_PRESSURE: "Сравнение Давление",
  BASIC_INFO_BOX_CHART: "Графики",
  BASIC_INFO_BOX_TABLE: "Таблицы",
  BASIC_INFO_BOX_NOT_AVAILABLE: "Недоступно для стержней с протоколом до 4.1.",
  BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY:
    "Чтобы просмотреть эти данные, перейдите на Страницу Редактирования Фермы и нажмите кнопку “включить“ на вкладке “Отчеты по стержням“.",
  BASIC_INFO_BOX_ENABLE_IRPD_INFOS:
    "Чтобы просмотреть эти данные, перейдите на Страницу Редактирования Фермы и нажмите кнопку “включить“ на вкладке “Отчеты по Насосам“.",
  BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED:
    "Сводка Операций рассчитывается для данных, которые уже существуют в системе, это может занять много времени",
  BASIC_INFO_BOX_GO_TO_EDIT_FARM: "Перейти к Редактированию Фермы",
  BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY:
    "Функционал доступен только в настольной версии.",
  SCHEDULE_IRRIGATION_FIELD_DIRECTION: "Направление",
  SCHEDULE_IRRIGATION_FIELD_MODE: "Режим Работы",
  SCHEDULE_IRRIGATION_FIELD_PERCENT: "Осадки",
  SCHEDULE_IRRIGATION_FIELD_PRECIP: "Осадки",
  SCHEDULE_IRRIGATION_VALUE_FORWARD: "Вперед",
  SCHEDULE_IRRIGATION_VALUE_REVERSE: "Реверс",
  SCHEDULE_IRRIGATION_VALUE_DRY: "Сухой",
  SCHEDULE_IRRIGATION_VALUE_WET: "Мокрый",
  SCHEDULE_IRRIGATION_BUTTON_TURN_ON: "Включить",
  SCHEDULE_IRRIGATION_BUTTON_ADD: "Добавить",
  SCHEDULE_IRRIGATION_BUTTON_REMOVE: "Удалить",
  SCHEDULE_IRRIGATION_DATE_START_LABEL: "Дата Начала",
  SCHEDULE_IRRIGATION_START_DATE_INVALID:
    "Вы не можете создать дату начала между уже установленными датами",
  SCHEDULE_IRRIGATION_END_DATE_INVALID:
    "Вы не можете создать дату окончания между уже установленными датами",
  GEOLOCATION_GETTER_GET_POSITION: "Получите желаемую позицию:",
  GEOLOCATION_GETTER_LATITUDE: "Широта:",
  GEOLOCATION_GETTER_LONGITUDE: "Долгота:",
  GEOLOCATION_GETTER_GET: "ПОЛУЧИТЬ ПОЗИЦИЮ",
  GEOLOCATION_GETTER_WAIT:
    "Подождите немного, чтобы получить более высокую точность GPS.",
  GEOLOCATION_GETTER_ACCURACY_LABEL: "Точность:",
  GEOLOCATION_GETTER_ACCURACY_VALUE: "${} метров",
  COUNTRY_SELECTOR_DDI: "Выберите страну",
  CREATE_USER_MODAL_TITLE: "Зарегистрировать пользователя",
  CREATE_USER_MODAL_ADMIN: "Администратор",
  CREATE_USER_MODAL_EMAIL: "Электронная почта",
  CREATE_USER_MODAL_BUTTON_SAVE: "Сохранить",
  CREATE_USER_MODAL_SUCCESS: "Приглашение отправлено.",
  CREATE_USER_MODAL_ERROR:
    "Произошла ошибка при отправке приглашения. Повторите попытку.",
  CREATE_USER_MODAL_ALREADY_REGISTERED:
    "Пользователь уже зарегистрирован на ферме.",
  CREATE_USER_MODAL_INVALID_EMAIL: "Недействительный адрес электронной почты.",
  PIVOT_CONFIG_FIELD_CENTER: "Центр",
  PIVOT_CONFIG_FIELD_REFERENCE_START: "Ориентир Начала",
  PIVOT_CONFIG_FIELD_REFERENCE_END: "Ориентир Конца",
  PIVOT_CONFIG_FIELD_RADIUS: "Радиус до последней башни",
  PIVOT_CONFIG_FIELD_TOTAL_RADIUS: "Общий Радиус Орошения",
  PIVOT_CONFIG_FIELD_FLOW: "Расход",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_STOP_MODE: "Режим остановки",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_MM_TO_STOP: "Остановить на (мм)",
  PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT: "Выкл.",
  PIVOT_CONFIG_VALUE_ENDGUN_ON: "Вкл.",
  PIVOT_CONFIG_FIELD_DATE_TIME: "Дата и время",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_STOP: "Остановить на мм",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_DECREASE: "Уменьшение количества осадков",
  SELECTED_PIVOT_SEGMENT_MODAL_LEFT_TITLE: "Настройки выбранного сегмента",
  SELECTED_PIVOT_SEGMENT_TITLE: "Выбранный сегмент",
  CLICK_ON_SEGMENT: "Щелкните на сегмент, чтобы редактировать информацию",
  SELECTED_PIVOT_SEGMENT_MODAL_RIGHT_TITLE: "Параметры орошения сегмента",
  SELECTED_PIVOT_SEGMENT_MODAL_TITLE: "Отправить Орошение Сегмента",
  SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS: "Орошение Сегмента успешно отправлено",
  SELECTED_PIVOT_SEGMENT_MODAL_FAILURE: "Не удалось отправить Орошени Сегмента",
  SEGMENT_IRRIGATION_FIELD_START_MODE: "Режим Запуска",
  SEGMENT_IRRIGATION_SHOW_CONFIGURED_SEGMENTS: "Показать Настроенные Сегменты",
  SEGMENT_IRRIGATION_FIELD_END_MODE: "Конечный Режим",
  SEGMENT_IRRIGATION_FIELD_START_DATE: "Дата Начала",
  SEGMENT_IRRIGATION_FIELD_END_DATE: "Дата Окончания",
  SEGMENT_IRRIGATION_FIELD_END_ANGLE: "Конечный Угол",
  SEGMENT_IRRIGATION_FIELD_END_ROUNDS: "Обороты",
  SEGMENT_IRRIGATION_MODE_NOW: "Сейчас",
  SEGMENT_IRRIGATION_ON_DATE: "В Дату",
  SEGMENT_IRRIGATION_NEVER_ENDS: "Не Останавливаться",
  SEGMENT_IRRIGATION_BY_ANGLE: "По Углу",
  SEGMENT_IRRIGATION_BY_DATE: "По Дате",
  SEGMENT_IRRIGATION_END_SECTOR: "Конечный Сектор",
  SEGMENT_IRRIGATION_BY_ROUNDS: "По оборотам",
  SEGMENT_IRRIGATION_ROUND_FIELD_ERROR:
    "Ошибка. Число оборотов должно быть больше нуля",
  SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE:
    "Угол остановки вне допустимого диапазона для этого стержня",
  SEGMENT_IRRIGATION_ERROR_LOADING_LATEST:
    "Невозможно загрузить последний Сегмент Орошения",
  SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS:
    "Не удалось загрузить последний полив. Сегменты различаются.",
  SELECTED_PIVOT_GENERAL: "Общие",
  SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES: "Редактировать Историю",
  SELECTED_PIVOT_HARVEST: "Урожай",
  SELECTED_PIVOT_BASIC_INFO: "Сводка Операций",
  SELECTED_PIVOT_PUMP_BOX: "Насосы",
  SELECTED_PIVOT_HISTORIC: "История",
  SELECTED_PIVOT_RECOMMENDATION: "Рекомендация",
  SELECTED_PIVOT_SETUP_IRRIGATION: "Настроить Полив",
  SELECTED_PIVOT_CHOOSE_IRRIGATION_MODE: "Выберите Режим Полива:",
  SELECTED_PIVOT_STANDARD_IRRIGATION: "Простой",
  SELECTED_PIVOT_BY_ANGLE_IRRIGATION: "По Углу",
  SELECTED_PIVOT_VRI_IRRIGATION: "Сегменты",
  SELECTED_PIVOT_SCHEDULE_IRRIGATION: "Расписание",
  SELECTED_PIVOT_STOP_ACTION: "Остановить стержень",
  SELECTED_PIVOT_EDIT_BUTTON_TEXT: "Изменить",
  SELECTED_PIVOT_BACK_BUTTON_TEXT: "Назад",
  SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON: "Сегменты",
  SELECTED_PIVOT_HISTORIC_SWITCH: "Дополнительно",
  PIVOT_CONFIG_DASHBOARD_NAME: "${}",
  PIVOT_CONFIG_REPORT_PREFERENCES: "Редактировть Сводку",
  PIVOT_REPORT_EXCEL: "Excel",
  IRPD_REPORT_CENTRAL: "Отчёт о работе",
  IRPD_CENTRAL_GH_REPORT:
    "Отчёт о работе центрального устройства создан. Скоро он будет доступен на вашем email.",
  IRPD_CENTRAL_GH_REPORT_ERROR:
    "Ошибка при запросе отчёта о работе центрального устройства. Пожалуйста, попробуйте позже.",
  PIVOT_REPORT_EXCEL_ERROR: "Нет данных для создания таблицы Excel.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL:
    "Скачивание файла Excel. Пожалуйста, подождите.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR:
    "Что-то пошло не так. Повторите попытку позже.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS:
    "Загрузка завершена. Вы можете закрыть это окно.",
  REPORT_TABLE_START_DATE: "Начало",
  REPORT_TABLE_END_DATE: "Конец",
  REPORT_TABLE_START_TOTAL: "Итого",
  REPORT_TABLE_START_WATER_BLADE: "Водяное Лезвие",
  REPORT_TABLE_START_DETAILS: "Подробности",
  HYDRIC_MANAGEMENT_CREATE_LABEL: "Насос управления водными ресурсами",
  HYDRIC_REPORT_TABLE_START_DATE: "Начало работы",
  HYDRIC_REPORT_TABLE_END_DATE: "Окончание работы",
  HYDRIC_REPORT_TABLE_DURATION: "Продолжительность работы",
  HYDRIC_REPORT_TABLE_CALC_VOLUME: "Рассчитанный объём",
  HYDRIC_REPORT_TABLE_TOTAL_VOLUME: "Объём по водомеру",
  SELECTED_PIVOT_IRRIFAST: "Выравнивание башен",
  IRRIFAST_TABLE_COL_SPEC: "Спецификация",
  IRRIFAST_TABLE_COL_VALUE: "Значение",
  IRRIFAST_TABLE_PROPS_TENSION: "Напряжение (DC)",
  IRRIFAST_TABLE_PROPS_FREQ: "Частота на выходе",
  IRRIFAST_TABLE_PROPS_CURRENT: "Ток на выходе",
  IRRIFAST_TABLE_PROPS_TENSION_SUFFIX: "В",
  IRRIFAST_TABLE_PROPS_FREQ_SUFFIX: "Гц",
  IRRIFAST_TABLE_PROPS_CURRENT_SUFFIX: "А",
  IRRIFAST_TABLE_ACTION_COLLAPSE: "Подробная информация",
  IRRIFAST_TABLE_LABEL_NONE:
    "Пожалуйста, выберите башню для просмотра подробных данных.",
  IRRIFAST_TABLE_STATUS_OFF: "Выключено",
  IRRIFAST_TABLE_STATUS_ALIGNED: "Выравнено",
  IRRIFAST_TABLE_STATUS_NOTALIGNED: "Не выравнено",

  DEVICE_BOX_ANALYZE: "Анализировать:",
  DEVICE_BOX_CONTROL: "Контроллер",
  DEVICE_BOX_GPS: "GPS",
  DEVICE_BOX_PIVOT: "Стержень",
  DEVICE_BOX_SHOW_GPS: "Показать GPS",
  DEVICE_BOX_SEARCH_RADIO: "Искать Радио",
  DEVICE_BOX_KEEP_LINES: "Сохранить Линии",
  SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS: "Расписание Полива успешно отправлено",
  SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE:
    "FailFailed to send Schedule irrigation",
  SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT: "Вы можете добавить только 7 строк.",
  BY_ANGLE_IRRIGATION_BUTTON_FORWARD: "ВПЕРЕД",
  BY_ANGLE_IRRIGATION_BUTTON_BACKWARD: "НАЗАД",
  BY_ANGLE_IRRIGATION_FIELD_PERCENTAGE: "Процент",
  BY_ANGLE_IRRIGATION_FIELD_PERCENT: "Процент",
  BY_ANGLE_IRRIGATION_FIELD_MILILITER: "Милиметр",
  BY_ANGLE_IRRIGATION_ERROR:
    "Ошибка. Полученное значение меньше ожидаемого минимума",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE: "Режим пушечного оросителя",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE: "Режим Работы",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION: "Направление",
  BY_ANGLE_IRRIGATION_FIELD_DEFAULT_ERROR:
    "Ошибка. Проверьте, все ли поля заполнены.",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_FORWARD: "Вперед",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_REVERSE: "Реверс",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON: "Вкл.",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF: "Выкл.",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY: "Сухой",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET: "Мокрый",
  BY_ANGLE_IRRIGATION_BUTTON_SEND: "Отправить Команду",
  IRRIGATION_END_DURATION: "Прогноз продолжительности операции: ",
  IRRIGATION_END_DATE_PREDICTION: "Прогноз даты окончания:",
  BY_ANGLE_IRRIGATION_BUTTON_CANCEL: "Отменить",
  ALERT_DIALOG_YES: "Да",
  ALERT_DIALOG_NO: "Нет",
  ALERT_DIALOG_CONFIRMATION: "Подтверждение",
  FIRST_STEPS: "Первые Шаги",
  FIRST_STEPS_SELECT_OPTION: "Выберите вариант",
  FIRST_STEPS_OPTION_ADM:
    "Я администратор фермы и мне нужно зарегистрировать свое оборудование",
  FIRST_STEPS_OPTION_EMPLOYEE:
    "Я работаю на ферме, и мне нужно зарегистрироваться.",
  FIRST_STEPS_OPTION_ADM_INFO:
    "Используйте свою камеру для сканирования QR-кода внутри Центра Управления или введите ниже 16-значную последовательность, соответствующую идентификатору Центра Управления.",
  FIRST_STEPS_OPTION_EMPLOYEE_INFO:
    "Спросите у администратора код фермы, чтобы продолжить регистрацию. ",
  FIRST_STEPS_FARM_CODE: "Код Фермы",
  FIRST_STEPS_CENTRAL_ID: "Идентификатор Центра Управления",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE: "Создать профиль",
  FIRST_STEPS_CREATE_PROFILE_ADM: "Создать администратора",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE_INFO:
    "Введите данные своего профиля. Ваша регистрация будет завершена после подтверждения письма с подтверждением.",
  FIRST_STEPS_CREATE_PROFILE_ADM_INFO:
    "Введите данные пользователя администратора фермы. Ваша регистрация будет завершена после подтверждения письма с подтверждением.",
  GENERAL_BOX_NO_PANEL_STREAM: "Пока обновлений нет",
  GENERAL_BOX_LAST_UPDATE: "Последнее сообщение: ",
  GENERAL_BOX_STOP_PIVOT_SUCCESS: "Команда остановки отправлена.",
  GENERAL_BOX_STOP_PIVOT_FAILURE: "Не удалось остановить стержень",
  GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE:
    "Не удалось проверить, находится ли стержень онлайн",
  EDIT_IRPD_DATASHEET_WARNING:
    "В настройках этого оборудования есть поля, значения которых отличаются от текущего технического паспорта.",
  EDIT_IRPD_SEND_SUCCESS: "Отправить",
  EDIT_IRPD_SEND_CANCEL: "Отменить",
  GENERAL_BOX_IRPD_IS_ONLINE_FAILURE:
    "Не удалось проверить, находтися ли irpd онлайн",
  IRPD_BOX_STOP_COMMAND_SENT: "Команда остановки успешно отправлена.",
  IRPD_BOX_COMMAND_ERROR: "Ошибка отправки команды.",
  IRPD_BOX_COMMAND_SENT: "Команда успешно отправлена.",
  IRPD_BOX_CONFIGURATION_SAVED: "Конфигурация успешно отправлена.",
  IRPD_BOX_CONFIGURATION_ERROR: "Ошибка отправки конфигурации.",
  EDIT_FARM_CENTER_MAP_BUTTON: "Центрировать на карте",
  EDIT_FARM_SAVE_SUCCESS: "Ферма успешно сохранена",
  EDIT_FARM_ERROR_NAME: "Неверный размер имени",
  EDIT_FARM_ERROR_BILLING_DATE: "Неверная дата выставления счета",
  EDIT_FARM_ERROR_INITIAL_LAT: "Неверная начальная широта",
  EDIT_FARM_ERROR_INITIAL_LNG: "Неверная начальная долгота",
  EDIT_FARM_ERROR_LAT: "Недопустимая широта",
  EDIT_FARM_ERROR_LNG: "Недопустимая долгота",
  EDIT_FARM_ERROR_ADDRESS: "Неверный адрес",
  EDIT_FARM_ERROR_POSTAL_CODE: "Неверный почтовый индекс",
  EDIT_FARM_ERROR_CITY: "Неверный Город",
  EDIT_FARM_ERROR_STATE: "Неверный Регион",
  EDIT_FARM_ERROR_COUNTRY: "Неверная Страна",
  EDIT_FARM_ERROR_PHONE: "Неверный номер телефона",
  EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE:
    "В ваших диапазонах мощности отсутствуют дни. Заполните все дни недели.",
  EDIT_FARM_REPEATED_DAYS: "Этот день уже добавлен в список.",
  EDIT_FARM_POWER_RANGES: "Диапазоны Мощности",
  EDIT_FARM__POWER_RANGES_DESCRIPTION:
    "Диапазоны Мощности используются для расчета затрат на электроэнергию для отчетов",
  EDIT_FARM_ADD_POWER_RANGES: "Добавить диапазоны мощности",
  EDIT_FARM_HOLLIDAYS: "ВЫХОДНЫЕ",
  EDIT_FARM_ADD_HOLLIDAYS: "ДОБАВИТЬ ВЫХОДНЫЕ",
  DATE_PICKER_HOLIDAYS: "Выберите дату",
  EDIT_FARM_DAY: "День",
  EDIT_FARM_MONTH: "Месяц",
  EDIT_FARM_START_TIME: "Время Начала",
  EDIT_FARM_END_TIME: "Время Окончания",
  EDIT_FARM_INTERVAL: "Интервал",
  EDIT_FARM_DURATION: "Продолжительность",
  EDIT_FARM_TYPE: "Тип",
  EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING:
    "Диапазоны мощности изменились, сохраните предыдущие изменения, чтобы активировать кнопки ниже.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL: "Включить Отчеты",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT:
    "Нажмите кнопку, чтобы включить создание отчетов для стержней фермы.",
  EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES:
    "Зарегистрируйте действительные Диапазоны Мощности, чтобы включить отчеты.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING: "Ваши отчеты создаются.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_ENABLED: "Ваши отчеты уже включены.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED: "Ваши отчеты активированы.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL: "Пересчитать Отчеты",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION:
    "Вы уверены, что хотите пересчитать свои\nотчеты?",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT:
    "Нажмите кнопку, чтобы пересчитать отчеты для стержней фермы.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE: "Пересчитать",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS: "Отчеты включены.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR: "Ошибка включения отчетов.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS: "Отчеты пересчитываются.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR:
    "Ошибка при попытке пересчета отчетов.",
  EDIT_FARM_PIVOT_REPORTS: "Отчеты по Стержням",
  EDIT_FARM_IRPD_REPORTS: "Отчеты по Насосам",
  PIVOT: "Стержень",
  PIVOT_PLURAL: "Стержни",
  PIVOT_STATUS_STOPPED: "Остановлен",
  PIVOT_STATUS_IN_MOVING: "Перемещение в Сухом Режиме",
  PIVOT_STATUS_IRRIGATING: "Полив",
  PIVOT_STATUS_PROBLEM: "Проблема",
  PIVOT_STATUS_PROGRAMMED: "Запрограммировано",
  PIVOT_STATUS_PANEL_ON: "Панель включена",
  PIVOT_STATUS_PANEL_OFF: "Панель отключена",
  PIVOT_STATUS_PRESSURIZING: "В ожидании давления",
  PIVOT_FIELD_NAME: "Имя",
  PAINEL_STREAM_REASON_0: "Панель включена",
  PAINEL_STREAM_REASON_1: "Перемещение в сухом режиме",
  PAINEL_STREAM_REASON_2: "Мокрый режим после создания давления",
  PAINEL_STREAM_REASON_4: "Мокрый режим после сбоя питания",
  PAINEL_STREAM_REASON_5: "Сухой режим после сбоя питания",
  PAINEL_STREAM_REASON_6:
    "Включен после сбоя питания и находится под давлением",
  PAINEL_STREAM_REASON_8: "Мокрый режим после времени паузы",
  PAINEL_STREAM_REASON_9: "Сухой режим после времени паузы",
  PAINEL_STREAM_REASON_10: "Влажный режим после времени паузы и под давлением",
  PAINEL_STREAM_REASON_11: "Сухой режим после начала поливов по расписанию",
  PAINEL_STREAM_REASON_16: "Включен после полива по расписанию",
  PAINEL_STREAM_REASON_18: "Мокрый режим после создания давления",
  PAINEL_STREAM_REASON_21: "Стержень настроен по расписанию в сухом режиме",
  PAINEL_STREAM_REASON_22:
    "Стержень включен и настроен по расписанию после повышения давления",
  PAINEL_STREAM_REASON_32: "Сводка запланирована в мокром режиме",
  PAINEL_STREAM_REASON_33: "Сухой режим после запланированного по расписанию",
  PAINEL_STREAM_REASON_34:
    "Влажный режим после запланированного по расписанию полива",
  PAINEL_STREAM_REASON_38: "Стержень мокрый после сбоя питания",
  PAINEL_STREAM_REASON_39: "Фертигация начата",
  PAINEL_STREAM_REASON_65: "Продолжая сухое расписание",
  PAINEL_STREAM_REASON_66: "Продолжая влажное расписание",
  PAINEL_STREAM_REASON_98: "Полив",
  PAINEL_STREAM_REASON_100: "Питание панели включено",
  PAINEL_STREAM_REASON_101: "Панель отключена",
  PAINEL_STREAM_REASON_102: "Ожидание создания давления",
  PAINEL_STREAM_REASON_103: "Стержень остановлен с давлением на",
  PAINEL_STREAM_REASON_104: "Фертигация остановлена",
  PAINEL_STREAM_REASON_240: "Остановлен дождемером",
  PAINEL_STREAM_REASON_241: "Остановлено перед следующим запуском расписания",
  PAINEL_STREAM_REASON_242: "Остановлено неизвестным фактором",
  PAINEL_STREAM_REASON_243: "Остановлен в конечном секторе",
  PAINEL_STREAM_REASON_244: "Поливы по расписанию завершены",
  PAINEL_STREAM_REASON_245: "Выполнение автореверсии",
  PAINEL_STREAM_REASON_246: "Остановлено после скачка напряжения",
  PAINEL_STREAM_REASON_247: "Остановлено из-за Времени Паузы",
  PAINEL_STREAM_REASON_248: "Остановлен из-за низкого давления",
  PAINEL_STREAM_REASON_249: "Остановлено через Интернет",
  PAINEL_STREAM_REASON_250: "Остановлено тайм-аутом давления",
  PAINEL_STREAM_REASON_251: "Остановлен после срабатывания теплового реле",
  PAINEL_STREAM_REASON_252: "Остановлено после завершения полива",
  PAINEL_STREAM_REASON_253: "Остановлен после сбоя питания",
  PAINEL_STREAM_REASON_254: "Нажата кнопка СТОП",
  PAINEL_STREAM_REASON_255: "Остановлено после нелинейности",
  PAINEL_STREAM_REASON_256: "Нет коммуникации",
  PANEL_STREAM_STATUS_0: "Остановлено через Интернет",
  PANEL_STREAM_STATUS_1: "Движение вперед",
  PANEL_STREAM_STATUS_2: "Движение назад",
  PANEL_STREAM_STATUS_3: "Ожидание давления",
  PANEL_STREAM_STATUS_4: "Полив",
  PANEL_STREAM_STATUS_5: "Ожидание задержки давления",
  PANEL_STREAM_STATUS_6: "Движение в сухом режиме",
  PANEL_STREAM_STATUS_7: "Запрограммировано",
  PANEL_STREAM_STATUS_8: "Подготовка к включению",
  PANEL_STREAM_STATUS_9: "Остановлено по Времени Паузы",
  PANEL_STREAM_STATUS_10: "Полив завершен",
  PANEL_STREAM_STATUS_11: "Остановлено автореверсией",
  PANEL_STREAM_STATUS_12: "Остановлено через Интернет",
  PANEL_STREAM_STATUS_13: "Ручная остановка",
  PANEL_STREAM_STATUS_14: "Панель включена",
  PANEL_STREAM_STATUS_14_2: "Pivot on",
  PANEL_STREAM_STATUS_15: "Безопасность",
  PANEL_STREAM_STATUS_16: "Сбой давления",
  PANEL_STREAM_STATUS_17: "Неверное направление полива",
  PANEL_STREAM_STATUS_18: "Ошибка питания",
  PANEL_STREAM_STATUS_19: "Напряжение вне допустимого диапазона",
  PANEL_STREAM_STATUS_20: "Ошибка параметра",
  PANEL_STREAM_STATUS_21: "Неизвестная ошибка",
  PANEL_STREAM_STATUS_22: "Панель выключена",
  PANEL_STREAM_STATUS_23: "Тайм-аут давления",
  PANEL_STREAM_STATUS_24: "Полив начат",
  PANEL_STREAM_STATUS_25: "Кнопка остановки нажата",
  PANEL_STREAM_STATUS_32: "Ошибка насоса",
  PANEL_STREAM_STATUS_33: "Сухое перемещение с помощью плювиометра",
  PANEL_STREAM_STATUS_34: "Остановлено плювиометром",
  PANEL_STREAM_STATUS_35: "Остановлено из-за ошибки часов",
  PANEL_STREAM_STATUS_36: "Фертигация начата",
  PANEL_STREAM_STATUS_37: "Фертигация остановлена",
  PANEL_STREAM_STATUS_38: "Нет коммуникации",
  PANEL_STREAM_STATUS_POWER_OFF: "Выключено",
  PANEL_STREAM_STATUS_NONE: "Неизвестный статус",
  IRPD_STATUS_STOPPED: "Остановлен",
  IRPD_STATUS_PUMPING: "Прокачка",
  IRPD_STATUS_PROBLEM: "Проблема",
  IRPD_STATUS_PROGRAMMED: "Запрограммировано",
  IRPD_STATUS_ENERGIZED: "Под напряжением",
  IRPD_STATUS_WITHOUT_ENERGY: "Без энергии",
  SELECTED_IRPD_BACK_BUTTON_TEXT: "Назад",
  SELECTED_IRPD_SAVE_BUTTON_TEXT: "Сохранить",
  SELECTED_IRPD_SAVE_BUTTON_TEXT_DS:
    "Сохранить / Установить как технический паспорт",
  IRPD_STATUS_1: "Включено через Интернет",
  IRPD_STATUS_2: "Включено вручную",
  IRPD_STATUS_3: "Включено после времени паузы",
  IRPD_STATUS_4: "Включен после сбоя питания",
  IRPD_STATUS_5: "Насос запрограммирован",
  IRPD_STATUS_6: "Устройство включено",
  IRPD_STATUS_7: "Насос включен датчиком",
  IRPD_STATUS_8: "Насос ожидает после сбоя питания",
  IRPD_STATUS_9: "Насос включён по радиокоманде",
  IRPD_STATUS_192: "Счётчик моточасов поврежден",
  IRPD_STATUS_193: "Насос управляется неизвестным радио",
  IRPD_STATUS_246: "Насос выключен IMeter",
  IRPD_STATUS_247: "Расписание истекло",
  IRPD_STATUS_248: "Неправильное расписание",
  IRPD_STATUS_249: "Насос выключен по окончании расписания",
  IRPD_STATUS_250: "Насос выключен датчиком",
  IRPD_STATUS_251: "Выкл.",
  IRPD_STATUS_252: "Питание отключено после сбоя питания",
  IRPD_STATUS_253: "Выключено по истечении времени паузы",
  IRPD_STATUS_254: "Выключено вручную",
  IRPD_STATUS_255: "Остановлено через Интернет",
  IRPD_CONFIG_FIELD_START_NOW: "Начать сейчас",
  IRPD_CONFIG_FIELD_STOP_NOW: "Остановись сейчас",
  IRPD_FIELD_START_INTERVAL: "Начать через",
  IRPD_CONFIG_FIELD_DONT_STOP: "Не останавливаться",
  IRPD_FIELD_END_INTERVAL: "Остановиться",
  IRPD_FIELD_OFF: "Выкл.",
  EDIT_IRPD_CANCEL: "Вы уверены?",
  EDIT_IRPD_ERROR_RTCDATE: "Недействительный RTC",
  EDIT_IRPD_ERROR_HOUR_RANGE_MAX: "Недопустимый максимальный час",
  EDIT_IRPD_ERROR_HOUR_RANGE_MIN: "Недействительный минимальный час",
  EDIT_IRPD_ERROR_ENERGY_TIME: "Недействительное время энергии",
  COUNTRY_AFGHANISTAN: "Афганистан",
  COUNTRY_SOUTH_AFRICA: "Южная Африка",
  COUNTRY_ALBANIA: "Албания",
  COUNTRY_GERMANY: "Германия",
  COUNTRY_ANDORRA: "Андорра",
  COUNTRY_ANGOLA: "Ангола",
  COUNTRY_ANGUILLA: "Ангилья",
  COUNTRY_ANTARCTICA: "Антарктида",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Антигуа и Барбуда",
  COUNTRY_SAUDI_ARABIA: "Саудовская Аравия",
  COUNTRY_ALGERIA: "Алжир",
  COUNTRY_ARGENTINA: "Аргентина",
  COUNTRY_ARMENIA: "Армения",
  COUNTRY_ARUBA: "Аруба",
  COUNTRY_AUSTRALIA: "Австралия",
  COUNTRY_AUSTRIA: "Австрия",
  COUNTRY_AZERBAIJAN: "Азербайджан",
  COUNTRY_BAHAMAS: "Багамы",
  COUNTRY_BAHRAIN: "Бахрейн",
  COUNTRY_BANGLADESH: "Бангладеш",
  COUNTRY_BARBADOS: "Барбадос",
  COUNTRY_BELARUS: "Беларусь",
  COUNTRY_BELGIUM: "Бельгия",
  COUNTRY_BELIZE: "Белиз",
  COUNTRY_BENIN: "Бенин",
  COUNTRY_BERMUDA: "Бермудские острова",
  COUNTRY_BOLIVIA: "Боливия",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Босния и Герцеговина",
  COUNTRY_BOTSWANA: "Ботсвана",
  COUNTRY_BRAZIL: "Бразилия",
  COUNTRY_BRUNEI: "Бруней",
  COUNTRY_BULGARIA: "Болгария",
  COUNTRY_BURKINA_FASO: "Буркина-Фасо",
  COUNTRY_BURUNDI: "Бурунди",
  COUNTRY_BHUTAN: "Бутан",
  COUNTRY_CAPE_VERDE: "Кабо-Верде",
  COUNTRY_CAMEROON: "Камерун",
  COUNTRY_CAMBODIA: "Камбоджа",
  COUNTRY_CANADA: "Канада",
  COUNTRY_CANARY_ISLANDS: "Канарские острова",
  COUNTRY_KAZAKHSTAN: "Казахстан",
  COUNTRY_CEUTA_AND_MELILLA: "Сеута и Мелилья",
  COUNTRY_CHAD: "Чад",
  COUNTRY_CHILE: "Чили",
  COUNTRY_CHINA: "Китай",
  COUNTRY_CYPRUS: "Кипр",
  COUNTRY_SINGAPORE: "Сингапур",
  COUNTRY_COLOMBIA: "Колумбия",
  COUNTRY_COMOROS: "Коморские Острова",
  COUNTRY_CONGO_REPUBLIC: "Конго (Республика)",
  COUNTRY_NORTH_KOREA: "Северная Корея",
  COUNTRY_SOUTH_KOREA: "Южная Корея",
  COUNTRY_CTE_DIVOIRE: "Кот-д'Ивуар",
  COUNTRY_COSTA_RICA: "Коста-Рика",
  COUNTRY_CROATIA: "Хорватия",
  COUNTRY_CUBA: "Куба",
  COUNTRY_CURAAO: "Кюрасао",
  COUNTRY_DIEGO_GARCIA: "Диего Гарсия",
  COUNTRY_DENMARK: "Дания",
  COUNTRY_DJIBOUTI: "Джибути",
  COUNTRY_DOMINICA: "Доминика",
  COUNTRY_EGYPT: "Египет",
  COUNTRY_EL_SALVADOR: "Сальвадор",
  COUNTRY_UNITED_ARAB_EMIRATES: "Объединенные Арабские Эмираты",
  COUNTRY_ECUADOR: "Эквадор",
  COUNTRY_ERITREA: "Эритрея",
  COUNTRY_SLOVAKIA: "Словакия",
  COUNTRY_SLOVENIA: "Словения",
  COUNTRY_SPAIN: "Испания",
  COUNTRY_UNITED_STATES: "США",
  COUNTRY_ESTONIA: "Эстония",
  COUNTRY_ETHIOPIA: "Эфиопия",
  COUNTRY_FIJI: "Фиджи",
  COUNTRY_PHILIPPINES: "Филиппины",
  COUNTRY_FINLAND: "Финляндия",
  COUNTRY_FRANCE: "Франция",
  COUNTRY_GABON: "Габон",
  COUNTRY_GAMBIA: "Гамбия",
  COUNTRY_GHANA: "Гана",
  COUNTRY_GEORGIA: "Грузия",
  COUNTRY_GIBRALTAR: "Гибралтар",
  COUNTRY_UNITED_KINGDOM: "Великобритания",
  COUNTRY_GRENADA: "Гренада",
  COUNTRY_GREECE: "Греция",
  COUNTRY_GREENLAND: "Гренландия",
  COUNTRY_GUADELOUPE: "Гваделупа",
  COUNTRY_GUAM: "Гуам",
  COUNTRY_GUATEMALA: "Гватемала",
  COUNTRY_GUERNSEY: "Гернси",
  COUNTRY_GUYANA: "Гайана",
  COUNTRY_FRENCH_GUIANA: "Французская Гвиана",
  COUNTRY_GUINEA: "Гвинея",
  COUNTRY_EQUATORIAL_GUINEA: "Экваториальная Гвинея",
  COUNTRY_GUINEA_BISSAU: "Гвинея-Бисау",
  COUNTRY_HAITI: "Гаити",
  COUNTRY_NETHERLANDS: "Нидерланды",
  COUNTRY_HONDURAS: "Гондурас",
  COUNTRY_HONG_KONG: "Гонконг",
  COUNTRY_HUNGARY: "Венгрия",
  COUNTRY_YEMEN: "Йемен",
  COUNTRY_BOUVET_ISLAND: "Остров Буве",
  COUNTRY_ASCENSION_ISLAND: "Остров Вознесения",
  COUNTRY_CLIPPERTON_ISLAND: "Остров Клиппертон",
  COUNTRY_ISLE_OF_MAN: "Остров Мэн",
  COUNTRY_CHRISTMAS_ISLAND: "Остров Рождества",
  COUNTRY_PITCAIRN_ISLANDS: "Острова Питкэрн",
  COUNTRY_RUNION: "Реюньон",
  COUNTRY_LAND_ISLANDS: "Аландские острова",
  COUNTRY_CAYMAN_ISLANDS: "Каймановы острова",
  COUNTRY_COCOS_KEELING_ISLANDS: "Кокосовые (Килинг) Острова",
  COUNTRY_COOK_ISLANDS: "Острова Кука",
  COUNTRY_FAROE_ISLANDS: "Фарерские острова",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS:
    "Южная Георгия и Южные Сандвичевы Острова",
  COUNTRY_HEARD_AND_MCDONALD_ISLANDS: "Острова Херда и Макдональда",
  COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS:
    "Фолклендские острова (Мальвинские острова)",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Северные Марианские Острова",
  COUNTRY_MARSHALL_ISLANDS: "Маршалловы Острова",
  COUNTRY_US_OUTLYING_ISLANDS: "Внешние острова США",
  COUNTRY_NORFOLK_ISLAND: "Остров Норфолк",
  COUNTRY_SOLOMON_ISLANDS: "Соломоновы Острова",
  COUNTRY_SEYCHELLES: "Сейшельские Острова",
  COUNTRY_TOKELAU: "Токелау",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Острова Теркс и Кайкос",
  COUNTRY_US_VIRGIN_ISLANDS: "Виргинские Острова США",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "Британские Виргинские острова",
  COUNTRY_INDIA: "Индия",
  COUNTRY_INDONESIA: "Индонезия",
  COUNTRY_IRAN: "Иран",
  COUNTRY_IRAQ: "Ирак",
  COUNTRY_IRELAND: "Ирландия",
  COUNTRY_ICELAND: "Исландия",
  COUNTRY_ISRAEL: "Израиль",
  COUNTRY_ITALY: "Италия",
  COUNTRY_JAMAICA: "Ямайка",
  COUNTRY_JAPAN: "Япония",
  COUNTRY_JERSEY: "Джерси",
  COUNTRY_JORDAN: "Иордания",
  COUNTRY_KIRIBATI: "Кирибати",
  COUNTRY_KOSOVO: "Косово",
  COUNTRY_KUWAIT: "Кувейт",
  COUNTRY_LAOS: "Лаос",
  COUNTRY_LESOTHO: "Лесото",
  COUNTRY_LATVIA: "Латвия",
  COUNTRY_LEBANON: "Ливан",
  COUNTRY_LIBERIA: "Либерия",
  COUNTRY_LIBYA: "Ливия",
  COUNTRY_LIECHTENSTEIN: "Лихтенштейн",
  COUNTRY_LITHUANIA: "Литва",
  COUNTRY_LUXEMBOURG: "Люксембург",
  COUNTRY_MACAU: "Макао",
  COUNTRY_MACEDONIA_FYROM: "Македония (БЮРМ)",
  COUNTRY_MADAGASCAR: "Мадагаскар",
  COUNTRY_MALAYSIA: "Малайзия",
  COUNTRY_MALAWI: "Малави",
  COUNTRY_MALDIVES: "Мальдивы",
  COUNTRY_MALI: "Мали",
  COUNTRY_MALTA: "Мальта",
  COUNTRY_MOROCCO: "Марокко",
  COUNTRY_MARTINIQUE: "Мартиника",
  COUNTRY_MAURITIUS: "Маврикий",
  COUNTRY_MAURITANIA: "Мавритания",
  COUNTRY_MAYOTTE: "Майотта",
  COUNTRY_MEXICO: "Мексика",
  COUNTRY_MICRONESIA: "Микронезия",
  COUNTRY_MOZAMBIQUE: "Мозамбик",
  COUNTRY_MOLDOVA: "Молдова",
  COUNTRY_MONACO: "Монако",
  COUNTRY_MONGOLIA: "Монголия",
  COUNTRY_MONTENEGRO: "Черногория",
  COUNTRY_MONTSERRAT: "Монтсеррат",
  COUNTRY_MYANMAR_BURMA: "Мьянма (Бирма)",
  COUNTRY_NAMIBIA: "Намибия",
  COUNTRY_NAURU: "Науру",
  COUNTRY_NEPAL: "Непал",
  COUNTRY_NICARAGUA: "Никарагуа",
  COUNTRY_NIGER: "Нигер",
  COUNTRY_NIGERIA: "Нигерия",
  COUNTRY_NIUE: "Ниуэ",
  COUNTRY_NORWAY: "Норвегия",
  COUNTRY_NEW_CALEDONIA: "Новая Каледония",
  COUNTRY_NEW_ZEALAND: "Новая Зеландия",
  COUNTRY_OMAN: "Оман",
  COUNTRY_CARIBBEAN_NETHERLANDS: "Карибские Нидерланды",
  COUNTRY_PALAU: "Палау",
  COUNTRY_PALESTINE: "Палестина",
  COUNTRY_PANAMA: "Панама",
  COUNTRY_PAPUA_NEW_GUINEA: "Папуа-Новая Гвинея",
  COUNTRY_PAKISTAN: "Пакистан",
  COUNTRY_PARAGUAY: "Парагвай",
  COUNTRY_PERU: "Перу",
  COUNTRY_FRENCH_POLYNESIA: "Французская Полинезия",
  COUNTRY_POLAND: "Польша",
  COUNTRY_PUERTO_RICO: "Пуэрто-Рико",
  COUNTRY_PORTUGAL: "Португалия",
  COUNTRY_QATAR: "Катар",
  COUNTRY_KENYA: "Кения",
  COUNTRY_KYRGYZSTAN: "Кыргызстан",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "Центральноафриканская Республика",
  COUNTRY_CONGO_DRC: "Конго (ДРК)",
  COUNTRY_DOMINICAN_REPUBLIC: "Доминиканская Республика",
  COUNTRY_CZECH_REPUBLIC: "Чешская Республика",
  COUNTRY_ROMANIA: "Румыния",
  COUNTRY_RWANDA: "Руанда",
  COUNTRY_RUSSIA: "Россия",
  COUNTRY_WESTERN_SAHARA: "Западная Сахара",
  COUNTRY_ST_PIERRE_AND_MIQUELON: "Сен-Пьер и Микелон",
  COUNTRY_AMERICAN_SAMOA: "Американское Самоа",
  COUNTRY_SAMOA: "Самоа",
  COUNTRY_SAN_MARINO: "Сан-Марино",
  COUNTRY_ST_HELENA: "Св. Елены",
  COUNTRY_ST_LUCIA: "Сент-Люсия",
  COUNTRY_ST_BARTHLEMY: "Сен-Бартелеми",
  COUNTRY_ST_KITTS_AND_NEVIS: "Сент-Китс и Невис",
  COUNTRY_ST_MARTIN: "Сен-Мартен",
  COUNTRY_SINT_MAARTEN: "Синт-Мартен",
  COUNTRY_SO_TOM_AND_PRNCIPE: "Сан-Томе и Принсипи",
  COUNTRY_ST_VINCENT_AND_GRENADINES: "Сент-Винсент и Гренадины",
  COUNTRY_SENEGAL: "Сенегал",
  COUNTRY_SIERRA_LEONE: "Сьерра-Леоне",
  COUNTRY_SERBIA: "Сербия",
  COUNTRY_SYRIA: "Сирия",
  COUNTRY_SOMALIA: "Сомали",
  COUNTRY_SRI_LANKA: "Шри-Ланка",
  COUNTRY_SWAZILAND: "Свазиленд",
  COUNTRY_SUDAN: "Судан",
  COUNTRY_SOUTH_SUDAN: "Южный Судан",
  COUNTRY_SWEDEN: "Швеция",
  COUNTRY_SWITZERLAND: "Швейцария",
  COUNTRY_SURINAME: "Суринам",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Шпицберген и Ян-Майен",
  COUNTRY_TAJIKISTAN: "Таджикистан",
  COUNTRY_THAILAND: "Таиланд",
  COUNTRY_TAIWAN: "Тайвань",
  COUNTRY_TANZANIA: "Танзания",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY:
    "Британская Территория в Индийском Океане",
  COUNTRY_FRENCH_SOUTHERN_TERRITORIES: "Южные Французские Территории",
  COUNTRY_TIMOR_LESTE: "Восточный Тимор",
  COUNTRY_TOGO: "Того",
  COUNTRY_TONGA: "Тонга",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Тринидад и Тобаго",
  COUNTRY_TRISTAN_DA_CUNHA: "Тристан-да-Кунья",
  COUNTRY_TUNISIA: "Тунис",
  COUNTRY_TURKMENISTAN: "Туркменистан",
  COUNTRY_TURKEY: "Турция",
  COUNTRY_TUVALU: "Тувалу",
  COUNTRY_UKRAINE: "Украина",
  COUNTRY_UGANDA: "Уганда",
  COUNTRY_URUGUAY: "Уругвай",
  COUNTRY_UZBEKISTAN: "Узбекистан",
  COUNTRY_VANUATU: "Вануату",
  COUNTRY_VATICAN_CITY: "Ватикан",
  COUNTRY_VENEZUELA: "Венесуэла",
  COUNTRY_VIETNAM: "Вьетнам",
  COUNTRY_WALLIS_AND_FUTUNA: "Уоллис и Футуна",
  COUNTRY_ZAMBIA: "Замбия",
  COUNTRY_ZIMBABWE: "Зимбабве",
  IRRIGATION_BOX_CONVENTIONAL_BUTTON: "Отправить Команду",
  IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG: "Команда Простого полива послана.",
  IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG: "Команда успешно отправлена.",
  IRRIGATION_BOX_BY_ANGLE_ERROR_MSG: "Ошибка отправки команды.",
  IRRIGATION_BOX_GENERIC_ERROR_MSG: "Ошибка отправки команды.",
  IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG:
    "Ошибка отправки команды. Проверьте, все ли поля заполнены.",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE: "Подтвердите свое действие",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION:
    "Подтвердите действие остановки для ${}",
  IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION:
    "Подтвердите действие прямого полива для ${}",
  IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION:
    "Подтвердите действие обратного полива для ${}",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES: "Отправить",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO: "Отменить",
  MOBILE_MODAL_TITLE_SIMPLE_IRRIGATION: "Простой Полив",
  MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION: "Расписание Полива",
  MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION: "Простой Полив",
  MOBILE_MODAL_TITLE_LANGUAGES: "Выберите Язык",
  MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION: "Полив по Углу",
  MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION: "Сегментный Полив",
  PIVOT_ACTION_FIELD_DIRECTION: "Направление",
  PIVOT_ACTION_FIELD_MODE: "Режим Работы",
  PIVOT_ACTION_FIELD_ENDGUN: "Конечный Ороситель",
  PIVOT_ACTION_FIELD_PRECIPITATION: "Осадки",
  PIVOT_ACTION_FIELD_START_DATE: "Начать в",
  PIVOT_ACTION_FIELD_START_TIME: "Время Начала",
  PIVOT_ACTION_FIELD_END_DATE: "Конец в",
  PIVOT_ACTION_FIELD_END_TIME: "Время Окончания",
  PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN: "Неизвестно",
  PIVOT_ACTION_VALUE_DIRECTION_STOPPED: "Остановлен",
  PIVOT_ACTION_VALUE_DIRECTION_PROGRESS: "Вперед",
  PIVOT_ACTION_VALUE_DIRECTION_REVERSE: "Реверс",
  PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS: "Начать Вперед",
  PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE: "Начать Реверс",
  PIVOT_ACTION_VALUE_DRY: "Сухой",
  PIVOT_ACTION_VALUE_MODE_WET: "Мокрый",
  PIVOT_ACTION_FIELD_PERCENT: "Процент",
  PIVOT_ACTION_FIELD_START_NOW: "Начать Сейчас",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME: "После Времени Паузы",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1: "После Паузы 1",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2: "После Паузы 2",
  PIVOT_ACTION_FIELD_WITHOUT_STOP: "Без остановки",
  PIVOT_ACTION_INVALID_DATE: "Недействительная дата",
  NAV_ITEM_LOGOUT: "Выйти",
  NAV_ITEM_LANGUAGE: "Язык",
  NAV_ITEM_PROFILE: "Редактировать Профиль",
  NAV_ITEM_FIRST_STEPS: "Первые Шаги",
  NAV_ITEMS_NEW_FARM: "Новая Ферма",
  SELECTED_FARM_NO_PIVOTS: "На этой ферме нет стержней.",
  SELECTED_FARM_CENTRAL_ONLINE: "Центр управления онлайн",
  SELECTED_FARM_PIVOT_ONLINE: "Pivot online",
  SELECTED_FARM_CENTRAL_FILTER_BY: "Фильтровать оборудование по",
  SELECTED_FARM_CENTRAL_OFFLINE: "Центр управления офлайн",
  SELECTED_FARM_PIVOT_OFFLINE: "Pivot offline",
  SELECTED_FARM_WATER_LIMIT_EXCEEDED: "Превышен лимит потребления воды",
  LOADING: "Загрузка",
  NOT_FOUND_TITLE: "Упс!",
  NOT_FOUND_ERROR_TEXT: "Страница не найдена.",
  NOT_FOUND_BACK_BUTTON_TEXT: "ГЛАВНАЯ СТРАНИЦА",
  NOT_FOUND_TOKEN: "Срок действия сеанса истек. Войдите снова.",
  EDIT_PIVOT_V5_RELEVANT_INFO: "Соответствующая Информация",
  EDIT_PIVOT_V5_PIVOT_TITLE_SEGMENTS: "Сегменты",
  EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG: "Настройки Давления",
  EDIT_PIVOT_V5_AUTOREVERSION: "Автореверсия",
  EDIT_PIVOT_V5_AUTOREVERSION_30S: "30 секунд",
  EDIT_PIVOT_V5_AUTOREVERSION_1MIN: "1 минута",
  EDIT_PIVOT_V5_AUTOREVERSION_2MIN: "2 минуты",
  EDIT_PIVOT_V5_REFERENCE_NORTH: "Использовать Север в качестве Ориентира",
  EDIT_PIVOT_V5_REFERENCE_NORTH_SELECT: "Установить Ориентир Стержня",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_END_ADORNMENT: "с",
  EDIT_PIVOT_V5_AUTOREVERSION_DISABLED: "Отключено",
  EDIT_PIVOT_ERROR_NAME: "Неверный размер имени",
  EDIT_PIVOT_V5_ERROR_POTENCY: "Недопустимое значение мощности",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LAT: "Недопустимая центральная широта",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LNG: "Недопустимая центральная долгота",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LAT:
    "Недопустимая широта начального ориентира",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LNG:
    "Недопустимая долгота начального ориентира",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LAT:
    "Недопустимое значение широты конечного ориентира",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LNG:
    "Недопустимое значение долготы конечного ориентира",
  EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION: "Ошибка отправки конфигурации:",
  EDIT_PIVOT_V5_ERROR_RADIUS: "Недопустимое значение радиуса",
  EDIT_PIVOT_V5_ERROR_FLOW: "Недопустимое значение расхода",
  EDIT_PIVOT_V5_ERROR_SPEED: "Недопустимое значение радиуса",
  EDIT_PIVOT_V5_ERROR_AREA: "Недопустимое значение области",

  EDIT_PIVOT_V5_ERROR_VOLTAGE_STABLE_TIME:
    "Недопустимое значение времени стабильного напряжения",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_REF: "Недопустимое значение опорного напряжения",
  EDIT_PIVOT_V5_ERROR_PUMP_TIMEOUT: "Недопустимое значение тайм-аута насоса",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_DELAY:
    "Неверное значение задержки давления насоса",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_SWITCH:
    "Недействительное значение реле давления насоса",
  EDIT_PIVOT_V5_ERROR_PUMP_SOFT_START_TIME:
    "Недействительное значение времени плавного пуска насоса",
  EDIT_PIVOT_V5_ERROR_ANGLE_START: "Недопустимое значение начального угла",
  EDIT_PIVOT_V5_ERROR_ANGLE_END: "Недопустимое значение конечного угла",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START: "Недопустимое значение времени начала",
  EDIT_PIVOT_V5_ERROR_PIVOT_AVERAGE_POWER:
    "Недопустимое значение средней мощности стержня",
  EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER:
    "Недействительное значение средней мощности канала",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END: "Недопустимое конечное значение времени",
  EDIT_PIVOT_V5_ERROR_POWER_DELAY: "Недопустимое значение задержки мощности",
  EDIT_PIVOT_V5_ERROR_SENSOR_SCALE_END:
    "Недопустимое конечное значение шкалы датчика",
  EDIT_PIVOT_V5_ERROR_PIVOT_SEGMENT: "Недопустимые сегменты стержня",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS: "Сегменты",
  EDIT_PIVOT_V5_ENDGUN: "Пушечный Ороситель",
  EDIT_PIVOT_V5_SAVE_SUCCESS: "Конфигурация стержня отправлена",
  EDIT_PIVOT_V5_SEGMENT_WARNING:
    "Заполните все сегменты и не превышайте рабочий угол",
  EDIT_PIVOT_V5_SAVE_ERROR:
    "Ошибка при сохранении стержня. Все поля должны быть заполнены.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK: "Контроль Напряжения",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_DELETE_ALL: "Удалить все",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START: "Начало: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END: "Конец: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_FORWARD: "Вперед: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_REVERSE: "Реверс: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_MODE: "${}, ${}%",
  PIVOT_CONFIG_V5_CONFIG_HISTORY: "История Конфигурации",
  PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM: "Очистить Таблицу",
  PIVOT_CONFIG_V5_SEGMENT_ERROR: "Вы можете создать верхние ${} сегментов",
  EDIT_PIVOT_V5_WEEKDAY_MONDAY: "ПН",
  EDIT_PIVOT_V5_WEEKDAY_TUESDAY: "ВТ",
  EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY: "СР",
  EDIT_PIVOT_V5_WEEKDAY_THURSDAY: "ЧТ",
  EDIT_PIVOT_V5_WEEKDAY_FRIDAY: "ПТ",
  EDIT_PIVOT_V5_WEEKDAY_SATURDAY: "СБ",
  EDIT_PIVOT_V5_WEEKDAY_SUNDAY: "ВС",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK: "Дни Недели",
  EDIT_PIVOT_V5_CONFIGURATIONS: "Конфигурации",
  COMMAND_SENT_WAITING_CONFIRMATION:
    "Команда отправлена. Дождитесь подтверждения устройства.",
  COMMAND_CONFIRMED: "Команда получена устройством.",
  COMMAND_ERROR: "Ошибка при выполнении команды",
  NAV_NO_NOTIFICATIONS: "У вас нет уведомлений.",
  CURRENT_ANGLE: "Текущий угол: ",
  POWER_RANGE_POINT: "Пик",
  POWER_RANGE_OUT_OF_POINT: "Вне пика",
  POWER_RANGE_REDUCED: "Уменьшено",
  POWER_RANGE_TIME_START: "Старт",
  POWER_RANGE_TIME_END: "Конец",
  POWER_RANGE_TYPE: "Тип",
  POWER_RANGE_DELETE_CONFIRMATION:
    "Вы уверены, что хотите удалить этот диапазон мощности?",
  HISTORIC_SEGMENT_IRRIGATION_START_MODE: "Режим Запуска: ",
  HISTORIC_SEGMENT_IRRIGATION_START_AT: "Начать с: ",
  HISTORIC_SEGMENT_IRRIGATION_END_MODE: "Режим Окончания: ",
  HISTORIC_SEGMENT_IRRIGATION_SEGMENT: "Сегмент ${}:",
  HISTORIC_SEGMENT_IRRIGATION_START_ANGLE: "Начальный Угол ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_ANGLE: "Конечный Угол: ${}",
  HISTORIC_SEGMENT_IRRIGATION_FORWARD_INFO: "Вперед - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO: "Реверс - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_AT: "Конец в: ",
  HISTORIC_SEGMENT_IRRIGATION_ROUNDS: "Обороты: ${}",
  HISTORIC_SEGMENT_IRRIGATION_START_DATE: "Начало: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_DATE: "Конец: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_MODE: "Режим Автореверсии: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_QUANTITY: "Обороты: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS: "Не Останавливаться",
  HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE: "Остановка по Углу",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE: "Остановки по Дате",
  HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR: "Остановка по Концу Сектора",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS: "Остановка по Оборотам",
  HISTORIC_SEGMENT_IRRIGATION_AUTO_REVERSIONS: "Автоматические Реверсии: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NOW: "Немедленно",
  HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE: "По Дате",
  SEGMENT_IRRIGATION_FIELD_START_NOW: "Начать сейчас?",
  SEGMENT_IRRIGATION_FIELD_END_BY_TIME: "Конец по времени?",
  SEGMENT_IRRIGATION_FIELD_END_BY_LAPS: "Конец по оборотам?",
  SEGMENT_IRRIGATION_FIELD_END_BY_SEGMENT: "Завершить по сегментам?",
  SEGMENT_IRRIGATION_FIELD_NEVER_ENDS: "Никогда не заканчивается.",
  SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS: "Количество оборотов",
  SEGMENT_IRRIGATION_FIELD_LAPS: "Круг(и)",
  SEGMENT_IRRIGATION_BUTTON_FORWARD: "НАЧАТЬ ВПЕРЕД",
  SEGMENT_IRRIGATION_BUTTON_REVERSE: "НАЧАТЬ НАЗАД",
  DRAWER_HOME: "Домой",
  DRAWER_FARMS: "Фермы",
  DRAWER_REPORTS: "Отчеты",
  NOTIFICATIONS_DRAWER_TITLE: "Уведомления",
  GENERAL_BOX_PRESSURE_LABEL: "${} бар",
  LOGIN_WELCOME: "Добро пожаловать",
  LOGIN_EMAIL: "Электронная почта",
  LOGIN_NEW_USER: "Новый клиент?",
  LOGIN_SIGN_IN: "Войти",
  LOGIN_SIGN_UP: "Зарегистрироваться",
  LOGIN_USERNAME_OR_EMAIL: "Имя пользователя или адрес электронной почты",
  LOGIN_PASSWORD: "Пароль",
  LOGIN_OR: "или",
  LOGIN_FORGET_PASSWORD: "Забыли пользователя или пароль?",
  LOGIN_GOOGLE_SIGN_IN: "Войти через Google",
  LOGIN_RECOVER_PASSWORD: "Восстановить пароль",
  LOGIN_GO_BACK: "Назад",
  LOGIN_SUCCESSFUL: "Пользователь успешно вошел в систему.",
  SIGN_UP_FOOTER: "Заполните все поля формы, чтобы продолжить.",
  SIGN_UP_WELCOME: "Добро пожаловать на платформу от Irricontrol.",
  SIGN_UP_WELCOME_AUX: "Заполните форму чтобы завершить регистрацию:",
  SIGN_UP_EMAIL: "Электронная почта",
  SIGN_UP_REPEAT_EMAIL: "Повторите письмо",
  SIGN_UP_NAME: "Имя",
  SIGN_UP_LAST_NAME: "Фамилия",
  SIGN_UP_USERNAME: "Имя пользователя",
  SIGN_UP_REPEAT_PASSWORD: "Повторите свой пароль",
  SIGN_UP_BACK: "Назад",
  SIGN_UP_NEXT: "Далее",
  SIGN_UP_WARNING_SUCCESS: "Регистрация успешно завершена",
  LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED:
    "Имя пользователя и/или пароль не предоставлены",
  LOGIN_ERROR_GOOGLE_USER_NOT_REGISTERED:
    "Пользователь Google не зарегистрирован",
  LOGIN_ERROR_DEFAULT: "Ошибка аутентификации",
  LOGIN_ERROR_SERVER_OFFLINE: "Сервер отключен",
  LOGIN_ERROR_INVALID_CREDENTIALS: "Неверное имя пользователя или пароль",
  SIGN_UP_CONFIRMATION_RETURN:
    "Вы уверены, что хотите покинуть страницу, не завершив регистрацию?",
  SIGN_UP_CONFIRMATION_SIGN_UP: "Вы подтверждаете все свои данные?",
  SIGN_UP_BUTTON_CONFIRM: "Подтвердить",
  CREATE_DEVICE_MODAL_TITLE: "Зарегистрировать Оборудование",
  CREATE_DEVICE_MODAL_CENTRAL: "Центральная Панель",
  CREATE_DEVICE_MODAL_EQUIPMENT: "Тип Оборудования",
  CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER: "Название Оборудования",

  CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER: "Hardware Id",

  CREATE_DEVICE_MODAL_CREATE_DEVICE: "Зарегистрировать Устройства",
  CREATE_DEVICE_MODAL_CONTROLLER_CODE: "Радио Контроллера",
  CREATE_DEVICE_MODAL_GPS_CODE: "Pадио GPS",
  CREATE_DEVICE_MODAL_PIVOT: "Стержень",
  CREATE_DEVICE_MODAL_GPS: "GPS",
  CREATE_DEVICE_MODAL_REPEATER: "Ретранслятор",
  CREATE_DEVICE_MODAL_REPEATER_CODE: "Pадио Ретранслятора",
  CREATE_DEVICE_MODAL_CODE_READ_SUCCESS: "Код успешно прочитан",
  CREATE_DEVICE_MODAL_CODE_READ_FAIL: "Ошибка чтения кода",
  CREATE_DEVICE_MODAL_CREATE: "Создать",
  CREATE_DEVICE_MODAL_ERROR_NOT_VALID: "Недействительное Радио",
  CREATE_DEVICE_MODAL_ERROR_TAKEN: "Это устройство уже занято",
  CREATE_DEVICE_MODAL_ERROR_NOT_MONITOR: "Это устройство не GPS",
  CREATE_DEVICE_MODAL_ERROR_NOT_CONTROL: "Это устройство не является стержнем",
  CREATE_DEVICE_MODAL_ERROR_NOT_REPEATER:
    "Это устройство не является Ретранслятором",
  CREATE_DEVICE_MODAL_ERROR_SERVER: "Ошибка сервера",
  CREATE_DEVICE_MODAL_SUCCESS: "Устройство успешно создано",
  EDIT_PROFILE_TITLE: "Профиль пользователя ",
  EDIT_PROFILE_ERROR_NAME: "Неверный размер имени",
  EDIT_PROFILE_ERROR_LAST_NAME: "Неверный размер имени",
  EDIT_PROFILE_ERROR_USERNAME: "Неверный размер имени пользователя",
  EDIT_PROFILE_ERROR_EMAIL: "Недействительный адрес электронной почты",
  EDIT_PROFILE_ERROR_CONFIRM_EMAIL: "Недействительный адрес электронной почты",
  EDIT_PROFILE_ERROR_BIRTHDAY: "Неверная дата",
  EDIT_PROFILE_ERROR_COUNTRY_CODE: "Недействительный код страны",
  EDIT_PROFILE_ERROR_CELLPHONE: "Неверный номер мобильного телефона",
  EDIT_PROFILE_ERROR_LANDLINE: "Неверный номер стационарного телефона",
  EDIT_PROFILE_ERROR_ADDRESS: "Неверный адрес",
  EDIT_PROFILE_ERROR_NUMBER_HOUSE: "Неверный номер дома",
  EDIT_PROFILE_ERROR_NEIGHBORHOOD: "Неверный район",
  EDIT_PROFILE_ERROR_CITY: "Неверный Город",
  EDIT_PROFILE_ERROR_STATE: "Неверный Регион",
  EDIT_PROFILE_ERROR_COUNTRY: "Неверная Страна",
  EDIT_PROFILE_ERROR_ZIPCODE: "Неверный почтовый индекс",
  INVALID_CPF: "Неверный код документа",
  EDIT_PROFILE_ERROR_PASSWORD: "Неверный пароль",
  EDIT_PROFILE_ERROR_PASSWORD2: "Неверный пароль",
  EDIT_PROFILE_NAME: "Имя",
  EDIT_PROFILE_LAST_NAME: "Фамилия",
  EDIT_PROFILE_EMAIL: "Электронная почта",
  EDIT_PROFILE_BIRTHDAY: "День рождения",
  EDIT_PROFILE_CELLPHONE: "Номер мобильного телефона",
  EDIT_PROFILE_LANDLINE: "Городской номер",
  EDIT_PROFILE_COUNTRY_CODE: "Код страны",
  EDIT_PROFILE_ADDRESS: "Адрес",
  EDIT_PROFILE_NUMBER_HOUSE: "Номер",
  EDIT_PROFILE_NEIGHBORHOOD: "Район",
  EDIT_PROFILE_CITY: "Город",
  EDIT_PROFILE_STATE: "Регион",
  EDIT_PROFILE_COUNTRY: "Страна",
  EDIT_PROFILE_ZIPCODE: "Почтовый индекс",
  EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE: "Официальный код документа",
  PROFILE_BOX_CONVENTIONAL_ERROR_MSG: "Ошибка редактирования профиля.",
  PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG: "Профиль пользователя отправлен.",
  EDIT_PROFILE_CONFIRM_PASSWORD: "Пароль",
  EDIT_PROFILE_PASSWORD: "Подтвердить пароль",
  EDIT_PROFILE_CONFIRM_EMAIL: "Подтвердить адрес электронной почты",
  EDIT_PROFILE_CURRENT_PASSWORD: "Текущий пароль",
  EDIT_PROFILE_NEW_PASSWORD: "Новый пароль",
  EDIT_PROFILE_CONFIRM_NEW_PASSWORD: "Подтвердите Новый Пароль",
  EDIT_PROFILE_PERSONAL_INFO: "Личная Информация:",
  EDIT_PROFILE_CONTACT_ADDRESS: "Контактный Адрес:",
  EDIT_PROFILE_PASSWORD_CHANGE: "Изменение Пароля:",
  SIMPLE_IRRIGATION_FIELD_DIRECTION: "Направление",
  SIMPLE_IRRIGATION_FIELD_MODE: "Режим Работы",
  SIMPLE_IRRIGATION_FIELD_PERCENT: "Процент",
  SIMPLE_IRRIGATION_FIELD_MM: "Осадки",
  SIMPLE_IRRIGATION_FIELD_BY_ANGLE: "Остановить по углу?",
  SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP: "Конечный Угол",
  SIMPLE_IRRIGATION_VALUE_INJECTION_ON: "Вкл.",
  SIMPLE_IRRIGATION_VALUE_INJECTION_OFF: "Выкл.",
  SIMPLE_IRRIGATION_VALUE_FORWARD: "Вперед",
  SIMPLE_IRRIGATION_VALUE_REVERSE: "Реверс",
  SIMPLE_IRRIGATION_VALUE_DRY: "Сухой",
  SIMPLE_IRRIGATION_VALUE_WET: "Мокрый",
  SIMPLE_IRRIGATION_FIELD_STOP_MODE: "Режим остановки",
  SIMPLE_IRRIGATION_NEVER_ENDS: "Не останавливаться",
  SIMPLE_IRRIGATION_END_SECTOR: "Конечный сектор",
  SIMPLE_IRRIGATION_BY_ROUNDS: "По Оборотам",
  SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP: "Обороты",
  FARM: "Ферма",
  FARMS: "Фермы",
  FARM_FIELD_SEARCH_BAR: "Поиск",
  FARM_FIELD_NAME: "Название Фермы",
  FARM_FIELD_BILLING_DATE: "День Счета за Электроэнергию",
  FARM_FIELD_LAT: "Широта",
  FARM_FIELD_LNG: "Долгота",
  FARM_FIELD_PHONE: "Телефон",
  FARM_FIELD_ADDRESS: "Почтовый Адрес",
  FARM_FIELD_POSTAL_CODE: "Почтовый Индекс",
  FARM_FIELD_CITY: "Город",
  FARM_FIELD_STATE: "Регион",
  FARM_FIELD_COUNTRY: "Страна",
  FARM_FIELD_START_INTERVAL: "Время Начала",
  FARM_FIELD_END_INTERVAL: "Время Конца",
  FARM_FIELD_TYPE_INTERVAL: "Тип",
  FARM_VALUE_RUSH_HOUR: "Время Паузы",
  FARM_VALUE_OFF_PEAK_TIME: "Время выключения Паузы",
  FARM_VALUE_REDUCED_TIME: "Сокращенное Время",
  HISTORIC_SIMPLE_IRRIGATION_CREATED_AT: "Создано в: ${}",
  HISTORIC_CREATED_BY: "Отправлено: ",
  HISTORIC_CREATED_ON_HARDWARE: "Создано на устройстве",
  HISTORIC_PAINEL_STREAM_REASON: "Причина:",
  HISTORIC_PAINEL_STREAM_REASON_VALUE: "${}",
  HISTORIC_PAINEL_STREAM_WITH_ENERGY: "Под напряжением",
  HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY: "Без напряжения",
  HISTORIC_PAINEL_STREAM_ALIGNED: "Выровнено",
  HISTORIC_PAINEL_STREAM_NOT_ALIGNED: "Не выровнено",
  HISTORIC_PAINEL_STREAM_HOURMETER: "Счетчик моточасов: ",
  HISTORIC_PAINEL_STREAM_POWER: "Напряжение: ",
  HISTORIC_PAINEL_STREAM_WITH_PRESSURE: "С давлением",
  HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE: "Тайм-аут давления насоса",
  HISTORIC_PAINEL_STREAM_REVERSIONS: "Выполнено реверсий: ${} раз",
  HISTORIC_GPS_STREAM_CREATED_HUMANIZED: "${} назад",
  HISTORIC_GPS_STREAM_CREATED: "${}",
  HISTORIC_GPS_STREAM_POSITION: "Позиция: ${},${}.",
  HISTORIC_GPS_STREAM_REASON: "Причина:",
  HISTORIC_GPS_STREAM_REASON_VALUE: "${}",
  HISTORIC_GPS_STREAM_SPEED: "Процент: ",
  HISTORIC_GPS_STREAM_HOURMETER: "Счетчик моточасов: ",
  HISTORIC_GPS_STREAM_WATER_PRESSURE: "Давление последней башни: ",
  HISTORIC_GPS_STREAM_CENTER_PRESSURE: "Давление в центре: ${} бар",
  HISTORIC_GPS_STREAM_DIRECTION: "Направление: ",
  HISTORIC_PIVOT_ACTION_STOP: "Остановить стержень",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD: "${} | ${}",
  HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED: "${} назад",
  HISTORIC_PIVOT_ACTION_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_START: "Начало: ",
  HISTORIC_PIVOT_ACTION_START_NOW: "Немедленно",
  HISTORIC_PIVOT_ACTION_END: "Запрограммированная остановка: ",
  HISTORIC_PIVOT_ACTION_NEVER_END: "Не останавливаться",
  HISTORIC_PIVOT_ACTION_MANUAL: "Создано вручную",
  HISTORIC_PIVOT_ACTION_USER: "Пользователем: ",
  HISTORIC_PIVOT_ACTION_DIRECTION: "Направление: ",
  HISTORIC_PIVOT_ACTION_ENDGUN: "Процент: ${}%, ${} мм",
  HISTORIC_PIVOT_ACTION_MODE: "Режим: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW: "По углу",
  HISTORIC_PIVOT_ACTION_VRI_START: "Начало: ",
  HISTORIC_PIVOT_ACTION_VRI_START_NOW: "Сейчас",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED: "${} назад",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL: "Создано вручную",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER: "Пользователь: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE: "Режим: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION: " ${} | ${}, ${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON: "Пушечный Ороситель Включен",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF: "Пушечный Ороситель Выключен",
  HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION: "Простой Орошение",
  HISTORIC_PANEL_STREAM_V5_: "Остановить Стержень",
  HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION: "Расписание Орошение",
  HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION: "Сегментный Орошение",
  HISTORIC_PANEL_STREAM_V5_STOPPED: "Остановлен",
  HISTORIC_PANEL_STREAM_V5_FORWARD: "Вперед",
  HISTORIC_PANEL_STREAM_V5_REVERSE: "Реверс",
  HISTORIC_PANEL_STREAM_V5_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PANEL_STREAM_V5_DIRECTION: "Направление: ",
  HISTORIC_PANEL_STREAM_V5_STATUS: "Статус: ${}",
  HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION: "Активное орошение: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL: "Общий cчетчик часов: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET: "Счетчик часов mокрого pежима: ",
  HISTORIC_PANEL_STREAM_V5_MODE: "Режим: ",
  HISTORIC_PANEL_STREAM_V5_PERCENTAGE: "Осадки: ",
  HISTORIC_PANEL_STREAM_V5_POSITION: "Позиция",
  HISTORIC_PANEL_STREAM_V5_ANGLE: "Угол: ",
  HISTORIC_PANEL_STREAM_V5_VOLTAGE: "Напряжение: ${} В",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER: "Давление в центральной башне: ",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER: "Давление в последней башне: ",
  HISTORIC_GPS_STREAM_V5_NO_LABEL: "${}",
  HISTORIC_GPS_STREAM_V5_REMAINING_TIME: "Расчетное оставшееся время: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE: "Текущее расписание: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT: "Текущий сегмент:  ",
  HISTORIC_PANEL_LABEL_DATE: "Дата",
  HISTORIC_PANEL_LABEL_DATASHEET: "Технический паспорт",
  HISTORIC_PANEL_LABEL_TYPE: "Тип",
  HISTORIC_PANEL_LABEL_SUMMARY: "Сводка",
  HISTORIC_MAINTENANCE_ON: "Режим обслуживания включен.",
  HISTORIC_MAINTENANCE_OFF: "Режим обслуживания отключен.",
  IRRIGATION_TYPE_SIMPLE: "Простой орошение",
  IRRIGATION_TYPE_SCHEDULE: "Полив по Расписанию",
  IRRIGATION_TYPE_SEGMENT: "Сегментный Полив",
  IRRIGATION_MODE_NONE: "Нет",
  IRRIGATION_MODE_WET: "Мокрый",
  IRRIGATION_MODE_DRY: "Сухой",
  IRRIGATION_DIRECTION_STOPPED: "Остановлен",
  IRRIGATION_DIRECTION_FORWARD: "Вперед",
  IRRIGATION_DIRECTION_REVERSE: "Обратный",
  EDIT_PIVOT_LAST_SETTINGS: "Последняя конфигурация: ${}",
  EDIT_PIVOT_MANUAL: "- Вручную",
  EDIT_PIVOT_BY_WEB: "- Интернет",
  EDIT_PIVOT_ERROR: "Форма имеет недопустимые значения.",
  EDIT_PIVOT_ERROR_CENTER_LAT: "Недопустимая широта центра",
  EDIT_PIVOT_ERROR_CENTER_LNG: "Недопустимая долгота центра",
  EDIT_PIVOT_ERROR_START_REF_LAT: "Недопустимая широта Начального Ориентира",
  EDIT_PIVOT_ERROR_START_REF_LNG: "Недопустимая долгота Начального Ориентира",
  EDIT_PIVOT_ERROR_END_REF_LAT: "Недопустимая широта Конечного Ориентира",
  EDIT_PIVOT_ERROR_END_REF_LNG: "Недопустимая долгота Конечного Ориентира",
  EDIT_PIVOT_ERROR_RADIUS: "Недопустимое значение радиуса",
  EDIT_PIVOT_ERROR_TOTAL_RADIUS: "Недопустимое значение общего радиуса",
  EDIT_PIVOT_ERROR_AREA: "Недопустимое значение Области",
  EDIT_PIVOT_ERROR_SPEED: "Недопустимое значение Скорости Последней Башни",
  EDIT_PIVOT_ERROR_FLOW: "Недопустимое значение Расхода",
  EDIT_PIVOT_ERROR_POTENCY: "Недопустимое значение Мощности Насоса",
  EDIT_PIVOT_ERROR_PUMP_TIME: "Недопустимое значение Времени Насоса",
  EDIT_PIVOT_ERROR_POWER_TIME: "Недопустимое значение Времени Включения",
  EDIT_PIVOT_ERROR_POWER_RANGE_MIN: "Неверный размер имени",
  EDIT_PIVOT_ERROR_POWER_RANGE_MAX: "Неверный размер имени",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MIN: "Неверный размер имени",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MAX: "Неверный размер имени",
  EDIT_PIVOT_ERROR_CLOCK_DATE: "Неверная дата",
  EDIT_PIVOT_ERROR_CLOCK_TIME: "Недействительное время",
  EDIT_PIVOT_CLOCK_LABEL: "Установить Часы Устройства",
  EDIT_PIVOT_SAVE_AWAIT:
    "Конфигурация отправлена. Дождитесь подтверждения устройства",
  EDIT_PIVOT_SAVE_AWAIT_ERROR: "Произошла ошибка",
  EDIT_PIVOT_SAVE_CONTROLLER_SUCCESS: "Контроллер успешно сохранен",
  EDIT_PIVOT_SAVE_GPS_SUCCESS: "GPS успешно сохранен",
  EDIT_PIVOT_SAVE_PUMP_SUCCESS: "Насос успешно сохранен",
  EDIT_PIVOT_GPS_LABEL: "GPS-Радио",
  EDIT_PIVOT_PUMP_LABEL: "Радио Насоса",
  EDIT_PIVOT_DELIVERED_LABEL: "Радио Контроллера",
  EDIT_PIVOT_CENTRAL_LABEL: "Радио Центра Управления",
  HISTORIC_GPS_STREAM_COMUNICATION_ERROR: "Pivot without communication",
  HISTORIC_STREAM_LOST_ERROR:
    "Возможная потеря информации от GPS или контроллера",
  TERRAIN_MAP: "Рельеф",
  GENERIC_REGISTER_DATA_ERROR: "Ошибка при регистрации данных",
  GENERIC_REGISTER_DATA_SUCCESS: "Данные успешно зарегистрированы!",

  CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY: "Не удалось создать стержень",
  METERSYSTEM_WATER_LEVEL_BY_TIME: "Уровень Воды по Времени",
  METERSYSTEM_CURRENT_FLOW: "Расход: ",
  METERSYSTEM_NO_READINGS: "Нет показаний",
  METERSYSTEM_TOTAL_FLOW: "Общий Расход",
  METERSYSTEM_FLOW_BY_TIME: "Расход по Времени",
  METER_SYSTEM_EDIT: "Редактировать Счетчик",
  METER_SYSTEM_EDIT_GENERAL: "Общие Конфигурации",
  METERSYSTEM_STATUS_1: "Устройство включено",
  METERSYSTEM_STATUS_2: "Включено после сбоя питания",
  METERSYSTEM_STATUS_3: "Мониторинг датчика",
  METERSYSTEM_STATUS_176: "Насос выключен",
  METERSYSTEM_STATUS_177: "Насос включен",
  METERSYSTEM_STATUS_178: "Насос устройства выключен другим IMeter",
  METERSYSTEM_STATUS_179: "Насос устройства включен другим IMeter",
  METERSYSTEM_STATUS_180: "Насос устройства выключен датчиком",
  METERSYSTEM_STATUS_181: "Насос устройства включен датчиком",
  METERSYSTEM_STATUS_192: "Счётчик моточасов поврежден",
  METERSYSTEM_STATUS_208: "Насос не блокирует контактор",
  METERSYSTEM_STATUS_209: "Время паузы",
  METERSYSTEM_STATUS_210: "Устройство выключено",
  METERSYSTEM_STATUS_211: "Выключен из-за сбоя питания",
  METERSYSTEM_STATUS_212: "Уровень заряда батареи ниже 10В",
  METERSYSTEM_STATUS_213: "Устройство потеряло контакт с другим IMeter",
  METER_SYSTEMS: "Системы Измерения:",
  DEVICES_CONTROLLER_NOT_FOUND: "Контроллер не найден",
  DEVICES_ANALYSIS: "Анализ:",
  DEVICES_STATUS_COLOR_NOT_FOUND: "Цвет состояния не найден",
  DEVICE: "Устройство:",
  DEVICE_FOUND: "Найдено",
  DEVICE_NOT_FOUND: "Не найдено",
  DEVICE_CENTRAL: "Центральный",
  DEVICE_LINE_VERY_STRONG: "Очень сильный",
  DEVICE_LINE_STRONG: "Сильный",
  DEVICE_LINE_MODERATE: "Умеренный",
  DEVICE_LINE_WEAK: "Слабый",
  DEVICE_LINE_ERROR: "Ошибка",
  DEVICE_LINE: "Строка:",

  ICROP_FORM_NO_ACCESS: "Похоже, у вас еще нет доступа к этой функции.",
  ICROP_FORM_SELECT_A_FARM: "Выберите ферму:",
  EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS: "Импорт Государственных Праздников",
  PAGE_TRY_AGAIN: "Попробуйте еще раз",
  EDIT_PIVOT_LAT_AND_LONG: "Широта и Долгота",
  PAUSE_TIME_WITHOUT_CONFIG: "Время Паузы не включено",
  WEEK_OF_THE_DAY: "Неделя дня",
  BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO:
    "В этой таблице нет данных за указанный диапазон дат.",

  BASIC_INFO_BOX_INFORMATION_CALCULATED:
    "Информация рассчитывается для данных, которые уже существуют в системе, это может занять много времени.",
  CROP_COTTOM: "Хлопок",
  CROP_RICE: "Рис",
  CROP_OAT: "Овес",
  CROP_POTATO: "Картофель",
  CROP_SWEET_POTATO: "Сладкий картофель",
  CROP_SUGAR_CANE: "Сахарный тростник",
  CROP_BARLEY: "Ячмень",
  CROP_PEA: "Горох",
  CROP_BEAN: "Боб",
  CROP_MILLET: "Просо",
  CROP_CORN: "Кукуруза",
  CROP_SWEET_CORN: "Сладкая кукуруза",
  CROP_POPCORN: "Попкорн",
  CROP_CORN_SEEDS: "Семена Кукурузы",
  CROP_SILAGE_CORN: "Силосная Кукуруза",
  CROP_PASTURE: "Пастбище",
  CROP_SOY: "Соя",
  CROP_SOY_SEEDS: "Семена Сои",
  CROP_SORGHUM: "Сорго",
  CROP_SORGHUM_SEEDS: "Семена сорго",
  CROP_TOMATO: "Помидор",
  CROP_WHEAT: "Пшеница",

  ORBITAL_SENSOR: "Орбитальный Датчик",
  ANNOUNCEMENT_SYSTEM_TITLE: "Объявление",

  PRESSURE_DELAY_INPUT:
    "Время задержки относится к промежутку времени после того, как на стержне возникло давление. Существует время ожидания, когда давление достигнет последней башни, чтобы стержень мог начать движение.",

  PRESSURE_SENSOR_RANGE_MIN:
    "Минимальное значение указывает диапазон давления, который устройство должно поддерживать. Ниже минимального значения стержень останавливается.",

  PRESSURE_SENSOR_RANGE_MAX:
    "Максимальное значение указывает диапазон давления, который устройство должно поддерживать. При превышении максимального значения стержень останавливается.",

  SENSOR_SCALE_END:
    "Шкала Датчика относится к максимальному давлению, которое он может измерить.",

  GET_NEW_STREAM: "Поиск последнего обновления",

  ADD_SENSOR: "Добавить Датчик",

  WATER_LEVEL_SENSOR: "Датчик Уровня",

  TRIGGER_RULE: "Правило Срабатывания",

  CHART: "График",

  PIVOT_UNDER_MAINTENANCE: "Стержень на обслуживании",

  GET_NEW_STREAM_ERROR: "Ошибка при поиске последнего обновления",
  PIVOT_MANAGEMENT: "Управление Стерженем",

  CHOOSE_ACCUMULATED_VOL_PERIOD: "Выберите период информации:",

  LATEST_INSTANT_FLOW: "Последняя мгновенная скорость потока",

  APPROXIMATE_USAGE_TIME: "Расчетное время использования",

  APPROXIMATE_USAGE_TIME_ABBREVIATED: "Расчетное время использования",

  ACCUMULATED_VOLUME: "Общий накопленный объем",

  HOURS_24: "24 ЧАСА",

  HOURS_48: "48 ЧАСОВ",

  HOURS_72: "72 ЧАСА",

  WEEK_1: "1 НЕДЕЛЯ",

  MONTH_1: "1 МЕСЯЦ",

  FLOW_BY_TIME_CHART: "Графику потока по времени",

  MONTHLY_WATER_ACTION_CONFIRMATION:
    "Месячный Лимит Воды был превышен, вы уверены, что хотите продолжить?",

  ATENTION: "Внимание",
  CONTINUE: "Продолжить",
  SEGMENT_FINAL_ANGLE: "Угол Конца Сегмента",
  WORKING_ANGLE: "Диапазон Угла Стержня",
  HARDWARE: "Аппаратное обеспечение",
  DAILY_RAINING: "по сегодняшему дождю",
  PLUVIOMETER_HISTOGRAM: "Гистограмма",
  IRRIGATION_INCREMENTED_DUE_TO_RAIN:
    "Скорость полива увеличена до ${}% из-за дождя.",
  RAINING_NOW: "Сейчас идет дождь",
  ICROP_DOC_URL:
    "https://irricontroldev.s3.us-east-2.amazonaws.com/icrop_en.pdf",
  ICROP_WELCOME_PANEL_TITLE: "Добро пожаловать в Панель Управления Стержня!",
  ICROP_WELCOME_PANEL_P1:
    "Здесь у вас есть доступ к рекомендациям по поливу стержнем, а также к информации о культуре и почве.",
  ICROP_WELCOME_PANEL_P2: "Загрузите документ ниже или откройте панель.",
  ICROP_DOC_DOWNLOAD: "Загрузить документ",
  ICROP_ACCESS_PANEL: "Доступ к панели",
  WEEK_ACCUMULATED_RAIN: "Количество осадков за неделю",
  ACCUMULATED_MILLIMETERS: "Суммарные миллиметры",
  BASE_CHANGE_CLOCK_ALERT:
    "Часы нижеуказанных устройств будут переведены на текущее время",
  EQUIPMENT_ID_MISSING:
    "Оборудование не найдено в запросе. Обратитесь в службу поддержки",
  RADIO_ID_NOT_FOUND_IN_DATABASE: "Радио не найдено",
  RADIO_ID_ALREADY_USED: "Радио уже используется",
  SEND_RADIO_CHANGE_TO: "Отправить обновление радио",
  NO_EQUIPMENT: "Нет",
  ALL_EQUIPMENTS: "Все оборудование",
  SEND: "Отправить",
  CHANGE_BASE_RADIO_MODAL_TITLE: "Изменить Радио Центра Управления",
  USER_PERMISSION: "Нет разрешения",
  USER_PERMISSION_READ: "Читатель",
  USER_PERMISSION_OPERATOR: "Оператор",
  USER_PERMISSION_CONFIGURATOR: "Конфигуратор",
  SETORIAL_ENDGUN_ERROR:
    "Этот стержень является секторным. Конфигурации над красной областью не будут иметь никакого эффекта",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION: "Описание",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT:
    "Время паузы - это временные диапазоны, в которых стержень не должен работать в мокром режиме, чтобы избежать использования в критические моменты, например, когда стоимость электроэнергии выше.",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT:
    "Обычно некоторые стержни разделяются на две или более частей в зависимости от сорта посевов, цикла культур или даже для облегчения управления операциями. С помощью этой функции вы можете сегментировать стержни и обеспечить больший контроль над их работой.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT:
    "Для стержней, у которых на последней башне установлен пушечный ороситель, можно сегментировать активацию, предотвращая ее включение в местах, где не должно орошаться, таких как дороги, дома и т.д.",
  PIVOT_CONFIG_V5_AUTORREVERSION_TEXT:
    "Автореверсия - это функция оборудования, которая позволяет стержню достичь конца своего курса и автоматически вернуться в рабочее состояние. Возврат стержня происходит, когда он достигает физического препятствия, называемого концом курса - доступно для панелей SmartConnect - или когда он достигает своего окончательного рабочего угла.",
  WORKING_ANGLE_LABEL: "Какого максимального угла достигает этот стержень?",
  PASSWORD_RECOVERY_INSERT_EMAIL:
    "Введите свой адрес электронной почты, чтобы мы могли отправить вам письмо для восстановления аккаунта.",
  VALID_UNTILL: "Действительно до",
  NO_EQUIPMENTS: "Нет оборудования",
  UPDATE_USER_TO_ADMINISTRATOR_SUCCESS:
    "Пользователь-администратор успешно добавлен.",
  CENTRAL_FOUND: "Центральная панель найдена",
  CONTROLLER_FOUND: "Контроллер найден",
  PUMP_FOUND: "Насос найден",
  REPEATER_FOUND: "Ретранслятор найден",
  FIELD_CAPACITY: "Вместимость Поля",
  FULL_MOISTURE: "Полная Влажность",
  MODERATE_MOISTURE: "Умеренная Влажность",
  LIMIT_POINT: "Предельная Точка",
  MODERATE_WATER_STRESS: "Умеренный Водный Стресс",
  WATER_STRESS: "Водный Стресс",
  APPROPRIATE: "Соответствующий",
  INNAPPROPRIATE: "Неприемлемый",
  SCALE: "Масштаб",
  BASIC_INFO_BOX_RAINFALL: "Дождь",
  PERIOD_TOTAL_WATER_CONSUMPTION: "Общее Потребление за Период",
  SECTOR_PIVOT_REASON_FALSE: "Нет",
  HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME: "Дата и время: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX: "Диапазон Часов Окончания: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN: "Диапазон Часов Начала: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX:
    "Диапазон Максимального Напряжения: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN:
    "Диапазон Минимального Напряжения: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE: "Скорость потока: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME:
    "Время возврата после сбоя питания: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME: "Время накачки: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER: "Скорость последней башни: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA: "Площадь орошения: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS: "Радиус орошения: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER: "Радиус до последней башни: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE: "Ориентир Окончания:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE: "Начальный Ориентир:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_CENTER: "Центр:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT: "Секторный Стержень: ${}",
  SECTOR_PIVOT_REASON_TRUE: "Да",
  HISTORIC_CONFIG_PIVOT_OLD_END_GUN: "Пушечный ороситель: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_NAME: "Имя: ${}",
  HISTORIC_CONFIG_PIVOT_DRIVE: "${}",
  HISTORIC_CONFIG_PIVOT_PINNED: "Любимые Конфигурации",
  HISTORIC_IRPD_ACTION_USER: "Пользователь: ",
  MEASURE_SCALE_UNIT_METER: "м³",
  MEASURE_SCALE_UNIT_LITER: "L",
  MEASURE_SCALE_100: "100",
  MEASURE_SCALE_10: "10",
  MEASURE_SCALE_1: "1",
  HISTORIC_IRPD_STREAM_HOURMETER: "Счетчик моточасов: ",
  HISTORIC_IRPD_ACTION_CREATED: "${} назад | ${}",
  NUMBER_OF_BARS_12: "12",
  NUMBER_OF_BARS_24: "24",
  NUMBER_OF_BARS_30: "30",
  EDIT_FARM_WEEK: "Неделя",
  CREATE_DEVICE_MODAL_PROTOCOL_5: "G2",
  CREATE_DEVICE_MODAL_PROTOCOL_4_1: "G1",
  EDIT_FARM_IRPD_INFOS_RECALCULATE_TEXT:
    "Нажмите кнопку, чтобы пересчитать отчеты для насосов фермы.",
  EDIT_FARM_IRPD_INFOS_ENABLE_TEXT:
    "Нажмите кнопку, чтобы включить отчеты для насосов фермы.",
  SELECTED_FARM_LIST: "Список",
  SWAP_RADIO: "Переключить Радиостанции Стержней",
  EDIT_RADIO: "Редактировать радио",
  HISTORIC_PIVOT_ACTION_BLADE: "Процент: ",
  HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE: "Обновление",
  HISTORIC_PAINEL_TYPE_COMMAND: "Команда",
  PEAK_TIME_DISABLED: "Имейте в виду: Время Паузы отключено",
  TABLE_OF: "из",
  FORGET_PASSWORD_OPTION_PASSWORD: "Я забыл свой пароль",
  SIGN_UP_FIELD_USERNAME_CHECK:
    "Используйте только буквы и цифры. От 8 до 20 символов.",
  SIGN_UP_FIELD_USERNAME_ALREADY_EXIST: "Имя пользователя недоступно",
  SIGN_UP_FIELD_REQUIRED: "Это поле обязательно для заполнения.",
  CREATE_FARM_LABEL: "Создать ферму",
  SELECTED_FARM_MAP: "Карта",
  RADIO: "Радио",
  SWAP: "Поменять местами",
  SIGN_UP_FIELD_PASSWORD_CHECK:
    "Убедитесь, что ваш пароль состоит из 8+ символов и цифр.",
  NO_COMPATIBLE_RADIO_FOUND: "Совместимое радио не найдено.",
  SWAP_RADIO_OF: "Поменять местами радио между",
  SELECT_TO_SWAP_RADIO_OF: "Выберите стержень для обмена радиостанциями между",
  HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL: "Вручную",
  NO_NOTIFICATIONS: "У вас нет уведомлений.",
  SAVE_RADIO: "Сохранить радио",
  CANCEL: "Отменить",
  CONTROL: "Контроллер",
  PUMP_CABLE: "Кабель Насоса",
  HISTORIC_IRPD_ACTION_NOW: "Немедленно",
  HISTORIC_PAINEL_TYPE_GPS_STREAM: "Обновление GPS",
  HISTORIC_PAINEL_NO_GPS: "Нет информации GPS",
  HISTORIC_PAINEL_TYPE_UPDATE: "Обновить",
  HISTORIC_PANEL_STREAM_V5_STOP_PIVOT: "Остановить Стержень",
  HISTORIC_PAINEL_TYPE_MAINTENANCE: "Техническое Обслуживание",
  HISTORIC_IRPD_ACTION_START_AT: "Начало: ",
  HISTORIC_IRPD_ACTION_END_AT: "Конец: ",
  CREATE_DEVICE_MODAL_IRPD_SOON: "Вскоре",
  REDO_CONVENTIONAL_ACTION: "Загрузить последнее орошение",
  PIVOT_ACTION_VALUE_MODE_DRY: "Сухой",
  UPDATING: "Обновление...",
  SIGN_UP_FIELD_PASSWORD_REPEAT_CHECK: "Пароли отличаются.",
  EDIT_FARM_CONTACT: "Контакт",
  CREATE_DEVICE_MODAL_PIVO_CHOOSER:
    "Автоматизация Pivot (SmartConnect и Nexus)",
  CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER:
    "Bauer Pivot (SmartTouch и Universal Pro)",
  CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER: "стержневой монитор",
  CREATE_DEVICE_MODAL_IRPD_CHOOSER: "Удаленное Управление Насосом",
  CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE: "Радио насос (Spoti)",
  CREATE_DEVICE_MODAL_PUMP_IRPD_CODE: "Радио насос (Irripump)",
  HISTORIC_PANEL_LABEL_BY: "Автор",
  HISTORIC_PAINEL_TYPE_PUMP_UPDATE: "Обновление Насоса",
  HISTORIC_CONFIG_PIVOT_V5_LANGUAGE: "Язык устройства: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MIN: "Минимальное Напряжение: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MAX: "Максимальное Напряжение: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_STABLE: "Стабильное Напряжение: ${}",
  HISTORIC_CONFIG_PIVOT_V5_WORKING_ANGLE: "Сектор: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_TIMEOUT: "Тайм-аут Насоса: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_DELAY: "Задержка Давления Насоса: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_READ_PRESSURE: "Считывание Давления: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH: "Реле Давления: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_SOFT_START_TIME: "Время Плавного Запуска: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Максимальное Значение Датчика Давления: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Минимальное Значение Датчика Давления: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_START: "Начало Времени Паузы: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_END: "Конец Времени Паузы: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POTENCY: "Мощность Насоса: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POWER_DELAY: "Задержка Питания: ${}",
  HISTORIC_CONFIG_PIVOT_V5_CANAL_AVERAGE_POWER: "Средняя Мощность Канала: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PILOT_AVERAGE_POWER: "Средняя Мощность Стержня: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_START: "Начальный Угол: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_END: "Конечный Угол: ${}",
  HISTORIC_CONFIG_PIVOT_V5_FIELD_ENDGUN_ANGLE_MODE: "Режим: ",
  HISTORIC_CREATED_AT: "Отправлено: ",
  HISTORIC_IRPD_ACTION_NEVER: "Никогда",
  HISTORIC_IRPD_ACTION_START: "Включите",
  HISTORIC_IRPD_ACTION_STOP: "Выключить",
  PAINEL_STREAM_RESUMED_REASON_242: "Неожиданная остановка",
  PAINEL_STREAM_RESUMED_REASON_246: "Остановлен - нестабильное питание",
  PAINEL_STREAM_RESUMED_REASON_248: "Остановлен - низкое давление",
  PAINEL_STREAM_RESUMED_REASON_250: "Остановлен - таймаут давления",
  PAINEL_STREAM_RESUMED_REASON_251: "Остановлен - тепловое реле",
  PAINEL_STREAM_RESUMED_REASON_253: "Остановлен - сбой питания",
  PAINEL_STREAM_RESUMED_REASON_255: "Безопасность",
  IRPD_ACTION_VALUE_MODE_WET: "Мокрый",
  IRPD_ACTION_VALUE_MODE_DRY: "Сухой",
  IRPD_STATUS_ON_BY_SENSOR: "Насос включен датчиком",
  IRPD_STATUS_WAITING_AFTER_POWER_FAILURE:
    "Подождите некоторое время после сбоя питания",
  IRPD_STATUS_HOURMETER_CORRUPTED: "Счетчик моточасов не учтен",
  IRPD_STATUS_INCORRECT_SCHEDULE: "Неверное расписание",
  IRPD_STATUS_END_SCHEDULE_REACHED: "Откачка после завершения графика",
  CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER: "Мощность Насоса",
  CREATE_DEVICE_MODAL_PROTOCOL: "Версия",
  FARM_FIELD_WATER_BILLING_DATE: "День Счета за Воду",
  CREATE_USER_MODAL_USERNAME_OR_EMAIL:
    "Имя, Имя пользователя или адрес электронной почты",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD:
    "Подтверждаете ли вы, что хотите включить стержень ВПЕРЁД с этими настройками?",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE:
    "Подтверждаете ли вы, что хотите включить стержень РЕВЕРС с этими настройками?",
  MEASURE_SCALE: "Шкала Измерения",
  MEASURE_SCALE_UNIT: "Единица Шкалы Измерения",
  VOLTAGE: "Напряжение",
  DATASHEET_ALERT_CONFIRMATION:
    "Вы уверены, что хотите заменить текущую конфигурацию технического паспорта на эту?",
  HISTORIC_CONFIG_PIVOT_SUBTITLE:
    "Конфигурация, отображаемая на этой вкладке, помечена как технический паспорт. Чтобы изменить её, перейдите на другие вкладки и установите другую конфигурацию как технический паспорт.",
  HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY:
    "Конфигурация удалена из избранного",
  DATASHEET_PLACEHOLDER: "Конфигурация технического паспорта",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ADD_SUCCESSFULLY:
    "Конфигурация успешно установлена как текущий технический паспорт!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_FAILED:
    "Не удалось установить конфигурацию как технический паспорт. Попробуйте позже!",
  HISTORIC_CONFIG_PIVOT_DATASHEET_ERROR: "Ошибка, попробуйте позже",
  EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND: "Радио центра управления недействительно",
  EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED:
    "Радио центра управления уже используется",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID: "Недопустимое радио контроллера",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED:
    "Контроллер радио уже используется",
  CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID: "Недопустимое радио GPS",
  CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED: "GPS-радио уже используется",
  CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID: "Недопустимое радио насоса",
  CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED: "Радио насоса уже используется",
  CREATE_USER_MODAL_404: "Пользователь не существует",
  CREATE_USER_MODAL_INVALID:
    "Неверное имя пользователя или адрес электронной почты",
  EDIT_PERMISSIONS_ADD_ADM_ERROR: "Ошибка добавления в качестве администратора",
  RADIO_CHANGED_SUCCESS: "Радио успешно изменено",
  RADIO_SWAP_SUCCESS: "Радиомодули успешно поменяны местами",
  HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY:
    "Конфигурация добавлена в избранное",
  HISTORIC_CONFIG_PIVOT_PINNED_ERROR: "Ошибка, попробуйте еще раз позже",
  IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG:
    "Нет предыдущей конфигурации полива",
  FARM_DISABLED:
    "Ферма отключена. Доступ предоставляется только после идентификации оплаты годового взноса.",
  FARM_DISABLED_CONTACT: "Свяжитесь с ",
  IRPD_STATUS_OPERATING: "Operating",
  IRPD_STATUS_SCHEDULE_EXPIRED: "Schedule Expired",
  CENTRAL_UPDATE: "Central Update",
  SHOW_CENTRAL_STATUS: "Show Central Updates",
  CREATE_USER_MODAL_EMAIL_NOT_FOUND: "Email not found",
  CREATE_USER_MODAL_SEND_INVITE: "Send Invite",
  CREATE_USER_MODAL_KEEP_TYPING: "Keep typing...",
  CREATE_USER_MODAL_FIND_USER_ERROR:
    "An error occurred while searching for user",
  SIGNAL_STRENGTH: "Signal Strength (dbm)",
  ID_RADIO: "ID (Radio)",
  LOGS: "Logs",
  POWER_RANGE_PEAK_ACRONYM: "PH",
  POWER_RANGE_OFF_PEAK_ACRONYM: "OPH",
  POWER_RANGE_REDUCED_ACRONYM: "NT",
  EDIT_FARM_EDIT_USER: "Редактировать пользователя",
  EDIT_FARM_NO_ADMIN: "Админ не зарегистрирован",
  USER_PERMISSION_CUSTOMIZED: "Обычай",
  PIVOT_SEGMENT_CONFIG_REVERSE: "Reverse Config",
  PIVOT_SEGMENT_CONFIG_FORWARD: "Forward Config",
  PIVOT_SEGMENT_BEGIN_REVERSE: "Start Reverse",
  PIVOT_SEGMENT_BEGIN_FORWARD: "Start Forward",
  SELECTED_PIVOT_ANGLE_MODAL_FAILURE: "Failed to send Irrigation by angle",
  EDIT_FARM_ANGLE_RANGES: "Диапазоны",
  FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE:
    "* Активация Пушечный ороситель выполняется автоматически в соответствии с конфигурацией тержень.",
  CREATE_DEVICE_MODAL_TYPE: "Тип",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_CENTRAL: "контролируемая центральная ось",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_LINEAR: "контролируемая линейная ось",
  CREATE_DEVICE_MODAL_PANEL_TYPE: "тип панели",
  CREATE_DEVICE_MODAL_NEXUS: "Nexus",
  CREATE_DEVICE_MODAL_SMARTCONNECT: "SmartConnect",
  CREATE_DEVICE_MODAL_BRAND_MODEL: "Brand model",
  CREATE_BRAND_MODEL_BAUER: "Bauer",
  CREATE_BRAND_MODEL_IRRIGABRAS: "Irrigabras",
  CREATE_BRAND_MODEL_VALLEY: "Valley",
  CREATE_BRAND_MODEL_LINDSAY: "Lindsay",
  CREATE_BRAND_MODEL_CARBORUNDUM: "Carborundum",
  CREATE_BRAND_MODEL_KREBS: "Krebs",
  CREATE_BRAND_MODEL_FOCKINK: "Fockink",
  CREATE_BRAND_MODEL_REINKE: "Reinke",
  CREATE_BRAND_MODEL_OTHER: "Другой",
  CREATE_DEVICE_MODAL_UNIT: "Potency Unit",
  CREATE_DEVICE_MODAL_KW: "kW",
  CREATE_DEVICE_MODAL_HP: "hp",
  CREATE_DEVICE_POTENCY_PLACEHOLDER: "Potency",
  CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT: "Performance",
  CREATE_DEVICE_MODAL_CONVERTED_POTENCY: "Converted Potency",
  ENABLE_PLUVIOMETER: "Enable pluviometer",
  DISABLE_PLUVIOMETER: "Disable pluviometer",
  PLUVIOMETER_BOX_ENABLE_DIALOG: "Are you sure you want ${}",
  CREATE_BRAND_MODEL_INSERT: "Input Brand Model",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID: "Invalid Repeater radio",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED:
    "Repeater radio already used",
  CREATE_DEVICE_MODAL_METER: "Meter System",
  CREATE_DEVICE_MODAL_SENSOR: "Sensor",
  CREATE_DEVICE_MODAL_SENSOR_5: "Sensor 5mWC",
  CREATE_DEVICE_MODAL_SENSOR_3: "Sensor 10mWC",
  CREATE_DEVICE_MODAL_SENSOR_2: "Sensor 20mWC",
  CREATE_DEVICE_MODAL_SENSOR_7: "Sensor 100mWC",
  CREATE_DEVICE_MODAL_IMETER: "IMeter Radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND: "Invalid Meter System radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED: "IMeter radio already used",
  CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND: "Invalid Pump radio",
  CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED: "Pump radio already used",
  CREATE_DEVICE_MODAL_ERROR_INVALID_HARDWARE_ID: "Harware ID is not available",
  SEND_COMMAND_ERROR: "Ошибка отправки команды.",
  CREATE_DEVICE_MODAL_ENERGY_TYPE: "модель",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR: "Solar",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT: "Dual voltage",
  PIVOT_STATUS_NO_COMMUNICATION_INFO_POPOVER:
    "Equipment without communication with the platform.",
  REPORT_TABLE_DISTANCE: "Distance",
  PRIVACY_POLICY: "политика конфиденциальности",
  TERMS_AND_CONDITIONS: "условия и положения",
  ANNOUNCEMENT_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Irricontrol's Terms and Conditions",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "I declare that I have read and agree to the Irricontrol Terms and Conditions of Use.",
  TERMS_AND_CONDITIONS_LOGIN_1:
    "By using the Irricontrol platform, you declare that you have read and accepted the",
  TERMS_AND_CONDITIONS_LOGIN_2: "and the",
  PAGE_NO_ACCESS_TO_FARMS: "Вы не имеете доступа к этой странице.",
  PAGE_NO_INTERNET:
    "Не удалось подключиться к Интернету, проверьте доступ к сети.",
  PAGE_PRIVACY_POLICY_NOT_ACCEPTED:
    "«Вы еще не приняли Условия использования Irricontrol.»",
  PAGE_CODE_404:
    "Нам не удалось найти запрошенную вами страницу. Повторите попытку.",
  PAGE_DEFAULT: "Произошла непредвиденная проблема, приносим извинения.",
  BACK_TO_INICIAL_PAGE: "Вернуться на главную страницу",
  BILLING_TAB_REDIRECT: "Техническая поддержка",

  PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP: "Расстояние от центра до последней башни.",
  PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Скорость от последней башни до 100%.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN: "Минимальное рабочее напряжение",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX: "Максимальное рабочее напряжение",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP:
    "Если поворотное устройство работает вне пределов напряжения в течение периода, превышающего установленное время, действие поворотного устройства прекращается.",
  SELECTED_ALERT_SELECT_PIVOT_VERSION_TOOLTIP:
    "Выберите версию поворотного устройства:",
  IRRIGATION_END_DATE_PREDICTION_TOOLTIP:
    "Прогноз даты окончания учитывает время простоя в часы пик.",
  BASIC_BAR_TOOLTIP_HP: "Часы пик.",
  BASIC_BAR_TOOLTIP_HFP: "Часы вне пика.",
  BASIC_BAR_TOOLTIP_HR: "Часы вне пика.",
  PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP:
    "Язык для использования в контроллере панели.",
  EDIT_IRPD_FIELD_ENERGY_TIME: "Время электроэнергии",
  EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP:
    "Время возврата для продолжения выполнения команды после отключения питания.",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY: "Время подачи электроэнергии",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP:
    "Время возврата для продолжения выполнения команды после отключения питания.",
  MEASUREMENT_MAX_VALUE: "Шкала датчика",
  MEASUREMENT_PULSES_VALUE: "Объём",
  MEASUREMENT_MAX_VALUE_TOOLTIP:
    "Относится к предельному времени, в течение которого шарнир будет находиться под давлением. Если это время превышено без достижения давления, насос отключается для обеспечения безопасности.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP:
    "Оборудование для измерения давления в центре шарнира.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP:
    "Если шарнир работает вне пределов давления в течение периода, превышающего установленный, действие шарнира прекращается.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE: "Условие остановки.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP:
    '"По значению" команда поворота останавливается после достижения порога, установленного в поле "Значение". "По декременту" поворот увеличивает скорость, чтобы компенсировать приложенное лезвие.',
  EDIT_PLUVIOMETER_MM_TO_STOP: "Значение",
  EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP:
    "Порог осадков, в миллиметрах, установленный в данной области.",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END: "Шкала датчика",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP:
    "Значение, указывающее на масштаб используемого дождемера.",
  PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT: "Тайм-аут насоса",
  PRESSURE_TIMEOUT_INPUT:
    "Указывает время, в течение которого стержень должен нагнетать давление. Если это время превышено без достижения давления, насос отключается для обеспечения безопасности.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Минимальное значение датчика",
  PIVOT_CONFIG_FIELD_SETORIAL: "Поворот сектора",
  PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP:
    "Поворот, рабочий угол которого отличается от 360°.",
  PIVOT_CONFIG_FIELD_SPEED: "Скорость последней башни",
  PIVOT_CONFIG_FIELD_SPEED_TOOLTIP: "Скорость последней башни при 100%.",
  PIVOT_CONFIG_FIELD_POWER_TIME: "Время возврата после отключения питания",
  PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP:
    "Время, в течение которого команда остается в силе после отключения питания.",
  PIVOT_CONFIG_FIELD_PUMP_TIME: "Время нагнетания давления",
  PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP:
    "Означает время, в течение которого шарнир должен находиться под давлением. Если это время превышено без достижения давления, насос отключается для обеспечения безопасности.",
  PIVOT_CONFIG_FIELD_POWER_RANGE: "Рабочее напряжение",
  PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP:
    "Функция, предотвращающая работу оборудования за пределами минимального и максимального напряжений.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MIN: "Минимальное рабочее напряжение",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MAX: "Максимальное рабочее напряжение",
  ENERGY_TIME_DESCRIPTION:
    "В этой функции вы можете установить время в минутах, которое насос должен ждать, чтобы перезапуститься после сбоя питания.",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP:
    "Местоположение последней вышки в точке 0°.",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP:
    "Местоположение центра поворотного узла.",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER: "Расположение Yстройства",
  PIVOT_CONFIG_V5_FIELD_NAME: "Имя поворотного устройства",
  PIVOT_CONFIG_V5_FIELD_LANGUAGE: "Язык устройства",
  PIVOT_CONFIG_V5_FIELD_AREA: "Площадь орошения",
  PIVOT_CONFIG_FIELD_AREA: "Поливаемая площадь",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME: "Таймаут",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_LIMIT_TIME: "Таймаут",
  PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP:
    "Функция, не позволяющая оборудованию работать за пределами минимального и максимального напряжений дольше порогового времени.",
  PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL: "Часы оборудования",
  PIVOT_CONFIG_FIELD_POTENCY: "Мощность насоса",
  PIVOT_CONFIG_FIELD_RTC: "Установить часы оборудования",
  PIVOT_CONFIG_FIELD_HOUR_RANGE: "Время пика",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN: "Начальное время пика",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX: "Время окончания пика",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_ENABLE: "Включить датчик дождя",
  PIVOT_CONFIG_V5_FIELD_CLOCK_DATE: "Дата оборудования",
  PIVOT_CONFIG_V5_FIELD_CLOCK_TIME: "Дата оборудования",
  EDIT_PIVOT_LABEL_POWER: "Мощность Hасоса",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE: "Показания давления",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY: "Время задержки",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH: "Время нестабильного реле давления",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1: "Включить пиковое время 1",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2: "Включить пиковое время 2",
  PIVOT_CONFIG_V5_FIELD_ANGLE_START: "Начальный угол",
  PIVOT_CONFIG_V5_FIELD_ANGLE_END: "Конечный угол",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_START: "Начальный угол",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END: "Конечный угол",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_START: "Начальный угол",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_END: "Конечный угол",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_START: "Начальный угол",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_END: "Конечный угол",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME: "Таймаут автореверса",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP:
    "Время, необходимое шарниру, чтобы остановить движение и изменить направление.",
  EDIT_PLUVIOMETER_MODE_BY_VALUE: "По значению",
  EDIT_PLUVIOMETER_MODE_DECREMENT: "По декременту",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF: "Всегда выключен",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON: "Всегда включен",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS: "По сегментам",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH: "По концу хода",
  SIMPLE_IRRIGATION_BY_ANGLE: "По углу",
  PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER: "Бустерный насос",
  PIVOT_CONFIG_FIELD_ENDGUN: "Конечное ружье",
  IRPD_FIELD_NAME: "Название насоса",
  PIVOT_CONFIG_V5_FIELD_POTENCY: "Мощность насоса",
  PIVOT_CONFIG_V5_IS_DATASHEET: "Технический паспорт",
  IRPD_MONTHLY_WATER_LIMIT: "Месячный лимит потребления воды",
  EDIT_IRPD_FIELD_RTCDATE: "Часы оборудования",
  EDIT_IRPD_FIELD_HOUR_RANGE_MAX: "Конец пикового часа",
  EDIT_IRPD_FIELD_HOUR_RANGE_MIN: "Начало часа пик",
  EDIT_PIVOT_LABEL_POWER_SETTINGS: "Настройки мощности",
  MEASUREMENT_OFFSET: "Смещение измерения",
  MEASUREMENT_OFFSET_TOOLTIP:
    "Установка значения для зондовых измерений, приближение их к реальным.",
  METERSYSTEM_FLOW_CURVE: "Остаточный Расход",
  METERSYSTEM_FLOW_CURVE_TOOLTIP:
    "Когда уровнемер установлен в реке, платформа Irricontrol Platform предоставляет возможность добавить константы кривой River Key, полученные в результате измерений в ней. С помощью этого уравнения и значения, измеренного на датчике уровня, платформа Irricontrol рассчитывает остаточный расход, показанный в Отчете на главной странице анализируемой измерительной системы.",
  PERMISSIONS_REGISTER_USER_INFO: "Разрешения",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL: "Центральное Pасположение",
  PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP:
    "Расположение последней башни в точке 0°.",
  PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP:
    "Расположение торцевого угла шарнира.",
  PIVOT_CONFIG_FIELD_CENTER_TOOLTIP: "Расположение центра шарнира.",
  NO_PERMISSION: "Нет разрешения",
  NO_PERMISSION_DESCRIPTION:
    "В этом разрешении пользователь не будет иметь доступа к выбранному оборудованию. Таким образом, он не появится на главном экране фермы",
  VISUALIZER: "документ-камера",
  VIZUALIZER_DESCRIPTION:
    "При таком разрешении пользователь имеет доступ к оборудованию и может просматривать все, начиная от отчетов и заканчивая отправленными командами. Однако пользователь не сможет отправлять команды на оборудование, вносить изменения в конфигурацию оборудования и не сможет получить доступ к странице редактирования фермы.",
  OPERATOR: "Оператор",
  OPERATOR_DESCRIPTION:
    "В этом разрешении пользователь имеет те же полномочия, что и Просмотрщик, кроме того, он может посылать команды оборудованию. Однако пользователь не сможет вносить изменения в настройки оборудования и не сможет получить доступ к странице редактирования фермы.",
  CONFIGURATOR: "Конфигуратор",
  CONFIGURATOR_DESCRIPTION:
    "В этом разрешении пользователь имеет те же полномочия, что и Оператор, кроме того, он сможет вносить правки в настройки оборудования. Однако при этом вы не сможете получить доступ к странице редактирования фермы",
  ADMINISTRATOR: "Администратор",
  ADMINISTRATOR_DESCRIPTION:
    "Наконец, как Администратор, пользователь будет иметь все разрешения Конфигуратора. Он будет иметь доступ к отчетам, истории команд, отправке новых команд, редактированию всей информации об оборудовании, редактированию фермы, а также сможет добавлять пользователей и управлять ими",
  PERMISSION_INFO: "Информация о разрешениях",
  HISTORIC_PIVOT_GPS_V5_SENSOR: "${} | ${} | ${} - ${} | ${}",
  HISTORIC_PIVOT_GPS_V5: "${} | ${} | ${} | ${}",
  VALUE: "${}",
  VALUE_HOUR_AND_MINUTE: "${}h ${}min",
  VALUE_AND_UNIT_HOUR: "${}h",
  VALUE_AND_UNIT_BAR: "${} бар",
  VALUE_AND_UNIT_MIN: "${} min",
  VALUE_AND_UNIT_DEGREES: "${}°",
  VALUE_AND_UNIT_PERCENT_MILIMETERS: "${}% - ${} мм",
  VALUE_AND_UNIT_MILIMETERS: "${} мм",
  VALUE_OF_TURNS: "${} оборотов",
  VALUE_AND_UNIT_POWER: "${} вольт",
  EDIT_PIVOT_MONITOR_LABEL: "Monitor Radio",
  CREATE_DEVICE_MODAL_MONITOR_CODE: "Monitor Radio",
  GPS_FOUND: "GPS/Monitor found",
  EDIT_FARM_USER_PERMISSION_ERROR: "Error saving permissions",
  MOBILE_ONLY_INFO: "This feature only works on the mobile version.",
  I18N_KEYS: "TAGS I18N",

  ERROR_MAX_16_CHARACTERS: "Поле должно содержать не более 16 символов",
  ERROR_MAX_32_CHARACTERS: "Поле должно содержать не более 32 символов",
  ERROR_16_CARACTERS_RADIO_CONTROLER:
    "Поле Controller Radio должно содержать до 16 символов",
  ERROR_16_CARACTERS_RADIO_GPS: "Поле GPS Radio должно содержать 16 символов",
  ERROR_16_CARACTERS_RADIO_PUMP:
    "Поле Pump Cable Radio должно содержать 16 символов",
  ERROR_16_CARACTERS_RADIO_MONITOR:
    "Поле Radio монитора должно содержать 16 символов",
  ERROR_16_CARACTERS_RADIO_REPEATER:
    'Поле "Радио" повторителя должно содержать 16 символов',
  ERROR_16_CARACTERS_RADIO_IMETER:
    "Поле Radio в IMeter должно содержать 16 символов",

  ERROR_INVALID_CHARACTERS: "Поле содержит недопустимые символы",
  ERROR_INVALID_AMOUNT_MIN: "Ниже минимального количества символов",
  ERROR_INVALID_AMOUNT_MAX: "Выше максимального количества символов",
  ERROR_EMPTY_FIELD: "Это поле не может быть пустым",
  ERROR_NEGATIVE_VALUES: "Заполнять только положительными значениями",
  ERROR_ZIP_CODE: "Почтовый индекс не найден",

  ERROR_MIN_MAX_PRECIPITATION: "Должно быть недопустимое значение лезвия",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN:
    "Минимальное значение напряжения, больше максимального",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX:
    "Минимальное значение напряжения, больше максимального",
  ERROR_GENERAL: "Поле имеет недопустимые значения",
  ERROR_ZERO: "Значение поля не может быть равно 0",
  ERROR_ADD_NAME: "Добавьте имя для сегмента",
  ERROR_PIVOT_LAT_AND_LONG: "Неверные широта и долгота",
  ERROR_PIVOT_LAT: "Неверная широта",
  ERROR_PIVOT_LONG: "Неверная долгота",
  FORGET_PASSWORD_FAILED:
    "Пароли должны содержать:\nAt минимум 8 символов;\nAt минимум одну цифру;\nAt минимум один специальный символ;",
  SIGN_UP_PASSWORD: "Пароль (8+ символов, включая цифры и специальные символы)",
  EDIT_PROFILE_ADDRESS_2: "Address details",
  CPF_ALREADY_USED: "CPF already used on the platform",
  SIGN_UP_WARNING_ERROR: "This email or CPF is already registered",
  IRPD_STATUS_NO_COMMUNICATION: "No Communication",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5: "5.0",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1: "5.1",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_2: "5.2",
  CREATE_DEVICE_MODAL_METER_PROTOCOL_5_3: "5.3",
  METERSYSTEM_WATER_LEVEL: "Измерение",
  METERSYSTEM_OFFSET_WATER_LEVEL: "Смещение",
  METERSYSTEMGH_WATER_LEVEL: "Уровень резервуара",
  METERSYSTEM_AJUSTED_WATER_LEVEL: "Уровень водохранилища",
  PIVOT_REPORT_TOTAL_COST_HINT:
    "To change the prices of the power ranges, go to pivot settings.",
  EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE: "Prices of Power Ranges",
  ERROR_VALUE_UNDER: "Это значение меньше принятого минимума",
  ERROR_VALUE_OVER: "Это значение больше принятого максимального",
  ERROR_MIN_MAX: "Должно иметь значения больше нуля",
  CPF_ALREADY_REGISTERED: "Этот email или CPF уже зарегистрирован",
  CURRENCY: "$",
  PIVOT_CONFIG_V5_BRAND_MODEL: "Brand Model",
  CREATE_DEVICE_MODAL_OTHER: "-",
  IRPD_PRESSURE_CHART_DAY: "день",
  IRPD_PRESSURE_CHART_NUMBER_OF_POINTS: "Количество баллов",
  NUMBER_OF_POINTS_12: "12",
  NUMBER_OF_POINTS_24: "24",
  NUMBER_OF_POINTS_30: "30",
  BASIC_INFO_BOX_CHART_PLUVIOMETER: "Датчик Дождя",
  BASIC_INFO_BOX_CHART_WATER_CONSUMPTION: "График потребления",
  BASIC_INFO_BOX_CHART_WATER_PRESSURE: "График давления",
  IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR: "датчик давления",
  TABLE_HP: "Час пик",
  BASIC_INFO_BOX_CHART_GENERATED_FOR: "Chart for ${}",
  MAINTENANCE_REASON_01: "Supply - Generator",
  MAINTENANCE_SPEC_NONE_01: "---",
  MAINTENANCE_SPEC_01A: "injector nozzle",
  MAINTENANCE_SPEC_01B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_01C: "injection pump",
  MAINTENANCE_SPEC_01D: "injector barrel",
  MAINTENANCE_SPEC_01E: "fuel shortage",
  MAINTENANCE_SPEC_01F: "fuel filter",
  MAINTENANCE_SPEC_01G: "water separator filter",
  MAINTENANCE_SPEC_01H: "fuel hose",
  MAINTENANCE_SPEC_01I: "fuel",
  MAINTENANCE_SPEC_01J: "fuel tank leakage",
  MAINTENANCE_REASON_02: "Supply - Water Pump",
  MAINTENANCE_SPEC_NONE_02: "---",
  MAINTENANCE_SPEC_02A: "injector nozzle",
  MAINTENANCE_SPEC_02B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_02C: "injection pump",
  MAINTENANCE_SPEC_02D: "injector barrel",
  MAINTENANCE_SPEC_02E: "fuel shortage",
  MAINTENANCE_SPEC_02F: "fuel filter",
  MAINTENANCE_SPEC_02G: "water separator filter",
  MAINTENANCE_SPEC_02H: "fuel hose",
  MAINTENANCE_SPEC_02I: "fuel",
  MAINTENANCE_SPEC_02J: "fuel tank leakage",
  MAINTENANCE_REASON_03: "Cooling - Water Pump",
  MAINTENANCE_SPEC_NONE_03: "---",
  MAINTENANCE_SPEC_03A: "water pump",
  MAINTENANCE_SPEC_03B: "alternator belt",
  MAINTENANCE_SPEC_03C: "belt tightener",
  MAINTENANCE_SPEC_03D: "primary air filter",
  MAINTENANCE_SPEC_03E: "secondary air filter",
  MAINTENANCE_SPEC_03F: "propeller",
  MAINTENANCE_SPEC_03G: "cracked radiator",
  MAINTENANCE_SPEC_03H: "radiator cap",
  MAINTENANCE_SPEC_03I: "belt tensioner",
  MAINTENANCE_REASON_04: "Cooling - Pivot",
  MAINTENANCE_SPEC_NONE_04: "---",
  MAINTENANCE_SPEC_04A: "water pump",
  MAINTENANCE_SPEC_04B: "alternator belt",
  MAINTENANCE_SPEC_04C: "belt tightener",
  MAINTENANCE_SPEC_04D: "primary air filter",
  MAINTENANCE_SPEC_04E: "secondary air filter",
  MAINTENANCE_SPEC_04F: "propeller",
  MAINTENANCE_SPEC_04G: "cracked radiator",
  MAINTENANCE_SPEC_04H: "radiator cap",
  MAINTENANCE_SPEC_04I: "belt tensioner",
  MAINTENANCE_REASON_05: "Centrifugal Pump",
  MAINTENANCE_SPEC_NONE_05: "---",
  MAINTENANCE_SPEC_05A: "gasket - tighten",
  MAINTENANCE_SPEC_05B: "gasket - change",
  MAINTENANCE_SPEC_05C: "lubricant - low level",
  MAINTENANCE_SPEC_05D: "damaged retainer",
  MAINTENANCE_SPEC_05E: "broken bearing",
  MAINTENANCE_REASON_06: "Vinasse Canal",
  MAINTENANCE_SPEC_NONE_06: "---",
  MAINTENANCE_SPEC_06A: "lack of vinasse",
  MAINTENANCE_SPEC_06B: "gate failure",
  MAINTENANCE_SPEC_06C: "low flow",
  MAINTENANCE_REASON_07: "Electricity - Water Pump",
  MAINTENANCE_SPEC_NONE_07: "---",
  MAINTENANCE_SPEC_07A: "alternator",
  MAINTENANCE_SPEC_07B: "automatic",
  MAINTENANCE_SPEC_07C: "battery",
  MAINTENANCE_SPEC_07D: "battery - cable",
  MAINTENANCE_SPEC_07E: "battery - support",
  MAINTENANCE_SPEC_07F: "wiring",
  MAINTENANCE_SPEC_07G: "fuse",
  MAINTENANCE_SPEC_07H: "starter motor",
  MAINTENANCE_SPEC_07I: "auxiliary start relay",
  MAINTENANCE_SPEC_07J: "solenoid",
  MAINTENANCE_REASON_08: "Electricity - Pivot",
  MAINTENANCE_SPEC_NONE_08: "---",
  MAINTENANCE_SPEC_08A: "alternator",
  MAINTENANCE_SPEC_08B: "battery",
  MAINTENANCE_SPEC_08C: "battery - cabble",
  MAINTENANCE_SPEC_08D: "battery - support",
  MAINTENANCE_SPEC_08E: "drive contactor",
  MAINTENANCE_SPEC_08F: "wiring",
  MAINTENANCE_SPEC_08G: "fuse",
  MAINTENANCE_SPEC_08H: "generator - brush",
  MAINTENANCE_SPEC_08I: "generator - brush holder",
  MAINTENANCE_SPEC_08J: "drive micro",
  MAINTENANCE_SPEC_08K: "security micro",
  MAINTENANCE_SPEC_08L: "starter motor",
  MAINTENANCE_SPEC_08M: "gearmotor",
  MAINTENANCE_SPEC_08N: "percent meter",
  MAINTENANCE_SPEC_08O: "plate",
  MAINTENANCE_REASON_09: "Structure - Water Pump",
  MAINTENANCE_SPEC_NONE_09: "---",
  MAINTENANCE_SPEC_09A: "header",
  MAINTENANCE_SPEC_09B: "support cable ratchet",
  MAINTENANCE_SPEC_09C: "suction hose",
  MAINTENANCE_SPEC_09D: "suction hose - clamp",
  MAINTENANCE_SPEC_09E: "suction hose - support cable",
  MAINTENANCE_SPEC_09F: "flat tire",
  MAINTENANCE_SPEC_09G: "wheel bearing",
  MAINTENANCE_SPEC_09H: "expansion tank",
  MAINTENANCE_SPEC_09I: "expansion tank - handle",
  MAINTENANCE_SPEC_09J: "expansion tank - register",
  MAINTENANCE_REASON_10: "Structure - Pivot",
  MAINTENANCE_SPEC_NONE_10: "---",
  MAINTENANCE_SPEC_10A: "cardan",
  MAINTENANCE_SPEC_10B: "cardan - flexible joint",
  MAINTENANCE_SPEC_10C: "cardan - universal joint",
  MAINTENANCE_SPEC_10D: "misalignment",
  MAINTENANCE_SPEC_10E: "sprinkler cleaning",
  MAINTENANCE_SPEC_10F: "pivot cleaning",
  MAINTENANCE_SPEC_10G: "span junction hose",
  MAINTENANCE_SPEC_10H: "broken hose",
  MAINTENANCE_SPEC_10I: "wheel stud",
  MAINTENANCE_SPEC_10J: "retaining plate",
  MAINTENANCE_SPEC_10K: "flat tire",
  MAINTENANCE_SPEC_10L: "sprinkler replacement",
  MAINTENANCE_SPEC_10M: "hose replacement",
  MAINTENANCE_SPEC_10N: "loose wheel",
  MAINTENANCE_SPEC_10O: "security system inoperative",
  MAINTENANCE_SPEC_10P: "front reducer support",
  MAINTENANCE_SPEC_10Q: "rear reducer support",
  MAINTENANCE_SPEC_10R: "trusses",
  MAINTENANCE_SPEC_10S: "reinforcement tube",
  MAINTENANCE_SPEC_10T: "base beam",
  MAINTENANCE_REASON_11: "Lubrication - Generator",
  MAINTENANCE_SPEC_NONE_11: "---",
  MAINTENANCE_SPEC_11A: "lubricant - filter",
  MAINTENANCE_SPEC_11B: "lubricant - low level",
  MAINTENANCE_SPEC_11C: "leakage - flexible",
  MAINTENANCE_SPEC_11D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_11E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_11F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_11G: "leakage - mirror cover",
  MAINTENANCE_SPEC_11H: "leakage - turbine",
  MAINTENANCE_REASON_12: "Lubrication - Water Pump",
  MAINTENANCE_SPEC_NONE_12: "---",
  MAINTENANCE_SPEC_12A: "lubricant - filter",
  MAINTENANCE_SPEC_12B: "lubricant - low level",
  MAINTENANCE_SPEC_12C: "leakage - flexible",
  MAINTENANCE_SPEC_12D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_12E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_12F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_12G: "leakage - mirror cover",
  MAINTENANCE_SPEC_12H: "leakage - turbine",
  MAINTENANCE_REASON_13: "Scheduled Downtime",
  MAINTENANCE_SPEC_NONE_13: "---",
  MAINTENANCE_SPEC_13A: "supply",
  MAINTENANCE_SPEC_13B: "change of location",
  MAINTENANCE_SPEC_13C: "pivoting",
  MAINTENANCE_SPEC_13D: "towing",
  MAINTENANCE_SPEC_13E: "hose change",
  MAINTENANCE_SPEC_13F: "wheels turning",
  MAINTENANCE_REASON_NONE: "---",
  MAINTENANCE_SPEC_NONE: "---",
  MAINTENANCE_NOTE_NONE: "-> ",
  MAINTENANCE_BUTTON_TOOLTIP_ENABLE: "Включить Режим Обслуживания",
  MAINTENANCE_BUTTON_TOOLTIP_DISABLE: "Отключить Режим Обслуживания",
  MAINTENANCE_MODAL_REASON_QUESTION: "Inform reason for downtime?",
  MAINTENANCE_MODAL_REASON_LABEL: "Select a reason:",
  MAINTENANCE_MODAL_SPEC_QUESTION: "Specify the reason for downtime?",
  MAINTENANCE_MODAL_SPEC_LABEL: "Select a specification:",
  MAINTENANCE_MODAL_NOTE_QUESTION: "Any notes?",
  MAINTENANCE_MODAL_NOTE_LABEL: "Note:",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE:
    "Включить Режим Обслуживания?",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE:
    "Отключить Режим Обслуживания?",
  MAINTENANCE_MODALS_BUTTON_CANCEL: "Отменить",
  MAINTENANCE_MODALS_BUTTON_NEXT: "следующий",
  MAINTENANCE_MODALS_BUTTON_CONFIRM: "Подтвердить",
  CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER: "Linear Pivot Monitor",
  CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID: "Invalid base radio",
  CREATE_DEVICE_MODAL_FLOW_RATE: "Flow Rate",
  CREATE_DEVICE_MODAL_PIVOT_LENGTH: "Length",
  CREATE_DEVICE_MODAL_PIVOT_SPEED: "Speed",
  EDIT_MONITOR_LINEAR_LABEL: "Edit Pivot Linear Monitor",
  PIVOT_CONFIG_FIELD_SIZE: "Tamanho do Pivô",
  PIVOT_CONFIG_FIELD_SIZE_TOOLTIP: "Comprimento total do Pivô Linear",
  PIVOT_CONFIG_V5_LINEAR_SPEED: "Veloc. do Pivô Linear",
  PIVOT_CONFIG_V5_LINEAR_SPEED_TOOLTIP: "Velocidade do pivô linear à 100%.",
  BASIC_INFO_BOX_TOTAL_WET_HOURS: "Total de horas trabalhadas",
  EDIT_LINEAR_PIVOT_MONITOR_LABEL: "Edit Linear Pivot Monitor",
  LPM_CONFIG_FIELD_LENGTH: "Length",
  LPM_CONFIG_FIELD_LENGTH_TOOLTIP: "Linear pivot total length",
  LPM_CONFIG_V5_FIELD_SPEED: "Speed",
  LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Speed of the linear pivot to 100%",
  BASIC_INFO_BOX_CHART_GENERATED_BETWEEN:
    "Chart for the period from ${} to ${}",
  BASIC_INFO_BOX_WATER_PRESSURE: "Давление",
  GRAPHIC_SCALE: "Graphic scale",
  GRAPHIC_SCALE_TOOLTIP:
    "The Graphic Scale refers to the maximum meters of water column (MCA) it can measure.",
  LPM_EFFICIENCY: "Эффективность: ",
  IMETER_FIELD_NAME: "Meter name",
  METERSYSTEM_FIELD_NAME: "System Name",
  MCA_10: "10 MCA",
  MCA_20: "20 MCA",
  MCA_100: "100 MCA",
  IMETER_CONFIG_V5_FIELD_SENSOR: "Sensor",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS: "оплодотворение: ",
  REPORT_TABLE_OPERATION_NOTE: "Наблюдение",
  REPORT_TABLE_IRRIGATION_MODE: "Режим",
  PANEL_STREAM_STATUS_26: "Удобрение",
  EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG: "Фертиригационный насос",
  SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP: "оплодотворение",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE: "Комментарии",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED: "Комментарии: ",
  IRRIGATION_MODE_FERTIRRIGATION: "оплодотворение",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP:
    "Выберите «Включить орошение фертигацией», если вы хотите использовать функциональность фертигации в командах орошения этой опорной точки.",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK: "Активировать оплодотворение",
  EDIT_IMETER_LEVEL_TAB: "Level",
  EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE: "Sensor's information",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE: "Chart configuration",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT:
    "These values are for inserting reference lines into the measurement chart.",
  EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT: "Maximum value",
  EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT: "Minimum value",
  BASIC_INFO_BOX_CHART_LAKE_LEVEL: "Level Chart",
  BASIC_INFO_BOX_LEVEL: "Level",
  GENERAL_BOX_MAX_LIMIT: "Maximum value:",
  GENERAL_BOX_MIN_LIMIT: "Minimum value:",
  PERIOD_TOTAL_RAINFALL: "Rain total for the period",
  PLUVIOMETER_CHART_NUMBER_OF_BARS: "Количество Бар",
  IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS: "Количество Бар",
  IMETER_LEVEL_CHART_NUMBER_OF_POINTS: "Количество баллов",
  SEGMENTS_PLANT_DATE: "Дата посадки",
  SEGMENTS_HARVEST_DATE: "Предполагаемая дата сбора урожая",
  EDIT_PIVOT_LABEL_PIVOT_SEGMENTS: "Сегменты и посадки",
  SEGMENT_NAME: "Название сегмента/растения",
  MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY: "Crop",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_PLANT_DATE: "Посадка: ${}",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_HARVEST_DATE: "Урожай: ${}",
  NEW_SEGMENT: "Добавить/редактировать сегменты",
  HOVER_ON_SEGMENT:
    "Наведите курсор на рисунок для получения информации о сегменте",
  ANGLE_RANGE: "Диапазон углов",
  ACCUMULATED_BLADE: "Накопленное лезвие",
  SEGMENT_TYPE: "Тип посадки/управления",
  CROP_WARNING_CONFIG:
    "Для получения дополнительной информации настройте даты посадки/управления в edit pivot",
  CROP_WARNING_INFO: "В этом временном диапазоне нет информации.",
  CROP_WARNING_SECTORIAL:
    "Эта опорная точка является секторальной. Сегменты опорной точки состоят только из цветных областей карты.",
  PLUVIOMETER_WITHOUT_CONFIG: "Pluviometer not enabled",
  ACTIVATED: "Activated",
  DEACTIVATED: "Deactivated",
  EDIT_PIVOT_V5_AUTOREVERSION_MODE: "Условие остановки.",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE: "По углу",
  ENDGUN_START_ANGLE: "Начало",
  ENDGUN_FINAL_ANGLE: "Конец",
  EDIT_IRPD_LOAD_SUCCESS: "Конфигурация успешно загружена!",
  EDIT_IRPD_LOAD_ERROR: "Ошибка загрузки конфигурации.",
  EDIT_IRPD_LOAD_BUTTON: "Загрузить",
  HISTORIC_CONFIG_INF_MONITOR: "Monitor",
  HISTORIC_CONFIG_INF_CONTROLLER: "Контроллер",
  HISTORIC_CONFIG_INF_HARDWARE: "Hardware",
  HISTORIC_CONFIG_INF_GPS: "GPS",
  HISTORIC_CONFIG_INF_PUMP: "Насос",
  HISTORIC_CONFIG_INF_DATASHEET: "Технический паспорт",
  HISTORIC_CONFIG_INF_IMETER: "Meter",
  HISTORIC_PANEL_LABEL_MONITOR: "MO",
  HISTORIC_PANEL_LABEL_CONTROLLER: "C",
  HISTORIC_PANEL_LABEL_HARDWARE: "H",
  HISTORIC_PANEL_LABEL_GPS: "G",
  HISTORIC_PANEL_LABEL_PUMP: "P",
  HISTORIC_PANEL_LABEL_IMETER: "ME",
  LEGEND: "Legend",
  ALL_CONFIGURATIONS: "All Configurations",
  CONFIG_NOT_SENT: "configuration not sent",
  CONFIG_SENT: "configuration sent, but not received",
  CONFIG_RECEIVED: "configuration received",
  TEXT_CONFIG_NOT_SENT: "Configuration was not sent to the device.",
  TEXT_CONFIG_SENT:
    "Configuration was sent to the device, but it was not received.",
  TEXT_CONFIG_RECEIVED: "Configuration was received by the device.",
  IRPD_EDIT_LABEL: "Edit Water Pump",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START: "Peak time start",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END: "Peak time end",

  BAUER_PIVOT_EDIT_ZERO_POSITION: "нулевое положение",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON: "Всегда включен",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE: "автоматический режим",
  BAUER_PIVOT_EDIT_SECTOR_BEGIN: "Начать сектор",
  BAUER_PIVOT_EDIT_SECTOR_END: "Конечный сектор",
  BAUER_PIVOT_EDIT_AUTOREVERSE: "Нормальный",
  BAUER_PIVOT_EDIT_PARKING_FUNCTION: "Позиция парковки",
  BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION: "Промежуточная позиция",

  BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE: "Ток давление",
  BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE: "Ток угол",
  BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE: "Скорость орошения",

  BAUER_PIVOT_HISTORY_TOOLTIP_USER: "Отправлено пользователем",
  BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE: "Отправлено устройством",

  BAUER_PIVOT_HISTORY_USER: "Обзор",
  BAUER_PIVOT_HISTORY_PROTOCOL: "Протокол",
  BAUER_PIVOT_HISTORY_PROTOCOL_TABLE: "Таблица протокола",

  BAUER_PIVOT_STATUS_0: "Машина не работает",
  BAUER_PIVOT_STATUS_1: "Машина работает со скоростью подачи",
  BAUER_PIVOT_STATUS_2: "Ожидание времени начала, чтобы начать орошение",
  BAUER_PIVOT_STATUS_3: "Орошение остановилось",
  BAUER_PIVOT_STATUS_4: "Схема безопасности прервана",
  BAUER_PIVOT_STATUS_5: "Машина, работающая в аварийном режиме",
  BAUER_PIVOT_STATUS_6:
    "Машина остановлена с помощью пульта дистанционного управления",
  BAUER_PIVOT_STATUS_7: "Выключатель безопасности выключился",
  BAUER_PIVOT_STATUS_8: "Давление воды слишком низкое",
  BAUER_PIVOT_STATUS_9: "Остановился из -за сильного ветра",
  BAUER_PIVOT_STATUS_10: "Напряжение слишком низкое",
  BAUER_PIVOT_STATUS_11: "Ошибка шины CAN",
  BAUER_PIVOT_STATUS_12: "Ошибка i2c",
  BAUER_PIVOT_STATUS_13: "Начать время задержки активно",
  BAUER_PIVOT_STATUS_14: "Положение парковки достигнуто",
  BAUER_PIVOT_STATUS_15: "Машина работает в режиме реверса",
  BAUER_PIVOT_STATUS_16: "Ошибка GPS",
  BAUER_PIVOT_STATUS_17: "Остановился из -за осадков",
  BAUER_PIVOT_STATUS_18: "Угловая ошибка",
  BAUER_PIVOT_STATUS_19: "Ошибка датчика SPAN",
  BAUER_PIVOT_STATUS_20: "Ошибка датчика наклона",
  BAUER_PIVOT_STATUS_21: "Последняя ошибка башни",
  BAUER_PIVOT_STATUS_22:
    "Расстояние до похороненного руководящего кабеля слишком большого",
  BAUER_PIVOT_STATUS_23: "Похоронный сигнал руководства слишком низкий",
  BAUER_PIVOT_STATUS_24: "Погребенная ошибка руководства",
  BAUER_PIVOT_STATUS_25: "Ошибка углового преобразователя частоты",
  BAUER_PIVOT_STATUS_26: "Ошибка компрессора",
  BAUER_PIVOT_STATUS_27: "Движение не обнаружено",
  BAUER_PIVOT_STATUS_28: "Промежуточное положение остановки достигнуто",
  BAUER_PIVOT_STATUS_40: "Давление воды слишком высокое",
  BAUER_PIVOT_STATUS_41: "Аварийный выключатель нажал",

  BAUER_PIVOT_STATUS_NEW_CON: "Новая связь",
  BAUER_PIVOT_STATUS_MAN_START: "Ручной запуск",
  BAUER_PIVOT_STATUS_MAN_STOP: "Ручная остановка",
  BAUER_PIVOT_STATUS_ACK_FWD: "Передача получена",
  BAUER_PIVOT_STATUS_ACK_REV: "Получен обратный ход",
  BAUER_PIVOT_STATUS_ACK_STOP: "Получена остановка",
  BAUER_PIVOT_STATUS_START_FWD: "Отправлена команда вперед",
  BAUER_PIVOT_STATUS_START_REV: "Отправлена команда обратного хода",
  BAUER_PIVOT_STATUS_STOP_CMD: "Отправлена команда остановки",
  BAUER_PIVOT_STATUS_GET_CONFIG: "Получить конфигурацию",
  BAUER_PIVOT_STATUS_SET_CONFIG: "Установить конфигурацию",
  BAUER_PIVOT_STATUS_ACK_CONFIG: "Конфигурация получена",
  BAUER_PIVOT_STATUS_ACK_VRI: "Получен VRI",
  BAUER_PIVOT_STATUS_RFTP_ERROR_1: "Файл: Нет данных",
  BAUER_PIVOT_STATUS_RFTP_MAX_RETRY: "Файл: Превышен лимит",
  BAUER_PIVOT_STATUS_RFTP_ERR_FILE: "Файл: ошибка контрольной суммы",
  BAUER_PIVOT_STATUS_RFTP_OK: "Файл: успешно",
  BAUER_PIVOT_STATUS_ACK_START: "Старт получен",
  BAUER_PIVOT_STATUS_DEFAULT: "Обновление",

  PUMP: "Насос",
  IMETER: "iManage",
  EDIT_REPEATER_LABEL_GENERAL: "Общий",
  EDIT_REPEATER_CENTRAL_LABEL: "Центральное радио",
  EDIT_REPEATER_RADIO_LABEL: "Радио ретранслятора",
  REPEATER_FIELD_NAME: "Имя",
  REPEATER_CONFIG_FIELD_ENERGY_TYPE: "Тип",
  REPEATER_CONFIG_FIELD_SOLAR: "Солнечная",
  REPEATER_CONFIG_FIELD_BIVOLT: "Бивольт",
  REPEATER_CONFIG_DEVICE_FIELD_GP_CENTER: "Расположение устройства",
  REPEATER_EDIT_LABEL: "Repeater",
  RADIO_SWAP_CONTROLLER_TITLE: "Сменить радио контроллера",
  RADIO_SWAP_GPS_TITLE: "Заменить радио GPS",
  RADIO_SWAP_PUMP_TITLE: "Сменить радио насоса",
  RADIO_SWAP_IMETER_TITLE: "Заменить радио iManage",
  RADIO_SWAP_REPEATER_TITLE: "Обменять радио повторителя",
  RADIO_SWAP_CONTROLLER_SUBTITLE:
    "Выберите поворот для изменения радио контроллера.",
  RADIO_SWAP_GPS_SUBTITLE: "Выберите точку поворота для смены GPS-радио.",
  RADIO_SWAP_PIVOT_PUMP_SUBTITLE:
    "Выберите точку поворота для изменения радиостанции насоса.",
  RADIO_SWAP_IMETER_SUBTITLE: "Выберите iManage для смены радио.",
  RADIO_SWAP_PUMP_SUBTITLE: "Выберите насос для изменения радио.",
  RADIO_SWAP_REPEATER_SUBTITLE: "Выберите повторитель для изменения радио.",
  REPEATER: "Repeater",
  BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO:
    "Данные о давлении, полученные между ${} и ${}",
  BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO:
    "Сравнительные данные, полученные между ${} и ${}",
  BASIC_INFO_BOX_COMPARE_DATE_START: "Начать в:",
  BASIC_INFO_BOX_COMPARE_DATE_END: "Stop at:",
  BASIC_INFO_BOX_PRESSURE_INFO:
    'Значения "Давление" относятся к временному интервалу, установленному в отчете. Значения "Сравнительного давления" относятся к временному интервалу, выбранному в данном графике. Используйте данные "Сравнительное давление" для сравнения показаний давления для каждого орошаемого угла между различными временными периодами.',
  HISTORIC_IRPD_STREAM_CONSUMPTION: "Estimated consumption",
  HISTORIC_IRPD_STREAM_PRESSURE: "Pressure",
  DEVICE_BOX_SHOW_PERIODIC_STREAMS: "Show periodic streams",
  LOGIN_ERROR_ACCEPT_PRIVACY:
    "To continue accessing, accept the privacy policy",
  EDIT_FARM_BILLING: "Billing",
  FARM_BILLING_FIELD_PHONE: "Telephone",
  FARM_BILLING_FIELD_ADDRESS: "Address",
  FARM_BILLING_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  FARM_BILLING_FIELD_CITY: "City",
  FARM_BILLING_FIELD_STATE: "State",
  FARM_BILLING_FIELD_COUNTRY: "Country",
  ALERT_DIALOG_ALERT_DELETE:
    "Are you sure you want to delete this notification?",
  NOTIFICATIONS_TABPANEL_LABEL_PIVOTS: "Pivots",
  NOTIFICATIONS_TABPANEL_LABEL_MONITOR: "Pivot monitors",
  NOTIFICATIONS_TABPANEL_LABEL_PUMP: "Pumps",
  NOTIFICATIONS_TABPANEL_LABEL_METER: "Level meters",
  SHOW_REASONS: "Show reasons",
  HIDE_REASONS: "Hide reasons",
  EDIT_NOTIFICATIONS: "Edit notifications",
  EDIT_NOTIFICATIONS_QUESTION: "Do you wish to save your changes?",
  EDIT_NOTIFICATIONS_SAVE: "Save",
  CREATE_NOTIFICATIONS: "Create notifications",
  FARM_HAS_NO_NOTIFICATIONS: "You have no notifications for ${}",
  CREATE_NOTIFICATIONS_QUESTION:
    "Do you wish to create this group of notifications?",
  CREATE_NOTIFICATIONS_CREATE: "Create",
  DELETE_NOTIFICATIONS_QUESTION:
    "Do you wish to delete this group of notifications?",
  DELETE_NOTIFICATIONS: "Delete notifications",
  DELETE_NOTIFICATIONS_CANCEL: "Cancel",
  DELETE_NOTIFICATIONS_DELETE: "Delete",
  CREATE_NOTIFICATIONS_ALL_DAY: "All day",
  CREATE_NOTIFICATIONS_BACK: "Back",
  CREATE_NOTIFICATIONS_NEXT: "Next",
  CREATE_NOTIFICATIONS_FINISH: "Finish",
  CREATE_NOTIFICATIONS_BASIC_INFO: "Basic information",
  CREATE_NOTIFICATIONS_TIME_PERIOD: "Time",
  CREATE_NOTIFICATIONS_DEVICES: "Devices",
  CREATE_NOTIFICATIONS_REASONS: "Reasons",
  CREATE_NOTIFICATIONS_CHOOSE_NAME:
    "Choose a name for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER:
    "Type a name for this group of notifications",
  CREATE_NOTIFICATIONS_PICK_TIME: "Pick a time for this group of notifications",
  CREATE_NOTIFICATIONS_STARTS_ON: "Starts at",
  CREATE_NOTIFICATIONS_ENDS_ON: "Ends at",
  CREATE_NOTIFICATIONS_SELECT_THE_DEVICES:
    "Select the devices for this group of notifications",
  CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES: "Select all the devices",
  CREATE_NOTIFICATIONS_SELECT_REASONS:
    "Select the reasons for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_GENERATION:
    "Choose the pivot generation for this group of notifications",
  HISTORY_OF_MEASUREMENTS: "History of Measurements",
  FARM_BILLING_FIELD_COMPANY_NAME: "Company name",
  FARM_BILLING_FIELD_DOCUMENT: "Document",
  FARM_BILLING_FIELD_DOCUMENT_TYPE: "Document type",
  FARM_BILLING_FIELD_EMAIL: "Email",
  EDIT_BILLING_SAVE_SUCCESS: "Billing information saved",
  EDIT_BILLING_ERROR: "Error while saving billing information",
  FARM_GENERAL_INFORMATION_TITLE: "General Information",
  FARM_BILLING_DESCRIPTION:
    "This information will be used to issue invoices for the use of the Irricontrol Platform.",
  FARM_BILLING_INFORMATION_TITLE: "Billing Information",
  FARM_BILLING_ADDRESS_TITLE: "Billing Address",
  FARM_CONTACT_INFORMATION_TITLE: "Contact Information",
  FARM_CONTACT_ADDRESS_TITLE: "Contact Address",
  FARM_CONTACT_ADDRESS_SWITCH: "Same as billing address",
  FARM_LOCATION_INFORMATION_TITLE: "Location Information",
  CREATE_FARM_STEP_LABEL_GENERAL: "General",
  CREATE_FARM_STEP_LABEL_CONTACT: "Contact",
  CREATE_FARM_STEP_LABEL_BILLING: "Billing",
  CREATE_FARM_STEP_LABEL_LOCATION: "Location",
  CENTRAL_RADIO: "Central Radio",
  CREATE_FARM_QUESTION: "Do you wish to create this farm?",
  CREATE_FARM_CREATE: "Create",
  FARM_CONTACT_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  COMMUNICATION_TYPE: "Communication Type",
  CREATE_DEVICE_MODAL_XBEE: "XBee",
  CREATE_DEVICE_MODAL_4G: "4G",
  CREATE_DEVICE_MODAL_GATEWAY_CODE: "Gateway Number",
  ERROR_16_CARACTERS_GATEWAY_CODE:
    "The Gateway Number field must have up to 16 characters",
  ERROR_16_CARACTERS_RADIO_GATEWAY:
    "The Gateway Radio field must have up to 16 characters",
  COMMUNICATION_TYPE_OPTION_XBEE: "XBee",
  COMMUNICATION_TYPE_OPTION_4G_LTE: "4G/LTE",
  SELECTED_PIVOT_ONLINE: "Pivot with internet",
  SELECTED_PIVOT_OFFLINE: "Pivot without internet",
  CENTRAL_LINKED: "Does the farm have a Central?",
  CENTRAL_LINKED_TOOLTIP:
    "Farms that have a Central are those that use radio communication. Farms that do not have a Central are those that use 4G communication, available only for pivots.",
  FARM_LOCATION: "Farm Location",
  EDIT_PIVOT_GATEWAY_NUMBER_LABEL: "Gateway Number",
  EDIT_GATEWAY: "Edit gateway",
  SELECTED_IRPD_ONLINE: "Pump with internet",
  SELECTED_IRPD_OFFLINE: "Pump without internet",
  GATEWAY_CHANGED_SUCCESS: "Gateway changed successfully",
  GATEWAY_ID_ALREADY_USED: "Gateway already used on another device",
  SAVE_GATEWAY: "Save gateway",
  HISTORIC_DATA_UNDEFINDED: "Неопределенный",
  HOVER_OVER_SEGMENT:
    "Hover the cursor over the drawing for segment information",
  CREATE_NOTIFICATIONS_CRITICAL: "Critical",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS:
    "Do you want this notification to be a critical alert?",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO:
    "Critical alerts play different and louder sounds than conventional notifications. These notifications are disruptive and should be used when immediate action is required, for example in the event of unexpected downtime.",
  CRITICAL_ALERTS_QUESTION_ENABLE: "Do you want to enable critical alerts?",
  CRITICAL_ALERTS_QUESTION_DISABLE: "Do you want to disable critical alerts?",
  CRITICAL_ALERTS_CANCEL: "Cancel",
  CRITICAL_ALERTS_ENABLE: "Enable",
  CRITICAL_ALERTS_DISABLE: "Disable",
  CRITICAL_ALERTS: "Critical alerts",
  FARM_PAYMENT_METHOD_TITLE: "Payment Method",
  FARM_BILLING_HISTORY_TITLE: "Billing History",
  BILLING_HISTORY_NO_INVOICES_FOUND: "No invoices found",
  BILLING_HISTORY_NO_BILLING_DATA_FOUND: "Billing data not found",
  BILLING_INVOICE_DUE_DATE: "Due Date",
  BILLING_INVOICE_STATUS: "Status",
  BILLING_INVOICE_STATUS_OPEN: "Open",
  BILLING_INVOICE_STATUS_PAID: "Paid",
  BILLING_INVOICE_STATUS_PAST_DUE: "Past due",
  BILLING_INVOICE_STATUS_VOID: "Void",
  BILLING_INVOICE_AMOUNT: "Amount",
  BILLING_INVOICE_LINK: "Link",
  BILLING_INVOICE_LINK_PAY: "Pay",
  BILLING_INVOICE_LINK_DOCS: "Documents",
  HISTORIC_PAINEL_TYPE_POSITION_STREAM: "Обновление угла",
  DEVICE_BOX_SHOW_POSITION: "Показать позицию",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Условия использования и защиты данных",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE:
    "Обновление условий использования и защиты данных",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT: "Наши",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2:
    "Цель - добавить условия использования наших услуг, чтобы поддерживать соответствие действующему законодательству, гарантирующему конфиденциальность ваших данных",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT: "Мы обновили наши",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2:
    "чтобы добавить новые условия использования наших услуг и обеспечить соответствие действующим законам, гарантирующим конфиденциальность ваших данных",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN:
    "Продолжая работу, вы соглашаетесь с нашими обновленными Условиями и всеми описанными в них правилами. Получить доступ и ознакомиться с ними можно по адресу",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK:
    "Условия использования и защиты данных",
  POLICY_TERMS_AND_CONDITIONS_CHECKBOX:
    "Я заявляю, что прочитал и согласен с Условиями использования и защиты данных платформы Irricontrol.",
  ACCEPT_TEXT: "Я ПРИНИМАЮ",
  PAYMENT_METHOD_WARNNING:
    "Счета в режиме ручной оплаты. Чтобы запустить автоматическую оплату, добавьте кредитную карту в параметры оплаты.",
  PAYMENT_METHOD_ADD: "Добавить способ оплаты",
  PAYMENT_METHOD_UPDATE: "Обновить способ оплаты",
  PAYMENT_METHOD_CREDIT_CARD_NUMBER: "Номер кредитной карты",
  PAYMENT_METHOD_CREDIT_CARD_EXPIRY: "Срок действия",
  PAYMENT_METHOD_CREDIT_NAME: "Имя на карте",
  DISPLAY_DATE_FORMAT: "DD.MM.YYYY",
  DEBTOR_NOTIFICANTION_USER:
    "Ваша ферма просрочила платежи. Попросите своего администратора произвести платежи.",
  DEBTOR_NOTIFICANTION_ADM:
    "Ваша ферма просрочила платежи. Произведите платежи",
  DEBTOR_NOTIFICANTION_LINK: "<в настройках>",
  DEBTOR_NOTIFICANTION_COUNT:
    "Ферма будет автоматически заблокирована через ${} дней.",
  BILLING_DESCRIPTION_TEXT:
    "На вкладке биллинга сосредоточена основная финансовая информация, связанная с коммерческими операциями на платформе.",
  PAYMENT_METHOD_WARNING:
    "Чтобы включить автоматические платежи, добавьте кредитную карту.",
  BILLING_WARNING: "Есть просроченные платежи!",
  SUCCESS: "Успех",
  SUCCESS_PAGE_TEXT: "Способ оплаты успешно обновлен.",
  CANCEL_PAGE_TEXT: "Метод оплаты не удалось обновить.",
  BILLING: "Счет",
  UNDER_CONSTRUCTION: "В процессе строительства",
  PRESSURE_CHART_COMPARISON_END_TOWER:
    "Сравнительное давление - Последняя башня",
  PRESSURE_CHART_END_TOWER: "Давление - последняя башня",
  PRESSURE_CHART_COMPARISON_CENTER:
    "Сравнительное давление - центральная башня",
  PRESSURE_CHART_CENTER: "Давление - Центральная башня",
  EDIT_PROFILE_TERMS_AND_CONDITIONS:
    "Для получения дополнительной информации об условиях, регулирующих работу платформы Irricontrol, перейдите по ссылке ",
  EDIT_FARM_ERROR_FIELDS: "Регистрация не удалась, проверьте поля еще раз.",
  EDIT_FARM_ERROR: "Ферма не может быть зарегистрирована.",
  NO_INFO: "Нет связи",
  HARDWARE_ID_ALREADY_EXISTS: "Идентификатор оборудования уже используется",
  ENCODER: "Энкодер",
  ENCODER_DESCRIPTION_TEXT:
    'В поворотных системах LTE/4G используется высокоточное оборудование, установленное в центральной башне, которое отвечает за регистрацию углового положения поворотного устройства в режиме реального времени. Вкладка "Энкодер" предназначена для настройки и калибровки энкодера, что позволяет установить 0° угла поворота шарнира в соответствии с его текущим положением.',
  ENCODER_BTN: "Сбросить угол 0°",
  ENCODER_CONFIRMATION: "Хотите ли вы сбросить текущий угол поворота на 0º?",
  EDIT_ENCODER_LABEL_SETTING_HISTORIC: "История сброса энкодера",
  REDEFINE: "Да",
  REDEFINE_ENCONDER_LABEL: "Сброс энкодера",
  EDIT_ENCODER_SAVE_AWAIT_ERROR: "Ошибка сброса энкодера на угол 0º",
  EDIT_ENCODER_SAVE_AWAIT_SUCCESS: "Энкодер сброшен на угол 0º",
  ENCODER_RESET_INSTRUCTION_TITLE: "Как использовать сброс энкодера",
  ENCODER_RESET_INSTRUCTION_STEP1: "Шаг 1:",
  ENCODER_RESET_INSTRUCTION_STEP1_TEXT:
    "Установите шарнир в требуемую начальную точку отсчета. Убедитесь, что энкодер, установленный в центре шарнира, находится в правильном положении.",
  ENCODER_RESET_INSTRUCTION_STEP2: "Шаг 2:",
  ENCODER_RESET_INSTRUCTION_STEP2_TEXT: 'Нажмите на кнопку "RESET ANGLE 0º"',
  ENCODER_RESET_INSTRUCTION_STEP3: "Шаг 3:",
  ENCODER_RESET_INSTRUCTION_STEP3_TEXT:
    'Откроется окно подтверждения. Подтвердите сброс, нажав "Да".',
  ENCODER_RESET_INSTRUCTION_DESCRIPTION:
    "Важно отметить, что данное оборудование сохраняет текущий угол, даже когда поворотная панель выключена. Поэтому используйте сброс только при первой установке, в случае замены оборудования или при обновлении исходной базовой конфигурации.",
  PAYMENT_METHOD_CREDIT_CARD: "Кредитная карта",
  PAYMENT_METHOD_CREDIT_BILL: "квитанцию",
  EDIT_BILLING_NOT_FOUND_USER:
    "Информация о выставлении счетов не зарегистрирована. Попросите своего администратора зарегистрировать ее",
  EDIT_BILLING_NOT_FOUND_ADM:
    "Информация для выставления счетов не зарегистрирована. Выполните регистрацию",
  PAYMENT_METHOD_DELETE_TEXT:
    "Вы уверены, что хотите удалить автоматическое выставление счетов?",
  PAYMENT_METHOD_DELETE_TITLE: "Удалить автоматическое выставление счетов",
  PAYMENT_METHOD_DELETE_BTN: "Удалить",
  PAYMENT_METHOD_UPDATE_BTN: "Обновить",
  BILLING_PREFERRED_LANGUAGE: "Предпочитаемый язык для электронной почты",
  BILLING_PREFERRED_LANGUAGE_TOOLTIP:
    '"Предпочитаемый язык электронной почты" определяет, на каком языке вы будете получать сообщения электронной почты о платежах и счетах на платформе Irricontrol. Выбор этого языка не изменяет язык интерфейса на платформе.',
  FARM_PAYMENT_METHOD_DESCRIPTION:
    'Добавьте "Кредитную карту", чтобы включить автоматическую оплату счетов. Эта опция гарантирует, что ваши счета будут оплачены в нужную дату, без необходимости ручного вмешательства в каждый расчетный цикл, избегая любых типов блокировок на платформе.',
  REPORT_EMAIL_NOTIFICATION:
    "Отчет будет отправлен по электронной почте в течение ${} мин.",
  REPORT_PROCESSING_NOTIFICATION: "Отчет в обработке. Оценка: ${} мин.",
  REPORT_OPERATION: "Отчет об операции",
  REPORT_FARM: "Отчет о ферме",
};

export default i18n_ru;

import React, { useCallback, useMemo, useRef, useState } from "react";
import "./SegmentsDisplayChartMap.scss";
import { ResponsivePie } from "@nivo/pie";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { GREEN_COLORS_TABLE_DISPLAY_CROPS } from "../../../../../../utils/utils";

type Data = {
  id: string;
  label: string;
  value: number;
  color?: string;
  mode_forward: string;
  mode_reverse: string;
  index: number;
};

export const SegmentsDisplayChartMap = ({ pivot, waterBladePerSegment }) => {
  const percentageForwardArray = new Array(
    pivot.controllerconfig.content?.segments.length
  ).fill("100");

  const irrigationModeForwardArray = new Array(
    pivot.controllerconfig.content?.segments.length
  ).fill("2");

  const irrigationModeReverseArray = new Array(
    pivot.controllerconfig.content?.segments.length
  ).fill("2");

  const startAngleChart = () => {
    if (pivot.controllerconfig.content.segments[0].angle_start == 0)
      return pivot.reference_angle - 360;
    else {
      let StartSegment =
        360 - pivot.controllerconfig.content.segments[0].angle_start;
      return pivot.reference_angle - 360 - StartSegment;
    }
  };

  const data = useMemo((): Data[] => {
    let responseData: Data[] = [];
    pivot.controllerconfig.content.segments.forEach((element, index) => {
      responseData.push({
        id: `${index + 1}`,
        label: pivot.controllerconfig?.segments_crop?.[index]?.name
          ? pivot.controllerconfig?.segments_crop?.[index]?.name
          : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${index + 1}`,
        value:
          parseInt(element.angle_end) <= parseInt(element.angle_start)
            ? parseInt(element.angle_end) -
              (parseInt(element.angle_start) - 360)
            : parseInt(element.angle_end) - parseInt(element.angle_start),
        mode_forward: irrigationModeForwardArray[index],
        mode_reverse: irrigationModeReverseArray[index],
        index: index,
      });
    });

    if (pivot.controllerconfig?.content?.sector?.end_angle !== 360) {
      responseData.push({
        id: ``,
        label: ``,
        value: 360 - pivot.controllerconfig?.content?.sector?.end_angle,
        mode_forward: "-1",
        mode_reverse: "-1",
        index: -1,
      });
    }
    return responseData;
  }, [
    irrigationModeForwardArray,
    irrigationModeReverseArray,
    pivot.controllerconfig,
  ]);

  const getColor = useCallback(
    (sector) => {
      if (sector.data.index == -1) return "transparent";
      else return GREEN_COLORS_TABLE_DISPLAY_CROPS[sector.data.index];
    },
    [pivot]
  );

  const chartRef = useRef<HTMLDivElement>(null);

  return (
    <div
      style={{ height: 270, marginTop: -290, opacity: 1, marginBottom: 20 }}
      ref={chartRef}
    >
      <ResponsivePie
        data={data}
        startAngle={startAngleChart()}
        innerRadius={0.75}
        padAngle={0.5}
        cornerRadius={3}
        enableSliceLabels={true}
        sliceLabel="id"
        sliceLabelsTextColor={"white"}
        sliceLabelsRadiusOffset={0.5}
        enableRadialLabels={false}
        colors={getColor}
        tooltip={(value) => {
          if (value.datum.data.index == -1) return <div></div>;
          return (
            <div className="holver-container">
              <div>
                <span className="holver-title">
                  {pivot.controllerconfig?.segments_crop?.[
                    value.datum.arc.index
                  ]?.name
                    ? pivot.controllerconfig?.segments_crop?.[
                        value.datum.arc.index
                      ]?.name
                    : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${1}`}
                </span>
              </div>
              <div>
                <span className="holver-color">
                  {`${parseInt(
                    pivot.controllerconfig.content.segments[
                      value.datum.arc.index
                    ]?.angle_start
                  )}° - ${parseInt(
                    pivot.controllerconfig.content.segments[
                      value.datum.arc.index
                    ]?.angle_end
                  )}°`}
                </span>
              </div>
              <div className="holver-color">
                {pivot.controllerconfig?.segments_crop?.[value.datum.arc.index]
                  ?.crop_plant_date &&
                  waterBladePerSegment !== "-" && (
                    <>
                      <span>{i18n("PIVOT_ACTION_FIELD_PRECIPITATION")}: </span>
                      <span>
                        {`${waterBladePerSegment[
                          value.datum.arc.index
                        ]?.water_blade.toFixed(2)} mm`}{" "}
                      </span>
                    </>
                  )}
              </div>
            </div>
          );
        }}
        onMouseEnter={(value) => {
          if (value.data.index === -1) return;
          chartRef.current.style.cursor = "pointer";
        }}
        onMouseLeave={() => {
          chartRef.current.style.cursor = "default";
        }}
      />
    </div>
  );
};

import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import styled from "@emotion/styled";
import { isMobile } from "../../../../../../../../mobileConfig";
import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { IMeter } from "../../../../../../../../recoils/MeterSystemRecoil";
import { nanoid } from "nanoid";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { ApplicationState } from "../../../../../../../../redux";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../../../redux/filterByDate/types";
import {
  labelDisplayedRows,
  TablePaginationActions,
} from "../../../../../../../../components/GeneralTable/GeneralTable";
import { useParams } from "react-router-dom";

function EnhancedTableHead(props) {
  const headCells = [
    {
      id: "date",
      label: i18n("BASIC_INFO_BOX_TENSION_DATES"),
    },
    {
      id: "volume",
      label: i18n("ACCUMULATED_VOLUME"),
    },
    {
      id: "hourmeter",
      label: i18n("APPROXIMATE_USAGE_TIME_ABBREVIATED"),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledHeadTableCell
            key={headCell.id}
            align={"center"}
            padding={"default"}
          >
            {headCell.label}
          </StyledHeadTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: isMobile() ? "95vw" : "100%",
    overflowX: isMobile() ? "scroll" : "hidden",
    boxShadow: "none",
    border: "1px solid rgba(0,0,0,0.15)",
    height: "100%",
    borderRadius: "0px",
  },
  table: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

type ResultItemObject = {
  content: {
    pulse_counter_readings?: {
      last_current_flow: number;
      overflow_quantity: number;
      totalized_volume_measured: number;
    };
    pump_hourmeter?: {
      hours: number;
      minutes: number;
    };
  };
  created: Date;
  flow: number;
};

interface Props {
  iMeter: IMeter;
}

const StyledHeadTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
`;

const StyledTableCell = styled(TableCell)`
  position: relative;
  font-size: 0.85em;
  padding: 5px;
  width: 200px;
  height: 70px;
  background-color: white;
`;

function PulseFlowReportMeterSystemTable(props: Props) {
  const params = useParams();

  const { iMeter } = props;

  const classes = useStyles();

  const [page, setPage] = React.useState(0);

  const [count, setCount] = useState(0);

  const [reportListResults, setReportListResults] = useState<
    ResultItemObject[]
  >(undefined);

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const emptyRows = new Array(10).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>

        <StyledTableCell align="center">
          <Skeleton />
        </StyledTableCell>
      </TableRow>
    );
  });

  const rows = useMemo(() => {
    if (reportListResults === undefined) return; // Historic isn't loaded yet.

    return reportListResults.map((row, index) => {
      return {
        created: row.created,
        volume:
          row.content?.pulse_counter_readings?.totalized_volume_measured +
          row.content?.pulse_counter_readings?.overflow_quantity * 4294967295,
        hourmeter: `${row.content?.pump_hourmeter?.hours}h ${row.content?.pump_hourmeter?.minutes}min`,
      };
    });
  }, [reportListResults]);

  const handleChangePage = (event, newPage) => {
    setReportListResults(undefined);
    getMeterSystemList(newPage);
    setPage(newPage);
  };

  async function getMeterSystemList(page = 0) {
    setReportListResults(undefined);
    if (
      filterByDate.data.start_date &&
      filterByDate.data.end_date &&
      filterByDate.data.type === FilterTypesChoices.REPORT
    ) {
      var date_end = filterByDate.data.end_date.toISOString();
      var date_start = filterByDate.data.start_date.toISOString();
      try {
        let response = await coreHTTPClient.get(
          `v3/farms/${params.farm}/metersystems/${params.metersystem}/meter/${
            iMeter.id
          }/water-level-history/?page=${
            page + 1
          }&date_start=${date_start}&date_end=${date_end}`
        );
        if (response.data) {
          setReportListResults(response.data.results);
          setCount(response.data.count);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        let response = await coreHTTPClient.get(
          `v3/farms/${params.farm}/metersystems/${params.metersystem}/meter/${
            iMeter.id
          }/pulse-flow-history/?page=${page + 1}`
        );
        if (response.data) {
          setReportListResults(response.data.results);
          setCount(response.data.count);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    if (iMeter && params) {
      getMeterSystemList();
    }
  }, [filterByDate, iMeter, params]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />

            <TableBody>
              {rows != undefined ? (
                rows.length > 0 ? (
                  rows.map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={nanoid()}>
                        <StyledTableCell align="center">
                          <div>{moment(row.created).format("DD/MM HH:mm")}</div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <div>{`${
                            row.volume * iMeter.latest_config.measure_scale
                          } ${iMeter.latest_config.measure_unit}`}</div>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <div>{row.hourmeter}</div>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )
              ) : (
                emptyRows
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
}

export default React.memo(PulseFlowReportMeterSystemTable);

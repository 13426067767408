import { Irpd } from "../../redux/irpds/types";
import {
  PivotBaseDeviceNode,
  GPSBaseDeviceNode,
  CentralBaseDeviceNode,
  PumpBaseDeviceNode,
  RepeaterBaseDeviceNode,
} from "./DeviceTypes";

import { Pivot } from "../../redux/pivots/types";
import { Farm } from "../../redux/farms/types";
import { isOldProtocol, isV5Protocol } from "../models/pivots";
import { Repeater } from "../../pages/Dashboard/Devices/Devices";

export function generatePivotDeviceObject(pivot: Pivot): PivotBaseDeviceNode {
  let centerPosition: number[] = [];
  let current_radio_id: string;
  if (isOldProtocol(pivot)) {
    centerPosition = pivot.config.center.split(",").map(parseFloat);
    current_radio_id = pivot.control_radio_id;
  } else if (isV5Protocol(pivot) && pivot.automation_type === 0) {
    centerPosition = [
      parseFloat(
        pivot.controllerconfig.content.pivot_positions.latitude_center
      ),
      parseFloat(
        pivot.controllerconfig.content.pivot_positions.longitude_center
      ),
    ];
    current_radio_id = pivot.control_radio_id;
  } else if (
    isV5Protocol(pivot) &&
    (pivot.automation_type === 1 || pivot.automation_type === 2)
  ) {
    centerPosition = [
      parseFloat(
        pivot.controllerstream_gps?.content?.latitude_longitude_gps?.latitude_gps.toString()
      ),
      parseFloat(
        pivot.controllerstream_gps?.content?.latitude_longitude_gps?.longitude_gps.toString()
      ),
    ];
    current_radio_id = pivot.monitor_radio_id;
  }

  return {
    type: "PIVOT",
    coordinate: {
      lat: centerPosition[0],
      lon: centerPosition[1],
    },
    name: pivot.name,
    radio_id: current_radio_id,
    object: pivot,
  };
}

export function generateGPSDeviceObject(pivot: Pivot): GPSBaseDeviceNode {
  let gpsPosition: number[] = [];

  if (isOldProtocol(pivot)) {
    gpsPosition = pivot.latest_gps_stream.position.split(",").map(parseFloat);
  } else if (isV5Protocol(pivot)) {
    gpsPosition = [
      parseFloat(
        pivot.controllerstream_gps?.content?.latitude_longitude_gps?.latitude_gps.toString()
      ),
      parseFloat(
        pivot.controllerstream_gps?.content?.latitude_longitude_gps?.longitude_gps.toString()
      ),
    ];
  }

  return {
    type: "GPS",
    coordinate: {
      lat: gpsPosition[0],
      lon: gpsPosition[1],
    },
    name: pivot.name + " GPS",
    radio_id: pivot.monitor_radio_id,
    object: pivot,
  };
}

export function generateCentralDeviceObject(farm: Farm): CentralBaseDeviceNode {
  return {
    type: "CENTRAL",
    coordinate: {
      lat: parseFloat(farm.location ? farm.location.split(",")[0] : "0"),
      lon: parseFloat(farm.location ? farm.location.split(",")[1] : "0"),
    },
    name: "Central",
    radio_id: farm?.base?.radio_id ? farm.base.radio_id : "",
    object: farm,
  };
}

export function generatePumpDeviceObject(irpd: Irpd): PumpBaseDeviceNode {
  let irpdCoordinates: number[] = irpd.position.split(",").map(parseFloat);
  return {
    type: "PUMP",
    coordinate: {
      lat: irpdCoordinates[0],
      lon: irpdCoordinates[1],
    },
    name: irpd.name,
    radio_id: (irpd.pump as unknown) as string,
    object: irpd,
  };
}

export function generateRepeaterDeviceObject(
  repeater: Repeater
): RepeaterBaseDeviceNode {
  let irpdCoordinates: number[] = repeater.position.split(",").map(parseFloat);
  return {
    type: "REPEATER",
    coordinate: {
      lat: irpdCoordinates[0],
      lon: irpdCoordinates[1],
    },
    name: repeater.name,
    radio_id: repeater?.repeater_radio_id,
    object: repeater,
  };
}

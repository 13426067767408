import React, { useEffect, useState } from "react";
import "./LanguageSelector.scss";
import { SettingState } from "../../../../redux/setting/types";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../../redux";
import { Language } from "../../../../redux/setting/types";
import { setLanguage } from "../../../../redux/setting/actions";
import { hideModal } from "../../../../redux/modal/actions";
import { i18n, i18nTextId } from "../../../../i18n/i18nText";
import { coreHTTPClient } from "../../../../services/webclient";
import { superUserStatus } from "../../../../recoils/SuperUserStatusRecoil";
import { useRecoilState } from "recoil";

const BRIcon = require("../../../../assets/images/brazil-icon-32.png");
const USAIcon = require("../../../../assets/images/usa-icon-32.png");
const DEIcon = require("../../../../assets/images/german-icon-32.png");
const RUIcon = require("../../../../assets/images/russia-icon-32.png");
const ESIcon = require("../../../../assets/images/spain-icon-32.png");
const I18NIcon = require("../../../../assets/images/language.png");

const flagSelector = (lang) => {
  switch (lang as String) {
    case "pt-br":
      return BRIcon;
    case "en":
      return USAIcon;
    case "de-at":
      return DEIcon;
    case "ru":
      return RUIcon;
    case "es":
      return ESIcon;
    case "keys":
      return I18NIcon;
  }
};

function LanguageSelector() {
  var selectedLanguage: SettingState = useSelector(
    (state: ApplicationState) => state.setting
  );

  const dispatch = useDispatch();

  const [languages, _] = useState([
    {
      id: Language.ENGLISH,
      name: "LANGUAGE_SELECTOR_ENGLISH",
    },
    {
      id: Language.PORTUGUESE_BRAZIL,
      name: "LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL",
    },
    {
      id: Language.SPAIN_SPANISH,
      name: "LANGUAGE_SELECTOR_SPANISH",
    },
    {
      id: Language.RUSSIAN,
      name: "LANGUAGE_SELECTOR_RUSSIAN",
    },
    {
      id: Language.GERMAN_AUSTRIA,
      name: "LANGUAGE_SELECTOR_GERMAN",
    },
  ]);

  // Checagem de super usuário
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);

  useEffect(() => {
    if (superUser) {
      languages.push({
        id: Language.I18N_KEYS,
        name: "I18N_KEYS",
      });
    }
  }, [languages]);

  return (
    <div className="language-selector">
      {languages.map((language, index) => {
        return (
          <div
            id={`language-${language.name}`}
            key={`language-${language.id}`}
            className={`language-selector__language-option${
              selectedLanguage.language === language.id ? "-selected" : ""
            }`}
            style={languages.length - 1 === index ? { border: "none" } : {}}
            onClick={async () => {
              let refresh = await dispatch(setLanguage(language.id));
              await coreHTTPClient.post(`/v3/auth/profile/`, {
                language: language?.id,
              });
              if (refresh) {
                dispatch(hideModal());
                // window.location.reload();
              }
            }}
          >
            <img
              src={flagSelector(language.id)}
              height="16"
              alt={language.name}
              style={{ marginRight: "10px" }}
            />
            {i18n(language.name as i18nTextId)}
          </div>
        );
      })}
    </div>
  );
}

export default LanguageSelector;

type Suffix = [string, boolean];

export function suffixClasses(base: string, suffixes: Suffix[]): string {
  const result: string[] = [base];

  suffixes.forEach((suffix) => {
    if (suffix[1]) result.push(`${base}${suffix[0]}`);
  });

  return result.join(" ");
}

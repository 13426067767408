import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { Close as IconClose } from "@material-ui/icons";
import { PaymentMethodOptions } from "../PaymentMethodContainer";

interface Props {
  methods: PaymentMethodOptions[];
  deletePaymentMethod: () => void;
}

const PaymentMethodRemoveConfirmation = (props: Props) => {
  const { methods, deletePaymentMethod } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    deletePaymentMethod();
    setIsOpen(false);
  };

  return (
    <div>
      <FormControl className="form-select__form-control">
        <Button
          id="checkout-link"
          style={{
            marginTop: "25px",
            marginLeft: "20px",
            color: "white",
            backgroundColor: "#d32f2f",
          }}
          variant="contained"
          onClick={handleClickOpen}
        >
          {i18n("PAYMENT_METHOD_DELETE_BTN")}
        </Button>
      </FormControl>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        style={{
          overflow: "hidden",
        }}
      >
        <Grid container alignItems="center" justify="center">
          <DialogTitle style={{ flex: 1 }}>
            {i18n("PAYMENT_METHOD_DELETE_TITLE")}
          </DialogTitle>
          <IconButton
            style={{
              padding: 0,
              margin: "20px",
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={() => handleClose()}
          >
            <IconClose />
          </IconButton>
        </Grid>
        <DialogContent>
          <Typography>{i18n("PAYMENT_METHOD_DELETE_TEXT")}</Typography>

          <Button
            id="checkout-link-ok"
            style={{ marginTop: "25px", marginLeft: "20px" }}
            variant="contained"
            color="primary"
            onClick={handleDelete}
          >
            {i18n("FORGET_PASSWORD_OK")}
          </Button>
          <Button
            id="checkout-link-cancel"
            style={{
              marginTop: "25px",
              marginLeft: "20px",
              color: "white",
              backgroundColor: "#d32f2f",
            }}
            variant="contained"
            onClick={handleClose}
          >
            {i18n("CANCEL")}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentMethodRemoveConfirmation;

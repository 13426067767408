import { ReactJSX } from "../../utils/types";

export enum ModalTypes {
  SHOW_MODAL = "@@modal/SHOW_MODAL",
  HIDE_MODAL = "@@modal/HIDE_MODAL",
}

export interface Modal {
  content: ReactJSX;
  title?: string;
  headerless?: boolean;
}

export interface ModalState {
  active: boolean;
  data: Modal;
}

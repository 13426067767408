import { CircularProgress } from "@material-ui/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useContext,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { DrawerContext } from "../../../../components/DrawerProvider/DrawerProvider";
import Map from "../../../../../../components/Map/Map";
import { MeterSystem } from "../../../../../../recoils/MeterSystemRecoil";
import {
  FullPivotList,
  ListLatestStreamPivot,
  PivotLight,
} from "../../../../../../recoils/PivotRecoil";
import { Irpd } from "../../../../../../redux/irpds/types";
import routes from "../../../../../../routes/routes";
import {
  instanceOfBauerPivotShape,
  instanceOfIrpdShape,
  instanceOfLinearPivotShapeShape,
  instanceOfMeterSystemShape,
  instanceOfPivotShape,
  instanceOfRepeaterShape,
  MapShape,
} from "../../../../../../utils/types";

import { addIrpdOnMap } from "./addIrpdOnMaps";
import { addMeterSystemsOnMap } from "./addMeterSystemsOnMap";
import { addPivotsOnMap } from "./addPivotsOnMaps";
import { addBauerPivotsOnMap } from "./addBauerPivotsOnMaps";
import { Repeater } from "../../../../../../recoils/RepeaterRecoil";
import { addRepeaterOnMap } from "./addRepeaterOnMaps";
import {
  BauerPivotObj,
  FarmBauerPivotObj,
} from "../../../../../../redux/bauerPivot/types";

// Assets
interface Props {
  pivotsLight?: PivotLight[];
  bauerPivots?: FarmBauerPivotObj[];
  irpds?: Irpd[];
  meterSystems?: MeterSystem[];
  repeaters?: Repeater[];
  draggable: boolean;
  disableUI?: boolean;
  latestPanelStream?: any;
  latestGPSStream?: any;
  disableFullScreen?: boolean;
  clickOnMapToToggleFullscreen?: boolean;
  zoomScroll?: boolean;
  // Component feito para renderizar o bounds quando entrar na tab de components
  isTabSelected?: number;
  zoom?: number;
  infoWindow?: boolean;

  /**
   * Prop que faz o mapa renderizar imperativamente ao invés de reativo.
   * feito, pois o reativo dele está com as dependências muito gerais e
   * sua lógica é bem pesada
   */
  isImperativeControlled?: boolean;
}

export interface FarmMapHandle {
  drawPivotsOnMap: () => any;
}

function FarmMap(props: Props, ref) {
  const {
    pivotsLight,
    bauerPivots,
    draggable,
    irpds,
    meterSystems,
    repeaters,
    latestGPSStream,
    latestPanelStream,
    disableUI,
    zoomScroll,
    isTabSelected,
    zoom,
    infoWindow,
  } = props;
  const googleMapRef = useRef(undefined);
  const [mapShapes, setMapShapes] = useState<MapShape[]>([]);
  const navigate = useNavigate();
  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  const [disableQuickSelect, setDisableQuickSelect] = useState<boolean>(false);
  const [currentDrawing, setCurrentDrawing] = useState<boolean>(false);

  const listStreamStatus = useRecoilValue(ListLatestStreamPivot);
  const streamStatusComplete = useMemo(() => {
    if (!listStreamStatus) return;
    return listStreamStatus.filter((p) =>
      pivotsLight ? pivotsLight.find((pL) => pL.id == p.id) : null
    );
  }, [listStreamStatus, pivotID]);
  // Adicionando pivotID neste useMemo força o recoil de streamStatus atualizar de acordo com o ID de pivô mudando na URL

  const pivots = useRecoilValue(FullPivotList);
  // Necessário filtro, pois ele pega só os pivots que foram passados no array para o componente
  const pivotsCompleted = useMemo(() => {
    if (!pivots) return;
    return pivots.filter((p) =>
      pivotsLight ? pivotsLight.find((pL) => pL.id == p.id) : null
    );
  }, [
    // Deep comparison to recognize irrifast status changes
    pivots,
    pivotsLight,
  ]);

  const bounds = useMemo(() => {
    const mapBounds = new window.google.maps.LatLngBounds();
    if (mapShapes?.length == 0) return undefined;
    mapShapes.forEach((shape) => {
      if (!shape) return;
      if (instanceOfPivotShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
      if (instanceOfBauerPivotShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
      if (instanceOfIrpdShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
      if (instanceOfMeterSystemShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
      if (instanceOfLinearPivotShapeShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
      if (instanceOfRepeaterShape(shape)) {
        mapBounds.union(shape.circle.getBounds());
      }
    });
    return mapBounds;
  }, [mapShapes]);

  const handleOnGoogleApiLoaded = useCallback(
    (maps: { map: any; maps: any }) => {
      if (!googleMapRef.current || googleMapRef.current != maps.map) {
        googleMapRef.current = maps.map;
        doMapDraw();
      }
    },
    [pivotsCompleted, bauerPivots, irpds, meterSystems, repeaters]
  );

  const ctx = useContext(DrawerContext);

  // useEffect que ouve se uma infoWindow foi clicada para navegação.
  // como a infoWindow não suporta click eu disparo um evento
  useEffect(() => {
    function navigateToPivot(
      e: CustomEvent<{ pivotId: string; farmId: string }>
    ) {
      const { farmId, pivotId } = e.detail;
      ctx.setFarmID(farmId);
      ctx.setPivotID(pivotId);
      navigate(
        routes.DASHBOARD.SELECTED_PIVOT.replace(":farm", farmId).replace(
          ":pivot",
          pivotId
        )
      );
    }

    window.addEventListener("navigateToPivot", navigateToPivot);

    function navigateToBauerPivot(
      e: CustomEvent<{ pivotId: string; farmId: string }>
    ) {
      const { farmId, pivotId } = e.detail;
      ctx.setFarmID(farmId);
      ctx.setPivotID(pivotId);
      navigate(
        routes.DASHBOARD.SELECTED_BAUER_PIVOT.replace(":farm", farmId).replace(
          ":pivot",
          pivotId
        )
      );
    }

    window.addEventListener("navigateToBauerPivot", navigateToBauerPivot);

    function navigateToIrpd(
      e: CustomEvent<{ irpdId: string; farmId: string }>
    ) {
      const { farmId, irpdId } = e.detail;
      navigate(
        routes.DASHBOARD.SELECTED_IRPD.replace(":farm", farmId).replace(
          ":irpd",
          irpdId
        )
      );
    }

    window.addEventListener("navigateToIrpd", navigateToIrpd);

    function navigateToMeterSystem(
      e: CustomEvent<{ meterSystemId: string; farmId: string }>
    ) {
      const { farmId, meterSystemId } = e.detail;
      navigate(
        routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(":farm", farmId).replace(
          ":metersystem",
          meterSystemId
        )
      );
    }

    window.addEventListener("navigateToMeterSystem", navigateToMeterSystem);

    function navigateToRepeater(
      e: CustomEvent<{ repeaterId: string; farmId: string }>
    ) {
      const { farmId, repeaterId } = e.detail;
      navigate(
        routes.DASHBOARD.EDIT_REPEATER.replace(":farm", farmId).replace(
          ":repeater",
          repeaterId
        )
      );
    }

    window.addEventListener("navigateToRepeater", navigateToRepeater);

    return () => {
      window.removeEventListener("navigateToPivot", navigateToPivot);
      window.removeEventListener("navigateToBauerPivot", navigateToBauerPivot);
      window.removeEventListener("navigateToIrpd", navigateToIrpd);
      window.removeEventListener(
        "navigateToMeterSystem",
        navigateToMeterSystem
      );
      window.removeEventListener("navigateToRepeater", navigateToRepeater);
    };
  }, []);

  const totalPivotLength = pivotsLight?.length;
  const pivotsAlreadyLoadedLength = pivotsCompleted
    ? pivotsCompleted.filter((p) => p.config || p.controllerconfig).length
    : 0;

  useImperativeHandle(ref, () => ({
    drawPivotsOnMap: () => {
      doMapDraw();
    },
  }));

  useEffect(() => {
    if (googleMapRef.current !== undefined)
      googleMapRef.current.fitBounds(bounds);
  }, [isTabSelected]);

  useEffect(() => {
    if (pivotID) {
      setDisableQuickSelect(true);
      setTimeout(() => {
        setDisableQuickSelect(false);
      }, 1000);
    }
  }, [pivotID]);

  async function doMapDraw() {
    if (googleMapRef.current === undefined) return;
    setMapShapes([]);
    setCurrentDrawing(true);
    let tempMapShapesPivots: MapShape[] = [];
    let tempMapShapesBauerPivots: MapShape[] = [];
    let tempMapShapesIrpds: MapShape[] = [];
    let tempMapShapesMeterSystems: MapShape[] = [];
    let tempMapShapesRepeaters: MapShape[] = [];
    if (mapShapes.length > 0) {
      mapShapes.forEach((shape) => {
        if (!shape) return;
        if (shape.type === "PivotShape") {
          shape.circle.setMap(null);
          shape.line.setMap(null);
          shape.triangle.setMap(null);
          shape.arrow.setMap(null);
          shape.sector && shape.sector.setMap(null);
          shape.dashedLine && shape.dashedLine.setMap(null);

          shape.semiCircle.forEach((s) => s.setMap(null));
        }
        if (shape.type === "BauerPivotShape") {
          shape.circle.setMap(null);
          shape.line.setMap(null);
          shape.triangle.setMap(null);
          shape.arrow.setMap(null);
          shape.sector && shape.sector.setMap(null);
          // shape.dashedLine && shape.dashedLine.setMap(null);

          // shape.semiCircle.forEach((s) => s.setMap(null));
        }
        if (shape.type === "LinearPivotShape") {
          shape.line.setMap(null);
          shape.circle.setMap(null);
          shape.rectangles.forEach((rectangle) => rectangle.setMap(null));
          shape.arrow.setMap(null);
        }
      });
    }

    if (pivotsAlreadyLoadedLength < totalPivotLength) return;

    if (pivotsCompleted != undefined) {
      tempMapShapesPivots = addPivotsOnMap(
        googleMapRef,
        pivotsCompleted,
        mapShapes,
        latestPanelStream,
        latestGPSStream,
        streamStatusComplete,
        farmID
      );
    }

    if (bauerPivots != undefined) {
      tempMapShapesBauerPivots = addBauerPivotsOnMap(
        googleMapRef,
        bauerPivots,
        mapShapes,
        farmID,
        infoWindow
      );
    }

    if (irpds != undefined) {
      tempMapShapesIrpds = await addIrpdOnMap(googleMapRef, irpds, farmID);
    }

    if (meterSystems != undefined) {
      tempMapShapesMeterSystems = await addMeterSystemsOnMap(
        googleMapRef,
        meterSystems,
        farmID
      );
    }

    if (repeaters != undefined) {
      tempMapShapesRepeaters = await addRepeaterOnMap(
        googleMapRef,
        repeaters,
        farmID
      );
    }

    const tempMapShapes = await tempMapShapesPivots.concat(
      tempMapShapesIrpds,
      tempMapShapesBauerPivots,
      tempMapShapesMeterSystems,
      tempMapShapesRepeaters
    );

    setMapShapes(tempMapShapes);
    setCurrentDrawing(false);
  }

  useEffect(() => {
    /**
     * Mapa controlado imperativamente não faz useEffect
     */
    if (props.isImperativeControlled || currentDrawing) return;
    doMapDraw();
  }, [
    googleMapRef.current,
    streamStatusComplete,
    irpds,
    meterSystems,
    pivotsCompleted,
    bauerPivots,
  ]);

  if (pivotsAlreadyLoadedLength < totalPivotLength || disableQuickSelect) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Map
      draggable={draggable}
      bounds={bounds}
      onGoogleApiLoaded={handleOnGoogleApiLoaded}
      disableUI={disableUI}
      disableFullScreen={props.disableFullScreen}
      clickOnMapToToggleFullscreen={props.clickOnMapToToggleFullscreen}
      zoomScroll={zoomScroll}
      children={undefined}
      zoom={zoom}
    />
  );
}

export default forwardRef<FarmMapHandle, Props>(FarmMap);

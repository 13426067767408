import React, { useEffect } from "react";
import Login from "../../pages/Auth/Login/Login";
import { Route, Routes, useNavigate, useLocation } from "react-router";
import routes from "../routes";
import ForgetPassword from "../../pages/Auth/ForgetPassword/ForgetPassword";
import PasswordRecovery from "../../pages/Auth/ForgetPassword/PasswordRecovery/PasswordRecovery";
import SignUp from "../../pages/Auth/SignUp/SignUp";

function AuthRouter() {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname !== routes.AUTH.LOGIN &&
      location.pathname !== routes.AUTH.FORGET_PASSWORD &&
      location.pathname !== routes.AUTH.PASSWORD_RECOVERY &&
      location.pathname !== routes.AUTH.SIGNUP
    ) {
      navigate(routes.AUTH.LOGIN);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path={routes.AUTH.LOGIN} element={<Login />} />

      <Route path={routes.AUTH.SIGNUP} element={<SignUp />} />

      <Route path={routes.AUTH.FORGET_PASSWORD} element={<ForgetPassword />} />

      <Route
        path={routes.AUTH.PASSWORD_RECOVERY}
        element={<PasswordRecovery />}
      />
    </Routes>
  );
}

export default AuthRouter;

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilState } from "recoil";
import useNotify from "../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../i18n/i18nText";
import { hideModal } from "../../../../../../../redux/modal/actions";
import {
  Device,
  PIVOT_COMMUNICATION_TYPE,
} from "../../../../../../../redux/pivots/types";
import { coreHTTPClient } from "../../../../../../../services/webclient";

import "./ModalSelectRadioToSwitch.scss";
import { DeviceRadioFamily } from "../../../../../../../recoils/DeviceRadioRecoil";

type DeviceType = "PIVOT" | "IRPD" | "IMETER" | "REPEATER";

type DeviceSubType =
  | "control"
  | "monitor"
  | "pump"
  | "imeter_device"
  | "repeater";

type DeviceRadio = {
  id: number;
  name: string;
};

type Props = {
  deviceType: DeviceType;
  deviceSubType?: DeviceSubType;
  farmID: number;
  deviceID: number;
  currentRadioId: String;
  meterSystemID?: any;
};

export default function ModalSelectRadioToSwitch(props: Props) {
  const [deviceRadios, setDeviceRadios] = useState<DeviceRadio[]>();

  const [deviceRadiosLoaded, setDeviceRadiosLoaded] = useState<boolean>(false);

  const [radioId, setRadioId] = useRecoilState(
    DeviceRadioFamily(`${props.deviceID}_${props.deviceSubType}`)
  );

  const [previousRadioId, setPreviousRadioId] = useRecoilState(
    DeviceRadioFamily(`${props.deviceID}_${props.deviceSubType}_previous`)
  );

  const dispatch = useDispatch();

  const notify = useNotify();

  function getDeviceNameByType(type: DeviceType, subtype: DeviceSubType) {
    switch (subtype) {
      case "control":
        return i18n("CONTROL");
      case "monitor":
        return "GPS";
      case "pump":
        if (type === "PIVOT") return i18n("PUMP_CABLE");
        else return i18n("PUMP");
      case "imeter_device":
        return i18n("IMETER");
      case "repeater":
        return i18n("REPEATER");
      default:
        return i18n("DEVICE");
    }
  }

  function getDeviceTitle(type: DeviceType, subtype: DeviceSubType) {
    switch (subtype) {
      case "control":
        return i18n("RADIO_SWAP_CONTROLLER_TITLE");
      case "monitor":
        return i18n("RADIO_SWAP_GPS_TITLE");
      case "pump":
        return i18n("RADIO_SWAP_PUMP_TITLE");
      case "imeter_device":
        return i18n("RADIO_SWAP_IMETER_TITLE");
      case "repeater":
        return i18n("RADIO_SWAP_REPEATER_TITLE");
      default:
        return i18n("DEVICE");
    }
  }

  function getDeviceSubtitle(type: DeviceType, subtype: DeviceSubType) {
    switch (subtype) {
      case "control":
        return i18n("RADIO_SWAP_CONTROLLER_SUBTITLE");
      case "monitor":
        return i18n("RADIO_SWAP_GPS_SUBTITLE");
      case "pump":
        if (type === "PIVOT") return i18n("RADIO_SWAP_PIVOT_PUMP_SUBTITLE");
        else return i18n("RADIO_SWAP_PUMP_SUBTITLE");
      case "imeter_device":
        return i18n("RADIO_SWAP_IMETER_SUBTITLE");
      case "repeater":
        return i18n("RADIO_SWAP_REPEATER_SUBTITLE");
      default:
        return i18n("DEVICE");
    }
  }

  async function performRadioSwap(
    deviceType: DeviceType,
    deviceSubType: DeviceSubType,
    otherDevice: number
  ) {
    let url = "";
    if (deviceType === "PIVOT")
      url = `v3/farms/${props.farmID}/pivots/${props.deviceID}/swap/${otherDevice}/${deviceSubType}/`;
    else if (deviceType === "IRPD")
      url = `v3/farms/${props.farmID}/irpds/${props.deviceID}/swap/${otherDevice}/`;
    else if (deviceType === "IMETER")
      url = `/v3/farms/${props.farmID}/metersystems/${props.meterSystemID}/meter/${props.deviceID}/swap/${otherDevice}/`;
    else if (deviceType === "REPEATER")
      url = `v3/farms/${props.farmID}/repeaters/${props.deviceID}/swap/${otherDevice}/`;

    let response = await coreHTTPClient.patch(url).then((response) => {
      setRadioId(response.data.radio_id);
      setPreviousRadioId(response.data.radio_id);
      notify("RADIO_SWAP_SUCCESS", "success", 4000);
      dispatch(hideModal());
    });
  }

  useEffect(() => {
    (async () => {
      let url = "";
      if (props.deviceType === "PIVOT")
        url = `/v3/farms/${props.farmID}/pivots/${props.deviceID}/devices/${props.deviceSubType}/`;
      else if (props.deviceType === "IRPD")
        url = `v3/farms/${props.farmID}/irpds/devices/`;
      else if (props.deviceType === "IMETER")
        url = `/v3/farms/${props.farmID}/metersystems/${props.meterSystemID}/meter/devices/`;
      else if (props.deviceType === "REPEATER")
        url = `v3/farms/${props.farmID}/repeaters/devices/`;

      await coreHTTPClient.get(url).then((returnValue) => {
        if (props.deviceType == "PIVOT")
          setDeviceRadios(
            returnValue.data.filter(
              (pivot) =>
                pivot.communication_type !== PIVOT_COMMUNICATION_TYPE.TYPE_4G
            )
          );
        else setDeviceRadios(returnValue.data);
        setDeviceRadiosLoaded(true);
      });
    })();
  }, []);

  let deviceRadiosTable = (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>
            {getDeviceNameByType(props.deviceType, props.deviceSubType)}
          </TableCell>

          <TableCell>{i18n("RADIO")}</TableCell>

          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {deviceRadios?.length > 1 ? (
          deviceRadios
            ?.filter((value) => {
              if (value[props.deviceSubType].radio_id === props.currentRadioId)
                return false;
              return true;
            })
            .map((value) => (
              <TableRow>
                <TableCell>{value.name}</TableCell>

                <TableCell>{value[props.deviceSubType].radio_id}</TableCell>

                <TableCell align="right">
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      performRadioSwap(
                        props.deviceType,
                        props.deviceSubType,
                        value.id
                      )
                    }
                  >
                    {i18n("SWAP")}
                  </Button>
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={3}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <h5>{i18n("NO_COMPATIBLE_RADIO_FOUND")}</h5>
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <div className="SelectRadio">
      <div className="SelectRadio__header">
        <div className="SelectRadio__title">
          <h3>{getDeviceTitle(props.deviceType, props.deviceSubType)}</h3>
          {getDeviceSubtitle(props.deviceType, props.deviceSubType)}
        </div>
      </div>

      <div className="SelectRadio__list">
        {deviceRadiosLoaded ? deviceRadiosTable : <></>}
      </div>
    </div>
  );
}

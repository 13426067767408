import { IrpdStream, IrpdAction } from "../../redux/irpds/types";
import { AsyncStatus, useAsyncStatus } from "../tools/useAsyncStatus";
import { useState, useEffect, useRef } from "react";
import { coreHTTPClient } from "../../services/webclient";
import axios from "axios";
import checkExists from "../../utils/checks/checkExists";
import { useSelector } from "react-redux";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../redux/filterByDate/types";
import { ApplicationState } from "../../redux";
import { CentralStream, IrrigationV5 } from "../../redux/pivots/types";
import { historicOrderByDate } from "../../pages/Dashboard/Pivot/SelectedPivot/components/HistoricBox/utils/utils";

export type HistoricModels =
  | "irpd_stream"
  | "irpd_action"
  | "irpd_stream_v5"
  | "irpd_action_v5"
  | "CentralStream";

interface Historic {
  limit: number;
  offset: number;
  count: number;

  filters: {
    models: HistoricModels[];
  };
  results: HistoricResultItem[];
}

interface IrpdStreamResult {
  model: "irpd_stream";
  data: IrpdStream;
}

interface IrpdActionResult {
  model: "irpd_action";
  data: IrpdAction;
}

interface IrpdActionResultV5 {
  model: "irpd_action_v5";
  data: IrrigationV5;
}

interface IrpdStreamResultV5 {
  model: "irpd_stream_v5";
  data: IrrigationV5;
}

interface CentralStreamResult {
  model: "CentralStream";
  data: CentralStream;
}

export type HistoricResultItem =
  | IrpdStreamResult
  | IrpdActionResult
  | IrpdActionResultV5
  | IrpdStreamResultV5
  | CentralStreamResult;

export type HistoricResultData =
  | IrpdStreamResult
  | IrpdActionResult
  | IrpdActionResultV5
  | IrpdStreamResultV5
  | CentralStreamResult;

interface HistoricActions {
  getOffset: () => number;
  updateOffset: (offset: number) => void;
  getHistoric?: () => Promise<void>;
  updateHistoric?: (data: HistoricResultData) => void;
  setHistoricModels?: (historic: HistoricModels[]) => void;
  setAdvancedHistoric?: (advanced: boolean) => void;
  setCentralHistoric?: (central: boolean) => void;
}

function mapHistoricToModelData(results: any[]): HistoricResultItem[] {
  return results.map((result) => ({
    model: Object.keys(result)[0] as any,
    data: result[Object.keys(result)[0]],
  }));
}

const useIrpdHistoric = (
  farmID: number,
  irpdID: number,
  initialLimit: number,
  initialOffset: number,
  initalModels: HistoricModels[],
  arrayInitial?: boolean[]
): [AsyncStatus, Historic | undefined, HistoricActions] => {
  const [async, start, finish] = useAsyncStatus();

  const [result, setResult] = useState<Historic>();

  const [offset, setOffset] = useState(initialOffset);
  const offsetRef = useRef<number>(initialOffset);
  const [models, setModels] = useState(initalModels);
  const [advancedHistoric, setAdvancedHistoric] = useState(false);
  const [centralHistoric, setCentralHistoric] = useState(false);
  const cancelCurrentRequestRef = useRef<any | null>(null);

  const filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  useEffect(() => {
    offsetRef.current = offset;
  }, [offset]);

  async function getHistoric(offsetParam?: number) {
    if (cancelCurrentRequestRef.current) {
      cancelCurrentRequestRef.current();
    }
    if (offsetParam) setOffset(offsetParam);
    await new Promise<void>(async (resolve) => {
      start();

      let filterByDateQueryString: string;
      if (
        filterByDate.data.type === FilterTypesChoices.HISTORIC &&
        filterByDate.data.start_date &&
        filterByDate.data.end_date
      ) {
        let { start_date, end_date } = filterByDate.data;
        filterByDateQueryString = `&date_start=${start_date.toISOString()}&date_end=${end_date.toISOString()}`;
      }

      cancelCurrentRequestRef.current = new AbortController();
      try {
        const response = await coreHTTPClient
          .get(
            `v3/farms/${farmID}/irpds/${irpdID}/history/?page=${
              offsetParam || offset
            }${advancedHistoric ? "&periodic=true" : ""}${
              centralHistoric ? "&central=true" : ""
            }${filterByDateQueryString ? filterByDateQueryString : ""}`,
            {
              cancelToken: new axios.CancelToken(
                (c) => (cancelCurrentRequestRef.current = c)
              ),
            }
          )
          .then((response) => {
            if (checkExists(["data", response, "__cascade"])) {
              let resultsMapped: HistoricResultItem[] = mapHistoricToModelData(
                response.data.results
              );

              response.data.results = historicOrderByDate(resultsMapped);
              setResult(response.data);
              finish();
            }
          });
      } catch (err) {
        finish();
      } finally {
        resolve();
      }
    });
  }

  function updateHistoric(newHistoricItem: HistoricResultItem) {
    //Se eu não estou na primeira página, não precisa ter atualização em tempo real
    if (offsetRef.current != 1) return;

    setResult((result) => {
      if (result !== undefined) {
        let isOnList = false;
        let newResult = result;

        newResult.results.map((index) => {
          if (index.data.uuid === newHistoricItem.data.uuid) {
            //
            index.data = newHistoricItem.data;
            isOnList = true;
          }
        });

        if (!isOnList) {
          newResult.results.unshift(newHistoricItem);
          newResult.count += 1;
        }

        if (newResult.results.length > 10) newResult.results.pop();

        const orderedByDateResults = historicOrderByDate(newResult.results);
        newResult["results"] = orderedByDateResults;
        return { ...(newResult as Historic) };
      }
    });
  }

  function getOffset() {
    return offset;
  }

  function updateOffset(page: number) {
    setOffset(page);
  }

  function setHistoricModels(historic: HistoricModels[]) {
    setModels(historic);
  }

  useEffect(() => {
    getHistoric(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [irpdID]);

  useEffect(() => {
    setOffset(1);
    getHistoric(1);
  }, [advancedHistoric, centralHistoric]);

  useEffect(() => {
    if (
      filterByDate.data.type === FilterTypesChoices.HISTORIC &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      getHistoric(1);
    }
  }, [filterByDate]);

  // useEffect(() => {
  //   if (!ctx.inicitialModels) {
  //     ctx.setInicitialModels(true);
  //     return;
  //   }

  //   ctx.setHistoric(!ctx.historic);
  // }, [models]);

  useEffect(() => {
    getHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return [
    async,
    result,
    {
      getOffset,
      updateOffset,
      getHistoric,
      updateHistoric,
      setHistoricModels,
      setAdvancedHistoric,
      setCentralHistoric,
    },
  ];
};

export default useIrpdHistoric;

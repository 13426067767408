import { useEffect, useState } from "react";
import io from "socket.io-client";
import { nanoid } from "nanoid";

const { WEB_SOCKET_URL } = process.env;

interface SocketIOActions {
  subscribe: (channel: string) => Promise<void>;
  unsubscribe: (channel: string) => Promise<void>;
  bind: (event: string | any[], callback: (data: any) => void) => Promise<void>;
  unbind: (event: string | any[]) => Promise<any>;
  disconnect: () => Promise<void>;
}

const socket = io(WEB_SOCKET_URL, {
  transports: ["websocket"],
});

//Map para guardar os channels
const channelMap: { [id: string]: string } = {};

const useSocketIO = (): [boolean, SocketIOActions] => {
  const [isConnected, setIsConnected] = useState(true);
  const [instanceId] = useState(nanoid());

  async function subscribe(channel: string) {
    //if (!socket.connected) return;
    //
    socket.emit("subscribe", { channel: channel });
    channelMap[instanceId] = channel;
  }

  async function unsubscribe(channel: string) {
    //if (!socket.connected) return;
    //
    socket.emit("unsubscribe", { channel: channel });
    delete channelMap[instanceId];
  }

  async function bind(
    event: string,
    callback: (data: any) => void
  ): Promise<void> {
    let channel = channelMap[instanceId];

    socket.on(`${channel}_${event}`, (data: any) => {
      callback(data);
    });
  }

  async function unbind(event: string) {
    let channel = channelMap[instanceId];

    socket.off(`${channel}_${event}`);
  }

  async function disconnect() {
    socket.disconnect();
    //
  }

  useEffect(() => {
    socket.connected ? setIsConnected(true) : setIsConnected(false);
  }, [socket.connected]);

  return [
    isConnected,
    {
      subscribe,
      bind,
      unbind,
      disconnect,
      unsubscribe,
    },
  ];
};

export default useSocketIO;

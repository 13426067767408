import React from "react";
import "./GeneralLocationModal.scss";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../redux";
import { ModalState } from "../../redux/modal/types";
import { hideModal } from "../../redux/modal/actions";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import MobileZone from "../Zones/MobileZone";
import DesktopZone from "../Zones/DesktopZone";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { useTheme } from "@material-ui/core/styles";
import styled from "@emotion/styled";
import { getDeviceNotch } from "../../utils/DeviceNotchCheck";
import { i18n, i18nTextId } from "../../i18n/i18nText";
import { LocationModalState } from "../../redux/locationModal/types";
import { hideLocationModal } from "../../redux/locationModal/actions";

const { detect } = require("detect-browser");
const browser = detect();

const StyledAppBar = styled(AppBar)`
  //iOs only
  @supports (-webkit-touch-callout: none) {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
`;

function GeneralLocationModal() {
  var modal: LocationModalState = useSelector(
    (state: ApplicationState) => state.locationModal
  );

  const dispatch = useDispatch();

  const theme = useTheme();

  return (
    <>
      <MobileZone>
        <div>
          <Dialog
            fullScreen
            open={modal.active}
            onClose={() => {
              dispatch(hideLocationModal());
            }}
            // TransitionComponent={Transition}
            id="mobileGeneralDivModal"
          >
            <StyledAppBar
              style={{
                height: browser.name == "ios-webview" ? "100px" : "auto",
              }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    dispatch(hideLocationModal());
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className="modal__toolbar-padding">
                  {i18n(modal.data.title as i18nTextId)}
                </Typography>
              </Toolbar>
            </StyledAppBar>
            <div style={{ marginBottom: "60px" }}></div>
            <StyledDiv
              onScroll={(e) => {
                e.stopPropagation();
              }}
              className={`general-modal__notch-${getDeviceNotch()}`}
              style={{
                marginTop: browser.name == "ios-webview" ? "120px" : "auto",
              }}
            >
              {modal.data.content}
            </StyledDiv>
          </Dialog>
        </div>
      </MobileZone>

      {/* <DesktopZone>
          <>
            <div
              className={suffixClasses("general-modal__backdrop", [
                ["--hidden", !modal.active]
              ])}
              onClick={() => dispatch(hideModal())}
            ></div>

            <div
              className={suffixClasses("general-modal__card", [
                ["--hidden", !modal.active]
              ])}
            >
              <div
                className="general-modal__close-button"
                onClick={() => dispatch(hideModal())}
              >
                &times;
            </div>
              {modal.data.content}
            </div>
          </>
        </DesktopZone> */}
      <DesktopZone>
        <Dialog
          open={modal.active}
          onClose={() => dispatch(hideModal())}
          maxWidth="lg"
        >
          {/*
          this check is  needed because  if we don't do this  check
          the modal closes before the DialogTitle and the smothness
          is all gone.
          */}
          {modal.active ? (
            modal.data.headerless ? null : (
              <DialogTitle disableTypography>
                <Typography style={{ textAlign: "center" }} variant="h6">
                  {i18n(modal.data.title as i18nTextId)}
                </Typography>
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: theme.spacing(1),
                    top: theme.spacing(1),
                    color: theme.palette.grey[500],
                  }}
                  onClick={() => dispatch(hideLocationModal())}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            )
          ) : null}
          <DialogContent>{modal.data.content}</DialogContent>
        </Dialog>
      </DesktopZone>
    </>
  );
}

export default GeneralLocationModal;

import { action } from "typesafe-actions";
import { Action } from "redux";
import { Pivot, PivotsTypes } from "./types";

export interface SetPivots extends Action {
  type: PivotsTypes.SET_PIVOTS;
  payload: Pivot[];
}

export interface UpdateOrInsertPivot extends Action {
  type: PivotsTypes.UPDATE_OR_INSERT_PIVOT;
  payload: Pivot;
}

export interface SendConfiguration extends Action {
  type: PivotsTypes.SET_CONVENTIONAL_CONFIG;
  payload: {

  }
}

export const setPivots = (pivots: Pivot[]): SetPivots =>
  action(PivotsTypes.SET_PIVOTS, pivots);

export const updateOrInsertPivot = (pivot: Pivot): UpdateOrInsertPivot =>
  action(PivotsTypes.UPDATE_OR_INSERT_PIVOT, pivot);

export type PivotsActions = SetPivots & UpdateOrInsertPivot;

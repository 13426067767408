import { action } from "typesafe-actions";
import { Farm, FarmsTypes } from "./types";
import { Action } from "redux";

export interface SetFarms extends Action {
  type: FarmsTypes.SET_FARMS;
  payload: Farm[];
}

export interface UpdateOrInsertFarm extends Action {
  type: FarmsTypes.UPDATE_OR_INSERT_FARM;
  payload: Farm;
}

export const setFarms = (farms: Farm[]): SetFarms =>
  action(FarmsTypes.SET_FARMS, farms);

export const updateOrInsertFarm = (farm: Farm): UpdateOrInsertFarm =>
  action(FarmsTypes.UPDATE_OR_INSERT_FARM, farm);

export type FarmsActions = SetFarms & UpdateOrInsertFarm;

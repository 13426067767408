import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  Typography,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import { i18n, i18nTextId } from "../../../../../../../../../i18n/i18nText";
import { Close as IconClose } from "@material-ui/icons";
import InputTooltip from "../../../../../../../../../components/InputTooltip/InputTooltip";

const BRIcon = require("../../../../../../../../../assets/images/brazil-icon-32.png");
const USAIcon = require("../../../../../../../../../assets/images/usa-icon-32.png");

const flagSelector = (lang) => {
  switch (lang as String) {
    case "pt-br":
      return BRIcon;
    case "en":
      return USAIcon;
  }
};

const languageObj = (id: String) => {
  switch (id as String) {
    case "pt-br":
      return { id: "pt-br", name: "LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL" };
    case "en":
      return { id: "en", name: "LANGUAGE_SELECTOR_ENGLISH" };
    default:
      return { id: "en", name: "LANGUAGE_SELECTOR_ENGLISH" };
  }
};

interface Props {
  farmID: string;
  costumerID: string;
  language: string;
  setLanguage: (value: string) => void;
  actions: any;
}

const LanguageSelectorModal = (props: Props) => {
  const { farmID, costumerID, language, setLanguage, actions } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(languageObj(language));

  const languages = [
    { id: "en", name: "LANGUAGE_SELECTOR_ENGLISH" },
    { id: "pt-br", name: "LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL" },
  ];

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLanguageChange = (languageId) => {
    const selectedLanguage = languages.find(
      (language) => language.id === languageId
    );
    setSelectedValue(selectedLanguage);
    setLanguage(selectedLanguage.id);
    if (costumerID) {
      actions.patch(parseInt(farmID), { language: selectedLanguage.id });
    }
    handleClose();
  };

  return (
    <div>
      <FormControl className="form-select__form-control">
        <InputLabel id={`${selectedValue.id}-label`}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "baseline",
              flexWrap: "nowrap",
            }}
          >
            {i18n("BILLING_PREFERRED_LANGUAGE")}
            <InputTooltip
              size={18}
              description={i18n("BILLING_PREFERRED_LANGUAGE_TOOLTIP")}
            />
          </div>
        </InputLabel>
        <Select
          fullWidth
          open={false}
          onClose={() => {}}
          onOpen={handleClickOpen}
          value={selectedValue.name}
          displayEmpty
          renderValue={() => (
            <Typography style={{ fontSize: "14px" }}>
              {i18n(selectedValue.name as i18nTextId)}
            </Typography>
          )}
        />
      </FormControl>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
        <Grid container alignItems="center">
          <DialogTitle id="simple-dialog-title">
            {i18n("MOBILE_MODAL_TITLE_LANGUAGES")}
          </DialogTitle>
          <IconButton
            style={{
              padding: 0,
              margin: "10px",
              flexShrink: 0,
            }}
            onClick={() => handleClose()}
          >
            <IconClose />
          </IconButton>
        </Grid>
        <DialogContent>
          <div
            className="language-selector-modal"
            style={{ paddingBottom: "25px" }}
          >
            {languages.map((language) => (
              <div
                key={`language-${language.id}`}
                className="language-selector__language-option"
                onClick={() => handleLanguageChange(language.id)}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <img
                      src={flagSelector(language.id)}
                      alt={language.name}
                      style={{ marginRight: 10, height: 16 }}
                    />
                  </Grid>
                  <Grid item>{i18n(language.name as i18nTextId)}</Grid>
                </Grid>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LanguageSelectorModal;

import React from "react";
import styled from "@emotion/styled";
import { IMeterCompleteAtomFamily } from "../../../../../../recoils/MeterSystemRecoil";
import { useRecoilValue } from "recoil";
import ReportMeterSystemTable from "../../../../Pivot/SelectedPivot/components/BasicInfoBox/components/ReportMeterSystemList/ReportMeterSystemTable";
import ReportGhMeterSystemTable from "../../../../Pivot/SelectedPivot/components/BasicInfoBox/components/ReportMeterSystemList/ReportGhMeterSystemTable";
import PulseFlowReportMeterSystemTable from "../../../../Pivot/SelectedPivot/components/BasicInfoBox/components/PulseFlowReportMeterSystemTable/PulseFlowReportMeterSystemTable";

const Container = styled.div`
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
`;

interface Props {
  iMeterID: number;
}

function MeterSystemCharts(props: Props) {
  const imeter = useRecoilValue(IMeterCompleteAtomFamily(props.iMeterID));

  if (!imeter) return null;

  return (
    <Container>
      {imeter.function === "PULSE_FLOW" ? (
        <PulseFlowReportMeterSystemTable iMeter={imeter} />
      ) : imeter.protocol == "5.3" ? (
        <ReportGhMeterSystemTable iMeter={imeter} />
      ) : (
        <ReportMeterSystemTable iMeter={imeter} />
      )}
    </Container>
  );
}

export default React.memo(MeterSystemCharts);

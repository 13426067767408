import React from "react";
import Email from "../../../../assets/images/email.svg";
import { Button } from "@material-ui/core";
import "./EmailMessage.scss";
import useStyles from "../../Login/hooks/useStyles";
import routes from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../../../i18n/i18nText";

interface Props {
  setPasswordRecovery: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

const EmailMessage = (props: Props) => {
  const classes = useStyles({});
  const navigate = useNavigate();

  const { email } = props;
  return (
    <div className="email-message">
      <div className="email-message__image">
        <img src={Email} />
      </div>
      <div className="email-message__info">
        {i18n("FORGET_PASSWORD_INFO_EMAIL", [`${email}`])}
      </div>
      <div className="email-message__button">
        <Button
          className={classes.buttonBack}
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(routes.AUTH.LOGIN);
          }}
        >
          <>{i18n("FORGET_PASSWORD_OK")}</>
        </Button>
      </div>
    </div>
  );
};

export default EmailMessage;

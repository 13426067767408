import React, { useEffect, useRef, useState } from "react";
import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import "./FormInputPosition.scss";
import { RecoilState, useRecoilState } from "recoil";
import { MarkerState } from "../../../../../../../../recoils/DraggableMapRecoil";
import { nullAtom } from "../../../../../../../../recoils/NullRecoil";
import { i18n, i18nTextId } from "../../../../../../../../i18n/i18nText";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";
import { checkLatLng } from "../../../../../../../../utils/checks";

interface Props {
  markerUrl?: any;
  id: string;
  label?: string;
  lat?: [string, (value: string) => void];
  lng?: [string, (value: string) => void];
  setError: Function;
  disabled?: boolean;
  recoilSelector?: RecoilState<MarkerState>;
  labelTooltip?: string;
}

function FormInputPosition(props: Props) {
  //Como está em fase de transição, estou adicionando um atom nulo que serve pra eu fazer a comparação
  let [latLng, setLatLng] = useRecoilState(props.recoilSelector || nullAtom);
  let [valueLatLng, setValueLatLng] = useState<string>("");
  const inputRef = useRef<HTMLDivElement>(null);

  let elementIsFocus = inputRef?.current?.firstChild == document.activeElement;

  useEffect(() => {
    if (latLng && valueLatLng !== `${latLng.lat},${latLng.lng}`)
      setValueLatLng(`${latLng.lat},${latLng.lng}`);
  }, [latLng]);

  useEffect(() => {
    if (elementIsFocus) setLatLngCallBack(valueLatLng);
  }, [valueLatLng]);

  const setLatLngCallBack = (value: string) => {
    if (value.includes(","))
      if (value.split(",").length == 2)
        if (
          parseFloat(valueLatLng.split(",")[0]) > -90 &&
          parseFloat(valueLatLng.split(",")[0]) < 90 &&
          parseFloat(valueLatLng.split(",")[1]) > -180 &&
          parseFloat(valueLatLng.split(",")[1]) < 180
        ) {
          setLatLng((ll) => ({
            ...ll,
            lat: value.split(",")[0],
            lng: value.split(",")[1],
          }));
          props.lat[1](parseFloat(value.split(",")[0]).toFixed(6));
          props.lng[1](parseFloat(value.split(",")[1]).toFixed(6));
        }
  };

  const errorLatLng = (): string | undefined => {
    if (props.disabled && props.disabled == true) {
      props.setError(false);
      return undefined;
    }
    const isValid = checkLatLng(valueLatLng);
    props.setError(!isValid);
    return isValid ? undefined : "ERROR_PIVOT_LAT_AND_LONG";
  };

  const LabelTooltip = props.labelTooltip ? (
    <InputTooltip
      description={i18n(props.labelTooltip as i18nTextId)}
      size={14}
    />
  ) : null;

  return (
    <div className="form-input-position__item">
      {props.label ? (
        <div
          className="form-input-position__item__label"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {i18n(props.label as i18nTextId)}
          {LabelTooltip}
        </div>
      ) : null}
      {typeof props.markerUrl === "string" ? (
        <img alt="" src={props.markerUrl} />
      ) : (
        props.markerUrl
      )}
      <div className="form-input-position__item__input-container">
        <FormInput
          id={`${props.id}-latlng`}
          helperText={errorLatLng()}
          label={"EDIT_PIVOT_LAT_AND_LONG"}
          state={[valueLatLng, setValueLatLng]}
          disabled={props.disabled}
          inputRef={inputRef}
        />
      </div>
    </div>
  );
}

export default FormInputPosition;

import { action } from "typesafe-actions";
import { FilterByDateRedux, FilterByDateTypes } from "./types";
import { Action } from "redux";

export interface SetFilterByDate extends Action {
  type: FilterByDateTypes.SET_FILTER_BY_DATE;
  payload: FilterByDateRedux;
}

export const setFilterByDate = (
  filterByDate: FilterByDateRedux
): SetFilterByDate =>
  action(FilterByDateTypes.SET_FILTER_BY_DATE, filterByDate);

export type FilterByDateActions = SetFilterByDate;

import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { Map, Refresh } from "@material-ui/icons";
import ListIcon from "@material-ui/icons/List";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import DesktopZone from "../../../components/Zones/DesktopZone";
import useIrpds from "../../../hooks/models/useIrpds";
import usePivots from "../../../hooks/models/usePivots";
import useSocketIO from "../../../hooks/tools/useSocketIO";
import { isMobile } from "../../../mobileConfig";
import { Farm } from "../../../redux/farms/types";
import { Irpd } from "../../../redux/irpds/types";
import { Pivot } from "../../../redux/pivots/types";
import { coreHTTPClient } from "../../../services/webclient";
import {
  CentralBaseDeviceNode,
  DeviceNode,
  GPSBaseDeviceNode,
  NodeTypes,
  PivotBaseDeviceNode,
  PumpBaseDeviceNode,
  RepeaterBaseDeviceNode,
} from "../../../utils/devices/DeviceTypes";
import { PivotSignalLevel } from "../../../utils/models/pivots";
import DashboardBox from "../components/DashboardBox/DashboardBox";
import ButtonWithProgress from "./components/DeviceBox/components/ButtonWithProgress/ButtonWithProgress";
import DeviceBox from "./components/DeviceBox/DeviceBox";
import DrawerRightDevices from "./components/DrawerRightDevices/DrawerRightDevices";
import DeviceFarmMap from "./components/FarmMap/DeviceFarmMap";
import "./Devices.scss";

import {
  generateCentralDeviceObject,
  generateGPSDeviceObject,
  generatePivotDeviceObject,
  generatePumpDeviceObject,
  generateRepeaterDeviceObject,
} from "../../../utils/devices/DeviceGenerator";

import { useSetRecoilState } from "recoil";
import MobileZone from "../../../components/Zones/MobileZone";
import useNotify from "../../../hooks/tools/useNotify";
import { i18n } from "../../../i18n/i18nText";
import { NavbarState } from "../../../recoils/NavbarRecoil";
import {
  BottomContainer,
  MapContainer,
} from "../Farm/SelectedFarm/SelectedFarm";

const { SOCKET_DEVICE_SUFFIX } = process.env;

export const useStyles = makeStyles({
  button: {
    //backgroundColor: "#0066FF",
    //color: "#0066FF"
  },
});

export interface SelectedFarmParams {
  farm: string;
}

export interface DeviceNodeFromAPI {
  from: string;
  to: string;
  db: number;
  quality: PivotSignalLevel;
}

export interface DeviceNodeFound extends DeviceNodeFromAPI {
  typeTo: NodeTypes;
  typeFrom: NodeTypes;

  fromCoordinate: {
    lat: number;
    lon: number;
  };

  toCoordinate: {
    lat: number;
    lon: number;
  };

  fromObject: Pivot | Irpd | Farm | null;
  toObject: Pivot | Irpd | Farm | null;
}

export interface NodeLogDevice {
  logId: number;
  type: NodeTypes;
  id: number;
  radio: string;
  farmName: string;
  deviceName: string;
  date: Date;
  strength_dbm: number;
  strength_string: string;
}

export interface NodeLogFound {
  logId: number;
  type: "FOUND";
  radio: string;
  device: string;
  deviceID: string;
  farmID: string;
  deviceType: string;
  farmName: string;
  date: Date;
}

export type Repeater = {
  automation_type?: number;

  base: number;
  farm: number;
  id: number;
  name: string;
  position: string;
  repeater: {
    created: string;
    id: number;
    radio_id: string;
    taken: string;
    type: string;
  };
  repeater_radio_id?: string; //ading to match objects api return
  type: string;
};

export type NodeLog = NodeLogDevice | NodeLogFound;

export interface EnabledDeviceMap {
  [radio_id: string]: boolean;
}

export function getNodeType(
  pivot_irpd: Pivot | Irpd,
  radio_id: string
): NodeTypes {
  if (pivot_irpd.base_radio_id == radio_id) {
    return "CENTRAL";
  }

  if (pivot_irpd.pump_radio_id == radio_id) {
    return "CENTRAL";
  }

  if ((pivot_irpd as Pivot).control_radio_id == radio_id) {
    return "PIVOT";
  }

  if ((pivot_irpd as Pivot).monitor_radio_id == radio_id) {
    return "GPS";
  }
}

//Função que mapeia todos os devices para seus objetos
function findDeviceNodeCoordinatesAndType(
  farm: Farm,
  pivots: Pivot[],
  irpds: Irpd[],
  node: DeviceNodeFromAPI
): DeviceNodeFound {
  let { db, from, quality, to } = node;
  let filledNode: DeviceNodeFound = {
    ...node,
    fromCoordinate: { lat: 0, lon: 0 },
    toCoordinate: { lat: 0, lon: 0 },
    typeTo: "CENTRAL",
    typeFrom: "CENTRAL",
    fromObject: null,
    toObject: null,
  };

  pivots.forEach((piv) => {
    //get toObject
    if (piv.base_radio_id == node.to) {
      filledNode.typeTo = "CENTRAL";
      filledNode.toCoordinate.lat = parseFloat(farm.location.split(",")[0]);
      filledNode.toCoordinate.lon = parseFloat(farm.location.split(",")[1]);
      filledNode.toObject = farm;
    }

    if (piv.control_radio_id == node.to) {
      filledNode.typeTo = "PIVOT";
      filledNode.toCoordinate.lat =
        piv.protocol >= 5
          ? piv.controllerconfig.content.pivot_positions.latitude_center
          : parseFloat(piv.config.center.split(",")[0]);
      filledNode.toCoordinate.lon =
        piv.protocol >= 5
          ? piv.controllerconfig.content.pivot_positions.longitude_center
          : parseFloat(piv.config.center.split(",")[1]);
      filledNode.toObject = piv;
    }

    if (piv.monitor_radio_id == node.to) {
      filledNode.typeTo = "GPS";
      filledNode.toCoordinate.lat =
        piv.protocol >= 5
          ? piv.controllerstream_gps?.content?.latitude_longitude_gps
              ?.latitude_gps
          : parseFloat(piv.latest_gps_stream.position.split(",")[0]);
      filledNode.toCoordinate.lon =
        piv.protocol >= 5
          ? piv.controllerstream_gps?.content?.latitude_longitude_gps
              ?.longitude_gps
          : parseFloat(piv.latest_gps_stream.position.split(",")[1]);
      filledNode.toObject = piv;
    }

    //get fromObject
    if (piv.base_radio_id == node.from) {
      filledNode.typeFrom = "CENTRAL";
      filledNode.fromCoordinate.lat = parseFloat(farm.location.split(",")[0]);
      filledNode.fromCoordinate.lon = parseFloat(farm.location.split(",")[1]);
      filledNode.fromObject = farm;
    }

    if (piv.control_radio_id == node.from) {
      filledNode.typeFrom = "PIVOT";
      filledNode.fromCoordinate.lat =
        piv.protocol >= 5
          ? piv.controllerconfig.content.pivot_positions.latitude_center
          : parseFloat(piv.config.center.split(",")[0]);
      filledNode.fromCoordinate.lon =
        piv.protocol >= 5
          ? piv.controllerconfig.content.pivot_positions.longitude_center
          : parseFloat(piv.config.center.split(",")[1]);
      filledNode.fromObject = piv;
    }

    if (piv.monitor_radio_id == node.from) {
      filledNode.typeFrom = "GPS";
      filledNode.fromCoordinate.lat =
        piv.protocol >= 5
          ? piv.controllerstream_gps?.content?.latitude_longitude_gps
              ?.latitude_gps
          : parseFloat(piv.latest_gps_stream.position.split(",")[0]);
      filledNode.fromCoordinate.lon =
        piv.protocol >= 5
          ? piv.controllerstream_gps?.content?.latitude_longitude_gps
              ?.longitude_gps
          : parseFloat(piv.latest_gps_stream.position.split(",")[1]);
      filledNode.fromObject = piv;
    }
  });

  //Provavelmente é uma pump
  if (!filledNode.fromObject || !filledNode.toObject)
    irpds.forEach((irpd) => {
      if (irpd.base_radio_id == node.to) {
        filledNode.typeTo = "CENTRAL";
        filledNode.toCoordinate.lat = parseFloat(farm.location.split(",")[0]);
        filledNode.toCoordinate.lon = parseFloat(farm.location.split(",")[1]);
        filledNode.toObject = farm;
      }

      if (irpd.pump_radio_id == node.to) {
        filledNode.typeTo = "PUMP";
        filledNode.toCoordinate.lat = parseFloat(irpd.position.split(",")[0]);
        filledNode.toCoordinate.lon = parseFloat(irpd.position.split(",")[1]);
        filledNode.toObject = irpd;
      }

      if (irpd.base_radio_id == node.from) {
        filledNode.typeFrom = "CENTRAL";
        filledNode.fromCoordinate.lat = parseFloat(farm.location.split(",")[0]);
        filledNode.fromCoordinate.lon = parseFloat(farm.location.split(",")[1]);
        filledNode.fromObject = farm;
      }

      if (irpd.pump_radio_id == node.from) {
        filledNode.typeFrom = "PUMP";
        filledNode.fromCoordinate.lat = parseFloat(irpd.position.split(",")[0]);
        filledNode.fromCoordinate.lon = parseFloat(irpd.position.split(",")[1]);
        filledNode.fromObject = irpd;
      }
    });

  return filledNode;
}

function Devices(props, ref) {
  //For Mobile: to save farm id and name, because it's gonna be needed inside the navbar
  const dispatch = useDispatch();
  const [isConnected, socket] = useSocketIO();
  //const farmID: number = parseInt(useParams().farm, 10);
  let [nodes, setNodes] = useState<DeviceNodeFound[]>([]);
  let [nodeLogs, setNodeLogs] = useState<NodeLog[]>([]);

  const location = useLocation();
  let idFarmURL = location.pathname.split("/");

  const notify = useNotify();

  //mapRef to use for clearing the inicial pivots on "Search Radio" and for DeviceBox navigation on click
  let mapRef = useRef<any>(null);

  let farms = useSelector<any, Farm[]>((state: any) => {
    return state.farms.list;
  });

  const setNavbar = useSetRecoilState(NavbarState);

  let [selectedFarm, setSelectedFarm] = useState(
    idFarmURL[2] ? parseInt(idFarmURL[2]) : farms[0].id
  );

  let [selectedFarmObject, setSelectedFarmObject] = useState(
    idFarmURL[2]
      ? farms.find((farm) => farm.id == parseInt(idFarmURL[2]))
      : farms[0]
  );

  let [farmRadioID, setFarmRadioID] = useState("");

  async function getRadioCentralID() {
    await new Promise(async () => {
      try {
        const response = await coreHTTPClient.get(`v3/farms/${selectedFarm}/`);
        if (response?.data?.base) {
          setFarmRadioID(response.data.base.radio_id);
          setSelectedFarmObject({
            ...selectedFarmObject,
            base: { id: selectedFarm, radio_id: response.data.base.radio_id },
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  const [loadingPivots, pivots] = usePivots(selectedFarm);
  const [loadingIrpds, irpds] = useIrpds(selectedFarm);
  const [enabledDevices, setEnabledDevices] = useState<EnabledDeviceMap>({});
  const navigate = useNavigate();

  const [repeaters, setRepeaters] = useState<Repeater[]>();

  const [shouldShowGPS, setShouldShowGPS] = useState(false);
  const [showTerrainMap, setShowTerrainMap] = useState(false);
  const [shouldAllowMultiple, setShouldAllowMultiple] = useState(false);

  const [selectedTab, setSelectedTab] = React.useState(
    localStorage.getItem("selected-bottom-tab-2")
      ? parseInt(localStorage.getItem("selected-bottom-tab-2"))
      : 0
  );

  const classes = useStyles({});

  useEffect(() => {
    getRadioCentralID();
    if (selectedFarm) {
      (async () => {
        try {
          const response = await coreHTTPClient.get(
            `v3/farms/${selectedFarm}/repeaters/`
          );
          if (response.data) setRepeaters(response.data);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [selectedFarm]);

  useEffect(() => {
    setNavbar({
      title: i18n("DEVICE_DRAWER_TITLE"),
    });
  }, []);

  useEffect(() => {
    if (isNaN(selectedFarm)) return;

    let farm = farms.find((f) => f.id == selectedFarm);

    window.document.title = `${farm?.name} - ${i18n("DEVICE_DRAWER_TITLE")}`;
    if (farmRadioID != "") {
      socket.subscribe(`${SOCKET_DEVICE_SUFFIX}n/${farmRadioID}`);
      // socket.subscribe(`${SOCKET_DEVICE_SUFFIX}f/${farmRadioID}`);
    }
    // else notify("DEVICE_SOCKET_SUBSCRIBE_ERROR", "warning");

    socket.bind(
      "device_sweep",
      (data: {
        radio_id: string;
        device_name: string;
        device_id: string;
        farm_id: string;
        device_type: string;
      }) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (
            radioIDs.includes(data.radio_id) ||
            data.farm_id == selectedFarm.toString()
          ) {
            setNodeLogs((logs) => [
              ...logs,
              {
                logId: logs.length + 1,
                type: "FOUND",
                date: new Date(),
                farmName: farm.name,
                radio: data.radio_id,
                device: data.device_name,
                deviceID: data.device_id,
                farmID: data.farm_id,
                deviceType: data.device_type,
              },
            ]);
            setEnabledDevices((map) => ({
              ...map,
              [data.radio_id]: true,
              [data.device_name]: true,
              [data.device_id]: true,
              [data.farm_id]: true,
              [data.device_type]: true,
            }));
          }
        });
      }
    );

    return () => {
      socket.unbind("get_devices");
      socket.unbind("find_nodes");
      if (farmRadioID != "") {
        socket.unsubscribe(`${SOCKET_DEVICE_SUFFIX}n/${farmRadioID}`);
        socket.unsubscribe(`${SOCKET_DEVICE_SUFFIX}f/${farmRadioID}`);
      }
    };
  }, [selectedFarm, farmRadioID]);

  useEffect(() => {
    if (pivots || irpds) {
      let farm = farms.find((f) => f.id == selectedFarm);
      (async () => {
        const response = await coreHTTPClient.get(`v3/farms/${selectedFarm}/`);
        if (response.data.location) {
          farm.location = response.data.location as string;
          setSelectedFarmObject({
            ...farm,
            base: { id: selectedFarm, radio_id: farmRadioID },
          });
        } else farm.location = "0,0";
      })();

      if (farmRadioID != "") {
        socket.subscribe(`${SOCKET_DEVICE_SUFFIX}f/${farmRadioID}`);
      }

      socket.bind("find_nodes", (data: DeviceNodeFromAPI) => {
        let deviceNode = findDeviceNodeCoordinatesAndType(
          farm,
          pivots,
          irpds,
          data
        );

        ReactDOM.unstable_batchedUpdates(() => {
          setNodeLogs((logs) => [
            ...logs,
            {
              logId: logs.length + 1,
              type: deviceNode.typeTo,
              date: new Date(),
              farmName: farm.name,
              id: deviceNode.toObject ? deviceNode.toObject.id : -1,
              radio: deviceNode.to,
              strength_dbm: data.db,
              strength_string: data.quality,
              deviceName: deviceNode.toObject ? deviceNode.toObject.name : "",
            },
          ]);
          setNodes((nodes) => [...nodes, deviceNode]);
        });
      });
    }

    return () => {
      socket.unbind("find_nodes");
    };
  }, [pivots, irpds]);

  async function doDeviceSearch(radio_id: string) {
    if (!shouldAllowMultiple) setNodes([]);

    await coreHTTPClient.post(`v3/farms/${selectedFarm}/mqtt/gf/`, {
      payload: radio_id,
    });
  }

  const devices: DeviceNode[] = useMemo(() => {
    let centralDevices: CentralBaseDeviceNode[] = [];
    let pivotDevices: PivotBaseDeviceNode[] = [];
    let gpsDevices: GPSBaseDeviceNode[] = [];
    let pumpDevices: PumpBaseDeviceNode[] = [];
    let repeaterDevices: RepeaterBaseDeviceNode[] = [];
    if (selectedFarmObject) {
      centralDevices = [generateCentralDeviceObject(selectedFarmObject)];
    }

    if (pivots) {
      pivotDevices = pivots.map((piv) => generatePivotDeviceObject(piv));
      let filteredPivots = pivots.filter((piv) => piv.automation_type === 0);
      gpsDevices = filteredPivots.map((piv) => generateGPSDeviceObject(piv));
    }

    if (irpds) {
      pumpDevices = irpds.map((irpd) => generatePumpDeviceObject(irpd));
    }

    if (repeaters) {
      repeaterDevices = repeaters.map((repeater) =>
        generateRepeaterDeviceObject(repeater)
      );
    }
    return [
      ...centralDevices,
      ...pivotDevices,
      ...gpsDevices,
      ...pumpDevices,
      ...repeaterDevices,
    ];
  }, [pivots, irpds, repeaters, selectedFarm, selectedFarmObject]);

  const radioIDs = devices.map((device) => device.radio_id);

  const deviceRefs = useMemo<React.RefObject<HTMLDivElement>[]>(() => {
    let deviceRefArray = [];

    devices.forEach((_) => {
      deviceRefArray.push(React.createRef<HTMLDivElement>());
    });

    return deviceRefArray;
  }, [devices]);

  return (
    <>
      <DesktopZone>
        <div className={isMobile() ? "selected-farm-mobile" : "selected-farm"}>
          <DrawerRightDevices nodeLogs={nodeLogs} />
          <div className="selected-farm__grid">
            <div className="selected-farm__devices">
              <DeviceBox
                deviceRefs={deviceRefs}
                loading={loadingPivots}
                repeaters={repeaters}
                selectedFarm={selectedFarm}
                setSelectedFarm={setSelectedFarm}
                farms={farms}
                irpds={irpds}
                enabledDevices={enabledDevices}
                nodes={nodes}
                doDeviceSearch={doDeviceSearch}
                mapRef={mapRef}
                setEnabledDevices={setEnabledDevices}
                devices={devices}
              />
            </div>
            <div className="selected-farm__map-container">
              <DashboardBox
                leftElement={
                  <div
                    style={{
                      maxWidth: 200,
                    }}
                  >
                    <ButtonWithProgress
                      className={classes.button}
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        (async () => {
                          setEnabledDevices({});
                          if (mapRef.current) mapRef.current.clearPivotMap();
                          const response = await coreHTTPClient.post(
                            `v3/farms/${selectedFarm}/mqtt/gn/`
                          );
                        })();
                      }}
                    >
                      {i18n("DEVICE_BOX_SEARCH_RADIO")}
                    </ButtonWithProgress>
                  </div>
                }
                centerElement={<h2>{selectedFarmObject?.name}</h2>}
                rightElement={
                  <>
                    <Switch
                      color="primary"
                      checked={showTerrainMap}
                      onChange={() => setShowTerrainMap((show) => !show)}
                    />
                    {i18n("TERRAIN_MAP")}

                    <div className="separator-header"></div>
                    <Switch
                      color="primary"
                      checked={shouldShowGPS}
                      onChange={() => setShouldShowGPS((show) => !show)}
                    />
                    <>{i18n("DEVICE_BOX_SHOW_GPS")}</>

                    <div className="separator-header"></div>

                    <Switch
                      color="primary"
                      checked={shouldAllowMultiple}
                      onChange={() => setShouldAllowMultiple((show) => !show)}
                    />
                    <>{i18n("DEVICE_BOX_KEEP_LINES")}</>
                  </>
                }
              >
                <div className="selected-farm__map">
                  {loadingPivots ? (
                    <div className="devices-loading-div">
                      <CircularProgress size={100} />
                    </div>
                  ) : (
                    <DeviceFarmMap
                      pivotRefs={deviceRefs}
                      ref={mapRef}
                      shouldShowGPS={shouldShowGPS}
                      farm={selectedFarmObject}
                      nodes={nodes}
                      enabledDevices={enabledDevices}
                      draggable={true}
                      devices={devices}
                      showTerrainMap={showTerrainMap}
                      disableUI
                    ></DeviceFarmMap>
                  )}
                </div>
              </DashboardBox>
            </div>
          </div>
        </div>
      </DesktopZone>
      <MobileZone>
        <div
          className={isMobile() ? "selected-farm-mobile" : "selected-farm"}
          style={selectedTab === 0 ? null : { display: "none" }}
        >
          <DrawerRightDevices nodeLogs={nodeLogs} />
          <div className="selected-farm__grid">
            <div className="selected-farm__devices">
              <DeviceBox
                deviceRefs={deviceRefs}
                loading={loadingPivots}
                repeaters={repeaters}
                selectedFarm={selectedFarm}
                setSelectedFarm={setSelectedFarm}
                farms={farms}
                irpds={irpds}
                enabledDevices={enabledDevices}
                nodes={nodes}
                doDeviceSearch={doDeviceSearch}
                mapRef={mapRef}
                setEnabledDevices={setEnabledDevices}
                devices={devices}
              />
            </div>
          </div>
        </div>
        <div style={selectedTab === 1 ? null : { display: "none" }}>
          <MapContainer key={`search-mobile-devices`}>
            {loadingPivots ? (
              <div className="devices-loading-div">
                <CircularProgress size={100} />
              </div>
            ) : (
              <DeviceFarmMap
                pivotRefs={deviceRefs}
                ref={mapRef}
                shouldShowGPS={shouldShowGPS}
                farm={selectedFarmObject}
                nodes={nodes}
                enabledDevices={enabledDevices}
                draggable={true}
                devices={devices}
                showTerrainMap={showTerrainMap}
                disableUI
              ></DeviceFarmMap>
            )}
          </MapContainer>
        </div>
        <BottomContainer>
          <BottomNavigation
            value={selectedTab}
            onChange={(event, newValue) => {
              localStorage.setItem("selected-bottom-tab-2", String(newValue));
              setSelectedTab(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction
              label={i18n("SELECTED_FARM_LIST")}
              icon={<ListIcon />}
            />
            <BottomNavigationAction
              label={i18n("SELECTED_FARM_MAP")}
              icon={<Map />}
            />
          </BottomNavigation>
        </BottomContainer>
      </MobileZone>
    </>
  );
}

export default Devices;

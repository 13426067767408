import React from "react";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { PivotMaintenance } from "../../../../../../../../../../redux/pivots/types";

interface Props {
  maintenance: PivotMaintenance;
}

const ExpandedMaintenance = (props: Props) => {
  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <span>
          {i18n("HISTORIC_IRPD_ACTION_USER")}
          <b>{i18n("VALUE", [`${props.maintenance.user?.username}`])}</b>
        </span>
      </div>
    </div>
  );
};

export default ExpandedMaintenance;

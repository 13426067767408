import React from "react";
import "./EditFarmFormPlaceholder.scss";
import DashboardBox from "../../../../../../components/DashboardBox/DashboardBox";
import { InfoOutlined } from "@material-ui/icons";
import { i18n } from "../../../../../../../../i18n/i18nText";

function EditFarmFormPlaceholder() {
  return (
    <>
      <div className="general-form__container">
        <DashboardBox centerElement={<h2>{<>{i18n("EDIT_FARM_LABEL")}</>}</h2>}>
          <div className="general-form__group">
            <div className="edit-farm-form-placeholder__12"></div>
            <div className="general-form__group__header">
              <InfoOutlined />
              {i18n("EDIT_FARM_GENERAL_INFORMATION")}
            </div>

            <div className="edit-farm-form-placeholder">
              <div className="edit-farm-form-placeholder__content">
                <div className="edit-farm-form-placeholder__4"></div>
                <div className="edit-farm-form-placeholder__4"></div>
                <div className="edit-farm-form-placeholder__4"></div>
              </div>
            </div>

            <div className="edit-farm-form-placeholder__row-10-2">
              <div className="edit-farm-form-placeholder">
                <div className="edit-farm-form-placeholder__content">
                  <div className="edit-farm-form-placeholder__6"></div>
                  <div className="edit-farm-form-placeholder__6"></div>
                  <div style={{ width: "80%" }}></div>
                </div>
              </div>
            </div>

            <div className="general-form__map">
              <div className="edit-farm-form-placeholder">
                <div className="edit-farm-form-placeholder__content">
                  <div className="edit-farm-form-placeholder__map"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="general-form__row-flex-end"></div>
        </DashboardBox>
      </div>
    </>
  );
}

export default EditFarmFormPlaceholder;

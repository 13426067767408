import { coreHTTPClient } from "../../services/webclient";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import useNotify from "../tools/useNotify";
import { hideLoading, showLoading } from "../../redux/loading/actions";

interface PivotActionCreateModel {
  direction: string;
  operation: string;
  endgun: string;
  speed: string;
  startDateTime?: string;
  endDateTime?: string;
  farmID: number;
  pivotID: number;
}

const useCreatePivotAction = (): [
  (pivotAction: PivotActionCreateModel) => void
] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  async function createPivotAction(pivotAction: PivotActionCreateModel) {
    const payload = {
      direction: parseInt(pivotAction.direction),
      mode: parseInt(pivotAction.operation),
      endgun: parseInt(pivotAction.endgun),
      start:
        pivotAction.startDateTime === undefined
          ? undefined
          : pivotAction.startDateTime,
      end:
        pivotAction.endDateTime === undefined
          ? undefined
          : pivotAction.endDateTime,
      pivot: pivotAction.pivotID,
      speed: parseInt(pivotAction.speed),
    };

    // direction: "0",
    //   operation: "0",
    //     endgun: "0",
    //       speed: "0",
    //         startDateTime: moment()

    await new Promise(async (resolve) => {
      try {
        dispatch(showLoading());
        await coreHTTPClient
          .post(
            `/v3/farms/${pivotAction.farmID}/pivots/${pivotAction.pivotID}/actions/`,
            payload
          )
          .then(() => {
            dispatch(hideLoading());
            dispatch(hideModal());
            notify("IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG", "success");
          });
      } catch (err) {
        dispatch(hideLoading());

        notify("IRRIGATION_BOX_GENERIC_ERROR_MSG", "error");
      }
    });
  }

  return [createPivotAction];
};

export default useCreatePivotAction;

import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { objIsEmpty } from "../../utils/types";
import useNotify from "../tools/useNotify";
import { useRecoilState } from "recoil";
import { Profile, ProfileState } from "../../recoils/ProfileRecoil";

interface ProfileActions {
  get: () => {};
  patch: (profileForm: Profile) => void;
}

const useProfile = (): [Profile, AsyncStatus, ProfileActions, boolean] => {
  const [async, initLoading, finishLoading] = useAsyncStatus();
  const [profile, updateProfile] = useRecoilState(ProfileState);
  const notify = useNotify();
  const [success, setSuccess] = useState(false);

  async function getProfile() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const profileResponse = await coreHTTPClient.get(`v3/auth/profile/`);

        if (checkExists(["data.profile", profileResponse, "__cascade"])) {
          const userResponse = await coreHTTPClient.get(`v3/auth/user/`);
          var tempProfile = {
            ...profileResponse.data?.profile,
            first_name: userResponse?.data?.first_name,
            last_name: userResponse?.data?.last_name,
            email: userResponse?.data?.email,
          };
          updateProfile(tempProfile);
        }
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function patchProfile(profileForm: Profile) {
    await new Promise(async (resolve) => {
      setSuccess(false);
      initLoading();
      try {
        if (!objIsEmpty(profileForm)) {
          const profileResponse = await coreHTTPClient.post(
            `/v3/auth/profile/`,
            profileForm
          );

          if (profileResponse.data.error == "CPF_ALREADY_USED") {
            notify("CPF_ALREADY_USED", "error");
          } else if (profileResponse.data.error == "INVALID_CPF") {
            notify("INVALID_CPF", "error");
          } else if (checkExists(["data", profileResponse, "__cascade"])) {
            const userResponse = await coreHTTPClient.get(`v3/auth/user/`);
            var profile = {
              ...profileResponse?.data,
              first_name: userResponse?.data?.first_name,
              last_name: userResponse?.data?.last_name,
              email: userResponse?.data?.email,
            };
            updateProfile(profile);
            setSuccess(true);
          }
        } else {
          await getProfile();
        }
      } catch (err) {
        notify("PROFILE_BOX_CONVENTIONAL_ERROR_MSG", "error");
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line
  }, []);

  return [
    profile,
    async,
    {
      get: getProfile,
      patch: patchProfile,
    },
    success,
  ];
};

export default useProfile;

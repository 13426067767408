import React from "react";
import "./DialogModal.scss";
import { Button } from "@material-ui/core";
import { useStyles } from "./DialogModal.style";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import { i18n, i18nTextId } from "../../i18n/i18nText";

interface DialogModalProps {
  title: string;
  titleArgs?: string[];
  description?: string;
  descriptionArgs?: string[];
  yes: string;
  yesArgs?: string[];
  yesAction: () => void;
  no: string;
  noArgs?: string[];
  children?: JSX.Element;
}

function DialogModal(props: DialogModalProps) {
  const {
    title,
    titleArgs,
    description,
    descriptionArgs,
    yes,
    yesArgs,
    no,
    noArgs,
    children,
  } = props;

  const classes = useStyles(props);

  const dispatch = useDispatch();

  return (
    <div className="dialog-modal">
      <div className="dialog-modal__content">
        <h2>{i18n(title as i18nTextId, titleArgs)}</h2>
        <p>{i18n(description as i18nTextId, descriptionArgs)}</p>
        {children}
      </div>
      <div className="dialog-modal__button-container">
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          className={classes.declineButton}
          onClick={() => {
            dispatch(hideModal());
          }}
        >
          {i18n(no as i18nTextId, noArgs)}
        </Button>
        <Button
          style={{ width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() => {
            props.yesAction();
            dispatch(hideModal());
          }}
        >
          {i18n(yes as i18nTextId, yesArgs)}
        </Button>
      </div>
    </div>
  );
}

export default DialogModal;

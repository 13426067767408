import { AsyncStatus, useAsyncStatus } from "../tools/useAsyncStatus";
import { useState, useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";
import { ControllerConfig } from "../../redux/pivots/types";
import useSocketIO from "../tools/useSocketIO";

const { SOCKET_SUFFIX } = process.env;

type EncoderHistory = {
  id: number;
  created_by: {
    id: number;
    username: string;
    email: string;
  };
  uuid: string;
  created: string;
  updated: string;
  message_status: number;
  pivot: number;
};

const useConfigEncoder = (
  farmID: number,
  pivotID: number
): [
  AsyncStatus,
  EncoderHistory[] | undefined,
  number,
  () => void,
  () => void,
  (value: string) => void
] => {
  const [async, initLoading, finishLoading] = useAsyncStatus();
  const [total, setTotal] = useState(0);
  const [result, setResult] = useState<EncoderHistory[]>();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();
  const [isConnected, socket] = useSocketIO();

  function updateResult(obj: EncoderHistory) {
    const index = result.findIndex((item) => item.id === obj.id);
    let updatedResult = [...result];

    if (index !== -1) {
      updatedResult[index] = obj;
    } else {
      updatedResult.push(obj);
    }

    updatedResult = updatedResult
      .sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      )
      .slice(0, 10);
    return updatedResult;
  }

  async function getHistoric() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient
          .get(`v4/farms/${farmID}/pivots/${pivotID}/encoder/reset/`)
          .then((response) => {
            setTotal(response.data.count);
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function nextPage() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(next).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function previousPage() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(previous).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  async function currentPage(current) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient
          .get(
            `v4/farms/${farmID}/pivots/${pivotID}/encoder/reset/?page=${current}`
          )
          .then((response) => {
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
      } catch (err) {
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}4g_encoder@${pivotID}`);

      socket.bind("ResetEncoder_reset", (data: EncoderHistory) => {
        const updatedResult = updateResult(data);
        setResult(updatedResult);
      });

      return () => {
        socket.unbind("ResetEncoder_reset");
        socket.unsubscribe(`${SOCKET_SUFFIX}4g_encoder@${pivotID}`);
      };
    }
  }, []);

  useEffect(
    () => {
      getHistoric();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [async, result, total, previousPage, nextPage, currentPage];
};

export default useConfigEncoder;

import { Tab, Tabs, Typography } from "@material-ui/core";
import { AssignmentOutlined, HistoryOutlined, Star } from "@material-ui/icons";
import React, { useState } from "react";
import { Irpd } from "../../../../../../redux/irpds/types";
import HistoricConfigV5 from "../DrawerRightEditIrpd/components/Historic/HistoricConfigV5";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../mobileConfig";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";

interface Props {
  farmID: number;
  irpd: Irpd;
  irpdID: number;
  loadHistoricInfo: (
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) => void;
}

const LabelsWrapper = styled.div`
  margin: 20px 15px;
`;

const IconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export function Labels() {
  return (
    <LabelsWrapper>
      {isMobile() ? (
        <>
          <h4 style={{ margin: "10px 0 5px 0", color: "#555" }}>
            {i18n("LEGEND")}:
          </h4>

          <IconLabelWrapper>
            <HistoryOutlined fontSize={"inherit"} />

            <div style={{ marginLeft: "5px" }}>
              {i18n("ALL_CONFIGURATIONS")}
            </div>
          </IconLabelWrapper>

          <IconLabelWrapper>
            <Star fontSize={"inherit"} />

            <div style={{ marginLeft: "5px" }}>
              {i18n("HISTORIC_CONFIG_PIVOT_PINNED")}
            </div>
          </IconLabelWrapper>

          <IconLabelWrapper>
            <RemoveIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("CONFIG_NOT_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("CONFIG_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneAllIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgb(33, 150, 243)"}
            />

            {i18n("CONFIG_RECEIVED")}
          </IconLabelWrapper>
        </>
      ) : (
        <>
          <h4 style={{ margin: "-10px 5px 0 -15px", color: "#555" }}>
            {i18n("LEGEND")}:
          </h4>

          <IconLabelWrapper>
            <HistoryOutlined
              fontSize={"inherit"}
              style={{ margin: "0 5px 0 -16px" }}
            />

            {i18n("ALL_CONFIGURATIONS")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <Star fontSize={"inherit"} style={{ margin: "0 5px 0 -16px" }} />

            {i18n("HISTORIC_CONFIG_PIVOT_PINNED")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <RemoveIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("TEXT_CONFIG_NOT_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("TEXT_CONFIG_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneAllIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgb(33, 150, 243)"}
            />

            {i18n("TEXT_CONFIG_RECEIVED")}
          </IconLabelWrapper>

          <div style={{ margin: "0 5px 0px -15px" }}>
            <b>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</b> -{" "}
            {i18n("HISTORIC_CONFIG_INF_PUMP")}
          </div>
        </>
      )}
    </LabelsWrapper>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <div style={{ padding: "0px 10px 3px 0" }}>{children}</div>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HistoricConfigMobileV5(props: Props) {
  const { farmID, irpdID, loadHistoricInfo, irpd } = props;

  //Configuração do tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Labels />

        <Tabs
          orientation="horizontal"
          value={value}
          centered={true}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Configuration Historic"
          variant="fullWidth"
        >
          <Tab
            style={{
              minWidth: 0,
              borderTop: "1px solid rgba(0,0,0,0.1)",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <HistoryOutlined
                color="inherit"
                htmlColor={value === 0 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(0)}
          />

          <Tab
            style={{
              minWidth: 0,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              borderTop: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <Star
                color="inherit"
                htmlColor={value === 1 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(1)}
          />

          <Tab
            style={{
              minWidth: 0,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              borderTop: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <AssignmentOutlined
                color="inherit"
                htmlColor={value === 2 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(2)}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <HistoricConfigV5
            farmID={farmID}
            irpd={irpd}
            irpdID={irpdID}
            pinned={false}
            loadHistoricInfo={loadHistoricInfo}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <HistoricConfigV5
            farmID={farmID}
            irpd={irpd}
            irpdID={irpdID}
            pinned={true}
            loadHistoricInfo={loadHistoricInfo}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Typography
            style={{
              backgroundColor: "#4caf50",
              padding: "8px 16px",
              borderRadius: 8,
              color: "#fff",
              margin: "16px 8px",
            }}
          >
            {i18n("HISTORIC_CONFIG_PIVOT_SUBTITLE")}
          </Typography>
          <HistoricConfigV5
            farmID={farmID}
            irpd={irpd}
            irpdID={irpdID}
            pinned={false}
            datasheet={true}
            loadHistoricInfo={props.loadHistoricInfo}
          />
        </TabPanel>
      </div>
    </div>
  );
}

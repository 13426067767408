import React, { useMemo, useCallback, useEffect, useState } from "react";
import GoogleMap, {
  Coords,
  ClickEventValue,
  MapOptions,
} from "google-map-react";
import { ChildrenProps } from "../../utils/types";
import { CustomMarkerProps } from "../CustomMarker/CustomMarker";

// / <reference path="<relevant path>/node_modules/@types/googlemaps/index.d.ts" />

export interface MapMouseEvent {
  lat: number;
  lng: number;
  x: number;
  y: number;
}

interface Props {
  lat?: number;
  lng?: number;
  draggable?: boolean;
  bounds?: any;
  zoom?: number;
  onClick?: (e: ClickEventValue) => void;
  onChildMouseDown?(
    childKey: string,
    childProps: CustomMarkerProps,
    mouse: MapMouseEvent
  ): void;
  onChildMouseUp?(
    childKey: string,
    childProps: CustomMarkerProps,
    mouse: MapMouseEvent
  ): void;
  onChildMouseMove?(
    childKey: string,
    childProps: CustomMarkerProps,
    mouse: MapMouseEvent
  ): void;
  onGoogleApiLoaded?(maps: { map: any; maps: any }): void;
  disableUI?: boolean;
  disableFullScreen?: boolean;
  center?: Coords;
  maxZoom?: number;
  mapType?: string;
  zoomScroll?: boolean;
  fitBoundsEveryBoundsChange?: boolean;
  clickOnMapToToggleFullscreen?: boolean;
}

function Map(props: Props & ChildrenProps) {
  const { bounds, onClick, fitBoundsEveryBoundsChange } = props;
  const [dontFitBoundsAnymore, setDontFitBoundsAnymore] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);

  const [maps, setMaps] = useState<any>(null);

  const onGoogleApiLoaded = useCallback(
    (maps: { map: any; maps: any; ref: Element | null }) => {
      setMaps(maps);
      if (props.onGoogleApiLoaded !== undefined) props.onGoogleApiLoaded(maps);
    },
    []
  );

  // Função que é redeclarada toda vez que os props mudarem. Necessária pois a primeira vez
  // que chega os props o lat/lng é 0
  useMemo(() => {
    if (maps && bounds !== undefined && maps.map !== undefined) {
      if (dontFitBoundsAnymore < 2) {
        maps.map.fitBounds(bounds);
        setDontFitBoundsAnymore(dontFitBoundsAnymore + 1);
      }
      maps.ref.onfullscreenchange = (_event) => {
        if (document.fullscreenElement) {
          maps.map.fitBounds(bounds);
          if (maps.map.getZoom() > 15) maps.map.setZoom(15);
          setFullScreen(true);
        } else {
          setFullScreen(false);
          maps.map.fitBounds(bounds);
          if (maps.map.getZoom() > 15) maps.map.setZoom(15);
        }
      };
    }
  }, [props]);

  useEffect(() => {
    if (fitBoundsEveryBoundsChange && maps && maps.map !== undefined) {
      maps.map.fitBounds(bounds);
      if (maps.map.getZoom() > 15) maps.map.setZoom(15);
    }
  }, [bounds]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMap
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChildMouseDown={props.onChildMouseDown}
        onChildMouseUp={props.onChildMouseUp}
        onChildMouseMove={props.onChildMouseMove}
        draggable={fullScreen ? fullScreen : props.draggable}
        bootstrapURLKeys={{ key: process.env.MAPS_KEY.replace("&key=", "") }}
        zoom={props.zoom !== undefined ? props.zoom : 15}
        center={props.center ? props.center : { lat: -23, lng: -46 }}
        onClick={(...args) => {
          if (props.clickOnMapToToggleFullscreen) {
            // Já tem um elemento fullscreen
            if (document.fullscreenElement) {
              document.exitFullscreen();
            } else if (maps?.ref?.requestFullscreen)
              maps.ref.requestFullscreen();
          }

          if (onClick !== undefined) {
            onClick(...args);
          }
        }}
        options={(_maps) => {
          const optionsObject: MapOptions = {
            mapTypeId:
              props.mapType !== undefined ? props.mapType : "satellite",
            disableDefaultUI: props.disableUI,
            fullscreenControl: !props.disableFullScreen,
            zoomControl: window.location.href.includes("edit") ? true : false,
            scrollwheel: props.zoomScroll,
          };

          if (props.maxZoom) optionsObject.maxZoom = props.maxZoom;
          return optionsObject;
        }}
      >
        {props.children}
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);

import React, { useMemo, useContext, useEffect, useRef } from "react";
import moment from "moment";
import { ReactJSX } from "../../../../../../utils/types";
import ExpandedContent from "./components/ExpandedContent/ExpandedContent";
import GeneralTable, {
  Column,
  Row,
} from "../../../../../../components/GeneralTable/GeneralTable";
import { useParams } from "react-router";
import { getResume, getResumeNoContent } from "./utils/utils";
import usePivotHistoric, {
  HistoricModels,
} from "../../../../../../hooks/models/usePivotHistoric";
import { PivotContext } from "../../SelectedPivot";
import { isMobile } from "../../../../../../mobileConfig";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AtomFamilySetterHelper,
  FallbackPivot,
  PivotConfigFamily,
} from "../../../../../../recoils/PivotRecoil";
import { fillMapHistoryBySocket } from "../../../../../../utils/models/pivots";
import { i18n } from "../../../../../../i18n/i18nText";

const { SOCKET_SUFFIX } = process.env;

interface Props {
  advanced: boolean;
  central: boolean;
}

function HistoricBox(props: Props) {
  const columns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "type",
      label: <>{i18n("HISTORIC_PANEL_LABEL_TYPE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const mobcolumns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const { pivot } = useContext(PivotContext);

  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);
  let atomFamilySetter = useSetRecoilState(AtomFamilySetterHelper);

  const historicTable = [
    "painel_stream",
    "pivot_action",
    "pivot_action_vri_deliverer",
    "simple_irrigation",
    "segment_irrigation",
    "schedule_irrigation",
    "panel_stream_v5_simple",
  ];

  const [async, historic, historicActions] = usePivotHistoric(
    farmID,
    pivotID,
    10,
    1,
    historicTable as HistoricModels[]
  );

  const [isConnected, socket] = useSocketIO();

  const isAdvanced = useRef<boolean>(props.advanced);

  const showCentralInformation = useRef<boolean>(props.central);

  const pivotMapHistoryRef = useRef(pivot.map_history);

  useEffect(() => {
    pivotMapHistoryRef.current = pivot.map_history;
  }, [pivot.map_history]);

  /*
    useEffect para propagar props.check na referência isChecked, usada dentro dos 
    binds websockets que são declarados somente no mount da tela.
  */
  //When switch change, the historicTable receive newobject to return newHisotirc

  useEffect(() => {
    showCentralInformation.current = props.central;
    historicActions.setCentralHistoric(props.central);
  }, [props.central]);

  useEffect(() => {
    isAdvanced.current = props.advanced;
    historicActions.setAdvancedHistoric(props.advanced);
  }, [props.advanced]);

  const pivotConfig = useRecoilValue(PivotConfigFamily(pivot.id));

  let rows = useMemo(() => {
    if (historic === undefined) return []; // Initialize with an empty array if historic is not loaded yet.

    const filteredRows = historic.results.map((item) => {
      let date: string;
      let type;
      let color;
      let overview;
      let isEmpty = false;
      // @ts-ignore
      if (item && item.data.content) {
        // @ts-ignore
        const contentKeys = Object.keys(item.data.content);

        if (contentKeys.some((key) => key.startsWith("rs"))) {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        // making variables when the keys are correct and the data isn't corromped
        const resume = getResume(item.model, item.data, pivot, pivotConfig);
        type = resume.type;
        color = resume.color;
        overview = resume.overview;
      } else {
        // making variables when the keys are incorrect and the data is corromped (content starting with key rs)
        const resume = getResumeNoContent(item.model, pivot);
        type = resume.type;
        color = resume.color;
        overview = resume.overview;
      }

      if (
        item.model === "painel_stream" ||
        item.model === "pivot_action" ||
        item.model === "gps_stream" ||
        item.model === "pivot_action_vri_deliverer"
      ) {
        if (item.model === "gps_stream") {
          item.data.uuid = item.data.id;
        }
        date = moment(item.data.created).format("DD MMM - HH:mm");
      } else {
        if (item.data.created)
          date = moment(item.data.created).format("DD MMM - HH:mm");
      }

      return {
        id: item.data.uuid,
        date: date,
        type: type,
        color: color,
        overview: overview,
        datailedComponent: (): ReactJSX => (
          <ExpandedContent item={item} isEmpty={isEmpty} />
        ),
      };
    });

    return filteredRows;
  }, [historic]);

  useEffect(() => {
    socket.subscribe(`${SOCKET_SUFFIX}farm@${farmID}`);
    socket.bind("CentralStream", (data) => {
      showCentralInformation.current &&
        historicActions.updateHistoric({
          model: "CentralStream",
          data,
        });
    });

    socket.subscribe(`${SOCKET_SUFFIX}pivot@${pivotID}`);

    socket.bind("maintenance", (data) => {
      historicActions.updateHistoric({
        model: "maintenance",
        data,
      });
    });

    if (pivot.protocol === 5) {
      socket.bind("ControllerAction_simple", (data) => {
        historicActions.updateHistoric({
          model: "ControllerAction_simple",
          data,
        });
      });

      socket.bind("ControllerAction_schedule", (data) => {
        historicActions.updateHistoric({
          model: "ControllerAction_schedule",
          data,
        });
      });

      socket.bind("ControllerAction_segment", (data) => {
        historicActions.updateHistoric({
          model: "ControllerAction_segment",
          data,
        });
      });

      socket.bind("ControllerAction_stop", (data) => {
        historicActions.updateHistoric({
          model: "ControllerAction_stop",
          data,
        });
      });

      socket.bind("ControllerStream_panel", (data) => {
        if (data.content.irrigation_status?.irrigation_status !== 24) {
          historicActions.updateHistoric({
            model: "ControllerStream_panel",
            data,
          });
        }
      });

      socket.bind("ControllerStream_gps", (data) => {
        isAdvanced.current &&
          historicActions.updateHistoric({
            model: "ControllerStream_gps",
            data,
          });

        let newMapHistory = fillMapHistoryBySocket(
          pivotMapHistoryRef.current,
          data,
          pivot.protocol
        );

        if (newMapHistory) {
          atomFamilySetter({
            PivotFamilyAtom: FallbackPivot,
            pivotId: pivot.id,
            value: {
              ...pivot,
              map_history: [...newMapHistory],
            },
          });
        }
      });
    } else {
      socket.bind("pivot_action", (data) => {
        // update pivot irrigation_end_angle to avoid keeping the dashed line on map
        atomFamilySetter({
          PivotFamilyAtom: FallbackPivot,
          pivotId: pivot.id,
          value: {
            ...pivot,
            irrigation_end_angle: null,
          },
        });

        historicActions.updateHistoric({ model: "pivot_action", data });
      });

      socket.bind("pivot_action_vri_deliverer", (data) => {
        if (
          data.delivered &&
          data.actionrecipe &&
          data.actionrecipe.angle_array
        ) {
          // update pivot irrigation_end_angle to avoid keeping the dashed line on map
          atomFamilySetter({
            PivotFamilyAtom: FallbackPivot,
            pivotId: pivot.id,
            value: {
              ...pivot,
              irrigation_end_angle:
                data.actionrecipe.angle_array[
                  data.actionrecipe.angle_array.length - 1
                ],
            },
          });
        }

        historicActions.updateHistoric({
          model: "pivot_action_vri_deliverer",
          data,
        });
      });

      socket.bind("painel_stream", (data) => {
        historicActions.updateHistoric({ model: "painel_stream", data });
      });

      socket.bind("gps_stream", (data) => {
        /*
          Como  gps_stream  não possui  uuid, passamos o id  no campo uuid,
          pois usamos como convenção o uuid para realizar varios algoritmos
          que procedem este.
        */
        isAdvanced.current &&
          historicActions.updateHistoric({
            model: "gps_stream",
            data: { ...data, uuid: data.id },
          });

        let newMapHistory = fillMapHistoryBySocket(
          pivotMapHistoryRef.current,
          data,
          pivot.protocol
        );

        if (newMapHistory) {
          atomFamilySetter({
            PivotFamilyAtom: FallbackPivot,
            pivotId: pivot.id,
            value: {
              ...pivot,
              map_history: [...newMapHistory],
            },
          });
        }
      });
    }

    return () => {
      if (pivot.protocol !== undefined) {
        socket.unbind("CentralStream");
        socket.unbind("ControllerAction_simple");
        socket.unbind("ControllerAction_schedule");
        socket.unbind("ControllerAction_segment");
        socket.unbind("ControllerStream_panel");
        socket.unbind("ControllerStream_gps");
        socket.unbind("gps_stream");
        socket.unbind("pivot_action");
        socket.unbind("pivot_action_vri_deliverer");
        socket.unbind("painel_stream");
        socket.unsubscribe(`${SOCKET_SUFFIX}pivot@${pivotID}`);
        socket.unsubscribe(`${SOCKET_SUFFIX}farm@${farmID}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pivot.protocol, pivotID]);

  if (historic !== undefined && rows !== undefined) {
    return (
      <GeneralTable
        page={historicActions.getOffset() - 1}
        hideColumns={true}
        columns={isMobile() ? mobcolumns : columns}
        total={historic.count}
        rows={rows}
        rowsPerPage={10}
        loading={async.loading}
        onPageChange={(offset) => {
          historicActions.updateOffset(offset + 1);
        }}
      />
    );
  } else {
    return (
      <GeneralTable
        page={historicActions.getOffset()}
        hideColumns={true}
        columns={isMobile() ? mobcolumns : columns}
        total={0}
        rows={new Array<Row>(0)}
        rowsPerPage={10}
        loading={true}
        onPageChange={() => {}}
      />
    );
  }
}

export default HistoricBox;

import React from "react";

const ControllerIcon = ({ color, size, extraStyle = null }) => {
  const style = { fill: color, width: `${size}px`, height: `${size}px` };
  const newStyle = { ...style, ...extraStyle };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={newStyle}
      viewBox="0 0 90.12 88"
    >
      <path d="M75.234 65.855a7.612 7.612 0 01-7.609 7.614H22.492a7.612 7.612 0 01-7.61-7.614v-43.71a7.612 7.612 0 017.61-7.614h45.133c4.203 0 7.61 3.41 7.61 7.614zM82.508 0H7.609A7.609 7.609 0 000 7.61v72.78A7.609 7.609 0 007.61 88h74.898a7.609 7.609 0 007.61-7.61V7.61A7.609 7.609 0 0082.507 0" />
      <path d="M44.43 22.371c-.18.059-.461.238-.614.39-.578.563-.55.044-.527 9.622l.027 8.64.254.375a1.807 1.807 0 002.977 0l.254-.375.027-8.64c.024-9.664.059-9.086-.578-9.664a1.745 1.745 0 00-1.82-.348" />
      <path d="M37.422 27.45c-.203.034-.844.304-1.41.6-3.98 2.079-6.926 5.09-8.856 9.036-1.226 2.492-1.82 4.754-2 7.559-.347 5.597 1.711 11.132 5.64 15.12 3.122 3.165 6.993 5.137 11.458 5.829 1.36.203 4.25.203 5.613 0 5.797-.895 10.664-4 13.903-8.864.656-.984 1.601-2.867 2.035-4.066.918-2.48 1.328-5.312 1.156-8.02-.18-2.804-.773-5.066-2-7.558-1.938-3.98-5.066-7.145-9.008-9.102-1.23-.62-1.723-.714-2.344-.449-.597.254-1.097.985-1.109 1.598 0 .918.367 1.36 1.71 2.031 2.79 1.406 4.876 3.25 6.532 5.793a16.252 16.252 0 01.91 16.145c-.859 1.707-1.683 2.84-3.07 4.234-3.113 3.129-7.125 4.797-11.523 4.797-3.625 0-6.95-1.125-9.907-3.344-.859-.644-2.41-2.195-3.082-3.078a16.336 16.336 0 01-1.64-17.094c1.59-3.25 4.117-5.765 7.492-7.46.672-.34 1.098-.606 1.258-.798.707-.859.504-2.093-.45-2.714-.27-.18-.882-.274-1.308-.196" />
    </svg>
  );
};

export default ControllerIcon;

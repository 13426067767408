import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  Close,
  Done,
  DoneAll,
  Edit,
  Error,
  Loop,
  Save,
} from "@material-ui/icons";
import { blue, red, grey } from "@material-ui/core/colors";
import ModalSelectRadioToSwitch from "./ModalSelectRadioToSwitch/ModalSelectRadioToSwitch";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../redux/modal/actions";
import { coreHTTPClient } from "../../../../../../services/webclient";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { useRecoilState } from "recoil";
import { DeviceRadioFamily } from "../../../../../../recoils/DeviceRadioRecoil";
import { MessageStatus } from "../../EditPivot";
import { i18n } from "../../../../../../i18n/i18nText";
import QrReader from "modern-react-qr-reader";
import { isMobile } from "../../../../../../mobileConfig";

/*
Editar rádio:
{{url}}/v3/farms/{{farm}}/pivots/{{pivot}}/controller/
*/

function getRadioStatusIcon(
  status: MessageStatus,
  error: string | undefined
): JSX.Element {
  switch (status) {
    case MessageStatus.WAITING:
      return <CircularProgress size={18} />;
    case MessageStatus.SENT:
      return <Done style={{ color: "darkgray" }} />;
    case MessageStatus.DELIVERED:
      return <DoneAll style={{ color: blue[500] }} />;
    case MessageStatus.ERROR:
      return (
        <Tooltip
          title={`${i18n(
            "EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION"
          )} ${error}`}
        >
          <Error style={{ color: red[500] }} />
        </Tooltip>
      );
    default:
      return <></>;
  }
}

type DeviceType = "PIVOT" | "IRPD" | "IMETER" | "REPEATER";

type DeviceSubType =
  | "control"
  | "monitor"
  | "pump"
  | "imeter_device"
  | "repeater";

type Props = {
  farmID: number;
  deviceID: number;
  radioID: string;
  deviceType: "PIVOT" | "IMETER" | "IRPD" | "REPEATER";
  deviceSubType?: "control" | "pump" | "monitor" | "imeter_device" | "repeater";
  label: string;
  status: MessageStatus;
  locked?: boolean;
  error?: string | undefined;
  meterSystemID?: any;
  communicationType?: number;
};

let globalResolve: (data: string) => void;

let globalReject: (data: string) => void;

export default function ChangeRadioField(props: Props) {
  // communicationType -> 0 é XBee
  // communicationType -> 1 é 4G

  const communicationType = props.communicationType
    ? props.communicationType
    : "";

  const [showQrReader, setShowQrReader] = useState(false);

  const [radioId, setRadioId] = useRecoilState(
    DeviceRadioFamily(`${props.deviceID}_${props.deviceSubType}`)
  );

  const [previousRadioId, setPreviousRadioId] = useRecoilState(
    DeviceRadioFamily(`${props.deviceID}_${props.deviceSubType}_previous`)
  );

  const [hasRadioIdErrors, setHasRadioIdErrors] = useState<boolean>(false);

  const [editable, setEditable] = useState<boolean>(false);

  const dispatch = useDispatch();

  const notify = useNotify();

  useEffect(() => {
    setRadioId(props.radioID);
    setPreviousRadioId(props.radioID);
  }, [props.radioID]);

  function getDeviceTitle(type: DeviceType, subtype: DeviceSubType) {
    switch (subtype) {
      case "control":
        return i18n("RADIO_SWAP_CONTROLLER_TITLE");
      case "monitor":
        return i18n("RADIO_SWAP_GPS_TITLE");
      case "pump":
        return i18n("RADIO_SWAP_PUMP_TITLE");
      case "imeter_device":
        return i18n("RADIO_SWAP_IMETER_TITLE");
      case "repeater":
        return i18n("RADIO_SWAP_REPEATER_TITLE");
      default:
        return i18n("DEVICE");
    }
  }

  async function showQrReaderWithMode(mode: "RADIO_ID") {
    setShowQrReader(true);

    const data = await new Promise<string>((resolve) => {
      globalResolve = resolve;
    });

    window.alert(`${i18n("CREATE_DEVICE_MODAL_CODE_READ_SUCCESS")}\n${data}`);

    setShowQrReader(false);

    if (mode == "RADIO_ID") {
      setRadioId(data.trim());
    }
  }

  function validateRadioId(radio_id: string) {
    if (radio_id.match(/^[a-fA-F0-9]{16}$/) === null) setHasRadioIdErrors(true);
    else setHasRadioIdErrors(false);

    setRadioId(radio_id.toUpperCase());
  }

  function editButtonOnClick() {
    setEditable(true);
  }

  function cancelButtonOnClick() {
    setEditable(false);
    setRadioId(previousRadioId);
    setHasRadioIdErrors(false);
  }

  async function saveButtonOnClick() {
    try {
      let url;
      if (props.deviceType === "PIVOT")
        url = `/v3/farms/${props.farmID}/pivots/${props.deviceID}/${props.deviceSubType}/`;
      else if (props.deviceType === "IRPD")
        url = `/v3/farms/${props.farmID}/irpds/${props.deviceID}/edit_radio/`;
      else if (props.deviceType === "IMETER")
        url = `/v3/farms/${props.farmID}/metersystems/${props.meterSystemID}/meter/${props.deviceID}/edit_radio/`;
      else if (props.deviceType === "REPEATER")
        url = `/v3/farms/${props.farmID}/repeaters/${props.deviceID}/edit_radio/`;

      let changeRadio = await coreHTTPClient
        .post(url, { radio_id: radioId })
        .then((response) => {
          setRadioId(response.data.radio_id);
          setEditable(false);
          setHasRadioIdErrors(false);
          notify(
            communicationType !== 1
              ? "RADIO_CHANGED_SUCCESS"
              : "GATEWAY_CHANGED_SUCCESS",
            "success",
            4000
          );
        });
    } catch (error) {
      if ("error" in error.response.data && communicationType !== 1)
        notify(error.response.data["error"], "error", 4000);
      else notify("GATEWAY_ID_ALREADY_USED", "error", 4000);
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor:
            props.status === MessageStatus.DELIVERED ? blue[50] : grey[200],
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" width="100%">
            <div style={{ fontSize: "14px", fontWeight: 600 }}>
              {props.label}
            </div>

            <div className="device-row mb-30">
              <div className="input">
                <TextField
                  InputProps={{
                    endAdornment: getRadioStatusIcon(props.status, props.error),
                  }}
                  value={radioId}
                  onChange={(event) => validateRadioId(event.target.value)}
                  disabled={props.locked === true || !editable}
                  error={hasRadioIdErrors}
                  fullWidth
                />
              </div>

              {editable && isMobile() ? (
                <div
                  className="camera-button"
                  onClick={async () => {
                    showQrReaderWithMode("RADIO_ID");
                  }}
                >
                  <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                    <Icon style={{ opacity: 0.85 }}> qr_code_scanner </Icon>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </Box>

          <Box display="flex" flexDirection="column">
            {!props.locked &&
              (editable ? (
                <>
                  <Tooltip
                    placement="right"
                    title={
                      communicationType == 1
                        ? i18n("SAVE_GATEWAY")
                        : i18n("SAVE_RADIO")
                    }
                  >
                    <IconButton
                      disabled={hasRadioIdErrors}
                      size="small"
                      onClick={saveButtonOnClick}
                    >
                      <Save fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip placement="right" title={i18n("CANCEL")}>
                    <IconButton size="small" onClick={cancelButtonOnClick}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip
                    placement="right"
                    title={
                      communicationType != 1
                        ? i18n("EDIT_RADIO")
                        : i18n("EDIT_GATEWAY")
                    }
                  >
                    <IconButton size="small" onClick={editButtonOnClick}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  {props.radioID && communicationType != 1 ? (
                    <Tooltip
                      placement="right"
                      title={getDeviceTitle(
                        props.deviceType,
                        props.deviceSubType
                      )}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          dispatch(
                            showModal({
                              content: (
                                <ModalSelectRadioToSwitch
                                  deviceType={props.deviceType}
                                  deviceSubType={props.deviceSubType}
                                  currentRadioId={radioId}
                                  farmID={props.farmID}
                                  deviceID={props.deviceID}
                                  meterSystemID={props.meterSystemID}
                                />
                              ),
                            })
                          );
                        }}
                      >
                        <Loop fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </>
              ))}
          </Box>
        </Box>
      </div>

      {showQrReader && (
        <div className="qr-reader-root">
          <div className="close-button" onClick={() => setShowQrReader(false)}>
            X
          </div>

          <QrReader
            delay={300}
            facingMode={"environment"}
            onError={(data) => globalReject && globalReject(data)}
            onScan={(data) => globalResolve && data && globalResolve(data)}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </>
  );
}

import React from "react";
import { PanelStreamOld } from "../../../../../../../../../../redux/pivots/types";
import {
  getPanelStreamReason,
  PANEL_STREAM_REASON_GROUP_STATUS,
  parseHourmeter,
} from "../../../../../../../../../../utils/models/pivots";

import { i18n } from "../../../../../../../../../../i18n/i18nText";

interface Props {
  painelStream: PanelStreamOld;
}

function ExpandedPainelStream(props: Props) {
  const { painelStream } = props;

  // var hideReasonsArray = PANEL_STREAM_REASON_GROUP_STATUS.IN_MOVING
  //   .concat(PANEL_STREAM_REASON_GROUP_STATUS.PANEL_OFF)
  //   .concat(PANEL_STREAM_REASON_GROUP_STATUS.PANEL_ON)
  //   .concat(PANEL_STREAM_REASON_GROUP_STATUS.PRESSURIZED)
  //   .concat(PANEL_STREAM_REASON_GROUP_STATUS.PRESSURIZING)
  //   .concat(PANEL_STREAM_REASON_GROUP_STATUS.PROGRAMMED)

  // const hidePressureInfos = useMemo(() => {
  //   if (!painelStream) return true;
  //   if (hideReasonsArray.includes(painelStream.reason)) return true;
  //   else return false
  // }, [painelStream])

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <div className="expanded-content__group">
          <div>
            <>{i18n("HISTORIC_PAINEL_STREAM_REASON")}</>
            <b style={{ marginLeft: "5px" }}>
              {i18n("HISTORIC_PAINEL_STREAM_REASON_VALUE", [
                getPanelStreamReason(painelStream.reason),
              ])}
            </b>
          </div>
          {/* <GlobalText
            spanWrap
            content={painelStreamPower(painelStream.power)}
          /> */}
          {/* <GlobalText
            spanWrap
            content={painelStreamSecurity(painelStream.security)}
          /> */}
        </div>
      </div>
      <div className="expanded-content__group">
        <span>
          {i18n("HISTORIC_PAINEL_STREAM_HOURMETER")}
          <b>{i18n("VALUE", [parseHourmeter(painelStream.hourmeter)])}</b>
        </span>
        {!PANEL_STREAM_REASON_GROUP_STATUS.PANEL_OFF.includes(
          painelStream.reason
        ) && (
          <span>
            {i18n("HISTORIC_PAINEL_STREAM_POWER")}
            <b>{i18n("VALUE_AND_UNIT_POWER", [`${painelStream.phase_a}`])}</b>
          </span>
        )}
        {/* {
          !hidePressureInfos && (
            <GlobalText
              spanWrap
              content={painelStreamPressure(painelStream.pressure_switch)}
            />
          )
        } */}
        {/* <GlobalText
          spanWrap
          content={"HISTORIC_PAINEL_STREAM_REVERSIONS"}
          args={[`${painelStream.reversion}`]}
        /> */}
      </div>
    </div>
  );
}

export default ExpandedPainelStream;

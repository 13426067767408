import React, { ReactNode } from "react";
import { isMobile } from "../../../../../../../../../mobileConfig";
import usePaymentMethod from "../../../../../../../../../hooks/models/usePaymentMethod";
import PaymentMethodComponent from "./PaymentMethodComponent";
import PaymentMethodMobile from "./PaymentMethodMobile";
import PaymentMethodSkeleton from "./PaymentMethodSkeleton";
import CreditCardIcon from "@material-ui/icons/CreditCard";

interface Props {
  farmID: string;
  costumerID: number;
}

export type PaymentMethodOptions = {
  id: string;
  name: string;
  icon: ReactNode;
  link: string;
  disabled: boolean;
};

export default function PaymentMethodContainer(props: Props) {
  const { costumerID, farmID } = props;
  const [
    loading,
    paymentMethod,
    checkoutLinkCard,
    deletePaymentMethod,
  ] = usePaymentMethod(farmID, costumerID);

  const methods: PaymentMethodOptions[] = [
    {
      id: "credit-card",
      name: "PAYMENT_METHOD_CREDIT_CARD",
      icon: <CreditCardIcon style={{ color: "black", fontSize: "60px" }} />,
      link: checkoutLinkCard,
      disabled: !checkoutLinkCard,
    },
  ];

  const hasRecurrence = paymentMethod.recurrence;
  const isMethodCard = paymentMethod.type === "card";

  return loading ? (
    <PaymentMethodSkeleton />
  ) : isMobile() ? (
    <PaymentMethodMobile
      paymentMethod={paymentMethod}
      methods={methods}
      hasRecurrence={hasRecurrence}
      isMethodCard={isMethodCard}
      deletePaymentMethod={deletePaymentMethod}
    />
  ) : (
    <PaymentMethodComponent
      paymentMethod={paymentMethod}
      methods={methods}
      hasRecurrence={hasRecurrence}
      isMethodCard={isMethodCard}
      deletePaymentMethod={deletePaymentMethod}
    />
  );
}

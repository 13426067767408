import "./Permission.scss";

import styled from "@emotion/styled";
import {
  Button,
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
  NativeSelect,
  Switch,
} from "@material-ui/core";
import { Delete, Replay } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useNotify from "../../../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../../../mobileConfig";
import { updateOrInsertFarm } from "../../../../../../../../../../redux/farms/actions";
import { getFarm as getFarmSelector } from "../../../../../../../../../../redux/farms/selector";
import Store, {
  ApplicationState,
} from "../../../../../../../../../../redux/index";
import { hideModal } from "../../../../../../../../../../redux/modal/actions";
import { UserAdministrator } from "../../../../../../../../../../redux/sharedTypes";
import { UserFarm } from "../../../../../../../../../../redux/usersFarm/type";
import { coreHTTPClient } from "../../../../../../../../../../services/webclient";
import "./Permission.scss";

export const blueTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0089D6",
      contrastText: "#fff",
    },
  },
});

interface Props {
  farmID: number;
  userID: number;
  user: UserFarm;
  pending?: boolean;
  setOpenModal: any;
  getUsersFarm: any;
}
interface PutPivotPermission {
  id: number;
  level: number;
  equipment: number | null;
  irpd: number | null;
  pivot: number | null;
  user: number;
}
interface PivotPermission {
  id: number;
  level: number;
  equipment: { id: number; name: string; protocol: number } | null;
  irpd: { id: number; name: string; protocol: number } | null;
  pivot: {
    id: number;
    name: string;
    protocol: number;
    automation_type: number;
  } | null;
  user: number;
  admin: boolean;
}

const UserInfoDiv = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const EmptyPermissionsContainer = styled.div`
  font-size: 1em;
  opacity: 0.7;
  padding: 20px 0;
  overflow: hidden;
  text-align: center;
`;

const RemoveButton = styled(Button)`
  color: red !important;
`;

const ResendInviteButton = styled(Button)`
  color: green !important;
`;

export default function Permission(props: Props) {
  const { farmID, userID, user, setOpenModal, getUsersFarm } = props;
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [userPermission, setUserPermission] = useState<PivotPermission[]>([]);
  const [allPermissions, setAllPermissions] = useState(-1);
  const notify = useNotify();
  const reseller = Store().store.getState().userID.reseller;
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  const dispatch = useDispatch();

  const farm = useSelector((state: ApplicationState) =>
    getFarmSelector(state, farmID)
  );

  async function renewPendingRegistration() {
    await new Promise(async (resolve) => {
      const response = await coreHTTPClient
        .post(`/v3/farms/${farmID}/users/${userID}/renewinvite/`)
        .then(() => {
          notify("EDIT_FARM_RESEND_INVITATION_SUCCESS", "success");
        })
        .catch(() => {
          notify("EDIT_FARM_RESEND_INVITATION_FAILED", "error");
        });
    });
  }

  async function savePermissions(
    farmID: number,
    userID: number,
    putUserPermission: PutPivotPermission[],
    notify: any,
    setDisabled: Function
  ) {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.put(
          `/v3/farms/${farmID}/users/${userID}/permissions/`,
          putUserPermission
        );
        if (response.status === 200) {
          resolve(notify("EDIT_FARM_USER_PERMISSION_SUCCESS", "success"));
          dispatch(hideModal());
          getUsersFarm();
        }
      } catch (err) {
        notify("EDIT_FARM_USER_PERMISSION_ERROR", "error");
        console.log("error");
      } finally {
        setDisabled(false);
      }
    });
  }

  useEffect(() => {
    (async () => {
      const response = await coreHTTPClient.get(
        `/v3/farms/${farmID}/users/${userID}/permissions/admin/`
      );
      setIsAdmin(response.data.is_admin);
    })();
  }, []);

  useEffect(() => {
    setLoadingPermissions(true);
    (async () => {
      let permissions = await coreHTTPClient.get(
        `/v3/farms/${farmID}/users/${userID}/permissions/`
      );
      let orderedPermissions = await (permissions.data as PivotPermission[]).sort(
        (a, b) => {
          let aName = a.pivot?.name || a.irpd?.name || a.equipment?.name;
          let bName = b.pivot?.name || b.irpd?.name || b.equipment?.name;
          if (aName > bName) return 1;
          else if (aName < bName) return -1;
          else return 0;
        }
      );

      setUserPermission(orderedPermissions);
      setLoadingPermissions(false);
      setAllPermissions(allDevicesPermissionLevel(orderedPermissions));
    })();
  }, []);

  // Confere se há monitor de pivô na farm
  // let hasPivotMonitor = userPermission
  //   .map((d) => {
  //     if (d.pivot && d.pivot.automation_type != 0) {
  //       return true;
  //     }
  //     return false;
  //   })
  //   .includes(true);

  const handleAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdmin(event.target.checked);
  };

  const allPermissionsChanged = (userPermissions, permissionLevel) => {
    if (permissionLevel != -1) {
      userPermissions.map((permission) => (permission.level = permissionLevel));
    }
    return userPermissions;
  };

  //Returns the level of the permission for all the devices if they are the same, if not, returns -1
  const allDevicesPermissionLevel = (userPermission) => {
    let oldPermission = userPermission[0].level;

    for (let index = 0; index < userPermission.length; index++) {
      if (userPermission[index].level != oldPermission) return -1;
      oldPermission = userPermission[index].level;
    }

    return oldPermission;
  };

  return (
    <div className="permission">
      {!loadingPermissions ? (
        <React.Fragment>
          <UserInfoDiv style={{ margin: isMobile() ? "10px 0 0 10px" : "0" }}>
            {props?.pending ? (
              <span
                className="users__row__status"
                style={{ fontSize: "16px", marginBottom: "3px" }}
              >
                <b>
                  {i18n("EDIT_FARM_USER")} {i18n("EDIT_FARM_USER_PENDING")}
                </b>
              </span>
            ) : (
              <span style={{ fontSize: "16px", marginBottom: "3px" }}>
                <b>@{user?.username}</b>
              </span>
            )}
            <span className="permission__user-info">
              {user?.first_name} {user?.last_name}
            </span>
            <span
              className={
                props?.pending
                  ? "permission__user-info__pending"
                  : "permission__user-info"
              }
            >
              {user?.email}
            </span>
            {props?.pending && (
              <div className="permission__actions">
                <ResendInviteButton
                  onClick={renewPendingRegistration}
                  style={{ width: "100%" }}
                  startIcon={<Replay />}
                >
                  {i18n("EDIT_FARM_SEND_INVITE_AGAIN")}
                </ResendInviteButton>
              </div>
            )}
            <div className="permission__actions">
              <RemoveButton
                size="medium"
                style={{}}
                onClick={() => {
                  setOpenModal(true);
                }}
                startIcon={<Delete />}
              >
                {i18n("EDIT_FARM_REMOVE")}
              </RemoveButton>
              <div style={{ font: "caption" }}>
                <>{i18n("CREATE_USER_MODAL_ADMIN")}</>
                <Switch
                  checked={isAdmin}
                  onChange={handleAdmin}
                  color="primary"
                  name="checkedA"
                />
              </div>
            </div>
          </UserInfoDiv>
          {!isAdmin ? (
            <div className="permission__list-pivot">
              {!userPermission.length ? (
                <EmptyPermissionsContainer>
                  {i18n("NO_EQUIPMENTS")}
                </EmptyPermissionsContainer>
              ) : (
                //Edição de todas as permissões
                <div>
                  <div className="users__titleRow">
                    <>{i18n("EDIT_FARM_TITLE_PERMISSIONS")}</>
                  </div>
                  {userPermission.length > 1 && (
                    <div key="allDevices" className="permission__head">
                      <div className="pivot">{i18n("ALL_EQUIPMENTS")}</div>
                      <div className="tag">
                        <NativeSelect
                          value={allPermissions}
                          onChange={(event: any) => {
                            setAllPermissions(parseInt(event.target.value));
                            let userPermissionAux = [...userPermission];
                            setUserPermission(
                              allPermissionsChanged(
                                userPermissionAux,
                                parseInt(event.target.value)
                              )
                            );
                          }}
                        >
                          <option value={0}>{i18n("USER_PERMISSION")}</option>
                          <option value={1}>
                            {i18n("USER_PERMISSION_READ")}
                          </option>
                          <option value={2}>
                            {i18n("USER_PERMISSION_OPERATOR")}
                          </option>
                          <option value={3}>
                            {i18n("USER_PERMISSION_CONFIGURATOR")}
                          </option>
                          <option value={-1}>
                            {i18n("USER_PERMISSION_CUSTOMIZED")}
                          </option>
                        </NativeSelect>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {userPermission.map((permission, index) => {
                return (
                  <div key={permission.id} className="permission__head">
                    <div className="pivot">
                      {permission.pivot
                        ? permission.pivot.name
                        : permission.irpd
                        ? permission.irpd.name
                        : permission.equipment
                        ? permission.equipment.name
                        : "Equipment without name"}
                    </div>
                    <div className="tag">
                      <NativeSelect
                        value={permission.level}
                        inputProps={{
                          name: "age",
                          id: "age-native-simple",
                        }}
                        onChange={(event: any) => {
                          let userPermissionAux = [...userPermission];
                          userPermissionAux[index].level = parseInt(
                            event.target.value
                          );
                          setUserPermission(userPermissionAux);
                          setAllPermissions(
                            allDevicesPermissionLevel(userPermissionAux)
                          );
                        }}
                      >
                        <option value={0}>{i18n("USER_PERMISSION")}</option>
                        <option
                          value={
                            permission.pivot &&
                            allPermissions == 2 &&
                            permission.pivot.automation_type != 0
                              ? 2
                              : 1
                          }
                        >
                          {i18n("USER_PERMISSION_READ")}
                        </option>
                        {permission.equipment ||
                        permission.irpd ||
                        (permission.pivot &&
                          permission.pivot.automation_type == 0) ? (
                          <option value={2}>
                            {i18n("USER_PERMISSION_OPERATOR")}
                          </option>
                        ) : null}
                        <option value={3}>
                          {i18n("USER_PERMISSION_CONFIGURATOR")}
                        </option>
                      </NativeSelect>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      ) : (
        <CircularProgress style={{ marginTop: "30px", alignSelf: "center" }} />
      )}

      <div className="permission__button">
        <MuiThemeProvider theme={blueTheme}>
          <Button
            disabled={disabled && !loadingPermissions}
            style={{ width: "100%" }}
            variant="contained"
            color="primary"
            onClick={() => {
              setDisabled(true);
              let putObjectUserPermission = [] as PutPivotPermission[];
              if (userPermission)
                userPermission.map((value) => {
                  let auxobj = {} as PutPivotPermission;
                  auxobj.id = value.id;
                  auxobj.level = value.level;
                  auxobj.user = value.user;
                  if (value.pivot) auxobj.pivot = value.pivot.id;
                  else auxobj.pivot = null;
                  if (value.irpd) auxobj.irpd = value.irpd.id;
                  else auxobj.irpd = null;
                  if (value.equipment) auxobj.equipment = value.equipment.id;
                  else auxobj.equipment = null;
                  putObjectUserPermission.push(auxobj);
                });

              (async () => {
                try {
                  savePermissions(
                    farmID,
                    userID,
                    putObjectUserPermission,
                    notify,
                    setDisabled
                  );
                  await coreHTTPClient.patch(
                    `/v3/farms/${farmID}/users/${userID}/permissions/admin/?administrator=${!isAdmin}`
                  );
                  let newFarm = { ...farm };
                  let administrators = [...farm.administrators];
                  if (!isAdmin) {
                    administrators = administrators.filter(
                      (adm) => adm.id != user.id
                    );
                  } else {
                    if (
                      administrators.findIndex(
                        (adm) => adm.username == user.username
                      ) === -1
                    ) {
                      administrators.push({
                        ...(user as UserAdministrator),
                        pending: props?.pending,
                      });
                    }
                  }
                  newFarm.administrators = administrators;
                  dispatch(updateOrInsertFarm(newFarm));
                } catch (e) {
                  notify("EDIT_PERMISSIONS_ADD_ADM_ERROR", "error");
                }
              })();
              dispatch(hideModal());
            }}
          >
            {disabled ? (
              <CircularProgress size={27} />
            ) : (
              <>{i18n("EDIT_FARM_BUTTON_SAVE")}</>
            )}
          </Button>
        </MuiThemeProvider>
      </div>
    </div>
  );
}

import { action } from "typesafe-actions";
import { LoadingTypes } from "./types";
import { Action } from "redux";

export interface ShowLoading extends Action {
  type: LoadingTypes.SHOW_LOADING;
}

export interface HideLoading extends Action {
  type: LoadingTypes.HIDE_LOADING;
}

export const showLoading = (): ShowLoading => action(LoadingTypes.SHOW_LOADING);

export const hideLoading = (): HideLoading =>
  action(LoadingTypes.HIDE_LOADING, null);

export type LoadingActions = ShowLoading | HideLoading;

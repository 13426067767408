import { useContext, useState } from "react";
import React from "react";
import {
  PivotConfig,
  Pivot,
} from "../../../../../../../../../redux/pivots/types";
import "../../../../../../../Pivot/SelectedPivot/components/HistoricBox/components/ExpandedContent/ExpandedContent.scss";
import {
  getSectorPivot,
  getEndGun,
} from "../../../../../../../../../utils/models/pivots";
import moment from "moment";
import {
  Button,
  CircularProgress,
  createMuiTheme,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";

import { EditPivotContext } from "../../../../EditPivotForm/EditPivotProvider/EditPivotProvider";
import { useDispatch } from "react-redux";
import { hideModal } from "../../../../../../../../../redux/modal/actions";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { useRecoilState } from "recoil";
import {
  centerMarkerSelector,
  initialMarkerSelector,
  isSetorialPivotState,
  referenceMarkerSelector,
} from "../../../../../../../../../recoils/DraggableMapRecoil";

import { coreHTTPClient } from "../../../../../../../../../services/webclient";
import { Star, StarBorder } from "@material-ui/icons";
import FormInput from "../../../../../../../../../components/Form/FormInput/FormInput";
import { motion } from "framer-motion";
import { i18n } from "../../../../../../../../../i18n/i18nText";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fca103",
      contrastText: "#fff",
    },
  },
});

interface Props {
  pivot: Pivot;
  pivotConfig: PivotConfig;
  ctx?: any;
  farmID: number;
  pivotID: number;
}
function ExpandedContentConfigOld(props: Props) {
  const { farmID, pivotID, pivot, pivotConfig } = props;
  const ctx = useContext(EditPivotContext);

  const [pinned, setPinned] = useState<boolean>(props.pivotConfig.pinned);
  const [loading, setLoading] = useState(false);
  const {
    setName,
    setEndgun,
    setSetorial,
    setCenterLat,
    setCenterLng,
    setStartRefLat,
    setStartRefLng,
    setEndRefLat,
    setEndRefLng,
    setCenterLatLabel,
    setCenterLngLabel,
    setStartLatLabel,
    setStartLngLabel,
    setEndLatLabel,
    setEndLngLabel,
    setRadius,
    setTotalRadius,
    setArea,
    setSpeed,
    setFlow,
    setPotency,
    setPumpTime,
    setPowerTime,
    setPowerRange,
    setPowerRangeMin,
    setPowerRangeMax,
  } = props.ctx || ctx;

  const dispatch = useDispatch();
  const notify = useNotify();
  //RECOIL STATE
  let [initialLatLng, setInitialLatLng] = useRecoilState(initialMarkerSelector);
  let [centerLatLng, setCenterLatLng] = useRecoilState(centerMarkerSelector);
  let [refLatLng, setRefLatLng] = useRecoilState(referenceMarkerSelector);
  let [setorialRecoil, setSetorialRecoil] = useRecoilState(
    isSetorialPivotState
  );

  const [pinName, setPinName] = useState("");
  const [showNameInput, setShowNameInput] = useState(false);

  const saveButtonHandler = () => {
    try {
      //Recoil para carregar o latitude e longitude
      setCenterLatLng((ll: any) => ({
        ...ll,
        lat: pivotConfig.center.split(",")[0],
        lng: pivotConfig.center.split(",")[1],
      }));
      setInitialLatLng((ll: any) => ({
        ...ll,
        lat: pivotConfig.reference.split(",")[0],
        lng: pivotConfig.reference.split(",")[1],
      }));
      setRefLatLng((ll: any) => ({
        ...ll,
        lat: pivotConfig.end_reference.split(",")[0],
        lng: pivotConfig.end_reference.split(",")[1],
      }));
      setSetorialRecoil(pivotConfig.setorial);

      //LEGACY System
      setCenterLat(parseFloat(pivotConfig.center.split(",")[0]).toFixed(7));
      setCenterLng(parseFloat(pivotConfig.center.split(",")[1]).toFixed(7));
      setStartRefLat(
        parseFloat(pivotConfig.reference.split(",")[0]).toFixed(7)
      );
      setStartRefLng(
        parseFloat(pivotConfig.reference.split(",")[1]).toFixed(7)
      );
      setEndRefLat(
        parseFloat(pivotConfig.end_reference.split(",")[0]).toFixed(7)
      );
      setEndRefLng(
        parseFloat(pivotConfig.end_reference.split(",")[1]).toFixed(7)
      );

      setCenterLatLabel(
        parseFloat(pivotConfig.center.split(",")[0]).toFixed(7)
      );
      setCenterLngLabel(
        parseFloat(pivotConfig.center.split(",")[1]).toFixed(7)
      );
      setStartLatLabel(
        parseFloat(pivotConfig.reference.split(",")[0]).toFixed(7)
      );
      setStartLngLabel(
        parseFloat(pivotConfig.reference.split(",")[1]).toFixed(7)
      );
      setEndLatLabel(
        parseFloat(pivotConfig.end_reference.split(",")[0]).toFixed(7)
      );
      setEndLngLabel(
        parseFloat(pivotConfig.end_reference.split(",")[1]).toFixed(7)
      );

      setName(pivot.name);
      setEndgun(pivotConfig.endgun);
      setSetorial(pivotConfig.setorial);
      setRadius(pivotConfig.radius.toString().split(".")[0]);
      setTotalRadius(pivotConfig.total_radius.toString().split(".")[0]);
      setArea(pivotConfig.area.toString().split(".")[0]);
      setSpeed(pivotConfig.speed.toString().split(".")[0]);
      setFlow(pivotConfig.flow.toString());
      //setPotency(pivotConfig.power_time.toString());
      setPumpTime(pivotConfig.pump_time.toString());
      setPowerTime(pivotConfig.power_time.toString());
      setPowerRangeMin(pivotConfig.power_range_min.toString());
      setPowerRangeMax(pivotConfig.power_range_max.toString());
      //
      setPotency(pivot.potency);

      notify("EDIT_PIVOT_LOAD_SUCCESS", "success", 3000);
      dispatch(hideModal());
    } catch (err) {
      notify("EDIT_PIVOT_LOAD_ERROR", "error");
      console.log(err);
    }
  };

  return (
    <div className="expanded-content__general">
      <div
        style={{
          width: "100%",
          height: "90px",
          margin: "0 0 -20px 5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <ThemeProvider theme={theme}>
          <IconButton
            aria-label="delete"
            onClick={() => {
              if (!pinned) {
                setShowNameInput(!showNameInput);
                return;
              }
              coreHTTPClient.patch(
                `v3/farms/${farmID}/pivots/${pivotID}/config/${props.pivotConfig.id}/`,
                { pinned: false }
              );
              setPinned(false);
              notify("HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY", "success");
            }}
          >
            {pinned ? <Star color="primary" /> : <StarBorder color="inherit" />}
          </IconButton>
        </ThemeProvider>
        <motion.div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          variants={{
            hidden: { width: 0, opacity: 0, x: "20px" },
            show: {
              width: "fit-content",
              opacity: 1,
              x: "0px",
            },
          }}
          initial="hidden"
          animate={showNameInput ? "show" : "hidden"}
        >
          <div style={{ width: "200px", marginBottom: "25px" }}>
            <FormInput
              id="pin-name"
              label={"SIGN_UP_NAME"}
              state={[pinName, setPinName]}
              disabled={!showNameInput}
            />
          </div>
          <Button
            variant="contained"
            style={{ transform: "scale(0.8)" }}
            // O botão está apenas invisível, mas precisa ser desativado
            disabled={!showNameInput || loading || !pinName}
            onClick={() => {
              (async () => {
                setLoading(true);
                try {
                  await coreHTTPClient.patch(
                    `v3/farms/${farmID}/pivots/${pivotID}/config/${props.pivotConfig.id}/`,
                    { pinned: true, name: pinName }
                  );
                  notify(
                    "HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY",
                    "success"
                  );
                  setShowNameInput(false);
                  setPinned(true);
                } catch {
                  notify("HISTORIC_CONFIG_PIVOT_PINNED_ERROR", "error");
                } finally {
                  setLoading(false);
                }
              })();
            }}
          >
            {loading ? <CircularProgress size={25} color="inherit" /> : "OK"}
          </Button>
        </motion.div>
      </div>
      <div className="expanded-content__title-">
        <>{i18n("EDIT_PIVOT_LABEL_GENERAL")}</>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>{i18n("HISTORIC_CONFIG_PIVOT_OLD_NAME", [pivot.name])}</span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_END_GUN", [
              getEndGun(pivotConfig.endgun),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT", [
              getSectorPivot(pivotConfig.setorial),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_CENTER", [
              parseFloat(pivotConfig.center.split(",")[0]).toFixed(7),
              parseFloat(pivotConfig.center.split(",")[1]).toFixed(7),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE", [
              parseFloat(pivotConfig.reference.split(",")[0]).toFixed(7),
              parseFloat(pivotConfig.reference.split(",")[1]).toFixed(7),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE", [
              parseFloat(pivotConfig.end_reference.split(",")[0]).toFixed(7),
              parseFloat(pivotConfig.end_reference.split(",")[1]).toFixed(7),
            ])}
          </span>
        </div>
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER", [
              pivotConfig.radius.toString().split(".")[0],
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS", [
              pivotConfig.total_radius.toString().split(".")[0],
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA", [
              pivotConfig.area.toString().split(".")[0],
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER", [
              pivotConfig.speed.toString().split(".")[0],
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME", [
              pivotConfig.pump_time.toString(),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME", [
              pivotConfig.power_time.toString(),
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE", [
              pivotConfig.flow.toString(),
            ])}
          </span>
        </div>
      </div>
      <div className="expanded-content__title">
        <>{i18n("EDIT_PIVOT_LABEL_POWER")}</>
      </div>
      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN", [
              pivotConfig.power_range_min.toString(),
            ])}
          </span>
        </div>
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX", [
              pivotConfig.power_range_max.toString(),
            ])}
          </span>
        </div>
      </div>
      <div className="expanded-content__title">
        <>{i18n("EDIT_PIVOT_LABEL_PAUSE_TIME")}</>
      </div>
      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN", [
              pivotConfig.hour_range_min,
            ])}
          </span>
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX", [
              pivotConfig.hour_range_max,
            ])}
          </span>
        </div>
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME", [
              pivotConfig.manual
                ? moment(pivotConfig.rtc).format("DD MMM - HH:mm")
                : moment(pivotConfig.rtc)
                    .add(3, "hours")
                    .format("DD MMM - HH:mm"),
            ])}
          </span>
        </div>
      </div>
      <Button color="primary" variant="contained" onClick={saveButtonHandler}>
        <>{i18n("EDIT_PIVOT_LOAD_BUTTON")}</>
      </Button>
    </div>
  );
}

export default ExpandedContentConfigOld;

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  list: {
    width: 250,
    height: "150%",
    backgroundColor: "#2f4050",
  },
  fullList: {
    width: "auto",
  },
});

import { makeStyles } from "@material-ui/styles";
import customMuiComponentStyle from "../../../../../../style/customMuiComponentStyle";

export const useStyles = makeStyles({
  tabBar: {
    border: "0px",
    boxShadow: "0px 0px 0px 0px ",
  },
  table: {
    minWidth: 0,
  },
  removeButton: {
    ...customMuiComponentStyle.redButton,
  },
  tableCellSilver: {
    backgroundColor: "#e3e5e4",
  },
  tableCellBlue: {
    backgroundColor: "#ace5ee",
  },
  tableCellGray: {
    backgroundColor: "#b5b5bd",
  },
  tableCellBrown: {
    backgroundColor: "#f9b384",
  },
  tab: {
    borderLeft: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
});

import React, { useEffect, useState } from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  StepIconProps,
  Theme,
  Typography,
  makeStyles,
  IconButton,
  Popover,
  Button,
} from "@material-ui/core";
import { Notification } from "../../../../redux/notifications/types";
import { i18n } from "../../../../i18n/i18nText";
import { Reasons } from "../../../../hooks/models/useReasons";
import { isMobile } from "../../../../mobileConfig";
import SmsFailedOutlinedIcon from "@material-ui/icons/SmsFailedOutlined";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import IOSSwitch from "../../components/IOSSwitch/IOSSwitch";

interface Props {
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
  reasons: Reasons[];
  criticalReasons: Reasons[];
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    maxHeight: isMobile() ? "55vh" : "450px",
    overflowY: "scroll",
    paddingBottom: "20px",
  },
  typography: {
    fontSize: "18px",
    fontWeight: "bolder",
    margin: "0px 0px 15px 0px",
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: "1rem",
      width: 300,
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const getReasonsState = (reasons: Reasons[], notification: Notification) => {
  let reasonState = {};
  reasons &&
    reasons.map(
      (reason) =>
        (reasonState = {
          ...reasonState,
          [`reason_${reason.id}`]: notification.reasons.includes(reason.id)
            ? true
            : false,
        })
    );
  return reasonState;
};

const getCriticalReasonsState = (
  criticalReasons: Reasons[],
  notification: Notification
) => {
  let criticalReasonsState = {};
  criticalReasons &&
    criticalReasons.map(
      (criticalReason) =>
        (criticalReasonsState = {
          ...criticalReasonsState,
          [`critical_reason_${criticalReason.id}`]: notification.critical_reasons.includes(
            criticalReason.id
          )
            ? true
            : false,
        })
    );
  return criticalReasonsState;
};
const NotificationsReasons = (props: Props) => {
  const { notification, setNotification, reasons, criticalReasons } = props;

  const [checked, setChecked] = useState(
    getReasonsState(reasons, notification)
  );

  const [checkedCritical, setCheckedCritical] = useState(
    getCriticalReasonsState(criticalReasons, notification)
  );

  const [open, setOpen] = React.useState(criticalReasons.map(() => false));

  // useEffect(() => {
  //   console.log(
  //     "Critical: ",
  //     notification.critical_reasons,
  //     "Reason: ",
  //     notification.reasons
  //   );
  // }, [notification]);

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const reasons = [...notification.reasons];
    let critical_reasons = [...notification.critical_reasons];
    const reasonID = parseInt(event.target.name.replace("reason_", ""));
    if (event.target.checked) reasons.push(reasonID);
    else {
      const index = reasons.indexOf(reasonID);
      const indexCritical = critical_reasons.indexOf(reasonID);
      if (index > -1) {
        reasons.splice(index, 1);
      }
      if (indexCritical > -1) {
        critical_reasons.splice(indexCritical, 1);
        const criticalReasonsState = { ...checkedCritical };
        const criticalReasonKey = `critical_reason_${reasonID}`;
        criticalReasonsState[criticalReasonKey] = event.target.checked;
        setCheckedCritical(criticalReasonsState);
      }
    }
    setNotification({
      ...notification,
      reasons: reasons,
      critical_reasons: critical_reasons,
    });
    setChecked({ ...checked, [event.target.name]: event.target.checked });

    const updatedOpen = [...open];
    updatedOpen[index] = event.target.checked;
    setOpen(updatedOpen);
  };

  const handleChangeCheckedCritical = (
    event: React.ChangeEvent<HTMLInputElement>,
    reasonID: number
  ) => {
    const criticalReasonsState = { ...checkedCritical };
    const criticalReasonKey = `critical_reason_${reasonID}`;
    criticalReasonsState[criticalReasonKey] = event.target.checked;

    let updatedCriticalReasons = [...notification.critical_reasons];
    let updatedNotificationReasons = [...notification.reasons];

    if (event.target.checked) {
      updatedCriticalReasons.push(reasonID);

      const correspondingReasonKey = `reason_${reasonID}`;
      if (!checked[correspondingReasonKey]) {
        const updatedChecked = { ...checked };
        updatedChecked[correspondingReasonKey] = true;
        setChecked(updatedChecked);
        updatedNotificationReasons.push(reasonID);
      }
    } else {
      const indexToRemove = updatedCriticalReasons.indexOf(reasonID);
      if (indexToRemove > -1) {
        updatedCriticalReasons.splice(indexToRemove, 1);
      }
    }

    setNotification({
      ...notification,
      reasons: updatedNotificationReasons,
      critical_reasons: updatedCriticalReasons,
    });
    setCheckedCritical(criticalReasonsState);
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile()) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  return (
    <>
      <Typography className={classes.typography}>
        {i18n("CREATE_NOTIFICATIONS_SELECT_REASONS")}
      </Typography>

      <div className={classes.body}>
        <List component="span" aria-label="reason reason">
          {checked &&
            reasons &&
            reasons.map((reason, index) => (
              <React.Fragment key={reason.id}>
                <ListItem divider button>
                  <ListItemText primary={reason.label} />
                  <IOSSwitch
                    checked={checked[`reason_${reason.id}`]}
                    onChange={(event) => handleChangeChecked(event, index)}
                    name={`reason_${reason.id}`}
                  />
                  {criticalReasons.some((obj) => obj.id === reason.id) && (
                    <IconButton
                      edge="end"
                      onClick={() => {
                        const updatedOpen = [...open];
                        updatedOpen[index] = !updatedOpen[index];
                        setOpen(updatedOpen);
                      }}
                    >
                      {open[index] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </ListItem>
                {criticalReasons.some((obj) => obj.id === reason.id) && (
                  <Collapse
                    in={open[index]}
                    timeout="auto"
                    unmountOnExit
                    key={`collapse_${reason.id}`}
                  >
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        className={classes.nested}
                        title={i18n(
                          "CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO"
                        )}
                        aria-label={i18n(
                          "CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO"
                        )}
                      >
                        <ListItemIcon>
                          <IconButton
                            aria-describedby={id}
                            onClick={handleClick}
                          >
                            <SmsFailedOutlinedIcon
                              style={{
                                boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                                color: "#f55348",
                                backgroundColor: "#fdecea",
                                borderRadius: "50%",
                                padding: "5px",
                                scale: "1.2",
                              }}
                            />
                          </IconButton>
                        </ListItemIcon>
                        <Popover
                          id={id}
                          open={openPopover}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Typography className={classes.label}>
                            {i18n("CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO")}
                          </Typography>
                        </Popover>
                        <ListItemText
                          primary={i18n("CRITICAL_ALERTS_QUESTION_ENABLE")}
                        />
                        <IOSSwitch
                          checked={
                            checkedCritical[`critical_reason_${reason.id}`]
                          }
                          onChange={(event) =>
                            handleChangeCheckedCritical(event, reason.id)
                          }
                          name={`critical_reason_${reason.id}`}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
        </List>
      </div>
    </>
  );
};

export default NotificationsReasons;

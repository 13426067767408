import React, { useState } from "react";
import moment from "moment";
import { Settings, Map, Power } from "@material-ui/icons";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import { IrpdConfigHistory } from "../../../DrawerRightEditIrpd";
import { Irpd } from "../../../../../../../../../redux/irpds/types";
import {
  Button,
  CircularProgress,
  createMuiTheme,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";
import { coreHTTPClient } from "../../../../../../../../../services/webclient";
import { Star, StarBorder } from "@material-ui/icons";
import { motion } from "framer-motion";
import FormInput from "../../../../../../../../../components/Form/FormInput/FormInput";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { isMobile } from "../../../../../../../../../mobileConfig";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fca103",
      contrastText: "#fff",
    },
  },
});

interface Props {
  pump?: any;
  irpd: Irpd;
  irpdConfig: IrpdConfigHistory;
  irpdID: number;
  farmID: number;
  hideFavoriteAction?: boolean;
  loadHistoricInfo: (
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdKwhPeak: number,
    irpdKwhOutOfPeak: number,
    irpdKwhReduced: number,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) => void;
}

function ExpandedContentConfigV5(props: Props) {
  const { pump, irpd, irpdConfig, irpdID, farmID, loadHistoricInfo } = props;
  const [pinned, setPinned] = useState<boolean>(props.irpdConfig.pinned);
  const [loading, setLoading] = useState(false);
  const [pinName, setPinName] = useState(irpdConfig.name || "");
  const [showNameInput, setShowNameInput] = useState(false);
  const notify = useNotify();

  return (
    <div className="expanded-content__general">
      {
        /* Favorite buttons */
        !props.hideFavoriteAction ? (
          <div
            style={{
              width: "100%",
              height: "90px",
              margin: "0 0 -20px 5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <ThemeProvider theme={theme}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  if (!pinned) {
                    setShowNameInput(!showNameInput);
                    return;
                  }
                  const apiVersion = irpd.protocol === 5.2 ? "v4" : "v3";
                  coreHTTPClient.patch(
                    `${apiVersion}/farms/${farmID}/irpds/${irpdID}/configv5/${props.irpdConfig.id}/`,
                    { pinned: false }
                  );
                  setPinned(false);
                  notify(
                    "HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY",
                    "success"
                  );
                }}
              >
                {pinned ? (
                  <Star color="primary" />
                ) : (
                  <StarBorder color="inherit" />
                )}
              </IconButton>
            </ThemeProvider>
            <motion.div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              variants={{
                hidden: { width: 0, opacity: 0, x: "20px" },
                show: {
                  width: "fit-content",
                  opacity: 1,
                  x: "0px",
                },
              }}
              initial="hidden"
              animate={showNameInput ? "show" : "hidden"}
            >
              <div style={{ width: "200px", marginBottom: "25px" }}>
                <FormInput
                  id="pin-name"
                  label={"SIGN_UP_NAME"}
                  state={[pinName, setPinName]}
                  disabled={!showNameInput}
                />
              </div>
              <Button
                variant="contained"
                style={{ transform: "scale(0.8)" }}
                // O botão está apenas invisível, mas precisa ser desativado
                disabled={!showNameInput || loading || !pinName}
                onClick={() => {
                  (async () => {
                    setLoading(true);
                    try {
                      const apiVersion = irpd.protocol === 5.2 ? "v4" : "v3";
                      await coreHTTPClient.patch(
                        `${apiVersion}/farms/${farmID}/irpds/${irpdID}/configv5/${props.irpdConfig.id}/`,
                        { pinned: true, name: pinName }
                      );
                      notify(
                        "HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY",
                        "success"
                      );
                      setShowNameInput(false);
                      setPinned(true);
                    } catch {
                      notify("HISTORIC_CONFIG_PIVOT_PINNED_ERROR", "error");
                    } finally {
                      setLoading(false);
                    }
                  })();
                }}
              >
                {loading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "OK"
                )}
              </Button>
            </motion.div>
          </div>
        ) : null
      }

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      ></div>

      {isMobile() ? (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            <div
              style={{
                display: "flex",
              }}
            >
              {pump}
              {i18n("HISTORIC_CONFIG_INF_PUMP").toUpperCase()}
            </div>
          </div>
        </div>
      ) : null}

      {/* GERAL ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Settings
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_GENERAL").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("IRPD_FIELD_NAME")}: <b>{irpdConfig.name_irpd_on_config}</b>
          </span>

          {irpdConfig.monthly_water_limit == 0 ? (
            <span>
              {i18n("IRPD_MONTHLY_WATER_LIMIT")}: <b>{i18n("DEACTIVATED")}</b>
            </span>
          ) : (
            <span>
              {i18n("IRPD_MONTHLY_WATER_LIMIT")}:{" "}
              <b>
                {irpdConfig.monthly_water_limit} {"m³"}
              </b>
            </span>
          )}

          <span>
            {i18n("PIVOT_CONFIG_V5_FIELD_POTENCY")}:{" "}
            <b>
              {irpdConfig.potency} {"kW"}
            </b>
          </span>

          {irpdConfig.content.imanage_sensors[0].max_value == 0 ? (
            <span>
              {i18n("MEASUREMENT_MAX_VALUE")}: <b>{i18n("DEACTIVATED")}</b>
            </span>
          ) : (
            <span>
              {i18n("MEASUREMENT_MAX_VALUE")}:{" "}
              <b>
                {irpdConfig.content.imanage_sensors[0].max_value / 10} {"bar"}
              </b>
            </span>
          )}

          <span>
            {i18n("EDIT_IRPD_FIELD_RTCDATE")}:{" "}
            <b>{moment(irpdConfig.created).format("DD MMM YY - HH:mm")}</b>
          </span>

          <span>
            {i18n("EDIT_IRPD_FIELD_ENERGY_TIME")}:{" "}
            <b>
              {irpdConfig.content.pump_power_time.minutes} {"min"}
            </b>
          </span>

          <span>
            {i18n("PIVOT_CONFIG_V5_FIELD_FLOW")}:{" "}
            <b>
              {irpdConfig.flow} {"m³/h"}
            </b>
          </span>

          <span>
            {i18n("IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR")}: {""}
            <b>
              {irpdConfig.has_pressure_sensor === false
                ? i18n("DEACTIVATED")
                : i18n("ACTIVATED")}
            </b>
          </span>

          <span>
            {i18n("PIVOT_CONFIG_V5_IS_DATASHEET")}:{" "}
            <b>
              {props.irpdConfig.is_datasheet
                ? i18n("ALERT_DIALOG_YES")
                : i18n("ALERT_DIALOG_NO")}
            </b>
          </span>
        </div>
      </div>

      {/* LOCALIZAÇÃO ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Map
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_LOCATION").toUpperCase()}</>
        </div>
      </div>

      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("EDIT_PIVOT_LAT_AND_LONG")}: <b>{irpdConfig.position}</b>,
          </span>
        </div>
      </div>

      {/* HORÁRIO DE PICO ----------------------------------------------------- */}

      <div
        style={{
          margin: "30px 0px 0px 20px",
          fontSize: "x-large",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Power
            style={{
              marginRight: "5px",
            }}
          />
          <>{i18n("EDIT_PIVOT_LABEL_PAUSE_TIME").toUpperCase()}</>
        </div>
      </div>

      {!irpdConfig.content.enable_peak_time.enable ? (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            {i18n("PAUSE_TIME_WITHOUT_CONFIG")}
          </div>
        </div>
      ) : (
        <div className="expanded-content__background">
          <div className="expanded-content__group">
            <span>
              {i18n("BASIC_INFO_BOX_PONTA")}: {""}
              <b>
                {i18n("CURRENCY")} {irpdConfig.kwh_peak}
              </b>
            </span>

            <span>
              {i18n("BASIC_INFO_BOX_FORA_DE_PONTA")}: {""}
              <b>
                {i18n("CURRENCY")} {irpdConfig.kwh_out_of_peak}
              </b>
            </span>

            <span>
              {i18n("BASIC_INFO_BOX_REDUZIDO")}: {""}
              <b>
                {i18n("CURRENCY")} {irpdConfig.kwh_reduced}
              </b>
            </span>

            <span
              style={{
                marginTop: "10px",
              }}
            >
              {i18n("EDIT_PIVOT_V5_WEEKDAY_SUNDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.sunday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_MONDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.monday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_TUESDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.tuesday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.wednesday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_THURSDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.thursday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_FRIDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.friday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <span>
              {i18n("EDIT_PIVOT_V5_WEEKDAY_SATURDAY")}: {""}
              <b>
                {irpdConfig.content.peak_time.saturday_enable == 0
                  ? i18n("DEACTIVATED")
                  : i18n("ACTIVATED")}
              </b>
            </span>

            <div
              style={{
                marginTop: "10px",
              }}
            >
              {"1. "}

              <span>
                {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START")}:{" "}
                <b>
                  {moment()
                    .set({
                      hour: irpdConfig.content.peak_time.start_hour_1,
                      minute: irpdConfig.content.peak_time.start_minute_1,
                      second: 0,
                      millisecond: 0,
                    })
                    .format("HH:mm")}
                </b>
              </span>
            </div>

            <div>
              <span
                style={{
                  marginLeft: "18px",
                }}
              >
                {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END")}:{" "}
                <b>
                  {moment()
                    .set({
                      hour: irpdConfig.content.peak_time.stop_hour_1,
                      minute: irpdConfig.content.peak_time.stop_minute_1,
                      second: 0,
                      millisecond: 0,
                    })
                    .format("HH:mm")}
                </b>
              </span>
            </div>

            {irpdConfig.content?.peak_time.start_hour_2 === 0 &&
            irpdConfig.content?.peak_time.start_minute_2 === 0 &&
            irpdConfig.content?.peak_time.stop_hour_2 === 0 &&
            irpdConfig.content?.peak_time.stop_minute_2 === 0 ? null : (
              <>
                <div>
                  {"2. "}
                  <span>
                    {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START")}:{" "}
                    <b>
                      {moment()
                        .set({
                          hour: irpdConfig.content.peak_time.start_hour_2,
                          minute: irpdConfig.content.peak_time.start_minute_2,
                          second: 0,
                          millisecond: 0,
                        })
                        .format("HH:mm")}
                    </b>
                  </span>
                </div>

                <div>
                  <span
                    style={{
                      marginLeft: "18px",
                    }}
                  >
                    {i18n("PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END")}:{" "}
                    <b>
                      {moment()
                        .set({
                          hour: irpdConfig.content.peak_time.stop_hour_2,
                          minute: irpdConfig.content.peak_time.stop_minute_2,
                          second: 0,
                          millisecond: 0,
                        })
                        .format("HH:mm")}
                    </b>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <Button
        color="primary"
        variant="contained"
        onClick={(e) => {
          loadHistoricInfo(
            irpdConfig.name_irpd_on_config,
            irpdConfig.monthly_water_limit !== 0,
            irpdConfig.monthly_water_limit.toString(),
            irpdConfig.potency.toString(),
            irpdConfig.content.imanage_sensors[0].max_value / 10 !== 0,
            (irpdConfig.content.imanage_sensors[0].max_value / 10).toString(),
            irpdConfig.content.pump_power_time.minutes.toString(),
            irpdConfig.flow.toString(),
            irpdConfig.has_pressure_sensor,
            irpdConfig?.position.split(",")[0].substring(0, 10),
            irpdConfig?.position.split(",")[1].substring(0, 10),
            (ll: any) => ({
              ...ll,
              lat: irpdConfig?.position.split(",")[0].substring(0, 10),
              lng: irpdConfig?.position.split(",")[1].substring(0, 10),
            }),
            irpdConfig.kwh_peak,
            irpdConfig.kwh_out_of_peak,
            irpdConfig.kwh_reduced,
            {
              mon: irpdConfig.content.peak_time.monday_enable,
              tue: irpdConfig.content.peak_time.tuesday_enable,
              wed: irpdConfig.content.peak_time.wednesday_enable,
              thu: irpdConfig.content.peak_time.thursday_enable,
              fri: irpdConfig.content.peak_time.friday_enable,
              sat: irpdConfig.content.peak_time.saturday_enable,
              sun: irpdConfig.content.peak_time.sunday_enable,
            },
            irpdConfig.content?.enable_peak_time?.enable === 0 ? false : true,
            moment().set({
              hour: irpdConfig.content?.peak_time.start_hour_1,
              minute: irpdConfig.content?.peak_time.start_minute_1,
              second: 0,
              millisecond: 0,
            }),
            moment().set({
              hour: irpdConfig.content?.peak_time.stop_hour_1,
              minute: irpdConfig.content?.peak_time.stop_minute_1,
              second: 0,
              millisecond: 0,
            }),
            irpdConfig.content?.enable_peak_time?.enable === 0 ||
              (irpdConfig.content?.peak_time.start_hour_2 === 0 &&
                irpdConfig.content?.peak_time.start_minute_2 === 0 &&
                irpdConfig.content?.peak_time.stop_hour_2 === 0 &&
                irpdConfig.content?.peak_time.stop_minute_2 === 0)
              ? false
              : true,
            moment().set({
              hour: irpdConfig.content?.peak_time.start_hour_2,
              minute: irpdConfig.content?.peak_time.start_minute_2,
              second: 0,
              millisecond: 0,
            }),
            moment().set({
              hour: irpdConfig.content?.peak_time.stop_hour_2,
              minute: irpdConfig.content?.peak_time.stop_minute_2,
              second: 0,
              millisecond: 0,
            })
          );
        }}
      >
        <>{i18n("EDIT_IRPD_LOAD_BUTTON")}</>
      </Button>
    </div>
  );
}

export default ExpandedContentConfigV5;

import React from "react";
import moment from "moment";
import {
  ScheduleIrrigation,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import {
  irrigationMode,
  irrigationDirection,
  formatDateTime2,
  irrigationInjectionPump,
} from "../../../../utils/utils";
import { PTPToMillimeter } from "../../../../../../../../../../utils/models/pivots";
import { handleStopMode } from "../../../../../IrrigationBox/components/ScheduleIrrigationV5/ScheduleIrrigationV5";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  scheduleIrrigation: ScheduleIrrigation;
  pivot?: Pivot;
}

function ExpandedScheduleIrrigation(props: Props): any {
  const { scheduleIrrigation } = props;

  const createdOnHardware = <span>{i18n("HISTORIC_CREATED_ON_HARDWARE")}</span>;
  const createdByUser = (
    <span>
      {i18n("HISTORIC_CREATED_BY")}
      <b>{i18n("VALUE", [`${scheduleIrrigation.username}`])}</b>
    </span>
  );

  const scheduleIrrigationContent = (
    <>
      {scheduleIrrigation.content.schedule_irrigation_parameters?.map(
        (schedule, index) => (
          <>
            <div>
              {scheduleIrrigation.angle_V5 == false ? (
                i18n("HISTORIC_SCHEDULE_IRRIGATION_INDEX", [
                  `${schedule.number_editing + 1}`,
                ])
              ) : (
                <>
                  i18n("HISTORIC_PANEL_STREAM_V5_ANGLE")
                  <b>
                    i18n("VALUE_AND_UNIT_DEGREES", [ `$
                    {schedule.number_editing + 1}`])
                  </b>
                </>
              )}
            </div>
            <div>
              {i18n("HISTORIC_SCHEDULE_IRRIGATION_MODE")}
              <b>{i18n("VALUE", [`${irrigationMode(schedule.mode)}`])}</b>
            </div>
            <div>
              {i18n("HISTORIC_SCHEDULE_IRRIGATION_PERCENT")}
              <b>
                {i18n("VALUE_AND_UNIT_PERCENT_MILIMETERS", [
                  `${schedule.percent}`,
                  `${formatFloatValue(
                    PTPToMillimeter(props.pivot, schedule.percent)
                  )}`,
                ])}
              </b>
            </div>
            <div>
              {i18n("HISTORIC_SCHEDULE_IRRIGATION_DIRECTION")}
              <b>
                {i18n("VALUE", [`${irrigationDirection(schedule.direction)}`])}
              </b>
            </div>
            <div>
              {i18n("HISTORIC_SCHEDULE_IRRIGATION_START_DATE")}
              <b>
                {i18n("VALUE", [
                  schedule.start_now
                    ? i18n("HISTORIC_IRPD_ACTION_NOW")
                    : formatDateTime2(
                        schedule.start_year,
                        schedule.start_month,
                        schedule.start_day,
                        schedule.start_hour,
                        schedule.start_minute
                      ),
                ])}
              </b>
            </div>
            <div>
              {schedule.stop_mode !== 3 ? (
                <>
                  {i18n("HISTORIC_SCHEDULE_IRRIGATION_END_MODE")}
                  <b>{i18n("VALUE", [handleStopMode(schedule.stop_mode)])}</b>
                </>
              ) : null}
            </div>
            <div>
              {schedule.stop_mode === 1 ? (
                <>
                  {i18n("HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE")}
                  <b>{i18n("VALUE_AND_UNIT_DEGREES", [schedule.stop_angle])}</b>
                </>
              ) : null}
              {schedule.stop_mode === 2 ? (
                <>
                  {i18n("HISTORIC_SCHEDULE_IRRIGATION_END_DATE")}
                  <b>
                    {i18n("VALUE", [
                      formatDateTime2(
                        schedule.end_year,
                        schedule.end_month,
                        schedule.end_day,
                        schedule.end_hour,
                        schedule.end_minute
                      ),
                    ])}
                  </b>
                </>
              ) : null}
              {schedule.stop_mode === 3 ? (
                <>{i18n("HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR")}</>
              ) : null}
              {schedule.stop_mode === 4 ? (
                <>
                  {i18n("HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS")}
                  <b>{i18n("VALUE_OF_TURNS", [schedule.schedule_rounds])}</b>
                </>
              ) : null}
            </div>
            <br></br>
            {!!scheduleIrrigation.content?.injection_pump_command?.command && (
              <>
                <div>
                  <span>
                    {i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS")}
                    <b>
                      {i18n("VALUE", [
                        irrigationInjectionPump(
                          scheduleIrrigation.content?.injection_pump_command
                            ?.command
                        ),
                      ])}
                    </b>
                  </span>
                </div>
                <div>
                  <span>
                    {i18n("IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED")}
                    <b>
                      {scheduleIrrigation.content?.injection_pump_command?.note}
                    </b>
                  </span>
                </div>
              </>
            )}
          </>
        )
      )}
    </>
  );

  const stopPivotContent = <>{i18n("HISTORIC_PANEL_STREAM_V5_STOPPED")}</>;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <span>
          {i18n("HISTORIC_CREATED_AT")}
          <b>
            {i18n("VALUE", [
              `${moment(scheduleIrrigation.created).format("DD MMM HH:mm")}`,
            ])}
          </b>
        </span>
        {scheduleIrrigation.created_on_hardware
          ? createdOnHardware
          : createdByUser}
        <span>
          {i18n("CURRENT_ANGLE")}
          <b>
            {i18n("VALUE", [
              ` ${formatFloatValue(scheduleIrrigation.current_angle)}º`,
            ])}
          </b>
        </span>
      </div>
      <div className="expanded-content__group">
        {scheduleIrrigation.content?.irrigation_status?.irrigation_status === 0
          ? stopPivotContent
          : scheduleIrrigationContent}
      </div>
    </div>
  );
}

export default ExpandedScheduleIrrigation;

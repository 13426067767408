import { useState } from "react";

export interface AsyncStatus {
  loading: boolean;
  done: boolean;
}

export const useAsyncStatus = (): [AsyncStatus, () => void, () => void] => {
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>({
    done: false,
    loading: false
  });

  function initLoading() {
    setAsyncStatus({
      done: false,
      loading: true
    });
  }

  function finishLoading() {
    setAsyncStatus({
      done: true,
      loading: false
    });
  }

  return [asyncStatus, initLoading, finishLoading];
};

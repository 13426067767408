import { useEffect, useState } from "react";
import { getAuthorization } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import axios from "axios";

export type PaymentMethodCard = {
  type: "card";
  name: string;
  brand: string;
  country: string;
  exp_date: string;
  last4: string;
  recurrence: boolean;
};

function maskExpdate(exp_month: string, exp_year: string) {
  return `${exp_month.toString().padStart(2, "0")}/${String(exp_year)
    .slice(-2)
    .padStart(2, "0")}`;
}

function maskCardNumber(last4: string) {
  return `************${last4}`;
}

function maskEmail(email: string): string {
  if (typeof email !== "string") {
    return null;
  }

  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return null;
  }

  const providerIndex = email.lastIndexOf("@");
  const dotIndex = email.lastIndexOf(".");
  if (dotIndex === -1 || providerIndex === -1) {
    return null;
  }

  const username = email.substring(0, atIndex);
  const provider = email.substring(atIndex + 1, dotIndex);
  const complement = email.substring(dotIndex);

  const maskedUsername =
    username.substring(0, 3) + username.substring(3).replace(/[a-zA-Z]/g, "*");
  const maskedProvider =
    provider.substring(0, 3) + provider.substring(3).replace(/[a-zA-Z]/g, "*");

  return maskedUsername + "@" + maskedProvider + complement;
}

function maskDocument(tax_id: string) {
  if (typeof tax_id !== "string") {
    return null;
  }

  const numbersOnly = tax_id.replace(/[^\d./-]/g, "");

  if (numbersOnly.length < 2) {
    return null;
  }

  const lastTwoDigits = numbersOnly.slice(-2);

  const maskedNumbers = numbersOnly.slice(0, -2).replace(/\d/g, "*");

  const maskedResult = `${maskedNumbers}${lastTwoDigits}`;

  return maskedResult;
}

const usePaymentMethod = (
  farmID: string,
  costumerID?: number
): [boolean, PaymentMethodCard, string, () => {}] => {
  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCard>({
    type: "card",
    name: "",
    brand: "",
    country: "",
    exp_date: "**/**",
    last4: "",
    recurrence: false,
  });
  const [checkoutLinkCard, setCheckoutLinkCard] = useState<string>("");

  async function getPaymentMethod(farmID: string, costumerID: number) {
    await new Promise(async (resolve) => {
      setLoading(true);

      try {
        const response = await axios.get(
          `/v4/farms/${farmID}/billing/customer/${costumerID}/payment_method/`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
          }
        );

        if (checkExists(["data", response, "__cascade"])) {
          setPaymentMethod({
            type: "card",
            name: response.data.billing_details.name,
            brand: response.data.card.brand,
            country: response.data.card.country,
            exp_date: maskExpdate(
              response.data.card.exp_month,
              response.data.card.exp_year
            ),
            last4: maskCardNumber(response.data.card.last4),
            recurrence: true,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }

  async function deletePaymentMethod() {
    await new Promise(async (resolve) => {
      setLoading(true);

      try {
        const response = await axios.delete(
          `/v4/farms/${farmID}/billing/customer/${costumerID}/payment_method/`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
          }
        );

        if (checkExists(["data", response, "__cascade"])) {
          setPaymentMethod({
            type: "card",
            name: "",
            brand: "",
            country: "",
            exp_date: "**/**",
            last4: "",
            recurrence: false,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }

  async function getCheckoutLinkCredit(farmID: string, costumerID: number) {
    await new Promise(async (resolve) => {
      setLoading(true);

      try {
        const response = await axios.get(
          `/v4/farms/${farmID}/billing/customer/${costumerID}/checkout/`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
          }
        );

        if (checkExists(["data", response, "__cascade"])) {
          setCheckoutLinkCard(response.data.url);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (farmID && costumerID) {
      getPaymentMethod(farmID, costumerID);
      getCheckoutLinkCredit(farmID, costumerID);
    }
    // eslint-disable-next-line
  }, []);

  return [loading, paymentMethod, checkoutLinkCard, deletePaymentMethod];
};

export default usePaymentMethod;

import React from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface Props {
  data?: any;
}

const handleLineDataVoltage = (array: any) => {
  var tempArray = [];
  array.forEach((item, index) => {
    tempArray.push({
      y: parseFloat(item.voltage.toFixed(2)),
      // x: moment(item.date).format("DD MMM YYYY HH:mm"),
      // x: moment(item.date).valueOf(),
      x: moment(item.date).unix(),
    });
  });
  tempArray = tempArray.sort(
    // @ts-ignore
    (a, b) => a.x - b.x
  );
  return [
    {
      data: tempArray,
    },
  ];
};

export const MyResponsiveLine = (props: Props) => {
  var data = handleLineDataVoltage(props.data);
  const handleTickValues = (data: any[]) => {
    if (data[0] && data[0].x && data.length > 1) {
      // por 12 legendas
      var tickValues = [];
      tickValues.push(data[0].x);
      tickValues.push(data[data.length - 1].x);
      if (data.length < 4) {
        return tickValues;
      } else {
        const sum = parseInt(String(data.length / 4));
        var itr = sum;
        while (itr < data.length) {
          if (data[itr]?.x && !tickValues.includes(data[itr]?.x)) {
            tickValues.push(data[itr]?.x);
          }
          itr = itr + sum;
        }
        return tickValues;
      }
    }
  };

  const state = {
    series: [
      {
        name: i18n("VOLTAGE"),
        data: props.data.map((item) => item?.voltage),
      },
    ],
    options: {
      animations: {
        enabled: false,
      },
      colors: ["#172783"],
      stroke: {
        width: 2,
        curve: "smooth" as const,
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: undefined,
        formatter: function (value) {
          return formatFloatValue(value);
        },
        style: {
          colors: ["rgba(51, 51, 51, 0.8)"],
        },
      },
      markers: {
        size: 4,
      },
      xaxis: {
        type: "datetime" as const,
        categories: props.data.map((item) =>
          moment(item.date).format("MM/DD/YYYY HH:mm")
        ),
        tickAmount: 4,
        labels: {
          datetimeUTC: false,
          rotate: 0,
          labels: {
            formatter: function (value, timestamp, opts) {
              return moment(timestamp).format("MM/DD/YYYY HH:mm");
            },
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatFloatValue(value);
          },
        },
        title: {
          text: i18n("VOLTAGE"),
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return formatFloatValue(value);
          },
        },
        x: {
          formatter: function (val, opts) {
            return moment(val).format("DD/MM/YYYY HH:mm");
          },
        },
      },
    },
  };

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="line"
      height="82%"
      width="100%"
    />
  );
};

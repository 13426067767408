import React from "react";
import {
  PivotAction,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import {
  expandDateArgs,
  pivotActionDirection,
  pivotActionMode,
} from "../../../../utils/utils";

import { PTPToMillimeter } from "../../../../../../../../../../utils/models/pivots";
import moment from "moment";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  pivotAction: PivotAction;
  pivot: Pivot;
}

function ExpandedPivotAction(props: Props) {
  const { pivotAction, pivot } = props;

  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <div>
          {i18n("HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED", [
            expandDateArgs(pivotAction.created)[0],
          ])}
        </div>
        {pivotAction.direction !== 0 && (
          <>
            <div>
              {i18n("HISTORIC_PIVOT_ACTION_START")}
              <b>
                {i18n("VALUE", [
                  pivotAction.start
                    ? moment(pivotAction.start)
                        .add("hours", 3)
                        .format("DD MMM - HH:mm")
                    : i18n("HISTORIC_PIVOT_ACTION_START_NOW"),
                ])}
              </b>
            </div>
            <div>
              {i18n("HISTORIC_PIVOT_ACTION_END")}
              <b>
                {i18n("VALUE", [
                  pivotAction.end
                    ? moment(pivotAction.end)
                        .add("hours", 3)
                        .format("DD MMM - HH:mm")
                    : i18n("HISTORIC_PIVOT_ACTION_NEVER_END"),
                ])}
              </b>
            </div>
          </>
        )}
        {pivotAction.manual ? (
          <span>{i18n("HISTORIC_PIVOT_ACTION_MANUAL")}</span>
        ) : (
          <span>
            {i18n("HISTORIC_PIVOT_ACTION_USER")}
            <b>{i18n("VALUE", [pivotAction?.user_profile?.username])}</b>
          </span>
        )}
      </div>
      <div className="expanded-content__group">
        {pivotAction.direction === 0 ? (
          <span>{i18n("HISTORIC_PIVOT_ACTION_STOP")}</span>
        ) : (
          <>
            <span>
              {i18n("HISTORIC_PIVOT_ACTION_DIRECTION")}
              <b>
                {i18n("VALUE", [pivotActionDirection(pivotAction.direction)])}
              </b>
            </span>
            <span>
              {i18n("HISTORIC_PIVOT_ACTION_BLADE")}
              <b>
                {i18n("VALUE_AND_UNIT_PERCENT_MILIMETERS", [
                  `${pivotAction.speed}`,
                  `${
                    pivotAction.mode === 2
                      ? formatFloatValue(
                          PTPToMillimeter(pivot, pivotAction.speed)
                        )
                      : 0
                  }`,
                ])}
              </b>
            </span>
            <span>
              {i18n("HISTORIC_PIVOT_ACTION_MODE")}
              <b>{i18n("VALUE", [pivotActionMode(pivotAction.mode)])}</b>
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpandedPivotAction;

import { Button, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { green, red } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { AddBox, ArrowBack, CheckCircle } from "@material-ui/icons";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import EmailIcon from "@material-ui/icons/Email";
import GroupIcon from "@material-ui/icons/Group";
import InfoIcon from "@material-ui/icons/Info";
import PowerIcon from "@material-ui/icons/Power";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFormValidation from "../../../../../../../hooks/tools/useFormValidation";
import { isMobile } from "../../../../../../../mobileConfig";
import {
  DayIndex,
  Farm,
  GroupedRange,
  IPowerRange,
  NewFarm,
} from "../../../../../../../redux/farms/types";
import { getCountryPhone } from "../../../../../../../utils/consts/countries";
import { numPad, objPatch } from "../../../../../../../utils/types";
import GeneralInformation from "./components/GeneralInformation";
import Users from "./components/Users/Users";
// import Group from "./components/Group/Group";
import styled from "@emotion/styled";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Holidays from "../../../../../../../components/Holidays/Holidays";
import PowerRange from "../../../../../../../components/PowerRange/PowerRange";
import useNotify from "../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../i18n/i18nText";
import Store, { ApplicationState } from "../../../../../../../redux/index";
import { hideModal, showModal } from "../../../../../../../redux/modal/actions";
import Routes from "../../../../../../../routes/routes";
import DashboardBox from "../../../../../components/DashboardBox/DashboardBox";
import IrpdInformations from "./components/IrpdInformations/IrpdInformations";
import OperationsSummary from "./components/OperationsSummary/OperationsSummary";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import Contact from "./components/Users/Contact";
import Billing from "./components/Users/Billing";
import useBilling from "../../../../../../../hooks/models/useBilling";
import useDebtor from "../../../../../../../hooks/models/useDebtor";
import { coreHTTPClient } from "../../../../../../../services/webclient";

interface Props {
  farm?: Farm;
  patchFarm?: (patchObject: any) => void;
  createFarm?: (patchObject: any) => void;
  userID?: number;
}

const CreatePowerRangeButton = styled(Button)`
  background-color: #3266ff;
  color: white;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%", width: "100%" }}
    >
      {value === index && (
        <Box style={{ padding: isMobile() && "0px" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  box: {
    padding: "0px",
  },
});

/**
 * Power Ranges,
 *
 * Vem do server como par chave - valor sendo, <ID_DO_DIA> : <RANGES>
 * Eu quero agrupar pelos ranges, então um grupo que representa esse dia tem de ter todos os ranges iguais
 */
function groupPowerRanges(
  farmPowerRanges: Farm["power_ranges"]
): GroupedRange[] {
  let groupedRange: GroupedRange[] = [];

  //Se eu ainda não tiver ranges, retorna
  if (!farmPowerRanges || farmPowerRanges.none == true) {
    return [];
  }

  //Vê se já tem um grupo com as características desse dia
  let groupedRangeMap: { [groupedRangeKey: string]: GroupedRange } = {};

  //Pra cada dia, indentifico o grupo
  Object.keys(farmPowerRanges).forEach((dayIndex) => {
    let ranges = farmPowerRanges[dayIndex] as IPowerRange[];

    //Ordena os ranges, do mais cedo para o mais tarde
    ranges.sort((a, b) => {
      let aStart = moment(a.start, "HH:mm:ss");
      let bStart = moment(b.start, "HH:mm:ss");

      return aStart.diff(bStart, "seconds");
    });

    // A unicicidade desse range é uma string que representa o objeto. Deep id.
    const RANGE_KEY = JSON.stringify(ranges);

    if (groupedRangeMap[RANGE_KEY]) {
      groupedRangeMap[RANGE_KEY].days.push(parseInt(dayIndex) as DayIndex);
    } else {
      groupedRangeMap[RANGE_KEY] = {
        range: ranges,
        uuid: nanoid(),
        days: [parseInt(dayIndex) as DayIndex],
      };
    }
  });

  return Object.values(groupedRangeMap);
}

export default function DrawerFarm(props: Props) {
  const tabValue = parseInt(window.location.href.split("tab=")[1]);

  const disabled = window.location.href.includes("disabled");

  const [value, setValue] = React.useState(tabValue ? tabValue : 0);

  const classes = useStyles(props);

  const farmID = useParams().farm;

  const { farm, userID } = props;

  const location = useLocation();

  const navigate = useNavigate();

  const [async, billing, actions, groupPermission] = useBilling(
    parseInt(farmID)
  );

  const [billingID, setBillingID] = useState(billing?.id);

  const [loading, debtor, debtorActions] = useDebtor(farmID);

  useEffect(() => {
    if (billing) {
      setBillingID(billing?.id);
      setBillingCompanyName(billing?.company_name);
      setBillingDocument(billing?.document);
      setBillingDocumentType(
        billing?.document_type ? billing.document_type : 0
      );
      setBillingAddress(billing?.address);
      setBillingPostalCode(billing?.postal_code);
      setBillingCity(billing?.city);
      setBillingState(billing?.state);
      setBillingCountry(billing?.country ? billing?.country : "BR");
      setBillingPhone(billing?.phone);
      setBillingEmail(billing?.email);
      debtorActions.get(farmID);
      formValidationActions.setError("billingDocument", undefined);
      formValidationActions.setError("billingCompanyName", undefined);
      formValidationActions.setError("billingPhone", undefined);
      formValidationActions.setError("billingEmail", undefined);
      formValidationActions.setError("billingPostalCode", undefined);
      formValidationActions.setError("billingAddress", undefined);
      formValidationActions.setError("billingCity", undefined);
      formValidationActions.setError("billingState", undefined);
      formValidationActions.setError("billingCountry", undefined);
    } else {
      formValidationActions.setError("billingDocument", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingCompanyName", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingPhone", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingEmail", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingPostalCode", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingAddress", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingCity", "ERROR_EMPTY_FIELD");
      formValidationActions.setError("billingState", "ERROR_EMPTY_FIELD");
      //formValidationActions.setError("billingCountry", "ERROR_EMPTY_FIELD");
    }
  }, [billing]);

  useEffect(() => {
    if (disabled || debtor?.is_debtor) setValue(1);
  }, [loading]);

  // Hook de validação de formulários
  const [validationErrors, formValidationActions] = useFormValidation([
    "name",
    "centralLinked",
    "billingDate",
    "initialLat",
    "initialLng",
    "lat",
    "lng",
    "phone",
    "postalCode",
    "address",
    "city",
    "state",
    "country",
    "billingDocument",
    "billingCompanyName",
    "billingPhone",
    "billingEmail",
    "billingPostalCode",
    "billingAddress",
    "billingCity",
    "billingState",
    "billingCountry",
  ]);

  //Validação no botão save. Caso haja algum erro, o botão não é exibido
  const [hideButtonSave, setHideButtonSave] = useState(false);

  useEffect(() => {
    checkErros();
  }, [validationErrors]);

  const checkErros = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Preenche latitude e longitude iniciais
  const initialLat = useMemo(() => {
    if (farm?.location !== null) return farm?.location?.split(",")[0];
    return "-23";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialLng = useMemo(() => {
    if (farm?.location !== null) return farm?.location?.split(",")[1];
    return "-46";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialStart = useMemo(() => {
    if (!farm.inicio_array) return [];
    return farm.inicio_array.map((hour) => {
      const hourSplitted = hour.split(":");
      return moment().set({
        hour: parseInt(hourSplitted[0]),
        minute: parseInt(hourSplitted[1]),
        second: parseInt(hourSplitted[2]),
        millisecond: 0,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialEnd = useMemo(() => {
    if (!farm.fim_array) return [];

    return farm.fim_array.map((hour) => {
      const hourSplitted = hour.split(":");
      return moment().set({
        hour: parseInt(hourSplitted[0]),
        minute: parseInt(hourSplitted[1]),
        second: parseInt(hourSplitted[2]),
        millisecond: 0,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialType = useMemo(() => {
    return farm.tipo_horario_array.map((type) => String(type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  //States
  const [name, setName] = useState(farm.name);

  const [centralLinked, setCentralLinked] = useState<boolean>(!!farm.base);

  const [base, setBase] = useState<string>(
    farm?.base?.radio_id ? farm.base.radio_id : ""
  );

  const [lat, setLat] = useState(initialLat);

  const [lng, setLng] = useState(initialLng);

  const [billingDate, setBillingDate] = useState(
    farm.billing_date ? String(farm.billing_date) : ""
  );

  const [waterBillingDate, setWaterBillingDate] = useState(
    farm.water_billing_date ? String(farm.water_billing_date) : ""
  );

  const [phone, setPhone] = useState(farm.phone);

  const [postalCode, setPostalCode] = useState(farm.postal_code);

  const [address, setAddress] = useState(farm.address);

  const [city, setCity] = useState(farm.city);

  const [state, setState] = useState(farm.state);

  const [country, setCountry] = useState("BR");

  const [billingDocumentType, setBillingDocumentType] = useState(
    billing?.document_type ? billing.document_type : 0
  );

  const [billingDocument, setBillingDocument] = useState(billing?.document);

  const [billingCompanyName, setBillingCompanyName] = useState(
    billing?.company_name
  );

  const [billingPhone, setBillingPhone] = useState(billing?.phone);

  const [billingEmail, setBillingEmail] = useState(billing?.email);

  const [billingPostalCode, setBillingPostalCode] = useState(
    billing?.postal_code
  );

  const [billingAddress, setBillingAddress] = useState(billing?.address);

  const [billingCity, setBillingCity] = useState(billing?.city);

  const [billingState, setBillingState] = useState(billing?.state);

  const [billingCountry, setBillingCountry] = useState(
    billing?.country ? billing?.country : "BR"
  );

  const [billingLanguage, setBillingLanguage] = useState(billing?.language);

  const [start, setStart] = useState<moment.Moment[]>(initialStart);

  const [end, setEnd] = useState<moment.Moment[]>(initialEnd);

  const [type, setArrayType] = useState<string[]>(initialType);

  const [farmPowerRanges, setFarmPowerRanges] = useState(farm.power_ranges);

  const [timezone, setTimezone] = useState(
    farm?.timezone ? farm?.timezone : "America/Sao_Paulo"
  );

  const [farmHolidays, setFarmHolidays] = useState<Farm["holidays_list"]>(
    farm.holidays_list.map((h) => ({
      day: h.day,
      month: h.month,
    }))
  );

  const [powerUpdatedRangesCount, setUpdatedPowerRangesCount] = useState<
    number
  >(-1);

  const notify = useNotify();

  const farmHolidaysSorted = useMemo(() => {
    return farmHolidays.sort((a, b) => {
      //Moment me deixou na mão, fazendo um valor que representa a diferença
      let aRelativeDate = a.day + 1 + (a.month + 1) * 30;
      let bRelativeDate = b.day + 1 + (b.month + 1) * 30;

      //

      return aRelativeDate - bRelativeDate;
    });
  }, [farmHolidays]);

  const IDD = useMemo(() => {
    const ddiPhone = getCountryPhone(country);
    if (ddiPhone !== "" && ddiPhone !== "0") return `+${parseInt(ddiPhone)}`;
    return "";
  }, [country]);

  const IDDBILLING = useMemo(() => {
    const ddiPhone = getCountryPhone(billingCountry);
    if (ddiPhone !== "" && ddiPhone !== "0") return `+${parseInt(ddiPhone)}`;
    return "";
  }, [billingCountry]);

  const backButton = (
    <Button
      color="primary"
      size="small"
      startIcon={<ArrowBack />}
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farm.id))
        )
      }
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const powerRanges = useMemo<GroupedRange[]>(
    () => groupPowerRanges(farmPowerRanges),
    [farmPowerRanges]
  );

  const editPowerRange = useCallback(
    (groupedRange: GroupedRange) => {
      // <PowerRange days={power.days} ranges={power.range} />

      //Cria uma cópia (DEEP), para ao editar não afetar o original. Custoso, sim, mas necessário
      let editableRange: GroupedRange = JSON.parse(
        JSON.stringify(groupedRange)
      );

      let otherRanges = powerRanges.filter(
        (range) => range.uuid != editableRange.uuid
      );
      let alreadyCheckedDays = otherRanges.flatMap((range) => range.days);

      dispatch(
        showModal({
          content: (
            <div
              style={{ minWidth: "55vw", paddingBottom: 10, maxWidth: "100vw" }}
            >
              <PowerRange
                editable
                days={editableRange.days}
                ranges={editableRange.range}
                alreadyCheckedDays={alreadyCheckedDays}
                savePowerRange={(editedGroupedRange) => {
                  setFarmPowerRanges((farmPowerRanges) => {
                    //Remove tudo relacionado ao editableRange, adiciona tudo relacionado ao range que eu acabei de
                    //criar. Assim facilita o caso de eu estar salvando um powerRange com dias a menos do que o que eu
                    //passei para o Modal.

                    //Deleta o que foi mandado para edição
                    editableRange.days.forEach((d) => {
                      delete farmPowerRanges[d];
                    });

                    //Adiciona do editado
                    editedGroupedRange.days.forEach((d) => {
                      farmPowerRanges[d] = editedGroupedRange.range;
                    });

                    //Deleta o {none: true} para não conflitar com os cheques, se ele existir
                    if (farmPowerRanges.none) delete farmPowerRanges.none;

                    return { ...farmPowerRanges };
                  });

                  dispatch(hideModal());
                }}
              />
            </div>
          ),
          title: "EDIT_FARM_BUTTON_ADD_POWER_RANGE",
        })
      );
    },
    [powerRanges]
  );

  let powerRangeAlreadySelectedDays = useMemo(() => {
    let alreadySelectedDays = powerRanges.map((p) => p.days).flat(2);
    return alreadySelectedDays;
  }, [powerRanges]);

  useEffect(() => {
    if (powerRanges) {
      setUpdatedPowerRangesCount(powerUpdatedRangesCount + 1);
    }
  }, [powerRanges]);

  useEffect(() => {
    const farm = Store().store.getState().farm.data;

    document.title =
      farm && farm.name
        ? farm.name + " - " + i18n("SELECTED_FARM_EDIT_BUTTON_TEXT")
        : "Irricontrol";
  }, []);

  useEffect(() => {
    if (billingLanguage !== billing?.language)
      setBillingLanguage(billing?.language);
  }, [billing?.language]);

  const saveButton = (
    <Button
      disabled={!hideButtonSave ? false : true}
      color="primary"
      endIcon={<CheckCircle />}
      onClick={() => {
        //Faltam dias no Faixas de Energia
        if (
          //Comecei a criar mas não terminei
          powerRangeAlreadySelectedDays.length != 7 &&
          !farmPowerRanges.none
        ) {
          notify("EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE", "error");
          return;
        }

        if (!formValidationActions.showErrorMessages()) {
          const newFarm: NewFarm = {
            name: name,
            billing_date: parseInt(billingDate),
            water_billing_date: parseInt(waterBillingDate),
            location: `${lat},${lng}`,
            address: address,
            postal_code: postalCode,
            city: city,
            state: state,
            timezone: timezone,
            country: country,
            phone: phone.replace(/\D/g, ""),
            inicio_array: start.map(
              (time) =>
                `${numPad(time.hour(), 2)}:${numPad(
                  time.minutes(),
                  2
                )}:${numPad(time.seconds(), 2)}`
            ),
            fim_array: end.map(
              (time) =>
                `${numPad(time.hour(), 2)}:${numPad(
                  time.minutes(),
                  2
                )}:${numPad(time.seconds(), 2)}`
            ),
            tipo_horario_array: type.map((t) => parseInt(t)),
            power_ranges: farmPowerRanges,
            holidays_list: farmHolidays.map((h) => ({
              day: h.day,
              month: h.month,
            })),
          };

          if (userID) newFarm.administrators = [userID];

          if (farm.inicio_array === null) farm.inicio_array = [];

          if (farm.fim_array === null) farm.fim_array = [];

          if (!centralLinked) newFarm.base = null;

          const patchObject = objPatch(farm, newFarm);

          if (patchObject) {
            const billingObject = {
              document_type: billingDocumentType,
              document: billingDocument,
              farm: parseInt(farmID),
              company_name: billingCompanyName,
              country: billingCountry,
              state: billingState,
              city: billingCity,
              address: billingAddress,
              postal_code: billingPostalCode,
              email: billingEmail,
              phone: billingPhone.replace(/\D/g, ""),
              language: billingLanguage,
            };

            if (billing) {
              actions.put(parseInt(farmID), billingObject);
            } else {
              actions.post(parseInt(farmID), billingObject);
            }
          }

          props.patchFarm(patchObject);
        }
      }}
    >
      <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
    </Button>
  );

  const fetchHolidays = async (country: "BR" | "RU") => {
    try {
      const currentYear = new Date().getFullYear();
      const reqUrl = `/v4/integrations/holidays/?year=${currentYear}&country=${country}`;
      const response = await coreHTTPClient.get(reqUrl);
      if (
        [200, 201].includes(response.status) &&
        response.data &&
        response.data.length > 0
      ) {
        return response.data;
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const modalHolidaysChooser = () => {
    dispatch(
      showModal({
        content: (
          <div
            style={{
              paddingBottom: 10,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "55vw",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                const contryHolidays = await fetchHolidays("BR");
                if (contryHolidays) {
                  setFarmHolidays((prev) => {
                    let holidays = prev.concat(contryHolidays);
                    holidays.sort((a, b) => {
                      if (a.month > b.month) return 1;
                      if (a.month < b.month) return -1;
                      if (a.day > b.day) return 1;
                      if (a.day < b.day) return -1;
                      return 0;
                    });

                    // Remove duplicates
                    holidays = holidays.filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) => t.day === item.day && t.month === item.month
                        )
                    );
                    return holidays;
                  });
                }
                dispatch(hideModal());
              }}
            >
              {i18n("COUNTRY_BRAZIL")}
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                const contryHolidays = await fetchHolidays("RU");
                if (contryHolidays) {
                  setFarmHolidays((prev) => {
                    let holidays = prev.concat(contryHolidays);
                    holidays.sort((a, b) => {
                      if (a.month > b.month) return 1;
                      if (a.month < b.month) return -1;
                      if (a.day > b.day) return 1;
                      if (a.day < b.day) return -1;
                      return 0;
                    });

                    // Remove duplicates
                    holidays = holidays.filter(
                      (item, index, self) =>
                        index ===
                        self.findIndex(
                          (t) => t.day === item.day && t.month === item.month
                        )
                    );
                    return holidays;
                  });
                }
                dispatch(hideModal());
              }}
            >
              {i18n("COUNTRY_RUSSIA")}
            </Button>
          </div>
        ),
        title: "EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS",
      })
    );
  };

  return (
    <div className="general-form__container">
      <DashboardBox
        disableFade={true}
        leftElement={backButton}
        centerElement={
          <h2>
            <>{i18n("EDIT_FARM_LABEL")}</>
          </h2>
        }
        rightElement={saveButton}
      >
        <div
          style={{
            padding: isMobile() && "0px",
            backgroundColor: "white",
            display: "grid",
            gridTemplateColumns: "12fr",
            height: "100%",
          }}
        >
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={handleChange}
            aria-label="Edit Farm"
            indicatorColor="primary"
            variant={"scrollable"}
            centered
          >
            <Tab
              className={classes.tab}
              disabled={disabled || debtor?.is_debtor}
              icon={
                validationErrors["name"] ||
                validationErrors["centralLinked"] ||
                validationErrors["billingDate"] ||
                validationErrors["lng"] ||
                validationErrors["lat"] ? (
                  <InfoIcon
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <InfoIcon
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              label={<>{i18n("EDIT_FARM_GENERAL_INFORMATION")}</>}
              {...a11yProps(0)}
            ></Tab>

            <Tab
              className={classes.tab}
              disabled={!groupPermission}
              icon={
                validationErrors["billingDocument"] ||
                validationErrors["billingCompanyName"] ||
                validationErrors["billingPhone"] ||
                validationErrors["billingEmail"] ||
                validationErrors["billingPostalCode"] ||
                validationErrors["billingAddress"] ||
                validationErrors["billingCity"] ||
                validationErrors["billingState"] ? (
                  <ReceiptOutlinedIcon
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <ReceiptOutlinedIcon
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              label={<>{i18n("EDIT_FARM_BILLING")}</>}
              {...a11yProps(1)}
            />

            <Tab
              className={classes.tab}
              disabled={disabled || debtor?.is_debtor}
              icon={
                validationErrors["postalCode"] ||
                validationErrors["address"] ||
                validationErrors["city"] ||
                validationErrors["state"] ? (
                  <EmailIcon
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <EmailIcon
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              label={<>{i18n("EDIT_FARM_CONTACT")}</>}
              {...a11yProps(2)}
            />

            <Tab
              className={classes.tab}
              disabled={disabled || debtor?.is_debtor}
              icon={
                <PowerIcon
                  style={{
                    color: green[500],
                  }}
                />
              }
              label={<>{i18n("EDIT_FARM_ENERGY_RANGES")}</>}
              {...a11yProps(3)}
            />

            <Tab
              disabled={disabled || debtor?.is_debtor}
              className={classes.tab}
              icon={
                <AssessmentIcon
                  style={{
                    color: green[500],
                  }}
                />
              }
              label={i18n("EDIT_FARM_PIVOT_REPORTS")}
              {...a11yProps(4)}
            />

            <Tab
              disabled={disabled || debtor?.is_debtor}
              className={classes.tab}
              icon={
                <AssessmentIcon
                  style={{
                    color: green[500],
                  }}
                />
              }
              label={i18n("EDIT_FARM_IRPD_REPORTS")}
              {...a11yProps(5)}
            />

            <Tab
              disabled={disabled || debtor?.is_debtor}
              className={classes.tab}
              icon={
                <BeachAccessIcon
                  style={{
                    color: green[500],
                  }}
                />
              }
              label={i18n("EDIT_FARM_HOLLIDAYS")}
              {...a11yProps(6)}
            />

            <Tab
              disabled={
                location.pathname.includes("/first-steps") ||
                disabled ||
                debtor?.is_debtor
              }
              className={classes.tab}
              icon={
                <GroupIcon
                  style={{
                    color: green[500],
                  }}
                />
              }
              label={<>{i18n("EDIT_FARM_USERS")}</>}
              {...a11yProps(7)}
            />
          </Tabs>

          <div
            style={{
              padding: isMobile() && "0px",
              backgroundColor: "#fff",
              display: "grid",
              gridTemplateColumns: "1fr",
              height: "100%",
            }}
          >
            <TabPanel value={value} index={0}>
              <GeneralInformation
                validationErrors={validationErrors}
                formValidationActions={formValidationActions}
                stateName={[name, setName]}
                stateCentralLinked={[centralLinked, setCentralLinked]}
                stateLat={[lat, setLat]}
                stateLng={[lng, setLng]}
                stateBill={[billingDate, setBillingDate]}
                stateWaterBill={[waterBillingDate, setWaterBillingDate]}
                initialLat={initialLat}
                initialLng={initialLng}
                rawTimezone={props.farm.timezone}
                setTimezone={setTimezone}
                stateBase={[base, setBase]}
              />
            </TabPanel>

            <TabPanel value={value} index={1}>
              {!async.loading && (
                <Billing
                  farmID={farmID}
                  costumerID={billingID}
                  actions={actions}
                  validationErrors={validationErrors}
                  formValidationActions={formValidationActions}
                  billingDocumentType={[
                    billingDocumentType,
                    setBillingDocumentType,
                  ]}
                  billingDocument={[billingDocument, setBillingDocument]}
                  billingAddress={[billingAddress, setBillingAddress]}
                  billingPostalCode={[billingPostalCode, setBillingPostalCode]}
                  billingCompanyName={[
                    billingCompanyName,
                    setBillingCompanyName,
                  ]}
                  billingPhone={[billingPhone, setBillingPhone]}
                  IDD={IDDBILLING}
                  billingEmail={[billingEmail, setBillingEmail]}
                  billingCity={[billingCity, setBillingCity]}
                  billingState={[billingState, setBillingState]}
                  billingCountry={[billingCountry, setBillingCountry]}
                  billingLanguage={[billingLanguage, setBillingLanguage]}
                ></Billing>
              )}
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Contact
                validationErrors={validationErrors}
                formValidationActions={formValidationActions}
                phone={[phone, setPhone]}
                IDD={IDD}
                postalCode={[postalCode, setPostalCode]}
                address={[address, setAddress]}
                city={[city, setCity]}
                state={[state, setState]}
                country={[country, setCountry]}
              ></Contact>
            </TabPanel>

            <TabPanel value={value} index={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px",
                  boxSizing: "border-box",
                  width: "calc(100%)",
                }}
              >
                <h3 style={{ margin: 3 }}>{i18n("EDIT_FARM_POWER_RANGES")}</h3>

                <span style={{ margin: 3, marginBottom: 10 }}>
                  {i18n("EDIT_FARM__POWER_RANGES_DESCRIPTION")}
                </span>

                <CreatePowerRangeButton
                  variant="contained"
                  disabled={powerRangeAlreadySelectedDays.length == 7}
                  onClick={() =>
                    editPowerRange({
                      uuid: nanoid(),
                      days: [],
                      range: [],
                    })
                  }
                >
                  <AddBox style={{ marginRight: 15 }}></AddBox>
                  {i18n("EDIT_FARM_ADD_POWER_RANGES")}
                </CreatePowerRangeButton>

                {powerRanges.map((power) => (
                  <PowerRange
                    key={`${power.days}_${power.range}`}
                    onEditClicked={() => editPowerRange(power)}
                    onDeleteClicked={() =>
                      setFarmPowerRanges((fpr) => {
                        if (fpr.none) return fpr;

                        power.days.forEach((d) => delete fpr[d]);

                        //Estou deletando o ultimo
                        if (powerRanges.length == 1) {
                          return { none: true };
                        }

                        return { ...fpr };
                      })
                    }
                    days={power.days}
                    ranges={power.range}
                  />
                ))}
              </div>
            </TabPanel>

            <TabPanel value={value} index={4}>
              <OperationsSummary
                powerRanges={farm?.power_ranges}
                startPivotReportAggregate={farm?.start_pivot_report_aggregate}
                hasPowerRangesChangedCount={powerUpdatedRangesCount}
                farmID={farmID}
              />
            </TabPanel>

            <TabPanel value={value} index={5}>
              <IrpdInformations
                powerRanges={farm?.power_ranges}
                startIrpdReportAggregate={farm?.start_irpd_report_aggregate}
                hasPowerRangesChangedCount={powerUpdatedRangesCount}
                farmID={farmID}
              />
            </TabPanel>

            <TabPanel value={value} index={6}>
              <div style={{ padding: "12px" }}>
                <h3
                  style={
                    isMobile()
                      ? { margin: 3, marginLeft: "5px" }
                      : { margin: 3 }
                  }
                >
                  {i18n("EDIT_FARM_ADD_HOLLIDAYS")}
                </h3>

                <Holidays
                  holidays={farmHolidays}
                  addHoliday={(newHoliday) => {
                    if (
                      farmHolidays.find(
                        (holiday) =>
                          holiday.day == newHoliday.day &&
                          holiday.month == newHoliday.month
                      )
                    ) {
                      notify("EDIT_FARM_REPEATED_DAYS", "error");
                    } else {
                      setFarmHolidays((holidays) => {
                        if (holidays.length > 25) return holidays;
                        else return [...holidays, newHoliday];
                      });
                    }
                  }}
                  deleteHoliday={(deletedHoliday) => {
                    setFarmHolidays((holidays) =>
                      holidays.filter(
                        (day) =>
                          !(
                            day.day == deletedHoliday.day &&
                            day.month == deletedHoliday.month
                          )
                      )
                    );
                  }}
                  importHolidays={() => {
                    modalHolidaysChooser();
                  }}
                />
              </div>
            </TabPanel>

            <TabPanel value={value} index={7}>
              <Users farmID={farm.id} farmCode={farm.farm_code} />
            </TabPanel>
          </div>
        </div>
      </DashboardBox>
    </div>
  );
}

import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { NotificationType } from "../../pages/Dashboard/Notifications/Notifications";
import { SettingState } from "../../redux/setting/types";

export interface Reasons {
  id: number;
  type: number;
  protocol: number;
  reason_number: number;
  label: string;
  critical: boolean;
}

const useReasons = (
  type: NotificationType,
  selectedLanguage: SettingState
): [
  boolean,
  Reasons[],
  Reasons[],
  (type: NotificationType, selectedLanguage: SettingState) => Promise<void>
] => {
  const [reasons, setReasons] = useState(undefined);
  const [criticalReasons, setCriticalReasons] = useState(undefined);
  const [loading, setLoading] = useState(true);

  async function getReasons(
    type: NotificationType,
    selectedLanguage: SettingState
  ) {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await coreHTTPClient.get(
          `v3/notifications/reasons/?&type=${type}&language=${selectedLanguage.language}`
        );
        if (checkExists(["data", response, "__cascade"])) {
          const results = response.data as Reasons[];
          setReasons(results);
          setCriticalReasons(
            results.filter((result) => result.critical === true)
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getReasons(type, selectedLanguage);
  }, []);

  return [loading, reasons, criticalReasons, getReasons];
};

export default useReasons;

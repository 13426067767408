import React, { Dispatch, SetStateAction, useEffect } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { i18n, i18nTextId } from "../../../i18n/i18nText";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../redux";
import { Language } from "../../../redux/setting/types";

interface Props {
  label?: string;
  newLabel?: i18nTextId;
  id: string;
  disabled?: boolean;
  state: [moment.Moment | null, Dispatch<SetStateAction<moment.Moment>>];
}

function FormDatePicker(props: Props) {
  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  useEffect(() => {
    switch (language) {
      case Language.ENGLISH:
        moment.locale("en-SG");
        break;
      case Language.PORTUGUESE_BRAZIL:
        moment.locale("pt-br");
        break;
      case Language.RUSSIAN:
        moment.locale("ru");
        break;
      case Language.GERMAN_AUSTRIA:
        moment.locale("de-at");
        break;
      case Language.SPAIN_SPANISH:
        moment.locale("es");
        break;
      case Language.I18N_KEYS:
        moment.locale("keys");
        break;
      default:
    }
  }, [language]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        // key={`DatePicker__${moment.locale()}`}
        id={props.id}
        disabled={props.disabled}
        label={
          props.newLabel ? (
            i18n(props.newLabel)
          ) : (
            <>{i18n(props.label as i18nTextId)}</>
          )
        }
        format="LL"
        value={props.state[0]}
        onChange={(value) => {
          if (value !== null) {
            props.state[1](value as moment.Moment);
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormDatePicker;

import React from "react";
import "./EditFarmForm.scss";
import { Farm } from "../../../../../../redux/farms/types";
import DrawerFarm from "./DrawerFarm/DrawerFarm";

interface Props {
  farm: Farm;
  patchFarm: (patchObject: any) => void;
  createFarm: (patchObject: any) => void;
}

function NewEditFarmForm(props: Props) {
  const { farm } = props;

  return (
    <DrawerFarm
      farm={farm}
      patchFarm={props.patchFarm}
      createFarm={props.createFarm}
    />
  );
}

export default NewEditFarmForm;

import "./DeviceBox.scss";

import styled from "@emotion/styled";
import {
  CircularProgress,
  FormControl,
  makeStyles,
  NativeSelect,
} from "@material-ui/core";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Routes from "../../../../../routes/routes";
import { isMobile } from "../../../../../mobileConfig";
import { Farm } from "../../../../../redux/farms/types";
import { Irpd } from "../../../../../redux/irpds/types";
import { coreHTTPClient } from "../../../../../services/webclient";
import DeviceListComponent from "../../../../../utils/devices/DeviceListComponent";
import { DeviceNode } from "../../../../../utils/devices/DeviceTypes";
import { ReactJSX } from "../../../../../utils/types";
import DashboardBox from "../../../components/DashboardBox/DashboardBox";
import { DeviceNodeFound, EnabledDeviceMap, Repeater } from "../../Devices";
import ButtonWithProgress from "./components/ButtonWithProgress/ButtonWithProgress";
import DeviceExpandableSubtitles from "./components/DeviceExpandableSubtitles/DeviceExpandableSubtitles";
import { i18n } from "../../../../../i18n/i18nText";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  loading: boolean;
  rightElement?: ReactJSX;
  repeaters?: Repeater[];

  farms: Farm[];
  irpds?: Irpd[];
  enabledDevices: EnabledDeviceMap;
  nodes: DeviceNodeFound[];
  selectedFarm: number;
  setSelectedFarm: (farmID: number) => any;
  doDeviceSearch: (radio_id: string) => any;

  mapRef: React.MutableRefObject<any>;
  setEnabledDevices: (...args: any[]) => any;

  devices: DeviceNode[];
  deviceRefs: React.RefObject<HTMLDivElement>[];
}

export const useStyles = makeStyles({
  pivotDeviceButton: {
    width: "100%",
    backgroundColor: "#0066FF",
  },
  marginLeft: {
    marginLeft: "10px",
  },
});

export const SignalQualityMap = {
  "very strong": "DEVICE_BOX_VERY_STRONG",
  strong: "DEVICE_BOX_STRONG",
  moderate: "DEVICE_BOX_MODERATE",
  weak: "DEVICE_BOX_WEAK",
  error: "DEVICE_BOX_ERROR",
};

function DeviceBox(props: Props) {
  const [expandedDevices, setExpandedDevices] = useState<string[]>([]);

  const [expandedSubtitles, setExpandedSubtitles] = useState<boolean>(true);
  const deviceListRef = useRef(null);

  const classes = useStyles(props);
  const navigate = useNavigate();

  const deviceComponentList = props.devices.map((device, deviceIndex) => {
    let isExpanded = expandedDevices.includes(device.radio_id);

    //Assim é possível filtrar na renderização e separar por abas.
    return {
      type: device.type,
      component: (
        <DeviceListComponent
          key={device.radio_id}
          ref={props.deviceRefs[deviceIndex]}
          onDeviceClick={() => {
            if (isExpanded) {
              setExpandedDevices((devicesRadioId) =>
                devicesRadioId.filter((radio_id) => radio_id != device.radio_id)
              );
            } else {
              setExpandedDevices((devicesRadioId) => [
                ...devicesRadioId,
                device.radio_id,
              ]);

              if (props.mapRef.current)
                props.mapRef.current.panToDevice(device);
            }
          }}
          doDeviceSearch={props.doDeviceSearch}
          deviceNode={device}
          devices={props.devices}
          enabledDevices={props.enabledDevices}
          foundNodes={props.nodes}
          isExpanded={isExpanded}
        />
      ),
    };
  });

  if (!props.loading) {
    return (
      // Esse padding é reponspavel por não deixar o último item da DeviceBox ficar atrás do componente de Tabs
      <div style={isMobile() ? { paddingBottom: "60px" } : null}>
        <DashboardBox
          singleElement={
            <Col>
              <Row>
                <FormControl>
                  <NativeSelect
                    value={props.selectedFarm}
                    onChange={(evt) => {
                      props.setSelectedFarm(
                        parseInt(evt.target.value as string)
                      );
                      navigate(
                        Routes.DASHBOARD.DEVICES.replace(
                          ":farm",
                          String(evt.target.value)
                        )
                      );
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <option value="" disabled>
                      {i18n("FARMS")}
                    </option>
                    {props.farms.map((farm) => (
                      <option key={farm.id} value={farm.id}>
                        {farm.name}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>

                <MenuBookIcon
                  style={{
                    color: "#757575",
                    marginLeft: 10,
                    opacity: expandedSubtitles ? 1 : 0.4,
                  }}
                  onClick={() => {
                    if (!expandedSubtitles && deviceListRef.current) {
                      deviceListRef.current.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }

                    setExpandedSubtitles((s) => !s);
                  }}
                />
              </Row>
            </Col>
          }
        >
          <div className="device-box-devices__container" ref={deviceListRef}>
            <DeviceExpandableSubtitles
              isExpanded={expandedSubtitles}
            ></DeviceExpandableSubtitles>

            {isMobile() && (
              <div style={{ padding: 15 }}>
                <ButtonWithProgress
                  className={classes.pivotDeviceButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    (async () => {
                      props.setEnabledDevices({});

                      const response = await coreHTTPClient.post(
                        `v3/farms/${props.selectedFarm}/mqtt/gn/`
                      );
                    })();
                  }}
                >
                  {i18n("DEVICE_BOX_SEARCH_RADIO")}
                </ButtonWithProgress>
              </div>
            )}

            {/* CENTRAL */}
            <div className="device-box__label">
              <>{i18n("CREATE_DEVICE_MODAL_CENTRAL")}</>:
            </div>

            {deviceComponentList
              .filter((dev) => dev.type == "CENTRAL")
              .map((dev) => dev.component)}

            {/* PIVOTS */}
            <div className="device-box__label">
              <>{i18n("SELECTED_FARM_PIVOTS")}</>:
            </div>

            {deviceComponentList
              .filter((dev) => dev.type == "PIVOT")
              .map((dev) => dev.component)}

            {/* IRPDS */}
            {props.irpds.length === 0 ? (
              <></>
            ) : (
              <div className="device-box-devices__label">
                <>{i18n("SELECTED_FARM_IRPDS")}</>:
              </div>
            )}

            {deviceComponentList
              .filter((dev) => dev.type == "PUMP")
              .map((dev) => dev.component)}

            {/* REPEATERS */}
            <div className="device-box__label">
              {props.repeaters?.length === 0 ? (
                <></>
              ) : (
                <>
                  <>{i18n("CREATE_DEVICE_MODAL_REPEATER")}</>:
                </>
              )}
            </div>

            {deviceComponentList
              .filter((dev) => dev.type == "REPEATER")
              .map((dev) => dev.component)}

            {/* 
          {searchInput === ""
            ? props.irpds.map((irpd) => (
                <IrpdItem
                  irpd={irpd}
                  sweepStatus={sweepStatus[`irpd-${irpd.id}`]}
                />
              ))
            : props.irpds
                .filter((irpd) => {
                  return irpd.name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
                })
                .map((irpd) => <IrpdItem irpd={irpd} />)} */}
          </div>
        </DashboardBox>
      </div>
    );
  }

  return (
    <DashboardBox
      singleElement={
        <FormControl>
          <NativeSelect
            value={props.selectedFarm}
            onChange={(evt) => {
              props.setSelectedFarm(parseInt(evt.target.value as string));
            }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <option value="" disabled>
              {i18n("FARMS")}
            </option>
            {props.farms.map((farm) => (
              <option key={farm.id} value={farm.id}>
                {farm.name}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      }
    >
      <div className="devices__loading-div">
        <CircularProgress size={30} />
      </div>
    </DashboardBox>
  );
}

export default DeviceBox;

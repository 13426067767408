import {
  Checkbox,
  FormControlLabel,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import moment, { Moment } from "moment";
import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { i18n } from "../../../../i18n/i18nText";
import { Notification } from "../../../../redux/notifications/types";

interface Props {
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dateContainer: {
    height: "250px",
  },
  dates: {},
  typography: {
    fontSize: "18px",
    fontWeight: "bolder",
    margin: "0px 0px 15px 0px",
  },
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
}));

const NotificationsTimeInterval = (props: Props) => {
  const { notification, setNotification } = props;
  const [checked, setChecked] = useState(
    notification.start === "00:00:00" && notification.end === "23:59:59"
  );
  const [start, setStart] = useState(
    !notification.start
      ? moment({ hour: 0, minute: 0, second: 0 })
      : moment(notification.start, "HH:mm:ss")
  );
  const [end, setEnd] = useState(
    !notification.end
      ? moment({ hour: 23, minute: 59, second: 59 })
      : moment(notification.end, "HH:mm:ss")
  );

  const handleChangeStartDate = (newValue: Moment) => {
    setStart(moment(newValue, "HH:mm:ss"));
    setNotification({
      ...notification,
      start: moment(newValue, "HH:mm:ss").format("HH:mm:ss"),
    });
  };

  const handleChangeEndDate = (newValue: Moment) => {
    setEnd(moment(newValue, "HH:mm:ss"));
    setNotification({
      ...notification,
      end: moment(newValue, "HH:mm:ss").format("HH:mm:ss"),
    });
  };

  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setStart(moment({ hour: 0, minute: 0, second: 0 }));
      setEnd(moment({ hour: 23, minute: 59, second: 59 }));
      setNotification({
        ...notification,
        start: "00:00:00",
        end: "23:59:59",
      });
    } else if (
      !event.target.checked &&
      !notification.start &&
      !notification.end
    ) {
      setStart(moment({ hour: 0, minute: 0, second: 0 }));
      setEnd(moment({ hour: 23, minute: 59, second: 59 }));
      setNotification({
        ...notification,
        start: moment(new Date(), "HH:mm:ss").format("HH:mm:ss"),
        end: moment(new Date(), "HH:mm:ss").format("HH:mm:ss"),
      });
    }
  };

  const classes = useStyles();

  return (
    <div className={classes.dateContainer}>
      <Typography className={classes.typography}>
        {i18n("CREATE_NOTIFICATIONS_PICK_TIME")}
      </Typography>

      <div className={classes.dates}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            margin="normal"
            id={"notification-start-date"}
            ampm={false}
            disabled={checked}
            label={i18n("CREATE_NOTIFICATIONS_STARTS_ON")}
            value={start}
            format="HH:mm"
            onChange={handleChangeStartDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            margin="normal"
            id={"notification-end-date"}
            ampm={false}
            disabled={checked}
            label={i18n("CREATE_NOTIFICATIONS_ENDS_ON")}
            value={end}
            format="HH:mm"
            onChange={handleChangeEndDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>

        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChangeChecked}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={i18n("CREATE_NOTIFICATIONS_ALL_DAY")}
        />
      </div>
    </div>
  );
};

export default NotificationsTimeInterval;

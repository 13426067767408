import React from "react";
import FormSelect from "../../../../../../../../../../components/Form/FormSelect/FormSelect";

interface Props {
  mode: string;
  setModeArray: (value: any) => void;
  currentIndex: number;
}

function SegmentModeSelect(props: Props) {
  const { mode, setModeArray, currentIndex } = props;

  return (
    <FormSelect
      id="mode"
      label={"SIMPLE_IRRIGATION_FIELD_MODE"}
      state={[
        mode,
        (value) => {
          setModeArray((prevArray) => {
            prevArray[currentIndex] = value;
            return [...prevArray];
          });
        },
      ]}
      values={[
        ["1", "SIMPLE_IRRIGATION_VALUE_WET"],
        ["2", "SIMPLE_IRRIGATION_VALUE_DRY"],
      ]}
    />
  );
}

export default SegmentModeSelect;

import { AdmTypes } from "./types";
import { AdmActions } from "./actions";

export interface AdminObject {
  id: 0;
  valid: false;
  type: "";
  take: false;
}

export interface AdminState {
  admin: AdminObject;
}

export const INITIAL_STATE: AdminState = {
  admin: {
    id: 0,
    valid: false,
    type: "",
    take: false,
  },
};

function reducer(state = INITIAL_STATE, action: AdmActions): AdminState {
  const { type, payload } = action;

  switch (type) {
    case AdmTypes.ADD_ADM:
      return { ...state, admin: payload };

    default:
      return state;
  }
}

export default reducer;

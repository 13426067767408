import React from "react";
import "./InfoBox.scss";
import { ReactJSX } from "../../../../../../../../utils/types";

interface Props {
  children: any;
  leftElement?: ReactJSX;
  centerElement?: ReactJSX;
  rightElement?: ReactJSX;
  fullWidth?: boolean;
  fullHeight?: boolean;
}
// fullHeight keeps the fullWidth but adds a fixed height

function InfoBox(props: Props) {
  return (
    <div
      className={"info-box"}
      style={
        props.fullWidth
          ? { width: "100%", maxWidth: "calc(100% - 20px)" }
          : props.fullHeight
          ? { width: "100%", maxWidth: "calc(100% - 20px)", height: "560px" }
          : {}
      }
    >
      <div className="info-box__grid">
        <div className="info-box__left-element">{props.leftElement}</div>
        <div className="info-box__center-element">{props.centerElement}</div>
        <div className="info-box__right-element">{props.rightElement}</div>
      </div>
      {props.children}
    </div>
  );
}

export default React.memo(InfoBox);

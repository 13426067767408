// Dependencies
import {
  Button,
  Chip,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import React, { FunctionComponent, ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import { Pivot } from "../../../../../../redux/pivots/types";
import { useRecoilValue } from "recoil";
import { LatestStreamPivot } from "../../../../../../recoils/PivotRecoil";

// Custom styles
const StyledList = styled.ul`
  list-style: none;
  padding: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;

const StyledListItem = styled.li`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
  font-weight: bold;
`;

const CollapsedContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IrrifastTitleRow = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

// Component props
type Props = {
  pivot: Pivot;
};

export const IRRIFAST_COLORS = {
  2: "#cccccc",
  1: "green",
  0: "red",
};

export const IRRIFAST_TOOLTIPS = {
  2: i18n("IRRIFAST_TABLE_STATUS_OFF"),
  1: i18n("IRRIFAST_TABLE_STATUS_ALIGNED"),
  0: i18n("IRRIFAST_TABLE_STATUS_NOTALIGNED"),
};

// Component
const IrrifastBox: FunctionComponent<Props> = ({ pivot }): ReactElement => {
  // Local state
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedTower, setSelectedTower] = useState<number | null>(null);

  // Recoil
  const latestStreamValue = useRecoilValue(LatestStreamPivot(pivot.id));

  // TSX
  return (
    <>
      <StyledList>
        {latestStreamValue.irrifastStream.map((tower, i) => {
          const computedStatus =
            tower.aligment_status === null ? 2 : tower.aligment_status;
          return (
            <Tooltip
              key={`tower-${tower.tower_number}`}
              title={IRRIFAST_TOOLTIPS[computedStatus]}
              placement="top"
            >
              <StyledListItem
                onClick={() => setSelectedTower(i)}
                style={
                  pivot.controllerstream_panel?.content?.irrigation_status
                    ?.irrigation_status !== 4
                    ? {
                        backgroundColor: IRRIFAST_COLORS[computedStatus],
                      }
                    : selectedTower && selectedTower + 1 === tower.tower_number
                    ? {
                        borderColor: "#000000",
                        borderWidth: 2,
                        borderStyle: "solid",
                        opacity: 1,
                        backgroundColor: IRRIFAST_COLORS[computedStatus],
                      }
                    : {
                        opacity: selectedTower ? 0.6 : 1,
                        backgroundColor: IRRIFAST_COLORS[computedStatus],
                      }
                }
              >
                {(i + 1).toString().padStart(2, "0")}
              </StyledListItem>
            </Tooltip>
          );
        })}
      </StyledList>
      {latestStreamValue.streamStatus.number === 4 ? (
        <CollapsedContainer>
          <Button color="primary" onClick={() => setCollapsed((prev) => !prev)}>
            {i18n("IRRIFAST_TABLE_ACTION_COLLAPSE")}{" "}
            {collapsed ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
          <Collapse
            in={collapsed}
            style={{
              width: "100%",
            }}
          >
            {selectedTower !== null ? (
              <>
                <IrrifastTitleRow>
                  <Typography variant="h5">
                    Torre{" "}
                    {latestStreamValue.irrifastStream[
                      selectedTower
                    ].tower_number
                      .toString()
                      .padStart(2, "0")}
                  </Typography>

                  <Chip
                    label={
                      IRRIFAST_TOOLTIPS[
                        latestStreamValue.irrifastStream[selectedTower]
                          .aligment_status === null
                          ? 2
                          : latestStreamValue.irrifastStream[selectedTower]
                              .aligment_status
                      ]
                    }
                    style={{
                      backgroundColor:
                        IRRIFAST_COLORS[
                          latestStreamValue.irrifastStream[selectedTower]
                            .aligment_status === null
                            ? 2
                            : latestStreamValue.irrifastStream[selectedTower]
                                .aligment_status
                        ],
                      color:
                        latestStreamValue.irrifastStream[selectedTower]
                          .aligment_status === null
                          ? "#000"
                          : "#fff",
                    }}
                  />
                </IrrifastTitleRow>
                <TableContainer component={Paper} style={{ marginBottom: 16 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{i18n("IRRIFAST_TABLE_COL_SPEC")}</TableCell>
                        <TableCell align="right">
                          {i18n("IRRIFAST_TABLE_COL_VALUE")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={`tension-${latestStreamValue.irrifastStream[selectedTower].tower_number}`}
                      >
                        <TableCell component="th" scope="row">
                          {i18n("IRRIFAST_TABLE_PROPS_TENSION")}
                        </TableCell>
                        <TableCell align="right">
                          {latestStreamValue.irrifastStream[selectedTower]
                            .cc_voltage !== null
                            ? latestStreamValue.irrifastStream[selectedTower]
                                .cc_voltage
                            : "-"}{" "}
                          {i18n("IRRIFAST_TABLE_PROPS_TENSION_SUFFIX")}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={`freq-${latestStreamValue.irrifastStream[selectedTower].tower_number}`}
                      >
                        <TableCell component="th" scope="row">
                          {i18n("IRRIFAST_TABLE_PROPS_FREQ")}
                        </TableCell>
                        <TableCell align="right">
                          {latestStreamValue.irrifastStream[selectedTower]
                            .output_frequency !== null
                            ? latestStreamValue.irrifastStream[selectedTower]
                                .output_frequency
                            : "-"}{" "}
                          {i18n("IRRIFAST_TABLE_PROPS_FREQ_SUFFIX")}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={`current-${latestStreamValue.irrifastStream[selectedTower].tower_number}`}
                      >
                        <TableCell component="th" scope="row">
                          {i18n("IRRIFAST_TABLE_PROPS_CURRENT")}
                        </TableCell>
                        <TableCell align="right">
                          {latestStreamValue.irrifastStream[selectedTower]
                            .output_current !== null
                            ? latestStreamValue.irrifastStream[selectedTower]
                                .output_current
                            : "-"}{" "}
                          {i18n("IRRIFAST_TABLE_PROPS_CURRENT_SUFFIX")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography component="p" style={{ margin: "16px 0" }}>
                {i18n("IRRIFAST_TABLE_LABEL_NONE")}
              </Typography>
            )}
          </Collapse>
        </CollapsedContainer>
      ) : null}
    </>
  );
};

export default IrrifastBox;

import React, { useContext, useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import { useLocation, useNavigate, useParams } from "react-router";
import { isMobile } from "../../mobileConfig";
import "./QuickSelect.scss";
import routes from "../../routes/routes";

interface Props {
  farmID: number;
}

interface Device {
  id: number;
  name: string;
  farm_id: number;
  farm_name: string;
  type: string;
  route: string;
  unique_id: number;
}

function QuickSelect(props: Props) {
  const { farmID } = props;

  const [allDevices, setAllDevices] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  async function getAllDevices(farmID: number) {
    try {
      // STEP 01: Get the list of devices for that farm
      const response = await coreHTTPClient.get(`v3/farms/${farmID}/devices`);

      // STEP 02: Sort the devices by name and type
      const devicesTypePivot = response.data.filter(
        (device) => device.type === "PIVOT"
      );
      devicesTypePivot.sort((a, b) => (a.name > b.name ? 1 : -1));

      const devicesTypeIrpd = response.data.filter(
        (device) => device.type === "IRPD"
      );
      devicesTypeIrpd.sort((a, b) => (a.name > b.name ? 1 : -1));

      const devicesTypeMeter_System = response.data.filter(
        (device) => device.type === "METER_SYSTEM"
      );
      devicesTypeMeter_System.sort((a, b) => (a.name > b.name ? 1 : -1));

      const devicesTypeBauerPivot = response.data.filter(
        (device) => device.type === "SMARTRAIN_PIVOT"
      );
      devicesTypeBauerPivot.sort((a, b) => (a.name > b.name ? 1 : -1));

      const devicesSortedByNameAndType = [
        ...devicesTypePivot,
        ...devicesTypeIrpd,
        ...devicesTypeMeter_System,
        ...devicesTypeBauerPivot,
      ];

      // STEP 03: Give the devices unique ids
      const devicesWithUniqueIds = devicesSortedByNameAndType.map(
        (device: Device, index: number) => {
          if (device.type == "SMARTRAIN_PIVOT")
            return {
              ...device,
              route: routes.DASHBOARD.SELECTED_BAUER_PIVOT.replace(
                ":farm",
                String(farmID)
              ).replace(":pivot", String(device.id)),
              unique_id: index + 1,
            };
          else
            return {
              ...device,
              unique_id: index + 1,
            };
        }
      );

      setAllDevices(devicesWithUniqueIds);
    } catch (error) {
      console.log(error);
    }
  }

  const allDeviceNameOptions =
    allDevices.length === 0
      ? null
      : allDevices.map((device) => (
          <option
            className={
              isMobile()
                ? "mobile-quickselect-option"
                : "desktop-quickselect-option"
            }
            key={device.unique_id}
            value={device.unique_id}
          >
            {device.name}{" "}
          </option>
        ));

  // LÓGICA PARA FIXAR O NOME DO EQUIPAMENTO ATUAL NO SELECT -----------------------------

  const location = useLocation();

  function getCurrentDeviceType(): string {
    if (location.pathname.includes("pivots")) {
      return "PIVOT";
    } else if (location.pathname.includes("irpds")) {
      return "IRPD";
    } else if (location.pathname.includes("metersystem")) {
      return "METER_SYSTEM";
    } else if (location.pathname.includes("bauer-pivot")) {
      return "SMARTRAIN_PIVOT";
    }
  }

  const params = useParams();

  function showCurrentDeviceName() {
    const currentPivotID = params.pivot;
    const currentIrpdID = params.irpd;
    const currentMetersystemID = params.metersystem;
    const currentBauerPivotID = params["bauer-pivot"];

    const currentDeviceObject =
      allDevices.length === 0
        ? null
        : allDevices.find((device) => {
            return (
              `${device.type}-${device.id}` ===
              `${getCurrentDeviceType()}-${Number(
                currentPivotID ||
                  currentIrpdID ||
                  currentMetersystemID ||
                  currentBauerPivotID
              )}`
            );
          });

    const currentDeviceValue = !currentDeviceObject
      ? null
      : currentDeviceObject.unique_id;

    setSelectedValue(currentDeviceValue);
  }

  // ----------------------------------------------------------------------------------------

  // LÓGICA PARA NAVEGAR PARA A PÁGINA DO EQUIPAMENTO SELECIONADO  -------------------------

  const navigate = useNavigate();

  function handleDeviceChange(event: React.ChangeEvent<{ value: unknown }>) {
    const nextDeviceValue = Number(event.target.value);

    const nextDeviceObject = allDevices.find(
      (device) => nextDeviceValue === device.unique_id
    );

    const nextDeviceRoute = nextDeviceObject.route;

    navigate(`/${nextDeviceRoute}`);
  }

  // ---------------------------------------------------------------------------------------

  useEffect(() => {
    getAllDevices(farmID);
  }, [params]);

  useEffect(() => {
    showCurrentDeviceName();
  }, [allDevices, params]);

  return (
    <>
      <select
        className={isMobile() ? "mobile-quickselect" : "desktop-quickselect"}
        value={selectedValue}
        onChange={handleDeviceChange}
      >
        {allDeviceNameOptions}
      </select>
    </>
  );
}

export default QuickSelect;

import createMuiTheme, {
  ThemeOptions,
} from "@material-ui/core/styles/createMuiTheme";

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: "#44b86a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0057FF",
      contrastText: "#fff",
    },
    success: {
      main: "#E5E5E5",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        width: "100%",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
      },
    },
    MuiInputLabel: {
      root: {
        color: "#999",
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 13,
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiSelect: {
      select: {
        height: "20.5px",
      },
    },
    MuiButton: {
      root: {
        color: "rgba(0,0,0,0.54)",
        transition: "color 0.2s",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "thin",
        },
        "*::webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        display: "initial",
      },
    },
  },
};

export default createMuiTheme(theme);

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PermissionError from "@material-ui/icons/ErrorOutline";
import InternetError from "@material-ui/icons/WifiOffOutlined";
import PrivacyPolicy from "@material-ui/icons/WebAssetOutlined";
import DefaultError from "@material-ui/icons/BuildOutlined";
import Code404 from "@material-ui/icons/StorageOutlined";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import { i18n } from "../../i18n/i18nText";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import routes from "../../routes/routes";
import { useRecoilValue } from "recoil";
import { isInternetOnState } from "../../recoils/IsInternetOn";
import { coreHTTPClient } from "../../services/webclient";
import { acceptTermsStatus } from "../../recoils/AcceptedTermsRecoil";

const WhatsApp = require("../../assets/images/whatsapp.svg");

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 20px;
  }
`;
const Text = styled.div`
  text-align: center;
  color: gray;
  max-width: 30vw;

  h1 {
    margin-top: -8px;
  }

  @media (max-width: 1024px) {
    max-width: 250px;
  }
`;
const VerticalLine = styled.div`
  padding: 0,
  height: 25px;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
  border-right: 3px solid #44b86a;
`;

export const TYPE = {
  PERMISSION: "Permission Error",
  INTERNET: "Internet Error",
  POLICY: "Terms and Conditions not accepted",
  CODE404: "HTTP Error 404",
  DISABLED: "Farm temporarily deactivated",
  DEFAULT: "An unexpected problem has occurred",
};

interface Props {
  Type?: String;
}

function Params(type) {
  let descriptionText = null;
  let icon = null;
  let errorText = type;
  if (type === TYPE.PERMISSION) {
    icon = (
      <PermissionError
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = i18n("PAGE_NO_ACCESS_TO_FARMS");
  } else if (type === TYPE.INTERNET) {
    icon = (
      <InternetError
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = i18n("PAGE_NO_INTERNET");
  } else if (type === TYPE.POLICY) {
    icon = (
      <PrivacyPolicy
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = i18n("PAGE_PRIVACY_POLICY_NOT_ACCEPTED");
  } else if (type === TYPE.CODE404) {
    icon = (
      <Code404
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = i18n("PAGE_CODE_404");
  } else if ((type = TYPE.DISABLED)) {
    icon = (
      <DesktopAccessDisabledIcon
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = <>{i18n("FARM_DISABLED")}</>;
  } else {
    errorText = TYPE.DEFAULT;
    icon = (
      <DefaultError
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
    );
    descriptionText = i18n("PAGE_DEFAULT");
  }
  return {
    Icon: icon,
    DescriptionText: descriptionText,
    ErrorText: errorText,
  };
}

export function NoAccess(props: Props) {
  const farmID: string = useParams()?.farm;
  const isInternetOn = useRecoilValue(isInternetOnState);
  const isAcceptedTerms = useRecoilValue(acceptTermsStatus);
  async function handleClick(event) {
    event.preventDefault();
    window.location.href = routes.DASHBOARD.MAIN;
  }
  async function handleDisabledClick(event) {
    event.preventDefault();
    window.location.href = `${routes.DASHBOARD.EDIT_DISABLED_FARM.replace(
      ":farm",
      farmID
    )}/?tab=1`;
  }

  const params = Params(props.Type);

  return (
    <Container>
      {params.Icon}
      <Text style={{ fontSize: "25px" }}>{params.DescriptionText}</Text>
      <Button
        style={{ transform: "scale(1.2)", marginTop: "80px" }}
        variant="contained"
        disabled={!isInternetOn || !isAcceptedTerms}
        color="primary"
        onClick={
          props.Type == TYPE.DISABLED ? handleDisabledClick : handleClick
        }
      >
        {props.Type == TYPE.DISABLED
          ? i18n("BILLING_TAB_REDIRECT")
          : i18n("BACK_TO_INICIAL_PAGE")}
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "40px",
          flexWrap: "nowrap",
          maxWidth: "250px",
        }}
      >
        <VerticalLine />
        <Text>{params.ErrorText}</Text>
        <VerticalLine />
      </div>
    </Container>
  );
}

export default NoAccess;

import React, { useCallback, useEffect, useState } from "react";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../hooks/tools/useFormValidation";
import DraggableMap from "../../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import FormInputPosition from "../../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import { centerMarkerSelector } from "../../../../../../recoils/DraggableMapRecoil";
import QrReader from "modern-react-qr-reader";
import { isMobile } from "../../../../../../mobileConfig";
import { useDispatch } from "react-redux";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import { Box, Icon, TextField, Tooltip } from "@material-ui/core";
import { showLocationModal } from "../../../../../../redux/locationModal/actions";

const blueDot = require("../../../../../../assets/images/markers/light-blue-dot.svg");

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

let globalResolve: (data: string) => void;

let globalReject: (data: string) => void;

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  createFarmState: [any, (value: any) => void];
  isCreating?: boolean;
}

export default function FarmLocationStep(props: Props) {
  const {
    validationErrors,
    formValidationActions,
    createFarmState,
    isCreating,
  } = props;

  const [showQrReader, setShowQrReader] = useState(false);

  const [radioId, setRadioId] = useState("");

  const [lat, setLat] = useState(createFarmState[0].location.split(",")[0]);

  const [lng, setLng] = useState(createFarmState[0].location.split(",")[1]);

  const dispatch = useDispatch();

  const [event, setEvent] = useState(false);

  const [farmCoordsError, setFarmCoordsError] = useState(false);

  useEffect(() => {
    createFarmState[1]({ ...createFarmState[0], location: `${lat},${lng}` });
  }, [lat, lng]);

  const setValidLat = useCallback(
    (value) => {
      setLat(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // lng
  const setValidLng = useCallback(
    (value) => {
      setLng(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setLat}
                setLongitude={setLng}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={blueDot} />
    </Button3D>
  );

  useEffect(() => {
    setRadioId(createFarmState[0].base.toUpperCase());
  }, [createFarmState[0].base]);

  async function showQrReaderWithMode(mode: "RADIO_ID") {
    setShowQrReader(true);

    const data = await new Promise<string>((resolve) => {
      globalResolve = resolve;
    });

    window.alert(`${i18n("CREATE_DEVICE_MODAL_CODE_READ_SUCCESS")}\n${data}`);

    setShowQrReader(false);

    if (mode == "RADIO_ID") {
      setRadioId(data.trim().toUpperCase());
    }

    document.getElementById("radio-central-farm").focus();
  }

  useEffect(() => {
    if (farmCoordsError) {
      formValidationActions.setError("lat", "ERROR_PIVOT_LAT_AND_LONG");
      formValidationActions.setError("lng", "ERROR_PIVOT_LAT_AND_LONG");
    } else {
      formValidationActions.clearError("lat");
      formValidationActions.clearError("lng");
    }
  }, [farmCoordsError]);

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <h3
        style={{
          marginBottom: "50px",
        }}
      >
        {i18n("FARM_LOCATION_INFORMATION_TITLE")}
      </h3>

      <div
        className="general-form__row-6-6"
        style={{ margin: "20px 0px 10px 0px", alignItems: "center" }}
      >
        <FormInputPosition
          setError={setFarmCoordsError}
          id="farm-config-position"
          markerUrl={markerButton()}
          label={
            createFarmState[0].central_linked
              ? "PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL"
              : "FARM_LOCATION"
          }
          lat={[lat, setValidLat]}
          lng={[lng, setValidLng]}
          recoilSelector={centerMarkerSelector}
        />

        {createFarmState[0].central_linked && (
          <div className="general-form__row-3">
            <div className="device-row">
              <div
                style={{
                  backgroundColor: "#eee",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "100%",
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" flexDirection="column" width="100%">
                    <div style={{ fontSize: "14px", fontWeight: 600 }}>
                      {i18n("CENTRAL_RADIO")}
                    </div>

                    <div className="device-row mb-30">
                      <div className="input">
                        <TextField
                          value={radioId.toUpperCase()}
                          onChange={(event) =>
                            createFarmState[1]({
                              ...createFarmState[0],
                              base: event.target.value.toUpperCase(),
                            })
                          }
                          InputProps={{
                            endAdornment: isMobile() ? (
                              <div
                                className="camera-button"
                                onClick={async () => {
                                  showQrReaderWithMode("RADIO_ID");
                                }}
                              >
                                <Tooltip title={i18n("MOBILE_ONLY_INFO")}>
                                  <Icon
                                    style={{
                                      opacity: 0.85,
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {" "}
                                    qr_code_scanner{" "}
                                  </Icon>
                                </Tooltip>
                              </div>
                            ) : null,
                          }}
                          error={
                            createFarmState[0].base.length != 16 ? true : false
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="edit-farm-form__map">
        <DraggableMap
          zoomScroll={false}
          _setExperimentalRecoil
          markers={[
            {
              lat: {
                state: lat,
                setFunction: setValidLat,
              },
              lng: {
                state: lat,
                setFunction: setValidLng,
              },
              markerUrl: blueDot,
              key: "center",
              event: {
                state: event,
                setFunction: setEvent,
              },
            },
          ]}
        />
      </div>
      {showQrReader && (
        <div className="qr-reader-root">
          <div className="close-button" onClick={() => setShowQrReader(false)}>
            X
          </div>

          <QrReader
            delay={300}
            facingMode={"environment"}
            onError={(data) => globalReject && globalReject(data)}
            onScan={(data) => globalResolve && data && globalResolve(data)}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

import { nanoid } from "nanoid";
import { atom, selector } from "recoil";

//Referência global dos markers do mapa Draggable, assim, mantenho eles com performance
let draggableMarkerMap: { [uuid: string]: { marker: google.maps.Marker } } = {};

export function addMarkerToGlobalMap(uuid: string, marker: google.maps.Marker) {
  draggableMarkerMap[uuid] = { marker };
}

export function deleteMarkerFromGlobalMap(uuid: string) {
  delete draggableMarkerMap[uuid];
}

export function getMarkerFromGlobalMap(
  uuid: string
): { marker: google.maps.Marker } | undefined {
  return draggableMarkerMap[uuid];
}

//Recoils

/**
 * TODO
 *
 * Não são só 3 markers, por isso, trocar os ATOMS e SELECTORS por seus respectivos FAMILIES (factory de atoms).
 *
 * Deixando assim por agora pra segurar os paus
 */

export interface MarkerState {
  lat: string;
  lng: string;
  uuid: string;
  isDragging?: boolean;
}

export const centerMarkerState = atom<MarkerState | null>({
  key: "centerMarker",
  default: null,
});

export const initialMarkerState = atom<MarkerState | null>({
  key: "initialMarker",
  default: null,
});

export const referenceMarkerState = atom<MarkerState | null>({
  key: "referenceMarker",
  default: null,
});

export const draggableMapEditState = atom<any | null>({
  key: "draggableMapEdit",
  default: null,
});

export const isSetorialPivotState = atom<boolean | null>({
  key: "isSetorialPivot",
  default: null,
});

export const isNorthReferenceState = atom<boolean | null>({
  key: "isNorthReference",
  default: null,
});

//Selectors para propagar as mudanças dos inputs sem causar re-render!

export const centerMarkerSelector = selector<MarkerState | null>({
  key: "centerMarkerSelector",
  get: ({ get }) => {
    return get(centerMarkerState);
  },
  //Set especial, pois atualiza o mapa sem rerender, ele só acontece nos inputs
  set: ({ set, get }, newValue: MarkerState) => {
    let centerMarkerCurrentState = get(centerMarkerState);
    if (centerMarkerCurrentState == null) {
      centerMarkerCurrentState = {} as MarkerState;
      centerMarkerCurrentState.uuid = nanoid();
    }
    let centerMarkerRef = getMarkerFromGlobalMap(centerMarkerCurrentState.uuid);

    if (centerMarkerRef?.marker && newValue) {
      centerMarkerRef.marker.setPosition({
        lat: parseFloat(newValue.lat),
        lng: parseFloat(newValue.lng),
      });
    }

    return set(centerMarkerState, newValue);
  },
});

export const initialMarkerSelector = selector<MarkerState | null>({
  key: "initialMarkerSelector",
  get: ({ get }) => {
    return get(initialMarkerState);
  },
  //Set especial, pois atualiza o mapa sem rerender, ele só acontece nos inputs
  set: ({ set, get }, newValue: MarkerState) => {
    let initialMarkerCurrentState = get(initialMarkerState);
    if (initialMarkerCurrentState == null) {
      initialMarkerCurrentState = {} as MarkerState;
      initialMarkerCurrentState.uuid = nanoid();
    }
    let initialMarkerRef = getMarkerFromGlobalMap(
      initialMarkerCurrentState.uuid
    );

    if (initialMarkerRef?.marker && newValue) {
      initialMarkerRef.marker.setPosition({
        lat: parseFloat(newValue.lat),
        lng: parseFloat(newValue.lng),
      });
    }

    return set(initialMarkerState, newValue);
  },
});

export const referenceMarkerSelector = selector<MarkerState | null>({
  key: "referenceMarkerSelector",
  get: ({ get }) => {
    return get(referenceMarkerState);
  },
  //Set especial, pois atualiza o mapa sem rerender, ele só acontece nos inputs
  set: ({ set, get }, newValue: MarkerState) => {
    let referenceMarkerCurrentState = get(referenceMarkerState);
    if (referenceMarkerCurrentState == null) {
      referenceMarkerCurrentState = {} as MarkerState;
      referenceMarkerCurrentState.uuid = nanoid();
    }
    let referenceMarkerRef = getMarkerFromGlobalMap(
      referenceMarkerCurrentState?.uuid
    );

    if (referenceMarkerRef?.marker && newValue) {
      referenceMarkerRef.marker.setPosition({
        lat: parseFloat(newValue.lat),
        lng: parseFloat(newValue.lng),
      });
    }

    return set(referenceMarkerState, newValue);
  },
});

import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { arrUpdateOrInsert, objIsEmpty } from "../../utils/types";
import useNotify from "../tools/useNotify";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { Repeater, RepeaterState } from "../../recoils/RepeaterRecoil";
import routes from "../../routes/routes";

interface RepeaterActions {
  get: () => {};
  // patch: (irpdConfig: any, patchObject: any) => void;
}

const useRepeater = (
  farmID: number,
  repeaterID: number
): [AsyncStatus, Repeater | undefined, RepeaterActions] => {
  const navigate = useNavigate();

  const [async, initLoading, finishLoading] = useAsyncStatus();

  const notify = useNotify();

  const [repeater, setRepeater] = useRecoilState(RepeaterState);

  async function getRepeater() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/repeaters/${repeaterID}/`
        );

        if (checkExists(["data", response, "__cascade"])) {
          setRepeater(response.data);
        }
      } catch (err) {
        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        }
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(() => {
    getRepeater();
    // eslint-disable-next-line
  }, []);

  return [
    async,
    repeater,
    {
      get: getRepeater,
    },
  ];
};

export default useRepeater;

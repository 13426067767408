import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ZendeskChatController() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  let location = useLocation();

  /**
   * Append script tag as last element in body tag if this script tag doesn't exist yet
   */
  const loadScript = () => {
    const iframeScript = document.getElementById("ze-snippet");

    if (!iframeScript) {
      let script = document.createElement("script");
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=0c1ede9e-6403-41b0-86ed-5d3ccf644618";
      script.setAttribute("id", "ze-snippet");
      script.type = "text/javascript";
      document.body.append(script);
    }
  };

  /**
   * Don't load script if it is already loaded or in login screen
   */
  useEffect(() => {
    if (
      !location.pathname.includes("/login") &&
      !location.pathname.includes("/forget-password") &&
      !location.pathname.includes("/edit") &&
      !location.pathname.includes("/notifications") &&
      !location.pathname.includes("/auth/register") &&
      !scriptLoaded
    ) {
      loadScript();
      setScriptLoaded(true);
    }
  }, [scriptLoaded, location.pathname]);

  /**
   * Hide chat by zindex in pages login, forgot password, edit and notifications, otherwise show
   */
  useEffect(() => {
    if (
      location.pathname.includes("/login") ||
      location.pathname.includes("/forget-password") ||
      location.pathname.includes("/edit") ||
      location.pathname.includes("/notifications") ||
      location.pathname.includes("/auth/register")
    ) {
      // @ts-ignore
      if (window.zE) {
        // @ts-ignore
        window.zE("messenger:set", "zIndex", -1);
      }
    } else {
      // @ts-ignore
      if (window.zE) {
        // @ts-ignore
        window.zE("messenger:set", "zIndex", 999);
      }
    }
  }, [location.pathname]);

  return null;
}

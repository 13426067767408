import React from "react";
import styled from "@emotion/styled";
import { CancelOutlined } from "@material-ui/icons";
import { i18n } from "../../../i18n/i18nText";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import routes from "../../../routes/routes";
import { useRecoilValue } from "recoil";
import { isInternetOnState } from "../../../recoils/IsInternetOn";
import { acceptTermsStatus } from "../../../recoils/AcceptedTermsRecoil";

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 20px;
  }
`;
const Text = styled.div`
  text-align: center;
  color: gray;
  max-width: 30vw;

  h1 {
    margin-top: -8px;
  }

  @media (max-width: 1024px) {
    max-width: 250px;
  }
`;
const VerticalLine = styled.div`
  padding: 0,
  height: 25px;
  border-radius: 5px;
  margin: 0px 10px 0px 10px;
  border-right: 3px solid #44b86a;
`;

export function BillingCancel() {
  const isInternetOn = useRecoilValue(isInternetOnState);
  const isAcceptedTerms = useRecoilValue(acceptTermsStatus);

  async function handleClick(event) {
    event.preventDefault();
    window.location.href = `${routes.DASHBOARD.MAIN}`;
  }

  return (
    <Container>
      <CancelOutlined
        style={{ fontSize: "180px", opacity: 0.5, marginBottom: "30px" }}
      />
      <Text style={{ fontSize: "25px" }}>{i18n("CANCEL_PAGE_TEXT")}</Text>
      <Button
        style={{ transform: "scale(1.2)", marginTop: "80px" }}
        variant="contained"
        disabled={!isInternetOn || !isAcceptedTerms}
        color="primary"
        onClick={handleClick}
      >
        {i18n("BACK_TO_INICIAL_PAGE")}
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "40px",
          flexWrap: "nowrap",
          maxWidth: "250px",
        }}
      >
        <VerticalLine />
        <Text>{i18n("CANCEL")}</Text>
        <VerticalLine />
      </div>
    </Container>
  );
}

export default BillingCancel;

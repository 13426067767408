import React, { useState, useCallback, useEffect } from "react";
import "./SegmentSpeedInputs.scss";
import { Pivot } from "../../../../../../../../../../redux/pivots/types";
import {
  PTPToMillimeter,
  mmToPTP,
} from "../../../../../../../../../../utils/models/pivots";
import { formatPositiveFloat } from "../../../../../../../../../../utils/checks";
import FormInput from "../../../../../../../../../../components/Form/FormInput/FormInput";

interface Props {
  pivot: Pivot;
  disabled?: boolean;
  currentIndex: number;
  percentage: string;
  setPercentageArray: (value: any) => void;
}

function SegmentSpeedInputs(props: Props) {
  const { pivot, currentIndex, percentage, setPercentageArray } = props;

  const [millimeter, setMillimeter] = useState<any>(
    PTPToMillimeter(pivot, parseFloat(percentage))
  );

  useEffect(() => {
    setMillimeter(PTPToMillimeter(pivot, parseFloat(percentage)));
  }, [percentage]);

  const setValidMilimeter = function (value: string) {
    if (value.length <= 8)
      setMillimeter(formatPositiveFloat(value.replace(/[".",","]\D/g, "")));
    if (
      mmToPTP(pivot, Number(value)).toString() &&
      parseFloat(mmToPTP(pivot, Number(value)).toString()) > 0 &&
      parseFloat(mmToPTP(pivot, Number(value)).toString()) <= 100
    ) {
      setPercentageArray((prevArray) => {
        // Se o array for o mesmo, não dar spread
        if (
          prevArray[currentIndex] === mmToPTP(pivot, Number(value)).toString()
        )
          return prevArray;

        prevArray[currentIndex] = mmToPTP(pivot, Number(value)).toString();

        return [...prevArray];
      });
    }
  };

  const setValidPercentage = function (value: string) {
    if (value === "") {
      // setPercentageArray(tempPercentageArray);
      setPercentageArray((prevArray) => {
        prevArray[currentIndex] = "";

        return [...prevArray];
      });
      setMillimeter("");
    } else if (value && parseFloat(value) > 0 && parseFloat(value) <= 100) {
      setPercentageArray((prevArray) => {
        // Se o array for o mesmo, não dar spread
        if (prevArray[currentIndex] === value.replace(/\D/g, ""))
          return prevArray;

        prevArray[currentIndex] = value.replace(/\D/g, "");

        return [...prevArray];
      });

      setMillimeter(String(PTPToMillimeter(pivot, parseInt(value))));
    }
  };

  return (
    <div className="container">
      <FormInput
        id={`percentage${currentIndex}`}
        state={[percentage, setValidPercentage]}
        label={"PIVOT_ACTION_FIELD_PERCENT"}
        inputMode="numeric"
        endAdornment="%"
        min="1"
        max="100"
        helperText={!percentage ? "ERROR_EMPTY_FIELD" : undefined}
      />
      <FormInput
        id={`millimeterArray${currentIndex}`}
        state={[millimeter, setValidMilimeter]}
        label={"PIVOT_ACTION_FIELD_PRECIPITATION"}
        inputMode="numeric"
        endAdornment="mm"
      />
    </div>
  );
}

export default SegmentSpeedInputs;

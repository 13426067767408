import React from "react";
import { ChildrenProps } from "../../utils/types";
import "./CustomMarker.scss";

export interface CustomMarkerProps {
  lat: number;
  lng: number;
}

function CustomMarker(props: CustomMarkerProps & ChildrenProps & any) {
  return <div className="custom-marker">{props.children}</div>;
}

export default CustomMarker;

import { Action } from "redux";
import { UserTypes } from "./types";
import { action } from "typesafe-actions";

export interface AddUserID extends Action {
  type: UserTypes.ADD_USERID;
  payload: {
    userID: number;
  };
}

export interface ClearUserID extends Action {
  type: UserTypes.CLEAR_USERID;
  payload: {};
}

export const addUserID = (userID: number, reseller: boolean): AddUserID =>
  action(UserTypes.ADD_USERID, { userID, reseller });

export const clearUserID = (): ClearUserID =>
  action(UserTypes.CLEAR_USERID, {});

export type UserActions = AddUserID;

import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { AddAlertOutlined, Delete, Edit } from "@material-ui/icons";
import {
  Box,
  Button,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@material-ui/core";
import NotificationSteps from "./NotificationSteps";
import { hideModal, showModal } from "../../../../redux/modal/actions";
import { useDispatch } from "react-redux";
import IOSSwitch from "../../components/IOSSwitch/IOSSwitch";
import { isMobile } from "../../../../mobileConfig";
import { NotificationType } from "../Notifications";
import { i18n } from "../../../../i18n/i18nText";
import { Notification } from "../../../../redux/notifications/types";
import moment from "moment";
import { NotificationHookActions } from "../../../../hooks/models/useNotifications";
import { Reasons } from "../../../../hooks/models/useReasons";
import { Devices } from "../../../../hooks/models/useDevices";
import SmsFailedOutlinedIcon from "@material-ui/icons/SmsFailedOutlined";

interface Props {
  farmID: number;
  type: NotificationType;
  notificationID?: number;
  notification?: Notification;
  devices: Devices[];
  reasons: Reasons[];
  criticalReasons: Reasons[];
  actions: NotificationHookActions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: "10px 0px",
    },
    root: {
      minHight: "200px",
      width: isMobile() ? "100%" : "700px",
    },
    media: {
      paddingTop: "56.25%",
    },
    avatar: {
      backgroundColor: red[500],
    },
    action: {
      flexWrap: "wrap",
    },
    header: {
      display: "flex",
      padding: "16px",
      alignItems: "flex-start",
      flexDirection: isMobile() ? "column" : "row",
      minHight: "200px",
      flexGrow: 1,
    },
    listItem: {
      padding: "0px 8px",
    },
    content: {
      margin: isMobile() ? "15px" : "30px",
      maxWidth: "500px",
    },
    buttonsDesktop: {
      display: "flex",
      alignItems: " center",
      justifyContent: "space-evenly",
      margin: "15px",
      fontColor: "white",
    },
    buttonsMobile: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "15px",
      color: "white",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
    },
    button: {
      width: "100px",
    },
    instructions: {
      display: "flex",
      alignItems: " center",
      justifyContent: "center",
      height: "150px",
    },
    label: {
      "& .MuiFormControlLabel-label": {
        fontSize: "1rem",
        width: 300,
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
  })
);

export default function NotificationsCard(props: Props) {
  const {
    farmID,
    type,
    notificationID,
    notification,
    devices,
    reasons,
    criticalReasons,
    actions,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkedEnable, setCheckedEnable] = useState(notification.enable);
  const [expanded, setExpanded] = useState(false);
  const [showReasons, setShowReasons] = useState(false);
  const [currentDevices, setCurrentDevices] = useState<Devices[]>([]);
  const [currentReasons, setCurrentReasons] = useState<Reasons[]>([]);
  const [currentCriticalReasons, setCurrentCriticalReasons] = useState<
    Reasons[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile()) setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  useEffect(() => {
    //here mode will always be edit, therefore the devices need to be all the same protocol
    if (farmID && notification && notification.devices && devices) {
      let protocol = devices.find(
        (device) => device.id == notification.devices[0]
      )?.protocol;
      let devicesList = devices.filter((device) => device.protocol == protocol);
      let reasonsList = reasons.filter((reason) => reason.protocol == protocol);
      let criticalReasonsList = criticalReasons.filter(
        (criticalReason) => criticalReason.protocol == protocol
      );
      setCurrentDevices(devicesList);
      setCurrentReasons(reasonsList);
      setCurrentCriticalReasons(criticalReasonsList);
    }
  }, [farmID, notification]);

  const handleChangeEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    actions.patch(type, notificationID, { enable: event.target.checked });
    setCheckedEnable(event.target.checked);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setShowReasons(!showReasons);
  };

  const handleClose = () => {
    dispatch(hideModal());
  };

  const handleDelete = () => {
    actions.delete(type, notification);
    dispatch(hideModal());
  };

  const getDateInterval = () => {
    if (notification.start && notification.end) {
      if (
        moment(notification.start, "HH:mm:ss").format("HH:mm") === "00:00" &&
        moment(notification.end, "HH:mm:ss").format("HH:mm") === "23:59"
      ) {
        return i18n("CREATE_NOTIFICATIONS_ALL_DAY");
      } else
        return `${moment(notification.start, "HH:mm:ss").format(
          "HH:mm"
        )} > ${moment(notification.end, "HH:mm:ss").format("HH:mm")}`;
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          classes={{ root: classes.header, action: classes.action }}
          action={
            <>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  dispatch(
                    showModal({
                      content: (
                        <NotificationSteps
                          farmID={farmID}
                          type={type}
                          mode={"edit"}
                          notificationID={notificationID}
                          notification={notification}
                          devices={currentDevices}
                          reasons={currentReasons}
                          actions={actions}
                        />
                      ),
                      title: "EDIT_NOTIFICATIONS",
                    })
                  );
                }}
              >
                <Edit style={{ fontSize: "25px" }} />
              </IconButton>

              <IconButton aria-label="delete">
                <Delete
                  style={{ fontSize: "25px" }}
                  onClick={() => {
                    dispatch(
                      showModal({
                        content: (
                          <>
                            <div className={classes.content}>
                              <Typography className={classes.instructions}>
                                {i18n("DELETE_NOTIFICATIONS_QUESTION")}
                              </Typography>
                            </div>

                            <div
                              className={
                                isMobile()
                                  ? classes.buttonsMobile
                                  : classes.buttonsDesktop
                              }
                            >
                              <Button
                                className={classes.button}
                                variant="contained"
                                color="inherit"
                                onClick={handleClose}
                              >
                                {i18n("DELETE_NOTIFICATIONS_CANCEL")}
                              </Button>

                              <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={handleDelete}
                              >
                                {i18n("DELETE_NOTIFICATIONS_DELETE")}
                              </Button>
                            </div>
                          </>
                        ),
                        title: "DELETE_NOTIFICATIONS",
                      })
                    );
                  }}
                />
              </IconButton>

              <IconButton aria-label="enable">
                <IOSSwitch
                  checked={checkedEnable}
                  onChange={handleChangeEnable}
                  name="checkedEnable"
                />
              </IconButton>
            </>
          }
          titleTypographyProps={{ variant: "h6" }}
          title={notification.name}
          subheader={getDateInterval()}
        />

        <CardContent>
          <div>
            {devices.map(
              (device) =>
                notification.devices.includes(device.id) && (
                  <Box
                    color="gray"
                    component="span"
                    m={1}
                    border={"1px solid gray"}
                    borderRadius={10}
                    padding={"0px 5px"}
                    display={"inline-block"}
                  >
                    {device.name}
                  </Box>
                )
            )}
          </div>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Divider />
            <div style={{ marginTop: "25px" }}>
              <Typography variant="body2" color="textSecondary" component="p">
                <List component="span" aria-label="reason reason">
                  {reasons &&
                    reasons.map(
                      (reason) =>
                        notification.reasons.includes(reason.id) && (
                          <ListItem className={classes.listItem}>
                            <ListItemText primary={reason.label} />
                            <ListItemIcon
                              title={i18n(
                                "CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO"
                              )}
                              aria-label={i18n(
                                "CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO"
                              )}
                            >
                              {notification.critical_reasons.includes(
                                reason.id
                              ) && (
                                <IconButton
                                  aria-describedby={id}
                                  onClick={handleClick}
                                >
                                  <SmsFailedOutlinedIcon
                                    style={{
                                      boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                                      color: "#f55348",
                                      backgroundColor: "#fdecea",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      scale: "1.1",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </ListItemIcon>
                            <Popover
                              id={id}
                              open={openPopover}
                              anchorEl={anchorEl}
                              onClose={handleClosePopover}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                            >
                              <Typography className={classes.label}>
                                {i18n(
                                  "CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO"
                                )}
                              </Typography>
                            </Popover>
                          </ListItem>
                        )
                    )}
                </List>
              </Typography>
            </div>
          </CardContent>
        </Collapse>

        <CardActions disableSpacing>
          <Button
            onClick={handleExpandClick}
            aria-label="show more"
            type="button"
            variant="outlined"
            color="secondary"
          >
            {showReasons === false
              ? i18n("SHOW_REASONS")
              : i18n("HIDE_REASONS")}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styled from "@emotion/styled";
import { motion, AnimatePresence } from "framer-motion";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";

const AbsoluteDiv = styled(motion.div)`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;

  background-color: #00000077;

  z-index: 99999;

  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  white-space: pre-line;
`;

const StyledButtonNext = styled(Button)`
  background-color: #2196f3 !important;
  color: #fff !important;

  &:hover {
    background-color: #06467a !important;
  }
`;

const StyledButtonCancel = styled(Button)`
  background-color: #9b9b9b !important;
  color: #fff !important;

  &:hover {
    background-color: #6e6e6e !important;
  }
`;

const ModalBox = styled(motion.div)`
  padding: 30px;

  background-color: white;

  display: grid;

  grid-template-areas:
    "Text Text"
    "Input Input"
    "Cancel Next";

  grid-row-gap: 30px;
  grid-column-gap: 20px;
`;

const TextArea = styled.div`
  grid-area: Text;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputArea = styled.div`
  grid-area: Input;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelArea = styled.div`
  grid-area: Cancel;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const NextArea = styled.div`
  grid-area: Next;

  display: flex;
  justify-content: center;
  align-items: center;
`;

//

interface Props {
  openModal: boolean;
  setModal: any;
  text: string;
  childToParent: any;
  handleNext: any;
  reason: string;
}

function MaintenanceModalSpec(props: Props) {
  let { openModal, setModal, text, childToParent, handleNext, reason } = props;

  const [modalData, setModalData] = useState(i18n("MAINTENANCE_SPEC_NONE"));

  return (
    <AnimatePresence>
      {openModal && (
        <AbsoluteDiv
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalBox initial={{ y: 1000 }} animate={{ y: 0 }} exit={{ y: 1000 }}>
            <TextArea>{i18n(text as i18nTextId)}</TextArea>

            <InputArea>
              {reason === i18n("MAINTENANCE_REASON_NONE") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [i18n("MAINTENANCE_SPEC_NONE"), "MAINTENANCE_SPEC_NONE"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_01") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_01"),
                      "MAINTENANCE_SPEC_NONE_01",
                    ],
                    [i18n("MAINTENANCE_SPEC_01A"), "MAINTENANCE_SPEC_01A"],
                    [i18n("MAINTENANCE_SPEC_01B"), "MAINTENANCE_SPEC_01B"],
                    [i18n("MAINTENANCE_SPEC_01C"), "MAINTENANCE_SPEC_01C"],
                    [i18n("MAINTENANCE_SPEC_01D"), "MAINTENANCE_SPEC_01D"],
                    [i18n("MAINTENANCE_SPEC_01E"), "MAINTENANCE_SPEC_01E"],
                    [i18n("MAINTENANCE_SPEC_01F"), "MAINTENANCE_SPEC_01F"],
                    [i18n("MAINTENANCE_SPEC_01G"), "MAINTENANCE_SPEC_01G"],
                    [i18n("MAINTENANCE_SPEC_01H"), "MAINTENANCE_SPEC_01H"],
                    [i18n("MAINTENANCE_SPEC_01I"), "MAINTENANCE_SPEC_01I"],
                    [i18n("MAINTENANCE_SPEC_01J"), "MAINTENANCE_SPEC_01J"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_02") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_02"),
                      "MAINTENANCE_SPEC_NONE_02",
                    ],
                    [i18n("MAINTENANCE_SPEC_02A"), "MAINTENANCE_SPEC_02A"],
                    [i18n("MAINTENANCE_SPEC_02B"), "MAINTENANCE_SPEC_02B"],
                    [i18n("MAINTENANCE_SPEC_02C"), "MAINTENANCE_SPEC_02C"],
                    [i18n("MAINTENANCE_SPEC_02D"), "MAINTENANCE_SPEC_02D"],
                    [i18n("MAINTENANCE_SPEC_02E"), "MAINTENANCE_SPEC_02E"],
                    [i18n("MAINTENANCE_SPEC_02F"), "MAINTENANCE_SPEC_02F"],
                    [i18n("MAINTENANCE_SPEC_02G"), "MAINTENANCE_SPEC_02G"],
                    [i18n("MAINTENANCE_SPEC_02H"), "MAINTENANCE_SPEC_02H"],
                    [i18n("MAINTENANCE_SPEC_02I"), "MAINTENANCE_SPEC_02I"],
                    [i18n("MAINTENANCE_SPEC_02J"), "MAINTENANCE_SPEC_02J"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_03") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_03"),
                      "MAINTENANCE_SPEC_NONE_03",
                    ],
                    [i18n("MAINTENANCE_SPEC_03A"), "MAINTENANCE_SPEC_03A"],
                    [i18n("MAINTENANCE_SPEC_03B"), "MAINTENANCE_SPEC_03B"],
                    [i18n("MAINTENANCE_SPEC_03C"), "MAINTENANCE_SPEC_03C"],
                    [i18n("MAINTENANCE_SPEC_03D"), "MAINTENANCE_SPEC_03D"],
                    [i18n("MAINTENANCE_SPEC_03E"), "MAINTENANCE_SPEC_03E"],
                    [i18n("MAINTENANCE_SPEC_03F"), "MAINTENANCE_SPEC_03F"],
                    [i18n("MAINTENANCE_SPEC_03G"), "MAINTENANCE_SPEC_03G"],
                    [i18n("MAINTENANCE_SPEC_03H"), "MAINTENANCE_SPEC_03H"],
                    [i18n("MAINTENANCE_SPEC_03I"), "MAINTENANCE_SPEC_03I"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_04") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_04"),
                      "MAINTENANCE_SPEC_NONE_04",
                    ],
                    [i18n("MAINTENANCE_SPEC_04A"), "MAINTENANCE_SPEC_04A"],
                    [i18n("MAINTENANCE_SPEC_04B"), "MAINTENANCE_SPEC_04B"],
                    [i18n("MAINTENANCE_SPEC_04C"), "MAINTENANCE_SPEC_04C"],
                    [i18n("MAINTENANCE_SPEC_04D"), "MAINTENANCE_SPEC_04D"],
                    [i18n("MAINTENANCE_SPEC_04E"), "MAINTENANCE_SPEC_04E"],
                    [i18n("MAINTENANCE_SPEC_04F"), "MAINTENANCE_SPEC_04F"],
                    [i18n("MAINTENANCE_SPEC_04G"), "MAINTENANCE_SPEC_04G"],
                    [i18n("MAINTENANCE_SPEC_04H"), "MAINTENANCE_SPEC_04H"],
                    [i18n("MAINTENANCE_SPEC_04I"), "MAINTENANCE_SPEC_04I"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_05") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_05"),
                      "MAINTENANCE_SPEC_NONE_05",
                    ],
                    [i18n("MAINTENANCE_SPEC_05A"), "MAINTENANCE_SPEC_05A"],
                    [i18n("MAINTENANCE_SPEC_05B"), "MAINTENANCE_SPEC_05B"],
                    [i18n("MAINTENANCE_SPEC_05C"), "MAINTENANCE_SPEC_05C"],
                    [i18n("MAINTENANCE_SPEC_05D"), "MAINTENANCE_SPEC_05D"],
                    [i18n("MAINTENANCE_SPEC_05E"), "MAINTENANCE_SPEC_05E"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_06") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_06"),
                      "MAINTENANCE_SPEC_NONE_06",
                    ],
                    [i18n("MAINTENANCE_SPEC_06A"), "MAINTENANCE_SPEC_06A"],
                    [i18n("MAINTENANCE_SPEC_06B"), "MAINTENANCE_SPEC_06B"],
                    [i18n("MAINTENANCE_SPEC_06C"), "MAINTENANCE_SPEC_06C"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_07") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_07"),
                      "MAINTENANCE_SPEC_NONE_07",
                    ],
                    [i18n("MAINTENANCE_SPEC_07A"), "MAINTENANCE_SPEC_07A"],
                    [i18n("MAINTENANCE_SPEC_07B"), "MAINTENANCE_SPEC_07B"],
                    [i18n("MAINTENANCE_SPEC_07C"), "MAINTENANCE_SPEC_07C"],
                    [i18n("MAINTENANCE_SPEC_07D"), "MAINTENANCE_SPEC_07D"],
                    [i18n("MAINTENANCE_SPEC_07E"), "MAINTENANCE_SPEC_07E"],
                    [i18n("MAINTENANCE_SPEC_07F"), "MAINTENANCE_SPEC_07F"],
                    [i18n("MAINTENANCE_SPEC_07G"), "MAINTENANCE_SPEC_07G"],
                    [i18n("MAINTENANCE_SPEC_07H"), "MAINTENANCE_SPEC_07H"],
                    [i18n("MAINTENANCE_SPEC_07I"), "MAINTENANCE_SPEC_07I"],
                    [i18n("MAINTENANCE_SPEC_07J"), "MAINTENANCE_SPEC_07J"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_08") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_08"),
                      "MAINTENANCE_SPEC_NONE_08",
                    ],
                    [i18n("MAINTENANCE_SPEC_08A"), "MAINTENANCE_SPEC_08A"],
                    [i18n("MAINTENANCE_SPEC_08B"), "MAINTENANCE_SPEC_08B"],
                    [i18n("MAINTENANCE_SPEC_08C"), "MAINTENANCE_SPEC_08C"],
                    [i18n("MAINTENANCE_SPEC_08D"), "MAINTENANCE_SPEC_08D"],
                    [i18n("MAINTENANCE_SPEC_08E"), "MAINTENANCE_SPEC_08E"],
                    [i18n("MAINTENANCE_SPEC_08F"), "MAINTENANCE_SPEC_08F"],
                    [i18n("MAINTENANCE_SPEC_08G"), "MAINTENANCE_SPEC_08G"],
                    [i18n("MAINTENANCE_SPEC_08H"), "MAINTENANCE_SPEC_08H"],
                    [i18n("MAINTENANCE_SPEC_08I"), "MAINTENANCE_SPEC_08I"],
                    [i18n("MAINTENANCE_SPEC_08J"), "MAINTENANCE_SPEC_08J"],
                    [i18n("MAINTENANCE_SPEC_08K"), "MAINTENANCE_SPEC_08K"],
                    [i18n("MAINTENANCE_SPEC_08L"), "MAINTENANCE_SPEC_08L"],
                    [i18n("MAINTENANCE_SPEC_08M"), "MAINTENANCE_SPEC_08M"],
                    [i18n("MAINTENANCE_SPEC_08N"), "MAINTENANCE_SPEC_08N"],
                    [i18n("MAINTENANCE_SPEC_08O"), "MAINTENANCE_SPEC_08O"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_09") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_09"),
                      "MAINTENANCE_SPEC_NONE_09",
                    ],
                    [i18n("MAINTENANCE_SPEC_09A"), "MAINTENANCE_SPEC_09A"],
                    [i18n("MAINTENANCE_SPEC_09B"), "MAINTENANCE_SPEC_09B"],
                    [i18n("MAINTENANCE_SPEC_09C"), "MAINTENANCE_SPEC_09C"],
                    [i18n("MAINTENANCE_SPEC_09D"), "MAINTENANCE_SPEC_09D"],
                    [i18n("MAINTENANCE_SPEC_09E"), "MAINTENANCE_SPEC_09E"],
                    [i18n("MAINTENANCE_SPEC_09F"), "MAINTENANCE_SPEC_09F"],
                    [i18n("MAINTENANCE_SPEC_09G"), "MAINTENANCE_SPEC_09G"],
                    [i18n("MAINTENANCE_SPEC_09H"), "MAINTENANCE_SPEC_09H"],
                    [i18n("MAINTENANCE_SPEC_09I"), "MAINTENANCE_SPEC_09I"],
                    [i18n("MAINTENANCE_SPEC_09J"), "MAINTENANCE_SPEC_09J"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_10") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_10"),
                      "MAINTENANCE_SPEC_NONE_10",
                    ],
                    [i18n("MAINTENANCE_SPEC_10A"), "MAINTENANCE_SPEC_10A"],
                    [i18n("MAINTENANCE_SPEC_10B"), "MAINTENANCE_SPEC_10B"],
                    [i18n("MAINTENANCE_SPEC_10C"), "MAINTENANCE_SPEC_10C"],
                    [i18n("MAINTENANCE_SPEC_10D"), "MAINTENANCE_SPEC_10D"],
                    [i18n("MAINTENANCE_SPEC_10E"), "MAINTENANCE_SPEC_10E"],
                    [i18n("MAINTENANCE_SPEC_10F"), "MAINTENANCE_SPEC_10F"],
                    [i18n("MAINTENANCE_SPEC_10G"), "MAINTENANCE_SPEC_10G"],
                    [i18n("MAINTENANCE_SPEC_10H"), "MAINTENANCE_SPEC_10H"],
                    [i18n("MAINTENANCE_SPEC_10I"), "MAINTENANCE_SPEC_10I"],
                    [i18n("MAINTENANCE_SPEC_10J"), "MAINTENANCE_SPEC_10J"],
                    [i18n("MAINTENANCE_SPEC_10K"), "MAINTENANCE_SPEC_10K"],
                    [i18n("MAINTENANCE_SPEC_10L"), "MAINTENANCE_SPEC_10L"],
                    [i18n("MAINTENANCE_SPEC_10M"), "MAINTENANCE_SPEC_10M"],
                    [i18n("MAINTENANCE_SPEC_10N"), "MAINTENANCE_SPEC_10N"],
                    [i18n("MAINTENANCE_SPEC_10O"), "MAINTENANCE_SPEC_10O"],
                    [i18n("MAINTENANCE_SPEC_10P"), "MAINTENANCE_SPEC_10P"],
                    [i18n("MAINTENANCE_SPEC_10Q"), "MAINTENANCE_SPEC_10Q"],
                    [i18n("MAINTENANCE_SPEC_10R"), "MAINTENANCE_SPEC_10R"],
                    [i18n("MAINTENANCE_SPEC_10S"), "MAINTENANCE_SPEC_10S"],
                    [i18n("MAINTENANCE_SPEC_10T"), "MAINTENANCE_SPEC_10T"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_11") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_11"),
                      "MAINTENANCE_SPEC_NONE_11",
                    ],
                    [i18n("MAINTENANCE_SPEC_11A"), "MAINTENANCE_SPEC_11A"],
                    [i18n("MAINTENANCE_SPEC_11B"), "MAINTENANCE_SPEC_11B"],
                    [i18n("MAINTENANCE_SPEC_11C"), "MAINTENANCE_SPEC_11C"],
                    [i18n("MAINTENANCE_SPEC_11D"), "MAINTENANCE_SPEC_11D"],
                    [i18n("MAINTENANCE_SPEC_11E"), "MAINTENANCE_SPEC_11E"],
                    [i18n("MAINTENANCE_SPEC_11F"), "MAINTENANCE_SPEC_11F"],
                    [i18n("MAINTENANCE_SPEC_11G"), "MAINTENANCE_SPEC_11G"],
                    [i18n("MAINTENANCE_SPEC_11H"), "MAINTENANCE_SPEC_11H"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_12") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_12"),
                      "MAINTENANCE_SPEC_NONE_12",
                    ],
                    [i18n("MAINTENANCE_SPEC_12A"), "MAINTENANCE_SPEC_12A"],
                    [i18n("MAINTENANCE_SPEC_12B"), "MAINTENANCE_SPEC_12B"],
                    [i18n("MAINTENANCE_SPEC_12C"), "MAINTENANCE_SPEC_12C"],
                    [i18n("MAINTENANCE_SPEC_12D"), "MAINTENANCE_SPEC_12D"],
                    [i18n("MAINTENANCE_SPEC_12E"), "MAINTENANCE_SPEC_12E"],
                    [i18n("MAINTENANCE_SPEC_12F"), "MAINTENANCE_SPEC_12F"],
                    [i18n("MAINTENANCE_SPEC_12G"), "MAINTENANCE_SPEC_12G"],
                    [i18n("MAINTENANCE_SPEC_12H"), "MAINTENANCE_SPEC_12H"],
                  ]}
                />
              ) : reason === i18n("MAINTENANCE_REASON_13") ? (
                <FormSelect
                  id="maintenance-modal-spec-select"
                  label={"MAINTENANCE_MODAL_SPEC_LABEL"} // Selecione uma especificação:
                  state={[modalData, setModalData]}
                  values={[
                    [
                      i18n("MAINTENANCE_SPEC_NONE_13"),
                      "MAINTENANCE_SPEC_NONE_13",
                    ],
                    [i18n("MAINTENANCE_SPEC_13A"), "MAINTENANCE_SPEC_13A"],
                    [i18n("MAINTENANCE_SPEC_13B"), "MAINTENANCE_SPEC_13B"],
                    [i18n("MAINTENANCE_SPEC_13C"), "MAINTENANCE_SPEC_13C"],
                    [i18n("MAINTENANCE_SPEC_13D"), "MAINTENANCE_SPEC_13D"],
                    [i18n("MAINTENANCE_SPEC_13E"), "MAINTENANCE_SPEC_13E"],
                    [i18n("MAINTENANCE_SPEC_13F"), "MAINTENANCE_SPEC_13F"],
                  ]}
                />
              ) : null}
            </InputArea>

            <CancelArea>
              <StyledButtonCancel
                onClick={() => {
                  setModal(false);
                }}
              >
                {i18n("MAINTENANCE_MODALS_BUTTON_CANCEL")}
              </StyledButtonCancel>
            </CancelArea>

            <NextArea>
              <StyledButtonNext
                onClick={() => {
                  childToParent(modalData);
                  handleNext(true);
                  setModal(false);
                }}
              >
                {i18n("MAINTENANCE_MODALS_BUTTON_NEXT")}
              </StyledButtonNext>
            </NextArea>
          </ModalBox>
        </AbsoluteDiv>
      )}
    </AnimatePresence>
  );
}

export default MaintenanceModalSpec;

import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import routes from "../routes";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../redux";
import AuthRouter from "../AuthRouter/AuthRouter";
import DashboardRouter from "../DashboardRouter/DashboardRouter";
import { Language } from "../../redux/setting/types";
import moment from "moment";
import "moment/locale/en-SG";
import "moment/locale/pt-br";
import "moment/locale/ru";
import "moment/locale/de";
import "moment/locale/es";
import SignUp from "../../pages/Auth/SignUp/SignUp";
import ForgetPassword from "../../pages/Auth/ForgetPassword/ForgetPassword";
import PasswordRecovery from "../../pages/Auth/ForgetPassword/PasswordRecovery/PasswordRecovery";
import { App } from "@capacitor/app";
import ExcelDownload from "../../pages/Auth/ExcelDownload/ExcelDownload";
import { useScrollToTopOnNavigate } from "../../utils/ScrollToTopHook";
import ZendeskChatController from "../../utils/ZendeskChatController";
import { SplashScreen } from "@capacitor/splash-screen";

const BaseRouter = () => {
  // Farms.
  const logged_in = useSelector(
    (state: ApplicationState) => state.auth.logged_in,
    shallowEqual
  );

  useScrollToTopOnNavigate();

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  let navigate = useNavigate();

  useEffect(() => {
    switch (language) {
      case Language.ENGLISH:
        moment.locale("en-SG");
        break;
      case Language.PORTUGUESE_BRAZIL:
        moment.locale("pt-br");
        break;
      case Language.RUSSIAN:
        moment.locale("ru");
        break;
      case Language.GERMAN_AUSTRIA:
        moment.locale("de-at");
        break;
      case Language.SPAIN_SPANISH:
        moment.locale("es");
        break;
      case Language.I18N_KEYS:
        moment.locale("keys");
        break;
      default:
    }
  }, [language]);

  return (
    <>
      {!logged_in ? (
        <Routes>
          <Route
            path={routes.AUTH.FORGET_PASSWORD}
            element={<ForgetPassword />}
          />
          <Route
            path={routes.AUTH.PASSWORD_RECOVERY}
            element={<PasswordRecovery />}
          />
          <Route path={routes.AUTH.SIGNUP} element={<SignUp />} />
          <Route path={"/*"} element={<AuthRouter />} />
          <Route
            path={routes.AUTH.PIVOT_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.IRPD_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.IRPD_EXCEL_DOWNLOAD_GH}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.METER_EXCEL_DOWNLOAD_GH}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.METER_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/firebase-messaging-sw.js" element={<PortalElement />} />
          <Route path="/logo192.png" element={<PortalElement />} />
          <Route
            path={routes.AUTH.PIVOT_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.IRPD_EXCEL_DOWNLOAD_GH}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.IRPD_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.METER_EXCEL_DOWNLOAD_GH}
            element={<ExcelDownload />}
          />
          <Route
            path={routes.AUTH.METER_EXCEL_DOWNLOAD}
            element={<ExcelDownload />}
          />
          <Route path={routes.AUTH.SIGNUP} element={<SignUp />} />
          <Route
            path={routes.AUTH.PASSWORD_RECOVERY}
            element={<PasswordRecovery />}
          />
          <Route path={"/*"} element={<DashboardRouter />} />
        </Routes>
      )}
      <ZendeskChatController />
    </>
  );
};

//Element to create a pass-through portal
function PortalElement() {
  window.location.reload();

  return null;
}

export default BaseRouter;

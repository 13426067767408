import React, { useState, useEffect } from "react";
import "./GeolocationGetter.scss";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch } from "react-redux";
import { Button, Icon } from "@material-ui/core";
import { RecoilState, useRecoilState } from "recoil";
import { MarkerState } from "../../recoils/DraggableMapRecoil";
import { nullAtom } from "../../recoils/NullRecoil";
import GpsFixedTwoToneIcon from "@material-ui/icons/GpsFixedTwoTone";
import { i18n } from "../../i18n/i18nText";
import { Geolocation } from "@capacitor/geolocation";
import { hideLocationModal } from "../../redux/locationModal/actions";

interface GeolocationOptions {
  enableHighAccuracy?: boolean;
  maximumAge?: number;
  timeout?: number;
}

interface Props {
  setLatitude: (latitude: any) => void;
  setLongitude: (longitude: any) => void;
  recoilSelector?: RecoilState<MarkerState>;
}

function GeolocationGetter(props: Props) {
  const dispatch = useDispatch();
  //Como está em fase de transição, estou adicionando um atom nulo que serve pra eu fazer a comparação
  let [latLng, setLatLng] = useRecoilState(props.recoilSelector || nullAtom);

  const [latitude, setLatitude] = useState<any>(undefined);
  const [longitude, setLongitude] = useState<any>(undefined);

  const [accuracyMeters, setAccuracyMeters] = useState<any>(undefined);

  const [id, setID] = useState(undefined);

  async function getHighAccuracyPosition() {
    const options: GeolocationOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
    };

    const id = await Geolocation.watchPosition(options, (position, err) => {
      if (position) {
        setAccuracyMeters(position.coords.accuracy);
        setLatitude(position.coords.latitude.toFixed(7));
        setLongitude(position.coords.longitude.toFixed(7));
      }
    });
    setID(id);
  }

  useEffect(() => {
    getHighAccuracyPosition();
  }, []);

  useEffect(() => {
    return () => {
      if (id) Geolocation.clearWatch({ id: id });
    };
  }, [id]);

  const saveLatitudeLongitude = () => {
    if (latitude && longitude) {
      props.setLatitude(latitude);
      props.setLongitude(longitude);

      //Implementação Recoil
      if (latLng) {
        setLatLng((ll) => ({ ...ll, lat: latitude, lng: longitude }));
      }
      Geolocation.clearWatch({ id: id });
      dispatch(hideLocationModal());
    }
  };

  return (
    <div className="geolocation-getter">
      <LinearProgress />
      <div className="geolocation-getter__container">
        <div className="geolocation-getter__wait-for-precision">
          {i18n("GEOLOCATION_GETTER_WAIT")}
        </div>
        <div className="geolocation-getter__grid-column-2-10">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon style={{ fontSize: "35px", opacity: 0.75 }}>pin_drop</Icon>
          </div>
          <div className="geolocation-getter__grid-row-1-1">
            <div className="geolocation-getter__label">
              {i18n("GEOLOCATION_GETTER_LATITUDE")}
            </div>
            <div className="geolocation-getter__value">
              {latitude !== undefined ? parseFloat(latitude)?.toFixed(7) : ""}
            </div>
          </div>
        </div>
        <div className="geolocation-getter__grid-column-2-10">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon style={{ fontSize: "35px", opacity: 0.75 }}>pin_drop</Icon>
          </div>
          <div className="geolocation-getter__grid-row-1-1">
            <div className="geolocation-getter__label">
              {i18n("GEOLOCATION_GETTER_LONGITUDE")}
            </div>
            <div className="geolocation-getter__value">
              {longitude !== undefined ? parseFloat(longitude)?.toFixed(7) : ""}
            </div>
          </div>
        </div>
        <div className="geolocation-getter__grid-column-2-10">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GpsFixedTwoToneIcon style={{ fontSize: "35px", opacity: 0.75 }} />
          </div>
          <div className="geolocation-getter__grid-row-1-1">
            <div className="geolocation-getter__label">
              {i18n("GEOLOCATION_GETTER_ACCURACY_LABEL")}
            </div>
            <div className="geolocation-getter__value">
              {i18n("GEOLOCATION_GETTER_ACCURACY_VALUE", [
                accuracyMeters ? parseFloat(accuracyMeters)?.toFixed(2) : " - ",
              ])}
            </div>
          </div>
        </div>
      </div>
      <Button
        color="primary"
        variant="contained"
        disabled={latitude === undefined || longitude === undefined}
        onClick={saveLatitudeLongitude}
      >
        {i18n("GEOLOCATION_GETTER_GET")}
      </Button>
    </div>
  );
}

export default GeolocationGetter;

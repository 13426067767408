/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Variants, motion } from "framer-motion";
import { PivotLineColorMap } from "../../../../../../../utils/models/pivots";
import { GpsFixed } from "@material-ui/icons";
import DesktopZone from "../../../../../../../components/Zones/DesktopZone";
import { i18n } from "../../../../../../../i18n/i18nText";
import { getDeviceIcon } from "../../../../../../../utils/devices/DeviceListComponent";

const Block = styled(motion.div)`
  box-sizing: border-box;
  width: 100%;

  overflow: hidden;

  font-size: 11px;
`;

const Grid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

const BigItem = styled.div`
  grid-column: 1/3;

  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled.div`
  font-weight: bold;
  font-size: 1.3em;
`;

const ItemLabel = styled.div`
  font-weight: 500;
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  margin-bottom: 2px;

  align-items: center;
`;

interface CircleProps {
  color: string;
}
const Circle = styled.div`
  box-sizing: border-box;
  background-color: ${(props: CircleProps) => props.color};

  width: 15px;
  height: 15px;
  border-radius: 15px;

  margin-right: 15px;
`;

const CircleGradient = styled.div`
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  border-radius: 15px;

  margin-right: 15px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e92400+0,e92400+17,ffd700+17,ffd700+40,207833+40,207833+60,934200+60,934200+60,934200+80,4679ed+80,4679ed+100 */
  background: #e92400; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #e92400 0%,
    #e92400 17%,
    #ffd700 17%,
    #ffd700 40%,
    #207833 40%,
    #207833 60%,
    #934200 60%,
    #934200 60%,
    #934200 80%,
    #4679ed 80%,
    #4679ed 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #e92400 0%,
    #e92400 17%,
    #ffd700 17%,
    #ffd700 40%,
    #207833 40%,
    #207833 60%,
    #934200 60%,
    #934200 60%,
    #934200 80%,
    #4679ed 80%,
    #4679ed 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #e92400 0%,
    #e92400 17%,
    #ffd700 17%,
    #ffd700 40%,
    #207833 40%,
    #207833 60%,
    #934200 60%,
    #934200 60%,
    #934200 80%,
    #4679ed 80%,
    #4679ed 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e92400', endColorstr='#4679ed',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`;

const BlockVariants: Variants = {
  closed: {
    height: "0px",
  },
  open: {
    height: "100%",
  },
};

interface Props {
  isExpanded: boolean;
}

export default function DeviceExpandableSubtitles(props: Props) {
  return (
    <Block
      variants={BlockVariants}
      animate={props.isExpanded ? "open" : "closed"}
    >
      <Grid>
        <BigItem>
          <SectionLabel>{i18n("DEVICE_DRAWER_TITLE")}</SectionLabel>
        </BigItem>

        <BigItem>
          <ItemBody>
            {getDeviceIcon("CENTRAL", "#0066FF", 24, {
              marginBottom: 5,
              marginRight: 14,
            })}
            {i18n("CENTRAL_FOUND")}
          </ItemBody>
          <ItemBody>
            {getDeviceIcon("PIVOT", "#0066FF", 24, {
              marginBottom: 5,
              marginRight: 14,
            })}
            {i18n("CONTROLLER_FOUND")}
          </ItemBody>
          <ItemBody>
            {getDeviceIcon("PUMP", "#0066FF", 24, {
              marginBottom: 5,
              marginRight: 14,
            })}
            {i18n("PUMP_FOUND")}
          </ItemBody>
          <ItemBody>
            {getDeviceIcon("REPEATER", "#0066FF", 24, {
              marginBottom: 5,
              marginRight: 14,
            })}
            {i18n("REPEATER_FOUND")}
          </ItemBody>

          <ItemBody>
            <GpsFixed
              style={{ marginRight: 15 }}
              fontSize={"small"}
              htmlColor={"#0066FF"}
            />{" "}
            {i18n("GPS_FOUND")}
          </ItemBody>
        </BigItem>

        <DesktopZone>
          <BigItem>
            <SectionLabel>{i18n("SELECTED_FARM_MAP_LABEL")}</SectionLabel>
          </BigItem>

          <Item>
            <ItemLabel>{i18n("DEVICE")}</ItemLabel>

            <ItemBody>
              <Circle color="#11FF07" /> {i18n("DEVICE_FOUND")}
            </ItemBody>

            <ItemBody>
              <Circle color="#FA0000" /> {i18n("DEVICE_NOT_FOUND")}
            </ItemBody>

            <ItemBody>
              <Circle color="#F7EB2A" /> {i18n("DEVICE_CENTRAL")}
            </ItemBody>
          </Item>

          <Item>
            <ItemLabel>{i18n("DEVICE_LINE")}</ItemLabel>

            <ItemBody>
              <Circle color={PivotLineColorMap["very strong"]} />{" "}
              {i18n("DEVICE_LINE_VERY_STRONG")}
            </ItemBody>

            <ItemBody>
              <Circle color={PivotLineColorMap.strong} />{" "}
              {i18n("DEVICE_LINE_STRONG")}
            </ItemBody>

            <ItemBody>
              <Circle color={PivotLineColorMap.moderate} />{" "}
              {i18n("DEVICE_LINE_MODERATE")}
            </ItemBody>

            <ItemBody>
              <Circle color={PivotLineColorMap.weak} />{" "}
              {i18n("DEVICE_LINE_WEAK")}
            </ItemBody>

            <ItemBody>
              <Circle color={PivotLineColorMap.error} />{" "}
              {i18n("DEVICE_LINE_ERROR")}
            </ItemBody>
          </Item>
        </DesktopZone>
      </Grid>
    </Block>
  );
}
//"#FA0000", //"#11FF07"

import { action } from "typesafe-actions";
import { SettingTypes, Language } from "./types";
import { Action } from "redux";

export interface SetLanguage extends Action {
  type: SettingTypes.SET_LANGUAGE;
  payload: {
    language: Language;
  };
}

export const setLanguage = (language: Language): SetLanguage =>
  action(SettingTypes.SET_LANGUAGE, { language });

export type AuthActions = SetLanguage;

import styled from "@emotion/styled";
import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { i18n } from "../../i18n/i18nText";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux";
import { setLanguage } from "../../redux/setting/actions";
import { Language, SettingState } from "../../redux/setting/types";

const BRIcon = require("../../assets/images/brazil-icon-32.png");
const USAIcon = require("../../assets/images/usa-icon-32.png");
const DEIcon = require("../../assets/images/german-icon-32.png");
const RUIcon = require("../../assets/images/russia-icon-32.png");
const ESIcon = require("../../assets/images/spain-icon-32.png");

const StyledSelect = styled(Select)`
  padding: -5px;
  .MuiInputBase-input {
    padding: 5px 25px 0px 0px;
  }
`;

const flagSelector = (lang) => {
  switch (lang as String) {
    case "pt-br":
      return {
        icon: BRIcon,
        language: "LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL" as any,
      };
    case "en":
      return { icon: USAIcon, language: "LANGUAGE_SELECTOR_ENGLISH" as any };
    case "de-at":
      return { icon: DEIcon, language: "LANGUAGE_SELECTOR_GERMAN" as any };
    case "ru":
      return { icon: RUIcon, language: "LANGUAGE_SELECTOR_RUSSIAN" as any };
    case "es":
      return { icon: ESIcon, language: "LANGUAGE_SELECTOR_SPANISH" as any };
  }
};

interface Props {
  reload?: boolean;
  showLanguageName?: boolean;
  value?: Language;
  setLanguage?: any;
}

export default function SimpleLanguageSelector(props: Props) {
  const dispatch = useDispatch();

  var selectedLanguage: SettingState = useSelector(
    (state: ApplicationState) => state.setting
  );

  return (
    <StyledSelect
      disableUnderline
      labelId={`login-page-native-select-label`}
      id={`login-page-native-select`}
      value={selectedLanguage.language}
      style={{ margin: props.showLanguageName ? "20px 0 20px 0" : null }}
      onChange={async (e) => {
        if (props.setLanguage) {
          props.setLanguage(e.target.value);
        }
        await dispatch(setLanguage(e.target.value as Language));
      }}
    >
      {["de-at", "en", "es", "pt-br", "ru"].map((value) => {
        return (
          <MenuItem value={value} style={{ zIndex: 5 }}>
            <img
              style={{ verticalAlign: "middle" }}
              src={flagSelector(value).icon}
              height={props.showLanguageName ? "20" : "16"}
              alt={value}
            />{" "}
            <span style={{ paddingLeft: 5 }}>
              {props.showLanguageName
                ? i18n(flagSelector(value).language)
                : null}
            </span>
            {/*  {value} */}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
}

// Microsoft Azure Authentication Library Configurations
import * as Msal from "msal";

const msalConfig = {
  auth: {
    clientId: "c8ee633d-c00f-4688-8695-34c4a9f02d97",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://app.irricontrol.com.br/",
    // redirectUri: "https://dev-dashboard.irricontrol.net/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  } as const,
};

//Used for manually triggering and parsing the response
export const msalLoginUrl =
  `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token` +
  `&scope=openid%20profile%20User.Read` +
  `&client_id=${msalConfig.auth.clientId}` +
  `&redirect_uri=${encodeURIComponent(msalConfig.auth.redirectUri)}` +
  `&state=eyJpZCI6IjhiOTQ1NTJhLWU1N2EtNDNmNS1hNjJhLWEzYWQ2MTMwYzA0MyIsInRzIjoxNTk2NzUwNDE1LCJtZXRob2QiOiJyZWRpcmVjdEludGVyYWN0aW9uIn0%3D` +
  `&nonce=40f9800a-e5bd-4144-8e34-eea59e757df3` +
  `&client_info=1` +
  `&x-client-SKU=MSAL.JS` +
  `&x-client-Ver=1.3.1` +
  `&client-request-id=61a20731-aabd-4c31-8db8-8e192f46407e` +
  `&response_mode=fragment`;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ["Mail.Read"],
};

export const myMSALObj = new Msal.UserAgentApplication(msalConfig);

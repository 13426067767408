import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { NotificationType } from "../../pages/Dashboard/Notifications/Notifications";

export interface Devices {
  id: number;
  name: string;
  protocol?: any;
  automation_type?: number;
}

const useDevices = (
  type: NotificationType,
  farmID?: number
): [boolean, Devices[], (type: NotificationType) => Promise<void>] => {
  const [devices, setDevices] = useState(undefined);
  const [loading, setLoading] = useState(true);

  async function getDevices(type: NotificationType) {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        if (type === NotificationType.PIVOT) {
          const response = await coreHTTPClient.get(
            `/v3/farms/${farmID}/pivots/light/`
          );
          if (checkExists(["data", response, "__cascade"])) {
            const results = response.data.filter(
              (data) => data.automation_type === 0
            ) as Devices[];
            setDevices(results);
          }
        } else if (type === NotificationType.MONITOR) {
          const response = await coreHTTPClient.get(
            `/v3/farms/${farmID}/pivots/light/`
          );
          if (checkExists(["data", response, "__cascade"])) {
            const results = response.data.filter(
              (data) => data.automation_type === 1
            ) as Devices[];
            setDevices(results);
          }
        } else if (type === NotificationType.IRPD) {
          const response = await coreHTTPClient.get(
            `/v3/farms/${farmID}/irpds/`
          );
          if (checkExists(["data", response, "__cascade"])) {
            const results = response.data.filter(
              (data) => data.protocol > 4
            ) as Devices[];
            setDevices(results);
          }
        } else setDevices([]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    getDevices(type);
    // eslint-disable-next-line
  }, []);

  return [loading, devices, getDevices];
};

export default useDevices;

import React, { PropsWithChildren, useState, useEffect } from "react";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import CircularStatic from "../CircularStatic/CircularStatic";
import styled from "@emotion/styled";

interface AbsoluteDivProps {
  isInProgress: boolean;
}

const AbsoluteDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  display: flex;
  justify-content: center;

  opacity: ${(props: AbsoluteDivProps) => (props.isInProgress ? 1 : 0)};
`;

const useStyles = makeStyles({
  progress: {
    barColorPrimary: "#0066FF",
  },
});

export default function ButtonWithProgress(
  props: PropsWithChildren<ButtonProps>
) {
  let [isInProgress, setInProgress] = useState(false);
  let [progressCount, setProgressCount] = useState(0);

  let styles = useStyles({});

  //Contador de 30 segundos
  useEffect(() => {
    let interval;
    if (isInProgress) {
      interval = setInterval(() => {
        setProgressCount((progress) => {
          //condição de parada
          if (progress + 100 / 30 >= 100) {
            setInProgress(false);
            return 0;
          }

          return progress + 100 / 30;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isInProgress]);

  return (
    <div className="button-progress">
      <Button
        disabled={isInProgress}
        {...props}
        onClick={(evt) => {
          if (isInProgress) return;

          setInProgress(true);
          props.onClick(evt);
        }}
      >
        <div style={{ position: "relative" }}>
          <div style={{ opacity: isInProgress ? 0 : 1 }}>{props.children}</div>
          <AbsoluteDiv isInProgress={isInProgress}>
            <CircularStatic value={progressCount} />
          </AbsoluteDiv>
        </div>
      </Button>
    </div>
  );
}

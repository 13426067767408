import { action } from "typesafe-actions";
import { ModalTypes, Modal } from "./types";
import { Action } from "redux";

export interface ShowModal extends Action {
  type: ModalTypes.SHOW_MODAL;
  payload: Modal;
}

export interface HideModal extends Action {
  type: ModalTypes.HIDE_MODAL;
  payload: null;
}

export const showModal = (modal: Modal): ShowModal =>
  action(ModalTypes.SHOW_MODAL, modal);

export const hideModal = (): HideModal => action(ModalTypes.HIDE_MODAL, null);

export type ModalActions = ShowModal | HideModal;

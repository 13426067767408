import React, { useState, useEffect, useMemo } from "react";
import "./BasicInfoBox.scss";
import { Pivot } from "../../../../../../redux/pivots/types";
import { coreHTTPClient } from "../../../../../../services/webclient";
import {
  Paper,
  CircularProgress,
  IconButton,
  Menu,
  Button,
  Tabs,
  Tab,
  Tooltip,
  Divider,
} from "@material-ui/core";
import moment, { Moment } from "moment";
import MyResponsiveRadar from "./components/Radar/WaterBladeRadar";
import { isMobile } from "../../../../../../mobileConfig";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../../../redux/filterByDate/types";
import { ApplicationState } from "../../../../../../redux";
import { useSelector, shallowEqual } from "react-redux";
import InfoBox from "./components/InfoBox/InfoBox";
import { MyResponsiveBar } from "./components/Bar/CostsBar";
import { MyResponsivePie } from "./components/Pie/EnergyCostPie";
import { MoreVert } from "@material-ui/icons";
import { useParams, useNavigate } from "react-router";
import styled from "@emotion/styled";
import { Skeleton } from "@material-ui/lab";
import ReportTable from "./components/ReportList/ReportTable";
import InfoIcon from "@material-ui/icons/Info";
import { MyResponsiveLine } from "./components/Line/VoltageLine";
import { MyResponsivePressureLine } from "./components/Line/LatestPressureLine";
import routes from "../../../../../../routes/routes";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import { i18n } from "../../../../../../i18n/i18nText";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { formatFloatValue } from "../../../../../../utils/models/format";

const StyledDivider = styled.div`
  width: 100%;
  margin: 15px 0 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const InfoContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  padding: 20%;
`;

export const GoToEditFarmButton = styled(Button)`
  margin-top: 15px;
  color: white;
  background-color: #0089d6 !important;
`;

const InfoBoxSkeleton = styled(Skeleton)`
  margin: 5px;
`;

const InfoBoxSkeletonFull = styled(Skeleton)`
  width: 93% !important;
  margin: 5px;
  height: 310px;
`;

const MarginTop = styled.div`
  margin-top: 80px;
`;

const Table = styled.div`
  height: 100%;
  opacity: 1;
  text-align: center;
  // font-weight: bold;
`;

const Row = styled.div`
  height: calc(20%);
  display: flex;
  align-items: center; /* Adicionado para centralizar verticalmente */
  justify-content: space-between;
  margin-bottom: 8px; /* Ajuste do espaçamento entre as linhas */
`;

const RowTitle = styled.div`
  flex: 2;
  font-size: 0.9em;
  text-align: left;
`;

const RowValue = styled.div`
  flex: 1;
  text-align: right;
  font-size: 0.9em;
  padding-left: 8px; /* Ajuste do espaçamento entre o título e o valor */
`;

interface Report {
  pivot_id: number;
  start_date: string;
  end_date: string;
  actions_count: {
    by_panel: number;
    by_web: number;
    not_executed: number;
  };
  unexpected_stops: {
    lack_of_pressure: number;
    energy_blackot: number;
    misalignment: number;
    power_surge: number;
  };
  hours_count: {
    total_hours: number;
    wet_total_hours: number;
    dry_total_hours: number;
  };
  energy_consumption: {
    ponta: {
      hours: number;
      kwh_value: number;
      kwh_consumption: number;
      total: number;
    };
    fora_de_ponta: {
      hours: number;
      kwh_value: number;
      kwh_consumption: number;
      total: number;
    };
    reduzido: {
      hours: number;
      kwh_value: number;
      kwh_consumption: number;
      total: number;
    };
  };
  flow: {
    value_m3: number;
    total_m3h: number;
  };
  water_blade: {
    average: number;
    by_angle: number[];
  };
  voltage_array: any[];
  voltage_min?: number;
  voltage_med?: number;
  voltage_max?: number;
  misalignment_array?: any[];
  start_pivot_report_aggregate?: number;
}

interface PressureChart {
  comparison_pressure_by_angles?: any[];
  current_pressure_by_angle?: any[];
}

export interface Histogram {
  created: string;
  daily_measure: number | null;
  total_measure: number | null;
}

interface Props {
  pivot: Pivot;
}

function BasicInfoBox(props: Props) {
  const farmID: string = useParams().farm;

  const navigate = useNavigate();

  const notify = useNotify();

  const [pieAnchorEl, setPieAnchorEl] = React.useState(null);

  const [pressureAnchorEl, setPressureAnchorEl] = React.useState(null);

  const handlePressureClick = (event) => {
    setPressureAnchorEl(event.currentTarget);
  };

  const [misalignmentAnchorEl, setMisalignmentAnchorEl] = React.useState(null);

  const handleMisalignmentClick = (event) => {
    setMisalignmentAnchorEl(event.currentTarget);
  };

  const handleMisalignmentClose = () => {
    setMisalignmentAnchorEl(null);
  };

  const pivotID = useParams().pivot;

  const handlePieClick = (event) => {
    if (pieAnchorEl) {
      setPieAnchorEl(null);
    } else setPieAnchorEl(event.currentTarget);
  };

  const [reportInfo, setReportInfo] = useState<Report>(undefined);

  const [pressureInfo, setPressureInfo] = useState<PressureChart>(undefined);

  const [pressureCompareStartDate, setPressureCompareStartDate] = useState(
    moment().subtract(2, "month")
  );

  const [pressureCompareEndDate, setPressureCompareEndDate] = useState(
    moment().subtract(1, "month")
  );

  const [valueKwhPeak, setValueKwhPeak] = useState<any>(undefined);

  const [valueKwhOutOfPeak, setValueKwhOutOfPeak] = useState<any>(undefined);

  const [valueKwhReduced, setValueKwhReduced] = useState<any>(undefined);

  const [flow, setFlow] = useState<any>(undefined);

  const [totalValuePeak, setTotalValuePeak] = useState<any>("0");
  const [totalValueOutOfPeak, setTotalValueOutOfPeak] = useState<any>("0");
  const [totalValueReduced, setTotalValueReduced] = useState<any>("0");

  const [pressureChartLoading, setPressureChartloading] = useState(false);

  const [displaySaved, setDisplaySaved] = useState(false);

  const [value, setValue] = useState(0);

  const [startDate, setStartDate] = useState(moment().subtract(1, "month"));

  const [endDate, setEndDate] = useState(moment());

  const [voltages, setVoltages] = useState<{
    min: number;
    average: number | string;
    max: number;
  }>({
    min: 0,
    average: 0,
    max: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem(`flow-${props.pivot?.id}`) !== null) {
      setFlow(parseFloat(localStorage.getItem(`flow-${props.pivot?.id}`)));
    } else if (props.pivot.protocol === 4.1) setFlow(props.pivot?.config?.flow);
    else if (props.pivot.protocol === 5) {
      setFlow(
        props.pivot?.controllerconfig?.content?.pivot_parameters?.flow_rate
      );
    }
  }, [props.pivot]);

  async function getPressureChart(
    currentStartDate,
    currentEndDate,
    comparisonStartDate,
    comparisonEndDate
  ) {
    await new Promise(async (resolve) => {
      await coreHTTPClient
        .get(
          `v3/reports/pivots/${pivotID}/pressure_chart/?comparison_start_date=${comparisonStartDate.toISOString()}&comparison_end_date=${comparisonEndDate.toISOString()}&current_start_date=${currentStartDate.toISOString()}&current_end_date=${currentEndDate.toISOString()}`
        )
        .then((res) => {
          setPressureInfo(res.data);
          setPressureChartloading(false);
        })
        .catch((err) => {
          setPressureChartloading(false);
        });
    });
  }

  async function getReport(startDate: Moment, endDate: Moment) {
    setReportInfo(undefined);
    if (startDate && endDate)
      await new Promise(async (resolve) => {
        await coreHTTPClient
          .get(
            `v3/farms/${farmID}/pivots/${pivotID}/report/?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
          )
          .then((res) => {
            setReportInfo(res.data);
          })
          .catch((err) => {});
      });
  }

  useEffect(() => {
    if (reportInfo !== undefined) {
      setVoltages({
        min: reportInfo?.voltage_min,
        average: reportInfo?.voltage_med,
        max: reportInfo?.voltage_max,
      });
    } else {
      setVoltages({
        min: 0,
        average: 0,
        max: 0,
      });
    }
  }, [reportInfo]);

  useEffect(() => {
    setValueKwhPeak(
      props.pivot.controllerconfig?.kwh_peak
        ? props.pivot.controllerconfig?.kwh_peak
        : props.pivot.config?.kwh_peak
    );
    setValueKwhOutOfPeak(
      props.pivot.controllerconfig?.kwh_out_of_peak
        ? props.pivot.controllerconfig?.kwh_out_of_peak
        : props.pivot.config?.kwh_out_of_peak
    );
    setValueKwhReduced(
      props.pivot.controllerconfig?.kwh_reduced
        ? props.pivot.controllerconfig?.kwh_reduced
        : props.pivot.config?.kwh_reduced
    );
  }, [props.pivot]);

  useEffect(() => {
    if (
      reportInfo &&
      valueKwhPeak !== undefined &&
      valueKwhOutOfPeak !== undefined &&
      valueKwhReduced !== undefined
    ) {
      setTotalValuePeak(
        String(
          (
            reportInfo?.energy_consumption.ponta.kwh_consumption *
            parseFloat(valueKwhPeak.replace(",", "."))
          )
            .toFixed(2)
            .replace("NaN", "0,00")
        )
      );
      setTotalValueOutOfPeak(
        String(
          (
            reportInfo?.energy_consumption.fora_de_ponta.kwh_consumption *
            parseFloat(valueKwhOutOfPeak.replace(",", "."))
          )
            .toFixed(2)
            .replace("NaN", "0,00")
        )
      );
      setTotalValueReduced(
        String(
          (
            reportInfo?.energy_consumption.reduzido.kwh_consumption *
            parseFloat(valueKwhReduced.replace(",", "."))
          )
            .toFixed(2)
            .replace("NaN", "0,00")
        )
      );
    }
  }, [reportInfo, valueKwhPeak, valueKwhOutOfPeak, valueKwhReduced]);

  useEffect(() => {
    if (String(flow) === "NaN") {
      setFlow("");
    }
  }, [flow]);

  const unexpectedStops = useMemo(() => {
    if (reportInfo && props.pivot.automation_type == 0) {
      return (
        <InfoBox centerElement={<>{i18n("BASIC_INFO_BOX_UNEXPECTED_STOPS")}</>}>
          <Table>
            <Row>
              <RowTitle>{i18n("BASIC_INFO_BOX_LACK_OF_PRESSURE")}</RowTitle>
              <RowValue>
                {reportInfo?.unexpected_stops?.lack_of_pressure}
              </RowValue>
            </Row>
            <Divider />
            <Row>
              <RowTitle>{i18n("BASIC_INFO_BOX_POWER_OUTAGE")}</RowTitle>
              <RowValue>
                {reportInfo?.unexpected_stops?.energy_blackot}
              </RowValue>
            </Row>
            <Divider />
            <Row>
              <RowTitle>{i18n("BASIC_INFO_BOX_MISALIGNMENT")}</RowTitle>
              <RowValue>{reportInfo?.unexpected_stops?.misalignment}</RowValue>
            </Row>
            <Divider />
            <Row>
              <RowTitle>{i18n("BASIC_INFO_BOX_POWER_SURGE")}</RowTitle>
              <RowValue>{reportInfo?.unexpected_stops?.power_surge}</RowValue>
            </Row>
          </Table>
        </InfoBox>
      );
    }
    return <></>;
  }, [reportInfo]);

  const radarCenterElement = <>{i18n("BASIC_INFO_BOX_WATER_BLADE_RADAR")}</>;

  const barCenterElement = (
    <div
      style={{
        fontSize: "0.8em",
      }}
    >
      {i18n("BASIC_INFO_BOX_WORK_HOURS")}
    </div>
  );

  const pieCenterElement = <>{i18n("BASIC_INFO_BOX_TOTAL_COST")}</>;

  const lineCenterElementVoltage = (
    <>
      <span>{i18n("BASIC_INFO_BOX_TENSION_TITLE")}</span>
      <Tooltip title={i18n("BASIC_INFO_BOX_TENSION_INFO")} aria-label="info">
        <InfoIcon
          style={{ fontSize: "1em", opacity: 0.6, marginLeft: "5px" }}
        />
      </Tooltip>
    </>
  );

  const lineCenterElementPressure = (
    <>
      <span style={isMobile() ? { fontSize: "0.9em" } : null}>
        {i18n("BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE")}
      </span>
      <Tooltip title={i18n("BASIC_INFO_BOX_PRESSURE_INFO")} aria-label="info">
        <InfoIcon
          style={{ fontSize: "1em", opacity: 0.6, marginLeft: "5px" }}
        />
      </Tooltip>
    </>
  );

  const pieRightElement = (
    <>
      <IconButton onClick={handlePieClick}>
        <MoreVert />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={pieAnchorEl}
        open={Boolean(pieAnchorEl)}
        onClose={() => {
          setPieAnchorEl(null);
        }}
      >
        <div className="popover-values">
          <div className="popover-values__title">
            {i18n("BASIC_INFO_BOX_TOTAL_FEES")}
          </div>

          <div className="popover-values__margins">
            <FormInput
              id="standard-basic-hp"
              label="BASIC_INFO_BOX_PONTA"
              state={[
                formatFloatValue(parseFloat(valueKwhPeak)),
                setValueKwhPeak,
              ]}
              disabled
            />
          </div>

          <div className="popover-values__margins">
            <FormInput
              id="standard-basic-hfp"
              label="BASIC_INFO_BOX_FORA_DE_PONTA"
              state={[
                formatFloatValue(parseFloat(valueKwhOutOfPeak)),
                setValueKwhOutOfPeak,
              ]}
              disabled
            />
          </div>

          <div className="popover-values__margins">
            <FormInput
              id="standard-basic-hr"
              label="BASIC_INFO_BOX_REDUZIDO"
              state={[
                formatFloatValue(parseFloat(valueKwhReduced)),
                setValueKwhReduced,
              ]}
              disabled
            />
          </div>

          <div className="popover-values__config-text">
            {i18n("PIVOT_REPORT_TOTAL_COST_HINT")}
          </div>
        </div>
      </Menu>
    </>
  );

  const pressureRightElement = (
    <>
      <IconButton onClick={handlePressureClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={pressureAnchorEl}
        open={Boolean(pressureAnchorEl)}
        onClose={() => {
          setPressureAnchorEl(null);
        }}
      >
        <div className="popover-values">
          <FormDateTimePicker
            id="report-filter-start-date"
            label="BASIC_INFO_BOX_COMPARE_DATE_START"
            maxDate={moment()}
            state={[pressureCompareStartDate, setPressureCompareStartDate]}
          />
          <FormDateTimePicker
            id="report-filter-end-date"
            label="BASIC_INFO_BOX_COMPARE_DATE_END"
            maxDate={moment()}
            state={[pressureCompareEndDate, setPressureCompareEndDate]}
          />
          <Button
            style={{
              backgroundColor: "#0066FF",
              marginTop: "10px",
              color: "white",
            }}
            onClick={() => {
              if (
                pressureCompareStartDate < pressureCompareEndDate &&
                pressureCompareEndDate < moment()
              ) {
                setPressureChartloading(true);
                getPressureChart(
                  startDate,
                  endDate,
                  pressureCompareStartDate,
                  pressureCompareEndDate
                );
                setPressureChartloading(false);
              } else {
                notify("PIVOT_ACTION_INVALID_DATE", "error");
              }
              setPressureAnchorEl(null);
            }}
            variant="contained"
          >
            {i18n("EDIT_FARM_BUTTON_SAVE")}
          </Button>
        </div>
      </Menu>
    </>
  );

  useEffect(() => {
    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      const body = {
        start_date: filterByDate.data.start_date,
        end_date: filterByDate.data.end_date,
        kwh_value_p: 1.0,
        kwh_value_hfp: 1.0,
        kwh_value_r: 1.0,
      };
      setStartDate(filterByDate.data.start_date);
      setEndDate(filterByDate.data.end_date);
      if (props.pivot.protocol >= 4.1) {
        getReport(filterByDate.data.start_date, filterByDate.data.end_date);
        setPressureChartloading(true);
        getPressureChart(
          filterByDate.data.start_date,
          filterByDate.data.end_date,
          pressureCompareStartDate,
          pressureCompareEndDate
        );
      }
    }
  }, [
    filterByDate.data.start_date?.format("DD MMM HH:mm"),
    filterByDate.data.end_date?.format("DD MMM HH:mm"),
    filterByDate.data.type,
  ]);

  useEffect(() => {
    setStartDate(moment().subtract(1, "month"));
    setEndDate(moment());

    const body = {
      start_date: moment().subtract(1, "month"),
      end_date: moment(),
      kwh_value_p: 1.0,
      kwh_value_hfp: 1.0,
      kwh_value_r: 1.0,
    };

    if (props.pivot.protocol >= 4.1) {
      getReport(startDate, endDate);
      setPressureChartloading(true);
      getPressureChart(
        startDate,
        endDate,
        pressureCompareStartDate,
        pressureCompareEndDate
      );
    }
  }, [pivotID, props.pivot.protocol]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  if (
    reportInfo &&
    (props.pivot.protocol === 4.1 || props.pivot.protocol === 5) &&
    reportInfo?.start_pivot_report_aggregate === 2
  ) {
    return (
      <div className="basic-info">
        <Paper
          style={{
            width: "100%",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            borderRadius: "0px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={i18n("BASIC_INFO_BOX_CHART")} {...a11yProps(0)} />
            <Tab label={i18n("BASIC_INFO_BOX_TABLE")} {...a11yProps(1)} />
          </Tabs>
        </Paper>

        <div className="basic-info__header-infos">
          {i18n("BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN", [
            moment(startDate).format("DD MMM YYYY HH:mm"),
            moment(endDate).format("DD MMM YYYY HH:mm"),
          ])}
        </div>

        {value === 0 ? (
          <>
            <div className="basic-info__container">
              <InfoBox centerElement={barCenterElement}>
                <MyResponsiveBar series={reportInfo?.energy_consumption} />

                <div className="basic-info__bottom-info">
                  {`${i18n("BASIC_INFO_BOX_TOTAL_VOLUME")}
                        ${(
                          flow * reportInfo?.hours_count.wet_total_hours
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} m³`}
                </div>
                <div className="basic-info__bottom-sub-info">
                  {`${i18n("BASIC_INFO_BOX_TOTAL_WET_HOURS")}
                        ${(reportInfo?.hours_count.wet_total_hours).toLocaleString()} h`}
                </div>
              </InfoBox>
              {props.pivot.automation_type != 2 && (
                <>
                  <InfoBox
                    centerElement={pieCenterElement}
                    rightElement={pieRightElement}
                  >
                    <MyResponsivePie
                      series={{
                        ponta: { total: parseFloat(totalValuePeak) },
                        fora_de_ponta: {
                          total: parseFloat(totalValueOutOfPeak),
                        },
                        reduzido: { total: parseFloat(totalValueReduced) },
                      }}
                    />
                    <div className="basic-info__bottom-info">
                      {`${i18n("BASIC_INFO_BOX_TOTAL_VALUE")} ${(
                        parseFloat(totalValueOutOfPeak) +
                        parseFloat(totalValuePeak) +
                        parseFloat(totalValueReduced)
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`}
                    </div>
                  </InfoBox>

                  <InfoBox centerElement={radarCenterElement}>
                    <MyResponsiveRadar
                      data={reportInfo?.water_blade.by_angle}
                      referenceAngle={props.pivot.reference_angle}
                    />
                  </InfoBox>
                </>
              )}
              {unexpectedStops}
              {props.pivot.automation_type == 0 ? (
                <InfoBox centerElement={lineCenterElementVoltage} fullWidth>
                  <div className="basic-info__line-overlay">
                    <div className="basic-info__line-overlay__container">
                      <div>
                        <b>{i18n("BASIC_INFO_BOX_TENSION_INFO_MIN")}:</b>
                        {voltages.min}
                        {" V"}
                      </div>
                      <div>
                        <b>{i18n("BASIC_INFO_BOX_TENSION_INFO_AVERAGE")}:</b>
                        {voltages.average === "NA" ? (
                          "NA"
                        ) : (
                          <>
                            {voltages.average}
                            {" V"}
                          </>
                        )}
                      </div>
                      <div>
                        <b>{i18n("BASIC_INFO_BOX_TENSION_INFO_MAX")}:</b>
                        {voltages.max}
                        {" V"}
                      </div>
                    </div>
                  </div>
                  <MyResponsiveLine data={reportInfo?.voltage_array} />
                </InfoBox>
              ) : null}
              <StyledDivider />
              <div
                className="basic-info__header-infos"
                style={{ marginBottom: "10px" }}
              >
                <div>
                  {i18n("BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO", [
                    moment(startDate).format("DD MMM YYYY HH:mm"),
                    moment(endDate).format("DD MMM YYYY HH:mm"),
                  ])}
                </div>
                <div>
                  {i18n("BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO", [
                    moment(pressureCompareStartDate).format(
                      "DD MMM YYYY HH:mm"
                    ),
                    moment(pressureCompareEndDate).format("DD MMM YYYY HH:mm"),
                  ])}
                </div>
              </div>
              {pressureChartLoading ? (
                <InfoBoxSkeletonFull
                  variant="rect"
                  width={280}
                  height={280}
                  style={{ borderRadius: "5px" }}
                />
              ) : (
                <InfoBox
                  centerElement={lineCenterElementPressure}
                  rightElement={pressureRightElement}
                  fullHeight
                >
                  <MyResponsivePressureLine
                    hasCenterPressure={Boolean(
                      props.pivot?.controllerconfig?.content.pressure_config
                        .read_pressure_by === 2 && props.pivot.protocol >= 5
                    )}
                    latestData={pressureInfo?.current_pressure_by_angle}
                    firstData={pressureInfo?.comparison_pressure_by_angles}
                  />
                </InfoBox>
              )}
            </div>
          </>
        ) : (
          <ReportTable
            pivotProtocol={props.pivot.protocol}
            pivotID={props.pivot.id}
            pivotConfig={
              props.pivot?.controllerconfig
                ? props.pivot?.controllerconfig
                : props.pivot?.config
            }
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    );
  }
  if (props.pivot.protocol < 4.1) {
    return (
      <InfoContainer>{i18n("BASIC_INFO_BOX_NOT_AVAILABLE")}</InfoContainer>
    );
  }
  if (reportInfo?.start_pivot_report_aggregate === 0) {
    return (
      <InfoContainer>
        {i18n("BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY")}
        <GoToEditFarmButton
          variant="contained"
          onClick={() => {
            navigate(
              `${routes.DASHBOARD.EDIT_FARM.replace(":farm", farmID)}?tab=3`
            );
          }}
        >
          {i18n("BASIC_INFO_BOX_GO_TO_EDIT_FARM")}
        </GoToEditFarmButton>
      </InfoContainer>
    );
  }
  if (props.pivot?.regenerate_reports) {
    return (
      <InfoContainer>
        <CircularProgress
          style={{ marginBottom: "20px", color: "gray", opacity: 0.3 }}
        />
        {i18n("BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED")}
      </InfoContainer>
    );
  }
  return (
    <div className="basic-info">
      <MarginTop />
      <div className="basic-info__container">
        <InfoBoxSkeleton
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <InfoBoxSkeleton
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <InfoBoxSkeleton
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <InfoBoxSkeleton
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <InfoBoxSkeletonFull
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <StyledDivider />
        <InfoBoxSkeletonFull
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
        <InfoBoxSkeletonFull
          variant="rect"
          width={280}
          height={280}
          style={{ borderRadius: "5px" }}
        />
      </div>
    </div>
  );
}

export default React.memo(BasicInfoBox);

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BLUE_COLORS_TABLE,
  ZOOM_TABLE,
} from "../../pages/Dashboard/Pivot/utils/utils";
import { Pivot } from "../../redux/pivots/types";
import { isOldProtocol, isV5Protocol } from "../../utils/models/pivots";
import {
  DrawArcBackward,
  DrawArcFoward,
  DrawCircle,
  DrawLine,
  writeOnCanvas,
} from "./components/Canvas";
import "./EndgunAngleSelector.scss";

import styled from "@emotion/styled";
import { RotateLeft, RotateRight } from "@material-ui/icons";
import { i18n, i18nTextId } from "../../i18n/i18nText";
import FormInputIrrigation from "../Form/FormInputIrrigation/FormInputIrrigation";
import AngleLabels from "../GeneralAngleSelector/components/AngleLabels";

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 10px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledH4 = styled.h4`
  margin: 0;
`;

interface Props {
  pivot?: Pivot;
  strokeSize: number; // Espessura do desenho da faixa de ângulo | 0 - cheio | 1 - sem espessura
  label: i18nTextId; // Label utilizando i18n novo
  angleDirection?: boolean; // Direção avanço ou reverso, sendo avanço (true) e reverso (false)
  startOnReference?: boolean; // Existem dois casos: o caso que a primeira faixa deve começar onde o pivô está ou começar na referência do pivô
  setShowLastAngle?: (value: boolean) => void; // Único atributo que é específico do componente usado para configurar segmentos, previne flicks na tabela mostrada no formulário de segmentos na configuração do pivô
  sectorLength?: number; // Caso o pivô não seja de 0...360 essa variável indica até qual ângulo ele pivô é capaz de atingir
  anglesState: [any[], any]; // [state, setState] referente ao resultado da seleção de faixas realizadas
  maxLength?: number; // Define quantas faixas serão possíveis de serem criadas
  centerPosition?: string[]; // If is not an Irricontrol Pivot
  gpsPosition?: string[]; // If is not an Irricontrol Pivot
  referenceAngle?: number; // If is not an Irricontrol Pivot
  referenceRadius?: number; // If is not an Irricontrol Pivot
  angleStart?: number;
}

export interface TableRow {
  direction: number;
  speed: number;
  endgun: number;
  start_angle: number;
  end_angle: number;
}

let angle;

function EndgunAngleSelector(props: Props) {
  const {
    label,
    anglesState,
    strokeSize,
    angleDirection,
    setShowLastAngle,
    startOnReference,
    sectorLength,
    maxLength,
    pivot,
  } = props;

  const plusIcon = require("../../assets/images/plus.svg");
  const trashCan = require("../../assets/images/redTrashCan.png");

  const isBauerPivot = props.centerPosition?.length > 0 ? true : false;
  const startAngleDraw = props.angleStart || 0;
  const [filletSelected, setFilletSelected] = useState(0);

  const touchEndEvent = useRef<any>(undefined);

  // DIRECTION ARRAYS
  let [direction, setDirection] = useState<string[]>(
    // O array de direção atualmente sempre é todas as faixas em avanço ou todas em reverso para generalizar o uso desse componente
    new Array(anglesState[0].length).fill(angleDirection === true ? "0" : "1")
  );

  // Responsável por mostrar as bolinhas de cada segmento com as suas devidas cores
  let [fillets, setFillets] = useState(
    direction.map((_, index) => {
      return BLUE_COLORS_TABLE[index];
    })
  );

  // Responsável por desenhar cada linha branca que divide os segmentos
  const [canvasWhiteLines, setCanvasWhiteLines] = useState<
    RefObject<HTMLCanvasElement>[]
  >(
    direction.map(() => {
      return createRef<HTMLCanvasElement>();
    })
  );

  // Também responsável por desenhar cada linha branca que divide os segmentos em segmentos separados que precisam da linha no início e no fim
  const [canvasWhiteLinesStart, setCanvasWhiteLinesStart] = useState<
    RefObject<HTMLCanvasElement>[]
  >(
    direction.map(() => {
      return createRef<HTMLCanvasElement>();
    })
  );

  // Responsável por desenhar cada arco dentro do pivô (a pŕopria faixa em si)
  const [canvasArcs, setCanvasArcs] = useState<RefObject<HTMLCanvasElement>[]>(
    direction.map(() => {
      return createRef<HTMLCanvasElement>();
    })
  );

  const [canvaRedArc, setCanvaRedArc] = useState<RefObject<HTMLCanvasElement>>(
    createRef<HTMLCanvasElement>()
  );

  // Desenho do canva que contorna o pivô com uma linha branca e mostra alguns "ticks" com os ângulos
  const firstCanva = useRef(null);

  let gpsPosition = props.gpsPosition;
  let centerPosition = props.centerPosition;
  if (pivot) {
    if (isOldProtocol(pivot)) {
      centerPosition = pivot.config.center.split(",");
      gpsPosition = pivot.latest_gps_stream.position.split(",");
    } else if (isV5Protocol(pivot)) {
      centerPosition = [
        pivot.controllerconfig.content.pivot_positions.latitude_center.toString(),
        pivot.controllerconfig.content.pivot_positions.longitude_center.toString(),
      ];

      gpsPosition = startOnReference
        ? [
            pivot.controllerconfig.content.pivot_positions.latitude_reference.toString(),
            pivot.controllerconfig.content.pivot_positions.longitude_reference.toString(),
          ]
        : [
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
            pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
          ];
    }
  }

  let gpsPositionGMaps = new google.maps.LatLng(
    parseFloat(gpsPosition[0]),
    parseFloat(gpsPosition[1])
  );

  let centerPositionGMaps = new google.maps.LatLng(
    parseFloat(centerPosition[0]),
    parseFloat(centerPosition[1])
  );

  //Posição do pivo
  let angleGps =
    isBauerPivot && startAngleDraw
      ? startAngleDraw
      : google.maps.geometry.spherical.computeHeading(
          centerPositionGMaps,
          gpsPositionGMaps
        );
  const isMouseDown = useRef(false);

  //Angulo de referencia
  let referenceAngle = pivot ? pivot.reference_angle : props.referenceAngle;

  const lastAngleRef = useRef(referenceAngle);

  // let referenceAngle = 0;

  let gpsAngleWithReference: number =
    angleGps > 0
      ? Math.round(angleGps - referenceAngle)
      : Math.round(angleGps + 360 - referenceAngle);

  gpsAngleWithReference =
    gpsAngleWithReference > 360
      ? gpsAngleWithReference - 360
      : gpsAngleWithReference < 0
      ? gpsAngleWithReference + 360
      : gpsAngleWithReference;

  const disableAddButton = useMemo(() => {
    return maxLength === anglesState?.[0]?.length;
  }, [anglesState?.[0]]);

  function handleAngleFromTouch(
    startAngle: number,
    canva: any,
    radius: number,
    e: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");
    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e?.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    //Calculate angle between pivot position and click
    angle = Math.atan2(y, x) - Math.atan2(refY, refX);
    if (angle > 0) {
      angle = 360 - (angle * 180) / Math.PI;
    } else {
      angle = -(angle * 180) / Math.PI;
    }

    lastAngleRef.current = Math.round(angle);
    /////////////////////////////////////////////////////////////////////
    anglesState[1]((prevState) => {
      var result = [...prevState];
      result[filletSelected] = {
        ...result[filletSelected],
        start_angle: Math.round(angle),
      };
      return result;
    });
  }

  function handleTouchAndClickEnd(
    canva: any,
    radius: number,
    startAngle: number,
    newAngle?: any,
    e?: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");

    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e?.clientX === undefined) {
      x = touchEndEvent.current.clientX;
      y = -touchEndEvent.current.clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field

      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
    }

    //Ajusta o angulo para contar a partir do angulo do pivo
    let angleAux = Math.round(
      angle +
        (startAngle > 0
          ? startAngle - referenceAngle
          : startAngle + 360 - referenceAngle)
    );

    angleAux =
      angleAux > 360
        ? angleAux - 360
        : angleAux < 0
        ? angleAux + 360
        : angleAux;

    anglesState[1]((prevState) => {
      var result = [...prevState];
      result[filletSelected] = {
        ...result[filletSelected],
        end_angle: angleAux,
      };
      return result;
    });
  }

  // Função que adiciona os primeiros angulos vindos da API
  function handleOnMount(
    strokeSize: number,
    canvaLineEnd: any,
    arcCanva: any,
    filletsMove: any,
    radius: number,
    startAngle: number,
    newAngle?: any,
    e?: any,
    index?: number
  ) {
    if (canvaLineEnd.current == null) return;
    let contextEnd = canvaLineEnd.current.getContext("2d");

    contextEnd.clearRect(
      0,
      0,
      canvaLineEnd.current.width,
      canvaLineEnd.current.height
    );
    //Get x,y coordinates (inside canvas) from click
    let x, y, angle;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = contextEnd.current?.getBoundingClientRect();

    x = x - offset?.x - contextEnd.current?.width / 2;
    y = y + offset?.y + contextEnd.current?.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((referenceAngle * Math.PI) / 180);
    refX = radius * Math.sin((referenceAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field
      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
      angle = Math.round(angle);
    }
    // Prevent overlapping
    if (angle < parseInt(anglesState[0][index - 1]?.angle)) {
      if (startAngleDraw == 0) {
        angle = 360;
      } else {
        angle = startAngleDraw;
      }
    }

    let newY = radius * Math.cos(((referenceAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((referenceAngle + angle) * Math.PI) / 180);
    //Desenha uma nova linha branca
    DrawLine(
      contextEnd,
      canvaLineEnd.current.width / 2,
      canvaLineEnd.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      contextEnd,
      canvaLineEnd.current.width / 2 + newX / 2,
      canvaLineEnd.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      contextEnd,
      canvaLineEnd.current.width / 2 + newX / 2 - 10,
      canvaLineEnd.current.height / 2 - newY / 2 + 5,
      angle + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      contextEnd,
      canvaLineEnd.current.width / 2,
      canvaLineEnd.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );

    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();
    // contextArc.beginPath();

    var valueToSubstract = startAngle > newAngle ? 360 : 0;
    DrawArcFoward(
      contextArc,
      contextArc.canvas.height / 2,
      contextArc.canvas.width / 2,
      referenceRadius * conversionRatio,
      ((startAngle + referenceAngle - 90) * Math.PI) / 180, //parte do
      ((angle + referenceAngle - 90) * Math.PI) / 180,
      filletsMove,
      strokeSize
    );

    // lastAngleRef.current = Math.round(newAngle);

    contextArc.fill();
    contextArc.restore();
  }

  // Função responsável por desenhar cada faixa de ângulo
  function handleMouseMove(
    strokeSize: number,
    canva: any,
    arcCanva: any,
    filletsMove: any,
    radius: number,
    startAngle: number,
    directionMove: string,
    newAngle?: any,
    e?: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");

    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field

      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
    }

    let newY = radius * Math.cos(((startAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((startAngle + angle) * Math.PI) / 180);

    //Desenha uma nova linha branca
    DrawLine(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      context,
      canva.current.width / 2 + newX / 2,
      canva.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Ajusta o angulo para contar a partir do angulo do pivo
    let angleAux = Math.round(
      angle +
        (startAngle > 0
          ? startAngle - referenceAngle
          : startAngle + 360 - referenceAngle)
    );

    angleAux =
      angleAux > 360
        ? angleAux - 360
        : angleAux < 0
        ? angleAux + 360
        : angleAux;

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      context,
      canva.current.width / 2 + newX / 2 - 10,
      canva.current.height / 2 - newY / 2 + 5,
      angleAux + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );

    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();
    // contextArc.beginPath();
    var tempAngle = angle == 360 ? 359 : angle;
    if (directionMove == "0") {
      if (lastAngleRef.current != -1) {
        //Check if it's the first fillet or not. If it's not
        //the new fillet must use the last one as its new reference
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleRef.current) * Math.PI) / 180, //Parte da posicão inicial do pivot
          ((-360 + startAngle - 90 + tempAngle) * Math.PI) / 180,
          filletsMove,
          strokeSize
        );
      } else {
        DrawArcFoward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180, //parte do
          ((-360 + startAngle - 90 + tempAngle) * Math.PI) / 180,
          filletsMove,
          strokeSize
        );
      }
    } else {
      if (lastAngleRef.current != -1) {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90 + lastAngleRef.current) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          strokeSize
        );
      } else {
        DrawArcBackward(
          contextArc,
          contextArc.canvas.height / 2,
          contextArc.canvas.width / 2,
          referenceRadius * conversionRatio,
          ((-360 + startAngle - 90) * Math.PI) / 180,
          ((-360 + startAngle - 90 + angle) * Math.PI) / 180,
          filletsMove,
          strokeSize
        );
      }
    }

    contextArc.fill();
    contextArc.restore();
  }

  // Função responsável por desenhar cada faixa de ângulo
  function handleWhiteLinePosition(
    canva: any,
    radius: number,
    startAngle: number,
    newAngle?: any,
    e?: any
  ) {
    if (canva.current == null) return;
    let context = canva.current.getContext("2d");
    context.clearRect(0, 0, canva.current.width, canva.current.height);
    //Get x,y coordinates (inside canvas) from click
    let x, y;
    //Check if function is being executed from desktop or mobile
    if (e !== undefined && e.clientX === undefined) {
      x = e.touches[0].clientX;
      y = -e.touches[0].clientY;
    } else if (e !== undefined) {
      x = e.clientX;
      y = -e.clientY;
    }

    let offset = canva.current.getBoundingClientRect();

    x = x - offset.x - canva.current.width / 2;
    y = y + offset.y + canva.current.height / 2;

    let refY;
    let refX;

    //calculate x,y coordinates from pivot position from central point
    refY = radius * Math.cos((startAngle * Math.PI) / 180);
    refX = radius * Math.sin((startAngle * Math.PI) / 180);

    if (newAngle !== undefined && newAngle !== "") {
      //get angle from endAngle field

      angle = parseInt(newAngle);
    } else {
      //Calculate angle between pivot position and click
      angle = Math.atan2(y, x) - Math.atan2(refY, refX);
      if (angle > 0) {
        angle = 360 - (angle * 180) / Math.PI;
      } else {
        angle = -(angle * 180) / Math.PI;
      }
    }

    let newY = radius * Math.cos(((startAngle + angle) * Math.PI) / 180);
    let newX = radius * Math.sin(((startAngle + angle) * Math.PI) / 180);

    //Desenha uma nova linha branca
    DrawLine(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      newX,
      newY,
      "white"
    );

    //Desenha o circulo menor indicador do angulo
    DrawCircle(
      context,
      canva.current.width / 2 + newX / 2,
      canva.current.height / 2 - newY / 2,
      15,
      0,
      2 * Math.PI,
      "white"
    );

    //Ajusta o angulo para contar a partir do angulo do pivo
    let angleAux = Math.round(
      angle +
        (startAngle > 0
          ? startAngle - referenceAngle
          : startAngle + 360 - referenceAngle)
    );

    angleAux =
      angleAux > 360
        ? angleAux - 360
        : angleAux < 0
        ? angleAux + 360
        : angleAux;

    //Escreve o angulo no circulo branco no meio da linha branca
    writeOnCanvas(
      context,
      canva.current.width / 2 + newX / 2 - 10,
      canva.current.height / 2 - newY / 2 + 5,
      angleAux + "º",
      "black"
    );

    //Desnha o circulo menor preto central
    DrawCircle(
      context,
      canva.current.width / 2,
      canva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );
  }

  function drawRedArc(
    strokeSize: number,
    sectorLength: number = 360,
    arcCanva: any
  ) {
    //Desenha o arco indicador do movimento do pivot
    let contextArc = arcCanva.current.getContext("2d");
    contextArc.clearRect(0, 0, arcCanva.current.width, arcCanva.current.height);
    contextArc.save();

    DrawArcFoward(
      contextArc,
      contextArc.canvas.height / 2,
      contextArc.canvas.width / 2,
      referenceRadius * conversionRatio,
      ((-360 + angleGps - 90 + sectorLength) * Math.PI) / 180, //parte do
      ((angleGps - 90) * Math.PI) / 180,
      sectorLength == 360 ? "transparent" : "#db1c0ec7",
      strokeSize
    );
    contextArc.fill();
    contextArc.restore();
  }

  // Muda direção do pivô (avanço | reverso)
  const handleDirection = (
    event: React.MouseEvent<HTMLElement>,
    newDirection: string | null
  ) => {
    if (newDirection) {
      setFillets(["#cc651b"]);
      anglesState[1]([]);
      setCanvasWhiteLines([createRef<HTMLCanvasElement>()]);
      setCanvasWhiteLinesStart([createRef<HTMLCanvasElement>()]);
      firstCanva.current = null;
      setDirection([newDirection]);
    }
  };

  let center, reference;
  if (pivot) {
    gpsPosition = [
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.latitude_gps.toString(),
      pivot.controllerstream_gps.content?.latitude_longitude_gps?.longitude_gps.toString(),
    ];
    center = new google.maps.LatLng(
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.latitude_center.toString()
          : pivot.config.center.substring(0, pivot.config.center.indexOf(","))
      ),
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.longitude_center.toString()
          : pivot.config.center.substring(
              pivot.config.center.indexOf(",") + 1,
              pivot.config.center.length
            )
      )
    );

    reference = new google.maps.LatLng(
      pivot.protocol === 5
        ? pivot.controllerconfig.content.pivot_positions.latitude_reference.toString()
        : parseFloat(
            pivot.config.reference.substring(
              0,
              pivot.config.reference.indexOf(",")
            )
          ),
      parseFloat(
        pivot.protocol === 5
          ? pivot.controllerconfig.content.pivot_positions.longitude_reference.toString()
          : pivot.config.reference.substring(
              pivot.config.reference.indexOf(",") + 1,
              pivot.config.reference.length
            )
      )
    );
  } else {
    //BAUER PIVOT
    center = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );
    reference = new google.maps.LatLng(
      parseFloat(gpsPosition[0]),
      parseFloat(gpsPosition[1])
    );
  }

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;
  let conversionRatio = 0;
  let conversionRatioReferenceLines = 0;
  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
      conversionRatio = ZOOM_TABLE[i].conversionRatio;
      conversionRatioReferenceLines =
        ZOOM_TABLE[i].conversionRatioReferenceLines;
    }
  }

  useEffect(() => {
    lastAngleRef.current = anglesState[0][filletSelected].start_angle;
  }, [filletSelected]);

  function popAngle() {
    if (fillets.length > 1) {
      anglesState[0].pop();
      anglesState[1]([...anglesState[0]]);
      fillets.pop();
      setFillets([...fillets]);
      canvasWhiteLines.pop();
      setCanvasWhiteLines([...canvasWhiteLines]);
      canvasWhiteLinesStart.pop();
      setCanvasWhiteLinesStart([...canvasWhiteLinesStart]);
      canvasArcs.pop();
      setCanvasArcs([...canvasArcs]);
      setFilletSelected(fillets.length - 1);
    }
  }

  const onAngleEndChange = useCallback(
    (e) => {
      let endAngleValue = e?.target?.value;
      if (endAngleValue == "") {
        endAngleValue = 0;
      }
      if (endAngleValue || endAngleValue == 0) {
        if (endAngleValue >= 0 && endAngleValue <= 360) {
          handleMouseMove(
            strokeSize,
            canvasWhiteLines[filletSelected],
            canvasArcs[filletSelected],
            fillets[filletSelected],
            referenceRadius * conversionRatio,
            referenceAngle,
            angleDirection === true ? "0" : "1",
            parseInt(endAngleValue)
          );
          anglesState[1]((prevState) => {
            let result = [...prevState];
            result[filletSelected].end_angle = parseInt(endAngleValue);
            return result;
          });
        }
      }
    },
    [
      anglesState,
      strokeSize,
      canvasWhiteLines,
      canvasArcs,
      fillets,
      referenceRadius,
      conversionRatio,
      angleDirection,
      filletSelected,
    ]
  );

  const onAngleStartChange = useCallback(
    (e) => {
      let startAngleValue = e?.target?.value;
      if (startAngleValue == "") {
        startAngleValue = 0;
      }
      if (startAngleValue || startAngleValue == 0) {
        if (startAngleValue >= 0 && startAngleValue <= 360) {
          lastAngleRef.current = parseInt(startAngleValue);
          anglesState[1]((prevState) => {
            var result = [...prevState];
            result[filletSelected].start_angle = parseInt(startAngleValue);
            return result;
          });
          handleWhiteLinePosition(
            canvasWhiteLinesStart[filletSelected],
            referenceRadius * conversionRatio,
            referenceAngle,
            parseInt(startAngleValue)
          );
          handleMouseMove(
            strokeSize,
            canvasWhiteLines[filletSelected],
            canvasArcs[filletSelected],
            fillets[filletSelected],
            referenceRadius * conversionRatio,
            referenceAngle,
            angleDirection === true ? "0" : "1",
            anglesState[0]?.[filletSelected]?.end_angle
          );
        }
      }
    },
    [
      lastAngleRef.current,
      anglesState,
      strokeSize,
      canvasWhiteLines,
      canvasArcs,
      fillets,
      referenceRadius,
      conversionRatio,
      angleDirection,
      filletSelected,
    ]
  );

  const circleDiameter = referenceRadius * conversionRatio * 2;

  useEffect(() => {
    drawRedArc(0, parseInt(`${sectorLength}`), canvaRedArc);
  }, [sectorLength]);

  // Desenhando as faixas ao renderizar o componente
  useEffect(() => {
    for (let index = 0; index < direction.length; index++) {
      // TODO talvez usar outra função apenas no load da API para obter os endguns já criados
      handleWhiteLinePosition(
        canvasWhiteLinesStart[index],
        referenceRadius * conversionRatio,
        referenceAngle,
        anglesState[0][index].start_angle
      );
      handleOnMount(
        strokeSize,
        canvasWhiteLines[index],
        canvasArcs[index],
        fillets[index],
        referenceRadius * conversionRatio,
        anglesState[0][index].start_angle,
        anglesState[0][index].end_angle,
        undefined,
        index
      );
    }
  }, []);

  // Desenho inicial das faixas
  useEffect(() => {
    let context = firstCanva.current?.getContext("2d");

    //Desenha circulo que representa alcance do pivot
    context.beginPath();
    context.arc(
      firstCanva.current.width / 2,
      firstCanva.current.height / 2,
      referenceRadius * conversionRatio,
      0,
      2 * Math.PI
    );
    context.strokeStyle = "white";
    context.stroke();

    //Desenha as linhas brancas ao redor do círculo
    for (let i = 0; i < 72; i++) {
      context.save();
      let ang = (i * 5 * Math.PI) / 180;
      context.translate(
        firstCanva.current.width / 2,
        firstCanva.current.height / 2
      );
      context.rotate(ang);
      context.fillStyle = "white";
      context.textBaseline = "middle";
      context.textAlign = "center";
      context.beginPath();
      context.moveTo(0, referenceRadius * conversionRatioReferenceLines);
      context.lineTo(0, referenceRadius * conversionRatio);
      context.strokeStyle = "white";
      context.stroke();
      //back to checkpoint
      context.restore();
    }

    // Translate the context to the point we want to rotate around
    context.translate(
      firstCanva.current.width / 2,
      firstCanva.current.height / 2
    );

    // Perform the rotation
    context.rotate((referenceAngle * Math.PI) / 180);

    //Escreve os graus 0º, 90º, 180º, 270º
    // writeOnCanvas(
    //   context,
    //   0,
    //   -referenceRadius * conversionRatioReferenceLines + 5,
    //   "0º",
    //   "white"
    // );
    // writeOnCanvas(
    //   context,
    //   -9,
    //   referenceRadius * conversionRatioReferenceLines - 2,
    //   "180º",
    //   "white"
    // );
    // writeOnCanvas(
    //   context,
    //   referenceRadius * conversionRatioReferenceLines - 15,
    //   4,
    //   "90º",
    //   "white"
    // );
    // writeOnCanvas(
    //   context,
    //   -referenceRadius * conversionRatioReferenceLines + 1,
    //   4,
    //   "270º",
    //   "white"
    // );

    let pivotPositionY =
      referenceRadius *
      conversionRatio *
      Math.cos((gpsAngleWithReference * Math.PI) / 180);
    let pivotPositionX =
      referenceRadius *
      conversionRatio *
      Math.sin((gpsAngleWithReference * Math.PI) / 180);

    //Linha preta (posição atual do pivot)
    DrawLine(context, 0, 0, pivotPositionX, pivotPositionY, "black");
    // DrawLine(context, 0, 0, 0, referenceRadius * conversionRatio, "black");

    //back to checkpoint
    context.restore();
    DrawCircle(
      context,
      firstCanva.current.width / 2,
      firstCanva.current.height / 2,
      5,
      0,
      2 * Math.PI,
      "black"
    );
    let contextWhiteLines = canvasWhiteLines[
      filletSelected
    ].current?.getContext("2d");

    //create canvas checkpoint with default translate and rotate
    contextWhiteLines.save();

    //Translate full canvas to it's center
    contextWhiteLines.translate(
      canvasWhiteLines[filletSelected].current.width / 2,
      canvasWhiteLines[filletSelected].current.height / 2
    );

    //Rotate canvas by reference_angle arround it's center
    contextWhiteLines.rotate((referenceAngle * Math.PI) / 180);

    //Desenha o circulo central preto
    DrawCircle(contextWhiteLines, 0, 0, 5, 0, 2 * Math.PI, "black");

    //rotate and translate back to checkpoint
    contextWhiteLines.restore();
  }, []);

  return (
    <div className="general-angle">
      {angleDirection === undefined && (
        <div className={"general-angle__toggle-container"}>
          <ToggleButtonGroup
            value={direction[filletSelected].toString()}
            exclusive
            onChange={handleDirection}
            aria-label="direction"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton value="1" aria-label="Reverse" style={{ width: 140 }}>
              <RotateLeft />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
            </ToggleButton>
            <ToggleButton
              value="0"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <RotateRight />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
      <StyledH4>{i18n("NEW_SEGMENT")}</StyledH4>
      <Row>
        <FormInputIrrigation
          id="pivot-config-angle-start"
          label={i18n("BY_ANGLE_IRRIGATION_FIELD_ANGLE_START")}
          val={anglesState?.[0]?.[filletSelected]?.start_angle?.toString()}
          onChangeFunction={onAngleStartChange}
          endAdornment="º"
          inputMode="numeric"
        />
        <FormInputIrrigation
          id="pivot-config-angle-end"
          label={i18n(label)}
          val={anglesState?.[0]?.[filletSelected]?.end_angle?.toString()}
          onChangeFunction={onAngleEndChange}
          endAdornment="º"
          inputMode="numeric"
        />
      </Row>
      {/* FAIXAS */}
      <div className="general-angle__fillets">
        <span style={{ fontSize: 14 }}>
          {i18n("SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON")}
        </span>
        <div
          className="general-angle__fillets-with-trash"
          style={{ width: window.innerWidth > 800 ? "600px" : "300px" }}
        >
          <div
            id="fillets-container"
            className="general-angle__fillets-container"
          >
            {fillets.length == 0 ? (
              <div className="general-angle__fillets-disabled-circle">1</div>
            ) : null}
            {fillets.map((fillet, index) => {
              return (
                <div
                  key={"fillet_" + index}
                  style={{
                    backgroundColor: BLUE_COLORS_TABLE[index].replace("99", ""),
                    cursor: "pointer",
                  }}
                  className={`${
                    index === filletSelected
                      ? "general-angle__fillets-circle-active"
                      : "general-angle__fillets-circle"
                  }`}
                  onClick={() => {
                    setFilletSelected(index);
                  }}
                >
                  {index + 1}
                </div>
              );
            })}
            {fillets.map((fillet, index) => {
              if (index + 1 == fillets.length) {
                return (
                  <div
                    key={"disabled-circle-" + index}
                    className="general-angle__fillets-disabled-circle"
                    style={{
                      cursor: "pointer",
                      opacity: disableAddButton ? 0.5 : 1,
                    }}
                    onClick={(e) => {
                      if (angle !== 0 && fillets.length < maxLength) {
                        if (disableAddButton) return;
                        setFilletSelected(index + 1);
                        setFillets([...fillets, BLUE_COLORS_TABLE[index + 1]]);

                        let newWhiteLine = canvasWhiteLines.concat(
                          createRef<HTMLCanvasElement>()
                        );
                        setCanvasWhiteLines([...newWhiteLine]);

                        let newWhiteLineStart = canvasWhiteLinesStart.concat(
                          createRef<HTMLCanvasElement>()
                        );
                        setCanvasWhiteLinesStart([...newWhiteLineStart]);

                        anglesState[1]([
                          ...anglesState[0],
                          {
                            start_angle: 0,
                            end_angle: 0,
                            number_editing: anglesState[0].length,
                          },
                        ]);

                        let newArc = canvasArcs.concat(
                          createRef<HTMLCanvasElement>()
                        );
                        setCanvasArcs([...newArc]);
                      }
                    }}
                  >
                    <img src={plusIcon} width={15} height={15} />
                  </div>
                );
              }
            })}
          </div>
          <div className="general-angle__fillets-trash">
            <img
              src={trashCan}
              style={{ width: 23, height: 23, cursor: "pointer" }}
              onClick={() => {
                popAngle();
              }}
            />
          </div>
        </div>
      </div>
      {/* IMAGEM DO MAPA COM CANVAS */}
      <div
        onMouseDown={() => {
          if (setShowLastAngle !== undefined) {
            setShowLastAngle(false);
          }
        }}
        onMouseUp={() => {
          if (setShowLastAngle !== undefined) {
            setShowLastAngle(true);
          }
        }}
        onTouchStart={() => {
          if (setShowLastAngle !== undefined) {
            setShowLastAngle(false);
          }
        }}
        onTouchEnd={() => {
          if (setShowLastAngle !== undefined) {
            setShowLastAngle(true);
          }
        }}
        style={{
          userSelect: "none",
          WebkitTouchCallout: "none" /* iOS Safari */,
          WebkitUserSelect: "none" /* Safari */,
          KhtmlUserSelect: "none" /* Konqueror HTML */,
          MozUserSelect: "none" /* Old versions of Firefox */,
          msUserSelect: "none" /* Internet Explorer/Edge */,
          width: window.innerWidth > 800 ? "600px" : "300px",
          height: "300px",
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AngleLabels
          referenceAngle={referenceAngle}
          circleDiameter={circleDiameter}
          endAngle={360} // Fix para pivo setorial em V5
        />
        <canvas
          id="EndgunAngleSelector"
          ref={firstCanva}
          width={300}
          height={300}
          style={{ position: "absolute", zIndex: 899 }}
        />
        {canvasArcs.map((canva, index) => {
          return (
            <canvas
              key={"canvaArc_" + index}
              ref={canva}
              width={300}
              height={300}
              style={{
                position: "absolute",
                zIndex: 820 + index,
              }}
            />
          );
        })}
        {canvasWhiteLinesStart.map((canva, index) => {
          return (
            <canvas
              id={"canva_start_" + index}
              key={"canva_start_" + index}
              ref={canva}
              width={300}
              height={300}
              style={{
                position: "absolute",
                zIndex: 900 + index,
              }}
            />
          );
        })}
        <canvas
          id={"red_canva"}
          key={"red_canva"}
          ref={canvaRedArc}
          width={300}
          height={300}
          style={{
            position: "absolute",
            zIndex: 800,
          }}
        />
        {canvasWhiteLines.map((canva, index) => {
          return (
            <canvas
              id={"canva_" + index}
              key={"canva_" + index}
              ref={canva}
              width={300}
              height={300}
              style={{
                position: "absolute",
                zIndex: 901 + index,
              }}
              onTouchStartCapture={(e: any) => {
                handleAngleFromTouch(
                  referenceAngle,
                  canvasWhiteLines[filletSelected],
                  referenceRadius * conversionRatio,
                  e
                );
                handleWhiteLinePosition(
                  canvasWhiteLinesStart[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  undefined,
                  e
                );
              }}
              onMouseDown={(e) => {
                handleAngleFromTouch(
                  referenceAngle,
                  canvasWhiteLines[filletSelected],
                  referenceRadius * conversionRatio,
                  e
                );
                handleWhiteLinePosition(
                  canvasWhiteLinesStart[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  undefined,
                  e
                );
                document.getElementById(
                  "canva_" + filletSelected
                ).style.cursor = "grab";
                isMouseDown.current = true;
              }}
              onMouseUp={(e) => {
                document.getElementById(
                  "canva_" + filletSelected
                ).style.cursor = "initial";
                handleTouchAndClickEnd(
                  canvasWhiteLinesStart[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  undefined,
                  e
                );
                isMouseDown.current = false;
              }}
              onMouseMove={(e) => {
                if (!isMouseDown.current) return;
                handleMouseMove(
                  strokeSize,
                  canvasWhiteLines[filletSelected],
                  canvasArcs[filletSelected],
                  fillets[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  angleDirection === true ? "0" : "1",
                  undefined,
                  e
                );
              }}
              onTouchMove={(e) => {
                handleMouseMove(
                  strokeSize,
                  canvasWhiteLines[filletSelected],
                  canvasArcs[filletSelected],
                  fillets[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  angleDirection === true ? "0" : "1",
                  undefined,
                  e
                );
                touchEndEvent.current = {
                  clientX: e?.touches[0]?.clientX,
                  clientY: e?.touches[0]?.clientY,
                };
              }}
              // onClick={(e) => {
              //   if (disableAddButton) return;
              //   handleWhiteLinePosition(
              //     canvasWhiteLinesStart[filletSelected],
              //     referenceRadius * conversionRatio,
              //     referenceAngle,
              //     undefined,
              //     e
              //   );
              //   handleMouseMove(
              //     strokeSize,
              //     canvasWhiteLines[filletSelected],
              //     canvasArcs[filletSelected],
              //     fillets[filletSelected],
              //     referenceRadius * conversionRatio,
              //     referenceAngle,
              //     angleDirection === true ? "0" : "1",
              //     undefined,
              //     e
              //   );
              // }}
              onTouchEnd={(e) => {
                handleTouchAndClickEnd(
                  canvasWhiteLines[filletSelected],
                  referenceRadius * conversionRatio,
                  referenceAngle,
                  undefined,
                  e
                );
                document.body.style.overflow = "initial";
              }}
              onTouchStart={(e) => {
                document.body.style.overflow = "hidden";
              }}
            />
          );
        })}
        <img
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${
            pivot
              ? pivot.protocol === 5
                ? `${pivot.controllerconfig.content.pivot_positions.latitude_center}, ${pivot.controllerconfig.content.pivot_positions.longitude_center}`
                : pivot.config.center
              : `${props.centerPosition[0]}, ${props.centerPosition[1]}`
          }&zoom=${zoomLevel}&size=${
            window.innerWidth > 800 ? 600 : 300
          }x300&maptype=satellite${process.env.MAPS_KEY}`}
          style={{
            width: window.innerWidth > 800 ? "600px" : "300px",
            height: "300px",
          }}
        />
      </div>
    </div>
  );
}

export default EndgunAngleSelector;

import { useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { arrUpdateOrInsert, objIsEmpty } from "../../utils/types";
import useNotify from "../tools/useNotify";
import { Irpd } from "../../redux/irpds/types";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { IrpdState, IrpdStateList } from "../../recoils/IrpdRecoil";
import routes from "../../routes/routes";

interface IrpdActions {
  get: () => {};
  patch: (irpdConfig: any, patchObject: any) => void;
}

const useIrpd = (
  farmID: number,
  irpdID: number,
  useV4?: boolean
): [AsyncStatus, Irpd | undefined, IrpdActions] => {
  const navigate = useNavigate();

  const [async, initLoading, finishLoading] = useAsyncStatus();

  const notify = useNotify();

  const [irpds, setIrpds] = useRecoilState(IrpdStateList);

  const [irpd, setIrpd] = useRecoilState(IrpdState);

  async function getIrpd() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/irpds/${irpdID}/`
        );

        if (checkExists(["data", response, "__cascade"])) {
          setIrpd(response.data);
          setIrpds(arrUpdateOrInsert(irpds, response.data, "id"));
        }
      } catch (err) {
        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        }
      } finally {
        finishLoading();
      }
    });
  }

  async function getIrpdV4() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(
          `v4/farms/${farmID}/irpds/${irpdID}/`
        );

        if (checkExists(["data", response, "__cascade"])) {
          setIrpd(response.data);
          setIrpds(arrUpdateOrInsert(irpds, response.data, "id"));
        }
      } catch (err) {
        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        }
      } finally {
        finishLoading();
      }
    });
  }

  async function patchIrpd(irpdConfig: any, patchObject: any) {
    await new Promise(async (resolve) => {
      try {
        await coreHTTPClient.post(
          `v3/farms/${farmID}/irpds/${irpdID}/${"config"}/`,
          irpdConfig
        );

        if (!objIsEmpty(patchObject)) {
          const response = await coreHTTPClient.patch(
            `v3/farms/${farmID}/irpds/${irpdID}/`,
            patchObject
          );

          if (checkExists(["data", response, "__cascade"]))
            setIrpds(arrUpdateOrInsert(irpds, response.data, "id"));

          notify("IRPD_BOX_CONFIGURATION_SAVED", "success");
        } else {
          await getIrpd();
        }
      } catch (err) {
        notify("IRPD_BOX_CONFIGURATION_ERROR", "error");
      }
    });
  }

  useEffect(() => {
    if (useV4) {
      getIrpdV4();
    } else {
      getIrpd();
    }
    // eslint-disable-next-line
  }, []);

  return [
    async,
    irpd,
    {
      get: getIrpd,
      patch: patchIrpd,
    },
  ];
};

export default useIrpd;

import React, { useContext } from "react";
import "./ExpandedContent.scss";
import { HistoricResultItem } from "../../../../../../../../hooks/models/usePivotHistoric";
import { PivotContext } from "../../../../SelectedPivot";
import ExpandedPainelStream from "./components/ExpandedPainelStream/ExpandedPainelStream";
import ExpandedGPSStream from "./components/ExpandedGPSStream/ExpandedGPSStream";
import ExpandedPivotActionVRIDeliver from "./components/ExpandedPivotActionVRIDeliver/ExpandedPivotActionVRIDeliver";
import ExpandedPivotAction from "./components/ExpandedPivotAction/ExpandedPivotAction";
import ExpandedPanelStreamV5 from "./components/ExpandedPanelStreamV5/ExpandedPanelStreamV5";
import ExpandedSimpleIrrigation from "./components/ExpandedSimpleIrrigation/ExpandedSimpleIrrigation";
import ExpandedSegmentIrrigation from "./components/ExpandedSegmentIrrigation/ExpandedSegmentIrrigation";
import ExpandedScheduleIrrigation from "./components/ExpandedScheduleIrrigation/ExpandedScheduleIrrigation";
import {
  PanelStreamOld,
  GPSStream,
  PivotAction,
  PivotActionVRIDeliverer,
  ControllerStream,
  SimpleIrrigation,
  ScheduleIrrigation,
  SegmentIrrigation,
  StopAction,
  PivotMaintenance,
} from "../../../../../../../../redux/pivots/types";

import ExpandedGPSStreamV5 from "./components/ExpandedGPSStreamV5/ExpandedGPSStreamV5";
import ExpandedStopAction from "./components/ExpandedStopAction/ExpandedStopAction";
import ExpandedMaintenance from "./components/ExpandedMaintenance/ExpandedMaintenance";
import { schedule2ActionVRI } from "../../utils/utils";

function ExpandedContent(props: {
  item: HistoricResultItem;
  isEmpty: boolean;
}) {
  const { pivot } = useContext(PivotContext);

  const { model } = props.item;
  if (props.isEmpty) return <></>;
  switch (model) {
    case "painel_stream":
      return (
        <ExpandedPainelStream
          painelStream={props.item.data as PanelStreamOld}
        />
      );

    case "gps_stream":
      return (
        <ExpandedGPSStream
          gpsStream={props.item.data as GPSStream}
          pivot={pivot}
        />
      );

    case "pivot_action":
      return (
        <ExpandedPivotAction
          pivotAction={props.item.data as PivotAction}
          pivot={pivot}
        />
      );
    case "pivot_action_vri_deliverer":
      return (
        <ExpandedPivotActionVRIDeliver
          pivotActionVRIDeliver={props.item.data as PivotActionVRIDeliverer}
          pivot={pivot}
          rounds={0}
        />
      );

    case "ControllerStream_panel":
      return (
        <ExpandedPanelStreamV5
          panelStreamV5={props.item.data as ControllerStream}
          pivot={pivot}
        />
      );

    case "ControllerStream_gps":
      return (
        <ExpandedGPSStreamV5
          panelStreamV5={props.item.data as ControllerStream}
          pivot={pivot}
        />
      );

    case "ControllerAction_simple":
      return (
        <ExpandedSimpleIrrigation
          simpleIrrigation={props.item.data as SimpleIrrigation}
          pivot={pivot}
        />
      );

    case "ControllerAction_schedule":
      if ((props.item.data as ScheduleIrrigation).angle_V5) {
        return (
          <ExpandedPivotActionVRIDeliver
            pivotActionVRIDeliver={schedule2ActionVRI(props.item.data)}
            pivot={pivot}
            rounds={
              // @ts-ignore
              props.item.data?.content.schedule_irrigation_parameters[0]
                ?.schedule_rounds
            }
          />
        );
      } else {
        return (
          <ExpandedScheduleIrrigation
            scheduleIrrigation={props.item.data as ScheduleIrrigation}
            pivot={pivot}
          />
        );
      }

    case "ControllerAction_segment":
      return (
        <ExpandedSegmentIrrigation
          segmentIrrigation={props.item.data as SegmentIrrigation}
        />
      );

    case "ControllerAction_stop":
      return <ExpandedStopAction stopAction={props.item.data as StopAction} />;

    case "maintenance":
      return (
        <ExpandedMaintenance
          maintenance={props.item.data as PivotMaintenance}
        />
      );

    case "CentralStream":
      return <></>;

    default:
      return null;
  }
}

export default ExpandedContent;

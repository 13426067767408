import { Device } from "@capacitor/device";

type Dimensions = {
  width: number;
  height: number;
};

let deviceWidthAndHeight: Dimensions | null = null; //Necessário para descobrir a versão de iPhone (se tem notch), sim, eu sei
let devicePlatform: "ios" | "android" | "electron" | "web" | null = null;

/**
 * Snackbar Provider
 * É um HoC no nosso código e depende do Notch. Ele inicializa primeiro que o device
 * e não é mais atualizado, por isso é necessário forçar um re-render.
 *
 * Assim, quando a variável está pronta, ele atualiza o Provider através de um
 * setState.
 */
let snackBarProviderRerender: () => any | null = null;
export function setSnackBarProviderRerenderFunction(rerenderFunc: () => any) {
  snackBarProviderRerender = rerenderFunc;
}

(async () => {
  let info = await Device.getInfo();

  devicePlatform = info.platform;
  deviceWidthAndHeight = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  //Faz o SnackBar dar re-render e consumir o estilo certo
  if (snackBarProviderRerender) snackBarProviderRerender();
})();

type NotchType = "minimal" | "full" | "none";

interface DeviceMap {
  [deviceName: string]: NotchType;
}

// Points, não pixels
let deviceWithNotchDimensionsArray: Dimensions[] = [
  { width: 428, height: 926 }, //12 Pro Max
  { width: 390, height: 844 }, //12
  { width: 414, height: 896 }, //11, Xr, 11 Pro Max, Xs Max
  { width: 375, height: 812 }, //11 Pro, X, Xs
];

// let deviceFullNotchMap: DeviceMap = {
//   //minimal

//   //full
//   "iPhone X": "full",
//   "iPhone Xs": "full",
//   "iPhone Xs Max": "full",
//   "iPhone 11 Pro Max": "full",
//   "iPhone 11": "full",
//   "iPhone 11 Pro": "full",
//   "iPhone Xʀ": "full",
//   "iPhone XR": "full",
// };

/**
 * Função para determinar qual device eu estou utilizando e qual o tamanho do notch.
 *
 * Em alguns modelos específicos, é full. Em todos os outros iOS é minimal.
 *
 * Algumas regras de dedo:
 * - Em CSS, definir um &__notch-[minimal | full | none?] para tratar o retorno dessa função direto no className
 * - O notch `minimal` é geralmente 10px a mais que o `none`, e o `full`é 30px a mais que o `minimal`
 *
 * @see deviceMap
 */
export function getDeviceNotch(): NotchType {
  if (!deviceWidthAndHeight) return "none";

  //Android não tem offset no notch
  if (devicePlatform == "android") return "none";

  if (devicePlatform == "ios") {
    let fullNotch = deviceWithNotchDimensionsArray.find(
      (d) =>
        d.width == deviceWidthAndHeight.width &&
        d.height == deviceWidthAndHeight.height
    );

    if (fullNotch) return "full";
    else return "minimal";
  }

  return "none";
}

export function getDevicePlatform() {
  return devicePlatform;
}

import React from "react";
import {
  PivotActionVRIDeliverer,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import {
  expandDateArgs,
  irrigationInjectionPump,
  pivotActionMode,
  pivotActionVRIDeliverEndgun,
  pivotActionVRIDirection,
} from "../../../../utils/utils";

import { PTPToMillimeter } from "../../../../../../../../../../utils/models/pivots";
import moment from "moment";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  pivotActionVRIDeliver: PivotActionVRIDeliverer;
  pivot: Pivot;
  rounds: number;
}

function ExpandedPivotActionVRIDeliver(props: Props) {
  const { pivotActionVRIDeliver, pivot, rounds } = props;
  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <div>
          <span>
            {i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE")}
            <b>
              {i18n("VALUE", [
                pivotActionMode(pivotActionVRIDeliver.actionrecipe?.mode),
              ])}
            </b>
          </span>
        </div>
        <div>
          <span>
            {i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED", [
              expandDateArgs(pivotActionVRIDeliver.created)[0],
            ])}
          </span>
        </div>
        <span>
          {i18n("HISTORIC_PIVOT_ACTION_VRI_START")}
          <b>
            {i18n("VALUE", [
              pivotActionVRIDeliver.start
                ? moment(pivotActionVRIDeliver.start)
                    .add("hours", 3)
                    .format("DD MMM - HH:mm")
                : i18n("HISTORIC_PIVOT_ACTION_VRI_START_NOW"),
            ])}
          </b>
        </span>
        {pivotActionVRIDeliver.manual ? (
          <span>{i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL")}</span>
        ) : (
          <span>
            {i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER")}
            <b>
              {i18n("VALUE", [
                `${pivotActionVRIDeliver.user_profile.username}`,
              ])}
            </b>
          </span>
        )}
      </div>
      <div className="expanded-content__group">
        {pivotActionVRIDeliver.actionrecipe?.speed_array.map((_, index) => {
          const pivotActionVRI = pivotActionVRIDeliver.actionrecipe;

          // Não imprime linhas com percentímetro igual a zero
          if (pivotActionVRI.speed_array[index] === 0) return;

          //args

          // DIRECTION
          const direction = pivotActionVRIDirection(
            pivotActionVRI.direction_array[index]
          );

          // ***********************************
          // PTP
          // const PTP = `${speedToPTP(
          //   pivot,
          //   pivotActionVRI.speed_array[index]
          // )} %`;
          // ***********************************

          const PTP = `${pivotActionVRI.speed_array[index]}%`;

          const millimeter = `${
            pivotActionVRI.mode === 2
              ? formatFloatValue(
                  PTPToMillimeter(pivot, pivotActionVRI.speed_array[index])
                )
              : 0
          }`;

          // ANGLE/ROUNDS
          let angle = `${pivotActionVRI.angle_array[index]}º`;
          if (rounds != 0 && rounds != undefined) {
            angle += ` | ${rounds} ${i18n("SEGMENT_IRRIGATION_FIELD_LAPS")}`;
          }

          // ENDGUN
          const endgun = pivotActionVRIDeliverEndgun(
            pivotActionVRI.endgun_array[index]
          );

          let finalText = i18n("HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION", [
            direction,
            PTP,
            millimeter,
            angle,
            endgun,
          ]);
          finalText =
            endgun == ""
              ? finalText.substring(0, finalText.length - 3)
              : finalText;

          return <span>{finalText}</span>;
        })}
        <br></br>
        {pivotActionVRIDeliver.content?.injection_pump_command?.command ==
          "1" && (
          <>
            <div>
              <span>
                {i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS")}
                <b>
                  {i18n("VALUE", [
                    irrigationInjectionPump(
                      pivotActionVRIDeliver.content?.injection_pump_command
                        ?.command
                    ),
                  ])}
                </b>
              </span>
            </div>
            <div>
              <span>
                {i18n("IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED")}
                <b>
                  {pivotActionVRIDeliver.content?.injection_pump_command?.note}
                </b>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpandedPivotActionVRIDeliver;

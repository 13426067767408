export enum AuthRoutes {
  LOGIN = "/login",
  LOGIN_UNAUTHORIZED = "/login?type=unauthorized",
  SIGNUP = "/v3/auth/register/:token",
  FORGET_PASSWORD = "/forget-password",
  PASSWORD_RECOVERY = "/recovery/:token/",
  PIVOT_EXCEL_DOWNLOAD = "/pivots/:pivot/excel/",
  IRPD_EXCEL_DOWNLOAD = "/irpds/:irpd/excel/",
  IRPD_EXCEL_DOWNLOAD_GH = "/irpds_gh/:irpd/excel/",
  METER_EXCEL_DOWNLOAD = "/metersystems/:metersystem/meter/:meter/excel/",
  METER_EXCEL_DOWNLOAD_GH = "/metersystems_gh/:metersystem/meter/:meter/excel/",
}

export enum DashboardRoutes {
  MAIN = "/dashboard",

  USER_HAS_NO_FARM = "/farms/none",

  NO_INTERNET_CONECTION = "/no-internet",
  PRIVACY_POLICY_NOT_ACCEPTED = "/privacy-policy-not-accepted",
  CODE404 = "/404",

  SELECTED_FARM = "/farms/:farm",
  EDIT_FARM = "/farms/:farm/edit",

  EDIT_DISABLED_FARM = "/farms/:farm/edit/disabled",

  SELECTED_PIVOT = "/farms/:farm/pivots/:pivot",
  EDIT_PIVOT = "/farms/:farm/pivots/:pivot/edit",

  SELECTED_BAUER_PIVOT = "/farms/:farm/bauer-pivot/:pivot",
  EDIT_BAUER_PIVOT = "/farms/:farm/bauer-pivot/:pivot/edit",

  ALERTS = "/farms/:farm/alerts",
  SELECTED_ALERT = "/farms/:farm/alerts/:alert",
  EDIT_ALERT = "/farms/:farm/alerts/:alert/edit",
  NEW_ALERT = "/farms/:farm/alerts/create/new",
  NOTIFICATIONS = "/farms/:farm/notifications",

  // RESELLER = "/reseller",
  // FIRST_STEPS = "/first-steps",
  // FIRST_STEPS_ADM = "/first-steps/adm",
  // FIRST_STEPS_EMPLOYEE = "/first-steps/employee",

  SUCCESS = "/success",
  CANCEL = "/cancel",

  CREATE_FARM = "/create-farm",

  PROFILE = "/me",
  FARM_USER_PROFILE = "/farms/:farm/user/:user",

  SELECTED_IRPD = "/farms/:farm/irpds/:irpd",
  EDIT_IRPD = "/farms/:farm/irpds/:irpd/edit",

  SELECTED_METER_SYSTEM = "/farms/:farm/metersystem/:metersystem",
  EDIT_METER_SYSTEM = "/farms/:farm/metersystem/:meter/edit",
  EDIT_IMETER = "/farms/:farm/metersystem/:metersystem/meter/:meter/edit",

  EDIT_REPEATER = "/farms/:farm/repeaters/:repeater/edit",

  DEVICES = "/farms/:farm/devices",

  FARM_DISABLED = "/farms/:farm/disabled",
}

export default {
  AUTH: AuthRoutes,
  DASHBOARD: DashboardRoutes,
};

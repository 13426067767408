import React, { useCallback, useState, useEffect, useContext } from "react";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import {
  checkIsOnIntervalNumeric,
  checkIsOnIntervalString,
  checkLatCoordinates,
  checkLngCoordinates,
  formatFloat,
} from "../../../../../../utils/checks";
import FormInputPosition from "./components/FormInputPosition/FormInputPosition";
import DraggableMap from "./components/DraggableMap/DraggableMap";
import FormCheckBox from "../../../../../../components/Form/FormCheckBox/FormCheckBox";
import moment from "moment";
import FormTimePicker from "../../../../../../components/Form/FormTimePicker/FormTimePicker";
import "./EditPivotForm.scss";
import { Pivot, PivotConfig } from "../../../../../../redux/pivots/types";
import {
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";

import Store, { ApplicationState } from "../../../../../../redux/index";
import { numPad } from "../../../../../../utils/types";
import { objPatch } from "../../../../../../utils/types";
import {
  CheckCircle,
  ArrowBack,
  FlashOn,
  Settings,
  Power,
} from "@material-ui/icons";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { ValidationErrorMessage } from "../../../../../../components/Form/types";
import Routes from "../../../../../../routes/routes";
import { useParams, useNavigate } from "react-router";
import DashboardBoxTitle from "../../../../components/DashboardBox/DashboardBoxTitle";
import PropTypes from "prop-types";
import { green, red } from "@material-ui/core/colors";
import { isMobile } from "../../../../../../mobileConfig";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import {
  handleNumberMask,
  validadeFloatInput,
  hasValidationErrors,
  countDecimals,
} from "../../../SelectedPivot/components/HistoricBox/utils/utils";

import { EditPivotContext } from "./EditPivotProvider/EditPivotProvider";
import { useDispatch, useSelector } from "react-redux";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import { showModal } from "../../../../../../redux/modal/actions";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { makeStyles } from "@material-ui/styles";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { updateOrInsertPivot } from "../../../../../../redux/pivots/actions";
import ShowHistoricFloatingLabel from "./ShowHistoricFloatingLabel";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PivotConfigFamily } from "../../../../../../recoils/PivotRecoil";
import {
  referenceMarkerSelector,
  centerMarkerSelector,
  initialMarkerSelector,
  isSetorialPivotState,
} from "../../../../../../recoils/DraggableMapRecoil";

import HistoricConfigMobileV4 from "./components/HistoricConfigMobile/HistoricConfigMobileV4";
import { MessageStatus, RadioStatuses } from "../../EditPivot";
import ChangeRadioField from "../ChangeRadioField/ChangeRadioField";
import ProtocolFooter from "../../../../../../components/ProtocolFooter/ProtocolFooter";
import { useCountdown } from "../../../../../../hooks/models/useCountdown";
import { nullAtom } from "../../../../../../recoils/NullRecoil";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import { ModalState } from "../../../../../../redux/modal/types";
import { superUserStatus } from "../../../../../../recoils/SuperUserStatusRecoil";
import InputTooltip from "../../../../../../components/InputTooltip/InputTooltip";
import { showLocationModal } from "../../../../../../redux/locationModal/actions";

const greenDot = require("../../../../../../assets/images/markers/light-green-dot.svg");
const orangeDot = require("../../../../../../assets/images/markers/light-orange-dot.svg");
const redDot = require("../../../../../../assets/images/markers/light-red-dot.svg");
const { SOCKET_SUFFIX } = process.env;

interface Props {
  pivot: Pivot;
  farmID: number;
  patchPivot: (pivotConfig: any, patchObject: any, isV5: boolean) => void;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles({
  tabBar: {
    height: "40px",
    border: "0px",
    boxShadow: "0px 0px 0px 0px ",
  },
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function NewEditPivotForm(props: Props) {
  const { pivot } = props;
  const notify = useNotify();
  const ctx = useContext(EditPivotContext);

  // state de validação
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    name: undefined,
    endgun: undefined,
    centerLat: undefined,
    centerLng: undefined,
    startRefLat: undefined,
    startRefLng: undefined,
    endRefLat: undefined,
    endRefLng: undefined,
    radius: undefined,
    totalRadius: undefined,
    area: undefined,
    speed: undefined,
    flow: undefined,
    potency: undefined,
    pumpTime: undefined,
    powerTime: undefined,
    powerRangeMax: undefined,
    powerRangeMin: undefined,
    hourRangeMax: undefined,
    hourRangeMin: undefined,
    clockDate: undefined,
    clockTime: undefined,
    valueKwhPeak: undefined,
    valueKwhOutOfPeak: undefined,
    setValidValueKwhReduced: undefined,
  });

  var modal: ModalState = useSelector((state: ApplicationState) => state.modal);

  const classes = useStyles(props);
  const [isConnected, socket] = useSocketIO();
  const [lastUpdate, setLastUpdate] = useState(
    moment(pivot.config.created).format("DD MMM YYYY HH:mm")
  );
  const [radioStatuses, setRadioStatuses] = useState<RadioStatuses>({
    monitor: props.pivot.config.gps_delivered
      ? MessageStatus.DELIVERED
      : MessageStatus.SENT,
    pump: props.pivot.config.pump_delivered
      ? MessageStatus.DELIVERED
      : MessageStatus.SENT,
    control: props.pivot.config.delivered
      ? MessageStatus.DELIVERED
      : MessageStatus.SENT,
  });
  const pivotID: number = parseInt(useParams().pivot, 10);
  const farmID: number = parseInt(useParams().farm, 10);
  const dispatch = useDispatch();

  const [counter, setCounter] = useCountdown("Pivot", String(pivotID));

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const setPivotConfig = useSetRecoilState(PivotConfigFamily(props.pivot.id));

  const [valueKwhPeak, setValueKwhPeak] = useState(
    props.pivot.config.kwh_peak ? props.pivot.config.kwh_peak : "1"
  );

  const setValidValueKwhPeak = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const [valueKwhOutOfPeak, setValueKwhOutOfPeak] = useState(
    props.pivot.config.kwh_out_of_peak
      ? props.pivot.config.kwh_out_of_peak
      : "1"
  );

  const setValidValueKwhOutOfPeak = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhOutOfPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhOutOfPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const [valueKwhReduced, setValueKwhReduced] = useState(
    props.pivot.config.kwh_reduced ? props.pivot.config.kwh_reduced : "1"
  );

  const setValidValueKwhReduced = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhReduced(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhReduced: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  useEffect(() => {
    if (pivot?.permission_level < 3) {
      navigate(
        Routes.DASHBOARD.SELECTED_PIVOT.replace(
          ":farm",
          String(farmID)
        ).replace(":pivot", String(pivot.id))
      );
    }
  }, [pivot]);

  useEffect(() => {
    socket.subscribe(`${SOCKET_SUFFIX}pivot@${pivot.id}`);

    socket.bind("pivot_config", (data: PivotConfig) => {
      setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
      if (
        data.delivered === false &&
        data.gps_delivered === false &&
        data.pump_delivered === false
      ) {
      } else {
        setRadioStatuses({
          control: data.delivered
            ? MessageStatus.DELIVERED
            : data.sent
            ? MessageStatus.SENT
            : MessageStatus.NOT_SENT,
          monitor: data.gps_delivered
            ? MessageStatus.DELIVERED
            : MessageStatus.SENT,
          pump: data.pump_delivered
            ? MessageStatus.DELIVERED
            : MessageStatus.SENT,
        });
      }
    });

    return () => {
      socket.unbind("pivot_config");
      socket.unsubscribe(`${SOCKET_SUFFIX}pivot@${pivot.id}`);
    };
  }, [isConnected]);

  //Configuração do tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [hideButtonSave, setHideButtonSave] = useState(false);
  const [event, setEvent] = useState(false);

  //Labels para validar os lat long sem comprometer o layout
  const { centerLatLabel, setCenterLatLabel } = ctx;
  const { centerLngLabel, setCenterLngLabel } = ctx;

  const { startLatLabel, setStartLatLabel } = ctx;
  const { startLngLabel, setStartLngLabel } = ctx;

  const { endLatLabel, setEndLatLabel } = ctx;
  const { endLngLabel, setEndLngLabel } = ctx;

  useEffect(() => {
    checkErros();
  }, [validationErrors]);

  const checkErros = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  // CAMPO NOME
  const { name, setName } = ctx;
  const setValidName = useCallback(
    (value) => {
      setName(value);
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 1, 16),
      });
    },
    [validationErrors]
  );

  // CAMPO CANHÃO FINAL
  const { endgun, setEndgun } = ctx;

  const { centerLat, setCenterLat } = ctx;
  const setValidCenterLat = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLatCoordinates(value)) {
        setCenterLat(value);
        setCenterLatLabel(value);
      }
      if (value.length === 0) {
        setCenterLat(pivot.config.center.split(",")[0]);
        setValidationErrors({
          ...validationErrors,
          centerLat: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          centerLat: undefined,
        });
      }
    },
    [validationErrors]
  );

  // LONGITUDE CENTRO
  const { centerLng, setCenterLng } = ctx;
  const setValidCenterLng = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setCenterLng(value);
        setCenterLngLabel(value);
      }
      if (value.length === 0) {
        setCenterLng(pivot.config.center.split(",")[1]);
        setValidationErrors({
          ...validationErrors,
          centerLng: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          centerLng: undefined,
        });
      }
    },
    [validationErrors]
  );

  // REFERENCIA INICIAL LATITUDE
  const { startRefLat, setStartRefLat } = ctx;
  const setValidStartRefLat = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLatCoordinates(value)) {
        setStartRefLat(value);
        setStartLatLabel(value);
      }
      if (value.length === 0) {
        setStartRefLat(pivot.config.reference.split(",")[0]);
        setValidationErrors({
          ...validationErrors,
          startRefLat: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          startRefLat: undefined,
        });
      }
    },
    [validationErrors]
  );

  // REFERENCIA INICIAL LONGITUDE
  const { startRefLng, setStartRefLng } = ctx;
  const setValidStartRefLng = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setStartRefLng(value);
        setStartLngLabel(value);
      }
      if (value.length === 0) {
        setStartRefLng(pivot.config.reference.split(",")[1]);
        setValidationErrors({
          ...validationErrors,
          startRefLng: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          startRefLng: undefined,
        });
      }
    },
    [validationErrors]
  );

  // REFERENCIA FINAL LATITUDE
  const { endRefLat, setEndRefLat } = ctx;
  const setValidEndRefLat = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setEndRefLat(value);
        setEndLatLabel(value);
      }
      if (value.length === 0) {
        setEndRefLat(pivot.config.end_reference.split(",")[0]);
        setValidationErrors({
          ...validationErrors,
          endRefLat: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          endRefLat: undefined,
        });
      }
    },
    [validationErrors]
  );

  // REFERENCIA FINAL LONGITUDE
  const { endRefLng, setEndRefLng } = ctx;
  const setValidEndRefLng = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setEndRefLng(value);
        setEndLngLabel(value);
      }
      if (value.length === 0) {
        setEndRefLng(pivot.config.end_reference.split(",")[1]);
        setValidationErrors({
          ...validationErrors,
          endRefLng: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          endRefLng: undefined,
        });
      }
    },
    [validationErrors]
  );

  // REFERENCIA COORDENADAS DO MARCADOR DE FIM SETORIAL RECOIL
  let [latLngEndRefRecoil, setLatLngEndRefRecoil] = useRecoilState(
    referenceMarkerSelector || nullAtom
  );

  // CHECKBOX SETORIAL
  const { setorial, setSetorial } = ctx;

  useEffect(() => {
    if (!setorial) {
      setLatLngEndRefRecoil((ll) => ({
        ...ll,
        lat: startRefLat,
        lng: startRefLng,
      }));
      setEndRefLat(startRefLat);
      setEndRefLng(startRefLng);
      setEndLatLabel(startLatLabel);
      setEndLngLabel(startLngLabel);
    }
  }, [setorial]);

  useEffect(() => {
    setEndRefLat(latLngEndRefRecoil?.lat || endRefLat);
    setEndRefLng(latLngEndRefRecoil?.lng || endRefLng);
    setEndLatLabel(latLngEndRefRecoil?.lat || endRefLat);
    setEndLngLabel(latLngEndRefRecoil?.lng || endRefLng);
  }, [latLngEndRefRecoil]);

  const [pivotCenterCoordsError, setPivotCenterCoordsError] = useState(false);
  const [pivotStartCoordsError, setPivotStartCoordsError] = useState(false);
  const [pivotEndCoordsError, setPivotEndCoordsError] = useState(false);

  // RAIO ATÉ A ULTIMA TORRE
  const { radius, setRadius } = ctx;
  const setValidRadius = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setRadius(value);
      setValidationErrors({
        ...validationErrors,
        radius: checkIsOnIntervalNumeric(value, 0, 1500, true),
      });
    },
    [validationErrors]
  );

  // RAIO IRRIGADO
  const { totalRadius, setTotalRadius } = ctx;
  const setValidTotalRadius = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setTotalRadius(value);
      setValidationErrors({
        ...validationErrors,
        totalRadius: checkIsOnIntervalNumeric(value, 0, 1200, true),
      });
    },
    [validationErrors]
  );

  // ÁREA IRRIGADA
  const { area, setArea } = ctx;
  const setValidArea = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setArea(value);
      setValidationErrors({
        ...validationErrors,
        area: checkIsOnIntervalNumeric(value, 0, 500, true),
      });
    },
    [validationErrors]
  );

  // VELOCIDADE DA ÚLTIMA TORRE
  const { speed, setSpeed } = ctx;
  const setValidSpeed = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setSpeed(value);
      setValidationErrors({
        ...validationErrors,
        speed: checkIsOnIntervalNumeric(value, 0, 500, true),
      });
    },
    [validationErrors]
  );

  // VAZÃO
  const { flow, setFlow } = ctx;
  const setValidFlow = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setFlow(value);
      setValidationErrors({
        ...validationErrors,
        flow: checkIsOnIntervalNumeric(value, 0, 1000, true),
      });
    },
    [validationErrors]
  );

  // POTÊNCIA DA BOMBA
  const { potency, setPotency } = ctx;
  const setValidPotency = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setPotency(value);
      setValidationErrors({
        ...validationErrors,
        potency: checkIsOnIntervalNumeric(value, 0, 500, true),
      });
    },
    [validationErrors]
  );

  // TEMPO DE PRESSURIZAÇÃO
  const { pumpTime, setPumpTime } = ctx;
  const setValidPumpTime = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setPumpTime(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        pumpTime: checkIsOnIntervalNumeric(value, 0, 30, true),
      });
    },
    [validationErrors]
  );

  // TEMPO DE ESTABILIZAÇÃO
  const { powerTime, setPowerTime } = ctx;
  const setValidPowerTime = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setPowerTime(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        powerTime: checkIsOnIntervalNumeric(value, 0, 30, true),
      });
    },
    [validationErrors]
  );

  // brand model
  const { brandModel, setBrandModel } = ctx;

  // other brand model
  const { otherBrand, setOtherBrand } = ctx;
  const setValidOtherBrand = useCallback(
    (value) => {
      setOtherBrand(value);
      setValidationErrors({
        ...validationErrors,
        otherBrand: checkIsOnIntervalString(value, 1, 20),
      });
    },
    [validationErrors]
  );

  // panel type
  const { panelType, setPanelType } = ctx;

  useEffect(() => {
    if (brandModel !== "Bauer" || otherBrand !== "") setPanelType("1");
  }, [panelType]);

  // CHECKBOX
  const { powerRange, setPowerRange } = ctx;
  const setValidPowerRange = useCallback((value) => {
    if (!value) {
      setPowerRangeMin("0");
      setPowerRangeMax("0");
    }

    setPowerRange(value);
  }, []);

  // TENSÃO DE TRABALHO INICIAL
  const { powerRangeMin, setPowerRangeMin } = ctx;
  const setValidPowerRangeMin = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setPowerRangeMin(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        powerRangeMin: checkIsOnIntervalNumeric(value, 0, 999, true),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // TENSÃO DE TRABALHO FINAL
  const { powerRangeMax, setPowerRangeMax } = ctx;
  const setValidPowerRangeMax = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setPowerRangeMax(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        powerRangeMax: checkIsOnIntervalNumeric(value, 0, 999, true),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const [hourRange, setHourRange] = useState(
    !(
      pivot.config.hour_range_min === "00:00:00" &&
      pivot.config.hour_range_max === "00:00:00"
    )
  );

  //HOURRANGE CHECK
  const setValidHourRange = useCallback(
    (value) => {
      setValidationErrors({
        ...validationErrors,
        ["hourRangeMax"]: undefined,
        ["hourRangeMin"]: undefined,
      });
      if (!value) {
        setHourRangeMin(
          moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
        );
        setHourRangeMax(
          moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
        );
      } else {
        setHourRangeMin(
          moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
        );
        setHourRangeMax(
          moment().set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          })
        );
      }
      setHourRange(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // HORA DE PICO INICIAL
  const [hourRangeMin, setHourRangeMin] = useState(
    moment().set({
      hour: parseInt(pivot.config.hour_range_min.split(":")[0]),
      minute: parseInt(pivot.config.hour_range_min.split(":")[1]),
      second: parseInt(pivot.config.hour_range_min.split(":")[2]),
      millisecond: 0,
    })
  );
  const setValidHourRangeMin = (value) => {
    const errorID = "hourRangeMin";

    setHourRangeMin(value);

    if (value.lenght === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_ERROR_HOUR_RANGE_MIN",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [errorID]: undefined,
      });
    }
  };

  // HORA DE PICO FINAL
  const [hourRangeMax, setHourRangeMax] = useState(
    moment().set({
      hour: parseInt(pivot.config.hour_range_max.split(":")[0]),
      minute: parseInt(pivot.config.hour_range_max.split(":")[1]),
      second: parseInt(pivot.config.hour_range_max.split(":")[2]),
      millisecond: 0,
    })
  );
  const setValidHourRangeMax = useCallback(
    (value) => {
      const errorID = "hourRangeMax";

      if (value > hourRangeMin) setHourRangeMax(value);

      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PIVOT_ERROR_HOUR_RANGE_MAX",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [hourRangeMin]
  );

  const [hasCustomClock, setHasCustomClock] = useState(false);

  const setValidDiffClock = useCallback(
    (value) => {
      setValidationErrors({
        ...validationErrors,
        ["clockDate"]: undefined,
        ["clockTime"]: undefined,
      });
      if (value) {
        setClockTime(
          moment().set({
            hour: moment().hour(),
            minute: moment().minute(),
            second: moment().second(),
            millisecond: moment().millisecond(),
          })
        );
        setClockDate(moment());
      } else {
        const zero = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        setClockTime(zero);
        setClockDate(zero);
      }
      setHasCustomClock(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Clock

  const [clockTime, setClockTime] = useState(
    moment().set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: moment().second(),
      millisecond: moment().millisecond(),
    })
  );

  const setValidclockTime = (value) => {
    const errorID = "clockTime";

    setHourRangeMin(value);

    if (value.lenght === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_ERROR_CLOCK_TIME",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        [errorID]: undefined,
      });
    }
  };

  const [clockDate, setClockDate] = useState(moment());
  const setValidClockDate = useCallback(
    (value) => {
      let errorID = "clockDate";
      setClockDate(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PIVOT_ERROR_CLOCK_DATE",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        navigate(
          Routes.DASHBOARD.SELECTED_PIVOT.replace(
            ":farm",
            String(farmID)
          ).replace(":pivot", String(pivot.id))
        );
      }}
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  // Mostra o nome da fazenda e pivô no título da página
  useEffect(() => {
    const farm = Store().store.getState().farm.data;
    pivot
      ? (document.title =
          (farm.name !== undefined ? farm.name + " - " : "") + pivot.name)
      : (document.title = "Irricontrol");
  }, [pivot, pivot.name]);

  async function _patchPivot(pivotObject) {
    await coreHTTPClient
      .patch(`v3/farms/${farmID}/pivots/${pivotID}/`, pivotObject)
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        setLoading(false);
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
        setCounter(0);
        setLoading(false);
      });
  }

  // check super user status
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);
  // end check super user status

  async function patchPivot(pivotConfig, deviceID, patchPivotObject) {
    notify("EDIT_PIVOT_SAVE_AWAIT", "info");
    if (!superUser) {
      setCounter(30);
      setLoading(true);
    }
    setRadioStatuses({
      control: MessageStatus.WAITING,
      monitor: MessageStatus.WAITING,
      pump: MessageStatus.WAITING,
    });
    await coreHTTPClient
      .post(`v3/farms/${farmID}/pivots/${pivotID}/config/`, pivotConfig)
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        setPivotConfig(res.data);
        _patchPivot(patchPivotObject);
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
        setCounter(0);
        setLoading(false);
      });
  }

  const saveButtonHandler = () => {
    //
    //
    if (!showErrorMessages()) {
      const patchObject = objPatch(pivot, {
        name: name,
        potency: parseFloat(potency),
        brand_model: brandModel === "Other" ? otherBrand : brandModel,
        panel_type: panelType,
      });

      let newClockDate = clockDate.clone();

      const rtcRef = hasCustomClock
        ? newClockDate
            .subtract(3, "hours")
            .set({ second: 0, millisecond: 0 })
            .toISOString()
        : moment()
            .subtract(3, "hours")
            .set({ second: 0, millisecond: 0 })
            .toISOString();

      const newPivotConfig = {
        endgun: parseInt(endgun),
        setorial: setorial,
        center: `${parseFloat(centerLat).toFixed(7)},${parseFloat(
          centerLng
        ).toFixed(7)}`,
        reference: `${parseFloat(startRefLat).toFixed(7)},${parseFloat(
          startRefLng
        ).toFixed(7)}`,
        end_reference: setorial
          ? `${parseFloat(endRefLat).toFixed(7)},${parseFloat(
              endRefLng
            ).toFixed(7)}`
          : "0,0",
        radius: parseFloat(radius).toFixed(2),
        total_radius: parseFloat(totalRadius).toFixed(2),
        area: parseFloat(area).toFixed(2),
        speed: parseFloat(speed).toFixed(2),
        flow: parseFloat(flow).toFixed(2),
        pump_time: parseInt(pumpTime),
        power_time: parseInt(powerTime),
        hour_range_min: `${numPad(hourRangeMin.hour(), 2)}:${numPad(
          hourRangeMin.minutes(),
          2
        )}:${numPad(hourRangeMin.seconds(), 2)}`,
        hour_range_max: `${numPad(hourRangeMax.hour(), 2)}:${numPad(
          hourRangeMax.minutes(),
          2
        )}:${numPad(hourRangeMax.seconds(), 2)}`,
        power_range_min: parseInt(powerRangeMin),
        power_range_max: parseInt(powerRangeMax),
        rtc: rtcRef,
        kwh_peak: parseFloat(valueKwhPeak),
        kwh_out_of_peak: parseFloat(valueKwhOutOfPeak),
        kwh_reduced: parseFloat(valueKwhReduced),
      };

      patchPivot(newPivotConfig, pivot.id, patchObject);
    }

    setValue(0);
  };

  const saveButtonComponent = (
    <Button
      color="primary"
      endIcon={
        loading || counter !== 0 ? (
          <CircularProgress size={20} style={{ marginLeft: "8px" }} />
        ) : (
          <CheckCircle />
        )
      }
      onClick={saveButtonHandler}
      disabled={
        counter !== 0 ||
        hideButtonSave ||
        loading ||
        pivotStartCoordsError ||
        pivotEndCoordsError ||
        pivotCenterCoordsError
      }
    >
      {counter !== 0 ? `${counter}` : <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>}
    </Button>
  );

  const showHistoricConfigModal = (pivotID, farmID, ctx, pivot) => {
    dispatch(
      showModal({
        content: (
          <HistoricConfigMobileV4
            farmID={farmID}
            pivotID={pivotID}
            pivot={pivot}
            ctx={ctx}
          />
        ),
        title: "EDIT_PIVOT_LABEL_SETTING_HISTORIC",
      })
    );
  };
  const markerButton = (
    imageUrl: string,
    setStartRefLat,
    setStartRefLng,
    recoilSelector,
    disabled: boolean = false
  ) => (
    <button
      className="edit-pivot-form__3d-button"
      onClick={() => {
        if (!disabled) {
          dispatch(
            showLocationModal({
              content: (
                <GeolocationGetter
                  setLatitude={setStartRefLat}
                  setLongitude={setStartRefLng}
                  recoilSelector={recoilSelector}
                />
              ),
            })
          );
        }
      }}
    >
      <img src={imageUrl} />
    </button>
  );

  const markers = setorial
    ? [
        {
          lat: {
            state: centerLat,
            setFunction: setValidCenterLat,
          },
          lng: {
            state: centerLng,
            setFunction: setValidCenterLng,
          },
          markerUrl: greenDot,
          key: "center",
          event: {
            state: event,
            setFunction: setEvent,
          },
        },
        {
          lat: {
            state: startRefLat,
            setFunction: setValidStartRefLat,
          },
          lng: {
            state: startRefLng,
            setFunction: setValidStartRefLng,
          },
          markerUrl: orangeDot,
          key: "start-ref",
          event: {
            state: event,
            setFunction: setEvent,
          },
        },
        {
          lat: {
            state: endRefLat,
            setFunction: setValidEndRefLat,
          },
          lng: {
            state: endRefLng,
            setFunction: setValidEndRefLng,
          },
          markerUrl: redDot,
          key: "end-ref",
          event: {
            state: event,
            setFunction: setEvent,
          },
        },
      ]
    : [
        {
          lat: {
            state: centerLat,
            setFunction: setValidCenterLat,
          },
          lng: {
            state: centerLng,
            setFunction: setValidCenterLng,
          },
          markerUrl: greenDot,
          key: "center",
          event: {
            state: event,
            setFunction: setEvent,
          },
        },
        {
          lat: {
            state: startRefLat,
            setFunction: setValidStartRefLat,
          },
          lng: {
            state: startRefLng,
            setFunction: setValidStartRefLng,
          },
          markerUrl: orangeDot,
          key: "start-ref",
          event: {
            state: event,
            setFunction: setEvent,
          },
        },
      ];

  return (
    <div className="general-form__container">
      <DashboardBox
        disableFade={true}
        leftElement={backButton}
        centerElement={<DashboardBoxTitle title={"EDIT_PIVOT_LABEL"} />}
        rightElement={saveButtonComponent}
        fixedHeight
      >
        <div
          style={{
            backgroundColor: "white",
            display: "grid",
            gridTemplateColumns: "12fr",
            height: "100%",
          }}
        >
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            aria-label="Edit Farm"
            centered={isMobile() ? false : true}
            variant={"scrollable"}
          >
            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_GENERAL")}</>}
              {...selectedTab(0)}
              icon={
                validationErrors["name"] ||
                validationErrors["endgun"] ||
                validationErrors["pumpTime"] ||
                pivotStartCoordsError ||
                pivotEndCoordsError ||
                pivotCenterCoordsError ||
                validationErrors["radius"] ||
                validationErrors["totalRadius"] ||
                validationErrors["area"] ||
                validationErrors["speed"] ||
                validationErrors["area"] ||
                validationErrors["flow"] ? (
                  <Settings
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Settings
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
            />
            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_POWER")}</>}
              {...selectedTab(1)}
              icon={
                validationErrors["potency"] ||
                validationErrors["powerTime"] ||
                validationErrors["powerRangeMax"] ||
                validationErrors["powerRangeMin"] ? (
                  <FlashOn
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <FlashOn
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
            />
            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_PAUSE_TIME")}</>}
              {...selectedTab(2)}
              icon={
                validationErrors["hourRangeMax"] ||
                validationErrors["hourRangeMin"] ||
                validationErrors["clockDate"] ||
                validationErrors["clockTime"] ? (
                  <Power
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Power
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
            />
          </Tabs>

          <div
          // style={{
          //   maxHeight: isMobile() ? "" : "calc(100vh - 150px)",
          //   overflow: "auto",
          // }}
          >
            <TabPanel value={value} index={0}>
              <div className="edit-pivot-form__last-settings">
                <span>{i18n("EDIT_PIVOT_LAST_SETTINGS", [lastUpdate])}</span>
                {pivot.config.manual ? (
                  <span>{i18n("EDIT_PIVOT_MANUAL")}</span>
                ) : (
                  <span>{i18n("EDIT_PIVOT_BY_WEB")}</span>
                )}
              </div>
              <div className="general-form__row-3-3-3-3">
                <ChangeRadioField
                  radioID={pivot?.base_radio_id}
                  farmID={farmID}
                  deviceID={pivotID}
                  label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
                  status={MessageStatus.NOT_SENT}
                  deviceType="PIVOT"
                  locked
                />
                {pivot?.control_radio_id ? (
                  <ChangeRadioField
                    radioID={pivot?.control_radio_id}
                    farmID={farmID}
                    deviceID={pivotID}
                    label={i18n("EDIT_PIVOT_DELIVERED_LABEL")}
                    status={radioStatuses.control}
                    deviceType="PIVOT"
                    deviceSubType="control"
                  />
                ) : null}
                <ChangeRadioField
                  radioID={pivot?.monitor_radio_id}
                  farmID={farmID}
                  deviceID={pivotID}
                  label={i18n("EDIT_PIVOT_GPS_LABEL")}
                  status={radioStatuses.monitor}
                  deviceType="PIVOT"
                  deviceSubType="monitor"
                />
                {pivot?.pump_radio_id ? (
                  <ChangeRadioField
                    radioID={pivot?.pump_radio_id}
                    farmID={farmID}
                    deviceID={pivotID}
                    label={i18n("EDIT_PIVOT_PUMP_LABEL")}
                    status={radioStatuses.pump}
                    deviceType="PIVOT"
                    deviceSubType="pump"
                  />
                ) : null}
                {/* <FormInput
                  id="pivot-painel"
                  error={undefined}
                  label={"EDIT_PIVOT_DELIVERED_LABEL"}
                  state={[controller, setController]}
                  disabled
                  endAdornment={
                    <div className="edit-pivot-form-v5__information">
                      {getRadioStatusIcon(radioStatuses.central)}
                    </div>
                  }
                />
                <FormInput
                  id="pivot-painel"
                  error={undefined}
                  label={"EDIT_PIVOT_GPS_LABEL"}
                  state={[gps, setGps]}
                  disabled
                  endAdornment={
                    <div className="edit-pivot-form-v5__information">
                      {getRadioStatusIcon(radioStatuses.gps)}
                    </div>
                  }
                /> */}
                {/* {pump ? (
                  <FormInput
                    id="pivot-painel"
                    error={undefined}
                    label={"EDIT_PIVOT_PUMP_LABEL"}
                    state={[pump, setPump]}
                    disabled
                    endAdornment={
                      <div className="edit-pivot-form-v5__information">
                        {getRadioStatusIcon(radioStatuses.pump)}
                      </div>
                    }
                  />
                ) : null} */}
              </div>

              <div className="general-form__row-4-4-4">
                <FormInput
                  id="pivot-name"
                  helperText={validationErrors["name"]}
                  label={"PIVOT_FIELD_NAME"}
                  state={[name, setValidName]}
                />

                <FormSelect
                  id="pivot-config-endgun"
                  label={"PIVOT_CONFIG_FIELD_ENDGUN"}
                  state={[endgun, setEndgun]}
                  values={[
                    ["0", "PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT"],
                    ["1", "PIVOT_CONFIG_VALUE_ENDGUN_ON"],
                    ["2", "PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER"],
                  ]}
                />

                <FormCheckBox
                  state={[setorial, setSetorial]}
                  label={"PIVOT_CONFIG_FIELD_SETORIAL"}
                  recoilState={isSetorialPivotState}
                  labelTooltip={"PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP"}
                />
              </div>
              <div
                className="general-form__row-4-4-4"
                style={{ marginTop: "15px" }}
              >
                <FormInputPosition
                  setError={setPivotCenterCoordsError}
                  id="pivot-config-center"
                  markerUrl={markerButton(
                    greenDot,
                    setCenterLat,
                    setCenterLng,
                    centerMarkerSelector
                  )}
                  label={"PIVOT_CONFIG_FIELD_CENTER"}
                  labelTooltip={"PIVOT_CONFIG_FIELD_CENTER_TOOLTIP"}
                  recoilSelector={centerMarkerSelector}
                  lat={[centerLatLabel, setValidCenterLat]}
                  lng={[centerLngLabel, setValidCenterLng]}
                />
                <FormInputPosition
                  setError={setPivotStartCoordsError}
                  id="pivot-config-start-ref"
                  markerUrl={markerButton(
                    orangeDot,
                    setStartRefLat,
                    setStartRefLng,
                    initialMarkerSelector
                  )}
                  label={"PIVOT_CONFIG_FIELD_REFERENCE_START"}
                  labelTooltip={"PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP"}
                  lat={[startLatLabel, setValidStartRefLat]}
                  recoilSelector={initialMarkerSelector}
                  lng={[startLngLabel, setValidStartRefLng]}
                />
                <FormInputPosition
                  setError={setPivotEndCoordsError}
                  id="pivot-config-end-ref"
                  markerUrl={markerButton(
                    redDot,
                    setEndRefLat,
                    setEndRefLng,
                    referenceMarkerSelector,
                    !setorial
                  )}
                  label={"PIVOT_CONFIG_FIELD_REFERENCE_END"}
                  labelTooltip={"PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP"}
                  lat={[endLatLabel, setValidEndRefLat]}
                  lng={[endLngLabel, setValidEndRefLng]}
                  recoilSelector={referenceMarkerSelector}
                  disabled={!setorial}
                />
              </div>
              <div className="edit-pivot-form__map">
                <DraggableMap
                  _setExperimentalRecoil
                  markers={markers}
                  zoomScroll={false}
                />
              </div>

              <div className="general-form__row-4-4-4">
                <FormInput
                  id="pivot-config-radius"
                  helperText={validationErrors["radius"]}
                  label={"PIVOT_CONFIG_FIELD_RADIUS"}
                  state={[radius, setValidRadius]}
                  tooltip="PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP"
                  inputMode="numeric"
                  endAdornment="m"
                />
                <FormInput
                  id="pivot-config-total-radius"
                  helperText={validationErrors["totalRadius"]}
                  label={"PIVOT_CONFIG_FIELD_TOTAL_RADIUS"}
                  state={[totalRadius, setValidTotalRadius]}
                  inputMode="numeric"
                  endAdornment="m"
                />
                <FormInput
                  id="pivot-config-area"
                  helperText={validationErrors["area"]}
                  label={"PIVOT_CONFIG_FIELD_AREA"}
                  state={[area, setValidArea]}
                  inputMode="numeric"
                  endAdornment="ha"
                />
              </div>

              <div className="general-form__row-6-6">
                <FormInput
                  id="pivot-config-speed"
                  helperText={validationErrors["speed"]}
                  label={"PIVOT_CONFIG_FIELD_SPEED"}
                  state={[speed, setValidSpeed]}
                  tooltip="PIVOT_CONFIG_FIELD_SPEED_TOOLTIP"
                  inputMode="numeric"
                  endAdornment="m/h"
                />
                <FormInput
                  id="pivot-config-flow"
                  helperText={validationErrors["flow"]}
                  label={"PIVOT_CONFIG_FIELD_FLOW"}
                  state={[flow, setValidFlow]}
                  inputMode="numeric"
                  endAdornment="m³/h"
                />
              </div>

              <div className="general-form__row-6-6">
                <FormInput
                  id="pivot-config-pump-time"
                  helperText={validationErrors["pumpTime"]}
                  label={"PIVOT_CONFIG_FIELD_PUMP_TIME"}
                  state={[pumpTime, setValidPumpTime]}
                  tooltip="PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP"
                  endAdornment="min"
                  inputMode="numeric"
                />
                <FormInput
                  id="pivot-config-power-time"
                  helperText={validationErrors["powerTime"]}
                  label={"PIVOT_CONFIG_FIELD_POWER_TIME"}
                  state={[powerTime, setValidPowerTime]}
                  tooltip="PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP"
                  endAdornment="min"
                  inputMode="numeric"
                />
                <FormSelect
                  id="pivot-brand-model"
                  label={"PIVOT_CONFIG_V5_BRAND_MODEL"}
                  state={[brandModel, setBrandModel]}
                  values={[
                    ["Bauer", "CREATE_BRAND_MODEL_BAUER"],
                    ["Carborundum", "CREATE_BRAND_MODEL_CARBORUNDUM"],
                    ["Fockink", "CREATE_BRAND_MODEL_FOCKINK"],
                    ["Irrigabras", "CREATE_BRAND_MODEL_IRRIGABRAS"],
                    ["Krebs", "CREATE_BRAND_MODEL_KREBS"],
                    ["Lindsay", "CREATE_BRAND_MODEL_LINDSAY"],
                    ["Reinke", "CREATE_BRAND_MODEL_REINKE"],
                    ["Valley", "CREATE_BRAND_MODEL_VALLEY"],
                    ["Other", "CREATE_BRAND_MODEL_OTHER"],
                  ]}
                />

                {brandModel !== "Bauer" &&
                brandModel !== "Carborundum" &&
                brandModel !== "Fockink" &&
                brandModel !== "Irrigabras" &&
                brandModel !== "Krebs" &&
                brandModel !== "Lindsay" &&
                brandModel !== "Reinke" &&
                brandModel !== "Valley" &&
                brandModel !== "Other"
                  ? setBrandModel("Other")
                  : null}

                {brandModel === "Other" ? (
                  <FormInput
                    id="otherBrand"
                    label="CREATE_BRAND_MODEL_INSERT"
                    state={[otherBrand, setValidOtherBrand]}
                    helperText={validationErrors["otherBrand"]}
                  />
                ) : null}

                <FormSelect
                  id="panelTypeSelect"
                  label="CREATE_DEVICE_MODAL_PANEL_TYPE"
                  newLabel="CREATE_DEVICE_MODAL_PANEL_TYPE"
                  state={[panelType, setPanelType]}
                  values={
                    panelType === null
                      ? [
                          [null, "CREATE_DEVICE_MODAL_OTHER"],
                          ["1", "CREATE_DEVICE_MODAL_NEXUS"],
                          ["2", "CREATE_DEVICE_MODAL_SMARTCONNECT"],
                        ]
                      : brandModel !== "Bauer"
                      ? [["1", "CREATE_DEVICE_MODAL_NEXUS"]]
                      : [
                          ["1", "CREATE_DEVICE_MODAL_NEXUS"],
                          ["2", "CREATE_DEVICE_MODAL_SMARTCONNECT"],
                        ]
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="general-form__row-3-3-3-3">
                <FormInput
                  id="pivot-potency"
                  helperText={validationErrors["potency"]}
                  label={"PIVOT_CONFIG_FIELD_POTENCY"}
                  inputMode="numeric"
                  state={[String(potency), setValidPotency]}
                  endAdornment="kW"
                />
                <FormCheckBox
                  state={[powerRange, setValidPowerRange]}
                  label={"PIVOT_CONFIG_FIELD_POWER_RANGE"}
                  labelTooltip={"PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP"}
                />
                <FormInput
                  id="pivot-config-power-range-min"
                  helperText={validationErrors["powerRangeMin"]}
                  label={"PIVOT_CONFIG_FIELD_POWER_RANGE_MIN"}
                  state={[
                    powerRange ? powerRangeMin : "",
                    setValidPowerRangeMin,
                  ]}
                  disabled={!powerRange}
                  endAdornment="V"
                  inputMode="numeric"
                />
                <FormInput
                  id="pivot-config-power-range-max"
                  helperText={validationErrors["powerRangeMax"]}
                  label={"PIVOT_CONFIG_FIELD_POWER_RANGE_MAX"}
                  state={[
                    powerRange ? powerRangeMax : "",
                    setValidPowerRangeMax,
                  ]}
                  disabled={!powerRange}
                  endAdornment="V"
                  inputMode="numeric"
                />
              </div>
            </TabPanel>

            {/* POWER RANGE */}
            <TabPanel value={value} index={2}>
              <div>
                <h4>{i18n("EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE")}</h4>

                <div className="general-form__row-4-4-4">
                  <FormInput
                    id="standard-basic-hp"
                    helperText={validationErrors["valueKwhPeak"]}
                    label="BASIC_INFO_BOX_PONTA"
                    state={[valueKwhPeak, setValidValueKwhPeak]}
                    inputMode="numeric"
                    startAdornment={i18n("CURRENCY")}
                  />

                  <FormInput
                    id="standard-basic-hfp"
                    helperText={validationErrors["valueKwhOutOfPeak"]}
                    label="BASIC_INFO_BOX_FORA_DE_PONTA"
                    state={[valueKwhOutOfPeak, setValidValueKwhOutOfPeak]}
                    inputMode="numeric"
                    startAdornment={i18n("CURRENCY")}
                  />

                  <FormInput
                    id="standard-basic-hr"
                    helperText={validationErrors["valueKwhReduced"]}
                    label="BASIC_INFO_BOX_REDUZIDO"
                    state={[valueKwhReduced, setValidValueKwhReduced]}
                    inputMode="numeric"
                    startAdornment={i18n("CURRENCY")}
                  />
                </div>
              </div>

              <div className="general-form__row-2-5-5">
                <FormCheckBox
                  state={[hourRange, setValidHourRange]}
                  label={"PIVOT_CONFIG_FIELD_HOUR_RANGE"}
                />

                <FormTimePicker
                  id="pivot-config-hour-range-min"
                  error={validationErrors["hourRangeMin"]}
                  disabled={!hourRange}
                  label={"PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN"}
                  state={[
                    hourRange ? hourRangeMin : null,
                    setValidHourRangeMin,
                  ]}
                />

                <FormTimePicker
                  id="pivot-config-hour-range-max"
                  error={validationErrors["hourRangeMax"]}
                  disabled={!hourRange}
                  label={"PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX"}
                  state={[
                    hourRange ? hourRangeMax : null,
                    setValidHourRangeMax,
                  ]}
                />
              </div>
              <div className="general-form__row-2-5-5">
                <FormCheckBox
                  state={[hasCustomClock, setValidDiffClock]}
                  label={"EDIT_PIVOT_CLOCK_LABEL"}
                />

                <FormDateTimePicker
                  id="final-hour"
                  label={"PIVOT_CONFIG_FIELD_DATE_TIME"}
                  state={[!hasCustomClock ? null : clockDate, setClockDate]}
                  disabled={!hasCustomClock}
                />
              </div>
            </TabPanel>
          </div>
          <ProtocolFooter protocol={pivot.protocol} />
        </div>
      </DashboardBox>
      {isMobile() && (
        <div
          onClick={() => {
            showHistoricConfigModal(pivot.id, props.farmID, ctx, pivot);
          }}
          style={{ display: modal.active ? "none" : "initial" }}
        >
          <ShowHistoricFloatingLabel className={classes.tabBar} />
        </div>
      )}
    </div>
  );
}

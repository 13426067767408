import React, { useState, useEffect } from "react";
import {
  Button,
  MuiThemeProvider,
  LinearProgress,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "./CreateUser.scss";
import { coreHTTPClient } from "../../../../../../../../../../services/webclient";
import { useDispatch, useSelector } from "react-redux";
import useNotify from "../../../../../../../../../../hooks/tools/useNotify";
import {
  hideLoading,
  showLoading,
} from "../../../../../../../../../../redux/loading/actions";
import { hideModal } from "../../../../../../../../../../redux/modal/actions";
import { blueTheme } from "../Permission/Permission";
import Store, {
  ApplicationState,
} from "../../../../../../../../../../redux/index";

import { getFarm as getFarmSelector } from "../../../../../../../../../../redux/farms/selector";
import { validateUsername } from "../../../../../../../../../Auth/SignUp/SignUp";
import useFarm from "../../../../../../../../../../hooks/models/useFarm";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import EmailIcon from "@material-ui/icons/Email";
import { matchSorter } from "match-sorter";

interface Props {
  farmID: number;
  getUsersFarm: any;
  // centralCode: string;
}

function ValidationEmail(value: string): boolean {
  if (
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ) &&
    value !== ""
  ) {
    return true;
  }

  return false;
}

async function RegisterUser(
  username_or_email: string,
  admin: boolean,
  farmID: number,
  notify: Function,
  dispatch: Function,
  getUsersFarm: any,
  getFarm: any,
  setLoading: Function
) {
  await new Promise(async (resolve) => {
    try {
      dispatch(showLoading());

      var body: any = {
        username_or_email: username_or_email,
        administrator: admin,
      };

      // Injeto no body email ou username devido ao serializer do User no back pedir um desses atributos
      // Se eu não injetar o convite entra na lista de pendentes porém eu não tenho identificação pelo username nem pelo email o qual foi enviado
      if (username_or_email.includes("@")) {
        body = { ...body, email: username_or_email };
      } else {
        body = { ...body, username: username_or_email };
      }

      await coreHTTPClient.post(`v3/farms/${farmID}/users/`, body);
      dispatch(hideLoading());
      dispatch(hideModal());
      notify(
        admin
          ? "UPDATE_USER_TO_ADMINISTRATOR_SUCCESS"
          : "CREATE_USER_MODAL_SUCCESS",
        "success"
      );
      if (admin) getFarm();
      getUsersFarm();
    } catch (error) {
      if (error.response?.status === 403) {
        notify("CREATE_USER_MODAL_ALREADY_REGISTERED", "error");
        setLoading(false);
      } else if (error.response?.data?.code === 404) {
        notify("CREATE_USER_MODAL_404", "error");
        setLoading(false);
      } else {
        notify("CREATE_USER_MODAL_ERROR", "error");
        setLoading(false);
      }
      dispatch(hideLoading());
    }
  });
}

function CreateUser(props: Props) {
  let [admin, setAdmin] = useState<boolean>(false);
  let [usernameOrEmail, setUsernameOrEmail] = useState<string>("");
  const [usernameOrEmailOptions, setUsernameOrEmailOptions] = useState<any>([]);
  const [findingLoading, setFindingLoading] = useState<boolean>(false);
  let [disabled, setDisabled] = useState<boolean>(true);
  const reseller = Store().store.getState().userID.reseller;

  const [loading, setLoading] = useState(false);

  let [_, __, farmActions] = useFarm(props.farmID);

  const farm = useSelector((state: ApplicationState) =>
    getFarmSelector(state, props.farmID)
  );

  const dispatch = useDispatch();

  const notify = useNotify();

  const handleAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdmin(event.target.checked);
  };

  useEffect(() => {
    if (usernameOrEmail === "") setDisabled(true);
    else setDisabled(false);
  }, [usernameOrEmail]);

  const findUser = (newValue) => {
    setFindingLoading(true);
    coreHTTPClient
      .get(`v3/auth/find/?username_or_email=${newValue}`)
      .then((res) => {
        setUsernameOrEmailOptions(res?.data || []);
      })
      .catch((err) => {
        notify("CREATE_USER_MODAL_FIND_USER_ERROR", "error");
      })
      .finally(() => {
        setFindingLoading(false);
      });
  };

  const handleOnInputChange = (e, newValue) => {
    if (newValue && newValue.length > 3) {
      findUser(newValue);
    }

    if (!newValue || newValue.length < 4) {
      setUsernameOrEmailOptions([]);
    }

    setUsernameOrEmail(newValue || "");
  };

  const userNotFoundText = () => {
    if (usernameOrEmail.length < 4) {
      return <span>{i18n("CREATE_USER_MODAL_KEEP_TYPING")}</span>;
    } else if (ValidationEmail(usernameOrEmail)) {
      return (
        <div style={{ textAlign: "center" }}>
          <EmailIcon style={{ verticalAlign: "middle", marginRight: 5 }} />
          <span style={{ marginBottom: 5 }}>{usernameOrEmail}</span>
          <div>
            <MuiThemeProvider theme={blueTheme}>
              <Button
                onMouseDown={createUser}
                variant="contained"
                color="primary"
              >
                {i18n("CREATE_USER_MODAL_SEND_INVITE")}
              </Button>
            </MuiThemeProvider>
          </div>
        </div>
      );
    } else {
      return <span>{i18n("CREATE_USER_MODAL_EMAIL_NOT_FOUND")}</span>;
    }
  };

  // Busca inteligente(fuzzy search) limitada a 5 resultados(somente mostra 5 resultados por busca)
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ["full_name", "email", "user__username"],
    }).slice(0, 5);

  const createUser = () => {
    setLoading(true);
    if (ValidationEmail(usernameOrEmail) || validateUsername(usernameOrEmail)) {
      RegisterUser(
        ValidationEmail(usernameOrEmail)
          ? usernameOrEmail?.toLowerCase()
          : usernameOrEmail,
        admin,
        props.farmID,
        notify,
        dispatch,
        props.getUsersFarm,
        farmActions.get,
        setLoading
      );
    } else {
      setLoading(false);
      notify("CREATE_USER_MODAL_INVALID", "error");
    }
  };

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <div className="root">
        <div className="create-user__social-container">
          <div className="create-user__text">
            <>{i18n("EDIT_FARM_REGISTER_USER")}</>
          </div>
        </div>
        <div className="create-user__email">
          <Autocomplete
            id="pivot-config-angle-start-ref"
            onInputChange={(e, newValeu) => handleOnInputChange(e, newValeu)}
            filterOptions={filterOptions}
            style={{ width: "100%", marginTop: 10 }}
            getOptionLabel={(option) => option.user__username || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n("CREATE_USER_MODAL_USERNAME_OR_EMAIL")}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {findingLoading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderOption={(option: any) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{option.full_name}</div>
                <div style={{ fontSize: "0.9em" }}>
                  <b>@{option.user__username}</b>
                </div>
              </div>
            )}
            options={usernameOrEmailOptions}
            noOptionsText={userNotFoundText()}
          />
        </div>
        <div className="create-user__button-container">
          <MuiThemeProvider theme={blueTheme}>
            <Button
              style={{ width: "100%", height: "40px" }}
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={createUser}
            >
              {i18n("CREATE_USER_MODAL_BUTTON_SAVE")}
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
    </>
  );
}

export default CreateUser;

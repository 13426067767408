import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import useDebtor from "../../../../../../../../../hooks/models/useDebtor";

interface Props {
  farmID: string;
}

export default function BillingDescription(props: Props) {
  const { farmID } = props;
  const [loading, debtor, debtorActions] = useDebtor(farmID);

  useEffect(() => {
    debtorActions.get(farmID);
  }, []);

  return (
    <Box>
      <span style={{ marginTop: "6px" }}>
        {i18n("BILLING_DESCRIPTION_TEXT")}
      </span>
      {!loading && debtor?.has_invoice_past_due && (
        <Alert severity="error">{i18n("BILLING_WARNING")}</Alert>
      )}
    </Box>
  );
}

import { Reducer } from "redux";
import { FarmsState, FarmsTypes, Farm } from "./types";
import { FarmsActions } from "./actions";
import { arrUpdateOrInsert } from "../../utils/types";

const INITIAL_STATE: FarmsState = {
  list: []
};

const reducer: Reducer<FarmsState, FarmsActions> = (
  state = INITIAL_STATE,
  action: FarmsActions
): FarmsState => {
  const { type, payload }: { type: string; payload: any } = action;

  switch (type) {
    case FarmsTypes.SET_FARMS:
      return { ...state, list: payload };

    case FarmsTypes.UPDATE_OR_INSERT_FARM:
      return {
        ...state,
        list: arrUpdateOrInsert<Farm>(state.list, payload, "id")
      };

    default:
      return state;
  }
};

export default reducer;

import { useEffect, useState } from "react";
import checkExists from "../../utils/checks/checkExists";
import { coreHTTPClient } from "../../services/webclient";
import { BauerPivot, FarmBauerPivotObj } from "../../redux/bauerPivot/types";
import { useRecoilState } from "recoil";
import { BauerPivotStateList } from "../../recoils/BauerPivotRecoil";
import useSocketIO from "../tools/useSocketIO";

const useBauerPivot = (
  farmID: number,
  useFromCacheIfPossible?: boolean
): [boolean, FarmBauerPivotObj[], () => Promise<void>] => {
  const [loading, setLoading] = useState(false);
  const [isSocketSubscribed, setIsSocketSubscribed] = useState(false);

  const [farmBauerPivotList, setFarmBauerPivotList] = useRecoilState(
    BauerPivotStateList
  );

  async function getBauerPivot() {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/smartrain/pivots`
        );
        if (checkExists(["data", response, "__cascade"])) {
          setFarmBauerPivotList(response.data);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    });
  }

  const [isConnected, socket] = useSocketIO();

  useEffect(() => {
    if (isConnected && farmBauerPivotList.length && !isSocketSubscribed) {
      setIsSocketSubscribed(true);
      farmBauerPivotList.forEach((value) => {
        socket.subscribe(`${value.hardware_id}@smartrain_pivot`);
        socket.bind("message_up_received", (data: BauerPivot) => {
          setFarmBauerPivotList((prev) => {
            const bauerUpdateBySocket = prev.map((farmBauerObj) => {
              if (farmBauerObj.hardware_id == data.machine_address) {
                let newFarmObj = { ...farmBauerObj, latest: data };
                return newFarmObj;
              } else return farmBauerObj;
            });
            return bauerUpdateBySocket;
          });
        });
      });
    }
  }, [farmBauerPivotList, isSocketSubscribed, isConnected]);

  useEffect(() => {
    if (useFromCacheIfPossible && farmBauerPivotList.length != 0) return;

    getBauerPivot();
    // eslint-disable-next-line
  }, [farmID]);

  return [loading, farmBauerPivotList, getBauerPivot];
};

export default useBauerPivot;

import { useLocation } from "react-router";
import { useEffect } from "react";

export function useScrollToTopOnNavigate() {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    //Scrolla todas as divs
    Array.from(document.querySelectorAll("div")).forEach(
      (x) => (x.scrollTop = 0)
    );
  }, [location.pathname]);
}

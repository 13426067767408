import React, { useState, useCallback, useEffect } from "react";
import {
  Button,
  Chip,
  Tabs,
  Tab,
  Typography,
  Box,
  Checkbox,
  CircularProgress,
  Select,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  MenuList,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  AccessTime,
  ArrowBack,
  ArrowDropDown,
  CheckCircle,
  StraightenOutlined,
  Settings,
  Map,
  Power,
} from "@material-ui/icons";

import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import DashboardBoxTitle from "../../../../components/DashboardBox/DashboardBoxTitle";
import { isMobile } from "../../../../../../mobileConfig";
import { green, red } from "@material-ui/core/colors";
import "./EditIrpdFormV5.scss";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import FormTimePicker from "../../../../../../components/Form/FormTimePicker/FormTimePicker";
import { ValidationErrorMessage } from "../../../../../../components/Form/types";
import moment from "moment";
import { useParams, useNavigate } from "react-router";
import Routes from "../../../../../../routes/routes";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { Irpd } from "../../../../../../redux/irpds/types";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import {
  handleNumberMask,
  countDecimals,
  validadeFloatInput,
} from "../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import styled from "@emotion/styled";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { arrUpdateOrInsert, objIsEmpty } from "../../../../../../utils/types";
import checkExists from "../../../../../../utils/checks/checkExists";
import { IrpdStateList } from "../../../../../../recoils/IrpdRecoil";
import { useRecoilState, useSetRecoilState } from "recoil";
import DraggableMap from "../../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import FormInputPosition from "../../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import { centerMarkerSelector } from "../../../../../../recoils/DraggableMapRecoil";
import FormCheckBox from "../../../../../../components/Form/FormCheckBox/FormCheckBox";
import { MessageStatus } from "../../../../Pivot/EditPivot/EditPivot";
import ChangeRadioField from "../../../../Pivot/EditPivot/components/ChangeRadioField/ChangeRadioField";
import DrawerRightEditIrpd from "../DrawerRightEditIrpd/DrawerRightEditIrpd";
import { showModal } from "../../../../../../redux/modal/actions";
import { useDispatch, useSelector } from "react-redux";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import ProtocolFooter from "../../../../../../components/ProtocolFooter/ProtocolFooter";
import { useCountdown } from "../../../../../../hooks/models/useCountdown";
import HistoricConfigMobileV5 from "../HistoricConfigMobile/HistoricConfigMobileV5";
import { ModalState } from "../../../../../../redux/modal/types";
import { ApplicationState } from "../../../../../../redux";
import ShowHistoricFloatingLabel from "../../../../Pivot/EditPivot/components/EditPivotForm/ShowHistoricFloatingLabel";
import {
  checkIsOnIntervalNumeric,
  checkIsOnIntervalString,
  formatFloat,
  formatPositiveFloat,
} from "../../../../../../utils/checks";
import { hideModal } from "../../../../../../redux/modal/actions";
import { showLocationModal } from "../../../../../../redux/locationModal/actions";

import { useRequest } from "ahooks";
import { Alert } from "@material-ui/lab";

const greenDot = require("../../../../../../assets/images/markers/light-green-dot.svg");
const { SOCKET_SUFFIX } = process.env;

export interface IrpdParams {
  farm: string;
  irpd: string;
}

interface Props {
  irpd: Irpd;
  availableMeters: any[];
}

const RtcContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;
`;

const ClockContainer = styled.div`
  font-size: 0.7em;
  opacity: 0.8;
  margin-left: -12px;
  display: grid;
  grid-template-columns: 1fr 8fr;
  height: 100%;
`;

const CheckBoxItem = styled.div`
  font-size: 0.8em;
  opacity: 0.8;
  margin-left: -12px;
`;

const InputCheckBoxItem = styled.div`
  font-size: 0.7em;
  opacity: 0.8;
  margin-left: -12px;
  display: grid;
  grid-template-columns: 1fr 8fr;
  height: 100%;
`;

const CheckBoxLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

const useStyles = makeStyles({
  tabBar: {
    height: "40px",
    border: "0px",
    boxShadow: "0px 0px 0px 0px ",
  },
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function EditIrpdFormV5(props: Props) {
  const { irpd, availableMeters } = props;

  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const notify = useNotify();
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const [counter, setCounter] = useCountdown("Irpd", String(irpdID));
  const [device] = useState(`${irpd.base}`);
  const [pump] = useState(`${irpd.pump}`);
  const [isConnected, socket] = useSocketIO();
  const [irpds, setIrpds] = useRecoilState(IrpdStateList);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(false);
  const [error, setError] = useState<any>(undefined);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [irpdCoordsError, setIrpdCoordsError] = useState(false);
  const dispatch = useDispatch();

  // Comparing irpd config with datasheet
  const {
    data: compareConfigResponse,
    run: compareRequest,
    loading: compareLoading,
  } = useRequest(
    (id: string = "") => {
      console.log("received config id", id);
      return coreHTTPClient.get(
        `v4/farms/${farmID}/irpds/${irpdID}/configv5/compare/?irpd_config_id=${
          id ? id : irpd.latest_irpd_config_v5?.id
        }`
      );
    },
    { manual: irpd.protocol !== 5.2 }
  );

  // Dropdown options
  const options = [
    i18n("SELECTED_IRPD_SAVE_BUTTON_TEXT"),
    i18n("SELECTED_IRPD_SAVE_BUTTON_TEXT_DS"),
  ];

  var modal: ModalState = useSelector((state: ApplicationState) => state.modal);

  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    hourRangeMax: undefined,
    hourRangeMin: undefined,
    rtcDate: undefined,
    energyTime: undefined,
    flow: undefined,
    irpdLat: undefined,
    irpdLng: undefined,
    name: undefined,
    monthlyWaterLimit: undefined,
    pauseTimeStart1: undefined,
    pauseTimeEnd1: undefined,
    pauseTimeStart2: undefined,
    pauseTimeOverlap: undefined,
    pauseTimeEnd2: undefined,
    potency: undefined,
    sensorMaxValue: undefined,
    sensorPulses: undefined,
    depthWell: undefined,
  });

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);

      socket.bind("IrpdConfigV5_standard", (data) => {
        if (data?.message_errors) {
          setError(data.message_errors);
        }
        setManual(data?.manual);
        setLastUpdate(moment(data.created).format("DD MMM HH:mm"));
        setMessageStatus(data.message_status);
      });
    }

    return () => {
      socket.unbind("IrpdConfigV5_standard");
      socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);
    };
  }, [isConnected]);

  const classes = useStyles(props);
  const [hideButtonSave, setHideButtonSave] = useState(false);

  const checkCanSave = (): boolean => {
    if (name === "") {
      return false;
    }
    return true;
  };

  // ------------------------------------------------------------------ COMEÇO DAS ABAS

  // GERAL -------------------------------------------------------------------

  const [lastUpdate, setLastUpdate] = useState(
    moment(irpd.latest_irpd_config_v5.created).format("DD MMM HH:mm")
  );

  const [manual, setManual] = useState<boolean>(
    props.irpd.latest_irpd_config_v5?.created_on_hardware
  );

  const [messageStatus, setMessageStatus] = useState(
    props.irpd.latest_irpd_config_v5?.message_status
  );

  const [name, setName] = useState(props.irpd.name);

  const setValidName = useCallback(
    (value) => {
      setName(value);
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 0, 17),
      });
    },
    [validationErrors]
  );

  const [enableMonthlyWaterLimit, setEnableMonthlyWaterLimit] = useState<
    boolean
  >(irpd.latest_irpd_config_v5?.monthly_water_limit !== 0);

  const [monthlyWaterLimit, setMonthlyWaterLimit] = useState<string>(
    irpd.latest_irpd_config_v5?.monthly_water_limit
  );

  const setValidMonthlyWaterLimit = useCallback(
    (value) => {
      const errorID = "monthlyWaterLimit";
      setMonthlyWaterLimit(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 100000, true),
      });
    },
    [validationErrors]
  );

  useEffect(() => {
    if (!enableMonthlyWaterLimit) {
      setMonthlyWaterLimit("0");
      setValidationErrors({
        ...validationErrors,
        monthlyWaterLimit: undefined,
      });
    }
  }, [monthlyWaterLimit, enableMonthlyWaterLimit]);

  const [potency, setPotency] = useState(irpd.latest_irpd_config_v5?.potency);

  const setValidPotency = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setPotency(value);
      setValidationErrors({
        ...validationErrors,
        potency: checkIsOnIntervalNumeric(value, 1, 22000, true),
      });
    },
    [validationErrors]
  );

  const [enableSensorScale, setEnableSensorScale] = useState<boolean>(
    irpd.latest_irpd_config_v5?.content?.imanage_sensors[0]?.max_value / 10 !==
      0
  );

  const [sensorMaxValue, setSensorScale] = useState(
    String(
      irpd.latest_irpd_config_v5?.content?.imanage_sensors[0]?.max_value / 10
    )
  );

  const [sensorPulses, setSensorPulses] = useState(
    String(irpd.latest_irpd_config_v5?.pulse_volume)
  );

  const setValidSensorMaxValue = useCallback(
    (value) => {
      value = formatFloat(value);
      if (validadeFloatInput(value)) setSensorScale(value);
      setValidationErrors({
        ...validationErrors,
        sensorMaxValue: checkIsOnIntervalNumeric(value, -100, 100, true),
      });
    },
    [validationErrors]
  );

  const setValidSensorPulsesValue = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setSensorPulses(value);
      setValidationErrors({
        ...validationErrors,
        sensorPulses: checkIsOnIntervalNumeric(value, 0, 9999, true),
      });
    },
    [validationErrors]
  );

  useEffect(() => {
    if (!enableSensorScale) {
      setSensorScale("0");
      setValidationErrors({
        ...validationErrors,
        sensorMaxValue: undefined,
      });
    }
  }, [sensorMaxValue, enableSensorScale]);

  const [rtc, setRtc] = useState<"auto" | "manual">("auto");

  const [rtcDate, setRtcDate] = useState(moment());

  const setValidRtcDate = useCallback(
    (value) => {
      let errorID = "rtcDate";
      setRtcDate(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "PIVOT_CONFIG_V5_FIELD_CLOCK",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [energyTime, setEnergyTime] = useState(
    irpd.latest_irpd_config_v5?.content?.pump_power_time?.minutes
  );

  const setValidEnergyTime = useCallback(
    (value) => {
      const errorID = "energyTime";
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setEnergyTime(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 300, true),
      });
    },
    [validationErrors]
  );

  const [flow, setFlow] = useState(
    irpd.latest_irpd_config_v5?.flow ? String(irpd.flow) : ""
  );

  const [depthWell, setDepthWell] = useState(
    irpd.latest_irpd_config_v5?.depth_well
      ? String(irpd.latest_irpd_config_v5?.depth_well)
      : "0"
  );

  const setValidFlow = useCallback(
    (value) => {
      const errorID = "flow";
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setFlow(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 1000, true),
      });
    },
    [validationErrors]
  );

  const setValidDepth = useCallback(
    (value) => {
      const errorID = "depthWell";
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setDepthWell(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalNumeric(value, 0, 1000, true),
      });
    },
    [validationErrors]
  );

  const [pressureSensor, setPressureSensor] = useState<boolean>(
    props.irpd.latest_irpd_config_v5?.has_pressure_sensor
  );

  // LOCALIZAÇÃO -------------------------------------------------------------------

  const [irpdLat, setIrpdLat] = useState(props.irpd.position.split(",")[0]);

  const setValidIrpdLat = useCallback(
    (value) => {
      setIrpdLat(value);
    },
    [validationErrors]
  );

  const [irpdLng, setIrpdLng] = useState(props.irpd.position.split(",")[1]);

  const setValidIrpdLng = useCallback(
    (value) => {
      setIrpdLng(value);
    },
    [validationErrors]
  );

  // HORÁRIO DE PICO -------------------------------------------------------------------

  const [valueKwhPeak, setValueKwhPeak] = useState(
    props.irpd.latest_irpd_config_v5?.kwh_peak
      ? props.irpd.latest_irpd_config_v5?.kwh_peak
      : "1"
  );

  const setValidValueKwhPeak = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const [valueKwhOutOfPeak, setValueKwhOutOfPeak] = useState(
    props.irpd.latest_irpd_config_v5?.kwh_out_of_peak
      ? props.irpd.latest_irpd_config_v5?.kwh_out_of_peak
      : "1"
  );

  const setValidValueKwhOutOfPeak = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhOutOfPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhOutOfPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const [valueKwhReduced, setValueKwhReduced] = useState(
    props.irpd.latest_irpd_config_v5?.kwh_reduced
      ? props.irpd.latest_irpd_config_v5?.kwh_reduced
      : "1"
  );

  const setValidValueKwhReduced = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhReduced(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhReduced: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const [weekday, setWeekday] = useState<any>({
    mon: props.irpd.latest_irpd_config_v5?.content.peak_time.monday_enable,
    tue: props.irpd.latest_irpd_config_v5?.content.peak_time.tuesday_enable,
    wed: props.irpd.latest_irpd_config_v5?.content.peak_time.wednesday_enable,
    thu: props.irpd.latest_irpd_config_v5?.content.peak_time.thursday_enable,
    fri: props.irpd.latest_irpd_config_v5?.content.peak_time.friday_enable,
    sat: props.irpd.latest_irpd_config_v5?.content.peak_time.saturday_enable,
    sun: props.irpd.latest_irpd_config_v5?.content.peak_time.sunday_enable,
  });

  const setValidWeekday = useCallback(
    (weekdayAbbreviantion) => {
      switch (weekdayAbbreviantion) {
        case "mon":
          setWeekday({ ...weekday, mon: weekday.mon ? 0 : 1 });
          break;
        case "tue":
          setWeekday({ ...weekday, tue: weekday.tue ? 0 : 1 });
          break;
        case "wed":
          setWeekday({ ...weekday, wed: weekday.wed ? 0 : 1 });
          break;
        case "thu":
          setWeekday({ ...weekday, thu: weekday.thu ? 0 : 1 });
          break;
        case "fri":
          setWeekday({ ...weekday, fri: weekday.fri ? 0 : 1 });
          break;
        case "sat":
          setWeekday({ ...weekday, sat: weekday.sat ? 0 : 1 });
          break;
        case "sun":
          setWeekday({ ...weekday, sun: weekday.sun ? 0 : 1 });
          break;
      }
    },
    [weekday]
  );

  const [pauseTimeStatus1, setPauseTimeStatus1] = useState<any>(
    (irpd.latest_irpd_config_v5.content?.peak_time.start_hour_1 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.start_minute_1 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_hour_1 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_minute_1 === 0) ||
      irpd.latest_irpd_config_v5.content?.enable_peak_time?.enable === false
      ? false
      : true
  );

  useEffect(() => {
    if (!pauseTimeStatus1) {
      setPauseTimeStatus2(false);
    }
  }, [pauseTimeStatus1]);

  const [pauseTimeStart1, setPauseTimeStart1] = useState(
    moment().set({
      hour: irpd.latest_irpd_config_v5.content?.peak_time.start_hour_1,
      minute: irpd.latest_irpd_config_v5.content?.peak_time.start_minute_1,
      second: 0,
      millisecond: 0,
    })
  );

  const setValidPauseTimeStart1 = (value) => {
    const errorID = "pauseTimeStart1";

    if (value.lenght === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
      });
    } else {
      setPauseTimeStart1(value);
    }
  };

  const [pauseTimeEnd1, setPauseTimeEnd1] = useState(
    moment().set({
      hour: irpd.latest_irpd_config_v5.content.peak_time.stop_hour_1,
      minute: irpd.latest_irpd_config_v5.content.peak_time.stop_minute_1,
      second: 0,
      millisecond: 0,
    })
  );

  const setValidPauseTimeEnd1 = (value) => {
    const errorID = "pauseTimeEnd1";

    if (!value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setPauseTimeEnd1(value);
    }
  };

  useEffect(() => {
    if (pauseTimeStart1.isSameOrAfter(pauseTimeEnd1)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: undefined,
      });
    }
  }, [pauseTimeStart1, pauseTimeEnd1]);

  const [pauseTimeStatus2, setPauseTimeStatus2] = useState(
    (irpd.latest_irpd_config_v5.content?.peak_time.start_hour_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.start_minute_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_hour_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_minute_2 === 0) ||
      pauseTimeStatus1 === false
      ? false
      : true
  );

  useEffect(() => {
    if (!pauseTimeStatus2) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeOverlap"]: undefined,
      });
    }
  }, [pauseTimeStatus2]);

  const [pauseTimeStart2, setPauseTimeStart2] = useState(
    moment().set({
      hour: irpd.latest_irpd_config_v5.content.peak_time.start_hour_2,
      minute: irpd.latest_irpd_config_v5.content.peak_time.start_minute_2,
      second: 0,
      millisecond: 0,
    })
  );

  const checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  };

  const setValidPauseTimeStart2 = (value) => {
    const errorID = "pauseTimeStart2";

    if (value.lenght === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
      });
    } else {
      var strTime2 = [value.format("HH:mm"), pauseTimeEnd2.format("HH:mm")];
      var strTime1 = [
        pauseTimeStart1.format("HH:mm"),
        pauseTimeEnd1.format("HH:mm"),
      ];

      var timeSegments = [strTime2, strTime1];

      if (checkOverlap(timeSegments)) {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: undefined,
        });
      }
      setPauseTimeStart2(value);
    }
  };

  const [pauseTimeEnd2, setPauseTimeEnd2] = useState(
    irpd.latest_irpd_config_v5.content?.peak_time.start_hour_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.start_minute_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_hour_2 === 0 &&
      irpd.latest_irpd_config_v5.content?.peak_time.stop_minute_2 === 0
      ? moment().set({
          hour: 0,
          minute: 1,
          second: 0,
          millisecond: 0,
        })
      : moment().set({
          hour: irpd.latest_irpd_config_v5.content.peak_time.stop_hour_2,
          minute: irpd.latest_irpd_config_v5.content.peak_time.stop_minute_2,
          second: 0,
          millisecond: 0,
        })
  );

  const setValidPauseTimeEnd2 = (value: moment.Moment) => {
    const errorID = "pauseTimeEnd2";

    if (!value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      var strTime2 = [pauseTimeStart2.format("HH:mm"), value.format("HH:mm")];
      var strTime1 = [
        pauseTimeStart1.format("HH:mm"),
        pauseTimeEnd1.format("HH:mm"),
      ];

      var timeSegments = [strTime2, strTime1];

      if (checkOverlap(timeSegments)) {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: undefined,
        });
      }
      setPauseTimeEnd2(value);
    }
  };

  useEffect(() => {
    if (pauseTimeStart2.isSameOrAfter(pauseTimeEnd2)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: undefined,
      });
    }
  }, [pauseTimeStart2, pauseTimeEnd2]);

  // Gestão hídrica

  const [linkedMeter, setLinkedMeter] = React.useState<number | null>(
    irpd.latest_irpd_config_v5.imeter_related
      ? irpd.latest_irpd_config_v5.imeter_related.id
      : null
  );

  // ------------------------------------------------------------------ FIM DAS ABAS

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  const checkErrors = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined || !checkCanSave()) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  useEffect(() => {
    checkErrors();
  }, [validationErrors]);

  async function patchIrpd(
    irpdConfig: any,
    patchObject: any,
    setDatasheet: boolean = false
  ) {
    setLoading(true);
    await new Promise(async (resolve, reject) => {
      try {
        const apiVersion = irpd.protocol === 5.2 ? "v4" : "v3";
        const newConfig = await coreHTTPClient.post(
          `${apiVersion}/farms/${farmID}/irpds/${irpdID}/configv5/`,
          irpdConfig
        );

        if (!objIsEmpty(patchObject)) {
          const response = await coreHTTPClient.patch(
            `v3/farms/${farmID}/irpds/${irpdID}/`,
            patchObject
          );

          if (checkExists(["data", response, "__cascade"])) {
            setIrpds(arrUpdateOrInsert(irpds, response.data, "id"));
          }

          // Finnaly update datasheet config
          if (setDatasheet) {
            const configId = newConfig.data.id;
            await coreHTTPClient.post(
              `/v4/farms/${farmID}/irpds/${irpdID}/configv5/swap_datasheet/`,
              {
                name: i18n("DATASHEET_PLACEHOLDER"),
                config_id: configId,
              }
            );
          }

          notify("IRPD_BOX_CONFIGURATION_SAVED", "success");
          if (irpd.protocol === 5.2) compareRequest(newConfig.data.id);
          setCounter(12);
          setLoading(false);
          if (rtc === "auto") setRtcDate(moment());

          resolve(response);
        }
      } catch (err) {
        setLoading(false);
        setCounter(0);
        notify("IRPD_BOX_CONFIGURATION_ERROR", "error");
        reject(err);
      }
    });
  }

  // Save dropdown options
  const saveIrpdDataFunction = async (replaceDatasheet: boolean = false) => {
    setValue(0);
    setMessageStatus(-1);
    if (!showErrorMessages()) {
      const irpdConfigContent = {
        clear_device_memory: {
          clear_device_memory: 1,
        },
        datalogger_address: { address: irpd.base },
        enable_peak_time: { enable: pauseTimeStatus1 ? 1 : 0 },
        peak_time: {
          start_hour_1: pauseTimeStatus1 ? pauseTimeStart1.hour() : 0,
          start_minute_1: pauseTimeStatus1 ? pauseTimeStart1.minute() : 0,
          stop_hour_1: pauseTimeStatus1 ? pauseTimeEnd1.hour() : 0,
          stop_minute_1: pauseTimeStatus1 ? pauseTimeEnd1.minute() : 0,
          start_hour_2: pauseTimeStatus2 ? pauseTimeStart2.hour() : 0,
          start_minute_2: pauseTimeStatus2 ? pauseTimeStart2.minute() : 0,
          stop_hour_2: pauseTimeStatus2 ? pauseTimeEnd2.hour() : 0,
          stop_minute_2: pauseTimeStatus2 ? pauseTimeEnd2.minute() : 0,
          friday_enable: pauseTimeStatus1 ? weekday.fri : 0,
          monday_enable: pauseTimeStatus1 ? weekday.mon : 0,
          sunday_enable: pauseTimeStatus1 ? weekday.sun : 0,
          tuesday_enable: pauseTimeStatus1 ? weekday.tue : 0,
          saturday_enable: pauseTimeStatus1 ? weekday.sat : 0,
          thursday_enable: pauseTimeStatus1 ? weekday.thu : 0,
          wednesday_enable: pauseTimeStatus1 ? weekday.wed : 0,
        },
        holidays: [],
        clock: {
          second: rtcDate.second(),
          minute: rtcDate.minute(),
          hour: rtcDate.hour(),
          day: rtcDate.date(),
          month: rtcDate.month() + 1,
          year: rtcDate.year() - 2000,
        },
        pump_power_time: {
          minutes: parseInt(energyTime),
        },
        imanage_sensors: [
          {
            number_editing: 0,
            sensor_type: 4,
            max_value: parseInt(sensorMaxValue) * 10,
            min_value: 0,
          },
        ],
      };

      const newPosition = irpdLat + "," + irpdLng;

      const patchObject = {
        name: name,
        potency: potency,
        position: newPosition,
        flow: parseFloat(flow),
      };

      const postObject = {
        content: irpdConfigContent,
        monthly_water_limit: !enableMonthlyWaterLimit
          ? 0
          : parseInt(monthlyWaterLimit),
        has_pressure_sensor: pressureSensor,
        name_irpd_on_config: name,
        flow: parseFloat(flow),
        position: newPosition,
        potency: potency,
        kwh_peak: parseFloat(valueKwhPeak),
        kwh_out_of_peak: parseFloat(valueKwhOutOfPeak),
        kwh_reduced: parseFloat(valueKwhReduced),
      };

      if (irpd.protocol === 5.2) {
        postObject["depth_well"] = parseFloat(depthWell);
        postObject["pulse_volume"] = Number(sensorPulses);
        if (linkedMeter) postObject["imeter_related"] = Number(linkedMeter);
      }

      await patchIrpd(postObject, patchObject, replaceDatasheet);
      setValue(0);
    }
  };

  const handleClick = () => {
    saveIrpdDataFunction(selectedIndex !== 0);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const saveButtonDropdownComponent = (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          color="primary"
          onClick={handleClick}
          disabled={
            hideButtonSave || loading || irpdCoordsError || counter !== 0
          }
          endIcon={
            loading || counter !== 0 ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : (
              <CheckCircle />
            )
          }
        >
          {counter !== 0 ? `${counter}` : <>{options[selectedIndex]}</>}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={
            hideButtonSave || loading || irpdCoordsError || counter !== 0
          }
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        style={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => {}}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_IRPD.replace(
            ":farm",
            String(farmID)
          ).replace(":irpd", String(irpdID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const markers = [
    {
      lat: {
        state: irpdLat,
        setFunction: setValidIrpdLat,
      },
      lng: {
        state: irpdLng,
        setFunction: setValidIrpdLng,
      },
      markerUrl: greenDot,
      key: "center",
      event: {
        state: event,
        setFunction: setEvent,
      },
    },
  ];

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setIrpdLat}
                setLongitude={setIrpdLng}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={greenDot} />
    </Button3D>
  );

  useEffect(() => {
    if (irpd?.permission_level < 3) {
      navigate(
        Routes.DASHBOARD.SELECTED_IRPD.replace(":farm", String(farmID)).replace(
          ":irpd",
          String(irpd.id)
        )
      );
    }
  }, [irpd]);

  const showHistoricConfigModal = (irpdID, farmID, loadHistoricInfo, irpd) => {
    dispatch(
      showModal({
        content: (
          <HistoricConfigMobileV5
            farmID={farmID}
            irpdID={irpdID}
            loadHistoricInfo={loadHistoricInfo}
            irpd={irpd}
          />
        ),
        title: "EDIT_PIVOT_LABEL_SETTING_HISTORIC",
      })
    );
  };

  const setIrpdLatLng = useSetRecoilState(centerMarkerSelector);

  // função que passa os setters dos estados dos inputs da tela de
  // Editar Bomba para o botão CARREGAR do histórico de configurações
  function loadHistoricInfo(
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdKwhPeak: number,
    irpdKwhOutOfPeak: number,
    irpdKwhReduced: number,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) {
    try {
      setValidName(irpdName);
      setEnableMonthlyWaterLimit(irpdEnableMonthlyWaterLimit);
      setValidMonthlyWaterLimit(irpdMonthlyWaterLimit);
      setValidPotency(irpdPotency);
      setEnableSensorScale(irpdEnableSensorScale);
      setValidSensorMaxValue(irpdSensorScale);
      setValidEnergyTime(irpdEnergyTime);
      setValidFlow(irpdFlow);
      setPressureSensor(irpdEnablePressureSensor);
      setValidIrpdLat(irpdPositionLat);
      setValidIrpdLng(irpdPositionLng);
      setIrpdLatLng(irpdPositionMarker);
      setValidValueKwhPeak(irpdKwhPeak);
      setValidValueKwhOutOfPeak(irpdKwhOutOfPeak);
      setValidValueKwhReduced(irpdKwhReduced);
      setWeekday(irpdWeek);

      if (irpdPauseTimeStatus1 === true) {
        setValidPauseTimeStart1(irpdPauseTimeStart1);
        setValidPauseTimeEnd1(irpdPauseTimeEnd1);
        setPauseTimeStatus1(irpdPauseTimeStatus1);
      } else {
        setPauseTimeStatus1(irpdPauseTimeStatus1);
      }

      if (irpdPauseTimeStatus2 === true) {
        setValidPauseTimeStart2(irpdPauseTimeStart2);
        setValidPauseTimeEnd2(irpdPauseTimeEnd2);
        setPauseTimeStatus2(irpdPauseTimeStatus2);
      } else {
        setPauseTimeStatus2(irpdPauseTimeStatus2);
      }

      notify("EDIT_IRPD_LOAD_SUCCESS", "success", 3000);
      dispatch(hideModal());
    } catch (error) {
      notify("EDIT_IRPD_LOAD_ERROR", "error");
    }
  }

  return (
    <>
      <DrawerRightEditIrpd irpd={irpd} loadHistoricInfo={loadHistoricInfo} />
      <div className={isMobile() ? "edit-irpd-mobile" : "edit-irpd"}>
        <div className="general-form__container">
          <DashboardBox
            leftElement={backButton}
            centerElement={<DashboardBoxTitle title={"IRPD_EDIT_LABEL"} />}
            rightElement={saveButtonDropdownComponent}
          >
            <div
              style={{
                backgroundColor: "white",
                display: "grid",
                gridTemplateColumns: "12fr",
                height: "100%",
              }}
            >
              <Tabs
                orientation={"horizontal"}
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                variant={"scrollable"}
                indicatorColor="primary"
                aria-label="Edit Farm"
                centered={isMobile() ? true : false}
              >
                <Tab
                  className={classes.tab}
                  label={i18n("EDIT_PIVOT_LABEL_GENERAL")}
                  tabIndex={value}
                  icon={
                    validationErrors["potency"] ||
                    validationErrors["sensorMaxValue"] ||
                    validationErrors["sensorPulses"] ||
                    validationErrors["hourRangeMax"] ||
                    validationErrors["hourRangeMin"] ||
                    validationErrors["hourRangeMin"] ||
                    (irpd.protocol === 5.2 && validationErrors["depthWell"]) ? (
                      <Settings
                        style={{
                          color: red[500],
                        }}
                      />
                    ) : (
                      <Settings
                        style={{
                          color: green[500],
                        }}
                      />
                    )
                  }
                />

                <Tab
                  className={classes.tab}
                  label={i18n("EDIT_PIVOT_LABEL_LOCATION")}
                  tabIndex={value}
                  icon={
                    irpdCoordsError ? (
                      <Map
                        style={{
                          color: red[500],
                        }}
                      />
                    ) : (
                      <Map
                        style={{
                          color: green[500],
                        }}
                      />
                    )
                  }
                />

                <Tab
                  className={classes.tab}
                  label={i18n("EDIT_PIVOT_LABEL_PAUSE_TIME")}
                  tabIndex={value}
                  icon={
                    validationErrors["pauseTimeStart1"] ||
                    validationErrors["pauseTimeEnd1"] ||
                    validationErrors["pauseTimeStart2"] ||
                    validationErrors["pauseTimeEnd2"] ||
                    validationErrors["pauseTimeOverlap"] ? (
                      <Power
                        style={{
                          color: red[500],
                        }}
                      />
                    ) : (
                      <Power
                        style={{
                          color: green[500],
                        }}
                      />
                    )
                  }
                />

                <Tab
                  className={classes.tab}
                  label={<>{i18n("EDIT_PIVOT_LABEL_CLOCK")}</>}
                  tabIndex={value}
                  icon={
                    validationErrors["rtcDate"] ? (
                      <AccessTime
                        style={{
                          color: red[500],
                        }}
                      />
                    ) : (
                      <AccessTime
                        style={{
                          color: green[500],
                        }}
                      />
                    )
                  }
                />

                {irpd.protocol === 5.2 ? (
                  <Tab
                    className={classes.tab}
                    label={i18n("EDIT_IRPD_LABEL_LINKED_METER")}
                    tabIndex={value}
                    icon={
                      availableMeters.length <= 1 ? (
                        <StraightenOutlined
                          style={{
                            color: red[500],
                          }}
                        />
                      ) : (
                        <StraightenOutlined
                          style={{
                            color: green[500],
                          }}
                        />
                      )
                    }
                  />
                ) : null}
              </Tabs>

              <div
                style={{
                  backgroundColor: "#fff",
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    maxHeight: isMobile() ? "" : "calc(100vh - 150px)",
                    overflow: "auto",
                  }}
                >
                  {/* GERAL ------------------------------------------------------------------- */}
                  <TabPanel value={value} index={0}>
                    <div className="edit-irpd-form__last-settings">
                      {i18n("EDIT_PIVOT_LAST_SETTINGS", [lastUpdate])}

                      {manual
                        ? i18n("EDIT_PIVOT_MANUAL")
                        : i18n("EDIT_PIVOT_BY_WEB")}
                    </div>

                    <div className="general-form__row-3-3-3-3">
                      <ChangeRadioField
                        radioID={device}
                        farmID={farmID}
                        deviceID={props.irpd.id}
                        label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
                        status={MessageStatus.NOT_SENT}
                        deviceType="IRPD"
                        locked
                      />

                      <ChangeRadioField
                        radioID={pump}
                        error={error}
                        farmID={farmID}
                        deviceID={props.irpd.id}
                        label={i18n("EDIT_PIVOT_PUMP_LABEL")}
                        status={messageStatus}
                        deviceType="IRPD"
                        deviceSubType="pump"
                      />
                    </div>

                    <div className="general-form__row-6-6">
                      <FormInput
                        id="irpd-name"
                        helperText={validationErrors["name"]}
                        label={"IRPD_FIELD_NAME"}
                        state={[name, setValidName]}
                      />

                      <InputCheckBoxItem>
                        <Checkbox
                          title={`${i18n("ALERT_ITEM_DO_ENABLE")}/${i18n(
                            "ALERT_ITEM_DO_DISABLE"
                          )} ${i18n("IRPD_MONTHLY_WATER_LIMIT")}`}
                          style={{
                            margin: ` 0 0 -20px ${isMobile() ? 0 : -5}px`,
                          }}
                          color="primary"
                          checked={enableMonthlyWaterLimit}
                          onChange={(event) => {
                            setEnableMonthlyWaterLimit((prevState) => {
                              if (!prevState && monthlyWaterLimit === "0") {
                                setMonthlyWaterLimit("1");
                              }
                              return !prevState;
                            });
                          }}
                        />

                        <FormInput
                          disabled={!enableMonthlyWaterLimit}
                          id="irpd-monthly-water-limit"
                          inputMode="numeric"
                          min="0"
                          max="100"
                          helperText={validationErrors["monthlyWaterLimit"]}
                          label={"IRPD_MONTHLY_WATER_LIMIT"}
                          state={[monthlyWaterLimit, setValidMonthlyWaterLimit]}
                          endAdornment="m³"
                        />
                      </InputCheckBoxItem>
                    </div>

                    <div className="general-form__row-6-6">
                      <FormInput
                        id="irpd-config-potency"
                        helperText={validationErrors["potency"]}
                        inputMode="numeric"
                        label={"PIVOT_CONFIG_V5_FIELD_POTENCY"}
                        state={[String(potency), setValidPotency]}
                        endAdornment="kW"
                      />

                      {
                        /* If protocol is 5.2 (GH) some fields will be replaced by another.
                        in this case it ill be replaced for a pulse counter */
                        irpd.protocol === 5.2 ? (
                          <FormInput
                            id="irpd-config-sensor-pulses"
                            helperText={validationErrors["sensorPulses"]}
                            inputMode="numeric"
                            label={"MEASUREMENT_PULSES_VALUE"}
                            state={[sensorPulses, setValidSensorPulsesValue]}
                            endAdornment="m³/pulse"
                          />
                        ) : (
                          <>
                            <InputCheckBoxItem>
                              <Checkbox
                                title={`${i18n("ALERT_ITEM_DO_ENABLE")}/${i18n(
                                  "ALERT_ITEM_DO_DISABLE"
                                )} ${i18n("MEASUREMENT_MAX_VALUE")}`}
                                color="primary"
                                style={{
                                  margin: ` 0 0 -20px ${isMobile() ? 0 : -5}px`,
                                }}
                                checked={enableSensorScale}
                                onChange={(event) => {
                                  setEnableSensorScale((prevState) => {
                                    if (!prevState && sensorMaxValue === "0") {
                                      setSensorScale("1");
                                    }
                                    return !prevState;
                                  });
                                }}
                              />

                              <FormInput
                                id="irpd-config-sensor-max-value"
                                disabled={!enableSensorScale}
                                helperText={validationErrors["sensorMaxValue"]}
                                inputMode="numeric"
                                label={"MEASUREMENT_MAX_VALUE"}
                                state={[sensorMaxValue, setValidSensorMaxValue]}
                                endAdornment="bar"
                              />
                            </InputCheckBoxItem>
                          </>
                        )
                      }
                    </div>

                    <div className="general-form__row-6-6">
                      <FormInput
                        id="irpd-config-energy-time"
                        helperText={validationErrors["flow"]}
                        label={"PIVOT_CONFIG_V5_FIELD_FLOW"}
                        state={[flow, setValidFlow]}
                        endAdornment="m³/h"
                      />

                      {irpd.protocol !== 5.2 ? (
                        <RtcContainer>
                          <ClockContainer>
                            <Checkbox
                              color="primary"
                              checked={pressureSensor}
                              onChange={(event) => {
                                setPressureSensor((prevState) => !prevState);
                              }}
                            />
                            <CheckBoxLabel>
                              {i18n("IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR")}
                            </CheckBoxLabel>
                          </ClockContainer>
                        </RtcContainer>
                      ) : (
                        <FormInput
                          id="irpd-config-depth-well"
                          helperText={validationErrors["depthWell"]}
                          label={"PIVOT_CONFIG_V5_FIELD_DEPTH_WELL"}
                          state={[depthWell, setValidDepth]}
                          endAdornment="MCA"
                        />
                      )}
                    </div>

                    <Divider
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    />

                    <div className="general-form__row-12">
                      <h4
                        style={{
                          margin: 0,
                          marginBottom: "5px",
                          marginTop: "0px",
                        }}
                      >
                        {i18n("EDIT_IRPD_FIELD_ENERGY_TIME")}
                      </h4>
                      <Typography component="p" style={{ fontSize: "14px" }}>
                        {i18n("EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP")}
                      </Typography>
                      <FormInput
                        id="irpd-config-energy-time"
                        helperText={validationErrors["energyTime"]}
                        label={"EDIT_IRPD_FIELD_ENERGY_TIME"}
                        state={[energyTime, setValidEnergyTime]}
                        endAdornment={"min"}
                      />
                    </div>
                  </TabPanel>

                  {/* LOCALIZAÇÃO ------------------------------------------------------------------- */}
                  <TabPanel value={value} index={1}>
                    <div>
                      <FormInputPosition
                        setError={setIrpdCoordsError}
                        id="irpd-config-center"
                        markerUrl={markerButton()}
                        label={"PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER"}
                        lat={[irpdLat, setValidIrpdLat]}
                        lng={[irpdLng, setValidIrpdLng]}
                        recoilSelector={centerMarkerSelector}
                      />

                      <div style={{ height: "400px", width: "100%" }}>
                        <DraggableMap
                          _setExperimentalRecoil
                          markers={markers}
                          zoomScroll={false}
                        />
                      </div>
                    </div>
                  </TabPanel>

                  {/* HORÁRIO DE PICO ------------------------------------------------------------------- */}
                  <TabPanel value={value} index={2}>
                    <h4>
                      {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}
                    </h4>

                    <p style={{ marginTop: "-19px" }}>
                      {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT")}
                    </p>

                    <div>
                      <h4>{i18n("EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE")}</h4>

                      <div className="general-form__row-4-4-4">
                        <FormInput
                          id="standard-basic-hp"
                          helperText={validationErrors["valueKwhPeak"]}
                          label="BASIC_INFO_BOX_PONTA"
                          state={[valueKwhPeak, setValidValueKwhPeak]}
                          inputMode="numeric"
                          startAdornment={i18n("CURRENCY")}
                        />

                        <FormInput
                          id="standard-basic-hfp"
                          helperText={validationErrors["valueKwhOutOfPeak"]}
                          label="BASIC_INFO_BOX_FORA_DE_PONTA"
                          state={[valueKwhOutOfPeak, setValidValueKwhOutOfPeak]}
                          inputMode="numeric"
                          startAdornment={i18n("CURRENCY")}
                        />

                        <FormInput
                          id="standard-basic-hr"
                          helperText={validationErrors["valueKwhReduced"]}
                          label="BASIC_INFO_BOX_REDUZIDO"
                          state={[valueKwhReduced, setValidValueKwhReduced]}
                          inputMode="numeric"
                          startAdornment={i18n("CURRENCY")}
                        />
                      </div>
                    </div>

                    <h4 style={{ margin: 0, marginBottom: "5px" }}>
                      {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK")}
                    </h4>

                    <div
                      className="general-form__row-x-1-1-1-1-1-1-1"
                      style={!isMobile() ? { width: "60%" } : null}
                    >
                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.sun}
                          onChange={(event) => {
                            setValidWeekday("sun");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_SUNDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.mon}
                          onChange={(event) => {
                            setValidWeekday("mon");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_MONDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.tue}
                          onChange={(event) => {
                            setValidWeekday("tue");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_TUESDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.wed}
                          onChange={(event) => {
                            setValidWeekday("wed");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.thu}
                          onChange={(event) => {
                            setValidWeekday("thu");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_THURSDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.fri}
                          onChange={(event) => {
                            setValidWeekday("fri");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_FRIDAY")}
                      </CheckBoxItem>

                      <CheckBoxItem>
                        <Checkbox
                          color="primary"
                          disabled={!pauseTimeStatus1}
                          checked={weekday.sat}
                          onChange={(event) => {
                            setValidWeekday("sat");
                          }}
                        />

                        {i18n("EDIT_PIVOT_V5_WEEKDAY_SATURDAY")}
                      </CheckBoxItem>
                    </div>

                    <h4
                      style={{
                        margin: 0,
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}
                    >
                      {i18n("EDIT_PIVOT_V5_CONFIGURATIONS")}
                    </h4>

                    <div className="general-form__row-2-5-5">
                      <FormCheckBox
                        state={[pauseTimeStatus1, setPauseTimeStatus1]}
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1"}
                      />

                      <FormTimePicker
                        id="pivot-config-pause-time-start-1"
                        disabled={!pauseTimeStatus1}
                        error={validationErrors["pauseTimeStart1"]}
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START"}
                        state={[pauseTimeStart1, setValidPauseTimeStart1]}
                      />

                      <FormTimePicker
                        id="pivot-config-pause-time-end-1"
                        disabled={!pauseTimeStatus1}
                        error={validationErrors["pauseTimeEnd1"]}
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END"}
                        state={[pauseTimeEnd1, setValidPauseTimeEnd1]}
                      />
                    </div>

                    <div className="general-form__row-2-5-5">
                      <FormCheckBox
                        state={[pauseTimeStatus2, setPauseTimeStatus2]}
                        disabled={!pauseTimeStatus1}
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2"}
                      />

                      <FormTimePicker
                        id="pivot-config-pause-time-start-2"
                        disabled={!pauseTimeStatus2}
                        error={
                          validationErrors["pauseTimeStart2"] ||
                          validationErrors["pauseTimeOverlap"]
                        }
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START"}
                        state={[pauseTimeStart2, setValidPauseTimeStart2]}
                      />

                      <FormTimePicker
                        id="pivot-config-pause-time-end-2"
                        disabled={!pauseTimeStatus2}
                        error={
                          validationErrors["pauseTimeEnd2"] ||
                          validationErrors["pauseTimeOverlap"]
                        }
                        label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END"}
                        state={[pauseTimeEnd2, setValidPauseTimeEnd2]}
                      />
                    </div>
                  </TabPanel>

                  {/* Device time setttings */}
                  <TabPanel value={value} index={3}>
                    <h4
                      style={{
                        margin: 0,
                        marginBottom: "5px",
                        marginTop: "0px",
                      }}
                    >
                      {i18n("EDIT_IRPD_FIELD_RTCDATE")}
                    </h4>
                    <div className="general-form__row-12">
                      <FormSelect
                        id="pivot-config-segment-mode-forward"
                        label={"PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL"}
                        state={[rtc, setRtc]}
                        values={[
                          ["auto", "PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK"],
                          ["manual", "PIVOT_CONFIG_V5_FIELD_CLOCK"],
                        ]}
                      />
                    </div>
                    <div className="general-form__row-6-6">
                      <FormDateTimePicker
                        disabled={rtc === "auto"}
                        id="irpd-config-rtc-date"
                        label={"EDIT_IRPD_FIELD_RTCDATE"}
                        state={[rtcDate, setValidRtcDate]}
                      />
                    </div>
                  </TabPanel>

                  {
                    /* Gestãõ hídrica */
                    irpd.protocol === 5.2 ? (
                      <TabPanel value={value} index={4}>
                        <FormSelect
                          noI18n
                          id="linkedMeter"
                          state={[linkedMeter, setLinkedMeter]}
                          values={availableMeters.map((am) => [am.id, am.name])}
                          label="LINK_METER_SELECT_LABEL"
                        />
                      </TabPanel>
                    ) : null
                  }
                </div>
                {!compareLoading &&
                compareConfigResponse?.data?.are_equal ? null : (
                  <div
                    style={{
                      paddingLeft: isMobile() ? 8 : 16,
                      paddingRight: isMobile() ? 8 : 16,
                    }}
                  >
                    {irpd.protocol === 5.2 ? (
                      <Alert severity="info">
                        {i18n("EDIT_IRPD_DATASHEET_WARNING")}
                      </Alert>
                    ) : null}
                  </div>
                )}
                <ProtocolFooter protocol={irpd.protocol} />
              </div>
            </div>
          </DashboardBox>

          {isMobile() && (
            <div
              onClick={() => {
                showHistoricConfigModal(
                  irpd.id,
                  farmID,
                  loadHistoricInfo,
                  irpd
                );
              }}
              style={{ display: modal.active ? "none" : "initial" }}
            >
              <ShowHistoricFloatingLabel className={classes.tabBar} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditIrpdFormV5;

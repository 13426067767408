import React from "react";
import { FormControl, Button } from "@material-ui/core";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { PaymentMethodOptions } from "../PaymentMethodContainer";

interface Props {
  method: PaymentMethodOptions;
}

const PaymentMethodSelection = (props: Props) => {
  const { method } = props;

  const handleLanguageChange = () => {
    const externalLink = method.link;
    window.open(externalLink, "_blank");
  };

  return (
    <div>
      <FormControl className="form-select__form-control">
        <Button
          id="checkout-link"
          style={{ marginTop: "25px" }}
          variant="contained"
          color="primary"
          onClick={handleLanguageChange}
        >
          {i18n("PAYMENT_METHOD_UPDATE_BTN")}
        </Button>
      </FormControl>
    </div>
  );
};

export default PaymentMethodSelection;

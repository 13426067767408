import React, { useMemo } from "react";
import { FormControl, InputLabel, NativeSelect } from "@material-ui/core";
import "./FormSelect.scss";
import { i18n, i18nTextId } from "../../../i18n/i18nText";
import InputTooltip from "../../InputTooltip/InputTooltip";

interface Props {
  id: string;
  label?: string;
  newLabel?: i18nTextId;
  state: [any, (value: any) => void];
  values: [string, string][]; //[value, label][]
  disabled?: boolean;
  noMargin?: boolean;
  labelTooltip?: string;
  noI18n?: boolean;
  setPivotData?: any;
  setPivotData4g?: any;
  setGPSData?: any;
  setPUMPData?: any;
  validationErrors?: any;
  setValidationErrors?: any;
}

function FormSelect(props: Props) {
  const {
    id,
    label,
    state,
    values,
    newLabel,
    labelTooltip,
    noI18n,
    setPivotData,
    setPivotData4g,
    setGPSData,
    setPUMPData,
    validationErrors,
    setValidationErrors,
  } = props;

  const formattedValues = useMemo(() => {
    if (noI18n)
      return values
        .filter((value) => value != null)
        .map((value) => [value[0], value[1]]);
    else
      return values
        .filter((value) => value != null)
        .map((value) => [value[0], i18n(value[1] as i18nTextId)]);
  }, [values]);

  const LabelTooltip = labelTooltip ? (
    <InputTooltip description={i18n(labelTooltip as i18nTextId)} size={18} />
  ) : null;

  return (
    <FormControl className="form-select__form-control">
      <InputLabel
        id={`${id}-label`}
        style={{
          color: "rgba(0, 0, 0, 0.38)",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {newLabel ? i18n(newLabel) : <>{i18n(label as i18nTextId)}</>}
        {LabelTooltip}
      </InputLabel>

      {setPivotData &&
      setPivotData4g &&
      setGPSData &&
      setPUMPData &&
      validationErrors &&
      setValidationErrors ? (
        <NativeSelect
          disabled={props.disabled}
          id={`${id}`}
          value={state[0]}
          onChange={(e) => {
            state[1](e.target.value);
            setPivotData("");
            setPivotData4g("");
            setGPSData("");
            setPUMPData("");
            setValidationErrors({
              ...validationErrors,
              pivotData: undefined,
              pivotData4g: undefined,
              gpsData: undefined,
              pumpData: undefined,
            });
          }}
        >
          {formattedValues.map((value) => {
            return (
              <option value={value[0]} key={value[0]}>
                {value[1]}
              </option>
            );
          })}
        </NativeSelect>
      ) : (
        <NativeSelect
          disabled={props.disabled}
          id={`${id}`}
          value={state[0]}
          onChange={(e) => {
            state[1](e.target.value);
          }}
        >
          {formattedValues.map((value) => {
            return (
              <option value={value[0]} key={value[0]}>
                {value[1]}
              </option>
            );
          })}
        </NativeSelect>
      )}
    </FormControl>
  );
}

export default FormSelect;

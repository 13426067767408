/* global google */
import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Map from "../../../../../components/Map/Map";
import { ControllerStream, GPSStream } from "../../../../../redux/pivots/types";
import {
  PainelStreamStatus,
  PivotLineColorMap,
} from "../../../../../utils/models/pivots";
import { Irpd } from "../../../../../redux/irpds/types";
import { DeviceNodeFound, EnabledDeviceMap } from "../../Devices";
import { Farm } from "../../../../../redux/farms/types";
import { SignalQualityMap } from "../DeviceBox/DeviceBox";
import { DeviceNode } from "../../../../../utils/devices/DeviceTypes";
import { i18n, i18nTextId } from "../../../../../i18n/i18nText";
import { Pivot } from "../../../../../redux/pivots/types";
interface Props {
  irpds?: Irpd[];
  draggable: boolean;
  disableUI?: boolean;
  latestStatus?: PainelStreamStatus;
  latestPanelStreamV5?: ControllerStream;
  latestGPSStream?: GPSStream;
  nodes: DeviceNodeFound[];
  farm: Farm;
  enabledDevices: EnabledDeviceMap;
  shouldShowGPS: boolean;
  pivotRefs: React.RefObject<HTMLDivElement>[];
  // Component feito para renderizar o bounds quando entrar na tab de components
  isTabSelected?: number;
  devices: DeviceNode[];
  showTerrainMap?: boolean;
}

export interface NodeLineShape {
  type: "LINE";
  shape: google.maps.Polyline;
  radio_id_from: string;
  radio_id_to: string;
}

type NodeCircleTypes = "CIRCLE" | "CIRCLE_GPS" | "CIRCLE_CENTRAL";

export interface NodeCircleShape {
  type: NodeCircleTypes;
  shape: google.maps.Circle;
  radio_id: string;
}

export interface NodeInfoShape {
  type: "INFO_CIRCLE" | "INFO_LINE";
  shape: google.maps.InfoWindow;
  radio_id: string;
}

type NodeShape = NodeLineShape | NodeCircleShape | NodeInfoShape;

let mapLines: { nodeShape: NodeShape; node: DeviceNodeFound }[] = [];

export default forwardRef((props: Props, ref) => {
  const { draggable, irpds, disableUI, nodes, isTabSelected } = props;

  const [googleMap, setGoogleMap] = useState<any>();
  const [mapShapes, setMapShapes] = useState<NodeShape[]>([]);

  const googleMapRef = useRef(undefined);

  const bounds = useMemo(() => {
    const mapBounds = new window.google.maps.LatLngBounds();

    mapShapes.forEach((shape) => {
      if (!shape) return;

      if (shape.type == "CIRCLE") mapBounds.union(shape.shape.getBounds());

      if (shape.type == "CIRCLE_GPS" && props.shouldShowGPS)
        mapBounds.union(shape.shape.getBounds());
    });
    return mapBounds;
  }, [mapShapes, props.shouldShowGPS]);

  const handleOnGoogleApiLoaded = useCallback(
    (maps: { map: any; maps: any }) => {
      if (googleMap === undefined) setGoogleMap(maps.map);
      if (!googleMapRef.current || googleMapRef.current != maps.map) {
        googleMapRef.current = maps.map;
      }
    },
    []
  );

  useImperativeHandle(ref, () => ({
    clearPivotMap: () => {
      mapShapes.forEach((shape) => {
        if (
          shape.type == "CIRCLE" ||
          shape.type == "CIRCLE_CENTRAL" ||
          shape.type == "CIRCLE_GPS" ||
          shape.type == "LINE"
        ) {
          shape.shape.setMap(null);
        }
      });

      setMapShapes([]);

      if (props.devices !== undefined) {
        const tempMapShapes = drawInitialDevices(props.devices, props.farm);
        setMapShapes(tempMapShapes);
      }
    },

    panToDevice: (device: DeviceNode) => {
      googleMap.panTo(
        new google.maps.LatLng(device.coordinate.lat, device.coordinate.lon)
      );
    },
  }));

  function isAPivotMonitor(object: any): object is Pivot {
    if (object.automation_type) return object.automation_type !== 0;
  }

  function drawInitialDevices(devices: DeviceNode[], farm: Farm) {
    const nodeShapes: NodeShape[] = [];

    devices.forEach((device, pivotIndex) => {
      const initialColor = device.type == "CENTRAL" ? "#F7EB2A" : "#FA0000";
      const circleDevice = new google.maps.Circle({
        strokeColor: initialColor, // "#11FF07",
        fillColor: initialColor, // "#11FF07",
        fillOpacity: 1,
        map: googleMap,
        center: {
          lat: device.coordinate.lat,
          lng: device.coordinate.lon,
        },
        radius: 40,
      });

      const infoWindowPivot = new google.maps.InfoWindow({
        content: `<span> ${device.name} ${
          isAPivotMonitor(device.object) ? "MONITOR" : ""
        } </span>
            <br>
            <span> ${device.radio_id ? device.radio_id : ""}
            </span>`,
      });

      google.maps.event.addListener(circleDevice, "click", () => {
        props.pivotRefs[pivotIndex].current.click();
        props.pivotRefs[pivotIndex].current.scrollIntoView({
          behavior: "smooth",
        });
      });

      google.maps.event.addListener(circleDevice, "mouseover", () => {
        infoWindowPivot.setPosition(circleDevice.getCenter());
        infoWindowPivot.open(googleMap, circleDevice);
      });

      google.maps.event.addListener(circleDevice, "mouseout", () => {
        infoWindowPivot.close();
      });

      let type: NodeCircleTypes = "CIRCLE";
      if (device.type == "CENTRAL") {
        type = "CIRCLE_CENTRAL";
      } else if (device.type == "GPS") {
        type = "CIRCLE_GPS";
      }

      nodeShapes.push({
        type,
        shape: circleDevice,
        radio_id: device.radio_id,
      });
    });

    return nodeShapes;
  }

  useEffect(() => {
    if (googleMap === undefined) return;

    if (mapShapes.length > 0) {
      mapShapes.forEach((shape) => {
        if (
          shape.type == "CIRCLE" ||
          shape.type == "CIRCLE_CENTRAL" ||
          shape.type == "CIRCLE_GPS" ||
          shape.type == "LINE"
        )
          shape.shape.setMap(null);
      });
    }

    if (props.devices !== undefined) {
      const tempMapShapes = drawInitialDevices(props.devices, props.farm);
      setMapShapes(tempMapShapes);
    }
  }, [googleMap, props.farm, props.devices]);

  useEffect(() => {
    if (Object.keys(props.enabledDevices).length === 0) {
      if (googleMap === undefined) return;

      if (mapShapes.length > 0) {
        mapShapes.forEach((shape) => {
          if (
            shape.type == "CIRCLE" ||
            shape.type == "CIRCLE_CENTRAL" ||
            shape.type == "CIRCLE_GPS" ||
            shape.type == "LINE"
          )
            shape.shape.setMap(null);
        });
      }

      if (props.devices !== undefined) {
        const tempMapShapes = drawInitialDevices(props.devices, props.farm);
        setMapShapes(tempMapShapes);
      }
    }
  }, [props.enabledDevices]);

  function drawEnabledDevices(
    enabledDevices: EnabledDeviceMap,
    mapShapes: NodeShape[],
    shouldShowGPS: boolean
  ) {
    Object.keys(enabledDevices).forEach((radio_id) => {
      const enabledShape = mapShapes.find((shape) => {
        if (shape.type == "CIRCLE" || shape.type == "CIRCLE_GPS") {
          return shape.radio_id == radio_id;
        }

        return false;
      });

      if (enabledShape) {
        if (enabledShape.type == "CIRCLE_GPS" && !props.shouldShowGPS) {
          enabledShape.shape.setVisible(false);
        }

        enabledShape.shape.setOptions({
          fillColor: "#11FF07",
          strokeColor: "#11FF07",
        });
      }
    });
  }

  useEffect(() => {
    if (googleMapRef.current !== undefined) {
      googleMapRef.current.fitBounds(bounds);
    }
  }, [isTabSelected]);

  function drawLines(nodes: DeviceNodeFound[], shouldShowGPS: boolean) {
    mapLines.forEach((line) => {
      if (line.nodeShape.type == "LINE") line.nodeShape.shape.setMap(null);
    });

    mapLines = [];

    nodes.forEach((node) => {
      if (!node.fromObject || !node.toObject) return;

      // se não, cria
      const line = new google.maps.Polyline({
        path: [
          new google.maps.LatLng(
            node.fromCoordinate.lat,
            node.fromCoordinate.lon
          ),
          new google.maps.LatLng(node.toCoordinate.lat, node.toCoordinate.lon),
        ],
        strokeColor: PivotLineColorMap[node.quality],
        strokeOpacity: 1,
        strokeWeight: 2,
        map: googleMap,
        zIndex: -1,
      });

      const infoWindowLine = new google.maps.InfoWindow({});

      const lineCenter = new google.maps.LatLng(
        (node.fromCoordinate.lat + node.toCoordinate.lat) / 2,
        (node.fromCoordinate.lon + node.toCoordinate.lon) / 2
      );

      if (node.typeFrom == "GPS" || node.typeTo == "GPS") {
        if (!props.shouldShowGPS) {
          line.setVisible(false);
        }
      }

      google.maps.event.addListener(line, "mouseover", () => {
        infoWindowLine.setContent(
          `
          <div style="display: flex; justify-content: center; flex-direction: column;">
            <div style="display: flex; flex-direction: row; font-weight: bold;">
              ${node.toObject.name} → ${node.fromObject.name} ${
            node.typeTo == "GPS" ? "(GPS)" : ""
          }
            </div>

            <div style="margin-top: 10px; width: 100%;">
              <b>quality:</b> ${i18n(
                SignalQualityMap[node.quality] as i18nTextId
              )} <br/>
              <b>strength:</b> ${node.db} dBm <br/>
            </div>
          </div>
          `
        );
        infoWindowLine.setPosition(lineCenter);
        infoWindowLine.open(googleMap, line);
      });

      google.maps.event.addListener(line, "mouseout", () => {
        infoWindowLine.close();
      });

      mapLines.push({
        nodeShape: {
          type: "LINE",
          radio_id_from: node.from,
          radio_id_to: node.to,
          shape: line,
        },
        node,
      });
    });
  }

  useEffect(() => {
    if (Object.keys(props.enabledDevices).length > 0) {
      drawEnabledDevices(props.enabledDevices, mapShapes, props.shouldShowGPS);
    }
  }, [props.enabledDevices, mapShapes, props.shouldShowGPS]);

  useEffect(() => {
    if (props.devices.length > 0) drawLines(nodes, props.shouldShowGPS);
  }, [nodes, props.devices, props.shouldShowGPS]);

  useEffect(() => {
    mapLines.forEach((line) => {
      if (line.nodeShape.type == "LINE") line.nodeShape.shape.setMap(null);
    });

    mapLines = [];
  }, []);

  useEffect(() => {
    if (!props.shouldShowGPS) {
      mapShapes.forEach((shape) => {
        if (shape.type == "CIRCLE_GPS") {
          shape.shape.setVisible(false);
        }
      });

      mapLines.forEach((line) => {
        if (line.node.typeTo == "GPS" || line.node.typeFrom == "GPS") {
          if (line.nodeShape.type == "LINE") {
            line.nodeShape.shape.setVisible(false);
          }
        }
      });
    } else {
      mapShapes.forEach((shape) => {
        if (shape.type == "CIRCLE_GPS") {
          shape.shape.setVisible(true);
        }
      });

      mapLines.forEach((line) => {
        if (line.node.typeTo == "GPS" || line.node.typeFrom == "GPS") {
          if (line.nodeShape.type == "LINE") {
            line.nodeShape.shape.setVisible(true);
          }
        }
      });
    }
  }, [props.shouldShowGPS, mapShapes]);

  return (
    <Map
      bounds={bounds}
      draggable={draggable}
      onGoogleApiLoaded={handleOnGoogleApiLoaded}
      disableUI={disableUI}
      fitBoundsEveryBoundsChange
      mapType={props.showTerrainMap ? "terrain" : undefined}
    >
      <></>
    </Map>
  );
});

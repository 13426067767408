import React, { Dispatch, SetStateAction } from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import "./FormDateTimePicker.scss";
import { i18n, i18nTextId } from "../../../i18n/i18nText";

interface Props {
  label: string;
  id: string;
  state: [moment.Moment | null, Dispatch<SetStateAction<moment.Moment>>];
  disabled?: boolean;
  error?: string;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
}

function FormDateTimePicker(props: Props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        margin="normal"
        id={props.id}
        ampm={false}
        helperText=""
        maxDate={props.maxDate}
        minDate={props.minDate}
        disabled={props.disabled}
        label={<>{i18n(props.label as i18nTextId)}</>}
        value={props.state[0]}
        format="DD MMM - HH:mm a"
        onChange={(value) => {
          if (value !== null) {
            props.state[1](value as moment.Moment);
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormDateTimePicker;

import { useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { useDispatch, useSelector } from "react-redux";
import { updateOrInsertPivot } from "../../redux/pivots/actions";
import { ApplicationState } from "../../redux";
import { getPivot as getPivotSelector } from "../../redux/pivots/selectors";
import { Pivot } from "../../redux/pivots/types";
import { useAsyncStatus, AsyncStatus } from "../tools/useAsyncStatus";
import { objIsEmpty } from "../../utils/types";
import useNotify from "../tools/useNotify";
import { useNavigate } from "react-router";
import routes from "../../routes/routes";

interface PivotActions {
  get: () => {};
  patch: (pivotConfig: any, patchObject: any, isV5?: boolean) => void;
  deviceID?: number;
}

const usePivot = (
  farmID: number,
  pivotID: number
): [AsyncStatus, Pivot | undefined, PivotActions] => {
  const dispatch = useDispatch();

  const [async, initLoading, finishLoading] = useAsyncStatus();

  const navigate = useNavigate();

  const pivot = useSelector((state: ApplicationState) =>
    getPivotSelector(state, pivotID)
  );
  const notify = useNotify();

  async function getPivot() {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        const response = await coreHTTPClient.get(
          `v3/farms/${farmID}/pivots/${pivotID}/`
        );

        if (checkExists(["data", response, "__cascade"]))
          dispatch(updateOrInsertPivot(response.data));
      } catch (err) {
        if (err.response?.status === 404) {
          navigate(routes.DASHBOARD.CODE404);
        }
      } finally {
        finishLoading();
      }
    });
  }

  async function patchPivot(
    pivotConfig: any,
    patchObject: any,
    isV5?: boolean,
    deviceID?: number
  ) {
    await new Promise(async (resolve) => {
      initLoading();

      try {
        var route = `v3/farms/${farmID}/pivots/${pivotID}/config/`;
        var routeV5 = `v3/farms/${farmID}/pivots/${pivotID}/device/${deviceID}/config/`;

        const res = await coreHTTPClient.post(
          isV5 ? routeV5 : route,
          isV5
            ? {
                message_subtype: "standard",
                equipment: pivotID,
                content: pivotConfig.content,
              }
            : pivotConfig
        );

        if (checkExists(["data", res, "__cascade"])) {
          dispatch(updateOrInsertPivot(res.data));
        }

        if (!objIsEmpty(patchObject)) {
          const response = await coreHTTPClient.patch(
            `v3/farms/${farmID}/pivots/${pivotID}/`,
            patchObject
          );
          if (checkExists(["data", response, "__cascade"])) {
            dispatch(updateOrInsertPivot(response.data));
          }
        } else {
          await getPivot();
        }
      } catch (err) {
        notify("IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG", "error");
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(() => {
    getPivot();
    // eslint-disable-next-line
  }, []);

  return [
    async,
    pivot,
    {
      get: getPivot,
      patch: patchPivot,
    },
  ];
};

export default usePivot;

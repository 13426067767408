import React, { useContext, useEffect, useRef, useState } from "react";
import momentTz from "moment-timezone";
import GeneralTable, {
  Column,
  Row,
  TablePaginationActions,
} from "../../../../../../components/GeneralTable/GeneralTable";
import { useParams } from "react-router";
import { isMobile } from "../../../../../../mobileConfig";
import { i18n } from "../../../../../../i18n/i18nText";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "../../../EditPivot/components/EditPivotFormV5/EditPivotFormV5.style";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { coreHTTPClient } from "../../../../../../services/webclient";
import {
  BauerPivot,
  bauerMappedData,
} from "../../../../../../redux/bauerPivot/types";
import {
  bauerPivotCommand,
  bauerPivotHistoryStatus,
  bauerPivotSentByUser,
  colorStatus,
} from "../../../../../../utils/models/bauerpivots";
import { FilterByDateState } from "../../../../../../redux/filterByDate/types";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../redux";
import "./HistoricBox.scss";
import { Skeleton } from "@material-ui/lab";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { setFilterByDate } from "../../../../../../redux/filterByDate/actions";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { blue } from "@material-ui/core/colors";
import moment from "moment";

const emptyRows = new Array(10).fill(0).map((_, index) => {
  return (
    <TableRow key={`row_${index}`}>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell>
        <Skeleton />
      </TableCell>

      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  );
});

interface Props {
  filterByDate: FilterByDateState;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box style={{ height: "100%" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

function EnhancedTableHead() {
  const headCells = isMobile()
    ? [
        {
          id: "Protocol",
          label: "Protocol",
        },
        {
          id: "Value",
          label: "Value",
        },
        {
          id: "Name",
          label: "Name",
        },
      ]
    : [
        {
          id: "Protocol1",
          label: "Protocol",
        },
        {
          id: "Value1",
          label: "Value",
        },
        {
          id: "Name1",
          label: "Name",
        },
        {
          id: "Protocol2",
          label: "Protocol",
        },
        {
          id: "Value2",
          label: "Value",
        },
        {
          id: "Name2",
          label: "Name",
        },
      ];
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={"none"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function HistoricBox(props: Props) {
  const columns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "type",
      label: <>{i18n("HISTORIC_PANEL_LABEL_TYPE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const mobcolumns: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 170,
    },
    {
      id: "overview",
      label: <>{i18n("HISTORIC_PANEL_LABEL_SUMMARY")}</>,
      minWidth: 170,
    },
  ];

  const pivotID: number = parseInt(useParams().pivot, 10);

  const { filterByDate } = props;

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState<any>();
  const [dataRow, setDataRows] = useState<BauerPivot[]>([]);
  const [value, setValue] = useState(0);
  const classes = useStyles(props);

  const [isConnected, socket] = useSocketIO();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const doneIcon = (
    <Tooltip title={i18n("COMMAND_SENT_WAITING_CONFIRMATION")}>
      <DoneIcon
        style={{
          margin: "0 5px 0 5px",
          fontSize: "1.4rem",
          color: blue[500],
        }}
      />
    </Tooltip>
  );

  const doneAllIcon = (
    <Tooltip title={i18n("COMMAND_CONFIRMED")}>
      <DoneAllIcon
        style={{
          margin: "0 5px 0 5px",
          fontSize: "1.4rem",
          color: blue[500],
        }}
      />
    </Tooltip>
  );

  const totalRef = useRef(total);
  const dataRowRef = useRef(dataRow);
  const pageRef = useRef(page);
  useEffect(() => {
    let machine_address = "";
    (async () => {
      const bauerPivot = await coreHTTPClient.get(
        `v4/smartrain/pivot/${pivotID}`
      );
      machine_address = bauerPivot.data.hardware_id;
      if (isConnected) {
        socket.subscribe(`${machine_address}@smartrain_pivot`);
        socket.bind("message_up_received", (data: BauerPivot) => {
          if (pageRef.current == 0) {
            const isSameDataUpdatedIndex = dataRowRef.current.findIndex(
              (value) => value.id == data.id
            );
            if (isSameDataUpdatedIndex >= 0) {
              let newData: BauerPivot[] = [...dataRowRef.current];
              newData[isSameDataUpdatedIndex] = data;
              dataRowRef.current = newData;
              setDataRows(newData);
            } else {
              let newData: BauerPivot[] = [...dataRowRef.current];
              newData.unshift(data);
              newData.pop();
              dataRowRef.current = newData;
              setTotal(totalRef.current + 1);
              setDataRows(newData);
            }
          }
        });
      }
    })();
    return () => {
      if (isConnected) {
        socket.unbind("message_up_received");
        socket.unsubscribe(`${machine_address}@smartrain_pivot`);
      }
    };
  }, []);

  const getBauerPivotInfo = async (filterByDate) => {
    const bauerPivot = await coreHTTPClient.get(
      `v4/smartrain/pivot/${pivotID}/history?page=${
        page + 1
      }&gte=${filterByDate.data.start_date.toISOString()}&lte=${filterByDate.data.end_date.toISOString()}`
    );
    if (bauerPivot.data) {
      setTotal(bauerPivot.data.total);
      setDataRows(bauerPivot.data.items);
      totalRef.current = bauerPivot.data.total;
      dataRowRef.current = bauerPivot.data.items;
      pageRef.current = page;
    }
  };

  useEffect(() => {
    try {
      getBauerPivotInfo(filterByDate);
    } catch (e) {
      console.log(e);
      getBauerPivotInfo(filterByDate);
    } finally {
      setLoading(false);
    }
  }, [page, filterByDate, pivotID]);

  const wet = "rgba(70, 121, 237, 0.05)";
  const problem = "rgba(255, 0, 0, 0.1)";

  useEffect(() => {
    const rowsMapped = dataRow.map((rowFromServer) => {
      let tableRows = [];
      if (!rowFromServer) return;
      let protocol;
      if (rowFromServer.raw_message) {
        protocol = rowFromServer.raw_message.split(";");
        if (isMobile()) {
          for (let index = 0; index < protocol.length; index++) {
            if (!protocol[index].includes(":")) continue;
            tableRows.push(
              <TableRow>
                <TableCell padding="none" align="center">
                  {protocol[index].split(":")[0]}
                </TableCell>
                <TableCell padding="none" align="center">
                  {protocol[index].split(":")[1]}
                </TableCell>
                <TableCell
                  padding="none"
                  align="center"
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  {bauerMappedData[protocol[index].split(":")[0]]}
                </TableCell>
              </TableRow>
            );
          }
        } else
          for (let index = 0; index < protocol.length; index = index + 2) {
            if (!protocol[index].includes(":")) continue;
            if (protocol[index + 1].includes(":")) {
              tableRows.push(
                <TableRow>
                  <TableCell padding="none" align="center">
                    {protocol[index].split(":")[0]}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {protocol[index].split(":")[1]}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{
                      borderRight: "1px solid black",
                      wordBreak: "break-word",
                    }}
                  >
                    {bauerMappedData[protocol[index].split(":")[0]]}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {protocol[index + 1].split(":")[0]}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {protocol[index + 1].split(":")[1]}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {bauerMappedData[protocol[index + 1].split(":")[0]]}
                  </TableCell>
                </TableRow>
              );
            } else {
              tableRows.push(
                <TableRow>
                  <TableCell padding="none" align="center">
                    {protocol[index].split(":")[0]}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {protocol[index].split(":")[1]}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{
                      borderRight: "1px solid black",
                      wordBreak: "break-word",
                    }}
                  >
                    {bauerMappedData[protocol[index].split(":")[0]]}
                  </TableCell>
                  <TableCell padding="none" align="center"></TableCell>
                  <TableCell padding="none" align="center"></TableCell>
                  <TableCell padding="none" align="center"></TableCell>
                </TableRow>
              );
            }
          }
      }

      return {
        color:
          rowFromServer.status == 1 || rowFromServer.status == 15
            ? wet
            : colorStatus(rowFromServer.status) == "#e92400"
            ? problem
            : undefined,
        date: momentTz
          .utc(rowFromServer.created_at)
          .tz(momentTz.tz.guess())
          .format("DD MMM HH:mm"),
        type: (
          <>
            {bauerPivotSentByUser(rowFromServer.command) ? (
              <Tooltip title={i18n("BAUER_PIVOT_HISTORY_TOOLTIP_USER")}>
                <ArrowUpward fontSize="inherit" />
              </Tooltip>
            ) : (
              <Tooltip title={i18n("BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE")}>
                <ArrowDownward fontSize="inherit" />
              </Tooltip>
            )}{" "}
            {bauerPivotCommand(rowFromServer.command)}
          </>
        ),
        overview:
          isMobile() && !rowFromServer.status ? (
            <>
              {bauerPivotSentByUser(rowFromServer.command) ? (
                <Tooltip title={i18n("BAUER_PIVOT_HISTORY_TOOLTIP_USER")}>
                  <ArrowUpward fontSize="inherit" />
                </Tooltip>
              ) : (
                <Tooltip title={i18n("BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE")}>
                  <ArrowDownward fontSize="inherit" />
                </Tooltip>
              )}{" "}
              {bauerPivotCommand(rowFromServer.command)}{" "}
              {rowFromServer.delivery_status == 2
                ? doneAllIcon
                : rowFromServer.delivery_status == 1
                ? doneIcon
                : undefined}
            </>
          ) : (
            <>
              {bauerPivotHistoryStatus(rowFromServer.status)}
              {rowFromServer.delivery_status == 2
                ? doneAllIcon
                : rowFromServer.delivery_status == 1
                ? doneIcon
                : undefined}
            </>
          ),
        datailedComponent: () =>
          value == 2 ? (
            <div className="expanded-content__background__white">
              <div className="expanded-content__group-padding">
                {rowFromServer.raw_message ? (
                  <TableContainer>
                    <Table
                      stickyHeader
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead />
                      <TableBody>
                        {tableRows.length > 0 ? tableRows : emptyRows}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : value == 1 ? (
            <div className="expanded-content__background">
              <div className="expanded-content__group-padding">
                <span style={{ wordBreak: "break-word", fontWeight: "bold" }}>
                  {rowFromServer.raw_message
                    ? rowFromServer.raw_message.replace(/;/g, "; ")
                    : ""}
                </span>
              </div>
            </div>
          ) : (
            <div className="expanded-content__background">
              <div className="expanded-content__group-padding">
                <div className="expanded-content__column-6-6">
                  {rowFromServer.current_pressure != undefined ? (
                    <span style={{ display: "block" }}>
                      {i18n("BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE")}:{" "}
                      <b>{rowFromServer.current_pressure} bar</b>
                    </span>
                  ) : null}
                  {rowFromServer.machine_angle != undefined && (
                    <span style={{ display: "block" }}>
                      {i18n("BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE")}:{" "}
                      <b>{rowFromServer.machine_angle / 100}º</b>
                    </span>
                  )}
                  {(rowFromServer.direction == 0 ||
                    rowFromServer.direction == 1) && (
                    <span style={{ display: "block" }}>
                      {i18n("PIVOT_ACTION_FIELD_DIRECTION")}:{" "}
                      <b>
                        {rowFromServer.direction == 0
                          ? i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")
                          : rowFromServer.direction == 1
                          ? i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")
                          : i18n("PIVOT_ACTION_VALUE_DIRECTION_STOPPED")}
                      </b>
                    </span>
                  )}
                  {rowFromServer.username ? (
                    <span style={{ display: "block" }}>
                      {i18n("HISTORIC_PIVOT_ACTION_USER")}{" "}
                      <b>{rowFromServer.username}</b>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ),
      };
    });
    setRows(rowsMapped);
    setLoading(false);
  }, [dataRow, value]);

  /*
    useEffect para propagar props.check na referência isChecked, usada dentro dos 
    binds websockets que são declarados somente no mount da tela.
  */
  //When switch change, the historicTable receive newobject to return newHisotirc

  if (rows !== undefined) {
    return (
      <>
        <Paper
          style={{
            width: "100%",
            boxShadow: "none",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            borderRadius: "0px",
          }}
        >
          <Tabs
            value={value}
            onChange={(e, value) => setValue(value)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={i18n("BAUER_PIVOT_HISTORY_USER")} {...a11yProps(0)} />
            <Tab
              label={i18n("BAUER_PIVOT_HISTORY_PROTOCOL")}
              {...a11yProps(1)}
            />
            <Tab
              label={i18n("BAUER_PIVOT_HISTORY_PROTOCOL_TABLE")}
              {...a11yProps(2)}
            />
          </Tabs>
        </Paper>
        {rows.length === 0 && (
          <Card className="bauer-reports-construction-card">
            <Typography className="bauer-reports-construction-text">
              {i18n("NO_INFO")}
            </Typography>
          </Card>
        )}
        <GeneralTable
          page={page}
          hideColumns={true}
          columns={isMobile() ? mobcolumns : columns}
          total={total}
          rows={rows}
          rowsPerPage={10}
          loading={loading}
          onPageChange={(offset) => {
            setPage(offset);
          }}
        />
      </>
    );
  } else {
    return (
      <GeneralTable
        page={1}
        hideColumns={true}
        columns={isMobile() ? mobcolumns : columns}
        total={0}
        rows={new Array<Row>(0)}
        rowsPerPage={10}
        loading={true}
        onPageChange={() => {}}
      />
    );
  }
}

export default HistoricBox;

import { Billing, BillingState, BillingTypes } from "./types";
import { BillingActions } from "./actions";

const INITIAL_STATE: BillingState = {
  data: null,
};

function reducer(state = INITIAL_STATE, action: BillingActions) {
  const { type, payload }: { type: string; payload: Billing } = action;

  switch (type) {
    case BillingTypes.SET_BILLING:
      return { ...state, data: payload };

    case BillingTypes.UPDATE_OR_INSERT_BILLING:
      return { ...state, data: { ...state.data, ...payload } };

    case BillingTypes.RESET:
      return { ...state, data: { ...payload } };

    default:
      return state;
  }
}

export default reducer;

import React from "react";
import { MutableRefObject } from "react";
import whiteMarker from "../../../../../../assets/images/markers/white.svg";
import WifiTetheringIcon from "../../../../../../assets/images/markers/wifi_tethering.svg";

import { MapShape } from "../../../../../../utils/types";
import { Repeater } from "../../../../../../recoils/RepeaterRecoil";

export async function addRepeaterOnMap(
  googleMapRef: MutableRefObject<any>,
  repeaters: Repeater[],
  farmID: number
): Promise<MapShape[]> {
  if (googleMapRef.current === undefined) return;

  return repeaters.map<MapShape>((repeater) => {
    let centerPosition: string[] = repeater.position.split(",");
    let referenceRadius: number = 60;

    let centerPositionGMaps = new google.maps.LatLng(
      parseFloat(centerPosition[0]),
      parseFloat(centerPosition[1])
    );
    const infowindow = new google.maps.InfoWindow({
      content: `<span 
    style="cursor: pointer;"
    onclick="(function (){
      let event = new CustomEvent('navigateToRepeater', { detail: {
        pivotId: ${repeater.id},
        farmId: ${farmID}
      }});

      window.dispatchEvent(event);
    })()">
      <span style='font-weight:600;font-size:.7rem;margin:4px 0 4px 8px;padding:4px 8px;border-radius:8px;color:#000;text-align: left'>
        ${repeater.name}
      </span>
    </span>`,
    });

    //////////////
    // Desenhos //
    //////////////

    let repeaterIcon = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: WifiTetheringIcon,
      },
    });

    let circle = new google.maps.Circle({
      fillOpacity: 0,
      strokeOpacity: 0.0,
      strokeWeight: 0.5,
      map: googleMapRef.current,
      center: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      radius: referenceRadius,
    });

    //TODO Setor

    let marker = new google.maps.Marker({
      position: {
        lat: parseFloat(centerPosition[0]),
        lng: parseFloat(centerPosition[1]),
      },
      map: googleMapRef.current,
      icon: {
        url: whiteMarker,
      },
      visible: false,
    });

    function setPivotVisibility(visibility: boolean) {
      circle.setVisible(visibility);
      repeaterIcon.setVisible(visibility);
      marker.setVisible(!visibility);
    }

    /////////////////////
    // Event Listeners //
    /////////////////////

    google.maps.event.addListener(repeaterIcon, "mouseover", function () {
      infowindow.setPosition(centerPositionGMaps);
      infowindow.open(googleMapRef.current, repeaterIcon);
    });

    google.maps.event.addListener(repeaterIcon, "click", function () {
      let event = new CustomEvent("navigateToRepeater", {
        detail: {
          repeaterId: repeater.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(repeaterIcon, "mouseout", function () {
      infowindow.close();
    });

    google.maps.event.addListener(marker, "mouseover", function () {
      infowindow.setPosition(centerPositionGMaps);
      infowindow.open(googleMapRef.current, marker);
    });

    google.maps.event.addListener(marker, "click", function () {
      let event = new CustomEvent("navigateToRepeater", {
        detail: {
          repeaterId: repeater.id,
          farmId: farmID,
        },
      });
      window.dispatchEvent(event);
    });

    google.maps.event.addListener(marker, "mouseout", function () {
      infowindow.close();
    });

    google.maps.event.addListener(
      googleMapRef.current,
      "zoom_changed",
      function () {
        let zoom = googleMapRef.current.getZoom();
        if (zoom > 11) {
          setPivotVisibility(true);
        }
        if (zoom <= 11) {
          setPivotVisibility(false);
        }
      }
    );

    return {
      type: "RepeaterShape",
      circle: circle,
    };
  });
}

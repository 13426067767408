import moment from "moment";
import { coreHTTPClient } from "../../services/webclient";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import useNotify from "../tools/useNotify";
import { hideLoading, showLoading } from "../../redux/loading/actions";

interface IrpdctionCreateModel {
  mode: string;
  checkStart: boolean;
  checkEnd: boolean;
  startDateTime?: moment.Moment;
  endDateTime?: moment.Moment;
  farmID: number;
  irpdID: number;
}

const useCreateIRPDAction = (): [
  (irpdAction: IrpdctionCreateModel) => void
] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  async function createIrpdAction(irpdAction: IrpdctionCreateModel) {
    let startDateTime = irpdAction.startDateTime;
    let endDateTime = irpdAction.endDateTime;
    const mode = parseInt(irpdAction.mode);
    const payloadON = {
      mode: parseInt(irpdAction.mode),
      start: irpdAction.checkStart ? null : startDateTime.subtract(3, "hours"),
      end: irpdAction.checkEnd ? null : endDateTime.subtract(3, "hours"),
    };

    const payloadOFF = {
      mode: parseInt(irpdAction.mode),
      end: irpdAction.checkEnd ? null : endDateTime,
    };

    await new Promise(async (resolve) => {
      try {
        dispatch(showLoading());
        await coreHTTPClient
          .post(
            `/v3/farms/${irpdAction.farmID}/irpds/${irpdAction.irpdID}/action/`,
            mode === 1 ? payloadON : payloadOFF
          )
          .then(() => {
            dispatch(hideLoading());
            dispatch(hideModal());
            notify("IRPD_BOX_COMMAND_SENT", "success");
          });
      } catch (err) {
        dispatch(hideLoading());

        notify("IRPD_BOX_COMMAND_ERROR", "error");
      }
    });
  }

  return [createIrpdAction];
};

export default useCreateIRPDAction;

import { MenuItem } from "./types";
import {
  Home,
  Notifications,
  LocationOn,
  AccountCircle,
  DeviceHub,
} from "@material-ui/icons";
import routes from "../../../../../routes/routes";

const Profile = {
  id: "profile",
  label: "NAV_ITEM_PROFILE",
  to: routes.DASHBOARD.PROFILE,
};

const Language = {
  id: "language",
  label: "NAV_ITEM_LANGUAGE",
};

const Logout = {
  id: "logout",
  label: "NAV_ITEM_LOGOUT",
};

export const DRAWER_ITEMS: MenuItem[] = [
  {
    id: "homepage",
    label: "DRAWER_HOME",
    icon: Home,
    submenus: [],
    to: "/dashboard",
  },
  {
    id: "home",
    label: "DRAWER_HOME",
    icon: AccountCircle,
    submenus: [Profile, Language, Logout],
    to: "/dashboard/main",
  },
  {
    id: "farms",
    label: "DRAWER_FARMS",
    icon: LocationOn,
    submenus: [],
    to: "/",
  },
  {
    id: "devices",
    label: "DEVICE_DRAWER_TITLE",
    icon: DeviceHub,
    submenus: [],
    to: "farms/:farm/devices",
  },
  {
    id: "notifications",
    label: "NOTIFICATIONS",
    icon: Notifications,
    submenus: [],
    to: "farms/:farm/notifications",
  },
];

export const DRAWER_ITEMS_NO_PERMISSIONS: MenuItem[] = [
  {
    id: "home",
    label: "DRAWER_HOME",
    icon: AccountCircle,
    submenus: [Profile, Language, Logout],
    to: "/dashboard/main",
  },
];

import { FarmState, FarmsTypes } from "./types";
import { FarmActions } from "./actions";

const INITIAL_STATE: FarmState = {
  data: {
    id: undefined,
    name: sessionStorage.getItem("currentFarmName")
      ? sessionStorage.getItem("currentFarmName")
      : undefined,
  },
};

function reducer(state = INITIAL_STATE, action: FarmActions) {
  const { type }: { type: string } = action;

  switch (type) {
    case FarmsTypes.STORE_FARM:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}

export default reducer;

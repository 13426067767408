// v3/farms/farmID/pivots/pivotID/actions/vri/

import { coreHTTPClient } from "../../services/webclient";
import { useDispatch } from "react-redux";
import { hideModal } from "../../redux/modal/actions";
import useNotify from "../tools/useNotify";
import { hideLoading, showLoading } from "../../redux/loading/actions";

interface PivotActionVRICreateModel {
  start_angle: number;
  direction_array: number[]; //avanço: 1, reverso: 0
  speed_array: number[];
  endgun_array: number[];
  angle_array: number[];
  operation_mode: number;
  start: string;
  farmID: number;
  pivotID: number;
}

const useCreatePivotActionVRI = (): [
  (pivotActionVRI: PivotActionVRICreateModel) => void
] => {
  const dispatch = useDispatch();

  const notify = useNotify();

  async function postVRIDeliverer(farmID, pivotID, id, time) {
    let body = {
      actionrecipe: id,
      start: time,
    };

    await coreHTTPClient
      .post(
        `/v3/farms/${farmID}/pivots/${pivotID}/actions/vri-deliverer/`,
        body
      )
      .then((response) => {
        dispatch(hideLoading());
        dispatch(hideModal());
      })
      .catch((err) => {
        dispatch(hideLoading());
      });
  }

  async function createPivotActionVRI(
    pivotActionVRI: PivotActionVRICreateModel
  ) {
    const payload = {
      start_angle: pivotActionVRI.start_angle,
      direction_array: pivotActionVRI.direction_array,
      speed_array: pivotActionVRI.speed_array,
      angle_array: pivotActionVRI.angle_array,
      endgun_array: pivotActionVRI.endgun_array,
      mode: pivotActionVRI.operation_mode,
    };

    await new Promise(async (resolve) => {
      try {
        dispatch(showLoading());
        await coreHTTPClient
          .post(
            `/v3/farms/${pivotActionVRI.farmID}/pivots/${pivotActionVRI.pivotID}/actions/vri/`,
            payload
          )
          .then((response) => {
            notify("IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG", "success");
            postVRIDeliverer(
              pivotActionVRI.farmID,
              pivotActionVRI.pivotID,
              response.data.id,
              pivotActionVRI.start
            );
          });
      } catch (err) {
        // dispatch(hideLoading());

        notify("IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG", "error");
      }
    });
  }

  return [createPivotActionVRI];
};

export default useCreatePivotActionVRI;

import React from "react";
import { useKeyboardOpen } from "../../../../../../utils/KeyboardHook";
import { Button } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { i18n } from "../../../../../../i18n/i18nText";

export default function ShowHistoricFloatingLabel(props: {
  className: string;
}) {
  let isKeyboardOpen = useKeyboardOpen();

  if (isKeyboardOpen) return <></>;

  return (
    <div className="edit-pivot-form-v5__tabBar">
      <Button
        color="primary"
        className={props?.className}
        variant="outlined"
        endIcon={<ExpandLessIcon />}
      >
        <>{i18n("EDIT_PIVOT_LABEL_SETTING_HISTORIC")}</>
      </Button>
    </div>
  );
}

import React from "react";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import {
  Pivot,
  StopAction,
} from "../../../../../../../../../../redux/pivots/types";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  stopAction: StopAction;
}

export default function ExpandedStopAction(props: Props) {
  return (
    <div className="expanded-content__background">
      <div className="expanded-content__group-padding">
        <span>
          {i18n("HISTORIC_CREATED_BY")}
          <b>{i18n("VALUE", [`${props.stopAction.username}`])}</b>
        </span>
      </div>
      <div className="expanded-content__group">
        <span>
          {i18n("CURRENT_ANGLE")}
          <b>
            {i18n("VALUE", [
              ` ${formatFloatValue(props.stopAction.current_angle, 1)}º`,
            ])}
          </b>
        </span>
      </div>
      <div className="expanded-content__group"></div>
    </div>
  );
}

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: "10px",
  },
  progress: {
    color: "white",
  },
  fab: {
    marginTop: theme.spacing(1),
    position: "absolute",
    bottom: "-20px",
    right: "-20px",
  },
  buttonLogin: {
    width: "90%",
    margin: "22px 0",
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonBack: {
    width: "43%",
    height: "35px",
    fontSize: "15px",
  },
}));

export default useStyles;

export enum UsersFarmTypes {
  SET_USERSFARM = "@@usersFarm/SET_USERSFARM",
  DELETE_USERSFARM = "@@usersFarm/DELETE_USERSFARM",
}

export interface UserFarm {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  pending: boolean;
}

export interface UsersFarmState {
  list: UserFarm[];
}

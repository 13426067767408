import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coreHTTPClient } from "../../../../../../services/webclient";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import Chart from "react-apexcharts";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import { isMobile } from "../../../../../../mobileConfig";
import GeneralChartDateSelect from "../../../../components/ChartDateSelect/GeneralChartDateSelect";
import ChartPagination from "../../../../components/ChartPagination/ChartPagination";
import { formatFloatValue } from "../../../../../../utils/models/format";

const Container = styled.div`
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
`;

type DataObject = { from: Date; to: Date; value: number };

interface Props {
  farmID: number;
  meterSystemID: number;
  iMeterID: number;
  graphicMaxValue: number;
  minLimit: number;
  maxLimit: number;
  protocol: string;
}

function LakeLevelChart(props: Props) {
  const {
    farmID,
    meterSystemID,
    iMeterID,
    graphicMaxValue,
    minLimit,
    maxLimit,
  } = props;

  const [chartPage, setChartPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chartDataLength, setChartDataLength] = useState("12");
  const [tempStartDate, setTempStartDate] = useState<moment.Moment>(
    moment().subtract(1, "month")
  );
  const [tempEndDate, setTempEndDate] = useState<moment.Moment>(moment());
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(1, "month")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [chartData, setChartData] = useState<DataObject[]>();

  function handleDataLength(value) {
    setChartDataLength(value);
    setChartPage(0);
  }

  async function getLakeLevelData() {
    let startDateURL: string;
    let endDateURL: string;

    startDateURL = startDate.format("YYYY-M-D");
    endDateURL = endDate.format("YYYY-M-D");

    setLoading(true);

    try {
      console.log("[meter system protocol]", props.protocol);
      const endpointversion =
        props.protocol === "5.3"
          ? `v4/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/lake_level/?date_start=${startDateURL}&date_end=${endDateURL}`
          : `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/lake-level/?date_start=${startDateURL}&date_end=${endDateURL}`;

      const response = await coreHTTPClient.get(endpointversion);
      if (response.data) {
        setChartData(response.data);
        setLastPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10))
        );
        setChartPage(
          Math.ceil(response.data?.length / parseInt(chartDataLength, 10)) - 1
        );
        setLoading(false);
      } else {
        setLastPage(0);
        setChartPage(0);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  function MyResponsiveBar(chartData: DataObject[]) {
    const sortedData = chartData
      .map((chartDatum) => ({
        labelDate: moment(chartDatum.from),
        value: chartDatum.value ? chartDatum.value.toFixed(2) : 0,
        from: chartDatum.from,
      }))
      .sort((a, b) => {
        if (moment(a.from).isBefore(b.from)) {
          return -1;
        }
        if (moment(a.from).isAfter(b.from)) {
          return 1;
        }
        return 0;
      })
      .slice(
        parseInt(chartDataLength, 10) * chartPage,
        parseInt(chartDataLength, 10) * (chartPage + 1)
      );

    const state = {
      series: [
        {
          name: "m",
          data: sortedData.map((sortedDatum) =>
            props.protocol === "5.3"
              ? Number(sortedDatum?.value)
              : Number(sortedDatum?.value) / 100
          ),
        },
      ],
      options: {
        chart: {
          events: {
            mounted: () => {
              window.scrollTo(0, 0);
            },
          },
        },
        colors: ["#4679ed"],
        grid: {
          row: {
            colors: ["#fff", "#fff"],
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: function (value) {
            return formatFloatValue(value);
          },
          style: {
            colors: ["#515160"],
            fontSize: "11px",
          },
        },
        xaxis: {
          categories: sortedData.map((sortedDatum) =>
            moment(sortedDatum.labelDate).format("DD/MM HH:mm")
          ),
          labels: {
            rotate: -50,
          },
        },
        yaxis: [
          {
            min: 0,
            max: graphicMaxValue,
            labels: {
              formatter(value) {
                return formatFloatValue(value);
              },
            },
            title: {
              text: `${i18n("BASIC_INFO_BOX_LEVEL")} (m)`,
            },
            tooltip: {
              y: {
                formatter(value) {
                  return formatFloatValue(value);
                },
              },
            },
            stroke: {
              curve: "smooth",
            },
          },
          {
            min: 0,
            max: 100,
            labels: {
              formatter(value) {
                return formatFloatValue(value);
              },
            },
            title: {
              text: `${i18n("BASIC_INFO_BOX_LEVEL")} (%)`,
              rotate: 90,
            },
            opposite: true,
          },
        ],
        annotations: {
          yaxis: [
            maxLimit !== 0 && minLimit !== 0
              ? {
                  y: graphicMaxValue * (maxLimit / 100),
                  borderColor: "red",
                  label: {
                    borderColor: "red",
                    style: {
                      color: "#fff",
                      background: "red",
                    },
                    text: `${maxLimit}%`,
                  },
                }
              : null,
            maxLimit !== 0 && minLimit !== 0
              ? {
                  y: graphicMaxValue * (minLimit / 100),
                  borderColor: "orange",
                  label: {
                    borderColor: "orange",
                    style: {
                      color: "#fff",
                      background: "orange",
                    },
                    text: `${minLimit}%`,
                  },
                }
              : null,
          ],
        },
      },
    };
    return (
      <Chart
        id="LakeLevelChart"
        // @ts-ignore
        options={state.options}
        series={state.series}
        type="area"
        width={isMobile() ? "125%" : "180%"}
        height={isMobile() ? "350px" : "400px"}
      />
    );
  }

  useEffect(() => {
    getLakeLevelData();
  }, [chartDataLength, startDate, endDate, iMeterID]);

  return (
    <DashboardBox
      centerElement={<h2>{i18n("BASIC_INFO_BOX_CHART_LAKE_LEVEL")}</h2>}
      rightElement={
        <GeneralChartDateSelect
          tempStartDate={tempStartDate}
          tempEndDate={tempEndDate}
          setTempStartDate={setTempStartDate}
          setTempEndDate={setTempEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      }
    >
      <Container>
        <div className="basic-info__header-infos">
          {i18n("BASIC_INFO_BOX_CHART_GENERATED_BETWEEN", [
            moment(startDate).format("DD MMM YYYY"),
            moment(endDate).format("DD MMM YYYY"),
          ])}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div style={{ margin: "0px 0px 40px 0px", width: "80%" }}>
            <FormSelect
              id="SelectPrecision"
              label="IMETER_LEVEL_CHART_NUMBER_OF_POINTS"
              newLabel="IMETER_LEVEL_CHART_NUMBER_OF_POINTS"
              state={[chartDataLength, handleDataLength]}
              values={[
                ["12", "NUMBER_OF_POINTS_12"],
                ["24", "NUMBER_OF_POINTS_24"],
                ["30", "NUMBER_OF_POINTS_30"],
              ]}
            />
          </div>
        </div>

        <div
          style={
            isMobile()
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }
              : {
                  marginLeft: "40px",
                  width: "50%",
                }
          }
        >
          {!loading ? (
            <div>
              {chartData ? (
                chartData.length > 0 ? (
                  <>{MyResponsiveBar(chartData)}</>
                ) : (
                  <div
                    style={{
                      width: isMobile() ? "100%" : "180%",
                      height: isMobile() ? "380px" : "430px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {i18n("METERSYSTEM_NO_READINGS")}
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: isMobile() ? "100%" : "180%",
                    height: isMobile() ? "380px" : "430px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                width: isMobile() ? "100%" : "180%",
                height: isMobile() ? "380px" : "430px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>

        <ChartPagination
          lastPage={lastPage}
          chartPage={chartPage}
          setChartPage={setChartPage}
          chartDataLength={chartDataLength}
          chartData={chartData}
        />
      </Container>
    </DashboardBox>
  );
}

export default LakeLevelChart;

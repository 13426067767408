import { AsyncStatus, useAsyncStatus } from "../tools/useAsyncStatus";
import { useState, useEffect } from "react";
import { coreHTTPClient } from "../../services/webclient";

const useIrpdConfigHistoricV5 = (
  farmID: number,
  irpdID: number,
  pinned: boolean = false,
  datasheet: boolean = false,
  protocol?: number
): [
  AsyncStatus,
  any[] | undefined,
  number,
  () => void,
  () => void,
  (value: string) => void
] => {
  const [async, initLoading, finishLoading] = useAsyncStatus();
  const [total, setTotal] = useState(0);
  const [result, setResult] = useState<any[]>();
  const [next, setNext] = useState();
  const [previous, setPrevious] = useState();

  async function getHistoric() {
    await new Promise(async (resolve, reject) => {
      initLoading();
      try {
        const apiVersion = protocol === 5.2 ? "v4" : "v3";
        const response = await coreHTTPClient
          .get(
            `${apiVersion}/farms/${farmID}/irpds/${irpdID}/configv5/?pinned=${pinned}&is_datasheet=${datasheet}`
          )
          .then((response) => {
            setTotal(response.data.count);
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
        resolve(response);
      } catch (err) {
        reject(err);
      } finally {
        finishLoading();
      }
    });
  }

  async function nextPage() {
    await new Promise(async (resolve, reject) => {
      initLoading();
      try {
        const response = await coreHTTPClient.get(next).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
        resolve(response);
      } catch (err) {
        reject(err);
      } finally {
        finishLoading();
      }
    });
  }

  async function previousPage() {
    await new Promise(async (resolve, reject) => {
      initLoading();
      try {
        const response = await coreHTTPClient.get(previous).then((response) => {
          setResult(response.data.results);
          setNext(response.data.next);
          setPrevious(response.data.previous);
        });
        resolve(response);
      } catch (err) {
        reject(err);
      } finally {
        finishLoading();
      }
    });
  }

  async function currentPage(current: string) {
    await new Promise(async (resolve, reject) => {
      initLoading();
      try {
        const response = await coreHTTPClient
          .get(
            `v3/farms/${farmID}/irpds/${irpdID}/configv5/?page=${current}&pinned=${pinned}&is_datasheet=${datasheet}`
          )
          .then((response) => {
            setResult(response.data.results);
            setNext(response.data.next);
            setPrevious(response.data.previous);
          });
        resolve(response);
      } catch (err) {
        reject(err);
      } finally {
        finishLoading();
      }
    });
  }

  useEffect(
    () => {
      getHistoric();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [async, result, total, previousPage, nextPage, currentPage];
};

export default useIrpdConfigHistoricV5;

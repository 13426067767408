import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../hooks/tools/useFormValidation";
import {
  checkEmail,
  checkIsNotNumber,
  checkIsOnIntervalString,
} from "../../../../../../utils/checks";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import countries from "../../../../../../utils/consts/countries";
import { handleNumberMask } from "../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { isMobile } from "../../../../../../mobileConfig";
import axios from "axios";
import useNotify from "../../../../../../hooks/tools/useNotify";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { i18n } from "../../../../../../i18n/i18nText";

interface Props {
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  newFarm: [any, (value: any) => void];
  IDD: any;
}

export default function BillingStep(props: Props) {
  const { validationErrors, formValidationActions, newFarm, IDD } = props;

  const notify = useNotify();

  const [documentType, seDocumentType] = React.useState(
    newFarm[0].customer.document_type
  );

  //customer document
  const setValidDocument = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          document: documentType ? formatCPF(trimValue) : formatCNPJ(trimValue),
        },
      }));

      const formatedValue = documentType
        ? formatCPF(trimValue)
        : formatCNPJ(trimValue);

      formValidationActions.setError(
        "customer.document",
        validateDocumentType(documentType, formatedValue)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  //customer company name
  const setValidCompanyName = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          company_name: trimValue,
        },
      }));

      formValidationActions.setError(
        "customer.company_name",
        checkIsOnIntervalString(value, 3, 34)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // customer phone
  const setValidPhone = useCallback(
    (value) => {
      let updatedPhone = handleNumberMask(value);

      if (updatedPhone.length > 11) {
        updatedPhone = updatedPhone.slice(0, 11);
      }

      updatedPhone = updatedPhone.replace(
        /^(\d{2})(\d{4,5})(\d{4})$/,
        "($1) $2-$3"
      );

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          phone: updatedPhone,
        },
      }));

      if (
        updatedPhone === "" ||
        !/^\(\d{2}\) \d{4,5}-\d{4}$/.test(updatedPhone) ||
        /^(\d)\1+$/.test(updatedPhone.replace(/\D/g, ""))
      ) {
        formValidationActions.setError(
          "customer.phone",
          "EDIT_FARM_ERROR_PHONE"
        );
      } else {
        formValidationActions.clearError("customer.phone");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // customer email
  const setValidEmail = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          email: trimValue,
        },
      }));

      if (value.length === 0 || !checkEmail(value))
        formValidationActions.setError(
          "customer.email",
          "EDIT_PROFILE_ERROR_EMAIL"
        );
      else formValidationActions.setError("customer.email", undefined);
    },

    [validationErrors]
  );

  const setValidPostalCode = useCallback(
    (value) => {
      let trimValue = handleNumberMask(value).trimLeft();

      if (trimValue.length > 8) {
        trimValue = trimValue.slice(0, 8);
      }

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          postal_code: trimValue,
        },
      }));

      const isAllZeros = /^0+$/.test(trimValue);

      const isAllSameDigit = /^(\d)\1+$/.test(trimValue);

      if (isAllZeros || isAllSameDigit) {
        formValidationActions.setError(
          "customer.postal_code",
          "VALIDATION_FIELD_INVALID"
        );
      } else {
        formValidationActions.clearError("customer.postal_code");
        formValidationActions.setError(
          "customer.postal_code",
          checkIsOnIntervalString(trimValue, 8, 8)
        );
      }
    },
    [validationErrors, formValidationActions, newFarm]
  );

  // customer address
  const setValidAddress = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          address: trimValue,
        },
      }));

      formValidationActions.setError(
        "customer.address",
        checkIsOnIntervalString(value, 3, 60)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // customer city
  const setValidCity = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        newFarm[1]((prevState) => ({
          ...prevState,
          customer: {
            ...prevState.customer,
            city: trimValue,
          },
        }));

        formValidationActions.setError(
          "customer.city",
          checkIsOnIntervalString(value, 3, 60)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // customer state
  const setValidState = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        newFarm[1]((prevState) => ({
          ...prevState,
          customer: {
            ...prevState.customer,
            state: trimValue,
          },
        }));

        formValidationActions.setError(
          "customer.state",
          checkIsOnIntervalString(value, 2, 36)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // customer country
  const setValidCountry = useCallback(
    (value) =>
      newFarm[1]((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          country: value,
        },
      })),
    []
  );

  const countryValues: [string, string][] = useMemo(
    () => countries.map((country) => [country.iso, country.content]),
    []
  );

  function handleDocumentTypeChange(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    seDocumentType(event.target.value as number);
    newFarm[1]((prevState: { customer: any }) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        document_type: event.target.value as number,
      },
    }));
  }

  function TestaCPF(strCPF) {
    let Soma: number;
    let Resto: number;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function TestaCNPJ(cnpj: string): boolean {
    if (cnpj === "") return false;

    if (cnpj.length !== 14) return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  }

  function validateDocumentType(documentType, documentValue) {
    const isCpf = documentType === 1;

    const cleanDocValue = documentValue.replace(/\D/g, "");

    if (documentValue === "") {
      return "ERROR_EMPTY_FIELD";
    }

    if (documentValue == null) {
      return "VALIDATION_FIELD_INVALID";
    }

    if (isCpf && !TestaCPF(cleanDocValue)) {
      return "VALIDATION_FIELD_INVALID";
    }

    if (!isCpf && !TestaCNPJ(cleanDocValue)) {
      return "VALIDATION_FIELD_INVALID";
    }

    const isAllZeros = /^0+$/.test(cleanDocValue);

    const isAllSameDigit = /^(\d)\1+$/.test(cleanDocValue);

    if (isAllZeros || isAllSameDigit) {
      return "VALIDATION_FIELD_INVALID";
    }

    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
    const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

    const documentRegex = isCpf ? cpfRegex : cnpjRegex;

    return !documentRegex.test(documentValue)
      ? "VALIDATION_FIELD_INVALID"
      : undefined;
  }

  function formatCPF(text) {
    let cleanedText = text.trimLeft().replace(/\D/g, "");

    if (cleanedText.length > 11) {
      cleanedText = cleanedText.slice(0, 11);
    }

    const match = cleanedText.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
    if (match) {
      return match[1] + "." + match[2] + "." + match[3] + "-" + match[4];
    }
    return cleanedText;
  }

  function formatCNPJ(cnpj) {
    let cleanedCNPJ = cnpj.trimLeft().replace(/\D/g, "");

    if (cleanedCNPJ.length > 14) {
      cleanedCNPJ = cleanedCNPJ.slice(0, 14);
    }

    const match = cleanedCNPJ.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return (
        match[1] +
        "." +
        match[2] +
        "." +
        match[3] +
        "/" +
        match[4] +
        "-" +
        match[5]
      );
    }

    return cleanedCNPJ;
  }

  function getPostalCodeInfo() {
    if (newFarm[0].customer.postal_code?.length == 8) {
      axios
        .get(
          `https://viacep.com.br/ws/${newFarm[0].customer.postal_code}/json/`
        )
        .then(function (response) {
          if (response.status == 200 && !response.data.erro) {
            setValidAddress(
              `${response.data.logradouro} - ${response.data.bairro}`
            );
            setValidCity(response.data.localidade);
            setValidState(response.data.uf);
          } else {
            notify("ERROR_ZIP_CODE", "error");
            setValidAddress("");
            setValidCity("");
            setValidState("");
          }
        });
    }
  }

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <div style={{ marginBottom: "40px" }}>
        {i18n("FARM_BILLING_DESCRIPTION")}
      </div>

      <h3
        style={{
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {i18n("FARM_BILLING_INFORMATION_TITLE")}
      </h3>

      <div
        className="general-form__row-2-5-5"
        style={{ alignItems: "baseline" }}
      >
        <FormControl
          // className={classes.formControl}
          variant="standard"
        >
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {i18n("FARM_BILLING_FIELD_DOCUMENT_TYPE")}
          </InputLabel>

          <Select
            value={documentType}
            onChange={handleDocumentTypeChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={0}>CNPJ</MenuItem>

            <MenuItem value={1}>CPF</MenuItem>
          </Select>
        </FormControl>

        <FormInput
          id="farm-document"
          inputMode="numeric"
          helperText={validationErrors["customer.document"]}
          label={"FARM_BILLING_FIELD_DOCUMENT"}
          state={[newFarm[0].customer.document, setValidDocument]}
        />
        <FormInput
          id="farm-name"
          helperText={validationErrors["customer.company_name"]}
          label={
            documentType
              ? "EDIT_PROFILE_NAME"
              : "FARM_BILLING_FIELD_COMPANY_NAME"
          }
          state={[newFarm[0].customer.company_name, setValidCompanyName]}
        />
      </div>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-phone"
          inputMode="numeric"
          helperText={validationErrors["customer.phone"]}
          startAdornment={IDD}
          label={"FARM_BILLING_FIELD_PHONE"}
          state={[newFarm[0].customer.phone, setValidPhone]}
        />

        <FormInput
          id="farm-email"
          helperText={validationErrors["customer.email"]}
          type="email"
          label={"FARM_BILLING_FIELD_EMAIL"}
          state={[newFarm[0].customer.email, setValidEmail]}
        />
      </div>

      <h3
        style={{
          marginTop: "40px",
          marginBottom: "20px",
        }}
      >
        {i18n("FARM_BILLING_ADDRESS_TITLE")}
      </h3>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-customer-postal-code"
          inputMode="numeric"
          helperText={validationErrors["customer.postal_code"]}
          label={"FARM_BILLING_FIELD_POSTAL_CODE"}
          state={[newFarm[0].customer.postal_code, setValidPostalCode]}
          onBlur={getPostalCodeInfo}
        />

        <FormInput
          id="farm-customer-address"
          helperText={validationErrors["customer.address"]}
          label={"FARM_BILLING_FIELD_ADDRESS"}
          state={[newFarm[0].customer.address, setValidAddress]}
        />
      </div>

      <div className="general-form__row-4-4-4">
        <FormInput
          id="farm-customer-city"
          helperText={validationErrors["customer.city"]}
          label={"FARM_BILLING_FIELD_CITY"}
          state={[newFarm[0].customer.city, setValidCity]}
        />

        <FormInput
          id="farm-customer-state"
          helperText={validationErrors["customer.state"]}
          label={"FARM_BILLING_FIELD_STATE"}
          state={[newFarm[0].customer.state, setValidState]}
        />

        <FormSelect
          id="farm-customer-country"
          label={"FARM_BILLING_FIELD_COUNTRY"}
          state={[newFarm[0].customer.country, setValidCountry]}
          values={countryValues}
        />
      </div>
    </div>
  );
}

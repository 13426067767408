import { ResponsiveLine } from "@nivo/line";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../../../../i18n/i18nText";

const ToolTip = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 5px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: black;
  font-size: 0.9em;
  border-radius: 4px;
  z-index: 999999999999;
`;

const TooltipWaterLevel = styled.div`
  font-weight: bold;
`;

interface Props {
  data?: CrudeData;
  frequency: string;
  scale: number;
  unit: string;
}

type CrudeData = [
  {
    content: {
      pulse_counter_readings?: {
        last_current_flow: number;
        overflow_quantity: number;
        totalized_volume_measured: number;
      };
    };
    created: Date;
  }
];

type ChartObj = {
  id: string;
  data: ChartData;
};

type ChartData = {
  x: string;
  y: number;
}[];

function getTickValues(frequency: string): string {
  if (frequency === "24h" || frequency === "48h") {
    return "every 1 hour";
  } else if (frequency === "72h") {
    return "every 3 hour";
  } else {
    return "every 1 day";
  }
}

export const PulseflowValueByTimeChart = (props: Props) => {
  const [chartData, setChartData] = useState<ChartObj>();

  const handleChartData = (array: CrudeData): ChartObj => {
    var tempArray: ChartData = [];
    array.forEach((item) => {
      if (item)
        tempArray.push({
          y:
            item.content?.pulse_counter_readings?.last_current_flow *
            props.scale,
          x: moment(item.created).format("YYYY-MM-DDTHH:mm:ssZ"),
        });
    });
    tempArray.sort((a, b) => {
      if (moment(a.x).isBefore(b.x)) {
        return -1;
      } else if (moment(a.x).isAfter(b.x)) {
        return 1;
      } else {
        return 0;
      }
    });
    return { id: "Vazão", data: tempArray };
  };

  const theme = {
    tooltip: {
      container: {
        color: "white",
        background: "rebeccapurple",
      },
    },
  };

  const tickValues = useMemo(() => {
    return getTickValues(props.frequency);
  }, [props.frequency]);

  useEffect(() => {
    setChartData(handleChartData(props.data));
  }, [props.data]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          fontWeight: "bold",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {i18n("FLOW_BY_TIME_CHART")}:
      </div>
      <ResponsiveLine
        data={[chartData]}
        margin={{ top: 10, right: 20, bottom: 80, left: 40 }}
        tooltip={(data) => {
          return (
            <ToolTip>
              <div>{moment(data.point.data.x).format("DD MMM YYYY HH:mm")}</div>
              <TooltipWaterLevel>
                {data.point.data.y}
                {` ${props.unit}/min`}
              </TooltipWaterLevel>
            </ToolTip>
          );
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S%Z",
        }}
        axisBottom={{
          format: "%d/%m-%Hh",
          tickValues: tickValues,
          tickRotation: 60,
        }}
        pointColor={{ theme: "background" }}
        colors={["#004a99"]}
        theme={theme}
        useMesh
      />
    </>
  );
};

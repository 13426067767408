import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import NotificationsCard from "./components/NotificationsCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Button,
  CircularProgress,
  Fab,
  Paper,
} from "@material-ui/core";
import routes from "../../../routes/routes";
import { AddCircle, ArrowBack } from "@material-ui/icons";
import { i18n } from "../../../i18n/i18nText";
import DashboardBox from "../components/DashboardBox/DashboardBox";
import { isMobile } from "../../../mobileConfig";
import { showModal } from "../../../redux/modal/actions";
import NotificationSteps from "./components/NotificationSteps";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useNotifications from "../../../hooks/models/useNotifications";
import { ApplicationState } from "../../../redux";
import useReasons from "../../../hooks/models/useReasons";
import useDevices from "../../../hooks/models/useDevices";
import { SettingState } from "../../../redux/setting/types";
import Store from "../../../redux/index";

export enum NotificationType {
  PIVOT = 0,
  MONITOR = 1,
  IRPD = 2,
  METER = 3,
}

export const TYPE_ROUTE = {
  0: "pivots",
  1: "pivots-monitors",
  2: "irpds",
  3: "imeters",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  rootDesktop: {
    display: "grid",
    justifyContent: "center",
    padding: "10px 60px 10px 60px",
    gridTemplateRows: `repeat(1, 1fr)`,
  },
  rootMobile: {
    padding: "0px 0px 0px 0px",
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "100%",
  },
  tabPanel: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
  },
  buttonContainer: {
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    position: isMobile() ? "fixed" : "relative",
    bottom: "20px",
  },
  buttonIcon: {
    display: "flex",
    gap: "8px",
  },
  page: {
    display: "block",
    width: isMobile() ? "100%" : "700px",
    minHeight: "700px",
  },
  emptyPage: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    minHeight: "700px",
    color: "#808080",
  },
}));

export default function Notifications() {
  const farmID: number = parseInt(useParams().farm, 10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const userID = useSelector((state: ApplicationState) => state.userID.userID);
  const selectedLanguage: SettingState = useSelector(
    (state: ApplicationState) => state.setting
  );

  const notifications = useSelector(
    (state: ApplicationState) => state.notifications.list,
    shallowEqual
  );

  const farm = Store().store.getState().farm.data;

  const [value, setValue] = useState<NotificationType>(NotificationType.PIVOT);
  const [loadingNotifications, _, actions] = useNotifications(value, farmID);
  const [loadingReasons, reasons, criticalReasons, getReasons] = useReasons(
    value,
    selectedLanguage
  );
  const [loadingDevices, devices, getDevices] = useDevices(value, farmID);

  useEffect(() => {
    actions.get(value);
    getReasons(value, selectedLanguage);
    getDevices(value);
  }, [value]);

  useEffect(() => {
    getReasons(value, selectedLanguage);
  }, [selectedLanguage]);

  const handleChange = (
    event: React.ChangeEvent<NotificationType>,
    type: NotificationType
  ) => {
    setValue(type);
  };

  const getNotificationsCards = () => {
    if (loadingNotifications || loadingReasons || loadingDevices) {
      return (
        <div className={`${classes.page} ${classes.emptyPage}`}>
          <CircularProgress size="24px" />
        </div>
      );
    } else {
      if (notifications && notifications.length) {
        return (
          <div className={classes.page}>
            <Typography gutterBottom>
              {farm && farm.name
                ? `${i18n("NOTIFICATIONS")} (${farm.name})`
                : ""}
            </Typography>
            {notifications.map((notification) => (
              <NotificationsCard
                farmID={farmID}
                type={value}
                notification={notification}
                notificationID={notification.id}
                reasons={reasons}
                criticalReasons={criticalReasons}
                devices={devices}
                actions={actions}
              />
            ))}
          </div>
        );
      } else {
        return (
          <div className={`${classes.page} ${classes.emptyPage}`}>
            <span>{i18n("FARM_HAS_NO_NOTIFICATIONS", [farm.name])}</span>
          </div>
        );
      }
    }
  };

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  return (
    <div className={isMobile() ? classes.rootMobile : classes.rootDesktop}>
      <DashboardBox
        leftElement={backButton}
        centerElement={
          <Typography>{isMobile() ? "" : i18n("NOTIFICATIONS")}</Typography>
        }
      >
        <div
          className={classes.container}
          style={{ flexDirection: isMobile() ? "column" : "row" }}
        >
          <Paper square>
            <Tabs
              orientation={isMobile() ? "horizontal" : "vertical"}
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Notifications Tabs"
              className={classes.tabs}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#44b86a",
                },
              }}
            >
              <Tab
                label={i18n("NOTIFICATIONS_TABPANEL_LABEL_PIVOTS")}
                {...a11yProps(NotificationType.PIVOT)}
              />

              <Tab
                label={i18n("NOTIFICATIONS_TABPANEL_LABEL_MONITOR")}
                {...a11yProps(NotificationType.MONITOR)}
              />

              <Tab
                label={i18n("NOTIFICATIONS_TABPANEL_LABEL_PUMP")}
                {...a11yProps(NotificationType.IRPD)}
              />

              {/* <Tab
              label={i18n("NOTIFICATIONS_TABPANEL_LABEL_METER")}
              {...a11yProps(NotificationType.METER)}
            /> */}
            </Tabs>
          </Paper>

          <div className={!isMobile() ? classes.tabPanel : "tabPanel-mobile"}>
            <TabPanel value={value} index={NotificationType.PIVOT}>
              {getNotificationsCards()}
            </TabPanel>

            <TabPanel value={value} index={NotificationType.MONITOR}>
              {getNotificationsCards()}
            </TabPanel>

            <TabPanel value={value} index={NotificationType.IRPD}>
              {getNotificationsCards()}
            </TabPanel>

            {/* <TabPanel value={value} index={NotificationType.METER}>
              {getNotificationsCards()}
            </TabPanel> */}

            <div className={classes.buttonContainer}>
              <Button
                aria-label="add-notification"
                className={classes.button}
                onClick={() => {
                  dispatch(
                    showModal({
                      content: (
                        <NotificationSteps
                          farmID={farmID}
                          userID={userID}
                          type={value}
                          mode={"create"}
                          reasons={reasons}
                          devices={devices}
                          actions={actions}
                        />
                      ),
                      title: "CREATE_NOTIFICATIONS",
                    })
                  );
                }}
                disabled={
                  loadingNotifications ||
                  loadingReasons ||
                  loadingDevices ||
                  !devices.length
                }
                type="button"
                color="secondary"
                variant="contained"
              >
                <div className={classes.buttonIcon}>
                  <AddCircle />
                  {i18n("CREATE_NOTIFICATIONS")}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </DashboardBox>
    </div>
  );
}

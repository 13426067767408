import { useDispatch } from "react-redux";
import { setToken } from "../../../../redux/auth/actions";
import { coreHTTPClient } from "../../../../services/webclient";
import { useState } from "react";
import useNotify from "../../../../hooks/tools/useNotify";
import checkExists from "../../../../utils/checks/checkExists";
import { addUserID } from "../../../../redux/userID/actions";
import { useSetRecoilState } from "recoil";
import { changePasswordStatus } from "../../../../recoils/ChangePasswordRecoil";

type PasswordAuth = (
  user: { email: string } | { username: string },
  password: string
) => Promise<void>;

function useLoginHook(): [PasswordAuth, boolean] {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const setChangePassword = useSetRecoilState(changePasswordStatus);

  async function pwdLogin(
    user: { email: string } | { username: string },
    password: string
  ) {
    await new Promise(async (resolve) => {
      setLoading(true);

      try {
        const response = await coreHTTPClient.post("v3/auth/login", {
          method: "password",
          ...user,
          password,
        });

        const { token } = response.data;
        dispatch(setToken(token));
        dispatch(addUserID(response.data.user, response.data.reseller));
        notify("LOGIN_SUCCESSFUL", "success");

        const changePassword = await coreHTTPClient.get("/v3/auth/profile/");
        setChangePassword(changePassword.data.profile.reset_password);
      } catch (error) {
        if (error.response !== undefined) {
          switch (error.response.data.error_code) {
            case 1202:
              notify("LOGIN_ERROR_INVALID_CREDENTIALS", "error");
              break;
            case 403:
              notify("LOGIN_ERROR_ACCEPT_PRIVACY", "info", 10000);
          }
        }
      } finally {
        setLoading(false);
      }
    });
  }

  return [pwdLogin, loading];
}

export default useLoginHook;

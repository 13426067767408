import React, { useState, useEffect } from "react";
import "./Users.scss";
import useUsersFarm from "../../../../../../../../../hooks/models/useUsersFarm";
import RemoveUser from "./RemoveUser/RemoveUser";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  showModal,
} from "../../../../../../../../../redux/modal/actions";
import { coreHTTPClient } from "../../../../../../../../../services/webclient";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import CreateUser from "./CreateUser/CreateUser";
import CircularProgress from "@material-ui/core/CircularProgress";

import styled from "@emotion/styled";
import {
  MuiThemeProvider,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Permission, { blueTheme } from "./Permission/Permission";
import { isMobile } from "../../../../../../../../../mobileConfig";
import { ApplicationState } from "../../../../../../../../../redux";
import { getFarm as getFarmSelector } from "../../../../../../../../../redux/farms/selector";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import useFarm from "../../../../../../../../../hooks/models/useFarm";
import { EditSharp } from "@material-ui/icons";
import MoreInfo from "@material-ui/icons/HelpOutlineOutlined";
import PermissionsInfo from "./CreateUser/PermisionsInfo";

interface Props {
  farmID: number;
  farmCode: string;
}

export default function Users(props: Props) {
  const { farmID, farmCode } = props;
  const [usersFarm, pendingUsersInFarm, getUsersFarm, loading] = useUsersFarm(
    farmID
  );

  const [openModal, setOpenModal] = useState(false);
  let [selectedUserId, setSelectedUserId] = useState<number | undefined>();
  let [selectedUserName, setSelectedUserName] = useState("");
  let [selectedEmail, setSelectedEmail] = useState("");
  let [selectedUserIsPending, setSelectedUserIsPending] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [deleteUser, setDeleteUser] = useState(false);
  const notify = useNotify();
  let [users, setUsers] = useState<any[]>();

  const farm = useSelector((state: ApplicationState) =>
    getFarmSelector(state, farmID)
  );

  let [_, __, farmActions] = useFarm(farmID);

  async function deleteUser() {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.delete(
          `/v3/farms/${farmID}/users/${selectedUserId}/`
        );
        if (response.data) {
          dispatch(hideModal());
          getUsersFarm();
          farmActions.get(farmID);
          notify("EDIT_FARM_USER_DELETE_SUCCESS", "success");
        }
      } catch (err) {}
    });
  }
  useEffect(() => {
    usersFarm && pendingUsersInFarm && setUsers(arrayWithUsers());
  }, [usersFarm, pendingUsersInFarm]);

  function arrayWithUsers() {
    let user;
    if (usersFarm.length > 0)
      user = usersFarm
        .filter(
          (user) =>
            !farm.administrators
              .map((admin) => admin.email == user.email)
              .includes(true)
        )
        .map((user) => {
          return (
            <div key={user.id} className="users__row">
              <div className="users__row__title">
                <div style={{ marginBottom: "5px" }}>@{user.username}</div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.9rem",
                    marginLeft: "8px",
                    opacity: 0.8,
                  }}
                >
                  {user.first_name} {user.last_name}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.9rem",
                    marginLeft: "8px",
                    opacity: 0.8,
                  }}
                >
                  {user.email}
                </div>
              </div>
              <div className="users__row__status-drop">
                <Tooltip
                  arrow={true}
                  title={i18n("EDIT_FARM_EDIT_USER")}
                  placement="right"
                >
                  <IconButton
                    size="medium"
                    disabled={!farm?.is_administrator}
                    onClick={() => {
                      dispatch(
                        showModal({
                          content: (
                            <Permission
                              farmID={farmID}
                              userID={user.id}
                              user={user}
                              pending={false}
                              setOpenModal={(open) => {
                                setSelectedUserName(user.username);
                                setSelectedUserId(user.id);
                                setSelectedUserIsPending(false);
                                setOpenModal(open);
                              }}
                              getUsersFarm={getUsersFarm}
                            />
                          ),
                          title: "EDIT_FARM_EDIT_USER",
                        })
                      );
                    }}
                  >
                    <EditSharp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        });

    function handleEmailString(email) {
      return isMobile()
        ? email.length > 20
          ? email.substring(0, 16) + "..."
          : email
        : email;
    }

    let pending;
    if (pendingUsersInFarm.length > 0)
      pending = pendingUsersInFarm
        .filter(
          (user) =>
            !farm.administrators
              .map((admin) => admin.email == user.email)
              .includes(true)
        )
        .map((user) => {
          return (
            <div key={user.id} className="users__row">
              {/* <div className="users__row__title">{user.first_name}</div> */}
              <div className="users__row__title">
                {user.email ? handleEmailString(user.email) : user.username}
              </div>

              <div className="users__row__status-drop">
                <div className="users__row__status">
                  <>{i18n("EDIT_FARM_USER_PENDING")}</>
                </div>
                <Tooltip
                  arrow={true}
                  title={i18n("EDIT_FARM_EDIT_USER")}
                  placement="right"
                >
                  <IconButton
                    size="medium"
                    disabled={!farm?.is_administrator}
                    onClick={() => {
                      dispatch(
                        showModal({
                          content: (
                            <Permission
                              farmID={farmID}
                              userID={user.id}
                              user={user}
                              pending={true}
                              getUsersFarm={getUsersFarm}
                              setOpenModal={(open) => {
                                setSelectedUserName(user.username);
                                setSelectedUserId(user.id);
                                setSelectedUserIsPending(true);
                                setOpenModal(open);
                              }}
                            />
                          ),
                          title: "EDIT_FARM_EDIT_USER",
                        })
                      );
                    }}
                  >
                    <EditSharp />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        });
    return [user, pending];
  }
  return (
    <div className="users">
      <div>
        <MuiThemeProvider theme={blueTheme}>
          <Button
            style={{ marginBottom: "5px", marginTop: "15px" }}
            className="button-farm"
            variant="text"
            color="default"
            onClick={() => {
              dispatch(
                showModal({
                  content: <PermissionsInfo />,
                  title: "PERMISSIONS_REGISTER_USER_INFO",
                })
              );
            }}
          >
            <div style={{ color: "#0000008a" }}>{i18n("PERMISSION_INFO")}</div>
            <MoreInfo style={{ color: "#0000008a", marginLeft: "5px" }} />
          </Button>
          <Button
            style={{ marginBottom: "25px" }}
            className="button-farm"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(
                showModal({
                  content: (
                    <CreateUser
                      farmID={farmID}
                      getUsersFarm={getUsersFarm}
                      // centralCode={props.pivots[0].base}
                    />
                  ),
                  title: "CREATE_USER_MODAL_TITLE",
                })
              );
            }}
          >
            {i18n("EDIT_FARM_BUTTON_REGISTER_USER")}
          </Button>
        </MuiThemeProvider>
      </div>
      <div
        style={{
          // padding: isMobileLayout() ? "19px 5px 5px 15px" : "2px 5px 5px 15px",
          fontSize: isMobile() ? "0.8rem" : "1rem",
        }}
      >
        <div className="users__titleRow">
          <>{i18n("EDIT_FARM_ADMINISTRATOR")}</>
        </div>
        {farm.administrators.length > 0 ? (
          farm?.administrators
            ?.sort(function (a, b) {
              if (a?.username < b?.username) {
                return -1;
              }
              if (a?.username > b?.username) {
                return 1;
              }
              return 0;
            })
            ?.map((administrator) => {
              let adminUser = usersFarm.filter(
                (user) => user.email == administrator.email
              )[0];
              adminUser = adminUser
                ? adminUser
                : pendingUsersInFarm.filter(
                    (user) => user.email == administrator.email
                  )[0];

              return (
                <div key={administrator.id} className="users__row">
                  <div
                    className="users__row__title"
                    // style={{ paddingLeft: "10px", marginBottom: "10px" }}
                  >
                    <div style={{ marginBottom: "5px" }}>
                      {administrator.pending
                        ? i18n("EDIT_FARM_USER") +
                          " " +
                          i18n("EDIT_FARM_USER_PENDING")
                        : `@${administrator.username}`}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: "0.9rem",
                        marginLeft: "8px",
                        opacity: 0.8,
                      }}
                    >
                      {administrator.first_name} {administrator.last_name}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: "0.9rem",
                        marginLeft: "8px",
                        opacity: 0.8,
                      }}
                    >
                      {administrator.email}
                    </div>
                  </div>
                  <div className="users__row__status-drop">
                    {administrator.pending && (
                      <div className="users__row__status">
                        <>{i18n("EDIT_FARM_USER_PENDING")}</>
                      </div>
                    )}
                    <Tooltip
                      arrow={true}
                      title={i18n("EDIT_FARM_EDIT_USER")}
                      placement="right"
                    >
                      <IconButton
                        size="medium"
                        disabled={!farm?.is_administrator}
                        onClick={() => {
                          dispatch(
                            showModal({
                              content: (
                                <Permission
                                  farmID={farmID}
                                  userID={administrator.id}
                                  user={adminUser}
                                  getUsersFarm={getUsersFarm}
                                  pending={administrator.pending}
                                  setOpenModal={(open) => {
                                    setSelectedUserName(administrator.username);
                                    setSelectedUserId(administrator.id);
                                    setSelectedUserIsPending(false);
                                    setOpenModal(open);
                                  }}
                                />
                              ),
                              title: "EDIT_FARM_EDIT_USER",
                            })
                          );
                        }}
                      >
                        <EditSharp />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              );
            })
        ) : (
          <div style={{ margin: "1em" }}>
            <span>{i18n("EDIT_FARM_NO_ADMIN")}</span>
          </div>
        )}
      </div>
      <div className="users__titleRow">
        <>{i18n("EDIT_FARM_EMPLOYEES")}</>
      </div>
      {loading ? (
        <CircularProgress style={{ marginTop: "10px", alignSelf: "center" }} />
      ) : (
        <>
          {users[0] || users[1] ? (
            users
          ) : (
            <div className="no-items">
              {i18n("EDIT_FARM_NO_REGISTERED_USERS")}
            </div>
          )}
        </>
      )}
      <RemoveUser
        openModal={openModal}
        setOpenModal={setOpenModal}
        deleteUser={() => {
          deleteUser();
        }}
        text={"EDIT_FARM_REMOVE_INFO"}
        name={
          selectedUserName == "" || selectedUserIsPending
            ? selectedEmail
            : selectedUserName
        }
      />
    </div>
  );
}

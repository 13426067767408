import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  NativeSelect,
  LabelDisplayedRowsArgs,
} from "@material-ui/core";

import { ReactJSX } from "../../utils/types";
import { useStyles } from "./GeneralTable.style";
import GeneralTableItem from "./GeneralTableItem";
import { nanoid } from "nanoid";
import { isMobile } from "../../mobileConfig";
import { Skeleton } from "@material-ui/lab";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { i18n } from "../../i18n/i18nText";

export interface Column {
  id: string;
  label: any;
  minWidth?: number;
  align?: "left" | "right" | "inherit" | "center" | "justify";
  format?: (value: number) => string;
}

export interface Row {
  id: number | string;
  datailedComponent: () => ReactJSX;
  [key: string]: any;
}

interface Props {
  page: number;
  hideColumns?: boolean;
  columns: Column[];
  rowsPerPage: number;
  total: number;
  rows: any;
  loading?: boolean;
  onPageChange: (page: number) => void;
}

export function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  let maxNumberOfPages = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  const handleSetPageButtonClick = (event) => {
    onChangePage(event, parseInt(event.target.value));
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  let objPagesSelector = [];

  for (let index = 0; index < maxNumberOfPages + 1; index++) {
    objPagesSelector.push(index);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}>
      <NativeSelect
        style={{
          width: page > 100 ? "70px" : "auto",
          height: "30px",
          alignSelf: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
          borderRadius: "5px",
        }}
        value={page}
        aria-label="Select Page"
        onChange={handleSetPageButtonClick}
      >
        {objPagesSelector.map((index) => (
          <option
            style={{ fontSize: "18px", fontWeight: "normal" }}
            key={`pageNumber${index}`}
            value={index}
          >
            {(index + 1).toString()}
          </option>
        ))}
      </NativeSelect>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}

export const labelDisplayedRows = (props) => {
  const { count, page } = props;

  return `${count > 0 ? page * 10 + 1 : 0}-${
    (page + 1) * 10 < count ? (page + 1) * 10 : count
  } ${i18n("TABLE_OF")} ${count}`;
};

function GeneralTable(props: Props) {
  const classes = useStyles(props);

  const {
    page,
    hideColumns,
    columns,
    rowsPerPage,
    total,
    rows,
    loading,
    onPageChange,
  } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage);
  };

  // COMPONENTES DA TABELA EM VARIÁVEIS
  let tableColumns = columns.map((column) => (
    <TableCell
      key={column.id}
      align={column.align}
      style={{
        minWidth: 0,
        zIndex: 0,
        width: `${100 / columns.length}%`,
      }}
    >
      {column.label}
    </TableCell>
  ));

  let emptyTableRows = new Array(rowsPerPage).fill(0).map((_, index) => {
    return (
      <TableRow key={`row_${index}`}>
        {columns.map((_, index) => {
          return (
            <TableCell
              key={`col_${index}`}
              style={
                isMobile() ? { padding: "10px", height: 64 } : { height: 64 }
              }
            >
              <Skeleton />
            </TableCell>
          );
        })}
      </TableRow>
    );
  });

  const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) => {
    return `${paginationInfo.from}-${paginationInfo.to} ${i18n("TABLE_OF")} ${
      paginationInfo.count !== -1 ? paginationInfo.count : paginationInfo.to
    }`;
  };

  let filledTableRows = rows.map((row, index) => {
    return (
      <GeneralTableItem
        key={`${row.id} ${index} ${page}` || nanoid()} //if it can't find an id, it's best to sacrifice performance for this component
        row={row}
        columns={columns}
        index={index}
      />
    );
  });

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          {hideColumns ? (
            <TableHead></TableHead>
          ) : (
            <TableHead>
              <TableRow>{tableColumns}</TableRow>
            </TableHead>
          )}
          <TableBody>{loading ? emptyTableRows : filledTableRows}</TableBody>
        </Table>
      </div>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={labelDisplayedRows}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        onChangePage={handleChangePage}
        // onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
}

export default GeneralTable;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import Button from "@material-ui/core/Button/Button";
import Routes from "../../../../routes/routes";
import { EditSharp, ArrowBack, Refresh, GetApp } from "@material-ui/icons";
import { ReactJSX } from "../../../../utils/types";
import FilterByDate from "../../../../components/FilterByDate/FilterByDate";
import {
  FilterByDateState,
  FilterTypesChoices,
} from "../../../../redux/filterByDate/types";
import MeterSystemGeneralBox from "./components/GeneralBox/MeterSystemGeneralBox";
import MeterSystemCharts from "./components/ChartsAndTables/MeterSystemCharts";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../services/webclient";
import {
  IMeterHelperSelector,
  MeterSystem,
} from "../../../../recoils/MeterSystemRecoil";
import { useSetRecoilState } from "recoil";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import { isMobile } from "../../../../mobileConfig";
import {
  FloatingFadedNavBar,
  MiddleLayoutNavBar,
  MobileContainer,
} from "../../Pivot/SelectedPivot/SelectedPivot";
import { useAnimation } from "framer-motion";
import EditIcon from "@material-ui/icons/Edit";
import IMetersMap from "./components/IMetersMap/IMetersMap";
import { CircularProgress } from "@material-ui/core";
import { i18n } from "../../../../i18n/i18nText";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../redux";
import moment from "moment";
import axios from "axios";
import useNotify from "../../../../hooks/tools/useNotify";
import LakeLevelChart from "./components/Charts_2.0/LakeLevelChart";
import QuickSelect from "../../../../components/QuickSelect/QuickSelect";
import MeterSystemTables from "./components/ChartsAndTables/MeterSystemTables";
import DashboardBoxImeter from "../../components/DashboardBox/DashboardBoxImeter";
import useSocketIO from "../../../../hooks/tools/useSocketIO";
import useFarm from "../../../../hooks/models/useFarm";
import { StringFormatter } from "../../../../utils/StringFormatter";

const { SOCKET_SUFFIX } = process.env;

var filesaver = require("file-saver");

const Container = styled.div`
  padding: 10px 10px 20px 55px;
  position: relative;
  z-index: 0;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const ContainerGrid = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;

  @media (max-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: 100%;
  }
`;

const Column = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content;
  grid-row-gap: 15px;
  row-gap: 15px;

  @media (max-width: 1024px) {
    row-gap: 20px;
  }
`;

const ColumnSingleItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

interface Bind {
  taskId: string;
  fileName: string;
  farm: { id: string; name: string };
  meterSystemName: string;
  bind: () => Promise<void>;
  unBind: () => Promise<void>;
}

function SelectedMeterSystem() {
  const farmID: number = parseInt(useParams().farm, 10);
  let meterSystemID: number = parseInt(useParams().metersystem, 10);

  const [sendingExcel, setSendingExcel] = useState(false);

  const notify = useNotify();

  const [myMeterSystem, setMyMeterSystem] = useState(null);

  const floatingFadedNavBarAnimation = useAnimation();

  const iMeterHelper = useSetRecoilState(IMeterHelperSelector);

  const navigate = useNavigate();

  var filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  document.title = "Irricontrol";

  const backButton: ReactJSX = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const editButtonHandler = () => {
    navigate(
      Routes.DASHBOARD.EDIT_IMETER.replace(":farm", String(farmID))
        .replace(":metersystem", String(myMeterSystem.id))
        .replace(":meter", String(myMeterSystem.imeter_set[0].id))
    );
  };

  const editButton: ReactJSX = (
    <>
      {myMeterSystem?.permission_level === 3 && (
        <Button
          color="primary"
          size="small"
          onClick={editButtonHandler}
          endIcon={<EditSharp />}
        >
          <>{i18n("SELECTED_PIVOT_EDIT_BUTTON_TEXT")}</>
        </Button>
      )}
    </>
  );

  const handleExcelClick = useCallback(() => {
    setSendingExcel(true);
    var date_start = undefined;
    var date_end = undefined;

    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      date_end = filterByDate.data.end_date.toISOString();
      date_start = filterByDate.data.start_date.toISOString();
    } else {
      date_end = moment().toISOString();
      date_start = moment().subtract(1, "month").toISOString();
    }

    if (isMobile()) {
      setSendingExcel(false);
      window.open(
        process.env.WEB_URL +
          // "http://localhost:3000" +
          `/metersystems/${meterSystemID}/meter/${
            myMeterSystem?.imeter_set?.[0]?.id
          }/excel/?date_start=${date_start}&date_end=${date_end}&name=${
            myMeterSystem.name
          }&language=${language}&apikey=${getAuthorization()}`
      );
    } else {
      axios
        .get(
          `/v3/reports/metersystems/${meterSystemID}/meter/${myMeterSystem?.imeter_set?.[0]?.id}/excel/?date_start=${date_start}&date_end=${date_end}`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              Connection: "close",
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
            responseType: "blob",
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          filesaver.saveAs(
            blob,
            `Relatório ${myMeterSystem?.imeter_set?.[0]?.name}.xlsx`
          );
        })
        .catch((err) => {
          notify("PIVOT_REPORT_EXCEL_ERROR", "error");
        })
        .finally(() => {
          setSendingExcel(false);
        });
    }
  }, [myMeterSystem, filterByDate]);

  const handleExcelClickGh = useCallback(() => {
    setSendingExcel(true);
    var date_start = undefined;
    var date_end = undefined;

    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      date_end = filterByDate.data.end_date.toISOString();
      date_start = filterByDate.data.start_date.toISOString();
    } else {
      date_end = moment().toISOString();
      date_start = moment().subtract(1, "month").toISOString();
    }

    if (isMobile()) {
      setSendingExcel(false);
      window.open(
        process.env.WEB_URL +
          // "http://localhost:3000" +
          `/metersystems_gh/${meterSystemID}/meter/${
            myMeterSystem?.imeter_set?.[0]?.id
          }/excel/?date_start=${date_start}&date_end=${date_end}&name=${
            myMeterSystem.name
          }&language=${language}&apikey=${getAuthorization()}`
      );
    } else {
      axios
        .get(
          `/v4/reports/meter_systems/${meterSystemID}/meter/${myMeterSystem?.imeter_set?.[0]?.id}/excel/?date_start=${date_start}&date_end=${date_end}`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              Connection: "close",
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
            responseType: "blob",
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          filesaver.saveAs(
            blob,
            `Relatório ${myMeterSystem?.imeter_set?.[0]?.name}.xlsx`
          );
        })
        .catch((err) => {
          notify("PIVOT_REPORT_EXCEL_ERROR", "error");
        })
        .finally(() => {
          setSendingExcel(false);
        });
    }
  }, [myMeterSystem, filterByDate]);

  const excelButton = (
    <>
      <Button
        size="small"
        onClick={handleExcelClick}
        startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
        disabled={sendingExcel}
      >
        <>{i18n("PIVOT_REPORT_EXCEL")}</>
      </Button>
    </>
  );

  const excelButtonGh = (
    <>
      <Button
        size="small"
        onClick={handleExcelClickGh}
        startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
        disabled={sendingExcel}
      >
        <>{i18n("PIVOT_REPORT_EXCEL")}</>
      </Button>
    </>
  );

  useEffect(() => {
    if (!meterSystemID) return;

    (async () => {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/metersystems/${meterSystemID}/`
      );

      let metersystem: MeterSystem = response.data;
      setMyMeterSystem(metersystem);

      metersystem.imeter_set.forEach((imeter) =>
        iMeterHelper({
          IMeterID: imeter.id,
          imeter,
        })
      );
    })();
  }, [meterSystemID]);

  if (!myMeterSystem) {
    return (
      <div>
        <CustomCircularProgress />
      </div>
    );
  } else if (isMobile()) {
    return (
      <div className="selected-metersystem-mobile">
        <FloatingFadedNavBar animate={floatingFadedNavBarAnimation}>
          <ArrowBack
            style={{ marginTop: 15 }}
            onClick={() =>
              navigate(
                Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
              )
            }
          />

          {myMeterSystem?.permission_level == 3 ? (
            <EditIcon
              style={{ fontSize: "1.25em", marginTop: 15 }}
              onClick={editButtonHandler}
            />
          ) : null}
        </FloatingFadedNavBar>

        <Container>
          <ContainerGrid>
            <Column>
              <MobileContainer style={{ marginBottom: 55 }}>
                <IMetersMap
                  draggable={false}
                  imeters={[myMeterSystem.imeter_set[0]]}
                />

                <MiddleLayoutNavBar>
                  <div
                    style={{
                      display: "flex",
                      width: "calc(100vw - 45px)",
                      alignItems: "center",
                      gap: "30px",
                      marginLeft: "10px",
                    }}
                  >
                    <QuickSelect farmID={farmID} />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Refresh
                        style={{
                          color: "white",
                        }}
                        onClick={() => window.location.reload()}
                      />
                    </div>
                  </div>
                </MiddleLayoutNavBar>
              </MobileContainer>

              <DashboardBox
                singleElement={
                  <MeterSystemGeneralBox
                    metersystem={myMeterSystem}
                    farmID={farmID}
                  />
                }
              >
                <></>
              </DashboardBox>
            </Column>

            <ColumnSingleItem>
              <div
                style={{
                  marginBottom: "65px",
                  maxWidth: "calc(100vw - 20px)",
                }}
              >
                <div style={{ marginBottom: "15px" }}>
                  <LakeLevelChart
                    protocol={myMeterSystem.imeter_set[0].protocol}
                    farmID={myMeterSystem.farm}
                    meterSystemID={myMeterSystem.id}
                    iMeterID={myMeterSystem.imeter_set[0].id}
                    graphicMaxValue={
                      myMeterSystem.imeter_set[0].latest_config
                        .graphic_max_value
                    }
                    minLimit={
                      myMeterSystem.imeter_set[0].latest_config.min_limit
                    }
                    maxLimit={
                      myMeterSystem.imeter_set[0].latest_config.max_limit
                    }
                  />
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <DashboardBoxImeter>
                    <MeterSystemCharts
                      iMeterID={myMeterSystem.imeter_set[0].id}
                    />
                  </DashboardBoxImeter>
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <DashboardBox
                    leftElement={
                      myMeterSystem.imeter_set[0].protocol === "5.3"
                        ? excelButtonGh
                        : excelButton
                    }
                    centerElement={<h2>{i18n("HISTORY_OF_MEASUREMENTS")}</h2>}
                    rightElement={
                      <FilterByDate
                        title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
                        type={FilterTypesChoices.REPORT}
                      />
                    }
                  >
                    <MeterSystemTables
                      iMeterID={myMeterSystem.imeter_set[0].id}
                    />
                  </DashboardBox>
                </div>
              </div>
            </ColumnSingleItem>
          </ContainerGrid>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="selected-metersystem">
        <Container>
          <ContainerGrid>
            <Column>
              {/* General imeter data */}
              <DashboardBox
                leftElement={backButton}
                centerElement={<h2>{i18n("EDIT_PIVOT_LABEL_GENERAL")}</h2>}
                rightElement={editButton}
              >
                <MeterSystemGeneralBox
                  metersystem={myMeterSystem}
                  farmID={farmID}
                />
              </DashboardBox>

              {/* Level chart */}
              <div style={{ marginBottom: "15px" }}>
                <LakeLevelChart
                  protocol={myMeterSystem.imeter_set[0].protocol}
                  farmID={myMeterSystem.farm}
                  meterSystemID={myMeterSystem.id}
                  iMeterID={myMeterSystem.imeter_set[0].id}
                  graphicMaxValue={
                    myMeterSystem.imeter_set[0].latest_config.graphic_max_value
                  }
                  minLimit={myMeterSystem.imeter_set[0].latest_config.min_limit}
                  maxLimit={myMeterSystem.imeter_set[0].latest_config.max_limit}
                />
              </div>
            </Column>

            <ColumnSingleItem>
              {/* Imeter history table */}
              <div style={{ maxWidth: "calc(50vw - 65px)" }}>
                <DashboardBox
                  leftElement={
                    myMeterSystem.imeter_set[0].protocol === "5.3"
                      ? excelButtonGh
                      : excelButton
                  }
                  centerElement={<h2>{i18n("HISTORY_OF_MEASUREMENTS")}</h2>}
                  rightElement={
                    <FilterByDate
                      title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
                      type={FilterTypesChoices.REPORT}
                    />
                  }
                >
                  <MeterSystemTables
                    iMeterID={myMeterSystem.imeter_set[0].id}
                  />
                </DashboardBox>
              </div>

              {/* Imeter flow rate */}
              <div
                style={{
                  marginBottom: "80px",
                  maxWidth: "calc(50vw - 65px)",
                }}
              >
                <div style={{ marginBottom: "15px" }}>
                  <DashboardBoxImeter>
                    <MeterSystemCharts
                      iMeterID={myMeterSystem.imeter_set[0].id}
                    />
                  </DashboardBoxImeter>
                </div>
              </div>
            </ColumnSingleItem>
          </ContainerGrid>
        </Container>
      </div>
    );
  }
}

export default React.memo(SelectedMeterSystem);

import "../../../../../style/components/DrawerRight.scss";
import { suffixClasses } from "../../../../../utils/classes";
import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LinkIcon from "@material-ui/icons/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import InfoIcon from "@material-ui/icons/Info";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "@emotion/styled";
import moment from "moment";
import { NodeLog } from "../../Devices";
import { isMobile } from "../../../../../mobileConfig";
import { i18n } from "../../../../../i18n/i18nText";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const HeaderSpan = styled.span`
  display: flex;
  font-weight: bold;
  font-size: 40px;
  padding-left: 15px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
`;

const CenteredText = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.6;
  font-size: 18px;
`;

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

interface Props {
  nodeLogs: NodeLog[];
}

const DrawerRightDevices = (props: Props) => {
  const [value, setValue] = useState(0);
  const [drawerVisibleRight, setDrawerVisibleRight] = useState(false);

  const toggleDrawerRight = () => setDrawerVisibleRight(!drawerVisibleRight);
  const openDrawerRight = () => setDrawerVisibleRight(true);
  const closeDrawerRight = () => setDrawerVisibleRight(false);
  const [selectedTab, setSelectedTab] = useState(undefined);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="drawerRight">
        <div
          className={suffixClasses("drawerRight__backdrop", [
            ["--inactive", !drawerVisibleRight],
          ])}
          onClick={() => {
            toggleDrawerRight();
          }}
        />
        <div
          className={suffixClasses("drawerRight__bar", [
            ["--inactive", !drawerVisibleRight],
          ])}
        >
          <>
            <div>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                aria-label="Edit Farm"
              >
                <Tab
                  style={{ minWidth: 0 }}
                  icon={
                    <InfoIcon
                      style={{
                        color: "white",
                        fontSize: 20,
                      }}
                    />
                  }
                  {...a11yProps(0)}
                  onClick={() => {
                    if (drawerVisibleRight && selectedTab === "tab-1") {
                      setSelectedTab("");
                      closeDrawerRight();
                    } else {
                      setSelectedTab("tab-1");
                      openDrawerRight();
                    }
                  }}
                />
              </Tabs>
            </div>

            {drawerVisibleRight ? (
              <div
                style={{
                  backgroundColor: "white",
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "1fr",
                  height: "100%",
                }}
              >
                <TabPanel value={value} index={0}>
                  <div
                    style={{
                      height: "calc(100vh - 60px)",
                      width: "calc(100% + 48px)",
                      //o material tem uns paddings internos que mexem com o autosizer, por isso essa div com cálculos negativos

                      margin: "-24px",
                      position: "relative",
                    }}
                  >
                    <HeaderSpan>{i18n("LOGS")}</HeaderSpan>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          itemKey={(index, data: NodeLog[]) => {
                            // Find the item at the specified index.
                            // In this case "data" is an Array that was passed to List as "itemData".
                            const item = data[index];

                            // Return a value that uniquely identifies this item.
                            // Typically this will be a UID of some sort.
                            return item.logId;
                          }}
                          className="List"
                          height={height}
                          itemCount={
                            props.nodeLogs?.length ? props.nodeLogs.length : 0
                          }
                          itemSize={155}
                          width={width}
                          itemData={props.nodeLogs}
                        >
                          {Row}
                        </List>
                      )}
                    </AutoSizer>

                    {props.nodeLogs.length == 0 && (
                      <CenteredText>{i18n("DEVICE_NO_LOGS")}</CenteredText>
                    )}
                  </div>
                </TabPanel>
              </div>
            ) : (
              <div />
            )}
          </>
        </div>
      </div>
    </>
  );
};

const DateSpan = styled.span`
  font-size: 13px;
`;

const SpanRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogSpan = styled.span`
  font-size: 13px;
`;

const SpanKey = styled(LogSpan)`
  font-weight: bold;
`;

const SpanValue = styled(LogSpan)`
  margin-left: 4px;
  opacity: 0.6;
`;

const Ref = styled.a`
  font-size: 14px;
  margin-left: 5px;
  margin-right: 3px;
  opacity: 0.6;
  color: rgb(0, 0, 0, 1.1);
`;

function Row({
  index,
  style,
  data,
}: {
  index: any;
  style: any;
  data: NodeLog[];
}) {
  let dataItem = data[data.length - 1 - index]; //array inverso

  const url = (dataItem) => {
    if (dataItem.deviceType === "control") {
      return `/farms/${dataItem?.farmID}/pivots/${dataItem?.deviceID}`;
    } else {
      return `/farms/${dataItem?.farmID}/irpds/${dataItem?.deviceID}`;
    }
  };

  //É um device, tem várias características
  if (dataItem.type != "FOUND")
    return (
      <div
        style={{
          ...style,
          backgroundColor:
            dataItem.logId % 2 == 0 ? "rgb(0, 0, 0, 0.1)" : "transparent",
          paddingLeft: 10,
          paddingTop: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DateSpan>
          {moment(dataItem.date).format("DD/MM/YYYY HH:mm:ss")}
        </DateSpan>

        <SpanRow>
          <SpanKey>{i18n("BASIC_INFO_BOX_TYPE")}</SpanKey>
          <SpanValue>{dataItem.type}</SpanValue>
        </SpanRow>

        <SpanRow>
          <SpanKey>{i18n("SELECTED_FARM_FILTER_NAME")}:</SpanKey>
          <SpanValue>{dataItem.deviceName}</SpanValue>
        </SpanRow>

        <SpanRow>
          <SpanKey>{i18n("ID_RADIO")}:</SpanKey>
          <SpanValue>
            {dataItem.id} ({dataItem.radio})
          </SpanValue>
        </SpanRow>

        <SpanRow>
          <SpanKey>{i18n("SIGNAL_STRENGTH")}:</SpanKey>
          <SpanValue>
            {dataItem.strength_string} ({dataItem.strength_dbm}dbm)
          </SpanValue>
        </SpanRow>

        <DateSpan>{dataItem.farmName}</DateSpan>
      </div>
    );
  else {
    //É só um RadioId achado, tem só o RadioId e a intensidade
    return (
      <div
        style={{
          ...style,
          backgroundColor:
            dataItem.logId % 2 == 0 ? "rgb(0, 0, 0, 0.1)" : "transparent",
          paddingLeft: 10,
          paddingTop: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DateSpan>
          {moment(dataItem?.date).format("DD/MM/YYYY HH:mm:ss")}
        </DateSpan>

        <DateSpan>{dataItem?.farmName}</DateSpan>

        <SpanRow>
          <SpanKey>{i18n("DEVICE")}</SpanKey>
          <Ref href={url(dataItem)}>{dataItem?.device}</Ref>
          {dataItem.deviceType === "-" ? (
            ""
          ) : (
            <LinkIcon fontSize={"small"}></LinkIcon>
          )}
        </SpanRow>

        <SpanRow>
          <SpanKey>{`${i18n("RADIO")}: `}</SpanKey>
          <SpanValue>{dataItem?.radio}</SpanValue>
        </SpanRow>
      </div>
    );
  }
}

export default DrawerRightDevices;

import { Reducer } from "redux";
import { Language, SettingState, SettingTypes } from "./types";
import { AuthActions } from "./actions";

const INITIAL_STATE: SettingState = {
  language: Language.PORTUGUESE_BRAZIL,
  //language: Language.ENGLISH
};

const reducer: Reducer<SettingState, AuthActions> = (
  state = INITIAL_STATE,
  action: AuthActions
): SettingState => {
  const { type }: { type: string } = action;

  switch (type) {
    case SettingTypes.SET_LANGUAGE:
      return { ...state, language: action.payload.language };

    default:
      return state;
  }
};

export default reducer;

import { useEffect, useState } from "react";
import { getAuthorization } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import axios from "axios";
import routes from "../../routes/routes";

interface DebtorActions {
  get: (farmID: string | number, daily?: boolean) => Promise<void>;
}

type Debtor = {
  has_invoice_past_due: boolean;
  is_debtor: boolean;
  total_days: number;
  show_daily_notification: boolean;
};

const useDebtor = (
  farmID: string | number
): [boolean, Debtor | undefined, DebtorActions, boolean] => {
  const [loading, setLoading] = useState(false);

  const [debtor, setDebtor] = useState<Debtor>(null);

  const [groupPermission, setGroupPermission] = useState(false);

  async function getDebtor(farmID: string | number, daily = false) {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/v4/farms/${farmID}/billing/customer/is_debtor/?daily=${daily}`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
          }
        );

        if (checkExists(["data", response, "__cascade"])) {
          setDebtor(response.data);
        }
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setGroupPermission(false);
        } else {
          setGroupPermission(true);
        }
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (
      !window.location.href.includes("disabled") &&
      !window.location.href.includes("edit") &&
      farmID &&
      debtor?.is_debtor
    )
      window.location.href = routes.DASHBOARD.FARM_DISABLED.replace(
        ":farm",
        `${farmID}`
      );
  }, [debtor]);

  return [
    loading,
    debtor,
    {
      get: getDebtor,
    },
    groupPermission,
  ];
};

export default useDebtor;

import { i18n } from "../../i18n/i18nText";

import engineirpd0 from "../../assets/images/markers/engineirpd0.svg";
import engineirpd1 from "../../assets/images/markers/engineirpd1.svg";
import engineirpd2 from "../../assets/images/markers/engineirpd2.svg";
import engineirpd3 from "../../assets/images/markers/engineirpd3.svg";
import engineirpd4 from "../../assets/images/markers/engineirpd4.svg";
import engineirpd5 from "../../assets/images/markers/engineirpd5.svg";
import engineirpd6 from "../../assets/images/markers/engineirpd6.svg";
import engineirpd7 from "../../assets/images/markers/engineirpd7.svg";
import engineirpd8 from "../../assets/images/markers/engineirpd8.svg";

import { Irpd } from "../../redux/irpds/types";

export const IRPD_STREAM_REASON_GROUP_STATUS = {
  STOPPED: [193, 251, 253, 254, 255],
  PUMPING: [1, 2, 3, 4], //Bombeando
  PROBLEM: [],
  PROGRAMMED: [5],
  ENERGIZED: [6],
  WITHOUT_ENERGY: [252],
  UNKNOWN: [0],
};

enum IRPD_STREAM_STATUS {
  STOPPED = 0,
  PUMPING,
  PROBLEM,
  PROGRAMMED,
  ENERGIZED,
  WITHOUT_ENERGY,
  UNKNOWN,
  HOURMETER_CORRUPTED,
  POSSIBLY_OFF = 193,
}

export enum IrpdStatusColors {
  ZERO = "#ffd700",
  ONE = "#4679ed",
  TWO = "#e92400",
  THREE = "#207833",
  FOUR = "#ff8c00",
  FIVE = "#e92400",
  SIX = "#000000",
  SEVEN = "#4679ed",
  EIGHT = "#757575",
}

export function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export const getIrpdStatusColor = (status: number): string => {
  switch (status) {
    case 0:
      return IrpdStatusColors.ZERO;
    case 1:
      return IrpdStatusColors.ONE;
    case 2:
      return IrpdStatusColors.TWO;
    case 3:
      return IrpdStatusColors.THREE;
    case 4:
      return IrpdStatusColors.FOUR;
    case 5:
      return IrpdStatusColors.FIVE;
    case 6:
      return IrpdStatusColors.SIX;
    case 7:
      return IrpdStatusColors.SEVEN;
    case 8:
      return IrpdStatusColors.EIGHT;
    case 193:
      return IrpdStatusColors.EIGHT;
  }
  return null;
};

export interface IrpdStreamStatus {
  number: number;
  text: string;
  color: string;
  path_image?: string;
}

export const getIrpdStreamStatus = (reason: number): IrpdStreamStatus => {
  switch (reason) {
    // to do: mapear os cases em um ENUM para melhor leitura
    case 1:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_1"), //Bomba Ligada Pela Web
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 2:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_2"), //Bomba Ligada Manualmente
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 3:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_3"), //Bomba Ligada Após Horário de Pico
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 4:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_4"), //Bomba Ligada Após Queda de Energia
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 5:
      return {
        number: IRPD_STREAM_STATUS.PROGRAMMED,
        text: i18n("IRPD_STATUS_PROGRAMMED"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PROGRAMMED),
        path_image: engineirpd3,
      };
    case 6:
      return {
        number: IRPD_STREAM_STATUS.ENERGIZED,
        text: i18n("IRPD_STATUS_ENERGIZED"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd0,
      };
    case 7:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_ON_BY_SENSOR"), // Pump turned on by a sensor
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 8:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_WAITING_AFTER_POWER_FAILURE"), //Pump waiting a time after power failure
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 9:
      return {
        number: IRPD_STREAM_STATUS.PUMPING,
        text: i18n("IRPD_STATUS_9"),
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PUMPING),
        path_image: engineirpd1,
      };
    case 192:
      return {
        number: IRPD_STREAM_STATUS.PROBLEM,
        text: i18n("IRPD_STATUS_HOURMETER_CORRUPTED"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PROBLEM),
        path_image: engineirpd2,
      };
    case 193:
      return {
        number: IRPD_STREAM_STATUS.POSSIBLY_OFF,
        text: i18n("IRPD_STATUS_NO_COMMUNICATION"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.POSSIBLY_OFF),
        path_image: engineirpd8,
      };
    case 194:
      return {
        number: IRPD_STREAM_STATUS.ENERGIZED,
        text: i18n("IRPD_STATUS_OPERATING"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.ENERGIZED),
        path_image: engineirpd4,
      };
    case 246:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_246"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd4, // 4
      };
    case 247:
      return {
        number: IRPD_STREAM_STATUS.PROBLEM,
        text: i18n("IRPD_STATUS_SCHEDULE_EXPIRED"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PROBLEM),
        path_image: engineirpd2,
      };
    case 248:
      return {
        number: IRPD_STREAM_STATUS.PROBLEM,
        text: i18n("IRPD_STATUS_INCORRECT_SCHEDULE"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PROBLEM),
        path_image: engineirpd2,
      };
    case 249:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_END_SCHEDULE_REACHED"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd0,
      };
    case 250:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_250"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd4,
      };
    case 251:
      return {
        number: IRPD_STREAM_STATUS.WITHOUT_ENERGY,
        text: i18n("IRPD_STATUS_WITHOUT_ENERGY"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.WITHOUT_ENERGY),
        path_image: engineirpd5,
      };
    case 252:
      return {
        number: IRPD_STREAM_STATUS.PROBLEM,
        text: i18n("IRPD_STATUS_252"), //Ok
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.PROBLEM),
        path_image: engineirpd2,
      };
    case 253:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_253"), //Bomba Desligada após horário de pico
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd0,
      };
    case 254:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_254"), //Bomba Desligada manualmente
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd0,
      };
    case 255:
      return {
        number: IRPD_STREAM_STATUS.STOPPED,
        text: i18n("IRPD_STATUS_255"), //Bomba Desligada Pela Web
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.STOPPED),
        path_image: engineirpd0,
      };
    default:
      return {
        number: IRPD_STREAM_STATUS.UNKNOWN,
        text: i18n("PANEL_STREAM_STATUS_NONE"),
        color: getIrpdStatusColor(IRPD_STREAM_STATUS.UNKNOWN),
        path_image: engineirpd6,
      };
  }
};

export const getIrpdStatusColorV5 = (status: number): string => {
  switch (status) {
    case 0:
      return IrpdStatusColors.ZERO;
    case 1:
      return IrpdStatusColors.ONE;
    case 2:
      return IrpdStatusColors.TWO;
    case 3:
      return IrpdStatusColors.THREE;
    case 4:
      return IrpdStatusColors.FOUR;
    case 5:
      return IrpdStatusColors.FIVE;
    case 6:
      return IrpdStatusColors.SIX;
    case 7:
      return IrpdStatusColors.SEVEN;
  }
  return null;
};

export function getLatestIrpdStreamV5(irpd: Irpd) {
  return irpd?.latest_irpd_stream_v5_event?.created >
    irpd?.latest_irpd_stream_v5_periodic?.created
    ? irpd?.latest_irpd_stream_v5_event
    : irpd?.latest_irpd_stream_v5_periodic;
}

import { useEffect, useState } from "react";
import { coreHTTPClient } from "../../services/webclient";
import checkExists from "../../utils/checks/checkExists";
import { Notification } from "../../redux/notifications/types";
import { useDispatch } from "react-redux";
import {
  setNotifications as actionSetNotifications,
  updateOrInsertNotification,
  deleteNotification as actionDeleteNotification,
} from "../../redux/notifications/actions";
import {
  NotificationType,
  TYPE_ROUTE,
} from "../../pages/Dashboard/Notifications/Notifications";

export interface NotificationHookActions {
  get: (type: number) => Promise<void>;
  patch: (
    type: number,
    notificationID: number,
    patchObject: any
  ) => Promise<void>;
  post: (type: number, notification: Notification) => Promise<void>;
  delete: (type: number, notification: Notification) => Promise<void>;
}

const useNotifications = (
  type?: NotificationType,
  farmID?: number
): [boolean, Notification[] | undefined, NotificationHookActions] => {
  const [notifications, setNotifications] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  async function getNotifications(type: NotificationType) {
    await new Promise(async (resolve) => {
      setLoading(true);
      try {
        const response = await coreHTTPClient.get(
          `v4/notifications/${TYPE_ROUTE[type]}/`
        );
        if (checkExists(["data", response, "__cascade"])) {
          try {
            if (farmID) {
              const filteredNotifications = response.data.filter(
                (notification) => notification.farm === farmID
              );
              setNotifications(filteredNotifications);
              dispatch(actionSetNotifications(filteredNotifications));
            } else {
              setNotifications([]);
              dispatch(actionSetNotifications([]));
            }
          } catch (err) {
            console.error(err);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    });
  }

  async function patchNotification(
    type: NotificationType,
    notificationID: number,
    patchObject: any
  ) {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.patch(
          `v4/notifications/${TYPE_ROUTE[type]}/${notificationID}/`,
          patchObject
        );
        if (checkExists(["data", response, "__cascade"])) {
          dispatch(updateOrInsertNotification(response.data));
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  async function postNotification(
    type: NotificationType,
    notification: Notification
  ) {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.post(
          `v4/notifications/${TYPE_ROUTE[type]}/`,
          notification
        );
        if (checkExists(["data", response, "__cascade"])) {
          dispatch(updateOrInsertNotification(response.data));
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  async function deleteNotification(
    type: NotificationType,
    notification: Notification
  ) {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.delete(
          `v4/notifications/${TYPE_ROUTE[type]}/${notification.id}/`
        );

        if (checkExists(["status", response, "__cascade"])) {
          if (response.status == 204)
            dispatch(actionDeleteNotification(notification));
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  useEffect(() => {
    if (type && farmID) getNotifications(type);
  }, []);

  return [
    loading,
    notifications,
    {
      get: getNotifications,
      patch: patchNotification,
      post: postNotification,
      delete: deleteNotification,
    },
  ];
};

export default useNotifications;

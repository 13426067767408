import React from "react";
import {
  ControllerStream,
  Pivot,
} from "../../../../../../../../../../redux/pivots/types";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../../../mobileConfig";

interface Props {
  panelStreamV5: ControllerStream;
  pivot?: Pivot;
}

function ExpandedGPSStreamV5(props: Props): any {
  const { panelStreamV5 } = props;

  return (
    <div className="expanded-content__background">
      <div
        className="expanded-content__group-padding"
        style={{ flexDirection: isMobile() ? "column" : "row" }}
      >
        <div style={{ marginRight: "3px" }}>
          {i18n("HISTORIC_PANEL_STREAM_V5_POSITION")}:
        </div>
        <a
          target="__blank"
          href={`http://maps.google.com/maps?t=k&q=${panelStreamV5.content.latitude_longitude_gps.latitude_gps},${panelStreamV5.content.latitude_longitude_gps.longitude_gps}`}
        >
          {` ${panelStreamV5.content.latitude_longitude_gps.latitude_gps},${panelStreamV5.content.latitude_longitude_gps.longitude_gps}`}
        </a>
      </div>
      {props.pivot?.automation_type == 0 ? (
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_GPS_STREAM_V5_REMAINING_TIME")}
            <b>
              {i18n("VALUE_HOUR_AND_MINUTE", [
                `${panelStreamV5.content.irrigation_remaining_time.hours}`,
                `${panelStreamV5.content.irrigation_remaining_time.minutes}`,
              ])}
            </b>
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default ExpandedGPSStreamV5;

import { Tab, Tabs, Typography } from "@material-ui/core";
import {
  EditLocationOutlined,
  HistoryOutlined,
  Star,
} from "@material-ui/icons";
import React, { useState } from "react";
import {
  PIVOT_COMMUNICATION_TYPE,
  Pivot,
} from "../../../../../../../../redux/pivots/types";
import HistoricConfigV5 from "../../../DrawerRightEditPivot/components/Historic/HistoricConfigV5";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../mobileConfig";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import HistoricConfigEncoder from "../../../DrawerRightEditPivot/components/Historic/HistoricConfigEncoder";

interface Props {
  ctx: any;
  farmID: number;
  pivot: Pivot;
  pivotID: number;
}

const LabelsWrapper = styled.div`
  margin: 20px 15px;
`;

const IconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface LabelProps {
  isV5?: boolean;
  pivot?: Pivot;
}

export function Labels(props: LabelProps) {
  const { pivot } = props;
  const automation_type = pivot.automation_type;
  const communication_type = pivot.communication_type;

  return (
    <LabelsWrapper>
      {isMobile() ? (
        <>
          <h4 style={{ margin: "10px 0 5px 0", color: "#555" }}>
            {i18n("LEGEND")}:
          </h4>

          <IconLabelWrapper>
            <HistoryOutlined fontSize={"inherit"} />

            <div style={{ marginLeft: "5px" }}>
              {i18n("ALL_CONFIGURATIONS")}
            </div>
          </IconLabelWrapper>

          <IconLabelWrapper>
            <Star fontSize={"inherit"} />

            <div style={{ marginLeft: "5px" }}>
              {i18n("HISTORIC_CONFIG_PIVOT_PINNED")}
            </div>
          </IconLabelWrapper>

          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
            <IconLabelWrapper>
              <EditLocationOutlined fontSize={"inherit"} />

              <div style={{ marginLeft: "5px" }}>
                {i18n("REDEFINE_ENCONDER_LABEL")}
              </div>
            </IconLabelWrapper>
          )}

          <IconLabelWrapper>
            <RemoveIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("CONFIG_NOT_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("CONFIG_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneAllIcon
              style={{ marginRight: "5px" }}
              fontSize={"inherit"}
              htmlColor={"rgb(33, 150, 243)"}
            />

            {i18n("CONFIG_RECEIVED")}
          </IconLabelWrapper>
        </>
      ) : (
        <>
          <h4 style={{ margin: "-10px 5px 0 -15px", color: "#555" }}>
            {i18n("LEGEND")}:
          </h4>

          <IconLabelWrapper>
            <HistoryOutlined
              fontSize={"inherit"}
              style={{ margin: "0 5px 0 -16px" }}
            />

            {i18n("ALL_CONFIGURATIONS")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <Star fontSize={"inherit"} style={{ margin: "0 5px 0 -16px" }} />

            {i18n("HISTORIC_CONFIG_PIVOT_PINNED")}
          </IconLabelWrapper>
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
            <IconLabelWrapper>
              <EditLocationOutlined
                fontSize={"inherit"}
                style={{ margin: "0 5px 0 -16px" }}
              />

              {i18n("REDEFINE_ENCONDER_LABEL")}
            </IconLabelWrapper>
          )}
          <IconLabelWrapper>
            <RemoveIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("TEXT_CONFIG_NOT_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgba(0,0,0,0.6)"}
            />

            {i18n("TEXT_CONFIG_SENT")}
          </IconLabelWrapper>

          <IconLabelWrapper>
            <DoneAllIcon
              style={{ margin: "0 5px 0 -15px" }}
              fontSize={"inherit"}
              htmlColor={"rgb(33, 150, 243)"}
            />

            {i18n("TEXT_CONFIG_RECEIVED")}
          </IconLabelWrapper>

          {automation_type == 2 ? (
            <div style={{ margin: "0 5px 0 -15px" }}>
              <b>{i18n("HISTORIC_PANEL_LABEL_MONITOR")}</b> -
              <span style={{ marginLeft: "10px" }}>
                {i18n("HISTORIC_CONFIG_INF_MONITOR")}
              </span>
            </div>
          ) : pivot?.control_radio_id || pivot?.protocol !== 5 ? (
            <div style={{ margin: "0 5px 0 -15px" }}>
              <b>{i18n("HISTORIC_PANEL_LABEL_CONTROLLER")}</b> -
              <span style={{ marginLeft: "10px" }}>
                {i18n("HISTORIC_CONFIG_INF_CONTROLLER")}
              </span>
            </div>
          ) : null}

          {automation_type == 2
            ? null
            : props?.isV5 && (
                <div style={{ margin: "0 5px 0 -15px" }}>
                  <b>{i18n("HISTORIC_PANEL_LABEL_HARDWARE")}</b> -
                  <span style={{ marginLeft: "10px" }}>
                    {i18n("HISTORIC_CONFIG_INF_HARDWARE")}
                  </span>
                </div>
              )}

          {communication_type === 1 ? null : (
            <>
              <div style={{ margin: "0 5px 0 -15px" }}>
                <b>{i18n("HISTORIC_PANEL_LABEL_GPS")}</b> -
                <span style={{ marginLeft: "10px" }}>
                  {i18n("HISTORIC_CONFIG_INF_GPS")}
                </span>
              </div>

              {automation_type == 2 ? null : (
                <div style={{ margin: "0 5px 0px -15px" }}>
                  <b>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</b> -
                  <span style={{ marginLeft: "10px" }}>
                    {i18n("HISTORIC_CONFIG_INF_PUMP")}
                  </span>
                </div>
              )}
            </>
          )}
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
            <div style={{ margin: "0 5px 0 -15px" }}>
              <b>{"E"}</b> -
              <span style={{ marginLeft: "10px" }}>{i18n("ENCODER")}</span>
            </div>
          )}
        </>
      )}
    </LabelsWrapper>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <div style={{ padding: "0px 10px 3px 0" }}>{children}</div>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HistoricConfigMobileV5(props: Props) {
  const { ctx, farmID, pivot, pivotID } = props;

  //Configuração do tab
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Labels isV5 pivot={pivot} />

        <Tabs
          orientation="horizontal"
          value={value}
          centered={true}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Configuration Historic"
          variant="fullWidth"
        >
          <Tab
            style={{
              minWidth: 0,
              borderTop: "1px solid rgba(0,0,0,0.1)",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <HistoryOutlined
                color="inherit"
                htmlColor={value === 0 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(0)}
          />

          <Tab
            style={{
              minWidth: 0,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              borderTop: "1px solid rgba(0,0,0,0.1)",
            }}
            icon={
              <Star
                color="inherit"
                htmlColor={value === 1 ? "#44b86a" : "initial"}
              />
            }
            {...selectedTab(1)}
          />
          {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
            <Tab
              style={{
                minWidth: 0,
                borderBottom: "1px solid rgba(0,0,0,0.1)",
                borderTop: "1px solid rgba(0,0,0,0.1)",
              }}
              icon={
                <EditLocationOutlined
                  htmlColor={value === 2 ? "#44b86a" : "initial"}
                />
              }
              {...selectedTab(2)}
            />
          )}
        </Tabs>

        <TabPanel value={value} index={0}>
          <HistoricConfigV5
            pivot={pivot}
            farmID={farmID}
            pivotID={pivotID}
            ctx={ctx}
            pinned={false}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <HistoricConfigV5
            farmID={farmID}
            pivotID={pivotID}
            pivot={pivot}
            ctx={ctx}
            pinned={true}
          />
        </TabPanel>
        {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
          <TabPanel value={value} index={2}>
            <HistoricConfigEncoder
              pivot={pivot}
              farmID={farmID}
              pivotID={pivotID}
            />
          </TabPanel>
        )}
      </div>
    </div>
  );
}

import React, { useMemo } from "react";
import SnackProvider from "./SnackProvider/SnackProvider";
import { MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "../../style/createMuiTheme";
import Redux from "../../redux";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Persistor } from "redux-persist";
import { Store } from "redux";
import { ChildrenProps } from "../../utils/types";
import GeneralModal from "../GeneralModal/GeneralModal";
import { RecoilRoot } from "recoil";
import GeneralLocationModal from "../GeneralLocationModal/GeneralLocationModal";

const AppProviders = (props: ChildrenProps) => {
  const store: Store = useMemo(() => Redux().store, []);
  const persistor: Persistor = useMemo(() => Redux().persistor, []);
  const { children } = props;

  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <SnackProvider>
          <MuiThemeProvider theme={createMuiTheme}>
            <RecoilRoot>
              {children}
              <GeneralModal />
              <GeneralLocationModal />
            </RecoilRoot>
          </MuiThemeProvider>
        </SnackProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default AppProviders;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, CircularProgress, Divider } from "@material-ui/core";
import { isMobile } from "../../../../../../../../../mobileConfig";
import Alert from "@material-ui/lab/Alert";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { coreHTTPClient } from "../../../../../../../../../services/webclient";
import ConfirmationModal from "../../../../../../../../Auth/SignUp/ConfirmationModal/ConfirmationModal";
import { i18n } from "../../../../../../../../../i18n/i18nText";

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EnableOperationsSummaryButton = styled(Button)`
  background-color: #196ffd;
  color: white;
  width: 310px;
  &:hover {
    background-color: #004ac4;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 15px;
  width: ${isMobile() ? "93%" : "65%"};
`;

const RecalculateOperationsSummaryButton = styled(Button)`
  background-color: #ffc832;
  color: white;
  width: 310px;
  &:hover {
    background-color: #e0a400;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 15px 0 15px 0;
`;

export default function OperationsSummary(props: {
  powerRanges: any;
  startPivotReportAggregate: any;
  farmID: any;
  hasPowerRangesChangedCount: number;
}) {
  const { powerRanges, startPivotReportAggregate, farmID } = props;

  const notify = useNotify();

  const [recalculateLoading, setRecalculateLoading] = useState(false);
  const [recalculateClicked, setRecalculateClicked] = useState(false);

  const [openConfirmation, setConfirmation] = useState(false);

  const [enableLoading, setEnableLoading] = useState(false);
  const [enableClicked, setEnableClicked] = useState(false);

  async function recalculateReport() {
    setRecalculateLoading(true);
    await new Promise(async (resolve) => {
      const response = await coreHTTPClient
        .post(`/v3/reports/farms/${farmID}/pivot-recalculate/`)
        .then((res) => {
          notify(
            "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS",
            "success",
            4000
          );
          setRecalculateClicked(true);
        })
        .catch((err) => {
          notify("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR", "error");
        })
        .finally(() => {
          setRecalculateLoading(false);
        });
    });
  }

  async function enableReport() {
    setEnableLoading(true);
    await new Promise(async (resolve) => {
      const response = await coreHTTPClient
        .post(`/v3/reports/farms/${farmID}/pivot-enable/`)
        .then((res) => {
          notify("EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS", "success", 4000);
          setEnableClicked(true);
        })
        .catch((err) => {
          notify("EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR", "error");
        })
        .finally(() => {
          setEnableLoading(false);
        });
    });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "12px",
          boxSizing: "border-box",
          width: "calc(100%)",
        }}
      >
        {props.hasPowerRangesChangedCount > 0 ? (
          <StyledAlert
            style={isMobile() ? { width: "100%" } : null}
            severity="warning"
          >
            {i18n("EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING")}
          </StyledAlert>
        ) : null}
        <h3 style={{ margin: 3 }}>
          {i18n("EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL")}
        </h3>
        <span style={{ margin: 3, marginBottom: 10 }}>
          {i18n("EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT")}
        </span>
        {!powerRanges["0"]?.length ? (
          <StyledAlert
            style={isMobile() ? { width: "100%" } : null}
            severity="error"
          >
            {i18n("EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES")}
          </StyledAlert>
        ) : null}
        {startPivotReportAggregate === 1 ||
        enableClicked ||
        recalculateClicked ? (
          <StyledAlert
            style={isMobile() ? { width: "100%" } : null}
            severity="info"
          >
            {i18n("EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING")}
          </StyledAlert>
        ) : null}
        {startPivotReportAggregate === 2 && !recalculateClicked ? (
          <StyledAlert
            style={isMobile() ? { width: "100%" } : null}
            severity="info"
          >
            {i18n("EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED")}
          </StyledAlert>
        ) : null}
        <ButtonContainer style={isMobile() ? { alignItems: "center" } : null}>
          <EnableOperationsSummaryButton
            onClick={() => {
              enableReport();
            }}
            endIcon={
              enableLoading ? (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: "8px", color: "white" }}
                />
              ) : null
            }
            disabled={
              !powerRanges["0"]?.length ||
              startPivotReportAggregate !== 0 || // Alert Verde Seu Resumo de Operações já está Habilitado
              enableClicked ||
              props.hasPowerRangesChangedCount > 0
            }
            style={
              !powerRanges["0"]?.length ||
              startPivotReportAggregate !== 0 ||
              enableClicked ||
              props.hasPowerRangesChangedCount > 0
                ? { backgroundColor: "#d4d4d4", color: "white" }
                : null
            }
          >
            {i18n("ALERT_ITEM_DO_ENABLE")}
          </EnableOperationsSummaryButton>
        </ButtonContainer>
        <StyledDivider />
        <h3 style={{ margin: 3 }}>
          {i18n("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL")}
        </h3>
        <span style={{ margin: 3, marginBottom: 10 }}>
          {i18n("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT")}
        </span>
        <ButtonContainer style={isMobile() ? { alignItems: "center" } : null}>
          <RecalculateOperationsSummaryButton
            onClick={() => {
              setConfirmation(true);
            }}
            endIcon={
              recalculateLoading ? (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: "8px", color: "white" }}
                />
              ) : null
            }
            disabled={
              startPivotReportAggregate !== 2 ||
              !powerRanges["0"]?.length ||
              recalculateClicked ||
              props.hasPowerRangesChangedCount > 0
            }
            style={
              !powerRanges["0"]?.length ||
              startPivotReportAggregate !== 2 ||
              recalculateClicked ||
              props.hasPowerRangesChangedCount > 0
                ? { backgroundColor: "#d4d4d4", color: "white" }
                : null
            }
          >
            {i18n("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE")}
          </RecalculateOperationsSummaryButton>
        </ButtonContainer>
        <ConfirmationModal
          openModal={openConfirmation}
          setModal={setConfirmation}
          text={"EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION"}
          confirmationButtonText={"SECTOR_PIVOT_REASON_TRUE"}
          handleConfirmation={() => {
            recalculateReport();
          }}
        />
      </div>
    </>
  );
}

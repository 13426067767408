import { TextField, Theme, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { NotificationType } from "../Notifications";
import { Notification } from "../../../../redux/notifications/types";
import { i18n } from "../../../../i18n/i18nText";

interface Props {
  type: NotificationType;
  mode: string;
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
  protocol: number;
  setProtocol: React.Dispatch<React.SetStateAction<number>>;
  hasProtocols: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    minHeight: "180px",
    maxHeight: "350px",
  },
  typography: {
    fontSize: "18px",
    fontWeight: "bolder",
    margin: "0px 0px 15px 0px",
  },
  toggle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 30px 30px 30px",
  },
}));

const NotificationsBasicInfo = (props: Props) => {
  const {
    type,
    mode,
    notification,
    setNotification,
    protocol,
    setProtocol,
    hasProtocols,
  } = props;

  const classes = useStyles();

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotification({
      ...notification,
      name: event.target.value,
    });
  };

  useEffect(() => {
    if (hasProtocols && !protocol) setProtocol(5);
  }, [protocol]);

  const getToggle = () => {
    if (type === NotificationType.PIVOT && mode === "create" && hasProtocols)
      return (
        <div style={{ margin: "20px 0px 0px 0px" }}>
          <Typography className={classes.typography}>
            {i18n("CREATE_NOTIFICATIONS_CHOOSE_GENERATION")}
          </Typography>

          <div className={classes.toggle}>
            <ToggleButtonGroup
              value={protocol}
              exclusive
              onChange={(event, value) => {
                if (value !== null) {
                  setProtocol(value);
                }
              }}
            >
              <ToggleButton value={4.1} aria-label="center aligned">
                G1
              </ToggleButton>

              <ToggleButton value={5} aria-label="center aligned">
                G2
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      );
    else return null;
  };

  return (
    <div className={classes.body}>
      <Typography className={classes.typography}>
        {i18n("CREATE_NOTIFICATIONS_CHOOSE_NAME")}
      </Typography>

      <TextField
        id="name"
        variant="outlined"
        placeholder={i18n("CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER")}
        defaultValue={notification.name}
        name={notification.name}
        error={notification.name.length > 30}
        onChange={handleChangeName}
      />
      {getToggle()}
    </div>
  );
};

export default NotificationsBasicInfo;

import { Button, Tooltip } from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { isMobile } from "../../../../../../mobileConfig";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { i18n } from "../../../../../../i18n/i18nText";
import { PivotMaintenanceFamily } from "../../../../../../recoils/PivotRecoil";
import { coreHTTPClient } from "../../../../../../services/webclient";
import MaintenanceModalReason from "./MaintenanceModalReason";
import MaintenanceModalSpec from "./MaintenanceModalSpec";
import MaintenanceModalNote from "./MaintenanceModalNote";
import MaintenanceModalConfirmation from "./MaintenanceModalConfirmation";

// sufixo do socket:
const { SOCKET_SUFFIX } = process.env;

// estrutura do objeto recebido pelo componente MaintenanceButton por meio de props:
interface Props {
  farm_id?: number;
  pivot_id?: number;
  permissionLevel?: number;
  pivot_automation_type?: any;
}

// definição do componente:
function MaintenanceButton(props: Props) {
  // Declaração de estados: __________________

  const [maintenanceStatus, setMaintenanceStatus] = useRecoilState(
    PivotMaintenanceFamily(props.pivot_id) // este estado começa com o pivot_id, só para não começar vazio
  ); // no useEffect 2 eu faço um get para pegar o status atual de manutenção do pivô do banco de dados; é isto que define se o cadeado aparece aberto ou fechado
  const [reason, setReason] = useState("");
  const [spec, setSpec] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [isConnected, socket] = useSocketIO();
  const [openReason, setOpenReason] = useState(false); // este estado começa falso, assim o <MaintenanceModalReason /> ainda não aparece
  const [openSpec, setOpenSpec] = useState(false); // este estado começa falso, assim o <MaintenanceModalSpec /> ainda não aparece
  const [openNote, setOpenNote] = useState(false); // este estado começa falso, assim o <MaintenanceModalNote /> ainda não aparece
  const [openConfirmation, setOpenConfirmation] = useState(false); // este estado começa falso, assim o <MaintenanceModalConfirmation /> ainda não aparece

  // Declaração de funções e variáveis: __________________

  // variável que habilita/desabilita o cadeado de acordo com o nível de permissão do usuário
  let isDisabled = loading && props.permissionLevel >= 2;

  // funções passadas para os modais para pegar as opções selecionadas pelo usuário
  const getReason = (modalData: any) => {
    setReason(modalData);
  };

  const getSpec = (modalData: any) => {
    setSpec(modalData);
  };

  const getNote = (modalData: any) => {
    setNote(modalData);
  };

  // função acionada no OnClick do botão CONFIRMAR do <MaintenanceModalConfirmation />
  // o botão CONFIRMAR está dentro do <MaintenanceModalConfirmation />
  // com esta função, eu envio um PATCH para trocar o status atual de manutenção do pivô no banco de dados
  // no mesmo patch, eu envio o motivo da parada, a especificação do motivo, e a observação sobre o motivo
  const toggleMaintenanceStatus = useCallback(() => {
    if (props.pivot_automation_type === 2) {
      // caso seja um pivô linear
      if (maintenanceStatus === true) {
        setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
        (async () => {
          setLoading(true);
          await coreHTTPClient
            .patch(
              `v3/farms/${props.farm_id}/pivots/${props.pivot_id}/maintenance/`,
              {
                maintenance: !maintenanceStatus,
              }
            )
            .catch(() => {
              setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
            })
            .finally(() => {
              setLoading(false);
            });
        })();

        return false;
      } else if (reason && spec && note) {
        setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
        (async () => {
          setLoading(true);
          await coreHTTPClient
            .patch(
              `v3/farms/${props.farm_id}/pivots/${props.pivot_id}/maintenance/`,
              {
                maintenance: !maintenanceStatus,
                reason: reason,
                specification: spec,
                note: note,
              }
            )
            .catch(() => {
              setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
            })
            .finally(() => {
              setLoading(false);
            });
        })();

        return false;
      }
    } else {
      // caso seja um pivô central
      setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
      (async () => {
        setLoading(true);
        await coreHTTPClient
          .patch(
            `v3/farms/${props.farm_id}/pivots/${props.pivot_id}/maintenance/`,
            {
              maintenance: !maintenanceStatus,
            }
          )
          .catch(() => {
            setMaintenanceStatus((maintenanceStatus) => !maintenanceStatus);
          })
          .finally(() => {
            setLoading(false);
          });
      })();

      return false;
    }
  }, [
    props.pivot_id,
    maintenanceStatus,
    reason,
    spec,
    note,
    props.pivot_automation_type,
  ]);

  // Declaração de useEffects: __________________

  // useEffect 1 para CONEXÃO DO SOCKETIO
  // useEffect 1
  useEffect(() => {
    let channel = `${SOCKET_SUFFIX}pivot@${props.pivot_id}`;
    if (isConnected) {
      socket.subscribe(channel);
      socket.bind("maintenance", (data) => {
        setMaintenanceStatus(data.maintenance);
      });
    }

    return () => {
      socket.unbind("maintenance");
    };
  }, [props.pivot_id]);

  // useEffect 2 para pegar o STATUS ATUAL DE MANUTENÇÃO do pivô no banco de dados
  // eu envio um GET para pegar o status atual de manutenção do pivô no banco de dados
  // se o status atual for false, o cadeado aparece aberto
  // se o status atual for true, o cadeado aparece fechado
  // useEffect 2
  useEffect(() => {
    if (props.pivot_id === undefined || props.farm_id === undefined) return;

    (async () => {
      setLoading(true);
      let maintenanceResponse = await coreHTTPClient
        .get(`v3/farms/${props.farm_id}/pivots/${props.pivot_id}/maintenance/`)
        .finally(() => setLoading(false));
      if (maintenanceResponse.data?.maintenance !== undefined)
        setMaintenanceStatus(maintenanceResponse.data.maintenance);
    })();
  }, [props.pivot_id]);

  // Declaração do retorno: __________________

  if (props.pivot_automation_type === 2) {
    // caso seja um pivô linear
    return (
      <>
        <Tooltip
          title={
            maintenanceStatus
              ? i18n("MAINTENANCE_BUTTON_TOOLTIP_DISABLE") // Desabilitar Modo Manutenção
              : i18n("MAINTENANCE_BUTTON_TOOLTIP_ENABLE") // Habilitar Modo Manutenção
          }
        >
          <Button
            color="primary"
            style={{
              color: isMobile() ? `white` : `#44b86a`,
              opacity: isDisabled ? 0.5 : 1,
            }}
            onClick={
              maintenanceStatus === true
                ? () => {
                    setOpenConfirmation(true);
                  }
                : () => {
                    setOpenReason(true);
                  }
            }
            disabled={isDisabled}
          >
            {maintenanceStatus !== false ? <Lock /> : <LockOpen />}
          </Button>
        </Tooltip>

        <MaintenanceModalReason
          openModal={openReason}
          setModal={setOpenReason}
          text={"MAINTENANCE_MODAL_REASON_QUESTION"} // Informar motivo de parada?
          childToParent={getReason}
          handleNext={setOpenSpec}
        />

        <MaintenanceModalSpec
          openModal={openSpec}
          setModal={setOpenSpec}
          text={"MAINTENANCE_MODAL_SPEC_QUESTION"} // Especificar o motivo da parada?
          childToParent={getSpec}
          handleNext={setOpenNote}
          reason={reason}
        />

        <MaintenanceModalNote
          openModal={openNote}
          setModal={setOpenNote}
          text={"MAINTENANCE_MODAL_NOTE_QUESTION"} // Alguma observação?
          childToParent={getNote}
          handleNext={setOpenConfirmation}
        />

        <MaintenanceModalConfirmation
          openModal={openConfirmation}
          setModal={setOpenConfirmation}
          text={
            maintenanceStatus
              ? "MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE" // Desabilitar Modo Manutenção?
              : "MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE" // Habilitar Modo Manutenção?
          }
          handleConfirmation={() => {
            toggleMaintenanceStatus();
          }}
        />
      </>
    );
  } else {
    // caso seja um pivô central
    return (
      <>
        <Tooltip
          title={
            maintenanceStatus
              ? i18n("MAINTENANCE_BUTTON_TOOLTIP_DISABLE") // Desabilitar Modo Manutenção
              : i18n("MAINTENANCE_BUTTON_TOOLTIP_ENABLE") // Habilitar Modo Manutenção
          }
        >
          <Button
            color="primary"
            style={{
              color: isMobile() ? `white` : `#44b86a`,
              opacity: isDisabled ? 0.5 : 1,
            }}
            onClick={() => {
              setOpenConfirmation(true);
            }}
            disabled={isDisabled}
          >
            {maintenanceStatus !== false ? <Lock /> : <LockOpen />}
          </Button>
        </Tooltip>

        <MaintenanceModalConfirmation
          openModal={openConfirmation}
          setModal={setOpenConfirmation}
          text={
            maintenanceStatus
              ? "MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE" // Desabilitar Modo Manutenção?
              : "MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE" // Habilitar Modo Manutenção?
          }
          handleConfirmation={() => {
            toggleMaintenanceStatus();
          }}
        />
      </>
    );
  }
}

// exportação do componente:
export default MaintenanceButton;

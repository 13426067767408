import { action } from "typesafe-actions";
import { NotificationsTypes, Notification } from "./types";
import { Action } from "redux";

export interface SetNotifications extends Action {
  type: NotificationsTypes.SET_NOTIFICATIONS;
  payload: Notification[];
}

export interface UpdateOrInsertNotification extends Action {
  type: NotificationsTypes.UPDATE_OR_INSERT_NOTIFICATION;
  payload: Notification;
}

export interface DeleteNotification extends Action {
  type: NotificationsTypes.DELETE_NOTIFICATION;
  payload: Notification;
}

export const setNotifications = (
  notifications: Notification[]
): SetNotifications =>
  action(NotificationsTypes.SET_NOTIFICATIONS, notifications);

export const updateOrInsertNotification = (
  notification: Notification
): UpdateOrInsertNotification =>
  action(NotificationsTypes.UPDATE_OR_INSERT_NOTIFICATION, notification);

export const deleteNotification = (
  notification: Notification
): DeleteNotification =>
  action(NotificationsTypes.DELETE_NOTIFICATION, notification);

export type NotificationActions = SetNotifications &
  UpdateOrInsertNotification &
  DeleteNotification;

import React from "react";
import Chart from "react-apexcharts";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { formatFloatValue } from "../../../../../../../../utils/models/format";

interface Props {
  data?: any;
  series?: any;
}

export const MyResponsiveBar = (props: Props) => {
  const handleBarData = (series) => {
    return [
      { HP: series.ponta.hours },
      { HFP: series.fora_de_ponta.hours },
      { HR: series.reduzido.hours },
    ];
  };

  const getHandleBarData = {
    HP: handleBarData(props?.series)[0]?.HP,
    HFP: handleBarData(props?.series)[1]?.HFP,
    HR: handleBarData(props?.series)[2]?.HR,
  };

  const state = {
    series: [
      {
        name: i18n("POWER_RANGE_PEAK_ACRONYM"),
        data: [getHandleBarData.HP],
      },
      {
        name: i18n("POWER_RANGE_OFF_PEAK_ACRONYM"),
        data: [getHandleBarData.HFP],
      },
      {
        name: i18n("POWER_RANGE_REDUCED_ACRONYM"),
        data: [getHandleBarData.HR],
      },
    ],
    options: {
      grid: {
        row: {
          colors: ["#fff", "#f7f2f2"],
        },
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: undefined,
        formatter: function (value) {
          return formatFloatValue(value);
        },
        style: {
          colors: ["rgba(51, 51, 51, 0.8)"],
        },
      },
      colors: ["#E84C4D", "#D3E8FE", "#4C9472"],
      animations: {
        enabled: false,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 350,
        },
      },
      xaxis: {
        categories: [
          i18n("POWER_RANGE_PEAK_ACRONYM"),
          i18n("POWER_RANGE_OFF_PEAK_ACRONYM"),
          i18n("POWER_RANGE_REDUCED_ACRONYM"),
        ],
        title: {
          text: i18n("BASIC_INFO_BOX_TIME_RANGES"),
          align: "left",
        },
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return formatFloatValue(val);
          },
        },
        title: {
          text: i18n("BASIC_INFO_BOX_HOURS"),
        },
      },
      tooltip: {
        x: {
          formatter: function (val, opts) {
            if (
              opts.w.globals.labels[opts.seriesIndex] ===
              opts.w.globals.labels[0]
            ) {
              return i18n("BASIC_BAR_TOOLTIP_HP");
            } else if (
              opts.w.globals.labels[opts.seriesIndex] ===
              opts.w.globals.labels[1]
            ) {
              return i18n("BASIC_BAR_TOOLTIP_HFP");
            } else if (
              opts.w.globals.labels[opts.seriesIndex] ===
              opts.w.globals.labels[2]
            ) {
              return i18n("BASIC_BAR_TOOLTIP_HR");
            }
          },
        },
      },
    },
  };
  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height="80%"
      width="100%"
    />
  );
};

import React, { useCallback, useMemo, useRef } from "react";
import FormInput from "../../../../../../../../../components/Form/FormInput/FormInput";
import {
  ValidationErrorMessage,
  ValidationErrorActions,
} from "../../../../../../../../../hooks/tools/useFormValidation";
import {
  checkEmail,
  checkIsNotNumber,
  checkIsOnIntervalString,
} from "../../../../../../../../../utils/checks";
import FormSelect from "../../../../../../../../../components/Form/FormSelect/FormSelect";
import countries from "../../../../../../../../../utils/consts/countries";
import { handleNumberMask } from "../../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import { isMobile } from "../../../../../../../../../mobileConfig";
import axios from "axios";
import useNotify from "../../../../../../../../../hooks/tools/useNotify";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { i18n } from "../../../../../../../../../i18n/i18nText";
import BillingHistoryContainer from "../BillingHistory/BillingHistoryContainer";
import PaymentMethodContainer from "../PaymentMethod/PaymentMethodContainer";
import BillingDescription from "../BillingDescription/BillingDescription";
import LanguageSelectModal from "../LanguageSelectModal/LanguageSelectModal";

interface Props {
  farmID: string;
  costumerID: any;
  actions: any;
  validationErrors: ValidationErrorMessage;
  formValidationActions: ValidationErrorActions;
  billingDocumentType: [number, (value: number) => void];
  billingDocument: [string, (value: string) => void];
  billingCompanyName: [string, (value: string) => void];
  billingPhone: [string, (value: string) => void];
  billingEmail: [string, (value: string) => void];
  IDD: any;
  billingPostalCode: [string, (value: string) => void];
  billingAddress: [string, (value: string) => void];
  billingCity: [string, (value: string) => void];
  billingState: [string, (value: string) => void];
  billingCountry: [string, (value: string) => void];
  billingLanguage: [string, (value: string) => void];
}

export default function Billing(props: Props) {
  const {
    farmID,
    costumerID,
    actions,
    validationErrors,
    formValidationActions,
    billingDocumentType,
    billingDocument,
    billingCompanyName,
    billingPhone,
    billingEmail,
    IDD,
    billingPostalCode,
    billingAddress,
    billingCity,
    billingState,
    billingCountry,
    billingLanguage,
  } = props;

  const notify = useNotify();

  // Refs para os campos de endereço
  const addressRef = useRef<HTMLInputElement | null>(null);

  const cityRef = useRef<HTMLInputElement | null>(null);

  const stateRef = useRef<HTMLInputElement | null>(null);

  //billing document type
  const [documentType, seDocumentType] = React.useState(0);

  //billing document
  const setValidDocument = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      billingDocument[1](
        documentType ? formatCPF(trimValue) : formatCNPJ(trimValue)
      );

      const formatedValue = documentType
        ? formatCPF(trimValue)
        : formatCNPJ(trimValue);

      formValidationActions.setError(
        "billingDocument",
        validateDocumentType(documentType, formatedValue)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  //billing company name
  const setValidCompanyName = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      billingCompanyName[1](trimValue);

      formValidationActions.setError(
        "billingCompanyName",
        checkIsOnIntervalString(value, 3, 34)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // billing phone
  const setValidPhone = useCallback(
    (value) => {
      let updatedPhone = handleNumberMask(value);

      if (updatedPhone.length > 14) {
        updatedPhone = updatedPhone.slice(0, 14);
      }

      updatedPhone = updatedPhone.replace(
        /^(\d{2})(\d{4,5})(\d{4})$/,
        "($1) $2-$3"
      );

      billingPhone[1](updatedPhone);

      if (
        updatedPhone === "" ||
        !/^\(\d{2}\) \d{4,5}-\d{4}$/.test(updatedPhone) ||
        /^(\d)\1+$/.test(updatedPhone.replace(/\D/g, ""))
      ) {
        formValidationActions.setError("billingPhone", "EDIT_FARM_ERROR_PHONE");
      } else {
        formValidationActions.clearError("billingPhone");
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // billing email
  const setValidEmail = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      billingEmail[1](trimValue);

      if (value.length === 0 || !checkEmail(value)) {
        formValidationActions.setError(
          "billingEmail",
          "EDIT_PROFILE_ERROR_EMAIL"
        );
      } else {
        formValidationActions.setError("billingEmail", undefined);
      }
    },

    [validationErrors, formValidationActions]
  );

  // billing postal code
  const setValidPostalCode = useCallback(
    (value) => {
      let trimValue = handleNumberMask(value).trimLeft();

      if (trimValue.length > 8) {
        trimValue = trimValue.slice(0, 8);
      }

      billingPostalCode[1](trimValue);

      const isAllZeros = /^0+$/.test(trimValue);

      const isAllSameDigit = /^(\d)\1+$/.test(trimValue);

      if (isAllZeros || isAllSameDigit) {
        formValidationActions.setError(
          "billingPostalCode",
          "VALIDATION_FIELD_INVALID"
        );
      } else {
        formValidationActions.clearError("billingPostalCode");
        formValidationActions.setError(
          "billingPostalCode",
          checkIsOnIntervalString(value, 8, 8)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors, formValidationActions, billingPostalCode]
  );

  // billing address
  const setValidAddress = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      billingAddress[1](trimValue);

      formValidationActions.setError(
        "billingAddress",
        checkIsOnIntervalString(value, 3, 60)
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingAddress, validationErrors]
  );

  // city
  const setValidCity = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        billingCity[1](trimValue);

        formValidationActions.setError(
          "billingCity",
          checkIsOnIntervalString(value, 3, 60)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingCity, validationErrors]
  );

  // state
  const setValidState = useCallback(
    (value) => {
      const trimValue = value.trimLeft();

      if (checkIsNotNumber(value)) {
        billingState[1](trimValue);

        formValidationActions.setError(
          "billingState",
          checkIsOnIntervalString(value, 2, 36)
        );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [billingState, validationErrors]
  );

  // country
  const setValidCountry = useCallback(
    (value) => {
      billingCountry[1](value);
    },
    [billingCountry]
  );

  const countryValues: [string, string][] = useMemo(
    () => countries.map((country) => [country.iso, country.content]),
    []
  );

  function handleDocumentTypeChange(
    event: React.ChangeEvent<{ value: unknown }>
  ) {
    seDocumentType(event.target.value as number);
    billingDocumentType[1](event.target.value as number);
  }

  function TestaCPF(strCPF) {
    let Soma: number;
    let Resto: number;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function TestaCNPJ(cnpj: string): boolean {
    if (cnpj === "") return false;

    if (cnpj.length !== 14) return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  }

  function validateDocumentType(documentType, documentValue) {
    const isCpf = documentType === 1;

    const cleanDocValue = documentValue.replace(/\D/g, "");

    if (documentValue === "") {
      return "ERROR_EMPTY_FIELD";
    }

    if (documentValue == null) {
      return "VALIDATION_FIELD_INVALID";
    }

    if (isCpf && !TestaCPF(cleanDocValue)) {
      return "VALIDATION_FIELD_INVALID";
    }

    if (!isCpf && !TestaCNPJ(cleanDocValue)) {
      return "VALIDATION_FIELD_INVALID";
    }

    const isAllZeros = /^0+$/.test(cleanDocValue);

    const isAllSameDigit = /^(\d)\1+$/.test(cleanDocValue);

    if (isAllZeros || isAllSameDigit) {
      return "VALIDATION_FIELD_INVALID";
    }

    const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
    const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

    const documentRegex = isCpf ? cpfRegex : cnpjRegex;

    return !documentRegex.test(documentValue)
      ? "VALIDATION_FIELD_INVALID"
      : undefined;
  }

  function formatCPF(text) {
    let cleanedText = text.trimLeft().replace(/\D/g, "");

    if (cleanedText.length > 11) {
      cleanedText = cleanedText.slice(0, 11);
    }

    const match = cleanedText.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
    if (match) {
      return match[1] + "." + match[2] + "." + match[3] + "-" + match[4];
    }
    return cleanedText;
  }

  function formatCNPJ(cnpj) {
    let cleanedCNPJ = cnpj.trimLeft().replace(/\D/g, "");

    if (cleanedCNPJ.length > 14) {
      cleanedCNPJ = cleanedCNPJ.slice(0, 14);
    }

    const match = cleanedCNPJ.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return (
        match[1] +
        "." +
        match[2] +
        "." +
        match[3] +
        "/" +
        match[4] +
        "-" +
        match[5]
      );
    }

    return cleanedCNPJ;
  }

  function getPostalCodeInfo() {
    if (billingPostalCode[0]?.length == 8) {
      axios
        .get(`https://viacep.com.br/ws/${billingPostalCode[0]}/json/`)
        .then(function (response) {
          if (response.status == 200 && !response.data.erro) {
            const address = `${response.data.logradouro} - ${response.data.bairro}`;
            setValidAddress(address);
            setValidCity(response.data.localidade);
            setValidState(response.data.uf);
          } else {
            notify("ERROR_ZIP_CODE", "error");
            setValidAddress("");
            setValidCity("");
            setValidState("");
          }
        });
    }
  }

  return (
    <div style={{ padding: isMobile() ? 12 : 0 }}>
      <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
        {i18n("BILLING")}
      </h3>

      <BillingDescription farmID={farmID} />

      <h3 style={{ marginTop: "40px", marginBottom: "10px" }}>
        {i18n("FARM_BILLING_INFORMATION_TITLE")}
      </h3>

      <div
        className="general-form__row-2-3-11"
        style={{ alignItems: "baseline" }}
      >
        <FormControl variant="standard">
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {i18n("FARM_BILLING_FIELD_DOCUMENT_TYPE")}
          </InputLabel>

          <Select
            value={
              billingDocumentType[0] ? billingDocumentType[0] : documentType
            }
            onChange={handleDocumentTypeChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={0}>CNPJ</MenuItem>

            <MenuItem value={1}>CPF</MenuItem>
          </Select>
        </FormControl>

        <FormInput
          id="farm-document"
          inputMode="numeric"
          helperText={validationErrors["billingDocument"]}
          label={"FARM_BILLING_FIELD_DOCUMENT"}
          state={[billingDocument[0], setValidDocument]}
        />

        <FormInput
          id="farm-name"
          helperText={validationErrors["billingCompanyName"]}
          label={
            documentType
              ? "EDIT_PROFILE_NAME"
              : "FARM_BILLING_FIELD_COMPANY_NAME"
          }
          state={[billingCompanyName[0], setValidCompanyName]}
        />
      </div>

      <div className="general-form__row-4-4-4">
        <LanguageSelectModal
          farmID={farmID}
          costumerID={costumerID}
          language={billingLanguage[0]}
          setLanguage={billingLanguage[1]}
          actions={actions}
        />
        <FormInput
          id="farm-billing-phone"
          inputMode="numeric"
          helperText={validationErrors["billingPhone"]}
          startAdornment={IDD}
          label={"FARM_BILLING_FIELD_PHONE"}
          state={[billingPhone[0], setValidPhone]}
        />

        <FormInput
          id="farm-billing-email"
          helperText={validationErrors["billingEmail"]}
          type="email"
          label={"FARM_BILLING_FIELD_EMAIL"}
          state={[billingEmail[0], setValidEmail]}
        />
      </div>

      <h3 style={{ marginTop: "40px", marginBottom: "10px" }}>
        {i18n("FARM_BILLING_ADDRESS_TITLE")}
      </h3>

      <div className="general-form__row-2-5">
        <FormInput
          id="farm-billing-postal-code"
          inputMode="numeric"
          helperText={validationErrors["billingPostalCode"]}
          label={"FARM_BILLING_FIELD_POSTAL_CODE"}
          state={[billingPostalCode[0], setValidPostalCode]}
          onBlur={getPostalCodeInfo}
        />

        <FormInput
          id="farm-billing-address"
          helperText={validationErrors["billingAddress"]}
          label={"FARM_BILLING_FIELD_ADDRESS"}
          state={[billingAddress[0], setValidAddress]}
          inputRef={addressRef} // Atribua a ref ao campo de endereço
        />
      </div>

      <div className="general-form__row-4-4-4">
        <FormInput
          id="farm-billing-city"
          helperText={validationErrors["billingCity"]}
          label={"FARM_BILLING_FIELD_CITY"}
          state={[billingCity[0], setValidCity]}
          inputRef={cityRef} // Atribua a ref ao campo de cidade
        />

        <FormInput
          id="farm-billing-state"
          helperText={validationErrors["billingState"]}
          label={"FARM_BILLING_FIELD_STATE"}
          state={[billingState[0], setValidState]}
          inputRef={stateRef} // Atribua a ref ao campo de estado
        />

        <FormSelect
          id="farm-billing-country"
          label={"FARM_BILLING_FIELD_COUNTRY"}
          state={[billingCountry[0], setValidCountry]}
          values={countryValues}
        />
      </div>

      <h3 style={{ marginTop: "40px", marginBottom: "10px" }}>
        {i18n("FARM_PAYMENT_METHOD_TITLE")}
      </h3>
      <span style={{ marginTop: "16px", textAlign: "justify" }}>
        {i18n("FARM_PAYMENT_METHOD_DESCRIPTION")}
      </span>

      <PaymentMethodContainer farmID={farmID} costumerID={costumerID} />

      <h3 style={{ marginTop: "40px", marginBottom: "10px" }}>
        {i18n("FARM_BILLING_HISTORY_TITLE")}
      </h3>

      <BillingHistoryContainer farmID={farmID} costumerID={costumerID} />
    </div>
  );
}

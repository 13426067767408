import React from "react";
import { Button } from "@material-ui/core";
import styled from "@emotion/styled";
import { motion, AnimatePresence } from "framer-motion";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";

const AbsoluteDiv = styled(motion.div)`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;

  background-color: #00000077;

  z-index: 99999;

  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  white-space: pre-line;
`;

const StyledButtonConfirm = styled(Button)`
  background-color: #2196f3 !important;
  color: #fff !important;

  &:hover {
    background-color: #06467a !important;
  }
`;

const StyledButtonCancel = styled(Button)`
  background-color: #9b9b9b !important;
  color: #fff !important;

  &:hover {
    background-color: #6e6e6e !important;
  }
`;

const ModalBox = styled(motion.div)`
  padding: 30px;

  background-color: white;

  display: grid;

  grid-template-areas:
    "Text Text"
    "Cancel Confirm";

  grid-row-gap: 30px;
  grid-column-gap: 20px;
`;

const TextArea = styled.div`
  grid-area: Text;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelArea = styled.div`
  grid-area: Cancel;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmArea = styled.div`
  grid-area: Confirm;

  display: flex;
  justify-content: center;
  align-items: center;
`;

//

interface Props {
  openModal: boolean;
  setModal: any;
  text: string;
  handleConfirmation: any;
}

function MaintenanceModalConfirmation(props: Props) {
  let { openModal, setModal, text, handleConfirmation } = props;

  return (
    <AnimatePresence>
      {openModal && (
        <AbsoluteDiv
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalBox initial={{ y: 1000 }} animate={{ y: 0 }} exit={{ y: 1000 }}>
            <TextArea>{i18n(text as i18nTextId)}</TextArea>

            <CancelArea>
              <StyledButtonCancel
                onClick={() => {
                  setModal(false);
                }}
              >
                {i18n("MAINTENANCE_MODALS_BUTTON_CANCEL")}
              </StyledButtonCancel>
            </CancelArea>

            <ConfirmArea>
              <StyledButtonConfirm
                onClick={() => {
                  handleConfirmation();
                  setModal(false);
                }}
              >
                {i18n("MAINTENANCE_MODALS_BUTTON_CONFIRM")}
              </StyledButtonConfirm>
            </ConfirmArea>
          </ModalBox>
        </AbsoluteDiv>
      )}
    </AnimatePresence>
  );
}

export default MaintenanceModalConfirmation;

import React from "react";
import { Grid } from "@material-ui/core";
import FormInput from "../../../../../../../../../../components/Form/FormInput/FormInput";
import { CreditCardOutlined } from "@material-ui/icons";
import { PaymentMethodCard } from "../../../../../../../../../../hooks/models/usePaymentMethod";

interface Props {
  paymentMethod: PaymentMethodCard;
}

export default function PaymentCardForm(props: Props) {
  const { paymentMethod } = props;

  return (
    <>
      <Grid item xs={12}>
        <FormInput
          id="payment-number"
          helperText={""}
          label={"PAYMENT_METHOD_CREDIT_CARD_NUMBER"}
          state={[paymentMethod.last4, () => {}]}
          endAdornment={<CreditCardOutlined />}
          type="text"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          id="payment-name"
          helperText={""}
          label={"PAYMENT_METHOD_CREDIT_NAME"}
          state={[paymentMethod.name, () => {}]}
          type="text"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <FormInput
          id="payment-exp-date"
          helperText={""}
          label={"PAYMENT_METHOD_CREDIT_CARD_EXPIRY"}
          state={[paymentMethod.exp_date, () => {}]}
          type="text"
          disabled
        />
      </Grid>
    </>
  );
}

import { UserFarm } from "../../redux/usersFarm/type";
import { coreHTTPClient } from "../../services/webclient";
import { setUsersFarm } from "../../redux/usersFarm/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ApplicationState } from "../../redux";

const useUsersFarm = (
  farmID
): [UserFarm[], UserFarm[], () => Promise<void>, boolean] => {
  const dispatch = useDispatch();
  //const [userFarm, setUserFarm] = useState([]);
  const usersFarms = useSelector(
    (state: ApplicationState) => state.usersFarm.list
  );
  const [pendingUsersInFarms, setPendingUsersInFarms] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getUsersFarm() {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.get(`/v3/farms/${farmID}/users/`);
        if (response) {
          dispatch(setUsersFarm(response.data.users));
          setPendingUsersInFarms(response.data.pending);
          setLoading(false);
        }
      } catch (err) {
      } finally {
        //setLoading(true);
      }
    });
  }

  useEffect(() => {
    getUsersFarm();
    // eslint-disable-next-line
  }, []);

  return [usersFarms, pendingUsersInFarms, getUsersFarm, loading];
};

export default useUsersFarm;

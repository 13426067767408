import React, { useState, useEffect } from "react";
import icWhiteLogo from "../../../../assets/images/logos/logo-white.svg";
import { TextField, Button } from "@material-ui/core";
import "./PasswordRecovery.scss";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../../routes/routes";
import useNewPassword from "../hooks/useNewPassword";
import WarningEmail from "../components/WarningEmail";
import { i18n } from "../../../../i18n/i18nText";
import { clearUserID } from "../../../../redux/userID/actions";
import { clearToken } from "../../../../redux/auth/actions";
import { useDispatch } from "react-redux";

// redux
interface Props {
  setForgetPassword?: React.Dispatch<React.SetStateAction<boolean>>;
}
interface PasswordRecovery {
  uid: string;
  token: string;
}

const PasswordRecovery = (props: Props) => {
  const navigate = useNavigate();
  const token: string = useParams().token;
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalFailed, setOpenModalFailed] = useState(false);
  const [
    { sendEmail, newPassword },
    loading,
    modal,
    success,
  ] = useNewPassword();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUserID());
    dispatch(clearToken());
    localStorage.clear();
  }, []);

  useEffect(() => {
    modal && setOpenModal(true);
  }, [modal]);

  useEffect(() => {
    if (success === false) setOpenModalFailed(true);
  }, [success]);

  return (
    <div className="password-recovery">
      <img className="login-page__logo" src={icWhiteLogo} alt="" />
      <div className="password-recovery__card">
        <div className="password-recovery__cardTitle ">
          <div className="password-recovery__cardTitle__title ">
            <>{i18n("FORGET_PASSWORD_RECOVEY")}</>
          </div>
        </div>

        <div className="password-recovery__card-information">
          <div className="password-recovery__card-information__firstSelect">
            <TextField
              id="standard-basic"
              type="password"
              onChange={(e) => setNewPassword1(e.target.value)}
              label={<>{i18n("FORGET_PASSWORD_NEW_PASSWORD")}</>}
            />
          </div>
          <div className="password-recovery__card-information__select">
            <TextField
              id="standard-basic"
              type="password"
              onChange={(e) => setNewPassword2(e.target.value)}
              label={<>{i18n("FORGET_PASSWORD_REPEAT_NEW_PASSWORD")}</>}
            />
          </div>
        </div>

        <div className="password-recovery__button">
          <Button
            color="primary"
            variant="contained"
            disabled={modal}
            onClick={() => {
              let regexValidator = new RegExp(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@'"!$!-_%*#?&]{8,}$/
              );
              if (newPassword1 !== newPassword2) {
                setOpenModalFailed(true);
              } else if (newPassword2 === "" || newPassword1 === "") {
                setOpenModalFailed(true);
              } else if (newPassword1.length < 8) {
                setOpenModalFailed(true);
              } else if (!regexValidator.test(newPassword1)) {
                setOpenModalFailed(true);
              } else {
                newPassword(newPassword1, newPassword2, token);
              }
            }}
          >
            <>{i18n("FORGET_PASSWORD_SAVE")}</>
          </Button>
        </div>

        {openModal && (
          <WarningEmail
            openModal={openModal}
            setOpenModal={(value) => {
              setOpenModal(false);
              navigate(routes.AUTH.LOGIN);
            }}
            text={"FORGET_PASSWORD_MODAL_SUCCESS"}
          />
        )}

        {openModalFailed && (
          <WarningEmail
            openModal={openModalFailed}
            setOpenModal={setOpenModalFailed}
            text={"FORGET_PASSWORD_FAILED"}
          />
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;

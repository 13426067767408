import React, { useState, useCallback, useEffect, useMemo } from "react";
import FormInput from "../../../components/Form/FormInput/FormInput";
import FormDate from "../../../components/Form/FormDatePicker/FormDatePicker";
import { ValidationErrorMessage } from "../../../components/Form/types";
import "./Profile.scss";
import { Button } from "@material-ui/core";
import moment from "moment";
import {
  checkEmail,
  checkIsNotNumber,
  checkIsOnIntervalString,
  checkUInt,
  validate_cpf,
} from "../../../utils/checks";
import useNotify from "../../../hooks/tools/useNotify";
import { Profile } from "../../../recoils/ProfileRecoil";
import { handleNumberMask } from "../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import CountrySelect, {
  countryToFlag,
  CountryType,
  DDIToIso,
  DDIToLabel,
} from "../../../components/ComboBoxCountryPicker/AutocompleteCountryPicker";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/routes";
import DesktopZone from "../../../components/Zones/DesktopZone";
import MobileZone from "../../../components/Zones/MobileZone";
import DashboardBox from "../components/DashboardBox/DashboardBox";
import DashboardBoxTitle from "../components/DashboardBox/DashboardBoxTitle";
import { CheckCircle } from "@material-ui/icons";
import { coreHTTPClient } from "../../../services/webclient";
import checkExists from "../../../utils/checks/checkExists";
import { i18n, i18nTextId } from "../../../i18n/i18nText";
import axios from "axios";
import countries from "../../../utils/consts/countries";
import FormSelect from "../../../components/Form/FormSelect/FormSelect";
import { useSetRecoilState } from "recoil";
import { SettingFistName } from "../../../recoils/SettingsRecoil";

interface Props {
  profile?: Profile;
  patch?: (profileForm: Profile) => void;
  profileType?: string;
  success?: boolean;
  disabled?: boolean;
}

export default function ProfileForm(props: Props) {
  const { profile, patch, profileType, success, disabled } = props;

  const notify = useNotify();
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(profile?.email ? false : true);

  useEffect(() => {
    if (success) {
      navigate(routes.DASHBOARD.MAIN);
    }
  }, [success]);

  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    firstName: undefined,
    lastName: undefined,
    officialDocument: undefined,
    email: undefined,
    birthday: undefined,
    prefix: undefined,
    cellPhone: undefined,
    landline: undefined,
    address_1: undefined,
    address_2: undefined,
    numberHouse: undefined,
    neighborhood: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    zipCode: undefined,
  });

  const [firstName, setFirstName] = useState(profile?.first_name);
  const setProfileName = useSetRecoilState(SettingFistName);
  const setValidFirstName = useCallback(
    (value) => {
      let errorID = "firstName";
      if (checkIsNotNumber(value)) {
        setFirstName(value);
        setValidationErrors({
          ...validationErrors,
          [errorID]: checkIsOnIntervalString(value, 3, 36),
        });
      }
    },
    [validationErrors]
  );

  const [lastName, setLastName] = useState(profile?.last_name);
  const setValidLastName = useCallback(
    (value) => {
      let errorID = "lastName";
      if (checkIsNotNumber(value)) {
        setLastName(value);
        setValidationErrors({
          ...validationErrors,
          [errorID]: checkIsOnIntervalString(value, 3, 36),
        });
      }
    },
    [validationErrors]
  );

  const [officialDocument, seOfficialDocument] = useState(profile?.cpf);
  const setValidOfficialDocument = useCallback(
    (value) => {
      let errorID = "officialDocument";
      if (checkUInt(value)) seOfficialDocument(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]:
          value?.length !== 11
            ? checkIsOnIntervalString(value, 11, 11)
            : !validate_cpf(value)
            ? "INVALID_CPF"
            : undefined,
      });
    },
    [validationErrors]
  );

  const [email, setEmail] = useState(profile?.email);
  const [language, setLanguage] = useState(profile?.language);
  const setValidEmail = useCallback(
    (value) => {
      let errorID = "email";
      setEmail(value);
      if (value?.length === 0 || !checkEmail(value)) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PROFILE_ERROR_EMAIL",
        });
        setDisableSave(true);
      } else {
        setDisableSave(false);
        setValidationErrors({ ...validationErrors, [errorID]: undefined });
      }
    },
    [validationErrors]
  );

  const [birthday, setBirthday] = useState(
    profile?.birth ? moment(profile?.birth) : moment()
  );
  const setValidBirthday = useCallback(
    (value) => {
      let errorID = "birthday";
      setBirthday(value);
      if (value?.length === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PROFILE_ERROR_BIRTHDAY",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  let profilePrefixPhone = profile?.prefix_cell_phone
    ? profile?.prefix_cell_phone
    : "55";

  let [prefixPhoneObject, setPrefixPhoneObject] = useState<CountryType>({
    code: DDIToIso(profilePrefixPhone),
    label: DDIToLabel(profilePrefixPhone),
    phone: profilePrefixPhone,
  });

  const [prefixPhone, setPrefixPhone] = useState(profilePrefixPhone);

  const setValidPrefixPhone = useCallback(
    (value: CountryType) => {
      let errorID = "prefix";
      if (value?.phone) {
        setPrefixPhone(`${countryToFlag(value.code)} + ${value.phone}`);
      }
      setPrefixPhone(value?.phone);
      setPrefixPhoneObject(value);
      if (value?.phone == "")
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PROFILE_ERROR_COUNTRY_CODE",
        });
      else setValidationErrors({ ...validationErrors, [errorID]: undefined });
    },
    [validationErrors]
  );

  const [cellPhone, setCellPhone] = useState(profile?.cell_phone);
  const setValidCellPhone = useCallback(
    (value) => {
      let errorID = "cellPhone";
      if (value?.length <= 20) setCellPhone(handleNumberMask(value));
      if (value?.length < 9 || checkIsNotNumber(cellPhone))
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PROFILE_ERROR_CELLPHONE",
        });
      else setValidationErrors({ ...validationErrors, [errorID]: undefined });
    },
    [validationErrors]
  );

  const [landline, setLandline] = useState(profile?.phone);
  const setValidLandline = useCallback(
    (value) => {
      let errorID = "landline";
      if (value.length <= 20) setLandline(handleNumberMask(value));
    },
    [validationErrors]
  );

  const [country, setCountry] = useState(
    profile?.country ? profile?.country : "BR"
  );
  const setValidCountry = useCallback((value) => setCountry(value), []);

  const countryValues: [string, string][] = useMemo(
    () => countries.map((country) => [country.iso, country.content]),
    []
  );

  const [state, setState] = useState(profile?.state ? profile?.state : "");
  const setValidState = useCallback(
    (value) => {
      let errorID = "state";
      if (checkIsNotNumber(value)) {
        setState(value);
        setValidationErrors({
          ...validationErrors,
          [errorID]: checkIsOnIntervalString(value, 2, 36),
        });
      }
    },
    [validationErrors]
  );

  const [city, setCity] = useState(profile?.city ? profile?.city : "");
  const setValidCity = useCallback(
    (value) => {
      let errorID = "city";
      if (checkIsNotNumber(value)) {
        setCity(value);
        setValidationErrors({
          ...validationErrors,
          [errorID]: checkIsOnIntervalString(value, 3, 60),
        });
      }
    },
    [validationErrors]
  );

  const [address_1, setAddress_1] = useState(
    profile?.address_1 ? profile?.address_1 : ""
  );
  const setValidAddress_1 = useCallback(
    (value) => {
      let errorID = "address_1";
      setAddress_1(value);
      setValidationErrors({ ...validationErrors, [errorID]: undefined });
    },
    [validationErrors]
  );

  const [address_2, setAddress_2] = useState(
    profile?.address_2 ? profile?.address_2 : ""
  );
  const setValidAddress_2 = useCallback(
    (value) => {
      let errorID = "address_2";
      setAddress_2(value);
    },
    [validationErrors]
  );

  const [numberHouse, setNumberHouse] = useState(profile?.number);
  const setValidNumberHouse = useCallback(
    (value) => {
      let errorID = "numberHouse";
      setNumberHouse(handleNumberMask(value));
      setValidationErrors({ ...validationErrors, [errorID]: undefined });
    },
    [validationErrors]
  );

  const [neighborhood, setNeighborhood] = useState(
    profile?.district ? profile?.district : ""
  );
  const setValidNeighborhood = useCallback(
    (value) => {
      let errorID = "neighborhood";
      setNeighborhood(value);
      setValidationErrors({ ...validationErrors, [errorID]: undefined });
    },
    [validationErrors]
  );

  const [zipCode, setZipCode] = useState(profile?.postal_code);
  const setValidZipCode = useCallback(
    (value) => {
      let errorID = "zipCode";
      setZipCode(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalString(value, 8, 8),
      });
    },
    [validationErrors]
  );

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  const triggerValidation = () => {
    if (firstName?.length === 0 || firstName === "")
      setValidationErrors((prevState) => ({
        ...prevState,
        firstName: "EDIT_PROFILE_ERROR_NAME",
      }));
    if (lastName?.length === 0 || lastName === "")
      setValidationErrors((prevState) => ({
        ...prevState,
        lastName: "EDIT_PROFILE_ERROR_LAST_NAME",
      }));
    if (officialDocument?.length < 11 || !validate_cpf(officialDocument))
      setValidationErrors((prevState) => ({
        ...prevState,
        officialDocument: "INVALID_CPF",
      }));
    if (prefixPhone?.length < 1 || prefixPhone?.length > 5) {
      notify("EDIT_PROFILE_ERROR_COUNTRY_CODE", "error");
    }
    if (
      cellPhone?.length == 0 ||
      !checkUInt(cellPhone) ||
      cellPhone?.length > 20
    )
      setValidationErrors((prevState) => ({
        ...prevState,
        cellPhone: "EDIT_PROFILE_ERROR_CELLPHONE",
      }));
    if (country?.length == 0)
      setValidationErrors((prevState) => ({
        ...prevState,
        country: "EDIT_PROFILE_ERROR_COUNTRY",
      }));
    if (!zipCode)
      setValidationErrors((prevState) => ({
        ...prevState,
        zipCode: "EDIT_PROFILE_ERROR_ZIPCODE",
      }));
    if (state?.length == 0)
      setValidationErrors((prevState) => ({
        ...prevState,
        state: "EDIT_PROFILE_ERROR_STATE",
      }));
    if (city?.length == 0)
      setValidationErrors((prevState) => ({
        ...prevState,
        city: "EDIT_PROFILE_ERROR_CITY",
      }));
  };

  const getValidationErrors = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        hasError = true;
      }
    });
    return hasError;
  };

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  async function changePassword(
    currentPassword,
    newPassword,
    confirmNewPassword
  ) {
    if (
      currentPassword == undefined ||
      newPassword === undefined ||
      confirmNewPassword === undefined
    ) {
      setLoading(false);
      return;
    }
    if (newPassword != confirmNewPassword) {
      notify("FORGET_PASSWORD_DIFFERENT", "error", 7000);
      setLoading(false);
      return;
    }
    if (newPassword?.length < 8 || !hasNumber(newPassword)) {
      notify("FORGET_PASSWORD_FAILED", "error", 7000);
      setLoading(false);
      return;
    }
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.post(`v3/auth/change/`, {
          current_password: currentPassword,
          new_password: newPassword,
        });
        if (checkExists(["data", response, "__cascade"])) {
          try {
            notify("FORGET_PASSWORD_MODAL_SUCCESS", "success");
          } catch (err) {}
        }
      } catch (err) {
        notify("CHANGE_PASSWORD_ERROR", "error");
      } finally {
        setLoading(false);
      }
    });
  }

  async function updateUser(firstName, lastName, email) {
    await new Promise(async (resolve) => {
      try {
        const response = await coreHTTPClient.patch(`v3/auth/user/`, {
          first_name: firstName,
          last_name: lastName,
          email,
        });
        notify("PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG", "success");
      } catch (err) {
        notify("PROFILE_BOX_CONVENTIONAL_ERROR_MSG", "error");
      }
    });
  }

  useEffect(() => {
    document.title = i18n("EDIT_PROFILE_TITLE");
  }, []);

  const saveButton = (
    <Button
      color="primary"
      endIcon={<CheckCircle />}
      disabled={disabled || loading || disableSave || getValidationErrors()}
      onClick={() => {
        setLoading(true);
        changePassword(currentPassword, newPassword, confirmNewPassword);
        if (!showErrorMessages()) {
          const profileForm = {
            name: firstName + " " + lastName,
            email: email,
            birth: birthday.toISOString().split("T", 1)[0],
            country: country,
            state: state,
            city: city,
            district: neighborhood,
            address_1: address_1,
            address_2: address_2,
            number: numberHouse,
            postal_code: zipCode,
            phone: landline,
            prefix_cell_phone: prefixPhone,
            cell_phone: cellPhone,
            cpf: officialDocument,
            language: language,
            role: null,
          };
          patch(profileForm);
          updateUser(firstName, lastName, email);
          setProfileName(firstName);
        }
      }}
    >
      <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>
    </Button>
  );

  useEffect(() => {
    if (zipCode?.length == 8) {
      axios
        .get(`https://viacep.com.br/ws/${zipCode}/json/`)
        .then(function (response) {
          if (response.status == 200 && !response.data.erro) {
            setValidState(response.data.uf);
            setValidCity(response.data.localidade);
            setValidAddress_1(response.data.logradouro);
            setValidNeighborhood(response.data.bairro);

            document.getElementById("firstName").focus();
            document.getElementById("lastName").focus();
            document.getElementById("cellPhone").focus();
            document.getElementById("officialDocument").focus();
            document.getElementById("state").focus();
            document.getElementById("city").focus();
            document.getElementById("address_1").focus();
            document.getElementById("neighborhood").focus();
            document.getElementById("zipCode").focus();
          } else {
            notify("ERROR_ZIP_CODE", "error");
          }
        });
      setValidZipCode(zipCode);
    }
  }, [zipCode]);

  useEffect(() => {
    triggerValidation();
  }, []);

  return (
    <>
      <DashboardBox
        centerElement={<DashboardBoxTitle title={"EDIT_PROFILE_TITLE"} />}
        rightElement={saveButton}
      >
        <div className="profile__content">
          <div className="profile__grid-col-2-3">
            <div className="profile__section-name">
              <h3>{i18n("EDIT_PROFILE_PERSONAL_INFO")}</h3>
            </div>
            <div>
              <div className="profile__grid-col-2-2">
                <FormInput
                  id="firstName"
                  helperText={validationErrors["firstName"]}
                  label={"EDIT_PROFILE_NAME"}
                  state={[firstName, setValidFirstName]}
                />
                <FormInput
                  id="lastName"
                  helperText={validationErrors["lastName"]}
                  label={"EDIT_PROFILE_LAST_NAME"}
                  state={[lastName, setValidLastName]}
                />
                <FormInput
                  id="officialDocument"
                  helperText={validationErrors["officialDocument"]}
                  label={"EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE"}
                  state={[officialDocument, setValidOfficialDocument]}
                />
                <FormInput
                  id="email"
                  helperText={validationErrors["email"]}
                  label={"EDIT_PROFILE_EMAIL"}
                  state={[email, setValidEmail]}
                />

                <DesktopZone>
                  <div className="container-row">
                    <div style={{ marginBottom: "2px" }}>
                      <CountrySelect
                        id="prefix"
                        label={"EDIT_PROFILE_COUNTRY_CODE"}
                        state={[prefixPhoneObject, setValidPrefixPhone]}
                      />
                    </div>
                    <div style={{ marginLeft: "15px" }}>
                      <FormInput
                        id="cellPhone"
                        type="tel"
                        helperText={validationErrors["cellPhone"]}
                        label={"EDIT_PROFILE_CELLPHONE"}
                        state={[cellPhone, setValidCellPhone]}
                      />
                    </div>
                  </div>
                  <FormInput
                    id="landline"
                    type="tel"
                    helperText={validationErrors["landline"]}
                    label={"EDIT_PROFILE_LANDLINE"}
                    state={[landline, setValidLandline]}
                  />
                  <FormDate
                    id="birthday"
                    label={"EDIT_PROFILE_BIRTHDAY"}
                    state={[birthday, setValidBirthday]}
                  />
                </DesktopZone>
              </div>
              <MobileZone>
                <div style={{ alignSelf: "center", marginTop: "5px" }}>
                  <div className="profile__grid-col-2-2">
                    <div style={{ marginBottom: "2px" }}>
                      <CountrySelect
                        id="prefix"
                        label={"EDIT_PROFILE_COUNTRY_CODE"}
                        state={[prefixPhoneObject, setValidPrefixPhone]}
                      />
                    </div>
                    <FormInput
                      id="cellPhone"
                      type="tel"
                      helperText={validationErrors["cellPhone"]}
                      label={"EDIT_PROFILE_CELLPHONE"}
                      state={[cellPhone, setValidCellPhone]}
                    />
                  </div>
                  <div className="profile__grid-col-2-2">
                    <FormInput
                      id="landline"
                      type="tel"
                      helperText={validationErrors["landline"]}
                      label={"EDIT_PROFILE_LANDLINE"}
                      state={[landline, setValidLandline]}
                    />
                    <div style={{ marginTop: "15px" }}>
                      <FormDate
                        id="birthday"
                        label={"EDIT_PROFILE_BIRTHDAY"}
                        state={[birthday, setValidBirthday]}
                      />
                    </div>
                  </div>
                </div>
              </MobileZone>
            </div>
          </div>
          <div className="profile__grid-col-2-3">
            <div className="profile__section-name">
              <h3>{i18n("EDIT_PROFILE_CONTACT_ADDRESS")}</h3>
            </div>
            <div>
              <div className="profile__grid-col-2-2">
                <div className="container-row">
                  <FormSelect
                    id="country"
                    label={"FARM_FIELD_COUNTRY"}
                    state={[country, setValidCountry]}
                    values={countryValues}
                  />
                </div>
              </div>
              <div className="profile__grid-col-2-2">
                <FormInput
                  id="zipCode"
                  helperText={validationErrors["zipCode"]}
                  label={"EDIT_PROFILE_ZIPCODE"}
                  state={[zipCode, setValidZipCode]}
                />
                <FormInput
                  id="state"
                  helperText={validationErrors["state"]}
                  label={"EDIT_PROFILE_STATE"}
                  state={[state, setValidState]}
                />
                <FormInput
                  id="city"
                  helperText={validationErrors["city"]}
                  label={"EDIT_PROFILE_CITY"}
                  state={[city, setValidCity]}
                />
                <FormInput
                  id="address_1"
                  helperText={validationErrors["address_1"]}
                  label={"EDIT_PROFILE_ADDRESS"}
                  state={[address_1, setValidAddress_1]}
                />
                <FormInput
                  id="neighborhood"
                  helperText={validationErrors["neighborhood"]}
                  label={"EDIT_PROFILE_NEIGHBORHOOD"}
                  state={[neighborhood, setValidNeighborhood]}
                />
                <FormInput
                  id="numberHouse"
                  helperText={validationErrors["numberHouse"]}
                  label={"EDIT_PROFILE_NUMBER_HOUSE"}
                  state={[numberHouse, setValidNumberHouse]}
                />
                <FormInput
                  id="address_2"
                  helperText={validationErrors["address_2"]}
                  label={"EDIT_PROFILE_ADDRESS_2"}
                  state={[address_2, setValidAddress_2]}
                />
              </div>
            </div>
          </div>
          <div className="profile__grid-col-2-3">
            <div className="profile__section-name">
              <h3>{i18n("EDIT_PROFILE_PASSWORD_CHANGE")}</h3>
            </div>
            <div>
              <div className="profile__grid-row-1-1-1">
                <FormInput
                  id="currentPassword"
                  label={"EDIT_PROFILE_CURRENT_PASSWORD"}
                  state={[currentPassword, setCurrentPassword]}
                  type="password"
                />
                <FormInput
                  id="newPassword"
                  label={"EDIT_PROFILE_NEW_PASSWORD"}
                  state={[newPassword, setNewPassword]}
                  type="password"
                />
                <FormInput
                  id="newConfirmPassword"
                  label={"EDIT_PROFILE_CONFIRM_NEW_PASSWORD"}
                  state={[confirmNewPassword, setConfirmNewPassword]}
                  type="password"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="login-page__privacy-container">
              <>{i18n("EDIT_PROFILE_TERMS_AND_CONDITIONS")}</>
              <>
                {" "}
                <a
                  className="login-page__privacy-link"
                  href="https://irricontrol.com.br/plataforma-termos-e-privacidade/"
                  target="__blank"
                >
                  {i18n("POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK")}
                </a>
                .{" "}
              </>
            </div>
          </div>
        </div>
      </DashboardBox>
    </>
  );
  return <div></div>;
}
